import { ChartInternal } from "c3";
import * as d3 from "d3";
import { range } from "lodash";

import { HistoryGraphItem } from "../components/submissionDetail";
import { formatDateTimeSeconds } from "./date";

/**
 * get formatted Y tick
 *
 * @param isAIChallenge
 * @param score
 * @param scoreMax
 */
export function formatYTick(
  isAIChallenge: boolean,
  score: number,
  scoreMax: number,
): string {
  return isAIChallenge
    ? score <= scoreMax
      ? d3.format(".2f")(score)
      : ""
    : score % 1 === 0
    ? d3.format("d")(score)
    : "";
}

/**
 * get maximum x axis position
 *
 * @param sortedItems
 * @param submittedAt
 */
export function getXMax(
  sortedItems: HistoryGraphItem[],
  submittedAt?: string,
): string | undefined {
  return submittedAt &&
    submittedAt.localeCompare(
      sortedItems[sortedItems.length - 1]?.datetime ?? "",
    ) > 0
    ? formatDateTimeSeconds(submittedAt)
    : undefined;
}

/**
 * get padding left
 *
 * @param sortedItems
 * @param submittedAt
 */
export function getPaddingLeft(
  sortedItems: HistoryGraphItem[],
  submittedAt?: string,
): number | undefined {
  return submittedAt &&
    submittedAt.localeCompare(
      sortedItems.length ? sortedItems[0].datetime : "",
    ) <= 0
    ? 60000
    : undefined;
}

/**
 * Returns a "nice" number approximately equal to range Rounds
 * the number if round = true Takes the ceiling if round = false.
 *
 *  localRange the data range
 *  round whether to round the result
 *  a "nice" number to be used for the data range
 */
const niceNum = (localRange: number, round: boolean) => {
  let niceFraction; /** nice, rounded fraction */

  /** exponent of localRange */
  const exponent = Math.floor(Math.log10(localRange));
  /** fractional part of localRange */
  const fraction = localRange / Math.pow(10, exponent);

  if (round) {
    if (fraction < 1.5) niceFraction = 1;
    else if (fraction < 3) niceFraction = 2;
    else if (fraction < 7) niceFraction = 5;
    else niceFraction = 10;
  } else {
    if (fraction <= 1) niceFraction = 1;
    else if (fraction <= 2) niceFraction = 2;
    else if (fraction <= 5) niceFraction = 5;
    else niceFraction = 10;
  }

  return niceFraction * Math.pow(10, exponent);
};

/**
 * Calculate values for tick spacing and nice
 * minimum and maximum data points on the axis.
 * https://stackoverflow.com/questions/8506881/nice-label-algorithm-for-charts-with-minimum-ticks
 */
export const calculateChartTicks = ({
  maxTicks = 10,
  minPoint,
  maxPoint,
}: {
  maxTicks?: number;
  minPoint: number;
  maxPoint: number;
}) => {
  const range = niceNum(maxPoint - minPoint, false);
  const tickSpacing = niceNum(range / (maxTicks - 1), true);
  const min = Math.floor(minPoint / tickSpacing) * tickSpacing;
  const max = Math.ceil(maxPoint / tickSpacing) * tickSpacing;

  const ticks = [];

  for (let x = min; x < max; x += tickSpacing) {
    ticks.push(x);
  }

  ticks.push(max);

  return ticks;
};

/**
 * Returns a "nice" rounded number for the donuts chart
 * @param value
 */
export const niceDonutChartNum = (value: number | undefined) => {
  if (value === undefined || value <= 0) {
    return 0;
  }
  return value >= 100
    ? Math.round(value)
    : Math.max(Math.round(value * 10) / 10, 0.1);
};

/**
 * returns [0, 1, 2, 3, 4] in which each value will replace x in Math.pow(10, x)
 */
export const getLogarithmicTicks = (logarithmicMaxValue: number) => {
  if (logarithmicMaxValue === 0) {
    return [0, 1];
  }

  return range(0, Math.ceil(logarithmicMaxValue + 1));
};

export const getChartXPosition = ({
  xScale,
  chartElement,
  tickPosition,
}: {
  xScale: ChartInternal[string];
  chartElement: HTMLElement;
  tickPosition: number;
}) => {
  const svg = d3.select(chartElement).select("svg");

  const rootDom = (svg.node() as Element)?.getBoundingClientRect?.();

  const svgLeft = rootDom.left;

  const chartDom = (
    svg.select(".c3-chart").node() as Element
  )?.getBoundingClientRect?.();

  const rectLeft = chartDom.left;
  const xAxisLegendWidth = rectLeft - svgLeft;
  const xPlacement = xScale(tickPosition) + xAxisLegendWidth;

  return xPlacement;
};

/**
 * Adjust the text's x position if it goes over the y axis area or is near
 * the right edge of the svg
 */
export const adjustTextPosition = ({
  chartElement,
  textElement,
}: {
  chartElement: HTMLElement;
  textElement: d3.Selection<SVGTextElement, unknown, null, undefined>;
}) => {
  const svg = d3.select(chartElement).select("svg");
  const textElementBoundaries: SVGRect | undefined = textElement
    .node()
    ?.getBBox();

  // get svgElement of the y axis container
  const yAxisContainerBoundaries: SVGRect | undefined = (
    svg.select(".c3-axis.c3-axis-y").node() as any
  )?.getBBox();

  const svgWidth =
    (svg.node() as Element)?.getBoundingClientRect?.().width ?? 0;

  if (textElementBoundaries && yAxisContainerBoundaries && svgWidth) {
    const textElementX = textElementBoundaries.x;
    const yAxisContainerBoundariesX = yAxisContainerBoundaries.x;

    // adjust text position if text goes over the y axis boundary
    if (Math.abs(yAxisContainerBoundariesX) > textElementX) {
      textElement.attr(
        "x",
        Math.abs(yAxisContainerBoundariesX) +
          textElementBoundaries.width * 0.625,
      );
      // adjust text position if text goes over the right edge of the svg
    } else if (textElementX + textElementBoundaries.width > svgWidth) {
      textElement.attr("x", svgWidth - textElementBoundaries.width / 2);
    }
  }
};
