import {
  ChallengeResultStatus,
  ChallengeTakenBy,
  ProgrammingLanguage,
  ScoreStatus,
} from "../services/enums";

class ApplicantChallengeResultModel {
  public id: number;
  public challengeId: number;
  public applicantId: number;
  public applicantExamId: number;
  public dueDate: string;
  public storageUrl: string;
  public storageId: string;
  public status: ChallengeResultStatus;
  public scoreStatus: ScoreStatus;
  public takenBy: ChallengeTakenBy;
  public openedAt: string;
  public finishedAt: string;
  public totalTestcases: number;
  public testOutput: string;
  public variables: { [key: string]: string };
  public lastScoredAt: string;
  public language: string;
  public updatedAt: string;
  public appealComment: string;
  public answers: {
    addedFiles: string[];
    fileVersions: { [key: string]: string };
  };
  public programmingLanguage: ProgrammingLanguage;

  constructor(args?: {}) {
    Object.assign(this, args);
  }

  public isEmpty() {
    return !Boolean(this.id);
  }

  /**
   * @protected
   * Do not call it directly. Use ApplicantExamModel.getChallengeResultStatusLabel instead.
   */
  public getResultStatusLabel() {
    return ChallengeResultStatus.toString(this.status);
  }

  public getScoreStatusLabel() {
    return ScoreStatus.toString(this.scoreStatus);
  }

  public getProgrammingLanguageLabel() {
    return (
      this.programmingLanguage &&
      ProgrammingLanguage.toString(this.programmingLanguage)
    );
  }

  /**
   * @protected
   * Do not call it directly. Use ApplicantExamModel.getChallengeStart instead.
   */
  public canStart() {
    return (
      this.status === undefined ||
      this.status === ChallengeResultStatus.Prepared ||
      this.status === ChallengeResultStatus.CanRestart
    );
  }

  /**
   * @protected
   * Do not call it directly. Use ApplicantExamModel.canChallengeSave instead.
   */
  public canSave() {
    return [
      ChallengeResultStatus.Started,
      ChallengeResultStatus.InProgress,
    ].includes(this.status);
  }

  /**
   * @protected
   * Do not call it directly. Use ApplicantExamModel.canChallengeReset instead.
   */
  public canReset() {
    return [ChallengeResultStatus.InProgress].includes(this.status);
  }

  /**
   * @protected
   * Do not call it directly. Use ApplicantExamModel.canChallengeSwitchLanguage instead.
   */
  public canSwitchLanguage() {
    return [
      ChallengeResultStatus.Started,
      ChallengeResultStatus.InProgress,
    ].includes(this.status);
  }

  /**
   * @protected
   * Do not call it directly. Use ApplicantExamModel.canChallengeSubmit instead.
   */
  public canSubmit() {
    return [
      ChallengeResultStatus.Started,
      ChallengeResultStatus.InProgress,
    ].includes(this.status);
  }

  /**
   * @protected
   * Do not call it directly. Use ApplicantExamModel.isChallengeStarted instead.
   */
  public isStarted() {
    return [
      ChallengeResultStatus.Started,
      ChallengeResultStatus.InProgress,
    ].includes(this.status);
  }

  /**
   * @protected
   * Do not call it directly. Use ApplicantExamModel.isChallengeFinished instead.
   */
  public isFinished() {
    return [
      ChallengeResultStatus.NotModified,
      ChallengeResultStatus.Finished,
      ChallengeResultStatus.ScoringWaiting,
    ].includes(this.status);
  }
}

export default ApplicantChallengeResultModel;
