import { EnumWithStringValueModel } from "@shared/models";

import MSG from "../services/message";

/* eslint-disable @typescript-eslint/no-namespace */

/**
 * Enum
 *
 * Enum should have static function to return string name.
 *
 * ex)
 *
 * enum Type {
 *   Normal,
 *   Expert,
 * }
 *
 * namespace Type {
 *   export function toString(type: Type) {
 *     switch(type) {
 *       case Type.Normal:
 *         return "message.normal";
 *       case Type.Expert:
 *        return "message.expert";
 *     }
 *   }
 * }
 */

function stringToEnum<T extends string>(o: T[]): { [K in T]: K } {
  return o.reduce((accumulator, currentValue) => {
    accumulator[currentValue] = currentValue;
    return accumulator;
  }, Object.create(null));
}

export function toDic(o: EnumWithStringValueModel[]): Record<string, string> {
  return o.reduce(
    (acc, { value, displayString }) => {
      acc[value + ""] = displayString;
      return acc;
    },
    {} as Record<string, string>,
  );
}

enum OrganizationKind {
  Normal = 1,
  Official = 2,
  Verify = 3,
}

enum OrganizationStatus {
  Active = 1,
  Inactive = 9,
}

/*
 * Application Type
 */
enum ApplicationType {
  Screening = 1,
  /**
   * @deprecated
   */
  Deprecated_Training = 2,
  /**
   * @deprecated
   */
  Deprecated_Assessment = 4,
}

namespace ApplicationType {
  export function toString(type: ApplicationType) {
    switch (type) {
      case ApplicationType.Screening:
        return MSG.getMessageByKey("applicationType.screening");
      default:
        return "Invalid ApplicationType";
    }
  }
}

export enum OrganizationAppType {
  Admin = 1,
  Individual,
}

export enum GlobalAppType {
  Org,
  Admin,
  User,
}

/*
 * Project Role
 */
enum ProjectRole {
  ProjectAdmin = 21,
  ExamCreator,
  ExamDeliverer,
  Reviewer,
  ChallengeCreator,
  /**
   * @deprecated
   */
  Deprecated_TrainingAdmin = 31,
  /**
   * @deprecated
   */
  Deprecated_CourseCreator,
  /**
   * @deprecated
   */
  Deprecated_CourseDeliverer,
  /**
   * @deprecated
   */
  Deprecated_CourseReviewer,
}

namespace ProjectRole {
  export function toString(type: ProjectRole) {
    switch (type) {
      case ProjectRole.ProjectAdmin:
        return MSG.getMessageByKey("projectRole.projectAdmin");
      case ProjectRole.ExamCreator:
        return MSG.getMessageByKey("projectRole.examCreator");
      case ProjectRole.ExamDeliverer:
        return MSG.getMessageByKey("projectRole.examDeliverer");
      case ProjectRole.Reviewer:
        return MSG.getMessageByKey("projectRole.reviewer");
      case ProjectRole.ChallengeCreator:
        return MSG.getMessageByKey("projectRole.challengeCreator");
      default:
        return "Invalid type for ProjectRole";
    }
  }
}

/*
 * User Role
 */
enum UserRole {
  SystemAdmin = 1,
  OrgAdmin = 11,
  /**
   * @deprecated
   */
  ChallengeCreator,
  /**
   * @deprecated
   */
  BookCreator,
}

namespace UserRole {
  export function toString(type: UserRole) {
    switch (type) {
      case UserRole.SystemAdmin:
        return MSG.getMessageByKey("userRole.systemAdmin");
      case UserRole.OrgAdmin:
        return MSG.getMessageByKey("userRole.orgAdmin");
      case UserRole.ChallengeCreator:
        return MSG.getMessageByKey("userRole.challengeCreator");
      case UserRole.BookCreator:
        return MSG.getMessageByKey("userRole.bookCreator");
      default:
        return "Invalid type for UserRole";
    }
  }
}

type Role = UserRole | ProjectRole;

export enum ProjectStatus {
  Active = 1,
  Archived = 9,
}

/*
 * Exam Type
 */
export enum ExamType {
  SingleLanguage = 1,
  EnglishJapanese,
}

/**
 * Challenge category
 */
enum ChallengeCategory {
  Official = 1,
  Custom,
}

namespace ChallengeCategory {
  export function toString(type: ChallengeCategory) {
    switch (type) {
      case ChallengeCategory.Official:
        return MSG.getMessageByKey("common.category.official");
      case ChallengeCategory.Custom:
        return MSG.getMessageByKey("common.category.custom");
      default:
        return "Invalid type with ChallengeCategory";
    }
  }
}

/**
 * Question category
 */
enum QuestionCategory {
  Preset = 1,
  Original,
}

namespace QuestionCategory {
  export function toString(type: QuestionCategory) {
    switch (type) {
      case QuestionCategory.Preset:
        return MSG.getMessageByKey("common.category.official");
      case QuestionCategory.Original:
        return MSG.getMessageByKey("common.category.custom");
      default:
        return "Invalid type with QuestionCategory";
    }
  }
}

/**
 * Question Type
 */
enum QuestionType {
  /**
   * Multiple choice question
   */
  MCQ = 1,
  /**
   * Fill in the blank
   */
  FIB,
  FreeText,
}

namespace QuestionType {
  export function toString(type: QuestionType) {
    switch (type) {
      case QuestionType.MCQ:
        return MSG.getMessageByKey("questionType.mcq");
      case QuestionType.FIB:
        return MSG.getMessageByKey("questionType.fib");
      case QuestionType.FreeText:
        return MSG.getMessageByKey("questionType.free");
      default:
        return "Invalid type with QuestionType";
    }
  }
}

/*
 * Status
 */
enum UserStatus {
  Normal = 0,
  Pending = 5,
  Removed = 10,
  Expired = 20,
}

namespace UserStatus {
  export function toString(type: UserStatus) {
    switch (type) {
      case UserStatus.Pending:
        return MSG.getMessageByKey("userStatus.pendingInvite");
      case UserStatus.Expired:
        return MSG.getMessageByKey("userStatus.expiredInvite");
      default:
        return "";
    }
  }
}

enum SubmissionListKind {
  all = 0,
  unread,
  inprogress,
  inreview, // unevaluated submissions that has < K reviews
  expired,
  canceled,
  reviewed, // unevaluated submissions that has >= K reviews
  passed,
  failed,
  archived,

  // Frontend-only groupings
  evaluated, // all submissions that have been evaluated (either as Failed or Passed)
  submitted, // all submissions that have been submitted by applicants but have not yet been evaluated
}

namespace SubmissionListKind {
  export function toApplicantExamStatusesParam(
    submissionListKind: SubmissionListKind,
  ) {
    if (
      [SubmissionListKind.all, SubmissionListKind.archived].includes(
        submissionListKind,
      )
    ) {
      return undefined;
    }
    if (submissionListKind === SubmissionListKind.evaluated) {
      return [ApplicantExamStatus.Passed, ApplicantExamStatus.Failed];
    } else if (submissionListKind === SubmissionListKind.submitted) {
      return [ApplicantExamStatus.Submitted, ApplicantExamStatus.InReview];
    } else {
      return [submissionListKind as number];
    }
  }

  export function toPathname(submissionStatus: number, canReview: boolean) {
    if (
      !canReview &&
      [ApplicantExamStatus.Submitted, ApplicantExamStatus.InReview].includes(
        submissionStatus,
      )
    ) {
      return SubmissionListKind[SubmissionListKind.submitted];
    }

    return SubmissionListKind[submissionStatus];
  }
}

/**
 * Submission types
 */
enum SubmissionType {
  Default = 1,
  Deadline = 2,
  Automatic = 3, // auto submit
  ManualOverride = 4, // manual submit
}

/**
 * Applicant Exam Status
 */
enum ApplicantExamStatus {
  Unread = 1,
  InProgress = 2,
  Submitted = 3, // New Display Name: In Review
  Expired = 4,
  Canceled = 5,
  InReview = 6, // New Display Name: Reviewed
  Passed = 7,
  Failed = 8,
}

namespace ApplicantExamStatus {
  export const SubmissionModalExcludeStatuses = [
    ApplicantExamStatus.Canceled,
    ApplicantExamStatus.Expired,
    ApplicantExamStatus.Unread,
    ApplicantExamStatus.Passed,
    ApplicantExamStatus.Failed,
  ];

  export function toString(
    type: ApplicantExamStatus,
    // when review functionality is disabled, "in review" and "reviewed" are displayed as "submitted"
    canReview = true,
  ) {
    switch (type) {
      case ApplicantExamStatus.Unread:
        return MSG.getMessageByKey("applicantExamStatus.unread");
      case ApplicantExamStatus.InProgress:
        return MSG.getMessageByKey("applicantExamStatus.inProgress");
      case ApplicantExamStatus.Submitted:
        return canReview
          ? MSG.getMessageByKey("applicantExamStatus.inReview")
          : MSG.getMessageByKey("applicantExamStatus.submitted");
      case ApplicantExamStatus.Expired:
        return MSG.getMessageByKey("applicantExamStatus.expired");
      case ApplicantExamStatus.Canceled:
        return MSG.getMessageByKey("applicantExamStatus.canceled");
      case ApplicantExamStatus.InReview:
        return canReview
          ? MSG.getMessageByKey("submission.reviewed")
          : MSG.getMessageByKey("applicantExamStatus.submitted");
      case ApplicantExamStatus.Passed:
        return MSG.getMessageByKey("applicantExamStatus.passed");
      case ApplicantExamStatus.Failed:
        return MSG.getMessageByKey("applicantExamStatus.failed");
      default:
        return "Invalid type with ApplicantExamStatus";
    }
  }

  export function toApplicantString(type: ApplicantExamStatus) {
    switch (type) {
      case ApplicantExamStatus.Unread:
        return MSG.getMessageByKey("applicantExamStatus.unread");
      case ApplicantExamStatus.InProgress:
        return MSG.getMessageByKey("applicantExamStatus.inProgress");
      case ApplicantExamStatus.Submitted:
        return MSG.getMessageByKey("applicantExamStatus.submitted");
      case ApplicantExamStatus.Expired:
        return MSG.getMessageByKey("applicantExamStatus.expired");
      case ApplicantExamStatus.Canceled:
        return MSG.getMessageByKey("applicantExamStatus.canceled");
      case ApplicantExamStatus.InReview:
        return MSG.getMessageByKey("applicantExamStatus.inReview");
      case ApplicantExamStatus.Passed:
        return MSG.getMessageByKey("applicantExamStatus.passed");
      case ApplicantExamStatus.Failed:
        return MSG.getMessageByKey("applicantExamStatus.failed");
      default:
        return "Invalid type with ApplicantExamStatus";
    }
  }
}

/**
 * Challenge status
 */
enum ChallengeStatus {
  Draft = 1,
  Ready,
  Removed = 9,
}

namespace ChallengeStatus {
  export function toString(type: ChallengeStatus) {
    switch (type) {
      case ChallengeStatus.Draft:
        return "Draft";
      case ChallengeStatus.Ready:
        return "Ready";
      case ChallengeStatus.Removed:
        return "Removed";
      default:
        return "Invalid Status";
    }
  }
}

enum MemberEditType {
  New,
  Edit,
}

/**
 * ChallengeResultStatus
 */
enum ChallengeResultStatus {
  Prepared,
  Started,
  InProgress,
  Finished,
  NotModified,
  CanRestart,
  ScoringWaiting,
}

namespace ChallengeResultStatus {
  export function toString(type: ChallengeResultStatus | undefined) {
    switch (type) {
      case ChallengeResultStatus.Prepared:
        return MSG.getMessageByKey("challengeResultStatus.prepared");
      case ChallengeResultStatus.Started:
        return MSG.getMessageByKey("challengeResultStatus.started");
      case ChallengeResultStatus.InProgress:
        return MSG.getMessageByKey("challengeResultStatus.inProgress");
      case ChallengeResultStatus.Finished:
        return MSG.getMessageByKey("challengeResultStatus.finished");
      case ChallengeResultStatus.NotModified:
        return MSG.getMessageByKey("challengeResultStatus.notModified");
      case ChallengeResultStatus.ScoringWaiting:
        return MSG.getMessageByKey("challengeResultStatus.scoringWaiting");
      case ChallengeResultStatus.CanRestart:
        return MSG.getMessageByKey("challengeResultStatus.canRestart");
      default:
        return MSG.getMessageByKey("challengeResultStatus.beforeStarting");
    }
  }
}

namespace ChallengeResultStatus {
  export function toArrangeString(type: ChallengeResultStatus) {
    switch (type) {
      case ChallengeResultStatus.Started:
      case ChallengeResultStatus.InProgress:
        return MSG.getMessageByKey("challengeResultStatus.inProgress");
      case ChallengeResultStatus.Finished:
      case ChallengeResultStatus.NotModified:
      case ChallengeResultStatus.ScoringWaiting:
        return MSG.getMessageByKey("challengeResultStatus.finished");
      default:
        return MSG.getMessageByKey("challengeResultStatus.beforeStarting");
    }
  }
}

/**
 * ScoreStatus
 */
enum ScoreStatus {
  NotScored = 1,
  Scoring,
  Scored,
  HasDifference,
  Rescored,
}

namespace ScoreStatus {
  export function toString(type: ScoreStatus) {
    switch (type) {
      case ScoreStatus.NotScored:
        return MSG.getMessageByKey("scoreStatus.notScored");
      case ScoreStatus.Scoring:
        return MSG.getMessageByKey("scoreStatus.scoring");
      case ScoreStatus.Scored:
        return MSG.getMessageByKey("scoreStatus.scored");
      case ScoreStatus.HasDifference:
        return MSG.getMessageByKey("scoreStatus.hasDifference");
      case ScoreStatus.Rescored:
        return MSG.getMessageByKey("scoreStatus.rescored");
      default:
        return "Invalid type with ScoreStatus";
    }
  }
}

/**
 * UnstableReason
 */
enum UnstableReason {
  NoPrincipalResult = "NoPrincipalResult",
  OnlyOneResult = "OnlyOneResult",
  AllTimeout = "AllTimeout",
  SomeTimeout = "SomeTimeout",
  AllResourceLimitExceed = "AllResourceLimitExceed",
  SomeResourceLimitExceed = "SomeResourceLimitExceed",
  OutOfMemoryOnBuild = "OutOfMemoryOnBuild",
  InconsistentTestcases = "InconsistentTestcases",
  UnstableScore = "UnstableScore",
  HasError = "HasError",
  Unknown = "Unknown",
}

const unstableReasonMessageKeys = {
  [UnstableReason.NoPrincipalResult]: "score.noPrincipalResult",
  [UnstableReason.OnlyOneResult]: "score.onlyOneResult",
  [UnstableReason.AllTimeout]: "score.allTimeout",
  [UnstableReason.SomeTimeout]: "score.someTimeout",
  [UnstableReason.AllResourceLimitExceed]: "score.allResourceLimitExceed",
  [UnstableReason.SomeResourceLimitExceed]: "score.someResourceLimitExceed",
  [UnstableReason.OutOfMemoryOnBuild]: "score.outOfMemoryOnBuild",
  [UnstableReason.InconsistentTestcases]: "score.inconsistentTestcases",
  [UnstableReason.UnstableScore]: "score.unstableScore",
  [UnstableReason.HasError]: "score.hasError",
  [UnstableReason.Unknown]: "score.unknown",
};

namespace UnstableReason {
  export function toErrorString(type: UnstableReason) {
    const messageKey = unstableReasonMessageKeys[type];

    return messageKey
      ? MSG.getMessageByKey(messageKey)
      : "Invalid type for UnstableReason";
  }
}

/**
 * Review Score
 */
enum ReviewScore {
  StronglyReject = 0,
  Reject = 1,
  Approve = 5,
  StronglyApprove = 6,
}

namespace ReviewScore {
  export function toString(type?: ReviewScore) {
    switch (type) {
      case ReviewScore.StronglyReject:
        return MSG.getMessageByKey("reviewScore.stronglyReject");
      case ReviewScore.Reject:
        return MSG.getMessageByKey("reviewScore.reject");
      case ReviewScore.Approve:
        return MSG.getMessageByKey("reviewScore.approve");
      case ReviewScore.StronglyApprove:
        return MSG.getMessageByKey("reviewScore.stronglyApprove");
      default:
        return MSG.getMessageByKey("reviewScore.notScored");
    }
  }
}

/**
 * Difficulty
 */
enum Difficulty {
  Easy = 1,
  Medium,
  Hard,
}

namespace Difficulty {
  export function toString(type: Difficulty) {
    switch (type) {
      case Difficulty.Easy:
        return MSG.getMessageByKey("difficulty.easy");
      case Difficulty.Medium:
        return MSG.getMessageByKey("difficulty.medium");
      case Difficulty.Hard:
        return MSG.getMessageByKey("difficulty.hard");
      default:
        return "Invalid Difficulty type";
    }
  }
}

/**
 * ChallengeStyle
 */
enum ChallengeStyle {
  Quiz = 1,
  Development,
  Algorithm,
  AI,
  Function = 7,
}

namespace ChallengeStyle {
  export function toString(type: ChallengeStyle) {
    switch (type) {
      case ChallengeStyle.Quiz:
        return MSG.getMessageByKey("enums.ChallengeStyle.Quiz");
      case ChallengeStyle.Development:
        return MSG.getMessageByKey("enums.ChallengeStyle.Development");
      case ChallengeStyle.Algorithm:
        return MSG.getMessageByKey("enums.ChallengeStyle.Algorithm");
      case ChallengeStyle.AI:
        return MSG.getMessageByKey("enums.ChallengeStyle.AI");
      case ChallengeStyle.Function:
        return MSG.getMessageByKey("enums.ChallengeStyle.Function");
      default:
        return "Invalid ChallengeStyle type";
    }
  }

  export function toIconType(type: ChallengeStyle) {
    switch (type) {
      case ChallengeStyle.Quiz:
        return "check-square";
      case ChallengeStyle.Development:
        return "code";
      case ChallengeStyle.Algorithm:
        return "code";
      case ChallengeStyle.AI:
        return "puzzle-piece";
      case ChallengeStyle.Function:
        return "code";
      default:
        return "Invalid ChallengeStyle type";
    }
  }

  /**
   * Check if the challenge type is coding type
   * @param type
   * @returns
   */
  export function isCodingTypeChallenge(type: ChallengeStyle | undefined) {
    if (type === undefined) {
      return false;
    }
    return [
      ChallengeStyle.Development,
      ChallengeStyle.Algorithm,
      ChallengeStyle.Function,
    ].includes(type);
  }

  /**
   * Check if the challenge is algorithm type
   * @param style
   * @returns
   */
  export function isAlgorithmTypeChallenge(
    style: ChallengeStyle | undefined,
  ): boolean {
    if (style === undefined) {
      return false;
    }
    return [ChallengeStyle.Algorithm, ChallengeStyle.Function].includes(style);
  }

  /**
   * Check if the challenge has appeal comment
   * @param type
   * @returns
   */
  export function hasAppealComment(type: ChallengeStyle) {
    return (
      type === ChallengeStyle.Development ||
      type === ChallengeStyle.Algorithm ||
      type === ChallengeStyle.Function ||
      type === ChallengeStyle.AI
    );
  }

  /**
   * Check if the challenge has README
   * @param type
   * @returns
   */
  export function hasReadme(type: ChallengeStyle) {
    return (
      type === ChallengeStyle.Development ||
      type === ChallengeStyle.Algorithm ||
      type === ChallengeStyle.Function ||
      type === ChallengeStyle.AI
    );
  }

  /**
   * Check if the challenge has multiple programming language
   * @param type
   * @returns
   */
  export function hasProgrammingLanguage(type: ChallengeStyle) {
    return (
      type === ChallengeStyle.Algorithm || type === ChallengeStyle.Function
    );
  }

  /**
   * Check if the challenge has solution explanation
   * @param type
   * @returns
   */
  export function hasSolutionExplanation(type: ChallengeStyle) {
    return (
      type === ChallengeStyle.Development ||
      type === ChallengeStyle.Algorithm ||
      type === ChallengeStyle.Function
    );
  }

  /**
   * Check if the challenge can do the local exam
   * @param type
   * @returns
   */
  export function hasLocalExam(type: ChallengeStyle) {
    return (
      type === ChallengeStyle.Development ||
      type === ChallengeStyle.Algorithm ||
      type === ChallengeStyle.Function
    );
  }

  /**
   * Check if the challenge has score
   * @param type
   * @returns
   */
  export function hasApplicantScore(type: ChallengeStyle) {
    return (
      type === ChallengeStyle.Development ||
      type === ChallengeStyle.Algorithm ||
      type === ChallengeStyle.Function
    );
  }

  export function canPlayback(type: ChallengeStyle) {
    return (
      type === ChallengeStyle.Development ||
      type === ChallengeStyle.Algorithm ||
      type === ChallengeStyle.Function
    );
  }

  export function canWebcam(type: ChallengeStyle) {
    return (
      type === ChallengeStyle.Development ||
      type === ChallengeStyle.Algorithm ||
      type === ChallengeStyle.Function ||
      type === ChallengeStyle.Quiz
    );
  }
}

/**
 * ChallengeType
 */
type ChallengeType = "coding" | "cli" | "function" | "quiz" | "ai";

/**
 * ProgrammingLanguage
 */
enum ProgrammingLanguage {
  C = 1,
  CPlusPlus,
  CSharp,
  Java,
  NodeJS,
  HTML,
  CSS,
  BrowserJS,
  PHP,
  /**
   * @deprecated Python2 has been end-of-life since the beginning of 2020
   */
  Python,
  Python3,
  Scala,
  Perl,
  Ruby,
  SQL,
  Groovy,
  Go,
  Rust,
  Haskell,
  Swift,
  Kotlin,
  TypeScript,
  Dart,
}

namespace ProgrammingLanguage {
  export function toString(type: ProgrammingLanguage) {
    switch (type) {
      case ProgrammingLanguage.CPlusPlus:
        return "C++";
      case ProgrammingLanguage.CSharp:
        return "C#";
      case ProgrammingLanguage.BrowserJS:
        return "JavaScript";
      case ProgrammingLanguage.Python:
        return "Python2";
      default:
        return ProgrammingLanguage[type];
    }
  }

  export function fromExt(ext: string): ProgrammingLanguage {
    switch (ext) {
      case "c":
        return ProgrammingLanguage.C;
      case "cpp":
        return ProgrammingLanguage.CPlusPlus;
      case "cs":
        return ProgrammingLanguage.CSharp;
      case "java":
        return ProgrammingLanguage.Java;
      case "js":
        return ProgrammingLanguage.NodeJS;
      case "ts":
        return ProgrammingLanguage.TypeScript;
      case "html":
        return ProgrammingLanguage.HTML;
      case "css":
        return ProgrammingLanguage.CSS;
      case "php":
        return ProgrammingLanguage.PHP;
      case "py":
        return ProgrammingLanguage.Python3;
      case "scala":
        return ProgrammingLanguage.Scala;
      case "pl":
      case "pm":
        return ProgrammingLanguage.Perl;
      case "rb":
        return ProgrammingLanguage.Ruby;
      case "sql":
        return ProgrammingLanguage.SQL;
      case "groovy":
        return ProgrammingLanguage.Groovy;
      case "go":
        return ProgrammingLanguage.Go;
      case "rs":
        return ProgrammingLanguage.Rust;
      case "hs":
        return ProgrammingLanguage.Haskell;
      case "swift":
        return ProgrammingLanguage.Swift;
      case "kt":
        return ProgrammingLanguage.Kotlin;
      case "dart":
        return ProgrammingLanguage.Dart;
      default:
        return 0 as ProgrammingLanguage;
    }
  }

  /**
   * get language for react-syntax-highlighter
   *
   * See
   * https://github.com/react-syntax-highlighter/react-syntax-highlighter/blob/master/AVAILABLE_LANGUAGES_HLJS.MD
   * @param filePath
   */
  export function getLanguage(filePath: string) {
    const index = filePath.lastIndexOf(".");
    if (index === -1) {
      return undefined;
    }
    const ext = filePath.substring(index + 1);
    if (ext.trim() === "") {
      return undefined;
    }
    switch (ext) {
      case "js":
      case "jsx":
        return "javascript";
      case "rb":
        return "ruby";
      case "py":
        return "python";
      case "cs":
        return "csharp";
      case "mod":
        return "go";
      case "c":
      case "h":
        return "cpp";
      case "md":
        return "markdown";
      case "pl":
      case "pm":
        return "perl";
      case "hs":
        return "haskell";
      case "yml":
        return "yaml";
      case "kt":
        return "kotlin";
      case "rs":
        return "rust";
      case "ts":
      case "tsx":
        return "typescript";
      default:
        return ext;
    }
  }
}

/**
 * StartChallengeStep
 */
enum StartChallengeStep {
  Introduction,
  ChooseLanguage,
  Ready,
}

namespace StartChallengeStep {
  export function toString(type: StartChallengeStep) {
    switch (type) {
      case StartChallengeStep.Introduction:
        return MSG.getMessageByKey("startChallengeStep.introduction");
      case StartChallengeStep.ChooseLanguage:
        return MSG.getMessageByKey("startChallengeStep.chooseLanguage");
      case StartChallengeStep.Ready:
        return MSG.getMessageByKey("startChallengeStep.ready");
      default:
        return "Invalid StartChallengeStep type";
    }
  }
}

/**
 * DeliveryMethod
 */
enum DeliveryMethod {
  Mail = 1,
  URL,
}

namespace DeliveryMethod {
  export function toString(type: DeliveryMethod) {
    switch (type) {
      case DeliveryMethod.Mail:
        return "メール配信";
      case DeliveryMethod.URL:
        return "URL配信";
      default:
        return "Invalid DeliveryMethod type";
    }
  }
}

enum DeliveryStatus {
  Unscheduled = 0,
  Scheduled,
  Dispatched,
  Cancelled,
}

enum Encoding {
  MS932 = "MS932",
  UTF_8 = "UTF-8",
}

namespace Encoding {
  export function toString(type: Encoding) {
    switch (type) {
      case Encoding.MS932:
        return "Shift_JIS";
      case Encoding.UTF_8:
        return "UTF-8";
      default:
        return "Invalid Encoding Type";
    }
  }
}

enum ExecStatus {
  ConnectionError = 1,
  RunError,
  InconsistentTestcases,
  BuildError,
  ResourceLimitExceed,
  Timeout,
  Success,
  OutOfMemoryOnBuild,
}

namespace ExecStatus {
  export function toString(type: ExecStatus) {
    switch (type) {
      case ExecStatus.ConnectionError:
        return MSG.getMessageByKey("execStatus.connectionError");
      case ExecStatus.RunError:
        return MSG.getMessageByKey("execStatus.runError");
      case ExecStatus.InconsistentTestcases:
        return MSG.getMessageByKey("execStatus.inconsistentTestcases");
      case ExecStatus.BuildError:
        return MSG.getMessageByKey("execStatus.buildError");
      case ExecStatus.ResourceLimitExceed:
        return MSG.getMessageByKey("execStatus.resourceLimitExceed");
      case ExecStatus.Timeout:
        return MSG.getMessageByKey("execStatus.timeout");
      case ExecStatus.Success:
        return MSG.getMessageByKey("execStatus.success");
      case ExecStatus.OutOfMemoryOnBuild:
        return MSG.getMessageByKey("execStatus.outOfMemoryOnBuild");
      default:
        return "Invalid Encoding Type";
    }
  }
}

enum SaveTrigger {
  UserTest = 1,
  Commit,
  Submit,
  SwitchLanguage,
  ManualRun,
}

enum MailStatus {
  Initial = 0,
  Delivered,
  Bounced,
  Dropped,
  Opened,
  Clicked,
  Processed,
  Deferred,
  Spam,
  Unsubscribe,
  GroupUnsubscribe,
  GroupResubscribe,
}

namespace MailStatus {
  export function getErrorMessage(type: MailStatus) {
    switch (type) {
      case MailStatus.Bounced:
        return MSG.getMessageByKey("mailStatusError.bounced");
      case MailStatus.Dropped:
        return MSG.getMessageByKey("mailStatusError.dropped");
      case MailStatus.Deferred:
        return MSG.getMessageByKey("mailStatusError.deferred");
      case MailStatus.Spam:
        return MSG.getMessageByKey("mailStatusError.spam");
      default:
        return "";
    }
  }
}

enum ChallengeTakenBy {
  NotSelected = 1,
  WebBrowser,
  LocalMachine,
}

enum ExamDeliveryKind {
  Standard = 1,
  ID,
}

enum AtsKind {
  Sonar = 1001,
  IWeb = 1002,
  Athletics = 1003,
  Training = 1005,
  Axol = 1006,
  E2R = 1007,
}

namespace AtsKind {
  export const values = Object.keys(AtsKind).filter(Number);
}

enum SpokenLanguages {
  English = "en",
  Japanese = "ja",
}

namespace SpokenLanguages {
  export function toString(type: string) {
    switch (type) {
      case SpokenLanguages.English:
        return MSG.getMessageByKey("common.language.english");
      case SpokenLanguages.Japanese:
        return MSG.getMessageByKey("common.language.japanese");
      default:
        return "";
    }
  }
}

enum CustomFormDefinitionType {
  Text = 1,
  Textarea,
  Number,
  Select,
  Radio,
  Checkbox,
  Date,
  DateRange,
}

enum ChartColor {
  White = "#ffffff",
  Primary = "#9bca54",
  Green = "#31ab68",
  Purple = "#dc6b88",
  Gray = "#acada9",
  LightGray = "#d9d9d9",
  Black = "black",
  NeutralGray300 = "#B4C4CC",
  Primary600 = "#658a2c",
  Primary200 = "#EBF5DC",
}

/**
 * Dispatch Group
 */
const DispatchGroup = stringToEnum(["com", "edu", "bootcamp"]);
type DispatchGroup = keyof typeof DispatchGroup;
const DispatchGroupType = stringToEnum([
  "sharingGroupIds",
  "trainingSharingGroups",
  "contestSharingGroups",
]);
type DispatchGroupType = keyof typeof DispatchGroupType;

enum IntegrationType {
  Slack = 1,
  // teams = 2,
}

enum IntegrationSocialEvent {
  NewUser = 1,
  SubmittedExam = 2,
  DailySummary = 3,
}

namespace IntegrationSocialEvent {
  export function toString(type: IntegrationSocialEvent) {
    switch (type) {
      case IntegrationSocialEvent.NewUser:
        return MSG.getMessageByKey("slack.userInvitationAcceptanceMessages");
      case IntegrationSocialEvent.SubmittedExam:
        return MSG.getMessageByKey("slack.submittedExam");
      case IntegrationSocialEvent.DailySummary:
        return MSG.getMessageByKey("slack.dailySubmittedExamsSummary");
      default:
        return "";
    }
  }
}

enum Tier {
  Custom = 1,
  Standard = 2,
  Business = 3,
  Enterprise = 4,
  Legacy = 5,
}

namespace Tier {
  export function toString(tier: Tier) {
    switch (tier) {
      case Tier.Custom:
        return "Custom";
      case Tier.Standard:
        return "Standard";
      case Tier.Business:
        return "Business";
      case Tier.Enterprise:
        return "Enterprise";
      case Tier.Legacy:
        return "Legacy";
      default:
        return "";
    }
  }
}

enum TierAction {
  CoreFunctionality = 0,

  // Challenges
  ChallengeDifficultySetting = 1,
  DevelopmentChallengeUsage = 2,
  AIChallengeUsage = 3,
  CliSupportEnabling = 4,

  // Review
  ExamineeReporting = 5,
  ReviewFunctionality = 6,
  HistoryPreviewing = 7,

  // Exam
  OptionalChallengeUsage = 8,
  RandomChallengeUsage = 9,
  MultilanguageExamUsage = 10,

  // Delivery
  UrlDelivering = 11,
  IdDelivering = 12,
  ScheduledDelivering = 13,

  // Manage
  ProjectCreation = 14,
  AllRoleCreation = 15,

  // Tool
  ExternalATSUsage = 16,

  // Custom Challenge
  CustomQuizUsage = 17,
  CustomCodingUsage = 18,

  // CSV
  BasicCSVDownload = 19,
  EnhancedCSVDownload = 20,
  ReviewCSVDownload = 27,
  HistoryCSVDownload = 30,

  // Security
  IpWhitelistEnabling = 21,
  MfaEnabling = 22,
  ProjectAuditLogs = 28,

  // Review
  GuestSharingEnabling = 23,

  // Exam
  OfficialExamUsage = 24,
  ChallengeCollectionUsage = 25,

  RandomQuiz = 26,

  // Applicant Action Tracking
  ApplicantActionLogs = 29,

  // Auto-filter Submissions
  AutoFilterExams = 31,

  // Key Event Tracking
  CodePlayback = 32,

  CodeDiff = 33,

  // Webcam Applicant Monitoring
  WebcamMonitoring = 34,
}

export const TierActionsForSystemAdmin = [
  TierAction.HistoryPreviewing,
  TierAction.BasicCSVDownload,
  TierAction.ReviewCSVDownload,
  TierAction.HistoryCSVDownload,
  TierAction.EnhancedCSVDownload,
  TierAction.ProjectCreation,
  TierAction.CodePlayback,
  TierAction.WebcamMonitoring,
  TierAction.ApplicantActionLogs,
  TierAction.IpWhitelistEnabling,
  TierAction.ProjectAuditLogs,
];

export const TierActionsForNonAssessmentProject = [
  TierAction.CoreFunctionality,
  TierAction.ChallengeDifficultySetting,
  TierAction.DevelopmentChallengeUsage,
  TierAction.AIChallengeUsage,
  TierAction.CliSupportEnabling,
  TierAction.ExamineeReporting,
  TierAction.ReviewFunctionality,
  TierAction.HistoryPreviewing,
  TierAction.OptionalChallengeUsage,
  TierAction.RandomChallengeUsage,
  TierAction.MultilanguageExamUsage,
  TierAction.UrlDelivering,
  TierAction.IdDelivering,
  TierAction.ScheduledDelivering,
  TierAction.ProjectCreation,
  TierAction.AllRoleCreation,
  TierAction.ExternalATSUsage,
  TierAction.CustomQuizUsage,
  TierAction.CustomCodingUsage,
  TierAction.BasicCSVDownload,
  TierAction.GuestSharingEnabling,
  TierAction.OfficialExamUsage,
  TierAction.ChallengeCollectionUsage,
];

// tier actions that non assesment projects should follow on org billing plan setting
export const NonAssessmentProjectBillingPlanActions = new Map<
  TierAction,
  boolean
>([
  [TierAction.ProjectAuditLogs, true],
  [TierAction.EnhancedCSVDownload, true],
  [TierAction.AutoFilterExams, true],
  [TierAction.RandomQuiz, true],
  [TierAction.ApplicantActionLogs, true],
  [TierAction.CodeDiff, true],
  [TierAction.CodePlayback, true],
  [TierAction.WebcamMonitoring, true],
]);

/**
 * Project Kind
 */
enum ProjectKind {
  Assessment = 1,
  Training = 2,
  Contest = 3,
  Digipass = 4,
}

namespace ProjectKind {
  export function toString(type: ProjectKind | undefined) {
    switch (type) {
      case ProjectKind.Assessment:
        return MSG.getMessageByKey("projectKind.assessment");
      case ProjectKind.Training:
        return MSG.getMessageByKey("projectKind.training");
      case ProjectKind.Contest:
        return MSG.getMessageByKey("projectKind.contest");
      case ProjectKind.Digipass:
        return MSG.getMessageByKey("projectKind.digipass");
      default:
        return "";
    }
  }

  export function toServiceName(type: ProjectKind | undefined) {
    switch (type) {
      case ProjectKind.Assessment:
        return MSG.getMessageByKey("projectKind.service.assessment");
      case ProjectKind.Training:
        return MSG.getMessageByKey("projectKind.service.training");
      case ProjectKind.Contest:
        return MSG.getMessageByKey("projectKind.service.contest");
      case ProjectKind.Digipass:
        return MSG.getMessageByKey("projectKind.service.digipass");
      default:
        return "";
    }
  }
}

enum CsvTypes {
  Basic = 1,
  EnhancedQuizCSV,
  EnhancedCodingCSV,
  ReviewCsv,
  ScoreHistoryCsv,
}

namespace CsvTypes {
  const csvTypeIcons = {
    [CsvTypes.Basic]: "clipboard-list",
    [CsvTypes.EnhancedQuizCSV]: "question",
    [CsvTypes.EnhancedCodingCSV]: "code",
    [CsvTypes.ReviewCsv]: "smile",
    [CsvTypes.ScoreHistoryCsv]: "history",
  };

  export function toApiType(type?: CsvTypes) {
    switch (type) {
      case CsvTypes.EnhancedQuizCSV:
        return "quiz";
      case CsvTypes.EnhancedCodingCSV:
        return "coding";
      case CsvTypes.ReviewCsv:
        return "reviews";
      case CsvTypes.ScoreHistoryCsv:
        return "coding/history";
      case CsvTypes.Basic:
      default:
        return "";
    }
  }
  export function findIcon(type: CsvTypes) {
    return csvTypeIcons[type];
  }
}

// All webcam action types
export enum WebcamActionType {
  FaceVerificationResult = "face-verification-result", // not used yet
  WebcamMultiplePeopleDetected = "multiple-people",
  WebcamImpersonationDetected = "impersonation",
  ExternalDeviceUsageDetected = "external-device-usage",
  WebcamAppeal = "webcam-appeal", // not used yet
  CandidateAbsent = "candidate-absent",
}
/**
 * refer to Applicant Action Type from API
 */
enum ApplicantActionType {
  StartExam = "start-exam",
  StartChallenge = "start-challenge",
  SaveChallenge = "save-challenge",
  SubmitChallenge = "submit-challenge",
  SubmitExam = "submit-exam",
  UpdateAppealComment = "update-appeal-comment",
  LoadChallenge = "load-challenge",
  CopyPaste = "copy-paste",
  TabUnfocus = "tab-unfocus",
  Screenshot = "screenshot",
  Webcam = "webcam-suspicious-activity",
}

// All actions are what is returned from the backend api
export type AllActionType = ApplicantActionType;

export const getWebcamDisplayName = (type: WebcamActionType) => {
  switch (type) {
    case WebcamActionType.FaceVerificationResult:
      return MSG.getMessageByKey("exam.applicantActionLogs.showWebcamEvent");
    case WebcamActionType.WebcamMultiplePeopleDetected:
      return MSG.getMessageByKey(
        "submission.actionLog.webcamMultiplePeopleDetected.label",
      );
    case WebcamActionType.WebcamImpersonationDetected:
      return MSG.getMessageByKey(
        "submission.actionLog.webcamImpersonationDetected.label",
      );
    case WebcamActionType.ExternalDeviceUsageDetected:
      return MSG.getMessageByKey(
        "submission.actionLog.externalDeviceUsage.label",
      );
    case WebcamActionType.CandidateAbsent:
      return MSG.getMessageByKey("submission.actionLog.candidateAbsent.label");
    default:
      return type;
  }
};

/**
 * These are to be used as fallback in case displayString is not provided from API
 */
export const getDisplayName = (type: AllActionType | undefined) => {
  switch (type) {
    case ApplicantActionType.StartExam:
      return MSG.getMessageByKey("applicantActionType.startExam");
    case ApplicantActionType.StartChallenge:
      return MSG.getMessageByKey("applicantActionType.startChallenge");
    case ApplicantActionType.SaveChallenge:
      return MSG.getMessageByKey("applicantActionType.saveChallenge");
    case ApplicantActionType.SubmitChallenge:
      return MSG.getMessageByKey("applicantActionType.submitChallenge");
    case ApplicantActionType.SubmitExam:
      return MSG.getMessageByKey("applicantActionType.submitExam");
    case ApplicantActionType.UpdateAppealComment:
      return MSG.getMessageByKey("applicantActionType.updateAppealComment");
    case ApplicantActionType.LoadChallenge:
      return MSG.getMessageByKey("applicantActionType.loadChallenge");
    case ApplicantActionType.CopyPaste:
      return MSG.getMessageByKey("applicantActionType.copyPaste");
    case ApplicantActionType.TabUnfocus:
      return MSG.getMessageByKey("applicantActionType.tabUnfocus");
    case ApplicantActionType.Screenshot:
      return MSG.getMessageByKey("applicantActionType.screenshot");

    default:
      return "";
  }
};

enum ApplicantActionSubmitCause {
  ManualSubmission = "manual-submission",
  BatchExpireChallengeResult = "batch-expire-challenge-result",
  BatchExpireApplicantExam = "batch-expire-applicant-exam",
  BatchAutoSubmit = "batch-auto-submit",
}

enum AutoFilterType {
  AutoPassFail = "auto-pass-fail",
  AutoPassFailReview = "auto-pass-fail-review",
}

enum EvaluationType {
  AutoFilter = "auto-filter",
  Human = "human",
}

enum IncludeInvitedQueryString {
  All = "all",
  NonExpired = "nonExpired",
  None = "none",
}

enum InvitationStatus {
  Pending = "pending",
  Expired = "expired",
}

enum KeyEventStatus {
  // the challenge is still in progress
  Active = 0,
  // the challenge is done but the processing isn't complete yet
  Unprocessed = 1,
  // the processing is complete and code playback is available
  Processed = 2,
  // there was an error in processing
  Error = 9,
}

namespace KeyEventStatus {
  export function toString(type: KeyEventStatus | undefined) {
    switch (type) {
      case KeyEventStatus.Active:
        return MSG.getMessageByKey("keyEventStatus.active");
      case KeyEventStatus.Unprocessed:
        return MSG.getMessageByKey("keyEventStatus.unprocessed");
      case KeyEventStatus.Processed:
        return MSG.getMessageByKey("keyEventStatus.processed");
      case KeyEventStatus.Error:
        return MSG.getMessageByKey("keyEventStatus.error");
      default:
        return MSG.getMessageByKey("keyEventStatus.not.available");
    }
  }
}

export enum SortDirection {
  None = "none",
  Asc = "asc",
  Desc = "desc",
}

enum FileScanningStatus {
  Malicious = "malicious",
  Clean = "clean",
  ScanningInProgress = "scanning-in-progress",
  ScanningSkipped = "scanning-skipped",
  ScanningFailed = "scanning-failed",
  Unknown = "unknown",
}

export enum ExamTypeEnums {
  official,
  multilanguage,
}

export enum PurposesOfUse {
  NotDefined = "not-defined",
  Other = "other",
}

export enum JobTitles {
  NotDefined = 0,
  Other = 11,
}

export enum ExamDeliverMethod {
  User = "user",
  URL = "url",
  ATS = "ats",
}

export enum LocalStorageConstants {
  Webcam = "webcam",
  WebcamLabel = "webcamLabel",
}

export enum WebcamSettings {
  required = "webcam.required",
  optional = "webcam.optional",
}

enum TalenthubLevels {
  Lvl1 = 1,
  Lvl2,
  Lvl3,
  Lvl4,
  Lvl5,
}

namespace TalenthubLevels {
  export const getTalenthubLevelLabel = (level: string): string => {
    return `exam.talentHub.levels.lv${level}`;
  };
  export const values = Object.keys(TalenthubLevels).filter(Number);
}

export enum TalenthubCategoryKinds {
  Skill = "skill",
  DSSL = "dssl",
  DSSP = "dssp",
  AI = "ai",
}

export enum TalenthubPublicationStatus {
  Draft = "draft",
  Limited = "limited",
  Published = "published",
}

export {
  OrganizationKind,
  OrganizationStatus,
  ApplicationType,
  QuestionType,
  QuestionCategory,
  ChallengeCategory,
  ApplicantExamStatus,
  ChallengeStatus,
  ChallengeResultStatus,
  ScoreStatus,
  UnstableReason,
  SubmissionListKind,
  SubmissionType,
  Difficulty,
  ChallengeStyle,
  ChallengeType,
  ProgrammingLanguage,
  StartChallengeStep,
  DeliveryMethod,
  DeliveryStatus,
  Role,
  ProjectRole,
  UserRole,
  Encoding,
  ExecStatus,
  SaveTrigger,
  MailStatus,
  ChallengeTakenBy,
  ExamDeliveryKind,
  AtsKind,
  ReviewScore,
  SpokenLanguages,
  CustomFormDefinitionType,
  ChartColor,
  DispatchGroup,
  DispatchGroupType,
  IntegrationType,
  IntegrationSocialEvent,
  Tier,
  TierAction,
  MemberEditType,
  ProjectKind,
  CsvTypes,
  ApplicantActionType,
  ApplicantActionSubmitCause,
  UserStatus,
  AutoFilterType,
  EvaluationType,
  IncludeInvitedQueryString,
  InvitationStatus,
  KeyEventStatus,
  FileScanningStatus,
  TalenthubLevels,
};
