import { ProjectModel } from "../models";
import {
  ApplicationType,
  ProjectStatus,
  OrganizationKind,
  AtsKind,
  TierAction,
  Tier,
  DispatchGroup,
} from "../services/enums";

export interface AdminOrganizationNewParamsModel {
  orgFormValue: {
    name: string;
    displayName?: string;
    imageUrl?: string;
    applicationTypes: number[];
    isVerifyEnable: boolean;
    sharingGroupIds: DispatchGroup[];
    trainingSharingGroups: DispatchGroup[];
    contestSharingGroups: DispatchGroup[];
    tier: number | undefined;
    whitelistedActions: number[];
    overrideMaxProjectUsers?: number;
    subCategory?: string;
  };
  integrations: {
    atsKind: AtsKind;
    enabled: boolean;
    notificationEmail?: string;
  }[];
  gitToken?: string;
  ipWhitelist?: string[];
  mfaRequired: boolean;
}
class OrganizationModel {
  public id: number;
  public name: string;
  public displayName: string;
  public plan: number;
  public kind: number;
  public imageUrl: string;
  public applicationTypes: Array<ApplicationType> = [];
  public sharingGroupIds: DispatchGroup[] = [];
  public trainingSharingGroups: DispatchGroup[] = [];
  public contestSharingGroups: DispatchGroup[] = [];
  public status: number;
  public createdBy: number;
  public updatedBy: number;
  public createdAt: string;
  public updatedAt: string;
  public projects: Array<ProjectModel> = [];
  public ipWhitelist: Array<string> = [];
  public mfaRequired: boolean;
  public hasContracts: boolean;
  public tier: Tier;
  public whitelistedActions: TierAction[] = [];
  public overrideMaxProjectUsers?: number;
  public subCategory?: string;
  /**
   * ID that only org with enabling verify org has.
   */
  public verifyOrganizationId?: number;
  /**
   * ID that only verify org has.
   */
  public verifyTargetId?: number;

  constructor(args: Partial<OrganizationModel> = {}) {
    Object.assign(this, args);

    const { projects = [] } = args;

    if (Array.isArray(projects)) {
      this.projects = projects.filter(
        (project) => project.applicationType === ApplicationType.Screening,
      );
    }
  }

  public isEnableApplicationType(applicationType: ApplicationType) {
    const projectApplicationTypes = this.projects
      .filter((project) => project.status !== ProjectStatus.Archived)
      .map((project) => project.applicationType);
    return (
      this.applicationTypes.includes(applicationType) &&
      projectApplicationTypes.includes(applicationType)
    );
  }

  public get DisplayName() {
    return this.displayName || this.name;
  }

  public canCreateVerifyOrg() {
    return (
      this.kind !== OrganizationKind.Verify &&
      this.verifyOrganizationId === undefined &&
      this.verifyTargetId === undefined
    );
  }
}

export const GIVERY_ORGANIZATION_ID = 1;

export default OrganizationModel;
