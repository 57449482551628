import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import { toast } from "react-toastify";

import {
  postChallengePin,
  deleteChallengePin,
  getChallengePinList,
  ChallengePinListResponse,
} from "@api/challenges";

import { useStoreContext } from "@context";

import { ExamModel, PinnedChallengeModel } from "@shared/models";
import { updateExamChallengeSetsPin } from "@shared/services/challengeCollection";
import Message from "@shared/services/message";

import { examKeys } from "../exams";

interface ChallengePinKeyParams {
  pinId: number;
  projectId: number;
}

interface DeleteChallengePinParams {
  pinId: number;
  challengeId: number;
}

export const challengePinKeys = {
  all: ["challenge", "pin"] as const,
  list: (projectId: number) => [...challengePinKeys.all, projectId] as const,
  detail: ({ projectId, pinId }: ChallengePinKeyParams) =>
    [...challengePinKeys.list(projectId), pinId] as const,
};

export function useDeleteChallengePin() {
  const { projectId, updateChallengePinState } = useStoreContext();
  const client = useQueryClient();

  const mutation = useMutation({
    mutationFn: ({ pinId }: DeleteChallengePinParams) =>
      deleteChallengePin({ pinId, projectId }),
    onSuccess: (_, { challengeId }) => {
      toast.success(Message.getMessageByKey("message.pin.delete.success"));
      updateChallengePinState({ challengeId } as PinnedChallengeModel, {
        isDelete: true,
      });
      client.setQueriesData(
        challengePinKeys.list(projectId),
        (data: ChallengePinListResponse) => ({
          ...data,
          challengeFavorites: data.challengeFavorites.filter(
            (favorite) => favorite.challengeId !== challengeId,
          ),
        }),
      );
      client.setQueriesData(
        examKeys.detailAll(projectId),
        (data: ExamModel) => {
          if (!data.challengesSets.length) {
            return data;
          }

          return {
            ...data,
            challengesSets: updateExamChallengeSetsPin({
              challengeSets: data.challengesSets,
              targetChallengeId: challengeId,
              newFavoriteId: undefined,
            }),
          };
        },
      );
    },
    onError: () => {
      toast.error(Message.getMessageByKey("common.error"));
    },
  });

  return mutation;
}

export const useCreateChallengePin = () => {
  const { projectId, updateChallengePinState } = useStoreContext();
  const client = useQueryClient();

  const mutation = useMutation({
    mutationFn: (challengeId: number) =>
      postChallengePin({ projectId, options: { data: { challengeId } } }),
    onSuccess: ({ result }) => {
      toast.success(Message.getMessageByKey("message.pin.add.success"));
      client.setQueriesData(
        challengePinKeys.list(projectId),
        (data: ChallengePinListResponse) => {
          if (!data || !data.challengeFavorites.length) {
            return data;
          }

          const isExistingChallenge = data.challengeFavorites.some(
            (favorite) => favorite.challengeId === result.challengeId,
          );

          return {
            ...data,
            challengeFavorites: isExistingChallenge
              ? data.challengeFavorites
              : [...data.challengeFavorites, result],
          };
        },
      );

      client.setQueriesData(
        examKeys.detailAll(projectId),
        (data: ExamModel) => {
          if (!data.challengesSets.length) {
            return data;
          }

          return {
            ...data,
            challengesSets: updateExamChallengeSetsPin({
              challengeSets: data.challengesSets,
              targetChallengeId: result.challengeId,
              newFavoriteId: result.id,
            }),
          };
        },
      );
      updateChallengePinState(result, { isDelete: false });
    },
  });

  return mutation;
};

export const useChallengePinList = (props?: { disabled?: boolean }) => {
  const { disabled = false } = props || {};
  const { projectId, updateChallengePinListState } = useStoreContext();

  const query = useQuery<ChallengePinListResponse>({
    queryKey: challengePinKeys.list(projectId),
    enabled: !disabled && Boolean(projectId),
    staleTime: 0,
    queryFn: async ({ signal }) => {
      const { result } = await getChallengePinList({
        projectId,
        options: {
          signal,
        },
      });

      updateChallengePinListState(result.challengeFavorites);

      return result;
    },
    select: useCallback(
      (data: ChallengePinListResponse) => ({
        ...data,
        challengeFavorites: data?.challengeFavorites ?? [],
      }),
      [],
    ),
    keepPreviousData: true,
  });

  return query;
};
