import { OverlappingTooltip } from "@components/orgs/submissions/submissionDetail/partials";

import { Button } from "@shared/components";
import Message from "@shared/services/message";

import SubmissionResultActionLogCard from "../submissionResultActionLogCard/SubmissionResultActionLogCard";
import SubmissionResultActionLogCardField from "../submissionResultActionLogCardField/SubmissionResultActionLogCardField";

interface Props {
  ip: string;
  agent: string;
  hash: string;
  isAgentDiff?: boolean;
  isDuplicateIp?: boolean;
  isHashDiff?: boolean;
  isIpDiff?: boolean;
  onOpenIpAddressClick?: (ip: string) => void;
}

export default function SubmissionResultActionLogSessionCard({
  agent,
  hash,
  ip,
  isAgentDiff,
  isDuplicateIp,
  isHashDiff,
  isIpDiff,
  onOpenIpAddressClick,
}: Props) {
  const tooltip = Message.getMessageByKey(
    "submission.actionLog.sessionData.changedTooltip",
  );

  return (
    <SubmissionResultActionLogCard titleKey="submission.actionLog.sessionData">
      <SubmissionResultActionLogCardField
        labelKey="submission.actionLog.sessionData.ip"
        isDiff={isIpDiff}
        isDuplicate={isDuplicateIp}
      >
        <SubmissionResultActionLogCardField.Indicators tooltipContent={tooltip}>
          {isDuplicateIp && <OverlappingTooltip narrowed />}
        </SubmissionResultActionLogCardField.Indicators>
        <SubmissionResultActionLogCardField.Content>
          {ip}
        </SubmissionResultActionLogCardField.Content>
        {isDuplicateIp && (
          <Button
            size="small"
            onClick={() => onOpenIpAddressClick?.(ip)}
            className="code-c-submission-result-action-log__card-field__ip-button"
          >
            {Message.getMessageByKey("submission.overlappingIp.help")}
          </Button>
        )}
      </SubmissionResultActionLogCardField>
      <SubmissionResultActionLogCardField
        labelKey="submission.actionLog.sessionData.agent"
        isDiff={isAgentDiff}
      >
        <SubmissionResultActionLogCardField.Indicators
          tooltipContent={tooltip}
        />
        <SubmissionResultActionLogCardField.Content>
          {agent}
        </SubmissionResultActionLogCardField.Content>
      </SubmissionResultActionLogCardField>
      <SubmissionResultActionLogCardField
        labelKey="submission.actionLog.sessionData.hash"
        isDiff={isHashDiff}
      >
        <SubmissionResultActionLogCardField.Indicators
          tooltipContent={tooltip}
        />
        <SubmissionResultActionLogCardField.Content>
          {hash}
        </SubmissionResultActionLogCardField.Content>
      </SubmissionResultActionLogCardField>
    </SubmissionResultActionLogCard>
  );
}
