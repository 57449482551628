import { FileUploadConfig } from "@shared/services/fileUpload";

import { ChallengeType, ProgrammingLanguage } from "../services/enums";
import { KeyEventLogModel } from "./KeyEventLog.model";

class ApplicantChallengeContextModel {
  public challengeId: number;
  public versionId: number;
  public resultId: number;
  public challengeResultId: number;
  public challengeResultHistoryId?: number;
  public title: string;
  public presignedUrl: string;
  public allowSave: boolean;
  public programmingLanguages: ProgrammingLanguage[] = [];
  public selectedLanguage: ProgrammingLanguage;
  public settings: {
    challengeType: ChallengeType;
    files: {};
    hiddenFiles: string[];
    testExcludes: string[];
    images: string[];
    test: string;
    allowNewFile: boolean;
    env: {
      imageName: string;
    };
    versionCommand?: string[];
  };
  public answers: {
    appCommand: string;
    mainFile: string;
    addedFiles: string[];
    fileVersions: {};
  };
  public variables: {};

  public data: ApplicantChallengeContextModel;
  public activeKeyEventLog?: KeyEventLogModel;
  public keyEventToken?: string;
  public fileUploadConfig: FileUploadConfig;

  constructor(args: {}) {
    this.data = args as ApplicantChallengeContextModel;
    Object.assign(this, args);
  }
}

export default ApplicantChallengeContextModel;
