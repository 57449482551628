import * as React from "react";

/**
 * Checks if each item in an array are identical and memoizes it
 */
export const useArrayMemo = <T extends Array<any>>(array: T) => {
  const ref = React.useRef<T>();

  const areArraysConsideredTheSame =
    ref.current && array.length === ref.current.length
      ? array.every((element, i) => {
          return element === ref.current?.[i];
        })
      : //initially there's no old array defined/stored, so set to false
        false;

  React.useEffect(() => {
    //only update prev results if array is not deemed the same
    if (!areArraysConsideredTheSame) {
      ref.current = array;
    }
  }, [areArraysConsideredTheSame, array]);

  return areArraysConsideredTheSame ? ref.current : array;
};
