import { Epic } from "redux-observable";

import {
  Action,
  ajaxAction,
  addToPayload,
  APIResponseAction,
  reportScoresAction,
  reportChallengeAction,
  reportApplicantListAction,
  reportApplicantListFirstAction,
  reportRankingAction,
  reportResultsAction,
  reportCategoriesAction,
  reportEvaluationPointAction,
  reportCategoriesEnumAction,
  questionFiltersAction,
} from "../actions";
import { RootState } from "../reducers";

const reportApplicantListRequestEpic: Epic<Action, RootState> = (
  action$,
  state,
) =>
  action$
    .ofType(
      reportApplicantListFirstAction.types.request,
      reportApplicantListAction.types.request,
    )
    .map((action) =>
      ajaxAction.request({
        method: "get",
        url: `/api/projects/${
          state.getState().project.currentProjectId
        }/submissions`,
        body: {
          limit: 50,
          archived: false,
          ...action.payload,
        },
        success: addToPayload(
          { extra: (action.payload as { examId: number }).examId },
          reportApplicantListAction.success,
        ),
        error: reportApplicantListAction.failure,
        options: { disableDefaultError: true },
        cancel: [
          reportApplicantListFirstAction.types.request,
          reportApplicantListAction.types.failure,
        ],
      }),
    );

const reportApplicantListSuccessEpic: Epic<Action, RootState> = (
  action$,
  state,
) =>
  action$.ofType(reportApplicantListAction.types.success).map((action) => {
    const payload = (action as APIResponseAction).payload;
    const pagination = payload.pagination;
    const examId = payload.extra;

    if (pagination && pagination.count > pagination.offset + pagination.limit) {
      return reportApplicantListAction.request({
        examId,
        offset: pagination.offset + pagination.limit,
      });
    } else {
      return reportApplicantListFirstAction.success();
    }
  });

/**
 * @deprecated please use GET /api/projects/:projectId/exams/:examId/statistics
 */
const reportScoresRequestEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(reportScoresAction.types.request).map((action) =>
    ajaxAction.request({
      method: "get",
      url: `/api/reports/examinees/projects/${
        state.getState().project.currentProjectId
      }/exams/${action.payload}/scoresdistribution`,
      success: reportScoresAction.success,
      error: reportScoresAction.failure,
      options: { disableDefaultError: true },
    }),
  );

const reportChallengeRequestEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(reportChallengeAction.types.request).map((action) =>
    ajaxAction.request({
      method: "get",
      url: `/api/reports/examinees/projects/${
        state.getState().project.currentProjectId
      }/submissions/${action.payload}/challenges/analysis`,
      success: reportChallengeAction.success,
      error: reportChallengeAction.failure,
      options: { disableDefaultError: true },
    }),
  );

/**
 * @deprecated please use GET /api/projects/:projectId/exams/:examId/statistics
 */
const reportRankingRequestEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(reportRankingAction.types.request).map((action) =>
    ajaxAction.request({
      method: "get",
      url: `/api/reports/examinees/projects/${
        state.getState().project.currentProjectId
      }/submissions/${action.payload}`,
      success: reportRankingAction.success,
      error: reportRankingAction.failure,
      options: { disableDefaultError: true },
    }),
  );

const reportResultsRequestEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(reportResultsAction.types.request).map((action) =>
    ajaxAction.request({
      method: "get",
      url: `/api/reports/examinees/projects/${
        state.getState().project.currentProjectId
      }/submissions/${action.payload}/analysis`,
      success: reportResultsAction.success,
      error: reportResultsAction.failure,
      options: { disableDefaultError: true },
    }),
  );

const reportCategoriesRequestEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(reportCategoriesAction.types.request).map((action) =>
    ajaxAction.request({
      method: "get",
      url: `/api/reports/examinees/projects/${
        state.getState().project.currentProjectId
      }/submissions/${action.payload}/categories/scores`,
      success: reportCategoriesAction.success,
      error: reportCategoriesAction.failure,
      options: { disableDefaultError: true },
    }),
  );

const reportEvaluationPointRequestEpic: Epic<Action, RootState> = (
  action$,
  state,
) =>
  action$.ofType(reportEvaluationPointAction.types.request).map((action) =>
    ajaxAction.request({
      method: "get",
      url: `/api/reports/examinees/projects/${
        state.getState().project.currentProjectId
      }/submissions/${action.payload}/evaluationpoints/scores`,
      success: reportEvaluationPointAction.success,
      error: reportEvaluationPointAction.failure,
      options: { disableDefaultError: true },
    }),
  );

const reportCategoriesEnumRequestEpic: Epic<Action, RootState> = (
  action$,
  state,
) =>
  action$.ofType(reportCategoriesEnumAction.types.request).map((action) =>
    ajaxAction.request({
      method: "get",
      url: `/api/enum/questions/tags`,
      success: (payload: {}) =>
        questionFiltersAction.success({ extra: "quizCategories", ...payload }),
    }),
  );

export default [
  reportApplicantListRequestEpic,
  reportApplicantListSuccessEpic,
  reportScoresRequestEpic,
  reportChallengeRequestEpic,
  reportRankingRequestEpic,
  reportResultsRequestEpic,
  reportCategoriesRequestEpic,
  reportEvaluationPointRequestEpic,
  reportCategoriesEnumRequestEpic,
];
