import { Link } from "react-router-dom";

/**
 * Prop interface
 */
export interface RestrictedLinkProps {
  allow?: boolean;
  to: string;
  children?: {};
}

/**
 * React Component
 */
export default function RestrictedLink({
  allow,
  to,
  children,
}: RestrictedLinkProps) {
  return allow ? <Link to={to}>{children}</Link> : <div>{children}</div>;
}
