import { ChallengeTextCaseJSONModel } from ".";

class ApplicantReportChallengeModel {
  public challengeId: number;
  public examId: number;
  public title: string;
  public programmingLanguage: number;
  public score = 0;
  public timeSpentSeconds = 0;
  public evaluationPoints: Array<{
    order: number;
    title: string;
    description: string;
  }> = [];
  /**
   * raw test output data
   * leave it as back compatibility
   * @deprecated
   */
  public testOutput: string;
  /**
   * JSON formatted test output data
   */
  public testOutputJson?: ChallengeTextCaseJSONModel; // Note: this is the correct name, but some apis return testcasesJson instead of testOutputJson
  public testcasesJson?: ChallengeTextCaseJSONModel;

  constructor(args?: {}) {
    Object.assign(this, args);
  }
}

export default ApplicantReportChallengeModel;
