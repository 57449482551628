import * as classnames from "classnames";
import * as React from "react";

import { Button, ButtonProps, Icon } from "@shared/components";

export interface PopupProps {
  children?: React.ReactNode;
  className?: string;
}

const PopupContext = React.createContext<{
  isCollapsed: boolean;
  toggleCollapse?: () => void;
  closePopup?: (callback: () => void) => void;
}>({ isCollapsed: false });

const Popup = function Popup({ children, className, ...props }: PopupProps) {
  const [isInitialStyle, setInitialStyle] = React.useState<boolean>(true);
  const [isCollapsed, setIsCollapsed] = React.useState<boolean>(false);
  const [onPopupClosed, setOnPopupClosed] = React.useState<
    (() => void) | undefined
  >(undefined);

  // This is to trigger the initial CSS transition when the popup is first rendered
  React.useEffect(() => {
    setInitialStyle(false);
  }, []);

  const onTransitionEnd = () => {
    if (onPopupClosed !== undefined) {
      onPopupClosed?.();
    }
  };

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const closePopup = (callback: () => void) => {
    // This sets the callback to be called when the popup finishes its closing transition
    setOnPopupClosed(() => callback);
    setInitialStyle(true);
  };

  const rootStyle = classnames("code-c-popup", {
    [`${className}`]: Boolean(className),
    "initial-style": isInitialStyle,
  });

  return (
    <PopupContext.Provider
      value={{
        toggleCollapse,
        closePopup,
        isCollapsed,
        ...props,
      }}
    >
      <div className={rootStyle} onTransitionEnd={onTransitionEnd}>
        {children}
      </div>
    </PopupContext.Provider>
  );
};

Popup.Header = function Header({ children }: { children: React.ReactNode }) {
  const { toggleCollapse, isCollapsed } = React.useContext(PopupContext);

  return (
    <div className="popup-header" onClick={toggleCollapse}>
      {children}
      <a className="collapse-button" onClick={toggleCollapse}>
        <Icon type={isCollapsed ? "expand-alt" : "compress-alt"} />
      </a>
    </div>
  );
};

Popup.CollapsibleContent = function CollapsibleContent({
  children,
}: {
  children: React.ReactNode;
}) {
  const { isCollapsed } = React.useContext(PopupContext);

  const collapsibleContentStyle = classnames("collapsible-content", {
    "is-collapsed": isCollapsed,
  });
  return <div className={collapsibleContentStyle}>{children}</div>;
};

Popup.Body = function Body({ children }: { children: React.ReactNode }) {
  return <div className="popup-body">{children}</div>;
};

Popup.Footer = function Footer({ children }: { children: React.ReactNode }) {
  return <div className="popup-footer">{children}</div>;
};

Popup.ActionButton = function ActionButton({
  shouldClosePopup = false,
  preCheckAction = () => true,
  onClick,
  ...restProps
}: {
  shouldClosePopup?: boolean;
  preCheckAction?: () => boolean;
  onClick: () => void;
} & ButtonProps) {
  const { closePopup } = React.useContext(PopupContext);

  const handleOnClick = () => {
    if (preCheckAction?.()) {
      if (shouldClosePopup) {
        closePopup?.(onClick);
      } else {
        onClick();
      }
    }
  };

  return <Button onClick={handleOnClick} {...restProps} />;
};

export default Popup;
