import { AxiosRequestConfig } from "axios";

import { SubmissionTimelineResponse } from "@shared/models";

import { get } from "../httpClient";

export interface GetSubmissionTimelineParams {
  projectId: number;
  submissionId: number;
  options?: AxiosRequestConfig;
}

export async function getSubmissionTimeline({
  projectId,
  submissionId,
  options,
}: GetSubmissionTimelineParams) {
  const { data } = await get<SubmissionTimelineResponse>(
    `/api/projects/${projectId}/submissions/${submissionId}/timeline`,
    options,
  );

  return data;
}
