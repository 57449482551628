import * as classnames from "classnames";
import { isEqual } from "lodash";
import * as React from "react";

import {
  Msg,
  Tooltip,
  Button,
  Icon,
  Modal,
  FormGroup,
  Form,
  Label,
  Textarea,
  Accordion,
} from "@shared/components";
import Message from "@shared/services/message";
import { matchString } from "@shared/services/string";

import { formatApplicantCSV } from "../../../examSectionUtil/ExamSectionUtil";

interface ManageDomainModalProps {
  domains?: string[];
  isOpen: boolean;
  onOk: (newDomains: string[]) => void;
  onClose: () => void;
}

const defaultDomains: string[] = [];

export const ManageDomainModal = ({
  domains = defaultDomains,
  isOpen,
  onOk,
  onClose,
}: ManageDomainModalProps) => {
  const rootStyle = classnames("code-exam-domain-modal");

  const [modalDomains, setModalDomains] = React.useState(domains);

  // for searching and filtering
  const [keyword, setKeyword] = React.useState("");
  const [filteredDomains, setFilteredDomains] = React.useState(domains);
  const [clear, setClear] = React.useState(false);

  const [form, setForm] = React.useState<{
    formValid: boolean;
    formValues: { domains: string };
    formErrors?: {};
  }>({ formValid: false, formValues: { domains: "" } });

  React.useEffect(() => {
    if (isOpen) {
      setModalDomains(domains);
      setKeyword("");
      setForm({
        formValid: false,
        formValues: { domains: "" },
      });
    }

    setClear(!isOpen);
  }, [isOpen, domains]);

  React.useEffect(() => {
    const newFilteredDomains = modalDomains.filter((domain) =>
      matchString(domain, keyword),
    );
    setFilteredDomains(newFilteredDomains);
  }, [modalDomains, keyword]);

  const onClickRemoveDomain = (domain: string) => {
    setModalDomains((oldDomains) =>
      oldDomains.filter((oldDomain) => oldDomain !== domain),
    );
  };

  const onChangeKeyword = (event: React.FormEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value;
    if (keyword !== value) {
      setKeyword(value);
    }
  };

  return (
    <Modal
      className={rootStyle}
      title={Message.getMessageByKey("exam.domains.manage")}
      size="small"
      isOpen={isOpen}
      onClose={onClose}
      onClickCancel={onClose}
      onClickOk={() => {
        const formDomains = form.formValues.domains
          ?.trim()
          .split("\n")
          .map((val) => val.trim())
          .filter(Boolean);

        const newDomains = new Set([...modalDomains, ...(formDomains ?? [])]);

        onOk(Array.from(newDomains));
      }}
      disableOk={!form.formValid}
    >
      <Form
        clear={clear}
        showAllErrors
        onFormChange={(
          formValid: boolean,
          formValues: { domains: string },
          formErrors: {},
        ) => {
          const formValueDomains: string[] =
            formatApplicantCSV(formValues?.domains)
              ?.split("\n")
              .map((val) => val?.trim())
              .filter(Boolean) ?? [];

          const allDomains = new Set([...modalDomains, ...formValueDomains]);

          setForm({
            formValid:
              formErrors && Object.keys(formErrors)?.length === 0
                ? !isEqual(allDomains, domains)
                : formValid,
            formValues,
            formErrors,
          });
        }}
        validation={{
          domains: [
            "string",
            [
              "domain",
              {
                excludeDomains: modalDomains,
              },
            ],
          ],
        }}
      >
        <div className="code-exam-domain-modal__domains-detail">
          <Accordion
            defaultExpanded
            summary={
              <div className="code-exam-domain-modal__domains-bar-count">
                <Msg id="exam.domains.showAll" />

                <span>({domains.length})</span>
              </div>
            }
          >
            <div>
              <div className="code-exam-domain-modal__domains-detail__search-bar">
                <div className="code-exam-domain-modal__domains-detail__search-bar__tag">
                  <Msg id="exam.domain" />
                </div>
                <div className="code-exam-domain-modal__domains-detail__search-bar__input-wrapper">
                  <input
                    className="search-bar__input-form"
                    onChange={onChangeKeyword}
                  />
                </div>
              </div>
              <div className="code-exam-domain-modal__domains-detail__list">
                {!filteredDomains.length && (
                  <div className="code-exam-domain-modal__domains-detail__list-no-result">
                    <Msg id="message.noResults" />
                  </div>
                )}
                {filteredDomains?.map((domain) => (
                  <div
                    className="code-exam-domain-modal__domains-detail__list-row"
                    key={domain}
                  >
                    <div className="code-exam-domain-modal__domains-detail__list-row__text">
                      {domain}
                    </div>
                    <Tooltip text={Message.getMessageByKey("delete")}>
                      <Button
                        size="small"
                        shrink={true}
                        onClick={() => onClickRemoveDomain(domain)}
                        ariaLabel="Delete"
                        disabled={false}
                      >
                        <Icon type="trash" />
                      </Button>
                    </Tooltip>
                  </div>
                ))}
              </div>
            </div>
          </Accordion>
        </div>
        <div className="code-exam-domain-modal__domains-input">
          <FormGroup>
            <Label>
              <Msg id="exam.domains.new" />
            </Label>
            <Textarea
              id="domains"
              name="domains"
              placeholder={Message.getMessageByKey(
                "exam.domains.inputPlaceholder",
              )}
            />
          </FormGroup>
        </div>
      </Form>
    </Modal>
  );
};
