import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";

import { useStoreContext } from "@context";

import history from "../../shared/services/history";
import {
  examKeys,
  useExamCounts,
  useInvalidateSubmissionRelation,
} from "./query";
import { useDidUpdateEffect } from "./useDidUpdateEffect";

export function useSubmissionAutoRefetch(examId: number) {
  const client = useQueryClient();
  const { projectId } = useStoreContext();
  const { data: examCounts } = useExamCounts(examId);
  const invalidateSubmissions = useInvalidateSubmissionRelation();
  const [isRouteChanged, setIsRouteChanged] = useState(false);

  // this effect will trigger when user navigate to another page under exam detail
  useDidUpdateEffect(() => {
    setIsRouteChanged(true);
    client.invalidateQueries(examKeys.count(projectId, examId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, examId, projectId, history.location.pathname]);

  // This effect will trigger when user navigate and examCounts is changed
  // therefore, we require to check both conditions are valid before invalidate all submission queries
  useDidUpdateEffect(() => {
    if (examCounts && isRouteChanged) {
      invalidateSubmissions();
      setIsRouteChanged(false);
    }
  }, [examCounts, isRouteChanged]);

  return { examCounts };
}
