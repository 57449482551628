import * as React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import ExamListRoute from "./examList/ExamList.route";

export interface ExamRouteProps {
  projectId: number;
  setCurrentProjectId: (payload: { projectId: number | undefined }) => void;
  error: boolean;
}

export interface ExamRouteState {
  error: boolean;
}

/**
 * Route settings
 */
export class ExamRoute extends React.Component<ExamRouteProps, ExamRouteState> {
  constructor(props: ExamRouteProps) {
    super(props);

    this.state = {
      error: false,
    };
  }

  public componentDidMount() {
    this.props.setCurrentProjectId({ projectId: this.props.projectId });
  }

  public componentDidUpdate(prevProps: ExamRouteProps) {
    const error = !prevProps.error && this.props.error;

    if (error) {
      this.setState({
        error,
      });
      this.props.setCurrentProjectId({ projectId: undefined });
    }
  }

  public render() {
    return this.state.error ? (
      <Redirect to="/404" />
    ) : (
      <Switch>
        <Route path="/p/:projectId/exams" component={ExamListRoute} />
      </Switch>
    );
  }
}
