import { connect } from "react-redux";

import { RootState } from "../../../../reducers";
import OfficialExamList from "./OfficialExamList";

const mapStateToProps = (state: RootState) => ({
  currentProjectId: state.project.currentProjectId,
  examCreateOptions: state.exam.examCreateOptions,
  loadingChallenge: state.challenge.loadingDetail,
  loadingExam: state.exam.loadingExam,
  loadingReadme: state.readme.loadingReadme,
  submitting: Boolean(state.ajax.submitting),
});

export default connect(mapStateToProps, {})(OfficialExamList);
