import { useStoreContext } from "@context";

import { TierAction } from "@shared/services/enums";

export function useTierActionPermissions() {
  const { isTierActionAllowed } = useStoreContext();

  const cliSupportEnabled = isTierActionAllowed(TierAction.CliSupportEnabling);
  const reviewAllowed = isTierActionAllowed(TierAction.ReviewFunctionality);
  const isScheduleDeliveryAllowed = isTierActionAllowed(
    TierAction.ScheduledDelivering,
  );
  const canDownloadCsv = isTierActionAllowed(TierAction.BasicCSVDownload);
  const canDownloadReviewCsv = isTierActionAllowed(
    TierAction.ReviewCSVDownload,
  );
  const canDownloadHistoryCsv = isTierActionAllowed(
    TierAction.HistoryCSVDownload,
  );
  const canViewActionLog = isTierActionAllowed(TierAction.ApplicantActionLogs);
  const canViewCodeDiff = isTierActionAllowed(TierAction.CodeDiff);
  const canCreateOfficialExams = isTierActionAllowed(
    TierAction.OfficialExamUsage,
  );
  const canCreateMultiLangExams = isTierActionAllowed(
    TierAction.MultilanguageExamUsage,
  );
  const allowGuestSharing = isTierActionAllowed(
    TierAction.GuestSharingEnabling,
  );

  return {
    allowGuestSharing,
    canCreateMultiLangExams,
    canCreateOfficialExams,
    canDownloadCsv,
    canDownloadHistoryCsv,
    canDownloadReviewCsv,
    canViewActionLog,
    canViewCodeDiff,
    cliSupportEnabled,
    isScheduleDeliveryAllowed,
    reviewAllowed,
  };
}
