import * as classnames from "classnames";

import { Msg, Form, FormGroup, Radio } from "@shared/components";
import { ActionType, MultiManageForm } from "@shared/models";

/**
 * Interface
 */
export type MultipleManageModalActionProps = {
  formValues: MultiManageForm;
  isShow: boolean;

  onFormChange: (valid: boolean, value: MultiManageForm, errors: {}) => void;
};

/**
 * Component
 * @param props MultipleManageModalActionProps
 */
export function MultipleManageModalAction({
  formValues: { action },
  isShow,
  onFormChange,
}: MultipleManageModalActionProps) {
  const rootStyle = classnames("form-group-container", {
    "is-show": isShow,
  });

  /**
   * Render
   */
  return (
    <div className={rootStyle}>
      <p>
        <Msg id="submission.message.selectAction" />
      </p>
      <Form
        validation={{ action: ["required"] }}
        initialValues={{ action: action?.toString() }}
        onFormChange={onFormChange}
      >
        <FormGroup className="radio-group">
          <Radio name="action" defaultValue={ActionType.Rescore.toString()}>
            <Msg id="submission.action.rescoreSubmissions" />
          </Radio>
          <Radio name="action" defaultValue={ActionType.Reset.toString()}>
            <Msg id="submission.action.resetSubmissions" />
          </Radio>
        </FormGroup>
      </Form>
    </div>
  );
}
