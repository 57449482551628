import classNames from "classnames";

import { useStoreContext, useSubmissionAccessTypeContext } from "@context";

import {
  EmptyState,
  Msg,
  ApplicantExamStatusTag,
  LanguageTag,
  Tag,
} from "@shared/components";
import {
  useApplicantSegmentExperience,
  useExamStatistics,
  useGuestReportExamScores,
  useGuestReportSubmission,
  useGuestSubmission,
  useGuestSubmissionExamStatistics,
  useSubmissionRankingDetails,
} from "@shared/hooks/query";
import {
  ApplicantReportModel,
  ReportExamScore,
  SubmissionAccessType,
  UserModel,
} from "@shared/models";
import { ApplicantExamStatus } from "@shared/services/enums";

import { ScoreChart } from "./ScoreChart";
import { ScoreSummary } from "./ScoreSummary";

interface SubmissionDetailScoreChartSectionComponentProps {
  applicantExamStatus: ApplicantExamStatus;
  applicantFullName?: string;
  applicantEmail?: string;
  applicantExperience?: string;
  submissionLanguage?: string;
  hasOverlappingApplicantExams?: boolean;
  onOverlappingBannerClick?: () => void;
  isSample?: boolean;
}

export function SubmissionDetailScoreChartSectionGuest(
  props: SubmissionDetailScoreChartSectionComponentProps,
) {
  const { data: applicantReportDetails } = useGuestReportSubmission();
  const { data: submission } = useGuestSubmission();
  const { data: examScoreDistribution } = useGuestReportExamScores();
  const { data: examScoreData } = useGuestSubmissionExamStatistics();

  return (
    <SubmissionDetailScoreChartSectionComponent
      {...props}
      isChartEnabled={Boolean(submission?.insightsEnabled)}
      hideEvaluation={!submission?.guestSharingSettings?.showReviews}
      applicantReportDetails={applicantReportDetails}
      examScoreDistribution={examScoreDistribution}
      hideExperience
      isSample={submission?.isTest}
      examScoreAverage={examScoreData?.examScoreStatistics?.average}
      chartAvgScoreIndex={examScoreData?.chartAvgScoreIndex}
    />
  );
}

export function SubmissionDetailScoreChartSection(
  props: SubmissionDetailScoreChartSectionComponentProps,
) {
  const applicantSegmentExperience = useApplicantSegmentExperience();
  const submissionAccessType = useSubmissionAccessTypeContext();
  const isChartEnabled = submissionAccessType === SubmissionAccessType.Full;
  const {
    data: {
      chartAvgScoreIndex,
      examScoreDistribution,
      examScoreStatistics: { average: examScoreAverage },
    },
  } = useExamStatistics();

  const { data: applicantReportDetails } = useSubmissionRankingDetails();
  const { user } = useStoreContext();

  return (
    <SubmissionDetailScoreChartSectionComponent
      {...props}
      applicantSegmentExperience={applicantSegmentExperience}
      isChartEnabled={isChartEnabled}
      chartAvgScoreIndex={chartAvgScoreIndex}
      examScoreDistribution={examScoreDistribution}
      examScoreAverage={examScoreAverage}
      applicantReportDetails={applicantReportDetails}
      user={user}
    />
  );
}

interface CommonProps {
  user?: UserModel;
  applicantReportDetails?: ApplicantReportModel;
  chartAvgScoreIndex?: number;
  examScoreAverage?: number;
  examScoreDistribution?: ReportExamScore[];
  isChartEnabled?: boolean;
  hideExperience?: boolean;
  hideEvaluation?: boolean;
  isSample?: boolean;
  applicantSegmentExperience?: Record<string, string>;
}

function SubmissionDetailScoreChartSectionComponent({
  applicantExamStatus,
  applicantFullName,
  applicantEmail,
  applicantExperience,
  submissionLanguage,
  hideExperience,
  hideEvaluation,
  hasOverlappingApplicantExams = false,
  onOverlappingBannerClick,
  user,
  applicantReportDetails,
  chartAvgScoreIndex,
  examScoreAverage,
  examScoreDistribution,
  isChartEnabled,
  isSample,
  applicantSegmentExperience,
}: SubmissionDetailScoreChartSectionComponentProps & CommonProps) {
  const rootStyle = classNames("code-submission-detail-score-chart-section");

  return (
    <div className={rootStyle}>
      <div className="tag-container">
        {isSample && (
          <Tag className="tag-container__sample-tag">
            <Msg id="delivery.test.label" />
          </Tag>
        )}
        <ApplicantExamStatusTag
          hideEvaluation={hideEvaluation}
          applicantExamStatus={applicantExamStatus}
        />
      </div>
      <h2 className="applicant-name">
        {applicantFullName && applicantFullName?.length > 0 ? (
          <>
            {applicantFullName}
            <small>{applicantEmail}</small>
          </>
        ) : (
          <>{applicantEmail}</>
        )}
      </h2>
      <div className="applicant-metadata">
        {!hideExperience && (
          <span className="applicant-metadata__item">
            <Msg id="submission.submissionDetail.itExperience" />
            {": "}
            {applicantSegmentExperience && applicantExperience ? (
              applicantSegmentExperience[applicantExperience]
            ) : (
              <Msg id="submission.submissionDetail.itExperience.notProvided" />
            )}
          </span>
        )}
        {submissionLanguage && (
          <span className="applicant-metadata__item">
            <Msg id="exam.examlanguage" />:{" "}
            <LanguageTag language="en" color="pink" className="language-tag" />
          </span>
        )}
      </div>
      {isChartEnabled && (
        <div className="main-score-container">
          {applicantReportDetails && examScoreDistribution ? (
            <>
              <ScoreSummary
                disableScoreTooltip={user?.language === "ja"}
                applicantReportDetails={applicantReportDetails}
              />
              <ScoreChart
                applicantReportDetails={applicantReportDetails}
                examScoreDistribution={examScoreDistribution}
                chartAvgScoreIndex={chartAvgScoreIndex}
                examScoreAverage={examScoreAverage}
              />
            </>
          ) : (
            <EmptyState />
          )}
        </div>
      )}
    </div>
  );
}
