import { AxiosRequestConfig } from "axios";

import { SubmissionNoteModel } from "@shared/models";

import { get, post } from "../httpClient";

export interface GetSubmissionNoteResponse {
  /** The most recent versions of the note IN REVERSE CHRONOLOGICAL ORDER.
   * The FIRST element in the list is the MOST RECENT.
   * The initial design only requires we show that, so all later elements can be ignored.
   */
  noteHistory: {
    count: number;
    limit: number;
    offset: number;
    result: SubmissionNoteModel[];
  };
}

interface SubmissionNoteListParams {
  projectId: number;
  submissionId: number;
  options?: AxiosRequestConfig;
}

export async function getSubmissionNoteList({
  projectId,
  submissionId,
  options,
}: SubmissionNoteListParams) {
  const { data } = await get<GetSubmissionNoteResponse>(
    `/api/projects/${projectId}/submissions/${submissionId}/notes`,
    options,
  );

  return data;
}

interface AddSubmissionNoteResponse {
  addedNote: SubmissionNoteModel;
}

export interface AddSubmissionNoteParams {
  projectId: number;
  submissionId: number;
  options?: AxiosRequestConfig<{ content: string }>;
}

export async function postSubmissionNote({
  projectId,
  submissionId,
  options,
}: AddSubmissionNoteParams) {
  const { data } = await post<AddSubmissionNoteResponse>(
    `/api/projects/${projectId}/submissions/${submissionId}/notes`,
    options,
  );

  return data;
}
