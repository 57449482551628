import classNames from "classnames";
import { useState } from "react";

import Button from "../button/Button";

interface TabSwitchButtonProps {
  tabs: { id: string; label: string }[];
  defaultActiveTab?: string;
  onTabSwitch: (newActiveTabId: string) => void;
}

export const TabSwitchButton = ({
  tabs,
  defaultActiveTab,
  onTabSwitch,
}: TabSwitchButtonProps) => {
  const [activeTab, setActiveTab] = useState(defaultActiveTab || tabs[0].id);

  const handleTabClick = (tabId: string) => {
    setActiveTab(tabId);
    onTabSwitch(tabId);
  };

  return (
    <div className="code-c-tab-switch-button">
      {tabs.map((tab) => (
        <Button
          key={tab.id}
          size="small"
          className={classNames({
            "code-c-tab-switch-button__disabled": tab.id !== activeTab,
          })}
          onClick={() => handleTabClick(tab.id)}
        >
          {tab.label}
        </Button>
      ))}
    </div>
  );
};

export default TabSwitchButton;
