import { connect } from "react-redux";
import { Action, Dispatch } from "redux";

import { challengeCollectionGetAction } from "@actions";

import { RootState } from "@reducers";

import { getTierAllowedByRedux } from "@shared/services/tier";

import {
  ChallengeCollectionDetailModal,
  ExternalProps,
} from "./ChallengeCollectionDetailModal";

const mapStateToProps = (state: RootState, props: ExternalProps) => ({
  language: state.intl.locale,
  challengeCollectionLoading: state.challenge.challengeCollectionLoading,
  challengeCollectionDetails: state.challenge.challengeCollectionDetails,
  examOptions: state.exam.examCreateOptions,
  isTierActionAllowed: getTierAllowedByRedux(state),
  ...props,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  getChallengeCollection: (id: number, conditions?: {}) => {
    dispatch(challengeCollectionGetAction.request(id, conditions));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ChallengeCollectionDetailModal);
