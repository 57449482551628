import { connect } from "react-redux";

import { RootState } from "../../../reducers";
import ExamTitleColumn, { ExternalProps } from "./ExamTitleColumn";

const mapStateToProps = (state: RootState, props: ExternalProps) => ({
  ...props,
  currentUser: state.user.user,
});

/**
 * Wrapped component
 */
const wrappedComponent = connect(mapStateToProps, () => ({}))(ExamTitleColumn);

export default wrappedComponent;
