import {
  useIsFetching,
  useIsMutating,
  useQueryClient,
} from "@tanstack/react-query";
import { useEffect, useState } from "react";

import Loading from "../loading/Loading";
import { Portal } from "../portal/Portal";
import { determineLoaderState } from "./utils";

export default function GlobalLoadingIndicator() {
  const queryClient = useQueryClient();
  const queriesFetching = useIsFetching();
  const mutationsFetching = useIsMutating();
  const [hideLoader, setHideLoader] = useState(true);

  useEffect(() => {
    // Call the logic function to determine if loader should be hidden or not
    const { hideLoader } = determineLoaderState(queryClient);
    setHideLoader(hideLoader);
  }, [queryClient, mutationsFetching, queriesFetching]); // Re-run when the queryClient changes (i.e., query status changes)

  if (hideLoader) {
    return null;
  }

  return (
    <Portal>
      <Loading isOpen hide={false} />
    </Portal>
  );
}
