import { connect } from "react-redux";
import { Action, Dispatch } from "redux";

import { orgsCsvColumnsGetAction } from "@actions";

import { RootState } from "@reducers";

import { CsvTypes } from "@shared/services/enums";

import SubmissionDownloadModal, {
  SubmissionDownloadModalProps,
} from "./SubmissionDownloadModal";

const mapStateToProps = (
  state: RootState,
  props: SubmissionDownloadModalProps,
) => ({
  loadingCsvColumnGroups: state.orgs.loadingCsvColumnGroups,
  csvColumnGroupsByCsvType: state.orgs.csvColumnGroupsByCsvType,
  csvTypes: state.orgs.csvTypes,
  ...props,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  getCsvColumns: (csvType: CsvTypes) => {
    dispatch(orgsCsvColumnsGetAction.request({}, csvType));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SubmissionDownloadModal);
