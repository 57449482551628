import * as React from "react";

import { Button, Icon, Modal, Msg, ScoreButton } from "@shared/components";
import { ApplicantExamStatus } from "@shared/services/enums";

export type SubmissionEvaluation =
  | ApplicantExamStatus.Passed
  | ApplicantExamStatus.Failed
  | undefined;

interface SubmissionEvaluationModalProps {
  evaluation?: SubmissionEvaluation;
  className?: string;
  onClickCancel: () => void;
  onClickOk: (newEvaluation: SubmissionEvaluation) => void;
}

/**
 * Page component
 */
export const SubmissionEvaluationModal: React.FunctionComponent<
  SubmissionEvaluationModalProps
> = ({
  evaluation,
  className,
  onClickCancel,
  onClickOk,
}: SubmissionEvaluationModalProps) => {
  const initialValue =
    evaluation &&
    [ApplicantExamStatus.Passed, ApplicantExamStatus.Failed].includes(
      evaluation,
    )
      ? evaluation
      : undefined;

  const [selected, setSelected] =
    React.useState<SubmissionEvaluation>(initialValue);

  React.useEffect(() => {
    setSelected(initialValue);
  }, [initialValue]);

  const handleResetClick = () => {
    setSelected(undefined);
  };

  React.useEffect(() => {
    setSelected(initialValue);
  }, [initialValue]);

  return (
    <Modal
      title={<Msg id="submission.evaluationResults" />}
      isOpen={true}
      onClose={onClickCancel}
      onClickCancel={onClickCancel}
      onClickOk={() => onClickOk(selected)}
      disableOk={selected === initialValue}
      ariaLabel="Add Evaluation Result"
      size="small"
      className={className}
      contentClassName="code-submission-evaluation-modal__body"
    >
      <div className="code-submission-evaluation-modal">
        <div className="code-submission-evaluation-modal__title">
          <Msg id="submission.evaluationResults.title" />
        </div>
        <div className="code-submission-evaluation-modal__content">
          <div className="code-submission-evaluation-modal__buttons">
            <ScoreButton
              width="small"
              color="red"
              disableHoverHighlight
              icon={<Icon type="close" size="large" />}
              isSelected={selected === ApplicantExamStatus.Failed}
              onClick={() =>
                setSelected(
                  selected === ApplicantExamStatus.Failed
                    ? undefined
                    : ApplicantExamStatus.Failed,
                )
              }
            >
              <Msg id="submission.fail" />
            </ScoreButton>
            <ScoreButton
              width="small"
              color="green"
              disableHoverHighlight
              icon={<Icon type="check" size="large" />}
              isSelected={selected === ApplicantExamStatus.Passed}
              onClick={() =>
                setSelected(
                  selected === ApplicantExamStatus.Passed
                    ? undefined
                    : ApplicantExamStatus.Passed,
                )
              }
            >
              <Msg id="submission.pass" />
            </ScoreButton>
          </div>
          <div className="code-submission-evaluation-modal__reset">
            {selected && (
              <Button stretch onClick={handleResetClick}>
                <Msg id="submission.reset" />
              </Button>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};
