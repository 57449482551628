import { useMutation } from "@tanstack/react-query";
import { AxiosResponseHeaders } from "axios";
import { toast } from "react-toastify";

import { postSubmissionCsv } from "@api/submissions";
import { postSubmissionAdminCsv } from "@api/submissions/submissionAdminCsv";

import { useStoreContext } from "@context";

import { DownloadCSVParamsModel, SortOrderItem } from "@shared/models";
import { Encoding } from "@shared/services/enums";
import Message from "@shared/services/message";
import { getFilenameFromDispositionHeader } from "@shared/services/queryString";

function handleCSVDownloadSuccess(
  {
    data,
    headers,
  }: {
    data: any;
    headers: AxiosResponseHeaders;
  },
  {
    encoding,
  }: DownloadCSVParamsModel & {
    sortOrder?: SortOrderItem;
  },
) {
  const filename = getFilenameFromDispositionHeader(
    headers["content-disposition"] ?? "",
  );
  const rawEncoding = encoding === Encoding.UTF_8 ? "utf-8" : "Shift_JIS";
  const blob = new Blob([data], {
    type: `text/csv;charset=${rawEncoding};`,
  });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  // if no filename for some reason, let computer autogenerate one
  if (filename) {
    link.download = filename;
  }
  document.body.appendChild(link);
  link.click();
  setTimeout(() => {
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  }, 100);
}

function handleCSVDownloadError() {
  toast.error(
    Message.getMessageByKey("message.submissions.csvDownload.failed"),
  );
}

export function useCreateSubmissionCsv({
  projectId: projectIdParam,
}: {
  projectId?: number;
} = {}) {
  const { projectId: contextProjectId } = useStoreContext();
  const projectId = projectIdParam || contextProjectId;

  const mutation = useMutation({
    mutationFn: (
      payload: DownloadCSVParamsModel & {
        sortOrder?: SortOrderItem;
      },
    ) =>
      postSubmissionCsv({
        projectId,
        options: { data: payload },
      }),
    onSuccess: handleCSVDownloadSuccess,
    onError: handleCSVDownloadError,
  });

  return mutation;
}

export function useCreateSubmissionAdminCsv({
  projectId,
  organizationName,
}: {
  projectId: number;
  organizationName: string;
}) {
  const mutation = useMutation({
    mutationFn: (
      payload: DownloadCSVParamsModel & {
        sortOrder?: SortOrderItem;
      },
    ) =>
      postSubmissionAdminCsv({
        projectId,
        organizationName,
        options: { data: payload },
      }),
    onSuccess: handleCSVDownloadSuccess,
    onError: handleCSVDownloadError,
  });

  return mutation;
}
