import * as React from "react";
import { Link } from "react-router-dom";

import { IpAddressBadge } from "@components/orgs/submissions/submissionDetail/partials/IpAddressBadge/IpAddressBadge";

import { useStoreContext } from "@context";

import {
  Column,
  DateTime,
  HeaderColumn,
  Icon,
  Modal,
  Msg,
  Pagination,
  Row,
  SortableText,
  Table,
  TableBody,
  TableHead,
} from "@shared/components";
import { useExam, useSubmission } from "@shared/hooks/query";
import { swapValueWithIndex } from "@shared/services/array";
import { formatTimeLeftString } from "@shared/services/date";
import { ApplicantExamStatus, SortDirection } from "@shared/services/enums";
import { extractScoreStringFromSubmissionList } from "@shared/services/score";

import { useIpSubmissionListBatch } from "./useIpSubmissionListBatch";

interface SubmissionIpAddressModalProps {
  ipFilter?: string;
  onClickClose: () => void;
}

export const SubmissionIpAddressModal = ({
  ipFilter,
  onClickClose,
}: SubmissionIpAddressModalProps) => {
  const { projectId } = useStoreContext();
  const {
    data: {
      examDetail: { applicantNameRequired },
    },
  } = useExam();
  const {
    data: { overlappingIpAddresses },
  } = useSubmission();

  const tableContainerRef = React.useRef<HTMLDivElement>(null);

  const applicantExamIds = React.useMemo(
    () =>
      overlappingIpAddresses?.overlappingApplicantExams.map(
        (applicantExam) => applicantExam.applicantExamId,
      ),
    [overlappingIpAddresses?.overlappingApplicantExams],
  );

  const { setCurrentPage, sortOrder, setSortOrder, data } =
    useIpSubmissionListBatch({
      ipFilter,
      applicantExamIds,
    });

  React.useEffect(() => {
    if (tableContainerRef.current?.parentElement) {
      tableContainerRef.current.parentElement.scrollTop = 0;
    }
  }, [data]);

  return (
    <Modal
      title={<Msg id="submission.overlappingIp.help" />}
      isOpen={true}
      onClose={onClickClose}
      onClickCancel={onClickClose}
      cancelButtonLabel={<Msg id="close" />}
      hasOkButton={false}
      size="large"
      ariaLabel="Applicants with the same IP address"
      className="code-submission-ip-address-modal"
    >
      <div ref={tableContainerRef}>
        <div className="code-submission-ip-address-modal__header">
          <Msg id="submission.ipAddress.title" />
          {ipFilter ? (
            <span className="code-submission-ip-address-modal__header__matching-ip">
              <Msg id="submission.ipAddress.matchingIpAddress" />
              <span>{ipFilter}</span>
            </span>
          ) : (
            <span className="code-submission-ip-address-modal__header__info">
              <Icon type="info-circle" />
              <Msg id="submission.ipAddress.info" />
            </span>
          )}
        </div>

        <Table className="code-exam-list__table">
          <TableHead>
            <HeaderColumn size={4}>
              <SortableText
                name={applicantNameRequired ? "applicantFullName" : "email"}
                sortItem={sortOrder}
                onClick={setSortOrder}
                ariaLabel={applicantNameRequired ? "Full Name" : "Email"}
                initialDirection={SortDirection.Desc}
              >
                <Msg id={"common.applicants"} />
              </SortableText>
            </HeaderColumn>
            <HeaderColumn absoluteSize={12}>
              <Msg id={"submission.actionLog.sessionData.ip"} />
            </HeaderColumn>
            <HeaderColumn absoluteSize={7}>
              <SortableText
                name="score"
                sortItem={sortOrder}
                initialDirection={SortDirection.Desc}
                onClick={setSortOrder}
                ariaLabel="Score"
                className="code-submission-ip-address-modal__overall-score"
              >
                <Msg id={"overallScore"} />
              </SortableText>
            </HeaderColumn>
            <HeaderColumn absoluteSize={7}>
              <SortableText
                name="deliveredAt"
                sortItem={sortOrder}
                onClick={setSortOrder}
                ariaLabel="Delivered At"
              >
                <Msg id="deliveredAt" />
              </SortableText>
            </HeaderColumn>
            <HeaderColumn absoluteSize={7}>
              <SortableText
                name="submittedAt"
                sortItem={sortOrder}
                onClick={setSortOrder}
                ariaLabel="Submitted At"
              >
                <Msg id={"submittedAt"} />
              </SortableText>
            </HeaderColumn>
            <HeaderColumn absoluteSize={6}>
              <SortableText
                name="timespentInSeconds"
                sortItem={sortOrder}
                onClick={setSortOrder}
                ariaLabel="Spent Time"
              >
                <Msg id={"timeSpent"} />
              </SortableText>
            </HeaderColumn>
          </TableHead>
          <TableBody>
            {data?.submissionList.map((submission) => {
              const name =
                submission.status === ApplicantExamStatus.Canceled ||
                submission.archivedAt ? (
                  <span>{submission.applicantFullName}</span>
                ) : (
                  <Link
                    className="code-c-submission-title-column__link is-bold"
                    to={`/p/${projectId}/exams/${submission.examId}/submissions/${submission.id}`}
                    onClick={onClickClose}
                  >
                    {submission.applicantFullName}
                  </Link>
                );

              let allIps = submission.overlappingIpAddresses
                ?.allApplicantRequestIps
                ? [...submission.overlappingIpAddresses?.allApplicantRequestIps]
                : [];

              // set IP filter as the first item in the array
              if (ipFilter) {
                allIps = swapValueWithIndex({
                  arr: allIps,
                  value: ipFilter,
                  index: 0,
                });
              }
              const [overlappingIp, ...otherOverlappingIps] = allIps;

              return (
                <Row key={submission.id}>
                  <Column className="code-submit-exam-modal__name">
                    <div>{name}</div>
                    <div>
                      <small>{submission.applicantEmail}</small>
                    </div>
                  </Column>
                  <Column>
                    {overlappingIp ? (
                      <div className="code-submission-ip-address-modal__ip-address">
                        <div className="code-submission-ip-address-modal__ip-address__text">
                          {overlappingIp}
                        </div>
                        {Boolean(otherOverlappingIps.length) && (
                          <IpAddressBadge ips={otherOverlappingIps} />
                        )}
                      </div>
                    ) : (
                      "-"
                    )}
                  </Column>
                  <Column>
                    {extractScoreStringFromSubmissionList(
                      submission.status,
                      submission.score,
                    )}
                  </Column>
                  <Column>
                    <DateTime dateTime={submission.deliveredAt} />
                  </Column>
                  <Column>
                    {submission.submittedAt ? (
                      <DateTime dateTime={submission.submittedAt} />
                    ) : (
                      "-"
                    )}
                  </Column>
                  <Column>
                    <span style={{ wordBreak: "break-word" }}>
                      {submission.timespentInSeconds
                        ? formatTimeLeftString(submission.timespentInSeconds)
                        : "-"}
                    </span>
                  </Column>
                </Row>
              );
            })}
          </TableBody>
        </Table>
        {data?.submissionPagination && (
          <div className="code-submission-ip-address-modal__pagination">
            <Pagination
              pagination={data?.submissionPagination}
              onPageChange={({ selected }) => {
                setCurrentPage(selected);
              }}
            />
          </div>
        )}
      </div>
    </Modal>
  );
};
