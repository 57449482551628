import { ExamSettingsFormValidation } from "@components/orgs/exams/examSections/examSettingsNew/utils";

import ExamModel from "@shared/models/Exam.model";
import UrlSharingConfigModel from "@shared/models/UrlSharingConfig.model";

export const getExamSettingValues = (
  exam: ExamModel,
): ExamSettingsFormValidation => {
  const {
    deliveryKind,
    urlDeliveryEnabled,
    idDeliveryConfig,
    atsDynamicDeadlineDays,
    isOfficial,
    guestSharingSettings,
    applicantActionSettings,
    autoFilterSettings,
    coverImageUrl,
    language,
    deadlineNotificationEnabled,
    atsIntegrationEnabled,
    webcamSettings,
    guestSharingEnabled,
  } = exam;

  let urlSharingConfig = exam.urlSharingConfig;
  // for older exams, urlSharingConfig is set even if urlDeliveryEnabled is false
  // so we need to check if urlDeliveryEnabled is false and set urlSharingConfig to undefined
  if (urlDeliveryEnabled === undefined || urlDeliveryEnabled === false) {
    urlSharingConfig = undefined as unknown as UrlSharingConfigModel;
  }
  // sets default values for guestSharingSettings if they are not present
  // older exams may not have these values set
  if (!applicantActionSettings.showCopyPasteEvents) {
    applicantActionSettings.showCopyPasteEvents = false;
  }
  if (!applicantActionSettings.showScreenshotEvents) {
    applicantActionSettings.showScreenshotEvents = false;
  }
  if (!applicantActionSettings.showTabChangeEvents) {
    applicantActionSettings.showTabChangeEvents = false;
  }
  if (!applicantActionSettings.showKeyEvents) {
    applicantActionSettings.showKeyEvents = false;
  }

  let applicantActionSettingsEnabled = false;
  if (applicantActionSettings) {
    applicantActionSettingsEnabled = Object.values(
      applicantActionSettings,
    ).some((value) => value);
  }
  if (webcamSettings.enabled) {
    applicantActionSettingsEnabled = true;
  }

  let autoFilterSettingsEnabled = false;
  if (autoFilterSettings) {
    autoFilterSettingsEnabled = Object.values(autoFilterSettings).some(
      (value) => value,
    );
  }

  const data: ExamSettingsFormValidation = {
    deliveryKind,
    urlDeliveryEnabled,
    applicantActionSettingsEnabled,
    autoFilterSettingsEnabled,
    guestSharingEnabled,
    urlSharingConfig: urlSharingConfig
      ? {
          domainWhitelist: urlSharingConfig.domainWhitelist,
          dynamicDeadlineHours: urlSharingConfig.dynamicDeadlineHours,
          endAt: new Date(urlSharingConfig.endAt),
          startAt: new Date(urlSharingConfig.startAt),
        }
      : undefined,
    idDeliveryConfig: idDeliveryConfig
      ? {
          passwordRequired: idDeliveryConfig.passwordRequired,
          dynamicDeadlineHours: idDeliveryConfig.dynamicDeadlineHours,
          endAt: new Date(idDeliveryConfig.endAt),
          startAt: new Date(idDeliveryConfig.startAt),
        }
      : (undefined as any),
    atsDynamicDeadlineDays,
    isOfficial,
    guestSharingSettings,
    applicantActionSettings,
    autoFilterSettings: autoFilterSettings as any,
    coverImageUrl: coverImageUrl || "",
    language,
    deadlineNotificationEnabled,
    atsIntegrationEnabled,
    webcamSettings,
  };
  return data;
};
