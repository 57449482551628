import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { CustomFormDefinitionPayload, putExamCustomForm } from "@api/exams";

import { useStoreContext } from "@context";

import Message from "@shared/services/message";

import { useInvalidateExamRelation } from "./useExam";

interface UpdateExamCustomFormParams {
  examId: number;
  data: CustomFormDefinitionPayload;
}

export function useUpdateExamCustomForm() {
  const { projectId } = useStoreContext();
  const invalidateExam = useInvalidateExamRelation();

  const mutation = useMutation({
    mutationFn: ({ examId, data }: UpdateExamCustomFormParams) =>
      putExamCustomForm({ examId, projectId, options: { data } }),
    onSuccess: (_, { examId }) => {
      toast.success(Message.getMessageByKey("message.exam.updated"));
      invalidateExam(examId);
    },
  });

  return mutation;
}
