import { Epic } from "redux-observable";

import {
  Action,
  APIResponseAction,
  ajaxAction,
  examListGetAction,
  examDetailsGetAction,
  adminOfficialExamAllListGetAction,
  adminPostOfficialExamAllListGetAction,
  adminExamDeactivateAction,
  adminExamReactivateAction,
  alertAction,
} from "../actions";
import { RootState } from "../reducers";
import { OfficialExamListModel, PaginationModel } from "../shared/models";
import Message from "../shared/services/message";

const examListGetEpic: Epic<Action, RootState> = (action$, state) =>
  action$
    .ofType(examListGetAction.types.request)
    .debounceTime(500)
    .map((action) => {
      const { projectId, ...body } = action.params as { projectId: number };
      return ajaxAction.request({
        method: "get",
        url: `/api/admin/orgs/${
          state.getState().admin.selectedOrg.name
        }/projects/${projectId}/exams`,
        body: { ...body },
        success: examListGetAction.success,
        cancel: [examListGetAction.types.request],
      });
    });

const examDetailsGetEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(examDetailsGetAction.types.request).map((action) =>
    ajaxAction.request({
      method: "get",
      url: `/api/admin/orgs/${
        state.getState().admin.selectedOrg.name
      }/projects/${action.payload}/exams/${action.params}`,
      success: examDetailsGetAction.success,
    }),
  );

export const examDeactivateEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(adminExamDeactivateAction.types.request).map((action) =>
    ajaxAction.request({
      method: "put",
      url: `/api/admin/orgs/${
        state.getState().admin.selectedOrg.name
      }/projects/${action.payload}/exams/${action.params}/deactivate`,
      success: alertAction.success(
        Message.getMessageByKey("message.admin.exam.deactivated"),
        adminExamDeactivateAction.success,
      ),
      error: adminExamDeactivateAction.failure,
    }),
  );

export const examReactivateEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(adminExamReactivateAction.types.request).map((action) =>
    ajaxAction.request({
      method: "put",
      url: `/api/admin/orgs/${
        state.getState().admin.selectedOrg.name
      }/projects/${action.payload}/exams/${action.params}/reactivate`,
      success: alertAction.success(
        Message.getMessageByKey("message.admin.exam.reactivated"),
        adminExamReactivateAction.success,
      ),
      error: adminExamReactivateAction.failure,
    }),
  );

export const postExamActivateEpic: Epic<Action, RootState> = (
  action$,
  state$,
) =>
  action$
    .ofType(
      adminExamReactivateAction.types.success,
      adminExamDeactivateAction.types.success,
    )
    .map(() =>
      examListGetAction.request(
        undefined,
        state$.getState().exam.examListCondition,
      ),
    );

export const officialExamAllListGetEpic: Epic<Action, RootState> = (
  action$,
  state,
) =>
  action$
    .ofType(adminOfficialExamAllListGetAction.types.request)
    .map((action) =>
      ajaxAction.request({
        method: "get",
        url: `/api/admin/exams/officials`,
        body: {
          ...action.payload,
          "sortOrder.column": "id",
          "sortOrder.direction": "desc",
        },
        success: adminPostOfficialExamAllListGetAction.request,
        error: adminOfficialExamAllListGetAction.failure,
        options: { disableDefaultError: true },
      }),
    );

export const postOfficialExamAllListGetEpic: Epic<Action, RootState> = (
  action$,
) =>
  action$
    .ofType(adminPostOfficialExamAllListGetAction.types.request)
    .flatMap((action) => {
      const { result: exams, pagination } = (
        action as APIResponseAction & {
          payload: {
            result: OfficialExamListModel[];
            pagination: PaginationModel;
          };
        }
      ).payload;
      const { count, limit, offset } = pagination;
      const hasNextPage = count > limit + offset;
      const reset = offset === 0;
      return [
        adminOfficialExamAllListGetAction.success({
          hasNextPage,
          reset,
          exams,
        }),
        ...(hasNextPage
          ? [
              adminOfficialExamAllListGetAction.request({
                limit,
                offset: limit + offset,
              }),
            ]
          : []),
      ];
    });

export default [
  examListGetEpic,
  examDetailsGetEpic,
  officialExamAllListGetEpic,
  postOfficialExamAllListGetEpic,
  examDeactivateEpic,
  examReactivateEpic,
  postExamActivateEpic,
];
