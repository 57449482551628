import * as classnames from "classnames";
import * as React from "react";

/**
 * Prop interface
 */
export type SubMenuContainerRightProps = {
  className?: string;
  children?: {};
} & React.HTMLProps<HTMLDivElement>;

/**
 * React Component
 */
export default function SubMenuContainerRight({
  className,
  children,
  ...props
}: SubMenuContainerRightProps) {
  const rootStyle = classnames("code-c-sub-menu-container-right", {
    [`${className}`]: Boolean(className),
  });

  return (
    <div className={rootStyle} {...props}>
      {children}
    </div>
  );
}
