import { AxiosRequestConfig } from "axios";

import { EnumWithStringValueModel } from "@shared/models";

import { get } from "../httpClient";

export { EnumWithStringValueModel };

interface GetSubmissionAIPresignedParams {
  projectId: number;
  challengeId: number;
  historyId: number;
  id: number;
  options?: AxiosRequestConfig;
}

export async function getSubmissionAIPresigned({
  projectId,
  challengeId,
  historyId,
  id,
  options,
}: GetSubmissionAIPresignedParams) {
  const { data } = await get<string>(
    `/api/projects/${projectId}/submissions/${id}/results/${challengeId}/${historyId}/presigned`,
    options,
  );

  return data;
}
