import { Action, imageUploadAction } from "../actions";
import { UploadImagesModel } from "../shared/models";

export interface ImageUploadState {
  uploading: boolean;
  error: boolean;
  uploadedImages?: UploadImagesModel;
}

const initialState = {
  uploading: false,
  error: false,
};

export const imageUploadReducer = (
  state: ImageUploadState = initialState,
  action: Action,
): ImageUploadState => {
  switch (action.type) {
    case imageUploadAction.types.request: {
      const { name } = action.payload as {
        name: string;
      };
      const uploadedImages = { ...state.uploadedImages };
      delete uploadedImages[name];

      return {
        ...state,
        ...{ uploading: true, error: false },
        uploadedImages,
      };
    }
    case imageUploadAction.types.success: {
      const { name, imageUrl } = action.payload as {
        name: string;
        imageUrl: string;
      };

      return {
        ...state,
        ...{
          uploading: false,
          error: false,
          uploadedImages: {
            ...state.uploadedImages,
            [name]: { error: false, url: imageUrl },
          },
        },
      };
    }
    case imageUploadAction.types.failure: {
      const { name, error } = action.payload as {
        name: string;
        error: string;
      };

      return {
        ...state,
        ...{ uploading: false, error: true },
        uploadedImages: {
          ...state.uploadedImages,
          [name]: { error: true, errorMessage: error, url: "" },
        },
      };
    }
    default:
      return state;
  }
};
