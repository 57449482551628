import actionCreator from "../shared/services/actionCreator";
import { OrganizationAppType } from "../shared/services/enums";

export interface GlobalTypeSet {
  appType: OrganizationAppType;
  forceSwitch: boolean;
}

export const globalTypeSetAction = actionCreator("globalTypeSetAction");
export const globalAppTypeSetAction = actionCreator("globalAppTypeSetAction");
