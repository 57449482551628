import { AxiosRequestConfig } from "axios";

import { ApiResponse, put } from "@api/httpClient";

import { UrlSharingConfigModel } from "@shared/models";

export type UpdateExamUrlSharingPayload = Pick<
  UrlSharingConfigModel,
  "startAt" | "endAt" | "dynamicDeadlineHours" | "domainWhitelist"
>;

interface UpdateExamUrlSharingParams {
  examId: number;
  projectId: number;
  options: AxiosRequestConfig<UpdateExamUrlSharingPayload>;
}

export async function putExamUrlSharing({
  examId,
  projectId,
  options,
}: UpdateExamUrlSharingParams) {
  const { data } = await put<ApiResponse<UrlSharingConfigModel>>(
    `/api/projects/${projectId}/exams/${examId}/urlsharing`,
    options,
  );

  return data;
}
