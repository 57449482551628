import * as classnames from "classnames";
import * as React from "react";

import { Modal, Loading, Msg } from "../../../../shared/components";
import { ChallengeReleaseNoteModel } from "../../../../shared/models";
import { ReleaseNote } from "../challengeSections";

/**
 * Prop interface
 */
export type ExternalProps = {
  isOpen: boolean;
  challengeId: number;
  usedChallengeVersionCode: string;
  onClose: () => void;
};

export type InjectedProps = {
  releaseNote?: ChallengeReleaseNoteModel;
  loading: boolean;
  getReleaseNote: (challengeId: number, versionCode?: string) => void;
  onClose: () => void;
};

export type ChallengeReleaseNoteProps = InjectedProps & ExternalProps;
/**
 * React Component
 */
export function ChallengeReleaseNote({
  isOpen,
  releaseNote,
  challengeId,
  usedChallengeVersionCode,
  loading,
  getReleaseNote,
  onClose,
}: ChallengeReleaseNoteProps) {
  const rootStyle = classnames("code-challenge-release-note");

  const [ready, setReady] = React.useState<boolean | undefined>(undefined);

  React.useEffect(() => {
    // isOpen => loading => ready
    if (!isOpen) {
      setReady(undefined);
    }
    if (loading) {
      setReady(false);
    }
    if (ready === false && loading === false) {
      setReady(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, loading]);

  React.useEffect(() => {
    getReleaseNote(challengeId, usedChallengeVersionCode);
  }, [challengeId, usedChallengeVersionCode, getReleaseNote]);

  return (
    <Modal
      className={rootStyle}
      title={<Msg id="challengeVersion.releaseNote" />}
      isOpen={isOpen && ready}
      onClickOk={onClose}
      onClose={onClose}
      hasCancelButton={false}
      ariaLabel="Challenge Release Note"
    >
      <Loading isOpen={loading} />
      <ReleaseNote releaseNote={releaseNote} />
    </Modal>
  );
}
