import classNames from "classnames";
import * as React from "react";

import { useHoverBox } from "@shared/hooks";
import { ReviewModel } from "@shared/models";
import { ReviewScore } from "@shared/services/enums";
import * as ScoreService from "@shared/services/score";

import ReviewScoreIcon from "../icon/reviewScoreIcon/ReviewScoreIcon";
import { reviewClassMap } from "../review/Review";
import { ReviewStatusTrackerBarItem } from "./ReviewStatusTrackerBarItem";

interface ReviewStatusTrackerBarProps {
  reviews: ReviewModel[];
}

export const ReviewStatusTrackerBar = ({
  reviews,
}: ReviewStatusTrackerBarProps) => {
  const counts = ScoreService.classifyReviews(reviews);

  const hasReview = Boolean(
    counts.stronglyApprove ||
      counts.approve ||
      counts.reject ||
      counts.stronglyReject,
  );
  const totalReviews =
    counts.stronglyApprove +
    counts.approve +
    counts.reject +
    counts.stronglyReject;

  const rootStyle = classNames("code-c-review-status-tracker-bar");

  const [element, setTargetElement] = React.useState<{
    targetElement: HTMLDivElement;
    reviewType: ReviewScore;
    reviewCount: number;
  } | null>(null);

  const onHover = (
    e: React.FormEvent<HTMLDivElement>,
    type: ReviewScore,
    count: number,
  ) => {
    setTargetElement({
      targetElement: e.currentTarget,
      reviewType: type,
      reviewCount: count,
    });
  };

  const onUnhover = () => setTargetElement(null);

  const targetElement = element?.targetElement;

  const { styles, attributes, setPopperElement } = useHoverBox({
    targetElement,
    minWidth: 150,
    offset: [
      targetElement?.clientWidth ? targetElement?.clientWidth / 2 : 0,
      8,
    ],
  });

  return (
    <div className={rootStyle}>
      <div className="code-c-review-status-tracker-bar__container">
        {!hasReview && <div className="no-review"></div>}
        {hasReview &&
          [
            [counts.stronglyReject, ReviewScore.StronglyReject],
            [counts.reject, ReviewScore.Reject],
            [counts.approve, ReviewScore.Approve],
            [counts.stronglyApprove, ReviewScore.StronglyApprove],
          ]
            .filter(([reviewCount]) => Boolean(reviewCount))
            .map(([reviewCount, reviewType]) => (
              <ReviewStatusTrackerBarItem
                key={reviewType}
                reviewType={reviewType}
                reviewCount={reviewCount}
                totalReviews={totalReviews}
                onHover={onHover}
                onUnhover={onUnhover}
              />
            ))}
      </div>
      {targetElement && (
        <div
          className="code-c-review-status-tracker-bar__hover-box"
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
        >
          <div
            className={classNames(
              "code-c-review-status-tracker-bar__hover-box__label",
              typeof element.reviewType !== "undefined"
                ? reviewClassMap[element.reviewType]
                : undefined,
            )}
          >
            <ReviewScoreIcon
              className="code-c-review-status-tracker-bar__icon"
              score={element.reviewType}
            />
            <span className="code-c-review-status-tracker-bar__text">
              {ReviewScore.toString(element.reviewType)}
            </span>
          </div>
          <span>{element.reviewCount}</span>
        </div>
      )}
    </div>
  );
};
