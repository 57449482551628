import * as classnames from "classnames";

import { ReviewScore } from "@shared/services/enums";

export default function ReviewScoreIcon({
  score,
  className,
}: {
  score: ReviewScore;
  className?: string;
}) {
  const rootStyle = classnames(
    "code-c-review-score-icon",
    "code-c-icon",
    "icon",
    {
      "is-strongly-approved": score === ReviewScore.StronglyApprove,
      "is-approved": score === ReviewScore.Approve,
      "is-rejected": score === ReviewScore.Reject,
      "is-strongly-rejected": score === ReviewScore.StronglyReject,
      [`${className}`]: Boolean(className),
    },
  );

  return (
    <span className={rootStyle}>
      <i className="score-icon" />
    </span>
  );
}
