import Logger from "./logger";

// sneaky declare for avoiding compile error.
declare global {
  const __MESSAGES__: MessageOrigin;
}

interface MessageOrigin {
  [key: string]: {
    en: string;
    ja: string;
  };
}

// __MESSAGES__ will get converted to actual version object by the webpack plugin.
const messageOrigin: Readonly<MessageOrigin> = Object.freeze(
  __MESSAGES__ as MessageOrigin,
);

class Message {
  public locale: string;
  public messages: { [key: string]: string } = {};

  public switchLocale(locale: string) {
    this.locale = locale;
    this.messages = Object.keys(messageOrigin).reduce((result, key) => {
      return Object.assign(result, { [`${key}`]: messageOrigin[key][locale] });
    }, {});
  }

  public getMessageByKey(key: string) {
    const message = this.messages[key] || "";
    if (!message) {
      Logger.warn(`Invalid message key: ${key}`);
    }
    return message || key;
  }

  public getSearchResultFormat(count: number | undefined) {
    return this.getCountLabelString(count || 0, "searchResult");
  }

  public getFormattedPlural(label: string, count: number) {
    return `${this.getMessageByKey(count !== 1 ? `${label}s` : label)}`;
  }

  public getCountLabelString(count: number, label: string) {
    return `${count}${this.getFormattedPlural(label, count)}`;
  }

  /**
   * get message from original message source
   * it might useful when displaying different locale messages mixed in
   * @param key
   * @param locale
   */
  public getMessageByKeyAndLocale(key: string, locale: string) {
    const message = messageOrigin[key][locale] || "";
    if (!message) {
      Logger.warn(`Invalid message key: ${key}, locale: ${locale}`);
    }
    return message || key;
  }
}

export default new Message();

/**
 * Truncates the text at the specified size. After truncation, an ellipsis is added.
 * @param value
 * @param size
 */
export function truncateString(value: string, size: number): string {
  const ellipsis = "...";
  return value.length > size
    ? value.substring(0, size - ellipsis.length) + ellipsis
    : value;
}
