import { useState, useEffect } from "react";

import { useWindowSize } from "./useWindowSize";

export const breakpoints = {
  sm: 744,
  md: 1024,
  lg: 1440,
};

const getBreakpoint = (width: number) => {
  if (width <= breakpoints.sm) return breakpoints.sm;
  if (width <= breakpoints.md) return breakpoints.md;
  if (width <= breakpoints.lg) return breakpoints.lg;

  return breakpoints.lg;
};

export const useBreakpoints = () => {
  const [screenSize, setScreenSize] = useState<number>(breakpoints.lg);

  const windowSize = useWindowSize();

  useEffect(() => {
    setScreenSize(getBreakpoint(windowSize.width ?? 0));
  }, [windowSize.width]);

  return screenSize;
};
