import classNames from "classnames";
import * as d3 from "d3";
import { debounce } from "lodash";
import React, { useCallback } from "react";
import shortid from "shortid";

type FunnelChartData = {
  label: string;
  count: number;
}[];

const FOOTER_HEIGHT = 72;

export function HorizontalFunnelChart({
  data,
  height: propsHeight,
  className,
}: {
  data: FunnelChartData;
  // set this to control the height of the chart
  height?: number;
  className?: string;
}) {
  const chartIdRef = React.useRef<string>(
    `horizontalFunnelChart${shortid.generate()}`,
  );
  const [{ width, height }, setSize] = React.useState({ width: 0, height: 0 });

  const updateSize = useCallback(() => {
    const { width } = (
      d3.select(`div#${chartIdRef.current}`).node() as Element
    ).getBoundingClientRect();

    setSize({
      width,
      height: propsHeight ?? width / 2.3 + FOOTER_HEIGHT,
    });
  }, [propsHeight]);

  React.useEffect(() => {
    updateSize();
  }, [updateSize]);

  React.useEffect(() => {
    const handleResize = debounce(updateSize, 300);

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [updateSize]);

  React.useEffect(() => {
    if (data.length === 0 || width === 0 || height === 0) return;

    const adjustedHeight = height - FOOTER_HEIGHT;

    const maxCount = data.length > 0 ? data[0]?.count : 0;
    const sections = data.map((d, i) => {
      const startX = (width / data.length) * i;
      const endX = (width / data.length) * (i + 1);
      const startY = adjustedHeight - (d.count / maxCount) * adjustedHeight;
      const endY =
        i == data.length - 1
          ? startY
          : adjustedHeight - (data[i + 1].count / maxCount) * adjustedHeight;

      return {
        startX,
        endX,
        startY,
        endY,
      };
    });

    d3.select(`div#${chartIdRef.current}`).selectAll("svg").remove();

    const svg = d3
      .select(`div#${chartIdRef.current}`)
      .append("svg")
      .attr("width", `${width}`)
      .attr("height", `${height}`)
      .classed("svg-content", true);

    sections.forEach(({ startX, startY, endX, endY }, idx) => {
      const funnelSection = svg.append("g").attr("x", startX).attr("y", startY);

      // Render funnel path
      const path = d3.path();
      path.moveTo(startX, adjustedHeight);
      path.lineTo(startX, startY);
      path.lineTo(endX, endY);
      path.lineTo(endX, adjustedHeight);
      path.closePath();

      funnelSection
        .append("path")
        .attr("d", path.toString())
        .classed("funnel-section", true);

      // Add labels
      const labelGroup = funnelSection
        .append("text")
        .attr("dominant-baseline", "hanging")
        .attr("x", startX)
        .attr("y", adjustedHeight + 16); // 16px gap between the funnel and labels

      // converting back to Number to get rid of the trailing zeros
      const percentage = Number(
        ((data[idx].count / maxCount) * 100).toFixed(2),
      );

      // Add percentage and count in a line
      labelGroup
        .append("tspan")
        .text(`${percentage}% `)
        .classed("label-percentage", true);
      labelGroup
        .append("tspan")
        .text(`(${data[idx].count})`)
        .attr("x", startX)
        .attr("dy", "1.8em")
        .classed("label-count", true);

      // Add the name of the data point
      let labelName = labelGroup
        .append("tspan")
        .text(data[idx].label)
        .attr("x", startX)
        .attr("dy", "1.4em")
        .classed("label-name", true);

      // Trim label and append ellipsis if it's too long
      let numTrimmedChars = 0;
      const textMaxWidth = endX - startX - 2;

      while (
        (labelName.node() as SVGTSpanElement).getComputedTextLength() >
          textMaxWidth &&
        numTrimmedChars < data[idx].label.length
      ) {
        numTrimmedChars -= 1;
        labelName = labelName.text(
          `${data[idx].label.slice(0, numTrimmedChars)}...`,
        );
      }
    });
  }, [data, height, width]);

  const rootStyle = classNames("code-c-horizontal-funnel-chart", className);

  return <div id={chartIdRef.current} className={rootStyle}></div>;
}
