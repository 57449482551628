import { connect } from "react-redux";
import { Action, Dispatch } from "redux";

import { challengeListGetAction } from "@actions";

import { RootState } from "@reducers";

import { useChallengePinList } from "@shared/hooks/query";
import { PaginationModel } from "@shared/models";
import { SortOrderItem } from "@shared/models";
import { getChallengeListSortDirection } from "@shared/services/challengeCollection";
import { getTierAllowedByRedux } from "@shared/services/tier";
import { withCustomHook } from "@shared/services/withCustomHook";

import ChallengeSelect, {
  ChallengeSelectProps,
  ExternalProps,
} from "./ChallengeSelect";

const wrapper = withCustomHook((props: ChallengeSelectProps) => {
  const { options } = props;
  const { data: challengePinList } = useChallengePinList({
    disabled: Boolean(options?.hidePin),
  });

  return {
    pinnedChallengeList: challengePinList?.challengeFavorites,
  };
})(ChallengeSelect);

const mapStateToProps = (state: RootState, props: ExternalProps) => {
  return {
    challengeList: state.challenge.challengeList,
    pagination: state.challenge.challengeListPagination,
    challengeFilters: state.challenge.challengeFilters,
    pinnedChallengeList: state.challenge.pinnedChallengeList,
    loading: Boolean(state.challenge.loadingList),
    isTierActionAllowed: getTierAllowedByRedux(state),
    canEditPin:
      state.user.user?.canEditPin(state.project.currentProjectId) ?? false,
    ...props,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  getChallenges: (
    pagination: PaginationModel,
    filters: {},
    sortOrder: SortOrderItem,
  ) => {
    dispatch(
      challengeListGetAction.request({
        ...pagination,
        ...filters,
        "sortOrder.column": sortOrder.column,
        "sortOrder.direction": getChallengeListSortDirection(
          sortOrder.column,
          sortOrder.direction,
        ),
      }),
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(wrapper);
