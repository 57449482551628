import SubmissionModel, {
  Challenge,
  SubmissionCheatMonitoring,
  SubmissionOverlappingIp,
} from "@shared/models/Submission.model";

import { SuspiciousEvent, SignificantCounts } from "./cheatMonitorTypes";

interface ChallengeTitle {
  id: number;
  title: string;
}

export const calculateTotalSignificantExamCount = (
  forExam?: SubmissionCheatMonitoring["forExam"],
) => {
  const totalSignificantExamCount = forExam
    ? Object.values(forExam).filter((event) => event.isSignificant).length
    : 0;
  return totalSignificantExamCount;
};

export const calculateSignificantChallengeCount = (
  forChallenges?: SubmissionCheatMonitoring["forChallenges"],
) => {
  return forChallenges
    ? forChallenges?.reduce(
        (acc, challenge) => {
          if (challenge.copyPaste?.isSignificant) {
            acc.copyPaste += 1;
          }
          if (challenge.leftTab?.isSignificant) {
            acc.leftTab += 1;
          }
          if (challenge.screenshot?.isSignificant) {
            acc.screenshot += 1;
          }

          return acc;
        },
        { copyPaste: 0, leftTab: 0, screenshot: 0 },
      )
    : { copyPaste: 0, leftTab: 0, screenshot: 0 };
};

export const calculateTotalOverlappingIpActivityCount = (
  overlappingIpAddresses?: SubmissionModel["overlappingIpAddresses"],
) => {
  return overlappingIpAddresses?.overlappingApplicantExams === undefined ||
    overlappingIpAddresses?.overlappingApplicantExams?.length === 0
    ? 0
    : 1;
};

// Utility function to map a challenge to a significant event
export const mapChallengeToEvent = (
  challenge: Challenge,
  examChallenges: ChallengeTitle[],
): SuspiciousEvent => {
  return {
    examChallengeId: challenge.examChallengeId,
    examChallengeTitle:
      examChallenges?.find((ec) => ec.id === challenge.examChallengeId)
        ?.title || "CHALLENGE NOT FOUND",
    isSignificantCopyPaste: challenge.copyPaste?.isSignificant,
    isSignificantLeftTab: challenge.leftTab?.isSignificant,
    isSignificantScreenshot: challenge.screenshot?.isSignificant,
    isSignificant:
      challenge.copyPaste?.isSignificant ||
      challenge.leftTab?.isSignificant ||
      challenge.screenshot?.isSignificant,
  };
};

// Utility function to filter significant events
export const filterSignificantEvents = (
  events: SuspiciousEvent[],
): SuspiciousEvent[] => {
  return events.filter((event) => event.isSignificant);
};

// Main function to get significant events
export const getSignificantEvents = (
  cheatMonitoring: SubmissionCheatMonitoring,
  examChallenges: ChallengeTitle[],
): SuspiciousEvent[] => {
  if (!cheatMonitoring || !cheatMonitoring.forChallenges) {
    return [];
  }

  const significantEvents = cheatMonitoring.forChallenges.map((challenge) =>
    mapChallengeToEvent(challenge, examChallenges),
  );

  return filterSignificantEvents(significantEvents);
};

export const getSignificantIssueTotals = (
  cheatMonitoring: SubmissionCheatMonitoring,
  overlappingIpAddresses: SubmissionOverlappingIp | undefined,
): SignificantCounts => {
  const totalSignificantExamCount = calculateTotalSignificantExamCount(
    cheatMonitoring.forExam,
  );

  const significantChallengeCounts = calculateSignificantChallengeCount(
    cheatMonitoring.forChallenges,
  );

  const totalSignificantChallengeCount = [
    ...Object.values(significantChallengeCounts),
  ].filter((count) => count > 0).length;

  const totalOverlappingIpActivityCount =
    calculateTotalOverlappingIpActivityCount(overlappingIpAddresses);

  const totalSignificantCount =
    totalSignificantExamCount +
    totalSignificantChallengeCount +
    totalOverlappingIpActivityCount;

  return {
    total: totalSignificantCount,
    counts: {
      ...significantChallengeCounts,
      sessionData: cheatMonitoring.forExam?.sessionData?.isSignificant
        ? cheatMonitoring.forExam?.sessionData?.uniqueSessionCount
        : 0,
      multiSubmission:
        overlappingIpAddresses?.overlappingApplicantExams?.length || 0,
    },
  };
};
