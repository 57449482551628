import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { putExamChallenge } from "@api/exams";

import { useStoreContext } from "@context";

import { ExamChallengeSetModel } from "@shared/models";
import { scrubExamChallengeUpdatePayload } from "@shared/services/exam";
import message from "@shared/services/message";

import { examKeys } from "./useExam";

interface UpdateExamChallengeParam {
  examId: number;
  payload: ExamChallengeSetModel[];
}

export function useUpdateExamChallenge() {
  const client = useQueryClient();
  const { projectId } = useStoreContext();

  const mutation = useMutation({
    mutationFn: ({ examId, payload }: UpdateExamChallengeParam) => {
      const challengesSets = scrubExamChallengeUpdatePayload(payload);

      return putExamChallenge({
        examId,
        projectId,
        options: { data: { challengesSets } },
      });
    },
    onSuccess: (_, { examId }) => {
      toast.success(message.getMessageByKey("message.exam.updated"));
      client.invalidateQueries(examKeys.detail(projectId, examId));
    },
  });

  return mutation;
}
