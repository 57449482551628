import { AxiosRequestConfig } from "axios";

import { get } from "@api/httpClient";

import { LocalFileMetaData } from "@shared/models";

interface GuestSubmissionResultLocalFilesMetaDataParams {
  token: string;
  challengeResultId: number;
  options?: AxiosRequestConfig;
}

export async function getGuestSubmissionResultLocalFilesMetadata({
  token,
  challengeResultId,
  options,
}: GuestSubmissionResultLocalFilesMetaDataParams) {
  const { data } = await get<LocalFileMetaData[]>(
    `/api/sharing/${token}/results/${challengeResultId}/localfiles/metadata`,
    options,
  );

  return data;
}

export async function getGuestSubmissionResultLocalFile({
  token,
  challengeResultId,
  options,
}: GuestSubmissionResultLocalFilesMetaDataParams) {
  const { data } = await get<string>(
    `/api/sharing/${token}/results/${challengeResultId}/localfiles/download/presigned`,
    options,
  );

  return data;
}
