import * as classnames from "classnames";

/**
 * Prop interface
 */
export interface TableHeadProps {
  className?: string;
  children?: {};
}

/**
 * React Component
 */
export default function TableHead({ className, children }: TableHeadProps) {
  const rootStyle = classnames("code-c-table-head", {
    [`${className}`]: Boolean(className),
  });

  return (
    <thead className={rootStyle}>
      <tr>{children}</tr>
    </thead>
  );
}
