import {
  APIResponseAction,
  Action,
  applicantActionLogGetAllAction,
} from "@actions";

import { IApplicantActionLog, PaginationModel } from "@shared/models";

export interface ActionLogState {
  applicantActionLogList: IApplicantActionLog[];
  applicantActionLogListChunk: IApplicantActionLog[];
  isActionLogListLoading: boolean;
  pagination: PaginationModel;
}

export const initialState: ActionLogState = {
  applicantActionLogList: [],
  applicantActionLogListChunk: [],
  isActionLogListLoading: false,
  pagination: new PaginationModel({ limit: 20, includePrevPageLastRow: true }),
};

export const actionLogReducer = (
  state: ActionLogState = initialState,
  action: Action,
): ActionLogState => {
  switch (action.type) {
    case applicantActionLogGetAllAction.types.request: {
      return {
        ...state,
        isActionLogListLoading: true,
        applicantActionLogList: [],
      };
    }

    case applicantActionLogGetAllAction.types.success: {
      const { result, pagination } = (
        action as APIResponseAction & {
          payload: {
            result: IApplicantActionLog[];
            pagination: PaginationModel;
          };
        }
      ).payload;

      const { count, offset, limit } = pagination;
      const hasNextPage = count > offset + limit;
      const chunk = [...state.applicantActionLogListChunk, ...result];

      return {
        ...state,
        ...(hasNextPage
          ? {
              applicantActionLogListChunk: chunk,
            }
          : {
              applicantActionLogListChunk: [],
              applicantActionLogList: chunk,
              isActionLogListLoading: false,
            }),
      };
    }

    default:
      return state;
  }
};
