import classnames from "classnames";
import { cloneDeep, isEqual } from "lodash";
import * as React from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { Prompt } from "react-router";

import {
  ALL_REVIEWERS_OPTION,
  ExamReviewersSearchForm,
} from "@components/orgs/exams/examSections/examReviewers/ExamReviewers";
import { ExamReviewersHeader } from "@components/orgs/exams/examSections/examReviewers/partials/ExamReviewersHeader";

import { Msg } from "@shared/components";
import { useInvitationStatus } from "@shared/hooks/query";
import { MemberListModel, ExamModel, PaginationModel } from "@shared/models";
import { getDisplayInvitationStatus } from "@shared/services/member";
import MSG from "@shared/services/message";

import { ExamReviewers, ExamDetailHeader } from "../../../examSections";
import { ExamEditSaveArea } from "../examEditSaveArea/ExamEditSaveArea";

/**
 * Types and Interfaces
 */
interface FormValues {
  checkAll: boolean;
  selectedReviewers: Record<number, boolean>;
  shareReviewsWithReviewers: boolean;
  requiredReviewerCount: string;
}

export interface ExamDetailReviewersProps {
  examDetail: ExamModel;
  projectMembers: MemberListModel[];
  examDetailReviewers?: MemberListModel[];
  projectMemberListLoading?: boolean;
  canReview?: boolean;
  getProjectMembers: (pagination?: PaginationModel, keyword?: string) => void;
  updateExamReviewers?: (payload: {
    reviewers: number[];
    shareReviewsWithReviewers: boolean;
    requiredReviews: ExamModel["reviewSettings"]["requiredReviews"];
  }) => void;
}

interface FormState {
  isDirty: boolean;
  reviewFormValid: boolean;
  formValues: FormValues;
  oldFormValues: FormValues;
}

export default function ExamDetailReviewers({
  canReview,
  examDetail,
  examDetailReviewers,
  projectMemberListLoading,
  projectMembers,
  getProjectMembers,
  updateExamReviewers,
}: ExamDetailReviewersProps) {
  const invitationStatus = useInvitationStatus();
  const [keyword, setKeyword] = React.useState("");
  const [editing, setEditing] = React.useState(false);

  const rootStyle = classnames("code-exam-edit__detail-reviewers", {
    "code-exam-detail-is-editing": editing,
  });

  const requiredReviewerCount =
    examDetail.reviewSettings.requiredReviews.requiredType === "all"
      ? ALL_REVIEWERS_OPTION
      : `${examDetail.reviewSettings.requiredReviews.count}`;

  const [form, setForm] = React.useState<FormState>({
    isDirty: false,
    reviewFormValid: false,
    formValues: {
      checkAll: false,
      selectedReviewers: {},
      shareReviewsWithReviewers: examDetail.shareReviewsWithReviewers,
      requiredReviewerCount,
    },
    oldFormValues: {
      checkAll: false,
      selectedReviewers: {},
      shareReviewsWithReviewers: examDetail.shareReviewsWithReviewers,
      requiredReviewerCount,
    },
  });
  const reviewers = React.useMemo(() => {
    let normalizedReviewers = examDetailReviewers || [];

    // TODO remove duplicates with new Set()
    if (editing) {
      if (normalizedReviewers?.length === 0) {
        normalizedReviewers = projectMembers;
      } else {
        normalizedReviewers = [
          ...normalizedReviewers,
          ...projectMembers.filter(
            ({ id }) =>
              examDetailReviewers?.findIndex((r) => r.id === id) === -1,
          ),
        ];
      }
    }

    if (keyword) {
      const incaseKeyword = keyword.toLowerCase();
      normalizedReviewers = normalizedReviewers.filter(
        ({ email, name }) =>
          email.toLocaleLowerCase().includes(incaseKeyword) ||
          name.toLocaleLowerCase().includes(incaseKeyword),
      );
    }

    // Remove this reduce once reviewer had been normalized from data layer
    return normalizedReviewers.reduce((acc, reviewer) => {
      acc.push(
        new MemberListModel({
          ...getDisplayInvitationStatus({ invitationStatus, reviewer }),
        }),
      );
      return acc;
    }, [] as MemberListModel[]);
  }, [editing, examDetailReviewers, invitationStatus, keyword, projectMembers]);

  React.useEffect(() => {
    const selectedReviewersMap =
      examDetailReviewers?.reduce(
        (acc, { id }) => {
          acc[id] = true;
          return acc;
        },
        {} as Record<number, boolean>,
      ) ?? [];

    setForm((prev) => {
      const state = cloneDeep(prev);

      state.formValues.selectedReviewers = projectMembers.reduce(
        (acc, { id }) => {
          acc[id] = selectedReviewersMap[id] ?? false;
          return acc;
        },
        { ...selectedReviewersMap },
      );
      state.oldFormValues.selectedReviewers = {
        ...state.formValues.selectedReviewers,
      };

      return state;
    });
  }, [examDetailReviewers, projectMembers]);

  React.useEffect(() => {
    if (!editing) {
      return;
    }

    setForm((prev) => {
      const state = {
        ...prev,
        formValues: cloneDeep(prev.formValues),
      };

      state.formValues.checkAll =
        (Boolean(reviewers?.length) &&
          reviewers?.every(
            ({ id }) => state.formValues.selectedReviewers[id],
          )) ||
        false;

      return state;
    });
  }, [editing, keyword, reviewers]);

  const onCancel = () => {
    setEditing(false);
    setKeyword("");
    setForm((prev) => ({
      ...prev,
      formValues: cloneDeep(prev.oldFormValues),
    }));
  };

  const onClickEdit = () => {
    if (!editing) {
      getProjectMembers();
      setEditing(true);
      setForm((prev) => ({
        ...prev,
        oldFormValues: cloneDeep(prev.formValues),
      }));

      return;
    }

    if (typeof updateExamReviewers === "function") {
      const {
        selectedReviewers,
        shareReviewsWithReviewers,
        requiredReviewerCount,
      } = form.formValues;

      const reviewers = Object.keys(selectedReviewers)
        .filter((key) => selectedReviewers[key])
        .map((key) => parseInt(key, 10));

      const requiredReviews =
        requiredReviewerCount === ALL_REVIEWERS_OPTION
          ? {
              requiredType: "all" as const,
            }
          : {
              requiredType: "count" as const,
              count: +requiredReviewerCount,
            };

      setEditing(false);
      setKeyword("");
      updateExamReviewers({
        reviewers,
        shareReviewsWithReviewers,
        requiredReviews,
      });
    }
  };

  const onRowClick = (reviewer: MemberListModel) => {
    setForm((prev) => {
      const state = { ...prev };
      state.formValues.selectedReviewers[reviewer.id] =
        !state.formValues.selectedReviewers[reviewer.id];
      state.isDirty = !isEqual(state.formValues, state.oldFormValues);
      state.formValues.checkAll =
        reviewers?.every(({ id }) =>
          Boolean(state.formValues.selectedReviewers[id]),
        ) || false;

      return state;
    });
  };

  const onToggleAllCheck = () => {
    setForm((prev) => {
      const state = { ...prev };
      const checkAll = !state.formValues.checkAll;
      state.formValues.checkAll = checkAll;

      if (reviewers) {
        reviewers.forEach(
          (r) => (state.formValues.selectedReviewers[r.id] = checkAll),
        );
      }
      state.isDirty = !isEqual(state.formValues, state.oldFormValues);

      return state;
    });
  };

  const onSettingsChange = (params: {
    share: boolean;
    requiredReviewerCount: string;
    formValid: boolean;
  }) => {
    setForm((oldForm) => {
      const newFormValues = {
        ...oldForm.formValues,
        shareReviewsWithReviewers: params.share,
        requiredReviewerCount: params.requiredReviewerCount,
      };

      return {
        ...oldForm,
        formValues: newFormValues,
        isDirty: !isEqual(oldForm.oldFormValues, newFormValues),
        reviewFormValid: params.formValid,
      };
    });
  };

  return (
    <div className={rootStyle}>
      <BreadcrumbsItem
        to={`/p/${examDetail.projectId}/exams/${examDetail.id}/reviewers`}
      >
        <Msg id="exam.reviewers" />
      </BreadcrumbsItem>
      <Prompt
        when={editing}
        message={MSG.getMessageByKey("navigation.confirm")}
      />
      <ExamDetailHeader
        archived={Boolean(examDetail && examDetail.isArchived())}
        editing={editing}
        editDisabled={!canReview}
        editTooltipText={<Msg id={"tier.disabled"} />}
        onClickCancel={onCancel}
        onClickEdit={onClickEdit}
        leftElement={
          <ExamReviewersHeader
            hideSearchField={!editing}
            keyword={keyword}
            onTextChanged={(
              formValid: boolean,
              formValues: ExamReviewersSearchForm,
            ) => {
              if (formValid) {
                setKeyword(formValues.keyword);
              }
            }}
            examId={examDetail.id}
          />
        }
      />
      <ExamReviewers
        hideTitle
        projectMemberListLoading={projectMemberListLoading}
        readOnly={!editing}
        checkAll={form.formValues.checkAll}
        canReview={canReview}
        reviewers={reviewers}
        resetForm={editing}
        initialValues={form.formValues}
        keyword={keyword}
        onRowClick={onRowClick}
        onToggleAllCheck={onToggleAllCheck}
        onSettingsChange={onSettingsChange}
        setKeyword={setKeyword}
      />
      {editing && (
        <ExamEditSaveArea
          disabled={!editing || !form.isDirty || !form.reviewFormValid}
          onSaveClick={onClickEdit}
        />
      )}
    </div>
  );
}
