import { connect } from "react-redux";
import { Action, Dispatch } from "redux";

import { challengeGetAction } from "@actions";

import { RootState } from "@reducers";

import { useOfficialExam } from "@shared/hooks/query";
import { getAppBasePath } from "@shared/selectors";
import { getTierAllowedByRedux } from "@shared/services/tier";
import { withCustomHook } from "@shared/services/withCustomHook";

import OfficialExamPanel, { ExternalProps } from "./OfficialExamPanel";

const wrapper = withCustomHook(({ examId }: ExternalProps) => {
  const { data: examDetail, isFetching: loadingExam } = useOfficialExam(examId);

  return { examDetail, loadingExam };
})(OfficialExamPanel);

const mapStateToProps = (state: RootState, props: ExternalProps) => ({
  appBasePath: getAppBasePath(state),
  challengeDetailList: state.challenge.challengeDetailList,
  currentProjectId: state.project.currentProjectId,
  examCreateOptions: state.exam.examCreateOptions,
  loadingChallenge: state.challenge.loadingDetail,
  loadingReadme: state.readme.loadingReadme,
  readmeList: state.readme.readmeList,
  submitting: Boolean(state.ajax.submitting),
  isTierActionAllowed: getTierAllowedByRedux(state),
  ...props,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  getChallengeDetail: (payload: {}) => {
    dispatch(challengeGetAction.request(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(wrapper);
