import {
  ContentsSearchForm,
  DifficultyTag,
  New,
  LinkedTag,
  Msg,
} from "@shared/components";
import { ChallengeFilterModel } from "@shared/models";
import {
  ChallengeCategory,
  ChallengeStyle,
  Difficulty,
  TierAction,
} from "@shared/services/enums";
import Message from "@shared/services/message";

import { ChallengesListFormValues } from "../challengeList/ChallengeList";
import { ChallengeSelectForm } from "../challengeSelect/ChallengeSelect";

/**
 * Prop interface
 */
export interface ExternalProps {
  formValues: ChallengesListFormValues | ChallengeSelectForm;
  challengeFilters: ChallengeFilterModel;
  clear?: boolean;
  disableOptionKeys?: string[];
  onFormChange: (formValid: boolean, formValues: {}, formErrors: {}) => void;
}

interface InjectedProps {
  isTierActionAllowed: (tierAction: TierAction) => boolean;
}

type ChallengeFilterProps = ExternalProps & InjectedProps;

/**
 * State interface
 */

const ChallengeFilter = ({
  formValues,
  challengeFilters,
  clear,
  disableOptionKeys,
  onFormChange,
  isTierActionAllowed,
}: ChallengeFilterProps) => (
  <ContentsSearchForm
    onChange={onFormChange}
    initialValues={formValues}
    clear={clear}
    keywordHint={<Msg id="challenge.keyword.hint" />}
    disableOptionKeys={disableOptionKeys}
    filters={[
      {
        title: <Msg id="challenge.filter.project" />,
        value: "onlyPinned",
        options: {
          value: true,
          label: <Msg id="challenge.filter.pinned" />,
          count: 0,
        },
      },
      {
        title: <Msg id="challenge.styles" />,
        value: "styles",
        options: challengeFilters.styles.map((filter) => {
          const disabled =
            (filter.value === ChallengeStyle.AI &&
              !isTierActionAllowed(TierAction.AIChallengeUsage)) ||
            (filter.value === ChallengeStyle.Development &&
              !isTierActionAllowed(TierAction.DevelopmentChallengeUsage));

          return {
            value: filter.value,
            label: <Msg id={filter.messageKey} />,
            count: 0,
            disabled,
            tooltip: disabled ? Message.getMessageByKey("tier.disabled") : "",
          };
        }),
      },
      {
        title: <Msg id="challenge.difficulties" />,
        value: "difficulties",
        options: challengeFilters.difficulties.map((filter) => {
          const disabled =
            filter.value !== Difficulty.Easy &&
            !isTierActionAllowed(TierAction.ChallengeDifficultySetting);

          return {
            value: filter.value,
            label: <DifficultyTag value={filter.value} />,
            count: 0,
            disabled,
            tooltip: disabled ? Message.getMessageByKey("tier.disabled") : "",
          };
        }),
      },
      {
        title: <Msg id="common.categories" />,
        value: "categories",
        options: [
          {
            value: ChallengeCategory.Official,
            label: ChallengeCategory.toString(ChallengeCategory.Official),
            count: 0,
          },
          {
            value: ChallengeCategory.Custom,
            label: ChallengeCategory.toString(ChallengeCategory.Custom),
            count: 0,
          },
        ].map((filter) => {
          const disabled =
            filter.value === ChallengeCategory.Custom &&
            !isTierActionAllowed(TierAction.CustomCodingUsage) &&
            !isTierActionAllowed(TierAction.CustomQuizUsage);

          return {
            ...filter,
            disabled,
            tooltip: disabled ? Message.getMessageByKey("tier.disabled") : "",
          };
        }),
      },
      {
        title: <Msg id="common.language" />,
        value: "spokenLanguages",
        optionKind: "radio",
        options: [
          {
            value: "en",
            label: <Msg id="common.language.english" />,
            count: 0,
          },
          {
            value: "ja",
            label: <Msg id="common.language.japanese" />,
            count: 0,
          },
        ],
      },
      {
        title: <Msg id="challenge.linked" />,
        value: "isLinked",
        options: {
          value: true,
          label: <LinkedTag />,
          count: 0,
        },
      },
      {
        title: <Msg id="common.new" />,
        value: "onlyNew",
        options: {
          value: true,
          label: <New />,
          count: 0,
        },
      },
      {
        title: <Msg id="programmingCategories" />,
        value: "programmingCategories",
        options: challengeFilters.programmingCategories.map((filter) => ({
          value: filter.value,
          label: filter.displayString,
          count: 0,
        })),
        moreLink: true,
      },
      {
        title: <Msg id="programmingLanguages" />,
        value: "programmingLanguages",
        options: challengeFilters.programmingLanguages.map((filter) => ({
          value: filter.value,
          label: filter.displayString,
          count: 0,
        })),
        moreLink: true,
      },
    ]}
  />
);

export default ChallengeFilter;
