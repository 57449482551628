import * as classnames from "classnames";
import { isEqual } from "lodash";
import * as React from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";

import {
  Block,
  LeftBlock,
  RightBlock,
  Button,
  Table,
  TableHead,
  TableBody,
  SortableText,
  Row,
  Column,
  HeaderColumn,
  Icon,
  Pagination,
  MemberTitleColumn,
  Modal,
  Tooltip,
  Loading,
  Restricted,
  Msg,
  Input,
  PageTitle,
  Select,
  SelectItem,
} from "@shared/components";
import {
  PaginationModel,
  MemberListModel,
  UserModel,
  ProjectSwitchItemModel,
} from "@shared/models";
import { SortOrderItem } from "@shared/models";
import { formatDateTimeMinutes } from "@shared/services/date";
import {
  UserStatus,
  ProjectRole,
  MemberEditType,
  SortDirection,
} from "@shared/services/enums";
import Message from "@shared/services/message";
import { updateUrl, parseUrl } from "@shared/services/queryString";
import * as urlParser from "@shared/services/urlParser";

import MemberRoleEdit from "../../../../members/memberRoleEdit/MemberRoleEdit.connect";

/**
 * Prop interface
 */
export interface SettingMemberProps {
  currentUser: UserModel;
  status: string;
  projectId: number;
  members: Array<MemberListModel>;
  pagination: PaginationModel;
  projectMemberListLoading: boolean;
  updatingUserLoading: boolean;
  updatingUserError: boolean;
  inviteMemberLoading: boolean;

  inviteMember: (projectId: number, params?: {}) => void;
  getMembers: (params: {
    pagination: PaginationModel;
    sortOrder: SortOrderItem;
    keyword?: string;
    roles?: ProjectRole[];
  }) => void;
  deleteMember: (projectId: number, userId: number, params?: {}) => void;
  updateMember: (userId: number, params?: {}) => void;
  unDeleteMember: (payload?: {}) => void;
}

/**
 * State interface
 */
export interface SettingMemberState {
  memberModalType?: MemberEditType;
  isOpenMemberEdit: boolean;
  isOpenImport: boolean;
  selectedMember?: MemberListModel;
  sortItem: SortOrderItem;
  confirmCancel: boolean;
  confirmReactivate: boolean;
  keyword: string;
  roles: number[];
}

enum UserStatusFromText {
  "active" = UserStatus.Normal,
  "pending" = UserStatus.Pending,
  "removed" = UserStatus.Removed,
}

const ALL_ROLES_OPTION = 0;

/**
 * Page component
 */
class SettingMember extends React.Component<
  SettingMemberProps,
  SettingMemberState
> {
  constructor(props: SettingMemberProps) {
    super(props);

    this.state = {
      isOpenMemberEdit: false,
      isOpenImport: false,
      confirmCancel: false,
      confirmReactivate: false,
      sortItem: {
        column: "name",
        direction: SortDirection.Asc,
      },
      keyword: "",
      roles: [ALL_ROLES_OPTION],
    };
  }

  public componentDidMount() {
    this.getMemberFromURL();
  }

  private getFilteredMembers(members: MemberListModel[]) {
    return members.filter(
      (member: MemberListModel) =>
        member.status === UserStatusFromText[this.props.status] &&
        (this.state.roles[0] === ALL_ROLES_OPTION ||
          member.roles.some((memberRole) =>
            this.state.roles.includes(memberRole.role),
          )),
    );
  }

  public componentDidUpdate(prevProps: SettingMemberProps) {
    const { updatingUserLoading: updating } = prevProps;
    const { updatingUserError, updatingUserLoading, members, pagination } =
      this.props;
    if (updating && !updatingUserLoading && !updatingUserError) {
      this.onCancel();
    }

    if (
      pagination.currentPage > 0 &&
      this.getFilteredMembers(members).length === 0
    ) {
      this.handlePageChange({ selected: pagination.currentPage - 1 });
    }
  }

  public shouldComponentUpdate(
    nextProps: SettingMemberProps,
    nextState: SettingMemberState,
  ) {
    return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state);
  }

  public render() {
    const rootStyle = classnames("code-setting-member");
    const { inviteMemberLoading } = this.props;

    const projectRoleOptions: SelectItem[] = [
      {
        label: Message.getMessageByKey("allRoles"),
        value: ALL_ROLES_OPTION,
      },
      ...[
        ProjectRole.ProjectAdmin,
        ProjectRole.ExamCreator,
        ProjectRole.ExamDeliverer,
        ProjectRole.Reviewer,
        ProjectRole.ChallengeCreator,
      ].map((projectRole) => ({
        label: ProjectRole.toString(projectRole),
        value: projectRole,
      })),
    ];

    const members = this.getFilteredMembers(this.props.members).map(
      (member: MemberListModel, index: number) => {
        const actions =
          member.status === UserStatus.Normal ? (
            <div className="code-setting-member__actions">
              <Restricted
                roles={[ProjectRole.ProjectAdmin]}
                strictAllow={this.props.currentUser.id !== member.id}
              >
                <Tooltip
                  text={Message.getMessageByKey("edit")}
                  placement="top-end"
                >
                  <Button
                    onClick={() => this.onOpenEditMember(member)}
                    shrink={true}
                    size={"small"}
                    ariaLabel="Edit"
                  >
                    <Icon type="pencil" />
                  </Button>
                </Tooltip>
              </Restricted>
              <Restricted
                roles={[ProjectRole.ProjectAdmin]}
                strictAllow={this.props.currentUser.id !== member.id}
              >
                <Tooltip
                  text={Message.getMessageByKey("delete")}
                  placement="top-end"
                >
                  <Button
                    onClick={() =>
                      this.setState({
                        confirmCancel: true,
                        selectedMember: member,
                      })
                    }
                    shrink={true}
                    size={"small"}
                    ariaLabel="Delete"
                  >
                    <Icon type="trash" />
                  </Button>
                </Tooltip>
              </Restricted>
            </div>
          ) : (
            <div className="code-setting-member__actions">
              <Tooltip
                text={Message.getMessageByKey("activation")}
                placement="top-end"
              >
                <Button
                  onClick={() =>
                    this.setState({
                      confirmReactivate: true,
                      selectedMember: member,
                    })
                  }
                  shrink={true}
                  size={"small"}
                  ariaLabel="Activation"
                >
                  <Icon type="recycle" />
                </Button>
              </Tooltip>
            </div>
          );
        return (
          <Row key={member.id}>
            <Column>
              <MemberTitleColumn member={member} />
            </Column>
            <Column>
              {member.getRoleLabel(this.props.projectId).map((role: string) => (
                <div key={role}>{role}</div>
              ))}
            </Column>
            <Column>{formatDateTimeMinutes(member.createdAt)}</Column>
            <Column>{formatDateTimeMinutes(member.lastLogin)}</Column>
            <Column>{actions}</Column>
          </Row>
        );
      },
    );

    return (
      <div className={rootStyle}>
        <BreadcrumbsItem to="/settings/profile/members">
          <Msg id="members" />
        </BreadcrumbsItem>
        <Block className="code-setting-member__header">
          <LeftBlock>
            <PageTitle>
              <Msg id="members" />
              <span className="code-setting-member__header__count">
                {Message.getSearchResultFormat(this.props.pagination.count)}
              </span>
            </PageTitle>
          </LeftBlock>
          <RightBlock>
            <Select
              name="role"
              options={projectRoleOptions}
              className="code-setting-member__header__role-select"
              value={this.state.roles[0].toString()}
              onChange={this.handleRoleChange}
            />
            <Input
              name="keyword"
              value={this.state.keyword}
              onChange={(e) => this.handleKeywordChange(e.currentTarget.value)}
              className="code-setting-member__header__search"
              placeholder={Message.getMessageByKey("common.emailOrName")}
            />
            <Restricted roles={[ProjectRole.ProjectAdmin]}>
              <Button
                type="primary"
                onClick={this.onOpenInvitation}
                ariaLabel="Invite New Member"
              >
                <Icon type="user-plus" />
                <span>
                  <Msg id="invite" />
                </span>
              </Button>
            </Restricted>
          </RightBlock>
        </Block>
        <div className="code-setting-member__loading-list">
          <Loading
            isOpen={this.props.projectMemberListLoading}
            fullScreen={false}
            overlay={false}
          />
        </div>
        <Table className="code-setting-member__table">
          <TableHead>
            <HeaderColumn size={6}>
              <SortableText
                name="name"
                sortItem={this.state.sortItem}
                onClick={this.handleSortChange}
                ariaLabel="Name"
              >
                <Msg id="common.name" />
              </SortableText>
            </HeaderColumn>
            <HeaderColumn size={3}>
              <Msg id="common.role" defaultMessage="Role" />
            </HeaderColumn>
            <HeaderColumn size={4}>
              <SortableText
                name="created_at"
                sortItem={this.state.sortItem}
                onClick={this.handleSortChange}
                ariaLabel="Created At"
              >
                <Msg id="createdAt" />
              </SortableText>
            </HeaderColumn>
            <HeaderColumn size={4}>
              <SortableText
                name="last_login"
                sortItem={this.state.sortItem}
                onClick={this.handleSortChange}
                ariaLabel="Last Login At"
              >
                <Msg id="lastLoginAt" defaultMessage="Last Login" />
              </SortableText>
            </HeaderColumn>
            <HeaderColumn size={2} />
          </TableHead>
          <TableBody>{members}</TableBody>
        </Table>
        <div className="code-setting-member__pagination">
          <Pagination
            pagination={this.props.pagination}
            onPageChange={this.handlePageChange}
          />
        </div>
        {this.state.isOpenMemberEdit && (
          <MemberRoleEdit
            isOpen={true}
            projectId={
              this.props.currentUser.projects.find(
                (project) => project.id === this.props.projectId,
              )?.id || ({} as ProjectSwitchItemModel).id
            }
            member={this.state.selectedMember}
            type={this.state.memberModalType}
            onCancel={this.onCancel}
            onClickOk={this.onMemberEditOk}
            loading={inviteMemberLoading}
          />
        )}
        {this.state.confirmCancel && (
          <Modal
            title={Message.getMessageByKey("member.confirmRemove")}
            isOpen={true}
            onClose={this.onCancel}
            onClickCancel={this.onCancel}
            onClickOk={this.onDeleteMember}
            okButtonAriaLabel="Delete"
            ariaLabel="Delete Member"
          >
            <Msg id="navigate.areYouSure" defaultMessage="Are you sure?" />
          </Modal>
        )}
        {this.state.confirmReactivate && (
          <Modal
            title={`Reactivate User ${
              this.state.selectedMember && this.state.selectedMember.name
            }`}
            isOpen={true}
            onClose={this.onCancel}
            onClickCancel={this.onCancel}
            onClickOk={() =>
              this.props.unDeleteMember(
                this.state.selectedMember && this.state.selectedMember.id,
              )
            }
            okButtonAriaLabel="Reactivate"
            ariaLabel="Reactivate Member"
          >
            <Msg id="navigate.areYouSure" defaultMessage="Are you sure?" />
          </Modal>
        )}
      </div>
    );
  }

  private getMemberFromURL = () => {
    const { column, direction, keyword, page, roles } = parseUrl(
      window.location.search,
    );
    const formValues = {
      keyword: urlParser.getString(keyword) || "",
    };
    const sortItem = {
      column,
      direction,
    };

    let formattedRoles = urlParser.getNumberArray(roles);

    // support only one role filter for now
    formattedRoles = formattedRoles?.[0]
      ? [formattedRoles?.[0]]
      : [ALL_ROLES_OPTION];

    // update / remove invalid roles
    if (
      formattedRoles[0] !== ALL_ROLES_OPTION &&
      !ProjectRole[formattedRoles[0]]
    ) {
      formattedRoles = [ALL_ROLES_OPTION];

      updateUrl({
        keyword: formValues.keyword,
        roles: formattedRoles,
        ...sortItem,
      });
    }

    this.setState(
      {
        keyword: formValues.keyword,
        sortItem,
        roles: formattedRoles,
      },
      () => {
        this.props.getMembers({
          pagination: this.props.pagination.getAtPage(
            urlParser.getPageNo(page),
          ),
          sortOrder: sortItem,
          keyword: formValues.keyword,
          roles:
            formattedRoles?.filter((role) => role !== ALL_ROLES_OPTION) ?? [],
        });
      },
    );
  };

  private handleSortChange = (sortItem: SortOrderItem) => {
    this.setState({
      sortItem,
    });

    updateUrl({
      keyword: this.state.keyword,
      roles: this.state.roles,
      ...sortItem,
    });

    this.props.getMembers({
      pagination: new PaginationModel(),
      sortOrder: sortItem,
      keyword: this.state.keyword,
      roles: this.getRoleParams(),
    });
  };

  private handleKeywordChange = (keyword: string) => {
    if (this.state.keyword === keyword) {
      return;
    }

    this.setState({ keyword }, () => {
      updateUrl({
        keyword: this.state.keyword,
        roles: this.state.roles,
        ...this.state.sortItem,
      });

      this.props.getMembers({
        pagination: new PaginationModel(),
        sortOrder: this.state.sortItem,
        keyword: this.state.keyword,
        roles: this.getRoleParams(),
      });
    });
  };

  private handleRoleChange = (e: React.FormEvent<HTMLSelectElement>) => {
    const newRole = Number(e.currentTarget.value);
    this.setState(
      {
        roles: [newRole],
      },
      () => {
        updateUrl({
          keyword: this.state.keyword,
          roles: this.state.roles,
          ...this.state.sortItem,
        });

        this.props.getMembers({
          pagination: new PaginationModel(),
          sortOrder: this.state.sortItem,
          keyword: this.state.keyword,
          roles: this.getRoleParams(),
        });
      },
    );
  };

  private handlePageChange = ({ selected }: { selected: number }) => {
    updateUrl({
      keyword: this.state.keyword,
      ...this.state.sortItem,
      page: selected,
      roles: this.state.roles,
    });

    this.props.getMembers({
      pagination: this.props.pagination.getAtPage(selected),
      sortOrder: this.state.sortItem,
      keyword: this.state.keyword,
      roles: this.getRoleParams(),
    });
  };

  private onOpenEditMember = (member: MemberListModel) => {
    this.setState({
      selectedMember: member,
      isOpenMemberEdit: true,
      memberModalType: MemberEditType.Edit,
    });
  };

  private onOpenInvitation = () => {
    this.setState({
      isOpenMemberEdit: true,
      memberModalType: MemberEditType.New,
    });
  };

  private onMemberEditOk = (payload: number, params?: {}) => {
    if (
      this.state.memberModalType === MemberEditType.Edit &&
      this.state.selectedMember
    ) {
      this.props.updateMember(payload, params);
    } else {
      this.props.inviteMember(payload, params);
    }
  };

  private onCancel = () => {
    this.setState({
      isOpenMemberEdit: false,
      memberModalType: undefined,
      selectedMember: undefined,
      confirmCancel: false,
      confirmReactivate: false,
    });
  };

  private onDeleteMember = (params?: {}) => {
    const { selectedMember } = this.state;
    if (selectedMember) {
      this.props.deleteMember(this.props.projectId, selectedMember.id, {
        pagination: new PaginationModel(),
        sortOrder: this.state.sortItem,
        keyword: this.state.keyword,
        roles: this.getRoleParams(),
      });
      this.onCancel();
    }
  };

  private getRoleParams = () => {
    return this.state.roles.includes(ALL_ROLES_OPTION) ? [] : this.state.roles;
  };
}

export default SettingMember;
