import { connect } from "react-redux";
import { Action, Dispatch } from "redux";

import { RootState } from "@reducers";

import { DeliveryCancelModal, ExternalProps } from "./DeliveryCancelModal";

const mapStateToProps = (state: RootState, props: ExternalProps) => ({
  ...props,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DeliveryCancelModal);
