import * as classnames from "classnames";
import * as React from "react";

import {
  Msg,
  Restricted,
  Button,
  Icon,
  JumpTo,
  Tooltip,
  PageTitle,
  ExamSegmentTags,
} from "@shared/components";
import { useUserRoles } from "@shared/hooks";
import { ProjectRole, UserRole } from "@shared/services/enums";

/**
 * Props
 */
export interface ExamDetailHeaderProps {
  editing: boolean;
  archived: boolean;
  hasPrint?: boolean;
  editDisabled?: boolean;
  editTooltipText?: React.ReactNode;
  title?: React.ReactNode;
  examId?: number;
  leftElement?: React.ReactNode;
  onClickCancel?: () => void;
  onClickEdit?: () => void;
}

export const ExamDetailHeader: React.FunctionComponent<
  ExamDetailHeaderProps
> = ({
  archived,
  editing,
  hasPrint,
  editDisabled,
  editTooltipText,
  title,
  examId,
  leftElement,
  onClickCancel,
  onClickEdit,
}: ExamDetailHeaderProps) => {
  const rootStyle = classnames("code-exam-detail-header");

  const { isExamDeliverer, hasMultipleRoles } = useUserRoles();

  const hasOnlyExamDelivererRole = isExamDeliverer && !hasMultipleRoles;

  return (
    <>
      <ExamSegmentTags />
      <div className={rootStyle}>
        <div className="code-exam-detail-header__left-element">
          {title && (
            <PageTitle>
              {title}
              {examId && (
                <small className="code-exam-detail__exam_id">
                  <Msg id={"form.exam.id"} />: {examId}
                </small>
              )}
            </PageTitle>
          )}
          {leftElement}
        </div>
        <div>
          {hasPrint && !editing && !hasOnlyExamDelivererRole && (
            <JumpTo
              to={`${window.location.href}/print`}
              className="code-c-button button"
              role="button"
            >
              <Icon type="external-link" />
              <Msg id="print.preview" />
            </JumpTo>
          )}
          <Restricted
            roles={[
              UserRole.SystemAdmin,
              ProjectRole.ProjectAdmin,
              ProjectRole.ExamCreator,
            ]}
            strictAllow={!archived}
          >
            <div>
              {editing ? (
                <Button
                  className="code-exam-edit__cancel-button"
                  onClick={onClickCancel}
                  ariaLabel="Cancel"
                >
                  <Msg id="cancel" />
                </Button>
              ) : (
                onClickEdit && (
                  <Tooltip
                    disabled={!editDisabled}
                    placement="top-end"
                    text={editTooltipText}
                  >
                    <Button
                      className="code-exam-edit__edit-button"
                      type="primary"
                      onClick={onClickEdit}
                      ariaLabel="Edit"
                      disabled={editDisabled}
                    >
                      <Msg id="edit" />
                    </Button>
                  </Tooltip>
                )
              )}
            </div>
          </Restricted>
        </div>
      </div>
    </>
  );
};
