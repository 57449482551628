import { isEqual } from "lodash";
import * as React from "react";

import { Msg, Divider } from "@shared/components";
import { ApplicantActionCountsModel } from "@shared/models";
import {
  AllActionType,
  ApplicantActionType,
  ChallengeResultStatus,
} from "@shared/services/enums";

import ActionLogFilterSelect, {
  ActionLogFilterSelectItem,
} from "../actionLogFilterSelect/ActionLogFilterSelect";
import {
  getCountOfActionType,
  shouldDisableChallengeOption,
} from "../submissionResultActionLogUtils/SubmissionResultActionLogUtils";

interface ChallengeOption {
  challengeResultId: number;
  challengeTitle: string;
  challengeResultStatus?: ChallengeResultStatus;
}

interface ActionOption {
  actionType: AllActionType;
  displayString: React.ReactNode;
}

export interface SelectedFilters {
  selectedChallengeResultId: number | "all";
  selectedActions: AllActionType[];
}

export interface ActionLogFilterProps {
  challengeOptions: ChallengeOption[];
  actionOptions: ActionOption[];
  onChange?: (selectedFilters: SelectedFilters) => void;
  actionLogCounts?: ApplicantActionCountsModel;
  selectedActions: AllActionType[];
  selectedChallengeResultId: number | "all";
}

const defaultSelection: SelectedFilters = {
  selectedChallengeResultId: "all",
  selectedActions: [],
};

const AllChallengeOption: ActionLogFilterSelectItem = {
  label: <Msg id="submission.actionLog.challengeOption.allChallenges" />,
  value: "all",
};

const SUSPICIOUS_ACTION_TYPE: Partial<AllActionType>[] = [
  ApplicantActionType.CopyPaste,
  ApplicantActionType.TabUnfocus,
  ApplicantActionType.Screenshot,
  ApplicantActionType.Webcam,
];

function ActionLogFilter({
  challengeOptions,
  actionOptions,
  onChange,
  actionLogCounts,
  selectedActions,
  selectedChallengeResultId,
}: ActionLogFilterProps) {
  const transformedChallengeOptions: ActionLogFilterSelectItem[] = [
    AllChallengeOption,
  ].concat(
    challengeOptions.map(
      ({ challengeResultId, challengeTitle, challengeResultStatus }) => {
        const disabled = shouldDisableChallengeOption(
          challengeResultId,
          challengeResultStatus,
          selectedActions,
          actionLogCounts,
        );
        const label = (
          <>
            <span className="challenge-title">{challengeTitle}</span>
            {challengeResultStatus === ChallengeResultStatus.Prepared && (
              <span className="challenge-status">
                <Msg id="submission.actionLog.challengeOption.notStarted" />
              </span>
            )}
          </>
        );

        return {
          value: challengeResultId,
          label,
          disabled,
          showTooltip: true,
        };
      },
    ),
  );
  const { suspiciousActionOptions, nonSuspiciousActionOptions } =
    actionOptions.reduce(
      (acc, { actionType, displayString }) => {
        const count = getCountOfActionType(
          actionType,
          selectedChallengeResultId,
          actionLogCounts,
        );
        // If count is undefined, it means that the action type is not enabled for the exam
        if (count !== undefined) {
          const label = `${displayString} (${count})`;
          const option = {
            value: actionType,
            label,
            disabled: count === 0,
          };

          if (SUSPICIOUS_ACTION_TYPE.includes(actionType as AllActionType)) {
            acc.suspiciousActionOptions.push(option);
          } else {
            acc.nonSuspiciousActionOptions.push(option);
          }
        }

        return acc;
      },
      {
        suspiciousActionOptions: [] as ActionLogFilterSelectItem[],
        nonSuspiciousActionOptions: [] as ActionLogFilterSelectItem[],
      },
    );

  // Add a divider to the last row of suspicious actions
  if (suspiciousActionOptions.length > 0) {
    suspiciousActionOptions[suspiciousActionOptions.length - 1].withDivider =
      true;
  }

  const transformedActionOptions = [
    ...suspiciousActionOptions,
    ...nonSuspiciousActionOptions,
  ];

  const updateSelectedFilters = (newSelectedFilters: SelectedFilters) => {
    onChange?.(newSelectedFilters);
  };

  const onChallengeFilterSelect = (item: ActionLogFilterSelectItem) => {
    updateSelectedFilters({
      selectedActions,
      selectedChallengeResultId: item.value as number,
    });
  };

  const onActionFilterSelect = (item: ActionLogFilterSelectItem) => {
    const actionType = item.value as ApplicantActionType;

    const newSelectedFilters = {
      selectedChallengeResultId,
      selectedActions: selectedActions.includes(actionType)
        ? selectedActions.filter(
            (selectedAction) => selectedAction !== actionType,
          )
        : [...selectedActions, actionType],
    };
    updateSelectedFilters(newSelectedFilters);
  };

  const clearFilters = () => {
    updateSelectedFilters(defaultSelection);
  };

  const ActionLabel = () => {
    return (
      <>
        <Msg id="actions" />
        {selectedActions.length > 0 && (
          <span>{` (${selectedActions.length})`}</span>
        )}
      </>
    );
  };

  const shouldShowClearFilter = !isEqual(
    {
      selectedChallengeResultId,
      selectedActions,
    },
    defaultSelection,
  );

  return (
    <div className="code-c-action-log-filter">
      <ActionLogFilterSelect
        options={transformedChallengeOptions}
        onSelect={onChallengeFilterSelect}
        name="challengeResultId"
        value={selectedChallengeResultId}
        className="code-c-action-log-filter__challenge-select"
        ariaLabel="Filter by Challenge"
      />
      <ActionLogFilterSelect
        placeholder={<ActionLabel />}
        options={transformedActionOptions}
        multiple={true}
        onSelect={onActionFilterSelect}
        name="action"
        value={selectedActions}
        className="code-c-action-log-filter__action-select"
        ariaLabel="Filter by Actions"
      />
      {shouldShowClearFilter && (
        <>
          <Divider vertical={true} />
          <a role="button" onClick={clearFilters}>
            <Msg id="clear.all" />
          </a>
        </>
      )}
    </div>
  );
}

export default ActionLogFilter;
