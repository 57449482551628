import { StackedBarChartItem } from "@shared/components";
import {
  ChallengeInsightModel,
  ChallengeStatisticModel,
  ExamChallengeModel,
  ExamModel,
  ReportChallengeModel,
} from "@shared/models";
import Message from "@shared/services/message";

import { ChartColor } from "./enums";

export enum ChallengeAnalysisTabs {
  timeTaken = "timeTaken",
  scoreDistribution = "scoreDistribution",
}

export const getChallengeStatisticDetails = ({
  exam,
  examChallengeAnalysisList,
}: {
  exam: ExamModel;
  examChallengeAnalysisList?: ReportChallengeModel[];
}): {
  challenge: ExamChallengeModel;
  analysis: ReportChallengeModel | undefined;
  challengeVersionId: number | undefined;
  challengeId: number | undefined;
}[] => {
  return exam.challengesSets.reduce((arr, challengeSet) => {
    const newChallenges = challengeSet.challenges.map((challenge) => {
      // adding this to keep track if challenge is linkedChallenge or not
      // analysis.challengeId can be either challenge.challengeId or challenge.linkedChallengeId
      let challengeVersionId = undefined;
      let challengeId = undefined;

      const analysis = examChallengeAnalysisList?.find((analysis) => {
        if (challenge.challengeId === analysis.challengeId) {
          challengeId = challenge.challengeId;
          challengeVersionId = challenge.challengeVersionId;
          return true;
        } else if (challenge.linkedChallengeId === analysis.challengeId) {
          challengeId = challenge.linkedChallengeId;
          challengeVersionId = challenge.linkedChallengeVersionId;
          return true;
        }

        return false;
      });

      return {
        challenge,
        analysis,
        challengeVersionId,
        challengeId,
      };
    });

    return [...arr, ...newChallenges];
  }, []);
};

export const getChallengeAnalysisBarChartData = ({
  challengeStatistic,
  challengeGlobalInsight,
}: {
  challengeStatistic: ChallengeStatisticModel | undefined;
  challengeGlobalInsight: ChallengeInsightModel | undefined;
}) => {
  const submissionCounts: number[] = Array(11).fill(0);
  const globalCounts: number[] = Array(11).fill(0);

  if (challengeStatistic?.dataSamples?.result) {
    for (const sample of challengeStatistic.dataSamples.result) {
      const index = Math.floor(sample.scorePercent / 10);
      submissionCounts[index]++;
    }
  }

  const thisExamLabel = Message.getMessageByKey(
    "exam.dashboard.challengeAnalysis.thisExam",
  );

  const globalLabel = Message.getMessageByKey("common.global");

  const barChartItems: StackedBarChartItem[] = [
    {
      label: thisExamLabel,
      data: submissionCounts,
    },
  ];

  const colors = {
    [thisExamLabel]: ChartColor.Primary,
  };

  const items = {
    barChartItems,
    colors,
    groups: [[thisExamLabel]],
  };

  if (challengeGlobalInsight) {
    items.barChartItems.push({
      label: globalLabel,
      data: globalCounts,
    });

    items.groups.push([globalLabel]);

    colors[globalLabel] = ChartColor.NeutralGray300;

    for (const scoreDistribution of challengeGlobalInsight.scoreDistributions) {
      const index = Math.floor(scoreDistribution.leftClosedBoundary / 10);
      globalCounts[index] += scoreDistribution.frequency;
    }
  }

  return items;
};
