import { connect } from "react-redux";
import { Action, Dispatch } from "redux";

import { applicantActionLogGetAllAction, challengeGetAction } from "@actions";

import { RootState } from "@reducers/index";

import { ApplicantActionType } from "@shared/services/enums";

import { CodePlayback, ExternalProps } from "./CodePlayback";

/**
 * States
 */
const mapStateToProps = (state: RootState, props: ExternalProps) => {
  const {
    examId: rawExamId,
    challengeId: rawChallengeId,
    submissionId: rawSubmissionId,
  } = props.match.params ?? {};

  const challengeId = Number(rawChallengeId);

  const challenge = state.challenge.challengeDetailList.find(
    (item) => item.id === challengeId,
  );

  const readme = state.readme.readmeList.find(
    (item) => item.challengeId === challengeId,
  );

  return {
    language: state.user.user?.language,
    useLocalPlayback: Boolean(process.env.REACT_APP_USE_LOCAL_PLAYBACK),
    examId: Number(rawExamId),
    challengeId,
    submissionId: Number(rawSubmissionId),
    challenge,
    actionLogs: state.actionLog.applicantActionLogList,
    readme,
  };
};

/**
 * Props
 */
const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  getChallenge: (id: number) => {
    dispatch(challengeGetAction.request(id));
  },
  getAllActionLog: (
    submissionId: number,
    options?: { challengeResultId: number; actions: ApplicantActionType[] },
  ) => {
    dispatch(applicantActionLogGetAllAction.request(options, submissionId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CodePlayback);
