import * as classnames from "classnames";
import { useState, useEffect } from "react";

import { Button, Icon, Modal, SortableItemList, Msg } from "@shared/components";
import { ExamChallengeSetModel } from "@shared/models";

/**
 * Prop interface
 */
export interface ExamChallengeSetReorderProps {
  isOpen: boolean;
  challengeSets: ExamChallengeSetModel[];
  onOK: (challengeSet: ExamChallengeSetModel[]) => void;
  onCancel: () => void;
}

/**
 * State interface
 */
export interface ExamChallengeSetReorderState {
  formValid: boolean;
  formValues: Partial<{ weight: number; numberChallengesToTake: number }>;
  formErrors: {};
}

/**
 * Page component
 */
function ExamChallengeSetReorder({
  isOpen,
  challengeSets,
  onCancel,
  onOK,
}: ExamChallengeSetReorderProps) {
  const [challengeList, setChallengeList] = useState(challengeSets);
  const rootStyle = classnames("code-exam-challenge-set-reorder");

  useEffect(() => {
    setChallengeList(challengeSets);
  }, [challengeSets]);

  const challengeSetCards = challengeList.map((challenge, index) => ({
    item: (
      <div className="challenge-set-card" key={challenge.id}>
        <div>
          <Icon type="bars" size="medium" />
        </div>
        <div className="challenge-set-card__title-container">
          <div>
            <strong>
              <Msg
                id={
                  challenge.isRandomSet
                    ? "exam.randomChallenges"
                    : challenge.isOptionalSet
                    ? "exam.optionalChallenges"
                    : "exam.requiredChallenges"
                }
              />
            </strong>
          </div>
          <div className="challenge-count">
            <Msg id="challengeCount" />: {challenge.challenges.length}
          </div>
        </div>
        <div className="arrow-button-container">
          {index < challengeSets.length - 1 && (
            <Button
              className="arrow-button-container__down"
              shrink={true}
              onClick={() => onSwapItem(index, false)}
              ariaLabel="Move Down"
            >
              <Icon type="arrow-down" />
            </Button>
          )}
          {index !== 0 && (
            <Button
              className="arrow-button-container__up"
              shrink={true}
              onClick={() => onSwapItem(index, true)}
              ariaLabel="Move Up"
            >
              <Icon type="arrow-up" />
            </Button>
          )}
        </div>
      </div>
    ),
    id: index,
  }));

  return (
    <Modal
      className={rootStyle}
      title={<Msg id="challengeSet.reorder.title" />}
      isOpen={isOpen}
      onClickOk={() => onOK(challengeList)}
      onClose={onClickCancel}
      onClickCancel={onClickCancel}
      ariaLabel="Reorder Challenge Set"
    >
      <SortableItemList
        items={challengeSetCards}
        onReorderItem={onReorderItem}
      />
    </Modal>
  );

  function onSwapItem(index: number, isUp: boolean) {
    const reorderedChallengeSets = challengeList.map((item, id) => {
      const targetItemIndex = isUp ? index - 1 : index + 1;

      const newOrderItem =
        index === id
          ? challengeList[targetItemIndex]
          : targetItemIndex === id
          ? challengeList[index]
          : item;

      return { ...newOrderItem, displayOrder: id + 1 };
    });

    setChallengeList(reorderedChallengeSets);
  }

  function onReorderItem(sortedItems: number[]) {
    const reorderedChallengeSets = challengeList.map((_item, id) => ({
      ...challengeList[sortedItems[id]],
      displayOrder: id + 1,
    }));
    setChallengeList(reorderedChallengeSets);
  }

  function onClickCancel() {
    onCancel();
    setChallengeList(challengeSets);
  }
}

export default ExamChallengeSetReorder;
