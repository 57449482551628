import { toast } from "react-toastify";
import { ActionType } from "typesafe-actions";

import PaginationModel from "../shared/models/Pagination.model";
import history from "../shared/services/history";

/**
 * New typed-save actions
 * NOTE
 *   Temporary Use Only. Once it's all replaced, it's no longer necessary.
 */
import * as AjaxActions from "./AjaxActions";
import * as ExternalSystemActions from "./ExternalSystemActions";

export * from "./GlobalActions";
export * from "./I18nActions";
export * from "./AjaxActions";
export * from "./UserActions";
export * from "./AuthActions";
export * from "./OrgsActions";
export * from "./ExamActions";
export * from "./ProjectActions";
export * from "./ChallengeActions";
export * from "./QuestionActions";
export * from "./ApplicantAction";
export * from "./ApplicantTimerAction";
export * from "./SubmissionActions";
export * from "./ReadmeActions";
export * from "./IntercomAction";
export * from "./AdminAction";
export * from "./GuestAction";
export * from "./ReportAction";
export * from "./ImageUploadAction";
export * from "./TutorialActions";
export * from "./HealthCheckAction";
export * from "./ActionLogAction";

export interface Action {
  type: string;
  payload?: {};
  params?: {};
}

// Action to call alert and pass payload to optional second action
export const alertAction = {
  /* eslint-disable @typescript-eslint/ban-types */
  success: (message: string, action: Function = () => ({ type: "@null" })) =>
    alert(message, "success", action),
  error: (message: string, action: Function = () => ({ type: "@null" })) =>
    alert(message, "error", action),
  info: (message: string, action: Function = () => ({ type: "@null" })) =>
    alert(message, "info", action),
  /* eslint-enable @typescript-eslint/ban-types */
};

const alert =
  (
    message: string,
    type: string,
    // eslint-disable-next-line @typescript-eslint/ban-types
    action: Function = () => ({ type: "@null" }),
  ) =>
  (payload?: {}) => {
    toast[type](message);
    return action(payload);
  };

export const redirectAction =
  (
    pathname: string,
    // eslint-disable-next-line @typescript-eslint/ban-types
    action: Function = () => ({ type: "@null" }),
    state?: {},
    refresh?: boolean,
    search?: string,
  ) =>
  (payload?: {}) => {
    // redirect
    history.push({ pathname, search, state });
    if (refresh) {
      window.location.reload();
    }
    return action(payload);
  };

export const reloadAction = () => {
  window.location.reload();
  return { type: "@null" };
};

export const externalRedirectAction = (url: string) => {
  window.location.assign(url);
  return { type: "@null" };
};

// Helper function to allow extra information other than just API response to be added to action
export const addToPayload =
  (
    extra: {},
    // eslint-disable-next-line @typescript-eslint/ban-types
    actionExtend: Function,
  ) =>
  (payload: {}) =>
    actionExtend({
      ...payload,
      ...extra,
    });

export interface ImageUploadAction extends Action {
  payload: {
    name: string;
    file: File;
    width: number;
    height: number;
    tags: string;
    transformation: string;
  };
}

export interface APIResponseAction extends Action {
  payload: {
    code: number;
    pagination?: PaginationModel;
    result?: {};
    messages?: Array<string>;
    extra?: {} | string;
  };
}

export interface CurrentProjectSwitchAction extends Action {
  payload: {
    projectId: number;
  };
}

/**
 * export type information
 * NOTE
 *   Temporary Use Only. Once it's all replaced, it's no longer necessary.
 *
 *     import * as actions from "./";
 *     export type ActionTypes = ActionType<typeof actions>;
 */
export type ActionTypes = ActionType<typeof ExternalSystemActions>;

/**
 * export type information for Epic
 * NOTE:
 *   Temporary Use Only
 *   As we haven't yet replaced all the actions, we define it temporarily to prevent compile errors
 *
 * @deprecated
 */
export type TemporalActionTypesForEpic = ActionType<
  typeof AjaxActions | typeof ExternalSystemActions
>;

/**
 * organize actions
 */
const actions = { ...ExternalSystemActions };
export { actions };
