import { StackedIcon } from "../stackedIcon/StackedIcon";

export function CodeDiffIcon() {
  return (
    <StackedIcon
      className="code-c-code-diff-icon"
      icons={[
        { type: "file", variant: "regular", size: "large" },
        { type: "plus-minus", variant: "solid", size: "normal" },
      ]}
    />
  );
}
