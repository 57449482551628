import * as classnames from "classnames";
import * as React from "react";

interface IconButtonProps {
  "aria-label"?: string;
  "aria-pressed"?: boolean;
  children?: React.ReactNode;
  className?: string;
  color?: string;
  disabled?: boolean;
  rounded?: boolean;
  type?: "button" | "submit" | "reset";
  size?: "base";
  onClick?: () => void;
}

function IconButton(
  {
    children,
    "aria-label": ariaLabel,
    "aria-pressed": ariaPressed,
    className,
    color,
    disabled,
    rounded,
    type = "button",
    size = "base",
    onClick,
  }: IconButtonProps,
  ref: React.Ref<HTMLButtonElement>,
) {
  const rootStyle = classnames("code-c-icon-button", className, {
    [`code-c-icon-button--${color}`]: Boolean(color),
    [`is-${size}`]: Boolean(size),
    "is-rounded": rounded,
    "is-disabled": disabled,
  });

  return (
    <button
      ref={ref}
      className={rootStyle}
      disabled={disabled}
      onClick={onClick}
      type={type}
      aria-label={ariaLabel}
      aria-pressed={ariaPressed}
      tabIndex={0}
    >
      {children}
    </button>
  );
}

export default React.forwardRef(IconButton);
