class ProjectListModel {
  public id: number;
  public name: string;
  public status: number;
  public applicationType: number;
  public memberCount: number;
  public deliveryCount: number;
  public examCount: number;
  public createdAt: string;

  constructor(args?: {}) {
    Object.assign(this, args);
  }
}

export default ProjectListModel;
