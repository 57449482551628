import * as classnames from "classnames";
import * as React from "react";
import { Redirect, RouteComponentProps } from "react-router-dom";
import Split from "react-split";

import { Loading, Msg, Markdown } from "@shared/components";
import { LocalCodeFiles } from "@shared/components/submissionDetail";
import { useIframeCommunication } from "@shared/hooks";
import { useGetSubmissionsChallengeResultPreview } from "@shared/hooks/query";
import { useLocalCodeFiles } from "@shared/hooks/useLocalCodeFiles";
import { LocalFileMetaData } from "@shared/models";
import { ChallengeTakenBy, FileScanningStatus } from "@shared/services/enums";
import { MESSAGE_EVENT_CONTEXT } from "@shared/services/events";
import { getPreviewURL } from "@shared/services/preview";
import { parseUrl, updateUrl } from "@shared/services/queryString";

import { getChallengeResultDisplayName } from "../PreviewUtil";
import { ChallengeResultPreviewButtonGroup } from "./partials/ChallengeResultPreviewButtonGroup";
import { ChallengeResultPreviewHeader } from "./partials/ChallengeResultPreviewHeader";

type ChallengeResultPreviewProps = RouteComponentProps<{
  challengeId: string;
}>;

const ChallengeResultPreview = ({
  match,
  location,
}: ChallengeResultPreviewProps) => {
  const { challengeId: challengeIdStr } = match.params ?? {};
  const { version: versionStr } = parseUrl(location.search);

  const challengeId = Number(challengeIdStr);
  const version = versionStr ? Number(versionStr) : undefined;

  const [codeEditorRef, setCodeEditorRef] =
    React.useState<HTMLIFrameElement | null>(null);
  const [selectedSaveHistoryId, setSelectedSaveHistoryId] = React.useState<
    number | undefined
  >(version);

  const [isRightDrawerOpen, setIsRightDrawerOpen] =
    React.useState<boolean>(false);

  const {
    data: challengeResultPreview,
    isFetching,
    error,
  } = useGetSubmissionsChallengeResultPreview(
    challengeId,
    selectedSaveHistoryId,
  );

  const { postMessage, ready: codeEditorReady } = useIframeCommunication({
    ref: { current: codeEditorRef },
  });

  // adding this workaround to force editor to rerender since
  // it doesn't auto update with the updated values sent via postMessage()
  const [valueChanged, setValueChanged] = React.useState<boolean>(false);

  React.useEffect(() => {
    setValueChanged(false);
  }, [challengeResultPreview]);

  React.useEffect(() => {
    const shouldSendContext =
      !challengeResultPreview?.saveHistory?.length || selectedSaveHistoryId;

    if (codeEditorReady && challengeResultPreview && shouldSendContext) {
      postMessage(MESSAGE_EVENT_CONTEXT, challengeResultPreview.context);
    }
  }, [
    challengeResultPreview,
    codeEditorReady,
    postMessage,
    selectedSaveHistoryId,
  ]);

  React.useEffect(() => {
    if (challengeResultPreview?.saveHistory?.length && !selectedSaveHistoryId) {
      const historyWithHighestScore = challengeResultPreview?.saveHistory?.find(
        (saveHistory) =>
          saveHistory.successfulTestcases ===
          challengeResultPreview.currentSuccessfulTestcases,
      );

      if (historyWithHighestScore) {
        setSelectedSaveHistoryId(historyWithHighestScore.id);
      }
    }
  }, [challengeResultPreview, selectedSaveHistoryId]);

  const onChangeSelectedSaveHistoryId = (selectedSaveHistoryId: number) => {
    setSelectedSaveHistoryId(selectedSaveHistoryId);
    setValueChanged(true);
    updateUrl({ version: selectedSaveHistoryId });
  };

  const {
    files: localCodeFiles,
    isEmpty: hasNoLocalCodeFiles,
    downloadFile,
    fileScanStatusesString,
    skippedFileScanReasons,
  } = useLocalCodeFiles({
    fileUploadConfig: challengeResultPreview?.context.fileUploadConfig,
    challengeResultId: challengeResultPreview?.context.challengeResultId || 0,
  });
  const hasLocalCodeFiles = !hasNoLocalCodeFiles;
  const [fileToBeDownloaded, setIsFileToBeDownloaded] = React.useState<
    LocalFileMetaData | undefined
  >(undefined);

  // error may be an object or string
  if (
    error?.includes?.("404") ||
    (version !== undefined &&
      (isNaN(version) ||
        !Number.isInteger(version) ||
        version < 0 ||
        version > Number.MAX_SAFE_INTEGER)) // quick fix; should instead rely on backend error
  ) {
    // TODO: should be handled in global level instead (#4512)
    return <Redirect to="/404" />;
  }

  const hasSaveHistory = Boolean(challengeResultPreview?.saveHistory?.length);

  const showLoading =
    isFetching ||
    !challengeResultPreview ||
    (!selectedSaveHistoryId && hasSaveHistory) ||
    valueChanged;

  const bodyClassName = classnames("code-challenge-result-preview__body", {
    "is-right-drawer-open":
      isRightDrawerOpen &&
      (challengeResultPreview?.appealComment || hasLocalCodeFiles),
  });

  return (
    <div className="code-challenge-result-preview">
      <ChallengeResultPreviewHeader
        orgLogoUrl={challengeResultPreview?.organizationImage}
        examName={challengeResultPreview?.examName ?? ""}
        isLocalExam={
          challengeResultPreview?.context.takenBy ===
          ChallengeTakenBy.LocalMachine
        }
        applicantName={getChallengeResultDisplayName(challengeResultPreview)}
        totalTestcases={challengeResultPreview?.totalTestcases ?? 0}
        successfulTestcases={challengeResultPreview?.successfulTestcases ?? 0}
        highestSuccessfulTestcases={
          challengeResultPreview?.currentSuccessfulTestcases ?? 0
        }
        timeSpentSeconds={challengeResultPreview?.timeSpentSeconds}
        saveHistory={challengeResultPreview?.saveHistory}
        selectedSaveHistoryId={selectedSaveHistoryId}
        onChangeSelectedSaveHistoryId={onChangeSelectedSaveHistoryId}
      />
      {showLoading && <Loading isOpen={true} />}
      {!showLoading && (
        <>
          <Split
            sizes={[75, 25]}
            minSize={100}
            gutterSize={14}
            className={bodyClassName}
          >
            <div className="code-challenge-result-preview__iframe-wrapper">
              <iframe
                id="code-editor-iframe"
                className="code-challenge-result-preview__iframe"
                ref={setCodeEditorRef}
                src={getPreviewURL(
                  challengeResultPreview?.editorType || "coding",
                )}
              />
              <div className="reader-preview-mode">Preview mode</div>
            </div>
            <div className="challenge-result-preview__code-explanation-drawer">
              {challengeResultPreview.appealComment && (
                <div className="challenge-result-preview__code-explanation-drawer__section code-explanation-section">
                  <div className="challenge-result-preview__code-explanation-drawer__header">
                    <Msg id="common.applicantExplanation" />
                  </div>
                  <Markdown body={challengeResultPreview.appealComment} />
                </div>
              )}
              {challengeResultPreview?.context.fileUploadConfig
                ?.uploadEnabled &&
                hasLocalCodeFiles && (
                  <div className="challenge-result-preview__code-explanation-drawer__section local-code-files-section">
                    <div className="challenge-result-preview__code-explanation-drawer__header">
                      <Msg id="submission.localCodeFiles" />
                    </div>
                    <LocalCodeFiles
                      files={localCodeFiles}
                      onClickDownload={(file) => {
                        if (
                          file.scanningStatus === FileScanningStatus.Malicious
                        ) {
                          setIsFileToBeDownloaded(file);
                        } else {
                          downloadFile(file);
                        }
                      }}
                      isConfirmModalOpen={fileToBeDownloaded !== undefined}
                      onCloseConfirmModal={() =>
                        setIsFileToBeDownloaded(undefined)
                      }
                      onConfirmModal={() => {
                        if (fileToBeDownloaded) {
                          downloadFile(fileToBeDownloaded);
                          setIsFileToBeDownloaded(undefined);
                        }
                      }}
                      fileScanStatusesString={fileScanStatusesString}
                      skippedFileScanReasons={skippedFileScanReasons}
                      layout="column"
                    />
                  </div>
                )}
            </div>
          </Split>
          <ChallengeResultPreviewButtonGroup
            isRightDrawerOpen={isRightDrawerOpen}
            toggleRightDrawer={() => setIsRightDrawerOpen(!isRightDrawerOpen)}
            disableCodeExplanationButton={
              !challengeResultPreview?.appealComment
            }
            disableLocalCodeFilesButton={!hasLocalCodeFiles}
            hideLocalCodeFilesButton={
              !challengeResultPreview?.context.fileUploadConfig?.uploadEnabled
            }
          />
        </>
      )}
    </div>
  );
};

export default ChallengeResultPreview;
