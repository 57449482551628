import * as classnames from "classnames";
import { isEqual } from "lodash";
import * as React from "react";

/**
 * Prop interface
 */
export interface RowProps {
  className?: string;
  children?: {};
  ariaHidden?: boolean;
  disabled?: boolean;
  onClick?: () => void;
}

/**
 * React Component
 */
export default class Row extends React.Component<RowProps, {}> {
  public shouldComponentUpdate(nextProps: RowProps) {
    return !isEqual(nextProps, this.props);
  }

  public render() {
    const { className, ariaHidden, children, disabled, onClick } = this.props;

    const rootStyle = classnames("code-c-row", {
      [`${className}`]: Boolean(className),
      ["clickable"]: Boolean(typeof onClick === "function"),
      ["disabled"]: disabled,
    });

    return (
      <tr
        className={rootStyle}
        onClick={() => !disabled && onClick?.()}
        aria-hidden={ariaHidden}
      >
        {children}
      </tr>
    );
  }
}
