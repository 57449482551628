import { range } from "lodash";

import { SortOrderItem } from "@shared/models";
import { SortDirection } from "@shared/services/enums";

import { parseUrl } from "../../../../../../shared/services/queryString";

export function getSortItem(value = ""): SortOrderItem {
  switch (value) {
    case "sortByMany":
      return {
        column: "usingOrganizationsCount",
        direction: SortDirection.Desc,
      };
    case "sortByLess":
      return {
        column: "usingOrganizationsCount",
        direction: SortDirection.Asc,
      };
    case "sortByNewest":
      return {
        column: "updatedAt",
        direction: SortDirection.Desc,
      };
    default:
      return {
        column: "id",
        direction: SortDirection.Desc,
      };
  }
}

export function getURLObjectBySortBy(value = "") {
  switch (value) {
    case "sortByMany":
      return { isMany: true };
    case "sortByLess":
      return { isMany: false };
    case "sortByNewest":
      return { isNewest: true };
    default:
      return {};
  }
}

export function getURLObjectByFilterValues({
  keyword = "",
  maxExaminationTime = "",
  naturalLanguage = "",
  targetEngineerRole = "",
}: Partial<{
  keyword: string;
  maxExaminationTime: string;
  naturalLanguage: string;
  targetEngineerRole: string;
}>) {
  const getValue = (value: string) => {
    return value === "" || value === "0" ? undefined : value;
  };
  return {
    keyword: keyword ? keyword : undefined,
    maxExaminationTime: getValue(maxExaminationTime),
    naturalLanguage: getValue(naturalLanguage),
    targetEngineerRole: getValue(targetEngineerRole),
  };
}

export function parseURLToSortBy(isMany?: string, isNewest?: string) {
  if (isMany == "true") {
    return "sortByMany";
  }
  if (isMany === "false") {
    return "sortByLess";
  }
  if (isNewest === "true") {
    return "sortByNewest";
  }
  return "";
}

export function parseURL(url = "") {
  const {
    page = "0",
    keyword = "",
    maxExaminationTime = "",
    naturalLanguage = "",
    targetEngineerRole = "0",
    isMany,
    isNewest,
  } = parseUrl(url);
  const sortBy = parseURLToSortBy(isMany, isNewest);
  return {
    page,
    filtersValue: {
      keyword,
      maxExaminationTime: ["30", "60", "120", "240"].includes(
        maxExaminationTime,
      )
        ? maxExaminationTime
        : "",
      naturalLanguage: ["en", "ja"].includes(naturalLanguage)
        ? naturalLanguage
        : "",
      targetEngineerRole: range(1, 18)
        .map((num) => `${num}`)
        .includes(targetEngineerRole)
        ? targetEngineerRole
        : "",
    },
    sortItem: getSortItem(sortBy),
    sortBy,
  };
}
