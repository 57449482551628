import { AxiosRequestConfig } from "axios";

import { get } from "@api/httpClient";

interface AppConfigAttributes {
  enabled: boolean;
  organizationIdList?: number[];
}

export interface AppConfigResponse {
  webcamEnabled?: AppConfigAttributes;
  submissionNote?: AppConfigAttributes;
  talentHub?: AppConfigAttributes;
}

export async function getAppConfig(options?: AxiosRequestConfig) {
  const { data } = await get<AppConfigResponse>("/api/app/config", options);

  return data;
}
