import { useCallback, useLayoutEffect, useState } from "react";

export interface ElementSize {
  width: number;
  height: number;
}

const getElements = ({ id, className, tagName }: UseElementClassProps) => {
  const elements = [];

  if (id) {
    const element = document.getElementById(id);
    if (element) {
      elements.push(element);
    }
  }

  if (className) {
    const classElements = document.getElementsByClassName(className);
    elements.push(...Array.from(classElements));
  }

  if (tagName) {
    if (tagName === "html") {
      elements.push(document.documentElement);
    }
  }

  return elements;
};

interface UseElementClassProps {
  id?: string;
  className?: string;
  tagName?: string;
}

export const useElement = ({
  id,
  className,
  tagName,
}: UseElementClassProps): {
  addClass: (classToAdd: string) => void;
  removeClass: (classToRemove: string) => void;
} => {
  const addClass = useCallback(
    (classToAdd: string) => {
      const elements = getElements({ id, className, tagName });

      if (elements.length) {
        elements.forEach((element) => {
          element.classList.add(classToAdd);
        });
      }
    },
    [id, className, tagName],
  );

  const removeClass = useCallback(
    (classToRemove: string) => {
      const elements = getElements({ id, className, tagName });

      if (elements.length) {
        elements.forEach((element) => {
          element.classList.remove(classToRemove);
        });
      }
    },
    [id, className, tagName],
  );

  return {
    addClass,
    removeClass,
  };
};

export function useElementSize<T extends HTMLElement = HTMLDivElement>(): [
  (node: T | null) => void,
  ElementSize,
] {
  const [ref, setRef] = useState<T | null>(null);
  const [size, setSize] = useState<ElementSize>({ width: 0, height: 0 });

  const handleSize = useCallback(() => {
    if (ref) {
      setSize({
        width: ref.offsetWidth,
        height: ref.offsetHeight,
      });
    }
  }, [ref]);

  useLayoutEffect(() => {
    if (!ref) {
      return;
    }

    handleSize();

    const observer = new ResizeObserver(handleSize);
    observer.observe(ref);

    return () => observer.disconnect();
  }, [handleSize, ref]);

  return [setRef, size];
}
