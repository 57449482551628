import { AxiosRequestConfig } from "axios";

import { ApplicantReportModel } from "@shared/models";

import { get } from "../httpClient";

export interface SubmissionRankingDetailsParams {
  projectId: number;
  submissionId: number;
  options?: AxiosRequestConfig;
}

export async function getReportSubmissionRankingDetails({
  projectId,
  submissionId,
  options,
}: SubmissionRankingDetailsParams) {
  const { data } = await get<ApplicantReportModel>(
    `/api/reports/examinees/projects/${projectId}/submissions/${submissionId}`,
    options,
  );

  return data;
}
