import { connect } from "react-redux";

import { useExamPermissions } from "@shared/hooks";
import { withCustomHook } from "@shared/services/withCustomHook";

/**
 * Redux store
 */
import { RootState } from "../../../../../reducers";
import ExamChallengeEdit, { ExternalProps } from "./ExamChallengeEdit";

const wrapper = withCustomHook(() => {
  const { enableViewCodePlayback, enableViewWebcam, enableApplicantActionLog } =
    useExamPermissions();

  return {
    enableCodePlayback: enableViewCodePlayback,
    enableWebcam: enableViewWebcam,
    enableApplicantActionLog: enableApplicantActionLog,
  };
})(ExamChallengeEdit);

const mapStateToProps = (state: RootState, props: ExternalProps) => {
  return {
    allProgrammingLanguages:
      state.challenge.challengeFilters.programmingLanguages,
  };
};

export default connect(mapStateToProps, () => ({}))(wrapper);
