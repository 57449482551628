import { forwardRef } from "react";

import { Icon } from "../../icon";
import { IconType } from "../../icon/icon/types";
import { Tag } from "../../tag";
import { DefaultOptionProps } from "../Options/DefaultOption";

interface AutoCompleteInputProps<T extends DefaultOptionProps> {
  open: boolean;
  inputProps: Record<string, unknown>;
  selectedOptions: T[];
  onDelete: (option: T) => void;
  isMulti?: boolean;
  inputRef: React.RefObject<HTMLInputElement>;
  className?: string;
}

export const AutoCompleteInput = forwardRef<
  HTMLInputElement,
  AutoCompleteInputProps<DefaultOptionProps>
>(({ open, inputProps, selectedOptions, onDelete, isMulti, inputRef }, ref) => {
  const { className, ...inputPropsRest } = inputProps;
  return (
    <div ref={ref} {...inputProps}>
      {isMulti &&
        selectedOptions.map((option) => (
          <Tag
            key={option.label}
            hasDelete
            onDelete={(e) => {
              onDelete(option);
              e.stopPropagation();
            }}
            labelClassName="wrap"
          >
            {option.label}
          </Tag>
        ))}
      <input
        type="text"
        ref={inputRef}
        className="code-c-autocomplete__input-container-text-input"
        {...inputPropsRest}
      />
      <Icon
        type={open ? IconType.ChevronUp : IconType.ChevronDown}
        className="code-c-autocomplete__input-container-caret-icon"
      />
    </div>
  );
});
