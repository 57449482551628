class ExternalSystemLinkModel {
  public id: number;
  public organizationId: number;
  public atsKind: number;
  public enabled = false;
  public trackToken = "";
  public notificationEmail = "";

  constructor(args?: {}) {
    Object.assign(this, args);
  }

  public isEmpty() {
    return !Boolean(this.id);
  }
}

export default ExternalSystemLinkModel;
