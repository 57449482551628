import classNames from "classnames";

import { Column, Msg, Row, Table, TableBody } from "..";
import { PossiblyNoDataWithTooltip } from "../question/partials/PossiblyNoDataWithTooltip";
import { TableProps } from "../table/Table";

interface ScoreDistributionTableProps extends Pick<TableProps, "theme"> {
  averageScore?: number;
  deviation?: number;
  medianScore?: number;
  tableClassName?: string;
}

export function ScoreDistributionTable({
  averageScore,
  deviation,
  medianScore,
  tableClassName,
  theme,
}: ScoreDistributionTableProps) {
  const tableStyle = classNames(
    "code-c-score-distribution__table",
    tableClassName,
  );

  return (
    <Table narrow hoverable={true} className={tableStyle} theme={theme}>
      <TableBody>
        <Row>
          <Column className="code-c-score-distribution__title">
            <Msg id="report.average" />
          </Column>
          <Column className="code-c-score-distribution__body">
            <PossiblyNoDataWithTooltip
              value={averageScore !== undefined ? `${averageScore}%` : ""}
            />
          </Column>
        </Row>
        <Row>
          <Column className="code-c-score-distribution__title">
            <Msg id="report.std" />
          </Column>
          <Column className="code-c-score-distribution__body">
            <PossiblyNoDataWithTooltip
              value={deviation !== undefined ? `${deviation}%` : ""}
            />
          </Column>
        </Row>
        <Row>
          <Column className="code-c-score-distribution__title">
            <Msg id="challenge.medianScore" />
          </Column>
          <Column className="code-c-score-distribution__body">
            <PossiblyNoDataWithTooltip
              value={medianScore !== undefined ? `${medianScore}%` : ""}
            />
          </Column>
        </Row>
      </TableBody>
    </Table>
  );
}
