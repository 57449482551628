import * as classnames from "classnames";

import {
  ApplicantActionCountsModel,
  IApplicantActionLog,
} from "@shared/models";
import {
  formatDateTimeSeconds,
  timeStringObjectAsDays,
} from "@shared/services/date";
import {
  AllActionType,
  ApplicantActionSubmitCause,
  ApplicantActionType,
  ChallengeResultStatus,
  getDisplayName,
  getWebcamDisplayName,
  WebcamActionType,
} from "@shared/services/enums";
import Message from "@shared/services/message";

interface TransformActionLogListParams {
  apiResponse: IApplicantActionLog[];
  examChallenges?: Record<string, string>;
  applicantActionsDisplayString?: Record<AllActionType, string>;
  overlappingIps?: Record<string, boolean>;
}

const getIcon = (action: string) => {
  switch (action) {
    case "copy-paste":
      return "clone";
    case "tab-unfocus":
      return "table-columns";
    case "screenshot":
      return "camera";
    case ApplicantActionType.Webcam:
      return "video";
    default:
      return null;
  }
};

interface ActionDetails {
  displayAction: string;
  formattedActionTime: string;
  isAgentDiff: boolean;
  isDiff: boolean;
  isDuplicateIp: boolean | undefined;
  isHashDiff: boolean;
  actionClassName: string;
  clickable: boolean;
  unfocusedTime: string | undefined;
  icon: string | null;
  isIpDiff: boolean;
  isManualSubmission: boolean;
  isServerAction: boolean;
  key: string;
  sessionData: {
    agent?: string;
    hash?: string;
    ip?: string;
  };
  title: string;
}

export type ApplicantActionLogTransformedModel = ReturnType<
  typeof transformActionLogList
>[number];

export const webcamActionTypeToMessage: Record<WebcamActionType, string> = {
  [WebcamActionType.CandidateAbsent]: "submission.actionLog.candidateAbsent",
  [WebcamActionType.ExternalDeviceUsageDetected]:
    "submission.actionLog.externalDeviceUsage",
  [WebcamActionType.WebcamImpersonationDetected]:
    "submission.actionLog.webcamImpersonationDetected",
  [WebcamActionType.WebcamMultiplePeopleDetected]:
    "submission.actionLog.webcamMultiplePeopleDetected",
  [WebcamActionType.FaceVerificationResult]: "",
  [WebcamActionType.WebcamAppeal]: "",
};

export const transformActionLogList = ({
  apiResponse,
  examChallenges,
  applicantActionsDisplayString,
  overlappingIps,
}: TransformActionLogListParams) => {
  const array = apiResponse.map((action, i, items) => {
    const prevItem = i > 0 ? items[i - 1] : items[i];
    let title: string = "-";
    if (
      action.action !== ApplicantActionType.SubmitExam &&
      action.challengeResultId &&
      examChallenges &&
      examChallenges[action.challengeResultId]
    ) {
      title = examChallenges[action.challengeResultId];
    }

    const isServerAction = Boolean(
      action.action === ApplicantActionType.SubmitExam &&
        [
          ApplicantActionSubmitCause.BatchExpireChallengeResult,
          ApplicantActionSubmitCause.BatchExpireApplicantExam,
          ApplicantActionSubmitCause.BatchAutoSubmit,
        ].includes(action.submitCause),
    );
    const isManualSubmission = Boolean(
      action.action === ApplicantActionType.SubmitExam &&
        action.submitCause &&
        action.submitCause === ApplicantActionSubmitCause.ManualSubmission,
    );

    const secondaryClassNameActions: AllActionType[] = [
      ApplicantActionType.CopyPaste,
      ApplicantActionType.TabUnfocus,
      ApplicantActionType.Screenshot,
      ApplicantActionType.Webcam,
    ];
    const actionTime = formatDateTimeSeconds(action.actionTime);
    const actionClassName = classnames({
      "has-text-secondary-700": secondaryClassNameActions.includes(
        action.action,
      ),
    });
    let isIpDiff = false;
    let isAgentDiff = false;
    let isHashDiff = false;
    if (
      (action.action === ApplicantActionType.SubmitExam &&
        !action.submitCause) ||
      action.action !== ApplicantActionType.SubmitExam
    ) {
      isIpDiff =
        prevItem.action !== ApplicantActionType.SubmitExam &&
        action.action !== ApplicantActionType.SubmitExam &&
        prevItem.applicantRequestIp !== action.applicantRequestIp;
      isAgentDiff =
        prevItem.applicantRequestUserAgent !== action.applicantRequestUserAgent;
      isHashDiff =
        prevItem.applicantRequestSessionHash !==
        action.applicantRequestSessionHash;
    }
    const isDiff =
      !isServerAction &&
      !isManualSubmission &&
      (isIpDiff || isAgentDiff || isHashDiff);
    const clickable = !isServerAction && !isManualSubmission;
    const isDuplicateIp =
      !isServerAction &&
      !isManualSubmission &&
      overlappingIps?.[action.applicantRequestIp || ""];

    let unfocusedTime = undefined;
    if (action.action === ApplicantActionType.TabUnfocus) {
      if (action.tabUnfocusedMillis) {
        unfocusedTime = Object.values(
          timeStringObjectAsDays(
            Math.max(action.tabUnfocusedMillis, 1000) / 1000,
          ),
        )
          .join("")
          .trim();
      } else {
        unfocusedTime = Message.getMessageByKey(
          "submission.actionLog.actionDetails.noReturn",
        );
      }
    }

    let normalizedAction = "";
    if (action.action === ApplicantActionType.Webcam) {
      for (let i = 0; i < action.webcam.suspiciousActivities.length; i++) {
        const activity = action.webcam.suspiciousActivities[i];
        normalizedAction += getWebcamDisplayName(activity);

        if (i !== action.webcam.suspiciousActivities.length - 1) {
          normalizedAction += ", ";
        }
      }
    } else {
      normalizedAction = `${
        applicantActionsDisplayString?.[action.action] ||
        getDisplayName(action.action)
      }`;
    }

    const icon = getIcon(action.action);

    const actionDetails: ActionDetails = {
      displayAction: normalizedAction,
      formattedActionTime: actionTime,
      isAgentDiff,
      isDiff,
      isDuplicateIp,
      isHashDiff,
      actionClassName,
      clickable,
      icon,
      isIpDiff,
      isManualSubmission,
      isServerAction,
      key: `actionLog${action.id}`,
      sessionData: {
        agent: action.applicantRequestUserAgent,
        hash: action.applicantRequestSessionHash,
        ip: action.applicantRequestIp,
      },
      title,
      unfocusedTime,
    };

    return { action, actionDetails };
  });

  return array;
};

export const getCountOfActionType = (
  actionType: AllActionType,
  selectedChallengeResultId?: number | "all",
  actionLogCounts?: ApplicantActionCountsModel,
) => {
  if (!actionLogCounts) {
    return undefined;
  }

  const { challengeCounts, examCounts } = actionLogCounts;

  if (!selectedChallengeResultId || selectedChallengeResultId == "all") {
    if (actionType in examCounts) {
      return examCounts[actionType];
    } else {
      let totalCount: number | undefined = undefined;
      Object.entries(challengeCounts).forEach(([, actionTypeToCount]) => {
        if (actionType in actionTypeToCount) {
          totalCount = (totalCount || 0) + (actionTypeToCount[actionType] || 0);
        }
      });

      return totalCount;
    }
  }

  return challengeCounts[selectedChallengeResultId]?.[actionType];
};

export const shouldDisableChallengeOption = (
  challengeResultId: number,
  challengeResultStatus?: ChallengeResultStatus,
  selectedActions: AllActionType[] = [],
  actionLogCounts?: ApplicantActionCountsModel,
) => {
  if (challengeResultStatus === ChallengeResultStatus.Prepared) {
    return true;
  }

  if (!actionLogCounts) {
    return false;
  }

  const { challengeCounts } = actionLogCounts;

  if (selectedActions.length === 0) {
    return Object.values(challengeCounts[challengeResultId] || []).every(
      (count) => count === 0,
    );
  }

  return selectedActions.every(
    (actionType) =>
      (challengeCounts[challengeResultId]?.[actionType] || 0) === 0,
  );
};
