import {
  Action,
  APIResponseAction,
  adminOrgSetAction,
  projectListAddAction,
  projectListRemoveAction,
  projectMemberDeleteAction,
  adminContractListAction,
  adminContractCreateAction,
  adminContractUpdateAction,
  adminContractDeleteAction,
  adminExamCopyOrgGetAction,
  adminExamCopyAction,
  adminProjectMemberDeleteAction,
  adminChallengeCollectionListAction,
  adminChallengeCollectionGetAction,
  adminChallengeCollectionCreateAction,
  adminChallengeCollectionUpdateAction,
  adminChallengeCollectionDeleteAction,
  adminOfficialExamAllListGetAction,
  adminExamReactivateAction,
  adminExamDeactivateAction,
  allActionsGetAction,
  allTiersGetAction,
  adminSSOGetAction,
  adminSSOMetaDeleteAction,
  adminSSOMetaUploadAction,
  adminSSOUpdateAction,
} from "../actions";
import {
  OrganizationModel,
  ProjectModel,
  PaginationModel,
  ContractModel,
  AdminChallengeCollectionListModel,
  AdminChallengeCollectionModel,
  OfficialExamListModel,
  EnumModel,
  SSOModel,
} from "../shared/models";

export interface AdminState {
  selectedOrg: OrganizationModel;

  contractListLoading: boolean;
  contractList: Array<ContractModel>;

  contractCreateLoading: boolean;
  contractCreateError: boolean;

  contractDeleteLoading: boolean;

  copyExamOrgDetails: OrganizationModel;
  copyExamOrgDetailsLoading: boolean;

  copyExamLoading: boolean;
  deleteExam: {
    submitting: boolean;
    error: boolean;
  };

  deleteMember: {
    loading: boolean;
    count: number;
    error: boolean;
  };

  challengeCollectionList: AdminChallengeCollectionListModel[];
  challengeCollectionListPagination: PaginationModel;
  challengeCollectionFilters: {};
  challengeCollectionDetails: AdminChallengeCollectionModel[];
  challengeCollectionLoading: boolean;
  challengeCollectionSubmitting: boolean;
  challengeCollectionError: boolean;

  officialExamListChunk: OfficialExamListModel[];
  officialExamList: OfficialExamListModel[];
  officialExamListLoading: boolean;
  officialExamListError: boolean;

  tiers: EnumModel[];
  actions: EnumModel[];

  ssoSetting?: SSOModel;
  ssoSettingLoading: boolean;
  ssoSettingSubmitting: boolean;
}

export const initialState = {
  selectedOrg: new OrganizationModel({
    applicationTypes: [1],
    projects: [],
  }),

  contractListLoading: false,
  contractList: [],

  contractCreateLoading: false,
  contractCreateError: false,

  contractDeleteLoading: false,

  copyExamOrgDetails: new OrganizationModel(),
  copyExamOrgDetailsLoading: false,

  copyExamLoading: false,
  isOrgCreated: false,

  deleteMember: {
    loading: false,
    error: false,
    count: 0,
  },
  deleteExam: {
    submitting: false,
    error: false,
  },
  challengeCollectionList: [],
  challengeCollectionListPagination: new PaginationModel(),
  challengeCollectionFilters: {},
  challengeCollectionDetails: [],
  challengeCollectionLoading: false,
  challengeCollectionSubmitting: false,
  challengeCollectionError: false,

  officialExamListChunk: [],
  officialExamList: [],
  officialExamListLoading: false,
  officialExamListError: false,

  tiers: [],
  actions: [],

  ssoSettingLoading: false,
  ssoSettingSubmitting: false,
};

export const adminReducer = (
  state: AdminState = initialState,
  action: Action,
): AdminState => {
  const payload = (action as APIResponseAction).payload;

  switch (action.type) {
    case adminOrgSetAction.types.request:
      return {
        ...state,
        selectedOrg: new OrganizationModel(action.payload),
      };

    case projectListAddAction.types.request: {
      const updateProjectIndex = state.selectedOrg.projects.findIndex(
        (project) => project.id === (payload.result as ProjectModel).id,
      );
      const updateProject =
        state.selectedOrg.projects.find(
          (project) => project.id === (payload.result as ProjectModel).id,
        ) ||
        new ProjectModel({ memberCount: 0, deliveryCount: 0, examCount: 0 });

      const updatedList = [...state.selectedOrg.projects];

      const updatedProject = new ProjectModel({
        ...updateProject,
        ...payload.result,
      });

      if (updateProjectIndex >= 0) {
        updatedList.splice(updateProjectIndex, 1, updatedProject);
      } else {
        updatedList.unshift(updatedProject);
      }

      const updatedOrg = new OrganizationModel({
        ...state.selectedOrg,
        projects: updatedList,
      });

      return {
        ...state,
        selectedOrg: updatedOrg,
      };
    }
    case projectListRemoveAction.types.request: {
      const updatedOrg = new OrganizationModel({
        ...state.selectedOrg,
        projects: state.selectedOrg.projects.filter(
          (project) => project.id !== (action.payload as number),
        ),
      });

      return {
        ...state,
        selectedOrg: updatedOrg,
      };
    }

    case adminContractListAction.types.request:
      return {
        ...state,
        contractListLoading: true,
        contractList: [],
      };
    case adminContractListAction.types.success:
      return {
        ...state,
        contractListLoading: false,
        contractList: (payload.result as {}[]).map(
          (contract) => new ContractModel(contract),
        ),
      };

    case adminContractCreateAction.types.request:
    case adminContractUpdateAction.types.request:
      return {
        ...state,
        contractCreateLoading: true,
        contractCreateError: false,
      };
    case adminContractCreateAction.types.failure:
    case adminContractUpdateAction.types.failure:
      return {
        ...state,
        contractCreateLoading: false,
        contractCreateError: true,
      };
    case adminContractCreateAction.types.success:
    case adminContractUpdateAction.types.success: {
      const newContract = new ContractModel(payload.result);

      const updateIndex = state.contractList.findIndex(
        (c) => c.id === newContract.id,
      );

      const contractList = [...state.contractList];

      if (updateIndex > -1) {
        contractList.splice(updateIndex, 1, newContract);
      } else {
        contractList.unshift(newContract);
      }

      return {
        ...state,
        contractList,
        contractCreateLoading: false,
      };
    }

    case adminContractDeleteAction.types.request:
      return {
        ...state,
        contractDeleteLoading: true,
      };
    case adminContractDeleteAction.types.failure:
      return {
        ...state,
        contractDeleteLoading: false,
      };
    case adminContractDeleteAction.types.success: {
      const contractList = state.contractList.filter(
        (c) => c.id !== (action.payload as number),
      );

      return {
        ...state,
        contractDeleteLoading: false,
        contractList,
      };
    }

    case adminExamCopyOrgGetAction.types.request:
      return {
        ...state,
        copyExamOrgDetails: new OrganizationModel(),
        copyExamOrgDetailsLoading: true,
      };
    case adminExamCopyOrgGetAction.types.success:
      return {
        ...state,
        copyExamOrgDetails: new OrganizationModel(payload.result),
        copyExamOrgDetailsLoading: false,
      };

    case adminExamCopyAction.types.request:
      return { ...state, copyExamLoading: true };

    case adminExamCopyAction.types.failure:
    case adminExamCopyAction.types.success:
      return { ...state, copyExamLoading: false };

    case adminProjectMemberDeleteAction.types.request: {
      const { projectIds } = (
        action as Action & {
          payload: { userId: number; projectIds: number[] };
        }
      ).payload;

      return {
        ...state,
        ...{
          deleteMember: {
            loading: true,
            error: false,
            count: projectIds.length,
          },
        },
      };
    }
    case adminProjectMemberDeleteAction.types.success: {
      return {
        ...state,
        ...{
          deleteMember: {
            loading: false,
            error: false,
            count: 0,
          },
        },
      };
    }
    case projectMemberDeleteAction.types.success: {
      const { count, ...rest } = state.deleteMember;
      return {
        ...state,
        ...{
          deleteMember: {
            ...rest,
            count: Math.max(count - 1, 0),
          },
        },
      };
    }
    case projectMemberDeleteAction.types.failure: {
      return {
        ...state,
        ...{
          deleteMember: {
            loading: false,
            error: true,
            count: 0,
          },
        },
      };
    }

    case adminChallengeCollectionListAction.types.request: {
      return {
        ...state,
        challengeCollectionError: false,
        challengeCollectionLoading: true,
        challengeCollectionFilters: payload,
      };
    }

    case adminChallengeCollectionListAction.types.success: {
      // TODO strong types
      const { result = [], pagination } = (
        action as APIResponseAction & {
          payload: {
            result: AdminChallengeCollectionListModel[];
            pagination: PaginationModel;
          };
        }
      ).payload;
      return {
        ...state,
        challengeCollectionList: result,
        challengeCollectionListPagination: new PaginationModel(pagination),
        challengeCollectionError: false,
        challengeCollectionLoading: false,
      };
    }

    case adminChallengeCollectionGetAction.types.request: {
      return {
        ...state,
        challengeCollectionError: false,
        challengeCollectionLoading: true,
      };
    }

    case adminChallengeCollectionGetAction.types.success: {
      const collection = payload.result as AdminChallengeCollectionModel;
      const index = state.challengeCollectionDetails.findIndex(
        (item) => item.id === collection.id,
      );
      return {
        ...state,
        challengeCollectionError: false,
        challengeCollectionLoading: false,
        challengeCollectionDetails:
          index === -1
            ? [...state.challengeCollectionDetails, collection]
            : [
                ...state.challengeCollectionDetails.slice(0, index),
                collection,
                ...state.challengeCollectionDetails.slice(index + 1),
              ],
      };
    }

    case adminChallengeCollectionCreateAction.types.request:
    case adminChallengeCollectionUpdateAction.types.request:
    case adminChallengeCollectionDeleteAction.types.request: {
      return {
        ...state,
        challengeCollectionSubmitting: true,
        challengeCollectionError: false,
      };
    }

    case adminChallengeCollectionCreateAction.types.success:
    case adminChallengeCollectionUpdateAction.types.success:
    case adminChallengeCollectionDeleteAction.types.success: {
      return {
        ...state,
        challengeCollectionSubmitting: false,
        challengeCollectionError: false,
      };
    }

    case adminChallengeCollectionListAction.types.failure:
    case adminChallengeCollectionGetAction.types.failure:
    case adminChallengeCollectionCreateAction.types.failure:
    case adminChallengeCollectionUpdateAction.types.failure:
    case adminChallengeCollectionDeleteAction.types.failure: {
      return {
        ...state,
        challengeCollectionError: true,
        challengeCollectionLoading: false,
        challengeCollectionSubmitting: false,
      };
    }

    case adminOfficialExamAllListGetAction.types.request: {
      return { ...state, officialExamListLoading: true };
    }

    case adminOfficialExamAllListGetAction.types.success: {
      const { hasNextPage, exams, reset } = (
        action as APIResponseAction & {
          payload: {
            hasNextPage: boolean;
            reset: boolean;
            exams: OfficialExamListModel[];
          };
        }
      ).payload;
      const officialExamListChunk = reset
        ? exams
        : [...state.officialExamListChunk, ...exams];
      return {
        ...state,
        officialExamList: hasNextPage
          ? state.officialExamList
          : officialExamListChunk,
        officialExamListChunk: hasNextPage ? officialExamListChunk : [],
        officialExamListLoading: hasNextPage,
        officialExamListError: false,
      };
    }

    case adminOfficialExamAllListGetAction.types.failure: {
      return {
        ...state,
        officialExamListLoading: false,
        officialExamListError: true,
      };
    }

    case adminExamDeactivateAction.types.request:
    case adminExamReactivateAction.types.request: {
      return {
        ...state,
        deleteExam: {
          submitting: true,
          error: false,
        },
      };
    }

    case adminExamDeactivateAction.types.success:
    case adminExamReactivateAction.types.success: {
      return { ...state, deleteExam: { submitting: false, error: false } };
    }

    case adminExamDeactivateAction.types.failure:
    case adminExamReactivateAction.types.failure: {
      return { ...state, deleteExam: { submitting: false, error: true } };
    }

    case allActionsGetAction.types.success: {
      return {
        ...state,
        actions:
          (payload.result as EnumModel[]).sort(
            (a, b) => a.displayOrder - b.displayOrder,
          ) ?? [],
      };
    }

    case allTiersGetAction.types.success: {
      return {
        ...state,
        tiers:
          (payload.result as EnumModel[]).sort(
            (a, b) => a.displayOrder - b.displayOrder,
          ) ?? [],
      };
    }

    case adminSSOGetAction.types.request: {
      return { ...state, ssoSettingLoading: true };
    }

    case adminSSOGetAction.types.success: {
      return {
        ...state,
        ssoSetting: payload.result as SSOModel,
        ssoSettingLoading: false,
      };
    }

    case adminSSOGetAction.types.failure: {
      return { ...state, ssoSettingLoading: false };
    }

    case adminSSOMetaDeleteAction.types.request:
    case adminSSOMetaUploadAction.types.request:
    case adminSSOUpdateAction.types.request: {
      return { ...state, ssoSettingSubmitting: true };
    }

    case adminSSOMetaDeleteAction.types.success:
    case adminSSOMetaUploadAction.types.success:
    case adminSSOUpdateAction.types.success:
    case adminSSOMetaDeleteAction.types.failure:
    case adminSSOMetaUploadAction.types.failure:
    case adminSSOUpdateAction.types.failure: {
      return { ...state, ssoSettingSubmitting: false };
    }

    default:
      return state;
  }
};
