import { AjaxError, AjaxResponse } from "rxjs";

export const API_SERVER_RESPONSE_KEYS = Object.freeze([
  "INSTANCE_ID",
  "DB_CONNECTED",
  "REDIS_CONNECTED",
  "LAST_HEALTH_CHECK",
  "FREE_BYTES",
  "TOTAL_BYTES",
  "PERCENT_USED",
]);

export const TEST_SERVER_RESPONSE_KEYS = Object.freeze([
  "connected_clients",
  "containers",
  "disk_status",
  "image_count",
  "instance_id",
  "num_goroutines",
]);

export function isAPIServerResponseValid(
  status: number,
  responseType: string,
  response: string,
) {
  if (status !== 200 || responseType !== "text") {
    return false;
  }
  return (
    API_SERVER_RESPONSE_KEYS.filter((key) => response.includes(key)).length ===
    API_SERVER_RESPONSE_KEYS.length
  );
}

export function isContentServerResponseValid(
  status: number,
  responseType: string,
  response: { message: string },
) {
  if (status !== 200 || responseType !== "json") {
    return false;
  }
  return response.message === "good luck";
}

export function isImageServerResponseValid(
  status: number,
  responseType: string,
  response: { message: string },
) {
  if (status !== 200 || responseType !== "json") {
    return false;
  }
  return response.message === "good luck";
}

export function isTestServerResponseValid(
  status: number,
  responseType: string,
  response: {},
) {
  if (status !== 200 || responseType !== "json") {
    return false;
  }
  const responseKeys = Object.keys(response);
  return (
    TEST_SERVER_RESPONSE_KEYS.filter((key) => responseKeys.includes(key))
      .length === TEST_SERVER_RESPONSE_KEYS.length
  );
}

export function getErrorMessage(data?: {}) {
  if (data instanceof AjaxError) {
    return `${data.message}: ${
      data.xhr && data.xhr.response ? JSON.stringify(data.xhr.response) : ""
    }`;
  }
  if (data instanceof AjaxResponse) {
    return `${data.responseText}: ${
      data.xhr && data.xhr.response ? JSON.stringify(data.xhr.response) : ""
    }`;
  }
  return JSON.stringify(data);
}
