import { Epic } from "redux-observable";
import { Observable } from "rxjs/Observable";
import { ajax } from "rxjs/observable/dom/ajax";

import {
  Action,
  healthCheckAction,
  apiServerHealthAction,
  orcaServerHealthAction,
  orcaServerWsHealthAction,
  contentsServerHealthAction,
  imageServerHealthAction,
} from "@actions";

import { RootState } from "@reducers";

import {
  isAPIServerResponseValid,
  isTestServerResponseValid,
  isContentServerResponseValid,
  isImageServerResponseValid,
} from "@shared/services/healthCheck";
import Logger from "@shared/services/logger";

const healthCheckEpic: Epic<Action, RootState> = (action$, state) =>
  action$
    .ofType(healthCheckAction.types.request)
    .flatMap((action) => [
      apiServerHealthAction.request(),
      orcaServerHealthAction.request(),
      contentsServerHealthAction.request(),
      imageServerHealthAction.request(),
    ]);

const apiServerHealthEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(apiServerHealthAction.types.request).mergeMap((action) =>
    ajax({
      method: "get",
      url: `/api/health?t=${new Date().getTime()}`,
      withCredentials: true,
      responseType: "text",
    })
      .map((response) => {
        const { status, responseType, response: data } = response;
        return isAPIServerResponseValid(status, responseType, data)
          ? apiServerHealthAction.success()
          : apiServerHealthAction.failure(response);
      })
      .catch((error) => {
        return Observable.of(apiServerHealthAction.failure(error));
      }),
  );

const orcaServerHealthEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(orcaServerHealthAction.types.request).mergeMap((action) =>
    ajax({
      method: "get",
      url: `https://${
        process.env.REACT_APP_ORCA_BACKEND_HOST
      }/api/health?t=${new Date().getTime()}`,
      crossDomain: true,
      withCredentials: true,
      responseType: "json",
    })
      .map((response) => {
        const { status, responseType, response: data } = response;
        return isTestServerResponseValid(status, responseType, data)
          ? orcaServerHealthAction.success()
          : orcaServerHealthAction.failure(response);
      })
      .catch((error) => {
        return Observable.of(orcaServerHealthAction.failure(error));
      }),
  );

// See this issue for more details
// https://github.com/givery-technology/codecheck-frontend/issues/2428
const orcaServerHealthDoneEpic: Epic<Action, RootState> = (action$, state) =>
  action$
    .ofType(
      orcaServerHealthAction.types.success,
      orcaServerHealthAction.types.failure,
    )
    .map((action) => orcaServerWsHealthAction.request());

const orcaServerWsHealthEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(orcaServerWsHealthAction.types.request).mergeMap((action) => {
    const promise = new Promise<void>((resolve, reject) => {
      // MEMO: Move to shared service when we user websocket widely.
      const webSocket = new WebSocket(
        `wss://${
          process.env.REACT_APP_ORCA_BACKEND_HOST
        }/socket?t=${new Date().getTime()}`,
      );
      webSocket.addEventListener("open", () => {
        Logger.info("Open ws connection");
        resolve();
        webSocket.close();
      });
      webSocket.addEventListener("close", () => {
        Logger.info("Close ws connection");
      });
      webSocket.addEventListener("error", (error: {}) => {
        Logger.info("Failed ws connection", error);
        reject(error);
      });
    });

    return Observable.from(promise)
      .map(() => orcaServerWsHealthAction.success())
      .catch((error) => Observable.of(orcaServerWsHealthAction.failure(error)));
  });

const contentsServerHealthEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(contentsServerHealthAction.types.request).mergeMap((action) =>
    ajax({
      method: "get",
      url: `https://${
        process.env.REACT_APP_CONTENTS_SERVER_HOST
      }/health/ping?t=${new Date().getTime()}`,
      crossDomain: true,
      responseType: "json",
    })
      .map(({ status, responseType, response }) => {
        return isContentServerResponseValid(status, responseType, response)
          ? contentsServerHealthAction.success()
          : contentsServerHealthAction.failure(response);
      })
      .catch((error) => {
        return Observable.of(contentsServerHealthAction.failure(error));
      }),
  );

const imageServerHealthEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(imageServerHealthAction.types.request).mergeMap((action) =>
    ajax({
      method: "get",
      url: `https://${
        process.env.REACT_APP_IMAGE_SERVER_HOST
      }/ping.json?t=${new Date().getTime()}`,
      crossDomain: true,
      responseType: "json",
    })
      .map(({ status, responseType, response }) => {
        return isImageServerResponseValid(status, responseType, response)
          ? imageServerHealthAction.success()
          : imageServerHealthAction.failure(response);
      })
      .catch((error) => {
        return Observable.of(imageServerHealthAction.failure(error));
      }),
  );

export default [
  healthCheckEpic,
  apiServerHealthEpic,
  orcaServerHealthEpic,
  orcaServerHealthDoneEpic,
  orcaServerWsHealthEpic,
  contentsServerHealthEpic,
  imageServerHealthEpic,
];
