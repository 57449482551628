import * as classnames from "classnames";

/**
 * Prop interface
 */
export interface TableProps {
  hoverable?: boolean;
  bordered?: boolean;
  insideBordered?: boolean;
  narrow?: boolean;
  fullwidth?: boolean;
  className?: string;
  theme?: "gray-light";
  children?: {};
}

/**
 * React Component
 */
export default function Table({
  bordered = true,
  insideBordered = false,
  hoverable = true,
  fullwidth = true,
  narrow = false,
  className,
  theme,
  children,
}: TableProps) {
  const rootStyle = classnames("code-c-table", {
    [`${className}`]: Boolean(className),
    "is-bordered": bordered,
    [`is-${theme}`]: Boolean(theme),
  });

  const tableStyle = classnames("table", {
    "is-fullwidth": fullwidth,
    "is-hoverable": hoverable,
    "is-bordered": bordered,
    "is-narrow": narrow,
    "is-inside-bordered": insideBordered,
    [`is-${theme}`]: Boolean(theme),
  });

  return (
    <div className={rootStyle} data-track-scroll-reset>
      <table className={tableStyle}>{children}</table>
    </div>
  );
}
