import { ApplicantExamStatus } from "../services/enums";

class ApplicantInitialModel {
  public id: number;
  public applicantId: number;
  public examId: number;
  public organizationId: number;
  public organizationImageUrl: string;
  public organizationName: string;
  public examName: string;
  public urlToken: string;
  public status: ApplicantExamStatus;
  public openedAt: string;

  constructor(args?: {}) {
    Object.assign(this, args);
  }
}

export default ApplicantInitialModel;
