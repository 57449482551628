import { Epic } from "redux-observable";
import { AjaxError } from "rxjs";

import {
  Action,
  ajaxAction,
  addToPayload,
  applicantExamTimeLeftAction,
  challengeTimeLeftAction,
} from "../actions";
import { RootState } from "../reducers";

export const applicantExamTimeLeftEpic: Epic<Action, RootState> = (
  action$,
  state,
) =>
  action$.ofType(applicantExamTimeLeftAction.types.request).map((action) =>
    ajaxAction.request({
      method: "get",
      url: `/api/applicants/exams/${
        state.getState().applicant.initial.id
      }/timeleft`,
      success: applicantExamTimeLeftAction.success,
      error: applicantExamTimeLeftAction.failure,
      options: { background: true, disableDefaultError: true },
    }),
  );

export const challengeTimeLeftEpic: Epic<Action, RootState> = (
  action$,
  state,
) =>
  action$.ofType(challengeTimeLeftAction.types.request).map((action) =>
    ajaxAction.request({
      method: "get",
      url: `/api/applicants/exams/${
        state.getState().applicant.initial.id
      }/results/${action.params}/timeleft`,
      success: addToPayload(
        { challengeId: action.payload },
        challengeTimeLeftAction.success,
      ),
      error: (error: AjaxError) =>
        challengeTimeLeftAction.failure({
          // To let the app know we've extended the time limit
          // https://github.com/givery-technology/codecheck-frontend/pull/2634
          result: -1,
          challengeId: action.payload,
          extra: error,
        }),
      options: { background: true, disableDefaultError: true },
    }),
  );

export default [challengeTimeLeftEpic, applicantExamTimeLeftEpic];
