import {
  Checkbox,
  ErrorIcon,
  FormGroup,
  Msg,
  Tooltip,
  ValidationMessage,
} from "@shared/components";
import { ProjectRole, TierAction } from "@shared/services/enums";
import { isRoleAllowed } from "@shared/services/tier";

import { PROJECT_MEMBER_ROLES } from "..";

export type InjectedProps = {
  isTierActionAllowed: (tierAction: TierAction) => boolean;
};

export type ExternalProps = {
  initialValues: number[];
  name?: string;
  errors?: { [key: string]: string };
  isExpired?: boolean;
  onFormChange: (roles: number[]) => void;
};

export type MemberRoleFormProps = ExternalProps & InjectedProps;

export function MemberRoleForm({
  name,
  errors,
  initialValues = [],
  isExpired,
  isTierActionAllowed,
  onFormChange,
}: MemberRoleFormProps) {
  const isNoLongerAvailable = (role: number) =>
    initialValues.includes(role) &&
    !isRoleAllowed({ role, isTierActionAllowed });

  const isIncludedInAdmin = (role: number) =>
    initialValues.includes(ProjectRole.ProjectAdmin) &&
    role !== ProjectRole.ProjectAdmin;

  const validTier = (role: number) =>
    isRoleAllowed({ role, isTierActionAllowed }) || isNoLongerAvailable(role);
  const onChange = (role: number, checked: boolean) => {
    const roles = checked
      ? role === ProjectRole.ProjectAdmin
        ? [role]
        : [...initialValues, role]
      : [...initialValues].filter((item) => item !== role);

    onFormChange([...roles.sort()]);
  };

  return (
    <FormGroup className="code-member-role-form">
      {PROJECT_MEMBER_ROLES.map((role, index) => (
        <div key={index} className="code-member-role-form__item">
          <Tooltip
            placement="top-start"
            text={<Msg id="tier.disabled" />}
            disabled={validTier(role)}
          >
            <Checkbox
              name={ProjectRole[role]}
              value={initialValues.includes(role)}
              className={!validTier(role) ? "invalid-tier" : ""}
              onChange={(e) => onChange(role, e.currentTarget.checked)}
              readOnly={
                (!initialValues.includes(role) &&
                  !isRoleAllowed({ role, isTierActionAllowed })) ||
                isIncludedInAdmin(role) ||
                isExpired
              }
            >
              {ProjectRole.toString(role)}
            </Checkbox>
          </Tooltip>
          {isNoLongerAvailable(role) && (
            <ErrorIcon tooltipText={<Msg id="tier.disabled.notSupported" />} />
          )}
        </div>
      ))}
      <ValidationMessage name={name} error={errors} />
    </FormGroup>
  );
}
