import * as classnames from "classnames";

import { Tag } from "../..";

/**
 * Prop interface
 */
export interface EnvTagProps {
  envString: string;
}

/**
 * React Component
 */
export function EnvTag({ envString }: EnvTagProps) {
  const rootStyle = classnames("code-c-env-tag", {
    [`is-${envString}`]: Boolean(envString),
  });
  return envString && envString.toLowerCase() !== "prod" ? (
    <Tag className={rootStyle}>{envString}</Tag>
  ) : null;
}
