import * as classnames from "classnames";
import { parseISO } from "date-fns";
import ja from "date-fns/locale/ja";
import * as React from "react";
import ReactDatePicker, {
  ReactDatePickerProps,
  registerLocale,
} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { ErrorMessage, ValidationMessage, hasValidationError } from "..";
import {
  dayjs,
  isValidDate,
  isValidDate_datepicker,
} from "../../services/date";
import MSG from "../../services/message";
import { ReadOnlyCustomInput } from "./partials/ReadOnlyCustomInput";

registerLocale("ja", ja);

/**
 * Enums
 */
export enum DatePickerType {
  Date,
  DateTime,
}

/**
 * Prop interface
 */
export interface DatePickerProps {
  id?: string;
  name?: string;
  value?: string;
  type?: DatePickerType;
  disabled?: boolean;
  allowPast?: boolean;
  className?: string;
  inputClassName?: string;
  error?: { [key: string]: string };
  errorMessage?: string;
  locale?: string;
  minDate?: string;
  maxDate?: string;
  readOnly?: boolean;
  adjustPastDateToPresent?: boolean;
  showYearDropdown?: boolean;
  highlightDates?: string[];
  timeIntervals?: number;

  /* For DateRangePicker */
  selectsStart?: boolean;
  selectsEnd?: boolean;
  startDate?: string;
  endDate?: string;

  onChange?: (date: string) => void;
  onBlur?: (e: React.FormEvent<HTMLInputElement>) => void;
}

/**
 * React Component
 */
export default function DatePicker({
  id,
  name,
  value = "",
  type = DatePickerType.Date,
  disabled,
  allowPast = false,
  readOnly = false,
  onBlur,
  onChange = () => void 0,
  error,
  errorMessage,
  adjustPastDateToPresent,
  showYearDropdown,
  className,
  inputClassName,
  locale = "ja",
  minDate = "",
  maxDate = "",
  timeIntervals = 15,
  highlightDates,
  selectsStart,
  selectsEnd,
  startDate,
  endDate,
}: DatePickerProps) {
  const datePickerRef = React.useRef<ReactDatePicker>(null);
  const rootStyle = classnames("code-c-date-picker", {
    [`${className}`]: Boolean(className),
  });

  const inputStyle = classnames("code-c-date-picker__input", "input", {
    "is-danger": errorMessage || hasValidationError(name, error),
    [`${inputClassName}`]: Boolean(inputClassName),
  });

  const options: Partial<ReactDatePickerProps> = {};

  if (type === DatePickerType.DateTime) {
    options.showTimeSelect = true;
    options.dateFormat = "yyyy/MM/dd HH:mm";
    options.timeFormat = "HH:mm";
    options.timeIntervals = timeIntervals;
    options.timeCaption = MSG.getMessageByKey("time");
  } else if (type === DatePickerType.Date) {
    options.dateFormat = "yyyy/MM/dd";
  }

  options.dateFormatCalendar = locale === "ja" ? "yyyy年 M月" : "yyyy/MM";

  options.minDate = isValidDate_datepicker(minDate)
    ? parseISO(minDate)
    : allowPast
    ? null
    : new Date();

  if (
    !allowPast &&
    isValidDate_datepicker(value) &&
    isValidDate_datepicker(minDate)
  ) {
    if (dayjs(value).isSame(dayjs(minDate), "day")) {
      options.minTime = parseISO(minDate);
      options.maxTime = new Date(dayjs().endOf("day").toString());
    } else if (dayjs(value).isBefore(dayjs(minDate), "day")) {
      options.minTime = new Date(dayjs().endOf("day").toString());
      options.maxTime = new Date(dayjs().endOf("day").toString());
    }
  }

  if (highlightDates) {
    options.highlightDates = highlightDates
      .filter((date) => isValidDate_datepicker(date))
      .map((date) => new Date(date));
  }

  if (maxDate && isValidDate_datepicker(maxDate)) {
    options.maxDate = parseISO(maxDate);
  }

  if (adjustPastDateToPresent && parseISO(value) < new Date()) {
    const d = new Date();
    d.setMinutes(d.getMinutes() + 5);
    options.openToDate = d;
  }

  if (selectsStart || selectsEnd) {
    options.selectsStart = selectsStart;
    options.selectsEnd = selectsEnd;
    options.startDate =
      startDate && isValidDate(startDate) ? parseISO(startDate) : undefined;
    options.endDate =
      endDate && isValidDate(endDate) ? parseISO(endDate) : undefined;
  }

  const onChangeHandler = (inputValue: Date) => {
    if (isValidDate_datepicker(inputValue)) {
      onBeforeChange(dayjs(inputValue).format());
    }
  };

  const onChangeRawHandler = (inputValue: string) => {
    if (isValidDate_datepicker(inputValue)) {
      onBeforeChange(dayjs(inputValue).format());
    } else {
      onBeforeChange(inputValue);
    }
  };

  const onBeforeChange = (inputValue: string) => {
    onChange(inputValue);
  };

  // For this issue
  // Date picker will display twice if you try to press tab after selecting a date.
  // - Jira https://givery-technology.atlassian.net/browse/TRAC-1523
  // I hope react-datepicker will fix it on their side.
  const onKeyDownHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { shiftKey, key } = e;
    if (shiftKey && key === "Tab") {
      datePickerRef.current?.setOpen(false, true);
    }
  };

  return (
    <div className={rootStyle}>
      <ReactDatePicker
        id={id}
        ref={datePickerRef}
        className={inputStyle}
        name={name}
        selected={isValidDate_datepicker(value) ? parseISO(value) : undefined}
        value={isValidDate_datepicker(value) ? undefined : value}
        onChange={(date: Date) => onChangeHandler(date)}
        onChangeRaw={(e: React.FormEvent<HTMLInputElement>) => {
          const value = (e.target as HTMLInputElement).value;
          if (typeof value !== "undefined") {
            onChangeRawHandler(value);
          }
        }}
        onCalendarClose={() => {
          // https://github.com/Hacker0x01/react-datepicker/issues/2028
          // onBlur is not called after selecting a value on the pop up calendar
          // added this as a workaround
          // only target.name is needed in our validation
          onBlur?.({
            target: {
              name: name || "",
            },
          } as unknown as React.FormEvent<HTMLInputElement>);
        }}
        onBlur={onBlur}
        disabled={disabled}
        showYearDropdown={showYearDropdown}
        scrollableYearDropdown={showYearDropdown}
        dropdownMode="select"
        showMonthDropdown={true}
        placeholderText={(options.dateFormat as string).toUpperCase()}
        onKeyDown={onKeyDownHandler}
        locale={locale}
        autoComplete={"off"}
        fixedHeight={true}
        {...(readOnly && {
          customInput: (
            <ReadOnlyCustomInput id={id} name={name} options={options} />
          ),
        })}
        {...options}
      />
      <ValidationMessage name={name} error={error} />
      <ErrorMessage message={errorMessage} />
    </div>
  );
}
