import * as classnames from "classnames";
import { isEmpty } from "lodash";
import * as React from "react";

import { Icon, Modal, Msg, Tag, VersionCodeTag } from "@shared/components";
import { useExamPermissions } from "@shared/hooks";
import {
  useCreateSubmissionCsv,
  useExam,
  useRescoreSubmission,
} from "@shared/hooks/query";
import { useResetSubmission } from "@shared/hooks/query/submissions/useSubmissionReset";
import {
  ModalStep,
  MultiManageForm,
  VersionType,
  ActionType,
} from "@shared/models";
import { hasPastDeadlineSubmissions } from "@shared/services/challengeStatus";
import {
  ChallengeStyle,
  Encoding,
  ExamType,
  SubmissionListKind,
} from "@shared/services/enums";
import Message from "@shared/services/message";

import { SubmissionDownloadModal } from "..";
import {
  MultipleManageModalAction,
  MultipleManageModalVersion,
  MultipleManageModalChallenge,
  MultipleManageModalConfirm,
  MultipleManageModalSubmissions,
  FormStateBox,
} from "./partials";

/**
 * Interface
 */
export type MultipleManageModalProps = {
  isOpen: boolean;
  submissionListKind: SubmissionListKind;
  onClickOk: () => void;
  onClickCancel: () => void;
};

const ariaLabel = {
  action: "Select Action",
  version: "Select Version",
  challenge: "Select Challenge",
  submissions: "Select Submissions",
  confirm: "Confirm",
};

const modalTitle = {
  action: "submission.selectAction",
  version: "submission.selectVersion",
  challenge: "submission.selectChallenge",
  submissions: "submission.selectSubmissions",
  confirm: "action.confirm",
};

/**
 * Component
 * @param props MultipleManageModalProps
 */
export function MultipleManageModal({
  isOpen,
  submissionListKind,
  onClickOk,
  onClickCancel,
}: MultipleManageModalProps) {
  const rootStyle = classnames("code-multiple-manage-modal");

  const getModalSize = (step: ModalStep) => {
    switch (step) {
      case ModalStep.challenge:
        return "large";
      case ModalStep.submissions:
      case ModalStep.confirm:
        return "full";
      default:
        return undefined;
    }
  };

  const {
    data: { examChallenges, examDetail },
  } = useExam();
  const { canDownloadEnhancedCsv } = useExamPermissions();
  const downloadCsv = useCreateSubmissionCsv();
  const rescoreSubmissions = useRescoreSubmission();
  const resetSubmissions = useResetSubmission();

  /**
   * States
   */
  const [step, setStep] = React.useState<ModalStep>(ModalStep.action);
  const [formValue, setFormValue] = React.useState<MultiManageForm>({});
  const [formErrors, setFormErrors] = React.useState<{} | false>({});
  const [isEncodingOpen, setIsEncodingOpen] = React.useState<boolean>(false);
  const { submissions, challenge, version, action, deadline } = formValue;
  const invalidSubmissions =
    hasPastDeadlineSubmissions(submissions) && !deadline;

  /**
   * Private Functions
   */
  const onFormChange = (value: MultiManageForm, errors?: {}) => {
    setFormValue({
      ...formValue,
      ...value,
    });
    if (errors !== undefined) {
      setFormErrors(errors);
    }
  };

  const onClickNext = () => {
    if (step === ModalStep.confirm) {
      if (action === ActionType.Reset) {
        resetSubmissions.mutate({
          applicantExamIds:
            submissions?.map((submission) => submission.id) || [],
          challengeIds: challenge?.challengeId ? [challenge.challengeId] : [],
          useLatestVersions: version === VersionType.New,
          examDeadline: deadline,
        });
      } else {
        if (challenge) {
          rescoreSubmissions.mutate({
            examId: examDetail.id,
            applicantExamIds:
              submissions?.map((submission) => submission.id) || [],
            rescoreExam: true,
            useLatestVersion: version === VersionType.New,
            challengeId: challenge?.challengeId,
          });
        }
      }
      onClickOk();
    } else {
      setStep(step + 1);
    }
  };

  const onClickBack = () => {
    if (step === ModalStep.action) {
      onClickCancel();
    } else {
      if (step === ModalStep.submissions) {
        setFormValue({
          ...formValue,
          submissions: undefined,
        });
      } else if (step === ModalStep.challenge) {
        setFormValue({
          ...formValue,
          challenge: undefined,
        });
      }
      setStep(step - 1);
    }
  };

  const onDownloadCsv = (
    type: string,
    challengeIds: number[],
    columns: number[],
    encoding: Encoding,
    language: string,
  ) => {
    downloadCsv.mutate({
      examId: examDetail.id,
      applicantExamIds: submissions?.map((submission) => submission.id),
      type,
      encoding,
      language,
      challengeIds,
      columns,
    });
    setIsEncodingOpen(false);
  };

  const ChallengeBar = () =>
    challenge ? (
      <div className="target-challenge-container">
        <p>{challenge.title}</p>
        <VersionCodeTag version={`${challenge.usedChallengeVersionCode}`} />
        <Tag>{ChallengeStyle.toString(challenge.style)}</Tag>
        <div>
          <Icon type="clock-o" />
          {!challenge.timeLimitMinutes ? (
            <Msg id={"noLimit"} />
          ) : (
            challenge.timeLimitMinutes
          )}
        </div>
      </div>
    ) : null;

  const isDisableOk = (step: ModalStep) => {
    const currentValue = formValue[ModalStep[step]];

    return Boolean(
      step === ModalStep.confirm
        ? action === ActionType.Reset
          ? invalidSubmissions || !isEmpty(formErrors)
          : false
        : !(
            currentValue &&
            (Array.isArray(currentValue) ? currentValue.length > 0 : true)
          ),
    );
  };

  /**
   * Render
   */
  return (
    <Modal
      ariaLabel={ariaLabel[ModalStep[step]]}
      cancelButtonAriaLabel={step === ModalStep.action ? "Cancel" : "Back"}
      cancelButtonLabel={Message.getMessageByKey(
        step === ModalStep.action ? "cancel" : "action.back",
      )}
      className={rootStyle}
      disableOk={isDisableOk(step)}
      isOpen={isOpen}
      okButtonAriaLabel={step === ModalStep.confirm ? "Submit" : "Next"}
      okButtonLabel={
        <>
          <Msg
            id={step === ModalStep.confirm ? "action.submit" : "action.next"}
          />
          {step === ModalStep.submissions &&
            Number(submissions?.length) > 0 && (
              <span className="code-multiple-manage-modal__count">
                {`(${submissions?.length})`}
              </span>
            )}
        </>
      }
      okButtonType={step === ModalStep.confirm ? "danger" : "primary"}
      onClickCancel={onClickBack}
      onClickOk={onClickNext}
      onClose={onClickCancel}
      title={
        <>
          <span className="title-step">{`${step}/${
            Object.keys(ModalStep).length / 2
          }`}</span>
          <Msg id={modalTitle[ModalStep[step]]} />
        </>
      }
      size={getModalSize(step)}
    >
      {[ModalStep.version, ModalStep.challenge, ModalStep.submissions].includes(
        step,
      ) && (
        <FormStateBox formValues={formValue} challengeBar={<ChallengeBar />} />
      )}
      <MultipleManageModalAction
        isShow={step === ModalStep.action}
        formValues={formValue}
        onFormChange={(valid, { action }, errors) =>
          onFormChange(action ? { action: Number(action) } : {})
        }
      />
      <MultipleManageModalVersion
        isShow={step === ModalStep.version}
        formValues={formValue}
        onFormChange={(valid, { version }, errors) =>
          onFormChange(version ? { version: Number(version) } : {})
        }
      />
      <MultipleManageModalChallenge
        isShow={step === ModalStep.challenge}
        formValues={formValue}
        onFormChange={(valid, { challenge }, errors) =>
          onFormChange(challenge ? { challenge } : {})
        }
        examChallenges={examDetail.getMultiLangChallenges()}
        isMultiLang={examDetail.examType === ExamType.EnglishJapanese}
      />
      <MultipleManageModalSubmissions
        isShow={step === ModalStep.submissions}
        formValues={formValue}
        onFormChange={(valid, { submissions }, errors) =>
          onFormChange(submissions ? { submissions } : {})
        }
        submissionListKind={submissionListKind}
      />
      <MultipleManageModalConfirm
        isShow={step === ModalStep.confirm}
        formValues={formValue}
        onFormChange={(valid, { deadline }, errors) =>
          onFormChange({ deadline }, errors)
        }
        challengeBar={<ChallengeBar />}
        onClickDownloadCSV={() => setIsEncodingOpen(true)}
        invalidSubmissions={invalidSubmissions}
      />
      {isEncodingOpen && (
        <SubmissionDownloadModal
          isOpen={isEncodingOpen}
          onCloseModal={() => setIsEncodingOpen(false)}
          onClickOk={onDownloadCsv}
          examChallenges={examChallenges}
          examDetail={examDetail}
          hasEnhancedCSVDownloadAction={canDownloadEnhancedCsv}
        />
      )}
    </Modal>
  );
}
