import * as classnames from "classnames";
import * as React from "react";

/**
 * Prop interface
 */
export interface ColumnProps {
  size?: number;
  colSpan?: number;
  className?: string;
  children?: React.ReactNode;
  noPaddingX?: boolean;
  noPaddingLeft?: boolean;
  noPaddingRight?: boolean;
  onClick?: () => void;
  "aria-label"?: string;
}

/**
 * React Component
 */
export default function Column({
  colSpan,
  className,
  children,
  noPaddingX,
  noPaddingLeft,
  noPaddingRight,
  onClick,
  ["aria-label"]: ariaLabel,
}: ColumnProps) {
  const rootStyle = classnames("code-c-column", {
    [`${className}`]: Boolean(className),
    "no-padding-x": noPaddingX,
    "no-padding-right": noPaddingRight,
    "no-padding-left": noPaddingLeft,
  });

  return (
    <td
      className={rootStyle}
      colSpan={colSpan}
      onClick={onClick}
      aria-label={ariaLabel}
    >
      {children}
    </td>
  );
}
