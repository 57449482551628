import classNames from "classnames";
import * as React from "react";

interface TimelineProps {
  children: React.ReactNode;
  className?: string;
}

function Timeline({ children, className }: TimelineProps) {
  const rootStyle = classNames("code-c-timeline", className);

  return <ul className={rootStyle}>{children}</ul>;
}

export default Timeline;
