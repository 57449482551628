import {
  CategoryScale,
  Chart,
  LinearScale,
  BarElement,
  Align,
  ChartData,
} from "chart.js";
import ChartDataLabels, { Context } from "chartjs-plugin-datalabels";
import * as classnames from "classnames";
import { Bar } from "react-chartjs-2";

import { Msg } from "..";
import { ApplicantReportModel, ReportExamScore } from "../../models";
import { ChartColor } from "../../services/enums";
import Message from "../../services/message";

Chart.register(ChartDataLabels, LinearScale, CategoryScale, BarElement);

export interface SubmissionExamSummaryProps {
  className?: string;
  applicantDetails?: ApplicantReportModel;
  scores: ReportExamScore[];
}

export default function SubmissionExamSummary({
  className,
  applicantDetails,
  scores,
}: SubmissionExamSummaryProps) {
  if (!applicantDetails) {
    return null;
  }

  const rootStyle = classnames("code-c-submission-exam-summary", {
    [`${className}`]: Boolean(className),
  });

  /*
   * Graph Data
   */
  const data = scores.map((score) => score.frequency);

  const labels = scores.map(
    ({ leftClosedBoundary, rightClosedBoundary }) =>
      `${leftClosedBoundary}${
        rightClosedBoundary > leftClosedBoundary
          ? "-" + rightClosedBoundary
          : ""
      }${Message.getMessageByKey("report.points")}`,
  );

  const applicantFinalScore = applicantDetails
    ? applicantDetails.finalScore
    : NaN;

  const backgroundColor = scores.map(
    ({ leftClosedBoundary, rightClosedBoundary }) =>
      Math.floor(applicantFinalScore) >= leftClosedBoundary &&
      Math.floor(applicantFinalScore) <= rightClosedBoundary
        ? ChartColor.Green
        : ChartColor.Gray,
  );

  const outOfPeople = Message.getMessageByKey("report.outOfPeople").replace(
    "{0}",
    (applicantDetails && String(applicantDetails.submissionsNumber)) || "",
  );

  const yAxisMax = data.length ? Math.max(...data) * 1.2 : 10;

  const graphData: ChartData<"bar", number[], string> = {
    labels,
    datasets: [
      {
        yAxisID: "yAxis",
        datalabels: {
          align: (context: Context): Align => {
            const value = context.dataset.data[context.dataIndex];
            return typeof value === "number" && value / yAxisMax < 0.15
              ? "end"
              : "start";
          },
          color: (context: Context) => {
            const value = context.dataset.data[context.dataIndex];
            return typeof value === "number" && value / yAxisMax < 0.15
              ? "darkgray"
              : "white";
          },
          padding: (context: Context) => {
            const value = context.dataset.data[context.dataIndex];
            return {
              top:
                typeof value === "number" && value / yAxisMax < 0.15 ? -5 : 2,
            };
          },
          anchor: "end",
          clip: true,
          font: {
            weight: "bold",
          },
          formatter: (value: string) =>
            !Boolean(value)
              ? ""
              : value + Message.getMessageByKey("report.people"),
        },
        backgroundColor,
        data,
      },
    ],
  };

  /*
   * Graph Options
   */
  const options = {
    layout: {
      padding: {
        right: 25,
      },
    },
    animation: {
      duration: 0,
    },
    transitions: {
      active: {
        animation: {
          duration: 0,
        },
      },
    },
    scales: {
      yAxis: {
        suggestedMax: yAxisMax,
        beginAtZero: true,
        ticks: {
          precision: 0,
        },
      },
    },
    plugins: {
      datalabels: [ChartDataLabels] as any,
      tooltip: {
        enabled: false,
      },
    },
  };

  const grade = applicantDetails ? applicantDetails.grade : "-";
  const rank = applicantDetails ? applicantDetails.rank : "-";

  return (
    <div className={rootStyle}>
      <div className="submission-exam-summary__exam-grades__box graph">
        {/* Old chart.js version had this which makes the print preview format the way it is */}
        {/* https://github.com/givery-technology/codecheck-frontend/pull/4212#discussion_r1102297006 */}
        <div className="submission-exam-summary__chart-placeholder">
          <div className="submission-exam-summary__chart-placeholder__content"></div>
        </div>
        <div className="label">
          <Msg id="report.scoreDistribution" />
          <div className="submission-exam-summary__exam-legend">
            <span className="submission-exam-summary__exam-legend__color" />
            <Msg id="report.youAreHere" />
          </div>
        </div>
        <Bar data={graphData} options={options} />
      </div>
      <div className="submission-exam-summary__exam-grades__box score">
        <div className="label">
          <Msg id="report.totalScore" />
        </div>
        <span className="result-value">
          {applicantFinalScore}
          <span className="result-suffix">
            <Msg id="report.points" />
          </span>
        </span>
        <div>
          {`${Message.getMessageByKey("report.deviationScore")} ${
            applicantDetails && applicantDetails.finalScoreDevValue
          }`}
        </div>
      </div>
      <div className="submission-exam-summary__exam-grades__box grade">
        <div className="label">
          <Msg id="report.totalGrade" />
        </div>
        <span className={`result-value rank-${grade.toLowerCase()}`}>
          {grade}
        </span>
      </div>
      <div className="submission-exam-summary__exam-grades__box ranking">
        <div className="label">
          <Msg id="report.ranking" />
        </div>
        <span className="result-value">
          {rank}
          <span className="result-suffix">
            <Msg id="report.rank" />
          </span>
        </span>
        <div>{outOfPeople}</div>
      </div>
      <div className="submission-exam-summary__exam-grades__box averages">
        <table>
          <tbody>
            <tr>
              <td>
                <div className="label">
                  <Msg id="report.average" />
                </div>
              </td>
              <td className="within-exam">
                {applicantDetails && applicantDetails.submissionsAvgScore}
                <Msg id="report.points" />
              </td>
            </tr>
            <tr>
              <td>
                <div className="label">
                  <Msg id="report.max" />
                </div>
              </td>
              <td className="within-exam">
                {applicantDetails && applicantDetails.submissionsMaxScore}
                <Msg id="report.points" />
              </td>
            </tr>
            <tr>
              <td>
                <div className="label">
                  <Msg id="report.std" />
                </div>
              </td>
              <td className="within-exam">
                {applicantDetails && `${applicantDetails.submissionsStdDev}%`}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
