import classnames from "classnames";
import React, { forwardRef, useEffect, TextareaHTMLAttributes } from "react";

import ValidationMessage, {
  hasValidationError,
} from "../validationMessage/ValidationMessage";

// interface TextareaProps {
//   name: string;
//   value?: string;
//   onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
//   onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
//   onClick?: (e: React.FormEvent<HTMLTextAreaElement>) => void;
//   placeholder?: string;
//   className?: string;
//   textareaClassName?: string;
//   error?: { [key: string]: string };
//   counter?: React.ReactNode;
//   disabled?: boolean;
//   id?: string;
//   ariaLabel?: string;
//   readOnly?: boolean;
// }

interface TextareaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  textareaClassName?: string;
  error?: { [key: string]: string };
  counter?: React.ReactNode;
  ariaLabel?: string;
}

// Define a custom ref type that extends HTMLTextAreaElement with custom methods
export interface TextareaRef extends Partial<HTMLTextAreaElement> {
  focus?: () => void;
  getSelection?: () => [number, number];
  setSelection?: (start: number, end: number) => void;
  setValue?: (text: string) => void;
}

const Textarea = forwardRef<TextareaRef, TextareaProps>(
  (
    {
      name,
      value,
      onChange,
      onBlur,
      onClick,
      placeholder,
      className,
      textareaClassName,
      error,
      counter,
      disabled,
      id,
      ariaLabel,
      readOnly,
    },
    ref,
  ) => {
    const textareaRef = React.useRef<HTMLTextAreaElement | null>(null);

    const rootStyle = classnames("code-c-textarea", "control", {
      [`${className}`]: Boolean(className),
    });

    const textareaStyle = classnames("textarea", {
      [`${textareaClassName}`]: Boolean(textareaClassName),
      "is-danger": hasValidationError(name, error),
    });

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      resize();
      if (onChange) {
        onChange(e);
      }
    };

    const resize = () => {
      if (textareaRef.current) {
        textareaRef.current.style.height = "auto";
        textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
      }
    };

    useEffect(() => {
      resize();
    }, [value]);

    return (
      <div className={rootStyle}>
        <textarea
          aria-label={ariaLabel}
          id={id}
          ref={(element: HTMLTextAreaElement) => {
            if (typeof ref === "function") {
              ref(element);
            } else if (ref) {
              (
                ref as React.MutableRefObject<HTMLTextAreaElement | null>
              ).current = element;
            }
            textareaRef.current = element;
          }}
          name={name}
          value={value}
          onChange={handleChange}
          onBlur={onBlur}
          placeholder={placeholder}
          disabled={disabled}
          readOnly={readOnly}
          onClick={onClick}
          className={textareaStyle}
        />
        <div className="code-c-textarea__footer">
          <ValidationMessage name={name} error={error} />
          {counter}
        </div>
      </div>
    );
  },
);

Textarea.displayName = "Textarea";

export { Textarea };
