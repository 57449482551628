import React, { useEffect, useState } from "react";

import { ExpandContents, SwitchPanel } from "@shared/components";

/**
 * Prop interface
 */
export interface SwitchContainerProps {
  expanded?: boolean;
  name?: string;
  id?: string;
  value?: boolean;
  readOnly?: boolean;
  className?: string;
  contentsClassName?: string;
  onChange?: (event: React.FormEvent<HTMLInputElement>) => void;
  switchContent?: React.ReactNode;
  isStandardPanel?: boolean;
  children?: React.ReactNode;
  width?: string;
  maxWidth?: string;
  bordered?: boolean;
  dataTestautomationid: string;
}

/**
 * React Component
 */
export default function SwitchContainer({
  expanded,
  name,
  id,
  value,
  readOnly = false,
  onChange,
  switchContent,
  isStandardPanel, // If you're using the PanelStandardContents component this will align the switch with the first line of the content
  children,
  className,
  contentsClassName,
  width = "100%",
  maxWidth,
  bordered = true,
  dataTestautomationid,
}: SwitchContainerProps) {
  const [isExpanded, setIsExpanded] = useState(expanded ?? false);

  useEffect(() => {
    if (expanded !== undefined) {
      setIsExpanded(expanded);
    }
  }, [expanded]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event);
    }
    if (expanded === undefined) {
      setIsExpanded(event.target.checked);
    }
  };

  return (
    <div
      className="code-c-switch-container"
      id={id}
      style={{ width: width, maxWidth: maxWidth }}
    >
      <SwitchPanel
        value={value}
        readOnly={readOnly}
        hideErrorMessage
        name={name}
        initialLineHeight={isStandardPanel ? "1.5rem" : undefined}
        width="100%"
        isExpandedBelow={isExpanded}
        onChange={handleChange}
        className={className}
        dataTestautomationid={dataTestautomationid}
      >
        {switchContent}
      </SwitchPanel>
      <ExpandContents
        expanded={isExpanded}
        bordered={bordered}
        className={contentsClassName}
        readOnly={readOnly}
      >
        {children}
      </ExpandContents>
    </div>
  );
}
