import escapeStringRegexp from "escape-string-regexp";

const BYTES_KB = 1024;

export const filenameReservedRegex = /[<>:"/\\|?*~\u0000-\u001F]/i;
export const windowsReservedNameRegex = /^(con|prn|aux|nul|com\d|lpt\d)$/i;
export const spaceDotBoundaryRegex = /^[.\s]+|[.\s]+$/;

/**
 * return highlighted text with keyword
 * @param text
 * @param keyword
 * @param wrapBy
 */
export function addTextHighlight(
  text = "",
  keyword = "",
  wrapBy: (chunk: string, index: number) => React.ReactNode,
): React.ReactNode[] {
  if (!keyword) {
    return [text];
  }

  // space in the keyword means any characters
  const regexp = new RegExp(
    `(${escapeStringRegexp(keyword.trim()).split(" ").join("|")})`,
    "ig",
  );
  return text.split(regexp).map((chunk, index) => {
    if (regexp.test(chunk)) {
      return wrapBy(chunk, index);
    } else {
      return chunk;
    }
  });
}

export function matchString(value: string, keyword: string): boolean {
  const formedKeyword = keyword.toLowerCase();
  return value.toLowerCase().indexOf(formedKeyword) !== -1;
}

export function getWordCount(str: string): number {
  const words = str
    .trim()
    .split(/\s+/)
    .map((word) => word.replace(/^\.$/, ""))
    .filter(Boolean);

  return words.length;
}

export function getHumanReadableColumnName(rawColumnName: string): string {
  const reservedWords = ["id", "url", "ats"];

  return rawColumnName
    .split("_")
    .map((word) => {
      if (!word) {
        return "";
      }
      if (reservedWords.includes(word.toLowerCase())) {
        return word.toUpperCase();
      }
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");
}

export function formatBytes(bytes: number, decimal = 2) {
  const units = ["Byte", "KB", "MB"];
  let l = 0;

  while (bytes >= BYTES_KB && l < units.length - 1) {
    bytes /= BYTES_KB;
    l++;
  }

  const unit = units[l] + (l == 0 && bytes > 1 ? "s" : "");

  return `${bytes.toFixed(Math.max(0, decimal)).replace(/\.00$/, "")} ${unit}`;
}

export function numberToLetters(num: number): string {
  let letters = "";
  while (num >= 0) {
    const remainder = num % 26;
    letters = String.fromCharCode(65 + remainder) + letters;
    num = Math.floor(num / 26) - 1;
  }
  return letters;
}

export function escapeSpecialChars(str: string) {
  return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
}
