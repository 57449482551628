import { StackedBarChartItem } from "@shared/components";
import {
  ContractUsageModel,
  ProjectUsageModel,
  ProjectUsageYearlyBreakDownModel,
  SimpleExamModel,
} from "@shared/models";
import {
  IterableProjectUsageModel,
  IterableUsageBreakDownModel,
} from "@shared/models/ProjectUsage.model";
import { niceDonutChartNum } from "@shared/services/chart";
import { dayjs } from "@shared/services/date";
import Message from "@shared/services/message";

export type DonutChartItem = {
  id?: number;
  label: string;
  value: number;
};

export function flatProjectUsageYearlyBreakdown(
  projectUsageYearlyBreakDown: ProjectUsageYearlyBreakDownModel,
) {
  const { year, monthlyDeliveredBreakdown, monthlyUsageBreakdown } =
    projectUsageYearlyBreakDown;

  return [
    {
      date: `${year}01`,
      deliveryCount: monthlyDeliveredBreakdown.janCount,
      usageCount: monthlyUsageBreakdown.janCount,
    },
    {
      date: `${year}02`,
      deliveryCount: monthlyDeliveredBreakdown.febCount,
      usageCount: monthlyUsageBreakdown.febCount,
    },
    {
      date: `${year}03`,
      deliveryCount: monthlyDeliveredBreakdown.marCount,
      usageCount: monthlyUsageBreakdown.marCount,
    },
    {
      date: `${year}04`,
      deliveryCount: monthlyDeliveredBreakdown.aprCount,
      usageCount: monthlyUsageBreakdown.aprCount,
    },
    {
      date: `${year}05`,
      deliveryCount: monthlyDeliveredBreakdown.mayCount,
      usageCount: monthlyUsageBreakdown.mayCount,
    },
    {
      date: `${year}06`,
      deliveryCount: monthlyDeliveredBreakdown.junCount,
      usageCount: monthlyUsageBreakdown.junCount,
    },
    {
      date: `${year}07`,
      deliveryCount: monthlyDeliveredBreakdown.julCount,
      usageCount: monthlyUsageBreakdown.julCount,
    },
    {
      date: `${year}08`,
      deliveryCount: monthlyDeliveredBreakdown.augCount,
      usageCount: monthlyUsageBreakdown.augCount,
    },
    {
      date: `${year}09`,
      deliveryCount: monthlyDeliveredBreakdown.sepCount,
      usageCount: monthlyUsageBreakdown.sepCount,
    },
    {
      date: `${year}10`,
      deliveryCount: monthlyDeliveredBreakdown.octCount,
      usageCount: monthlyUsageBreakdown.octCount,
    },
    {
      date: `${year}11`,
      deliveryCount: monthlyDeliveredBreakdown.novCount,
      usageCount: monthlyUsageBreakdown.novCount,
    },
    {
      date: `${year}12`,
      deliveryCount: monthlyDeliveredBreakdown.decCount,
      usageCount: monthlyUsageBreakdown.decCount,
    },
  ];
}

export function sumAllIterableUsageBreakDown(
  breakdowns: IterableUsageBreakDownModel[][],
): IterableUsageBreakDownModel[] {
  // get all date keys
  const keys = new Set(
    breakdowns
      .reduce((result, breakdown) => {
        return [...result, breakdown.map((item) => item.date)].flat();
      }, [] as string[])
      .sort(),
  );

  const newBreakdowns: IterableUsageBreakDownModel[] = [];

  // sum all usage data
  keys.forEach((date) => {
    const { deliveryCount, usageCount } = breakdowns.reduce(
      (result, breakdown) => {
        const target = breakdown.find((item) => item.date === date);
        return target
          ? {
              usageCount: result.usageCount + target.usageCount,
              deliveryCount: result.deliveryCount + target.deliveryCount,
            }
          : result;
      },
      { deliveryCount: 0, usageCount: 0 },
    );

    newBreakdowns.push({
      date,
      deliveryCount,
      usageCount,
    });
  });

  return newBreakdowns;
}

export function getIterableProjectUsageYearlyBreakDownModel(
  projectUsage: ProjectUsageModel,
): IterableProjectUsageModel {
  const { yearlyBreakdowns, ...rest } = projectUsage;

  return {
    breakdowns: yearlyBreakdowns
      .reduce((breakdowns, item) => {
        return [...breakdowns, flatProjectUsageYearlyBreakdown(item)];
      }, [])
      .flat(),
    yearlyBreakdowns,
    ...rest,
  };
}

export function getContractPeriod({
  breakdowns,
  contractStartAt,
  contractEndAt,
}: {
  breakdowns: IterableUsageBreakDownModel[];
  contractStartAt: string;
  contractEndAt: string;
}): [string, string] {
  // NOTE:
  // Statistics are calculated based on UTC.
  // check if we also include one month before and after the period in the data just in case.
  const preStartAt = dayjs(contractStartAt).add(-1, "months");
  const postEndAt = dayjs(contractEndAt).add(1, "months");

  const hasDelivered = (targetDate: dayjs.Dayjs): boolean => {
    return (
      (breakdowns.find(
        (item) => item.date === dayjs(targetDate).format("YYYYMM"),
      )?.deliveryCount ?? 0) > 0
    );
  };

  return [
    hasDelivered(preStartAt) ? preStartAt.toISOString() : contractStartAt,
    hasDelivered(postEndAt) ? postEndAt.toISOString() : contractEndAt,
  ];
}

export function filterUsageBreakdownByContractPeriod({
  breakdowns,
  startAt,
  endAt,
}: {
  breakdowns: IterableUsageBreakDownModel[];
  startAt: string;
  endAt: string;
}): IterableUsageBreakDownModel[] {
  const startDate = dayjs(startAt);
  const endDate = dayjs(endAt);

  return breakdowns.filter((item) => {
    const targetDate = dayjs(item.date, "YYYYMM");
    return (
      startDate.isSameOrBefore(targetDate, "months") &&
      endDate.isSameOrAfter(targetDate, "months")
    );
  });
}

/**
 * Build all data for drawing donut chart
 *
 * data structure overview for data point
 *  - per project consumed data
 *  - other:
 *      sum all consumed counts only if filtered
 *  - remaining
 *
 * @param deliveryLimit
 * @param projectUsages
 * @param filtered
 * @returns
 */
export const buildDonutChartData = (
  deliveryLimit: number,
  projectUsages: ProjectUsageModel[],
  filtered?: {
    filterBy: SimpleExamModel;
    projectUsages: ProjectUsageModel[];
  },
): { dataPoints: DonutChartItem[]; usagePercentage: number } => {
  const targetProjectUsages = getActiveProjectUsages(
    filtered ? filtered.projectUsages : projectUsages,
    filtered
      ? ({ usageCount: 0, deliveredCount: 0 } as ProjectUsageModel)
      : undefined,
  );

  // build data points for main project usage
  const dataPoints: DonutChartItem[] = [...targetProjectUsages]
    .map((item) => ({
      id: filtered ? filtered.filterBy.examId : item.project.id,
      label: filtered ? filtered.filterBy.examName : item.project.name,
      value: item.usageCount ?? 0,
    }))
    .sort((a, b) => b.value - a.value);

  const { totalUsageCount } = getTotalCounts(targetProjectUsages);

  const { totalUsageCount: baseTotalUsageCount } =
    getTotalCounts(projectUsages);

  // add other data point when it's filtered
  if (filtered) {
    dataPoints.push({
      label: Message.getMessageByKey("contract.other"),
      value: Math.max(baseTotalUsageCount - totalUsageCount, 0),
    });
  }

  // add remaining data point
  dataPoints.push({
    label: Message.getMessageByKey("contract.remaining"),
    value: Math.max(deliveryLimit - baseTotalUsageCount, 0),
  });

  // calculate usage percentage
  const usagePercentage = niceDonutChartNum(
    (totalUsageCount / (deliveryLimit || 1)) * 100,
  );

  return { dataPoints, usagePercentage };
};

/**
 * Build all data for stacked bar chart
 * @param param
 * @returns
 */
export function buildStackedBarChartData({
  projectUsages,
  totalProjectUsage,
  startAt,
  endAt,
  filtered,
}: {
  projectUsages: IterableProjectUsageModel[];
  totalProjectUsage: Pick<IterableProjectUsageModel, "breakdowns">;
  startAt: string;
  endAt: string;
  filtered?: {
    filterBy: SimpleExamModel;
    projectUsages: IterableProjectUsageModel[];
  };
}): { stackedChartItems: StackedBarChartItem[]; groups: string[][] } {
  const targetProjectUsages = filtered ? filtered.projectUsages : projectUsages;

  const stackedChartItems: StackedBarChartItem[] = targetProjectUsages.map(
    (usage) => {
      const id = filtered ? filtered.filterBy.examId : usage.project.id;
      const label = filtered ? filtered.filterBy.examName : usage.project.name;

      return {
        id,
        label,
        data: filterUsageBreakdownByContractPeriod({
          breakdowns: usage.breakdowns,
          startAt,
          endAt,
        }).map((item) => item.usageCount),
      };
    },
  );

  // indicates which bars need to be stacked
  const groups = stackedChartItems.length
    ? [stackedChartItems.map((chartItem) => chartItem.label)]
    : [];

  // add delivered submissions to stacked item list
  stackedChartItems.push({
    label: Message.getMessageByKey("contract.totalDeliveredExams"),
    data: filterUsageBreakdownByContractPeriod({
      breakdowns: totalProjectUsage.breakdowns,
      startAt,
      endAt,
    }).map((item) => item.deliveryCount),
  });

  return { stackedChartItems, groups };
}

export function getXTicksForStackedBarChart(
  projectUsageYearlyBreakDown: ProjectUsageYearlyBreakDownModel[],
  startAt: string,
  endAt: string,
) {
  const startDate = dayjs(startAt);
  const endDate = dayjs(endAt);

  const xTicks = [...projectUsageYearlyBreakDown]
    .map((item) => item.year)
    .sort()
    .flatMap((year) => {
      return Array.from(Array(12).keys()).map((index) => ({
        year,
        month: index + 1,
      }));
    })
    .filter(({ year, month }) => {
      const date = dayjs(`${year}-${month}`, "YYYY-M");
      return (
        startDate.isSameOrBefore(date, "months") &&
        endDate.isSameOrAfter(date, "months")
      );
    })
    .map(({ year, month }) => `${year}/${month.toString().padStart(2, "0")}`);

  return xTicks;
}

export function includeExamInContract(
  contract: ContractUsageModel,
  exam: SimpleExamModel | undefined,
): boolean {
  if (exam === undefined) {
    return false;
  }

  const projectIds = contract.contract.projects.map((item) => item.projectId);
  return projectIds.includes(exam.projectId);
}

/**
 * Exclude records with no delivery history
 * @param projectUsages
 * @param placeholderData
 * @returns
 */
export function getActiveProjectUsages(
  projectUsages: ProjectUsageModel[],
  placeholderData?: ProjectUsageModel,
): ProjectUsageModel[] {
  const activeProjectUsages = projectUsages.filter(
    ({ deliveredCount, usageCount }) =>
      !(deliveredCount === 0 && usageCount === 0),
  );

  return activeProjectUsages.length > 0
    ? activeProjectUsages
    : placeholderData
    ? [placeholderData]
    : [];
}

/**
 * Summarize total count stats
 * @param projectUsages
 * @returns
 */
export function getTotalCounts(projectUsages: ProjectUsageModel[]): {
  totalUsageCount: number;
  totalDeliveredCount: number;
} {
  return projectUsages.reduce(
    (result, item) => {
      return {
        totalUsageCount: result.totalUsageCount + (item.usageCount || 0),
        totalDeliveredCount:
          result.totalDeliveredCount + (item.deliveredCount || 0),
      };
    },
    { totalUsageCount: 0, totalDeliveredCount: 0 },
  );
}

/**
 * Return exams that are delivered within the contract period
 * @param projectUsages
 * @returns
 */
export function getExamsInContract(
  projectUsages: ProjectUsageModel[],
  exams: SimpleExamModel[],
) {
  const deliveredExamIds = projectUsages.reduce((result, projectUsage) => {
    result.push(
      ...projectUsage.examBreakdowns
        .filter((examBreakdown) => examBreakdown.deliveredCount !== 0)
        .map((examBreakdown) => examBreakdown.examId),
    );
    return result;
  }, [] as number[]);

  return exams.filter((exam) => deliveredExamIds.includes(exam.examId));
}

/**
 * get default extent range for the x axis
 * @param item
 * @returns
 */
export function getDefaultXAxisExtent(ticks: string[]): [number, number] {
  const ticksCount = ticks.length;

  if (ticksCount < 12) {
    return [0, ticksCount];
  }

  const lastDataPointIndexFromEnd = [...ticks]
    .reverse()
    .findIndex((tick) => dayjs(tick, "YYYY-M").isSame(dayjs(), "months"));

  if (lastDataPointIndexFromEnd === -1) {
    return [ticksCount - 12, ticksCount];
  }

  const lastDataPointIndex = ticksCount - lastDataPointIndexFromEnd;

  return lastDataPointIndex > 12
    ? [lastDataPointIndex - 12, lastDataPointIndex]
    : [0, 12];
}
