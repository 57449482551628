import * as classnames from "classnames";

import {
  TableHead,
  HeaderColumn,
  SortableText,
  Msg,
  Checkbox,
} from "@shared/components";
import { SortOrderItem } from "@shared/models";

/**
 * Interface
 */
interface SubmissionListWithResultsTableHeaderProps {
  sortItem?: SortOrderItem;
  isSelected?: boolean;
  readOnly?: boolean;
  onClickSortItem?: (sortItem: SortOrderItem) => void;
  onClickSubmissionAll?: (checked: boolean) => void;
}

/**
 * Component
 * @param props SubmissionListWithResultsTableHeaderProps
 */
export function SubmissionListWithResultsTableHeader({
  sortItem,
  isSelected,
  readOnly,
  onClickSortItem,
  onClickSubmissionAll,
}: SubmissionListWithResultsTableHeaderProps) {
  const rootStyle = classnames("code-submission-list-table-header");
  return (
    <TableHead className={rootStyle}>
      {typeof onClickSubmissionAll === "function" && (
        <HeaderColumn className="align-right" size={1}>
          <div className="checkbox-wrapper">
            <Checkbox
              value={Boolean(isSelected)}
              name="submissions"
              onChange={(e) => onClickSubmissionAll(e.currentTarget.checked)}
              readOnly={readOnly}
            />
          </div>
        </HeaderColumn>
      )}
      <HeaderColumn size={6}>
        {sortItem ? (
          <SortableText
            name="applicantFullName"
            sortItem={sortItem}
            onClick={onClickSortItem}
            ariaLabel="Full Name"
          >
            <Msg id="common.applicants" />
          </SortableText>
        ) : (
          <Msg id="common.applicants" />
        )}
      </HeaderColumn>
      <HeaderColumn size={3}>
        {sortItem ? (
          <SortableText
            name="status"
            sortItem={sortItem}
            onClick={onClickSortItem}
            ariaLabel="submission.submissionStatus"
          >
            <Msg id="status" />
          </SortableText>
        ) : (
          <Msg id="status" />
        )}
      </HeaderColumn>
      <HeaderColumn size={2}>
        {sortItem ? (
          <SortableText
            name="deliveredAt"
            sortItem={sortItem}
            onClick={onClickSortItem}
            ariaLabel="Delivered At"
          >
            <Msg id="deliveredAt" />
          </SortableText>
        ) : (
          <Msg id="deliveredAt" />
        )}
      </HeaderColumn>
      <HeaderColumn size={2}>
        {sortItem ? (
          <SortableText
            name="deadline"
            sortItem={sortItem}
            onClick={onClickSortItem}
            ariaLabel="Deadline"
          >
            <Msg id="common.deadline" />
          </SortableText>
        ) : (
          <Msg id="common.deadline" />
        )}
      </HeaderColumn>
      <HeaderColumn size={2}>
        {sortItem ? (
          <SortableText
            name="submittedAt"
            sortItem={sortItem}
            onClick={onClickSortItem}
            ariaLabel="Submitted At"
          >
            <Msg id="submittedAt" />
          </SortableText>
        ) : (
          <Msg id="submittedAt" />
        )}
      </HeaderColumn>
      <HeaderColumn size={3} className="challenge-column align-center">
        <Msg id="challengeResultStatus" />
      </HeaderColumn>
      <HeaderColumn size={3} className="challenge-column">
        <Msg id="challengeVersion.versionNumber" />
      </HeaderColumn>
    </TableHead>
  );
}
