import * as classnames from "classnames";
import * as React from "react";

import { Msg, Restricted } from "../../../../../../shared/components";
import { ProjectRole, UserRole } from "../../../../../../shared/services/enums";

/**
 * Props
 */
export interface ExamDetailUpdateAvailableBoxProps {
  hasOutdatedChallengeVersion: boolean;
  showDetailLink?: boolean;
  archived: boolean;
  onClickDetail: () => void;
}

export const ExamDetailUpdateAvailableBox: React.FunctionComponent<
  ExamDetailUpdateAvailableBoxProps
> = (props: ExamDetailUpdateAvailableBoxProps) => {
  const { archived, hasOutdatedChallengeVersion, showDetailLink } = props;
  const rootStyle = classnames("code-exam-detail-update-available-box");
  return (
    <div className={rootStyle}>
      <Restricted
        roles={[
          UserRole.SystemAdmin,
          ProjectRole.ProjectAdmin,
          ProjectRole.ExamCreator,
          ProjectRole.ExamDeliverer,
        ]}
        strictAllow={Boolean(hasOutdatedChallengeVersion && !archived)}
      >
        <aside className="code-exam-detail-update-available-box__container">
          <div className="code-exam-detail-update-available-box__header" />
          <div className="code-exam-detail-update-available-box__body">
            <p>
              <strong>
                <Msg id="challengeVersion.notificationBox.title" />
              </strong>
            </p>
            <p>
              <Msg id="challengeVersion.notificationBox.body1" />
            </p>
            {showDetailLink && (
              <a onClick={props.onClickDetail} role="button">
                <Msg id="action.seeMoreDetails" />
              </a>
            )}
          </div>
        </aside>
      </Restricted>
    </div>
  );
};
