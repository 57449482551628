import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";

import {
  RecalculateScorePayload,
  postRecalculateScore,
} from "@api/scoreMaintain";

import Message from "@shared/services/message";

export const CREATE_RECALCULATE_SCORE_MUTATION_KEY = ["createRecalculateScore"];

export function useRecalculateScore() {
  const mutation = useMutation({
    mutationKey: CREATE_RECALCULATE_SCORE_MUTATION_KEY,
    mutationFn: (payload: RecalculateScorePayload) =>
      postRecalculateScore({ data: payload }),
    onSuccess: () => {
      toast.success(
        Message.getMessageByKey("message.submission.recalculate.success"),
      );
    },
  });

  return mutation;
}
