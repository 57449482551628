import * as classnames from "classnames";
import { ToastContainer, TypeOptions } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

/**
 * Prop interface
 */
export interface ToastrProps {
  className?: string;
}

/**
 * React Component
 */
export default function Toastr({ className }: ToastrProps) {
  const rootStyle = classnames("code-c-toastr", {
    [`${className}`]: Boolean(className),
  });

  const toastContextClass = {
    success: "code-c-toastr__success",
    error: "code-c-toastr__error",
    info: "code-c-toastr__info",
    warning: "code-c-toastr__warning",
    default: "code-c-toastr__info",
  };

  return (
    <ToastContainer
      className={rootStyle}
      toastClassName={(context: { type?: TypeOptions }) =>
        classnames("code-c-toastr__toast", {
          [`${toastContextClass[context?.type ?? "default"]}`]: true,
        })
      }
      position="bottom-right"
      autoClose={2500}
      hideProgressBar={true}
      theme="colored"
    />
  );
}
