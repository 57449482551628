import { EventEmitter } from "fbemitter";

/**
 * Event types
 */
export const MESSAGE_EVENT_READY = "ready";
export const MESSAGE_EVENT_RESET = "message.reset";
export const MESSAGE_EVENT_CONTEXT = "message.context";
export const MESSAGE_EVENT_SAVED = "message.saved";
export const MESSAGE_EVENT_HAS_UNSAVED = "message.hasUnsaved";
export const MESSAGE_EVENT_QUIZ_MOVED = "message.movePage";
export const MESSAGE_EVENT_BOOK_MOVED = "message.moved";
export const MESSAGE_EVENT_TEST_INFO = "message.testInfo";
export const MESSAGE_EVENT_INIT_REMOTE = "message.initEnv";
export const MESSAGE_EVENT_CONTENT_CHANGED = "message.contentChanged";
export const MESSAGE_EVENT_CACHE_CLEAR = "message.cacheClear";
export const MESSAGE_EVENT_SOLVED = "message.solved";
export const MESSAGE_EVENT_MOVE_CHAPTER = "message.moveChapter";
export const MESSAGE_EVENT_CLOSED = "message.closed";
export const MESSAGE_EVENT_TEST_RESULT = "message.testResult";
export const MESSAGE_EVENT_RUN_COMMAND = "message.runCommand";
export const MESSAGE_NOTIFY_ERROR = "message.notifyError";
export const MESSAGE_EVENT_BOOK_COMPLETE = "book.complete";
export const MESSAGE_EVENT_RUN_TESTS = "test.run";
export const MESSAGE_SAVE_ANSWERS = "message.saveAnswers";
export const MESSAGE_PASTED = "message.pasted";
export const MESSAGE_QUIZ_PASTED = "message.quizPasted";
export const MESSAGE_SWITCH_HISTORY = "message.switchHistory";
export const MESSAGE_PREVIEW_CONTENTS = "message.previewContents";
export const MESSAGE_CONTACT_TO_SUPPORT = "message.contactToSupport";
export const MESSAGE_ALERT_OVERLAY = "message.alertOverlay";

export const emitter = new EventEmitter();

export interface MessageSaved {
  isCommit: boolean;
  isTestRun?: boolean;
  challengeResultHistoryId?: number;
}

export interface MessageHasUnsaved {
  hasUnsaved: boolean;
}

export interface MessageMoved {
  page: number;
  showReview?: boolean;
  historyReplace?: boolean;
}
