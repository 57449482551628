import * as classnames from "classnames";
import * as React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import {
  SubMenu,
  SubMenuLabel,
  SubMenuList,
  SubMenuItem,
  SubMenuContainer,
  SubMenuContainerLeft,
  SubMenuContainerRight,
  Loading,
  RestrictedRoute,
  Restricted,
  Msg,
  Tooltip,
} from "@shared/components";
import { UserModel } from "@shared/models";
import { UserRole, ProjectRole, TierAction } from "@shared/services/enums";

import AccountSettingsRoutes from "./AccountSettings.route";
import OrgSettingsRoutes from "./OrgSettings.route";
import ProjectSettingsRoutes from "./ProjectSettings.route";

/**
 * Prop interface
 */
export interface SettingProps {
  currentUser: UserModel;
  currentProject: number;
  submitting: boolean;
  isTierActionAllowed: (tierAction: TierAction) => boolean;
}

/**
 * State interface
 */
export interface SettingState {
  systemAndOrgAdmin: boolean;
  projectAdmin: boolean;
}

/**
 * Page component
 */
class Setting extends React.Component<SettingProps, SettingState> {
  constructor(props: SettingProps) {
    super(props);

    const systemAndOrgAdmin = this.props.currentUser.roles.some(
      (role) =>
        role.role === UserRole.OrgAdmin || role.role === UserRole.SystemAdmin,
    );
    const projectAdmin = props.currentUser.roles.some(
      (role) =>
        role.projectId === props.currentProject &&
        role.role === ProjectRole.ProjectAdmin,
    );

    this.state = {
      systemAndOrgAdmin,
      projectAdmin,
    };
  }

  public render() {
    const rootStyle = classnames("code-setting");

    const { systemAndOrgAdmin, projectAdmin } = this.state;
    const canViewProjectAuditLogs = this.props.isTierActionAllowed(
      TierAction.ProjectAuditLogs,
    );

    const orgSettingsMenu = (
      <Restricted roles={[UserRole.SystemAdmin, UserRole.OrgAdmin]}>
        <SubMenu>
          <SubMenuLabel>
            <Msg id="orgSettings" defaultMessage="orgSettings" />
          </SubMenuLabel>
          <SubMenuList ariaLabel="Organization Side Menu">
            <SubMenuItem
              label={<Msg id="orgProfile" />}
              route={{ pathname: "/settings/org/profile" }}
            />
            <SubMenuItem
              label={<Msg id="pendingInvites" />}
              route={{ pathname: "/settings/org/members/pending" }}
            />
            <SubMenuItem
              label={<Msg id="configure" />}
              route={{ pathname: "/settings/org/settings" }}
            />
            <SubMenuItem
              label={<Msg id="contract" />}
              route={{ pathname: "/settings/org/contract" }}
            />
            <SubMenuItem
              label={<Msg id="auditLog.organization" />}
              route={{ pathname: "/settings/org/auditlog" }}
            />
          </SubMenuList>
        </SubMenu>
      </Restricted>
    );

    const projectSettingsMenu = (
      <Restricted roles={[UserRole.SystemAdmin, ProjectRole.ProjectAdmin]}>
        <SubMenu>
          <SubMenuLabel>
            <Msg id="projectSettings" />
          </SubMenuLabel>
          <SubMenuList ariaLabel="Project Member Side Menu">
            <SubMenuItem
              label={<Msg id="members" />}
              route={{
                pathname: `/p/${this.props.currentProject}/settings/members/active`,
              }}
              ariaLabel="Project Member Active"
            />
            {!systemAndOrgAdmin && (
              <SubMenuItem
                label={<Msg id="pendingInvites" />}
                route={{
                  pathname: `/p/${this.props.currentProject}/settings/members/pending`,
                }}
                ariaLabel="Project Member Pending"
              />
            )}
            {projectAdmin && (
              <SubMenuItem
                label={<Msg id="slack.integration" />}
                route={{
                  pathname: `/p/${this.props.currentProject}/settings/integrations/slack`,
                }}
                ariaLabel="Project Slack Integration"
              />
            )}
            <Tooltip
              placement="top-start"
              text={<Msg id="tier.disabled" />}
              disabled={canViewProjectAuditLogs}
            >
              <SubMenuItem
                disabled={!canViewProjectAuditLogs}
                label={<Msg id="auditLog.project" />}
                route={{
                  pathname: `/p/${this.props.currentProject}/settings/auditLog`,
                }}
                ariaLabel="Project Audit Log"
              />
            </Tooltip>
          </SubMenuList>
        </SubMenu>
      </Restricted>
    );

    const accountSettingsMenu = (
      <SubMenu>
        <SubMenuLabel>
          <Msg id="accountSettings" />
        </SubMenuLabel>
        <SubMenuList ariaLabel="Account Side Menu">
          <SubMenuItem
            label={<Msg id="notificationSettings" />}
            route={{ pathname: `/settings/account/general` }}
          />
        </SubMenuList>
      </SubMenu>
    );

    return (
      <div className={rootStyle}>
        <SubMenuContainer>
          <SubMenuContainerLeft>
            {orgSettingsMenu}
            {projectSettingsMenu}
            {accountSettingsMenu}
          </SubMenuContainerLeft>
          <SubMenuContainerRight>
            <Loading isOpen={this.props.submitting} />
            <Switch>
              <Route path="/settings/org" component={OrgSettingsRoutes} />
              <RestrictedRoute
                roles={[UserRole.SystemAdmin, ProjectRole.ProjectAdmin]}
                path="/p/:projectId/settings"
              >
                <ProjectSettingsRoutes
                  systemAndOrgAdmin={systemAndOrgAdmin}
                  projectAdmin={projectAdmin}
                  canViewProjectAuditLogs={canViewProjectAuditLogs}
                  currentProjectId={this.props.currentProject}
                />
              </RestrictedRoute>
              <Route
                path="/settings/account"
                component={AccountSettingsRoutes}
              />
              {systemAndOrgAdmin && (
                <Redirect from="/settings" to="/settings/org" />
              )}
              {projectAdmin && (
                <Redirect
                  from="/settings"
                  to={`/p/${this.props.currentProject}/settings`}
                />
              )}
              <Redirect from="/settings" to="/settings/account" />
            </Switch>
          </SubMenuContainerRight>
        </SubMenuContainer>
      </div>
    );
  }
}

export default Setting;
