import * as classnames from "classnames";
import { cloneDeep, isEqual } from "lodash";
import * as React from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";

import {
  SubMenuContainer,
  SubMenuContainerLeft,
  SubMenuContainerRight,
  ContentsSearchForm,
  Loading,
  Table,
  TableHead,
  HeaderColumn,
  PageTitle,
  Pagination,
  SortableText,
  CollapsibleQuestionColumn,
  Question,
  DocumentTitle,
  Modal,
  Column,
  Msg,
  QuickHelp,
} from "@shared/components";
import {
  PaginationModel,
  QuestionListModel,
  QuestionModel,
  OrganizationModel,
  QuestionFilterModel,
} from "@shared/models";
import { SortOrderItem } from "@shared/models";
import {
  QuestionType,
  QuestionCategory,
  TierAction,
  SortDirection,
} from "@shared/services/enums";
import Message from "@shared/services/message";
import { isGiveryOrg } from "@shared/services/organization";
import { parseUrl, updateUrl } from "@shared/services/queryString";
import * as urlParser from "@shared/services/urlParser";

import QuestionImport from "../questionImport/QuestionImport";
import QuestionNew from "../questionNew/QuestionNew";
import { QuestionListHeader, QuestionListAction } from "./partials";

/**
 * Prop interface
 */
export interface QuestionListProps {
  currentProjectId: number;
  loading: boolean;
  loadingDetail: boolean;
  questionList: Array<QuestionListModel>;
  questionDetailList: Array<QuestionModel>;
  pagination: PaginationModel;

  questionFilters: QuestionFilterModel;
  questionSource: string;
  importedQuestions: Array<QuestionModel>;
  importLoading: boolean;
  selectedOrg: OrganizationModel;

  createQuestionLoading: boolean;
  createQuestionError: boolean;
  location: { search: string } | undefined;

  isTierActionAllowed: (tierAction: TierAction) => boolean;
  getQuestionList: (
    pagination: PaginationModel,
    filters: {},
    sortOrder: SortOrderItem,
  ) => void;
  getQuestion: (payload: {}) => void;
  deleteQuestion: (payload: {}) => void;
  updateQuestionSource: (id: number, params: {}) => void;
  getQuestionSource: (id: number) => void;
  createQuestionFromSource: (payload: {}) => void;
  importQuestions: (payload: {}) => void;
  copyQuestion: (payload: {}) => void;
}

/**
 * State interface
 */
export interface QuestListFormValues {
  keyword?: string;
  kinds?: number[];
  categories?: number[];
}

export interface QuestionListState {
  sortItem: SortOrderItem;
  formValues: QuestListFormValues;
  isEditOpen: boolean;
  isDeleteOpen: boolean;
  isCopyOpen: boolean;
  isImportOpen: boolean;
  isNewOpen: boolean;
  selectedQuestion: QuestionListModel;
  addedQuestions: Array<QuestionListModel>;
}

/**
 * Page component
 */
class QuestionList extends React.Component<
  QuestionListProps,
  QuestionListState
> {
  constructor(props: QuestionListProps) {
    super(props);

    this.state = {
      sortItem: {
        column: "id",
        direction: SortDirection.Desc,
      },
      formValues: {},
      isEditOpen: false,
      isDeleteOpen: false,
      isCopyOpen: false,
      isImportOpen: false,
      isNewOpen: false,
      selectedQuestion: new QuestionListModel(),
      addedQuestions: [],
    };
  }

  private applyAllowedTierActions = (
    formValues: QuestListFormValues,
  ): {
    formValues: QuestListFormValues;
    shouldUpdateUrl: boolean;
  } => {
    const updatedFormValues = cloneDeep(formValues);

    const categories = updatedFormValues.categories?.filter((style) => {
      if (style === QuestionCategory.Original) {
        return this.props.isTierActionAllowed(TierAction.CustomQuizUsage);
      }

      return true;
    });

    updatedFormValues.categories =
      (categories?.length ?? 0) > 0 ? categories : undefined;

    // if counts of options changed then should update to correct url
    const shouldUpdateUrl =
      updatedFormValues.categories?.length !== formValues.categories?.length;

    return { formValues: updatedFormValues, shouldUpdateUrl };
  };

  public componentDidMount() {
    const {
      column = "id",
      direction = SortDirection.Desc,
      page,
      keyword,
      kinds,
      categories,
    } = parseUrl(this.props.location && this.props.location.search);

    const { formValues, shouldUpdateUrl } = this.applyAllowedTierActions({
      keyword: urlParser.getString(keyword),
      kinds: urlParser.getNumberArray(kinds),
      categories: urlParser.getNumberArray(categories),
    });

    const sortItem = {
      column,
      direction,
    };

    if (shouldUpdateUrl) {
      updateUrl({
        ...formValues,
        ...sortItem,
      });
    }

    this.setState({ formValues, sortItem });

    this.props.getQuestionList(
      this.props.pagination.getAtPage(urlParser.getPageNo(page)),
      formValues,
      sortItem,
    );
  }

  public componentDidUpdate(prevProps: QuestionListProps) {
    if (!isEqual(prevProps.questionList, this.props.questionList)) {
      this.setState({
        addedQuestions: [],
      });
    }
    if (
      prevProps.createQuestionLoading &&
      !this.props.createQuestionLoading &&
      !this.props.createQuestionError
    ) {
      this.onCloseModal();
    }
  }

  public shouldComponentUpdate(
    nextProps: QuestionListProps,
    nextState: QuestionListState,
  ) {
    return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state);
  }

  public render() {
    const rootStyle = classnames("code-question-list");

    const {
      questionDetailList,
      questionFilters,
      selectedOrg,
      isTierActionAllowed,
      currentProjectId,
    } = this.props;
    const { keyword } = this.state.formValues;

    // TODO should be used organization kind = 2
    const isGivery = isGiveryOrg(selectedOrg.id);

    const selectedQuestionDetails = questionDetailList.find(
      (item) => item.id === this.state.selectedQuestion.id,
    );

    const questions = this.props.questionList.map(
      (question: QuestionListModel) => {
        const questionDetail = questionDetailList.find(
          (item) => item.id === question.id,
        );

        const customQuestionAllowed = this.props.isTierActionAllowed(
          TierAction.CustomQuizUsage,
        );

        const actions = (
          <Column>
            <QuestionListAction
              question={question}
              customQuestionAllowed={customQuestionAllowed}
              isGivery={isGivery}
              onClickCopy={() => this.onOpenCopyModal(question)}
              onClickEdit={() => this.onOpenEditModal(question)}
              onClickDelete={() => this.onOpenDeleteModal(question)}
            />
          </Column>
        );

        return (
          <CollapsibleQuestionColumn
            key={question.id}
            question={question}
            onOpen={this.onOpenQuestion}
            loading={this.props.loadingDetail && !questionDetail}
            keyword={keyword}
            actions={actions}
          >
            <Question
              showTextarea={false}
              question={questionDetail}
              highlightedKeyword={keyword}
              hasHeader={false}
              insightsDisplayOptions={{ showGlobalStats: true }}
            />
          </CollapsibleQuestionColumn>
        );
      },
    );

    const copyModal = (
      <Modal
        title={Message.getMessageByKey("question.copy")}
        isOpen={this.state.isCopyOpen}
        onClose={this.onCloseModal}
        onClickOk={this.onConfirmCopy}
        onClickCancel={this.onCloseModal}
        okButtonLabel={Message.getMessageByKey("copy")}
        okButtonAriaLabel="Copy"
        ariaLabel="Copy Question"
      >
        <div style={{ fontWeight: "bold", wordBreak: "break-all" }}>
          {this.state.selectedQuestion.title}
        </div>
        <Msg id="question.confirmCopy.message" />
      </Modal>
    );

    return (
      <div className={rootStyle}>
        <DocumentTitle page={Message.getMessageByKey("common.questionList")} />
        <BreadcrumbsItem to={`/p/${currentProjectId}/questions`}>
          <Msg id={"common.questionList"} />
        </BreadcrumbsItem>
        <SubMenuContainer>
          <SubMenuContainerLeft>
            <ContentsSearchForm
              onChange={this.onFormChange}
              initialValues={this.state.formValues}
              keywordHint={<Msg id="question.keyword.hint" />}
              filters={[
                {
                  title: <Msg id="common.categories" />,
                  value: "categories",
                  options: [
                    {
                      value: QuestionCategory.Preset,
                      label: QuestionCategory.toString(QuestionCategory.Preset),
                      count: 0,
                    },
                    {
                      value: QuestionCategory.Original,
                      label: QuestionCategory.toString(
                        QuestionCategory.Original,
                      ),
                      count: 0,
                    },
                  ].map((filter) => {
                    const disabled =
                      filter.value === QuestionCategory.Original &&
                      !this.props.isTierActionAllowed(
                        TierAction.CustomQuizUsage,
                      );

                    return {
                      ...filter,
                      disabled,
                      tooltip: disabled
                        ? Message.getMessageByKey("tier.disabled")
                        : "",
                    };
                  }),
                },
                {
                  title: <Msg id={"kinds"} />,
                  value: "kinds",
                  options: [
                    {
                      value: QuestionType.MCQ,
                      label: QuestionType.toString(QuestionType.MCQ),
                      count: 0,
                    },
                    {
                      value: QuestionType.FIB,
                      label: QuestionType.toString(QuestionType.FIB),
                      count: 0,
                    },
                    {
                      value: QuestionType.FreeText,
                      label: QuestionType.toString(QuestionType.FreeText),
                      count: 0,
                    },
                  ],
                },
                {
                  title: <Msg id={"quizCategories"} />,
                  value: "quizCategories",
                  options: questionFilters.quizCategories.map((filter) => ({
                    value: filter.value,
                    label: filter.displayString,
                    count: 0,
                  })),
                },
              ]}
            />
          </SubMenuContainerLeft>
          <SubMenuContainerRight>
            <QuestionListHeader
              isGivery={isGivery}
              count={this.props.pagination.count}
              onClickNewQuestion={this.onOpenNewQuestion}
              isTierActionAllowed={isTierActionAllowed}
            />
            <div className="code-question-list__block">
              <Loading
                isOpen={this.props.loading}
                fullScreen={false}
                overlay={false}
              />
              <Table className="code-question-list__table">
                <TableHead>
                  <HeaderColumn absoluteSize={5}>
                    <SortableText
                      name="id"
                      sortItem={this.state.sortItem}
                      onClick={this.onSortChange}
                      ariaLabel="ID"
                    >
                      <Msg id="common.id" />
                    </SortableText>
                  </HeaderColumn>
                  <HeaderColumn size={5}>
                    <SortableText
                      name="title"
                      sortItem={this.state.sortItem}
                      onClick={this.onSortChange}
                      ariaLabel="Title"
                    >
                      <Msg id="common.title" />
                    </SortableText>
                  </HeaderColumn>
                  <HeaderColumn absoluteSize={5}>
                    <SortableText
                      name="kind"
                      sortItem={this.state.sortItem}
                      onClick={this.onSortChange}
                      ariaLabel="Kind"
                    >
                      <Msg id={"kind"} />
                    </SortableText>
                  </HeaderColumn>
                  <HeaderColumn size={4}>
                    <Msg id={"includedChallenge"} />
                  </HeaderColumn>
                  <HeaderColumn
                    absoluteSize={5}
                    className="is-keep-all align-right"
                  >
                    <SortableText
                      name="company_usage_count"
                      sortItem={this.state.sortItem}
                      onClick={this.onSortChange}
                      ariaLabel="Company Usage"
                    >
                      <Msg id="challenge.numberOfOrgsUsing" />
                    </SortableText>
                  </HeaderColumn>
                  <HeaderColumn
                    absoluteSize={5}
                    className="is-keep-all align-right"
                  >
                    <SortableText
                      name="global_submission_count"
                      sortItem={this.state.sortItem}
                      onClick={this.onSortChange}
                      ariaLabel="Submission Count"
                    >
                      <Msg id="challenge.numberOfSubmission" />
                    </SortableText>
                  </HeaderColumn>
                  <HeaderColumn absoluteSize={5} className="align-right">
                    <SortableText
                      name="global_average_score"
                      sortItem={this.state.sortItem}
                      onClick={this.onSortChange}
                      ariaLabel="Correct Rate"
                    >
                      <Msg id="question.correctRate" />
                      <QuickHelp
                        text={Message.getMessageByKey(
                          "question.correctRateColumn.tooltip",
                        )}
                        maxSize="large"
                      />
                    </SortableText>
                  </HeaderColumn>
                  <HeaderColumn absoluteSize={5} className="align-right">
                    <SortableText
                      name="global_average_time"
                      sortItem={this.state.sortItem}
                      onClick={this.onSortChange}
                      ariaLabel="Average Time Taken"
                    >
                      <Msg id="challenge.averageTimeTaken" />
                    </SortableText>
                  </HeaderColumn>
                  <HeaderColumn size={1} />
                </TableHead>
                {questions}
              </Table>
            </div>
            <div className="code-question-list__pagination">
              <Pagination
                pagination={this.props.pagination}
                onPageChange={this.onPageChange}
              />
            </div>
          </SubMenuContainerRight>
        </SubMenuContainer>
        <QuestionImport
          isOpen={this.state.isImportOpen}
          onClose={this.onCloseModal}
          onOk={this.onImportQuestions}
          importQuestions={this.props.importQuestions}
          importLoading={this.props.importLoading}
          importedQuestions={this.props.importedQuestions}
          deleteQuestion={this.props.deleteQuestion}
        />
        <Modal
          title={Message.getMessageByKey("confirm.delete")}
          isOpen={this.state.isDeleteOpen}
          onClose={this.onCloseModal}
          onClickOk={this.onConfirmDelete}
          onClickCancel={this.onCloseModal}
          okButtonLabel={Message.getMessageByKey("delete")}
          okButtonType={"danger"}
          okButtonAriaLabel="Delete"
          ariaLabel="Delete Question"
        >
          <PageTitle>{this.state.selectedQuestion.title}</PageTitle>
          <Msg id="question.confirmDelete" />
        </Modal>
        {copyModal}
        <QuestionNew
          title={
            <Msg id={this.state.isNewOpen ? "question.new" : "question.edit"} />
          }
          isOpen={this.state.isNewOpen || this.state.isEditOpen}
          onClose={this.onCloseModal}
          onCreateQuestion={
            this.state.isNewOpen ? this.onCreateFromSource : this.onSave
          }
          loading={this.props.createQuestionLoading}
          question={this.state.isEditOpen ? selectedQuestionDetails : undefined}
          questionFilters={questionFilters}
        />
      </div>
    );
  }

  private onFormChange = (
    formValid: boolean,
    formValues: {},
    formErrors: {},
  ) => {
    if (isEqual(formValues, this.state.formValues)) {
      return;
    }

    updateUrl({ ...formValues, ...this.state.sortItem });

    this.setState({
      formValues,
    });

    this.props.getQuestionList(
      new PaginationModel(),
      formValues,
      this.state.sortItem,
    );
  };

  private onPageChange = ({ selected }: { selected: number }) => {
    updateUrl({
      ...this.state.formValues,
      ...this.state.sortItem,
      page: selected,
    });

    this.props.getQuestionList(
      this.props.pagination.getAtPage(selected),
      this.state.formValues,
      this.state.sortItem,
    );
  };

  private onSortChange = (sortItem: SortOrderItem) => {
    this.setState({
      sortItem,
    });

    updateUrl({ ...this.state.formValues, ...sortItem });

    this.props.getQuestionList(
      new PaginationModel(),
      this.state.formValues,
      sortItem,
    );
  };

  private onOpenQuestion = (questionId: number) => {
    const index = this.props.questionDetailList.findIndex(
      (item) => item.id === questionId,
    );
    if (index === -1) {
      this.props.getQuestion(questionId);
    }
  };

  private onOpenEditModal = (question: QuestionListModel) => {
    this.onOpenQuestion(question.id);

    this.setState({
      isEditOpen: true,
      selectedQuestion: question,
    });
  };

  private onOpenCopyModal = (question: QuestionListModel) => {
    this.setState({
      isCopyOpen: true,
      selectedQuestion: question,
    });
  };

  private onOpenDeleteModal = (question: QuestionListModel) => {
    this.setState({
      isDeleteOpen: true,
      selectedQuestion: question,
    });
  };

  private onCloseModal = () => {
    this.setState({
      isDeleteOpen: false,
      isEditOpen: false,
      isImportOpen: false,
      isNewOpen: false,
      isCopyOpen: false,
    });
  };

  private onSave = (payload: {}) => {
    this.props.updateQuestionSource(this.state.selectedQuestion.id, payload);
  };

  private onConfirmDelete = () => {
    this.props.deleteQuestion(this.state.selectedQuestion.id);
    this.onCloseModal();
  };

  private onConfirmCopy = () => {
    this.props.copyQuestion(this.state.selectedQuestion.id);
    this.onCloseModal();
  };

  private onImportQuestions = (questions: Array<QuestionModel>) => {
    this.setState({
      addedQuestions: questions.map(
        (question) => new QuestionListModel(question),
      ),
    });

    this.onCloseModal();
  };

  private onOpenNewQuestion = () => {
    this.setState({
      isNewOpen: true,
    });
  };

  private onCreateFromSource = (payload: {}) => {
    this.props.createQuestionFromSource(payload);
  };
}

export default QuestionList;
