import { AxiosRequestConfig } from "axios";

import { ChallengeModel } from "@shared/models";

import { _delete, get } from "../httpClient";

interface ChallengeParams {
  projectId: number;
  challengeId: number;
  options?: Omit<AxiosRequestConfig, "params">;
}

interface GetChallengeParams extends ChallengeParams {
  challengeVersionId: number;
}

export async function getChallenge({
  projectId,
  challengeId,
  challengeVersionId,
  options,
}: GetChallengeParams) {
  const { data } = await get<ChallengeModel>(
    `/api/projects/${projectId}/challenges/${challengeId}`,
    {
      ...options,
      params: {
        challengeVersionId,
      },
    },
  );

  return data;
}

export async function deleteChallenge({
  projectId,
  challengeId,
  options,
}: ChallengeParams) {
  const { data } = await _delete<ChallengeModel>(
    `/api/projects/${projectId}/challenges/${challengeId}`,
    options,
  );

  return data;
}
