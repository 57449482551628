import * as classnames from "classnames";
import * as React from "react";

import {
  Msg,
  Button,
  Tooltip,
  Restricted,
  VersionCodeTag,
} from "../../../../../shared/components";
import { ExamChallengeModel, UserModel } from "../../../../../shared/models";
import {
  ProjectRole,
  UserRole,
  ExamType,
} from "../../../../../shared/services/enums";
import Message from "../../../../../shared/services/message";

/**
 * Props
 */
export type ExternalProps = {
  examType?: ExamType;
  challenge: ExamChallengeModel;
  onOpenChallengeReleaseNote: (
    challengeId: number,
    usedChallengeVersionCode?: string,
  ) => void;
  onOpenChallengeVersionUpConfirm: (challenge: ExamChallengeModel) => void;
};

export type InjectedProps = {
  currentProjectId: number;
  currentUser?: UserModel;
};

export type ExamChallengeUpdateAvailableBoxProps = InjectedProps &
  ExternalProps;

export const ExamChallengeUpdateAvailableBox: React.FunctionComponent<
  ExamChallengeUpdateAvailableBoxProps
> = (props: ExamChallengeUpdateAvailableBoxProps) => {
  const { examType, currentUser, challenge, currentProjectId } = props;
  const {
    originalChallenge: { linkedChallenge },
  } = challenge;

  const rootStyle = classnames("code-exam-challenge-update-available-box");

  return currentUser ? (
    <Restricted
      roles={[
        UserRole.SystemAdmin,
        ProjectRole.ProjectAdmin,
        ProjectRole.ExamCreator,
        ProjectRole.ExamDeliverer,
      ]}
      strictAllow={Boolean(
        examType === ExamType.EnglishJapanese
          ? challenge.nextAvailableChallengeVersionCode &&
              challenge.nextAvailableLinkedChallengeVersionCode
          : challenge.nextAvailableChallengeVersionCode,
      )}
    >
      <aside className={rootStyle}>
        <div className="code-exam-challenge-update-available-box__header" />
        <div className="code-exam-challenge-update-available-box__body">
          <div>
            <strong>
              <Msg id="challengeVersion.notificationBox.title" />
            </strong>
          </div>
          <div>
            <Msg id="challengeVersion.notificationBox.body2" />
          </div>
          <div>
            <strong>
              <Msg id="challengeVersion.releaseNote" />
            </strong>
          </div>
          <div className="code-exam-challenge-update-available-box__bottom">
            <div className="code-exam-challenge-update-available-box__versions">
              <a
                onClick={() =>
                  props.onOpenChallengeReleaseNote(
                    challenge.challengeId,
                    challenge.usedChallengeVersionCode,
                  )
                }
                role="button"
                className="code-exam-challenge-update-available-box__versions-link"
              >
                <span className="clamp-1">{challenge.title}</span>
                <VersionCodeTag
                  version={challenge.nextAvailableChallengeVersionCode}
                />
              </a>
              {linkedChallenge &&
                challenge.nextAvailableLinkedChallengeVersionCode && (
                  <a
                    onClick={() =>
                      props.onOpenChallengeReleaseNote(
                        linkedChallenge.id,
                        `${linkedChallenge.currentVersion.majorVersionNumber}.${linkedChallenge.currentVersion.minorVersionNumber}`,
                      )
                    }
                    role="button"
                    className="code-exam-challenge-update-available-box__versions-link"
                  >
                    <span className="clamp-1">{linkedChallenge.title}</span>
                    <VersionCodeTag
                      version={
                        challenge.nextAvailableLinkedChallengeVersionCode
                      }
                    />
                  </a>
                )}
            </div>
            {currentUser.hasRole(
              [ProjectRole.ProjectAdmin, ProjectRole.ExamCreator],
              currentProjectId,
            ) ? (
              <Button
                type="warning"
                size="small"
                shrink={true}
                onClick={() => props.onOpenChallengeVersionUpConfirm(challenge)}
              >
                <Msg id="action.update" />
              </Button>
            ) : (
              <Tooltip
                text={Message.getMessageByKey(
                  "challengeVersion.notificationBox.deny",
                )}
                maxSize="large"
                placement="top-end"
              >
                <Button
                  type="warning"
                  size="small"
                  shrink={true}
                  disabled={true}
                >
                  <Msg id="action.update" />
                </Button>
              </Tooltip>
            )}
          </div>
        </div>
      </aside>
    </Restricted>
  ) : null;
};
