export const DEFAULT_PAGINATION_LIMIT = 20;

class PaginationModel {
  public count = 0;
  public limit: number = DEFAULT_PAGINATION_LIMIT;
  public offset = 0;
  // if includePrevPageLastRow is true, then the first row of the current page is the last row of the previous page
  public includePrevPageLastRow?: boolean;

  constructor(args?: {}) {
    Object.assign(this, args);
  }

  get totalPageNo(): number {
    return Math.ceil(this.count / this.actualLimit) || 1;
  }

  get currentPage(): number {
    return Math.floor(this.actualOffset / this.actualLimit);
  }

  private get needNormalization(): boolean {
    // We only need to adjust the offset and limit if
    // includePrevPageLastRow is true and it is not the first page
    return Boolean(this.includePrevPageLastRow) && this.offset > 0;
  }

  /** The actual limit and actual offset are used to calculate the current page & total page no
   * which affects how the Pagination component is displayed */
  private get actualLimit(): number {
    return this.needNormalization ? this.limit - 1 : this.limit;
  }

  private get actualOffset(): number {
    return this.needNormalization ? this.offset + 1 : this.offset;
  }

  /**
   * return new pagination instance for the API request
   * @param page
   */
  public getAtPage(page: number): PaginationModel {
    let newOffset = this.actualLimit * page;
    let newLimit = this.actualLimit;
    if (this.includePrevPageLastRow && page > 0) {
      newOffset = newOffset - 1;
      newLimit = newLimit + 1;
    }

    return new PaginationModel(
      !page || page < 1
        ? { limit: newLimit }
        : { ...this, offset: newOffset, limit: newLimit },
    );
  }
}

export default PaginationModel;
