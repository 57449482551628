import { connect } from "react-redux";
import { Action, Dispatch } from "redux";

import { adminOrgSetAction } from "../../../actions";
import { RootState } from "../../../reducers";
import OrgPicker from "./OrgPicker";

export interface OwnProps {
  className?: string;
}

const mapStateToProps = (state: RootState, props: OwnProps) => ({
  ...props,
  orgList: state.orgs.organizationAllList,
  selectedOrg:
    state.orgs.organizationAllList.find(
      (org) => org.name === state.admin.selectedOrg.name,
    ) || state.admin.selectedOrg,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  setOrg: (org: { name: string }) => {
    dispatch(adminOrgSetAction.request(org, true));
  },
});

/**
 * Wrapped component
 */
const wrappedComponent = connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrgPicker);

export default wrappedComponent;
