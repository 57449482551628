import Rollbar from "rollbar";

import Logger from "./logger";
import { version } from "./version";

const config: Rollbar.Configuration = {
  accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  hostWhiteList: (process.env.REACT_APP_ROLLBAR_HOST_WHITELIST || "").split(
    ",",
  ),
  ignoredMessages: (process.env.REACT_APP_ROLLBAR_IGNORE_MESSAGES || "").split(
    ",",
  ),
  payload: {
    environment: process.env.REACT_APP_ENV,
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: JSON.stringify(version),
        // Optionally have Rollbar guess which frames the error was thrown from
        // when the browser does not provide line and column numbers.
        guess_uncaught_frames: true,
      },
    },
  },
};

class ErrorTracking {
  rollbar: Rollbar;

  constructor() {
    if (process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN) {
      Logger.info("Running with error tracking mode.");
      this.rollbar = new Rollbar(config);
    } else {
      Logger.info("Running with no error tracking mode.");
    }
  }
}

export default new ErrorTracking();
