import * as classnames from "classnames";
import * as React from "react";

interface ScoreButtonProps {
  isSelected?: boolean;
  disabled?: boolean;
  ariaLabel?: string;
  icon?: React.ReactNode;
  width?: "small" | "medium";
  disableHoverHighlight?: boolean;
  children?: React.ReactNode;
  color: "dark-green" | "green" | "red" | "dark-red";
  onClick?: () => void;
}

export const ScoreButton = ({
  isSelected,
  disabled,
  ariaLabel,
  icon,
  color,
  width = "medium",
  disableHoverHighlight = false,
  children,
  onClick,
}: ScoreButtonProps) => {
  const scoreIconStyle = classnames("code-c-score-button", {
    [`is-checked`]: isSelected,
    [`is-disabled`]: disabled,
    [`width-${width}`]: true,
    ["hover-highlight"]: !disableHoverHighlight,
    [color]: Boolean(color),
  });

  return (
    <div
      className={scoreIconStyle}
      aria-label={ariaLabel}
      aria-checked={isSelected}
      onClick={() => {
        if (!disabled) {
          onClick?.();
        }
      }}
    >
      <div className="code-c-score-button__score-icon">{icon}</div>
      <div className="code-c-score-button__score-text">{children}</div>
    </div>
  );
};
