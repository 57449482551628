import classnames from "classnames";
import * as React from "react";

/**
 * Prop interface
 */
export interface HeaderColumnProps {
  size?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
  absoluteSize?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  colSpan?: number;
  className?: string;
  noPaddingX?: boolean;
  noPaddingLeft?: boolean;
  noPaddingRight?: boolean;
  children?: React.ReactNode;
}

/**
 * React Component
 */
export default function HeaderColumn({
  size,
  absoluteSize,
  colSpan,
  noPaddingX,
  noPaddingLeft,
  noPaddingRight,
  className,
  children,
}: HeaderColumnProps) {
  const rootStyle = classnames("code-c-header-column", {
    [`is-${size}`]: Boolean(size),
    [`is-absolute-${absoluteSize}`]: Boolean(absoluteSize),
    "no-padding-x": noPaddingX,
    "no-padding-right": noPaddingRight,
    "no-padding-left": noPaddingLeft,
    [`${className}`]: Boolean(className),
  });

  return (
    <th className={rootStyle} colSpan={colSpan}>
      {children}
    </th>
  );
}
