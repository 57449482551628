import { Tag } from "..";
import MSG from "../../services/message";

/**
 * Prop interface
 */
interface NewProps {
  onClick?: () => void;
}

/**
 * React Component
 */
export default function New({ onClick }: NewProps) {
  return (
    <div className={"code-c-new"}>
      <Tag className={"code-c-new"} onClick={onClick}>
        {MSG.getMessageByKey("challenge.new")}
      </Tag>
    </div>
  );
}
