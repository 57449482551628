import { getType } from "typesafe-actions";

import { AtsKind } from "@shared/services/enums";

import { actions, ActionTypes } from "../actions";
import { ExternalSystemLinkModel, PaginationModel } from "../shared/models";

export interface ExternalSystemState {
  externalSystemMap: Record<string, ExternalSystemLinkModel>;
  externalSystemListPagination: PaginationModel;
}

export const initialState = {
  externalSystemMap: AtsKind.values.reduce(
    (acum, key) =>
      Object.assign(acum, { [key]: new ExternalSystemLinkModel() }),
    {},
  ),
  externalSystemListPagination: new PaginationModel(),
};

export const externalSystemReducer = (
  state: ExternalSystemState = initialState,
  action: ActionTypes,
): ExternalSystemState => {
  switch (action.type) {
    case getType(actions.externalSystemListResetAction.success):
      return { ...state, ...initialState };

    case getType(actions.externalSystemListGetAction.success):
      const externalSystemMap = action.payload.result.reduce(
        (acum, item) => {
          acum[item.atsKind] = new ExternalSystemLinkModel(item);
          return acum;
        },
        { ...state.externalSystemMap },
      );

      return {
        ...state,
        externalSystemMap,
        externalSystemListPagination: new PaginationModel(
          action.payload.pagination,
        ),
      };

    case getType(actions.externalSystemSetAction.request): {
      const externalSystemDetail = new ExternalSystemLinkModel(
        action.payload.result,
      );
      const externalSystemMap = {
        ...state.externalSystemMap,
        [externalSystemDetail.atsKind]: externalSystemDetail,
      };

      return { ...state, externalSystemMap };
    }

    default:
      return state;
  }
};
