import {
  QueryClient,
  MutationFilters,
  QueryCache,
  MutationCache,
  Query,
} from "@tanstack/react-query";

interface Meta {
  hideLoader?: boolean;
}

interface LoaderLogicResult {
  hideLoader: boolean;
}

/**
 * Decides if the loader should be shown or hidden based on the query and mutation cache
 * If any query or mutation is loading without hideLoader, the loader should show
 * If all queries and mutations are loading with hideLoader=true, the loader should hide
 * Ignores queries and mutations that are not loading and fetching
 */
export function determineLoaderState(
  queryClient: QueryClient,
): LoaderLogicResult {
  const queryCache: QueryCache = queryClient.getQueryCache();
  const mutationCache: MutationCache = queryClient.getMutationCache();

  let anyQueryLoadingWithoutHideLoader = false;
  let anyMutationLoadingWithoutHideLoader = false;
  // Check queries in the cache
  queryCache.findAll().forEach((query: Query) => {
    const status = query.state.status;
    const fetchStatus = query.state.fetchStatus;
    const meta = query.meta as Meta;
    // Check if the query is loading and fetching, idle and paused queries should be ignored
    if (status === "loading" && fetchStatus === "fetching") {
      if (meta?.hideLoader !== true) {
        anyQueryLoadingWithoutHideLoader = true;
      }
    }
  });

  const filter: MutationFilters = {};
  // Check mutations in the mutation cache
  mutationCache.findAll(filter).forEach((mutation) => {
    const meta = mutation.meta as Meta;
    const status = mutation.state.status;

    if (status === "loading") {
      if (meta?.hideLoader !== true) {
        anyMutationLoadingWithoutHideLoader = true;
      }
    }
  });

  if (anyQueryLoadingWithoutHideLoader || anyMutationLoadingWithoutHideLoader) {
    return { hideLoader: false }; // Loader should show if any query or mutation is loading without hideLoader
  }
  return { hideLoader: true }; // Default: if no queries or mutations are loading, hide the loader
}
