import { connect } from "react-redux";

import { RootState } from "../../../reducers";
import FilterConditions, { ExternalProps } from "./FilterConditions";

const mapStateToProps = (state: RootState, props: ExternalProps) => ({
  ...props,
  programmingCategories: state.challenge.challengeFilters.programmingCategories,
  programmingLanguages: state.challenge.challengeFilters.programmingLanguages,
});

/**
 * Wrapped component
 */
const wrappedComponent = connect(mapStateToProps, () => ({}))(FilterConditions);

export default wrappedComponent;
