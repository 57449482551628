import { Markdown, Dropdown } from "track-markdown";

interface MarkdownOptions {
  showCopyButton?: boolean;
  noRelativeLinks?: boolean;
}

/**
 * Simple Renderer
 * @param content
 * @param options
 */
export function render(content: string, options?: MarkdownOptions) {
  return Markdown.convert(content, options);
}

/**
 * Renderer for FIB(Fill in the blank)
 * @param content
 * @param answers
 * @param options
 */
export function renderFIB(
  content: string,
  answers: string[],
  options?: MarkdownOptions,
) {
  // NOTE quiz editor does not support dropdown. so it is empty.
  return Markdown.convertWithBlank(
    content,
    answers,
    new Dropdown({}),
    undefined,
    options,
  );
}

/**
 * Renderer for FreeText
 * @param content
 * @param answers
 * @param options
 */
export function renderFreeText(
  content: string,
  answers: string[],
  options?: MarkdownOptions,
) {
  return Markdown.convertWithFreeText(content, answers, options);
}
