import { useStoreContext } from "@context";

import { Button, CodeDiffIcon, JumpTo, Tooltip } from "@shared/components";
import { useExam } from "@shared/hooks/query";
import { SubmissionResultDetailModel } from "@shared/models";
import { ChallengeResultStatus } from "@shared/services/enums";
import Message from "@shared/services/message";
import { getCodeDiffUrl } from "@shared/services/url";

export type ExternalProps = {
  submissionResultDetail: SubmissionResultDetailModel;
};

type CodeDiffButtonProps = ExternalProps;

export const CodeDiffButton = (props: CodeDiffButtonProps) => {
  const {
    submissionResultDetail: { status, applicantExamId, challengeId },
  } = props;
  const { projectId } = useStoreContext();
  const {
    data: {
      examDetail: { id: examId },
    },
  } = useExam();

  const isButtonDisabled = [
    ChallengeResultStatus.Prepared,
    ChallengeResultStatus.Started,
    ChallengeResultStatus.NotModified,
  ].includes(status);

  const tooltipMessage = Message.getMessageByKey(
    isButtonDisabled
      ? "submission.codeDiff.not.available"
      : "action.openCodeDiff",
  );

  const button = isButtonDisabled ? (
    <Button disabled={true} size="small" shrink={true} className="is-nomedia">
      <CodeDiffIcon />
    </Button>
  ) : (
    <JumpTo
      to={getCodeDiffUrl({
        projectId,
        examId,
        applicantExamId: applicantExamId,
        challengeId,
      })}
      options={{ stopPropagation: true }}
      className="button is-small is-nomedia"
    >
      <CodeDiffIcon />
    </JumpTo>
  );

  return (
    <Tooltip text={tooltipMessage} placement="top-end">
      {button}
    </Tooltip>
  );
};
