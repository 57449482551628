import { Epic } from "redux-observable";
import { AjaxCreationMethod } from "rxjs/observable/dom/AjaxObservable";

import { Action, AjaxAction, ajaxAction } from "../actions";
import { RootState } from "../reducers";
import { ajaxWrapper } from "../shared/services/ajax";

export const ajaxEpic: Epic<Action, RootState> = (
  action$,
  state,
  { ajax }: { ajax: AjaxCreationMethod },
) =>
  action$
    .ofType(ajaxAction.types.request)
    .flatMap(
      ({
        payload: {
          method,
          url,
          body,
          headers,
          success,
          error,
          cancel = [],
          options,
        },
      }: AjaxAction) =>
        ajaxWrapper(
          ajax,
          method,
          url,
          body,
          headers,
          success,
          error,
          options,
        ).race(
          action$
            .ofType(...cancel)
            .mapTo(ajaxAction.success())
            .take(1),
        ),
    );

export default [ajaxEpic];
