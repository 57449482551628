import * as classnames from "classnames";
import { isEqual } from "lodash";
import * as React from "react";

import {
  Modal,
  SubMenuContainer,
  SubMenuContainerRight,
  SubMenuContainerLeft,
  Table,
  TableHead,
  HeaderColumn,
  CollapsibleChallengeColumn,
  Pagination,
  Loading,
  Column,
  SortableText,
  Msg,
  Overlay,
} from "@shared/components";
import {
  PaginationModel,
  ChallengeModel,
  ExamChallengeModel,
  ChallengeFilterFormModel,
  PinnedChallengeModel,
  ChallengeFilterModel,
} from "@shared/models";
import { SortOrderItem } from "@shared/models";
import {
  ChallengeStatus,
  SortDirection,
  TierAction,
} from "@shared/services/enums";
import { isChallengeAllowed } from "@shared/services/tier";

import ChallengeCollection from "../challengeCollection/ChallengeCollection.connect";
import ChallengeDetail from "../challengeDetail/ChallengeDetail.connect";
import ChallengeFilter from "../challengeFilter/ChallengeFilter.connect";
import { ChallengeSectionService } from "../challengeSections";

/**
 * Prop interface
 */
export type ExternalProps = {
  isOpen: boolean;
  selectedChallenges: Array<ExamChallengeModel>;
  options?: {
    hidePin?: boolean;
    defaultConditions?: {};
    disableOptionKeys?: string[];
    strictLinkedChallenge?: boolean;
  };
  drawerOptions?: {
    hideCopy?: boolean;
  };
  collectionOptions?: {
    hideCollection?: boolean;
    showOfficial?: boolean;
    defaultConditions?: {};
    strictLinkedChallenge?: boolean;
  };
  onSelect: (challenges: Array<ChallengeModel>) => void;
  onCancel: () => void;
};

export type InjectedProps = {
  challengeList: Array<ChallengeModel>;
  pagination: PaginationModel;
  challengeFilters: ChallengeFilterModel;
  pinnedChallengeList: PinnedChallengeModel[];
  canEditPin: boolean;
  loading: boolean;
  getChallenges: (
    pagination: PaginationModel,
    filters: {},
    sortOrder: SortOrderItem,
  ) => void;
  isTierActionAllowed: (tierAction: TierAction) => boolean;
};

export type ChallengeSelectProps = InjectedProps & ExternalProps;

export type ChallengeSelectForm = {
  keyword: string;
};

/**
 * State interface
 */
export interface ChallengeSelectState {
  sortItem: SortOrderItem;
  formValues: ChallengeSelectForm;
  selectedChallenges: Array<ChallengeModel>;
  selectedChallenge?: ChallengeModel;
  drawer: {
    isOpen: boolean;
    targetChallengeId?: number;
  };
  reviewSelection: {
    isOpen: boolean;
    selectedChallenges: ChallengeModel[];
  };
}

/**
 * Page component
 */
class ChallengeSelect extends React.Component<
  ChallengeSelectProps,
  ChallengeSelectState
> {
  constructor(props: ChallengeSelectProps) {
    super(props);

    const { options } = props;

    this.state = {
      sortItem: {} as SortOrderItem,
      formValues: { keyword: "", ...options?.defaultConditions },
      selectedChallenges: [],
      drawer: { isOpen: false },
      reviewSelection: { isOpen: false, selectedChallenges: [] },
    };
  }

  public componentDidMount() {
    this.props.getChallenges(
      new PaginationModel(),
      this.state.formValues,
      this.state.sortItem,
    );

    this.setState({
      selectedChallenges: [],
    });
  }

  public render() {
    const {
      drawer,
      reviewSelection,
      formValues: { keyword },
      selectedChallenges,
    } = this.state;

    const {
      isOpen,
      onCancel,
      challengeList,
      pinnedChallengeList,
      challengeFilters,
      drawerOptions,
      collectionOptions,
      options,
      canEditPin,
    } = this.props;

    const rootStyle = classnames("code-challenge-select");

    const challenges = challengeList
      .filter((challenge) =>
        options?.strictLinkedChallenge
          ? ChallengeSectionService.isValidLinkedChallenge(challenge)
          : true,
      )
      .map((challenge, index) => {
        const selected = this.props.selectedChallenges.some((item) =>
          ChallengeSectionService.isLinkedExamChallengePair(item, challenge),
        );
        const draft = challenge.status === ChallengeStatus.Draft;
        const pinnedChallenge = pinnedChallengeList.find(
          (item) => item.challengeId === challenge.id,
        );

        return (
          <CollapsibleChallengeColumn
            className={draft ? "challenge-column__draft" : ""}
            key={index}
            challenge={challenge}
            keyword={keyword}
            hasCheckbox={true}
            readOnly={selected || draft}
            checked={
              selected ||
              Boolean(
                selectedChallenges.some((item) =>
                  ChallengeSectionService.isLinkedChallengePair(
                    item,
                    challenge,
                  ),
                ),
              )
            }
            isPinned={pinnedChallenge !== undefined}
            onClickDetail={this.onOpenChallenge}
            onClickCheck={() => {
              this.setState({
                selectedChallenges: this.toggleSelectedChallenge(
                  selectedChallenges,
                  this.props.challengeList,
                  challenge,
                ),
              });
            }}
            onClickTag={this.onTagClick}
            postActionMenu={<Column />}
            isChallengeAllowed={isChallengeAllowed({
              challenge,
              isTierActionAllowed: this.props.isTierActionAllowed,
            })}
          />
        );
      });

    return (
      <div className={rootStyle}>
        <Modal
          isOpen={isOpen}
          size="full"
          title={<Msg id="exam.challenges.select" />}
          className="code-challenge-select__select"
          onClose={onCancel}
          onClickCancel={onCancel}
          onClickOk={this.onReview}
          okButtonLabel={
            <Msg
              id="challenge.select.review.button"
              values={{
                count: selectedChallenges.length,
              }}
            />
          }
          okButtonAriaLabel="Review Selection"
          hasSubMenu={true}
          ariaLabel="Select Challenge"
        >
          <SubMenuContainer>
            <SubMenuContainerLeft>
              <ChallengeFilter
                formValues={this.state.formValues}
                challengeFilters={challengeFilters}
                onFormChange={this.onFormChange}
                disableOptionKeys={options?.disableOptionKeys}
              />
            </SubMenuContainerLeft>
            <SubMenuContainerRight className="code-challenge-select__container-right">
              {!collectionOptions?.hideCollection && (
                <ChallengeCollection
                  selectedChallengeIds={this.props.selectedChallenges.flatMap(
                    (item) => [
                      item.challengeId,
                      ...(item.linkedChallenge
                        ? [item.linkedChallenge.id]
                        : []),
                    ],
                  )}
                  defaultConditions={collectionOptions?.defaultConditions}
                  temporarySelectedChallenges={this.state.selectedChallenges}
                  pinnedChallengeList={pinnedChallengeList}
                  canSelect={true}
                  onSelect={this.onSelectFromCollection}
                  modalOptions={collectionOptions}
                />
              )}
              <Loading
                isOpen={this.props.loading}
                fullScreen={false}
                overlay={false}
              />
              <Table className="code-challenge-select__table">
                <TableHead>
                  <HeaderColumn size={1} />
                  <HeaderColumn size={1}>
                    <SortableText
                      name="id"
                      sortItem={this.state.sortItem}
                      onClick={this.onSortChange}
                      ariaLabel="ID"
                    >
                      <Msg id="common.id" />
                    </SortableText>
                  </HeaderColumn>
                  <HeaderColumn size={4}>
                    <SortableText
                      name="title"
                      sortItem={this.state.sortItem}
                      onClick={this.onSortChange}
                      ariaLabel="Title"
                    >
                      <Msg id="common.title" />
                    </SortableText>
                  </HeaderColumn>
                  <HeaderColumn size={1}>
                    <SortableText
                      name="difficulty"
                      sortItem={this.state.sortItem}
                      onClick={this.onSortChange}
                      ariaLabel="Difficulty"
                    >
                      <Msg id={"difficulty"} />
                    </SortableText>
                  </HeaderColumn>
                  <HeaderColumn size={2}>
                    <SortableText
                      name="style"
                      sortItem={this.state.sortItem}
                      onClick={this.onSortChange}
                      ariaLabel="Style"
                    >
                      <Msg id={"challenge.style"} />
                    </SortableText>
                  </HeaderColumn>
                  <HeaderColumn size={1} className="align-right">
                    <SortableText
                      name="numberOfOrgsUsing"
                      sortItem={this.state.sortItem}
                      onClick={this.onSortChange}
                      ariaLabel="Number Of Organizations Using"
                    >
                      <Msg id={"challenge.numberOfOrgsUsing"} />
                    </SortableText>
                  </HeaderColumn>
                  <HeaderColumn size={1} className={"align-right"}>
                    <SortableText
                      name="basicTimeMinutes"
                      sortItem={this.state.sortItem}
                      onClick={this.onSortChange}
                      ariaLabel="Basic Time Minutes"
                    >
                      <Msg id={"basicTimeMinutes"} />
                    </SortableText>
                  </HeaderColumn>
                  <HeaderColumn size={1} className="align-right">
                    <SortableText
                      name="normalcyScore"
                      initialDirection={SortDirection.Desc}
                      sortItem={this.state.sortItem}
                      onClick={this.onSortChange}
                      ariaLabel="Score Distribution"
                    >
                      <Msg id="challenge.scoreDistribution" />
                    </SortableText>
                  </HeaderColumn>
                  <HeaderColumn size={1} className="align-right">
                    <SortableText
                      name="numberOfSubmission"
                      sortItem={this.state.sortItem}
                      onClick={this.onSortChange}
                      ariaLabel="Number Of Submissions"
                    >
                      <Msg id="challenge.numberOfSubmission" />
                    </SortableText>
                  </HeaderColumn>
                  <HeaderColumn size={1} className="align-right">
                    <SortableText
                      name="averageScore"
                      sortItem={this.state.sortItem}
                      onClick={this.onSortChange}
                      ariaLabel="Average Score"
                    >
                      <Msg id="challenge.average" />
                    </SortableText>
                  </HeaderColumn>
                  <HeaderColumn size={1} className="align-right">
                    <SortableText
                      name="medianMinutesTaken"
                      sortItem={this.state.sortItem}
                      onClick={this.onSortChange}
                      ariaLabel="Average Completion Time"
                    >
                      <Msg id="challenge.averageTimeTakenMin" />
                    </SortableText>
                  </HeaderColumn>
                  <HeaderColumn size={1} />
                </TableHead>
                {challenges}
              </Table>
              <div className="code-challenge-select__pagination">
                <Pagination
                  pagination={this.props.pagination}
                  onPageChange={this.onPageChange}
                />
              </div>
            </SubMenuContainerRight>
          </SubMenuContainer>
        </Modal>
        {reviewSelection.isOpen && (
          <Modal
            isOpen={true}
            className="code-challenge-select__review"
            size="x-large"
            title={<Msg id="challenge.select.review" />}
            onClickOk={this.onSelect}
            okButtonLabel={
              <Msg
                id="challenge.select.add.button"
                values={{
                  count: reviewSelection.selectedChallenges.length,
                }}
              />
            }
            okButtonAriaLabel="Add Selection"
            cancelButtonLabel={<Msg id="action.back" />}
            cancelButtonAriaLabel="Back"
            onClickCancel={this.onCancelReview}
            onClose={this.onCancelReview}
            ariaLabel="Review Selection"
          >
            <p className="code-challenge-select__review-text">
              <Msg id="challenge.select.review.text" />
            </p>
            <Table className="code-challenge-select__review-table">
              <TableHead>
                <HeaderColumn size={1} />
                <HeaderColumn size={1}>
                  <Msg id="common.id" />
                </HeaderColumn>
                <HeaderColumn size={4}>
                  <Msg id="common.title" />
                </HeaderColumn>
                <HeaderColumn size={1}>
                  <Msg id="difficulty" />
                </HeaderColumn>
                <HeaderColumn size={2}>
                  <Msg id="challenge.style" />
                </HeaderColumn>
                <HeaderColumn size={1} className="align-right">
                  <Msg id="challenge.numberOfOrgsUsing" />
                </HeaderColumn>
                <HeaderColumn size={1} className={"align-right"}>
                  <Msg id="basicTimeMinutes" />
                </HeaderColumn>
                <HeaderColumn size={1} className="align-right">
                  <Msg id="challenge.scoreDistribution" />
                </HeaderColumn>
                <HeaderColumn size={1} className="align-right">
                  <Msg id="challenge.numberOfSubmission" />
                </HeaderColumn>
                <HeaderColumn size={1} className="align-right">
                  <Msg id="challenge.average" />
                </HeaderColumn>
                <HeaderColumn size={1} className="align-right">
                  <Msg id="challenge.averageTimeTakenMin" />
                </HeaderColumn>
                <HeaderColumn size={1} />
              </TableHead>
              {selectedChallenges.map((challenge) => {
                const pinnedChallenge = pinnedChallengeList.find(
                  (item) => item.challengeId === challenge.id,
                );

                return (
                  <CollapsibleChallengeColumn
                    key={challenge.id}
                    challenge={challenge}
                    hasCheckbox={true}
                    checked={Boolean(
                      reviewSelection.selectedChallenges.some(
                        (item) => item.id === challenge.id,
                      ),
                    )}
                    onClickDetail={this.onOpenChallenge}
                    onClickCheck={() =>
                      this.setState({
                        reviewSelection: {
                          isOpen: true,
                          selectedChallenges: this.toggleSelectedChallenge(
                            reviewSelection.selectedChallenges,
                            selectedChallenges,
                            challenge,
                          ),
                        },
                      })
                    }
                    postActionMenu={<Column />}
                    isChallengeAllowed={isChallengeAllowed({
                      challenge,
                      isTierActionAllowed: this.props.isTierActionAllowed,
                    })}
                    isPinned={pinnedChallenge !== undefined}
                  />
                );
              })}
            </Table>
          </Modal>
        )}
        {drawer.isOpen && drawer.targetChallengeId !== undefined && (
          <>
            <ChallengeDetail
              challengeId={drawer.targetChallengeId}
              highlightedKeyword={keyword}
              hideCopy={drawerOptions?.hideCopy}
              onClose={this.onCloseModal}
              hidePin={options?.hidePin === true || !canEditPin}
            />
            <Overlay
              className="code-challenge-select__overlay"
              isOpen={true}
              onClick={this.onCloseModal}
            />
          </>
        )}
      </div>
    );
  }

  private onPageChange = ({ selected }: { selected: number }) => {
    this.props.getChallenges(
      this.props.pagination.getAtPage(selected),
      this.state.formValues,
      this.state.sortItem,
    );
  };

  private onSortChange = (sortItem: SortOrderItem) => {
    this.setState({
      sortItem,
    });

    this.props.getChallenges(
      new PaginationModel(),
      this.state.formValues,
      sortItem,
    );
  };

  private onFormChange = (
    formValid: boolean,
    formValues: ChallengeSelectForm,
    formErrors: {},
  ) => {
    if (isEqual(formValues, this.state.formValues)) {
      return;
    }

    this.setState({
      formValues,
    });

    this.props.getChallenges(
      new PaginationModel(),
      formValues,
      this.state.sortItem,
    );
  };

  private onSelect = () => {
    if (this.props.onSelect) {
      this.props.onSelect(this.state.reviewSelection.selectedChallenges);
    }
  };

  private onOpenChallenge = (challengeId: number) => {
    this.setState({
      drawer: { isOpen: true, targetChallengeId: challengeId },
    });
  };

  private onCloseModal = () => {
    this.setState({ drawer: { isOpen: false } });
  };

  private onTagClick = (params: Partial<ChallengeFilterFormModel>) => {
    const { formValues: prevFormValues, sortItem } = this.state;
    const formValues = { ...prevFormValues, ...params };
    this.setState({ formValues });

    this.props.getChallenges(new PaginationModel(), formValues, sortItem);
  };

  private onSelectFromCollection = (data: {
    selectedChallenges: ChallengeModel[];
    removedChallenges: ChallengeModel[];
  }) => {
    const { selectedChallenges, removedChallenges } = data;
    this.setState({
      selectedChallenges: [
        ...this.state.selectedChallenges,
        ...selectedChallenges
          .filter((item) =>
            this.state.selectedChallenges.every(
              (selected) =>
                !ChallengeSectionService.isLinkedChallengePair(selected, item),
            ),
          )
          .map((item) => this.buildChallengeModel(item)),
      ].filter(
        (challenge) =>
          !removedChallenges.some((item) =>
            ChallengeSectionService.isLinkedChallengePair(item, challenge),
          ),
      ),
    });
  };

  private onReview = () => {
    const { selectedChallenges } = this.state;
    if (selectedChallenges.length) {
      this.setState({
        reviewSelection: {
          isOpen: true,
          selectedChallenges,
        },
      });
    } else {
      this.props.onCancel();
    }
  };

  private onCancelReview = () => {
    const { selectedChallenges } = this.state.reviewSelection;
    this.setState({
      reviewSelection: { isOpen: false, selectedChallenges: [] },
      selectedChallenges,
    });
  };

  /**
   * Toggle selected challenge state from the selected challenges
   * @param selectedChallenges
   * @param sourceChallenges
   * @param challenge
   */
  private toggleSelectedChallenge(
    selectedChallenges: ChallengeModel[],
    sourceChallenges: ChallengeModel[],
    challenge: ChallengeModel,
  ): ChallengeModel[] {
    let newSelectedChallenges = [];
    const selected = selectedChallenges.some((item) =>
      ChallengeSectionService.isLinkedChallengePair(item, challenge),
    );
    if (!selected) {
      const targetChallenge = sourceChallenges.find((item) =>
        ChallengeSectionService.isLinkedChallengePair(item, challenge),
      );
      if (!targetChallenge) {
        throw new Error(`challenge is not found. id=${challenge.id}`);
      }
      newSelectedChallenges = [
        ...selectedChallenges,
        this.buildChallengeModel(targetChallenge),
      ];
    } else {
      newSelectedChallenges = selectedChallenges.filter(
        (item) =>
          !ChallengeSectionService.isLinkedChallengePair(item, challenge),
      );
    }
    return newSelectedChallenges;
  }

  /**
   * Challenge data that comes from challenge select modal is lacking with some field
   * @param challenge
   */
  private buildChallengeModel = (challenge: ChallengeModel) => {
    const pinnedChallenge = this.props.pinnedChallengeList.find(
      (item) => item.challengeId === challenge.id,
    );
    return new ChallengeModel({
      ...challenge,
      favoriteId: pinnedChallenge?.id,
      difficulty: challenge.currentVersion.difficulty,
      basicTimeMinutes: challenge.currentVersion.basicTimeMinutes,
      usedChallengeVersionCode: `${challenge.currentVersion.majorVersionNumber}.${challenge.currentVersion.minorVersionNumber}`,
    });
  };
}

export default ChallengeSelect;
