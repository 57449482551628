import { Epic } from "redux-observable";
import { Observable } from "rxjs/Observable";

import { Action, imageUploadAction, alertAction } from "../actions";
import { RootState } from "../reducers";
import { uploadImage } from "../shared/services/image";

export interface ImageUploadAction {
  name: string;
  file: File;
  width: number;
  height: number;
  tags: string;
  transformation?: string;
}

export const imageUploadEpic: Epic<Action, RootState> = (action$, state) =>
  action$
    .ofType(imageUploadAction.types.request)
    .switchMap(({ payload }: Action & { payload: ImageUploadAction }) =>
      uploadImage(
        payload.name,
        payload.file,
        payload.width,
        payload.height,
        payload.tags,
        payload.transformation,
      )
        .flatMap((response) => [
          imageUploadAction.success({
            name: payload.name,
            imageUrl: response.secure_url,
          }),
        ])
        .catch((error: Error) =>
          Observable.of(
            alertAction.error(
              error.message,
              imageUploadAction.failure,
            )({ name: payload.name, error: error.message }),
          ),
        ),
    );

export default [imageUploadEpic];
