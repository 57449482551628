import {
  Action,
  APIResponseAction,
  guestReportExamAnalysisAction,
  guestReportExamScoresAction,
  guestReportSubmissionAction,
  guestReportSubmissionAnalysisAction,
  guestReportSubmissionCategoriesAction,
  guestReportEvaluationPointAction,
  guestReportCategoryEnumAction,
} from "../actions";
import {
  EnumModel,
  ReportChallengeModel,
  ApplicantReportModel,
  ApplicantReportChallengeModel,
  ApplicantReportCategoryScoresModel,
  ApplicantReportEvaluationPointScoresModel,
  SubmissionReportChallengeModel,
  SubmissionReportChallengeCategoryModel,
  ReportExamScore,
} from "../shared/models";
import { ChallengeStyle } from "../shared/services/enums";
import { getEvaluationPoints } from "../shared/services/evaluationPoint";

export interface GuestReportState {
  examAnalysisLoading: boolean;
  examAnalysisError: boolean;
  examAnalysis: Array<ReportChallengeModel>;

  examScoresLoading: boolean;
  examScoresError: boolean;
  examScores: Array<ReportExamScore>;

  submissionDetailLoading: boolean;
  submissionDetailError: boolean;
  submissionDetail?: ApplicantReportModel;

  submissionAnalysisLoading: boolean;
  submissionAnalysisError: boolean;
  submissionAnalysis: Array<ApplicantReportChallengeModel>;

  submissionCategoriesLoading: boolean;
  submissionCategoriesError: boolean;
  submissionCategories: Array<ApplicantReportCategoryScoresModel>;

  submissionEvaluationPointsLoading: boolean;
  submissionEvaluationPointsError: boolean;
  submissionEvaluationPoints: Array<ApplicantReportEvaluationPointScoresModel>;

  categoryEnumLoading: boolean;
  categoryEnumError: boolean;
  categoryEnum: Array<EnumModel>;
}

const initialState = {
  examAnalysisLoading: false,
  examAnalysisError: false,
  examAnalysis: [],

  examScoresLoading: false,
  examScoresError: false,
  examScores: [],

  submissionDetailLoading: false,
  submissionDetailError: false,
  submissionDetail: undefined,

  submissionAnalysisLoading: false,
  submissionAnalysisError: false,
  submissionAnalysis: [],

  submissionCategoriesLoading: false,
  submissionCategoriesError: false,
  submissionCategories: [],

  submissionEvaluationPointsLoading: false,
  submissionEvaluationPointsError: false,
  submissionEvaluationPoints: [],

  categoryEnumLoading: false,
  categoryEnumError: false,
  categoryEnum: [],
};

export const reportLoading = (state: GuestReportState) =>
  state.examAnalysisLoading ||
  state.submissionAnalysisLoading ||
  state.submissionCategoriesLoading ||
  state.categoryEnumLoading;

export const reportError = (state: GuestReportState) =>
  state.examAnalysisError ||
  state.submissionAnalysisError ||
  state.submissionCategoriesError ||
  state.categoryEnumError;

export const challengeSelector = (state: GuestReportState) => {
  if (!state.submissionDetail) {
    return [];
  }

  const categoryMap = state.categoryEnum.reduce((mapped, category) => {
    mapped[category.value] = category;
    return mapped;
  }, {});

  return state.examAnalysis.map((challenge) => {
    const applicantChallengeData =
      state.submissionAnalysis.find(
        (c) => c.challengeId === challenge.challengeId,
      ) || new ApplicantReportChallengeModel();

    let categories: SubmissionReportChallengeCategoryModel[] = [];

    // category business
    if (challenge.style === ChallengeStyle.Quiz) {
      categories = state.submissionCategories
        .filter(
          (category) =>
            category.challengeId === challenge.challengeId &&
            categoryMap[category.category],
        )
        .map((category) => {
          return new SubmissionReportChallengeCategoryModel({
            title: categoryMap[category.category].displayString,
            description: categoryMap[category.category].descriptionString,
            applicantScore: category.applicantCorrectAnswers,
            maximumScore: category.totalQuestionsNumber,
            examAverageScore: category.examAverageScore,
            systemAverageScore: category.globalAverageScore,
          });
        });
    } else {
      const { evaluationPoints } = getEvaluationPoints(
        applicantChallengeData.testOutput,
        applicantChallengeData.testcasesJson,
        applicantChallengeData.evaluationPoints,
      );
      categories = evaluationPoints.map((evaluationPoint) => {
        const evaluationPointAverages = state.submissionEvaluationPoints.find(
          (evalPointAv) =>
            evalPointAv.challengeId === challenge.challengeId &&
            evalPointAv.evaluationPoint === evaluationPoint.title,
        ) || { examAverageScore: undefined, systemAverageScore: undefined };

        return new SubmissionReportChallengeCategoryModel({
          title: evaluationPoint.title,
          description: evaluationPoint.description,
          applicantScore: evaluationPoint.testCases.filter(
            (testCase) => testCase.passed,
          ).length,
          maximumScore: evaluationPoint.testCases.length,
          examAverageScore: evaluationPointAverages.examAverageScore,
          systemAverageScore: evaluationPointAverages.systemAverageScore,
        });
      });
    }

    return new SubmissionReportChallengeModel({
      examId: challenge.examId,
      challengeId: challenge.challengeId,
      title: challenge.title,
      style: challenge.style,
      difficulty: challenge.difficulty,
      examAverageScore: challenge.examScopeScores.average,
      examMaxScore: challenge.examScopeScores.maxScore,
      examStandardDeviation: challenge.examScopeScores.standardDeviation,
      examScoreDevValue: challenge.examScopeScores.scoreDevValue,
      systemAverageScore: challenge.systemScopeScores.average,
      systemMaxScore: challenge.systemScopeScores.maxScore,
      systemStandardDeviation: challenge.systemScopeScores.standardDeviation,
      systemScoreDevValue: challenge.systemScopeScores.scoreDevValue,

      score: applicantChallengeData.score,
      timeSpentSeconds: applicantChallengeData.timeSpentSeconds,

      categories,
    });
  });
};

export const guestReportReducer = (
  state: GuestReportState = initialState,
  action: Action,
): GuestReportState => {
  const payload = (action as APIResponseAction).payload || {};

  switch (action.type) {
    /*
     * Exam Analysis (challenges)
     */
    case guestReportExamAnalysisAction.types.request:
      return {
        ...state,
        examAnalysisLoading: true,
        examAnalysisError: false,
        examAnalysis: [],
      };
    case guestReportExamAnalysisAction.types.failure:
      return {
        ...state,
        examAnalysisLoading: false,
        examAnalysisError: true,
      };
    case guestReportExamAnalysisAction.types.success:
      return {
        ...state,
        examAnalysisLoading: false,
        examAnalysis: ((payload.result as {}[]) || []).map(
          (result) => new ReportChallengeModel(result),
        ),
      };

    /*
     * Exam Score Distribution
     */
    case guestReportExamScoresAction.types.request:
      return {
        ...state,
        examScoresLoading: true,
        examScoresError: false,
        examScores: [],
      };
    case guestReportExamScoresAction.types.failure:
      return {
        ...state,
        examScoresLoading: false,
        examScoresError: true,
      };
    case guestReportExamScoresAction.types.success:
      return {
        ...state,
        examScoresLoading: false,
        examScores: (payload.result as ReportExamScore[]) || [],
      };

    /*
     * Submission Detail
     */
    case guestReportSubmissionAction.types.request:
      return {
        ...state,
        submissionDetailLoading: true,
        submissionDetailError: false,
        submissionDetail: undefined,
      };
    case guestReportSubmissionAction.types.failure:
      return {
        ...state,
        submissionDetailLoading: false,
        submissionDetailError: true,
      };
    case guestReportSubmissionAction.types.success:
      return {
        ...state,
        submissionDetailLoading: false,
        submissionDetail:
          payload.result && new ApplicantReportModel(payload.result),
      };

    /*
     * Submission Analysis (challenges)
     */
    case guestReportSubmissionAnalysisAction.types.request:
      return {
        ...state,
        submissionAnalysisLoading: true,
        submissionAnalysisError: false,
        submissionAnalysis: [],
      };
    case guestReportSubmissionAnalysisAction.types.failure:
      return {
        ...state,
        submissionAnalysisLoading: false,
        submissionAnalysisError: true,
      };
    case guestReportSubmissionAnalysisAction.types.success:
      return {
        ...state,
        submissionAnalysisLoading: false,
        submissionAnalysis: ((payload.result as {}[]) || []).map(
          (result) => new ApplicantReportChallengeModel(result),
        ),
      };

    /*
     * Submission Category Scores
     */
    case guestReportSubmissionCategoriesAction.types.request:
      return {
        ...state,
        submissionCategoriesLoading: true,
        submissionCategoriesError: false,
        submissionCategories: [],
      };
    case guestReportSubmissionCategoriesAction.types.failure:
      return {
        ...state,
        submissionCategoriesLoading: false,
        submissionCategoriesError: true,
      };
    case guestReportSubmissionCategoriesAction.types.success:
      return {
        ...state,
        submissionCategoriesLoading: false,
        submissionCategories: ((payload.result as {}[]) || [])
          .map((result) => new ApplicantReportCategoryScoresModel(result))
          .filter((result) => result.category),
      };

    /*
     * Evaluation Point Averages
     */
    case guestReportEvaluationPointAction.types.request:
      return {
        ...state,
        submissionEvaluationPointsLoading: true,
        submissionEvaluationPointsError: false,
        submissionEvaluationPoints: [],
      };
    case guestReportEvaluationPointAction.types.failure:
      return {
        ...state,
        submissionEvaluationPointsLoading: false,
        submissionEvaluationPointsError: true,
      };
    case guestReportEvaluationPointAction.types.success:
      return {
        ...state,
        submissionEvaluationPointsLoading: false,
        submissionEvaluationPoints: ((payload.result as {}[]) || [])
          .map(
            (result) => new ApplicantReportEvaluationPointScoresModel(result),
          )
          .filter(
            (result) => result.evaluationPoint && result.evaluationPoint.length,
          ),
      };

    /*
     * Category Enum
     */
    case guestReportCategoryEnumAction.types.request:
      return {
        ...state,
        categoryEnumLoading: true,
        categoryEnumError: false,
        categoryEnum: [],
      };
    case guestReportCategoryEnumAction.types.failure:
      return {
        ...state,
        categoryEnumLoading: false,
        categoryEnumError: true,
      };
    case guestReportCategoryEnumAction.types.success:
      return {
        ...state,
        categoryEnumLoading: false,
        categoryEnum: (payload.result as EnumModel[]) || [],
      };

    default:
      return state;
  }
};
