import { ProjectRole } from "@shared/services/enums";

export * from "./memberRoleForm";
export * from "./projectRoleForm";

export const PROJECT_MEMBER_ROLES = [
  ProjectRole.ProjectAdmin,
  ProjectRole.ExamCreator,
  ProjectRole.ExamDeliverer,
  ProjectRole.ChallengeCreator,
  ProjectRole.Reviewer,
];
