import classnames from "classnames";
import * as React from "react";

import Message from "@shared/services/message";
import { numberToLetters } from "@shared/services/string";

import {
  Checkbox,
  Markdown,
  Table,
  Row,
  Column,
  TableHead,
  TableBody,
  HeaderColumn,
  Msg,
  Tag,
  Loading,
  Tooltip,
  Icon,
} from "..";
import {
  QuestionModel,
  QuestionFIBModel,
  QuestionMCQModel,
  QuestionFreeTextModel,
} from "../../models";

/**
 * Enum
 */
import { ChallengeStatus, QuestionType } from "../../services/enums";
import { InsightOverviewRow } from "./partials/InsightOverviewRow";
import { PossiblyNoDataWithTooltip } from "./partials/PossiblyNoDataWithTooltip";

/**
 * Prop interface
 */
export interface ExternalProps {
  question?: QuestionModel;
  highlightedKeyword?: string;
  hasHeader?: boolean;
  hasError?: boolean;
  className?: string;
  showTextarea?: boolean;
  // Define this prop if you want to display question insights
  insightsDisplayOptions?: {
    showGlobalStats?: boolean;
    showExamLevelStats?: boolean;
    showOverview?: boolean;
  };
}

type InjectedProps = {
  getExamLevelInsights: (examId: number, questionId: number) => {};
};

type QuestionProps = ExternalProps & InjectedProps;

/**
 * React Component
 */
export default function Question({
  hasHeader = true,
  hasError = false,
  question = new QuestionModel(),
  className,
  showTextarea,
  insightsDisplayOptions,
}: QuestionProps) {
  const rootStyle = classnames("code-c-question", {
    "has-error": hasError,
    [`${className}`]: Boolean(className),
  });

  const {
    showGlobalStats = false,
    showExamLevelStats = false,
    showOverview = false,
  } = insightsDisplayOptions ?? {};

  const header = hasHeader ? (
    <div className="code-c-question__header">
      {question.status === ChallengeStatus.Draft && (
        <Tag className="code-c-question__status_tag">
          {Message.getMessageByKey("challenge.drafted")}
        </Tag>
      )}
      {question.status === ChallengeStatus.Removed && (
        <Tag className="code-c-question__status_tag">
          {Message.getMessageByKey("challenge.removed")}
        </Tag>
      )}
      <p className="code-c-question__title">{question.title}</p>
      <div className="tags" />
    </div>
  ) : null;

  let overviewSection = null;
  if (showOverview) {
    overviewSection = (
      <>
        <dd>
          <Table insideBordered={true} hoverable={false}>
            <TableHead>
              <HeaderColumn absoluteSize={5} />
              <HeaderColumn className="align-right">
                <Msg id="question.companyUsage" />
              </HeaderColumn>
              <HeaderColumn className="align-right">
                <Msg id="question.submissionCount" />
              </HeaderColumn>
              <HeaderColumn className="align-right">
                <Msg id="question.timeTaken.average" />
              </HeaderColumn>
              <HeaderColumn className="align-right">
                <Tooltip
                  text={
                    <Msg id="question.timeTaken.standardDeviation.tooltip" />
                  }
                  maxSize="medium"
                >
                  <Msg id="question.timeTaken.standardDeviation" />
                </Tooltip>
              </HeaderColumn>
            </TableHead>
            <TableBody>
              {showExamLevelStats && (
                <InsightOverviewRow
                  rowNameKey="common.exam"
                  insight={question?.examLevelInsight}
                  shouldShowOrganizationCount={false}
                />
              )}
              <InsightOverviewRow
                rowNameKey="common.global"
                insight={question?.globalInsight}
                shouldShowOrganizationCount={true}
              />
            </TableBody>
          </Table>
        </dd>
      </>
    );
  }

  let contents = null;

  switch (question.kind) {
    case QuestionType.MCQ:
      const questionMCQ = new QuestionMCQModel(question);

      const choices = questionMCQ.getChoices().map((choice, index) => {
        const isCorrectAnswer = questionMCQ.isCorrectAnswer(index);
        const rowStyle = classnames({
          "is-correct-answer": isCorrectAnswer,
        });

        return insightsDisplayOptions ? (
          <Row key={`mcq-${index}`} className={rowStyle}>
            <Column className={classnames("align-center")}>
              {isCorrectAnswer && <Icon type="check" />}
            </Column>
            <Column
              className={classnames("align-center", "has-text-weight-bold")}
            >
              {numberToLetters(index)}
            </Column>
            <Column>
              <Markdown body={choice} />
            </Column>
            {showExamLevelStats && (
              <Column className="align-right">
                <PossiblyNoDataWithTooltip
                  value={questionMCQ.getExamLevelAnswerRateAsFormattedString(
                    index,
                  )}
                />
              </Column>
            )}
            {showGlobalStats && (
              <Column className="align-right">
                <PossiblyNoDataWithTooltip
                  value={questionMCQ.getGlobalAnswerRateAsFormattedString(
                    index,
                  )}
                />
              </Column>
            )}
          </Row>
        ) : (
          <li key={`mcq-${index}`}>
            <Checkbox
              className={rowStyle}
              readOnly={true}
              value={questionMCQ.isUserAnswer(index)}
            >
              <Markdown body={choice} />
            </Checkbox>
          </li>
        );
      });

      contents = (
        <dl className="code-c-question__body">
          <dt>
            <Msg id="common.question" />
          </dt>
          <dd>
            <Markdown body={question.getQuestionText()} />
          </dd>
          <dt>
            <Msg
              id={
                insightsDisplayOptions ? "question.insights" : "common.answer"
              }
            />
          </dt>
          <dd>
            {insightsDisplayOptions ? (
              <Table
                hoverable={false}
                className="code-c-question__mcq_container"
              >
                <TableHead>
                  <HeaderColumn
                    size={2}
                    className={classnames("align-center", "correct-column")}
                  >
                    <Msg id="submission.quiz.correct" />
                  </HeaderColumn>
                  <HeaderColumn
                    size={2}
                    className={classnames("align-center", "answer-column")}
                  >
                    <Msg id="common.answer" />
                  </HeaderColumn>
                  <HeaderColumn />
                  {showExamLevelStats && (
                    <HeaderColumn size={4} className="align-right">
                      <Msg id="question.answerRate.exam" />
                    </HeaderColumn>
                  )}
                  {showGlobalStats && (
                    <HeaderColumn size={4} className="align-right">
                      <Msg id="question.answerRate.global" />
                    </HeaderColumn>
                  )}
                </TableHead>
                <TableBody>{choices}</TableBody>
              </Table>
            ) : (
              <ol type="A" className="code-c-question__mcq_container">
                {choices}
              </ol>
            )}
          </dd>
          {overviewSection}
          {question.howToSolve && (
            <>
              <dt>
                <Msg id="common.explanation" />
              </dt>
              <dd>
                <Markdown body={question.howToSolve} />
              </dd>
            </>
          )}
        </dl>
      );
      break;

    case QuestionType.FIB:
      const questionFIB = new QuestionFIBModel(question);
      const hasUserAnswer = Boolean(questionFIB.getUserAnswers().length);

      const answersFIB = questionFIB.getCorrectAnswer().map((answer, index) => {
        const fibStyle = classnames("code-c-question__fib-item", {
          "is-wrong-answer":
            hasUserAnswer && !questionFIB.isCorrectAnswer(index),
        });

        return (
          <Row key={`fbi-${index}`} className={fibStyle}>
            <Column
              className={classnames("align-center", "has-text-weight-bold")}
            >
              {index + 1}.
            </Column>
            <Column>{answer}</Column>
            {showExamLevelStats && (
              <Column className="align-right">
                <PossiblyNoDataWithTooltip
                  value={questionFIB.getExamLevelCorrectRateAsFormattedString(
                    index,
                  )}
                />
              </Column>
            )}
            {showGlobalStats && (
              <Column className="align-right">
                <PossiblyNoDataWithTooltip
                  value={questionFIB.getGlobalCorrectRateAsFormattedString(
                    index,
                  )}
                />
              </Column>
            )}
            {hasUserAnswer && (
              <Column>{questionFIB.getUserAnswers()[index]}</Column>
            )}
          </Row>
        );
      });

      contents = (
        <dl className="code-c-question__body">
          <dt>
            <Msg id="common.question" />
          </dt>
          <dd>
            <Markdown
              mode={question.kind}
              body={question.getQuestionText()}
              answers={questionFIB.getCorrectAnswer(false)}
            />
          </dd>
          <dt>
            <Msg
              id={
                insightsDisplayOptions ? "question.insights" : "common.answer"
              }
            />
          </dt>
          <dd>
            <Table hoverable={false}>
              <TableHead>
                <HeaderColumn
                  size={2}
                  className={classnames("align-center", "blank-column")}
                ></HeaderColumn>
                <HeaderColumn>
                  <Msg id="submission.quiz.rightAnswer" />
                </HeaderColumn>
                {showExamLevelStats && (
                  <HeaderColumn size={4} className="align-right">
                    <Msg id="question.correctRate.exam" />
                  </HeaderColumn>
                )}
                {showGlobalStats && (
                  <HeaderColumn size={4} className="align-right">
                    <Msg id="question.correctRate.global" />
                  </HeaderColumn>
                )}
                {hasUserAnswer && (
                  <HeaderColumn>
                    <Msg id="submission.quiz.applicantsAnswer" />
                  </HeaderColumn>
                )}
              </TableHead>
              <TableBody>{answersFIB}</TableBody>
            </Table>
          </dd>
          {overviewSection}
          {question.howToSolve && (
            <>
              <dt>
                <Msg id="common.explanation" />
              </dt>
              <dd>
                <Markdown mode={question.kind} body={question.howToSolve} />
              </dd>
            </>
          )}
        </dl>
      );
      break;

    case QuestionType.FreeText:
      const questionFreeText = new QuestionFreeTextModel(question);
      const answerFreeText = questionFreeText.getUserAnswer();

      // TODO: if no answer, display placeholder text (eg: 解答未記入です)
      contents = (
        <dl className="code-c-question__body">
          <dt>
            <Msg id="common.question" />
          </dt>
          <dd>
            <Markdown
              mode={question.kind}
              body={question.getQuestionText()}
              showTextarea={showTextarea}
            />
          </dd>
          {insightsDisplayOptions && (
            <>
              <dt>
                <Msg id="question.insights" />
              </dt>
              <dd>
                <Table
                  hoverable={false}
                  fullwidth={false}
                  className="code-c-question__insights__freetext"
                >
                  <TableHead>
                    {showExamLevelStats && (
                      <HeaderColumn className={classnames("align-center")}>
                        <Msg id="question.responseRate.exam" />
                      </HeaderColumn>
                    )}
                    {showGlobalStats && (
                      <HeaderColumn className={classnames("align-center")}>
                        <Msg id="question.responseRate.global" />
                      </HeaderColumn>
                    )}
                  </TableHead>
                  <TableBody>
                    <Row>
                      {showExamLevelStats && (
                        <Column className="align-right">
                          <PossiblyNoDataWithTooltip
                            value={questionFreeText.getExamLevelAnswerRateAsFormattedString()}
                          />
                        </Column>
                      )}
                      {showGlobalStats && (
                        <Column className="align-right">
                          <PossiblyNoDataWithTooltip
                            value={questionFreeText.getGlobalAnswerRateAsFormattedString()}
                          />
                        </Column>
                      )}
                    </Row>
                  </TableBody>
                </Table>
              </dd>
            </>
          )}
          {overviewSection}
          {questionFreeText.hasUserAnswer() && (
            <div>
              <dt>
                <Msg id="common.answer" />
              </dt>
              <dd>
                <div className="code-c-question__freetext">
                  {answerFreeText.split("\n").map((line, index) => (
                    <React.Fragment key={`free-txt-${index}`}>
                      {line ? <div>{line}</div> : <br />}
                    </React.Fragment>
                  ))}
                </div>
                {/* add style: word-break: break-word */}
              </dd>
            </div>
          )}
          {question.howToSolve && (
            <>
              <dt>
                <Msg id="common.explanation" />
              </dt>
              <dd>
                <Markdown mode={question.kind} body={question.howToSolve} />
              </dd>
            </>
          )}
        </dl>
      );
      break;
    default:
  }

  const isLoading = showExamLevelStats && question.loadingInsight;

  return (
    <div className={rootStyle}>
      {header}
      <Loading isOpen={isLoading} fullScreen={false} overlay={false} />
      {!isLoading && contents}
    </div>
  );
}
