import * as React from "react";
import { FormattedMessage } from "react-intl";

import Message from "@shared/services/message";

import { JumpTo } from "..";

/**
 * Props
 */
export interface MsgProps {
  id: string;
  defaultMessage?: string;
  tagName?: React.ElementType | "none";
  values?: {};
  // href for when there is an anchor link <a> in the message
  to?: string;
  className?: string;
}

export default function Msg({
  id,
  defaultMessage,
  tagName = "span",
  values = {},
  to,
  className,
}: MsgProps) {
  const defaultValues = {
    br: <br />,
    /* eslint-disable react/display-name */
    p: (chunks: string) => <p>{chunks}</p>,
    li: (chunks: string) => <li>{chunks}</li>,
    i: (chunks: string) => <i>{chunks}</i>,
    strong: (chunks: string) => <strong>{chunks}</strong>,
    small: (chunks: string) => <small>{chunks}</small>,
    a: (chunks: string) => <JumpTo to={to || ""}>{chunks}</JumpTo>,
    intercom: (chunks: string) => (
      <a onClick={() => window.Intercom && window.Intercom("show")}>{chunks}</a>
    ),
    privacy: (chunks: string) => (
      <JumpTo to={Message.getMessageByKey("common.privacy.url")}>
        {chunks}
      </JumpTo>
    ),
    applicantTerms: (chunks: string) => (
      <JumpTo to={Message.getMessageByKey("common.applicantTerms.url")}>
        {chunks}
      </JumpTo>
    ),
    faq: (chunks: string) => (
      <JumpTo to={process.env.REACT_APP_FAQ_URL || ""}>{chunks}</JumpTo>
    ),
    /* eslint-enable react/display-name */
  };

  const params = {
    id,
    defaultMessage,
    values: { ...defaultValues, ...values },
  };

  if (className) {
    return (
      <span className={className}>
        <FormattedMessage {...params} />
      </span>
    );
  }

  return (
    <FormattedMessage
      // if no tag name supplied, returns a fragment
      {...(tagName !== "none" && { tagName })}
      {...params}
    />
  );
}
