class ProjectModel {
  public id: number;
  public organizationId: number;
  public applicationType: number;
  public name: string;
  public kind: number;
  public status: number;
  public createdBy: number;
  public updatedBy: number;
  public createdAt: string;
  public updatedAt: string;
  public reportingEnabled: boolean;
  public examDeliveryLimit: number;
  /**
   * @deprecated Use tier action permission instead
   */
  public idDeliveryEnabled: boolean;
  /**
   * @deprecated Use tier action permission instead
   */
  public enhancedSubmissionDownloadEnabled: boolean;

  constructor(args?: {}) {
    Object.assign(this, args);
  }
}

export default ProjectModel;
