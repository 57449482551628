// import reducers here
import { combineReducers } from "redux";

import { intlReducer } from "@shared/libs/react-intl-redux";

import { ActionLogState, actionLogReducer } from "./ActionLogReducer";
import { AdminState, adminReducer } from "./AdminReducer";
import { AjaxState, ajaxReducer } from "./AjaxReducer";
import { ApplicantState, applicantReducer } from "./ApplicantReducer";
import {
  ApplicantTimerState,
  applicantTimerReducer,
} from "./ApplicantTimerReducer";
import { AuthState, authReducer } from "./AuthReducer";
import { ChallengeState, challengeReducer } from "./ChallengeReducer";
import { ExamState, examReducer } from "./ExamReducer";
import {
  ExternalSystemState,
  externalSystemReducer,
} from "./ExternalSystemReducer";
import { GlobalState, globalReducer } from "./GlobalReducer";
import { GuestReportState, guestReportReducer } from "./GuestReportReducer";
import {
  GuestSubmissionState,
  guestSubmissionReducer,
} from "./GuestSubmissionReducer";
import { HealthCheckState, healthCheckReducer } from "./HealthCheckReducer";
import { ImageUploadState, imageUploadReducer } from "./ImageUploadReducer";
import { OrgsState, orgsReducer } from "./OrgsReducer";
import { ProjectState, projectReducer } from "./ProjectReducer";
import { QuestionState, questionReducer } from "./QuestionReducer";
import { ReadmeState, readmeReducer } from "./ReadmeReducer";
import { ReportState, reportReducer } from "./ReportReducer";
import { UserState, userReducer } from "./UserReducer";

interface IntlState {
  locale: string;
  messages: {};
}

export type RootState = {
  admin: AdminState;
  global: GlobalState;
  intl: IntlState;
  ajax: AjaxState;
  user: UserState;
  auth: AuthState;
  orgs: OrgsState;
  exam: ExamState;
  project: ProjectState;
  challenge: ChallengeState;
  question: QuestionState;
  applicant: ApplicantState;
  applicantTimer: ApplicantTimerState;
  readme: ReadmeState;
  externalSystem: ExternalSystemState;
  guestSubmission: GuestSubmissionState;
  healthCheck: HealthCheckState;
  report: ReportState;
  guestReport: GuestReportState;
  imageUpload: ImageUploadState;
  actionLog: ActionLogState;
};

const organizationReducers = combineReducers({
  global: globalReducer,
  intl: intlReducer,
  ajax: ajaxReducer,
  user: userReducer,
  auth: authReducer,
  orgs: orgsReducer,
  exam: examReducer,
  project: projectReducer,
  challenge: challengeReducer,
  question: questionReducer,
  readme: readmeReducer,
  admin: adminReducer,
  report: reportReducer,
  imageUpload: imageUploadReducer,
  actionLog: actionLogReducer,
});

const applicantReducers = combineReducers({
  intl: intlReducer,
  ajax: ajaxReducer,
  applicant: applicantReducer,
  applicantTimer: applicantTimerReducer,
  healthCheck: healthCheckReducer,
});

const adminReducers = combineReducers({
  global: globalReducer,
  admin: adminReducer,
  intl: intlReducer,
  ajax: ajaxReducer,
  user: userReducer,
  auth: authReducer,
  orgs: orgsReducer,
  readme: readmeReducer,
  project: projectReducer,
  exam: examReducer,
  challenge: challengeReducer,
  imageUpload: imageUploadReducer,
  externalSystem: externalSystemReducer,
});

const guestReducers = combineReducers({
  intl: intlReducer,
  ajax: ajaxReducer,
  readme: readmeReducer,
  guestSubmission: guestSubmissionReducer,
  guestReport: guestReportReducer,
});

export {
  organizationReducers,
  applicantReducers,
  adminReducers,
  guestReducers,
};
