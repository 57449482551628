import classnames from "classnames";
import dayjs from "dayjs";
import { cloneDeep } from "lodash";
import _ from "lodash";
import { useEffect, useState } from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { Prompt, withRouter } from "react-router-dom";

import DocumentTitle from "@shared/components/documentTitle/DocumentTitle.connect";
import { FormType } from "@shared/components/form/types";
import { Icon } from "@shared/components/icon/icon/Icon";
import Loading from "@shared/components/loading/Loading";
import Modal from "@shared/components/modal/Modal";
import Msg from "@shared/components/msg/Msg";
import { StepsHeader } from "@shared/components/stepsHeader/StepsHeader";
import { ExamChallengeSetModel } from "@shared/models/ExamChallenge.model";
import IdDeliveryConfigModel from "@shared/models/IdDeliveryConfig.model";
import MemberListModel from "@shared/models/MemberList.model";
import { ExamType, TierAction } from "@shared/services/enums";
import { scrubExamChallengeUpdatePayload } from "@shared/services/exam";
import MSG from "@shared/services/message";

import ExamChallenges from "../examSections/examChallenges/ExamChallenges.connect";
import ExamForms from "../examSections/examForms/ExamForms";
import ExamOutline from "../examSections/examOutline/ExamOutline";
import ExamReviewers, {
  ALL_REVIEWERS_OPTION,
} from "../examSections/examReviewers/ExamReviewers";
import { prepareFormValues } from "../examSections/examSectionUtil/ExamSectionUtil";
import { DraftFormProvider } from "../examSections/examSettingsNew/DraftFormProvider";
import { ExamSettingsForm } from "../examSections/examSettingsNew/ExamSettingsNew";
import {
  ExamSettingsFormValidation,
  formInitialValues,
} from "../examSections/examSettingsNew/utils";
import { ExamCreateProps, Form, ReviewerForm } from "./types";
import {
  formatApplicantActionSettingsValues,
  formatAutoFilterSettingsValues,
  formatGuestSharingValues,
  formatNewFormValues,
  formatWebcamSettingsValues,
  normalizeReviewers,
} from "./utils";

const ExamCreate = ({
  isTierActionAllowed,
  currentProjectId,
  uploadingImage,
  enableMultiLanguage,
  projectMemberListLoading,
  getProjectDetails,
  getProjectMembers,
  getInvitationStatus,
  projectMembers,
  invitationStatus,
  submitting,
  location,
  onCreateNew,
  examDetail,
}: ExamCreateProps) => {
  const fromCopy = location?.state?.fromCopy;
  const hasReviewFeature = isTierActionAllowed(TierAction.ReviewFunctionality);
  const examType = Boolean(enableMultiLanguage)
    ? ExamType.EnglishJapanese
    : ExamType.SingleLanguage;

  const [stepValid, setStepValid] = useState(
    fromCopy
      ? [true, true, true, ...(hasReviewFeature ? [true, false] : [false])]
      : [false, false, true, ...(hasReviewFeature ? [true, false] : [false])],
  );
  useEffect(() => {
    setStepValid(
      fromCopy
        ? [true, true, true, ...(hasReviewFeature ? [true, false] : [false])]
        : [false, false, true, ...(hasReviewFeature ? [true, false] : [false])],
    );
  }, [hasReviewFeature, fromCopy]);

  const [currentStep, setCurrentStep] = useState(0);
  const { formValues: initialformValues } = prepareFormValues({
    hasReviewFeature,
    examDetail,
  });
  const [creating, setCreating] = useState(false);
  const [formValues, setFormValues] = useState<Partial<Form>>(
    fromCopy
      ? initialformValues
      : {
          formDefs: {
            forms: [],
            applicantNameRequired: true,
          },
          challengesSets: [],
        },
  );
  const [visited, setVisited] = useState([true, false, false, false, false]);
  const [stepValues, setStepValues] = useState({});

  const [isFormAlertModalOpen, setIsFormAlertModalOpen] = useState(false);

  const [reviewers, setReviewers] = useState<MemberListModel[]>([]);

  const [keyword, setKeyword] = useState("");

  const [reviewerForm, setReviewerForm] = useState<ReviewerForm>({
    checkAll: false,
    selectedReviewers: {},
    shareReviewsWithReviewers: false,
    requiredReviewerCount: "1",
  });

  useEffect(() => {
    getProjectDetails(currentProjectId);
    getProjectMembers();
    getInvitationStatus();
  }, []);

  useEffect(() => {
    setReviewers(normalizeReviewers(projectMembers || [], invitationStatus));
    setReviewerForm({
      checkAll: false,
      selectedReviewers: projectMembers.reduce(
        (acc, r) => {
          acc[r.id] = false;
          return acc;
        },
        {} as Record<number, boolean>,
      ),
      shareReviewsWithReviewers: false,
      requiredReviewerCount: "1",
    });
  }, [projectMembers]);

  const stepErrors = stepValid.map(
    (valid: boolean, index: number) => !valid && visited[index],
  );
  const rootStyle = classnames("code-exam-edit");

  const handleChangeStep = (step: number) => {
    setCurrentStep(step);
    setVisited((prevVisited) =>
      prevVisited.map((visited, index) => index <= step),
    );
  };

  const closeFormAlertModal = () => {
    setIsFormAlertModalOpen(false);
  };

  const onRowClick = (member: MemberListModel) => {
    const newReviewerForm = cloneDeep(reviewerForm);
    newReviewerForm.selectedReviewers[member.id] =
      !newReviewerForm.selectedReviewers[member.id];
    newReviewerForm.checkAll = reviewers.every(({ id }) =>
      Boolean(newReviewerForm.selectedReviewers[id]),
    );
    // delay time to update state due to rerender causing issues multiple onclick triggers
    setTimeout(() => {
      setReviewerForm(newReviewerForm);
    }, 0);
  };

  const onToggleAllCheck = () => {
    const newReviewerForm = cloneDeep(reviewerForm);
    newReviewerForm.checkAll = !newReviewerForm.checkAll;
    reviewers.forEach(
      ({ id }) =>
        (newReviewerForm.selectedReviewers[id] = newReviewerForm.checkAll),
    );
    setReviewerForm(newReviewerForm);
  };

  const onFormChange = (
    step: number,
    formValid: boolean,
    stepValues: {},
    formErrors?: {},
  ) => {
    const newStepValid = [...stepValid];
    if (step === 1 || step === 0) {
      newStepValid[step] = formValid || !formErrors;
    } else {
      newStepValid[step] = formValid;
    }

    setTimeout(() => {
      setStepValid(newStepValid);
      setStepValues((prevStepValues) => ({ ...prevStepValues, ...stepValues }));
      const newFormValues = formatNewFormValues({ formValues, stepValues });

      // I need to stop endless rerenders here by checking if the formValues are the same
      // the outline form is a cause of this
      if (!_.isEqual(formValues, newFormValues)) {
        setFormValues(newFormValues);
      }
    }, 0);
  };

  const handleJumpToForm = () => {
    closeFormAlertModal();
    handleChangeStep(2);
  };

  const onSettingsChange = ({
    share,
    requiredReviewerCount,
  }: {
    share: boolean;
    requiredReviewerCount: string;
  }) => {
    setReviewerForm({
      ...reviewerForm,
      shareReviewsWithReviewers: share,
      requiredReviewerCount: requiredReviewerCount,
    });
  };

  const onKeyworkChange = (keyword: string) => {
    setKeyword(keyword);
    const reviewers = projectMembers.filter(({ name, email }) => {
      const keywordLowerCase = keyword.toLowerCase();
      return (
        name.toLocaleLowerCase().includes(keywordLowerCase) ||
        email.toLocaleLowerCase().includes(keywordLowerCase)
      );
    });
    const newReviewers = normalizeReviewers(reviewers, invitationStatus);
    const newReviewerForm = cloneDeep(reviewerForm);
    newReviewerForm.checkAll =
      newReviewers.length > 0 &&
      newReviewers.every(({ id }) => reviewerForm.selectedReviewers[id]);
    setReviewers(newReviewers);
    setReviewerForm(newReviewerForm);
  };

  const handleCreateExam = async () => {
    const newFormValues = {
      ...formValues,
      ...stepValues,
    };

    const {
      idDeliveryConfig = new IdDeliveryConfigModel(),
      formDefs = { applicantNameRequired: true },
    } = newFormValues;

    if (
      Object.keys(idDeliveryConfig).length &&
      formDefs.applicantNameRequired
    ) {
      setIsFormAlertModalOpen(true);
    } else {
      createExam();
    }
  };

  const createExam = () => {
    const newFormValues = formatNewFormValues({ formValues, stepValues });

    // not a best practice but this component should be typed properly
    const { engineerRole, purposeOfUse, ...rest } = newFormValues as any;
    const requiredReviews =
      reviewerForm.requiredReviewerCount === ALL_REVIEWERS_OPTION
        ? {
            requiredType: "all",
          }
        : {
            requiredType: "count",
            count: +reviewerForm.requiredReviewerCount,
          };
    const guestSharingSettingsFormatted = formatGuestSharingValues(
      newFormValues.guestSharingEnabled!,
      newFormValues.guestSharingSettings!,
    );

    const applicantActionSettingsFormatted =
      formatApplicantActionSettingsValues(
        newFormValues.applicantActionSettingsEnabled!,
        newFormValues.applicantActionSettings!,
      );

    const webcamSettingsFormatted = formatWebcamSettingsValues(
      newFormValues.applicantActionSettingsEnabled!,
      newFormValues.webcamSettings!,
    );

    const autoFilterSettingsFormatted = formatAutoFilterSettingsValues(
      newFormValues.autoFilterSettings,
      newFormValues.autoFilterSettingsEnabled as boolean,
    );

    const exam = {
      status: 1,
      language: "ja",
      ...rest,
      examSegment: {
        engineerRole: Number(engineerRole),
        purposeOfUse,
      },
      challengesSets: scrubExamChallengeUpdatePayload(
        newFormValues?.challengesSets as unknown as ExamChallengeSetModel[],
      ),
      reviewers: Object.keys(reviewerForm.selectedReviewers)
        .filter((id) => Boolean(reviewerForm.selectedReviewers[id]))
        .map((id) => parseInt(id, 10)),
      shareReviewsWithReviewers: reviewerForm.shareReviewsWithReviewers,
      reviewSettings: {
        requiredReviews,
      },
      idDeliveryConfig: newFormValues.idDeliveryConfig
        ? {
            ...newFormValues.idDeliveryConfig,
            startAt: dayjs(newFormValues.idDeliveryConfig.startAt).format(),
            endAt: dayjs(newFormValues.idDeliveryConfig.endAt).format(),
          }
        : undefined,
      urlSharingConfig: newFormValues.urlSharingConfig
        ? {
            ...newFormValues.urlSharingConfig,
            startAt: dayjs(newFormValues.urlSharingConfig.startAt).format(),
            endAt: dayjs(newFormValues.urlSharingConfig.endAt).format(),
          }
        : undefined,
      examType,
      guestSharingSettings: guestSharingSettingsFormatted,
      applicantActionSettings: applicantActionSettingsFormatted,
      webcamSettings: webcamSettingsFormatted,
      autoFilterSettings: autoFilterSettingsFormatted,
    };

    setFormValues(newFormValues);
    setStepValues({});
    setCreating(true);
    console.log({ exam });
    onCreateNew(exam);
  };

  const steps = [
    <ExamOutline
      key="examOutline"
      examType={examType}
      initialValues={fromCopy ? initialformValues : formValues}
      onFormChange={(formValid, formValues, formErrors) => {
        onFormChange(0, formValid, formValues, formErrors);
      }}
      showAllErrors={visited[0] && stepValid[0] === false}
      componentType="examCreate"
    />,
    <ExamChallenges
      key="examChallenges"
      examType={examType}
      challengesSets={
        (formValues.challengesSets as unknown as ExamChallengeSetModel[]) || []
      }
      editAllowed={true}
      onFormChange={(formValid, formValues, formErrors) => {
        console.log("Exam Challenges", { formValid, formErrors });
        onFormChange(1, formValid, formValues, formErrors);
      }}
      showAllErrors={visited[1] && stepValid[1] === false}
      challengeSelectOptions={{ showOfficial: true }}
      showExamLevelInsights={false}
    />,
    <ExamForms
      key="examForms"
      examType={examType}
      initialValues={formValues.formDefs ? formValues.formDefs.forms : []}
      applicantNameRequired={
        formValues.formDefs ? formValues.formDefs.applicantNameRequired : true
      }
      onFormChange={(formValid, formValues) => {
        onFormChange(2, formValid, formValues);
      }}
    />,
  ];

  if (hasReviewFeature) {
    steps.push(
      <ExamReviewers
        key="examReviewers"
        initialValues={reviewerForm}
        canReview={isTierActionAllowed(TierAction.ReviewFunctionality)}
        projectMemberListLoading={projectMemberListLoading}
        resetForm
        checkAll={reviewerForm.checkAll}
        reviewers={reviewers}
        keyword={keyword}
        onRowClick={onRowClick}
        onToggleAllCheck={onToggleAllCheck}
        onSettingsChange={onSettingsChange}
        setKeyword={onKeyworkChange}
      />,
    );
  }
  steps.push(
    <ExamSettingsForm
      type={FormType.Create}
      key="examSettingsForm"
      onFormChange={(formValid, formValues) => {
        onFormChange(hasReviewFeature ? 4 : 3, formValid, formValues);
      }}
    />,
  );

  return (
    <div className={rootStyle}>
      <Prompt
        when={
          !creating &&
          (Object.keys(formValues).length > 0 ||
            Object.keys(stepValues).length > 0)
        }
        message={MSG.getMessageByKey("navigation.confirm")}
      />
      <DocumentTitle
        page={`${MSG.getMessageByKey("common.exam")}${MSG.getMessageByKey(
          "create",
        )}`}
      />
      <Loading isOpen={submitting} />
      <BreadcrumbsItem to={`/p/${currentProjectId}/exams`}>
        <Msg id="common.examList" />
      </BreadcrumbsItem>
      <BreadcrumbsItem to={`/p//${currentProjectId}/exams/new`}>
        <Msg id="common.exam" />
        <Msg id="create" />
      </BreadcrumbsItem>
      <StepsHeader
        className="code-exam-edit__steps"
        stepItems={[
          MSG.getMessageByKey("exam.outline"),
          MSG.getMessageByKey("exam.challenges"),
          MSG.getMessageByKey("exam.entryForm"),
          hasReviewFeature ? MSG.getMessageByKey("exam.reviewers") : "",
          MSG.getMessageByKey("exam.configure"),
        ].filter(Boolean)}
        currentStep={currentStep}
        errors={stepErrors}
        onNext={handleChangeStep}
        onPrev={handleChangeStep}
        onComplete={handleCreateExam}
        disableNext={false}
        disableComplete={
          stepValid.some((step) => step === false) || uploadingImage
        }
      />

      <DraftFormProvider<ExamSettingsFormValidation>
        defaultValues={formInitialValues}
      >
        {steps[currentStep]}
      </DraftFormProvider>
      <Modal
        className="form-alert-modal"
        isOpen={isFormAlertModalOpen}
        title={MSG.getMessageByKey("button.createExam")}
        onClickOk={createExam}
        onClickCancel={closeFormAlertModal}
        onClose={closeFormAlertModal}
        ariaLabel="ID Delivery Confirmation"
      >
        <Icon type="exclamation-triangle" size="medium" />
        <Msg id="createExam.confirm.applicantNameRequiredWithIdDelivery" />
        <div className="jump-button" onClick={() => handleJumpToForm()}>
          <Msg id="createExam.editEntryForm" />
        </div>
      </Modal>
    </div>
  );
};

export const ExamCreateNew = withRouter(ExamCreate);
