import * as classnames from "classnames";
import * as React from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";

import {
  Block,
  LeftBlock,
  Form,
  FormGroup,
  Label,
  Button,
  PageTitle,
  Checkbox,
  Restricted,
  Msg,
  Tag,
  Select,
} from "@shared/components";
import { UserModel } from "@shared/models";
import { ProjectRole } from "@shared/services/enums";
import Message from "@shared/services/message";
import { isFeatureEnv, isLocal } from "@shared/services/url";

/**
 * Prop interface
 */
export interface AccountGeneralProps {
  language: string | undefined;
  hasSettings: boolean;
  dailySubmissionNotificationEnabled: boolean;
  realtimeSubmissionNotificationEnabled: boolean;
  newUserNotificationEnabled: boolean;
  hasSlackIntegration: boolean;
  slackAllNotificationEnabled: boolean;
  updating: boolean;
  updateUser: (payload: UserModel) => void;
  updateUserLocal: (payload: {}) => void;
  getSocialUserSettings: () => void;
  updateSocialUserSettings: (enableSlackAllNotification: boolean) => void;
}

/**
 * Page component
 */
class AccountGeneral extends React.Component<AccountGeneralProps> {
  public componentDidMount() {
    this.props.getSocialUserSettings();
  }

  public render() {
    const { language } = this.props;

    const rootStyle = classnames("code-account-general");

    const hostname = window.location.hostname;

    return (
      <div className={rootStyle}>
        <BreadcrumbsItem to="/settings/account/general">
          <Msg id="notificationSettings" />
        </BreadcrumbsItem>
        {(isLocal(hostname) || isFeatureEnv(hostname)) && (
          <div>
            <Block className="code-setting__header">
              <LeftBlock className="code-account-general__header">
                <PageTitle>
                  <Msg id="configure" />
                </PageTitle>
              </LeftBlock>
            </Block>
            <div className="code-account-general__form">
              <FormGroup>
                <Label className="code-account-general__label">
                  <Msg id="common.language" />
                  <Tag>Local Only</Tag>
                </Label>
                <Select
                  options={["en", "ja"].map((key) => ({
                    label: Message.getMessageByKey(key),
                    value: key,
                  }))}
                  value={language}
                  onChange={this.handleLanguageChanged}
                />
              </FormGroup>
            </div>
          </div>
        )}
        <Block className="code-account-general__header code-setting__header">
          <LeftBlock>
            <PageTitle>
              <Msg id="notificationSettings" />
            </PageTitle>
          </LeftBlock>
        </Block>
        {this.props.hasSettings ? (
          <Form
            validation={{
              dailySubmissionNotificationEnabled: ["boolean"],
              realtimeSubmissionNotificationEnabled: ["boolean"],
              slackAllNotificationEnabled: ["boolean"],
            }}
            className="code-account-general__form"
            initialValues={{
              dailySubmissionNotificationEnabled:
                this.props.dailySubmissionNotificationEnabled,
              realtimeSubmissionNotificationEnabled:
                this.props.realtimeSubmissionNotificationEnabled,
              newUserNotificationEnabled: this.props.newUserNotificationEnabled,
              slackAllNotificationEnabled:
                this.props.slackAllNotificationEnabled,
            }}
            onSubmit={this.handleUpdate}
            submitting={this.props.updating}
          >
            <Restricted
              roles={[ProjectRole.ProjectAdmin, ProjectRole.Reviewer]}
            >
              <Label title={<Msg id="form.notifications" />} />
              <div className="code-account-general__form-group">
                <FormGroup>
                  <Label title={<Msg id="form.notificationType" />} />
                  <Checkbox name="dailySubmissionNotificationEnabled">
                    <Msg id="form.notifications.submissionsDaily" />
                  </Checkbox>
                  <Checkbox name="realtimeSubmissionNotificationEnabled">
                    <Msg id="form.notifications.submissionsRealtime" />
                  </Checkbox>
                  <Checkbox name="newUserNotificationEnabled">
                    <Msg id="form.notifications.userInviteAcceptance" />
                  </Checkbox>
                </FormGroup>
                <FormGroup>
                  <Restricted
                    roles={[ProjectRole.ProjectAdmin]}
                    strictAllow={this.props.hasSlackIntegration}
                  >
                    <Label title={<Msg id="form.slackTitle" />} />
                    <Checkbox name="slackAllNotificationEnabled">
                      <Msg id="form.notifications.slackAllNotifications" />
                    </Checkbox>
                  </Restricted>
                </FormGroup>
              </div>
              <FormGroup className="code-account-general__button">
                <Button type="primary" ariaLabel="Update">
                  <Msg id="action.update" />
                </Button>
              </FormGroup>
            </Restricted>
          </Form>
        ) : (
          <div className="code-account-general__form">
            <Msg id="account.no.notification.settings" />
          </div>
        )}
      </div>
    );
  }

  private handleUpdate = (
    formValues: UserModel & { slackAllNotificationEnabled: boolean },
  ) => {
    const { slackAllNotificationEnabled, ...userUpdateValues } = formValues;

    this.props.updateUser(userUpdateValues as UserModel);

    if (this.props.hasSlackIntegration) {
      this.props.updateSocialUserSettings(slackAllNotificationEnabled);
    }
  };

  private handleLanguageChanged = (
    event: React.FormEvent<HTMLSelectElement>,
  ) => {
    const { updateUserLocal, language } = this.props;
    const { value } = event.currentTarget;

    if (value !== language) {
      updateUserLocal({ language: value });
    }
  };
}

export default AccountGeneral;
