import * as classnames from "classnames";
import { isEqual } from "lodash";
import * as React from "react";

import {
  Challenge,
  CliSupportBadge,
  Column,
  DifficultyTag,
  Icon,
  LinkedTag,
  Loading,
  Row,
  TableBody,
  TagCloud,
  Msg,
} from "..";
import {
  ExamChallengeModel,
  EnumModel,
  ChallengeModel,
  ReadmeModel,
} from "../../models";
import { ChallengeStyle } from "../../services/enums";

/**
 * Prop interface
 */
export interface ExternalProps {
  actions?: React.ReactNode;
  challenge: ExamChallengeModel;
  challengeDetail?: ChallengeModel;
  challengeReadme?: ReadmeModel;
  className?: string;
  loading?: boolean;
}
export interface InjectedProps {
  appBasePath: string;
  programmingLanguages: Array<EnumModel>;
  getChallengeDetail?: (challengeId: number) => void;
}

type CollapsibleExamChallengeColumnProps = InjectedProps & ExternalProps;

/**
 * State interface
 */
export interface CollapsibleExamChallengeColumnState {
  isOpen: boolean;
}

/**
 * React Component
 */
export default class CollapsibleExamChallengeColumn extends React.Component<
  CollapsibleExamChallengeColumnProps,
  CollapsibleExamChallengeColumnState
> {
  constructor(props: CollapsibleExamChallengeColumnProps) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }
  public shouldComponentUpdate(
    nextProps: CollapsibleExamChallengeColumnProps,
    nextState: CollapsibleExamChallengeColumnState,
  ) {
    return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state);
  }

  public render() {
    const {
      actions,
      challenge,
      challengeDetail,
      challengeReadme,
      className,
      appBasePath,
      loading,
      programmingLanguages = [],
    } = this.props;

    const { isOpen } = this.state;

    const rootStyle = classnames("code-c-collapsible-exam-challenge-column", {
      [`${className}`]: Boolean(className),
      "is-open": isOpen,
    });

    const tagItems = (challenge: ExamChallengeModel) =>
      (challenge.style !== ChallengeStyle.Algorithm &&
      challenge.originalChallenge &&
      challenge.originalChallenge.programmingLanguages
        ? challenge.originalChallenge.programmingLanguages
        : challenge.programmingLanguages
      )
        .map(
          (id) =>
            (
              programmingLanguages.find(
                (language) => language.value === id,
              ) || {
                displayString: "",
              }
            ).displayString,
        )
        .map((tag) => ({ label: tag }));

    return (
      <TableBody className={rootStyle} ariaExpanded={isOpen}>
        <Row className="code-c-collapsible-exam-challenge-column__header">
          <Column className="header__title">
            <div className="challenge-tags">
              {challenge.originalChallenge.linkedChallenge && <LinkedTag />}
              {challenge.localExamEnabled && <CliSupportBadge />}
            </div>
            <div
              className="challenge-title-wrapper"
              onClick={this.onClickOpen}
              role="button"
              aria-pressed={isOpen}
              aria-label="Toggle Challenge Detail"
            >
              <div className="challenge-title" title={challenge.title}>
                {challenge.title}
              </div>
              <div>
                <Icon type={isOpen ? "angle-up" : "angle-down"} />
              </div>
            </div>
          </Column>
          <Column className="align-right">
            <DifficultyTag value={challenge.difficulty} />
          </Column>
          <Column className="align-right">
            {ChallengeStyle.toString(challenge.style)}
          </Column>
          <Column className="align-right">
            {challenge.timeLimitMinutes !== undefined ? (
              challenge.timeLimitMinutes
            ) : (
              <Msg id={"noLimit"} />
            )}
          </Column>
          {actions}
        </Row>
        <Row className="code-c-collapsible-exam-challenge-column__body">
          <Column colSpan={5}>
            {challenge.description && (
              <div className="body__discription">{challenge.description}</div>
            )}
            {tagItems(challenge).length > 0 && (
              <TagCloud tagItems={tagItems(challenge)} />
            )}
          </Column>
        </Row>
        {isOpen && (
          <Row
            className="code-c-collapsible-exam-challenge-column__content"
            ariaHidden={!isOpen}
          >
            <Column colSpan={5} className="content-container">
              <Loading isOpen={loading} fullScreen={false} overlay={false} />
              {!loading && (
                <Challenge
                  challenge={challengeDetail}
                  readme={challengeReadme}
                  appBasePath={appBasePath}
                />
              )}
            </Column>
          </Row>
        )}
      </TableBody>
    );
  }

  private onClickOpen = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });

    if (this.props.getChallengeDetail) {
      this.props.getChallengeDetail(this.props.challenge.challengeId);
    }
  };
}
