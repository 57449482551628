import classnames from "classnames";
import { forwardRef } from "react";

export interface DefaultOptionContainerProps {
  floatingProps: Record<string, unknown>;
  popupClassName?: string;
  close: () => void;
}

export const DefaultOptionContainer = forwardRef<
  HTMLDivElement,
  DefaultOptionContainerProps & React.HTMLProps<HTMLDivElement>
>(({ floatingProps, popupClassName, children }, ref) => {
  return (
    <div
      ref={ref}
      {...floatingProps}
      className={classnames("select-floating", popupClassName)}
    >
      {children}
    </div>
  );
});
