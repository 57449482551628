import * as React from "react";
import { Link } from "react-router-dom";

import { Icon } from "@shared/components";

interface ExamOutlineSectionProps {
  children?: React.ReactNode;
  labelTo?: string;
  to?: string;
}

interface ExamOutlineSectionHeaderProps {
  children?: React.ReactNode;
  icon?: string;
}

interface ExamOutlineSectionCheckboxProps {
  children?: React.ReactNode;
  checked?: boolean;
}

function ExamOutlineSection({
  children,
  labelTo,
  to,
}: ExamOutlineSectionProps) {
  return (
    <div className="code-exam-dashboard-outline__section">
      <div className="code-exam-dashboard-outline__section-content">
        {children}
      </div>
      {Boolean(to) && (
        <Link
          to={to as string}
          className="code-exam-dashboard-outline__section-footer"
        >
          {labelTo}
        </Link>
      )}
    </div>
  );
}

ExamOutlineSection.Header = function Header({
  children,
  icon,
}: ExamOutlineSectionHeaderProps) {
  return (
    <div className="code-exam-dashboard-outline__section-header">
      {icon && <Icon type={icon} color="primary" />}
      {children && <h3 className="text-lg">{children}</h3>}
    </div>
  );
};

ExamOutlineSection.Checkbox = function Checkbox({
  children,
  checked,
}: ExamOutlineSectionCheckboxProps) {
  return (
    <div>
      <Icon
        type="circle-check"
        color={checked ? "primary" : "neutral-300"}
        variant={checked ? "solid" : "regular"}
        className="code-c-icon__text-base code-exam-dashboard-outline__icon"
      />
      {children}
    </div>
  );
};

export default ExamOutlineSection;
