import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Action, Dispatch } from "redux";

import {
  reportCategoriesEnumAction,
  reportChallengeAction,
  reportResultsAction,
  reportCategoriesAction,
  reportEvaluationPointAction,
  reportScoresAction,
  reportRankingAction,
} from "@actions";

import { RootState } from "@reducers";

import { challengeSelector } from "@reducers/ReportReducer";

import { UserModel } from "@shared/models";
import { getTierAllowedByRedux } from "@shared/services/tier";

import SubmissionDetail from "./SubmissionDetail";

const mapStateToProps = (
  state: RootState,
  props: RouteComponentProps<{ examId: string; submissionId: string }>,
) => {
  return {
    examId: Number(props.match.params.examId),
    submissionId: Number(props.match.params.submissionId),
    currentProjectId: state.project.currentProjectId,
    currentUser: state.user.user || new UserModel(),
    applicantReportDetails: state.report.applicantDetails,
    reportExamScores: state.report.scores,
    reportLoading:
      state.report.challengesLoading ||
      state.report.applicantChallengesLoading ||
      state.report.challengeCategoriesLoading,
    reportChallenges: challengeSelector(
      state.report,
      state.question.questionFilters.quizCategories,
    ),
    isTierActionAllowed: getTierAllowedByRedux(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  getSubmissionDetail: (submissionId: number, examId?: number) => {
    // Keep as reference util migrate submission state complete
    // dispatch(reviewListGetAction.request(submissionId));
    // dispatch(submissionResultListGetAction.request({ submissionId }));
    // dispatch(submissionFormGetAction.request(submissionId));
    // from report
    dispatch(reportRankingAction.request(submissionId));
    dispatch(reportScoresAction.request(examId));
  },
  getReport: (submissionId: number) => {
    dispatch(reportCategoriesEnumAction.request());
    dispatch(reportChallengeAction.request(submissionId));
    dispatch(reportResultsAction.request(submissionId));
    dispatch(reportCategoriesAction.request(submissionId));
    dispatch(reportEvaluationPointAction.request(submissionId));
  },
  resetReport: () => {
    dispatch(reportChallengeAction.success());
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SubmissionDetail),
);
