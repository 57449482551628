import * as React from "react";

import { ErrorIcon, Icon, JumpTo, Msg, StackedIcon } from "..";

/**
 * Prop interface
 */

interface PanelStandardContentsProps {
  title: string;
  titleIcon?: string;
  jumpToLink?: string;
  children?: React.ReactNode;
  showErrorIcon?: boolean;
}

/**
 * Utility Functions
 */

export const buildPanelIcon = (
  iconType: string,
  variant?: "solid" | "regular" | undefined,
) => (
  <StackedIcon
    className="code-c-switch-panel-content__icon"
    icons={[
      { type: "square", variant: "solid", size: "normal" },
      { type: iconType, variant: variant || "regular", size: "normal" },
    ]}
  />
);

/**
 * React Component
 */
// Builds the content for a standard panel with an icon, title, help link and text
// Also need to set the initialLineHeight prop to "1.5rem" to align the circle with the first line of text
export default function PanelStandardContents({
  title,
  titleIcon,
  jumpToLink,
  children,
  showErrorIcon,
}: PanelStandardContentsProps) {
  return (
    <div className="code-c-switch-panel-content">
      <div className="code-c-switch-panel-content__title">
        {titleIcon && buildPanelIcon(titleIcon)}
        <span>
          {title}
          {jumpToLink && (
            <JumpTo to={jumpToLink}>
              <Icon
                type={"circle-question"}
                variant={"regular"}
                iconClassName="code-c-switch-panel-content__icon-helpbutton"
              />
            </JumpTo>
          )}
          {showErrorIcon && (
            <div className="warning-icon">
              <ErrorIcon tooltipText={<Msg id="tier.disabled" />} />
            </div>
          )}
        </span>
      </div>
      <div className="code-c-switch-panel-content__text">{children}</div>
    </div>
  );
}
