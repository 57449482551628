import * as classnames from "classnames";
import { isEqual } from "lodash";
import * as React from "react";

import {
  Form,
  FormGroup,
  Label,
  Input,
  Modal,
  QuickHelp,
  Msg,
} from "../../../../../shared/components";
import { ExamChallengeSetModel } from "../../../../../shared/models";
import Message from "../../../../../shared/services/message";
import { getChallengeSetWeight } from "../examSectionUtil/ExamSectionUtil";

/**
 * Prop interface
 */
export interface ExamChallengeSetEditProps {
  isOpen: boolean;
  challengeSet: ExamChallengeSetModel;
  onOK: (challengeSet: ExamChallengeSetModel, weight: number) => void;
  onCancel: () => void;
}

/**
 * State interface
 */
export interface ExamChallengeSetEditState {
  formValid: boolean;
  formValues: Partial<{ weight: number; numberChallengesToTake: number }>;
  formErrors: {};
}

/**
 * Page component
 */
class ExamChallengeSetEdit extends React.Component<
  ExamChallengeSetEditProps,
  ExamChallengeSetEditState
> {
  constructor(props: ExamChallengeSetEditProps) {
    super(props);
    this.state = {
      formValid: false,
      formValues: {},
      formErrors: {},
    };
  }

  public shouldComponentUpdate(
    nextProps: ExamChallengeSetEditProps,
    nextState: ExamChallengeSetEditState,
  ) {
    return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state);
  }

  public render() {
    const { isOpen, challengeSet } = this.props;
    const { formErrors } = this.state;
    const { isOptionalSet, numberChallengesToTake } = challengeSet;

    const rootStyle = classnames("code-exam-challenge-set-edit");

    const weight = getChallengeSetWeight(challengeSet);

    const initialValues = {
      weight: weight.toString(),
      numberChallengesToTake: (numberChallengesToTake === undefined
        ? 1
        : numberChallengesToTake
      ).toString(),
    };

    const validation = {
      weight: ["number", ["min", 0], ["max", 10], "required", "integer"],
      numberChallengesToTake: [
        "number",
        ["min", 0],
        ["max", 99],
        "required",
        "integer",
      ],
    };

    return (
      <Modal
        className={rootStyle}
        title={<Msg id="challengeSet.edit.title" />}
        isOpen={isOpen}
        onClickOk={this.onClickOK}
        onClose={this.props.onCancel}
        onClickCancel={this.props.onCancel}
        disableOk={Boolean(formErrors)}
        ariaLabel="Customize Challenge Set"
      >
        <Form
          initialValues={initialValues}
          validation={validation}
          onFormChange={this.onFormChange}
          clear={isOpen}
        >
          <FormGroup>
            <Label title={<Msg id="weight" />} />
            <Input name="weight" size={3} min="0" max="10" />
          </FormGroup>
          {isOptionalSet && (
            <FormGroup>
              <Label
                title={
                  <div>
                    <Msg id="exam.numberChallengesToTake" />
                    <QuickHelp
                      text={Message.getMessageByKey(
                        "exam.numberChallengesToTake.description",
                      )}
                      maxSize="medium"
                    />
                  </div>
                }
              />
              <Input name="numberChallengesToTake" size={3} min="0" />
            </FormGroup>
          )}
        </Form>
      </Modal>
    );
  }

  private onFormChange = (
    formValid: boolean,
    formValues: {},
    formErrors: {},
  ) => {
    this.setState({ formValid, formValues, formErrors });
  };

  private onClickOK = () => {
    const { challengeSet } = this.props;
    const { weight, numberChallengesToTake } = this.state.formValues;
    if (typeof this.props.onOK === "function") {
      this.props.onOK(
        {
          ...challengeSet,
          numberChallengesToTake: Number(numberChallengesToTake),
        },
        Number(weight),
      );
    }
  };
}

export default ExamChallengeSetEdit;
