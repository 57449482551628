import * as classnames from "classnames";
import { isEqual } from "lodash";
import * as React from "react";

import { Avatar, Icon, Overlay, Msg } from "@shared/components";
import { UserModel } from "@shared/models";

/**
 * Prop interface
 */
export interface AccountMenuProps {
  user: UserModel | undefined;
  signOut: () => void;
}

/**
 * State interface
 */
export interface AccountMenuState {
  isOpen: boolean;
}

const defaultProps: Partial<AccountMenuProps> = {
  user: new UserModel(),
};

/**
 * Page component
 */
export default class AccountMenu extends React.Component<
  AccountMenuProps,
  AccountMenuState
> {
  public static defaultProps = defaultProps;

  constructor(props: AccountMenuProps) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  public shouldComponentUpdate(
    nextProps: AccountMenuProps,
    nextState: AccountMenuState,
  ) {
    return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state);
  }

  public render() {
    const { isOpen } = this.state;

    const rootStyle = classnames("code-account-menu");

    const menuStyle = classnames("code-account-menu__menu", {
      "is-open": isOpen,
    });

    const { user = new UserModel() } = this.props;

    return (
      <div className={rootStyle} aria-expanded={isOpen}>
        <div
          className="code-account-menu__trigger"
          onClick={this.onToggle}
          role="button"
          aria-label="Account Menu"
          aria-pressed={isOpen}
        >
          <Avatar size="small" src={user.imageUrl} />
          <span className="is-hidden-mobile">{user.name}</span>
          <Icon type="caret-down" />
        </div>
        <div
          className={menuStyle}
          onClick={this.onToggle}
          role="menu"
          aria-hidden={!isOpen}
        >
          <div>
            <a
              role="menuitem"
              className="code-account-menu__menu-item"
              href={process.env.REACT_APP_TRACK_ACCOUNT_SERVICE_URL}
              rel="noreferrer noopener"
              target="_blank"
            >
              <Icon type="external-link" />
              <Msg id={"account.service"} />
            </a>
          </div>
          <div>
            <a
              role="menuitem"
              className="code-account-menu__menu-item"
              onClick={() => this.props.signOut()}
            >
              <Icon type="sign-out" />
              <Msg id={"signout"} />
            </a>
          </div>
        </div>
        <Overlay isOpen={isOpen} onClick={this.onToggle} />
      </div>
    );
  }

  private onToggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };
}
