import * as classnames from "classnames";

import {
  Icon,
  Msg,
  QuickHelp,
  Tooltip,
} from "../../../../../shared/components";
import { ChallengeModel } from "../../../../../shared/models";
import { getEvaluationPoints } from "../../../../../shared/services/evaluationPoint";
import Message from "../../../../../shared/services/message";

/**
 * Prop interface
 */
export interface EvaluationPointProps {
  challenge: ChallengeModel;
}

/**
 * React Component
 */
export function EvaluationPoint({ challenge }: EvaluationPointProps) {
  const rootStyle = classnames("code-c-evaluation-point");

  // UGH I really hate this nested shit from the backend
  const showEvaluationPoint =
    challenge &&
    challenge.currentVersion &&
    challenge.currentVersion.settings &&
    challenge.currentVersion.settings.evaluationPoint !== undefined;

  const evaluationPoints =
    challenge &&
    challenge.currentVersion &&
    (challenge.currentVersion.initTestOutput ||
      challenge.currentVersion.testcasesJson) &&
    challenge.currentVersion.settings &&
    challenge.currentVersion.settings.evaluationPoint
      ? getEvaluationPoints(
          challenge.currentVersion.initTestOutput,
          challenge.currentVersion.testcasesJson,
          challenge.currentVersion.settings.evaluationPoint,
        )
      : { testCaseCount: 0, evaluationPoints: [] };

  const totalTestCases = evaluationPoints.evaluationPoints.reduce(
    (count: number, evalPoint: { testCases: Array<{}> }) =>
      count + evalPoint.testCases.length,
    0,
  );

  const evaluationTable = evaluationPoints.evaluationPoints.map(
    (evalPoints) => (
      <div
        className="code-c-evaluation-point__row"
        key={evalPoints.order}
        role="rowgroup"
        aria-label={evalPoints.title}
      >
        <div className="code-c-evaluation-point__category" role="cell">
          <div className="code-c-evaluation-point__title">
            {evalPoints.title}
          </div>
          <div className="code-c-evaluation-point__count">
            {evalPoints.testCases.length}/{evaluationPoints.testCaseCount}
          </div>
          <div className="code-c-evaluation-point__description">
            {evalPoints.description}
          </div>
        </div>
        <div className="code-c-evaluation-point__testcases" role="rowgroup">
          {evalPoints.testCases.map((testCase) => (
            <div
              key={testCase.id}
              className="code-c-evaluation-point__testcase"
              role="row"
            >
              <span
                className="code-c-evaluation-point__testcase-secret"
                role="cell"
              >
                {testCase.secret && (
                  <Tooltip
                    text={Message.getMessageByKey(
                      "challenge.testcases.secret.tooltip",
                    )}
                  >
                    <Icon
                      type="lock"
                      className="code-c-evaluation-point__secret-icon"
                    />
                  </Tooltip>
                )}
              </span>
              <span
                className="code-c-evaluation-point__testcase-number"
                role="cell"
              >
                {testCase.id}
              </span>
              <span
                className="code-c-evaluation-point__testcase-description"
                role="cell"
              >
                {testCase.name}
              </span>
            </div>
          ))}
        </div>
      </div>
    ),
  );

  return (
    <div className={rootStyle}>
      {totalTestCases !== evaluationPoints.testCaseCount && (
        <div className="code-c-evaluation-point__warning" role="alert">
          <Icon type="exclamation-triangle" />
          <Msg id="error.evalPoint" />
        </div>
      )}
      {evaluationPoints.raw && (
        <div className="code-c-evaluation-point__warning" role="alert">
          <Icon type="exclamation-triangle" />
          <Msg id="challenge.evaluationPoint.outdated" />
        </div>
      )}
      {showEvaluationPoint ? (
        <>
          <div className="code-c-evaluation-point__testcases-summary">
            <div>
              <Msg id={"challenge.testcases.open"} />
              {`: ${challenge.currentVersion.openTestcases}/${totalTestCases}`}
            </div>
            <div>
              <Icon
                type="lock"
                className="code-c-evaluation-point__secret-icon"
              />
              <Msg id={"challenge.testcases.secret"} />
              {`: ${challenge.currentVersion.secretTestcases}/${totalTestCases}`}
              <QuickHelp
                text={Message.getMessageByKey(
                  "challenge.testcases.secret.note",
                )}
                maxSize="large"
              />
            </div>
          </div>
          <div className="code-c-evaluation-point__table" role="table">
            {evaluationTable}
          </div>
        </>
      ) : (
        <p role="status">
          <Msg id="challenge.noSolutionDetails" />
        </p>
      )}
    </div>
  );
}
