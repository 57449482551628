import * as classnames from "classnames";

import {
  ChallengeModel,
  ExamChallengeModel,
  ChallengeFilterFormModel,
} from "@shared/models";
import { isNew } from "@shared/services/date";

/**
 * Enums
 */
import {
  ChallengeStatus,
  ChallengeStyle,
  QuestionCategory,
  TierAction,
} from "@shared/services/enums";
import Message from "@shared/services/message";
import {
  isCustomCodingAllowed,
  isCustomQuizAllowed,
} from "@shared/services/tier";

import { Icon, New, Tag, LinkedTag, HighlightedText, LanguageTag } from "..";

/**
 * Prop interface
 */
export interface ChallengeTitleProps {
  challenge: ChallengeModel | (ExamChallengeModel & { status: number });
  isChallengeAllowed: boolean;
  keyword?: string;
  className?: string;
  onClick?: () => void;
  onTagClick?: (params: Partial<ChallengeFilterFormModel>) => void;
  isTierActionAllowed: (tierAction: TierAction) => boolean;
}

/**
 * React Component
 */
export const ChallengeTitle = ({
  challenge,
  keyword,
  isChallengeAllowed,
  className,
  onClick,
  onTagClick,
  isTierActionAllowed,
}: ChallengeTitleProps) => {
  const rootStyle = classnames("code-c-challenge-title", {
    [`${className}`]: Boolean(className),
  });

  function handleTagClick(params: Partial<ChallengeFilterFormModel>) {
    if (typeof onTagClick === "function") {
      onTagClick(params);
    }
  }

  return (
    <div className={rootStyle}>
      <div className="code-c-challenge-title__header">
        {!isChallengeAllowed && (
          <div className="tag code-c-challenge-title__not-available">
            <Icon type="exclamation-triangle" />
            {Message.getMessageByKey("tier.disabled.challenge")}
          </div>
        )}
        {challenge.createdAt && isNew(challenge.createdAt) && (
          <New onClick={() => handleTagClick({ onlyNew: true })} />
        )}
        {"language" in challenge && (
          <LanguageTag
            language={challenge.language}
            onClick={() =>
              handleTagClick({ spokenLanguages: [challenge.language] })
            }
          />
        )}
        {challenge.isOfficial ? (
          <Tag
            onClick={() =>
              handleTagClick({
                categories: [QuestionCategory.Preset],
              })
            }
            hasError={
              challenge.style === ChallengeStyle.Quiz
                ? !isCustomQuizAllowed(challenge, isTierActionAllowed)
                : !isCustomCodingAllowed(challenge, isTierActionAllowed)
            }
          >
            {Message.getMessageByKey("questionCategory.preset")}
          </Tag>
        ) : (
          <Tag
            onClick={() =>
              handleTagClick({
                categories: [QuestionCategory.Original],
              })
            }
          >
            {Message.getMessageByKey("questionCategory.original")}
          </Tag>
        )}
        {challenge.status === ChallengeStatus.Draft && (
          <Tag className="code-c-challenge-title__tag">
            {Message.getMessageByKey("challenge.drafted")}
          </Tag>
        )}
        {challenge.status === ChallengeStatus.Removed && (
          <Tag className="code-c-challenge-title__tag">
            {Message.getMessageByKey("challenge.removed")}
          </Tag>
        )}
        {challenge.linkedChallenge &&
          challenge.linkedChallenge.status === ChallengeStatus.Ready && (
            <LinkedTag onClick={() => handleTagClick({ isLinked: true })} />
          )}
      </div>
      <a
        className={classnames("code-c-challenge-title__body", {
          clickable: isChallengeAllowed,
        })}
        onClick={isChallengeAllowed ? onClick : undefined}
        role="button"
        aria-label="Toggle Challenge Detail"
      >
        <div className="code-c-challenge-title__title">
          <HighlightedText keyword={keyword}>{challenge.title}</HighlightedText>
        </div>
        <div>
          <Icon
            type="file-text"
            className="code-c-challenge-title__open-button"
          />
        </div>
      </a>
    </div>
  );
};
