import * as classnames from "classnames";
import * as React from "react";

import { dayjs, isValidDate } from "@shared/services/date";

import { DatePicker, DatePickerType, FormGroup } from "..";

export default function DateRangePicker({
  name,
  startPickerName,
  endPickerName,
  startDateValue,
  endDateValue,
  minDate = "1900-01-01",
  maxDate = "2099-12-31",
  type = DatePickerType.Date,
  locale,
  disabled,
  error,
  className,
  onBlur,
  onStartDateChange,
  onEndDateChange,
}: {
  name?: string;
  startPickerName?: string;
  endPickerName?: string;
  startDateValue?: string;
  endDateValue?: string;
  minDate?: string;
  maxDate?: string;
  type?: DatePickerType;
  locale?: string;
  disabled?: boolean;
  error?: { [key: string]: string };
  className?: string;
  onStartDateChange?: (startDate: string) => void;
  onEndDateChange?: (endDate: string) => void;
  onBlur?: (e: React.FormEvent<HTMLInputElement>) => void;
}) {
  const rootStyles = classnames("code-c-date-range-picker", {
    [`${className}`]: Boolean(className),
  });
  const hasError = Boolean(name && error?.[name]);
  const inputClassName = classnames({ "is-danger": hasError });

  return (
    <FormGroup name={name} error={error} className={rootStyles}>
      <div>
        <DatePicker
          name={startPickerName}
          type={type}
          locale={locale}
          minDate={minDate}
          maxDate={
            !endDateValue || !isValidDate(endDateValue)
              ? maxDate
              : dayjs.max(dayjs(endDateValue), dayjs(maxDate))?.format()
          }
          showYearDropdown={true}
          value={startDateValue}
          onChange={onStartDateChange}
          onBlur={onBlur}
          error={error}
          inputClassName={inputClassName}
          selectsStart
          startDate={startDateValue}
          endDate={endDateValue}
          disabled={disabled}
        />
        <span> – </span>
        <DatePicker
          name={endPickerName}
          type={type}
          locale={locale}
          minDate={
            !startDateValue || !isValidDate(startDateValue)
              ? minDate
              : dayjs.max(dayjs(startDateValue), dayjs(minDate))?.format()
          }
          maxDate={maxDate}
          showYearDropdown={true}
          value={endDateValue}
          onChange={onEndDateChange}
          onBlur={onBlur}
          error={error}
          inputClassName={inputClassName}
          selectsEnd
          startDate={startDateValue}
          endDate={endDateValue}
          disabled={disabled}
        />
      </div>
    </FormGroup>
  );
}
