import { AxiosRequestConfig } from "axios";

import { ApiResponse, put } from "@api/httpClient";

import { ExamChallengeSetModel } from "@shared/models";
import { scrubExamChallengeUpdatePayload } from "@shared/services/exam";

interface ExamChallengeParam {
  examId: number;
  projectId: number;
  options: AxiosRequestConfig<{
    challengesSets: ReturnType<typeof scrubExamChallengeUpdatePayload>;
  }>;
}

export async function putExamChallenge({
  examId,
  projectId,
  options,
}: ExamChallengeParam) {
  const { data } = await put<ApiResponse<ExamChallengeSetModel[]>>(
    `/api/projects/${projectId}/exams/${examId}/sets`,
    options,
  );

  return data;
}
