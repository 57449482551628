import { AxiosRequestConfig } from "axios";

import { ApiResponse, put } from "@api/httpClient";

import { IdDeliveryConfigModel } from "@shared/models";

export type UpdateExamIdSharingPayload = Pick<
  IdDeliveryConfigModel,
  "startAt" | "endAt" | "dynamicDeadlineHours" | "passwordRequired"
>;

interface UpdateExamIdSharingParams {
  examId: number;
  projectId: number;
  options: AxiosRequestConfig<UpdateExamIdSharingPayload>;
}

export async function putExamIdSharing({
  examId,
  projectId,
  options,
}: UpdateExamIdSharingParams) {
  const { data } = await put<ApiResponse<IdDeliveryConfigModel>>(
    `/api/projects/${projectId}/exams/${examId}/iddelivery`,
    options,
  );

  return data;
}
