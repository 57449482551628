import {
  UserRole,
  ProjectRole,
  UserStatus,
  InvitationStatus,
} from "../services/enums";
import { UserRoleModel } from "./User.model";

class MemberListModel {
  public id: number;
  public userId: number;
  public name: string;
  public email: string;
  public roles: Array<UserRoleModel> = [];
  public status: UserStatus;
  public imageUrl: string;
  public createdAt: string;
  public lastLogin: string;
  public invitationStatus?: InvitationStatus;
  public displayStatus?: string;

  constructor(args?: {}) {
    Object.assign(this, args);

    if (args) {
      const { email, name } = args as MemberListModel;
      Object.assign(this, {
        ...(name === "_invitation_" ? { name: email } : {}),
      });
    }
  }

  public getRoles(projectId?: number): number[] {
    return this.roles
      .filter((role) => role.projectId === projectId)
      .map((role) => role.role);
  }

  public getRoleLabel(projectId?: number): Array<string> {
    const roles = this.getRoles(projectId);

    if (projectId) {
      return roles.map((role) => ProjectRole.toString(role));
    } else {
      return roles
        .filter(
          (role) => role === UserRole.OrgAdmin || role === UserRole.SystemAdmin,
        )
        .map((role) => UserRole.toString(role));
    }
  }
}

export default MemberListModel;
