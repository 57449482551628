import actionCreator from "../shared/services/actionCreator";

export const guestReportExamAnalysisAction = actionCreator(
  "guestReportExamAnalysisAction",
);
export const guestReportExamScoresAction = actionCreator(
  "guestReportExamScoresAction",
);
export const guestReportSubmissionAction = actionCreator(
  "guestReportSubmissionAction",
);
export const guestReportSubmissionAnalysisAction = actionCreator(
  "guestReportSubmissionAnalysisAction",
);
export const guestReportSubmissionCategoriesAction = actionCreator(
  "guestReportSubmissionCategoriesAction",
);
export const guestReportEvaluationPointAction = actionCreator(
  "guestReportEvaluationPointAction",
);

export const guestReportCategoryEnumAction = actionCreator(
  "guestReportCategoryEnumAction",
);

export const guestSubmissionGetAction = actionCreator(
  "guestSubmissionGetAction",
);

export const guestSubmissionResultListGetAction = actionCreator(
  "guestSubmissionResultListGetAction",
);

export const guestSubmissionResultDetailListSetAction = actionCreator(
  "guestSubmissionResultDetailListSetAction",
);

export const guestSubmissionReviewGetAction = actionCreator(
  "guestSubmissionReviewGetAction",
);
