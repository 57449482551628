import React from "react";

import {
  StackedBarChart,
  StackedBarChartItem,
  StackedBarChartProps,
} from "@shared/components";
import { useWindowSize } from "@shared/hooks";
import { breakpoints } from "@shared/hooks/useBreakpoints";
import { ApplicantReportModel, ReportExamScore } from "@shared/models";
import { ChartColor } from "@shared/services/enums";
import Message from "@shared/services/message";

export function ScoreChart({
  examScoreDistribution,
  applicantReportDetails,
  chartAvgScoreIndex,
  examScoreAverage,
}: {
  applicantReportDetails: ApplicantReportModel;
  examScoreDistribution: ReportExamScore[];
  chartAvgScoreIndex?: number;
  examScoreAverage?: number;
}) {
  const xLabel = `${Message.getMessageByKey("score")} (%)`;
  const markerLabel = examScoreAverage
    ? `${Message.getMessageByKey("report.average")}: ${examScoreAverage.toFixed(
        2,
      )}%`
    : "";

  const color = { [xLabel]: ChartColor.Primary200 };
  const { width: screenWidth = 0 } = useWindowSize();

  const applicantFinalScore = applicantReportDetails?.finalScore ?? NaN;

  const { items, xTicks, finalScoreTickPosition } = React.useMemo(() => {
    const formatScore = examScoreDistribution.reduce(
      (
        acc,
        { leftClosedBoundary, rightClosedBoundary, frequency },
        currentIndex,
      ) => {
        if (
          Math.floor(applicantFinalScore) >= leftClosedBoundary &&
          Math.floor(applicantFinalScore) <= rightClosedBoundary
        ) {
          acc.applicantFinalScoreTickPosition = currentIndex;
        }

        acc.data.push(frequency);
        acc.xTicks.push(
          `${leftClosedBoundary}${
            rightClosedBoundary !== 100 ? `-${rightClosedBoundary}` : ""
          }`,
        );

        return acc;
      },
      {
        data: [] as number[],
        xTicks: [] as string[],
        applicantFinalScoreTickPosition: -1 as number,
      },
    );

    return {
      xTicks: formatScore.xTicks,
      items: [
        { data: formatScore.data, label: xLabel },
      ] satisfies StackedBarChartItem[],
      finalScoreTickPosition: formatScore.applicantFinalScoreTickPosition,
    };
  }, [applicantFinalScore, examScoreDistribution, xLabel]);

  const options = React.useMemo<StackedBarChartProps["options"]>(() => {
    const isLargeScreen = screenWidth >= breakpoints.lg;
    const rotate = isLargeScreen ? 0 : 30;
    const paddingRight = isLargeScreen ? 0 : 15;

    return {
      maxYTicks: 5,
      xTick: { rotate, outer: false },
      xLabelHeight: isLargeScreen ? 50 : 60,
      hideLegend: true,
      data: {
        color: (color, d) => {
          if (
            typeof d === "object" &&
            "index" in d &&
            d?.index === finalScoreTickPosition
          ) {
            return ChartColor.Primary;
          }
          return color;
        },
      },
      parentWidthResizing: true,
      hideTooltipColorIcon: true,
      padding: {
        right: paddingRight,
        bottom: 0,
      },
    };
  }, [finalScoreTickPosition, screenWidth]);

  return (
    <StackedBarChart
      colors={color}
      items={items}
      xTicks={xTicks}
      xLabel={xLabel}
      yLabel={Message.getMessageByKey("question.submissionCount")}
      theme="submission-score-summary"
      options={options}
      className="code-c-submission-detail__score-chart"
      chartMarkerProps={
        typeof chartAvgScoreIndex !== "undefined"
          ? {
              label: markerLabel,
              tickPosition: chartAvgScoreIndex,
              color: ChartColor.Gray,
            }
          : undefined
      }
      chartBarLabelProps={
        finalScoreTickPosition !== -1
          ? {
              label: Message.getMessageByKey("common.applicant"),
              tickPosition: finalScoreTickPosition,
            }
          : undefined
      }
    />
  );
}
