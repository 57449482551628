import * as classnames from "classnames";
import * as React from "react";

import { Header, Menu, Breadcrumbs } from "@shared/components";
import { UserModel } from "@shared/models";
import history from "@shared/services/history";
import { isOrgsContainerReady } from "@shared/services/url";

export interface LayoutProps {
  children?: React.ReactNode;
  currentProjectId: number;
  currentUser: UserModel;
  orgLogo: string;
  orgName: string;
  options?: {
    hideSideMenu?: boolean;
    hideHeader?: boolean;
    hideBreadcrumbs?: boolean;
  };
  refreshUser: () => void;
}

export const Layout = ({
  children,
  currentProjectId,
  currentUser,
  orgLogo,
  orgName,
  options = {},
  refreshUser,
}: LayoutProps) => {
  const rootStyle = classnames("code-orgs-container", {
    [`no-sidemenu`]: options.hideSideMenu,
  });
  const currentProject = currentUser?.projects?.find(
    (project) => project.id === currentProjectId,
  );

  React.useEffect(() => {
    // HACK!! call User API to rebuild the cache on the API side
    // https://givery-technology.atlassian.net/browse/TRAC-2000
    window.addEventListener("focus", refreshUser);

    const unlisten = history.listen((location, action) => {
      if (location.pathname === "/" && action === "PUSH") {
        refreshUser();
      }
    });

    return () => {
      window.removeEventListener("focus", refreshUser);
      unlisten();
    };
  }, [refreshUser]);

  // HACK!!
  // To prevent the children from being rendered until the current project ID is updated when the browser's back button is pressed.
  const ready = isOrgsContainerReady(
    window.location.pathname,
    currentUser,
    currentProjectId,
  );

  return (
    <div className={rootStyle}>
      {!options.hideHeader && (
        <Header
          orgLogo={orgLogo}
          orgName={orgName}
          showSwitcher={!options.hideSideMenu}
          isSystemAdmin={
            currentUser && currentUser.isOnlySystemAdmin(currentProjectId)
          }
        />
      )}
      {!options.hideSideMenu && (
        <Menu
          projectId={currentProjectId}
          currentUser={currentUser}
          applicationType={currentProject && currentProject.applicationType}
        />
      )}
      <div className="code-orgs-container__body">
        {!options.hideBreadcrumbs && <Breadcrumbs />}
        {/* TODO: replace with <outlet> from router */}
        {ready && children}
      </div>
    </div>
  );
};
