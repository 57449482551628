import * as classnames from "classnames";
import * as React from "react";

import { Icon } from "../icon";

export type BannerProps = {
  type?:
    | "success"
    | "warning"
    | "warning-strong"
    | "warning-gray"
    | "error"
    | "info";
  className?: string;
  children?: React.ReactNode;
  transparent?: boolean;
};

const getIconType = (type: BannerProps["type"]) => {
  switch (type) {
    case "success":
      return "circle-check";
    case "warning":
      return "exclamation-circle";
    case "warning-strong":
      return "exclamation-triangle";
    case "warning-gray":
      return "exclamation-triangle";
    case "error":
      return "exclamation-circle";
    case "info":
    default:
      return "info-circle";
  }
};

export default function Banner({
  type = "info",
  className,
  children,
  transparent,
}: BannerProps) {
  const rootStyle = classnames("code-c-banner", {
    [`is-${type}`]: Boolean(type),
    [`is-transparent`]: Boolean(transparent),
    [`${className}`]: Boolean(className),
  });

  return (
    <div className={rootStyle}>
      <Icon type={getIconType(type)} className="code-c-icon__text-base" />
      {children}
    </div>
  );
}
