import * as classnames from "classnames";

import { Modal, Msg, Label, FormGroup } from "../../../../shared/components";
import { ReviewerUserModel } from "../../../../shared/models";
import Message from "../../../../shared/services/message";

/**
 * Interface
 */
export type ExternalProps = {
  isOpen: boolean;
  reviewer: ReviewerUserModel;
  onOK: () => void;
  onClose: () => void;
};

export type InjectedProps = {};

export type PingReviewerProps = InjectedProps & ExternalProps;

/**
 * Component
 * @param props PingReviewerProps
 */
export default function PingReviewer(props: PingReviewerProps) {
  const { isOpen, reviewer } = props;

  /**
   * Private Functions
   */
  const rootStyle = classnames("code-ping-reviewer");

  /**
   * Render
   */
  return (
    <Modal
      className={rootStyle}
      title={Message.getMessageByKey("submission.pingReviewer.title")}
      isOpen={isOpen}
      onClickOk={props.onOK}
      onClose={props.onClose}
      onClickCancel={props.onClose}
      cancelButtonLabel={Message.getMessageByKey("close")}
      cancelButtonAriaLabel="Close"
      okButtonLabel={Message.getMessageByKey("common.notify")}
      okButtonAriaLabel="Nortify"
      ariaLabel="Nortify Reviewer"
    >
      <div className="code-ping-reviewer__body">
        <Msg id="submission.pingReviewer.body" />
        <div>
          <FormGroup>
            <Label>
              <Msg id="common.name" />
            </Label>
            <p className="is-break-word">{reviewer.name}</p>
          </FormGroup>
          <FormGroup>
            <Label>
              <Msg id="common.email" />
            </Label>
            <p className="is-break-word">{reviewer.email}</p>
          </FormGroup>
        </div>
      </div>
    </Modal>
  );
}
