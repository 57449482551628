import { BreadcrumbsProvider } from "react-breadcrumbs-dynamic";
import { connect } from "react-redux";
import { Router } from "react-router-dom";
import { Action, Dispatch } from "redux";

import { RouterProvider, StoreProvider } from "@context";

import { MultiProvider, OrgBackgroundQueries } from "@shared/components";
import GlobalLoadingIndicator from "@shared/components/globalLoadingIndicator/GlobalLoadingIndicator";

import { currentProjectSetAction } from "../../actions";
import Error from "../../components/common/error/error/Error";
import NotFound from "../../components/common/error/notFound/NotFound";
import AppContainer from "../../containers/app/AppContainer";
import { RootState } from "../../reducers";
import { UserModel } from "../../shared/models";
import { isSupported } from "../../shared/services/browserSupport";
import { OrganizationAppType } from "../../shared/services/enums";
import history from "../../shared/services/history";
import InitialData from "../../shared/services/initialData";
import Message from "../../shared/services/message";

/**
 * Container and route setting
 */
import AppRoutes from "./AppRoutes";

interface AppProps {
  canRender: boolean;
  currentProjectId: number;
  setCurrentProjectId: (projectId?: number) => void;
  appType: OrganizationAppType;
  currentUser: UserModel;
  authenticated: boolean;
  hasTiers: boolean;
}

/**
 * Page component
 */
function App({
  appType,
  authenticated,
  canRender,
  currentProjectId,
  currentUser,
  hasTiers,
  setCurrentProjectId,
}: AppProps) {
  let children = null;
  if (!isSupported) {
    children = (
      <Error
        location={{
          state: {
            error: Message.getMessageByKey("message.unsupportedBrowser"),
            description: Message.getMessageByKey(
              "message.unsupportedBrowserDescription",
            ),
          },
        }}
        hasBackButton={false}
      />
    );
  } else if (!InitialData.orgName) {
    children = <NotFound hasBackButton={false} />;
  } else {
    children = (
      <AppRoutes
        currentProjectId={currentProjectId}
        appType={appType}
        currentUser={currentUser}
        authenticated={authenticated}
        hasTiers={hasTiers}
      />
    );
  }

  if (!canRender) {
    return null;
  }

  return (
    <Router history={history}>
      <OrgBackgroundQueries />
      <BreadcrumbsProvider>
        <div className="code-app">
          <MultiProvider
            providers={[
              <StoreProvider key="StoreContextProvider" />,
              <RouterProvider key="RouterContextProvider" />,
            ]}
          >
            <GlobalLoadingIndicator />
            <AppContainer
              currentProjectId={currentProjectId}
              setCurrentProjectId={setCurrentProjectId}
            >
              {children}
            </AppContainer>
          </MultiProvider>
        </div>
      </BreadcrumbsProvider>
    </Router>
  );
}

const mapStateToProps = (state: RootState) => ({
  canRender: !state.user.loading, // !!Object.keys(state.intl.messages).length,
  currentProjectId: state.project.currentProjectId,
  appType: state.global.type,
  currentUser: state.user.user,
  authenticated: state.auth.authenticated,
  hasTiers: !!state.orgs.tiers?.length,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  setCurrentProjectId: (projectId?: number) => {
    dispatch(currentProjectSetAction.request({ projectId }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
