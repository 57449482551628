import { Epic } from "redux-observable";
import { getType } from "typesafe-actions";

import {
  actions,
  TemporalActionTypesForEpic as ActionTypes,
  alertAction,
  ajaxAction,
} from "../actions";
import { RootState } from "../reducers";
import Message from "../shared/services/message";

export const externalSystemListGetEpic: Epic<ActionTypes, RootState> = (
  action$,
  state,
) =>
  action$
    .ofType(getType(actions.externalSystemListGetAction.request))
    .map(
      (
        action: ReturnType<typeof actions.externalSystemListGetAction.request>,
      ) =>
        ajaxAction.request({
          method: "get",
          url: `/api/admin/orgs/${action.payload.organizationName}/ats/settings`,
          success: actions.externalSystemListGetAction.success,
        }),
    );

export const externalSystemListResetEpic: Epic<ActionTypes, RootState> = (
  action$,
  state,
) =>
  action$
    .ofType(getType(actions.externalSystemListResetAction.request))
    .map(() => actions.externalSystemListResetAction.success({}));

export const externalSystemCreateEpic: Epic<ActionTypes, RootState> = (
  action$,
  state,
) =>
  action$
    .ofType(getType(actions.externalSystemCreateAction.request))
    .map(
      (action: ReturnType<typeof actions.externalSystemCreateAction.request>) =>
        ajaxAction.request({
          method: "post",
          url: `/api/admin/orgs/${action.payload.organizationName}/ats/settings`,
          body: action.payload.formValues,
          success: alertAction.success(
            Message.getMessageByKey("message.orgExternalSystemLink.created"),
            actions.externalSystemSetAction.request,
          ),
        }),
    );

export const externalSystemUpdateEpic: Epic<ActionTypes, RootState> = (
  action$,
  state,
) =>
  action$
    .ofType(getType(actions.externalSystemUpdateAction.request))
    .map(
      (action: ReturnType<typeof actions.externalSystemUpdateAction.request>) =>
        ajaxAction.request({
          method: "put",
          url: `/api/admin/orgs/${action.payload.organizationName}/ats/settings/${action.payload.atsRelationId}`,
          body: action.payload.formValues,
          success: alertAction.success(
            Message.getMessageByKey("message.orgExternalSystemLink.updated"),
            actions.externalSystemSetAction.request,
          ),
        }),
    );

export default [
  externalSystemCreateEpic,
  externalSystemListGetEpic,
  externalSystemListResetEpic,
  externalSystemUpdateEpic,
];
