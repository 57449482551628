import { connect } from "react-redux";
import { RouteProps } from "react-router-dom";

import { RootState } from "../../../reducers";
import { Role } from "../../services/enums";
import RestrictedRoute from "./RestrictedRoute";

interface OwnProps {
  roles: Role | Role[];
  children: React.ReactNode;
}

const mapStateToProps = (state: RootState, props: OwnProps & RouteProps) => ({
  ...props,
  allow: state.user.user?.hasRole(props.roles, state.project.currentProjectId),
});

export default connect(mapStateToProps, {})(RestrictedRoute);
