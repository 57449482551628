import { Epic } from "redux-observable";

import {
  Action,
  ajaxAction,
  addToPayload,
  readmeAction,
  APIResponseAction,
  guestSubmissionResultDetailListSetAction,
  guestReportExamAnalysisAction,
  guestReportExamScoresAction,
  guestReportSubmissionAction,
  guestReportSubmissionAnalysisAction,
  guestReportSubmissionCategoriesAction,
  guestReportEvaluationPointAction,
  guestReportCategoryEnumAction,
  guestSubmissionGetAction,
  guestSubmissionResultListGetAction,
  guestSubmissionReviewGetAction,
} from "../actions";
import { RootState } from "../reducers";
import {
  SubmissionResultListModel,
  SubmissionResultDetailModel,
} from "../shared/models";
import { ChallengeStyle } from "../shared/services/enums";

interface GetSubmissionDetailListResponseAction extends Action {
  payload: {
    result: Array<SubmissionResultListModel>;
    token: string;
  };
}

const guestReportExamAnalysisEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(guestReportExamAnalysisAction.types.request).map((action) =>
    ajaxAction.request({
      method: "get",
      url: `/api/reports/examinees/share/${action.payload}/submissions/challenges/analysis`,
      success: guestReportExamAnalysisAction.success,
      error: guestReportExamAnalysisAction.failure,
    }),
  );

const guestReportExamScoresEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(guestReportExamScoresAction.types.request).map((action) =>
    ajaxAction.request({
      method: "get",
      url: `/api/reports/examinees/share/${action.payload}/exams/scoresdistribution`,
      success: guestReportExamScoresAction.success,
      error: guestReportExamScoresAction.failure,
    }),
  );

const guestReportSubmissionEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(guestReportSubmissionAction.types.request).map((action) =>
    ajaxAction.request({
      method: "get",
      url: `/api/reports/examinees/share/${action.payload}/submissions`,
      success: guestReportSubmissionAction.success,
      error: guestReportSubmissionAction.failure,
    }),
  );

const guestReportSubmissionAnalysisEpic: Epic<Action, RootState> = (
  action$,
  state,
) =>
  action$
    .ofType(guestReportSubmissionAnalysisAction.types.request)
    .map((action) =>
      ajaxAction.request({
        method: "get",
        url: `/api/reports/examinees/share/${action.payload}/submissions/analysis`,
        success: guestReportSubmissionAnalysisAction.success,
        error: guestReportSubmissionAnalysisAction.failure,
      }),
    );

const guestReportSubmissionCategoriesEpic: Epic<Action, RootState> = (
  action$,
  state,
) =>
  action$
    .ofType(guestReportSubmissionCategoriesAction.types.request)
    .map((action) =>
      ajaxAction.request({
        method: "get",
        url: `/api/reports/examinees/share/${action.payload}/submissions/categories/scores`,
        success: guestReportSubmissionCategoriesAction.success,
        error: guestReportSubmissionCategoriesAction.failure,
      }),
    );

const guestReportEvaluationPointEpic: Epic<Action, RootState> = (
  action$,
  state,
) =>
  action$.ofType(guestReportEvaluationPointAction.types.request).map((action) =>
    ajaxAction.request({
      method: "get",
      url: `/api/reports/examinees/share/${action.payload}/submissions/evaluationpoints/scores`,
      success: guestReportEvaluationPointAction.success,
      error: guestReportEvaluationPointAction.failure,
    }),
  );

const guestReportCategoryEnumEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(guestReportCategoryEnumAction.types.request).map((action) =>
    ajaxAction.request({
      method: "get",
      url: `/api/enum/questions/tags`,
      success: guestReportCategoryEnumAction.success,
      error: guestReportCategoryEnumAction.failure,
    }),
  );

const guestSubmissionGetEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(guestSubmissionGetAction.types.request).map((action) =>
    ajaxAction.request({
      method: "get",
      url: `/api/sharing/${action.payload}/detail`,
      success: guestSubmissionGetAction.success,
      error: guestSubmissionGetAction.failure,
    }),
  );

const guestSubmissionResultListGetEpic: Epic<Action, RootState> = (
  action$,
  state,
) =>
  action$
    .ofType(guestSubmissionResultListGetAction.types.request)
    .map((action) =>
      ajaxAction.request({
        method: "get",
        url: `/api/sharing/${action.payload}/results`,
        success: addToPayload(
          { token: action.payload },
          guestSubmissionResultListGetAction.success,
        ),
        error: guestSubmissionResultListGetAction.failure,
      }),
    );

const guestSubmissionDetailListGetEpic: Epic<Action, RootState> = (
  action$,
  state,
) =>
  action$
    .ofType(guestSubmissionResultListGetAction.types.success)
    .flatMap((action) => {
      const payload = (action as GetSubmissionDetailListResponseAction).payload;
      return (payload.result || [])
        .filter((item) => item.challengeResultStatus !== undefined)
        .map((item) =>
          ajaxAction.request({
            method: "get",
            url: `/api/sharing/${payload.token}/results/${item.challengeId}`,
            success: guestSubmissionResultDetailListSetAction.request,
            error: guestSubmissionResultDetailListSetAction.failure,
          }),
        );
    });

const guestSubmissionReviewGetEpic: Epic<Action, RootState> = (
  action$,
  state,
) =>
  action$.ofType(guestSubmissionReviewGetAction.types.request).map((action) =>
    ajaxAction.request({
      method: "get",
      url: `/api/reports/examinees/share/${action.payload}/submissions/reviews`,
      success: guestSubmissionReviewGetAction.success,
      error: guestSubmissionReviewGetAction.failure,
    }),
  );

const guestSubmissionReadmeGetEpic: Epic<Action, RootState> = (
  action$,
  state,
) =>
  action$
    .ofType(guestSubmissionResultDetailListSetAction.types.request)
    .map((action) => {
      const detail = new SubmissionResultDetailModel(
        (action as APIResponseAction).payload.result,
      );
      if (ChallengeStyle.hasReadme(detail.style)) {
        const readmeLink =
          (detail.codingResult && detail.codingResult.readmeLink) ||
          (detail.aiResult && detail.aiResult.readmeLink) ||
          "";
        return readmeAction.request(detail.challengeId, readmeLink);
      } else {
        return { type: "@null" };
      }
    });

export default [
  guestSubmissionGetEpic,
  guestSubmissionReviewGetEpic,
  guestSubmissionResultListGetEpic,
  guestSubmissionDetailListGetEpic,
  guestSubmissionReadmeGetEpic,
  guestReportExamAnalysisEpic,
  guestReportExamScoresEpic,
  guestReportSubmissionEpic,
  guestReportSubmissionAnalysisEpic,
  guestReportSubmissionCategoriesEpic,
  guestReportEvaluationPointEpic,
  guestReportCategoryEnumEpic,
];
