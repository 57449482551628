import * as React from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import { hybrid } from "react-syntax-highlighter/dist/cjs/styles/hljs";

import { MoreLink } from "@shared/components";
import { ProgrammingLanguage } from "@shared/services/enums";

import SubmissionResultActionLogCard from "../submissionResultActionLogCard/SubmissionResultActionLogCard";
import SubmissionResultActionLogCardField from "../submissionResultActionLogCardField/SubmissionResultActionLogCardField";

interface Props {
  pastedText?: string;
  pastedTextFilename?: string;
  pastedTextQuestionTitle?: string;
}

const MAX_DEFAULT_PASTED_TEXT_LENGTH = 10000;

const SubmissionResultActionLogPastedCard = React.memo(
  function SubmissionResultActionLogPastedCard({
    pastedText,
    pastedTextFilename,
    pastedTextQuestionTitle,
  }: Props) {
    const [isShowMore, setIsShowMore] = React.useState(false);

    if (!pastedText) {
      return null;
    }

    const isQuiz = Boolean(pastedTextQuestionTitle);

    return (
      <SubmissionResultActionLogCard
        titleKey={
          isQuiz
            ? "submission.actionLog.pastedCard.quizTitle"
            : "submission.actionLog.pastedCard.title"
        }
        className="code-c-submission-result-action-log__pasted-card"
      >
        {(pastedTextFilename || pastedTextQuestionTitle) && (
          <SubmissionResultActionLogCardField
            className={
              isQuiz
                ? "code-c-submission-result-action-log__card-field__question-title"
                : undefined
            }
            labelKey={
              isQuiz
                ? "submission.actionLog.actionDetail.question"
                : "submission.actionLog.actionDetail.file"
            }
          >
            {pastedTextFilename || pastedTextQuestionTitle}
          </SubmissionResultActionLogCardField>
        )}
        <article className="code-c-submission-result-action-log__card-field">
          {isQuiz && (
            <div className="code-c-submission-result-action-log__card-field__pasted-text">
              {pastedText}
            </div>
          )}
          {!isQuiz && (
            <SyntaxHighlighter
              className="code-editor-appeal-comment__codeblock-code"
              style={hybrid}
              language={
                pastedText.length > MAX_DEFAULT_PASTED_TEXT_LENGTH && isShowMore
                  ? "plaintext"
                  : ProgrammingLanguage.getLanguage(pastedTextFilename || "")
              }
            >
              {isShowMore
                ? pastedText
                : pastedText.slice(0, MAX_DEFAULT_PASTED_TEXT_LENGTH)}
            </SyntaxHighlighter>
          )}
        </article>
        {!isShowMore && (
          <MoreLink
            totalCount={pastedText.length}
            displayCount={MAX_DEFAULT_PASTED_TEXT_LENGTH}
            onClick={() => setIsShowMore(!isShowMore)}
          />
        )}
      </SubmissionResultActionLogCard>
    );
  },
);

export default SubmissionResultActionLogPastedCard;
