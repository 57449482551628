import { ApplicantExamModel, UserModel } from "../models";
import { ua, os } from "./browserSupport";
import {
  Difficulty,
  ProgrammingLanguage,
  ChallengeStyle,
  ChallengeTakenBy,
  ChallengeResultStatus,
} from "./enums";
import Message from "./message";
import { escapeSpecialChars } from "./string";

/**
 * collect applicant data to send the survey
 * @param data
 */
export function buildApplicantSurveyData(data: ApplicantExamModel) {
  const { name: examTitle } = data.exam;
  const { name: browserName, version: browserVersion } = ua;
  const { name: osName, version: osVersion } = os;
  const challenges = new Set();
  const statuses = new Set();
  const styles = new Set();
  const difficulties = new Set();
  const programingLanguages = new Set();
  const solvingMethod = new Set();
  data.challengesSets.forEach((challengeSet) => {
    challengeSet.challenges.forEach((challenge) => {
      const { id, title, style, difficulty } = challenge;
      const result = data.getResultByChallengeId(id);
      const { status, programmingLanguage, takenBy } = result;
      challenges.add(title);
      statuses.add(ChallengeResultStatus[status] || "NotStarted");
      styles.add(ChallengeStyle[style]);
      difficulties.add(Difficulty[difficulty]);
      programingLanguages.add(
        ProgrammingLanguage.toString(programmingLanguage),
      );
      solvingMethod.add(ChallengeTakenBy[takenBy]);
    });
  });
  return {
    examTitle,
    challenges: Array.from(challenges.values()).join(","),
    statuses: Array.from(statuses.values()).join(","),
    styles: Array.from(styles.values()).join(","),
    difficulties: Array.from(difficulties.values()).join(","),
    programingLanguages: Array.from(programingLanguages.values()).join(","),
    solvingMethod: Array.from(solvingMethod.values()).join(","),
    language: Message.locale,
    env: process.env.REACT_APP_ENV,
    osName,
    osVersion,
    browserName,
    browserVersion,
  };
}

export function buildReviewerSurveyData(user: UserModel) {
  return {
    name: user.name,
    email: user.email,
    createdAt: user.createdAt,
  };
}

type ShowSurveyProps =
  | {
      uniqueId: string;
      data: ApplicantExamModel;
      surveyType: "applicant";
    }
  | {
      uniqueId: string;
      data: UserModel;
      surveyType: "reviewer";
    };
/**
 * Show survey widget
 * @param uniqueId
 * @param data
 */
export function showSurvey({ uniqueId, data, surveyType }: ShowSurveyProps) {
  if (
    (surveyType === "reviewer" && !process.env.REACT_APP_NPS_APP_ID) ||
    (surveyType === "applicant" && !process.env.REACT_APP_NPS_APP_ID_REVIEWER)
  ) {
    // Skip activation
    return;
  }

  const satismeterId = "givery_satismeter";

  const giverySatismeter = document.getElementById(satismeterId);

  // Skip activation if element already exists
  if (giverySatismeter !== null) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("id", satismeterId);

  script.type = "text/javascript";
  script.textContent = `
  (function() { window.satismeter = window.satismeter || function() {(window.satismeter.q = window.satismeter.q || []).push(arguments);};window.satismeter.l = 1 * new Date();var script = document.createElement("script");var parent = document.getElementsByTagName("script")[0].parentNode;script.async = 1;script.src = "https://app.satismeter.com/satismeter.js";parent.appendChild(script);})();
  satismeter({
    writeKey: "${escapeSpecialChars(
      surveyType === "applicant"
        ? process.env.REACT_APP_NPS_APP_ID ?? ""
        : process.env.REACT_APP_NPS_APP_ID_REVIEWER ?? "",
    )}",
    userId: "${uniqueId}",
    language: "${Message.locale}",
    traits: ${JSON.stringify(
      surveyType === "applicant"
        ? buildApplicantSurveyData(data as ApplicantExamModel)
        : buildReviewerSurveyData(data as UserModel),
    )},
  });`;
  script.async = true;
  document.body.appendChild(script);
}
