import EnumModel from "./Enum.model";

class CsvTypesModel extends EnumModel {
  public icon: string;
  public label: string;
  public description: string;

  constructor(args: {}) {
    super(args);
  }
}

export default CsvTypesModel;
