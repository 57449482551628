import { connect } from "react-redux";

import { RootState } from "@reducers";

import { ExamDeliveryList, ExternalProps } from "./ExamDeliveryList";

/**
 * States
 */
const mapStateToProps = (state: RootState, props: ExternalProps) => ({
  currentUser: state.user.user,
  currentProjectId: state.project.currentProjectId,
  ...props,
});

/**
 * Props
 */
export default connect(mapStateToProps, {})(ExamDeliveryList);
