export class SubmissionReportChallengeCategoryModel {
  public title: string;
  public description: string;
  public maximumScore: number;
  public applicantScore: number;

  public examAverageScore: number;
  public systemAverageScore: number;

  constructor(args?: {}) {
    Object.assign(this, args);
  }
}

export class SubmissionReportChallengeModel {
  public examId: number;
  public challengeId: number;
  public title: string;

  // Challenge Info
  public score = 0;
  public timeSpentSeconds = 0;
  public style: number;
  public difficulty: number;
  public examAverageScore = 0;
  public examMaxScore = 0;
  public examStandardDeviation = 0;
  public examScoreDevValue = 0;
  public systemAverageScore = 0;
  public systemMaxScore = 0;
  public systemStandardDeviation = 0;
  public systemScoreDevValue = 0;
  public notModified: boolean;

  // Category Info
  public categories: Array<SubmissionReportChallengeCategoryModel> = [];

  constructor(args?: {}) {
    Object.assign(this, args);
  }
}
