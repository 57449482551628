import { useId } from "@floating-ui/react";

import Checkbox from "@shared/components/checkbox/Checkbox";

import { DefaultOptionProps } from "../DefaultOption";

interface FloatingUiProps {
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
  onFocus: (e: React.FocusEvent<HTMLDivElement>) => void;
  onMouseMove: (e: React.MouseEvent<HTMLDivElement>) => void;
  onPointerLeave: (e: React.PointerEvent<HTMLDivElement>) => void;
}

export interface CheckboxMultiOption
  extends DefaultOptionProps,
    FloatingUiProps {}

export const CheckboxMultiOption = ({
  label,
  isSelected,
  ...rest
}: CheckboxMultiOption) => {
  const id = useId();
  return (
    <div
      className="code-c-autocomplete__checkbox-option"
      id={id}
      aria-selected={isSelected}
      {...rest}
    >
      <Checkbox
        value={isSelected}
        className="code-c-autocomplete__checkbox-option__checkbox-container"
        stopPropagation={true}
      />
      <span>{label}</span>
    </div>
  );
};
