import { useFormContext } from "react-hook-form";

import { useStoreContext } from "@context";

import {
  Label,
  Msg,
  Checkbox,
  FormGroup,
  Select,
  SelectItem,
} from "@shared/components";
import { useExam } from "@shared/hooks/query";
import { TierAction, ExamType } from "@shared/services/enums";
import Message from "@shared/services/message";

import { ExamSettingsFormValidation } from "../utils";
import { AtsIntegrationCheckbox } from "./AtsIntegrationCheckbox";
import { SelfDeliveryCheckbox } from "./SelfDeliveryCheckbox";

interface EmailDeliverySectionProps {
  readOnly?: boolean;
  urlToken?: string;
}

export const EmailDeliverySection = ({
  readOnly,
  urlToken,
}: EmailDeliverySectionProps) => {
  const {
    data: { examDetail },
  } = useExam();

  const { isTierActionAllowed, user } = useStoreContext();
  const isDeliverByUrlAllowed = isTierActionAllowed(TierAction.UrlDelivering);
  const isIntegrateATSAllowed = isTierActionAllowed(
    TierAction.ExternalATSUsage,
  );
  const multiLanguage = examDetail?.examType === ExamType.EnglishJapanese;

  const { register } = useFormContext<ExamSettingsFormValidation>();

  return (
    <div className="code-exam-create-new__settings__email-delivery">
      {!multiLanguage && (
        <FormGroup>
          <Label>
            <Msg id="exam.emailLanguage" />
          </Label>
          <Select
            dataTestautomationid="emailLanguage"
            disabled={readOnly}
            {...register("language")}
            options={
              [
                { label: Message.getMessageByKey("ja"), value: "ja" },
                { label: Message.getMessageByKey("en"), value: "en" },
              ] as SelectItem[]
            }
          />
        </FormGroup>
      )}
      <Checkbox
        readOnly={readOnly}
        description={<Msg id="exam.notification.deadline.subText" />}
        dataTestautomationid="deadlineNotificationEnabled"
        {...register("deadlineNotificationEnabled")}
      >
        <Msg id="exam.notification.deadline" />
      </Checkbox>
      <SelfDeliveryCheckbox
        readOnly={readOnly}
        urlToken={urlToken}
        orgname={user?.organization.name ?? ""}
        isDeliverByUrlAllowed={isDeliverByUrlAllowed}
      />
      <AtsIntegrationCheckbox
        readOnly={readOnly}
        isIntegrateATSAllowed={isIntegrateATSAllowed}
        isMultiLanguage={multiLanguage}
      />
    </div>
  );
};
