import { Epic } from "redux-observable";

import {
  Action,
  alertAction,
  ajaxAction,
  authSetAction,
  userGetAction,
  userRefreshAction,
  userUpdateAction,
  userAuthedAction,
  currentProjectSetAction,
  projectSetSwitcherAction,
  i18nGetAction,
  globalTypeSetAction,
  intercomSetAction,
  questionFiltersAction,
  challengeFiltersGetAction,
  examOptionsGetAction,
  authSignOutAction,
  allTiersGetAction,
  allActionsGetAction,
  userLocalUpdateAction,
  reloadAction,
  allCsvTypesGetAction,
} from "@actions";

import { RootState } from "@reducers";

import {
  OrganizationModel,
  UserRoleModel,
  ProjectSwitchItemModel,
} from "@shared/models";
import { pushVariables } from "@shared/services/analytics";
import {
  OrganizationAppType,
  UserRole,
  ProjectRole as ProjectRoleEnum,
} from "@shared/services/enums";
import History from "@shared/services/history";
import {
  getCurrentProjectIdByUser,
  setCurrentProjectIdByUser,
} from "@shared/services/localStorage";
import Message from "@shared/services/message";
import {
  getProjectIdFromURL,
  extractSourceURL,
} from "@shared/services/urlParser";

interface UserSetAction extends Action {
  payload: {
    result: {
      email: string;
      language: string;
      projects: ProjectSwitchItemModel[];
      roles: Array<UserRoleModel>;
      organization: OrganizationModel;
    };
  };
}

export function getCurrentProjectId(
  pathname: string,
  projects?: ProjectSwitchItemModel[],
  userId?: number,
): number {
  const DEFAULT_PROJECT_ID = 0;

  if (!projects || !Array.isArray(projects)) {
    return DEFAULT_PROJECT_ID;
  }
  const matches = getProjectIdFromURL(pathname);
  const [, projectId] = matches || [];
  const project = projects.find(
    (project) => project.id === parseInt(projectId, 10),
  );
  const currentProjectId =
    project?.id ||
    getCurrentProjectIdByUser(userId?.toString() ?? "") ||
    projects[0]?.id ||
    DEFAULT_PROJECT_ID;
  // Save current project id for next time
  if (userId) {
    setCurrentProjectIdByUser(currentProjectId, userId.toString());
  }

  return currentProjectId;
}

const userGetEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(userGetAction.types.request).map((action) =>
    ajaxAction.request({
      method: "get",
      url: "/api/user",
      success: userGetAction.success,
      error: userGetAction.failure,
      options: {
        disableDefaultError: true,
      },
    }),
  );

/**
 * Refresh User Cache of API Side
 * @param action$
 * @param state
 */
const userRefreshEpic: Epic<Action, RootState> = (action$, state) =>
  action$
    .ofType(userRefreshAction.types.request)
    .debounceTime(275)
    .map((action) =>
      ajaxAction.request({
        method: "get",
        url: "/api/user",
        success: userRefreshAction.success,
        error: (error: string) => {
          if (error.startsWith("404")) {
            return authSignOutAction.request();
          }
          return userRefreshAction.failure();
        },
        options: {
          disableDefaultError: true,
          background: true,
        },
      }),
    );

const userSetLanguageEpic: Epic<Action, RootState> = (action$, state) =>
  action$
    .ofType(userGetAction.types.success, userRefreshAction.types.success)
    .filter(
      (action: UserSetAction) =>
        action.payload.result.language !== state.getState().intl.locale,
    )
    .flatMap((action: UserSetAction) => [
      i18nGetAction(action.payload.result.language),
      questionFiltersAction.success({ extra: "quizCategories", payload: [] }),
      challengeFiltersGetAction.success({
        extra: "programmingCategories",
        payload: [],
      }),
      examOptionsGetAction.request({ force: true }),
    ]);

const userSetAuthEpic: Epic<Action, RootState> = (action$, state) => {
  return action$
    .ofType(userGetAction.types.success)
    .flatMap((action: Action) => [
      authSetAction.request(true),
      userAuthedAction.request(),
      /**
       * put extra actions to fetch master data below
       */
      challengeFiltersGetAction.request(),
      examOptionsGetAction.request(),
      allTiersGetAction.request(),
      allActionsGetAction.request(),
      allCsvTypesGetAction.request(),
    ]);
};

const userUpdateSuccessEpic: Epic<Action, RootState> = (action$, state) =>
  action$
    .ofType(userGetAction.types.success)
    .filter((_) => state.getState().user.updatingUser)
    .map((_) =>
      alertAction.success(
        Message.getMessageByKey("message.user.updated"),
        userUpdateAction.success,
      )({}),
    );

const userSetProjectEpic: Epic<Action, RootState> = (action$, state) =>
  action$
    .ofType(userGetAction.types.success)
    // TODO need to tweak after index.html spec gets fixed
    .filter((action) => !state.getState().project.currentProjectId)
    .flatMap((action: UserSetAction) => {
      // Note: Experimental!! send some data to GTM
      const { email, roles = [] } = action.payload.result;
      pushVariables({
        email,
        roles: Array.from(
          new Set(
            roles.map(
              (role) => ({ ...UserRole, ...ProjectRoleEnum })[role.role],
            ),
          ),
        ).join(","),
      });

      // Note: use the source URL if the user passed through sign in
      const redirectFrom =
        History.location && History.location.state
          ? extractSourceURL(
              History.location.state as { from: { pathname: string } },
            )
          : "";

      return [
        currentProjectSetAction.request({
          projectId: getCurrentProjectId(
            redirectFrom || window.location.pathname,
            action.payload.result.projects,
            state.getState().user.user?.id,
          ),
        }),
      ];
    });

const userSetProjectSwitcherEpic: Epic<Action, RootState> = (action$, state) =>
  action$
    .ofType(userGetAction.types.success)
    .filter((_) => state.getState().user.user !== undefined)
    .map((action: UserSetAction) => {
      const projects = (state.getState().user.user || { projects: [] })
        .projects;
      return projectSetSwitcherAction.request(projects || []);
    });

const userSetGlobalApplicationTypeEpic: Epic<Action, RootState> = (
  action$,
  state,
) =>
  action$
    .ofType(userGetAction.types.success)
    .filter((_) => state.getState().user.user !== undefined)
    .map((action: UserSetAction) => {
      const roles = (state.getState().user.user || { roles: [] }).roles;
      const appType =
        Array.isArray(roles) && Boolean(roles.length)
          ? OrganizationAppType.Admin
          : OrganizationAppType.Individual;
      return globalTypeSetAction.request({ appType, showMessage: false });
    });

const userSetIntercomInfoEpic: Epic<Action, RootState> = (action$, state) =>
  action$
    .ofType(userGetAction.types.success)
    .map((action: UserSetAction) =>
      intercomSetAction({ type: "organization", data: action.payload.result }),
    );

export const userUpdateEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(userUpdateAction.types.request).map((action) =>
    ajaxAction.request({
      method: "put",
      url: "/api/user",
      body: action.payload,
      success: userGetAction.success,
      error: userGetAction.failure,
    }),
  );

export const userLocalUpdateEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(userLocalUpdateAction.types.request).map((action) =>
    ajaxAction.request({
      method: "put",
      url: "/api/user/local",
      body: action.payload,
      success: reloadAction,
      error: userLocalUpdateAction.failure,
    }),
  );

export default [
  userSetProjectEpic,
  userSetLanguageEpic,
  userGetEpic,
  userSetAuthEpic,
  userUpdateEpic,
  userSetProjectSwitcherEpic,
  userUpdateSuccessEpic,
  userSetGlobalApplicationTypeEpic,
  userSetIntercomInfoEpic,
  userRefreshEpic,
  userLocalUpdateEpic,
];
