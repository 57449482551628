import { AxiosRequestConfig } from "axios";

import { ApiResponse, put } from "@api/httpClient";

import { ExamModel } from "@shared/models";

interface ExamArchiveParams {
  examId: number;
  projectId: number;
  options?: AxiosRequestConfig;
}

export async function putExamArchive({
  examId,
  projectId,
  options,
}: ExamArchiveParams) {
  const { data } = await put<ApiResponse<ExamModel>>(
    `/api/projects/${projectId}/exams/${examId}/archive`,
    options,
  );

  return data;
}

export async function putExamUnarchive({
  examId,
  projectId,
  options,
}: ExamArchiveParams) {
  const { data } = await put<ApiResponse<ExamModel>>(
    `/api/projects/${projectId}/exams/${examId}/unarchive`,
    options,
  );

  return data;
}
