import { useEffect, useRef } from "react";

export const useOnClickOutside = <T extends Element>(
  callback: (event: MouseEvent) => void,
) => {
  const ref = useRef<T | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback(event);
      }
    };
    document.addEventListener("click", handleClickOutside, true);

    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [callback]);

  return ref;
};
