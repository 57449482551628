import * as classnames from "classnames";
import { isEqual } from "lodash";
import * as React from "react";

import {
  Modal,
  Form,
  FormGroup,
  Label,
  Input,
  Textarea,
  Radio,
  Checkbox,
  Table,
  TableHead,
  TableBody,
  HeaderColumn,
  Row,
  Column,
  Button,
  Icon,
  Select,
  SelectItem,
  SortableItemList,
  Loading,
  TagPicker,
  Msg,
  Tag,
  Tooltip,
} from "@shared/components";
import {
  QuestionModel,
  ChallengeModel,
  ChallengeFilterModel,
  QuestionFilterModel,
} from "@shared/models";
import {
  ChallengeStyle,
  ChallengeStatus,
  QuestionType,
  TierAction,
} from "@shared/services/enums";
import Message from "@shared/services/message";

import QuestionNew from "../../questions/questionNew/QuestionNew";
import QuestionSelect from "../../questions/questionSelect/QuestionSelect.connect";

export enum ChallengeType {
  New,
  Edit,
}

/**
 * Prop interface
 */

export interface InjectedProps {
  challenge: ChallengeModel;
  challengeFilters: ChallengeFilterModel;
  challengeType: ChallengeType;
  className?: string;
  isOpen?: boolean;
  loadingCreate?: boolean;
  loadingDetail?: boolean;

  onCancel?: () => void;
  onSave?: (settingFormValues?: {}, questions?: {}) => void;
  isTierActionAllowed: (tierAction: TierAction) => boolean;
}

export interface ExternalProps {
  importLoading: boolean;
  importedQuestions: Array<QuestionModel>;
  loadingQuestion: boolean;
  questionDetailList: Array<QuestionModel>;
  questionFilters: QuestionFilterModel;
  updateQuestionError: boolean;
  updateQuestionLoading: boolean;
  getQuestion: (questionId: number) => void;
  importQuestions: (formData: FormData) => void;
  updateQuestion: (questionId: number, payload: {}) => void;
}

type ChallengeNewProps = ExternalProps & InjectedProps;

export interface ChallengeNewState {
  isSettingsFormValid: boolean;
  settingFormValues: {};
  challengeSearchFormValue: string;
  settingFormErrors: boolean;
  isQuestionsOpen: boolean;
  isSelectionOpen: boolean;
  disableOk: boolean;
  questions: Array<QuestionModel>;
  programmingCategories: Array<number>;
  isUpdateQuestionOpen?: boolean;
  selectedQuestion?: QuestionModel;
}

/**
 * React Component
 */
class ChallengeNew extends React.Component<
  ChallengeNewProps,
  ChallengeNewState
> {
  private selectFileInput: HTMLInputElement;

  constructor(props: ChallengeNewProps) {
    super(props);

    this.state = {
      isSettingsFormValid: false,
      disableOk: true,
      settingFormValues: {},
      challengeSearchFormValue: "",
      isQuestionsOpen: false,
      isSelectionOpen: false,
      questions: props.challenge.questions,
      programmingCategories: props.challenge.programmingCategories,
      settingFormErrors: false,
    };
  }

  public UNSAFE_componentWillReceiveProps(newProps: ChallengeNewProps) {
    if (!isEqual(this.props.importedQuestions, newProps.importedQuestions)) {
      this.setState({
        questions: this.state.questions.concat(
          newProps.importedQuestions || [],
        ),
      });
    }

    if (
      !isEqual(this.props.isOpen, newProps.isOpen) ||
      !isEqual(this.props.challenge, newProps.challenge)
    ) {
      this.setState({
        questions: newProps.challenge.questions,
        programmingCategories: newProps.challenge.programmingCategories,
        isSettingsFormValid: false,
        settingFormErrors: false,
        challengeSearchFormValue: "",
        disableOk: true,
        settingFormValues: {
          basicTimeMinutes: newProps.challenge.currentVersion.basicTimeMinutes,
          description: newProps.challenge.description,
          difficulty: newProps.challenge.currentVersion.difficulty.toString(),
          language: newProps.challenge.language || "ja",
          status: newProps.challenge.status === ChallengeStatus.Draft,
          title: newProps.challenge.title,
        },
      });
    }

    if (!newProps.loadingDetail && this.props.loadingDetail) {
      this.disableOk();
    }

    if (
      !newProps.updateQuestionError &&
      this.props.updateQuestionLoading &&
      !newProps.updateQuestionLoading &&
      !newProps.updateQuestionLoading
    ) {
      this.onCloseUpdateQuestion();
    }
  }

  public render() {
    const {
      className,
      isOpen,
      challenge,
      loadingDetail,
      questionDetailList,
      onCancel,
      isTierActionAllowed,
    } = this.props;

    const editing = this.props.challengeType === ChallengeType.Edit;
    const isChallengeDifficultyAllowed = isTierActionAllowed(
      TierAction.ChallengeDifficultySetting,
    );
    const rootStyle = classnames("code-challenge-new", {
      [`${className}`]: Boolean(className),
    });

    const selectedQuestion =
      questionDetailList &&
      questionDetailList.find(
        (question) =>
          question.id ===
          (this.state.selectedQuestion ? this.state.selectedQuestion.id : -1),
      );

    const quizSelectTable = () => {
      const questionsList = this.state.questions.map((question, index) => {
        const updatedQuestion =
          (questionDetailList &&
            questionDetailList.find((q) => q.id === question.id)) ||
          undefined;

        return {
          item: (
            <div
              key={question.id}
              className="code-challenge-new__right-table-body"
            >
              <div className="table-icon">
                <Icon
                  className="challenge-questions__action"
                  type="bars"
                  size="medium"
                />
              </div>
              <div className="table-id">{question.id}</div>
              <div className="table-title">
                {updatedQuestion ? updatedQuestion.title : question.title}
                {question.status === ChallengeStatus.Draft && (
                  <Tag className="code-challenge-new__right-table-body__status_tag">
                    {Message.getMessageByKey("challenge.drafted")}
                  </Tag>
                )}
                {question.status === ChallengeStatus.Removed && (
                  <Tag className="code-challenge-new__right-table-body__status_tag">
                    {Message.getMessageByKey("challenge.removed")}
                  </Tag>
                )}
              </div>
              <div className="table-buttons">
                {challenge.organizationId === question.organizationId && (
                  <Button
                    shrink={true}
                    size={"small"}
                    onClick={() => this.onOpenUpdateQuestion(question)}
                  >
                    <Icon type={"pencil"} />
                  </Button>
                )}
                <span style={{ display: "inline-block", width: "0.5rem" }} />
                <Button
                  shrink={true}
                  size={"small"}
                  onClick={() => this.deleteQuestion(question.id)}
                >
                  <Icon type={"trash"} />
                </Button>
              </div>
            </div>
          ),
          id: index,
        };
      });

      return (
        <Column>
          <Label className="code-challenge-new__right-label">
            <Msg id={"challenge.chooseFromQuestions"} />
            <Button
              size="small"
              shrink={true}
              onClick={this.onOpenQuestionSelect}
            >
              <Icon type="plus-circle" />
              <Msg id="action.add" />
            </Button>
          </Label>
          <Label className="code-challenge-new__right-label">
            <Msg id={"importFromZip"} />
            <Button
              size="small"
              shrink={true}
              onClick={() =>
                this.selectFileInput && this.selectFileInput.click()
              }
            >
              <Msg id={"button.chooseFile"} />
            </Button>
          </Label>
          <Input
            style={{ display: "none" }}
            reference={(r: HTMLInputElement) => (this.selectFileInput = r)}
            type="file"
            accept=".zip"
            onChange={this.onImportQuestions}
            value=""
          />
          <div className="code-challenge-new__right-table">
            <div className="code-challenge-new__right-table-head">
              <div className="table-icon" />
              <div className="table-id">
                <Msg id="id" />
              </div>
              <div className="table-title">
                <Msg id="common.title" />
              </div>
              <div className="table-buttons" />
            </div>
            <div className="challenge-questions">
              <SortableItemList
                items={questionsList}
                onReorderItem={this.onReorderItem}
              />
            </div>
          </div>
        </Column>
      );
    };

    const modalContents = loadingDetail ? (
      <div
        style={{
          position: "relative",
          minHeight: "300px",
          pointerEvents: "none",
        }}
      >
        <Loading isOpen={true} fullScreen={false} overlay={false} />
      </div>
    ) : (
      <Table hoverable={false}>
        <TableHead>
          <HeaderColumn>
            <Msg id={"challenge.basicSettings"} />
          </HeaderColumn>
          <HeaderColumn>
            <Msg id={"challenge.typeSpecificSettings"} />
          </HeaderColumn>
        </TableHead>
        <TableBody>
          <Row>
            <Column>
              <Form
                validation={{
                  title: ["string", "required", ["max", 255], "notEmpty"],
                  description: ["string", "notEmpty"],
                  basicTimeMinutes: [
                    "number",
                    "required",
                    "integer",
                    ["min", 1],
                    ["max", 999999],
                  ],
                  programmingCategories: ["array"],
                  language: ["string"],
                  status: ["boolean"],
                }}
                initialValues={{
                  title: challenge.title,
                  description: challenge.description,
                  basicTimeMinutes:
                    challenge.currentVersion &&
                    challenge.currentVersion.basicTimeMinutes
                      ? challenge.currentVersion.basicTimeMinutes
                      : 60,
                  difficulty:
                    challenge.currentVersion &&
                    challenge.currentVersion.difficulty
                      ? challenge.currentVersion.difficulty.toString()
                      : "1",
                  language: challenge.language || "ja",
                  status: challenge.status === ChallengeStatus.Draft || false,
                }}
                onFormChange={this.onSettingsFormChange}
                clear={this.props.isOpen}
              >
                <FormGroup>
                  <Checkbox name="status">
                    <Msg id={"challenge.draft"} />
                  </Checkbox>
                </FormGroup>
                <FormGroup>
                  <Label title={<Msg id={"common.title"} />} />
                  <Input name="title" />
                </FormGroup>
                <FormGroup>
                  <Label title={<Msg id={"form.description"} />} />
                  <Textarea name="description" />
                </FormGroup>
                <FormGroup>
                  <Label title={<Msg id={"basicTimeMinutes"} />} />
                  <Input name="basicTimeMinutes" type="number" min="1" />
                </FormGroup>
                <FormGroup>
                  <Label title={<Msg id={"difficulty"} />} />
                  <Radio name="difficulty" defaultValue="1">
                    <Msg id="difficulty.easy" />
                  </Radio>
                  <Tooltip
                    placement="top-start"
                    text={<Msg id="tier.disabled" />}
                    disabled={isChallengeDifficultyAllowed}
                  >
                    <Radio
                      name="difficulty"
                      defaultValue="2"
                      readOnly={!isChallengeDifficultyAllowed}
                    >
                      <Msg id="difficulty.medium" />
                    </Radio>
                  </Tooltip>
                  <Tooltip
                    placement="top-start"
                    text={<Msg id="tier.disabled" />}
                    disabled={isChallengeDifficultyAllowed}
                  >
                    <Radio
                      name="difficulty"
                      defaultValue="3"
                      readOnly={!isChallengeDifficultyAllowed}
                    >
                      <Msg id="difficulty.hard" />
                    </Radio>
                  </Tooltip>
                </FormGroup>
                <FormGroup>
                  <Label title={<Msg id={"form.language"} />} />
                  <Select
                    name="language"
                    options={
                      [
                        { label: Message.getMessageByKey("ja"), value: "ja" },
                        { label: Message.getMessageByKey("en"), value: "en" },
                      ] as SelectItem[]
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <Label title={<Msg id={"programmingCategories"} />} />
                  <TagPicker
                    onChange={this.onCategoryChange}
                    onlySuggestions={true}
                    suggestions={this.props.challengeFilters.programmingCategories.map(
                      (category) => ({
                        label: category.displayString,
                        value: category.value,
                      }),
                    )}
                    tagItems={this.props.challengeFilters.programmingCategories
                      .map((category) => ({
                        label: category.displayString,
                        value: category.value,
                      }))
                      .filter((category) =>
                        challenge.programmingCategories.includes(
                          category.value,
                        ),
                      )}
                    clear={this.props.isOpen}
                    alwaysShow={true}
                  />
                </FormGroup>
              </Form>
            </Column>
            {quizSelectTable()}
          </Row>
        </TableBody>
      </Table>
    );

    return (
      <div className={rootStyle}>
        <Loading isOpen={this.props.importLoading} />
        <Modal
          title={<Msg id={editing ? "challenge.update" : "challenge.create"} />}
          isOpen={isOpen}
          onClickOk={this.onClickOk}
          onClickCancel={onCancel}
          onClose={onCancel}
          disableOk={
            this.state.disableOk || this.props.importLoading || loadingDetail
          }
          size={"large"}
        >
          {modalContents}
        </Modal>
        <QuestionSelect
          isOpen={this.state.isQuestionsOpen}
          onCancel={this.onCloseQuestionSelect}
          onSelect={this.onAddQuestions}
          selectedQuestions={this.state.questions}
        />
        <QuestionNew
          title={<Msg id="question.edit" />}
          isOpen={!!this.state.isUpdateQuestionOpen}
          onClose={this.onCloseUpdateQuestion}
          onCreateQuestion={this.onUpdateQuestion}
          loading={
            !!this.props.updateQuestionLoading || !!this.props.loadingQuestion
          }
          question={selectedQuestion || this.state.selectedQuestion}
          questionFilters={this.props.questionFilters}
        />
      </div>
    );
  }

  private onReorderItem = (sortedItems: number[]) => {
    const reorderedQuestions = this.state.questions.map(
      (_, id) =>
        new QuestionModel({
          ...this.state.questions[sortedItems[id]],
        }),
    );
    this.setState(
      {
        questions: reorderedQuestions,
      },
      this.disableOk,
    );
  };

  private onAddQuestions = (questions: Array<QuestionModel>) => {
    this.setState(
      {
        questions: this.state.questions.concat(questions),
      },
      this.disableOk,
    );
    this.onCloseQuestionSelect();
  };

  private deleteQuestion = (id: number) => {
    this.setState(
      {
        questions: this.state.questions.filter(
          (question) => question.id !== id,
        ),
      },
      this.disableOk,
    );
  };

  private onImportQuestions = (event: React.FormEvent<HTMLInputElement>) => {
    const files = (event.target as HTMLInputElement).files;
    const file = (files && files.length && files[0]) || undefined;

    const formData = new FormData();
    formData.append("file", file as File);

    if (this.props.importQuestions) {
      this.props.importQuestions(formData);
    }
  };

  private onSettingsFormChange = (
    isSettingsFormValid: boolean,
    settingFormValues: {},
    errors: {},
  ) => {
    this.setState(
      {
        settingFormValues,
        isSettingsFormValid,
        settingFormErrors: Boolean(errors),
      },
      this.disableOk,
    );
  };

  private disableOk = () => {
    const { challenge } = this.props;
    const { isSettingsFormValid, settingFormErrors, questions } = this.state;

    const commonFormValid = isSettingsFormValid || !settingFormErrors;
    const quizFormValid =
      challenge.style === ChallengeStyle.Quiz ? questions.length > 0 : true;

    this.setState({
      disableOk: !(commonFormValid && quizFormValid),
    });
  };

  private onCategoryChange = (
    programmingCategories: Array<{ value: number }>,
  ) => {
    const gitFormValid = this.props.challengeType === ChallengeType.Edit;

    const formValid =
      this.state.isSettingsFormValid ||
      this.props.challengeType === ChallengeType.Edit;

    this.setState({
      disableOk: !(formValid && (this.state.questions.length || gitFormValid)),
      programmingCategories: programmingCategories.map(
        (category) => category.value,
      ),
    });
  };

  private onClickOk = () => {
    if (this.props.onSave) {
      const formValid =
        this.state.isSettingsFormValid ||
        this.props.challengeType === ChallengeType.Edit;

      const status = this.state.settingFormValues.hasOwnProperty("status")
        ? (this.state.settingFormValues as { status: boolean }).status
          ? ChallengeStatus.Draft
          : ChallengeStatus.Ready
        : undefined;

      const { basicTimeMinutes, ...restFormValues } = this.state
        .settingFormValues as {
        basicTimeMinutes: number;
      };

      const challengeProperties = formValid
        ? {
            ...restFormValues,
            programmingCategories: this.state.programmingCategories,
            basicTimeMinutes: Number(basicTimeMinutes),
            status,
          }
        : undefined;

      const challengeQuestions =
        this.state.questions.length &&
        !isEqual(this.state.questions, this.props.challenge.questions)
          ? { questionIds: this.state.questions.map((question) => question.id) }
          : undefined;

      this.props.onSave(challengeProperties, challengeQuestions);
    }
  };

  private onOpenQuestionSelect = () => {
    this.setState({
      isQuestionsOpen: true,
    });
  };

  private onCloseQuestionSelect = () => {
    this.setState({
      isQuestionsOpen: false,
    });
  };

  private onOpenUpdateQuestion = (question: QuestionModel) => {
    const choices = question.settings && question.settings.choices;
    let selectedQuestion = new QuestionModel(question);

    if (
      question.kind === QuestionType.MCQ &&
      !choices &&
      this.props.getQuestion
    ) {
      // get question
      this.props.getQuestion(question.id);
    } else {
      selectedQuestion = new QuestionModel(
        Object.assign({}, question, { choices: choices || [] }),
      );
    }

    this.setState({
      selectedQuestion,
      isUpdateQuestionOpen: true,
    });
  };

  private onCloseUpdateQuestion = () => {
    this.setState({
      selectedQuestion: undefined,
      isUpdateQuestionOpen: false,
    });
  };

  private onUpdateQuestion = (payload: {}) => {
    if (this.props.updateQuestion && this.state.selectedQuestion) {
      this.props.updateQuestion(this.state.selectedQuestion.id, payload);
    }
  };
}

export default ChallengeNew;
