import {
  UserModel,
  ApplicantSharingExamModel,
  ApplicantInitialModel,
  ApplicantExamModel,
} from "../shared/models";
import { intercom } from "../shared/services/intercom";

interface IntercomSet {
  type: "applicant_sharing" | "applicant_exam" | "organization" | "applicant";
  data?: {};
}

export const intercomSetAction = ({ type, data }: IntercomSet) => {
  switch (type) {
    case "organization":
      const user = new UserModel(data);
      intercom.updateIntercomInfo({
        user_id: user.id,
        name: user.name,
        email: user.email,
        "Organization id": user.organizationId,
        "Organization name":
          user.organization.displayName || user.organization.name,
      });
      break;
    case "applicant_sharing":
      const sharing = new ApplicantSharingExamModel(data);
      intercom.updateIntercomInfo({
        "Organization id": sharing.organizationId,
        "Organization name": sharing.organizationDisplayName,
        "Applicant exam id": sharing.examId,
        "Applicant exam name": sharing.examName,
      });
      break;
    case "applicant_exam":
      const applicantExam = new ApplicantInitialModel(data);
      intercom.updateIntercomInfo({
        "Organization id": applicantExam.organizationId,
        "Organization name": applicantExam.organizationName,
        "Applicant exam id": applicantExam.examId,
        "Applicant exam name": applicantExam.examName,
      });
      break;
    case "applicant":
      const exam = new ApplicantExamModel(data as ApplicantExamModel);
      intercom.updateIntercomInfo({
        email: exam.applicant.email,
        name: exam.applicant.fullname,
        user_id: exam.applicant.userId || `applicant_${exam.applicant.id}`,
        "Applicant id": exam.applicant.id,
      });

      break;
    default:
  }
  return { type: "@null" };
};
