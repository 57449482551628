import * as React from "react";
import { FormattedPlural } from "react-intl";

import { Msg } from "@shared/components";
import { SpokenLanguages } from "@shared/services/enums";
import { getWordCount } from "@shared/services/string";

interface SubmissionDetailQuestionFreeTextProps {
  userAnswer: string;
  language?: SpokenLanguages;
  icon?: React.ReactNode;
}

export const SubmissionDetailQuestionFreeText = ({
  userAnswer,
  language,
  icon,
}: SubmissionDetailQuestionFreeTextProps) => {
  return (
    <div>
      <div className="code-c-submission-detail-question__table-header question-freetext-table">
        <div />
        <div>
          <Msg id="submission.quiz.submittedAnswer" />
        </div>
      </div>
      <div className="code-c-submission-detail-question__table-col question-freetext-table">
        <div>{icon}</div>
        <div className="is-wrap">{userAnswer}</div>
      </div>
      <div className="question-freetext-char-count" data-testid="char-count">
        <span>
          {userAnswer.length}
          <FormattedPlural
            value={userAnswer.length}
            one={<Msg id="common.character" />}
            other={<Msg id="common.characters" />}
          />
        </span>
        {language === SpokenLanguages.English && (
          <span>
            , {getWordCount(userAnswer)}
            <FormattedPlural
              value={getWordCount(userAnswer)}
              one={<Msg id="common.word" />}
              other={<Msg id="common.words" />}
            />
          </span>
        )}
      </div>
    </div>
  );
};
