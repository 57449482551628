import * as classnames from "classnames";
import * as H from "history";
import { isEqual } from "lodash";
import * as React from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";

import {
  Table,
  TableHead,
  Column,
  HeaderColumn,
  Pagination,
  SortableText,
  CollapsibleChallengeColumn,
  Loading,
  SubMenuContainer,
  SubMenuContainerLeft,
  SubMenuContainerRight,
  DocumentTitle,
  Msg,
  FilterConditions,
  Overlay,
  Restricted,
} from "@shared/components";
import {
  useCreateChallenge,
  useCreateChallengePin,
  useDeleteChallengePin,
  useUpdateChallengeQuestion,
} from "@shared/hooks/query";
import {
  ChallengeModel,
  PaginationModel,
  OrganizationModel,
  ChallengeFilterFormModel,
  PinnedChallengeModel,
  ChallengeFilterModel,
} from "@shared/models";
import { SortOrderItem } from "@shared/models";
import { pushEvent } from "@shared/services/analytics";
import {
  ChallengeStyle,
  UserRole,
  ProjectRole,
  TierAction,
  SortDirection,
  ChallengeStatus,
} from "@shared/services/enums";
import history from "@shared/services/history";
import Message from "@shared/services/message";
import { isGiveryOrg } from "@shared/services/organization";
import { parseUrl, updateUrl } from "@shared/services/queryString";
import {
  filterChallengeListFilters,
  isChallengeAllowed,
} from "@shared/services/tier";
import * as urlParser from "@shared/services/urlParser";

import { ChallengeActionDropdown } from "../challengeActionDropdown/ChallengeActionDropdown";
import ChallengeActionModals from "../challengeActionModals/ChallengeActionModals.connect";
import ChallengeCollection from "../challengeCollection/ChallengeCollection.connect";
import ChallengeDetail from "../challengeDetail/ChallengeDetail.connect";
import ChallengeFilter from "../challengeFilter/ChallengeFilter";
import { ChallengeType } from "../challengeNew/ChallengeNew";
import ChallengeNew from "../challengeNew/ChallengeNew.connect";
import { ChallengeListHeader } from "./partials";

interface HookProps {
  addPinChallenge: ReturnType<typeof useCreateChallengePin>;
  createChallenge: ReturnType<typeof useCreateChallenge>;
  deletePinChallenge: ReturnType<typeof useDeleteChallengePin>;
  updateChallengeQuestions: ReturnType<typeof useUpdateChallengeQuestion>;
}
/**
 * Prop interface
 */
export interface ChallengeListProps extends HookProps {
  currentProjectId: number;
  challengeDetailList: Array<ChallengeModel>;
  challengeFilters: ChallengeFilterModel;
  challengeList: Array<ChallengeModel>;
  copyChallengeError: boolean;
  copyChallengeLoading: boolean;
  errorCreate: boolean;
  loading: boolean;
  loadingCreate: boolean;
  loadingDetail: boolean;
  location?: H.Location<{
    clickMenu?: boolean;
    clickTab?: boolean;
    resetScroll?: boolean;
  }>;
  org: OrganizationModel;
  pagination: PaginationModel;
  pinnedChallengeList: PinnedChallengeModel[];
  canEditPin: boolean;

  getChallenge: (payload: {}) => void;
  getChallengeList: (
    pagination: PaginationModel,
    filters: {},
    sortOrder: SortOrderItem,
  ) => void;
  isTierActionAllowed: (tierAction: TierAction) => boolean;
}

/**
 * State interface
 */
export interface ChallengesListFormValues {
  keyword?: string;
  styles?: number[];
  categories?: number[];
  difficulties?: number[];
  onlyNew?: string;
  onlyPinned?: string;
  spokenLanguages?: string[];
  programmingLanguages?: number[];
  programmingCategories?: number[];
  isLinked?: string;
}

export interface ChallengeListState {
  sortItem: SortOrderItem;
  formValues: ChallengesListFormValues;
  isNewOpen: boolean;
  isEditOpen: boolean;
  isDeleteOpen: boolean;
  isCopyOpen: boolean;
  drawer: {
    isOpen: boolean;
    targetChallengeId?: number;
    // MEMO: this is kind of extra code. we can remove it after finishing the survey
    // https://givery-technology.atlassian.net/browse/TRAC-2278
    clickCount: number;
  };
  selectedChallenge: ChallengeModel;
  clearSearchForm: boolean;
}

/**
 * Page component
 */
class ChallengeList extends React.Component<
  ChallengeListProps,
  ChallengeListState
> {
  constructor(props: ChallengeListProps) {
    super(props);

    this.state = {
      sortItem: {} as SortOrderItem,
      formValues: {},
      isNewOpen: false,
      isDeleteOpen: false,
      isEditOpen: false,
      isCopyOpen: false,
      selectedChallenge: new ChallengeModel(),
      clearSearchForm: false,
      drawer: {
        isOpen: false,
        clickCount: 0,
      },
    };
  }

  public UNSAFE_componentWillMount() {
    this.getChallengesFromParams(this.props);
  }

  public UNSAFE_componentWillReceiveProps(nextProps: ChallengeListProps) {
    const { location: nextLocation } = nextProps;
    const { location } = this.props;

    // retrieve the list data again after changing the conditions except for selectedId
    const { selectedId: nextSelectedId, ...nextConditions } = parseUrl(
      nextLocation?.search,
    );
    const { selectedId, ...conditions } = parseUrl(location?.search);
    if (!isEqual(nextConditions, conditions)) {
      this.getChallengesFromParams(nextProps);
    }
  }

  public shouldComponentUpdate(
    nextProps: ChallengeListProps,
    nextState: ChallengeListState,
  ) {
    return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state);
  }

  public componentDidUpdate(prevProps: ChallengeListProps) {
    // has created or updated a quiz
    // close new modal after finishing to create a new quiz
    if (
      (!this.props.createChallenge.isLoading &&
        prevProps.createChallenge.isLoading &&
        this.props.createChallenge.isSuccess) ||
      // use by update action
      (!this.props.loadingCreate &&
        prevProps.loadingCreate &&
        !this.props.errorCreate) ||
      (!this.props.updateChallengeQuestions.isLoading &&
        prevProps.updateChallengeQuestions.isLoading &&
        this.props.updateChallengeQuestions.isSuccess)
    ) {
      this.onCloseModal({
        ignoreDrawer: this.state.drawer.isOpen,
      });
    }

    // close slider / modal after copying a challenge
    if (
      !this.props.copyChallengeLoading &&
      prevProps.copyChallengeLoading &&
      !this.props.copyChallengeError
    ) {
      this.onCloseChallenge();
    }

    // Reset scroll position when challenge menu in the left side bar or the challenge tab gets clicked
    if (
      this.props.location?.state?.clickMenu ||
      this.props.location?.state?.clickTab ||
      this.props.location?.state?.resetScroll
    ) {
      history.replace(this.props.location.pathname, null);

      const scrollParent = document.querySelector(
        ".code-c-sub-menu-container-left",
      );
      if (scrollParent) {
        scrollParent.scrollTo(0, 0);
      }

      this.setState({ clearSearchForm: true }, () => {
        this.setState({ clearSearchForm: false });
      });
    }
  }

  private onOpenEditModal = (challenge: ChallengeModel) => {
    this.getChallenge(challenge);
    this.setState({
      isEditOpen: true,
      selectedChallenge: challenge,
    });
  };

  private onOpenDeleteModal = (challenge: ChallengeModel) => {
    this.setState({
      isDeleteOpen: true,
      selectedChallenge: challenge,
    });
  };

  private onOpenCopyModal = (challenge: ChallengeModel) => {
    // refresh the challenge to get all the details regarding the challenge
    // the value passed via params comes from challengeList which only has minimal data
    this.getChallenge(challenge);
    this.setState({
      selectedChallenge: challenge,
      isCopyOpen: true,
    });
  };

  public render() {
    const rootStyle = classnames("code-challenge-list");

    const {
      challengeDetailList,
      challengeFilters,
      loading,
      loadingCreate,
      pinnedChallengeList,
      org,
      canEditPin,
      currentProjectId,
    } = this.props;

    const { drawer } = this.state;

    const { keyword } = this.state.formValues;

    // TODO should be used organization kind = 2
    const isGivery = isGiveryOrg(org.id);

    const contents = this.props.challengeList.map(
      (challenge: ChallengeModel) => {
        const challengeAllowed = isChallengeAllowed({
          challenge,
          isTierActionAllowed: this.props.isTierActionAllowed,
        });

        const copyAllowed = this.props.isTierActionAllowed(
          TierAction.CustomQuizUsage,
        );

        const actions = (
          <Column className="code-challenge-list__actions">
            <Restricted
              roles={[
                UserRole.SystemAdmin,
                UserRole.ChallengeCreator,
                ProjectRole.ProjectAdmin,
                ProjectRole.ChallengeCreator,
              ]}
              strictAllow={challenge.style === ChallengeStyle.Quiz && !isGivery}
            >
              <ChallengeActionDropdown
                challengeAllowed={challengeAllowed}
                copyAllowed={copyAllowed}
                isGivery={isGivery}
                challenge={challenge}
                onClickCopy={() => this.onOpenCopyModal(challenge)}
                onClickEdit={() => this.onOpenEditModal(challenge)}
                onClickDelete={() => this.onOpenDeleteModal(challenge)}
              />
            </Restricted>
          </Column>
        );

        const pinnedChallenge = pinnedChallengeList.find(
          (item) => item.challengeId === challenge.id,
        );

        return (
          <CollapsibleChallengeColumn
            isChallengeAllowed={challengeAllowed}
            key={challenge.id}
            keyword={keyword}
            challenge={challenge}
            onClickDetail={this.onOpenChallenge}
            onClickTag={this.onTagClick}
            onClickPin={() =>
              this.onTogglePin(challenge.id, pinnedChallenge?.id)
            }
            postActionMenu={actions}
            hasTogglePin={canEditPin}
            isPinned={pinnedChallenge !== undefined}
          />
        );
      },
    );

    return (
      <div className={rootStyle}>
        <DocumentTitle page={Message.getMessageByKey("common.challengeList")} />
        <BreadcrumbsItem to={`/p/${currentProjectId}/challenges`}>
          <Msg id={"common.challengeList"} />
        </BreadcrumbsItem>
        <SubMenuContainer>
          <SubMenuContainerLeft>
            <ChallengeFilter
              formValues={this.state.formValues}
              challengeFilters={challengeFilters}
              onFormChange={this.onFormChange}
              clear={this.state.clearSearchForm}
              isTierActionAllowed={this.props.isTierActionAllowed}
            />
          </SubMenuContainerLeft>
          <SubMenuContainerRight
            className="code-challenge-list__container-right"
            data-track-scroll-reset
          >
            <ChallengeCollection
              pinnedChallengeList={pinnedChallengeList}
              canEditPin={canEditPin}
              onTogglePin={this.onTogglePin}
              modalOptions={{
                showOfficial: this.props.isTierActionAllowed(
                  TierAction.OfficialExamUsage,
                ),
              }}
            />
            <ChallengeListHeader
              isGivery={isGivery}
              count={this.props.pagination.count}
              onClickNewQuiz={() => this.onOpenNewModal(ChallengeStyle.Quiz)}
            />
            <FilterConditions
              conditions={this.state.formValues}
              onChange={(formValues) => this.onFormChange(true, formValues, {})}
            />
            <div className="code-challenge-list__table">
              {/* Edit and new modals have their own loading spinner so don't show spinner if
                  edit / new modal is open */}
              <Loading
                isOpen={loading && !loadingCreate && !this.state.isEditOpen}
                fullScreen={false}
                overlay={false}
              />
              <Table>
                <TableHead>
                  <HeaderColumn size={1} />
                  <HeaderColumn size={1}>
                    <SortableText
                      name="id"
                      sortItem={this.state.sortItem}
                      onClick={this.onSortChange}
                      ariaLabel="ID"
                    >
                      <Msg id="common.id" />
                    </SortableText>
                  </HeaderColumn>
                  <HeaderColumn size={4}>
                    <SortableText
                      name="title"
                      sortItem={this.state.sortItem}
                      onClick={this.onSortChange}
                      ariaLabel="Title"
                    >
                      <Msg id="common.title" />
                    </SortableText>
                  </HeaderColumn>
                  <HeaderColumn size={1}>
                    <SortableText
                      name="difficulty"
                      sortItem={this.state.sortItem}
                      onClick={this.onSortChange}
                      ariaLabel="Difficulty"
                    >
                      <Msg id={"difficulty"} />
                    </SortableText>
                  </HeaderColumn>
                  <HeaderColumn size={2}>
                    <SortableText
                      name="style"
                      sortItem={this.state.sortItem}
                      onClick={this.onSortChange}
                      ariaLabel="Style"
                    >
                      <Msg id={"challenge.style"} />
                    </SortableText>
                  </HeaderColumn>
                  <HeaderColumn size={1} className="align-right">
                    <SortableText
                      name="numberOfOrgsUsing"
                      sortItem={this.state.sortItem}
                      onClick={this.onSortChange}
                      ariaLabel="Number Of Organizations Using"
                    >
                      <Msg id={"challenge.numberOfOrgsUsing"} />
                    </SortableText>
                  </HeaderColumn>
                  <HeaderColumn size={1} className="align-right">
                    <SortableText
                      name="basicTimeMinutes"
                      sortItem={this.state.sortItem}
                      onClick={this.onSortChange}
                      ariaLabel="Basic Time Minutes"
                    >
                      <Msg id={"basicTimeMinutes"} />
                    </SortableText>
                  </HeaderColumn>
                  <HeaderColumn size={1} className="align-right">
                    <SortableText
                      name="normalcyScore"
                      initialDirection={SortDirection.Desc}
                      sortItem={this.state.sortItem}
                      onClick={this.onSortChange}
                      ariaLabel="Score Distribution"
                    >
                      <Msg id="challenge.scoreDistribution" />
                    </SortableText>
                  </HeaderColumn>
                  <HeaderColumn size={1} className="align-right">
                    <SortableText
                      name="numberOfSubmission"
                      sortItem={this.state.sortItem}
                      onClick={this.onSortChange}
                      ariaLabel="Number Of Submissions"
                    >
                      <Msg id="challenge.numberOfSubmission" />
                    </SortableText>
                  </HeaderColumn>
                  <HeaderColumn size={1} className="align-right">
                    <SortableText
                      name="averageScore"
                      sortItem={this.state.sortItem}
                      onClick={this.onSortChange}
                      ariaLabel="Average Score"
                    >
                      <Msg id="challenge.average" />
                    </SortableText>
                  </HeaderColumn>
                  <HeaderColumn size={1} className="align-right">
                    <SortableText
                      name="medianMinutesTaken"
                      sortItem={this.state.sortItem}
                      onClick={this.onSortChange}
                      ariaLabel="Average Completion Time"
                    >
                      <Msg id="challenge.averageTimeTakenMin" />
                    </SortableText>
                  </HeaderColumn>
                  <HeaderColumn size={1} />
                </TableHead>
                {contents}
              </Table>
            </div>
            <div className="code-challenge-list__pagination">
              <Pagination
                pagination={this.props.pagination}
                onPageChange={this.onPageChange}
              />
            </div>
          </SubMenuContainerRight>
        </SubMenuContainer>
        {this.state.isNewOpen && (
          <ChallengeNew
            challenge={Object.assign(
              {},
              this.state.selectedChallenge,
              challengeDetailList.find(
                (challenge) => challenge.id === this.state.selectedChallenge.id,
              ),
            )}
            challengeFilters={challengeFilters}
            challengeType={ChallengeType.New}
            isOpen={this.state.isNewOpen}
            loadingDetail={this.props.loading}
            onCancel={this.onCloseModal}
            onSave={this.onCreateNew}
          />
        )}
        <ChallengeActionModals
          isGivery={isGivery}
          challenge={this.state.selectedChallenge}
          isEditOpen={this.state.isEditOpen}
          isDeleteOpen={this.state.isDeleteOpen}
          isCopyOpen={this.state.isCopyOpen}
          onCloseModal={this.onCloseModal}
          updateChallengeQuestions={this.updateChallenge}
          onDeleteChallenge={this.onCloseChallenge}
        />
        {drawer.isOpen && drawer.targetChallengeId !== undefined && (
          <>
            <ChallengeDetail
              className="code-challenge-list__detail-panel"
              challengeId={drawer.targetChallengeId}
              highlightedKeyword={keyword}
              onClose={this.onCloseChallenge}
              hidePin={!canEditPin}
              hideActionDropdown={false}
            />
            <Overlay
              className="code-challenge-list__overlay"
              isOpen={true}
              onClick={this.onCloseChallenge}
            />
          </>
        )}
      </div>
    );
  }

  private getChallengesFromParams = (props: ChallengeListProps) => {
    const {
      column,
      direction,
      page,
      keyword,
      styles,
      categories,
      difficulties,
      onlyNew,
      onlyPinned,
      spokenLanguages,
      programmingLanguages,
      programmingCategories,
      isLinked,
      selectedId,
    } = parseUrl(props.location && props.location.search);

    const { updatedFormValues: formValues, shouldUpdateUrl } =
      filterChallengeListFilters(
        {
          keyword: urlParser.getString(keyword),
          styles: urlParser.getNumberArray(styles),
          categories: urlParser.getNumberArray(categories),
          difficulties: urlParser.getNumberArray(difficulties),
          onlyNew: urlParser.getBooleanString(onlyNew),
          onlyPinned: urlParser.getBooleanString(onlyPinned),
          spokenLanguages: urlParser.getStringArray(spokenLanguages),
          programmingLanguages: urlParser.getNumberArray(programmingLanguages),
          programmingCategories: urlParser.getNumberArray(
            programmingCategories,
          ),
          isLinked: urlParser.getBooleanString(isLinked),
        } as ChallengesListFormValues,
        this.props.isTierActionAllowed,
      );

    const sortItem = {
      column,
      direction,
    };

    if (shouldUpdateUrl) {
      updateUrl({
        ...formValues,
        ...sortItem,
      });
    }

    // open drawer if the selected id has passed
    const challengeId = urlParser.getNumber(selectedId);
    if (challengeId) {
      this.onOpenChallenge(challengeId);
    }

    this.setState({ formValues, sortItem });

    props.getChallengeList(
      props.pagination.getAtPage(urlParser.getPageNo(page)),
      formValues,
      sortItem,
    );
  };

  private onPageChange = ({ selected }: { selected: number }) => {
    updateUrl({
      ...this.state.formValues,
      ...this.state.sortItem,
      page: selected,
    });

    this.props.getChallengeList(
      this.props.pagination.getAtPage(selected),
      this.state.formValues,
      this.state.sortItem,
    );
  };

  private onSortChange = (sortItem: SortOrderItem) => {
    this.setState({
      sortItem,
    });

    updateUrl({ ...this.state.formValues, ...sortItem });

    this.props.getChallengeList(
      new PaginationModel(),
      this.state.formValues,
      sortItem,
    );
  };

  private onFormChange = (
    formValid: boolean,
    formValues: {},
    formErrors: {},
  ) => {
    if (isEqual(formValues, this.state.formValues)) {
      return;
    }

    updateUrl({ ...formValues, ...this.state.sortItem });

    this.setState({
      formValues,
    });

    this.props.getChallengeList(
      new PaginationModel(),
      formValues,
      this.state.sortItem,
    );
  };

  private onOpenChallenge = (challengeId: number) => {
    const { clickCount } = this.state.drawer;
    this.setState({
      drawer: {
        isOpen: true,
        targetChallengeId: challengeId,
        clickCount: clickCount + 1,
      },
    });
    const params = parseUrl(this.props.location?.search);
    updateUrl({ ...params, selectedId: challengeId });
  };

  private onCloseChallenge = () => {
    this.onCloseModal();
    const { selectedId, ...params } = parseUrl(this.props.location?.search);
    updateUrl({ ...params });
    // MEMO: this is kind of extra code. we can remove it after finishing the survey
    // https://givery-technology.atlassian.net/browse/TRAC-2278
    if (this.state.drawer.clickCount >= 3) {
      pushEvent("showSurveyOrg");
    }
  };

  private onCloseModal = (params?: { ignoreDrawer?: boolean }) => {
    const { clickCount } = this.state.drawer;
    this.setState({
      isNewOpen: false,
      isCopyOpen: false,
      isEditOpen: false,
      isDeleteOpen: false,
      drawer: params?.ignoreDrawer
        ? this.state.drawer
        : { isOpen: false, clickCount },
    });
  };

  private onOpenNewModal = (style: ChallengeStyle) => {
    this.setState({
      isNewOpen: true,
      selectedChallenge: new ChallengeModel({ style }),
    });
  };

  private onCreateNew = (
    formValues: {
      basicTimeMinutes: number;
      difficulty: number;
      language: string;
      programmingCategories: number[];
      status: ChallengeStatus;
      title: string;
    },
    questions?: { questionIds: Array<number> },
  ) => {
    if (questions) {
      this.props.createChallenge.mutate(
        Object.assign({}, formValues, questions),
      );
    } else {
      // uh oh? :( :(
    }
  };

  private onTagClick = (params: Partial<ChallengeFilterFormModel>) => {
    updateUrl({
      ...{ ...this.state.formValues, ...params },
      ...this.state.sortItem,
    });

    this.props.getChallengeList(
      new PaginationModel(),
      this.state.formValues,
      this.state.sortItem,
    );
  };

  private onTogglePin = (challengeId: number, pinId?: number) => {
    pinId !== undefined
      ? this.props.deletePinChallenge.mutate({ challengeId, pinId })
      : this.props.addPinChallenge.mutate(challengeId);
  };
  private getChallenge = (challenge: ChallengeModel) => {
    const index = this.props.challengeDetailList.findIndex(
      (item) => item.id === challenge.id,
    );

    // quiz might have questions which already updated or deleted, so get the detail again
    if (challenge.style === ChallengeStyle.Quiz || index === -1) {
      this.props.getChallenge(challenge.id);
    }
  };

  private updateChallenge = (challengeId: number, questionIds: number[]) => {
    this.props.updateChallengeQuestions.mutate({ challengeId, questionIds });
  };
}

export default ChallengeList;
