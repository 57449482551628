import * as classnames from "classnames";
import * as React from "react";

import { SortOrderItem } from "@shared/models";
import { SortDirection } from "@shared/services/enums";

import { Icon } from "..";

/**
 * Prop interface
 */
export interface SortableTextProps {
  sortItem: SortOrderItem;
  name?: string;
  initialDirection?: SortDirection;
  className?: string;
  children?: {};
  disabled?: boolean;
  onClick?: (sortItem: SortOrderItem) => void;
  ariaLabel?: string;
}

/**
 * React Component
 */
export default function SortableText({
  sortItem,
  className,
  children,
  name,
  initialDirection = SortDirection.Asc,
  onClick,
  ariaLabel,
}: SortableTextProps) {
  const rootStyle = classnames("code-c-sortable-text", {
    [`${className}`]: Boolean(className),
  });

  const ICON_TABLE = Object.freeze({
    asc: "sort-asc",
    desc: "sort-desc",
  });

  const A11Y_TABLE = Object.freeze({
    asc: "ascending",
    desc: "descending",
  });

  const handleClick = () => {
    const currentDirection =
      name === sortItem.column ? sortItem.direction : SortDirection.None;

    const direction =
      currentDirection === SortDirection.None
        ? initialDirection === SortDirection.Desc
          ? SortDirection.Desc
          : SortDirection.Asc
        : currentDirection === SortDirection.Desc
        ? SortDirection.Asc
        : SortDirection.Desc;

    if (onClick && name) {
      onClick({
        column: name,
        direction,
      } as SortOrderItem);
    }
  };

  let icon = null;
  let ariaSort: React.HTMLAttributes<HTMLDivElement>["aria-sort"] = "none";
  if (sortItem.column === name && sortItem.direction) {
    const iconType = ICON_TABLE[sortItem.direction];

    icon =
      sortItem.direction === SortDirection.Asc ||
      sortItem.direction === SortDirection.Desc ? (
        <Icon size="small" type={iconType} />
      ) : null;

    ariaSort = A11Y_TABLE[sortItem.direction];
  }

  return (
    <div
      className={rootStyle}
      onClick={handleClick}
      role="button"
      aria-label={ariaLabel}
      aria-sort={ariaSort}
    >
      <a className="code-c-sortable-text__text">{children}</a>
      <div className="code-c-sortable-text__icon">{icon}</div>
    </div>
  );
}
