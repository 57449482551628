import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { putSubmissionResetPassword } from "@api/submissions";

import { useStoreContext } from "@context";

import Message from "@shared/services/message";

export function useCreateSubmissionResetPassword() {
  const { projectId } = useStoreContext();
  const mutation = useMutation({
    mutationFn: (submissionId: number) =>
      putSubmissionResetPassword(projectId, submissionId),
    onSuccess: () => {
      toast.success(
        Message.getMessageByKey("message.submission.resetPassword.success"),
      );
    },
  });

  return mutation;
}
