import { connect } from "react-redux";
import { Action, Dispatch } from "redux";

import { authSignOutAction } from "@actions";

import { RootState } from "../../../reducers";
import AccountMenu, { AccountMenuProps } from "./AccountMenu";

export { AccountMenu, AccountMenuProps };

const mapStateToProps = (state: RootState) => ({
  user: state.user.user,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  signOut: () => {
    dispatch(authSignOutAction.request());
  },
});

/**
 * Wrapped component
 */
const wrappedComponent = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountMenu);

export default wrappedComponent;
