import { startOfToday } from "date-fns";
import { IntlShape } from "react-intl";
import { z } from "zod";

import {
  AutoFilterType,
  ExamDeliveryKind,
  WebcamSettings,
} from "@shared/services/enums";

import {
  autoFilterSettingsSuperRefine,
  examSettingsdateRangeSuperRefine,
} from "../examSectionUtil/ExamSectionUtil";

export const idDeliveryConfig = (intl: IntlShape, isCreate: boolean) => {
  const required_error = intl.formatMessage({
    id: "required",
  });
  const minDateError = intl.formatMessage({
    id: "validation.date.notAllowPast",
  });
  return z
    .object({
      startAt: isCreate
        ? z.date({ required_error }).min(startOfToday(), minDateError)
        : z.date({ required_error }),
      endAt: z.date({ required_error }),
      dynamicDeadlineHours: z.number().int().min(1).max(365).optional(),
      passwordRequired: z.boolean(),
    })
    .superRefine((data, ctx) => {
      if (!data?.startAt || !data?.endAt) {
        return;
      }
      return examSettingsdateRangeSuperRefine(
        { startAt: data.startAt, endAt: data.endAt },
        ctx,
        intl,
      );
    });
};

export const emailDeliverySectionUrlDelivery = (
  intl: IntlShape,
  isCreate: boolean,
) => {
  const required_error = intl.formatMessage({
    id: "required",
  });

  const minDateError = intl.formatMessage({
    id: "validation.date.notAllowPast",
  });

  return z
    .object({
      startAt: isCreate
        ? z.date({ required_error }).min(startOfToday(), minDateError)
        : z.date({ required_error }),
      endAt: z.date({ required_error }),
      dynamicDeadlineHours: z.number().int().min(1).max(365).optional(),
      domainWhitelist: z.array(z.string()).optional(),
    })
    .superRefine((data, ctx) => {
      if (!data || !data.startAt || !data.endAt) {
        return;
      }

      examSettingsdateRangeSuperRefine(
        { startAt: data.startAt, endAt: data.endAt },
        ctx,
        intl,
      );
    });
};

const autoFilterValidation1 = z.object({
  filterType: z.literal(AutoFilterType.AutoPassFail),
  autoPassFail: z.object({
    passFailThreshold: z.number().int().min(0).max(100),
  }),
});

const autoFilterValidation2 = (intl: IntlShape) => {
  return z
    .object({
      filterType: z.literal(AutoFilterType.AutoPassFailReview),
      autoPassFailReview: z.object({
        passAtAndAbove: z.number().int().min(0).max(100),
        failAtAndBelow: z.number().int().min(0).max(100).optional(),
      }),
    })
    .superRefine((data, ctx) => {
      autoFilterSettingsSuperRefine(data, ctx, intl);
    });
};

const autoFilterValidation3 = (intl: IntlShape) => {
  return z
    .object({
      filterType: z.literal(AutoFilterType.AutoPassFailReview),
      autoPassFailReview: z.object({
        passAtAndAbove: z.number().int().min(0).max(100).optional(),
        failAtAndBelow: z.number().int().min(0).max(100),
      }),
    })
    .superRefine((data, ctx) => {
      autoFilterSettingsSuperRefine(data, ctx, intl);
    });
};

const autoFilterSettingsValidation = (intl: IntlShape) => {
  return z.union([
    autoFilterValidation1,
    autoFilterValidation2(intl),
    autoFilterValidation3(intl),
    z.undefined(),
  ]);
};

export const examSettingsFormValidation = ({
  deliveryKind,
  urlDeliveryEnabled,
  intl,
  isCreate,
}: {
  deliveryKind: ExamDeliveryKind;
  urlDeliveryEnabled: boolean;
  intl: IntlShape;
  isCreate: boolean;
}) => {
  // Create base validation
  const baseValidation = z.object({
    language: z.enum(["en", "ja"]),
    atsIntegrationEnabled: z.boolean(),
    atsDynamicDeadlineDays: z.number().int().min(1).max(365).optional(),
    deadlineNotificationEnabled: z.boolean(),
    deliveryKind: z.nativeEnum(ExamDeliveryKind),
    urlDeliveryEnabled: z.boolean(),
    isOfficial: z.boolean(),
    guestSharingEnabled: z.boolean(),
    guestSharingSettings: z.object({
      showSecretTestcases: z.boolean(),
      showReviews: z.boolean(),
      showInsights: z.boolean(),
      showName: z.boolean(),
      showEntryFormResponses: z.boolean(),
      showSubmissionDetails: z.boolean(),
      showActionLog: z.boolean(),
      showNote: z.boolean(),
    }),
    applicantActionSettingsEnabled: z.boolean(),
    applicantActionSettings: z.object({
      showKeyEvents: z.boolean(),
      showCopyPasteEvents: z.boolean(),
      showTabChangeEvents: z.boolean(),
      showScreenshotEvents: z.boolean(),
    }),
    webcamSettings: z.object({
      enabled: z.boolean(),
      livenessVerification: z.nativeEnum(WebcamSettings),
    }),
    autoFilterSettingsEnabled: z.boolean(),
    autoFilterSettings: autoFilterSettingsValidation(intl),
    coverImageUrl: z.string().optional(),
  });

  // Conditionally add `urlDeliverySection` if `urlDeliveryEnabled` is true
  if (
    deliveryKind === ExamDeliveryKind.Standard &&
    urlDeliveryEnabled === true
  ) {
    return baseValidation.extend({
      urlSharingConfig: emailDeliverySectionUrlDelivery(intl, isCreate),
      idDeliveryConfig: z.undefined(),
    });
  }
  if (deliveryKind === ExamDeliveryKind.Standard) {
    return baseValidation.extend({
      urlSharingConfig: z.undefined(),
      idDeliveryConfig: z.undefined(),
    });
  } else {
    return baseValidation.extend({
      urlSharingConfig: z.undefined(),
      idDeliveryConfig: idDeliveryConfig(intl, isCreate),
    });
  }
};

export type ExamSettingsFormValidation = z.infer<
  ReturnType<typeof examSettingsFormValidation>
>;

export const convertToUTC = (date: Date) => {
  return date.toISOString().replace(/\.\d{3}Z$/, "Z");
};

export const formInitialValues: ExamSettingsFormValidation = {
  urlDeliveryEnabled: false,
  language: "ja",
  atsIntegrationEnabled: false,
  deadlineNotificationEnabled: true,
  deliveryKind: ExamDeliveryKind.Standard,
  urlSharingConfig: undefined,
  idDeliveryConfig: undefined,
  guestSharingEnabled: false,
  guestSharingSettings: {
    showSecretTestcases: false,
    showReviews: false,
    showInsights: false,
    showName: false,
    showEntryFormResponses: false,
    showSubmissionDetails: false,
    showActionLog: false,
    showNote: false,
  },
  applicantActionSettingsEnabled: false,
  applicantActionSettings: {
    showKeyEvents: false,
    showCopyPasteEvents: false,
    showTabChangeEvents: false,
    showScreenshotEvents: false,
  },
  webcamSettings: {
    enabled: false,
    livenessVerification: WebcamSettings.optional,
  },
  autoFilterSettingsEnabled: false,
  autoFilterSettings: undefined,
  isOfficial: false,
};
