import classnames from "classnames";

export interface ErrorMessageProps {
  message?: string;
  className?: string;
}

export const ErrorMessage = ({ message, className }: ErrorMessageProps) => {
  const rootStyle = classnames("code-c-validation-message", {
    [`${className}`]: Boolean(className),
  });

  if (!message) {
    return null;
  }

  return (
    <div className={rootStyle} role="alert">
      {message}
    </div>
  );
};
