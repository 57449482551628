const applicantParseRegexp = new RegExp(
  /^\/[0-9a-zA-Z\-_]+\/(exams|share|idshare)\/([a-z0-9A-Z\-_]+)/,
);

const projectIdParseRegexp = new RegExp(/^\/p\/([0-9]+)/);

export function parseApplicantURL(pathname: string) {
  return applicantParseRegexp.exec(pathname);
}

export function getProjectIdFromURL(pathname: string) {
  return projectIdParseRegexp.exec(pathname);
}

/**
 * extract source URL from the state of location
 *
 * it returns pathname if the "from" has "pathname"
 */
export function extractSourceURL(state?: {
  from: string | { pathname: string; search?: string };
}) {
  if (!state || !state.from) {
    return "/";
  }
  if (typeof state.from === "string" || state.from instanceof String) {
    return state.from as string;
  } else {
    return state.from.pathname
      ? state.from.pathname + (state.from.search || "")
      : "/";
  }
}

/**
 * get number value from the URL parameter
 * @param value
 */
export function getNumber(
  value: string | boolean | number | null | undefined,
): number | undefined {
  if (value !== undefined && value !== null && value !== "") {
    const number = Number(value.toString());
    return !isNaN(number) ? number : undefined;
  } else {
    return undefined;
  }
}

/**
 * get number array value from the URL parameter
 * @param value
 */
export function getNumberArray(
  value: string | boolean | number | null | undefined,
): number[] | undefined {
  if (value !== undefined && value !== null && value !== "") {
    const array = value
      .toString()
      .split(",")
      .map((string) => Number(string))
      .filter((number) => !isNaN(number));
    return array.length > 0 ? array : undefined;
  } else {
    return undefined;
  }
}

/**
 * get boolean value from the URL parameter
 * @param value
 */
export function getBoolean(
  value: string | boolean | number | null | undefined,
): boolean | undefined {
  if (value === true || value === "true") {
    return true;
  }
  if (value === false || value === "false") {
    return false;
  }
  return undefined;
}

/**
 * get boolean value as string !! from the URL parameter
 *
 * NOTE: some of form values does not allow to set boolean value...
 * @param value
 */
export function getBooleanString(
  value: string | boolean | number | null | undefined,
): string | undefined {
  if (value === true || value === "true") {
    return "true";
  }
  if (value === false || value === "false") {
    return "false";
  }
  return undefined;
}

/**
 * get string value from the URL parameter
 * @param value
 */
export function getString(
  value: string | boolean | number | null | undefined,
): string | undefined {
  return value !== undefined && value !== null && value !== ""
    ? value.toString()
    : undefined;
}

/**
 * get string array value from the URL parameter
 * @param value
 */
export function getStringArray(
  value: string | boolean | number | null | undefined,
): string[] | undefined {
  if (value !== undefined && value !== null && value !== "") {
    const array = value.toString().split(",");
    return array.length > 0 ? array : undefined;
  } else {
    return undefined;
  }
}

/**
 * get page number from the URL parameter
 * @param value
 */
export function getPageNo(
  value: string | boolean | number | null | undefined,
): number {
  const pageNo = getNumber(value);
  // The Max number should be less than 2147483647 which is the max number of the API side.
  // https://givery-technology.atlassian.net/browse/TRAC-1193
  return pageNo !== undefined && pageNo >= 0 && pageNo <= 9999999 ? pageNo : 0;
}
