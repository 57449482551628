import classNames from "classnames";
import dayjs from "dayjs";
import * as React from "react";

import { useStoreContext } from "@context";

import { Button, Msg, Textarea, RichMarkdown } from "@shared/components";
import {
  useAddSubmissionNote,
  useGuestSubmission,
  useGuestSubmissionMemo,
  useSubmissionNoteList,
} from "@shared/hooks/query";
import { useRouteParams } from "@shared/hooks/query/useRouteParams";
import { SubmissionNoteModel } from "@shared/models";
import { formatDate } from "@shared/services/date";
import Message from "@shared/services/message";

export const SubmissionDetailMemoGuest = () => {
  const { data: note } = useGuestSubmissionMemo();

  const { data: submission } = useGuestSubmission();

  if (!submission?.guestSharingSettings?.showNote) {
    return null;
  }

  return <SubmissionDetailMemoComponent note={note} isGuestPage />;
};

export const SubmissionDetailMemo = () => {
  const { projectId } = useStoreContext();
  const { submissionId } = useRouteParams();
  const { data: noteList } = useSubmissionNoteList(projectId, submissionId);

  const note = noteList?.noteHistory?.result?.[0];

  return <SubmissionDetailMemoComponent note={note} />;
};

interface SubmissionDetailMemoComponentProps {
  isGuestPage?: boolean;
  note?: SubmissionNoteModel;
}

const SubmissionDetailMemoComponent = ({
  isGuestPage,
  note,
}: SubmissionDetailMemoComponentProps) => {
  const [contents, setContents] = React.useState("");

  React.useEffect(() => {
    if (typeof note?.content !== "undefined") {
      setContents(note.content);
    }
  }, [note?.content]);

  const { mutate: addSubmission } = useAddSubmissionNote();

  const [isEditting, setIsEditting] = React.useState(false);
  const noteRef = React.useRef<HTMLDivElement | null>(null);
  const [showSeeMore, setShowSeeMore] = React.useState(true);
  const [isOverflowing, setIsOverflowing] = React.useState(false);

  React.useLayoutEffect(() => {
    if (noteRef.current) {
      setIsOverflowing(
        noteRef.current.scrollHeight > noteRef.current.clientHeight,
      );
    }
  }, [
    isEditting,
    noteRef.current?.clientHeight,
    noteRef.current?.scrollHeight,
  ]);

  return (
    <div className="code-submission-detail-memo">
      <div className="code-submission-detail-memo__label">
        <Msg id="submission.submissionDetail.memo.note" tagName={"span"} />
      </div>

      <div>
        {!isEditting && (
          <>
            <div
              className={classNames("code-submission-detail-memo__add-notes", {
                "code-submission-detail-memo__add-notes__empty": !contents,
                "limited-height": showSeeMore,
                editable: !isGuestPage,
              })}
              onClick={isGuestPage ? undefined : () => setIsEditting(true)}
              ref={noteRef}
              aria-label="memo-label"
            >
              <RichMarkdown
                preview
                className="code-submission-detail-memo__markdown"
                value={
                  contents ||
                  Message.getMessageByKey(
                    isGuestPage
                      ? "submission.submissionDetail.memo.guestNoMemo"
                      : "submission.submissionDetail.memo.addNotes",
                  )
                }
              />

              {showSeeMore && isOverflowing && (
                <div className="code-submission-detail-memo__add-notes__fade"></div>
              )}
            </div>
            {showSeeMore && isOverflowing && (
              <Button
                outlined
                size="small"
                className="code-submission-detail-memo__add-notes__see-more"
                onClick={() => setShowSeeMore(false)}
              >
                <Msg id="submission.submissionDetail.memo.seeMore" />
              </Button>
            )}
          </>
        )}
        {isEditting && (
          <div>
            <Textarea
              className="code-submission-detail-memo__text-area"
              placeholder={Message.getMessageByKey(
                "submission.submissionDetail.memo.addNotes",
              )}
              value={contents}
              onChange={(e) => setContents(e.currentTarget.value)}
              ariaLabel="memo-textarea"
            />
            <div className="code-submission-detail-memo__buttons">
              <Button
                size="small"
                shrink
                type="primary"
                ariaLabel="memo-save"
                disabled={!!note?.content && contents === note?.content}
                onClick={() => {
                  setIsEditting(false);
                  addSubmission({
                    data: {
                      content: contents.trim(),
                    },
                  });
                }}
              >
                <Msg id="save" />
              </Button>
              <Button
                size="small"
                shrink
                onClick={() => {
                  setContents(note?.content ?? "");
                  setIsEditting(false);
                }}
              >
                <Msg id="cancel" />
              </Button>
            </div>
          </div>
        )}
      </div>

      {/* show memo if author exists */}
      {/* do not show contents if guest page and has no contents */}
      {!isEditting && note?.authorInfo && !(isGuestPage && !contents) && (
        <span
          className={classNames("code-submission-detail-memo__last-updated")}
        >
          {Message.getMessageByKey(
            "submission.submissionDetail.memo.lastUpdatedBy",
          )}
          : {note.authorInfo.name},{" "}
          {formatDate(dayjs(note.createdAt).toISOString())}
        </span>
      )}
    </div>
  );
};
