import * as classnames from "classnames";
import { isEqual } from "lodash";
import * as React from "react";

import {
  Msg,
  Form,
  Table,
  TableBody,
  Pagination,
  Input,
} from "@shared/components";
import { useExam, useSubmissionList } from "@shared/hooks/query";
import {
  SubmissionListModel,
  MultiManageForm,
  PaginationModel,
} from "@shared/models";
import { SortOrderItem } from "@shared/models";
import { isValidStatus } from "@shared/services/challengeStatus";
import {
  ApplicantExamStatus,
  SortDirection,
  SubmissionListKind,
} from "@shared/services/enums";
import Message from "@shared/services/message";

import {
  SubmissionListWithResultsTableHeader,
  SubmissionListWithResultsTableRow,
} from ".";

export type MultipleManageModalSubmissionsProps = {
  formValues: MultiManageForm;
  isShow: boolean;
  submissionListKind: SubmissionListKind;
  onFormChange: (valid: boolean, value: MultiManageForm, errors: {}) => void;
};

/**
 * Component
 * @param props MultipleManageModalSubmissionsProps
 */
export function MultipleManageModalSubmissions({
  formValues: { action, version, challenge, submissions },
  isShow,
  submissionListKind,
  onFormChange,
}: MultipleManageModalSubmissionsProps) {
  const rootStyle = classnames("form-group-container", {
    "is-show": isShow,
  });
  const modalBodyRef = React.useRef<HTMLDivElement | null>(null);

  /**
   * States
   */
  const [params, setParams] = React.useState<{
    keyword: string;
    page: number | undefined;
    sortOrder: SortOrderItem;
  }>({
    keyword: "",
    page: undefined,
    sortOrder: {
      column: "status",
      direction: SortDirection.Asc,
    },
  });
  const [pagination, setPagination] = React.useState(new PaginationModel());
  const [selectedSubmissions, setSelectedSubmissions] = React.useState<
    SubmissionListModel[]
  >([]);

  const validStatus = (submission: SubmissionListModel) =>
    isValidStatus({
      submission,
      action,
      version,
      challenge,
    });

  /**
   * Effects
   */
  let submissionStatus: ApplicantExamStatus | undefined =
    ApplicantExamStatus.InReview;
  switch (submissionListKind) {
    case SubmissionListKind.all:
      submissionStatus = undefined;
      break;
    case SubmissionListKind.inprogress:
      submissionStatus = ApplicantExamStatus.InProgress;
      break;
    case SubmissionListKind.submitted:
      submissionStatus = ApplicantExamStatus.Submitted;
      break;

    default:
      break;
  }

  const {
    data: { examDetail },
  } = useExam();
  const { data } = useSubmissionList(
    {
      ...params,
      status: submissionStatus,
      ...(submissionListKind === SubmissionListKind.all && {
        excludedStatuses: ApplicantExamStatus.SubmissionModalExcludeStatuses,
      }),
      ...(params.page && { offset: pagination.getAtPage(params.page).offset }),
      examId: examDetail.id,
      includeResults: true,
      isTest: false,
    },
    examDetail.applicantNameRequired,
  );
  const { submissionList = [], submissionPagination = new PaginationModel() } =
    data || {};
  React.useEffect(() => {
    if (!isEqual(pagination, submissionPagination)) {
      setPagination(submissionPagination);
    }
  }, [pagination, submissionListKind, submissionPagination]);

  React.useEffect(() => {
    if (!submissions) {
      setSelectedSubmissions([]);
    }
  }, [submissions]);

  /**
   * Private Functions
   */
  const onSearchChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { value: keyword } = e.currentTarget;
    setParams({ ...params, keyword, page: undefined });
  };

  const handleSortChange = (sortOrder: SortOrderItem) => {
    setParams({ ...params, sortOrder, page: undefined });
  };

  const handlePageChange = ({ selected }: { selected: number }) => {
    setParams({ ...params, page: selected });
    const scrollParent = document.querySelector(".modal-card-body");
    if (scrollParent) {
      scrollParent.scrollTo(0, 0);
    }
  };

  const onClickSubmissionAll = (checked: boolean) => {
    const addedSubmissions = submissionList.reduce(
      (all, current) =>
        all?.find((submission) => submission.id === current.id) ||
        !validStatus(current).isValid
          ? all
          : [...all, current],
      [...selectedSubmissions],
    );

    const removedSubmissions = selectedSubmissions.filter(
      (submission) => !submissionList.some((s) => s.id === submission.id),
    );
    const nextSubmissions = checked ? addedSubmissions : removedSubmissions;

    setSelectedSubmissions(nextSubmissions);
    onFormChange(true, { submissions: nextSubmissions }, {});
  };

  /**
   * Render
   */
  return (
    <div className={rootStyle} ref={modalBodyRef}>
      <div className="code-multiple-manage-modal__table-header">
        <div>
          <p>
            <Msg id="submission.message.selectSubmissions" />
          </p>
          <p>
            <Msg
              id={
                submissionListKind === SubmissionListKind.all
                  ? "submission.message.selectSubmissions.notice"
                  : "submission.message.selectSubmissions.notice2"
              }
              values={{
                submissionStatus:
                  submissionStatus &&
                  ApplicantExamStatus.toString(submissionStatus),
              }}
            />
          </p>
        </div>
        <Input
          className="code-multiple-manage-modal__search"
          value={params.keyword}
          onChange={onSearchChange}
          placeholder={Message.getMessageByKey("common.emailOrName")}
        />
      </div>
      <Form
        validation={{ submissions: ["required"] }}
        initialValues={{ submissions: submissions }}
        onFormChange={onFormChange}
      >
        <Table>
          <SubmissionListWithResultsTableHeader
            sortItem={params.sortOrder}
            isSelected={
              submissionList.find((submission) =>
                selectedSubmissions.find((s) => s.id === submission.id),
              ) &&
              submissionList.every(
                (submission) =>
                  !validStatus(submission).isValid ||
                  selectedSubmissions.find((s) => s.id === submission.id),
              )
            }
            onClickSortItem={handleSortChange}
            onClickSubmissionAll={onClickSubmissionAll}
            readOnly={submissionList.every(
              (submission) => !validStatus(submission).isValid,
            )}
          />
          <TableBody>
            {submissionList.map((submission) => (
              <SubmissionListWithResultsTableRow
                key={submission.id}
                challenge={challenge}
                submission={submission}
                isSelected={
                  !!selectedSubmissions?.find(
                    (selectedSubmission) =>
                      selectedSubmission.id === submission.id,
                  )
                }
                isValidStatus={validStatus(submission)}
                onClickSubmission={(submissionId, checked) => {
                  const nextSubmissions = checked
                    ? [...selectedSubmissions, submission]
                    : selectedSubmissions.filter(
                        (submission) => submission.id !== submissionId,
                      );
                  setSelectedSubmissions(nextSubmissions);
                  onFormChange(true, { submissions: nextSubmissions }, {});
                }}
                action={action}
              />
            ))}
          </TableBody>
        </Table>
      </Form>
      <div className="code-multiple-manage-modal__pagination">
        <Pagination
          pagination={submissionPagination}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  );
}
