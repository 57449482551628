import { GridLineOptions } from "c3";
import * as classnames from "classnames";

import { TimeExtension } from "@shared/models";
import { formatDateTimeSeconds } from "@shared/services/date";
import Message from "@shared/services/message";

import { HistoryGraphItem } from "../../HistoryGraph";

/**
 * Interface
 */
interface HistoryGraphGridsModel {
  value: string | number;
  text: string;
  class: string;
  position: GridLineOptions["position"];
}

interface HistoryGraphGridsProps {
  submittedAt?: string;
  timeExtensions: TimeExtension[];
}

export const getXgrids = ({
  submittedAt,
  timeExtensions,
}: HistoryGraphGridsProps): HistoryGraphGridsModel[] => {
  const xgrids = [];

  const baseXgridForm = (date: string, className: string, label: string) => {
    const dateString = formatDateTimeSeconds(date);
    const xgridStyle = classnames("code-c-history-graph__region-line-x", {
      [`${className}`]: Boolean(className),
    });
    return {
      value: dateString,
      text: `${Message.getMessageByKey(label)} - ${dateString}`,
      class: xgridStyle,
      position: "start" as const,
    };
  };

  if (submittedAt) {
    xgrids.push(baseXgridForm(submittedAt, "submitted-at", "submittedAt"));
  }
  if (timeExtensions.length) {
    timeExtensions.forEach((extention) => {
      xgrids.push(
        baseXgridForm(extention.createdAt, "extended-at", "extendedAt"),
      );
    });
  }
  return xgrids;
};

export const getYgrids = ({
  scoreMax,
  items,
}: {
  scoreMax: number;
  items: HistoryGraphItem[];
}): HistoryGraphGridsModel[] => {
  const highestScore = Math.max(...items.map((item) => item.value));
  const isGotPerfectScore = scoreMax === highestScore;
  const ygridStyle = classnames("code-c-history-graph__region-line-y", {
    ["is-perfect-score"]: Boolean(isGotPerfectScore),
  });

  return [
    {
      value: scoreMax,
      text: Message.getMessageByKey("report.perfectScore"),
      class: ygridStyle,
      position: "start",
    },
  ];
};
