import { Msg, QuickHelp, Tooltip } from "@shared/components";
import { ApplicantReportModel } from "@shared/models";

export function ScoreSummary({
  applicantReportDetails,
  disableScoreTooltip,
}: {
  applicantReportDetails: ApplicantReportModel;
  disableScoreTooltip?: boolean;
}) {
  const {
    finalScore: applicantFinalScore,
    rank,
    submissionsNumber: totalApplicants,
    finalScoreDevValue,
    submissionsAvgScore,
    submissionsStdDev,
  } = applicantReportDetails;

  return (
    <div className="score-summary">
      <div className="score-summary-boxes">
        <div className="score-summary-box">
          <div className="label">
            <Msg id="report.totalScore" />
          </div>
          <div className="result-value">
            {applicantFinalScore}
            <span className="result-suffix">
              <Msg id="report.points" />
            </span>
          </div>
          <div>
            <Tooltip
              text={
                <Msg id="submission.submissionDetail.deviationScore.tooltip" />
              }
              disabled={disableScoreTooltip}
              maxSize="medium"
              wrapperStyle={{ display: "block" }}
            >
              <>
                <Msg id="report.deviationScore" /> {finalScoreDevValue}
              </>
            </Tooltip>
          </div>
        </div>
        <div className="score-summary-box">
          <div className="label">
            <Msg id="report.ranking" />
          </div>
          <div className="result-value">
            {rank}
            <span className="result-suffix">
              <Msg id="report.rank" />
            </span>
          </div>
          <div>
            <Msg id="report.outOfPeople" values={{ "0": totalApplicants }} />
          </div>
        </div>
      </div>
      <div className="score-stats">
        <div className="score-stats-row">
          <Msg id="report.average" />
          <span>
            {submissionsAvgScore}
            <Msg tagName="none" id="report.points" />
          </span>
        </div>
        <div className="score-stats-row">
          <span>
            <Msg tagName="none" id="report.std" />
            <QuickHelp
              text={
                <Msg id="submission.submissionDetail.stdDeviation.tooltip" />
              }
              placement="right"
              maxSize="medium"
            />
          </span>
          <span>
            {submissionsStdDev}
            <Msg tagName="none" id="report.points" />
          </span>
        </div>
      </div>
    </div>
  );
}
