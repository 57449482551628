import * as classnames from "classnames";
import * as React from "react";

import {
  Modal,
  Form,
  FormGroup,
  Label,
  Radio,
  Msg,
  Tooltip,
  Icon,
  Checkbox,
  Select,
  SelectItem,
  CheckboxGroup,
  Button,
  JumpTo,
} from "@shared/components";
import {
  CsvDownloadForm,
  useDidUpdateEffect,
  useSubmissionDownloadSetting,
  useTierActionPermissions,
} from "@shared/hooks";
import {
  CsvColumnGroupModel,
  CsvColumnsGroupsByCsvTypeModel,
  CsvTypesModel,
  ExamChallengeModel,
  ExamModel,
  LoadingCsvColumnGroupsModel,
} from "@shared/models";
import { CsvTypes, Encoding, Tier } from "@shared/services/enums";
import {
  hasChallengeHistory,
  hasCodingChallenge,
  hasQuizChallenge,
} from "@shared/services/exam";
import Message from "@shared/services/message";

/**
 * Prop interface
 */
export interface SubmissionDownloadModalProps {
  className?: string;
  isOpen?: boolean;
  examChallenges: ExamChallengeModel[];
  examDetail: ExamModel;
  hasEnhancedCSVDownloadAction: boolean;
  onCloseModal: () => void;
  onClickOk: (
    type: string,
    challengeIds: number[],
    columns: number[],
    encoding: Encoding,
    language: string,
    includeEntryForm: boolean,
  ) => void;
}

type Props = SubmissionDownloadModalProps & {
  getCsvColumns: (csvType: CsvTypes) => void;
  loadingCsvColumnGroups?: LoadingCsvColumnGroupsModel;
  csvColumnGroupsByCsvType?: CsvColumnsGroupsByCsvTypeModel;
  csvTypes: CsvTypesModel[];
  organizationTier?: Tier;
};

interface State {
  formValues: CsvDownloadForm;
}

type CsvTypeOption = Pick<CsvTypesModel, "icon" | "label" | "description"> & {
  disabled?: boolean;
  labelClassName: string;
  key: string;
  tooltip: string;
  value: string;
};

const EncodingOptions: SelectItem[] = [
  { label: Encoding.toString(Encoding.UTF_8), value: Encoding.UTF_8 },
  { label: Encoding.toString(Encoding.MS932), value: Encoding.MS932 },
];

export default function SubmissionDownloadModal({
  className,
  examChallenges,
  examDetail,
  isOpen,
  hasEnhancedCSVDownloadAction,
  csvTypes = [],
  loadingCsvColumnGroups,
  csvColumnGroupsByCsvType,
  onClickOk,
  onCloseModal,
  getCsvColumns,
}: Props) {
  const defaultFormState: CsvDownloadForm = {
    type: CsvTypes.Basic.toString(),
    encoding: Encoding.UTF_8,
    language: "en",
    allChallenges: true,
    challenges: Array(examChallenges.length).fill(true),
    challengeIds: {},
    saveSettings: false,
    columnSelectionMode: "all",
    columnGroups: {},
    columns: [],
    includeEntryForm: true,
  };
  const { id: examId } = examDetail;
  const { canDownloadCsv, canDownloadHistoryCsv, canDownloadReviewCsv } =
    useTierActionPermissions();
  const [setting, setSetting, removeSetting] =
    useSubmissionDownloadSetting(examId);
  const isMounted = React.useRef(true);
  const languageOptions = React.useMemo<SelectItem[]>(
    () => [
      { label: Message.getMessageByKey("ja"), value: "ja" },
      { label: Message.getMessageByKey("en"), value: "en" },
    ],
    [],
  );
  const rootStyle = classnames("code-submission-download-modal", className);
  const disabledTooltip = Message.getMessageByKey("tier.disabled");
  const challengeCheckAllLabel = Message.getMessageByKey(
    "submission.challenge.all",
  );
  const [formState, setFormState] = React.useState<State>(() => {
    const state = { formValues: defaultFormState };
    if (!setting) {
      return state;
    }

    state.formValues = {
      ...setting,
      // Reflect all challenges checkbox when challenge list had been updated (add/remove)
      allChallenges:
        Object.keys(setting.challengeIds).length >= examChallenges.length,
      challenges: examChallenges.map(({ id }) =>
        Boolean(setting.challengeIds?.[id]),
      ),
    };

    return state;
  });
  const challengeOptions = React.useMemo<string[]>(
    () => examChallenges.map(({ title }) => title),
    [examChallenges],
  );
  const selectedCsvType = formState.formValues.type;
  const csvColumnGroups: CsvColumnGroupModel[] | undefined =
    csvColumnGroupsByCsvType?.[selectedCsvType];
  const isColumnGroupsLoading = Boolean(
    loadingCsvColumnGroups?.[selectedCsvType],
  );
  const isSupportChallengeFilter =
    formState.formValues.type === CsvTypes.Basic.toString();
  const csvTypeOptions = React.useMemo(
    () =>
      csvTypes.reduce((arr, { description, icon, label, value }) => {
        let isValid = false;
        let included = true;

        switch (value) {
          case CsvTypes.Basic:
            isValid = canDownloadCsv;
            break;
          case CsvTypes.EnhancedCodingCSV:
            if (!hasEnhancedCSVDownloadAction) {
              break;
            }
            isValid = true;
            included = hasCodingChallenge(examDetail);
            break;
          case CsvTypes.EnhancedQuizCSV:
            if (!hasEnhancedCSVDownloadAction) {
              break;
            }
            isValid = true;
            included = hasQuizChallenge(examDetail);
            break;
          case CsvTypes.ReviewCsv:
            isValid = canDownloadReviewCsv;
            break;
          case CsvTypes.ScoreHistoryCsv:
            if (!canDownloadHistoryCsv) {
              break;
            }
            isValid = true;
            included = hasChallengeHistory(examDetail);
            break;
          default:
            break;
        }

        const labelClassName = classnames(
          "code-submission-download-modal__radio",
          {
            "is-clickable": isValid,
          },
        );

        if (included) {
          arr.push({
            description,
            disabled: isValid,
            key: `${label}-${value}`,
            icon,
            label,
            labelClassName,
            tooltip: disabledTooltip,
            value: `${value}`,
          });
        }

        return arr;
      }, [] as CsvTypeOption[]),
    [
      canDownloadCsv,
      canDownloadHistoryCsv,
      canDownloadReviewCsv,
      csvTypes,
      disabledTooltip,
      examDetail,
      hasEnhancedCSVDownloadAction,
    ],
  );
  const isColumnSelected = formState.formValues.columns.some(Boolean);
  const enableDownload = React.useMemo(() => {
    // Check if the selected CSV type is disabled
    // formValues.type couldn't be used as index because csbTypeOptions is filtered out when no matched challenge
    if (
      !csvTypeOptions?.find(({ value }) => formState.formValues.type === value)
        ?.disabled
    ) {
      return false;
    }
    // Check if Both challenges and columns are selected
    return (
      formState.formValues.challenges.some(Boolean) &&
      (isColumnSelected || formState.formValues.columnSelectionMode === "all")
    );
  }, [
    csvTypeOptions,
    formState.formValues.challenges,
    formState.formValues.columnSelectionMode,
    formState.formValues.type,
    isColumnSelected,
  ]);

  const handleClickOk = () => {
    const {
      type,
      allChallenges,
      columnSelectionMode,
      columns,
      encoding,
      language,
      saveSettings,
      includeEntryForm,
    } = formState.formValues;
    let challengeIds: number[] = [];
    let columnIds: number[] = [];

    if (!allChallenges) {
      challengeIds = examChallenges.reduce<number[]>(
        (acc, { challengeId }, index) => {
          if (formState.formValues.challenges[index]) {
            acc.push(challengeId);
          }
          return acc;
        },
        [],
      );
    }

    if (columnSelectionMode !== "all") {
      // create columns filter
      columnIds = columns.reduce<number[]>((acc, isChecked, index) => {
        if (isChecked) {
          acc.push(index);
        }
        return acc;
      }, []);
    }

    if (saveSettings) {
      // Support challenge reordering
      formState.formValues.challengeIds = examChallenges.reduce(
        (acc, { id }, index) => {
          if (formState.formValues.challenges[index]) {
            acc[id] = true;
          }
          return acc;
        },
        {},
      );
      setSetting(formState.formValues);
    } else {
      removeSetting();
    }
    if (onClickOk) {
      onClickOk(
        CsvTypes.toApiType(+type),
        challengeIds,
        columnIds,
        encoding,
        language,
        includeEntryForm,
      );
    }
  };

  const handleFormChange = (
    formValid: boolean,
    formValues: CsvDownloadForm,
  ) => {
    setFormState({ formValues });
  };

  const handleChallengeChange = (
    isChecked: boolean,
    childCheckboxes: boolean[],
  ) => {
    setFormState((prevState) => {
      const newState = {
        ...prevState.formValues,
        allChallenges: isChecked,
        challenges: childCheckboxes,
      };

      return {
        formValues: newState,
      };
    });
  };

  const handleColumnChange = (
    groupKey: string,
    isGroupChecked: boolean,
    columnCheckboxes: boolean[],
  ) => {
    setFormState((prevState) => {
      const newState = { ...prevState };
      newState.formValues.columnGroups[groupKey] = isGroupChecked;

      const columns = csvColumnGroups?.find(
        ({ groupKey: key }) => key === groupKey,
      )?.columns;

      newState.formValues.columns =
        columns?.reduce(
          (arr, { value }, index) => {
            arr[value] = columnCheckboxes[index];
            return arr;
          },
          [...newState.formValues.columns],
        ) ?? [];

      return newState;
    });
  };

  // handle csv type updated
  useDidUpdateEffect(() => {
    setFormState(({ formValues }) => {
      const { allChallenges, challenges, columnSelectionMode } =
        defaultFormState;
      return {
        formValues: {
          ...formValues,
          allChallenges,
          challenges,
          columnSelectionMode,
        },
      };
    });
  }, [formState.formValues.type]);

  // Fetch column groups based on selected csv type
  // Only if they haven't been fetched before
  React.useEffect(() => {
    if (!isColumnGroupsLoading && csvColumnGroups === undefined) {
      getCsvColumns(parseInt(formState.formValues.type) as CsvTypes);
    }
  }, [
    csvColumnGroups,
    formState.formValues.type,
    getCsvColumns,
    isColumnGroupsLoading,
  ]);

  React.useEffect(() => {
    if (csvColumnGroups === undefined) {
      return;
    }
    // initialize columns and column groups selection
    if (isMounted.current && csvColumnGroups) {
      isMounted.current = false;

      // if columns are already selected, don't overwrite them
      if (setting?.columns?.length > 0) {
        return;
      }
    }

    setFormState((prevState) => {
      const newState: CsvDownloadForm = {
        ...prevState.formValues,
        columnGroups: {},
        columns: [],
      };

      csvColumnGroups.forEach(({ groupKey, columns }) => {
        columns.forEach(({ value }) => {
          newState.columns[value] = true;
        });

        newState.columnGroups[groupKey] = true;
      });

      return {
        formValues: newState,
      };
    });
  }, [csvColumnGroups, setting?.columns]);

  return (
    <Modal
      className={rootStyle}
      contentClassName="code-submission-download-modal__body"
      title={Message.getMessageByKey("button.csvDownload")}
      isOpen={isOpen}
      disableOk={!enableDownload}
      onClose={onCloseModal}
      onClickCancel={onCloseModal}
      onClickOk={handleClickOk}
      okButtonLabel={Message.getMessageByKey("button.download")}
      okButtonAriaLabel="Download"
      ariaLabel="Download CSV"
    >
      <Form
        validation={{
          type: ["string", "required"],
          encoding: ["string", "required"],
          language: ["string", "required"],
        }}
        initialValues={formState.formValues}
        onFormChange={handleFormChange}
      >
        <FormGroup>
          <Label className="code-submission-download-modal__field-label">
            <Msg id="common.dataType" />
          </Label>
          {csvTypeOptions.map(
            ({
              description,
              disabled,
              icon,
              key,
              label,
              labelClassName,
              tooltip,
              value,
            }) => (
              /** TODO: attribute disabled might confuse us in the future since tooltip disabled and radio readonly had opposite meaning */
              <Tooltip key={key} text={tooltip} disabled={disabled}>
                <Radio
                  readOnly={!disabled}
                  name="type"
                  defaultValue={value}
                  labelClassName={labelClassName}
                  centered
                >
                  <div className="code-submission-download-modal__radio-label">
                    <div className="code-submission-download-modal__radio-label-icon">
                      <Icon type={icon} size="smedium" />
                    </div>
                    <div>
                      <p className="code-submission-download-modal__radio-title">
                        {label}
                      </p>
                      <p className="code-submission-download-modal__radio-subtitle">
                        {description}
                      </p>
                    </div>
                  </div>
                </Radio>
              </Tooltip>
            ),
          )}
        </FormGroup>
        {isSupportChallengeFilter && (
          <FormGroup>
            <Label className="code-submission-download-modal__field-label">
              <Msg id="common.challenges" />
            </Label>
            {challengeOptions.length === 0 ? (
              <p>No challenges</p>
            ) : (
              <CheckboxGroup
                ariaName="allChallenges"
                checkAllLabel={challengeCheckAllLabel}
                checkAllValue={formState.formValues.allChallenges}
                options={challengeOptions}
                optionName="challenges"
                values={formState.formValues.challenges}
                onChange={handleChallengeChange}
              />
            )}
          </FormGroup>
        )}
        <FormGroup>
          <Label className="code-submission-download-modal__field-label">
            <Msg id="common.columns" />
          </Label>
          <Radio
            className="code-submission-download-modal__columns-radio"
            name="columnSelectionMode"
            defaultValue="all"
          >
            <Msg id="submission.csv.downloadAllColumns" />
          </Radio>
          <Radio
            className="code-submission-download-modal__columns-radio"
            name="columnSelectionMode"
            defaultValue="selected"
          >
            <Msg id="submission.csv.downloadSelectedColumns" />
          </Radio>
          {formState.formValues.columnSelectionMode === "selected" && (
            <div className="code-submission-download-modal__selected-columns">
              {csvColumnGroups?.map(({ groupKey, groupString, columns }) => (
                <CheckboxGroup
                  key={groupKey}
                  ariaName={groupString}
                  checkAllLabel={groupString}
                  checkAllValue={formState.formValues.columnGroups[groupKey]}
                  options={columns.map((column) => column.displayString)}
                  optionName={groupString}
                  values={columns.map(({ value }) => {
                    const columnSelectionValues = formState.formValues.columns;
                    return Boolean(columnSelectionValues[value]);
                  })}
                  onChange={(isChecked, childCheckboxes) =>
                    handleColumnChange(groupKey, isChecked, childCheckboxes)
                  }
                />
              ))}
              {formState.formValues.type === `${CsvTypes.Basic}` && (
                <Checkbox name="includeEntryForm" readOnly={!isColumnSelected}>
                  <Msg id="submission.entryFormColumn" />
                </Checkbox>
              )}
            </div>
          )}
          <JumpTo
            className="code-submission-download-modal__learn-more"
            to="https://givery.notion.site/CSV-5b866bb54ba84d12ae75dd3e7511f220"
          >
            <Button disabled={false} size="small">
              <Icon type="question-circle" />
              <Msg id="submission.csv.learnMore" />
            </Button>
          </JumpTo>
        </FormGroup>
        <div className="code-submission-download-modal__field">
          <FormGroup>
            <Label className="code-submission-download-modal__field-label">
              <Msg id={"common.encodingType"} />
            </Label>
            <Select
              name="encoding"
              options={EncodingOptions}
              innerClassName="code-submission-download-modal__field-select"
            />
          </FormGroup>
          <FormGroup>
            <Label className="code-submission-download-modal__field-label">
              <Msg id={"common.headerLanguage"} />
            </Label>
            <Select
              name="language"
              options={languageOptions}
              innerClassName="code-submission-download-modal__field-select"
            />
          </FormGroup>
        </div>
        <Checkbox className="is-flex" name="saveSettings">
          <Msg id="submission.saveSettings" />
        </Checkbox>
      </Form>
    </Modal>
  );
}
