import { connect } from "react-redux";

import { RootState } from "@reducers/index";

import { TierAction } from "@shared/services/enums";
import { getTierAllowedByRedux } from "@shared/services/tier";

import { ExamDetailRoute, ExternalProps } from "./ExamDetail.route";

const mapStateToProps = (state: RootState, props: ExternalProps) => {
  const hasTiers = !!state.orgs.tiers?.length;

  return {
    shouldRenderCodeDiffRoute:
      !hasTiers || // wait for tier to be loaded first to avoid race condition
      getTierAllowedByRedux(state)(TierAction.CodeDiff),
  };
};

export default connect(mapStateToProps, () => ({}))(ExamDetailRoute);
