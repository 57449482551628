/**
 * Retrieves the function names of a type T
 */
type FunctionPropertyNames<T> = {
  // eslint-disable-next-line @typescript-eslint/ban-types
  [K in keyof T]: T[K] extends Function ? K : never;
}[keyof T];

type RequiredField<T, Field extends keyof T> = Omit<T, Field> &
  Required<Pick<T, Field>>;
/**
 * Returns the type T without the functions
 */
export type StripFunctions<T> = Omit<T, FunctionPropertyNames<T>>;

export function areFieldsDefined<T extends {}, U extends Array<keyof T>>(
  obj: T,
  fields: U,
): obj is RequiredField<T, U[number]> {
  return fields.every((field) => obj[field] !== undefined);
}
