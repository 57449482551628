import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { isEmpty } from "lodash";
import { toast } from "react-toastify";

import {
  deleteApplicantScheduleDelivery,
  deleteScheduleDeliveryExamByMail,
  getExamDeliveryTemplate,
  getExamMailDelivery,
  getExamMailDeliveryDetail,
  getExamScheduledMailDelivery,
  postDeliverExamByMail,
  postDeliverOfficialExamSample,
  postDeliverSampleExamByEmail,
  postScheduleDeliverExamByMail,
  updateExamDelivery,
  updateScheduleExamDelivery,
} from "@api/exams";
import { BaseSearchQuery, Pagination } from "@api/httpClient";

import { useStoreContext } from "@context";

import {
  MailDeliveryListModel,
  MailDeliveryModel,
  MailDeliveryType,
  PaginationModel,
} from "@shared/models";
import { getLastWeekTimestamp } from "@shared/services/date";
import history from "@shared/services/history";
import Message from "@shared/services/message";

import { submissionKeys } from "../submissions";
import { useRouteParams } from "../useRouteParams";
import { useInvalidateExamRelation } from "./useExam";

interface UseDeliverEmailPayload {
  applicants?: { email: string }[];
  deadline: string;
  language?: string;
  mailSubject: string;
  mailBody: string;
  senderName?: string;
}

interface UseScheduleDeliverMailPayload extends UseDeliverEmailPayload {
  scheduledFor: string;
  startAt: string;
}

interface UseDeliverOfficialExamPayload
  extends Omit<UseDeliverEmailPayload, "applicants"> {
  applicants: string;
  noTransition?: boolean;
}

interface UseDeliverParams<T = UseDeliverEmailPayload> {
  examId: number;
  data: T;
}

interface UseDeliverUpdateParams<T = UseDeliverEmailPayload>
  extends UseDeliverParams<T> {
  deliveryId: number;
}

interface UpdateMailDeliveryParams {
  deliveryId: number;
  orgName: string;
  data: { deadline: string };
}

type DeleteScheduleDeliveryParams = Omit<UseDeliverUpdateParams, "data">;

interface DeleteApplicantScheduleDeliveryParams
  extends Omit<UseDeliverParams, "data"> {
  applicantExamId: number;
}

interface ExamDeliveryKeys {
  projectId: number;
  examId?: number;
}

interface ExamDeliveryListKeys extends ExamDeliveryKeys {
  filters?: Omit<BaseSearchQuery, "keyword">;
  type?: MailDeliveryType;
}

interface ExamMailDeliveryDetailKeys extends ExamDeliveryKeys {
  deliveryId: number;
}

export const examMailDeliveryKeys = {
  all: ["exam", "mailDelivery"] as const,
  deliveryListAll: () => [...examMailDeliveryKeys.all, "list"] as const,
  deliveryList: ({
    examId,
    filters,
    projectId,
    type,
  }: ExamDeliveryListKeys) => {
    const key = [...examMailDeliveryKeys.deliveryListAll(), projectId];
    if (!examId) {
      return key;
    }
    if (!type) {
      return [...key, examId];
    }
    if (isEmpty(filters)) {
      return [...key, examId, type];
    }

    // final query key would be: ["exam","mailDelivery",projectId,examId,type]
    return [...key, examId, type, { filters }];
  },
  mailDeliveryDetailAll: () =>
    [...examMailDeliveryKeys.all, "mailDetail"] as const,
  mailDeliveryDetailProject: ({
    projectId,
  }: Pick<ExamDeliveryKeys, "projectId">) =>
    [...examMailDeliveryKeys.mailDeliveryDetailAll(), projectId] as const,
  mailDeliveryDetail: ({ deliveryId, ...rest }: ExamMailDeliveryDetailKeys) =>
    [
      ...examMailDeliveryKeys.mailDeliveryDetailProject(rest),
      deliveryId,
    ] as const,
  templateAll: () => [...examMailDeliveryKeys.all, "template"] as const,
  templateList: (projectId: number) =>
    [...examMailDeliveryKeys.templateAll(), projectId] as const,
  template: (projectId: number, examId: number) =>
    [...examMailDeliveryKeys.templateList(projectId), examId] as const,
};

export function useExamMailDelivery(
  filters: Omit<BaseSearchQuery, "keyword">,
  type: MailDeliveryType = "history",
) {
  const { projectId } = useStoreContext();
  const { examId } = useRouteParams();
  const enabled = Boolean(examId && projectId);

  const query = useQuery({
    queryKey: examMailDeliveryKeys.deliveryList({
      projectId,
      examId,
      filters,
      type,
    }),
    queryFn: async ({ signal }) => {
      const params = {
        ...filters,
      };
      const res = await (type === "history"
        ? getExamMailDelivery
        : getExamScheduledMailDelivery)({
        examId,
        projectId,
        options: { params, signal },
      });

      return res;
    },
    enabled,
    initialData: {
      result: [] as MailDeliveryListModel[],
      pagination: new PaginationModel(),
    } as Pagination<MailDeliveryListModel[]>,
    initialDataUpdatedAt: getLastWeekTimestamp(),
    select: (data) => {
      return {
        data: data.result,
        pagination: new PaginationModel(data.pagination),
      };
    },
  });

  return query;
}

export function useExamMailDeliveryDetail(deliveryId: number) {
  const { projectId } = useStoreContext();
  const { examId } = useRouteParams();
  const enabled = Boolean(examId && projectId && deliveryId);

  const query = useQuery({
    queryKey: examMailDeliveryKeys.mailDeliveryDetail({
      examId,
      projectId,
      deliveryId,
    }),
    queryFn: async ({ signal }) => {
      const { result } = await getExamMailDeliveryDetail({
        deliveryId,
        examId,
        projectId,
        options: { signal },
      });

      return result;
    },
    enabled,
    initialData: {} as MailDeliveryModel,
    initialDataUpdatedAt: getLastWeekTimestamp(),
  });

  return query;
}

export function useExamDeliveryTemplate() {
  const { projectId } = useStoreContext();
  const { examId } = useRouteParams();
  const enabled = Boolean(examId && projectId);

  const query = useQuery({
    queryKey: examMailDeliveryKeys.template(projectId, examId),
    queryFn: async ({ signal }) => {
      const { result } = await getExamDeliveryTemplate({
        examId,
        projectId,
        options: { signal },
      });
      return result;
    },
    enabled,
    initialData: {
      template: "",
      subject: "",
      body: "",
    },
    initialDataUpdatedAt: getLastWeekTimestamp(),
  });

  return query;
}

export function useDeliverMail() {
  const client = useQueryClient();
  const { projectId } = useStoreContext();
  const invalidate = useInvalidateExamRelation();

  const mutation = useMutation({
    mutationFn: ({ examId, data }: UseDeliverParams) =>
      postDeliverExamByMail({ examId, projectId, options: { data } }),
    onSuccess: (_, { examId }) => {
      toast.success(Message.getMessageByKey("message.exam.delivered"));
      invalidate(examId);
      client.invalidateQueries(
        submissionKeys.list(projectId, { examId, keyword: "" }),
      );
      client.invalidateQueries(
        examMailDeliveryKeys.deliveryList({
          projectId,
          examId,
        }),
      );
      history.push({
        pathname: `/p/${projectId}/exams/${examId}/submissions_unread`,
      });
    },
  });

  return mutation;
}

export function useScheduleDeliverMail() {
  const client = useQueryClient();
  const { projectId } = useStoreContext();
  const invalidate = useInvalidateExamRelation();

  const mutation = useMutation({
    mutationFn: ({
      examId,
      data,
    }: UseDeliverParams<UseScheduleDeliverMailPayload>) =>
      postScheduleDeliverExamByMail({ examId, projectId, options: { data } }),
    onSuccess: (_, { examId }) => {
      toast.success(Message.getMessageByKey("message.exam.scheduled"));
      invalidate(examId);
      client.invalidateQueries(
        submissionKeys.list(projectId, { examId, keyword: "" }),
      );
      client.invalidateQueries(
        examMailDeliveryKeys.deliveryList({ examId, projectId }),
      );
      history.push({
        pathname: `/p/${projectId}/exams/${examId}/delivery_scheduled`,
      });
    },
  });

  return mutation;
}

export function useDeliverSampleByEmail() {
  const client = useQueryClient();
  const { projectId } = useStoreContext();
  const invalidate = useInvalidateExamRelation();

  const mutation = useMutation({
    mutationFn: ({ examId, data }: UseDeliverParams) =>
      postDeliverSampleExamByEmail({ examId, projectId, options: { data } }),
    onSuccess: (_, { examId }) => {
      toast.success(Message.getMessageByKey("message.exam.delivered"));
      invalidate(examId);
      client.invalidateQueries(
        submissionKeys.list(projectId, { examId, keyword: "" }),
      );
    },
  });

  return mutation;
}

export function useDeliverOfficialExamSample() {
  const client = useQueryClient();
  const { projectId } = useStoreContext();
  const invalidate = useInvalidateExamRelation();

  const mutation = useMutation({
    mutationFn: ({
      examId,
      data,
    }: UseDeliverParams<UseDeliverOfficialExamPayload>) =>
      postDeliverOfficialExamSample({ examId, projectId, options: { data } }),
    onSuccess: (_, { examId, data: { noTransition } }) => {
      toast.success(Message.getMessageByKey("message.exam.delivered"));
      invalidate(examId);
      client.invalidateQueries(
        submissionKeys.list(projectId, { examId, keyword: "" }),
      );
      if (!noTransition) {
        history.push({
          pathname: `/p/${projectId}/exams/${examId}/submissions_unread`,
        });
      }
    },
  });

  return mutation;
}

export function useUpdateScheduleMailDelivery() {
  const client = useQueryClient();
  const { projectId } = useStoreContext();
  const invalidate = useInvalidateExamRelation();

  const mutation = useMutation({
    mutationFn: ({ deliveryId, examId, data }: UseDeliverUpdateParams) =>
      updateScheduleExamDelivery({
        deliveryId,
        examId,
        projectId,
        options: { data },
      }),
    onSuccess: (_, { deliveryId, examId }) => {
      toast.success(Message.getMessageByKey("message.delivery.update.success"));
      invalidate(examId);
      client.invalidateQueries(
        examMailDeliveryKeys.deliveryList({ examId, projectId }),
      );
      client.invalidateQueries(
        examMailDeliveryKeys.mailDeliveryDetail({
          deliveryId,
          examId,
          projectId,
        }),
      );
      client.invalidateQueries(
        submissionKeys.list(projectId, { examId, keyword: "" }),
      );
    },
    onError: () => {
      toast.error(Message.getMessageByKey("message.delivery.update.failed"));
    },
  });

  return mutation;
}

export function useUpdateMailDelivery() {
  const client = useQueryClient();
  const { projectId } = useStoreContext();
  const invalidate = useInvalidateExamRelation();

  const mutation = useMutation({
    mutationFn: ({ deliveryId, orgName, data }: UpdateMailDeliveryParams) =>
      updateExamDelivery({
        deliveryId,
        orgName,
        projectId,
        options: { data },
      }),
    onSuccess: (res) => {
      const examId = res.result.examId;
      toast.success(Message.getMessageByKey("message.delivery.update.success"));
      invalidate(examId);
      client.invalidateQueries(
        submissionKeys.list(projectId, { examId, keyword: "" }),
      );
      client.invalidateQueries(
        examMailDeliveryKeys.deliveryList({
          examId,
          projectId,
        }),
      );
      client.invalidateQueries(
        examMailDeliveryKeys.mailDeliveryDetailProject({ projectId }),
      );
    },
    onError: () => {
      toast.error(Message.getMessageByKey("message.delivery.update.failed"));
    },
  });

  return mutation;
}

export function useDeleteScheduleMailDelivery() {
  const client = useQueryClient();
  const { projectId } = useStoreContext();
  const invalidate = useInvalidateExamRelation();

  const mutation = useMutation({
    mutationFn: ({ deliveryId, examId }: DeleteScheduleDeliveryParams) =>
      deleteScheduleDeliveryExamByMail({
        deliveryId,
        examId,
        projectId,
      }),
    onSuccess: (_, { examId }) => {
      toast.success(Message.getMessageByKey("message.delivery.cancel.success"));
      invalidate(examId);
      client.invalidateQueries(
        examMailDeliveryKeys.deliveryList({ examId, projectId }),
      );
      client.invalidateQueries(
        submissionKeys.list(projectId, { examId, keyword: "" }),
      );
    },
    onError: () => {
      toast.error(Message.getMessageByKey("message.delivery.cancel.failed"));
    },
  });

  return mutation;
}

export function useDeleteApplicantScheduleDelivery() {
  const client = useQueryClient();
  const { projectId } = useStoreContext();
  const invalidate = useInvalidateExamRelation();

  const mutation = useMutation({
    mutationFn: ({
      applicantExamId,
      examId,
    }: DeleteApplicantScheduleDeliveryParams) =>
      deleteApplicantScheduleDelivery({
        applicantExamId,
        examId,
        projectId,
      }),
    onSuccess: (_, { examId }) => {
      toast.success(Message.getMessageByKey("message.delivery.cancel.success"));
      invalidate(examId);
      client.invalidateQueries(
        examMailDeliveryKeys.deliveryList({ examId, projectId }),
      );
      client.invalidateQueries(
        examMailDeliveryKeys.mailDeliveryDetailProject({ projectId }),
      );
      client.invalidateQueries(
        submissionKeys.list(projectId, { examId, keyword: "" }),
      );
    },
    onError: () => {
      toast.error(Message.getMessageByKey("message.delivery.cancel.failed"));
    },
  });

  return mutation;
}
