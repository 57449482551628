import classnames from "classnames";
import { isEmpty } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { Prompt } from "react-router";

import {
  formatApplicantActionSettingsValues,
  formatAutoFilterSettingsValues,
  formatGuestSharingValues,
  formatWebcamSettingsValues,
} from "@components/orgs/exams/examCreate/utils";
import { DraftFormProvider } from "@components/orgs/exams/examSections/examSettingsNew/DraftFormProvider";
import { ExamSettingsForm } from "@components/orgs/exams/examSections/examSettingsNew/ExamSettingsNew";
import {
  convertToUTC,
  ExamSettingsFormValidation,
} from "@components/orgs/exams/examSections/examSettingsNew/utils";

import { ExamDetailPayload } from "@api/exams";

import { useStoreContext } from "@context";

import { Modal, Icon, Msg } from "@shared/components";
import { FormType } from "@shared/components/form/types";
import {
  useExam,
  useExamCounts,
  useUpdateExam,
  useUpdateExamIdSharing,
  useUpdateExamUrlSharing,
} from "@shared/hooks/query";
import { UrlSharingConfigModel, IdDeliveryConfigModel } from "@shared/models";
import { ExamDeliveryKind, SpokenLanguages } from "@shared/services/enums";
import MSG from "@shared/services/message";

import { ExamDetailHeader } from "../../../examSections";
import { ExamEditSaveArea } from "../examEditSaveArea/ExamEditSaveArea";
import { getExamSettingValues } from "./utils";

export interface ExamDetailSettingsProps {
  hasCriticalIssue: boolean;
}

export const ExamDetailSettingsNew = ({
  hasCriticalIssue,
}: ExamDetailSettingsProps) => {
  const {
    data: { examDetail },
  } = useExam();
  const {
    data: { hasBeenDelivered },
  } = useExamCounts();

  const { uploadingImage } = useStoreContext();

  const initialValues = useMemo(
    () => getExamSettingValues(examDetail),
    [examDetail],
  );

  const [editing, setEditing] = useState(false);
  const [isFormAlertModalOpen, setIsFormAlertModalOpen] = useState(false);

  const [form, setForm] = useState<{
    formValid: boolean;
    formValues: ExamSettingsFormValidation;
  }>({
    formValid: false,
    formValues: initialValues,
  });

  const updateExam = useUpdateExam();
  const updateUrlSharing = useUpdateExamUrlSharing();
  const updateIdSharing = useUpdateExamIdSharing();

  useEffect(() => {
    setForm((oldState) => ({
      ...oldState,
      formValues: initialValues,
    }));
  }, [initialValues]);

  const updateExamSettings = async (
    data: ExamDetailPayload,
    idDelivery?: IdDeliveryConfigModel,
    urlDelivery?: UrlSharingConfigModel,
  ) => {
    const examId = examDetail.id;
    updateExam.mutate(
      {
        examId,
        data,
      },
      {
        onSettled: (_, error) => {
          if (error) {
            return;
          }

          // exam update requests need to be done sequentially otherwise the config api will fail.
          // eg. on 1st sharing config update
          if (data.deliveryKind === ExamDeliveryKind.Standard) {
            if (data.urlDeliveryEnabled) {
              updateUrlSharing.mutate({
                examId,
                data: urlDelivery || ({} as UrlSharingConfigModel),
              });
            }
          } else {
            updateIdSharing.mutate({
              examId,
              data: idDelivery || ({} as IdDeliveryConfigModel),
            });
          }
        },
      },
    );
  };

  const closeFormAlertModal = () => {
    setIsFormAlertModalOpen(false);
  };

  const onCancel = () => {
    setEditing(false);
    setForm((oldState) => ({
      ...oldState,
      formValues: initialValues,
    }));
  };

  const onClickEdit = () => {
    setEditing((oldEditing) => !oldEditing);
  };

  const handleUpdate = () => {
    const { deliveryKind = ExamDeliveryKind.Standard } = form.formValues;

    if (
      Number(deliveryKind) === ExamDeliveryKind.ID &&
      examDetail?.applicantNameRequired
    ) {
      setIsFormAlertModalOpen(true);
    } else {
      onClickUpdate();
    }
  };

  const onClickOk = () => {
    closeFormAlertModal();
    onClickUpdate();
  };

  const onClickUpdate = () => {
    if (!form.formValues) {
      return;
    }

    const {
      deadlineNotificationEnabled = true,
      guestSharingEnabled = false,
      guestSharingSettings,
      webcamSettings,
      urlDeliveryEnabled,
      urlSharingConfig = new UrlSharingConfigModel(),
      language = SpokenLanguages.Japanese,
      atsIntegrationEnabled,
      atsDynamicDeadlineDays,
      idDeliveryConfig = new IdDeliveryConfigModel(),
      applicantActionSettingsEnabled,
      deliveryKind,
      applicantActionSettings,
      autoFilterSettings,
      isOfficial,
      coverImageUrl,
      autoFilterSettingsEnabled,
    } = form.formValues;

    if (urlSharingConfig && urlSharingConfig.startAt) {
      urlSharingConfig.startAt = convertToUTC(urlSharingConfig.startAt as Date);
    }
    if (urlSharingConfig && urlSharingConfig.endAt) {
      urlSharingConfig.endAt = convertToUTC(urlSharingConfig.endAt as Date);
    }

    if (idDeliveryConfig && idDeliveryConfig.startAt) {
      idDeliveryConfig.startAt = convertToUTC(idDeliveryConfig.startAt as Date);
    }
    if (idDeliveryConfig && idDeliveryConfig.endAt) {
      idDeliveryConfig.endAt = convertToUTC(idDeliveryConfig.endAt as Date);
    }

    const guestSharingSettingsFormatted = formatGuestSharingValues(
      guestSharingEnabled,
      guestSharingSettings,
    );

    const applicantActionSettingsFormatted =
      formatApplicantActionSettingsValues(
        applicantActionSettingsEnabled,
        applicantActionSettings,
      );

    const webcamSettingsFormatted = formatWebcamSettingsValues(
      applicantActionSettingsEnabled,
      webcamSettings,
    );

    const autoFilterSettingsFormatted = formatAutoFilterSettingsValues(
      autoFilterSettings,
      autoFilterSettingsEnabled,
    );

    updateExamSettings(
      {
        urlDeliveryEnabled,
        guestSharingEnabled,
        deadlineNotificationEnabled,
        language: language as SpokenLanguages,
        atsIntegrationEnabled,
        atsDynamicDeadlineDays,
        deliveryKind,
        applicantActionSettings: applicantActionSettingsFormatted,
        autoFilterSettings: autoFilterSettingsFormatted,
        guestSharingSettings: guestSharingSettingsFormatted,
        webcamSettings: webcamSettingsFormatted,
        isOfficial,
        coverImageUrl,
      },
      isEmpty(idDeliveryConfig)
        ? undefined
        : (idDeliveryConfig as IdDeliveryConfigModel),
      isEmpty(urlSharingConfig)
        ? undefined
        : (urlSharingConfig as UrlSharingConfigModel),
    );
    onClickEdit();
  };

  const onFormChange = useCallback(
    ({
      formValid,
      formValues,
    }: {
      formValid: boolean;
      formValues: ExamSettingsFormValidation;
    }) => {
      setForm({
        formValid,
        formValues,
      });
    },
    [],
  );

  const rootStyle = classnames("code-exam-edit-new__detail_settings", {
    "code-exam-detail-is-editing": editing,
  });
  const pageTitle = MSG.getMessageByKey("configure");

  return (
    <div className={rootStyle}>
      <BreadcrumbsItem
        to={`/p/${examDetail.projectId}/exams/${examDetail.id}/settings`}
      >
        {pageTitle}
      </BreadcrumbsItem>
      <Prompt
        when={editing}
        message={MSG.getMessageByKey("navigation.confirm")}
      />
      <ExamDetailHeader
        archived={Boolean(examDetail && examDetail.isArchived())}
        editing={editing}
        editDisabled={hasCriticalIssue}
        editTooltipText={<Msg id="tier.disabled.examButton" />}
        onClickCancel={onCancel}
        onClickEdit={onClickEdit}
        title={pageTitle}
        examId={examDetail.id}
      />

      <DraftFormProvider<ExamSettingsFormValidation>
        defaultValues={initialValues}
      >
        <ExamSettingsForm
          type={FormType.Edit}
          readOnly={!editing}
          initialValues={initialValues}
          hasBeenDelivered={hasBeenDelivered}
          onFormChange={(formValid, formValues: ExamSettingsFormValidation) => {
            onFormChange({ formValid, formValues });
          }}
          editLimited={examDetail && !examDetail.isChallengeEditable()}
          challengesSets={examDetail?.challengesSets}
          urlToken={examDetail?.urlToken}
        />
      </DraftFormProvider>
      {editing && (
        <ExamEditSaveArea
          disabled={editing && (!form.formValid || uploadingImage)}
          onSaveClick={handleUpdate}
        />
      )}
      <Modal
        className="form-alert-modal"
        isOpen={isFormAlertModalOpen}
        title={MSG.getMessageByKey("editExam")}
        onClickOk={onClickOk}
        onClickCancel={closeFormAlertModal}
        onClose={closeFormAlertModal}
        ariaLabel="ID Delivery Confirmation"
      >
        <Icon type="exclamation-triangle" size="medium" />
        <Msg id="editExam.confirm.applicantNameRequiredWithIdDelivery.setting" />
      </Modal>
    </div>
  );
};
