import * as classnames from "classnames";
import * as React from "react";

import { useDownloadSubmissionAIPresigned } from "@shared/hooks/query";
import { ExecStatus } from "@shared/services/enums";

import { SubmissionDetail, Msg } from "../..";
import { SubmissionResultDetailModel } from "../../../models";
import { getTimeExtensions } from "../../../services/score";

/**
 * Prop interface
 */
export type SubmissionDetailAIProps = {
  submissionResultDetail: SubmissionResultDetailModel;
  className?: string;
  hideFileDownload?: boolean;
};

/**
 * React Component
 */
export const SubmissionDetailAI: React.FunctionComponent<
  SubmissionDetailAIProps
> = (props: SubmissionDetailAIProps) => {
  const { submissionResultDetail, className, hideFileDownload } = props;
  const {
    aiResult,
    applicantExamId,
    finishedAt,
    appealComment,
    openedAt,
    extensions,
    challengeId,
  } = submissionResultDetail;
  const downloadUrl = useDownloadSubmissionAIPresigned();

  /**
   * State
   */

  /**
   * Effects
   */

  /**
   * Private Functions
   */
  const rootStyle = classnames("code-c-submission-detail-ai", {
    [`${className}`]: Boolean(className),
  });

  const graphItems = aiResult.scoreHistory.map((item) => ({
    datetime: item.createdAt,
    value: item.score || 0,
    status: item.execStatus, // the status of AI challenge score is not reliable (seems always return 7).
  }));
  if (graphItems.length === 1) {
    // if the score is only one, the score graph line become a dot, so add extra score to make it a line
    graphItems.push({
      datetime: finishedAt,
      value: graphItems[0].value,
      status: ExecStatus.Success,
    });
  }
  const timeExtentions = getTimeExtensions(extensions, challengeId);

  const handleDownload = (historyId: number) => {
    downloadUrl.mutate({
      id: applicantExamId,
      challengeId,
      historyId,
    });
  };

  return (
    <div className={rootStyle}>
      <SubmissionDetail.SubmissionDetailSection
        title={<Msg id="submission.scoreHistory" />}
      >
        <SubmissionDetail.HistoryGraph
          items={graphItems}
          timeExtensions={timeExtentions}
          submittedAt={finishedAt}
          scoreMax={Math.max(
            aiResult.scoreType.max || 0,
            aiResult.highScore,
            1.0,
          )}
          scoreMin={
            aiResult.scoreType.rankBy === "high"
              ? 0
              : Math.min(
                  aiResult.scoreType.min || 0,
                  ...graphItems.map((item) => item.value),
                  0,
                )
          }
          scoreType={aiResult.scoreType.rankBy}
          openedAt={openedAt}
        />
      </SubmissionDetail.SubmissionDetailSection>
      {!hideFileDownload && (
        <SubmissionDetail.SubmissionDetailSection
          title={<Msg id="submission.aiUploadedFile" />}
        >
          <SubmissionDetail.SubmittedFileTable
            submissionResultDetail={submissionResultDetail}
            onClickDownload={handleDownload}
          />
        </SubmissionDetail.SubmissionDetailSection>
      )}
      <SubmissionDetail.SubmissionDetailSection
        title={<Msg id="common.applicantExplanation" />}
      >
        <SubmissionDetail.ApplicantExplanation
          applicantExplanation={appealComment}
        />
      </SubmissionDetail.SubmissionDetailSection>
    </div>
  );
};
