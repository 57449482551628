import {
  ApiResponse,
  Pagination,
  _delete,
  get,
  post,
  put,
} from "@api/httpClient";

import { MailDeliveryListModel, MailDeliveryModel } from "@shared/models";

import { DeliverExamParams } from "./examDelivery";

interface ExamDeliveryTemplate {
  template: string;
  enTemplate?: string;
  subject: string;
  body: string;
  senderName?: string;
}

interface MailDeliveryDetailParams<D = any> extends DeliverExamParams<D> {
  deliveryId: number;
}

interface UpdateMailDeliveryPayload {
  deadline: string;
}

interface UpdateMailDeliveryParams
  extends Omit<MailDeliveryDetailParams<UpdateMailDeliveryPayload>, "examId"> {
  orgName: string;
}

type DeleteScheduleDeliveryParams = Omit<
  MailDeliveryDetailParams,
  "data" | "options"
>;

interface DeleteApplicantScheduleDeliveryParams
  extends Omit<DeliverExamParams, "options"> {
  applicantExamId: number;
}

export async function getExamDeliveryTemplate({
  examId,
  projectId,
  options,
}: DeliverExamParams) {
  const { data } = await get<ExamDeliveryTemplate>(
    `/api/projects/${projectId}/exams/${examId}/mailpresets`,
    options,
  );

  return data;
}

export async function getExamMailDelivery({
  examId,
  projectId,
  options,
}: DeliverExamParams) {
  const { data } = await get<
    MailDeliveryListModel[],
    Pagination<MailDeliveryListModel[]>
  >(`/api/projects/${projectId}/exams/${examId}/maildelivery`, options);

  return data;
}

export async function getExamMailDeliveryDetail({
  deliveryId,
  examId,
  projectId,
  options,
}: MailDeliveryDetailParams) {
  const { data } = await get<MailDeliveryModel>(
    `/api/projects/${projectId}/exams/${examId}/maildelivery/${deliveryId}`,
    options,
  );

  return data;
}

export async function getExamScheduledMailDelivery({
  examId,
  projectId,
  options,
}: DeliverExamParams) {
  const { data } = await get<
    MailDeliveryListModel[],
    Pagination<MailDeliveryListModel[]>
  >(
    `/api/projects/${projectId}/exams/${examId}/scheduled/maildelivery`,
    options,
  );

  return data;
}

export async function postDeliverExamByMail({
  examId,
  projectId,
  options,
}: DeliverExamParams) {
  const { data } = await post<ApiResponse<MailDeliveryModel>>(
    `/api/projects/${projectId}/exams/${examId}/maildelivery`,
    options,
  );

  return data;
}

export async function postScheduleDeliverExamByMail({
  examId,
  projectId,
  options,
}: DeliverExamParams) {
  const { data } = await post<ApiResponse<MailDeliveryModel>>(
    `/api/projects/${projectId}/exams/${examId}/scheduled/maildelivery`,
    options,
  );

  return data;
}

export async function postDeliverSampleExamByEmail({
  examId,
  projectId,
  options,
}: DeliverExamParams) {
  const { data } = await post<ApiResponse<MailDeliveryModel>>(
    `/api/projects/${projectId}/exams/${examId}/testdelivery`,
    options,
  );

  return data;
}

export async function postDeliverOfficialExamSample({
  examId,
  projectId,
  options,
}: DeliverExamParams) {
  const { data } = await post<ApiResponse<MailDeliveryModel>>(
    `/api/projects/${projectId}/exams/officials/${examId}/selfdeliver`,
    options,
  );

  return data;
}

export async function updateExamDelivery({
  deliveryId,
  orgName,
  projectId,
  options,
}: UpdateMailDeliveryParams) {
  const { data } = await post<ApiResponse<MailDeliveryModel>>(
    `/api/admin/orgs/${orgName}/projects/${projectId}/deliveries/${deliveryId}`,
    options,
  );

  return data;
}

export async function updateScheduleExamDelivery({
  deliveryId,
  examId,
  projectId,
  options,
}: MailDeliveryDetailParams) {
  const { data } = await put<ApiResponse<MailDeliveryModel>>(
    `/api/projects/${projectId}/exams/${examId}/scheduled/maildelivery/${deliveryId}`,
    options,
  );

  return data;
}

export async function deleteScheduleDeliveryExamByMail({
  deliveryId,
  examId,
  projectId,
}: DeleteScheduleDeliveryParams) {
  const { data } = await _delete<ApiResponse<string>>(
    `/api/projects/${projectId}/exams/${examId}/scheduled/maildelivery/${deliveryId}`,
  );

  return data;
}

export async function deleteApplicantScheduleDelivery({
  applicantExamId,
  examId,
  projectId,
}: DeleteApplicantScheduleDeliveryParams) {
  const { data } = await _delete<ApiResponse<string>>(
    `/api/projects/${projectId}/exams/${examId}/scheduled/submissions/${applicantExamId}`,
  );

  return data;
}
