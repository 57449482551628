import * as classnames from "classnames";

/**
 * Prop interface
 */
export interface LeftBlockProps {
  direction?: string;
  className?: string;
  children?: {};
}

/**
 * React Component
 */
export default function LeftBlock({
  direction,
  className,
  children,
}: LeftBlockProps) {
  const rootStyle = classnames("code-c-left-block", "level-left", {
    [`is-${direction}`]: Boolean(direction),
    [`${className}`]: Boolean(className),
  });

  return <div className={rootStyle}>{children}</div>;
}
