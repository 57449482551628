import {
  isSupported as isBrowserSupported,
  isDesktop,
  bowser as testResult,
} from "../services/browserSupport";
import { localStorage, sessionStorage } from "../services/storage";

const PROPERTY_NAMES = Object.freeze([
  "machine",
  "browser",
  "cookieEnabled",
  "webStorageEnabled",
  "apiServerConnection",
  "orcaServerConnection",
  "orcaServerWsConnection",
  "contentsServerConnection",
  "imageServerConnection",
]);

class HealthCheckModel {
  public machine: boolean;
  public browser: boolean;
  public cookieEnabled: boolean;
  public webStorageEnabled: boolean;
  public apiServerConnection: boolean;
  public orcaServerConnection: boolean;
  public orcaServerWsConnection: boolean;
  public contentsServerConnection: boolean;
  public imageServerConnection: boolean;
  public apiServerConnectionError: string;
  public orcaServerConnectionError: string;
  public orcaServerWsConnectionError: string;
  public contentsServerConnectionError: string;
  public imageServerConnectionError: string;

  constructor(args?: {}) {
    Object.assign(this, args);

    this.webStorageEnabled = localStorage.available && sessionStorage.available;
    this.browser = isBrowserSupported.valueOf();
    this.machine = isDesktop;

    // See: https://github.com/Modernizr/Modernizr/blob/master/feature-detects/cookies.js
    try {
      // Create cookie
      document.cookie = "cookietest=1";
      this.cookieEnabled = document.cookie.indexOf("cookietest=") !== -1;

      // Delete cookie
      document.cookie = "cookietest=1; expires=Thu, 01-Jan-1970 00:00:01 GMT";
    } catch (e) {
      this.cookieEnabled = false;
    }
  }

  public passed() {
    return (
      this.machine &&
      this.browser &&
      this.cookieEnabled &&
      this.webStorageEnabled &&
      this.apiServerConnection &&
      this.orcaServerConnection &&
      this.orcaServerWsConnection &&
      this.contentsServerConnection &&
      this.imageServerConnection
    );
  }

  public errorCount() {
    return PROPERTY_NAMES.reduce((total, key) => {
      return total + (this[key] === false ? 1 : 0);
    }, 0);
  }

  public checked() {
    return PROPERTY_NAMES.filter((key) => this[key] === undefined).length === 0;
  }

  public osString() {
    return testResult.getOSName();
  }

  public browserString() {
    return testResult.getBrowserName();
  }
}

export default HealthCheckModel;
