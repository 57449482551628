import { ajax } from "rxjs/observable/dom/ajax";

import Message from "../services/message";

const makeQueryString = (
  paramsObject: Record<string, string | number | undefined>,
) =>
  Object.keys(paramsObject).reduce(
    (queryString, key) =>
      paramsObject[key] !== undefined
        ? (queryString += `${key}=${paramsObject[key]}&`)
        : queryString,
    "?",
  );

const cloudinaryConfig = () => ajax.getJSON("/api/images/config");

const cloudinarySignature = (
  width: number,
  height: number,
  tags?: string,
  transformation?: string,
) => {
  const params = makeQueryString({
    width,
    height,
    tags,
    transformation,
  });

  return ajax.getJSON(`/api/images/signature${params}`);
};

const getUrl = (cloudName: string) =>
  `https://api.cloudinary.com/v1_1/${cloudName}/image/upload`;

/**
 * Upload image
 * see:
 *   https://cloudinary.com/documentation/image_upload_api_reference#upload_method
 * @param name
 * @param file
 * @param width
 * @param height
 * @param tags
 * @param transformation
 */
export const uploadImage = (
  name: string,
  file: File,
  width: number,
  height: number,
  tags?: string,
  transformation?: string,
) =>
  cloudinaryConfig().switchMap(({ result: { cloud_name } }) =>
    cloudinarySignature(width, height, tags, transformation)
      .concatMap((response: { result: Record<string, string> }) => {
        const formData = new FormData();
        formData.append("file", file);

        Object.keys(response.result).forEach((key) => {
          if (typeof response.result[key] === "string") {
            formData.append(key, response.result[key]);
          }
        });

        return ajax
          .post(getUrl(cloud_name), formData)
          .map(({ response: resp }) => resp);
      })
      .catch((error) => {
        throw new Error(Message.getMessageByKey("error.filemisc"));
      }),
  );

export const dataURLtoBlob = (dataURL: string) => {
  const arr = dataURL.split(",");

  const mime = arr[0].match(/:(.*?);/)?.[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new Blob([u8arr], { type: mime });
};
