import classnames from "classnames";
import * as React from "react";

import { useElementSize } from "@shared/hooks";

interface EllipsisMiddleProps {
  children: string;
  className?: string;
  suffixLength: number;
}

function EllipsisMiddle({
  children,
  className,
  suffixLength,
}: EllipsisMiddleProps) {
  const [setSuffixRef, suffixSize] = useElementSize<HTMLSpanElement>();
  const rootStyle = classnames("code-c-ellipsis-middle", className);
  const [prefix, suffix] = React.useMemo(() => {
    if (children.length <= suffixLength) {
      return [children, ""];
    }

    const firstPart = children.slice(0, children.length - suffixLength);
    const lastPart = children.slice(-suffixLength).trim();

    return [firstPart, lastPart];
  }, [children, suffixLength]);
  const prefixStyle = React.useMemo<React.CSSProperties>(
    () => ({
      maxWidth: `calc(100% - ${suffixSize.width}px)`,
    }),
    [suffixSize.width],
  );

  return (
    <span className={rootStyle} aria-label={children} title={children}>
      <span aria-hidden style={prefixStyle}>
        {prefix}
      </span>
      <span ref={setSuffixRef} aria-hidden>
        {suffix}
      </span>
    </span>
  );
}

export default EllipsisMiddle;
