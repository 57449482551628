import * as React from "react";

import { Label, Select } from "@shared/components";
import { ProjectRoleModel, ProjectSwitchItemModel } from "@shared/models";

import { MemberRoleForm } from "..";

/**
 * Prop interface
 */

export interface ProjectRoleFormProps {
  name?: string;
  errors?: { [key: string]: string };
  initialValues: ProjectRoleModel;
  remainingProjects: ProjectSwitchItemModel[];
  projectName: string;
  isProjectChangeable: boolean;
  isExpired?: boolean;
  onChangeProject: (id: number) => void;
  onDeleteProject: () => void;
  onChangeProjectRoles: (roles: number[]) => void;
}

export const ProjectRoleForm: React.FunctionComponent<ProjectRoleFormProps> = ({
  name,
  errors,
  initialValues,
  remainingProjects,
  projectName,
  isProjectChangeable,
  isExpired,
  onChangeProject,
  onDeleteProject,
  onChangeProjectRoles,
}: ProjectRoleFormProps) => {
  return (
    <div key={initialValues.id} className="code-project-role-form">
      <div className="code-project-role-form__project-select">
        {isProjectChangeable || isExpired ? (
          <Label
            className="code-project-role-form__project-select__title-label"
            title={projectName}
          />
        ) : (
          <Select
            className="code-project-role-form__project-select__title-label"
            options={[
              { id: initialValues.id, name: projectName },
              ...remainingProjects,
            ].map((p) => ({
              label: p.name,
              value: p.id,
            }))}
            onChange={(e: React.FormEvent<HTMLSelectElement>) =>
              onChangeProject(Number(e.currentTarget.value))
            }
          />
        )}
        {!isExpired && (
          <button
            className="delete"
            aria-label="close"
            onClick={onDeleteProject}
          />
        )}
      </div>
      <MemberRoleForm
        name={name}
        errors={errors}
        initialValues={[...initialValues.roles]}
        onFormChange={onChangeProjectRoles}
        isExpired={isExpired}
      />
    </div>
  );
};
