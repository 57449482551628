import { createAsyncAction } from "typesafe-actions";

const actionCreator2 = createAsyncAction;

export { actionCreator2 };

const actionCreator = (type: string) => {
  const request = `${type}_REQUEST`;
  const success = `${type}_SUCCESS`;
  const failure = `${type}_FAILURE`;

  return {
    types: { request, success, failure },
    request(payload?: {}, params?: {}) {
      return {
        type: request,
        payload,
        params,
      };
    },
    success(payload?: {}, params?: {}) {
      return {
        type: success,
        payload,
        params,
      };
    },
    failure(payload?: {}, params?: {}) {
      return {
        type: failure,
        payload,
        params,
      };
    },
  };
};

export type ActionCreator = ReturnType<typeof actionCreator>;

export default actionCreator;
