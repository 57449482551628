import * as classnames from "classnames";
import * as React from "react";
import SwiperCore from "swiper";
import "swiper/css";
import "swiper/css/mousewheel";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination, Mousewheel } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import { TierAction } from "@shared/services/enums";

import {
  ChallengeCollectionCard,
  Label,
  Loading,
  Msg,
} from "../../../../shared/components";
import {
  ChallengeCollectionListModel,
  PinnedChallengeModel,
  ChallengeModel,
} from "../../../../shared/models";
import ChallengeCollectionDetailModal from "./partials/ChallengeCollectionDetailModal.connect";

/**
 * Prop interface
 */
export type ExternalProps = {
  className?: string;
  pinnedChallengeList: PinnedChallengeModel[];
  /**
   * Not editable selected challenge list
   */
  selectedChallengeIds?: number[];
  /**
   * Editable selected challenge list
   */
  temporarySelectedChallenges?: ChallengeModel[];
  canEditPin?: boolean;
  canSelect?: boolean;
  modalOptions?: {
    showOfficial?: boolean;
    strictLinkedChallenge?: boolean;
    defaultConditions?: {};
  };
  defaultConditions?: {};
  onTogglePin?: (challengeId: number, pinId?: number) => void;
  onSelect?: (data: {
    selectedChallenges: ChallengeModel[];
    removedChallenges: ChallengeModel[];
  }) => void;
};

export type InjectedProps = {
  language: string;
  challengeCollectionList: ChallengeCollectionListModel[];
  challengeCollectionLoading: boolean;
  isTierActionAllowed: (tierAction: TierAction) => boolean;
  getChallengeCollectionList: (conditions?: {}) => void;
};

export type ChallengeCollectionProps = ExternalProps & InjectedProps;

/**
 * React Component
 */
export const ChallengeCollection: React.FunctionComponent<
  ChallengeCollectionProps
> = (props: ChallengeCollectionProps) => {
  const {
    className,
    language,
    challengeCollectionList,
    pinnedChallengeList,
    selectedChallengeIds,
    temporarySelectedChallenges,
    canEditPin,
    canSelect = false,
    challengeCollectionLoading,
    isTierActionAllowed,
    getChallengeCollectionList,
    modalOptions,
    defaultConditions,
  } = props;

  /**
   * State
   */
  SwiperCore.use([Pagination, Navigation, Mousewheel]);

  const rootStyle = classnames("code-challenge-collection", {
    [`${className}`]: Boolean(className),
  });

  const [collectionModal, setCollectionModal] = React.useState<{
    isOpen: boolean;
    targetCollectionId?: number;
  }>({
    isOpen: false,
  });

  const canGetChallengeCollection = isTierActionAllowed(
    TierAction.ChallengeCollectionUsage,
  );

  /**
   * Effects
   */
  React.useEffect(() => {
    if (canGetChallengeCollection) {
      getChallengeCollectionList(defaultConditions);
    }
  }, [
    getChallengeCollectionList,
    defaultConditions,
    canGetChallengeCollection,
  ]);

  /**
   * Private Functions
   */
  const onOpenChallengeCollection = (collectionId: number) => {
    setCollectionModal({
      isOpen: true,
      targetCollectionId: collectionId,
    });
  };

  const onSelect = (data: {
    selectedChallenges: ChallengeModel[];
    removedChallenges: ChallengeModel[];
  }) => {
    if (typeof props.onSelect === "function") {
      props.onSelect(data);
    }
    setCollectionModal({ isOpen: false });
  };

  /**
   * Render
   */
  return (
    <div className={rootStyle}>
      {challengeCollectionLoading && !collectionModal.isOpen && (
        <Loading isOpen={true} fullScreen={false} overlay={false} />
      )}
      {challengeCollectionList.length > 0 && (
        <div className="challenge-collection__card-container">
          <Label className="challenge-collection__label">
            <Msg id="challengeCollection" />
          </Label>
          <Swiper
            spaceBetween={15}
            slidesPerView={"auto"}
            navigation={true}
            mousewheel={true}
            hashNavigation={{
              watchState: true,
            }}
          >
            {challengeCollectionList?.map((collection, index) => (
              <SwiperSlide key={collection.id}>
                <ChallengeCollectionCard
                  challengeCollection={collection}
                  locale={language}
                  onClick={() => onOpenChallengeCollection(collection.id)}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}
      {collectionModal.isOpen && collectionModal.targetCollectionId && (
        <ChallengeCollectionDetailModal
          isOpen={collectionModal.isOpen}
          challengeCollectionId={collectionModal.targetCollectionId}
          selectedChallengeIds={selectedChallengeIds}
          temporarySelectedChallenges={temporarySelectedChallenges}
          pinnedChallengeList={pinnedChallengeList}
          canEditPin={canEditPin}
          canSelect={canSelect}
          showOfficialExam={modalOptions?.showOfficial}
          strictLinkedChallenge={modalOptions?.strictLinkedChallenge}
          defaultConditions={modalOptions?.defaultConditions}
          onTogglePin={props.onTogglePin}
          onSelect={onSelect}
          onClose={() => setCollectionModal({ isOpen: false })}
        />
      )}
    </div>
  );
};
