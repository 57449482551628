import * as classnames from "classnames";

import { Icon, IconProps } from "../icon/Icon";
import { IconType, IconVariant } from "../icon/types";

interface Props {
  className?: string;
  icons: Pick<IconProps, "type" | "variant" | "size" | "className">[];
  color?: "primary" | "info" | "success" | "warning" | "danger"; // or use className to style the wrapper
  strikethrough?: boolean;
  exclamation?: boolean;
}

const stackIconSize = {
  normal: "fa-stack-1x",
  large: "fa-stack-2x",
};

export function StackedIcon({
  className,
  icons,
  color,
  strikethrough,
  exclamation,
}: Props) {
  const rootStyle = classnames(
    "code-c-stacked-icon",
    "code-c-icon",
    "fa-stack",
    "icon",
    {
      [`has-text-${color}`]: Boolean(color),
      [`${className}`]: Boolean(className),
    },
  );
  const iconsComponent = icons.map(
    ({ type, variant, size, className: iconClassName }, index) => {
      const iconStyle = classnames(!variant && "fa", {
        [`fa-${type}`]: Boolean(type),
        [`fa-${variant}`]: Boolean(variant),
        [`${stackIconSize[size || ""]}`]: Boolean(stackIconSize[size || ""]),

        [`${iconClassName}`]: Boolean(iconClassName),
      });

      return <i className={iconStyle} key={index} />;
    },
  );

  return (
    <span className={rootStyle}>
      {iconsComponent}
      {strikethrough && (
        <>
          <Icon
            type={IconType.Slash}
            className="fa-stack-1x code-c-stacked-icon__strike1"
            data-testid="stacked-icon-strike"
          />
          <Icon
            type={IconType.Slash}
            className="fa-stack-1x code-c-stacked-icon__strike2"
          />
        </>
      )}
      {exclamation && (
        <div
          className="fa-stack-1x code-c-stacked-icon-exclamation"
          data-testid="stacked-icon-exclamation"
        >
          <Icon
            type={IconType.Circle}
            className="code-c-stacked-icon-exclamation-border"
          />
          <Icon
            type={IconType.CircleExclamation}
            variant={IconVariant.Solid}
            className="fa-solid code-c-stacked-icon-exclamation-inner"
          />
        </div>
      )}
    </span>
  );
}
