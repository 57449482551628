import { Icon, Modal, Msg } from "@shared/components";
import Message from "@shared/services/message";

interface SubmissionCheatHelpModalProps {
  onClickClose: () => void;
}

export const SubmissionCheatHelpModal = ({
  onClickClose,
}: SubmissionCheatHelpModalProps) => {
  return (
    <Modal
      title={
        <Msg id="submission.submissionDetail.cheatMonitor.help.typesOfSuspicious" />
      }
      isOpen={true}
      onClose={onClickClose}
      onClickCancel={onClickClose}
      cancelButtonLabel={<Msg id="close" />}
      hasOkButton={false}
      size="small"
      ariaLabel={Message.getMessageByKey(
        "submission.submissionDetail.cheatMonitor.help.typesOfSuspicious",
      )}
      className="code-submission-cheathelp-modal"
    >
      <div className="code-submission-cheathelp-modal__content">
        <div>
          <h4 className="code-submission-cheathelp-modal__heading">
            <Icon
              type="clone"
              className="code-submission-cheathelp-modal__icon"
            />
            <Msg id="submission.submissionDetail.cheatMonitor.pasted" />
          </h4>
          <p className="code-submission-cheathelp-modal__description">
            <Msg id="submission.submissionDetail.cheatMonitor.help.pasted" />
          </p>
          <p className="code-submission-cheathelp-modal__highlight">
            <Msg id="submission.submissionDetail.cheatMonitor.help.pastedDetail" />
          </p>
        </div>
        <div>
          <h4 className="code-submission-cheathelp-modal__heading">
            <Icon
              type="table-columns"
              className="code-submission-cheathelp-modal__icon"
            />
            <Msg id="submission.submissionDetail.cheatMonitor.leftTab" />
          </h4>
          <p className="code-submission-cheathelp-modal__description">
            <Msg id="submission.submissionDetail.cheatMonitor.help.leftTab" />
          </p>
          <p className="code-submission-cheathelp-modal__highlight">
            <Msg id="submission.submissionDetail.cheatMonitor.help.leftTabDetail" />
          </p>
        </div>
        <div>
          <h4 className="code-submission-cheathelp-modal__heading">
            <Icon
              type="camera"
              className="code-submission-cheathelp-modal__icon"
            />
            <Msg id="submission.submissionDetail.cheatMonitor.screenshot" />
          </h4>
          <p className="code-submission-cheathelp-modal__description">
            <Msg id="submission.submissionDetail.cheatMonitor.help.screenshot" />
          </p>
        </div>
        <div>
          <h4 className="code-submission-cheathelp-modal__heading">
            <Icon
              type="exclamation-triangle"
              className="code-submission-cheathelp-modal__icon"
            />
            <Msg id="submission.submissionDetail.cheatMonitor.dataChange" />
          </h4>
          <p className="code-submission-cheathelp-modal__description">
            <Msg id="submission.submissionDetail.cheatMonitor.help.dataChange" />
          </p>
        </div>
        <div>
          <h4 className="code-submission-cheathelp-modal__heading">
            <Icon
              type=""
              iconClassName="code-c-icon__user-exclamation-gray code-c-icon__text-base code-submission-cheathelp-modal__icon-base-small"
              className="code-submission-cheathelp-modal__icon"
            />
            <Msg id="submission.submissionDetail.cheatMonitor.multiSubmission" />
          </h4>
          <p className="code-submission-cheathelp-modal__description">
            <Msg id="submission.submissionDetail.cheatMonitor.help.multiSubmission" />
          </p>
        </div>
      </div>
    </Modal>
  );
};
