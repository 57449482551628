import { isEqual } from "lodash";
import { Epic } from "redux-observable";

import {
  Action,
  alertAction,
  redirectAction,
  ajaxAction,
  orgsUpdateAction,
  orgsUserSetAction,
  userOrgSetAction,
  orgsGetListAllAction,
  orgsGetAction,
  orgsUserUpdateAction,
  orgsUserListGetAction,
  orgsUserDeactivateAction,
  orgsUpdateSecurityAction,
  orgsGetIPAction,
  orgAllContractsGetAction,
  orgContractGetAction,
  orgAuditLogGetAction,
  orgsUserInviteResendAction,
  orgsUserInviteDeleteAction,
  orgsUserInviteListAction,
  orgsUserReactivateAction,
  orgsGetListAction,
  orgsUserInviteUpdateAction,
  allActionsGetAction,
  allTiersGetAction,
  allCsvTypesGetAction,
  orgsCsvColumnsGetAction,
  invitationStatusGetAction,
} from "../actions";
import { RootState } from "../reducers";
import { OrganizationModel } from "../shared/models";
import Message from "../shared/services/message";

interface ImageUpdateAction extends Action {
  payload: OrganizationModel;
}

const orgsGetEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(orgsGetAction.types.request).map((action) =>
    ajaxAction.request({
      method: "get",
      url: `/api/org`,
      success: orgsGetAction.success,
    }),
  );

const orgsUpdateEpic: Epic<Action, RootState> = (action$, state) =>
  action$
    .ofType(orgsUpdateAction.types.request)
    .map(({ payload }: ImageUpdateAction) => {
      return ajaxAction.request({
        method: "put",
        url: `/api/org`,
        body: payload,
        success: alertAction.success(
          Message.getMessageByKey("message.organization.updated"),
          orgsGetAction.success,
        ),
      });
    });

const orgsSetEpic: Epic<Action, RootState> = (action$, state) =>
  action$
    .ofType(orgsGetAction.types.success)
    .map((action) => userOrgSetAction.request(action.payload || {}));

export const orgsUserInviteResendEpic: Epic<Action, RootState> = (
  action$,
  state,
) =>
  action$.ofType(orgsUserInviteResendAction.types.request).map((action) => {
    return ajaxAction.request({
      method: "post",
      url: `/api/org/users/invites/${action.payload}/resend`,
      success: alertAction.success(
        Message.getMessageByKey("message.invitation.resent"),
        orgsUserInviteResendAction.success,
      ),
      error: orgsUserInviteResendAction.failure,
    });
  });

export const orgsUserInviteDeleteEpic: Epic<Action, RootState> = (
  action$,
  state,
) =>
  action$.ofType(orgsUserInviteDeleteAction.types.request).map((action) => {
    return ajaxAction.request({
      method: "delete",
      url: `/api/org/users/invites/${action.payload}`,
      success: alertAction.success(
        Message.getMessageByKey("message.invitation.canceled"),
        orgsUserInviteDeleteAction.success,
      ),
      error: orgsUserInviteDeleteAction.failure,
    });
  });

export const orgUserInviteUpdateEpic: Epic<Action, RootState> = (
  action$,
  state$,
) =>
  action$
    .ofType(orgsUserInviteUpdateAction.types.request)
    .map(({ payload, params }) =>
      ajaxAction.request({
        method: "put",
        body: params,
        url: `/api/org/users/invites/${payload}`,
        success: alertAction.success(
          Message.getMessageByKey("message.invitation.updated"),
          orgsUserInviteUpdateAction.success,
        ),
        error: orgsUserInviteUpdateAction.failure,
      }),
    );

export const orgsUserInviteListEpic: Epic<Action, RootState> = (
  action$,
  state,
) =>
  action$
    .ofType(orgsUserInviteListAction.types.request)
    .debounceTime(500)
    .map((action) => {
      return ajaxAction.request({
        method: "get",
        url: `/api/org/users/invites`,
        body: action.payload,
        success: orgsUserInviteListAction.success,
        error: orgsUserInviteListAction.failure,
      });
    });

export const postOrgsUserInviteChangedEpic: Epic<Action, RootState> = (
  action$,
  state$,
) =>
  action$
    .ofType(orgsUserInviteDeleteAction.types.success)
    .map(() =>
      orgsUserInviteListAction.request(
        state$.getState().orgs.orgsPendingInvitesCondition,
      ),
    );

const orgsUserDeactivateEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(orgsUserDeactivateAction.types.request).map((action) => {
    return ajaxAction.request({
      method: "put",
      url: `/api/org/users/${action.payload}`,
      body: { status: 10 },
      success: redirectAction(
        `/p/${state.getState().project.currentProjectId}/settings/members`,
        alertAction.success(
          Message.getMessageByKey("message.member.deactivated"),
          orgsUserDeactivateAction.success,
        ),
      ),
    });
  });

const orgsUserReactivateEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(orgsUserReactivateAction.types.request).map((action) => {
    return ajaxAction.request({
      method: "put",
      url: `/api/org/users/${action.payload}`,
      body: { status: 0 },
      success: redirectAction(
        `/p/${state.getState().project.currentProjectId}/settings/members`,
        alertAction.success(
          Message.getMessageByKey("message.member.reactivated"),
          orgsUserSetAction.success,
        ),
      ),
    });
  });

const orgsUserUpdateEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(orgsUserUpdateAction.types.request).map((action) => {
    return ajaxAction.request({
      method: "put",
      url: `/api/org/users/${action.payload}/roles`,
      body: action.params,
      success: alertAction.success(
        Message.getMessageByKey("message.member.updated"),
        orgsUserUpdateAction.success,
      ),
    });
  });

const orgsUserListGetEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(orgsUserListGetAction.types.request).map((action) =>
    ajaxAction.request({
      method: "get",
      url: "/api/org/users",
      body: action.payload,
      success: orgsUserListGetAction.success,
      cancel: [
        orgsUserInviteListAction.types.request,
        orgsUserListGetAction.types.request,
      ],
    }),
  );

const orgsListGetEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(orgsGetListAction.types.request).map((action) =>
    ajaxAction.request({
      method: "get",
      url: `/api/admin/orgs`,
      body: action.payload,
      success: orgsGetListAction.success,
    }),
  );

const orgsListGetAllEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(orgsGetListAllAction.types.request).map((action) =>
    ajaxAction.request({
      method: "get",
      url: `/api/admin/orgs`,
      body: action.payload,
      success: orgsGetListAllAction.success,
    }),
  );

export const orgsUpdateSecurityEpic: Epic<Action, RootState> = (
  action$,
  state$,
) =>
  action$.ofType(orgsUpdateSecurityAction.types.request).map((action) => {
    const { ipWhitelist } = action.payload as { ipWhitelist: string[] };
    const { ipWhitelist: prevIpWhitelist = [] } =
      state$.getState().orgs.organization || {};
    const ipHasChanged = !isEqual(ipWhitelist, prevIpWhitelist);

    return ajaxAction.request({
      method: "put",
      url: `/api/org/security`,
      body: action.payload,
      success: ipHasChanged
        ? redirectAction(
            window.location.pathname,
            alertAction.success(
              Message.getMessageByKey("message.organization.updated"),
              orgsUpdateSecurityAction.success,
            ),
            undefined, // state
            true, // refresh page
          )
        : alertAction.success(
            Message.getMessageByKey("message.organization.updated"),
            orgsUpdateSecurityAction.success,
          ),
    });
  });

const orgsGetIPEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(orgsGetIPAction.types.request).map((action) =>
    ajaxAction.request({
      method: "get",
      url: "/api/ip",
      success: orgsGetIPAction.success,
    }),
  );

const orgAllContractsGetEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(orgAllContractsGetAction.types.request).map((action) =>
    ajaxAction.request({
      method: "get",
      url: "/api/org/contractusages",
      ...(typeof action?.payload !== "undefined" &&
        Boolean(Object.keys(action.payload).length) && {
          body: action.payload,
        }),
      success: orgAllContractsGetAction.success,
      error: orgAllContractsGetAction.failure,
    }),
  );

const orgContractGetEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(orgContractGetAction.types.request).map((action) =>
    ajaxAction.request({
      method: "get",
      url: `/api/org/contractusages/${action.params}`,
      ...(typeof action?.payload !== "undefined" &&
        Boolean(Object.keys(action.payload).length) && {
          body: action.payload,
        }),
      success: orgContractGetAction.success,
      error: orgContractGetAction.failure,
    }),
  );

const orgAuditLogGetEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(orgAuditLogGetAction.types.request).map((action) =>
    ajaxAction.request({
      method: "get",
      url: `/api/org/auditlogs`,
      body: action.payload,
      success: orgAuditLogGetAction.success,
      error: orgAuditLogGetAction.failure,
    }),
  );

const allActionsGetEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(allActionsGetAction.types.request).map((action) =>
    ajaxAction.request({
      method: "get",
      url: `/api/enum/organization/actions`,
      success: allActionsGetAction.success,
      error: allActionsGetAction.failure,
    }),
  );

const allTiersGetEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(allTiersGetAction.types.request).map((action) =>
    ajaxAction.request({
      method: "get",
      url: `/api/enum/organization/tiers`,
      success: allTiersGetAction.success,
      error: allTiersGetAction.failure,
    }),
  );

const allCsvTypesGetEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(allCsvTypesGetAction.types.request).map(() =>
    ajaxAction.request({
      method: "get",
      url: `/api/enum/csvtypes`,
      success: allCsvTypesGetAction.success,
      error: allCsvTypesGetAction.failure,
    }),
  );

const orgsCsvColumnsGetEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(orgsCsvColumnsGetAction.types.request).map((action) =>
    ajaxAction.request({
      method: "get",
      url: `/api/enum/csvtypes/${action.params}/columns`,
      success: (payload: {}) =>
        orgsCsvColumnsGetAction.success(payload, action.params),
      error: (payload: {}) =>
        orgsCsvColumnsGetAction.failure(payload, action.params),
    }),
  );

const invitationStatus: Epic<Action, RootState> = (action$) =>
  action$.ofType(invitationStatusGetAction.types.request).map((action) =>
    ajaxAction.request({
      method: "get",
      url: "/api/enum/users/invitationstatuses",
      success: invitationStatusGetAction.success,
      error: invitationStatusGetAction.failure,
    }),
  );

export default [
  orgsGetEpic,
  orgsUpdateEpic,
  orgsSetEpic,
  orgsUserInviteResendEpic,
  orgsUserInviteDeleteEpic,
  orgUserInviteUpdateEpic,
  orgsUserInviteListEpic,
  orgsUserDeactivateEpic,
  orgsUserReactivateEpic,
  orgsUserUpdateEpic,
  orgsUserListGetEpic,
  orgsListGetEpic,
  orgsListGetAllEpic,
  orgsUpdateSecurityEpic,
  orgsGetIPEpic,
  orgAllContractsGetEpic,
  orgContractGetEpic,
  orgAuditLogGetEpic,
  postOrgsUserInviteChangedEpic,
  allActionsGetEpic,
  allTiersGetEpic,
  allCsvTypesGetEpic,
  orgsCsvColumnsGetEpic,
  invitationStatus,
];
