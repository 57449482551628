import { useIntl } from "react-intl";
import { z, ZodErrorMap, ZodIssueCode } from "zod";

import Logger from "@shared/services/logger";

/**
 * A provider which overwrites the default error messages of zod
 */
export const ZodTranslationProvider = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const t = useIntl();

  const customErrorMap: ZodErrorMap = (issue, context) => {
    let message: string = context.defaultError;

    switch (issue.code) {
      case ZodIssueCode.too_big: {
        if (issue.type === "string") {
          if (!issue.maximum) {
            break;
          }

          const maximum = Number(issue.maximum);

          message = t.formatMessage(
            {
              id: "validation.string.maxLength",
            },
            {
              length: maximum,
            },
          );
        } else if (issue.type === "number") {
          if (typeof issue.maximum !== "undefined") {
            const maximum = Number(issue.maximum);

            // number().lte()
            if (issue.inclusive) {
              message = t.formatMessage(
                { id: "validation.number.max" },
                { 0: maximum },
              );
              // number().lt()
            } else {
              message = t.formatMessage(
                { id: "validation.number.less" },
                { 0: maximum },
              );
            }
          }
        } else {
          Logger.warn('Unhandled "too big"!');
        }
        break;
      }

      case ZodIssueCode.too_small: {
        if (issue.type === "string") {
          if (!issue.minimum) {
            break;
          }

          const minimum = Number(issue.minimum);

          if (minimum === 1) {
            message = t.formatMessage({
              id: "validation.required",
            });
          } else {
            message = t.formatMessage(
              { id: "validation.string.minLength" },
              { length: minimum },
            );
          }
        } else if (issue.type === "number") {
          if (typeof issue.minimum !== "undefined") {
            const minimum = Number(issue.minimum);

            // number().gte()
            if (issue.inclusive) {
              message = t.formatMessage(
                { id: "validation.number.min" },
                { 0: minimum },
              );
              // number().gt()
            } else {
              message = t.formatMessage(
                { id: "validation.number.greater" },
                { 0: minimum },
              );
            }
          }
        } else {
          Logger.warn("Unhandled too small!");
        }
        break;
      }

      case ZodIssueCode.invalid_string: {
        if (typeof issue.validation === "object") {
          message = t.formatMessage({
            id: "validation.required",
          });
        } else if (issue.validation === "email") {
          message = t.formatMessage({
            id: "validation.invalidEmail",
          });
        }
        break;
      }

      case ZodIssueCode.custom: {
        message = t.formatMessage({
          id: "validation.mistakeFound",
        });
        break;
      }

      default: {
        if (issue.code === ZodIssueCode.invalid_type) {
          switch (issue.expected) {
            case "integer":
              message = t.formatMessage({
                id: "validation.number.integer",
              });
              break;
            case "number":
              message = t.formatMessage({
                id: "validation.number",
              });
              break;
          }
          break;
        }

        message = t.formatMessage({
          id: "validation.mistakeFound",
        });
      }
    }

    return { message };
  };

  z.setErrorMap(customErrorMap);

  return <>{children}</>;
};
