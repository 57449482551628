import * as classnames from "classnames";
import { useState } from "react";

import { useStoreContext } from "@context";

import {
  Modal,
  Loading,
  FormGroup,
  Form,
  Label,
  Input,
  Msg,
  Icon,
  LanguageSelect,
} from "@shared/components";
import { MemberListModel, PendingInviteModel } from "@shared/models";
import {
  MemberEditType,
  ProjectRole,
  TierAction,
  SpokenLanguages,
} from "@shared/services/enums";
import { isRoleAllowed } from "@shared/services/tier";

import { MemberRoleForm } from "../partials";

/**
 * Prop interface
 */
export type MemberRoleEditForm = {
  email: string;
  roles: number[];
  invitationLanguage?: SpokenLanguages;
};

export interface ExternalProps {
  isOpen: boolean;
  projectId: number;
  type?: MemberEditType;
  member?: MemberListModel | PendingInviteModel;
  loading?: boolean;
  onClickOk?: (id: number, params: {}) => void;
  onCancel?: () => void;
}

export type InjectedProps = {
  isTierActionAllowed: (tierAction: TierAction) => boolean;
};

export type MemberRoleEditProps = ExternalProps & InjectedProps;

/**
 * Page component
 */
export const MemberRoleEdit: React.FunctionComponent<MemberRoleEditProps> = ({
  type,
  isOpen,
  member = new MemberListModel({ roles: [] }),
  projectId,
  loading,
  onClickOk,
  onCancel,
  isTierActionAllowed,
}: MemberRoleEditProps) => {
  /**
   * State
   */
  const [form, setForm] = useState<{
    formValues: MemberRoleEditForm;
    formValid: boolean;
    formErrors: {};
    tierValid: boolean;
  }>({
    formValues: {
      email: "",
      roles: [],
    },
    formValid: false,
    formErrors: {},
    tierValid: false,
  });

  const { user } = useStoreContext();

  const language = user?.language ?? SpokenLanguages.English;

  const rootStyle = classnames("code-member-role-edit");
  const isEdit = type === MemberEditType.Edit;
  const isInvite = type === MemberEditType.New;

  /**
   * Private Functions
   */
  const handleClickOk = () => {
    if (typeof onClickOk !== "function") {
      return;
    }

    const { roles, email, invitationLanguage } = form.formValues;

    if (type === MemberEditType.Edit && member) {
      onClickOk(member.id, { roles });
    } else {
      onClickOk(projectId, {
        email,
        roles: roles.map((role) => ({
          projectId,
          role,
        })),
        invitationLanguage,
      });
    }
  };

  const onFormChange = (
    formValid: boolean,
    formValues: MemberRoleEditForm,
    formErrors: {},
  ) => {
    setForm({
      ...form,
      formValues,
      formValid,
      formErrors,
    });
  };

  const onRoleFormChange = (roles: number[]) => {
    setForm({
      ...form,
      formValues: { ...form.formValues, roles },
      tierValid: roles.every((role) =>
        isRoleAllowed({ role, isTierActionAllowed }),
      ),
    });
  };

  return (
    <Modal
      className={rootStyle}
      title={<Msg id={isEdit ? "member.updateRole" : "member.invite"} />}
      okButtonLabel={<Msg id={isEdit ? "action.update" : "action.invite"} />}
      okButtonAriaLabel={isEdit ? "Update" : "Send Invitation"}
      ariaLabel={isEdit ? "Update Member Role" : "Invite New Member"}
      isOpen={isOpen}
      disableOk={!form.formValid || !form.tierValid}
      onClose={onCancel}
      onClickCancel={onCancel}
      onClickOk={handleClickOk}
      additionalFooterContent={
        !isEdit && (
          <>
            <Icon type="exclamation-triangle" />
            <Msg id="member.invite.message.expiration" />
          </>
        )
      }
    >
      <Loading isOpen={loading} />
      <Form
        validation={{
          email: ["string", "required", "strictEmail"],
          roles: ["array", ["min", 1]],
        }}
        initialValues={{
          email: member?.email ?? "",
          roles: member.getRoles(projectId).includes(ProjectRole.ProjectAdmin)
            ? [ProjectRole.ProjectAdmin]
            : member.getRoles(projectId),
          invitationLanguage: isInvite ? language : undefined,
        }}
        updateValues={{ roles: form.formValues.roles }}
        showError={{ roles: true }}
        onFormChange={onFormChange}
        clear={isOpen}
      >
        <FormGroup>
          <Label htmlFor="email" title={<Msg id="common.email" />} />
          {isEdit ? (
            <p className="is-break-word">{member?.email ?? ""}</p>
          ) : (
            <Input id="email" name="email" />
          )}
        </FormGroup>
        {member && "name" in member && (
          <FormGroup>
            <Label title={<Msg id="common.name" />} />
            <p className="is-break-word">{member.name ?? ""}</p>
          </FormGroup>
        )}
        <MemberRoleForm
          name="roles"
          initialValues={[...form.formValues.roles]}
          onFormChange={onRoleFormChange}
          errors={form.formErrors}
        />
        {isInvite && <LanguageSelect name="invitationLanguage" />}
      </Form>
    </Modal>
  );
};
