import { useEffect } from "react";
import { useFormContext } from "react-hook-form";

import { useStoreContext } from "@context";

import {
  FormGroup,
  Msg,
  PanelStandardContents,
  SwitchContainer,
} from "@shared/components";
import { AutoFilterSettingsModel } from "@shared/models";
import { AutoFilterType, TierAction } from "@shared/services/enums";
import Message from "@shared/services/message";

import { getAutoFilterFormValuesFromSettings } from "../../examSectionUtil/ExamSectionUtil";
import { AutoFilterForm } from "../../examSettings/partials/autoFilterForm/AutoFilterForm";
import { useDraftForm } from "../DraftFormProvider";
import { ExamSettingsFormValidation } from "../utils";

interface AutofilterSectionProps {
  readOnly?: boolean;
}

export const AutofilterSection = ({ readOnly }: AutofilterSectionProps) => {
  const { isTierActionAllowed } = useStoreContext();
  const isAutoFilterAllowed = isTierActionAllowed(TierAction.AutoFilterExams);

  const {
    watch,
    setValue,
    formState: { errors },
    trigger,
  } = useFormContext<ExamSettingsFormValidation>();

  // this will be undefined if autofilter settings checkbox is unchecked
  const autoFilterSettings = watch("autoFilterSettings");

  const autoFilterSettingsEnabled = watch("autoFilterSettingsEnabled");

  const { draftState, setDraftState } =
    useDraftForm<ExamSettingsFormValidation>();

  const {
    filterType: watchFormFilterType,
    failingScore: watchFailingScore,
    passingScore: watchPassingScore,
  } = getAutoFilterFormValuesFromSettings(
    autoFilterSettings as AutoFilterSettingsModel,
  );

  useEffect(() => {
    if (!isAutoFilterAllowed) {
      setValue("autoFilterSettingsEnabled", false);
      setValue("autoFilterSettings", undefined);
    }
  }, [isAutoFilterAllowed]);

  const passingError =
    watchFormFilterType === AutoFilterType.AutoPassFail
      ? (errors.autoFilterSettings as any)?.autoPassFail?.passFailThreshold
          ?.message
      : (errors.autoFilterSettings as any)?.autoPassFailReview?.passAtAndAbove
          ?.message;

  const failingError =
    watchFormFilterType === AutoFilterType.AutoPassFail
      ? undefined
      : (errors.autoFilterSettings as any)?.autoPassFailReview?.failAtAndBelow
          ?.message;

  return (
    <SwitchContainer
      value={autoFilterSettingsEnabled}
      readOnly={readOnly || !isAutoFilterAllowed}
      expanded={isAutoFilterAllowed && autoFilterSettingsEnabled}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = event.target.checked;

        const draftFilterType = draftState.autoFilterSettings;
        setValue("autoFilterSettingsEnabled", isChecked);
        if (isChecked) {
          if (draftFilterType) {
            setValue("autoFilterSettings", draftFilterType);
          } else {
            setValue("autoFilterSettings", {
              filterType: AutoFilterType.AutoPassFail,
              autoPassFail: {
                passFailThreshold: undefined as any,
              },
            });
          }
        } else {
          setValue("autoFilterSettings", undefined);
        }
      }}
      isStandardPanel
      switchContent={
        <PanelStandardContents
          title={Message.getMessageByKey("exam.autoFilter.sectionTitle")}
          showErrorIcon={!isAutoFilterAllowed}
        >
          <Msg id="exam.autoFilter.description" />
        </PanelStandardContents>
      }
      dataTestautomationid="autoFilterSwitch"
    >
      <div className="code-exam-create__autofilter">
        <FormGroup className="code-exam-create_settings__auto-filter">
          <AutoFilterForm
            className="code-exam-create_settings__auto-filter__form"
            filterType={watchFormFilterType}
            passingScore={watchPassingScore}
            failingScore={watchFailingScore}
            readOnly={readOnly || !isAutoFilterAllowed}
            inputNames={{}}
            error={{}}
            passingScoreError={passingError}
            failingScoreError={failingError}
            onChange={({ filterType, passingScore, failingScore }) => {
              if (filterType) {
                const autoFilterSettings = {
                  filterType,
                  autoPassFail: {
                    passFailThreshold: watchPassingScore || (undefined as any),
                  },
                  autoPassFailReview: {
                    passAtAndAbove: watchPassingScore || undefined,
                    failAtAndBelow: watchFailingScore || undefined,
                  },
                };

                setValue("autoFilterSettings", autoFilterSettings as any, {
                  shouldValidate: false,
                });
                setDraftState((draft) => {
                  draft.autoFilterSettings = autoFilterSettings as any;
                  return draft;
                });
              }
              if (passingScore !== null) {
                const score = passingScore ?? undefined;

                if (watchFormFilterType === AutoFilterType.AutoPassFail) {
                  console.log("setting value", score);
                  setValue(
                    "autoFilterSettings.autoPassFail.passFailThreshold",
                    score as any, // should allow user to set to empty string
                    { shouldValidate: false },
                  );
                  setDraftState((draft) => {
                    if (
                      draft.autoFilterSettings?.filterType ===
                      AutoFilterType.AutoPassFail
                    ) {
                      draft.autoFilterSettings.autoPassFail.passFailThreshold =
                        score as number;
                    }
                    return draft;
                  });
                } else if (
                  watchFormFilterType === AutoFilterType.AutoPassFailReview
                ) {
                  setValue(
                    "autoFilterSettings.autoPassFailReview.passAtAndAbove",
                    score as number,
                    { shouldValidate: false },
                  );
                  setDraftState((draft) => {
                    if (
                      draft.autoFilterSettings?.filterType ===
                      AutoFilterType.AutoPassFailReview
                    ) {
                      draft.autoFilterSettings.autoPassFailReview.passAtAndAbove =
                        score as number;
                    }
                    return draft;
                  });
                }
              }

              if (failingScore !== null) {
                const score = failingScore ?? undefined;

                setValue(
                  "autoFilterSettings.autoPassFailReview.failAtAndBelow",
                  score as any,
                  { shouldValidate: false },
                );
              }

              trigger("autoFilterSettings");
            }}
          />
        </FormGroup>
      </div>
    </SwitchContainer>
  );
};
