import { EnvTag, Msg, Select } from "@shared/components";
import { useExam } from "@shared/hooks/query";
import { KeyEventLogModel } from "@shared/models";
import initialData from "@shared/services/initialData";
import Message from "@shared/services/message";

export type CodePlaybackHeaderProps = {
  challengeName: string;
  applicantName: string;
  selectedKeyEventLogId: number | undefined;
  keyEventLogs: KeyEventLogModel[];
  successfulTestcases: number;
  totalTestcases: number;
  onChangeSelectedKeyEventLogId: (id: number) => void;
};

export const CodePlaybackHeader = (props: CodePlaybackHeaderProps) => {
  const {
    challengeName,
    applicantName,
    keyEventLogs,
    selectedKeyEventLogId,
    successfulTestcases,
    totalTestcases,
    onChangeSelectedKeyEventLogId,
  } = props;
  const {
    data: {
      examDetail: { name: examName },
    },
  } = useExam();

  const envString = (initialData.env || "").toLowerCase();

  const options = keyEventLogs.map((keyEventLog, index) => ({
    label: `${Message.getMessageByKey("preview.retake")} ${index + 1}`,
    value: keyEventLog.id,
  }));

  return (
    <header className="code-code-playback-header">
      <div className="code-code-playback-header__left">
        <div className="text-lg is-text-ellipsis">{examName}</div>
        <div className="code-code-playback-header__score__divider">|</div>
        <div className="text-lg is-bold is-text-ellipsis">{challengeName}</div>
      </div>
      <div className="code-code-playback-header__right">
        <div className="code-code-playback-header__name is-text-ellipsis">
          {applicantName}
        </div>
        <div className="code-code-playback-header__score__divider">|</div>
        <div className="code-code-playback-header__score">
          <div>
            <Msg id="common.score" />
          </div>
          <div className="code-code-playback-header__score-badge">
            <div className="code-code-playback-header__score-bold">
              {successfulTestcases}
            </div>
            <div>/</div>
            <div>{totalTestcases}</div>
          </div>
        </div>
        {keyEventLogs.length > 1 && (
          <div>
            <Select
              value={selectedKeyEventLogId}
              options={options}
              onChange={(e) =>
                onChangeSelectedKeyEventLogId(Number(e.currentTarget.value))
              }
            />
          </div>
        )}
        {envString !== "production" && <EnvTag envString={envString} />}
      </div>
    </header>
  );
};
