import * as classnames from "classnames";
import * as React from "react";

import { ErrorMessage, ValidationMessage, hasValidationError } from "..";

/**
 * Prop interface
 */
export interface InputProps {
  /**
   * Type
   */
  type?: string;
  /**
   * placeholder
   */
  placeholder?: string;
  disabled?: boolean;
  readOnly?: boolean;
  size?: number;
  id?: string;
  value?: string;
  name?: string;
  accept?: string;
  error?: { [key: string]: string };
  errorMessage?: string;
  hasError?: boolean;
  min?: string | number;
  max?: string | number;
  step?: string;
  maxlength?: number;
  reference?: (ref: HTMLInputElement | null) => void;
  style?: {};
  spellcheck?: boolean;
  autocomplete?: string;
  suffix?: string;
  /**
   * class name
   */
  className?: string;
  /**
   *
   */
  hasDefaultCss?: boolean;
  inputClassName?: string;
  onChange?: (e: React.FormEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FormEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.FormEvent<HTMLInputElement>) => void;
  onEnter?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  dataTestautomationid?: string;
}

/**
 * React Component
 */
const Input = React.forwardRef<HTMLInputElement, InputProps>(function (
  {
    type = "text",
    style,
    reference,
    id,
    value,
    placeholder,
    disabled,
    spellcheck,
    autocomplete,
    readOnly,
    size,
    min,
    max,
    step,
    maxlength,
    className,
    inputClassName,
    hasDefaultCss = true,
    onChange,
    onBlur,
    onFocus,
    onEnter,
    onKeyDown,
    name,
    accept,
    error,
    errorMessage,
    hasError,
    suffix,
    dataTestautomationid,
  }: InputProps,
  ref,
) {
  const internalRef = React.useRef<HTMLInputElement | null>(null);

  const rootStyle = classnames("code-c-input", "control", {
    [`is-${size}`]: Boolean(size),
    [`${className}`]: Boolean(className),
    "is-readonly": readOnly,
    // style for custom input with non-editable suffix
    [`has-suffix`]: Boolean(suffix),
    input: Boolean(suffix),
    "is-danger":
      Boolean(suffix) &&
      (Boolean(errorMessage || hasError) || hasValidationError(name, error)),
  });

  const inputStyle = classnames({
    input: hasDefaultCss,
    [`${inputClassName}`]: Boolean(inputClassName),
    "is-danger":
      Boolean(errorMessage || hasError) || hasValidationError(name, error),
  });

  function handleKeyPress(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === "Enter") {
      if (onEnter) {
        onEnter(e);
      }
    }
  }
  function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (onKeyDown) {
      onKeyDown(e);
    }
  }

  function onWrapperClick(e: React.MouseEvent<HTMLDivElement>) {
    if (Boolean(suffix) && !internalRef.current?.contains(e.currentTarget)) {
      internalRef.current?.focus();
    }
  }

  return (
    <div className={rootStyle} onClick={onWrapperClick}>
      <input
        style={style}
        ref={(r: HTMLInputElement) => {
          if (reference) {
            reference?.(r);
          } else if (typeof ref === "function") {
            ref?.(r);
          }
          internalRef.current = r;
        }}
        className={inputStyle}
        type={type}
        min={min}
        max={max}
        step={step}
        id={id}
        value={value}
        disabled={disabled}
        readOnly={readOnly}
        placeholder={placeholder}
        spellCheck={spellcheck}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        onKeyPress={handleKeyPress}
        onKeyDown={handleKeyDown}
        name={name}
        accept={accept}
        maxLength={maxlength}
        autoComplete={autocomplete}
        data-testautomationid={dataTestautomationid}
      />
      {suffix && <span>{suffix}</span>}
      <ValidationMessage name={name} error={error} />
      <ErrorMessage message={errorMessage} />
    </div>
  );
});

Input.displayName = "Input";

export default Input;
