import * as classnames from "classnames";

/**
 * Prop interface
 */
export interface ProgressCircleProps {
  className?: string;
  value: number;
  size?: "small" | "medium" | "large";
}

/**
 * React Component
 */
export default function ProgressCircle({
  value,
  size,
  className,
}: ProgressCircleProps) {
  const rootStyle = classnames("code-c-progress-circle", {
    [`is-${size}`]: Boolean(size),
    [`p${value}`]: true,
    [`${className}`]: Boolean(className),
  });

  // NOTE: Since the inner contents of SVG are expanded according to the size of the outer frame, keep this shape.
  const width = 48;
  const radius = (width / 2) * 0.9;

  const circumference = 2 * Math.PI * radius;
  const strokeDashOffsetValue =
    (circumference * (100 - Math.min(value, 100))) / 100;

  return (
    <div
      className={rootStyle}
      aria-valuenow={value}
      aria-valuemin={0}
      aria-valuemax={100}
    >
      <svg
        className="code-c-progress-circle__progress"
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        viewBox={`0 0 ${width} ${width}`}
      >
        <circle
          className="code-c-progress-circle__background"
          cx={width / 2}
          cy={width / 2}
          r={radius}
        />
        <circle
          className="code-c-progress-circle__bar"
          cx={width / 2}
          cy={width / 2}
          r={radius}
          style={{
            strokeDasharray: circumference,
            strokeDashoffset: `${strokeDashOffsetValue}`,
          }}
        />
      </svg>
      <span className="code-c-progress-circle__label">{value}%</span>
    </div>
  );
}
