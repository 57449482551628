import * as React from "react";

import { useStoreContext } from "@context";

import { Button, Icon, JumpTo, Msg, Tooltip } from "@shared/components";
import {
  useCreateSubmissionResultPlayback,
  useExam,
} from "@shared/hooks/query";
import { SubmissionResultDetailModel } from "@shared/models";
import {
  ChallengeTakenBy,
  KeyEventStatus,
  ScoreStatus,
} from "@shared/services/enums";
import { getKeyEventStatus } from "@shared/services/keyEvents";
import Message from "@shared/services/message";
import { getCodeplaybackUrl } from "@shared/services/url";

export type ExternalProps = {
  resultDetail: SubmissionResultDetailModel;
};

export type PlaybackButtonProps = ExternalProps;

export const PlaybackButton: React.FunctionComponent<PlaybackButtonProps> = (
  props: PlaybackButtonProps,
) => {
  const { resultDetail } = props;
  const { projectId } = useStoreContext();
  const {
    data: {
      examDetail: { id: examId },
    },
  } = useExam();

  const {
    applicantExamId,
    challengeId,
    id,
    scoreStatus,
    status: challengeResultStatus,
    codingResult: { keyEventLogs },
  } = resultDetail;

  const status = getKeyEventStatus(challengeResultStatus, keyEventLogs);

  const processKeyEventBatch = useCreateSubmissionResultPlayback();

  const onProcess = () => {
    keyEventLogs
      ?.filter((event) => event.status === KeyEventStatus.Unprocessed)
      .forEach((event) => {
        processKeyEventBatch.mutate({
          challengeResultId: id,
          keyEventLogId: event.id,
        });
      });
  };

  if (
    scoreStatus === ScoreStatus.Scoring &&
    (status === KeyEventStatus.Processed ||
      status === KeyEventStatus.Unprocessed)
  ) {
    return (
      <Tooltip
        text={Message.getMessageByKey(
          "keyEventStatus.unprocessed.scoringInProgress",
        )}
        placement="top-end"
        wrapperClassName="code-c-playback-button"
      >
        <Button size="small" shrink={true} disabled={true}>
          <Icon
            type={status === KeyEventStatus.Processed ? "play" : "forward-fast"}
          />
        </Button>
      </Tooltip>
    );
  }

  if (status === KeyEventStatus.Processed) {
    const tooltipText =
      resultDetail.takenBy === ChallengeTakenBy.LocalMachine ? (
        <>
          <Icon type="warning" className="has-text-yellow-500" />
          <Msg id="keyEventStatus.localExam.warning" />
        </>
      ) : (
        <Msg id="action.openPlaybackViewer" />
      );
    return (
      <Tooltip
        text={tooltipText}
        placement="top-end"
        wrapperClassName="code-c-playback-button"
      >
        <JumpTo
          to={getCodeplaybackUrl({
            projectId,
            examId,
            applicantExamId,
            challengeId,
          })}
          options={{ stopPropagation: true }}
          className="button is-small is-nomedia"
        >
          <Icon type="play" />
        </JumpTo>
      </Tooltip>
    );
  }

  if (status === KeyEventStatus.Unprocessed) {
    return (
      <Tooltip
        text={KeyEventStatus.toString(status)}
        placement="top-end"
        wrapperClassName="code-c-playback-button"
      >
        <Button size="small" shrink={true} onClick={onProcess}>
          <Icon type="forward-fast" />
        </Button>
      </Tooltip>
    );
  }

  return (
    <Tooltip
      text={KeyEventStatus.toString(status)}
      placement="top-end"
      wrapperClassName="code-c-playback-button"
    >
      <Button disabled={true} size="small" shrink={true}>
        <Icon type="play" />
      </Button>
    </Tooltip>
  );
};
