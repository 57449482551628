import { AxiosRequestConfig } from "axios";

import { ApplicantExamsModel } from "@shared/models";

import { ApiResponse, put } from "../httpClient";

export interface SubmissionExtendPayload {
  examDeadline: string;
  challengeIds: number[];
  useLatestVersions: boolean;
}

export async function putSubmissionExtend(
  projectId: number,
  submissionId: number,
  options?: AxiosRequestConfig<SubmissionExtendPayload>,
) {
  const { data } = await put<ApiResponse<ApplicantExamsModel>>(
    `/api/projects/${projectId}/submissions/${submissionId}/extend`,
    options,
  );

  return data;
}
