import * as classnames from "classnames";
import * as React from "react";

import Checkbox, { CheckboxProps } from "../checkbox/Checkbox";

/**
 * Prop interface
 */
export interface SubMenuCheckboxItemProps extends CheckboxProps {
  count?: number;
  name?: string;
  value?: boolean;
  error?: { [key: string]: string };
  disabled?: boolean;
  onChange?: (event: React.FormEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FormEvent<HTMLInputElement>) => void;
}

/**
 * React Component
 */
export default function SubMenuCheckboxItem({
  name,
  count,
  onChange,
  onBlur,
  value,
  error,
  disabled,
  className,
  children,
}: SubMenuCheckboxItemProps) {
  const rootStyle = classnames("code-c-sub-menu-checkbox-item", {
    [`${className}`]: Boolean(className),
  });

  const countElement = count ? (
    <span className="code-c-sub-menu-checkbox-item__count">{count}</span>
  ) : null;

  return (
    <div className={rootStyle}>
      <Checkbox
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        readOnly={disabled}
      >
        {children}
      </Checkbox>
      {countElement}
    </div>
  );
}
