import * as classnames from "classnames";
import * as React from "react";

import { SubmissionDetail, Msg } from "..";
import {
  SubmissionResultListModel,
  SubmissionResultDetailModel,
} from "../../models";
import { ApplicantExamStatus, ChallengeStyle } from "../../services/enums";

/**
 * Prop interface
 */
export interface ChallengeResultDetailProps {
  status: ApplicantExamStatus;
  result: SubmissionResultListModel;
  detail?: SubmissionResultDetailModel;
  className?: string;
  questionNumber: number;
  canViewCodePlayback?: boolean;
  canViewCodeDiff?: boolean;
  hideFileDownload?: boolean;
  onClickRecalculate?: (challengeResult: SubmissionResultDetailModel) => void;
}

export interface ChallengeResultDetailState {
  isOpen: boolean;
}

/**
 * React Component
 */
export default class ChallengeResultDetail extends React.Component<
  ChallengeResultDetailProps,
  ChallengeResultDetailState
> {
  constructor(props: ChallengeResultDetailProps) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  public render() {
    const { status, result, detail, className } = this.props;

    const rootStyle = classnames("code-c-submission-detail", {
      "is-open": this.state.isOpen,
      "is-close": !this.state.isOpen,
      [`${className}`]: Boolean(className),
    });

    let testResult = null;

    if (detail) {
      switch (result.style) {
        case ChallengeStyle.Quiz: {
          testResult = (
            <SubmissionDetail.SubmissionDetailQuiz
              submissionResultDetail={detail}
            />
          );
          break;
        }
        case ChallengeStyle.Algorithm:
        case ChallengeStyle.Development:
        case ChallengeStyle.Function: {
          testResult = (
            <SubmissionDetail.SubmissionDetailCoding
              submissionResultDetail={detail}
              fileUploadConfig={result.fileUploadConfig}
            />
          );

          break;
        }
        case ChallengeStyle.AI: {
          testResult = (
            <SubmissionDetail.SubmissionDetailAI
              submissionResultDetail={detail}
              hideFileDownload={this.props.hideFileDownload}
            />
          );
          break;
        }
        default:
      }
    } else {
      testResult = (
        <div className="code-c-submission-detail__empty">
          <Msg id="submission.noAnswer" />
        </div>
      );
    }

    return (
      <div className={rootStyle} aria-expanded={this.state.isOpen}>
        <SubmissionDetail.SubmissionDetailSubHeader
          className="code-c-submission-detail__header"
          isOpen={this.state.isOpen}
          applicantExamStatus={status}
          submissionResultList={result}
          submissionResultDetail={detail}
          onClick={() => this.setState({ isOpen: !this.state.isOpen })}
          onClickRecalculate={this.props.onClickRecalculate}
          canViewCodePlayback={this.props.canViewCodePlayback}
          canViewCodeDiff={this.props.canViewCodeDiff}
        />
        {this.state.isOpen && (
          <div aria-hidden={!this.state.isOpen}>{testResult || undefined}</div>
        )}
      </div>
    );
  }
}
