import * as classnames from "classnames";

/**
 * Prop interface
 */
export interface OverlayProps {
  isOpen?: boolean;
  fullScreen?: boolean;
  onClick?: () => void;
  className?: string;
  children?: {};
}

/**
 * React Component
 */
export default function Overlay({
  isOpen,
  fullScreen = true,
  onClick,
  className,
  children,
}: OverlayProps) {
  const rootStyle = classnames("code-c-overlay", {
    "is-full-screen": fullScreen,
    [`${className}`]: Boolean(className),
  });

  if (!isOpen) {
    return null;
  }

  return (
    <div className={rootStyle} onClick={onClick} aria-hidden={!isOpen}>
      {children}
    </div>
  );
}
