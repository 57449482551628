import { connect } from "react-redux";
import { Action, Dispatch } from "redux";

import {
  questionImportAction,
  questionGetAction,
  questionSourceUpdateAction,
} from "@actions";

import { RootState } from "@reducers";

import { getTierAllowedByRedux } from "@shared/services/tier";

import ChallengeNew from "./ChallengeNew";

const mapStateToProps = (state: RootState) => ({
  importLoading: state.question.loadingImportList,
  importedQuestions: state.question.questionImportList,
  loadingQuestion: state.question.loadingDetail,
  questionDetailList: state.question.questionDetailList,
  questionFilters: state.question.questionFilters,
  updateQuestionError: state.question.errorCreate,
  updateQuestionLoading: state.question.loadingCreate,
  isTierActionAllowed: getTierAllowedByRedux(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  getQuestion: (payload: {}) => {
    dispatch(questionGetAction.request(payload));
  },
  importQuestions: (payload: {}) => {
    dispatch(questionImportAction.request(payload));
  },
  updateQuestion: (id: number, params: {}) => {
    dispatch(questionSourceUpdateAction.request(id, params));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ChallengeNew);
