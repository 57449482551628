import { Epic } from "redux-observable";

import {
  Action,
  ajaxAction,
  alertAction,
  APIResponseAction,
  questionCopyAction,
  questionDeleteAction,
  questionExamLevelInsightsGetAction,
  questionFiltersAction,
  questionGetAction,
  questionImportAction,
  questionListDeleteAction,
  questionListGetAction,
  questionListUpdateAction,
  questionSourceCreateAction,
  questionSourceGetAction,
  questionSourceUpdateAction,
  redirectAction,
} from "@actions";

import { RootState } from "@reducers";

import { QuestionModel } from "@shared/models";
import { getCurrentProjectId } from "@shared/selectors";
import { QuestionType } from "@shared/services/enums";
import Message, { truncateString } from "@shared/services/message";

const questionGetEpic: Epic<Action, RootState> = (action$, state$) =>
  action$.ofType(questionGetAction.types.request).map((action) =>
    ajaxAction.request({
      method: "get",
      url: `/api/projects/${getCurrentProjectId(state$)}/questions/${
        action.payload
      }`,
      success: questionGetAction.success,
    }),
  );

const questionDeleteEpic: Epic<Action, RootState> = (action$, state$) =>
  action$.ofType(questionDeleteAction.types.request).map((action) =>
    ajaxAction.request({
      method: "delete",
      url: `/api/projects/${getCurrentProjectId(state$)}/questions/${
        action.payload
      }`,
      success: alertAction.success(
        Message.getMessageByKey("message.question.delete.success"),
        questionListDeleteAction.request,
      ),
    }),
  );

const questionListGetEpic: Epic<Action, RootState> = (action$, state$) =>
  action$
    .ofType(questionListGetAction.types.request)
    .debounceTime(275)
    .map((action) =>
      ajaxAction.request({
        method: "get",
        url: `/api/projects/${getCurrentProjectId(state$)}/questions`,
        body: action.payload,
        success: questionListGetAction.success,
        cancel: [questionListGetAction.types.request],
      }),
    );

const questionFiltersGetEpic: Epic<Action, RootState> = (action$, state) =>
  action$
    .ofType(
      questionListGetAction.types.request,
      questionFiltersAction.types.request,
    )
    .filter(
      () => !state.getState().question.questionFilters.quizCategories.length,
    )
    .flatMap(() => [
      ajaxAction.request({
        method: "get",
        url: "/api/enum/challenges/quizcategories",
        success: (payload: {}) =>
          questionFiltersAction.success({
            extra: "quizCategories",
            ...payload,
          }),
      }),
    ]);

const questionImportEpic: Epic<Action, RootState> = (action$, state$) =>
  action$.ofType(questionImportAction.types.request).map((action) =>
    ajaxAction.request({
      method: "post",
      url: `/api/projects/${getCurrentProjectId(state$)}/questions/import`,
      headers: {
        "X-Requested-With": "codecheck",
      },
      body: action.payload,
      success: questionImportAction.success,
      error: questionImportAction.failure,
    }),
  );

const questionSourceGetEpic: Epic<Action, RootState> = (action$, state$) =>
  action$.ofType(questionSourceGetAction.types.request).map((action) =>
    ajaxAction.request({
      method: "get",
      url: `/api/projects/${getCurrentProjectId(state$)}/questions/${
        action.payload
      }/source`,
      headers: {
        "X-Requested-With": "codecheck",
      },
      success: questionSourceGetAction.success,
      cancel: [questionSourceGetAction.types.request],
    }),
  );

const questionSourceUpdateEpic: Epic<Action, RootState> = (action$, state$) =>
  action$.ofType(questionSourceUpdateAction.types.request).map((action) =>
    ajaxAction.request({
      method: "post",
      url: `/api/projects/${getCurrentProjectId(state$)}/questions/${
        action.payload
      }/source`,
      headers: {
        "X-Requested-With": "codecheck",
      },
      body: action.params,
      success: alertAction.success(
        Message.getMessageByKey("message.question.update.succcess"),
        questionSourceUpdateAction.success,
      ),
      error: questionSourceUpdateAction.failure,
      cancel: [
        questionSourceGetAction.types.request,
        questionSourceUpdateAction.types.request,
      ],
    }),
  );

const questionSourceUpdateSuccessEpic: Epic<Action, RootState> = (
  action$,
  state,
) =>
  action$
    .ofType(questionSourceUpdateAction.types.success)
    .flatMap((action) => [
      questionGetAction.success(action.payload),
      questionListUpdateAction.request(action.payload as {}),
    ]);

const questionSourceCreateEpic: Epic<Action, RootState> = (action$, state$) =>
  action$.ofType(questionSourceCreateAction.types.request).map((action) =>
    ajaxAction.request({
      method: "post",
      url: `/api/projects/${getCurrentProjectId(state$)}/questions/source`,
      body: action.payload,
      success: alertAction.success(
        Message.getMessageByKey("message.question.create.success"),
        questionSourceCreateAction.success,
      ),
      error: questionSourceCreateAction.failure,
    }),
  );

const questionCopyGetQuestion: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(questionCopyAction.types.request).map((action) =>
    ajaxAction.request({
      method: "get",
      url: `/api/questions/${action.payload}`,
      success: questionCopyAction.success,
    }),
  );

const questionCopyCreate: Epic<Action, RootState> = (action$, state$) =>
  action$.ofType(questionCopyAction.types.success).map((action) => {
    const question = (action as APIResponseAction).payload
      .result as QuestionModel;
    const title = truncateString(
      `[${Message.getMessageByKey("copy.label")}] ${question.title}`,
      255,
    );

    const answers =
      question.kind === QuestionType.MCQ
        ? question.settings.choices
            .map(
              (choice, index) =>
                `\n- [${
                  question.answers.includes(`\${${index}}`) ? "x" : " "
                }] ${choice}`,
            )
            .join("")
        : "";

    const explanation = question.howToSolve
      ? `\n### explanation\n${question.howToSolve}`
      : "";

    const body = {
      source: `##${title}\n${question.question}${answers}${explanation}`,
      quizCategories: question.quizCategories,
    };

    const projectId = getCurrentProjectId(state$);

    return ajaxAction.request({
      method: "post",
      url: `/api/projects/${projectId}/questions/source`,
      body,
      success: redirectAction(
        `/p/${projectId}/questions`,
        alertAction.success(
          Message.getMessageByKey("message.question.copy.success"),
        ),
        undefined,
        true,
      ),
    });
  });

const questionExamLevelInsightsGetEpic: Epic<Action, RootState> = (
  action$,
  state$,
) =>
  action$
    .ofType(questionExamLevelInsightsGetAction.types.request)
    .map((action) => {
      const projectId = getCurrentProjectId(state$);
      const { examId, questionId } = action.params as {
        examId: number;
        questionId: number;
      };

      return ajaxAction.request({
        method: "get",
        url: `/api/projects/${projectId}/exams/${examId}/questions/${questionId}/insight`,
        success: (payload: {}) => {
          return questionExamLevelInsightsGetAction.success(
            payload,
            action.params,
          );
        },
        error: (payload: {}) => {
          return questionExamLevelInsightsGetAction.failure(
            payload,
            action.params,
          );
        },
      });
    });

export default [
  questionGetEpic,
  questionDeleteEpic,
  questionListGetEpic,
  questionImportEpic,
  questionSourceGetEpic,
  questionSourceUpdateEpic,
  questionSourceCreateEpic,
  questionSourceUpdateSuccessEpic,
  questionFiltersGetEpic,
  questionCopyGetQuestion,
  questionCopyCreate,
  questionExamLevelInsightsGetEpic,
];
