import * as classnames from "classnames";

/**
 * Prop interface
 */
export interface MoreLinkProps {
  totalCount?: number;
  displayCount?: number;
  onClick?: () => void;
  className?: string;
  showMore?: boolean;
}

/**
 * React Component
 */
export default function MoreLink({
  totalCount = 0,
  displayCount = 0,
  onClick,
  className,
  showMore = true,
}: MoreLinkProps) {
  const rootStyle = classnames("code-c-more-link", {
    [`${className}`]: Boolean(className),
  });

  if (totalCount <= displayCount) {
    return null;
  }

  return showMore ? (
    <a
      className={rootStyle}
      onClick={onClick}
      role="button"
      aria-label="Show More"
    >
      Show more (+{totalCount - displayCount})
    </a>
  ) : (
    <a
      className={rootStyle}
      onClick={onClick}
      role="button"
      aria-label="Show Less"
    >
      Show less
    </a>
  );
}
