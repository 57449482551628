import { connect } from "react-redux";

import { RootState } from "@reducers";

import { getTierAllowedByRedux } from "@shared/services/tier";

import ChallengeTitleColumn, { ExternalProps } from "./ChallengeTitleColumn";

const mapStateToProps = (state: RootState, props: ExternalProps) => ({
  ...props,
  programmingLanguages: state.challenge.challengeFilters.programmingLanguages,
  programmingCategories: state.challenge.challengeFilters.programmingCategories,
  isTierActionAllowed: getTierAllowedByRedux(state),
});

export default connect(mapStateToProps, () => ({}))(ChallengeTitleColumn);
