import { AxiosRequestConfig } from "axios";

import { ApiResponse, _delete, put } from "@api/httpClient";

import { SubmissionModel } from "@shared/models";
import { ApplicantExamStatus } from "@shared/services/enums";

export type SubmissionEvaluationStatus =
  | ApplicantExamStatus.Passed
  | ApplicantExamStatus.Failed;

interface UpdateSubmissionEvaluationPayload {
  status: SubmissionEvaluationStatus;
}

interface SubmissionEvaluationParams {
  projectId: number;
  submissionId: number;
}

interface SubmissionEvaluationResponse {
  status: ApplicantExamStatus;
}

interface UpdateSubmissionEvaluationParams extends SubmissionEvaluationParams {
  options: AxiosRequestConfig<UpdateSubmissionEvaluationPayload>;
}
export async function putSubmissionEvaluation({
  projectId,
  submissionId,
  options,
}: UpdateSubmissionEvaluationParams) {
  const { data } = await put<ApiResponse<SubmissionEvaluationResponse>>(
    `/api/projects/${projectId}/submissions/${submissionId}/evaluation`,
    options,
  );

  return data;
}

export async function deleteSubmissionEvaluation({
  projectId,
  submissionId,
}: SubmissionEvaluationParams) {
  const { data } = await _delete<ApiResponse<SubmissionModel>>(
    `/api/projects/${projectId}/submissions/${submissionId}/evaluation`,
  );

  return data;
}
