import { useFormContext } from "react-hook-form";

import {
  Checkbox,
  Label,
  Msg,
  PanelStandardContents,
  SwitchContainer,
  Tooltip,
} from "@shared/components";
import Message from "@shared/services/message";

import { ExamSettingsFormValidation } from "../utils";

interface ExternalSharingSectionProps {
  readOnly?: boolean;
  isLogApplicantActionAllowed?: boolean;
  isGuestShareAllowed: boolean;
}

export const ExternalSharingSection = ({
  readOnly,
  isLogApplicantActionAllowed,
  isGuestShareAllowed,
}: ExternalSharingSectionProps) => {
  const { register, watch, setValue } =
    useFormContext<ExamSettingsFormValidation>();

  const externalSharingEnabled = watch("guestSharingEnabled");
  const disabled = readOnly || !isGuestShareAllowed;

  return (
    <div>
      <SwitchContainer
        value={externalSharingEnabled}
        readOnly={disabled}
        expanded={externalSharingEnabled}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setValue("guestSharingEnabled", e.target.checked);
        }}
        isStandardPanel
        switchContent={
          <PanelStandardContents
            title={Message.getMessageByKey("exam.guestSharing.switch.title")}
            jumpToLink={Message.getMessageByKey(
              "exam.guestSharing.switch.link",
            )}
            showErrorIcon={!isGuestShareAllowed}
          >
            <Msg id="exam.guestSharing.switch.description" />
          </PanelStandardContents>
        }
        dataTestautomationid="guestSharingSwitch"
      >
        <div className="code-exam-create__guest-sharing">
          {/* applicant information */}
          <div className="code-exam-create__guest-sharing__group">
            <Label className="code-exam-create__guest-sharing__label">
              <Msg id="exam.guestSharing.applicantInformation.label" />
            </Label>
            <Tooltip
              text={<Msg id="tier.disabled"></Msg>}
              disabled={isGuestShareAllowed}
            >
              <Checkbox
                dataTestautomationid="guestSharingShowName"
                readOnly={disabled}
                {...register("guestSharingSettings.showName")}
              >
                <Msg id="exam.guestSharing.showName" />
              </Checkbox>
            </Tooltip>
          </div>

          {/* submission insights */}
          <div className="code-exam-create__guest-sharing__group">
            <Label className="code-exam-create__guest-sharing__label">
              <Msg id="exam.guestSharing.submissionInsights.label" />
            </Label>

            <div>
              <Tooltip
                text={<Msg id="tier.disabled"></Msg>}
                disabled={isGuestShareAllowed}
              >
                <Checkbox
                  dataTestautomationid="guestSharingShowSubmissionInsights"
                  readOnly={disabled}
                  {...register("guestSharingSettings.showInsights")}
                >
                  <Msg id="exam.guestSharing.submissionInsights.scoreRanking" />
                </Checkbox>
              </Tooltip>
            </div>
            {/* // uncomment when backend adds functionality to Submission Details option */}
            {/* <Checkbox
                    readOnly={readOnly}
                    name="guestSharingShowSubmissionDetails"
                  >
                    <Msg id="exam.guestSharing.submissionInsights.submissionDetails" />
                </Checkbox> */}
            <div>
              <Tooltip
                text={<Msg id="tier.disabled"></Msg>}
                disabled={isGuestShareAllowed}
              >
                <Checkbox
                  dataTestautomationid="guestSharingShowSecretTestcases"
                  readOnly={disabled}
                  {...register("guestSharingSettings.showSecretTestcases")}
                >
                  <Msg id="exam.guestSharing.submissionInsights.includeSecretTestCases" />
                </Checkbox>
              </Tooltip>
            </div>
            <div>
              <Tooltip
                text={<Msg id="tier.disabled"></Msg>}
                disabled={isGuestShareAllowed}
              >
                <Checkbox
                  dataTestautomationid="guestSharingShowActionLog"
                  readOnly={disabled || !isLogApplicantActionAllowed}
                  {...register("guestSharingSettings.showActionLog")}
                >
                  <Msg id="exam.guestSharing.submissionInsights.applicantActionLog" />
                </Checkbox>
              </Tooltip>
            </div>
          </div>

          {/* Review and comments */}
          <div className="code-exam-create__guest-sharing__group">
            <Label className="code-exam-create__guest-sharing__label">
              <Msg id="exam.guestSharing.reviewAndComments.label" />
            </Label>
            <div>
              <Tooltip
                text={<Msg id="tier.disabled"></Msg>}
                disabled={isGuestShareAllowed}
              >
                <Checkbox
                  dataTestautomationid="guestSharingShowReviews"
                  readOnly={disabled}
                  {...register("guestSharingSettings.showReviews")}
                >
                  <Msg id="exam.guestSharing.reviewAndComments.reviewsEvaluation" />
                </Checkbox>
              </Tooltip>
            </div>
            <div>
              <Tooltip
                text={<Msg id="tier.disabled"></Msg>}
                disabled={isGuestShareAllowed}
              >
                <Checkbox
                  dataTestautomationid="guestSharingShowNote"
                  readOnly={disabled}
                  {...register("guestSharingSettings.showNote")}
                >
                  <Msg id="exam.guestSharing.reviewAndComments.note" />
                </Checkbox>
              </Tooltip>
            </div>
          </div>
        </div>
      </SwitchContainer>
    </div>
  );
};
