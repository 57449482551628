import { useMemo } from "react";

import { useGetChallengeInsight } from "@shared/hooks/query/challenges";
import { useGetChallenge } from "@shared/hooks/query/challenges/useChallenge";
import {
  ExamChallengeStatisticsParams,
  useGetExamChallengeStatistics,
  useGetExamChallengeStatisticsBatch,
} from "@shared/hooks/query/reports";
import { ChallengeStatisticModel } from "@shared/models";

const ITEMS_PER_REQUEST = 500;

export const useChallengeStatistic = ({
  examChallengeId,
  statisticInfo,
}: {
  examChallengeId: number;
  statisticInfo: ChallengeStatisticModel | undefined;
}) => {
  const challengeStatisticParams = useMemo(() => {
    if (!statisticInfo) {
      return [];
    }

    const pages = statisticInfo
      ? Math.floor(statisticInfo.dataSamples.count / ITEMS_PER_REQUEST) + 1
      : 0;

    const newParams = Array(pages)
      .fill(0)
      .map(
        (_, idx): ExamChallengeStatisticsParams => ({
          examId: statisticInfo.examId,
          limit: ITEMS_PER_REQUEST,
          offset: idx * ITEMS_PER_REQUEST,
          challengeId: examChallengeId,
        }),
      );

    return newParams;
  }, [statisticInfo, examChallengeId]);

  const statisticData = useGetExamChallengeStatisticsBatch(
    challengeStatisticParams,
  );

  const statistic: ChallengeStatisticModel | undefined = useMemo(() => {
    if (!statisticInfo) {
      return statisticInfo;
    }

    return {
      examId: statisticInfo.examId,
      dataSamples: {
        ...statisticInfo.dataSamples,
        result:
          statisticData?.reduce(
            (arr, statistic) => [...arr, ...statistic.dataSamples.result],
            [],
          ) ?? [],
      },
    };
  }, [statisticInfo, statisticData]);

  return statistic;
};

interface UseExamChallengeStatisticProps {
  examId: number;
  challengeId: number;
  /**
   * this is challengeSet[0].challenge.id
   * challengeSet[0].challenge.id is different from challengeSet[0].challenge.challengeId ¯\_(ツ)_/¯
   */
  examChallengeId: number;
  challengeVersionId: number;
  canGetChallenges?: boolean;
}

export const useExamChallengeStatistic = ({
  examId,
  challengeId,
  examChallengeId,
  challengeVersionId,
  canGetChallenges,
}: UseExamChallengeStatisticProps) => {
  const statisticInfo = useGetExamChallengeStatistics({
    examId,
    challengeId: examChallengeId,
  });

  const { data: challenge } = useGetChallenge({
    challengeId,
    challengeVersionId,
    enabled: canGetChallenges,
  });

  const { data: challengeGlobalInsight } = useGetChallengeInsight({
    challengeInsightId: challenge?.challengeInsightId ?? 0,
    enabled: Boolean(challenge?.challengeInsightId),
  });

  const statistic = useChallengeStatistic({
    examChallengeId,
    statisticInfo: statisticInfo.data,
  });

  return { statistic, challengeGlobalInsight };
};
