import * as classnames from "classnames";

/**
 * Prop interface
 */
export interface BlockProps {
  /**
   * By default, for space concerns, the block is vertical on mobile. If you want the block to be horizontal on mobile as well, set this as true.
   */
  fixed?: boolean;
  className?: string;
  children?: {};
  onClick?: () => void;
  role?: string;
  ariaPressed?: boolean;
  ariaLabel?: string;
}

/**
 * React Component
 */
export default function Block({
  fixed,
  className,
  children,
  onClick,
  role,
  ariaPressed,
  ariaLabel,
}: BlockProps) {
  const rootStyle = classnames("code-c-block", "level", {
    "is-mobile": fixed,
    [`${className}`]: Boolean(className),
  });

  return (
    <div
      className={rootStyle}
      onClick={onClick}
      role={role}
      aria-pressed={ariaPressed}
      aria-label={ariaLabel}
    >
      {children}
    </div>
  );
}
