import {
  Action,
  APIResponseAction,
  guestSubmissionGetAction,
  guestSubmissionResultListGetAction,
  guestSubmissionResultDetailListSetAction,
  guestSubmissionReviewGetAction,
} from "../actions";
import {
  ReadmeModel,
  SubmissionModel,
  SubmissionResultListModel,
  SubmissionResultDetailModel,
  ReviewModel,
} from "../shared/models";

export interface GuestSubmissionState {
  submission: SubmissionModel;
  resultList: SubmissionResultListModel[];
  resultDetailList: SubmissionResultDetailModel[];
  resultReadmeList: ReadmeModel[];
  resultReviewList: ReviewModel[];

  error: boolean;
}

const initialState = {
  submission: new SubmissionModel(),
  resultList: [],
  resultDetailList: [],
  resultReadmeList: [],
  resultReviewList: [],
  error: false,
};

export const guestSubmissionReducer = (
  state: GuestSubmissionState = initialState,
  action: Action,
): GuestSubmissionState => {
  const payload = (action as APIResponseAction).payload || {};

  switch (action.type) {
    case guestSubmissionGetAction.types.request:
      return {
        ...state,
        submission: new SubmissionModel(),
        error: false,
      };
    case guestSubmissionGetAction.types.success:
      return {
        ...state,
        submission: new SubmissionModel(payload.result),
      };

    case guestSubmissionGetAction.types.failure:
    case guestSubmissionResultListGetAction.types.failure:
    case guestSubmissionResultDetailListSetAction.types.failure:
      return {
        ...state,
        error: true,
      };

    case guestSubmissionResultListGetAction.types.request:
      return {
        ...state,
        resultList: [],
        resultDetailList: [],
        error: false,
      };
    case guestSubmissionResultListGetAction.types.success:
      return {
        ...state,
        resultList: ((payload.result as Array<{}>) || []).map(
          (result) => new SubmissionResultListModel(result),
        ),
      };
    case guestSubmissionResultDetailListSetAction.types.request:
      return {
        ...state,
        resultDetailList: [
          ...state.resultDetailList,
          new SubmissionResultDetailModel(payload.result),
        ],
      };
    case guestSubmissionReviewGetAction.types.success:
      return {
        ...state,
        resultReviewList: ((payload.result as ReviewModel[]) || []).map(
          (result) => new ReviewModel(result),
        ),
      };

    default:
      return state;
  }
};
