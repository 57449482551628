import { connect } from "react-redux";

import {
  useExam,
  useGetSubmissionsResults,
  useUpdateSubmissionExtend,
} from "@shared/hooks/query";
import { withCustomHook } from "@shared/services/withCustomHook";

import { RootState } from "../../../../reducers";
import ExtendSubmission, { OwnProps, ParentProps } from "./ExtendSubmission";

const wrapper = withCustomHook<OwnProps & ParentProps>(() => {
  const { data: submissionResultList } = useGetSubmissionsResults();
  const {
    data: { examChallenges },
  } = useExam();
  const extendSubmission = useUpdateSubmissionExtend();

  return {
    submissionResultList,
    examChallenges,
    extendSubmission,
  };
})(ExtendSubmission);

const mapStateToProps = (state: RootState) => ({
  loading: Boolean(state.ajax.submitting),
});

export default connect(mapStateToProps, {})(wrapper);
