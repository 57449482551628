import * as classnames from "classnames";

import { Tag } from "../../components";
import { ChallengeModel, LinkedChallengeModel } from "../../models";

/**
 * Enums
 */
import { ChallengeStatus } from "../../services/enums";

/**
 * Prop interface
 */

export interface LinkedChallengeItemProps {
  challenge: ChallengeModel | LinkedChallengeModel;
  className?: string;
  hasDelete?: boolean;
  hasBorderTop?: boolean;
  onClickDelete?: () => void;
  onClick?: (challenge: ChallengeModel | LinkedChallengeModel) => void;
}

/**
 * React Component
 */
export default function LinkedChallengeItem({
  challenge,
  className,
  hasDelete,
  hasBorderTop,
  onClickDelete,
  onClick,
}: LinkedChallengeItemProps) {
  const rootStyle = classnames("code-c-linked-challenge-item", {
    [`${className}`]: Boolean(className),
    hasBorderTop: Boolean(hasBorderTop),
    "on-clickable": Boolean(onClick),
  });

  const handleClick = () => {
    if (typeof onClick === "function") {
      onClick(challenge);
    }
  };

  return (
    <div className={rootStyle} onClick={() => handleClick()}>
      <div className="code-c-linked-challenge-item__left">
        {challenge.title}
      </div>
      <div className="code-c-linked-challenge-item__right">
        {challenge.status === ChallengeStatus.Draft && <Tag>Draft</Tag>}
        {challenge.language && (
          <Tag className="white">{challenge.language.toUpperCase()}</Tag>
        )}
        {hasDelete && <a className="delete" onClick={onClickDelete} />}
      </div>
    </div>
  );
}
