import * as classnames from "classnames";

import MSG from "@shared/services/message";

import { Tag } from "..";

/**
 * Prop interface
 */
export interface RandomizedBadgeProps {
  className?: string;
  hasError?: boolean;
}

/**
 * React Component
 */
export default function RandomizedBadge({
  className,
  hasError,
}: RandomizedBadgeProps) {
  const rootStyle = classnames("code-c-randomized-badge", {
    [`${className}`]: Boolean(className),
  });

  return (
    <Tag className={rootStyle} hasError={hasError}>
      {MSG.getMessageByKey("challenge.randomized")}
    </Tag>
  );
}
