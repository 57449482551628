import * as classnames from "classnames";
import * as React from "react";
import { Link } from "react-router-dom";

import { ProjectSwitchItemModel, UserModel } from "@shared/models";
import InitialData from "@shared/services/initialData";
import Message from "@shared/services/message";
import { sessionStorage } from "@shared/services/storage";
import { isAssessmentProject } from "@shared/services/tier";

import {
  Block,
  LeftBlock,
  RightBlock,
  BlockItem,
  Select,
  SelectItem,
  AccountMenu,
  HeaderLogo,
  ProjectSwitch,
  Icon,
  JumpTo,
  EnvTag,
  Msg,
  OrgPicker,
  ProjectKindTag,
} from "..";

/**
 * Enums
 */
export enum HeaderType {
  Bare,
  Orgs,
  User,
  Admin,
}

/**
 * Prop interface
 */
export type ExternalProps = {
  type?: HeaderType;
  language?: string;
  onChangeLanguage?: (locale: string) => void;
  className?: string;
  orgLogo?: string;
  orgName?: string;
  showSwitcher?: boolean;
  isSystemAdmin?: boolean;
};

export type InjectedProps = {
  user: UserModel | undefined;
  currentProject: ProjectSwitchItemModel | undefined;
};

export type HeaderProps = InjectedProps & ExternalProps;

/**
 * React Component
 */
export default function Header({
  showSwitcher = false,
  type = HeaderType.Orgs,
  language = "ja",
  onChangeLanguage,
  className,
  orgLogo,
  orgName,
  isSystemAdmin,
  user,
  currentProject,
}: HeaderProps) {
  const rootStyle = classnames("code-c-header", "navbar", "is-mobile", {
    [`${className}`]: Boolean(className),
  });

  const [lang, setLang] = React.useState(language);

  function onFormChange(event: React.FormEvent<HTMLSelectElement>) {
    const { value } = event.currentTarget;
    if (language !== value) {
      setLang(value);
      sessionStorage.setItem("language", value);
      window.location.reload();
    }
  }

  let projectSwitch: {} | null = null;
  let accountMenu: {} | null = null;
  let languageSwitch = null;
  let helpLink = null;
  let orgPicker = null;
  let env = null;
  let projectKind = null;

  if (showSwitcher && type === HeaderType.Orgs) {
    projectSwitch = (
      <BlockItem>
        <ProjectSwitch />
      </BlockItem>
    );
  }

  if (type === HeaderType.Orgs && !isAssessmentProject(currentProject?.kind)) {
    projectKind = (
      <BlockItem>
        <ProjectKindTag kind={currentProject?.kind} />
      </BlockItem>
    );
  }

  const envString = (InitialData.env || "").toLowerCase();
  if (envString && envString !== "production") {
    env = (
      <BlockItem>
        <EnvTag envString={envString} />
      </BlockItem>
    );
  }

  if (user && (type === HeaderType.Orgs || type === HeaderType.Admin)) {
    accountMenu = (
      <BlockItem className="code-c-header__account-menu">
        {isSystemAdmin && (
          <span className="code-c-header__system-admin tag">System Admin</span>
        )}
        <AccountMenu />
      </BlockItem>
    );
  }

  // TODO only show after signin
  if (user && type === HeaderType.Admin) {
    orgPicker = <OrgPicker className="code-c-header__org-picker" />;
  }

  if (type === HeaderType.User) {
    const options = ["en", "ja"].map(
      (key) =>
        ({
          label: Message.getMessageByKey(key),
          value: key,
        }) as SelectItem,
    );

    helpLink = (
      <BlockItem>
        <JumpTo
          to={Message.getMessageByKey("editor.link.helpCenter")}
          className="code-c-header__help"
        >
          <Icon type="question-circle" className="is-small" />
          <Msg id={"help"} />
        </JumpTo>
      </BlockItem>
    );

    languageSwitch = (
      <BlockItem>
        <Select
          name="language"
          className="code-c-header__language-switch"
          options={options}
          value={lang}
          onChange={onFormChange}
        />
      </BlockItem>
    );
  }

  const logo =
    type === HeaderType.User || type === HeaderType.Bare ? (
      <HeaderLogo imageUrl={orgLogo} displayName={orgName} />
    ) : (
      <Link to={"/"} className="code-c-header__custom-logo">
        <HeaderLogo imageUrl={orgLogo} displayName={orgName} />
      </Link>
    );

  return (
    <Block className={rootStyle}>
      <LeftBlock className="code-c-header__left">
        <BlockItem className="code-c-header__logo">{logo}</BlockItem>
        {projectSwitch}
      </LeftBlock>
      <RightBlock className="code-c-header__right">
        {env}
        {projectKind}
        {helpLink}
        {languageSwitch}
        {orgPicker}
        {accountMenu}
      </RightBlock>
    </Block>
  );
}
