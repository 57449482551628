class ReportChallengeModel {
  public examId: number;
  public challengeId: number;
  public style: number;
  public difficulty: number;
  public title: string;
  public timeLimitMinutes: number;
  public examScopeScores: {
    average: number;
    maxScore: number;
    medianScore: number;
    standardDeviation: number;
    scoreDevValue: number;
  } = {
    average: 0,
    maxScore: 0,
    medianScore: 0,
    standardDeviation: 0,
    scoreDevValue: 0,
  };
  public systemScopeScores: {
    average: number;
    maxScore: number;
    medianScore: number;
    standardDeviation: number;
    scoreDevValue: number;
  } = {
    average: 0,
    maxScore: 0,
    medianScore: 0,
    standardDeviation: 0,
    scoreDevValue: 0,
  };

  constructor(args?: {}) {
    Object.assign(this, args);
  }
}

export default ReportChallengeModel;
