import * as classnames from "classnames";

/**
 * Prop interface
 */
export interface HeroTileProps {
  size?: "large";
  className?: string;
  children?: {};
}

/**
 * React Component
 */
export default function HeroTile({ size, className, children }: HeroTileProps) {
  const rootStyle = classnames("code-c-hero-tile", {
    [`is-${size}`]: Boolean(size),
    [`${className}`]: Boolean(className),
  });

  return <div className={rootStyle}>{children}</div>;
}
