import * as React from "react";

import { Modal, Msg } from "@shared/components";
import {
  SlackChannelModel,
  SlackIntegrationInputModel,
  SlackIntegrationModel,
} from "@shared/models";

import {
  SettingSlackIntegrationForm,
  SettingSlackIntegrationFormType,
} from "../settingSlackIntegrationForm/SettingSlackIntegrationForm";

/**
 * Prop interface
 */
export interface ExternalProps {
  isOpen: boolean;
  slackChannelIntegrations: SlackIntegrationModel[];
  channelClicked: SlackIntegrationModel;
  onClickCancel: () => void;
  onClickOk: (
    integrationId: number,
    params: SlackIntegrationInputModel,
  ) => void;
}

interface SettingSlackIntegrationUpdateChannelModalProps extends ExternalProps {
  socialChannels: SlackChannelModel[];
}

/**
 * Page Component
 */
export const SettingSlackIntegrationUpdateChannelModal: React.FunctionComponent<
  SettingSlackIntegrationUpdateChannelModalProps
> = ({
  isOpen,
  socialChannels,
  slackChannelIntegrations,
  channelClicked,
  onClickCancel,
  onClickOk,
}: SettingSlackIntegrationUpdateChannelModalProps) => {
  const initValues = React.useMemo<SlackIntegrationInputModel>(() => {
    const {
      channelId,
      events,
      language,
      examNotificationSubscriptions,
      subscribedToAllExamNotifications,
    } = channelClicked;

    return {
      channelId,
      events,
      language,
      examNotificationSubscriptions,
      subscribedToAllExamNotifications,
    };
  }, [channelClicked]);

  const [form, setForm] = React.useState<SettingSlackIntegrationFormType>({
    formValues: initValues,
    formValid: false,
  });

  React.useEffect(() => {
    if (isOpen) {
      setForm({
        formValues: initValues,
        formValid: false,
      });
    }
  }, [isOpen, initValues]);

  const { socialChannelOptions, errorIntegrations } = React.useMemo(() => {
    const existingChannelOptions = slackChannelIntegrations.map(
      (slackChannelIntegration) => ({
        channelId:
          slackChannelIntegration.channelId === channelClicked.channelId
            ? slackChannelIntegration.channelId
            : "",
        channelName: slackChannelIntegration.displayName,
        isPrivateChannel: slackChannelIntegration.isPrivate,
      }),
    );

    const errorIntegrations = slackChannelIntegrations.filter(
      (slackChannelIntegration) =>
        Boolean(slackChannelIntegration.mostRecentDeliveryResult?.error),
    );

    return {
      socialChannelOptions: [...existingChannelOptions, ...socialChannels],
      errorIntegrations,
    };
  }, [socialChannels, slackChannelIntegrations, channelClicked.channelId]);

  return (
    <Modal
      isOpen
      title={<Msg id="slack.updateChannelNotification" />}
      onClose={onClickCancel}
      onClickCancel={onClickCancel}
      onClickOk={() => onClickOk(channelClicked.id, form.formValues)}
      disableOk={!form.formValid}
      ariaLabel="Update Channel Notifications"
    >
      <SettingSlackIntegrationForm
        initialValues={initValues}
        form={form}
        showDefaultError
        socialChannels={socialChannelOptions}
        errorIntegrations={errorIntegrations}
        onFormChange={(updatedFormValues) =>
          setForm({
            formValid: updatedFormValues.formValid,
            formValues: { ...updatedFormValues.formValues },
          })
        }
      />
    </Modal>
  );
};
