import * as classnames from "classnames";
import { isEqual, memoize } from "lodash";
import * as React from "react";
import { toast } from "react-toastify";

import {
  Button,
  Icon,
  PageTitle,
  ValidationMessage,
  Msg,
  Alert,
} from "@shared/components";
import {
  useCreateChallengePin,
  useDeleteChallengePin,
} from "@shared/hooks/query";
import {
  ChallengeModel,
  ExamChallengeModel,
  ExamChallengeSetModel,
  OriginalChallengeModel,
  PinnedChallengeModel,
  UserModel,
} from "@shared/models";
import {
  ChallengeStyle,
  ProjectRole,
  UserRole,
  ExamType,
  TierAction,
} from "@shared/services/enums";
import Message from "@shared/services/message";

import { ExamSectionUtil } from "..";
import ChallengeReleaseNote from "../../../challenges/challengeReleaseNote/ChallengeReleaseNote.connect";
import ChallengeSelect from "../../../challenges/challengeSelect/ChallengeSelect.connect";
import { ChallengeSetSelect } from "../../challengeSetSelect/ChallengeSetSelect";
import { ChallengeUpdateConfirm } from "../../examSections";
import ExamChallengeEdit from "../examChallengeEdit/ExamChallengeEdit.connect";
import ExamChallengeSetEdit from "../examChallengeSetEdit/ExamChallengeSetEdit";
import { ExamChallengeSetHeader } from "../examChallengeSetHeader/ExamChallengeSetHeader";
import ExamChallengeSetReorder from "../examChallengeSetReorder/ExamChallengeSetReorder";
import ExamChallengeSet from "./ExamChallengeSet";

export interface ExternalProps {
  examType?: ExamType;
  challengesSets: ExamChallengeSetModel[];
  editAllowed?: boolean;
  editLimited?: boolean;
  readOnly?: boolean;
  resetForm?: boolean;
  showAllErrors?: boolean;
  showUpdateAvailableBox?: boolean;
  challengeSelectOptions?: {
    showOfficial?: boolean;
  };
  onFormChange?: (formValid: boolean, formValues: {}, formErrors?: {}) => void;
  showExamLevelInsights: boolean;
  hideTitle?: boolean;
}

export interface InjectedProps {
  canEditPin: boolean;
  currentUser: UserModel;
  currentProjectId: number;
  challengeMajorUpdate: (challengeId: number, versionNumber: string) => void;
  isTierActionAllowed: (tierAction: TierAction) => boolean;
  pinnedChallengeList: PinnedChallengeModel[];
  loadingMajorUpdate: boolean;
  errorMajorUpdate?: boolean;
}

interface HookProps {
  deleteFavorite: ReturnType<typeof useDeleteChallengePin>;
  enableViewCodePlayback: boolean;
  enableViewWebcam: boolean;
  enableApplicantActionLog: boolean;
  addPinChallenge: ReturnType<typeof useCreateChallengePin>;
  deletePinChallenge: ReturnType<typeof useDeleteChallengePin>;
  invalidateExam: () => void;
}

type ExamChallengesProps = ExternalProps & InjectedProps & HookProps;
export interface ExamChallengesState {
  isOpenContents: boolean;
  editingChallenge: ExamChallengeModel;
  editingChallengeWeightEditable: boolean;
  isOptionalSettingOpen: boolean;
  isOpenChallengeEdit: boolean;
  isOpenChallengeSetEdit: boolean;
  isOpenChallengeSetReorder: boolean;
  challengesSets: ExamChallengeSetModel[];
  selectingChallengeSetIndex?: number;
  formErrors: {
    totalWeight: string;
    examChallengeSetCount: string;
    challengesSets: { [key: string]: string }[];
  };
  editingChallengeSetIndex: number;
  editingChallengeSet: ExamChallengeSetModel;
  releaseNote: {
    isOpen: boolean;
    challengeId?: number;
    usedChallengeVersionCode?: string;
  };
  challengeMajorUpdate: {
    isOpen: boolean;
    challenge?: ExamChallengeModel;
  };
}

class ExamChallenges extends React.Component<
  ExamChallengesProps,
  ExamChallengesState
> {
  constructor(props: ExamChallengesProps) {
    super(props);
    this.state = {
      isOpenContents: false,
      editingChallenge: new ExamChallengeModel(),
      editingChallengeWeightEditable: false,
      isOptionalSettingOpen: false,
      isOpenChallengeEdit: false,
      isOpenChallengeSetEdit: false,
      isOpenChallengeSetReorder: false,
      challengesSets: props.challengesSets || [],
      formErrors: {
        totalWeight: "",
        examChallengeSetCount: "",
        challengesSets: [],
      },
      editingChallengeSetIndex: 0,
      editingChallengeSet: new ExamChallengeSetModel(),
      releaseNote: { isOpen: false },
      challengeMajorUpdate: { isOpen: false },
    };
  }

  public shouldComponentUpdate(
    nextProps: Readonly<ExamChallengesProps>,
    nextState: Readonly<ExamChallengesState>,
  ): boolean {
    return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state);
  }

  public componentDidMount() {
    const { formErrors } = this.validate();
    this.setState({ formErrors });
  }

  public componentDidUpdate(
    prevProps: Readonly<ExamChallengesProps>,
    prevState: Readonly<ExamChallengesState>,
  ) {
    if (!isEqual(this.props.challengesSets, prevProps.challengesSets)) {
      this.setState(
        {
          challengesSets: this.props.challengesSets || [],
        },
        () => {
          const { formErrors } = this.validate();
          this.setState({ formErrors });
        },
      );
    }

    // Update Pinned status on Exam Create page
    if (
      !isEqual(this.props.pinnedChallengeList, prevProps.pinnedChallengeList)
    ) {
      const { challengesSets } = this.state;
      const newChallengesSets = challengesSets.map((challengeSet) => {
        const challenges = challengeSet.challenges.map((challenge) => {
          const pinnedChallenge = this.props.pinnedChallengeList.find(
            (pinned) => pinned.challengeId === challenge.challengeId,
          );
          return {
            ...challenge,
            favoriteId: pinnedChallenge ? pinnedChallenge.id : undefined,
          };
        });
        return { ...challengeSet, challenges };
      });
      this.setState({ challengesSets: newChallengesSets });
    }

    if (
      !this.props.errorMajorUpdate &&
      prevProps.loadingMajorUpdate !== this.props.loadingMajorUpdate &&
      !this.props.loadingMajorUpdate
    ) {
      this.setState({ challengeMajorUpdate: { isOpen: false } });
      this.props.invalidateExam();
    }
  }

  // memoize the options
  // https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#what-about-memoization
  getChallengeCollectionOptions = memoize((examType) => ({
    ...this.props.challengeSelectOptions,
    ...(examType === ExamType.EnglishJapanese
      ? {
          strictLinkedChallenge: true,
          defaultConditions: { onlyLinked: true },
        }
      : {}),
  }));

  public render() {
    const rootStyle = classnames("code-exam-edit__challenges");

    const {
      readOnly = false,
      editAllowed = false,
      editLimited = false,
      showAllErrors = false,
      showUpdateAvailableBox = false,
      canEditPin = false,
      currentUser,
      currentProjectId,
      examType,
      showExamLevelInsights,
      enableViewCodePlayback,
      enableViewWebcam,
      enableApplicantActionLog,
      hideTitle,
      isTierActionAllowed,
    } = this.props;
    const { challengesSets, formErrors, releaseNote } = this.state;

    const collectionOptions = this.getChallengeCollectionOptions(examType);

    return (
      <div className={rootStyle}>
        <div className="code-exam-edit__form">
          {!readOnly && editLimited && (
            <Alert type="warning" className="code-exam-edit__edit-limited">
              <Msg id={"code-exam-edit.limited"} />
            </Alert>
          )}
          {!hideTitle && (
            <PageTitle>
              <Msg id="common.challenges" />
            </PageTitle>
          )}
          <div className="code-exam-edit__challenges__scroll-container">
            {showAllErrors && (
              <ValidationMessage
                className="code-exam-edit__challenges__error-message"
                name="error"
                error={{
                  error: Message.getMessageByKey(
                    formErrors.examChallengeSetCount,
                  ),
                }}
              />
            )}
            {showAllErrors && (
              <ValidationMessage
                className="code-exam-edit__challenges__error-message"
                name="error"
                error={{
                  error: Message.getMessageByKey(formErrors.totalWeight),
                }}
              />
            )}
            {challengesSets.map((challengeSet, index) => (
              <div key={index}>
                <ExamChallengeSetHeader
                  index={index}
                  reorderAllowed={challengesSets.length > 1}
                  readOnly={readOnly}
                  editAllowed={editAllowed}
                  challengeSet={challengeSet}
                  onEdit={() =>
                    this.onOpenEditChallengeSet(index, challengeSet)
                  }
                  onReorder={this.onOpenReorderChallengeSet}
                  onDelete={() => this.onDeleteChallengeSet(index)}
                  formErrors={formErrors.challengesSets[index]}
                  isTierActionAllowed={isTierActionAllowed}
                />
                <ExamChallengeSet
                  examType={examType}
                  isClickable={currentUser?.hasRole(
                    [
                      ProjectRole.ProjectAdmin,
                      ProjectRole.ExamCreator,
                      ProjectRole.Reviewer,
                      UserRole.SystemAdmin,
                    ],
                    currentProjectId,
                  )}
                  hideCopy={
                    !currentUser?.hasRole(
                      [
                        UserRole.SystemAdmin,
                        ProjectRole.ProjectAdmin,
                        ProjectRole.ExamCreator,
                      ],
                      currentProjectId,
                    )
                  }
                  readOnly={readOnly}
                  editAllowed={editAllowed}
                  editPin={canEditPin}
                  showAllErrors={showAllErrors}
                  showUpdateAvailableBox={showUpdateAvailableBox}
                  challengeSet={challengeSet}
                  showExamLevelStats={showExamLevelInsights}
                  onChallengeSelect={() => this.onOpenSelectContents(index)}
                  onRemoveChallenge={(challengeIndex) =>
                    this.onRemoveChallenge(index, challengeIndex)
                  }
                  onExamChallengeEdit={(challenge) =>
                    this.onExamChallengeEditOpen(challenge, challengeSet)
                  }
                  onChallengeMoved={(newChallengeSet) =>
                    this.onChallengeMoved(index, newChallengeSet)
                  }
                  onSwitchLinkedChallenge={(challengeIndex) =>
                    this.onSwitchLinkedChallenge(index, challengeIndex)
                  }
                  onOpenChallengeReleaseNote={this.onOpenChallengeReleaseNote}
                  onOpenChallengeVersionUpConfirm={
                    this.onOpenChallengeVersionUpConfirm
                  }
                  onTogglePin={this.onTogglePin}
                  formErrors={formErrors.challengesSets[index]}
                  isTierActionAllowed={isTierActionAllowed}
                  enableViewCodePlayback={enableViewCodePlayback}
                  enableViewWebcam={enableViewWebcam}
                  enableApplicantActionLog={enableApplicantActionLog}
                />
              </div>
            ))}
            {!readOnly && editAllowed && (
              <div>
                {challengesSets.length < 10 ? (
                  <Button
                    onClick={this.onOpenOptionalSetting}
                    ariaLabel="Add New Challenge Set"
                  >
                    <Msg id="action.addOptionalChallenges" />
                  </Button>
                ) : (
                  <p>
                    <Icon className="has-warning" type="exclamation-triangle" />
                    <Msg id="exam.maximumChallengeSetCount" />
                  </p>
                )}
              </div>
            )}
          </div>
        </div>
        {this.state.isOpenContents && (
          <ChallengeSelect
            isOpen={this.state.isOpenContents}
            onSelect={this.onSelectContents}
            onCancel={this.onCloseModal}
            selectedChallenges={this.state.challengesSets.reduce(
              (total, current) => [...total, ...current.challenges],
              [],
            )}
            collectionOptions={collectionOptions}
            options={
              examType === ExamType.EnglishJapanese
                ? {
                    disableOptionKeys: ["isLinked"],
                    defaultConditions: {
                      isLinked: true,
                      spokenLanguages: [currentUser.language],
                    },
                    strictLinkedChallenge: true,
                  }
                : {}
            }
          />
        )}
        <ChallengeSetSelect
          isOpen={this.state.isOptionalSettingOpen}
          onSave={this.onAddChallengeSet}
          onCancel={this.onCloseModal}
          isTierActionAllowed={isTierActionAllowed}
        />
        <ExamChallengeEdit
          isOpen={this.state.isOpenChallengeEdit}
          editAllowed={this.props.editAllowed}
          weightEditAllowed={this.state.editingChallengeWeightEditable}
          challenge={this.state.editingChallenge}
          onOK={this.onExamChallengeEdit}
          onCancel={this.onCloseModal}
          isTierActionAllowed={isTierActionAllowed}
        />
        <ExamChallengeSetEdit
          isOpen={this.state.isOpenChallengeSetEdit}
          challengeSet={this.state.editingChallengeSet}
          onOK={this.onEditChallengeSet}
          onCancel={this.onCloseModal}
        />
        <ExamChallengeSetReorder
          isOpen={this.state.isOpenChallengeSetReorder}
          challengeSets={this.state.challengesSets}
          onOK={this.onReorderChallengeSet}
          onCancel={this.onCloseModal}
        />
        {releaseNote.isOpen &&
          releaseNote.challengeId &&
          releaseNote.usedChallengeVersionCode && (
            <ChallengeReleaseNote
              isOpen={releaseNote.isOpen}
              challengeId={releaseNote.challengeId}
              usedChallengeVersionCode={releaseNote.usedChallengeVersionCode}
              onClose={this.onCloseModal}
            />
          )}
        {this.state.challengeMajorUpdate.challenge && (
          <ChallengeUpdateConfirm
            isOpen={this.state.challengeMajorUpdate.isOpen}
            challenge={this.state.challengeMajorUpdate.challenge}
            onUpdate={this.onChallengeMajorUpdate}
            onCancel={this.onCloseModal}
          />
        )}
      </div>
    );
  }

  private updateParent = () => {
    const { challengesSets } = this.state;
    const { formValid, formErrors } = this.validate();
    this.setState({ formErrors }, () => {
      if (this.props.onFormChange) {
        this.props.onFormChange(formValid, { challengesSets }, formErrors);
      }
    });
  };

  private onCloseModal = () => {
    this.setState({
      isOpenContents: false,
      isOptionalSettingOpen: false,
      isOpenChallengeEdit: false,
      isOpenChallengeSetEdit: false,
      isOpenChallengeSetReorder: false,
      releaseNote: { isOpen: false },
      challengeMajorUpdate: { isOpen: false },
    });
  };

  private onOpenSelectContents = (selectingChallengeSetIndex: number) => {
    this.setState({ selectingChallengeSetIndex, isOpenContents: true });
  };

  private onSelectContents = (selectedChallenges: Array<ChallengeModel>) => {
    const { challengesSets, selectingChallengeSetIndex } = this.state;
    const newChallengeSets = challengesSets.map((challengeSet, index) => {
      const weight = ExamSectionUtil.getChallengeSetWeight(challengeSet);
      if (index === selectingChallengeSetIndex) {
        const challenges = selectedChallenges.map(
          (challenge) =>
            new ExamChallengeModel({
              ...challenge,
              ...{
                challengeId: challenge.id,
                originalChallenge: new OriginalChallengeModel({
                  title: challenge.title,
                  programmingLanguages: challenge.programmingLanguages,
                  linkedChallenge: challenge.linkedChallenge,
                  language: challenge.language,
                }),
                programmingLanguages:
                  challenge.style === ChallengeStyle.Development
                    ? undefined
                    : challenge.programmingLanguages,
                weight,
                timeLimitMinutes: challenge.basicTimeMinutes,
              },
            }),
        );
        return {
          ...challengeSet,
          challenges: [...challengeSet.challenges, ...challenges],
        };
      } else {
        return challengeSet;
      }
    });
    this.setState(
      {
        challengesSets: ExamSectionUtil.updateDisplayOrder(newChallengeSets),
        selectingChallengeSetIndex: undefined,
      },
      () => {
        this.updateParent();
        this.onCloseModal();
      },
    );
  };

  private onOpenOptionalSetting = () => {
    this.setState({ isOptionalSettingOpen: true });
  };

  private onAddChallengeSet = (newChallengeSet: ExamChallengeSetModel) => {
    const challengesSets = [...this.state.challengesSets, newChallengeSet];
    this.setState(
      {
        challengesSets: ExamSectionUtil.updateDisplayOrder(challengesSets),
      },
      () => {
        this.updateParent();
        this.onCloseModal();
      },
    );
  };

  private onExamChallengeEditOpen = (
    editingChallenge: ExamChallengeModel,
    challengeSet: ExamChallengeSetModel,
  ) => {
    const { isRandomSet, isOptionalSet } = challengeSet;
    this.setState({
      isOpenChallengeEdit: true,
      editingChallenge,
      editingChallengeWeightEditable: !(isRandomSet || isOptionalSet),
    });
  };

  private onExamChallengeEdit = (editingChallenge: ExamChallengeModel) => {
    const { challengesSets } = this.state;

    const newChallengesSets = challengesSets.map((challengeSet) => {
      const challenges = challengeSet.challenges.map((challenge) =>
        challenge.id === editingChallenge.id ? editingChallenge : challenge,
      );
      return { ...challengeSet, ...{ challenges } };
    });

    this.setState({ challengesSets: newChallengesSets }, () => {
      this.updateParent();
      this.onCloseModal();
    });
  };

  private onOpenEditChallengeSet = (
    editingChallengeSetIndex: number,
    editingChallengeSet: ExamChallengeSetModel,
  ) => {
    this.setState({
      isOpenChallengeSetEdit: true,
      editingChallengeSetIndex,
      editingChallengeSet,
    });
  };

  private onOpenReorderChallengeSet = () => {
    this.setState({
      isOpenChallengeSetReorder: true,
    });
  };

  private onReorderChallengeSet = (
    newChallengeSets: ExamChallengeSetModel[],
  ) => {
    this.setState({ challengesSets: newChallengeSets }, () => {
      this.updateParent();
      this.onCloseModal();
    });
  };

  private onEditChallengeSet = (
    newChallengeSet: ExamChallengeSetModel,
    weight: number,
  ) => {
    const { challengesSets, editingChallengeSetIndex } = this.state;
    const newChallengesSets = challengesSets.map((challengeSet, index) => {
      const { isOptionalSet, isRandomSet } = challengeSet;
      if (
        index === editingChallengeSetIndex &&
        (isOptionalSet || isRandomSet)
      ) {
        const challenges = challengeSet.challenges.map((challenge) => ({
          ...challenge,
          ...{ weight },
        }));
        return {
          ...challengeSet,
          ...{
            numberChallengesToTake: newChallengeSet.numberChallengesToTake,
            challenges,
            weightPlaceholderForNoChallenge: challenges.length
              ? undefined
              : weight,
          },
        } as ExamChallengeSetModel;
      } else {
        return challengeSet;
      }
    });
    this.setState({ challengesSets: newChallengesSets }, () => {
      this.updateParent();
      this.onCloseModal();
    });
  };

  private onDeleteChallengeSet = (index: number) => {
    const challengesSets = [...this.state.challengesSets];
    challengesSets.splice(index, 1);
    this.setState(
      { challengesSets: ExamSectionUtil.updateDisplayOrder(challengesSets) },
      () => {
        this.updateParent();
        this.onCloseModal();
      },
    );
  };

  private onRemoveChallenge = (
    challengeSetIndex: number,
    challengeIndex: number,
  ) => {
    const challengesSets = this.state.challengesSets.map(
      (challengeSet, index) => {
        if (index === challengeSetIndex) {
          const challenges = [...challengeSet.challenges];
          challenges.splice(challengeIndex, 1);
          return { ...challengeSet, ...{ challenges } };
        } else {
          return challengeSet;
        }
      },
    );
    this.setState(
      { challengesSets: ExamSectionUtil.updateDisplayOrder(challengesSets) },
      () => {
        this.updateParent();
        this.onCloseModal();
      },
    );
  };

  private onChallengeMoved = (
    challengeSetIndex: number,
    newChallengeSet: ExamChallengeSetModel,
  ) => {
    const challengesSets = this.state.challengesSets.map(
      (challengeSet, index) =>
        index === challengeSetIndex ? newChallengeSet : challengeSet,
    );
    this.setState(
      { challengesSets: ExamSectionUtil.updateDisplayOrder(challengesSets) },
      () => {
        this.updateParent();
      },
    );
  };

  private onSwitchLinkedChallenge = (
    targetChallengeSetIndex: number,
    targetChallengeIndex: number,
  ) => {
    const challengesSets = this.state.challengesSets.map(
      (challengeSet, challengeSetIndex) => {
        if (challengeSetIndex === targetChallengeSetIndex) {
          const challenges = challengeSet.challenges.map(
            (challenge, challengeIndex) => {
              if (challengeIndex === targetChallengeIndex) {
                return ExamSectionUtil.switchLinkedChallenge(challenge);
              } else {
                return challenge;
              }
            },
          );
          return {
            ...challengeSet,
            ...{ challenges },
          };
        } else {
          return challengeSet;
        }
      },
    );

    if (!ExamSectionUtil.isAllChallengesNotDuplicated(challengesSets)) {
      toast.warning(Message.getMessageByKey("exam.linkedChallengeError"));
    } else {
      this.setState(
        { challengesSets: ExamSectionUtil.updateDisplayOrder(challengesSets) },
        () => {
          this.updateParent();
        },
      );
    }
  };

  private onOpenChallengeReleaseNote = (
    challengeId: number,
    usedChallengeVersionCode: string,
  ) => {
    this.setState({
      releaseNote: { isOpen: true, challengeId, usedChallengeVersionCode },
    });
  };

  private onOpenChallengeVersionUpConfirm = (challenge: ExamChallengeModel) => {
    this.setState({ challengeMajorUpdate: { isOpen: true, challenge } });
  };

  private onChallengeMajorUpdate = () => {
    const {
      challengeMajorUpdate: { challenge },
    } = this.state;
    if (!challenge) {
      throw new Error(
        "challenge should not be empty while the major updating.",
      );
    }
    const { challengeId, nextAvailableChallengeVersionCode } = challenge;
    if (!nextAvailableChallengeVersionCode) {
      throw new Error("nextAvailableChallengeVersionCode should not be empty.");
    }
    this.props.challengeMajorUpdate(
      challengeId,
      nextAvailableChallengeVersionCode,
    );
  };

  private onTogglePin = (challengeId: number, pinId?: number) => {
    pinId !== undefined
      ? this.props.deletePinChallenge.mutate({ challengeId, pinId })
      : this.props.addPinChallenge.mutate(challengeId);
  };

  private validate = () => {
    const { challengesSets } = this.state;

    const examChallengeSetCountError =
      ExamSectionUtil.isExamChallengeSetCountValid(challengesSets);
    const totalWeightError = ExamSectionUtil.isTotalWeightValid(challengesSets);

    const isChallengesSetsValid = challengesSets.map((challengeSet, index) => {
      const isChallengeSetCountValid =
        ExamSectionUtil.isChallengeSetCountValid(challengeSet);
      return { ...isChallengeSetCountValid };
    });

    const formErrors = {
      totalWeight: totalWeightError,
      examChallengeSetCount: examChallengeSetCountError,
      challengesSets: isChallengesSetsValid,
    };

    const isChallengesSetsErrorCount = formErrors.challengesSets.reduce(
      (total, error) => total + Object.keys(error).length,
      0,
    );

    const formValid =
      !examChallengeSetCountError &&
      !totalWeightError &&
      isChallengesSetsErrorCount === 0;

    return { formValid, formErrors };
  };
}

export default ExamChallenges;
