import { DeliveryMethod } from "../services/enums";
import ApplicantExamModel from "./ApplicantExam.model";

class IdExamDeliveryModel {
  public id: number;
  public deliveryMethod: DeliveryMethod;
  public examId: string;
  public startAt: string;
  public endAt: string;
  public dynamicDeadlineHours: number;
  public language?: string;
  public passwordRequired: boolean;
  public createdBy: number;
  public updatedBy: number;
  public createdAt: string;
  public updatedAt: string;
  public applicantExams: Array<ApplicantExamModel> = [];

  constructor(args?: {}) {
    Object.assign(this, args);
  }
}

export default IdExamDeliveryModel;
