import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { ExamReviewerPayload, putExamReviewer } from "@api/exams";

import { useStoreContext } from "@context";

import Message from "@shared/services/message";

import { useInvalidateSubmissionRelation } from "../submissions";
import { examKeys } from "./useExam";

interface UpdateExamReviewersParams {
  examId: number;
  data: ExamReviewerPayload;
  hideToast?: boolean;
}

export function useUpdateExamReviewers() {
  const client = useQueryClient();
  const { projectId } = useStoreContext();
  const invalidateSubmission = useInvalidateSubmissionRelation();

  const mutation = useMutation({
    mutationFn: ({ examId, data }: UpdateExamReviewersParams) =>
      putExamReviewer({ examId, projectId, options: { data } }),
    onSuccess: (_, { examId, hideToast }) => {
      if (!hideToast) {
        toast.success(Message.getMessageByKey("message.exam.updated"));
      }

      invalidateSubmission();
      client.invalidateQueries(examKeys.detail(projectId, examId));
    },
  });

  return mutation;
}
