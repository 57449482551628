import { connect } from "react-redux";

import { RootState } from "@reducers";

import { getTierAllowedByRedux } from "@shared/services/tier";

import Setting from "./Setting";

const mapStateToProps = (state: RootState) => ({
  currentUser: state.user.user,
  submitting: state.ajax.submitting && !state.project.projectMemberListLoading,
  currentProject: state.project.currentProjectId,
  isTierActionAllowed: getTierAllowedByRedux(state),
});

/**
 * Wrapped component
 */
const wrappedComponent = connect(mapStateToProps, () => ({}))(Setting);

export default wrappedComponent;
