import * as Bowser from "bowser";

import Logger from "./logger";

const bowser = Bowser.getParser(window.navigator.userAgent);
Logger.info("bowser", bowser.getResult());

/*
 * Minimum Versions
 * Any Browsers not on this list are also unsupported
 *
 * BROWSER_ALIASES_MAP
 * https://github.com/lancedikson/bowser/blob/master/src/constants.js#L3
 */
const supportedBrowsers = {
  chrome: ">=50",
  firefox: ">=50",
  edge: ">=10",
  safari: ">=10",
};

/**
 * see: https://github.com/lancedikson/bowser/blob/master/src/constants.js
 */
const platform = bowser.getPlatform();
const ua = bowser.getBrowser();
const os = bowser.getOS();

const isSupported = Boolean(bowser.satisfies(supportedBrowsers));
const isMobile = ["mobile", "tablet"].includes(platform.type ?? "");
const isDesktop = platform.type === "desktop";

const isMSEdge = bowser.isBrowser("Microsoft Edge");
const isMSIE = bowser.isBrowser("Internet Explorer");

export {
  isSupported,
  isDesktop,
  isMobile,
  isMSEdge,
  isMSIE,
  bowser,
  ua,
  platform,
  os,
};
