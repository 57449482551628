import { GIVERY_ORGANIZATION_ID } from "./../models/Organization.model";
import { OrganizationKind } from "./enums";

export const isGiveryOrg = (orgId?: number) => {
  return orgId === GIVERY_ORGANIZATION_ID;
};

export const isOfficialOrg = (orgKind?: number) => {
  return orgKind === OrganizationKind.Official;
};
