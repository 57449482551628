import {
  Action,
  applicantExamOpenAction,
  applicantExamSubmitAction,
  challengeSubmitAction,
  challengeTimeLeftAction,
  applicantExamTimeLeftAction,
  applicantTickAction,
  APIResponseAction,
} from "../actions";
import {
  ApplicantChallengeResultModel,
  ApplicantExamTimerModel,
  ApplicantChallengeTimerModel,
} from "../shared/models";

/**
 * Time state machine
 * - not start    : undefined
 * - in progress  : +
 * - times ran out: 0
 * - error        : -1 (mostly extended deadline)
 */
export interface ApplicantTimerState {
  exam: ApplicantExamTimerModel;
  challenges: ApplicantChallengeTimerModel[];
  error?: boolean;
}

export const initialState: ApplicantTimerState = {
  exam: { submitted: false },
  challenges: [],
};

export const applicantTimerReducer = (
  state: ApplicantTimerState = initialState,
  action: Action,
): ApplicantTimerState => {
  // TODO string type
  const payload = (
    action as APIResponseAction & {
      payload: { result: any; challengeId?: number };
    }
  ).payload;

  switch (action.type) {
    case applicantExamOpenAction.types.success: {
      return initialState;
    }
    case applicantExamSubmitAction.types.success: {
      const challenges = state.challenges.map((item) => {
        const { challengeId } = item;
        return {
          challengeId,
          seconds: 0,
          submitted: true,
        };
      });
      return {
        ...state,
        exam: { seconds: 0, submitted: true },
        challenges,
      };
    }
    case challengeSubmitAction.types.success: {
      // TODO string type
      const { challengeId, id: resultId } = (
        payload as {
          result: ApplicantChallengeResultModel;
        }
      ).result;
      const challenges = state.challenges.map((item) =>
        item.challengeId === challengeId
          ? { challengeId, resultId, seconds: 0, submitted: true }
          : item,
      );
      return {
        ...state,
        challenges,
      };
    }
    case challengeTimeLeftAction.types.success:
    case challengeTimeLeftAction.types.failure: {
      // TODO string type
      const { result, challengeId, extra } = payload as {
        result: number;
        challengeId: number;
        extra?: string;
      };

      const challenge = { challengeId, seconds: result };
      const index = state.challenges.findIndex(
        (item) => item.challengeId === challengeId,
      );
      const challenges =
        index === -1
          ? [...state.challenges, challenge]
          : [
              ...state.challenges.slice(0, index),
              challenge,
              ...state.challenges.slice(index + 1),
            ];
      return {
        ...state,
        challenges,
        // maybe deadline has extended. it needs to reload.
        error: Boolean(extra && extra.includes("404")),
      };
    }
    case applicantExamTimeLeftAction.types.success: {
      // TODO string type
      const seconds = payload.result as number;
      return {
        ...state,
        exam: {
          ...state.exam,
          ...{
            seconds,
            lastRecordedMillisLeft: seconds * 1000,
            lastRecordedTime: performance.now(),
          },
        },
      };
    }
    case applicantTickAction.types.request: {
      const challenges = state.challenges.map((item) => ({
        ...item,
        ...{
          seconds: tick(item.seconds),
        },
      }));
      const seconds = tick(state.exam.seconds);
      const isSecondsDefined = seconds !== undefined && seconds >= 0;
      let { lastRecordedMillisLeft, lastRecordedTime } = state.exam;

      if (isSecondsDefined) {
        lastRecordedMillisLeft = seconds * 1000;
        lastRecordedTime = performance.now();
      }

      return {
        ...state,
        exam: {
          ...state.exam,
          ...{
            seconds,
            lastRecordedMillisLeft,
            lastRecordedTime,
          },
        },
        challenges,
      };
    }
    default:
      return state;
  }
};

export function tick(seconds?: number) {
  // -1 means deadline extended. don't touch this!!
  return seconds === undefined || seconds <= 0
    ? seconds
    : Math.max(0, seconds - 1);
}
