import * as classnames from "classnames";
import * as React from "react";
import { Link } from "react-router-dom";

/**
 *
 */
export interface TabItem {
  label: React.ReactNode;
  to?: string;
  disabled?: boolean;
}

/**
 * Prop interface
 */
export interface TabsProps {
  tabItems: TabItem[];
  activeIndex?: number;
  onClick?: (index: number) => void;
  className?: string;
}

/**
 * React Component
 */
export default function Tabs({
  tabItems = [],
  activeIndex = 0,
  onClick,
  className,
}: TabsProps) {
  const rootStyle = classnames("code-c-tabs", "tabs", "is-boxed", {
    [`${className}`]: Boolean(className),
  });

  const tabItemElements = tabItems.map((item: TabItem, index: number) => {
    const tabItemStyles = classnames("code-c-tabs__item", {
      "is-active": item.to
        ? item.to === location.pathname
        : activeIndex === index,
      disabled: item.disabled,
    });

    function handleClick() {
      if (onClick) {
        onClick(index);
      }
    }

    const link = item.to ? (
      <Link
        to={{
          pathname: item.to,
          state: { clickTab: true },
        }}
      >
        {item.label}
      </Link>
    ) : (
      <a onClick={handleClick}>{item.label}</a>
    );

    return (
      <li key={index} className={tabItemStyles}>
        {link}
      </li>
    );
  });

  return (
    <div className={rootStyle}>
      <ul>{tabItemElements}</ul>
    </div>
  );
}
