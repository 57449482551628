import { AxiosRequestConfig } from "axios";

import { Pagination, get } from "@api/httpClient";

import { ExamModel, OfficialExamListModel } from "@shared/models";

interface GetOfficialExamListParams {
  projectId: number;
  options: AxiosRequestConfig;
}

interface GetOfficialExamParams extends GetOfficialExamListParams {
  examId: number;
}

export async function getOfficialExamList({
  projectId,
  options,
}: GetOfficialExamListParams) {
  const { data } = await get<
    OfficialExamListModel[],
    Pagination<OfficialExamListModel[]>
  >(`/api/projects/${projectId}/exams/officials`, options);

  return data;
}

export async function getOfficialExam({
  examId,
  projectId,
  options,
}: GetOfficialExamParams) {
  const { data } = await get<ExamModel>(
    `/api/projects/${projectId}/exams/officials/${examId}`,
    options,
  );

  return data;
}
