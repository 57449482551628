import { parseISO } from "date-fns";

import {
  ActionType,
  ExamChallengeModel,
  SubmissionListModel,
  SubmissionResultListModel,
  VersionType,
} from "@shared/models";
import {
  ApplicantExamStatus,
  ChallengeResultStatus,
  ChallengeStyle,
} from "@shared/services/enums";

import { isSubmissionLatestVersion } from "./challengeVersion";

const {
  NotModified,
  Started,
  InProgress,
  Finished,
  CanRestart,
  ScoringWaiting,
} = ChallengeResultStatus;

export function getTagColor(
  challengeResultStatus: ChallengeResultStatus,
): "green" | "neutral" | "yellow" {
  return [Finished, ScoringWaiting].includes(challengeResultStatus)
    ? "yellow"
    : [Started, InProgress].includes(challengeResultStatus)
    ? "green"
    : "neutral";
}

export const getSelectedChallengeResult = (
  submission: SubmissionListModel,
  challenge?: ExamChallengeModel,
): SubmissionResultListModel | undefined => {
  return submission.challengeResults?.find(
    (result) => result.challengeId === challenge?.challengeId,
  );
};

export const isValidStatus = ({
  submission,
  challenge,
  action,
  version,
}: {
  submission: SubmissionListModel;
  challenge?: ExamChallengeModel;
  action?: ActionType;
  version?: VersionType;
}): {
  isValid: boolean;
  detail: {
    isValidSubmissionStatus: boolean;
    isSupportedChallengeStatus: boolean;
    isSupportedVersion: boolean;
  };
} => {
  const submissionResult = getSelectedChallengeResult(submission, challenge);
  const challengeResultStatus = submissionResult?.challengeResultStatus;
  const isValidSubmissionStatus = ![
    ApplicantExamStatus.Unread,
    ApplicantExamStatus.Canceled,
    ApplicantExamStatus.Expired,
  ].includes(submission.status);

  const supportedChallengeStatus =
    action === ActionType.Rescore
      ? version === VersionType.Current
        ? [Finished, ScoringWaiting]
        : [Finished, CanRestart, ScoringWaiting]
      : version === VersionType.Current
      ? [Started, InProgress, Finished, ScoringWaiting, NotModified]
      : [
          Started,
          InProgress,
          Finished,
          CanRestart,
          ScoringWaiting,
          NotModified,
        ];

  const isSupportedChallengeStatus = Boolean(
    challengeResultStatus &&
      supportedChallengeStatus.includes(challengeResultStatus),
  );

  const isSupportedVersion =
    challengeResultStatus === CanRestart
      ? !isSubmissionLatestVersion({
          examChallenge: challenge,
          submissionResult,
        })
      : true;

  return {
    isValid:
      isValidSubmissionStatus &&
      isSupportedChallengeStatus &&
      isSupportedVersion,
    detail: {
      isValidSubmissionStatus,
      isSupportedChallengeStatus,
      isSupportedVersion,
    },
  };
};

export const isNotStartedSubmission = (
  status: ChallengeResultStatus | undefined,
  submissionStatus: ApplicantExamStatus,
) =>
  ([ApplicantExamStatus.Unread, ApplicantExamStatus.InProgress].includes(
    submissionStatus,
  ) &&
    !status) ||
  status === ChallengeResultStatus.CanRestart;

export const cannotRescore = (
  style: ChallengeStyle,
  action?: ActionType,
): boolean =>
  action === ActionType.Rescore &&
  [ChallengeStyle.Quiz, ChallengeStyle.AI].includes(style);

export const hasPastDeadlineSubmissions = (
  submissions?: SubmissionListModel[],
): boolean =>
  submissions?.some(
    (submission) => parseISO(submission.deadline) < new Date(),
  ) ?? false;

export const getChallengeStatusText = (
  challengeStatus?: ChallengeResultStatus,
  submissionStatus?: ApplicantExamStatus,
): string =>
  ChallengeResultStatus.toString(
    ApplicantExamStatus.Expired === submissionStatus && !challengeStatus
      ? NotModified
      : challengeStatus,
  );
