import { Placement } from "@popperjs/core";
import * as classnames from "classnames";
import * as React from "react";

import { Tooltip } from "@shared/components/tooltip/Tooltip";

/**
 * Prop interface
 */
export interface WarningIconProps {
  className?: string;
  tooltipText?: React.ReactNode;
  tooltipPlacement?: Placement;
}

/**
 * React Component
 */
export function WarningIcon({
  className,
  tooltipText,
  tooltipPlacement,
}: WarningIconProps) {
  const rootStyle = classnames(
    "code-c-warning-icon",
    "code-c-icon",
    "fa-stack",
    {
      [`${className}`]: Boolean(className),
    },
  );

  const warningIcon = (
    <span className={rootStyle}>
      <i className="fa fa-exclamation-triangle fa-stack-1x warning-background" />
      <i className="fa fa-exclamation fa-stack-1x" />
      <i className="fa fa-exclamation-triangle fa-stack-1x" />
    </span>
  );

  return tooltipText ? (
    <Tooltip text={tooltipText} placement={tooltipPlacement}>
      {warningIcon}
    </Tooltip>
  ) : (
    warningIcon
  );
}
