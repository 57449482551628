import { connect } from "react-redux";

import { RootState } from "@reducers";

import ContentsList from "./ContentsList";

const mapStateToProps = (state: RootState) => ({
  currentProjectId: state.project.currentProjectId,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ContentsList);
