import { AxiosRequestConfig } from "axios";

import { ApiResponse, put } from "@api/httpClient";

import { CustomFormDefinitionModel } from "@shared/models";

export interface CustomFormDefinitionPayload {
  formDefinitions: CustomFormDefinitionModel[];
  applicantNameRequired: boolean;
}

interface ExamChallengeParam {
  examId: number;
  projectId: number;
  options: AxiosRequestConfig<CustomFormDefinitionPayload>;
}

export async function putExamCustomForm({
  examId,
  projectId,
  options,
}: ExamChallengeParam) {
  const { data } = await put<ApiResponse>(
    `/api/projects/${projectId}/exams/${examId}/forms`,
    options,
  );

  return data;
}
