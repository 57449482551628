class BaseEnumModel {
  public displayOrder: number;
  public displayString: string;
  public messageKey: string;
  public descriptionString?: string;

  // for tier actions
  public actions?: number[];
  public maxProjectUsers?: number;

  // for org categories
  public mainCategory?: string;

  public uiSelectable?: boolean;

  constructor(args: {}) {
    Object.assign(this, args);
  }
}

class EnumModel extends BaseEnumModel {
  public value: number;
}

export class EnumWithStringValueModel extends BaseEnumModel {
  public value: string;
}

export default EnumModel;
