import { AxiosRequestConfig } from "axios";

import { get } from "@api/httpClient";

import { ReportExamScore } from "@shared/models";

interface ExamStatisticsParam {
  examId: number;
  projectId: number;
  options: AxiosRequestConfig<{ applicantExamIdForRanking: number }>;
}

// all field will be absent when count is 0
export interface ExamScoreStatisticsResponse {
  average?: number;
  median?: number;
  max?: number;
  standardDeviationPop?: number;
  standardDeviationSamp?: number; // count 1 is also absent
  count: number;
  queryRelative?: {
    // This is returned ONLY when applicantExamId is specified, AND that submission has a finalScore.
    rank: number;
  };
}

export interface ExamStatisticsResponse {
  examId: number;
  examScoreStatistics: ExamScoreStatisticsResponse;
  examScoreDistribution: ReportExamScore[];
}

export async function getExamStatistics({
  examId,
  projectId,
  options,
}: ExamStatisticsParam) {
  const { data } = await get<ExamStatisticsResponse>(
    `/api/projects/${projectId}/exams/${examId}/statistics`,
    options,
  );

  return data;
}
