import * as classnames from "classnames";

import { ErrorTile } from "../../../../shared/components";
import Message from "../../../../shared/services/message";

/**
 * Prop interface
 */
export type WarnTrainingProps = {};

/**
 * Page component
 */
export default function WarnTraining(props: WarnTrainingProps) {
  const rootStyle = classnames("code-warn-training");
  return (
    <div className={rootStyle}>
      <ErrorTile
        onClickBackPathName="/signout"
        errorCode="403"
        errorMessage={Message.getMessageByKey("error.trainingWarn.title")}
        errorDescription={Message.getMessageByKey(
          "error.trainingWarn.description",
        )}
      />
    </div>
  );
}
