import classNames from "classnames";

import { useStoreContext } from "@context";

import { useExamPermissions } from "@shared/hooks";
import {
  useExam,
  useExamJobTitleMap,
  usePurposeOfUseMap,
} from "@shared/hooks/query";
import { ExamType } from "@shared/services/enums";
import MSG from "@shared/services/message";
import { isGiveryOrg } from "@shared/services/organization";

import { TagItem } from "../tag";
import TagCloud from "../tagCloud/TagCloud";

interface ExamSegmentTags {
  className?: string;
}

function ExamSegmentTags({ className }: ExamSegmentTags) {
  const { user } = useStoreContext();
  const {
    data: { examDetail },
  } = useExam();
  const rootStyle = classNames("code-c-exam-segment-tags", className);
  const { data: jobTitleMap } = useExamJobTitleMap();
  const { data: purposeOfUseMap } = usePurposeOfUseMap();
  const { multiLangAllowed } = useExamPermissions();

  const tagItems: TagItem[] = [];

  if (examDetail.archivedAt) {
    tagItems.push({
      label: MSG.getMessageByKey("common.inactive"),
      colorOverride: "gray-darkest",
    });
  }

  if (isGiveryOrg(user?.organizationId) && examDetail.isOfficial) {
    tagItems.push({
      label: MSG.getMessageByKey("exam.officialExam"),
      hasError: !multiLangAllowed,
      colorOverride: "green",
      darkFont: true,
    });
  }

  if (examDetail.examType === ExamType.EnglishJapanese) {
    tagItems.push({
      label: MSG.getMessageByKey("exam.multilanguage"),
      colorOverride: "pink",
    });
  }

  if (examDetail.examSegment && purposeOfUseMap && jobTitleMap) {
    tagItems.push(
      {
        label: purposeOfUseMap[examDetail.examSegment.purposeOfUse],
      },
      {
        label: jobTitleMap[examDetail.examSegment.engineerRole],
      },
    );
  }

  return (
    <TagCloud
      className={rootStyle}
      tagItems={tagItems}
      color="gray-lightest"
      opacity="80"
    />
  );
}

export default ExamSegmentTags;
