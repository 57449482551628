import * as classnames from "classnames";

import { CustomFormDefinitionDisplay } from "..";
import {
  Button,
  Icon,
  LanguageTag,
  Msg,
  SortableItemList,
} from "../../../../../../../shared/components";
import { CustomFormDefinitionModel } from "../../../../../../../shared/models";

/**
 * Prop interface
 */
export interface CustomFormConfigureBoxProps {
  definitions: CustomFormDefinitionModel[];
  language?: string;
  onEdit: (definition?: CustomFormDefinitionModel, language?: string) => void;
  onRemove: (definition: CustomFormDefinitionModel) => void;
  onReorderItem: (sortItemIndexes: number[], language?: string) => void;
}

/**
 * React Component
 */
export function CustomFormConfigureBox({
  definitions,
  language,
  onEdit,
  onRemove,
  onReorderItem,
}: CustomFormConfigureBoxProps) {
  /**
   * State
   */

  /**
   * Private Functions
   */
  const rootStyle = classnames("code-c-custom-form-configure-box");

  /**
   * Render
   */
  return (
    <div className={rootStyle}>
      <div className="code-c-custom-form-configure-box__actions">
        {language && <LanguageTag language={language} color="pink" />}
        <Button
          size="small"
          shrink={true}
          ariaLabel="Add New Entry Form"
          onClick={() => onEdit(undefined, language)}
        >
          <Icon type="plus" />
          <Msg id="entryForm.addItem" />
        </Button>
      </div>
      <SortableItemList
        className="code-c-custom-form-configure-box__table"
        accept={language}
        items={definitions
          .filter((definition) =>
            language ? definition.language === language : true,
          )
          .map((definistion, index) => ({
            item: (
              <CustomFormDefinitionDisplay
                key={definistion.key}
                definition={new CustomFormDefinitionModel(definistion)}
                onEdit={() => onEdit(definistion, language)}
                onRemove={() => onRemove(definistion)}
              />
            ),
            id: index,
          }))}
        onReorderItem={(sortItemIndexes: number[]) =>
          onReorderItem(sortItemIndexes, language)
        }
      />
    </div>
  );
}
