import * as React from "react";
import { ReactDatePickerProps } from "react-datepicker";

interface ReadOnlyCustomInputProps {
  id?: string;
  name?: string;
  value?: string;
  options?: Partial<ReactDatePickerProps>;
  onClick?: () => void;
  onKeyDown?: () => void;
}

// Custom component for the readOnly appearance
const ReadOnlyCustomInput = React.forwardRef<
  HTMLInputElement,
  ReadOnlyCustomInputProps
>((props, ref) => (
  <input
    className="code-c-date-picker__readonly-custom-input input"
    id={props.id}
    name={props.name}
    value={props.value}
    readOnly={true}
    onClick={props.onClick}
    onKeyDown={props.onKeyDown}
    placeholder={(props.options?.dateFormat as string).toUpperCase()}
    ref={ref}
  />
));

ReadOnlyCustomInput.displayName = "ReadOnlyCustomInput";

export { ReadOnlyCustomInput };
