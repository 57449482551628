import { useHoverBox } from "@shared/hooks";

import { ReviewSummary } from "../../../../../../../../shared/components";
import {
  ReviewModel,
  ReviewerUserModel,
} from "../../../../../../../../shared/models";

/**
 * Interface
 */
interface ReviewSummaryBoxProps {
  reviews: ReviewModel[];
  pendingReviewers: ReviewerUserModel[];
  targetElement?: HTMLDivElement;
}

/**
 * Component
 * @param props ReviewSummaryBoxProps
 */
export function ReviewSummaryBox({
  reviews = [],
  pendingReviewers = [],
  targetElement,
}: ReviewSummaryBoxProps) {
  const { styles, attributes, setPopperElement } = useHoverBox({
    targetElement,
    offset: [72, 8],
  });

  return (
    <div
      className="code-c-review-summary-box"
      ref={setPopperElement}
      style={styles.popper}
      {...attributes.popper}
    >
      <ReviewSummary
        reviews={reviews}
        pendingCount={pendingReviewers.length}
        style="column"
      />
    </div>
  );
}
