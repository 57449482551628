import * as classnames from "classnames";

import { SearchSelect, SelectOption } from "../../../shared/components";
import { OrganizationModel } from "../../../shared/models";

/**
 * Prop interface
 */
export interface OrgPickerProps {
  className?: string;
  selectedOrg: OrganizationModel;
  orgList: Array<OrganizationModel>;
  setOrg: (org: { name: string }) => void;
}

/**
 * React Component
 */
export default function OrgPicker({
  className,
  orgList,
  setOrg,
  selectedOrg,
}: OrgPickerProps) {
  const rootStyle = classnames("code-c-org-picker", {
    [`${className}`]: Boolean(className),
  });

  const orgOptions = orgList.map((org) => ({
    value: org.id,
    label: org.displayName
      ? `${org.name} -- ${org.displayName}`
      : `${org.name}`,
  }));

  const setOrganization = (orgSelect: SelectOption) => {
    const { value } = orgSelect;
    const organization = orgList.find((org) => org.id === Number(value));

    if (organization) {
      setOrg(organization);
    }
  };

  return (
    <div className={rootStyle}>
      <SearchSelect
        selected={selectedOrg.id}
        options={orgOptions}
        onChange={setOrganization}
        autoWidth
      />
    </div>
  );
}
