import { Helmet } from "react-helmet";

/**
 * Prop interface
 */
export interface DocumentTitleProps {
  page: string;
  orgname: string;
}

/**
 * React Component
 */
export default function DocumentTitle({ page, orgname }: DocumentTitleProps) {
  return (
    <Helmet>
      <title>{`${page} - ${orgname} - Track Test`}</title>
    </Helmet>
  );
}
