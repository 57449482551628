import * as classnames from "classnames";
import * as React from "react";

import {
  Modal,
  Msg,
  Form,
  FormGroup,
  Checkbox,
  Label,
  Textarea,
} from "@shared/components";
import Message from "@shared/services/message";

/**
 * Interface
 */
export type ExternalProps = {
  cancelMultiple: boolean;
  isOpen: boolean;
  hasMemo: boolean;
  onOK: (memo?: string) => void;
  onClose: () => void;
};

export type InjectedProps = {};

export type DeliveryCancelModalProps = InjectedProps & ExternalProps;

type DeliveryCancelModalForm = {
  hasMemo: boolean;
  memo: string;
};

/**
 * Component
 * @param props DeliveryCancelModalProps
 */
export function DeliveryCancelModal(props: DeliveryCancelModalProps) {
  const { isOpen, hasMemo, cancelMultiple } = props;

  /**
   * States
   */
  const [formValues, setFormValues] = React.useState<DeliveryCancelModalForm>({
    hasMemo: false,
    memo: "",
  });

  /**
   * Private Functions
   */
  const rootStyle = classnames("code-delivery-cancel-modal", {
    "has-memo": hasMemo,
  });

  const onFormChange = (
    formValid: boolean,
    formValue: DeliveryCancelModalForm,
  ) => {
    setFormValues(formValue);
  };

  const onClickOk = () => {
    const { hasMemo, memo } = formValues;
    props.onOK(hasMemo ? memo.trim() : undefined);
  };

  const texts = cancelMultiple
    ? {
        title: "delivery.cancelAll",
        okButtonLabel: "delivery.cancelAll",
        ariaLabel: "Cancel Deliveries",
        text: "delivery.cancelAll.confirm",
        memo: "delivery.cancelAll.memo",
        memoLabel: "delivery.cancel.memo.label",
      }
    : {
        title: "delivery.cancel",
        okButtonLabel: "delivery.cancel",
        ariaLabel: "Cancel Delivery",
        text: "delivery.cancel.confirm",
        memo: "delivery.cancel.memo",
        memoLabel: "delivery.cancel.memo.label",
      };

  /**
   * Render
   */
  return (
    <Modal
      className={rootStyle}
      title={Message.getMessageByKey(texts.title)}
      isOpen={isOpen}
      onClickOk={onClickOk}
      onClose={props.onClose}
      onClickCancel={props.onClose}
      cancelButtonLabel={Message.getMessageByKey("close")}
      cancelButtonAriaLabel="Close"
      okButtonLabel={Message.getMessageByKey(texts.okButtonLabel)}
      okButtonType="danger"
      okButtonAriaLabel={texts.ariaLabel}
      ariaLabel={texts.ariaLabel}
    >
      <p className="code-delivery-cancel-modal__text">
        <Msg id={texts.text} />
      </p>
      {hasMemo && (
        <Form
          validation={{ hasMemo: ["boolean"], memo: ["string"] }}
          initialValues={{ hasMemo: false, memo: "" }}
          onFormChange={onFormChange}
        >
          <FormGroup>
            <Checkbox name="hasMemo">
              <Msg id={texts.memo} />
            </Checkbox>
            {formValues.hasMemo && (
              <div className="code-delivery-cancel-modal__memo">
                <Label title={<Msg id={texts.memoLabel} />} htmlFor="memo" />
                <Textarea id="memo" name="memo" />
              </div>
            )}
          </FormGroup>
        </Form>
      )}
    </Modal>
  );
}
