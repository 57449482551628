import { useEffect, useMemo } from "react";

import {
  useGetSubmissionsPreviewContentBatch,
  useGetSubmissionResultPlayback,
  useGetSubmissionsResultDetail,
  useExam,
} from "@shared/hooks/query";
import { ApplicantActionType } from "@shared/services/enums";
import { getSaveHistoryIds } from "@shared/services/keyEvents";

import { CodePlaybackProps } from "./CodePlayback";

export const useCodePlaybackData = (
  props: CodePlaybackProps & { currentKeyEventLogId: number | undefined },
) => {
  const {
    challengeId,
    submissionId,
    currentKeyEventLogId = 0,
    actionLogs,
    readme,
    getChallenge,
    getAllActionLog,
  } = props;

  useExam();
  const { data: resultDetail } = useGetSubmissionsResultDetail(challengeId);
  const { data: submissionKeyEventLogs } = useGetSubmissionResultPlayback(
    resultDetail.id,
    currentKeyEventLogId,
  );
  const isReadMeReady = readme !== undefined;

  const isResultDetailReady = Boolean(
    resultDetail && Array.isArray(resultDetail.codingResult.keyEventLogs),
  );

  const keyEventLogs = useMemo(() => {
    const keyEventLogIds =
      resultDetail?.codingResult.keyEventLogs?.map((item) => item.id) || [];
    return (
      submissionKeyEventLogs.filter((item) =>
        keyEventLogIds.includes(item.keyEventLog.id),
      ) || []
    );
  }, [resultDetail?.codingResult.keyEventLogs, submissionKeyEventLogs]);
  const isActionLogReady = Boolean(actionLogs.length > 0);

  const { currentKeyEventLog, previewContentIds } = useMemo(() => {
    const currentKeyEventLog =
      currentKeyEventLogId !== undefined
        ? keyEventLogs.find(
            (item) => item.keyEventLog.id === currentKeyEventLogId,
          )
        : undefined;

    const previewContentIds =
      currentKeyEventLog && isActionLogReady
        ? [
            ...Object.values(
              currentKeyEventLog.keyEventLog.metadata.loadIndices,
            ),
            // Save indices from key event log might be missing
            // (e.g. user submits the challenge before the save has finished)
            // so it's better to rely on  save history ids from the action logs
            ...getSaveHistoryIds(actionLogs, currentKeyEventLog),
          ]
        : [];

    return { previewContentIds, currentKeyEventLog };
  }, [actionLogs, currentKeyEventLogId, isActionLogReady, keyEventLogs]);
  const previewContents = useGetSubmissionsPreviewContentBatch({
    challengeId,
    challengeResultId: resultDetail.id,
    currentKeyEventLog,
    eventLogId: currentKeyEventLogId,
  });

  const isPreviewContentReady =
    previewContentIds.length > 0 &&
    previewContents.length > 0 &&
    previewContentIds.every((id) => {
      return Boolean(previewContents.find((item) => item.historyId === id));
    });

  const ready =
    Boolean(currentKeyEventLog) &&
    isResultDetailReady &&
    isPreviewContentReady &&
    isActionLogReady &&
    isReadMeReady;

  useEffect(() => {
    getChallenge(challengeId);
  }, [challengeId, getChallenge]);

  useEffect(() => {
    if (isResultDetailReady && currentKeyEventLogId) {
      const { id: challengeResultId } = resultDetail;

      getAllActionLog(submissionId, {
        challengeResultId,
        actions: [
          ApplicantActionType.CopyPaste,
          ApplicantActionType.TabUnfocus,
          ApplicantActionType.SaveChallenge,
          ApplicantActionType.LoadChallenge,
          ApplicantActionType.Screenshot,
        ],
      });
    }
  }, [
    isResultDetailReady,
    submissionId,
    currentKeyEventLogId,
    resultDetail,
    getAllActionLog,
  ]);

  return {
    ready,
    readme,
    resultDetail,
    keyEventLogs,
    currentKeyEventLog,
    actionLogs,
    previewContents: previewContents.filter((item) =>
      previewContentIds.includes(item.historyId),
    ),
  };
};
