import { Epic } from "redux-observable";

import {
  Action,
  ajaxAction,
  adminChallengeCollectionListAction,
  adminChallengeCollectionDeleteAction,
  adminChallengeCollectionCreateAction,
  adminChallengeCollectionUpdateAction,
  alertAction,
  adminChallengeCollectionGetAction,
  APIResponseAction,
} from "../actions";
import { RootState } from "../reducers";
import { AdminChallengeCollectionListModel } from "../shared/models";
import Message from "../shared/services/message";

export const adminChallengeCollectionListEpic: Epic<Action, RootState> = (
  action$,
) =>
  action$
    .ofType(adminChallengeCollectionListAction.types.request)
    .debounceTime(275)
    .map((action) =>
      ajaxAction.request({
        method: "get",
        url: `/api/admin/challengecollections`,
        body: action.payload,
        success: adminChallengeCollectionListAction.success,
        error: adminChallengeCollectionListAction.failure,
        cancel: [adminChallengeCollectionListAction.types.request],
      }),
    );

export const adminChallengeCollectionGetEpic: Epic<Action, RootState> = (
  action$,
) =>
  action$
    .ofType(adminChallengeCollectionGetAction.types.request)
    .map((action) =>
      ajaxAction.request({
        method: "get",
        url: `/api/admin/challengecollections/${action.payload}`,
        success: adminChallengeCollectionGetAction.success,
        error: adminChallengeCollectionGetAction.failure,
      }),
    );

export const adminChallengeCollectionCreateEpic: Epic<Action, RootState> = (
  action$,
) =>
  action$
    .ofType(adminChallengeCollectionCreateAction.types.request)
    .map((action) =>
      ajaxAction.request({
        method: "post",
        url: `/api/admin/challengecollections`,
        body: action.payload,
        success: alertAction.success(
          Message.getMessageByKey("message.challengeCollection.create.success"),
          adminChallengeCollectionCreateAction.success,
        ),
        error: adminChallengeCollectionCreateAction.failure,
      }),
    );

export const adminChallengeCollectionUpdateEpic: Epic<Action, RootState> = (
  action$,
) =>
  action$
    .ofType(adminChallengeCollectionUpdateAction.types.request)
    .map((action) =>
      ajaxAction.request({
        method: "put",
        url: `/api/admin/challengecollections/${action.params}`,
        body: action.payload,
        success: alertAction.success(
          Message.getMessageByKey("message.challengeCollection.update.success"),
          adminChallengeCollectionUpdateAction.success,
        ),
        error: adminChallengeCollectionUpdateAction.failure,
      }),
    );

export const adminChallengeCollectionDeleteEpic: Epic<Action, RootState> = (
  action$,
) =>
  action$
    .ofType(adminChallengeCollectionDeleteAction.types.request)
    .map((action) =>
      ajaxAction.request({
        method: "delete",
        url: `/api/admin/challengecollections/${action.payload}`,
        success: alertAction.success(
          Message.getMessageByKey("message.challengeCollection.delete.success"),
          adminChallengeCollectionDeleteAction.success,
        ),
        error: adminChallengeCollectionDeleteAction.failure,
      }),
    );

export const adminChallengeCollectionListRetrieveEpic: Epic<
  Action,
  RootState
> = (action$, state$) =>
  action$
    .ofType(
      adminChallengeCollectionCreateAction.types.success,
      adminChallengeCollectionDeleteAction.types.success,
    )
    .map(() => {
      return adminChallengeCollectionListAction.request();
    });

export const adminChallengeCollectionRetrieveEpic: Epic<Action, RootState> = (
  action$,
) =>
  action$
    .ofType(adminChallengeCollectionUpdateAction.types.success)
    .map((action) => {
      const collection = (
        action as APIResponseAction & {
          payload: { result: AdminChallengeCollectionListModel };
        }
      ).payload.result;
      return adminChallengeCollectionGetAction.request(collection.id);
    });

export default [
  adminChallengeCollectionListEpic,
  adminChallengeCollectionGetEpic,
  adminChallengeCollectionCreateEpic,
  adminChallengeCollectionUpdateEpic,
  adminChallengeCollectionDeleteEpic,
  adminChallengeCollectionListRetrieveEpic,
  adminChallengeCollectionRetrieveEpic,
];
