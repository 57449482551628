import { useFormContext } from "react-hook-form";

import {
  ErrorIcon,
  Label,
  Msg,
  PanelStandardContents,
  RadioPanel,
  Tooltip,
} from "@shared/components";
import { ExamDeliveryKind, TierAction } from "@shared/services/enums";
import Message from "@shared/services/message";
import { isDeliveryByIdAllowed } from "@shared/services/tier";

import { useDraftForm } from "../DraftFormProvider";
import { ExamSettingsFormValidation } from "../utils";

interface ExamDeliveryTypeSectionProps {
  examDeliveryType: ExamDeliveryKind;
  hasBeenDelivered: boolean;
  readOnly?: boolean;
  showEditLimitedTooltip?: boolean;
  isTierActionAllowed: (tierAction: TierAction) => boolean;
}

export const ExamDeliveryTypeSection = ({
  examDeliveryType,
  hasBeenDelivered,
  readOnly,
  showEditLimitedTooltip,
  isTierActionAllowed,
}: ExamDeliveryTypeSectionProps) => {
  const shouldShowIdDeliveryWarning = isDeliveryByIdAllowed(
    examDeliveryType,
    isTierActionAllowed,
  );
  const isDeliverByIdAllowed = isTierActionAllowed(TierAction.IdDelivering);
  const { watch, setValue } = useFormContext<ExamSettingsFormValidation>();
  const { setDraftState, draftState } =
    useDraftForm<ExamSettingsFormValidation>();

  const deliveryKind = watch("deliveryKind");
  const urlDeliveryEnabled = watch("urlDeliveryEnabled");

  return (
    <div>
      <Label className="exam-label">
        <Msg id="exam.deliveryKind" />
        {!shouldShowIdDeliveryWarning && (
          <ErrorIcon tooltipText={<Msg id="tier.disabled.notSupported" />} />
        )}
      </Label>
      <div className="code-exam-create__settings__delivery-type">
        <Tooltip
          placement="bottom"
          text={<Msg id="code-exam-edit.limited" />}
          disabled={!showEditLimitedTooltip}
          className="code-exam-create-new__settings__tooltip"
        >
          <RadioPanel
            readOnly={hasBeenDelivered || readOnly}
            initialLineHeight="1.5rem"
            checked={deliveryKind === ExamDeliveryKind.Standard}
            onChange={() => {
              setDraftState((draft) => {
                draft.deliveryKind = ExamDeliveryKind.Standard;
                return draft;
              });
              if (urlDeliveryEnabled) {
                setValue("urlSharingConfig", {
                  domainWhitelist:
                    draftState.urlSharingConfig?.domainWhitelist ?? [],
                  startAt: draftState.urlSharingConfig?.startAt as Date,
                  endAt: draftState.urlSharingConfig?.endAt as Date,
                  dynamicDeadlineHours:
                    draftState.urlSharingConfig?.dynamicDeadlineHours,
                });
              }
              setValue("idDeliveryConfig", undefined);
              setValue("deliveryKind", ExamDeliveryKind.Standard, {
                shouldValidate: true,
                shouldDirty: true,
              });
            }}
            dataTestautomationid="standardDeliveryRadio"
          >
            <PanelStandardContents
              title={Message.getMessageByKey("exam.radio.email.title")}
              titleIcon="envelope"
              jumpToLink={Message.getMessageByKey("exam.radio.email.link")}
            >
              {Message.getMessageByKey("exam.radio.email.description")}
            </PanelStandardContents>
          </RadioPanel>
        </Tooltip>
        <Tooltip
          text={<Msg id="tier.disabled" />}
          disabled={isDeliverByIdAllowed}
        >
          <Tooltip
            placement="bottom"
            text={<Msg id="code-exam-edit.limited" />}
            disabled={!showEditLimitedTooltip && !isDeliverByIdAllowed}
            className="code-exam-create-new__settings__tooltip"
          >
            <RadioPanel
              readOnly={hasBeenDelivered || readOnly || !isDeliverByIdAllowed}
              initialLineHeight="1.5rem"
              checked={deliveryKind === ExamDeliveryKind.ID}
              dataTestautomationid="idDeliveryRadio"
              onChange={() => {
                setDraftState((draft) => {
                  draft.deliveryKind = ExamDeliveryKind.ID;
                  return draft;
                });
                setValue("urlSharingConfig", undefined);

                if (draftState.idDeliveryConfig) {
                  setDraftState((draft) => {
                    draft.idDeliveryConfig = {
                      startAt: draftState.idDeliveryConfig?.startAt,
                      endAt: draftState.idDeliveryConfig?.endAt,
                      dynamicDeadlineHours:
                        draftState.idDeliveryConfig?.dynamicDeadlineHours,
                      passwordRequired:
                        draftState.idDeliveryConfig?.passwordRequired,
                    };
                    setValue("idDeliveryConfig", draft.idDeliveryConfig);
                    return draft;
                  });
                } else {
                  setDraftState((draft) => {
                    draft.idDeliveryConfig = {
                      startAt: undefined as unknown as Date,
                      endAt: undefined as unknown as Date,
                      dynamicDeadlineHours: undefined,
                      passwordRequired: undefined as unknown as boolean,
                    };
                    setValue("idDeliveryConfig", draft.idDeliveryConfig);
                    return draft;
                  });
                }

                setValue("deliveryKind", ExamDeliveryKind.ID, {
                  shouldValidate: true,
                  shouldDirty: true,
                });
              }}
            >
              <PanelStandardContents
                title={Message.getMessageByKey("exam.radio.id.title")}
                titleIcon="id-badge"
                jumpToLink={Message.getMessageByKey("exam.radio.id.link")}
              >
                {Message.getMessageByKey("exam.radio.id.description")}
              </PanelStandardContents>
            </RadioPanel>
          </Tooltip>
        </Tooltip>
      </div>
    </div>
  );
};
