import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

import {
  Checkbox,
  Msg,
  Tooltip,
  ErrorIcon,
  Banner,
  SwitchContainer,
  PanelStandardContents,
} from "@shared/components";
import { useFeatureFlags } from "@shared/hooks/query";
import { ExamChallengeSetModel } from "@shared/models";
import { WebcamSettings } from "@shared/services/enums";
import {
  hasLocalExamEnabled,
  shouldShowLocalExamWarning,
} from "@shared/services/exam";
import Message from "@shared/services/message";

import { ExamSectionUtil } from "../..";
import { getMonitoringWarningMessage } from "../../examSectionUtil/ExamSectionUtil";
import { RequireWebcamConfirmModal } from "../../examSettings/partials";
import { ExamSettingsFormValidation } from "../utils";

interface ApplicantMonitoringSectionProps {
  readOnly?: boolean;
  isCodePlaybackAllowed?: boolean;
  isLogApplicantActionAllowed?: boolean;
  isWebcamMonitoringAllowed?: boolean;
  examChallengeSets?: ExamChallengeSetModel[];
}

const fieldNames = [
  "applicantActionSettings.showCopyPasteEvents" as const,
  "applicantActionSettings.showKeyEvents" as const,
  "applicantActionSettings.showScreenshotEvents" as const,
  "applicantActionSettings.showTabChangeEvents" as const,
  "webcamSettings.enabled" as const,
];

export const ApplicantMonitoringSection = ({
  readOnly,
  isCodePlaybackAllowed = false,
  isLogApplicantActionAllowed = false,
  isWebcamMonitoringAllowed = false,
  examChallengeSets,
}: ApplicantMonitoringSectionProps) => {
  const { data } = useFeatureFlags();

  const { register, watch, setValue } =
    useFormContext<ExamSettingsFormValidation>();

  const booleans = watch(fieldNames);

  const applicantActionSettingsEnabled = watch(
    "applicantActionSettingsEnabled",
  );

  const [
    showCopyPasteEventsCheckboxEnabled,
    showKeyEventsCheckboxEnabled,
    showScreenshotEventsCheckboxEnabled,
    showTabChangeEventsCheckboxEnabled,
    showWebcamCheckboxEnabled,
  ] = booleans;

  const showLocalExamWarning = shouldShowLocalExamWarning({
    hasLocalExamEnabled: hasLocalExamEnabled(examChallengeSets),
    isCodePlaybackAllowed,
    isWebcamMonitoringAllowed,
    isLogApplicantActionAllowed,
    showKeyEventsCheckboxEnabled,
    showWebcamCheckboxEnabled,
    showCopyPasteEventsCheckboxEnabled,
    showTabChangeEventsCheckboxEnabled,
    showScreenshotEventsCheckboxEnabled,
  });

  const shouldShowPlaybackWarning =
    isCodePlaybackAllowed &&
    showKeyEventsCheckboxEnabled &&
    !ExamSectionUtil.isAllChallengesCodePlayable(examChallengeSets);

  const shouldShowWebcamWarning =
    isWebcamMonitoringAllowed &&
    showWebcamCheckboxEnabled &&
    !ExamSectionUtil.areAllChallengesCammable(examChallengeSets);

  const [isRequireWebcamConfirmationOpen, setRequireWebcamConfirmationOpen] =
    useState(false);

  const webcamLivenessVerification = watch(
    "webcamSettings.livenessVerification",
  );

  useEffect(() => {
    if (!showWebcamCheckboxEnabled) {
      setValue("webcamSettings.livenessVerification", WebcamSettings.optional, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
  }, [showWebcamCheckboxEnabled, setValue]);

  const disablePanel =
    !isCodePlaybackAllowed &&
    !isLogApplicantActionAllowed &&
    !isWebcamMonitoringAllowed;

  return (
    <div className="code-exam-create_settings__applicant-action-log">
      <SwitchContainer
        value={applicantActionSettingsEnabled}
        readOnly={readOnly || disablePanel}
        expanded={applicantActionSettingsEnabled}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setValue("applicantActionSettingsEnabled", e.target.checked);
        }}
        isStandardPanel
        switchContent={
          <PanelStandardContents
            title={Message.getMessageByKey("exam.monitoring.measures")}
            jumpToLink={Message.getMessageByKey("exam.monitoring.switch.link")}
            showErrorIcon={disablePanel}
          >
            <Msg id="exam.monitoring.switch.description" />
          </PanelStandardContents>
        }
        dataTestautomationid="applicantActionSwitch"
      >
        <div className="code-exam-create_settings__applicant-action-log">
          {(shouldShowPlaybackWarning || shouldShowWebcamWarning) && (
            <Banner type="warning">
              <Msg
                id={getMonitoringWarningMessage({
                  shouldShowPlaybackWarning,
                  shouldShowWebcamWarning,
                })}
              />
            </Banner>
          )}
          {showLocalExamWarning && (
            <Banner
              className="code-exam-create__settings__local-exam-warning"
              type="warning"
            >
              <Msg id="exam.monitoring.warning.localExam" />
            </Banner>
          )}

          <Checkbox
            dataTestautomationid="showKeyEvents"
            readOnly={readOnly || !isCodePlaybackAllowed}
            hideErrorMessage
            description={<Msg id="exam.playback.label.description" />}
            {...register("applicantActionSettings.showKeyEvents")}
          >
            <div className="exam-label">
              <Tooltip
                text={<Msg id="tier.disabled" />}
                disabled={
                  readOnly
                    ? isCodePlaybackAllowed
                    : isCodePlaybackAllowed || showKeyEventsCheckboxEnabled
                }
              >
                <Msg id="exam.monitoring.playback.label" />
              </Tooltip>
              {!isCodePlaybackAllowed && showKeyEventsCheckboxEnabled && (
                <ErrorIcon
                  tooltipText={<Msg id="tier.disabled.notSupported" />}
                />
              )}
            </div>
          </Checkbox>
          <Checkbox
            dataTestautomationid="showCopyPasteEvents"
            readOnly={readOnly || !isLogApplicantActionAllowed}
            hideErrorMessage
            {...register("applicantActionSettings.showCopyPasteEvents")}
            description={<Msg id="exam.monitoring.pastedCode.description" />}
          >
            <div className="exam-label">
              <Tooltip
                text={<Msg id="tier.disabled" />}
                disabled={
                  readOnly
                    ? isLogApplicantActionAllowed
                    : isLogApplicantActionAllowed ||
                      showCopyPasteEventsCheckboxEnabled
                }
              >
                <Msg id="exam.monitoring.pastedCode.label" />
              </Tooltip>
              {!isLogApplicantActionAllowed &&
                showCopyPasteEventsCheckboxEnabled && (
                  <ErrorIcon
                    tooltipText={<Msg id="tier.disabled.notSupported" />}
                  />
                )}
            </div>
          </Checkbox>
          <Checkbox
            dataTestautomationid="showTabChangeEvents"
            readOnly={readOnly || !isLogApplicantActionAllowed}
            hideErrorMessage
            {...register("applicantActionSettings.showTabChangeEvents")}
            description={<Msg id="exam.monitoring.leftExamTab.description" />}
          >
            <div className="exam-label">
              <Tooltip
                text={<Msg id="tier.disabled" />}
                disabled={
                  readOnly
                    ? isLogApplicantActionAllowed
                    : isLogApplicantActionAllowed ||
                      showTabChangeEventsCheckboxEnabled
                }
              >
                <Msg id="exam.monitoring.leftExamTab.label" />
              </Tooltip>
              {!isLogApplicantActionAllowed &&
                showTabChangeEventsCheckboxEnabled && (
                  <ErrorIcon
                    tooltipText={<Msg id="tier.disabled.notSupported" />}
                  />
                )}
            </div>
          </Checkbox>
          <Checkbox
            dataTestautomationid="showScreenshotEvents"
            readOnly={readOnly || !isLogApplicantActionAllowed}
            hideErrorMessage
            {...register("applicantActionSettings.showScreenshotEvents")}
            description={<Msg id="exam.monitoring.screenshot.description" />}
          >
            <div className="exam-label">
              <Tooltip
                text={<Msg id="tier.disabled" />}
                disabled={
                  readOnly
                    ? isLogApplicantActionAllowed
                    : isLogApplicantActionAllowed ||
                      showScreenshotEventsCheckboxEnabled
                }
              >
                <Msg id="exam.monitoring.screenshot.label" />
              </Tooltip>
              {!isLogApplicantActionAllowed &&
                showScreenshotEventsCheckboxEnabled && (
                  <ErrorIcon
                    tooltipText={<Msg id="tier.disabled.notSupported" />}
                  />
                )}
            </div>
          </Checkbox>

          {data?.webcamEnabled && (
            <div className="with-sub-options">
              <Checkbox
                dataTestautomationid="showWebcamEvents"
                readOnly={readOnly || !isWebcamMonitoringAllowed}
                hideErrorMessage
                {...register("webcamSettings.enabled")}
                description={
                  <Msg id="exam.applicantActionLogs.showWebcamEvent.explanation" />
                }
              >
                <div className="exam-label">
                  <Tooltip
                    text={<Msg id="tier.disabled" />}
                    disabled={
                      readOnly
                        ? isWebcamMonitoringAllowed
                        : isWebcamMonitoringAllowed || showWebcamCheckboxEnabled
                    }
                  >
                    <Msg id="exam.applicantActionLogs.showWebcamEvent" />
                  </Tooltip>
                  {!isWebcamMonitoringAllowed && showWebcamCheckboxEnabled && (
                    <ErrorIcon tooltipText={<Msg id="tier.disabled" />} />
                  )}
                </div>
              </Checkbox>
              <Checkbox
                dataTestautomationid="livenessVerification"
                readOnly={
                  readOnly ||
                  !showWebcamCheckboxEnabled ||
                  !isWebcamMonitoringAllowed
                }
                hideErrorMessage
                value={webcamLivenessVerification === WebcamSettings.required}
                onChange={(e) => {
                  const isChecked = e.target.checked;
                  setValue(
                    "webcamSettings.livenessVerification",
                    isChecked
                      ? WebcamSettings.required
                      : WebcamSettings.optional,
                    {
                      shouldValidate: true,
                      shouldDirty: true,
                    },
                  );

                  if (isChecked) {
                    setRequireWebcamConfirmationOpen(true);
                  }
                }}
                className="is-sub-option"
              >
                <div>
                  <Tooltip
                    text={<Msg id="tier.disabled" />}
                    disabled={
                      readOnly
                        ? isWebcamMonitoringAllowed
                        : isWebcamMonitoringAllowed || showWebcamCheckboxEnabled
                    }
                  >
                    <Msg id="exam.applicantActionLogs.showWebcamEvent.subOption" />
                  </Tooltip>
                </div>
              </Checkbox>
            </div>
          )}
        </div>
      </SwitchContainer>
      {isRequireWebcamConfirmationOpen && (
        <RequireWebcamConfirmModal
          onClose={() => {
            setRequireWebcamConfirmationOpen(false);
            setValue(
              "webcamSettings.livenessVerification",
              WebcamSettings.optional,
              {
                shouldValidate: true,
                shouldDirty: true,
              },
            );
          }}
          onConfirm={() => {
            setRequireWebcamConfirmationOpen(false);
          }}
        />
      )}
    </div>
  );
};
