import React from "react";

export const FormattedPressedKeys = ({
  pressedKeys,
}: {
  pressedKeys?: string[];
}) => {
  return (
    <>
      {pressedKeys?.map((key, idx) => {
        const isLastItem = idx === pressedKeys.length - 1;

        return (
          <React.Fragment key={key}>
            <code key={key}>{key}</code>
            {!isLastItem && " + "}
          </React.Fragment>
        );
      })}
    </>
  );
};
