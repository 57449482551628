import { connect } from "react-redux";

import { RootState } from "@reducers";

import { getCurrentProject } from "@shared/selectors";
import {
  isAssessmentProject,
  getTierAllowedByRedux,
} from "@shared/services/tier";

import ExamList, { ExternalProps } from "./ExamList";

const mapStateToProps = (state: RootState, props: ExternalProps) => {
  const currentProject = getCurrentProject(state);

  return {
    ...props,
    currentUser: state.user.user,
    projectId: state.project.currentProjectId,
    currentProject,
    loading: Boolean(state.ajax.submitting),
    isAssessmentProject: isAssessmentProject(currentProject?.kind),
    isTierActionAllowed: getTierAllowedByRedux(state),
  };
};

export default connect(mapStateToProps, null)(ExamList);
