/**
 * Enum
 */
import { InvitationStatus, ReviewScore, Role } from "../services/enums";

export type ReviewerUserModel = {
  id: number;
  email: string;
  imageUrl: string;
  invitationStatus?: InvitationStatus;
  name: string;
  roles: Role[];
  displayStatus?: string;
};

class ReviewModel {
  public id: number;
  public score: ReviewScore;
  public feedback = "";
  public userId: number;
  public reviewer: ReviewerUserModel = {} as ReviewerUserModel;
  public createdAt: string;
  public updatedAt: string;
  public isStale: boolean;
  public invitationStatus: InvitationStatus;
  public displayStatus = "";

  constructor(args?: {}) {
    Object.assign(this, args);
  }
}

export default ReviewModel;
