import {
  Action,
  APIResponseAction,
  questionGetAction,
  questionSourceCreateAction,
  questionSourceGetAction,
  questionSourceUpdateAction,
  questionFiltersAction,
  questionListGetAction,
  questionListUpdateAction,
  questionListDeleteAction,
  questionImportAction,
  questionCopyAction,
  currentProjectSetAction,
  questionExamLevelInsightsGetAction,
  questionExamLevelInsightClearAction,
} from "../actions";
import {
  QuestionListModel,
  PaginationModel,
  QuestionModel,
  QuestionFilterModel,
  QuestionInsightModel,
} from "../shared/models";

export interface QuestionState {
  questionList: Array<QuestionListModel>;
  questionListPagination: PaginationModel;

  questionImportList: Array<QuestionModel>;
  loadingImportList: boolean;

  questionDetailList: Array<QuestionModel>;

  loadingList: boolean;
  loadingDetail: boolean;

  questionSource: string;
  loadingSource: boolean;

  loadingCreate: boolean;
  errorCreate: boolean;

  questionFilters: QuestionFilterModel;
}

const initialState = {
  questionList: [],
  questionListPagination: new PaginationModel(),

  questionImportList: [],
  questionDetailList: [],

  loadingImportList: false,

  loadingList: false,
  loadingDetail: false,

  questionSource: "",
  loadingSource: false,

  loadingCreate: false,
  errorCreate: false,

  questionFilters: {
    quizCategories: [],
  },
};

interface ImportList {
  id: number;
  title: string;
  question: string;
  answers: Array<string>;
  settings: {
    choices: Array<string>;
  };
  kind: number;
  howToSolve: string;
  isOfficial: boolean;
  createdAt: string;
  updatedAt: string;
}

// TODO not used. Noooooo!! it's used!!
const mapAPItoModel = (importedQuestion: ImportList) => {
  const {
    id,
    title,
    question,
    answers,
    kind,
    isOfficial,
    createdAt,
    updatedAt,
    howToSolve,
    settings,
  } = importedQuestion;
  return new QuestionModel({
    id,
    title,
    question,
    // TODO why...?
    choices: settings.choices,
    answers,
    kind,
    isOfficial,
    howToSolve,
    settings,
    createdAt,
    updatedAt,
  });
};

const getUpdatedQuestionDetailList = (
  oldQuestionDetailList: QuestionModel[],
  questionId: number,
  questionUpdate: Partial<QuestionModel>,
) => {
  const questionDetailIndex = oldQuestionDetailList.findIndex(
    (question) => question.id === questionId,
  );

  if (questionDetailIndex === -1) {
    return [
      ...oldQuestionDetailList,
      new QuestionModel({ id: questionId, ...questionUpdate }),
    ];
  }

  const oldQuestionDetail = oldQuestionDetailList[questionDetailIndex];
  const newQuestionDetail = new QuestionModel({
    ...oldQuestionDetail,
    ...questionUpdate,
  });

  return [
    ...oldQuestionDetailList.slice(0, questionDetailIndex),
    newQuestionDetail,
    ...oldQuestionDetailList.slice(questionDetailIndex + 1),
  ];
};

export const questionReducer = (
  state: QuestionState = initialState,
  action: Action,
) => {
  const payload = (action as APIResponseAction).payload || {};

  switch (action.type) {
    case questionGetAction.types.request:
      return { ...state, loadingDetail: true };
    case questionGetAction.types.success: {
      let questionDetailList: Array<QuestionModel> = [];

      const question = new QuestionModel({
        ...payload.result,
        choices: (payload.result as { settings: { choices: Array<{}> } })
          .settings.choices,
      });
      const index = state.questionDetailList.findIndex(
        (item) => item.id === question.id,
      );

      if (index === -1) {
        questionDetailList = [...state.questionDetailList, question];
      } else {
        questionDetailList = [
          ...state.questionDetailList.slice(0, index),
          question,
          ...state.questionDetailList.slice(index + 1),
        ];
      }
      return {
        ...state,
        questionDetailList,
        loadingDetail: false,
      };
    }
    case questionListGetAction.types.request:
      return {
        ...state,
        questionList: [],
        questionListPagination: new PaginationModel(),
        loadingList: true,
      };
    case questionListGetAction.types.success:
      return {
        ...state,
        questionList: (payload.result as Array<{}>).map(
          (question: {}) => new QuestionListModel(question),
        ),
        questionListPagination: new PaginationModel(payload.pagination),
        loadingList: false,
      };

    case questionSourceCreateAction.types.request:
    case questionSourceUpdateAction.types.request:
      return {
        ...state,
        loadingCreate: true,
        errorCreate: false,
      };

    case questionSourceCreateAction.types.failure:
    case questionSourceUpdateAction.types.failure:
      return {
        ...state,
        loadingCreate: false,
        errorCreate: true,
      };

    case questionSourceUpdateAction.types.success:
      return { ...state, loadingCreate: true };

    case questionSourceCreateAction.types.success:
    case questionListUpdateAction.types.request: {
      const updateQuestionIndex = state.questionList.findIndex(
        (question) => question.id === (payload.result as QuestionListModel).id,
      );
      const updatedQuestion = new QuestionListModel(
        Object.assign(
          {},
          state.questionList[updateQuestionIndex],
          payload.result,
        ),
      );
      let updatedQuestionList = [...state.questionList];
      if (updateQuestionIndex >= 0) {
        updatedQuestionList.splice(updateQuestionIndex, 1, updatedQuestion);
      } else {
        updatedQuestionList = [updatedQuestion, ...updatedQuestionList];
      }

      return {
        ...state,
        questionList: updatedQuestionList,
        loadingCreate: false,
      };
    }

    case questionListDeleteAction.types.request: {
      const updateQuestionIndex = state.questionList.findIndex(
        (question) => question.id === (payload.result as QuestionListModel).id,
      );

      if (updateQuestionIndex === -1) {
        return state;
      }

      const updatedQuestionList = [...state.questionList];
      updatedQuestionList.splice(updateQuestionIndex, 1);

      return {
        ...state,
        questionList: updatedQuestionList,
      };
    }

    case questionImportAction.types.request:
      return {
        ...state,
        loadingImportList: true,
        questionImportList: [],
      };
    case questionImportAction.types.failure:
      return {
        ...state,
        loadingImportList: false,
      };
    case questionImportAction.types.success:
      return {
        ...state,
        loadingImportList: false,
        questionImportList: (payload.result as Array<ImportList>).map(
          (importedQuestion: ImportList) => mapAPItoModel(importedQuestion),
        ),
      };

    case questionSourceGetAction.types.request:
      return {
        ...state,
        loadingSource: true,
        questionSource: "",
      };
    case questionSourceGetAction.types.success:
      return {
        ...state,
        loadingSource: false,
        questionSource: (payload.result as { source: string }).source || "",
      };

    case questionFiltersAction.types.success:
      return {
        ...state,
        questionFilters: {
          ...state.questionFilters,
          [payload.extra as string]: payload.result || [],
        },
      };

    case questionCopyAction.types.request:
      return {
        ...state,
        loadingList: true,
      };

    case questionCopyAction.types.success:
    case questionCopyAction.types.failure:
      return {
        ...state,
        loadingList: false,
      };

    // clean up store data right after project switch
    case currentProjectSetAction.types.request: {
      const { questionFilters } = state;
      return { ...initialState, questionFilters };
    }

    case questionExamLevelInsightsGetAction.types.request: {
      const questionId = action.params as number;

      return {
        ...state,
        questionDetailList: getUpdatedQuestionDetailList(
          state.questionDetailList,
          questionId,
          { loadingInsight: true, errorLoadingInsight: false },
        ),
      };
    }

    case questionExamLevelInsightsGetAction.types.success: {
      const questionId = action.params as number;

      return {
        ...state,
        questionDetailList: getUpdatedQuestionDetailList(
          state.questionDetailList,
          questionId,
          {
            loadingInsight: false,
            errorLoadingInsight: false,
            examLevelInsight: new QuestionInsightModel({ ...payload.result }),
          },
        ),
      };
    }

    case questionExamLevelInsightsGetAction.types.failure: {
      const questionId = action.params as number;

      return {
        ...state,
        questionDetailList: getUpdatedQuestionDetailList(
          state.questionDetailList,
          questionId,
          { loadingInsight: false, errorLoadingInsight: true },
        ),
      };
    }

    case questionExamLevelInsightClearAction.types.request: {
      return {
        ...state,
        questionDetailList: state.questionDetailList.map(
          (question) =>
            new QuestionModel({
              ...question,
              // Clean up every exam level insight once a new exam detail is fetched
              examLevelInsight: undefined,
              loadingInsight: false,
            }),
        ),
      };
    }

    default:
      return state;
  }
};
