import { debounce } from "lodash";
import * as React from "react";

import { Accordion, Button, Icon, Msg, Tooltip } from "@shared/components";
import { ApplicantExamsModel } from "@shared/models";
import { MailStatus } from "@shared/services/enums";
import Message from "@shared/services/message";

interface Props {
  applicantCount: number;
  canEditScheduledDelivery?: boolean;
  filteredApplicants?: ApplicantExamsModel[];
  searchTag?: string;
  onChangeKeyword: (e: React.FormEvent<HTMLInputElement>) => void;
  onClickRemoveApplicant?: (selected: ApplicantExamsModel) => void;
  setKeyword?: (keyword: string) => void;
}

export default function ApplicantList({
  applicantCount,
  canEditScheduledDelivery,
  filteredApplicants,
  searchTag = "common.email",
  onChangeKeyword,
  onClickRemoveApplicant,
  setKeyword,
}: Props) {
  const handleOpen = () => {
    setKeyword?.("");
  };

  const handleOnSearch = debounce((e: React.FormEvent<HTMLInputElement>) => {
    onChangeKeyword(e);
  }, 300);

  const handleDeleteRowClick = (applicant: ApplicantExamsModel) => () => {
    onClickRemoveApplicant?.(applicant);
  };

  return (
    <Accordion
      onOpen={handleOpen}
      summary={
        <>
          <Msg id="exam.showAllApplicants" />
          <span className="code-exam-delivery-list__applicants-bar-count">
            {`(${applicantCount})`}
          </span>
        </>
      }
    >
      <div className="code-exam-delivery-list__applicants-detail">
        <div className="code-exam-delivery-list__applicants-detail__search-bar">
          <div className="code-exam-delivery-list__applicants-detail__search-bar__tag">
            <Msg id={searchTag} />
          </div>
          <div className="code-exam-delivery-list__applicants-detail__search-bar__input-wrapper">
            <input
              className="search-bar__input-form"
              onChange={handleOnSearch}
            />
          </div>
        </div>
        <div className="code-exam-delivery-list__applicants-detail__list">
          {filteredApplicants?.length ? (
            filteredApplicants.map((applicant) => {
              const mailError = MailStatus.getErrorMessage(
                applicant.mailStatus,
              );

              return (
                <div
                  className="code-exam-delivery-list__applicants-detail__list-row"
                  key={applicant.id}
                >
                  <div className="code-exam-delivery-list__applicants-detail__list-row__text">
                    {applicant.applicant.email}
                  </div>
                  {mailError && (
                    <Tooltip text={mailError}>
                      <Icon
                        type="exclamation-triangle"
                        className="has-warning"
                      />
                    </Tooltip>
                  )}
                  {canEditScheduledDelivery && (
                    <Tooltip text={Message.getMessageByKey("delete")}>
                      <Button
                        size="small"
                        shrink={true}
                        onClick={handleDeleteRowClick(applicant)}
                        ariaLabel="Delete"
                        disabled={false}
                      >
                        <Icon type="trash" />
                      </Button>
                    </Tooltip>
                  )}
                </div>
              );
            })
          ) : (
            <div className="code-exam-delivery-list__applicants-detail__list-no-result">
              <Msg id="message.noResults" />
            </div>
          )}
        </div>
      </div>
    </Accordion>
  );
}
