import { useStoreContext } from "@context";

import { ProjectRole, UserRole } from "@shared/services/enums";

export function useUserRoles() {
  const { projectId, user } = useStoreContext();

  const isAccessExamSettingPage = user?.hasRole(
    [
      UserRole.SystemAdmin,
      ProjectRole.ProjectAdmin,
      ProjectRole.ExamCreator,
      ProjectRole.ExamDeliverer,
    ],
    projectId,
  );
  const canAccessDashboardCharts = user?.canAccessDashboardCharts(projectId);
  const isProjectAdmin = user?.hasRole([ProjectRole.ProjectAdmin], projectId);
  const isSystemAdmin = user?.hasRole(UserRole.SystemAdmin);
  const isGuestSharingRole = user?.hasRole(
    [UserRole.SystemAdmin, ProjectRole.ProjectAdmin, ProjectRole.Reviewer],
    projectId,
  );

  const isExamDeliverer = user
    ? user?.hasRole([ProjectRole.ExamDeliverer], projectId)
    : false;

  const roles = user?.getRoles(projectId);
  const hasMultipleRoles = roles ? roles.length > 1 : false;

  return {
    canAccessDashboardCharts,
    isAccessExamSettingPage,
    isGuestSharingRole,
    isProjectAdmin,
    isSystemAdmin,
    isExamDeliverer,
    hasMultipleRoles,
  };
}
