import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { Switch, Route, Redirect } from "react-router-dom";

import { Msg } from "@shared/components";

import { AccountGeneral } from "./accountSettingsPartials";

const AccountSettingsRoutes = () => (
  <>
    <BreadcrumbsItem to="/settings/account">
      <Msg id={"accountSettings"} />
    </BreadcrumbsItem>
    <Switch>
      <Route
        path="/settings/account/general"
        exact={true}
        component={AccountGeneral}
      />
      <Redirect from="/settings/account" to="/settings/account/general" />
    </Switch>
  </>
);

export default AccountSettingsRoutes;
