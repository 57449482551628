import * as classnames from "classnames";

/**
 * Prop interface
 */
export interface AvatarProps {
  /**
   * image src
   */
  src?: string;
  /**
   * size
   */
  size?: string;
  /**
   * class name
   */
  className?: string;
  defaultSrc?: string; // An alternative default image to use when src is empty
  defaultMessage?: React.ReactNode; // A message to overlay on the default image when src is empty
}

const defaultImageSrc = require("../../../assets/images/default_avatar.png");

/**
 * React Component
 */
export default function Avatar({
  src,
  size,
  className,
  defaultSrc,
  defaultMessage,
}: AvatarProps) {
  const isDefault = !src;

  const imgSrc = isDefault ? defaultSrc || defaultImageSrc : src;

  const rootStyle = classnames("code-c-avatar", "image", {
    [`is-${size}`]: Boolean(size),
    [`${className}`]: Boolean(className),
  });

  return (
    <div className="code-c-avatar__wrapper">
      <figure className={rootStyle}>
        <img className="code-c-avatar__image" src={imgSrc} />
      </figure>
      {isDefault && defaultMessage ? (
        <div className="code-c-avatar__defaultmessage">{defaultMessage}</div>
      ) : null}
    </div>
  );
}
