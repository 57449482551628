import { AxiosRequestConfig } from "axios";

import { ChallengeResultSettings } from "@components/preview/settings.model";

import { get } from "../httpClient";

interface SubmissionChallengeResultPreviewParams {
  challengeId: number;
  examId: number;
  projectId: number;
  submissionId: number;
  options?: AxiosRequestConfig;
}

export async function getSubmissionChallengeResultPreview({
  challengeId,
  examId,
  projectId,
  submissionId,
  options,
}: SubmissionChallengeResultPreviewParams) {
  const { data } = await get<ChallengeResultSettings>(
    `/api/projects/${projectId}/exams/${examId}/submissions/${submissionId}/preview/${challengeId}`,
    options,
  );

  return data;
}
