import * as classNames from "classnames";

import { FormGroup, Msg, Radio } from "@shared/components";
import { AutoFilterType } from "@shared/services/enums";

import { BarChartRow } from "./BarChartRow";
import { ScoreInputRow } from "./ScoreInputRow";

export type AutoFilterFormChange = {
  filterType: AutoFilterType | null;
  passingScore?: number | null;
  failingScore?: number | null;
};

export interface AutoFilterFormProps {
  filterType: AutoFilterType;
  passingScore?: number;
  failingScore?: number;
  /**
   * @deprecated get rid of this once new settings page is merged. React hook forms does not use the name field
   */
  inputNames?: {
    filterType?: string;
    passingScore?: string;
    failingScore?: string;
  };
  /**
   * @deprecated use passingScoreError and failingScoreError instead
   */
  error?: {
    [key: string]: string;
  };
  passingScoreError?: string;
  failingScoreError?: string;
  onChange?: (params: AutoFilterFormChange) => void;
  readOnly?: boolean;
  className?: string;
}

export function AutoFilterForm({
  filterType,
  passingScore,
  failingScore,
  inputNames: {
    filterType: filterTypeName = "filterType",
    passingScore: passingScoreInputName = "passingScore",
    failingScore: failingScoreInputName = "failingScore",
  } = {},
  error,
  passingScoreError,
  failingScoreError,
  onChange,
  readOnly = false,
  className,
}: AutoFilterFormProps) {
  const rootStyles = classNames("code-auto-filter-form", className, {
    "is-readonly": readOnly,
  });

  const onFilterTypeChange = (filterType: AutoFilterType) => {
    onChange?.({ filterType, passingScore: null, failingScore: null });
  };

  const onPassingScoreChange = (score?: number) => {
    onChange?.({ filterType: null, passingScore: score, failingScore });
  };

  const onFailingScoreChange = (score?: number) => {
    onChange?.({
      filterType: null,
      passingScore,
      failingScore: score,
    });
  };

  const hasError =
    Boolean(passingScoreError || error?.[passingScoreInputName]) ||
    Boolean(failingScoreError || error?.[failingScoreInputName]);

  return (
    <FormGroup className={rootStyles}>
      <div className="filter-type-section">
        <Radio
          checked={filterType === AutoFilterType.AutoPassFail}
          defaultValue={AutoFilterType.AutoPassFail}
          readOnly={readOnly}
          onChange={(e) =>
            onFilterTypeChange(e.currentTarget.value as AutoFilterType)
          }
          dataTestautomationid="autoFilterAutoPassFail"
        >
          <Msg id="exam.autoFilter.filterType.all" />
        </Radio>
        <Radio
          dataTestautomationid="autoFilterAutoPassFailReview"
          checked={filterType === AutoFilterType.AutoPassFailReview}
          defaultValue={AutoFilterType.AutoPassFailReview}
          readOnly={readOnly}
          onChange={(e) =>
            onFilterTypeChange(e.currentTarget.value as AutoFilterType)
          }
        >
          <Msg id="exam.autoFilter.filterType.partial" />
        </Radio>
      </div>
      <div className="score-section">
        {/* Passing Score input */}
        <ScoreInputRow
          {...{
            filterType,
            error,
            inputName: passingScoreInputName,
            score: passingScore,
            min:
              filterType === AutoFilterType.AutoPassFailReview &&
              failingScore !== undefined &&
              failingScore >= 0 &&
              failingScore < 100
                ? failingScore + 1
                : 0,
            max: 100,
            onChange: onPassingScoreChange,
            errorMessage: passingScoreError,
            icon: "check",
            labelBeforeKey: "exam.autoFilter.passingScore.labelBefore",
            labelAfterKey: "exam.autoFilter.passingScore.labelAfter",
            readOnly,
          }}
        />
        {/* Failing Score input */}
        {filterType === AutoFilterType.AutoPassFailReview && (
          <ScoreInputRow
            {...{
              filterType,
              error,
              inputName: failingScoreInputName,
              score: failingScore,
              min: 0,
              max:
                passingScore !== undefined &&
                passingScore > 0 &&
                passingScore <= 100
                  ? passingScore - 1
                  : 100,
              onChange: onFailingScoreChange,
              errorMessage: failingScoreError,
              icon: "times",
              labelBeforeKey: "exam.autoFilter.failingScore.labelBefore",
              labelAfterKey: "exam.autoFilter.failingScore.labelAfter",
              readOnly,
            }}
          />
        )}
        <BarChartRow
          {...{ filterType, passingScore, failingScore, hasError }}
        />
      </div>
    </FormGroup>
  );
}
