import { UrlSharingConfigModel } from ".";
import { dayjs } from "../services/date";

/**
 * servics
 */
import { ExamType } from "../services/enums";
import { getExamName } from "../services/exam";

class ApplicantSharingExamModel {
  public examId: number;
  public examType: ExamType;
  public examName: string;
  public examEnName?: string;
  public organizationId: number;
  public organizationImageUrl: string;
  public organizationDisplayName: string;
  public urlSharingConfig: UrlSharingConfigModel = new UrlSharingConfigModel();
  public urlDeliveryEnabled: boolean;
  public urlToken: string;

  constructor(args?: {}) {
    Object.assign(this, args);
  }

  public isEmpty() {
    return !Boolean(this.examId);
  }

  public isAvailable() {
    if (this.urlSharingConfig.endAt) {
      return dayjs().isBefore(this.urlSharingConfig.endAt);
    } else {
      return true;
    }
  }

  public getExamName(language: string): string {
    return getExamName(
      this.examType === ExamType.EnglishJapanese,
      this.examName,
      this.examEnName,
      language,
    );
  }
}

export default ApplicantSharingExamModel;
