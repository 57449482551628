import * as classnames from "classnames";

import {
  Icon,
  Table,
  TableBody,
  Row,
  Column,
  Msg,
  QuickHelp,
  Tooltip,
} from "../..";
import { ChallengeTextCaseJSONModel } from "../../../models";
import {
  getEvaluationPoints,
  hasJSONSupported,
} from "../../../services/evaluationPoint";
import Message from "../../../services/message";
import { parse as tapParser } from "../../../services/tapParser";

/**
 * Prop interface
 */
export interface TestOutputProps {
  output?: string;
  outputJson?: ChallengeTextCaseJSONModel;
  evaluationPoint?: { order: number; title: string; description: string }[];
  showSecretTestcases?: boolean;
  className?: string;
}

/**
 * React Component
 */
export const TestOutput = ({
  output,
  outputJson,
  evaluationPoint,
  showSecretTestcases,
  className,
}: TestOutputProps) => {
  const rootStyle = classnames("code-c-test-output", {
    [`${className}`]: Boolean(className),
  });

  const evaluationPoints = getEvaluationPoints(
    output,
    outputJson,
    evaluationPoint,
  );
  const isJSONSupported = hasJSONSupported(outputJson);

  const evaluationTable = (
    <>
      {isJSONSupported && (
        <div className="code-c-test-output__testcases-summary">
          <Icon type="lock" className="code-c-test-output__secret-icon" />
          <Msg id="challenge.testcases.secret" />
          <QuickHelp
            text={Message.getMessageByKey("challenge.testcases.secret.note")}
            maxSize="large"
          />
        </div>
      )}
      <Table bordered={false} className="code-c-test-output__table">
        <TableBody>
          {evaluationPoints.evaluationPoints.map((evalPoints) => (
            <Row key={evalPoints.order}>
              <Column className="code-c-test-output__evalpoint-column">
                <div className="code-c-test-output__evalpoint-title">
                  {evalPoints.title}
                </div>
                <div className="code-c-test-output__evalpoint-count">
                  {
                    evalPoints.testCases.filter((testCase) => testCase.passed)
                      .length
                  }
                  /{evalPoints.testCases.length}
                </div>
                <div className="code-c-test-output__evalpoint-description">
                  {evalPoints.description}
                </div>
              </Column>
              <Column className="code-c-test-output__column">
                {evalPoints.testCases.map((testCase) => (
                  <div
                    key={`${testCase.id}-${testCase.secret}`}
                    className={`code-c-test-output__item ${
                      testCase.passed ? "is-ok" : "is-ng"
                    }`}
                  >
                    <div className="code-c-test-output__secret">
                      {testCase.secret && (
                        <Tooltip
                          text={Message.getMessageByKey(
                            "challenge.testcases.secret.tooltip",
                          )}
                        >
                          <Icon
                            type="lock"
                            className="code-c-test-output__secret-icon"
                          />
                        </Tooltip>
                      )}
                    </div>
                    <div className="code-c-test-output__line">
                      {testCase.id}
                    </div>
                    <div className="code-c-test-output__result">
                      <Icon type={testCase.passed ? "check" : "times"} />
                    </div>
                    <div className="code-c-test-output__text">
                      {testCase.secret && !showSecretTestcases ? (
                        <div className="code-c-test-output__text__secret">
                          <Msg id="challenge.testcases.secret.mask" />
                        </div>
                      ) : (
                        testCase.name
                      )}
                    </div>
                  </div>
                ))}
              </Column>
            </Row>
          ))}
        </TableBody>
      </Table>
    </>
  );

  const oldRender = output ? (
    tapParser(output).map((item, index) => (
      <div
        key={index}
        className={`code-c-test-output__item ${item.ok ? "is-ok" : "is-ng"}`}
      >
        <div className="code-c-test-output__line">{item.lineNumber}</div>
        <div className="code-c-test-output__result">
          <Icon type={item.ok ? "check" : "times"} />
        </div>
        <div className="code-c-test-output__text">{item.description}</div>
      </div>
    ))
  ) : (
    <div className="code-c-test-output__no-test">
      <Msg id="submission.noTestOutput" />
    </div>
  );

  const resultsRender = evaluationPoints.testCaseCount
    ? evaluationTable
    : oldRender;

  return <div className={rootStyle}>{resultsRender}</div>;
};
