import * as React from "react";

import SubmissionResultActionLogCard from "../submissionResultActionLogCard/SubmissionResultActionLogCard";
import SubmissionResultActionLogCardField from "../submissionResultActionLogCardField/SubmissionResultActionLogCardField";

interface Props {
  children?: React.ReactNode;
  labelKey?: string;
  titleKey?: string;
  className?: string;
}

export default function SubmissionResultActionLogActionDetail({
  children,
  labelKey,
  titleKey = "submission.actionLog.actionDetail",
  className,
}: Props) {
  if (!children) {
    return null;
  }

  return (
    <SubmissionResultActionLogCard titleKey={titleKey}>
      <SubmissionResultActionLogCardField
        labelKey={labelKey}
        className={className}
      >
        {children}
      </SubmissionResultActionLogCardField>
    </SubmissionResultActionLogCard>
  );
}
