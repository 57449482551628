import {
  ApplicantReportModel,
  ExamModel,
  ReviewModel,
  ReviewerUserModel,
  SubmissionAccessType,
  SubmissionCheatMonitoring,
  SubmissionModel,
  SubmissionOverlappingIp,
  UserModel,
} from "@shared/models";
import {
  ApplicantExamStatus,
  ProjectRole,
  TierAction,
  UserRole,
} from "@shared/services/enums";
import {
  isSubmittedStatus,
  shouldShowActionLogTab,
} from "@shared/services/exam";

export const getCurrentUserReview = (
  reviews: ReviewModel[],
  currentUser: UserModel,
) => {
  return reviews.find((review) => review.userId === currentUser.id);
};

export const buildDisplayConditionals = ({
  currentUser,
  currentProjectId,
  submission,
  examDetail,
  isTierActionAllowed,
  applicantReportDetails,
  reviews,
  allowReview,
  pendingReviewers,
  isOpenComment,
}: {
  currentUser: UserModel;
  currentProjectId: number;
  submission: SubmissionModel;
  examDetail: ExamModel;
  isTierActionAllowed: (action: TierAction) => boolean;
  applicantReportDetails?: ApplicantReportModel;
  reviews: ReviewModel[];
  allowReview: boolean;
  pendingReviewers: ReviewerUserModel[];
  isOpenComment: boolean;
}) => {
  // User role
  const isExamDeliverer =
    currentUser.getAccessSubmissionType(
      currentProjectId,
      submission.status,
      examDetail.reviewers,
    ) === SubmissionAccessType.ExamDeliverer;
  const isProjectAdmin = currentUser.hasRole(
    ProjectRole.ProjectAdmin,
    currentProjectId,
  );
  const isSystemAdmin = currentUser.hasRole(UserRole.SystemAdmin);
  const isReviewer = currentUser.hasRole(
    ProjectRole.Reviewer,
    currentProjectId,
  );

  const currentUserReview = getCurrentUserReview(reviews, currentUser);

  const isSubmissionSubmitted = isSubmittedStatus(submission.status);
  const isSubmittedOrReviewStatus =
    submission.status === ApplicantExamStatus.Submitted ||
    submission.status === ApplicantExamStatus.InReview;

  const numberOfReviewers = pendingReviewers.length + reviews.length;

  // Display conditionals
  const showSubmissionDetailHeader = !isExamDeliverer;
  const showSubmissionDetails = !isExamDeliverer;
  const canViewSkillReport =
    isTierActionAllowed(TierAction.ExamineeReporting) &&
    examDetail.reportingEnabled &&
    isSubmissionSubmitted;
  const showSkillReportTab =
    canViewSkillReport && Boolean(applicantReportDetails);
  const showCustomFormResult = !isExamDeliverer;
  const showAllReviews =
    !isExamDeliverer &&
    isSubmissionSubmitted &&
    numberOfReviewers > 0 &&
    (isSystemAdmin ||
      isProjectAdmin ||
      (examDetail.shareReviewsWithReviewers && isReviewer));
  const showOnlyCurrentReviewer =
    !isExamDeliverer &&
    Boolean(currentUserReview) &&
    allowReview &&
    isSubmissionSubmitted &&
    !examDetail.shareReviewsWithReviewers &&
    !isProjectAdmin &&
    !isSystemAdmin;

  const showReviewButton =
    !isOpenComment &&
    isTierActionAllowed(TierAction.ReviewFunctionality) &&
    (!Boolean(currentUserReview) || Boolean(currentUserReview?.isStale)) &&
    allowReview &&
    isSubmittedOrReviewStatus;
  const showReviewPopup = isOpenComment && isSubmittedOrReviewStatus;
  const showReviewButtonAsPrimary = !isProjectAdmin;
  const showEvaluateButton = isSubmittedOrReviewStatus && isProjectAdmin;

  const shouldShowEditReviewButton = (review: ReviewModel) => {
    return (
      Boolean(currentUserReview) &&
      currentUser.id === review.userId &&
      allowReview &&
      isSubmittedOrReviewStatus
    );
  };

  return {
    showSubmissionDetailHeader,
    showSubmissionDetails,
    showCustomFormResult,
    showSkillReportTab,
    canViewSkillReport,
    showActionLogTab: shouldShowActionLogTab(submission.status),
    showReviewButton,
    showReviewButtonAsPrimary,
    showReviewPopup,
    showEvaluateButton,
    showAllReviews,
    showOnlyCurrentReviewer,
    shouldShowEditReviewButton,
  };
};

export const getIssueTotals = (
  cheatMonitoring: SubmissionCheatMonitoring,
  overlappingIpAddresses: SubmissionOverlappingIp | undefined,
) => {
  const totalSignificantExamCount = cheatMonitoring.forExam
    ? Object.values(cheatMonitoring.forExam).filter(
        (event) => event.isSignificant,
      ).length
    : 0;

  const significantChallengeCounts = cheatMonitoring.forChallenges
    ? cheatMonitoring.forChallenges?.reduce(
        (acc, challenge) => {
          if (challenge.copyPaste?.isSignificant) {
            acc.copyPaste += 1;
          }
          if (challenge.leftTab?.isSignificant) {
            acc.leftTab += 1;
          }
          if (challenge.screenshot?.isSignificant) {
            acc.screenshot += 1;
          }

          return acc;
        },
        { copyPaste: 0, leftTab: 0, screenshot: 0 },
      )
    : { copyPaste: 0, leftTab: 0, screenshot: 0 };

  const totalSignificantChallengeCount = [
    ...Object.values(significantChallengeCounts),
  ].filter((count) => count > 0).length;

  const totalOverlappingIpActivityCount =
    overlappingIpAddresses?.hasOverlappingApplicantExams ||
    (overlappingIpAddresses?.overlappingApplicantExams &&
      overlappingIpAddresses?.overlappingApplicantExams?.length > 0)
      ? 1
      : 0;

  const totalSignificantCount =
    totalSignificantExamCount +
    totalSignificantChallengeCount +
    totalOverlappingIpActivityCount;

  return {
    total: totalSignificantCount,
    counts: {
      ...significantChallengeCounts,
      sessionData: cheatMonitoring.forExam?.sessionData?.isSignificant
        ? cheatMonitoring.forExam?.sessionData?.uniqueSessionCount
        : 0,
      multiSubmission:
        overlappingIpAddresses?.overlappingApplicantExams?.length || 0,
    },
  };
};
