import * as React from "react";

import {
  ApplicantList,
  FormGroup,
  Label,
  LanguageTag,
  Modal,
  Msg,
} from "@shared/components";
import { ModalProps } from "@shared/components/modal/Modal";
import { useApplicantList } from "@shared/hooks";
import { ApplicantExamsModel } from "@shared/models";
import { formatDateTimeMinutes } from "@shared/services/date";
import MSG from "@shared/services/message";

interface Props
  extends Pick<
    ModalProps,
    "disableOk" | "isOpen" | "onClickCancel" | "onClose" | "onClickOk"
  > {
  applicantCSV?: string;
  deadline: string;
  isScheduleDelivery?: boolean;
  isSubmitting: boolean;
  language?: string;
  mailSubject?: string;
  multiLanguage: boolean;
  preview: string;
  scheduledFor: string;
  senderName: string;
}

export default function ConfirmationModal({
  applicantCSV,
  deadline,
  disableOk,
  isOpen,
  isScheduleDelivery,
  isSubmitting,
  language,
  mailSubject,
  multiLanguage,
  preview,
  scheduledFor,
  senderName,
  onClickCancel,
  onClickOk,
  onClose,
}: Props) {
  const deliveryLabel = MSG.getMessageByKey(
    isScheduleDelivery
      ? "exam.delivery.schedule.delivery"
      : "exam.delivery.confirm.delivery",
  );
  const ariaLabel = isScheduleDelivery ? "Schedule a Delivery" : "Deliver Exam";
  const {
    applicantCount,
    filteredApplicants,
    handleChangeKeyword,
    setApplicants,
    setSearchKeyword,
  } = useApplicantList();

  React.useEffect(() => {
    if (!applicantCSV) {
      return;
    }

    const applicants = applicantCSV
      .trim()
      .split("\n")
      .map(
        // id is represent unique "key" prop
        (row, id) => ({ id, applicant: { email: row } }) as ApplicantExamsModel,
      );

    setApplicants(applicants);
  }, [applicantCSV, setApplicants]);

  return (
    <Modal
      isOpen={isOpen}
      title={deliveryLabel}
      okButtonLabel={deliveryLabel}
      onClose={onClose}
      onClickCancel={onClickCancel}
      onClickOk={onClickOk}
      disableOk={disableOk}
      disableCancel={isSubmitting}
      disableClose={isSubmitting}
      okButtonAriaLabel={ariaLabel}
      ariaLabel={ariaLabel}
    >
      {multiLanguage && language && (
        <FormGroup>
          <Label>
            <Msg id="exam.examlanguage" />
          </Label>
          <p>
            <LanguageTag language={language} color="pink" />
          </p>
        </FormGroup>
      )}
      {isScheduleDelivery && (
        <FormGroup>
          <Label htmlFor="scheduledFor">
            <Msg id="exam.delivery.scheduledDate" />
          </Label>
          <p>{formatDateTimeMinutes(scheduledFor || "")}</p>
        </FormGroup>
      )}
      <FormGroup>
        <Label>
          <Msg id="delivery.deadline" />
        </Label>
        <p>{formatDateTimeMinutes(deadline)}</p>
      </FormGroup>
      <FormGroup>
        <Label>
          <Msg id="common.applicant" />
        </Label>
        <ApplicantList
          applicantCount={applicantCount}
          filteredApplicants={filteredApplicants}
          onChangeKeyword={handleChangeKeyword}
          setKeyword={setSearchKeyword}
        />
      </FormGroup>
      <FormGroup>
        <Label>
          <Msg id="delivery.from" />
        </Label>
        <p className="is-wrap">{senderName}</p>
      </FormGroup>
      <FormGroup>
        <Label>
          <Msg id="delivery.subject" />
        </Label>
        <p className="is-wrap">{mailSubject}</p>
      </FormGroup>
      <FormGroup>
        <Label>
          <Msg id="delivery.body" />
        </Label>
        <div className="code-deliver-email__confirm__mail">{preview}</div>
      </FormGroup>
      <p className="code-deliver-email__text">
        <Msg id="message.applicant.urlAnnotation" />
      </p>
    </Modal>
  );
}
