import * as classnames from "classnames";
import * as React from "react";
import { FileRejection, useDropzone } from "react-dropzone";
import { toast } from "react-toastify";

import { Icon, Button, Loading, Msg, Avatar } from "..";
import Message from "../../services/message";

/**
 * Prop interface
 */
export interface ImageUploadProps {
  name?: string;
  src?: string;
  onDrop?: (acceptedFiles: File[], rejectedFiles?: FileRejection[]) => void;
  disabled?: boolean;
  className?: string;
  uploadType?: "avatar" | "image";
  avatarSize?:
    | "small"
    | "medium"
    | "large"
    | "xlarge"
    | "xxlarge"
    | "xxlarge-landscape";
  defaultMessage?: React.ReactNode;
  stretchButton?: boolean;
}

const MAX_SIZE = 10000000; // 10MB
const defaultAvatarSrc = require("../../../assets/images/default_avatar.png");
const defaultImageSrc = require("../../../assets/images/default_coverimage.png");

/**
 * React Component
 */
export default function ImageUpload({
  src,
  onDrop,
  disabled,
  className,
  uploadType = "avatar",
  defaultMessage,
  stretchButton,
  avatarSize = "xxlarge",
}: ImageUploadProps) {
  const rootStyle = classnames("code-c-image-upload", {
    [`${className}`]: Boolean(className),
  });

  const defaultSrc =
    uploadType === "avatar" ? defaultAvatarSrc : defaultImageSrc;

  const dropzoneRef = React.useRef<HTMLInputElement>(null);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    disabled,
    multiple: false,
    accept: {
      "image/*": [],
    },
    maxSize: MAX_SIZE,
    onDropRejected: (files) => {
      if (files[0].file.size > MAX_SIZE) {
        toast.error(Message.getMessageByKey("error.filesize"));
      } else {
        toast.error(Message.getMessageByKey("error.filemisc"));
      }
    },
  });

  return (
    <div className={rootStyle}>
      <div
        {...getRootProps({
          className: classnames("code-c-image-upload__drag-area", {
            "is-disabled": disabled,
          }),
        })}
      >
        <input {...getInputProps()} ref={dropzoneRef} />
        <Avatar
          src={src}
          defaultSrc={defaultSrc}
          size={avatarSize}
          defaultMessage={defaultMessage}
        />
        <Loading isOpen={disabled} fullScreen={false} />
      </div>
      <div className="code-c-image-upload__button">
        <Button
          size="small"
          loading={disabled}
          onClick={() => dropzoneRef.current?.click()}
          ariaLabel="Choose Upload File"
          stretch={stretchButton}
        >
          <Icon type="camera" />
          <span>
            <Msg id="button.chooseImageFile" />
          </span>
        </Button>
      </div>
    </div>
  );
}
