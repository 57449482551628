import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { connect } from "react-redux";
import {
  Switch,
  Route,
  Redirect,
  RouteComponentProps,
  withRouter,
} from "react-router-dom";
import { Action, Dispatch } from "redux";

import { getDefaultSignInPathname } from "@shared/services/url";

import { currentProjectSetAction } from "../../actions";
import WarnTraining from "../../components/common/error/warnTraining/WarnTraining";
import { ContentsRoute } from "../../components/orgs/contents/Contents.route";
import { ExamRoute } from "../../components/orgs/exams/Exam.route";
import { SettingsRoute } from "../../components/orgs/settings/Settings.route";
import { RootState } from "../../reducers";
import { RestrictedRoute, Msg } from "../../shared/components";
import { UserModel } from "../../shared/models";
import { ProjectRole, UserRole } from "../../shared/services/enums";

interface ExposedProps {
  routerLocation: {};
}

interface OrgsRoutesProps {
  authenticated: boolean;
  routerLocation: { pathname: string };
  examError: boolean;
  signedOut: boolean;
  currentUser: UserModel;
  currentProjectId: number;
  setCurrentProjectId: (payload: {}) => void;
}

/**
 * Route setting
 */
const OrgsRoutes = ({
  currentProjectId,
  authenticated,
  routerLocation,
  setCurrentProjectId,
  examError,
  signedOut,
}: OrgsRoutesProps & RouteComponentProps<{}>) => {
  return authenticated ? (
    <div>
      <BreadcrumbsItem to="/">
        <Msg id={"home"} />
      </BreadcrumbsItem>
      <Switch>
        <RestrictedRoute
          path="/p/:projectId/exams"
          roles={[
            UserRole.SystemAdmin,
            ProjectRole.ProjectAdmin,
            ProjectRole.ExamCreator,
            ProjectRole.ExamDeliverer,
            ProjectRole.Reviewer,
          ]}
        >
          <ExamRoute
            error={examError}
            setCurrentProjectId={setCurrentProjectId}
            projectId={currentProjectId}
          />
        </RestrictedRoute>
        <RestrictedRoute
          path="/p/:projectId/(challenges|questions)"
          roles={[
            UserRole.SystemAdmin,
            ProjectRole.ProjectAdmin,
            ProjectRole.ExamCreator,
            ProjectRole.ChallengeCreator,
          ]}
        >
          <ContentsRoute />
        </RestrictedRoute>
        <Route path="/p/:projectId/settings" component={SettingsRoute} />
        <Route path="/settings" component={SettingsRoute} />
        <Route path="/courses" component={WarnTraining} />
        <Redirect to="/" />
      </Switch>
    </div>
  ) : (
    <Redirect
      to={{
        pathname: getDefaultSignInPathname(window.location.hostname),
        state: { from: signedOut ? "/" : routerLocation },
      }}
    />
  );
};

const mapStateToProps = (state: RootState, props: ExposedProps) => ({
  ...props,
  authenticated: state.auth.authenticated,
  examError: state.exam.error,
  signedOut: state.auth.signedOut,
  currentUser: state.user.user,
  currentProjectId: state.project.currentProjectId,
});

const mapDispatchToProps = (
  dispatch: Dispatch<Action>,
  ownProps: OrgsRoutesProps,
) => ({
  setCurrentProjectId: (payload: { projectId: number | undefined }) => {
    if (ownProps.currentProjectId !== payload.projectId) {
      dispatch(currentProjectSetAction.request(payload));
    }
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(OrgsRoutes));
