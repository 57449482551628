import * as classnames from "classnames";

import { Icon } from "../../../shared/components";
import Message from "../../../shared/services/message";
import {
  PasswordOptions,
  defaultPasswordOptions,
} from "../../../shared/services/validation";
import Label from "../label/Label";

/**
 * Prop interface
 */
export interface PasswordRequirementsProps {
  className?: string;
  validation?: PasswordOptions;
  password: string;
}

const defaultValidation = Object.assign({}, defaultPasswordOptions);
/**
 * React Component
 */
export default function PasswordRequirements({
  className,
  validation = defaultValidation,
  password = "",
}: PasswordRequirementsProps) {
  const rootStyle = classnames("code-c-password-requirements", {
    [`${className}`]: Boolean(className),
  });

  const validator = {
    min: (pass: string, count: number) => pass.length >= count,
    max: (pass: string, count: number) => pass.length <= count,
    lowerCase: (pass: string, count: number) =>
      (pass.match(/[a-z]/g) || []).length >= count,
    upperCase: (pass: string, count: number) =>
      (pass.match(/[A-Z]/g) || []).length >= count,
    numeric: (pass: string, count: number) =>
      (pass.match(/[0-9]/g) || []).length >= count,
    symbol: (pass: string, count: number) =>
      (pass.match(/[^a-zA-Z0-9]/g) || []).length >= count,
  };

  const requirements = Object.keys(validation)
    .filter((key) => key !== "requirementCount")
    .map((key) => {
      const count = validation[key];

      const OK = validator[key](password, count);

      return (
        <li
          className={
            OK
              ? "code-c-password-requirements__passed"
              : "code-c-password-requirements__failed"
          }
          key={key}
        >
          <Icon type={OK ? "check" : "times"} />
          {Message.getMessageByKey(`validation.password.${key}`).replace(
            "{0}",
            count,
          )}
        </li>
      );
    });

  return (
    <div className={rootStyle}>
      <Label>{Message.getMessageByKey("validation.password.title")}</Label>
      <ul>{requirements}</ul>
    </div>
  );
}
