import * as classnames from "classnames";
import * as React from "react";

import { Msg } from "@shared/components";

interface Props {
  children: React.ReactNode;
  titleKey: string;
  className?: string;
}

export default function SubmissionActionLogCard({
  children,
  titleKey,
  className,
}: Props) {
  const rootStyle = classnames(
    "code-c-submission-result-action-log__detail-card",
    className,
  );

  return (
    <article className={rootStyle}>
      <h2 className="code-c-submission-result-action-log__card-title">
        <Msg id={titleKey} />
      </h2>
      <section>{children}</section>
    </article>
  );
}
