import { connect } from "react-redux";

import { RootState } from "../../../../../../../../reducers";
import {
  SettingSlackIntegrationAddChannelModal,
  ExternalProps,
} from "./SettingSlackIntegrationAddChannelModal";

const mapStateToProps = (state: RootState, props: ExternalProps) => {
  return {
    ...props,
    socialChannels: state.project.projectSlackSocialChannels,
  };
};

const mapDispatchToProps = () => ({});

/**
 * Wrapped component
 */
const wrappedComponent = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SettingSlackIntegrationAddChannelModal);

export default wrappedComponent;
