import actionCreator from "../shared/services/actionCreator";

export const questionSourceCreateAction = actionCreator(
  "questionSourceCreateAction",
);
export const questionGetAction = actionCreator("questionGetAction");
export const questionSourceGetAction = actionCreator("questionSourceGetAction");
export const questionSourceUpdateAction = actionCreator(
  "questionSourceUpdateAction",
);

export const questionFiltersAction = actionCreator("questionFiltersAction");

export const questionDeleteAction = actionCreator("questionDeleteAction");

export const questionListGetAction = actionCreator("questionListGetAction");

export const questionListUpdateAction = actionCreator(
  "questionListUpdateAction",
);

export const questionListDeleteAction = actionCreator(
  "questionListDeleteAction",
);

export const questionImportAction = actionCreator("questionImportAction");

export const questionListAllGetAction = actionCreator(
  "questionListAllGetAction",
);
export const questionCopyAction = actionCreator("questionCopyAction");

export const questionExamLevelInsightsGetAction = actionCreator(
  "questionExamLevelInsightsGetAction",
);

export const questionExamLevelInsightClearAction = actionCreator(
  "questionExamDetailAction",
);
