import * as classnames from "classnames";
import { isEqual } from "lodash";
import * as React from "react";
import { Prompt as RouterPrompt } from "react-router";

/**
 * Prop interface
 */
export interface PromptProps {
  when?: boolean;
  message?: string;
  className?: string;
}

/**
 * React Component
 * use Prompt from react-router directly.
 * @deprecated
 */
class Prompt extends React.Component<PromptProps> {
  constructor(props: PromptProps) {
    super(props);
  }

  public componentDidMount() {
    if (this.props.when) {
      this.enable();
    }
  }

  public shouldComponentUpdate(nextProps: PromptProps) {
    return !isEqual(nextProps, this.props);
  }

  public UNSAFE_componentWillReceiveProps(nextProps: PromptProps) {
    if (nextProps.when) {
      if (!this.props.when) {
        this.enable();
      }
    } else {
      this.disable();
    }
  }

  public componentWillUnmount() {
    this.disable();
  }

  public render() {
    const { when = true, message = "", className } = this.props;

    const rootStyle = classnames("code-c-prompt", {
      [`${className}`]: Boolean(className),
    });

    return (
      <div className={rootStyle}>
        <RouterPrompt when={when} message={message} />
      </div>
    );
  }

  private enable() {
    window.addEventListener("beforeunload", this.onBeforeunload);
  }

  private disable() {
    window.removeEventListener("beforeunload", this.onBeforeunload);
  }

  private onBeforeunload(event: BeforeUnloadEvent) {
    // string contents are irrelevant
    const message = "navigation.confirm";

    if (event) {
      event.returnValue = message;
    }
    return message;
  }
}

export default Prompt;
