import * as classnames from "classnames";
import * as React from "react";

import { IconType, IconVariant } from "./types";

/**
 * Prop interface
 */
export interface IconProps {
  /**
   * icon type
   */
  type: IconType | string;
  /**
   * icon style
   */
  variant?: "solid" | "regular" | IconVariant;
  /**
   * color - colors: primary, info, success, warning, danger or use className to style the wrapper
   */
  color?: string;
  /**
   * size
   */
  size?: string;
  /**
   * onClick
   */
  onClick?: (e?: React.SyntheticEvent<EventTarget>) => void;
  /**
   * className
   */
  className?: string;
  /**
   * className for inner icon
   */
  iconClassName?: string;
}

/**
 * React Component
 */
export function Icon({
  type,
  variant,
  color,
  size,
  onClick,
  className,
  iconClassName,
}: IconProps) {
  const iconSizeTable = {
    medium: "fa-lg",
    large: "fa-2x",
  };

  const rootStyle = classnames("code-c-icon", "icon", {
    [`has-text-${color}`]: Boolean(color),
    [`is-${size}`]: Boolean(size),
    [`${className}`]: Boolean(className),
  });

  const iconStyle = classnames(!variant && "fa", {
    // Using fa and fa-solid together doesn't work for some icons
    [`fa-${type}`]: Boolean(type),
    [`fa-${variant}`]: Boolean(variant),
    [`${iconSizeTable[size || ""]}`]: Boolean(iconSizeTable[size || ""]),
    [`${iconClassName}`]: Boolean(iconClassName),
  });

  const dataTestId = variant ? type + "-" + variant : type;

  return (
    <span className={rootStyle} onClick={onClick}>
      <i className={iconStyle} data-testid={dataTestId} />
    </span>
  );
}
