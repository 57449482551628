import actionCreator from "../shared/services/actionCreator";

export const reportApplicantListFirstAction = actionCreator(
  "reportApplicantListFirstAction",
);
export const reportApplicantListAction = actionCreator(
  "reportApplicantListAction",
);

export const reportScoresAction = actionCreator("reportScoresAction");
/**
 * @deprecated Please use getReportExamChallengeAnalysis() instead with useGetExamChallengeAnalysis
 */
export const reportChallengeAction = actionCreator("reportChallengeAction");
/**
 * @deprecated Please use getReportSubmissionRankingDetails() instead with useGetSubmissionRankingDetails
 */
export const reportRankingAction = actionCreator("reportRankingAction");
export const reportResultsAction = actionCreator("reportResultsAction");

export const reportCategoriesAction = actionCreator("reportCategoriesAction");
export const reportEvaluationPointAction = actionCreator(
  "reportEvaluationPointAction",
);

export const reportCategoriesEnumAction = actionCreator(
  "reportCategoriesEnumAction",
);
export const examSubmissionGetAction = actionCreator("examSubmissionGetAction");
