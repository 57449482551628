import * as classNames from "classnames";
import * as React from "react";

import { FormattedPressedKeys, Icon, Tooltip } from "@shared/components";
import Message from "@shared/services/message";

interface ChildProp {
  children?: React.ReactNode;
}

interface Props extends ChildProp {
  clickable?: boolean;
  isCollapse?: boolean;
  time: string;
  onClick?: (
    value: boolean,
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => void;
}

const SubmissionResultActionLogRow = ({
  children,
  clickable,
  isCollapse = true,
  time,
  onClick,
}: Props) => {
  const [collapse, setCollapse] = React.useState(isCollapse);
  const classes = classNames("code-c-submission-result-action-log__table-row", {
    "is-clickable": clickable,
  });
  const icon = collapse ? "angle-down" : "angle-up";
  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!clickable) {
      return;
    }

    e.preventDefault();
    const value = !collapse;
    setCollapse((prevState) => !prevState);

    if (onClick) {
      onClick(value, e);
    }
  };

  React.useEffect(() => {
    setCollapse(isCollapse);
  }, [isCollapse]);

  return (
    <div className={classes} onClick={handleClick}>
      <div>{clickable && <Icon type={icon} />}</div>
      {children}
      <div className="time-column">{time}</div>
    </div>
  );
};

SubmissionResultActionLogRow.Title = function Title({ children }: ChildProp) {
  if (children === "-") {
    return <>{children}</>;
  }

  return (
    <Tooltip
      wrapperClassName="code-c-submission-result-action-log__row-title"
      placement="bottom"
      maxSize="large"
      text={children}
    >
      <>{children}</>
    </Tooltip>
  );
};
SubmissionResultActionLogRow.Action = function Action({
  children,
  className,
}: ChildProp & { className: string }) {
  const rootStyles = classNames(
    "code-c-submission-result-action-log__row-action",
    { [`${className}`]: Boolean(className) },
  );
  return <div className={rootStyles}>{children}</div>;
};

SubmissionResultActionLogRow.ActionExtraInfo = function ActionExtraInfo({
  unfocusedTime,
  pastedText,
  screenshotPressedKeys,
}: {
  unfocusedTime?: string;
  pastedText?: string;
  screenshotPressedKeys?: string[];
}) {
  let extraInfo = undefined;
  if (unfocusedTime) {
    extraInfo = unfocusedTime;
  } else if (pastedText) {
    const pastedTextLength = pastedText?.length || 0;
    extraInfo = Message.getCountLabelString(
      pastedTextLength,
      "common.character",
    );
  } else if (screenshotPressedKeys) {
    extraInfo = <FormattedPressedKeys pressedKeys={screenshotPressedKeys} />;
  } else {
    return null;
  }

  return <>({extraInfo})</>;
};

export default SubmissionResultActionLogRow;
