import { connect } from "react-redux";
import { Action, Dispatch } from "redux";

import { examSimpleListGetAction, orgAllContractsGetAction } from "@actions";

import { RootState } from "@reducers";

import SettingContract from "./SettingContract";

const mapStateToProps = (state: RootState) => ({
  pastContracts: state.orgs.pastContracts,
  currentContracts: state.orgs.currentContracts,
  futureContracts: state.orgs.futureContracts,
  organization: state.user.user?.organization,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  getSimpleExamList: () => {
    dispatch(examSimpleListGetAction.request({}, { archived: "all" }));
  },
  getContracts: (examId?: number) => {
    dispatch(orgAllContractsGetAction.request(examId ? { examId } : {}));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingContract);
