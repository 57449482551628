import { Epic } from "redux-observable";

import {
  Action,
  ajaxAction,
  challengeFiltersGetAction,
  challengeGetAction,
  challengeInsightGetAction,
  challengeListGetAction,
  readmeAction,
  challengeReleaseNoteGetAction,
  challengeCollectionListGetAction,
  challengeCollectionGetAction,
} from "@actions";

import { RootState } from "@reducers";

import { ChallengeModel } from "@shared/models";
import { getCurrentOrganizationName } from "@shared/selectors";
import { ChallengeStyle } from "@shared/services/enums";

export const challengeGetEpic: Epic<Action, RootState> = (action$, state$) =>
  action$.ofType(challengeGetAction.types.request).map((action) =>
    ajaxAction.request({
      method: "get",
      url: `/api/admin/orgs/${getCurrentOrganizationName(state$)}/challenges/${
        action.payload
      }`,
      body: action.params,
      success: challengeGetAction.success,
      error: challengeGetAction.failure,
    }),
  );

const challengeSetEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(challengeGetAction.types.success).map((action) => {
    const challenge = new ChallengeModel(
      (action.payload as { result: ChallengeModel }).result,
    );

    if (ChallengeStyle.hasReadme(challenge.style)) {
      return readmeAction.request(
        challenge.id,
        challenge.currentVersion.readmeUrl || "",
      );
    } else {
      return { type: "@null" };
    }
  });

const challengeListGetEpic: Epic<Action, RootState> = (action$, state$) =>
  action$
    .ofType(challengeListGetAction.types.request)
    .debounceTime(275)
    .map((action) => {
      // TODO strong type someday...
      const { onlyPinned, ...rest } = action.payload as {
        onlyPinned?: string;
      };
      const body = {
        ...rest,
        // it needs to set project id together when onlyFavorite is true
        ...(onlyPinned === "true"
          ? {
              onlyFavorite: true,
              challengeFavoriteChallengeIds: state$
                .getState()
                .challenge.pinnedChallengeList.map((item) => item.challengeId),
            }
          : {}),
      };
      return ajaxAction.request({
        method: "post",
        url: `/api/admin/orgs/${getCurrentOrganizationName(state$)}/challenges`,
        body,
        success: challengeListGetAction.success,
        cancel: [challengeListGetAction.types.request],
      });
    });

const challengeFiltersGetEpic: Epic<Action, RootState> = (action$, state) =>
  action$
    .ofType(challengeFiltersGetAction.types.request)
    .filter(
      () =>
        !state.getState().challenge.challengeFilters.programmingCategories
          .length,
    )
    .flatMap(() => [
      ajaxAction.request({
        method: "get",
        url: "/api/enum/challenges/programmingcategories",
        success: (payload: {}) =>
          challengeFiltersGetAction.success({
            extra: "programmingCategories",
            ...payload,
          }),
      }),
      ajaxAction.request({
        method: "get",
        url: "/api/enum/challenges/programminglanguages",
        success: (payload: {}) =>
          challengeFiltersGetAction.success({
            extra: "programmingLanguages",
            ...payload,
          }),
      }),
      ajaxAction.request({
        method: "get",
        url: "/api/enum/challenges/difficulties",
        success: (payload: {}) =>
          challengeFiltersGetAction.success({
            extra: "difficulties",
            ...payload,
          }),
      }),
      ajaxAction.request({
        method: "get",
        url: "/api/enum/challenges/styles",
        success: (payload: {}) =>
          challengeFiltersGetAction.success({ extra: "styles", ...payload }),
      }),
    ]);

const challengeInsightGetEpic: Epic<Action, RootState> = (action$, state$) =>
  action$.ofType(challengeInsightGetAction.types.request).map((action) =>
    ajaxAction.request({
      method: "get",
      url: `/api/admin/orgs/${getCurrentOrganizationName(state$)}/insight/${
        action.payload
      }`,
      success: challengeInsightGetAction.success,
      error: challengeInsightGetAction.failure,
    }),
  );

export const challengeReleaseNoteGetEpic: Epic<Action, RootState> = (action$) =>
  action$.ofType(challengeReleaseNoteGetAction.types.request).map((action) => {
    // TODO apply strong type
    const { challengeId, versionCode } = (
      action as Action & {
        params: { challengeId: number; versionCode?: string };
      }
    ).params;
    return ajaxAction.request({
      method: "get",
      body: { laterThan: versionCode },
      url: `/api/challenges/${challengeId}/releases`,
      success: challengeReleaseNoteGetAction.success,
      error: challengeReleaseNoteGetAction.failure,
    });
  });

export const challengeCollectionListGetEpic: Epic<Action, RootState> = (
  action$,
) =>
  action$.ofType(challengeCollectionListGetAction.types.request).map((action) =>
    ajaxAction.request({
      method: "get",
      url: `/api/challengecollections`,
      body: action.payload,
      success: challengeCollectionListGetAction.success,
      error: challengeCollectionListGetAction.failure,
      cancel: [challengeCollectionListGetAction.types.request],
    }),
  );

export const challengeCollectionGetEpic: Epic<Action, RootState> = (action$) =>
  action$.ofType(challengeCollectionGetAction.types.request).map((action) =>
    ajaxAction.request({
      method: "get",
      url: `/api/challengecollections/${action.payload}`,
      body: action.params,
      success: challengeCollectionGetAction.success,
      error: challengeCollectionGetAction.failure,
    }),
  );

export default [
  challengeFiltersGetEpic,
  challengeGetEpic,
  challengeInsightGetEpic,
  challengeListGetEpic,
  challengeSetEpic,
  challengeReleaseNoteGetEpic,
  challengeCollectionListGetEpic,
  challengeCollectionGetEpic,
];
