import * as classnames from "classnames";

import { formatTimeElapsedString } from "@shared/services/date";
import { ExecStatus } from "@shared/services/enums";
import Message from "@shared/services/message";
import { getRoundedRawScore } from "@shared/services/score";

/**
 * React Component
 */
import { DataModel, HistoryGraphItem } from "../../HistoryGraph";

/**
 * Prop interface
 */
export interface HistoryGraphTooptipProps {
  data: DataModel[];
  items: HistoryGraphItem[];
  startedAt: string;
  isAIChallenge: boolean;
  scoreMax: number;
}

export const getTooltipElementString = ({
  data,
  items,
  startedAt,
  isAIChallenge,
  scoreMax,
}: HistoryGraphTooptipProps): string => {
  const { status = ExecStatus.Success } = items[data[0].index];
  const hasError = status !== ExecStatus.Success;
  const tooltipStyle = classnames("code-c-history-graph-tooltip", {
    ["has-error"]: Boolean(hasError),
  });
  const tooltipRowStyle = classnames("code-c-history-graph-tooltip__row");
  const tableRows = data.reduce((previousValue, currentValue) => {
    const { value: score, x: scoredAt } = currentValue;
    const scoreDate = new Date(scoredAt);
    const startedDate = new Date(startedAt);
    const elapsedDate = formatTimeElapsedString(
      (scoreDate.getTime() - startedDate.getTime()) / 1000,
    );
    const scoreString = isAIChallenge
      ? getRoundedRawScore(score)
      : `${score} / ${scoreMax}`;
    const errorTag = `<span class='code-c-tag tag'>${ExecStatus.toString(
      status,
    )}</span>`;

    return (previousValue +=
      `<tr class='${tooltipRowStyle}'>` +
      `<td colspan='1'>${hasError ? errorTag : scoreString}</td>` +
      `<td class='value' colspan='3'>${elapsedDate}</td>` +
      `<td class='value' colspan='3'>${scoreDate.toLocaleString("ja-JP")} 
          </td>` +
      `</tr>`);
  }, "");

  return (
    `<div class='${tooltipStyle}'>` +
    `<table><tr class='${tooltipRowStyle}'>
      ` +
    `<th colspan='1'>${Message.getMessageByKey("result")}
        </th>
        ` +
    `<th colspan='3'>${Message.getMessageByKey("submission.timeElapsed")}
        </th>` +
    `<th colspan='3'>${Message.getMessageByKey("submission.timeSaved")} 
        </th>` +
    `</tr>${tableRows} 
        </table></div>`
  );
};
