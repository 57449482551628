import * as classnames from "classnames";
import * as React from "react";

import { ValidationMessage, hasValidationError } from "..";

/**
 * Prop interface
 */
export interface SwitchPanelProps {
  name?: string;
  id?: string;
  value?: boolean;
  readOnly?: boolean;
  className?: string;
  labelClassName?: string;
  error?: { [key: string]: string };
  hideErrorMessage?: boolean;
  onChange?: (event: React.FormEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FormEvent<HTMLInputElement>) => void;
  children?: React.ReactNode;
  ariaLabel?: string;
  ariaPressed?: boolean; // Used for aria-pressed attribute when role is set to "button"
  role?: string;
  alignCircle?: "top" | "bottom" | "center";
  initialLineHeight?: string; // If we want the circle to be aligned vertically with the first line of the content then set this prop to the line-height of the first line. Needs to include a unit, e.g. "1.5rem"
  width?: string;
  maxWidth?: string;
  isExpandedBelow?: boolean; // Set to true when in a ToggleContainer and the container has been expanded below the switch panel
  dataTestautomationid: string;
}

/**
 * React Component
 */
export default function SwitchPanel({
  name,
  id,
  value,
  readOnly = false,
  onChange,
  onBlur,
  error,
  hideErrorMessage = false,
  children,
  className,
  labelClassName,
  ariaLabel,
  ariaPressed,
  role,
  alignCircle = "top",
  initialLineHeight,
  width,
  maxWidth,
  isExpandedBelow = false,
  dataTestautomationid,
}: SwitchPanelProps) {
  const [isChecked, setIsChecked] = React.useState(value);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
    if (onChange) {
      onChange(event);
    }
  };

  const rootStyle = classnames("code-c-switch-panel", "checkbox", {
    [`${className}`]: Boolean(className),
    "is-checked": isChecked,
  });
  const labelStyle = classnames("code-c-switch-panel__label", labelClassName, {
    "is-readonly": readOnly,
    "is-danger": hasValidationError(name, error),
    "is-expanded-below": isExpandedBelow,
  });

  return (
    <div className={rootStyle} style={{ width: width, maxWidth: maxWidth }}>
      <label
        className={classnames(labelStyle, [`is-align-${alignCircle}`])}
        aria-label={ariaLabel}
        aria-pressed={role === "button" ? ariaPressed : undefined}
        role={role}
      >
        <div
          className="code-c-switch-panel__checkmark-wrapper"
          style={{ height: initialLineHeight }}
        >
          <input
            className="code-c-switch-checkbox"
            type="checkbox"
            id={id}
            name={name}
            checked={value}
            disabled={readOnly}
            onChange={handleChange}
            onBlur={onBlur}
            data-testautomationid={dataTestautomationid}
          />
          <span className="code-c-switch-panel__checkmark-container">
            <span className="code-c-switch-panel__checkmark" />
          </span>
        </div>
        <div className="code-c-switch-panel__label__text">{children}</div>
      </label>
      {!hideErrorMessage && <ValidationMessage name={name} error={error} />}
    </div>
  );
}
