import { Fade, Msg, TimelineItem } from "@shared/components";

interface SubmissionTimelineHeaderProps {
  dateTime: string;
  show?: boolean;
  titleId: string;
}

function SubmissionTimelineHeader({
  dateTime,
  show,
  titleId,
}: SubmissionTimelineHeaderProps) {
  return (
    <TimelineItem className="code-submission-timeline-item">
      <Fade show={show}>
        <article className="code-submission-timeline-header">
          <Msg
            tagName="h4"
            id={titleId}
            className="code-submission-timeline-header__title"
          />
          <p>{dateTime}</p>
        </article>
      </Fade>
    </TimelineItem>
  );
}

export default SubmissionTimelineHeader;
