import * as classnames from "classnames";

import { Button, Icon, Tag } from "../../../../../../../shared/components";
import { CustomFormDefinitionModel } from "../../../../../../../shared/models";
import { CustomFormDefinitionType } from "../../../../../../../shared/services/enums";
import Message from "../../../../../../../shared/services/message";

/**
 * Prop interface
 */
export interface CustomFormDefinitionDisplayProps {
  definition: CustomFormDefinitionModel;
  onEdit?: () => void;
  onRemove?: () => void;
}

/**
 * React Component
 */
export function CustomFormDefinitionDisplay({
  definition,
  onEdit,
  onRemove,
}: CustomFormDefinitionDisplayProps) {
  /**
   * State
   */
  /**
   * Private Functions
   */
  const rootStyle = classnames("code-c-custom-form-definition-display");

  /**
   * Render
   */
  return (
    <div className={rootStyle}>
      <div className="code-c-custom-form-definition-display__container">
        <div className="code-c-custom-form-definition-display__bars">
          <Icon type="bars" />
        </div>
        <div>
          <div>
            <small>{CustomFormDefinitionType[definition.dataType]}</small>
          </div>
          <div className="code-c-custom-form-definition-display__title">
            {definition.displayName}
            {definition.required && (
              <Tag>{Message.getMessageByKey("form.required")}</Tag>
            )}
          </div>
        </div>
      </div>
      <div className="code-c-custom-form-definition-display__buttons">
        <Button size="small" shrink={true} aria-label="Edit" onClick={onEdit}>
          <Icon type="pencil" />
        </Button>
        <Button
          size="small"
          shrink={true}
          aria-label="Delete"
          onClick={onRemove}
        >
          <Icon type="trash" />
        </Button>
      </div>
    </div>
  );
}
