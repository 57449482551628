import * as classnames from "classnames";
import * as React from "react";

import { CliSupportBadge } from "@shared/components";
import { useLocalCodeFiles } from "@shared/hooks/useLocalCodeFiles";
import { LocalFileMetaData, SubmissionResultDetailModel } from "@shared/models";
import {
  ChallengeTakenBy,
  FileScanningStatus,
  ProgrammingLanguage,
} from "@shared/services/enums";
import { FileUploadConfig } from "@shared/services/fileUpload";
import { getTimeExtensions } from "@shared/services/score";

import { SubmissionDetail, Msg } from "../..";
import { LocalCodeFiles } from "../localCodeFiles/LocalCodeFiles";

/**
 * Prop interface
 */
export type SubmissionDetailCodingProps = {
  submissionResultDetail: SubmissionResultDetailModel;
  className?: string;
  fileUploadConfig?: FileUploadConfig;
};

/**
 * React Component
 */
export const SubmissionDetailCoding: React.FunctionComponent<
  SubmissionDetailCodingProps
> = (props: SubmissionDetailCodingProps) => {
  const { submissionResultDetail, className, fileUploadConfig } = props;
  const {
    codingResult,
    finishedAt,
    appealComment,
    programmingLanguage,
    totalTestcases,
    openedAt,
    showSecretTestcases,
    extensions,
    challengeId,
    id: challengeResultId,
  } = submissionResultDetail;

  /**
   * Private Functions
   */
  const rootStyle = classnames("code-c-submission-detail-coding", {
    [`${className}`]: Boolean(className),
  });

  const graphItems = codingResult.scoreHistory.map((item) => ({
    datetime: item.createdAt,
    value: item.successfulTestcases || 0,
    status: item.execStatus,
  }));
  if (graphItems.length === 1) {
    // if the score is only one, the score graph line become a dot, so add extra score to make it a line
    graphItems.push({
      datetime: finishedAt,
      value: graphItems[0].value,
      status: graphItems[0].status,
    });
  }
  const timeExtensions = getTimeExtensions(extensions, challengeId);

  const {
    isEmpty: noLocalCodeFiles,
    files,
    downloadFile,
    fileScanStatusesString,
    skippedFileScanReasons,
  } = useLocalCodeFiles({
    fileUploadConfig,
    challengeResultId,
  });
  const [fileToBeDownloaded, setIsFileToBeDownloaded] = React.useState<
    LocalFileMetaData | undefined
  >(undefined);

  return (
    <div className={rootStyle}>
      {programmingLanguage && (
        <SubmissionDetail.SubmissionDetailSection
          title={<Msg id="selectedLanguage" />}
        >
          <div className="code-c-submission-detail-section__title">
            {ProgrammingLanguage.toString(programmingLanguage)}
            {submissionResultDetail.takenBy ===
              ChallengeTakenBy.LocalMachine && <CliSupportBadge isExam />}
          </div>
        </SubmissionDetail.SubmissionDetailSection>
      )}
      <SubmissionDetail.SubmissionDetailSection
        title={<Msg id="submission.scoreHistory" />}
      >
        <SubmissionDetail.HistoryGraph
          items={graphItems}
          submittedAt={finishedAt}
          scoreMax={totalTestcases}
          openedAt={openedAt}
          timeExtensions={timeExtensions}
        />
      </SubmissionDetail.SubmissionDetailSection>
      <SubmissionDetail.SubmissionDetailSection
        title={<Msg id="submission.testOutput" />}
        className="no-body-padding"
      >
        <SubmissionDetail.TestOutput
          output={codingResult.testOutput}
          outputJson={codingResult.testOutputJson}
          evaluationPoint={codingResult.evaluationPoint}
          showSecretTestcases={showSecretTestcases}
        />
      </SubmissionDetail.SubmissionDetailSection>
      {fileUploadConfig?.uploadEnabled && (
        <SubmissionDetail.SubmissionDetailSection
          title={<Msg id="submission.localCodeFiles" />}
          isEmpty={noLocalCodeFiles}
        >
          <LocalCodeFiles
            files={files}
            onClickDownload={(file) => {
              if (file.scanningStatus === FileScanningStatus.Malicious) {
                setIsFileToBeDownloaded(file);
              } else {
                downloadFile(file);
              }
            }}
            isConfirmModalOpen={fileToBeDownloaded !== undefined}
            onCloseConfirmModal={() => setIsFileToBeDownloaded(undefined)}
            onConfirmModal={() => {
              if (fileToBeDownloaded) {
                downloadFile(fileToBeDownloaded);
                setIsFileToBeDownloaded(undefined);
              }
            }}
            fileScanStatusesString={fileScanStatusesString}
            skippedFileScanReasons={skippedFileScanReasons}
          />
        </SubmissionDetail.SubmissionDetailSection>
      )}
      <SubmissionDetail.SubmissionDetailSection
        title={<Msg id="common.applicantExplanation" />}
        isEmpty={!appealComment}
      >
        <SubmissionDetail.ApplicantExplanation
          applicantExplanation={appealComment}
        />
      </SubmissionDetail.SubmissionDetailSection>
    </div>
  );
};
