import * as classnames from "classnames";
import * as Clipboard from "clipboard";
import * as React from "react";
import { generate } from "shortid";

import {
  Tabs,
  Tag,
  FormGroup,
  Label,
  Icon,
  Msg,
  DifficultyTag,
  TagCloud,
  HighlightedText,
  JumpTo,
  DraftTag,
  New,
  LinkedTag,
  TogglePin,
  Restricted,
  Loading,
} from "@shared/components";

/**
 * Hooks
 */
import { useScrollToTop } from "@shared/hooks";
import {
  useChallengePinList,
  useCreateChallengePin,
  useDeleteChallengePin,
  useUpdateChallengeQuestion,
} from "@shared/hooks/query";
import {
  ChallengeModel,
  EnumModel,
  ChallengeReleaseNoteModel,
  ReadmeModel,
  ChallengeInsightModel,
  OrganizationModel,
  QuestionModel,
} from "@shared/models";
import { formatDateTimeMinutes, isNew } from "@shared/services/date";
import {
  ChallengeStyle,
  ChallengeStatus,
  SpokenLanguages,
  UserRole,
  ProjectRole,
  TierAction,
} from "@shared/services/enums";
import Message from "@shared/services/message";
import { isGiveryOrg } from "@shared/services/organization";
import { isChallengeAllowed } from "@shared/services/tier";
import {
  getChallengePreviewPath,
  getChallengePermalinkURL,
} from "@shared/services/url";

import { ChallengeActionDropdown } from "../challengeActionDropdown/ChallengeActionDropdown";
import ChallengeActionModals from "../challengeActionModals/ChallengeActionModals.connect";
import {
  ChallengePrompts,
  ChallengeQuestions,
  ChallengeReleases,
  ChallengeSolution,
  ChallengeStats,
} from "./partials";
import { useQuestionInsights } from "./partials/useQuestionInsights";

/**
 * Prop interface
 */
export type ExternalProps = {
  className?: string;
  challengeId: number;
  highlightedKeyword?: string;
  hideCopy?: boolean;
  hidePin?: boolean;
  hideActionDropdown?: boolean;
  version?: number;
  challengeVersionId?: number;
  onClose: () => void;
  showExamLevelStats?: boolean;
};

export type InjectedProps = {
  appBasePath: string;
  org?: OrganizationModel;
  detailChallenge?: ChallengeModel;
  releaseNote?: ChallengeReleaseNoteModel;
  readme?: ReadmeModel;
  challengeInsight?: ChallengeInsightModel;
  programmingCategories: EnumModel[];
  programmingLanguages: EnumModel[];
  challengeUpdateError: boolean;
  challengeUpdating: boolean;
  loadingDetail: boolean;
  loadingInsight: boolean;
  loadingPrompts: boolean;
  loadingReleases: boolean;
  loadingChallenge: boolean;
  errorDetail: boolean;
  questionDetailList: QuestionModel[];
  getChallenge: (challengeId: number, challengeVersionId?: number) => void;
  getReleases: (challengeId: number) => void;
  getChallengeInsight: (challengeInsightId: number) => void;
  isTierActionAllowed: (tierAction: TierAction) => boolean;
  getExamLevelInsights: (examId: number, questionId: number) => void;
};

type ChallengeDetailProps = ExternalProps & InjectedProps;

enum ChallengeDetailTab {
  ChallengePrompts,
  SolutionDetail,
  Insight,
  Questions,
  Releases,
}

/**
 * React Component
 */
export function ChallengeDetail({
  appBasePath,
  highlightedKeyword,
  hideCopy,
  hidePin = true,
  hideActionDropdown = true,
  org,
  version,
  challengeVersionId,
  challengeId,
  detailChallenge,
  releaseNote,
  readme,
  challengeInsight,
  programmingCategories = [],
  programmingLanguages = [],
  challengeUpdateError,
  challengeUpdating,
  loadingDetail,
  loadingInsight,
  loadingPrompts,
  loadingReleases,
  loadingChallenge,
  errorDetail,
  questionDetailList,
  getChallenge,
  getReleases,
  getChallengeInsight,
  onClose,
  isTierActionAllowed,
  getExamLevelInsights,
  className,
  showExamLevelStats = false,
}: ChallengeDetailProps) {
  const [ready, setReady] = React.useState<boolean | undefined>();

  const [activeTabIndex, setActiveTabIndex] = React.useState(0);

  const [activeComponentIndex, setActiveComponentIndex] = React.useState(0);

  const [isEditOpen, setEditOpen] = React.useState(false);
  const [isDeleteOpen, setDeleteOpen] = React.useState(false);
  const [isCopyOpen, setCopyOpen] = React.useState(false);
  const [isUpdating, setIsUpdating] = React.useState(false);

  const [challenge, setChallenge] = React.useState(detailChallenge);

  const [tabItems, setTabItems] = React.useState<
    {
      label: string;
      index: ChallengeDetailTab;
    }[]
  >([]);

  const [copybox, setCopybox] = React.useState<{ id: string; copied: boolean }>(
    {
      id: `copybox${generate()}`,
      copied: false,
    },
  );

  const rootStyle = classnames("code-challenge-detail", {
    [`${className}`]: Boolean(className),
  });

  const buttonStyle = classnames("button is-small is-shrink", {
    badge: copybox.copied,
  });

  const buttonRef = React.useCallback((node: HTMLButtonElement | null) => {
    let clipboard: Clipboard | undefined;
    if (node) {
      clipboard = new Clipboard(node);
    } else {
      clipboard?.destroy();
    }
  }, []);

  const tagContentRef = React.useRef<HTMLDivElement | null>(null);

  const scrollToTop = useScrollToTop(tagContentRef);
  const deletePinChallenge = useDeleteChallengePin();
  const updateChallengeQuestions = useUpdateChallengeQuestion();
  const createPinChallenge = useCreateChallengePin();

  /**
   * Effects
   */
  React.useEffect(() => {
    if (challengeId) {
      getChallenge(challengeId, challengeVersionId);
      getReleases(challengeId);
    }
  }, [getChallenge, getReleases, challengeId, challengeVersionId]);

  React.useEffect(() => {
    if (detailChallenge) {
      setChallenge(detailChallenge);
    }
  }, [detailChallenge]);

  React.useEffect(() => {
    if (loadingDetail) {
      setReady(false);
    } else {
      setReady(!errorDetail);
      if (errorDetail) {
        // close the detail panel when API request something goes wrong.
        onClose();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorDetail, loadingDetail, setReady]);

  React.useEffect(() => {
    if (ready && challenge) {
      setTabItems([
        challenge.style === ChallengeStyle.Quiz
          ? {
              label: Message.getMessageByKey("challenge.tab.questions"),
              index: ChallengeDetailTab.Questions,
            }
          : {
              label: Message.getMessageByKey("challenge.tab.prompts"),
              index: ChallengeDetailTab.ChallengePrompts,
            },
        ...(ChallengeStyle.hasSolutionExplanation(challenge.style)
          ? [
              {
                label: Message.getMessageByKey("challenge.tab.solution"),
                index: ChallengeDetailTab.SolutionDetail,
              },
            ]
          : []),
        ...[
          {
            label: Message.getMessageByKey("challenge.tab.insight"),
            index: ChallengeDetailTab.Insight,
          },
          {
            label: Message.getMessageByKey("challenge.tab.releases"),
            index: ChallengeDetailTab.Releases,
          },
        ],
      ]);
      if (challenge.challengeInsightId) {
        getChallengeInsight(challenge.challengeInsightId);
      }
    }
  }, [getChallengeInsight, challenge, ready, setTabItems]);

  React.useEffect(() => {
    if (tabItems.length > 0) {
      setActiveTabIndex(0);
      setActiveComponentIndex(tabItems[0].index);
    }
  }, [setActiveComponentIndex, setActiveTabIndex, tabItems]);

  React.useEffect(() => {
    if (
      isEditOpen &&
      (challengeUpdating || updateChallengeQuestions.isLoading)
    ) {
      setIsUpdating(true);
    }
  }, [isEditOpen, challengeUpdating, updateChallengeQuestions.isLoading]);

  // close the edit modal and requery challenge detail when challenge is updated.
  React.useEffect(() => {
    if (
      isUpdating &&
      !challengeUpdating &&
      !challengeUpdateError &&
      updateChallengeQuestions.isSuccess
    ) {
      setIsUpdating(false);
      setEditOpen(false);
      getChallenge(challengeId, challengeVersionId);
      getReleases(challengeId);
    }
  }, [
    challengeId,
    challengeUpdateError,
    challengeUpdating,
    challengeVersionId,
    getChallenge,
    getReleases,
    isUpdating,
    updateChallengeQuestions.isSuccess,
  ]);

  const { data: pinnedChallengeList } = useChallengePinList();

  useQuestionInsights({
    questionIds: (challenge?.questions || []).map((question) => question.id),
    questionDetailList: questionDetailList ?? [],
    shouldShowExamLevelInsights: showExamLevelStats,
    getExamLevelInsights,
  });

  /**
   * Private Functions
   */
  const handleTabClick = (index: number) => {
    setActiveTabIndex(index);
    setActiveComponentIndex(tabItems[index].index);
    scrollToTop();
  };

  const handleCopy = () => {
    setCopybox({ ...copybox, copied: true });
    window.setTimeout(() => setCopybox({ ...copybox, copied: false }), 1000);
  };

  const handleUpdateChallengeQuestion = (
    challengeId: number,
    questionIds: number[],
  ) => {
    updateChallengeQuestions.mutate({ challengeId, questionIds });
  };

  if (!challenge) {
    return null;
  }

  const previewPath = getChallengePreviewPath(challenge.id, challenge.style, {
    programmingLanguage: (challenge.programmingLanguages ?? [])[0],
    basePath: appBasePath,
    ...(version && { version }),
    ...(challengeVersionId && { challengeVersionId }),
  });

  const challengeAllowed = isChallengeAllowed({
    challenge,
    isTierActionAllowed,
  });

  const copyAllowed = isTierActionAllowed(TierAction.CustomQuizUsage);

  const isGivery = isGiveryOrg(org?.id);

  const pinnedChallenge = pinnedChallengeList?.challengeFavorites?.find(
    (item) => item.challengeId === challenge.id,
  );

  const isPinned = Boolean(pinnedChallenge);
  const onTogglePin = () =>
    pinnedChallenge
      ? deletePinChallenge.mutate({
          challengeId: pinnedChallenge.challengeId,
          pinId: pinnedChallenge.id,
        })
      : createPinChallenge.mutate(challengeId);

  const handleCloseModal = () => {
    setEditOpen(false);
    setDeleteOpen(false);
    setCopyOpen(false);
  };

  const handleDeleteChallenge = () => {
    handleCloseModal();
    onClose?.();
  };
  /**
   * Render
   */
  return (
    <div className={rootStyle}>
      {/* Edit modal has it's own loading spinner so don't show spinner here */}
      <Loading isOpen={loadingChallenge && !isEditOpen} />
      <header className="code-challenge-detail__header">
        <div className="code-challenge-detail__header-left">
          {!hideCopy && (
            <button
              className={buttonStyle}
              data-clipboard-text={`${getChallengePermalinkURL(challengeId, {
                basePath: appBasePath,
              })}`}
              data-badge={Message.getMessageByKey("message.copied")}
              onClick={handleCopy}
              ref={buttonRef}
            >
              <Icon type="link" />
            </button>
          )}
          {!hidePin && (
            <TogglePin
              className="code-challenge-detail__pin"
              pinned={isPinned}
              onClick={onTogglePin}
            />
          )}
          <h2 className="code-challenge-detail__title is-wrap">
            <HighlightedText keyword={highlightedKeyword}>
              {challenge.title}
            </HighlightedText>
          </h2>
          {challenge.isOfficial && (
            <Tag>{Message.getMessageByKey("questionCategory.preset")}</Tag>
          )}
          {challenge.status === ChallengeStatus.Draft && <DraftTag />}
          {challenge.status === ChallengeStatus.Removed && (
            <Tag>{Message.getMessageByKey("challenge.removed")}</Tag>
          )}
          {challenge.linkedChallenge &&
            challenge.linkedChallenge.status === ChallengeStatus.Ready && (
              <LinkedTag />
            )}
        </div>

        <div className="code-challenge-detail__header-right">
          {!hideActionDropdown && (
            <Restricted
              roles={[
                UserRole.SystemAdmin,
                UserRole.ChallengeCreator,
                ProjectRole.ProjectAdmin,
                ProjectRole.ChallengeCreator,
              ]}
              strictAllow={challenge.style === ChallengeStyle.Quiz && !isGivery}
            >
              <ChallengeActionDropdown
                challengeAllowed={challengeAllowed}
                copyAllowed={copyAllowed}
                isGivery={isGivery}
                challenge={challenge}
                onClickCopy={() => setCopyOpen(true)}
                onClickEdit={() => setEditOpen(true)}
                onClickDelete={() => setDeleteOpen(true)}
              />
            </Restricted>
          )}
          <button className="delete" aria-label="Close" onClick={onClose} />
        </div>
      </header>
      <main>
        <div className="code-challenge-detail__body" ref={tagContentRef}>
          <p className="code-challenge-detail__description is-wrap">
            <HighlightedText keyword={highlightedKeyword}>
              {challenge.description}
            </HighlightedText>
          </p>
          <div className="code-challenge-detail__contents">
            <Tabs
              tabItems={tabItems}
              activeIndex={activeTabIndex}
              onClick={handleTabClick}
            />
            <div className="code-challenge-detail__tab-contents">
              {activeComponentIndex === ChallengeDetailTab.ChallengePrompts && (
                <ChallengePrompts
                  challenge={challenge}
                  prompts={readme}
                  programmingLanguages={programmingLanguages}
                  previewPath={previewPath}
                  loading={loadingPrompts}
                />
              )}
              {activeComponentIndex === ChallengeDetailTab.SolutionDetail && (
                <ChallengeSolution challenge={challenge} />
              )}
              {activeComponentIndex === ChallengeDetailTab.Insight && (
                <ChallengeStats
                  challengeInsight={
                    challenge.challengeInsightId === challengeInsight?.id
                      ? challengeInsight
                      : undefined
                  }
                  loading={loadingInsight}
                />
              )}
              {activeComponentIndex === ChallengeDetailTab.Questions && (
                <ChallengeQuestions
                  challenge={challenge}
                  showExamLevelStats={showExamLevelStats}
                />
              )}
              {activeComponentIndex === ChallengeDetailTab.Releases && (
                <ChallengeReleases
                  releaseNote={releaseNote}
                  loading={loadingReleases}
                />
              )}
            </div>
          </div>
        </div>
        <aside>
          <FormGroup>
            <JumpTo to={previewPath} className="button is-small">
              <Icon type="external-link" />
              <Msg id="preview" />
            </JumpTo>
          </FormGroup>
          <FormGroup>
            <Label title={<Msg id="challenge.style" />} />
            <Tag>{ChallengeStyle.toString(challenge.style)}</Tag>
          </FormGroup>
          <FormGroup>
            <Label title={<Msg id="challenge.difficulty" />} />
            <DifficultyTag value={challenge.currentVersion.difficulty} />
          </FormGroup>
          <FormGroup>
            <Label title={<Msg id="basicTimeMinutes" />} />
            <p>
              <Icon type="clock-o" />
              {challenge.currentVersion.basicTimeMinutes}
            </p>
          </FormGroup>
          <FormGroup>
            <Label title={<Msg id="common.language" />} />
            <Tag>{SpokenLanguages.toString(challenge.language)}</Tag>
          </FormGroup>
          {challenge.numberOfOrgsUsing !== undefined && (
            <FormGroup>
              <Label title={<Msg id="challenge.numberOfOrgsUsing" />} />
              <p>
                <Icon type="building" />
                {challenge.numberOfOrgsUsing}
              </p>
            </FormGroup>
          )}
          {challenge.programmingCategories.length > 0 && (
            <FormGroup>
              <Label title={<Msg id="programmingCategories" />} />
              <TagCloud
                className="code-challenge-detail__categories"
                tagItems={challenge.programmingCategories.map((id) => ({
                  label:
                    programmingCategories.find(
                      (category) => category.value === id,
                    )?.displayString || id.toString(),
                }))}
              />
            </FormGroup>
          )}
          <FormGroup>
            <Label title={<Msg id="createdAt" />} />
            <div>
              {formatDateTimeMinutes(challenge.createdAt)}
              {isNew(challenge.createdAt) && <New />}
            </div>
          </FormGroup>
          <FormGroup>
            <Label title={<Msg id="lastUpdatedAt" />} />
            <p>
              {formatDateTimeMinutes(
                releaseNote?.releaseNotes[0].releasedAt || "",
              )}
            </p>
          </FormGroup>
          <FormGroup>
            <Label title={<Msg id="challengeVersion.versionNumber" />} />
            <p>{`${challenge.currentVersion.majorVersionNumber}.${challenge.currentVersion.minorVersionNumber}`}</p>
          </FormGroup>
          <FormGroup>
            <Label title={<Msg id="common.id" />} />
            <p>
              <HighlightedText keyword={highlightedKeyword}>
                {challenge.id.toString()}
              </HighlightedText>
            </p>
          </FormGroup>
        </aside>
      </main>
      <ChallengeActionModals
        isGivery={isGivery}
        challenge={challenge}
        isEditOpen={isEditOpen}
        isDeleteOpen={isDeleteOpen}
        isCopyOpen={isCopyOpen}
        onCloseModal={handleCloseModal}
        updateChallengeQuestions={handleUpdateChallengeQuestion}
        onDeleteChallenge={handleDeleteChallenge}
      />
    </div>
  );
}
