import { useMemo } from "react";

import { useParams } from "@context";

type RouteParams = "examId" | "submissionId" | "challengeId";

export function useRouteParams() {
  const param = useParams();
  const parseParams = useMemo(
    () =>
      Object.keys(param).reduce(
        (acum, key) => {
          const parseId = Number(param[key]);

          if (!isNaN(parseId)) {
            acum[key] = parseId;
          }

          return acum;
        },
        {} as Record<RouteParams, number>,
      ),
    [param],
  );

  return parseParams;
}
