import { useQuery } from "@tanstack/react-query";

import { getChallengeInsight } from "@api/challenges";

import { useStoreContext } from "@context";

import { ChallengeInsightModel } from "@shared/models";

interface ChallengeInsightKeyParams {
  projectId: number;
  challengeInsightId: number;
}

export const challengeInsightKeys = {
  all: ["challenge", "insight"] as const,
  insight: ({ projectId, challengeInsightId }: ChallengeInsightKeyParams) => [
    ...challengeInsightKeys.all,
    projectId,
    challengeInsightId,
  ],
};

interface ChallengeInsightParams {
  challengeInsightId: number;
  enabled?: boolean;
}

export function useGetChallengeInsight({
  challengeInsightId,
  enabled = true,
}: ChallengeInsightParams) {
  const { projectId } = useStoreContext();

  const query = useQuery<ChallengeInsightModel>({
    queryKey: challengeInsightKeys.insight({
      projectId,
      challengeInsightId,
    }),
    enabled,
    queryFn: async ({ signal }) => {
      const { result } = await getChallengeInsight({
        projectId,
        challengeInsightId,
        options: {
          signal,
        },
      });

      return result;
    },
    keepPreviousData: true,
  });

  return query;
}
