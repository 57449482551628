import * as classnames from "classnames";
import { isEqual } from "lodash";
import * as React from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { Prompt } from "react-router";

import { ExamOutlineForm } from "@components/orgs/exams/examSections/examOutline/ExamOutline";

import { ExamModel, UserModel } from "@shared/models";
import { ProjectRole, UserRole } from "@shared/services/enums";
import MSG from "@shared/services/message";

import { ExamOutline, ExamDetailHeader } from "../../../examSections";
import { ExamEditSaveArea } from "../examEditSaveArea/ExamEditSaveArea";

export interface ExamDetailOutlineProps {
  examDetail: ExamModel;
  editing?: boolean;
  currentUser?: UserModel;

  updateExamOutline?: (payload: {}) => void;
}

export interface ExamDetailOutlineState {
  editing: boolean;
  saving: boolean;
  formValid: boolean;
  formValues: {};
  oldFormValues: {};
}

class ExamDetailOutline extends React.Component<
  ExamDetailOutlineProps,
  ExamDetailOutlineState
> {
  constructor(props: ExamDetailOutlineProps) {
    super(props);

    const currentProjectId = props.examDetail ? props.examDetail.projectId : 0;

    const editing =
      props.editing &&
      props.currentUser &&
      (props.currentUser.hasRole(ProjectRole.ExamCreator, currentProjectId) ||
        props.currentUser.hasRole(UserRole.SystemAdmin));

    this.state = {
      editing: editing || false,
      saving: false,
      formValid: false,
      formValues: {},
      oldFormValues: {},
    };
  }

  public UNSAFE_componentWillReceiveProps(nextProps: ExamDetailOutlineProps) {
    if (!isEqual(this.props.examDetail, nextProps.examDetail)) {
      const currentProjectId = nextProps.examDetail
        ? nextProps.examDetail.projectId
        : 0;

      const editing =
        nextProps.editing &&
        nextProps.currentUser &&
        (nextProps.currentUser.hasRole(
          ProjectRole.ExamCreator,
          currentProjectId,
        ) ||
          nextProps.currentUser.hasRole(UserRole.SystemAdmin)) &&
        Boolean(nextProps.examDetail && !nextProps.examDetail.isArchived());

      this.setState({
        editing: (!this.state.saving && editing) || false,
      });
    }
  }

  public shouldComponentUpdate(
    nextProps: ExamDetailOutlineProps,
    nextState: ExamDetailOutlineState,
  ) {
    return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state);
  }

  public render() {
    const rootStyle = classnames("code-exam-edit__detail_outline", {
      "code-exam-detail-is-editing": this.state.editing,
    });
    const { examDetail } = this.props;
    const pageTitle = MSG.getMessageByKey("exam.outline");

    return (
      <div className={rootStyle}>
        <BreadcrumbsItem
          to={`/p/${examDetail.projectId}/exams/${examDetail.id}/outline`}
        >
          {pageTitle}
        </BreadcrumbsItem>
        <Prompt
          when={this.state.editing}
          message={MSG.getMessageByKey("navigation.confirm")}
        />
        <ExamDetailHeader
          archived={Boolean(examDetail && examDetail.isArchived())}
          editing={this.state.editing}
          onClickCancel={this.onCancel}
          onClickEdit={this.onClickEdit}
          title={MSG.getMessageByKey("outline")}
          examId={examDetail.id}
        />
        <ExamOutline
          hideTitle
          examType={examDetail?.examType}
          readOnly={!this.state.editing}
          initialValues={examDetail}
          onFormChange={this.onFormChange}
          resetForm={!this.state.editing && !this.state.saving}
          editLimited={examDetail && !examDetail.isChallengeEditable()}
          componentType="examDetail"
        />
        {this.state.editing && (
          <ExamEditSaveArea
            disabled={this.state.editing && !this.state.formValid}
            onSaveClick={this.onClickEdit}
          />
        )}
      </div>
    );
  }

  private onCancel = () => {
    this.setState({
      editing: false,
      saving: false,
      formValues: { ...this.state.oldFormValues },
    });
  };

  private onClickEdit = () => {
    if (this.state.editing) {
      const { engineerRole, purposeOfUse, ...rest } = this.state
        .formValues as ExamOutlineForm;

      this.props.updateExamOutline?.({
        ...rest,
        examSegment: {
          engineerRole,
          purposeOfUse,
        },
      });
    }
    this.setState({
      editing: !this.state.editing,
      saving: this.state.editing,
      oldFormValues: { ...this.state.formValues },
    });
  };

  private onFormChange = (formValid: boolean, formValues: {}) => {
    this.setState({
      formValid,
      formValues,
    });
  };
}

export default ExamDetailOutline;
