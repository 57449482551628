import { connect } from "react-redux";

import { RootState } from "@reducers/index";

import DiffViewer, { ExternalProps } from "./DiffViewer";

/**
 * States
 */
const mapStateToProps = (state: RootState, props: ExternalProps) => {
  const { examId, challengeId, submissionId } = props.match.params ?? {};

  return {
    examId: Number(examId),
    challengeId: Number(challengeId),
    applicantExamId: Number(submissionId),
    userLanguage: state.user.user?.language,
    useLocalDiffViewer: Boolean(process.env.REACT_APP_USE_LOCAL_DIFF_VIEWER),
  };
};

export default connect(mapStateToProps, {})(DiffViewer);
