import * as classnames from "classnames";
import { isEqual } from "lodash";
import * as React from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";

import {
  PageTitle,
  Block,
  LeftBlock,
  Form,
  FormGroup,
  Label,
  Button,
  Textarea,
  QuickHelp,
  Msg,
  Checkbox,
  Tooltip,
} from "@shared/components";
import { OrganizationModel } from "@shared/models";
import { TierAction } from "@shared/services/enums";
import Message from "@shared/services/message";
import { isGiveryOrg } from "@shared/services/organization";

/**
 * Prop interface
 */
type SettingConfigureForm = {
  ipWhitelist: string;
  mfaRequired: boolean;
};

export interface SettingConfigureProps {
  organization: OrganizationModel;
  currentIP: string;
  updateSecurity: (payload: {}) => void;
  getIP: () => void;
  isTierActionAllowed: (tierAction: TierAction) => boolean;
}

export interface SettingConfigureState {
  formValues: SettingConfigureForm;
}

/**
 * Page component
 */
class SettingConfigure extends React.Component<
  SettingConfigureProps,
  SettingConfigureState
> {
  constructor(props: SettingConfigureProps) {
    super(props);

    this.state = {
      formValues: { ipWhitelist: "", mfaRequired: false },
    };
  }

  public componentDidMount() {
    this.props.getIP();
  }

  public shouldComponentUpdate(
    nextProps: SettingConfigureProps,
    nextState: SettingConfigureState,
  ) {
    return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state);
  }

  public render() {
    if (!this.props.organization || !this.props.currentIP) {
      return null;
    }

    const {
      organization: { ipWhitelist = [], mfaRequired } = {},
      currentIP = "",
      isTierActionAllowed,
    } = this.props;

    const rootStyle = classnames("code-setting-configure");

    return (
      <div className={rootStyle}>
        <BreadcrumbsItem to="/settings/org/settings">
          <Msg id="configure" />
        </BreadcrumbsItem>
        <Block className="code-setting__header">
          <LeftBlock>
            <PageTitle>
              <Msg id="configure" />
            </PageTitle>
          </LeftBlock>
        </Block>
        <Form
          className="code-setting-configure__form"
          onSubmit={this.onSubmit}
          onFormChange={this.onFormChange}
          validation={{
            ipWhitelist: ["string", ["ipWhitelist", { currentIP }]],
            mfaRequired: ["boolean"],
          }}
          initialValues={{
            ipWhitelist: ipWhitelist.join("\n"),
            mfaRequired: mfaRequired,
          }}
          updateValues={this.state.formValues}
        >
          <FormGroup>
            {/* // MEMO: Only Assessment-type Projects have these features */}
            <Label isOptional={true} htmlFor="ipWhitelist">
              <Msg id="form.ipWhitelist" />
              <QuickHelp
                text={Message.getMessageByKey("form.ipWhitelist.tooltip")}
                maxSize="large"
              />
            </Label>
            <Label>
              <Msg id="form.ipWhitelist.currentIP" />
              {isTierActionAllowed(TierAction.IpWhitelistEnabling) ? (
                <a
                  onClick={() =>
                    this.setState({
                      formValues: {
                        ...this.state.formValues,
                        ipWhitelist: `${currentIP}\n${
                          (
                            this.state.formValues as {
                              ipWhitelist: string;
                            }
                          ).ipWhitelist || ""
                        }`,
                      },
                    })
                  }
                >
                  {currentIP}
                </a>
              ) : (
                <span>{currentIP}</span>
              )}
            </Label>

            <Tooltip
              wrapperClassName="code-setting-configure__tooltip"
              text={<Msg id="tier.disabled" />}
              disabled={isTierActionAllowed(TierAction.IpWhitelistEnabling)}
            >
              <Textarea
                id="ipWhitelist"
                name="ipWhitelist"
                placeholder={Message.getMessageByKey(
                  "form.ipWhitelist.placeholder",
                )}
                disabled={!isTierActionAllowed(TierAction.IpWhitelistEnabling)}
              />
            </Tooltip>
          </FormGroup>
          <FormGroup>
            <Label>
              <Msg id="form.mfa" />
            </Label>
            <Tooltip
              text={
                isGiveryOrg(this.props.organization?.id) ? (
                  <Msg id="tier.disabled.givery" />
                ) : (
                  <Msg id="tier.disabled" />
                )
              }
              disabled={
                !isGiveryOrg(this.props.organization?.id) &&
                isTierActionAllowed(TierAction.MfaEnabling)
              }
            >
              <Checkbox
                name="mfaRequired"
                readOnly={
                  isGiveryOrg(this.props.organization?.id) || // MEMO: Blocked on Givery orgs as it could affect admin
                  !isTierActionAllowed(TierAction.MfaEnabling)
                }
              >
                <Msg id="form.mfa.option" />
              </Checkbox>
            </Tooltip>
          </FormGroup>
          <FormGroup className="code-setting-configure__buttons">
            <Button type="primary" ariaLabel="Save">
              <Msg id="save" />
            </Button>
          </FormGroup>
        </Form>
      </div>
    );
  }

  private onFormChange = (_: boolean, formValues: SettingConfigureForm) => {
    this.setState({
      formValues,
    });
  };

  private onSubmit = (formValues: SettingConfigureForm) => {
    const { ipWhitelist: sourceIPWhitelist = "", mfaRequired } = formValues;
    const ipWhitelist = sourceIPWhitelist
      .trim()
      .split("\n")
      .filter((ip) => ip);

    this.props.updateSecurity({ ipWhitelist, mfaRequired });
  };
}

export default SettingConfigure;
