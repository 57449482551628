import { useMemo, useState } from "react";

import {
  Checkbox,
  StackedBarChart,
  StackedBarChartProps,
  Msg,
} from "@shared/components";
import { breakpoints } from "@shared/hooks/useBreakpoints";
import { ReportChallengeModel } from "@shared/models";
import { getChallengeAnalysisBarChartData } from "@shared/services/examDashboard";
import Message from "@shared/services/message";

import { useExamChallengeStatistic } from "../../hooks/useExamChallengeStatistic";

interface ChallengeAnalysisBarChartProps {
  challengeId: number;
  examId: number;
  examChallengeId: number;
  challengeVersionId: number;
  screenSize?: number;
  analysis?: ReportChallengeModel;
  canGetChallenges?: boolean;
}

// ['0-9', '10-19',..., '100']
const xTicks = Array(11)
  .fill(0)
  .map((_, idx) => `${idx * 10}-${(idx + 1) * 10 - 1}`);

xTicks[xTicks.length - 1] = "100";

export const ChallengeAnalysisBarChart = ({
  challengeId,
  examId,
  examChallengeId,
  challengeVersionId,
  analysis,
  canGetChallenges,
  screenSize = breakpoints.lg,
}: ChallengeAnalysisBarChartProps) => {
  const [logarithmicScale, setLogarithmicScale] = useState(false);

  const averageMarkerPosition =
    typeof analysis?.examScopeScores.average !== "undefined"
      ? Math.floor(analysis.examScopeScores.average / 10)
      : undefined;

  const { statistic, challengeGlobalInsight } = useExamChallengeStatistic({
    examId,
    challengeId,
    examChallengeId,
    challengeVersionId,
    canGetChallenges,
  });

  const { barChartItems, colors, groups } = useMemo(
    () =>
      getChallengeAnalysisBarChartData({
        challengeStatistic: statistic,
        challengeGlobalInsight,
      }),
    [statistic, challengeGlobalInsight],
  );

  const { options } = useMemo(() => {
    const isSmallScreen = [breakpoints.md, breakpoints.sm].includes(screenSize);

    return {
      options: {
        height: 300,
        xTick: {
          rotate: isSmallScreen ? 45 : 0,
          outer: false,
        },
        xLabelHeight: isSmallScreen ? 70 : 50,
        legendPositionTopRight: true,
        maxYTicks: 5,
        yAxisPadding: {
          top: 40,
        },
        logarithmicYTicks: logarithmicScale,
        bar: {
          width: {
            ratio: 0.8,
          },
          space: 0.1,
        },

        data: {
          order: null,
        },
        padding: {
          top: 15,
          right: isSmallScreen ? 10 : 0,
          bottom: isSmallScreen ? 40 : 10,
        },
      } satisfies Partial<StackedBarChartProps>["options"],
    };
  }, [logarithmicScale, screenSize]);

  return (
    <div>
      <StackedBarChart
        theme="analysis"
        xLabel={`${Message.getMessageByKey("score")} (%)`}
        yLabel={Message.getMessageByKey("challenge.numberOfSubmission")}
        items={barChartItems}
        xTicks={xTicks}
        colors={colors}
        groups={groups}
        options={options}
        chartMarkerProps={
          typeof averageMarkerPosition !== "undefined"
            ? {
                label: `${Message.getMessageByKey(
                  "report.average",
                )} (${Message.getMessageByKey(
                  "exam.dashboard.challengeAnalysis.thisExam",
                )})`,
                tickPosition: averageMarkerPosition,
              }
            : undefined
        }
      />
      <Checkbox
        className="code-challenge-stats__logarithmic_scale"
        value={logarithmicScale}
        onChange={() => setLogarithmicScale(!logarithmicScale)}
      >
        <Msg id="challenge.logarithmicScale" />
      </Checkbox>
    </div>
  );
};
