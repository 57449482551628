import { isEmpty } from "lodash";
import * as React from "react";
import { generate } from "shortid";

import {
  Action,
  APIResponseAction,
  tutorialExamGetAction,
  applicantInitialGetAction,
  applicantSharingGetAction,
  applicantAuthAction,
  applicantExamGetAction,
  challengeStartAction,
  challengeStartStep2Action,
  challengeSaveAction,
  challengeResetAction,
  challengeSubmitAction,
  challengeSwitchLangAction,
  challengeUpdateVarsAction,
  challengeContextAction,
  challengeContextRemoveAction,
  applicantUpdateFormAction,
  applicantExamOpenAction,
  applicantExamSubmitAction,
  applicantNotificationAddAction,
  applicantNotificationRemoveAction,
  editorHasUnsavedSetAction,
  editorReadyAction,
  getSourceFiles,
  presignedAction,
  updateAppealComment,
  applicantIdSharingGetAction,
  applicantIdStartExamAction,
  applicantIdPasswordAction,
  applicantIdSigninAction,
  resetAppealComment,
  applicantIncludeAppealCommentLogAction,
  applicantSwitchTabEventAction,
  applicantKeyEventSessionDuplicatedAction,
  applicantFileUploadAction,
  applicantExamFaceVerifiedAction,
} from "../actions";
import {
  ApplicantModel,
  ApplicantExamModel,
  ApplicantChallengeModel,
  ApplicantInitialModel,
  ApplicantSharingExamModel,
  ExamModel,
  OrganizationModel,
  CustomFormDefinitionModel,
  ApplicantChallengeResultModel,
  ApplicantChallengeContextModel,
  ApplicantEditorContextModel,
  IdDeliverySharingModel,
  ApplicantChallengeStartContextModel,
} from "../shared/models";
import { MessageSaved } from "../shared/services/events";

export interface ApplicantState {
  auth: {
    authenticated?: boolean;
    loading: boolean;
    submitting: boolean;
  };
  initial: ApplicantInitialModel;
  initialShared: ApplicantSharingExamModel;
  exam: ApplicantExamModel;
  challengeStartContext: ApplicantChallengeStartContextModel;
  challengeEditorContext: ApplicantEditorContextModel;
  challengeContext: ApplicantChallengeContextModel;
  notifications: { key: string; element: React.ReactNode }[];
  error: boolean;
  sessionDuplicated: boolean;
  keyEventSessionDuplicated: boolean;
  tutorial: boolean;
  challengeSourceFiles: Array<{ name: string; code: string }>;
  isUpdatingAppealComment: boolean;
  isUpdateAppealCommentSuccess: boolean;
  isUpdateAppealCommentError: boolean;
  isIncludedAppealCommentLog: boolean;
  initialId: IdDeliverySharingModel;

  idExamPasswordRequired: boolean;
  idExamPasswordInitialized: boolean;

  idExamUrlToken: string;
  tabEventUUID: string;
  isFileUploading: boolean;
}

const initialState = {
  auth: {
    loading: true,
    submitting: false,
  },
  initial: {} as ApplicantInitialModel,
  initialShared: new ApplicantSharingExamModel(),
  exam: new ApplicantExamModel({
    exam: {} as ExamModel,
    org: {} as OrganizationModel,
    applicant: {} as ApplicantModel,
    challenges: [] as ApplicantChallengeModel[],
    formDefinitions: [] as CustomFormDefinitionModel[],
    results: [] as ApplicantChallengeResultModel[],
  } as ApplicantExamModel),
  challengeStartContext: {
    challengeId: 0,
    isPrepared: false,
    hasLanguageSwitch: false,
  },
  challengeEditorContext: new ApplicantEditorContextModel(),
  challengeContext: {} as ApplicantChallengeContextModel,
  notifications: [],
  error: false,
  sessionDuplicated: false,
  keyEventSessionDuplicated: false,
  tutorial: false,
  isUpdatingAppealComment: false,
  isUpdateAppealCommentSuccess: false,
  isUpdateAppealCommentError: false,
  isIncludedAppealCommentLog: false,
  challengeSourceFiles: [],

  initialId: new IdDeliverySharingModel(),

  idExamPasswordRequired: false,
  idExamPasswordInitialized: false,
  idExamUrlToken: "",
  tabEventUUID: "",
  isFileUploading: false,
};

export const applicantReducer = (
  state: ApplicantState = initialState,
  action: Action,
): ApplicantState => {
  const payload = (action as APIResponseAction).payload || {};

  switch (action.type) {
    case applicantInitialGetAction.types.failure:
    case applicantSharingGetAction.types.failure:
    case applicantIdSharingGetAction.types.failure:
      return { ...state, error: true };

    case applicantAuthAction.types.success:
      return {
        ...state,
        auth: { authenticated: true, loading: false, submitting: false },
      };

    case applicantAuthAction.types.failure:
      return {
        ...state,
        auth: { authenticated: false, loading: false, submitting: false },
      };

    case tutorialExamGetAction.types.request:
      return { ...state, tutorial: true };

    case tutorialExamGetAction.types.success:
    case applicantInitialGetAction.types.success:
      return {
        ...state,
        initial: new ApplicantInitialModel(
          payload.result as ApplicantInitialModel,
        ),
      };

    case applicantInitialGetAction.types.request:
    case applicantSharingGetAction.types.request:
    case applicantIdSharingGetAction.types.request:
      return { ...state, error: false };

    case applicantSharingGetAction.types.success:
      return {
        ...state,
        initialShared: new ApplicantSharingExamModel(payload.result),
      };

    case applicantIdSharingGetAction.types.success:
      return {
        ...state,
        initialId: new IdDeliverySharingModel(payload.result),
      };

    case applicantIdStartExamAction.types.request:
      return {
        ...state,
        idExamPasswordRequired: false,
        idExamPasswordInitialized: false,
        idExamUrlToken: "",
      };

    case applicantIdSigninAction.types.success:
    case applicantIdPasswordAction.types.success:
    case applicantIdStartExamAction.types.success: {
      const { urlToken, passwordInitialized } = payload.result as {
        urlToken: string;
        passwordInitialized: boolean;
      };
      if (urlToken && urlToken.length) {
        return { ...state, idExamUrlToken: urlToken };
      }
      if (typeof passwordInitialized !== "undefined") {
        return {
          ...state,
          idExamPasswordRequired: true,
          idExamPasswordInitialized: passwordInitialized,
        };
      }
      return state;
    }

    case applicantExamGetAction.types.success:
      return {
        ...state,
        exam: new ApplicantExamModel(payload.result as ApplicantExamModel),
      };
    case applicantExamFaceVerifiedAction.types.success: {
      return {
        ...state,
        exam: new ApplicantExamModel({
          ...state.exam,
          webcamStatus: {
            ...state.exam.webcamStatus,
            hasVerifiedLiveness: true,
            canStartExam: true,
          },
        }),
      };
    }

    case applicantExamOpenAction.types.success: {
      return {
        ...state,
        exam: new ApplicantExamModel({
          ...state.exam,
          ...payload.result,
        }),
      };
    }

    case applicantExamSubmitAction.types.success: {
      return {
        ...state,
        exam: new ApplicantExamModel({
          ...state.exam,
          ...payload.result,
        }),
      };
    }

    case applicantUpdateFormAction.types.success:
      return {
        ...state,
        exam: new ApplicantExamModel({
          ...state.exam,
          ...payload.result,
        }),
      };

    case challengeStartAction.types.request:
      return {
        ...state,
        challengeStartContext:
          action.payload as ApplicantChallengeStartContextModel,
      };

    case challengeStartStep2Action.types.request:
      if (isEmpty(payload)) {
        return state;
      } else {
        return {
          ...state,
          exam: new ApplicantExamModel({
            ...state.exam,
            results: state.exam.mergeChallengeResult(
              new ApplicantChallengeResultModel(
                payload.result as ApplicantChallengeResultModel,
              ),
            ),
          }),
          challengeStartContext: Object.assign(
            {},
            state.challengeStartContext,
            {
              resultId: (payload.result as ApplicantChallengeResultModel).id,
            },
          ),
        };
      }

    case challengeStartAction.types.success:
      return {
        ...state,
        exam: new ApplicantExamModel({
          ...state.exam,
          results: state.exam.mergeChallengeResult(
            new ApplicantChallengeResultModel(
              payload.result as ApplicantChallengeResultModel,
            ),
          ),
        }),
        // TODO: make this more typesafe
        challengeStartContext: {} as any, // clear temporary context
      };

    case challengeSubmitAction.types.request:
      return {
        ...state,
        challengeEditorContext: new ApplicantEditorContextModel(
          Object.assign({}, state.challengeEditorContext, {
            hasUnsaved: false,
            challengeSubmitting: true,
            challengeSubmitted: false,
            lastSavedAt: "",
            lastTestRanAt: "",
          }),
        ),
      };

    case challengeSubmitAction.types.success:
      const newChallengeResult = new ApplicantChallengeResultModel(
        payload.result as ApplicantChallengeResultModel,
      );
      return {
        ...state,
        exam: new ApplicantExamModel({
          ...state.exam,
          results: state.exam.mergeChallengeResult(newChallengeResult),
        }),
        challengeEditorContext: new ApplicantEditorContextModel(
          Object.assign({}, state.challengeEditorContext, {
            challengeSubmitting: false,
            challengeSubmitted: true,
            lastSavedAt: "",
            lastTestRanAt: "",
          }),
        ),
      };

    case challengeSaveAction.types.request: {
      const { isTestRun } = action.payload as MessageSaved;
      const lastSavedAt = new Date();
      const updatedResult = new ApplicantChallengeResultModel(
        Object.assign(
          {},
          state.exam.results.find(
            (res) => res.id === state.challengeContext.challengeResultId,
          ),
          { updatedAt: lastSavedAt, ...payload.result },
        ),
      );

      return {
        ...state,
        exam: new ApplicantExamModel(
          Object.assign({
            ...state.exam,
            results: state.exam.mergeChallengeResult(updatedResult),
          }),
        ),
        challengeEditorContext: new ApplicantEditorContextModel(
          Object.assign({}, state.challengeEditorContext, {
            lastSavedAt,
            lastTestRanAt: isTestRun
              ? lastSavedAt
              : state.challengeEditorContext.lastTestRanAt,
            editing: false,
          }),
        ),
      };
    }

    case challengeResetAction.types.success:
    case challengeSwitchLangAction.types.success:
    case challengeUpdateVarsAction.types.success:
      return {
        ...state,
        exam: new ApplicantExamModel({
          ...state.exam,
          results: state.exam.mergeChallengeResult(
            new ApplicantChallengeResultModel(
              payload.result as ApplicantChallengeResultModel,
            ),
          ),
        }),
      };

    case applicantNotificationAddAction.types.request:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          { key: generate(), element: action.payload },
        ],
      };

    case applicantNotificationRemoveAction.types.request:
      return {
        ...state,
        notifications: state.notifications.filter(
          (item) => item.key !== action.payload,
        ),
      };

    case challengeStartAction.types.failure:
    case challengeSubmitAction.types.failure:
    case challengeResetAction.types.failure:
    case challengeSwitchLangAction.types.failure:
    case applicantUpdateFormAction.types.failure:
    case applicantExamOpenAction.types.failure:
    case applicantExamSubmitAction.types.failure: {
      // NOTE: sad... such a force casting.
      return {
        ...state,
        sessionDuplicated: (payload as {} as string).includes("409"),
      };
    }

    case challengeContextAction.types.request:
      return {
        ...state,
        challengeContext: new ApplicantChallengeContextModel({}),
        challengeEditorContext: new ApplicantEditorContextModel(
          Object.assign({}, state.challengeEditorContext, {
            contextReady: false,
            hasUnsaved: false,
            challengeSubmitting: false,
            challengeSubmitted: false,
            lastSavedAt: "",
            lastTestRanAt: "",
          }),
        ),
        challengeSourceFiles: [],
      };

    case challengeContextAction.types.success:
      return {
        ...state,
        challengeContext: new ApplicantChallengeContextModel(
          payload.result as ApplicantChallengeContextModel,
        ),
        challengeEditorContext: new ApplicantEditorContextModel(
          Object.assign({}, state.challengeEditorContext, {
            contextReady: true,
            hasUnsaved: false,
            challengeSubmitting: false,
            challengeSubmitted: false,
            lastSavedAt: "",
            lastTestRanAt: "",
          }),
        ),
      };

    case challengeContextRemoveAction.types.request:
      return {
        ...state,
        challengeContext: new ApplicantChallengeContextModel({}),
        challengeEditorContext: new ApplicantEditorContextModel(
          Object.assign({}, state.challengeEditorContext, {
            contextReady: false,
            editorReady: false,
            hasUnsaved: false,
            challengeSubmitting: false,
            challengeSubmitted: false,
          }),
        ),
      };

    case editorHasUnsavedSetAction.types.request:
      return {
        ...state,
        challengeEditorContext: new ApplicantEditorContextModel(
          Object.assign({}, state.challengeEditorContext, {
            ...action.payload,
          }),
        ),
      };

    case editorReadyAction.types.request:
      return {
        ...state,
        challengeEditorContext: new ApplicantEditorContextModel(
          Object.assign({}, state.challengeEditorContext, {
            editorReady: true,
          }),
        ),
      };

    case presignedAction.types.request:
      return { ...state, challengeSourceFiles: [] };

    case getSourceFiles.types.success: {
      const newFile = action.payload as { name: string; code: string };
      const index = state.challengeSourceFiles.findIndex(
        (file) => file.name === newFile.name,
      );

      const sourceFiles = [...state.challengeSourceFiles];

      if (index > -1) {
        sourceFiles[index] = newFile;
      } else {
        sourceFiles.push(newFile);
      }

      return { ...state, challengeSourceFiles: sourceFiles };
    }

    case updateAppealComment.types.request: {
      return {
        ...state,
        isUpdatingAppealComment: true,
        isUpdateAppealCommentSuccess: false,
        isUpdateAppealCommentError: false,
      };
    }
    case updateAppealComment.types.failure: {
      return {
        ...state,
        isUpdatingAppealComment: false,
        isUpdateAppealCommentSuccess: false,
        isUpdateAppealCommentError: true,
      };
    }
    case updateAppealComment.types.success: {
      const newResult = new ApplicantChallengeResultModel(payload.result);
      const results = [
        ...state.exam.results.filter((res) => res.id !== newResult.id),
        newResult,
      ];
      const exam = new ApplicantExamModel({ ...state.exam, results });

      return {
        ...state,
        exam,
        isUpdatingAppealComment: false,
        isUpdateAppealCommentSuccess: true,
        isUpdateAppealCommentError: false,
        isIncludedAppealCommentLog: false,
      };
    }

    case resetAppealComment.types.request: {
      return {
        ...state,
        isUpdatingAppealComment: false,
        isUpdateAppealCommentSuccess: false,
        isUpdateAppealCommentError: false,
      };
    }

    case applicantIncludeAppealCommentLogAction.types.request: {
      const { isIncludedAppealCommentLog = true } = action.payload as {
        isIncludedAppealCommentLog: boolean;
      };
      return {
        ...state,
        isIncludedAppealCommentLog,
      };
    }
    case applicantSwitchTabEventAction.types.request: {
      if (action.payload) {
        return { ...state };
      }

      return {
        ...state,
        tabEventUUID: generate(),
      };
    }

    case applicantKeyEventSessionDuplicatedAction.types.request: {
      return {
        ...state,
        keyEventSessionDuplicated: true,
      };
    }

    case applicantFileUploadAction.types.request: {
      return {
        ...state,
        isFileUploading: true,
      };
    }

    case applicantFileUploadAction.types.success:
    case applicantFileUploadAction.types.failure: {
      return {
        ...state,
        isFileUploading: false,
      };
    }

    default:
      return state;
  }
};
