import { filterValidProgrammingLanguages } from "@shared/services/challengeCollection";

import { QuestionModel, CurrentVersionModel } from "../models";
import { ChallengeStatus, ProgrammingLanguage } from "../services/enums";

class LinkedChallenge {
  public id: number;
  public organizationId: number;
  public style: number;
  public isOfficial: boolean;
  public gitUrl: string;
  public gitBranch: string;
  public gitDirectory: string;
  public originalChallengeId: number;
  public title: string;
  public description: string;
  public storageId: string;
  public status: ChallengeStatus;
  public howToSolve?: string;
  public language: string;
  public rankBy: number;
  public createdBy: string;
  public updatedBy: number;
  public createdAt: string;
  public updatedAt: string;
  public tags: Array<string> = [];
  public programmingLanguages: ProgrammingLanguage[] = [];
  public programmingCategories: Array<number> = [];
  public questions: QuestionModel[] = [];
  public currentVersion: CurrentVersionModel;

  constructor(args?: {}) {
    Object.assign(this, args);

    if (Array.isArray(this.questions)) {
      this.questions = this.questions.map((_) => new QuestionModel(_));
    }

    if (this.programmingLanguages) {
      this.programmingLanguages = filterValidProgrammingLanguages(
        this.programmingLanguages,
      );
    }

    const tags =
      (args && (args as { tags: Array<{ name: string }> }).tags) || [];
    this.tags = tags.map((tag) => tag.name);
  }

  public getProgrammingLanguageLabels() {
    return this.programmingLanguages
      .map((_) => ProgrammingLanguage.toString(_))
      .join(" / ");
  }
}

export default LinkedChallenge;
