import {
  Action,
  apiServerHealthAction,
  orcaServerHealthAction,
  orcaServerWsHealthAction,
  contentsServerHealthAction,
  imageServerHealthAction,
} from "../actions";
import HealthCheckModel from "../shared/models/HealthCheck.model";
import { getErrorMessage } from "../shared/services/healthCheck";

export interface HealthCheckState {
  health: HealthCheckModel;
}

const initialState = {
  health: new HealthCheckModel(),
};

export const healthCheckReducer = (
  state: HealthCheckState = initialState,
  action: Action,
): HealthCheckState => {
  switch (action.type) {
    case apiServerHealthAction.types.success:
      return {
        ...state,
        health: new HealthCheckModel(
          Object.assign({}, state.health, {
            apiServerConnection: true,
            apiServerConnectionError: "",
          }),
        ),
      };

    case apiServerHealthAction.types.failure:
      return {
        ...state,
        health: new HealthCheckModel(
          Object.assign({}, state.health, {
            apiServerConnection: false,
            apiServerConnectionError: getErrorMessage(action.payload),
          }),
        ),
      };

    case orcaServerHealthAction.types.success:
      return {
        ...state,
        health: new HealthCheckModel(
          Object.assign({}, state.health, {
            orcaServerConnection: true,
            orcaServerConnectionError: "",
          }),
        ),
      };

    case orcaServerHealthAction.types.failure:
      return {
        ...state,
        health: new HealthCheckModel(
          Object.assign({}, state.health, {
            orcaServerConnection: false,
            orcaServerConnectionError: getErrorMessage(action.payload),
          }),
        ),
      };

    case orcaServerWsHealthAction.types.success:
      return {
        ...state,
        health: new HealthCheckModel(
          Object.assign({}, state.health, {
            orcaServerWsConnection: true,
            orcaServerWsConnectionError: "",
          }),
        ),
      };

    case orcaServerWsHealthAction.types.failure:
      return {
        ...state,
        health: new HealthCheckModel(
          Object.assign({}, state.health, {
            orcaServerWsConnection: false,
            orcaServerWsConnectionError: getErrorMessage(action.payload),
          }),
        ),
      };

    case contentsServerHealthAction.types.success:
      return {
        ...state,
        health: new HealthCheckModel(
          Object.assign({}, state.health, {
            contentsServerConnection: true,
            contentsServerConnectionError: "",
          }),
        ),
      };

    case contentsServerHealthAction.types.failure:
      return {
        ...state,
        health: new HealthCheckModel(
          Object.assign({}, state.health, {
            contentsServerConnection: false,
            contentsServerConnectionError: getErrorMessage(action.payload),
          }),
        ),
      };

    case imageServerHealthAction.types.success:
      return {
        ...state,
        ...{
          health: new HealthCheckModel({
            ...state.health,
            imageServerConnection: true,
            imageServerConnectionError: "",
          }),
        },
      };

    case imageServerHealthAction.types.failure:
      return {
        ...state,
        ...{
          health: new HealthCheckModel({
            ...state.health,
            imageServerConnection: false,
            imageServerConnectionError: getErrorMessage(action.payload),
          }),
        },
      };

    default:
      return state;
  }
};
