import {
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from "react-router-dom";

import Layout from "@components/orgs/layout/Layout.connect";

import { RestrictedRoute } from "@shared/components";
import { useExamPermissions } from "@shared/hooks";
import { useExam } from "@shared/hooks/query";
import { UserModel } from "@shared/models";
import {
  OrganizationAppType,
  ProjectRole,
  UserRole,
} from "@shared/services/enums";

import GuestContainer from "../../containers/guest/GuestContainer";

/**
 * Containers
 */
import OrgsContainer from "../../containers/orgs/OrgsContainer.connect";
import AccessDenied from "../common/error/accessDenied/AccessDenied";
import Error from "../common/error/error/Error";
import NotFound from "../common/error/notFound/NotFound";
import { DeliverEmail, DeliverId } from "./exams/examDeliver/partials";
import ExamDeliverLayout from "./exams/examDeliver/partials/layout/ExamDeliverLayout";

interface AppRoutesBaseProps {
  currentProjectId: number;
  appType: OrganizationAppType;
  currentUser: UserModel;
  authenticated: boolean;
  hasTiers: boolean;
}

type AppRoutesProps = AppRoutesBaseProps &
  RouteComponentProps<{ examId: string; projectId: string }>;

/**
 * Route component
 */
function AppRoutes({
  currentProjectId,
  currentUser,
  appType,
  hasTiers,
}: AppRoutesProps) {
  const rootPath =
    currentUser instanceof UserModel
      ? currentUser.getRootPath()
      : new UserModel(currentUser).getRootPath();
  const {
    data: { examDetail },
  } = useExam();

  const isLoading = examDetail.isEmpty() || !hasTiers;
  const { hasExamDetailOrChallengeSetIssues } = useExamPermissions();

  return (
    <Switch>
      <Redirect from="/" exact={true} to={rootPath} />
      <Route path="/auth" component={GuestContainer} />
      <Route path="/user" component={OrgsContainer} />
      <Route path="/signout" exact={true} component={OrgsContainer} />

      {appType === OrganizationAppType.Admin && (
        <Route
          path="/p/:projectId/exams/:examId/challenges/print"
          render={({ location }) => (
            <OrgsContainer
              location={location}
              options={{
                hideSideMenu: true,
                hideBreadcrumbs: true,
                hideHeader: true,
              }}
            />
          )}
        />
      )}

      {appType === OrganizationAppType.Admin && (
        <Route
          path="/p/:projectId/exams/:examId/submissions/:submissionId/playback/:challengeId"
          render={({ location }) => (
            <OrgsContainer
              location={location}
              options={{
                hideSideMenu: true,
                hideBreadcrumbs: true,
                hideHeader: true,
              }}
            />
          )}
        />
      )}

      {appType === OrganizationAppType.Admin && (
        <Route
          path="/p/:projectId/exams/:examId/submissions/:submissionId/diff/:challengeId"
          render={({ location }) => (
            <OrgsContainer
              location={location}
              options={{
                hideSideMenu: true,
                hideBreadcrumbs: true,
                hideHeader: true,
              }}
            />
          )}
        />
      )}
      {appType === OrganizationAppType.Admin && (
        <Route
          path="/p/:projectId/exams/:examId/submissions/:submissionId/preview/:challengeId"
          render={({ location }) => (
            <OrgsContainer
              location={location}
              options={{
                hideSideMenu: true,
                hideBreadcrumbs: true,
                hideHeader: true,
              }}
            />
          )}
        />
      )}
      {/* react router v6
      <Route path="/p" element={OrgsContainer}>
        <Route path=":projectId/exams/:examId/deliveries/new" element={ExamDeliverLayout}>
          <Route path="email" element={<DeliverEmail />} />
          <Route path="id" element={<DeliverId />} />
        </Route>
      </Route>
       */}
      {appType === OrganizationAppType.Admin && (
        <Route
          path="/(p|settings)"
          render={() => (
            <>
              <Route path="/p" component={OrgsContainer} />
              <Route path="/settings" component={OrgsContainer} />
              <Route
                path="/p/:projectId/exams/:examId/deliveries"
                render={() => (
                  <>
                    {!isLoading && hasExamDetailOrChallengeSetIssues && (
                      <Redirect to={`/p/${currentProjectId}/exams`} />
                    )}
                    <Layout>
                      <ExamDeliverLayout>
                        <RestrictedRoute
                          path="/p/:projectId/exams/:examId/deliveries/new/email"
                          roles={[
                            UserRole.SystemAdmin,
                            ProjectRole.ProjectAdmin,
                            ProjectRole.ExamDeliverer,
                          ]}
                          exact
                        >
                          <DeliverEmail />
                        </RestrictedRoute>
                        <RestrictedRoute
                          path={`/p/:projectId/exams/:examId/deliveries/new/id`}
                          roles={[
                            UserRole.SystemAdmin,
                            ProjectRole.ProjectAdmin,
                            ProjectRole.ExamDeliverer,
                          ]}
                          exact
                        >
                          <DeliverId />
                        </RestrictedRoute>
                      </ExamDeliverLayout>
                    </Layout>
                  </>
                )}
              />
            </>
          )}
        />
      )}

      <Route
        path="/courses"
        render={({ location }) => (
          <OrgsContainer
            location={location}
            options={{ hideSideMenu: true, hideBreadcrumbs: true }}
          />
        )}
      />

      <Route path="/error" component={Error} />
      <Route path="/accessDenied" component={AccessDenied} />
      <Route component={NotFound} />
    </Switch>
  );
}

export default withRouter(AppRoutes);
