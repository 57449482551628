import * as classnames from "classnames";
import { Link } from "react-router-dom";

import { ExamListModel, UserModel } from "@shared/models";
import { UserRole, ProjectRole, ExamType } from "@shared/services/enums";
import Message from "@shared/services/message";
import { isGiveryOrg } from "@shared/services/organization";

import { Restricted, Tag, HighlightedText, UpdateAvailableTag } from "..";

/**
 * Prop interface
 */
export interface ExternalProps {
  exam: ExamListModel;
  projectId: number;
  keyword?: string;
  className?: string;
}
export interface InjectedProps {
  currentUser?: UserModel;
}

export type ExamTitleColumnProps = ExternalProps & InjectedProps;

/**
 * React Component
 */
export default function ExamTitleColumn({
  exam,
  keyword,
  projectId,
  className,
  currentUser,
}: ExamTitleColumnProps) {
  const rootStyle = classnames("code-c-exam-title-column", {
    [`${className}`]: Boolean(className),
  });

  return (
    <div className={rootStyle}>
      <div className="code-c-exam-title-column__tags">
        {exam.archivedAt && (
          <Tag className="code-c-exam-title-column__archived">
            {Message.getMessageByKey("common.inactive")}
          </Tag>
        )}
        {!exam.canDeliver && (
          <Tag className="code-c-exam-title-column__disabled">
            {Message.getMessageByKey("tier.disabled.delivery")}
          </Tag>
        )}
        {exam.examType === ExamType.EnglishJapanese && (
          <Tag className="code-c-exam-title-column__multilang">
            {Message.getMessageByKey("exam.multilanguage")}
          </Tag>
        )}
        <Restricted
          roles={[
            UserRole.SystemAdmin,
            ProjectRole.ProjectAdmin,
            ProjectRole.ExamCreator,
            ProjectRole.ExamDeliverer,
          ]}
          wrapperTagName={false}
          strictAllow={!exam.archivedAt && exam.hasOutdatedChallengeVersion}
        >
          <UpdateAvailableTag className="code-c-exam-title-column__new-version" />
        </Restricted>
        {isGiveryOrg(currentUser?.organizationId) && exam.isOfficial && (
          <Tag className="code-c-exam-title-column__official">
            {Message.getMessageByKey("exam.officialExam")}
          </Tag>
        )}
      </div>
      <div>
        <Link
          to={`/p/${projectId}/exams/${exam.id}`}
          className="code-c-exam-title-column__link is-bold"
        >
          <HighlightedText keyword={keyword}>{exam.name}</HighlightedText>
        </Link>
      </div>
    </div>
  );
}
