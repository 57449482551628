import * as classnames from "classnames";

import Message from "@shared/services/message";

import { Tag } from "../..";

/**
 * Prop interface
 */
export interface UpdateAvailableTagProps {
  className?: string;
}

/**
 * React Component
 */
export function UpdateAvailableTag({ className }: UpdateAvailableTagProps) {
  const rootStyle = classnames("code-c-update-available-tag", {
    [`${className}`]: Boolean(className),
  });

  return (
    <Tag className={rootStyle}>
      {Message.getMessageByKey("challengeVersion.updateAvailable")}
    </Tag>
  );
}
