import { Epic } from "redux-observable";

import {
  Action,
  ProjectAction,
  ajaxAction,
  alertAction,
  redirectAction,
  projectListGetAction,
  projectListAddAction,
  projectMemberListGetAction,
  projectMemberListAddAction,
  projectRemoveSwitcherAction,
  projectAddSwitcherAction,
  projectUpdateSwitcherAction,
  projectErrorAction,
  projectMemberUpdateAction,
  projectMemberInviteAction,
  projectGetAction,
  projectUpdateAction,
  projectCreateAction,
  projectDeleteAction,
  projectMemberCreateAction,
  projectMemberDeleteAction,
  projectInviteListGetAction,
  projectInviteResendAction,
  projectInviteDeleteAction,
  projectInviteUpdateAction,
  projectSlackGetAction,
  projectSlackRegistrationGetAction,
  projectSocialGetAction,
  projectSocialDeleteAction,
  projectSocialChannelsGetAction,
  projectSocialChannelAddAction,
  projectSocialChannelUpdateAction,
  projectSocialChannelDeleteAction,
  projectSocialUserGetAction,
  projectSocialUserUpdateAction,
  externalRedirectAction,
  projectSocialChannelMoveStep1Action,
  projectSocialChannelMoveStep2Action,
  projectAuditLogGetAction,
} from "../actions";
import { RootState } from "../reducers";
import { SlackIntegrationModel } from "../shared/models";
import Message from "../shared/services/message";

const projectGetEpic: Epic<Action, RootState> = (action$, state) =>
  action$
    .ofType(projectGetAction.types.request)
    .map(({ payload }: ProjectAction) =>
      ajaxAction.request({
        method: "get",
        url: `/api/projects/${payload.projectId}`,
        success: projectGetAction.success,
        error: projectErrorAction.request,
      }),
    );

const projectSetEpic: Epic<Action, RootState> = (action$, state) =>
  action$
    .ofType(projectGetAction.types.success)
    .map((action) => projectUpdateSwitcherAction.request(action.payload as {}));

const projectUpdateEpic: Epic<Action, RootState> = (action$, state) =>
  action$
    .ofType(projectUpdateAction.types.request)
    .map(({ payload, params }: ProjectAction) =>
      ajaxAction.request({
        method: "put",
        url: `/api/projects/${payload.projectId}`,
        body: params,
        success: alertAction.success(
          "Project Updated",
          projectGetAction.success,
        ),
      }),
    );

const projectDeleteEpic: Epic<Action, RootState> = (action$, state) =>
  action$
    .ofType(projectDeleteAction.types.request)
    .map(({ payload }: ProjectAction) =>
      ajaxAction.request({
        method: "delete",
        url: `/api/projects/${payload.projectId}`,
        success: redirectAction(
          "/org/projects/",
          alertAction.success("Project Deleted", () =>
            projectRemoveSwitcherAction.request(payload.projectId),
          ),
        ),
      }),
    );

const projectCreateEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(projectCreateAction.types.request).map((action) =>
    ajaxAction.request({
      method: "post",
      url: "/api/projects",
      body: action.payload,
      success: alertAction.success(
        "Project Created",
        projectListAddAction.request,
      ),
    }),
  );

const projectListGetEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(projectListGetAction.types.request).map((action) =>
    ajaxAction.request({
      method: "get",
      url: "/api/projects",
      body: action.payload,
      success: projectListGetAction.success,
    }),
  );

const projectListAddEpic: Epic<Action, RootState> = (action$, state) =>
  action$
    .ofType(projectListAddAction.types.request)
    .map((action) => projectAddSwitcherAction.request(action.payload as {}));

const projectMemberListGetEpic: Epic<Action, RootState> = (action$, state) =>
  action$
    .ofType(projectMemberListGetAction.types.request)
    .debounceTime(275)
    .map(({ payload, params }: ProjectAction) =>
      ajaxAction.request({
        method: "get",
        url: `/api/projects/${
          payload.projectId || state.getState().project.currentProjectId
        }/members`,
        body: params,
        success: projectMemberListGetAction.success,
      }),
    );

const projectMemberCreateEpic: Epic<Action, RootState> = (action$, state) =>
  action$
    .ofType(projectMemberCreateAction.types.request)
    .map(({ payload, params }: ProjectAction) =>
      ajaxAction.request({
        method: "post",
        url: `/api/projects/${payload.projectId}/members`,
        body: params,
        success: alertAction.success(
          "Member Added",
          projectMemberListAddAction.request,
        ),
      }),
    );

const projectMemberDeleteEpic: Epic<Action, RootState> = (action$, state) =>
  action$
    .ofType(projectMemberDeleteAction.types.request)
    .map(({ payload }: ProjectAction) => {
      return ajaxAction.request({
        method: "delete",
        url: `/api/projects/${payload.projectId}/members/${payload.userId}`,
        success: alertAction.success(
          Message.getMessageByKey("message.member.deactivated"),
          // Refresh the list of members to get the correct pagination.
          () =>
            projectMemberListGetAction.request(
              { skipReset: true }, // Skip reseting the members list
              {
                ...state.getState().project.projectMemberPagination,
                "sortOrder.column": payload.params?.sortOrder?.column,
                "sortOrder.direction": payload.params?.sortOrder?.direction,
                keyword: payload.params?.keyword,
                roles: payload.params?.roles,
              },
            ),
        ),
      });
    });

const projectMemberUpdateEpic: Epic<Action, RootState> = (action$, state) =>
  action$
    .ofType(projectMemberUpdateAction.types.request)
    .map(({ payload, params }: ProjectAction) =>
      ajaxAction.request({
        method: "put",
        url: `/api/projects/${
          state.getState().project.currentProjectId
        }/members/${payload}`,
        body: params,
        success: alertAction.success(
          "Member Updated",
          projectMemberUpdateAction.success,
        ),
        error: projectMemberUpdateAction.failure,
      }),
    );

const projectMemberInviteEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(projectMemberInviteAction.types.request).map((action) =>
    ajaxAction.request({
      method: "post",
      url: `/api/projects/${action.payload}/members/invite`,
      body: action.params,
      success: alertAction.success(
        Message.getMessageByKey("message.invitation.sent"),
        projectMemberInviteAction.success,
      ),
      error: projectMemberInviteAction.failure,
    }),
  );

export const projectInviteListGetEpic: Epic<Action, RootState> = (
  action$,
  state$,
) =>
  action$
    .ofType(projectInviteListGetAction.types.request)
    .debounceTime(500)
    .map((action) =>
      ajaxAction.request({
        method: "get",
        url: `/api/projects/${
          state$.getState().project.currentProjectId
        }/members/invite`,
        body: action.payload,
        success: projectInviteListGetAction.success,
        error: projectInviteListGetAction.failure,
      }),
    );

export const projectInviteResendEpic: Epic<Action, RootState> = (
  action$,
  state$,
) =>
  action$.ofType(projectInviteResendAction.types.request).map((action) =>
    ajaxAction.request({
      method: "post",
      url: `/api/projects/${
        state$.getState().project.currentProjectId
      }/members/invite/${action.payload}/resend`,
      success: alertAction.success(
        Message.getMessageByKey("message.invitation.resent"),
        projectInviteResendAction.success,
      ),
      error: projectInviteResendAction.failure,
    }),
  );

export const projectInviteUpdateEpic: Epic<Action, RootState> = (
  action$,
  state$,
) =>
  action$
    .ofType(projectInviteUpdateAction.types.request)
    .map(({ payload, params }) =>
      ajaxAction.request({
        method: "put",
        body: params,
        url: `/api/projects/${
          state$.getState().project.currentProjectId
        }/members/invite/${payload}`,
        success: alertAction.success(
          Message.getMessageByKey("message.invitation.updated"),
          projectInviteUpdateAction.success,
        ),
        error: projectInviteUpdateAction.failure,
      }),
    );

export const projectInviteDeleteEpic: Epic<Action, RootState> = (
  action$,
  state$,
) =>
  action$.ofType(projectInviteDeleteAction.types.request).map((action) =>
    ajaxAction.request({
      method: "delete",
      url: `/api/projects/${
        state$.getState().project.currentProjectId
      }/members/invite/${action.payload}`,
      success: alertAction.success(
        Message.getMessageByKey("message.invitation.canceled"),
        projectInviteDeleteAction.success,
      ),
      error: projectInviteDeleteAction.failure,
    }),
  );

export const postProjectInviteChangedEpic: Epic<Action, RootState> = (
  action$,
  state$,
) =>
  action$
    .ofType(projectInviteDeleteAction.types.success)
    .map(({ payload }: ProjectAction) =>
      projectInviteListGetAction.request(
        payload?.projectId ||
          state$.getState().project.pendingInviteListCondition,
      ),
    );

export const projectSlackGetEpic: Epic<Action, RootState> = (action$, state) =>
  action$
    .ofType(projectSlackGetAction.types.request)
    .map(({ payload }: ProjectAction) =>
      ajaxAction.request({
        method: "get",
        url: `/api/projects/${
          payload.projectId || state.getState().project.currentProjectId
        }/social/slack`,
        success: projectSlackGetAction.success,
        error: projectSlackGetAction.failure,
        options: { disableDefaultError: true },
      }),
    );

export const projectSlackGetRegistrationEpic: Epic<Action, RootState> = (
  action$,
  state,
) =>
  action$
    .ofType(projectSlackRegistrationGetAction.types.request)
    .map(({ payload }: ProjectAction) =>
      ajaxAction.request({
        method: "get",
        url: `/api/projects/${
          payload.projectId || state.getState().project.currentProjectId
        }/social/slack/registration`,
        success: (payload: { code: number; result: string }) =>
          externalRedirectAction(payload.result),
        error: projectSlackRegistrationGetAction.failure,
      }),
    );

export const projectSocialGetEpic: Epic<Action, RootState> = (action$, state) =>
  action$
    .ofType(projectSocialGetAction.types.request)
    .map(({ payload }: ProjectAction) =>
      ajaxAction.request({
        method: "get",
        url: `/api/projects/${
          payload.projectId || state.getState().project.currentProjectId
        }/social/social/${payload.socialId}`,
        success: projectSocialGetAction.success,
        error: projectSocialGetAction.failure,
      }),
    );

export const projectSocialDeleteEpic: Epic<Action, RootState> = (
  action$,
  state,
) =>
  action$
    .ofType(projectSocialDeleteAction.types.request)
    .map(({ payload }: ProjectAction) =>
      ajaxAction.request({
        method: "delete",
        url: `/api/projects/${
          payload.projectId || state.getState().project.currentProjectId
        }/social/${payload.socialId}`,
        success: alertAction.success(
          Message.getMessageByKey("slack.removeSlackIntegrationSuccess"),
          projectSocialDeleteAction.success,
        ),
        error: alertAction.error(
          Message.getMessageByKey("slack.removeSlackIntegrationError"),
          projectSocialDeleteAction.failure,
        ),
      }),
    );

export const projectSocialChannelsGetEpic: Epic<Action, RootState> = (
  action$,
  state,
) =>
  action$
    .ofType(
      projectSocialChannelsGetAction.types.request,
      projectSlackGetAction.types.success,
      projectSocialChannelAddAction.types.success,
      projectSocialChannelDeleteAction.types.success,
      projectSocialChannelMoveStep2Action.types.success,
      projectSocialChannelMoveStep2Action.types.failure,
    )
    .map(() =>
      ajaxAction.request({
        method: "get",
        url: `/api/projects/${
          state.getState().project.currentProjectId
        }/social/${state.getState().project.projectSlackSocial?.id}/channel`,
        success: projectSocialChannelsGetAction.success,
        error: projectSocialChannelsGetAction.failure,
        options: {
          disableDefaultError: true,
        },
      }),
    );

export const projectSocialChannelAddEpic: Epic<Action, RootState> = (
  action$,
  state,
) =>
  action$
    .ofType(projectSocialChannelAddAction.types.request)
    .map(({ payload, params }: ProjectAction) =>
      ajaxAction.request({
        method: "post",
        url: `/api/projects/${
          payload.projectId || state.getState().project.currentProjectId
        }/social/${payload.socialId}/channel`,
        body: params,
        success: alertAction.success(
          Message.getMessageByKey("slack.addChannelSuccess"),
          projectSocialChannelAddAction.success,
        ),
        error: alertAction.error(
          Message.getMessageByKey("slack.addChannelError"),
          projectSocialChannelAddAction.failure,
        ),
      }),
    );

export const projectSocialChannelUpdateEpic: Epic<Action, RootState> = (
  action$,
  state,
) =>
  action$
    .ofType(projectSocialChannelUpdateAction.types.request)
    .map(({ payload, params }: ProjectAction) =>
      ajaxAction.request({
        method: "put",
        url: `/api/projects/${
          payload.projectId || state.getState().project.currentProjectId
        }/social/${payload.socialId}/channel/${payload.integrationId}`,
        body: params,
        success: alertAction.success(
          Message.getMessageByKey("slack.updateChannelSuccess"),
          projectSocialChannelUpdateAction.success,
        ),
        error: alertAction.error(
          Message.getMessageByKey("slack.updateChannelError"),
          projectSocialChannelUpdateAction.failure,
        ),
      }),
    );

export const projectSocialChannelMoveStep1Epic: Epic<Action, RootState> = (
  action$,
  state,
) =>
  action$
    .ofType(projectSocialChannelMoveStep1Action.types.request)
    .map(({ payload, params }: ProjectAction) =>
      ajaxAction.request({
        method: "delete",
        url: `/api/projects/${
          payload.projectId || state.getState().project.currentProjectId
        }/social/${payload.socialId}/channel/${payload.integrationId}`,
        success: () =>
          projectSocialChannelMoveStep1Action.success(payload, params),
        error: alertAction.error(
          Message.getMessageByKey("slack.updateChannelError"),
          projectSocialChannelMoveStep1Action.failure,
        ),
        options: {
          disableDefaultError: true,
        },
      }),
    );

export const projectSocialChannelMoveStep2Epic: Epic<Action, RootState> = (
  action$,
  state,
) =>
  action$
    .ofType(projectSocialChannelMoveStep1Action.types.success)
    .map(({ payload, params }: ProjectAction) =>
      ajaxAction.request({
        method: "post",
        url: `/api/projects/${
          payload.projectId || state.getState().project.currentProjectId
        }/social/${payload.socialId}/channel`,
        body: params,
        success: alertAction.success(
          Message.getMessageByKey("slack.updateChannelSuccess"),
          ({ result }: { code: number; result: SlackIntegrationModel }) =>
            projectSocialChannelMoveStep2Action.success({
              result,
              extra: payload,
            }),
        ),
        error: alertAction.error(
          Message.getMessageByKey("slack.updateChannelError"),
          () =>
            projectSocialChannelMoveStep2Action.failure({
              result: payload,
            }),
        ),
        options: {
          disableDefaultError: true,
        },
      }),
    );

export const projectSocialChannelDeleteEpic: Epic<Action, RootState> = (
  action$,
  state,
) =>
  action$
    .ofType(projectSocialChannelDeleteAction.types.request)
    .map(({ payload }: ProjectAction) =>
      ajaxAction.request({
        method: "delete",
        url: `/api/projects/${
          payload.projectId || state.getState().project.currentProjectId
        }/social/${payload.socialId}/channel/${payload.integrationId}`,
        success: alertAction.success(
          Message.getMessageByKey("slack.deleteChannelSuccess"),
          () => projectSocialChannelDeleteAction.success({ result: payload }),
        ),
        error: alertAction.error(
          Message.getMessageByKey("slack.deleteChannelError"),
          projectSocialChannelDeleteAction.failure,
        ),
      }),
    );

export const projectSocialUserGetEpic: Epic<Action, RootState> = (
  action$,
  state,
) =>
  action$.ofType(projectSocialUserGetAction.types.request).map(() =>
    ajaxAction.request({
      method: "get",
      url: `/api/projects/${
        state.getState().project.currentProjectId ||
        state.getState().user?.user?.projects[0]?.id
      }/social/user`,
      success: projectSocialUserGetAction.success,
      error: projectSocialUserGetAction.failure,
      options: { disableDefaultError: true },
    }),
  );

export const projectSocialUserUpdateEpic: Epic<Action, RootState> = (
  action$,
  state,
) =>
  action$
    .ofType(projectSocialUserUpdateAction.types.request)
    .map(({ payload, params }: ProjectAction) =>
      ajaxAction.request({
        method: "put",
        url: `/api/projects/${
          payload.projectId ||
          state.getState().project.currentProjectId ||
          state.getState().user?.user?.projects[0]?.id
        }/social/user`,
        body: params,
        success: projectSocialUserUpdateAction.success,
        error: projectSocialUserUpdateAction.failure,
      }),
    );

const projectAuditLogGetEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(projectAuditLogGetAction.types.request).map((action) =>
    ajaxAction.request({
      method: "get",
      url: `/api/projects/${
        state.getState().project.currentProjectId
      }/auditlogs`,
      body: action.payload,
      success: projectAuditLogGetAction.success,
      error: projectAuditLogGetAction.failure,
    }),
  );

export default [
  projectGetEpic,
  projectSetEpic,
  projectUpdateEpic,
  projectDeleteEpic,
  projectCreateEpic,
  projectListGetEpic,
  projectListAddEpic,
  projectMemberListGetEpic,
  projectMemberCreateEpic,
  projectMemberDeleteEpic,
  projectMemberUpdateEpic,
  projectMemberInviteEpic,
  projectInviteListGetEpic,
  projectInviteResendEpic,
  projectInviteDeleteEpic,
  projectInviteUpdateEpic,
  postProjectInviteChangedEpic,
  projectSlackGetEpic,
  projectSlackGetRegistrationEpic,
  projectSocialGetEpic,
  projectSocialDeleteEpic,
  projectSocialChannelsGetEpic,
  projectSocialChannelAddEpic,
  projectSocialChannelUpdateEpic,
  projectSocialChannelMoveStep1Epic,
  projectSocialChannelMoveStep2Epic,
  projectSocialChannelDeleteEpic,
  projectSocialUserGetEpic,
  projectSocialUserUpdateEpic,
  projectAuditLogGetEpic,
];
