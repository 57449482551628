import {
  UserRoleModel,
  ProjectRoleModel,
  ProjectSwitchItemModel,
  MemberListModel,
  ReviewerUserModel,
} from "@shared/models";
import {
  InvitationStatus,
  ProjectRole,
  UserRole,
} from "@shared/services/enums";

export function getProjectRoleObject<T>(
  roles: ProjectRole[],
  value: T,
): { [key in keyof typeof ProjectRole]: T } {
  return roles.reduce(
    (roles, role) => ({
      ...roles,
      [ProjectRole[role]]: value,
    }),
    {} as { [key in keyof typeof ProjectRole]: T },
  );
}

export function getProjectRoleArray(
  roles: UserRoleModel[],
): ProjectRoleModel[] {
  return roles
    .filter((role) => role.projectId)
    .reduce((projectRoles, role) => {
      const roleIndex = projectRoles.findIndex(
        (projectRole) => projectRole.id === role.projectId,
      );
      const newRoles = [...projectRoles];

      if (roleIndex !== -1) {
        if (!newRoles[roleIndex].roles.includes(ProjectRole.ProjectAdmin)) {
          newRoles[roleIndex].roles.push(role.role);
        }
        if (role.role === ProjectRole.ProjectAdmin) {
          newRoles[roleIndex].roles = [ProjectRole.ProjectAdmin];
        }
      } else {
        newRoles.push({ id: role.projectId ?? 0, roles: [role.role] });
      }
      return newRoles;
    }, [] as ProjectRoleModel[]);
}

const validRoles = {
  [UserRole.OrgAdmin]: true,
  [UserRole.SystemAdmin]: true,
  [ProjectRole.ProjectAdmin]: true,
  [ProjectRole.ExamCreator]: true,
  [ProjectRole.ExamDeliverer]: true,
  [ProjectRole.Reviewer]: true,
  [ProjectRole.ChallengeCreator]: true,
};

/**
 * old accounts still have deprecated roles attached to them.
 * best to filter them out before passing to backend to prevent errors
 */
export function filterValidProjectRoles(
  roles: (UserRoleModel | { role: number })[],
): (UserRoleModel | { role: number })[] {
  return roles.filter((role) => validRoles[role.role]);
}

export function getDisplayInvitationStatus({
  reviewer,
  invitationStatus,
}: {
  reviewer: MemberListModel | ReviewerUserModel;
  invitationStatus?: Record<InvitationStatus, string>;
}) {
  return {
    ...reviewer,
    displayStatus:
      invitationStatus && reviewer.invitationStatus
        ? invitationStatus[reviewer.invitationStatus] ?? ""
        : "",
  };
}

export function getProjectsWithTargetRoles({
  projects,
  userRoles,
  targetRoles,
}: {
  projects: ProjectSwitchItemModel[];
  userRoles: UserRoleModel[];
  targetRoles: ProjectRole[];
}) {
  return projects.filter(
    (project) =>
      userRoles
        ?.filter((userRole) => userRole.projectId === project.id)
        .some((userRole) => targetRoles.includes(userRole.role)),
  );
}
