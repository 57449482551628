import * as classnames from "classnames";

import { Question, Msg } from "@shared/components";
import { ChallengeModel } from "@shared/models";

import { ChallengeDetailBox } from "..";

/**
 * Prop interface
 */
export interface ChallengeQuestionsProps {
  challenge: ChallengeModel;
  showExamLevelStats: boolean;
}

/**
 * React Component
 */
export function ChallengeQuestions({
  challenge,
  showExamLevelStats,
}: ChallengeQuestionsProps) {
  const rootStyle = classnames("code-challenge-questions");
  const questions = challenge.questions.map((question, index) => (
    <div className="code-challenge-questions__row" key={question.id}>
      <div className="code-challenge-questions__no">Q{index + 1}</div>
      <Question
        className="code-challenge-questions__question"
        question={question}
        insightsDisplayOptions={{
          showGlobalStats: true,
          showExamLevelStats,
          showOverview: true,
        }}
      />
    </div>
  ));
  return (
    <div className={rootStyle}>
      <ChallengeDetailBox title={<Msg id="common.questions" />}>
        {questions}
      </ChallengeDetailBox>
    </div>
  );
}
