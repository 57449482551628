import * as React from "react";
import { createContext, FC, useContext, useEffect, useMemo } from "react";
import { matchPath, RouteComponentProps, withRouter } from "react-router-dom";

import { MultiProvider } from "@shared/components";

type routeProps = RouteComponentProps<
  {},
  {},
  { fromCopy?: boolean; officialExamId?: number }
>;
// These contexts could be remove after upgrade to react router v6
const ParamsContext = createContext<
  Readonly<Record<string, string | undefined>>
>({});
const LocationContext = createContext<routeProps["location"]>({
  hash: "",
  pathname: "",
  search: "",
  state: {},
});

export const useParams = () => useContext(ParamsContext);
export const useLocation = () => useContext(LocationContext);

const RouterProvider: FC<routeProps> = ({ children, location }) => {
  const [routeParams, setRouteParams] = React.useState<
    Record<string, string | undefined>
  >({});
  const matchSubmission = useMemo(
    () =>
      matchPath<{ examId: string; submissionId: string }>(location.pathname, {
        path: "/p/:projectId/exams/:examId/submissions/:submissionId",
      }),
    [location.pathname],
  );
  const matchExam = useMemo(
    () =>
      matchPath<{ examId: string }>(location.pathname, {
        path: "/p/:projectId/exams/:examId",
      }),
    [location.pathname],
  );
  const matchApplicantExam = useMemo(
    () =>
      matchPath<{ challengeId: string }>(location.pathname, {
        path: "/:orgname/exams/:token/challenges/:challengeId",
      }),
    [location.pathname],
  );

  const matchGuest = useMemo(
    () =>
      matchPath<{ examId: string }>(location.pathname, {
        path: "/share/submission/:token",
      }),
    [location.pathname],
  );

  useEffect(() => {
    setRouteParams({
      ...matchExam?.params,
      ...matchGuest?.params,
      ...matchSubmission?.params,
      ...matchApplicantExam?.params,
    });
  }, [
    matchExam?.params,
    matchSubmission?.params,
    matchGuest?.params,
    matchApplicantExam?.params,
  ]);

  return (
    <MultiProvider
      providers={[
        <ParamsContext.Provider
          key="ParamsContextProvider"
          value={routeParams}
        />,
        <LocationContext.Provider
          key="LocationContextProvider"
          value={location}
        />,
      ]}
    >
      {children}
    </MultiProvider>
  );
};

export default withRouter(RouterProvider);
