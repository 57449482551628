import * as classnames from "classnames";

import { Restricted, Msg, Button, Icon, Tooltip } from "@shared/components";
import {
  ProjectRole,
  SubmissionListKind,
  UserRole,
} from "@shared/services/enums";

/**
 * Interface
 */
interface SubmissionListHeaderButtonProps {
  isExamDeliverer: boolean;
  isPasswordRequired: boolean;
  canDownloadCSV: boolean;
  submissionListKind: SubmissionListKind;
  onClickDownloadCSV: () => void;
  onClickDownloadPassword: () => void;
  onClickManageMulti: () => void;
}

/**
 * Component
 * @param props SubmissionListHeaderButton
 */
export function SubmissionListHeaderButton({
  isExamDeliverer,
  isPasswordRequired,
  canDownloadCSV,
  submissionListKind,
  onClickDownloadCSV,
  onClickDownloadPassword,
  onClickManageMulti,
}: SubmissionListHeaderButtonProps) {
  const rootStyle = classnames("code-submission-list-header-button");
  return (
    <div className={rootStyle}>
      {!isExamDeliverer && (
        <Tooltip
          placement="top-end"
          text={<Msg id="tier.disabled" />}
          disabled={canDownloadCSV}
        >
          <Button
            onClick={onClickDownloadCSV}
            ariaLabel="Download CSV"
            disabled={!canDownloadCSV}
          >
            <Icon type="download" />
            <Msg id="button.csvDownload" />
          </Button>
        </Tooltip>
      )}
      <Restricted
        roles={[ProjectRole.ProjectAdmin, ProjectRole.ExamDeliverer]}
        strictAllow={isPasswordRequired}
      >
        <Button
          onClick={onClickDownloadPassword}
          ariaLabel="Download Initial Password List"
        >
          <Icon type="file" />
          <Msg id="button.initialPasswordListDownload" />
        </Button>
      </Restricted>
      <Restricted
        roles={[UserRole.SystemAdmin]}
        strictAllow={[
          SubmissionListKind.all,
          SubmissionListKind.submitted,
          SubmissionListKind.inreview,
          SubmissionListKind.reviewed,
          SubmissionListKind.inprogress,
        ].includes(submissionListKind)}
      >
        <Button
          onClick={onClickManageMulti}
          ariaLabel="Manage Multiple Submissions"
          type="warning"
        >
          <Icon type="wrench" />
          <Msg id="button.manageMultipleSubmissions" />
        </Button>
      </Restricted>
    </div>
  );
}
