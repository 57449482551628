import classnames from "classnames";
import * as React from "react";

import { useStoreContext } from "@context";

import {
  ReviewSummary,
  Msg,
  Review,
  ReviewStatusTrackerBar,
} from "@shared/components";
import { useTierActionPermissions } from "@shared/hooks";
import { useInvitationStatus, usePingReviewer } from "@shared/hooks/query";
import { ReviewerUserModel, SubmissionListModel } from "@shared/models";
import { ApplicantExamStatus, ProjectRole } from "@shared/services/enums";
import { isAutoEvaluated, isEvaluatedStatus } from "@shared/services/exam";
import { getDisplayInvitationStatus } from "@shared/services/member";
import * as ScoreService from "@shared/services/score";

import PingReviewer from "../pingReviewer/PingReviewer";
import { SubmissionAutoEvaluated } from "../submissionAutoEvaluated/SubmissionAutoEvaluated";
import { SubmissionEvaluationDropdown } from "../submissionEvaluationDropdown/SubmissionEvaluationDropdown";

/**
 * Interface
 */
export type ExternalProps = {
  submission: SubmissionListModel;
  onClose: () => void;
  onClickSubmission: () => void;
  onEvaluationChange: (newStatus: ApplicantExamStatus) => void;
  className?: string;
};

export type ReviewDrawerProps = ExternalProps;

/**
 * Component
 * @param props ReviewDrawerProps
 */
export default function ReviewDrawer({
  submission,
  onClose,
  onClickSubmission,
  onEvaluationChange,
  className,
}: ReviewDrawerProps) {
  const {
    id: submissionId,
    reviews,
    pendingReviewers,
    evaluationMeta: submissionEvaluationMeta,
    status: submissionStatus,
    applicantEmail,
    applicantFullName,
    applicantId,
  } = submission;

  const { projectId, user } = useStoreContext();
  const pingReviewer = usePingReviewer();
  const invitationStatus = useInvitationStatus();
  const { reviewAllowed } = useTierActionPermissions();

  /**
   * States
   */
  const [pingModal, setPingModal] = React.useState<{
    isOpen: boolean;
    reviewer?: ReviewerUserModel;
  }>({ isOpen: false });

  const [pinngedIds, setPinngedIds] = React.useState<number[]>([]);

  const normalizedPendingReviewers = pendingReviewers.map(
    (reviewer) =>
      getDisplayInvitationStatus({
        invitationStatus,
        reviewer,
      }) as ReviewerUserModel,
  );

  const isEvaluated = isEvaluatedStatus(submissionStatus);

  const isReviewableStatus =
    submissionStatus === ApplicantExamStatus.Submitted ||
    submissionStatus === ApplicantExamStatus.InReview ||
    isEvaluated;

  const isProjectAdmin = Boolean(
    user?.hasRole(ProjectRole.ProjectAdmin, projectId),
  );

  /**
   * Private Functions
   */
  const rootStyle = classnames("code-review-drawer", {
    [`${className}`]: Boolean(className),
  });

  const onPingReviewer = () => {
    const { reviewer } = pingModal;
    if (reviewer) {
      pingReviewer.mutate({ submissionId, reviewerId: reviewer.id });
      setPinngedIds(Array.from(new Set([...pinngedIds, reviewer.id])));
    }
    setPingModal({ isOpen: false });
  };

  /**
   * Render
   */
  return (
    <div className={rootStyle}>
      <div className="code-review-drawer__header">
        <div className="code-review-drawer__header__left">
          <div className="code-review-drawer__header__left__title">
            <div className="code-review-drawer__evaluation">
              {isReviewableStatus && (
                <SubmissionEvaluationDropdown
                  submissionId={submissionId}
                  submissionStatus={submissionStatus}
                  disabled={!isProjectAdmin}
                  onEvaluationChange={onEvaluationChange}
                />
              )}
              {isAutoEvaluated(submissionStatus, submissionEvaluationMeta) && (
                <SubmissionAutoEvaluated />
              )}
            </div>
            <div className="code-review-drawer__title">
              <span
                className="code-review-drawer__title__name"
                onClick={onClickSubmission}
              >
                {applicantFullName || applicantId}
              </span>
              {applicantEmail && (
                <>
                  <span className="code-review-drawer__title__email">
                    {applicantEmail}
                  </span>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="code-review-drawer__header__right">
          <button className="delete" aria-label="Close" onClick={onClose} />
        </div>
      </div>
      <div className="code-review-drawer__body">
        <div className="code-review-drawer__review-summary">
          <Msg id="reviews" tagName="h3" />
          <ReviewSummary
            reviews={reviews}
            pendingCount={pendingReviewers.length}
            hideExistingReviews
            displayNotReviewed
          />
        </div>
        {Boolean(reviews.length) && (
          <div>
            <ReviewStatusTrackerBar reviews={reviews} />
          </div>
        )}
        <div className="code-review-drawer__scroll-container">
          {ScoreService.sortReviews(reviews).map((review) => (
            <Review
              key={review.id}
              reviewer={review.reviewer}
              canReview={reviewAllowed}
              score={review.score}
              feedback={review.feedback}
              isStale={review.isStale}
              isPingable={
                !isEvaluated &&
                user?.hasProjectAdmin(projectId) &&
                user?.id !== review.reviewer.id &&
                review.isStale
              }
              reviewedAt={review.updatedAt}
              pingDisabled={pinngedIds.includes(review.reviewer.id)}
              onClickPing={() =>
                setPingModal({ isOpen: true, reviewer: review.reviewer })
              }
            />
          ))}
          {normalizedPendingReviewers.map((reviewer) => (
            <Review
              key={reviewer.id}
              reviewer={reviewer}
              canReview={reviewAllowed}
              isPingable={
                !isEvaluated &&
                user?.hasProjectAdmin(projectId) &&
                user?.id !== reviewer.id
              }
              pingDisabled={
                pinngedIds.includes(reviewer.id) ||
                Boolean(reviewer.invitationStatus)
              }
              onClickPing={() => setPingModal({ isOpen: true, reviewer })}
            />
          ))}
        </div>
      </div>
      {pingModal.isOpen && pingModal.reviewer && (
        <PingReviewer
          isOpen={true}
          reviewer={pingModal.reviewer}
          onOK={onPingReviewer}
          onClose={() => setPingModal({ isOpen: false })}
        />
      )}
    </div>
  );
}
