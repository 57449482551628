import MSG from "@shared/services/message";

import {
  IntegrationSocialEvent,
  IntegrationType,
  SpokenLanguages,
} from "../services/enums";

export class SlackModel {
  id: number;
  organizationId: number;
  projectId: number;
  integrations: SlackIntegrationModel[];
  kind: IntegrationType;
  scope: string;
  displayName: string;
  createdAt: string;
  createdBy: number;
  updatedAt: string;
  updatedBy: number;

  constructor(args?: {}) {
    Object.assign(this, args);
  }
}

// the channels of the slack app
export class SlackChannelModel {
  channelId: string;
  channelName: string;
  isPrivateChannel: boolean;

  constructor(args?: {}) {
    Object.assign(this, args);
  }
}

// the channel after adding via Tract Test app
export class SlackIntegrationModel {
  id: number;
  projectSocialId: number;
  channelId: string;
  displayName: string;
  events: IntegrationSocialEvent[];
  isPrivate: boolean;
  language: SpokenLanguages;
  createdAt: string;
  createdBy: number;
  updatedAt: string;
  updatedBy: number;
  examNotificationSubscriptions: number[];
  subscribedToAllExamNotifications: boolean;
  mostRecentDeliveryResult?: {
    deliveredAt: number;
    error?: string;
  };

  constructor(args?: {}) {
    Object.assign(this, args);
  }

  static getIntegrationErrorString(errorStr: string): string {
    switch (errorStr) {
      case "channel_not_found":
        return MSG.getMessageByKey("slack.errors.channelNotFound");
      case "account_inactive":
        return MSG.getMessageByKey("slack.errors.accountInactive");
      case "is_archived":
        return MSG.getMessageByKey("slack.errors.channelArchived");
      default:
        return MSG.getMessageByKey("slack.errors.genericError");
    }
  }
}

export class SlackIntegrationUserSettingModel {
  id: number;
  projectId: number;
  slackSocialId: number;
  slackUserId: string;
  userId: number;
  slackEnabled: boolean;
  createdAt: string;
  updatedAt: string;

  constructor(args?: {}) {
    Object.assign(this, args);
  }
}

export interface SlackIntegrationInputModel {
  channelId: string;
  events: IntegrationSocialEvent[];
  language: SpokenLanguages;
  subscribedToAllExamNotifications: boolean;
  examNotificationSubscriptions: number[];
}
