import { escapeRegExp } from "lodash";
import { parseFIBAnswer } from "track-markdown";

/**
 * Get FIB answer from string
 * @param value
 * @returns string
 */
export function parse(value: string) {
  return parseFIBAnswer(value).answer;
}

/**
 * Append line break on the end with string.
 * @param source
 */
export function appendLineBreak(source: string): string {
  // NOTE the "- " string will breaks because line syntax allow to have line break in it. Add one more space to avoid it.
  const replaceRegexp = new RegExp(/([-\*\+] )([\r\n|\n|\r]*)$/);
  const combedSource = source.replace(replaceRegexp, `$1 $2`);
  const regexpString = new RegExp(/[\r\n|\n|\r]$/);
  return regexpString.test(combedSource) ? combedSource : `${combedSource}\n`;
}

/**
 * getFormattedText
 * @param {string} text text has been written in textarea
 * @param {number[]} cursorPosition selecting cursor position
 * @param {string[]} markdownToInsert markdown prefix and suffix to insert
 * @param {boolean} multiline
 * @returns {[string, number[], number[]]} [formattedText, newCursorPosition, newTextRange]
 */
export function getFormattedText(
  text: string,
  [cursorStart, cursorEnd]: number[],
  [markdownPrefix, markdownSuffix]: string[],
  multiline?: boolean,
): [string, number[], number[]] {
  const markdownPrefixLength = markdownPrefix.length;
  const hasTextToInsert = (words: string) =>
    words.startsWith(markdownPrefix) && words.endsWith(markdownSuffix);
  const selectedWords = (words: string) =>
    words.slice(markdownPrefix.length, words.length - markdownSuffix.length);
  const afterCursorHasText = (cursorPoint: number) =>
    cursorPoint < text.length && text[cursorPoint].trim() !== "";
  const beforeCursorHasText = (cursorPoint: number) =>
    cursorPoint > 0 && text[cursorPoint - 1].trim() !== "";

  let [textStart, textEnd] = [cursorStart, cursorEnd]; // text range will affect new text
  let wordsAroundCursor: string | undefined = undefined; // word next to cursor position
  let formattedText = "";
  let [newCursorStart, newCursorEnd] = [0, 0];

  if (
    markdownPrefixLength !== 0 &&
    cursorStart === cursorEnd &&
    (afterCursorHasText(cursorStart) || beforeCursorHasText(cursorStart))
  ) {
    while (beforeCursorHasText(textStart)) {
      textStart--;
    }
    while (afterCursorHasText(textEnd)) {
      textEnd++;
    }
  }

  const newTextStart = Math.max(0, textStart - markdownPrefix.length);
  const newTextEnd = Math.min(text.length, textEnd + markdownSuffix.length);
  const surroundingSelection = text.slice(newTextStart, newTextEnd);
  const wordUnderCursor = text.slice(textStart, textEnd);

  if (hasTextToInsert(wordUnderCursor)) {
    wordsAroundCursor = selectedWords(wordUnderCursor);
  } else if (hasTextToInsert(surroundingSelection)) {
    [textStart, textEnd] = [newTextStart, newTextEnd];
    wordsAroundCursor = selectedWords(surroundingSelection);
  }

  if (wordsAroundCursor !== undefined) {
    const formattedTextAroudCursor = multiline
      ? wordsAroundCursor.replace(
          new RegExp( // multiline toggle off regex
            `${escapeRegExp(markdownSuffix)}\n${escapeRegExp(
              markdownPrefix,
            ).replace("1", "\\d+")}`,
            "g",
          ),
          "\n",
        )
      : wordsAroundCursor;

    formattedText = formattedTextAroudCursor;
    [newCursorStart, newCursorEnd] =
      cursorStart === cursorEnd
        ? [
            cursorStart - markdownPrefix.length,
            cursorEnd - markdownPrefix.length,
          ]
        : [textStart, textStart + formattedTextAroudCursor.length];
  } else if (multiline) {
    const prependNewline = textStart > 0 && text[textStart - 1] !== "\n";
    const appendNewline = textEnd < text.length && text[textEnd] !== "\n";
    const adjustedStart = textStart + (prependNewline ? 1 : 0);
    const noNewlines = wordUnderCursor
      .split("\n")
      .map(
        (line, index) =>
          markdownPrefix.replace("1", String(index + 1)) +
          line +
          markdownSuffix,
      )
      .join("\n");

    formattedText =
      (prependNewline ? "\n" : "") + noNewlines + (appendNewline ? "\n" : "");
    [newCursorStart, newCursorEnd] =
      cursorStart === cursorEnd
        ? [
            adjustedStart + markdownPrefixLength,
            adjustedStart + markdownPrefixLength,
          ]
        : [adjustedStart, adjustedStart + noNewlines.length];
  } else {
    formattedText = markdownPrefix + wordUnderCursor + markdownSuffix;
    [newCursorStart, newCursorEnd] = [
      cursorStart + markdownPrefixLength,
      cursorEnd + markdownPrefixLength,
    ];
  }

  return [formattedText, [newCursorStart, newCursorEnd], [textStart, textEnd]];
}
