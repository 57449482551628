import * as classnames from "classnames";
import * as React from "react";

import {
  Msg,
  Tooltip,
  Button,
  Icon,
  Modal,
  FormGroup,
  Form,
  Label,
  LeftBlock,
  Textarea,
  Tag,
  Input,
  Block,
  DatePicker,
  DatePickerType,
  LanguageTag,
  Radio,
  ApplicantList,
} from "@shared/components";
import { useApplicantList, useTierActionPermissions } from "@shared/hooks";
import {
  useExamDeliveryTemplate,
  useExamMailDeliveryDetail,
} from "@shared/hooks/query";
import {
  ApplicantExamsModel,
  MailDeliveryCreateModel,
  MailDeliveryListModel,
  MailDeliveryType,
} from "@shared/models";
import { dayjs, formatDateTimeMinutes } from "@shared/services/date";
import { DeliveryMethod, DeliveryStatus } from "@shared/services/enums";
import Message from "@shared/services/message";

import {
  formatApplicantCSV,
  formatDeliveryEmailTemplate,
} from "../../../examSections/examSectionUtil/ExamSectionUtil";

export type ExamDeliveryDetailModalProps = {
  status?: MailDeliveryType;
  canEditScheduledDelivery: boolean;
  canEditHistoryDelivery: boolean;
  isOpen: boolean;
  selectedDelivery: MailDeliveryListModel;
  submitting: boolean;
  examUrlToken: string;
  onCloseModal: () => void;
  onDeliveryClickOk: (params: { deadline: string }) => void;
  onScheduledDeliveryClickOk: (params: MailDeliveryCreateModel) => void;
  onDeleteScheduledDelivery: () => void;
};

const APPLICANT_LIMIT = 100;

type FormModel = {
  formErrors: {};
  formValid: boolean;
  formValues: Partial<{
    deadline: string;
    scheduledFor: string;
    applicantCSV: string;
    mailSubject: string;
    mailBody: string;
    senderName: string;
    language?: string;
  }>;
};

export const ExamDeliveryDetailModal: React.FunctionComponent<
  ExamDeliveryDetailModalProps
> = ({
  status,
  canEditScheduledDelivery,
  canEditHistoryDelivery,
  selectedDelivery,
  onCloseModal,
  isOpen,
  onDeliveryClickOk,
  onScheduledDeliveryClickOk,
  submitting,
  examUrlToken,
  onDeleteScheduledDelivery,
}: ExamDeliveryDetailModalProps) => {
  const { applicantExamCount, examId, id: selectedId } = selectedDelivery;
  const { data: deliveryDetail } = useExamMailDeliveryDetail(selectedId);
  const { isScheduleDeliveryAllowed } = useTierActionPermissions();
  const { data: emailTemplate } = useExamDeliveryTemplate();
  /**
   * State
   */
  const rootStyle = classnames("code-exam-delivery-detail-modal");

  const [editing, setEditing] = React.useState<boolean>(false);

  const [form, setForm] = React.useState<FormModel>({
    formErrors: {},
    formValid: false,
    formValues: {},
  });

  const [warnModal, setWarnModal] = React.useState<{
    isOpen: boolean;
  }>({
    isOpen: false,
  });

  const [removeApplicantModal, setRemoveApplicantModal] = React.useState<{
    isOpen: boolean;
    selectedApplicant?: ApplicantExamsModel;
  }>({
    isOpen: false,
  });
  const {
    applicants,
    applicantCount,
    filteredApplicants,
    remove,
    setApplicants,
    updateKeyword,
  } = useApplicantList({
    applicantExamCount,
    examId,
  });

  const preview = (body?: string, deadline?: string) =>
    formatDeliveryEmailTemplate(
      deliveryDetail?.language === "en"
        ? emailTemplate.enTemplate || ""
        : emailTemplate.template,
      {
        body,
        deadline,
        token: examUrlToken,
      },
    );

  const initialValues =
    status === "history"
      ? {
          deadline: selectedDelivery.endAt,
        }
      : {
          scheduledFor: selectedDelivery.startAt,
          deadline: selectedDelivery.endAt,
          mailSubject: selectedDelivery.mailSubject,
          mailBody: selectedDelivery.mailBody,
          senderName: selectedDelivery.mailSenderName,
          language: deliveryDetail?.language,
        };

  /**
   * Effects
   */
  React.useEffect(() => {
    setApplicants(deliveryDetail.applicantExams);
  }, [deliveryDetail.applicantExams, selectedDelivery.id, setApplicants]);

  /**
   * Private Functions
   */
  const onFormChange = (
    formValid: boolean,
    formValues: { applicantCSV: string; mailBody: string; deadline: string },
    formErrors: {},
  ) => {
    if (
      status === "history" &&
      selectedDelivery.endAt &&
      formValues.deadline &&
      dayjs(formValues.deadline).format() ===
        dayjs(selectedDelivery.endAt).format()
    ) {
      setForm({ formValues, formValid: false, formErrors: {} });
      return;
    }

    setForm({ formValues, formErrors, formValid });
  };

  const onChangeKeyword = (event: React.FormEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value;
    updateKeyword(value);
  };

  const onRemoveApplicant = (selectedApplicant: ApplicantExamsModel) => {
    remove(selectedApplicant);
    setRemoveApplicantModal({ isOpen: false });
  };

  const onClickOkButton = () => {
    const {
      deadline = "",
      applicantCSV = "",
      mailSubject = "",
      mailBody = "",
      senderName,
      scheduledFor = "",
      language,
    } = form.formValues;

    if (status === "history") {
      onDeliveryClickOk({
        deadline,
      });

      return;
    }

    const params = {
      deadline,
      applicants: applicantCSV.length
        ? formatApplicantCSV(applicantCSV)
            .trim()
            .split("\n")
            .map((email) => ({ email }))
        : [],
      mailSubject,
      mailBody,
      senderName,
      scheduledFor,
      startAt: scheduledFor,
      ...(deliveryDetail?.language ? { language } : {}),
    };

    onScheduledDeliveryClickOk(params);
  };

  const onClickRemoveApplicant = (selectedApplicant: ApplicantExamsModel) => {
    if (applicants?.length === 1) {
      setWarnModal({
        isOpen: true,
      });
    } else {
      setRemoveApplicantModal({
        isOpen: true,
        selectedApplicant,
      });
    }
  };

  const deliveryDetailForm = (editing: boolean) => {
    if (!editing || (editing && status === "history")) {
      return (
        <>
          {deliveryDetail?.language && (
            <FormGroup>
              <Label>
                <Msg id="exam.examlanguage" />
              </Label>
              <div>
                <LanguageTag language={deliveryDetail.language} color="pink" />
              </div>
            </FormGroup>
          )}
          {deliveryDetail?.deliveryMethod === DeliveryMethod.URL && (
            <>
              <FormGroup>
                <Label>
                  <Msg id="exam.delivery.method" />
                </Label>
                <div>
                  <Tag>{Message.getMessageByKey("exam.urlDelivery.tag")}</Tag>
                </div>
              </FormGroup>
              <FormGroup>
                <Label>
                  <Msg id="exam.urlDelivery.startDate" />
                </Label>
                <div>
                  {formatDateTimeMinutes(selectedDelivery?.startAt ?? "")}
                </div>
              </FormGroup>
              <FormGroup>
                <Label>
                  <Msg id="exam.urlDelivery.endDate" />
                </Label>
                <div>
                  {formatDateTimeMinutes(selectedDelivery?.endAt ?? "")}
                </div>
              </FormGroup>
            </>
          )}
          {deliveryDetail?.deliveryMethod === DeliveryMethod.Mail && (
            <>
              <FormGroup>
                <Label>
                  <Msg
                    id={
                      status === "history"
                        ? "deliveredAt"
                        : "exam.delivery.scheduledDateTime"
                    }
                  />
                </Label>
                <div>
                  {formatDateTimeMinutes(
                    status === "history" &&
                      selectedDelivery.deliveryStatus ===
                        DeliveryStatus.Unscheduled
                      ? selectedDelivery.createdAt
                      : selectedDelivery.startAt || "",
                  )}

                  {status === "history" &&
                    selectedDelivery.deliveryStatus !==
                      DeliveryStatus.Unscheduled && (
                      <Tag className="delivery-tag">
                        {Message.getMessageByKey(
                          "exam.delivery.scheduledDelivery",
                        )}
                      </Tag>
                    )}
                </div>
              </FormGroup>
              <FormGroup>
                <Label>
                  <Msg id="common.deadline" />
                </Label>
                {editing ? (
                  <DatePicker
                    id="deadline"
                    name="deadline"
                    type={DatePickerType.DateTime}
                    allowPast={false}
                    minDate={
                      selectedDelivery.endAt &&
                      dayjs().format() > selectedDelivery.endAt
                        ? dayjs().format()
                        : selectedDelivery.endAt
                    }
                  />
                ) : (
                  <div>
                    {selectedDelivery.endAt &&
                      formatDateTimeMinutes(selectedDelivery.endAt)}
                  </div>
                )}
              </FormGroup>
            </>
          )}
          <FormGroup>
            <Label>
              <Msg id="delivery.from" />
            </Label>
            <div className="is-break-word">
              {selectedDelivery.mailSenderName}
            </div>
          </FormGroup>
          <FormGroup>
            <Label>
              <Msg id="delivery.subject" />
            </Label>
            <div className="is-break-word">{selectedDelivery.mailSubject}</div>
          </FormGroup>
          <FormGroup>
            <Label>
              <Msg id="delivery.body" />
            </Label>
            <div>
              <LeftBlock className="code-exam-delivery-list__preview__window__block is-full-width">
                {preview(selectedDelivery.mailBody, selectedDelivery.endAt)}
              </LeftBlock>
              <p className="code-exam-delivery-list__preview__window__text">
                <Msg id="message.applicant.urlAnnotation" />
              </p>
            </div>
          </FormGroup>
        </>
      );
    }

    return (
      <>
        <FormGroup className="code-exam-delivery-detail-modal__add-applicants">
          <Label>
            <Msg id="exam.addApplicants" />
          </Label>
          <Tooltip
            placement="top"
            text={<Msg id="tier.disabled" />}
            disabled={isScheduleDeliveryAllowed}
          >
            <Textarea
              disabled={
                applicants?.length === APPLICANT_LIMIT ||
                !isScheduleDeliveryAllowed
              }
              id="applicantCSV"
              name="applicantCSV"
              placeholder={Message.getMessageByKey("placeholder.enterEmail")}
            />
          </Tooltip>
        </FormGroup>
        {deliveryDetail?.language && (
          <FormGroup>
            <Label>
              <Msg id="exam.examlanguage" />
            </Label>
            <div className="code-exam-delivery-detail-modal__language">
              <div>
                <Radio name="language" defaultValue="ja">
                  <LanguageTag language="ja" color="pink" />
                </Radio>
              </div>
              <div>
                <Radio name="language" defaultValue="en">
                  <LanguageTag language="en" color="pink" />
                </Radio>
              </div>
            </div>
          </FormGroup>
        )}
        <FormGroup className="code-exam-delivery-detail-modal__schedule__scheduledFor">
          <Label>
            <Msg id={"exam.delivery.scheduledDate"} />
          </Label>
          <DatePicker
            id="scheduledFor"
            name="scheduledFor"
            type={DatePickerType.DateTime}
            maxDate={form.formValues.deadline}
            timeIntervals={60}
            readOnly={true}
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="deadline">
            <Msg id="common.deadline" />
          </Label>
          <DatePicker
            id="deadline"
            name="deadline"
            type={DatePickerType.DateTime}
            minDate={form.formValues.scheduledFor}
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="senderName">
            <Msg id="delivery.from" />
          </Label>
          <Input id="senderName" name="senderName" />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="mailSubject">
            <Msg id="delivery.subject" />
          </Label>
          <Input id="mailSubject" name="mailSubject" />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="mailBody">
            <Msg id="delivery.body" />
          </Label>
          <Block className="code-exam-delivery-list__preview">
            <LeftBlock className="code-exam-delivery-list__preview__input">
              <Textarea id="mailBody" name="mailBody" />
            </LeftBlock>
            <div className="code-exam-delivery-list__preview__window">
              <LeftBlock className="code-exam-delivery-list__preview__window__block">
                {preview(form.formValues.mailBody, form.formValues.deadline)}
              </LeftBlock>
              <p className="code-exam-delivery-list__preview__window__text">
                <Msg id="message.applicant.urlAnnotation" />
              </p>
            </div>
          </Block>
        </FormGroup>
      </>
    );
  };

  return (
    <>
      <Modal
        ariaLabel="Delivery Detail"
        cancelButtonLabel={<Msg id="close" />}
        className={rootStyle}
        disableCancel={submitting}
        disableClose={submitting}
        disableOk={submitting || !form.formValid}
        hasOkButton={editing}
        isOpen={isOpen}
        okButtonLabel={<Msg id="action.update" />}
        onClickBackground={onCloseModal}
        onClickCancel={onCloseModal}
        onClickOk={onClickOkButton}
        onClose={onCloseModal}
        size="large"
        title={<Msg id="exam.delivery.detail" />}
      >
        <Form
          clear={!editing}
          validation={{
            deadline: [
              "date",
              "iso",
              "required",
              ["within10YearsFrom", dayjs().format()],
              ...(status === "scheduled" ? ["notAllowPast"] : []),
              ...[
                status === "history"
                  ? [
                      "greaterThan",
                      selectedDelivery.endAt &&
                      dayjs().format() > selectedDelivery.endAt
                        ? dayjs().format()
                        : dayjs(selectedDelivery.endAt).format(),
                    ]
                  : [],
              ],
            ],
            ...(status === "scheduled" && {
              scheduledFor: [
                "date",
                "iso",
                "required",
                "notAllowPast",
                [
                  "max",
                  dayjs(form.formValues.deadline).isValid()
                    ? form.formValues.deadline
                    : undefined,
                ],
                ["within10YearsFrom", dayjs().format()],
              ],
              applicantCSV: [
                "string",
                [
                  "emailArray",
                  {
                    excludeEmails: applicants?.map(
                      (applicant) => applicant.applicant.email,
                    ),
                  },
                ],
              ],
              senderName: ["string", ["max", 255]],
              mailSubject: ["string", "required", ["max", 255]],
              mailBody: ["string", "required"],
              ...(deliveryDetail?.language
                ? { language: ["string", "required"] }
                : {}),
            }),
          }}
          initialValues={initialValues}
          showError={{
            scheduledFor: true,
          }}
          onFormChange={onFormChange}
          error={form.formErrors}
        >
          <>
            <div className="code-exam-delivery-detail-modal__applicant-container">
              <FormGroup>
                <Label>
                  <Msg id="common.applicant" />
                </Label>
                <ApplicantList
                  applicantCount={applicantCount}
                  canEditScheduledDelivery={
                    status === "scheduled" && canEditScheduledDelivery
                  }
                  filteredApplicants={filteredApplicants}
                  onChangeKeyword={onChangeKeyword}
                  onClickRemoveApplicant={onClickRemoveApplicant}
                  setKeyword={updateKeyword}
                />
              </FormGroup>
            </div>
            <div className="code-exam-delivery-detail-modal__edit-container">
              <div className="code-exam-delivery-detail-modal__edit-container__toolbar">
                {selectedDelivery.deliveryMethod === DeliveryMethod.URL &&
                  canEditHistoryDelivery && (
                    <Tooltip
                      text={Message.getMessageByKey(
                        "exam.delivery.updateIndividually",
                      )}
                      placement="top-end"
                    >
                      <Button ariaLabel="Edit Delivery" size="small" disabled>
                        <Msg id="exam.delivery.editDelivery" />
                      </Button>
                    </Tooltip>
                  )}
                {selectedDelivery.deliveryMethod !== DeliveryMethod.URL &&
                  (canEditScheduledDelivery || canEditHistoryDelivery) && (
                    <Button
                      ariaLabel={
                        editing ? "Cancel Edit Delivery" : "Edit Delivery"
                      }
                      disabled={false}
                      onClick={() => setEditing(editing ? false : true)}
                      size="small"
                    >
                      <Msg
                        id={editing ? "cancel" : "exam.delivery.editDelivery"}
                      />
                    </Button>
                  )}
              </div>
              {deliveryDetailForm(editing)}
            </div>
          </>
        </Form>
      </Modal>
      {warnModal.isOpen && selectedDelivery !== undefined && (
        <Modal
          ariaLabel="Confirm Cancel"
          className="code-exam-delivery-list__delete-modal"
          title={
            <>
              <Icon className="exclamation-icon" type="exclamation-triangle" />
              <Msg id="cancel.delivery" />
            </>
          }
          okButtonType="danger"
          okButtonLabel={<Msg id="cancel.delivery" />}
          isOpen={warnModal.isOpen && selectedDelivery !== undefined}
          onClose={() => setWarnModal({ isOpen: false })}
          onClickCancel={() => setWarnModal({ isOpen: false })}
          onClickBackground={() => setWarnModal({ isOpen: false })}
          onClickOk={onDeleteScheduledDelivery}
        >
          <div className="code-exam-delivery-list__delete-modal__message">
            <Msg id="exam.delivery.schedule.confirm.delete" />
          </div>
        </Modal>
      )}
      {removeApplicantModal.isOpen &&
        removeApplicantModal.selectedApplicant !== undefined && (
          <Modal
            ariaLabel="Remove Applicant"
            className="code-exam-delivery-list__delete-modal"
            title={<Msg id="exam.delivery.remove.applicant" />}
            okButtonLabel={<Msg id="exam.delivery.remove.applicant" />}
            okButtonType="danger"
            isOpen={
              removeApplicantModal.isOpen &&
              removeApplicantModal.selectedApplicant !== undefined
            }
            onClose={() => setRemoveApplicantModal({ isOpen: false })}
            onClickCancel={() => setRemoveApplicantModal({ isOpen: false })}
            onClickBackground={() => setRemoveApplicantModal({ isOpen: false })}
            onClickOk={() =>
              removeApplicantModal.selectedApplicant &&
              onRemoveApplicant(removeApplicantModal.selectedApplicant)
            }
          >
            <div>
              <Msg id="exam.delivery.confirm.remove.applicant" />
            </div>
            <div className="delivery-title">
              {removeApplicantModal.selectedApplicant.applicant.email}
            </div>
          </Modal>
        )}
    </>
  );
};
