import * as classnames from "classnames";
import * as React from "react";

import {
  Alert,
  Button,
  Checkbox,
  DatePicker,
  DatePickerType,
  Form,
  FormGroup,
  Icon,
  Label,
  Msg,
  Table,
  TableBody,
  ValidationMessage,
} from "@shared/components";
import {
  ActionType,
  MultiManageForm,
  SubmissionListModel,
  VersionType,
} from "@shared/models";
import { dayjs } from "@shared/services/date";
import { filterSubmissionByName } from "@shared/services/filter";
import Message from "@shared/services/message";

import {
  SubmissionListWithResultsTableHeader,
  SubmissionListWithResultsTableRow,
} from ".";

/**
 * Interface
 */
export type MultipleManageModalConfirmProps = {
  formValues: MultiManageForm;
  isShow: boolean;
  challengeBar: JSX.Element | null;
  invalidSubmissions?: boolean;

  onFormChange: (valid: boolean, value: MultiManageForm, errors: {}) => void;
  onClickDownloadCSV: () => void;
};

/**
 * Component
 * @param props MultipleManageModalConfirmProps
 */
export function MultipleManageModalConfirm({
  formValues: { action, version, deadline, challenge, submissions },
  isShow,
  challengeBar,
  invalidSubmissions,
  onFormChange,
  onClickDownloadCSV,
}: MultipleManageModalConfirmProps) {
  const rootStyle = classnames("form-group-container", {
    "is-show": isShow,
  });

  /**
   * State
   */
  const [isOpenSubmissions, setIsOpenSubmissions] =
    React.useState<boolean>(false);
  const [isExtendDeadline, setIsExtendDeadline] =
    React.useState<boolean>(false);
  const [filteredSubmissions, setFilteredSubmissions] = React.useState<
    SubmissionListModel[] | undefined
  >([]);
  const [keyword, setKeyword] = React.useState<string>("");

  /**
   * Effects
   */
  React.useEffect(() => {
    if (submissions) {
      const filteredApplicants = submissions.filter(
        filterSubmissionByName(keyword),
      );
      setFilteredSubmissions(filteredApplicants);
    }
  }, [submissions, keyword]);

  const onChangeKeyword = (event: React.FormEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    if (keyword !== value) {
      setKeyword(value);
    }
  };

  const onClickCheckbox = (event: React.FormEvent<HTMLInputElement>) => {
    setIsExtendDeadline(event.currentTarget.checked);
  };

  return (
    <div className={rootStyle}>
      <Form
        validation={{
          deadline: [
            "date",
            "iso",
            "notAllowPast",
            ["within10YearsFrom", dayjs().format()],
          ],
        }}
        initialValues={{ deadline: deadline?.toString() }}
        onFormChange={onFormChange}
      >
        <FormGroup>
          <Label title={<Msg id="submission.actionType" />} />
          <p>
            <Msg
              id={
                action === ActionType.Rescore
                  ? "submission.action.rescoreSubmissions"
                  : "submission.action.resetSubmissions"
              }
            />
          </p>
        </FormGroup>
        <FormGroup>
          <Label title={<Msg id="submission.version" />} />
          <p>
            <Msg
              id={
                version === VersionType.Current
                  ? "submission.action.currentVersion"
                  : "submission.action.updateVersion"
              }
            />
          </p>
        </FormGroup>
        <FormGroup>
          <Label title={<Msg id="submission.target.challenge" />} />
          {challengeBar}
        </FormGroup>
        <FormGroup>
          <Label title={<Msg id="submission.target.submissions" />} />
          <div className="code-multiple-manage-modal__submission-list-container">
            <div
              className="code-multiple-manage-modal__submissions-bar"
              onClick={() => setIsOpenSubmissions(!isOpenSubmissions)}
              role="button"
            >
              <Icon type={isOpenSubmissions ? "angle-down" : "angle-right"} />
              <Msg
                id={
                  isOpenSubmissions
                    ? "submission.hideSubmissions"
                    : "submission.showAllSubmissions"
                }
              />
              <span className="code-multiple-manage-modal__count">
                ({submissions?.length})
              </span>
            </div>
            {isOpenSubmissions && (
              <div className="code-multiple-manage-modal__submission-table-wrapper">
                <div className="code-c-input control code-multiple-manage-modal__search">
                  <input
                    className="input"
                    onChange={onChangeKeyword}
                    placeholder={Message.getMessageByKey("common.emailOrName")}
                    type="text"
                  />
                </div>
                <Table>
                  <SubmissionListWithResultsTableHeader />
                  <TableBody>
                    {filteredSubmissions?.map((submission) => (
                      <SubmissionListWithResultsTableRow
                        key={submission.id}
                        challenge={challenge}
                        submission={submission}
                      />
                    ))}
                  </TableBody>
                </Table>
              </div>
            )}
          </div>
        </FormGroup>
        {action === ActionType.Reset && (
          <FormGroup>
            <Label title={<Msg id="submission.extendDeadline" />} />
            <Checkbox
              value={isExtendDeadline}
              className="code-multiple-manage-modal__text"
              onChange={onClickCheckbox}
              readOnly={Boolean(deadline)}
            >
              <Msg id="submission.action.extendDeadline" />
            </Checkbox>
            {isExtendDeadline && (
              <div className="code-multiple-manage-modal__datepicker-wrapper">
                <Label title={<Msg id="change-deadline.newDeadline" />} />
                <DatePicker
                  type={DatePickerType.DateTime}
                  name="deadline"
                  allowPast={false}
                />
              </div>
            )}
            {invalidSubmissions && (
              <ValidationMessage
                name="deadline"
                error={{
                  deadline: Message.getMessageByKey(
                    "submission.message.extendDeadline",
                  ),
                }}
              />
            )}
          </FormGroup>
        )}
        <Alert type="warning">
          <Msg id="submission.message.downloadCSV" />
          <Button
            onClick={onClickDownloadCSV}
            ariaLabel="Download CSV"
            disabled={false}
          >
            <Icon type="download" />
            <Msg id="button.csvDownload" />
          </Button>
        </Alert>
      </Form>
    </div>
  );
}
