import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import * as ReactDOM from "react-dom";
import { createStore, applyMiddleware, compose } from "redux";
import { createEpicMiddleware } from "redux-observable";
import { ajax } from "rxjs/observable/dom/ajax";

import { queryClient } from "@api/queryClient";

import { RollbarProvider } from "@shared/components";
import { ZodTranslationProvider } from "@shared/hooks/providers/ZodTranslationProvider";
import { Provider } from "@shared/libs/react-intl-redux";

import "../node_modules/@fortawesome/fontawesome-free/css/all.min.css";
import "../node_modules/@fortawesome/fontawesome-free/css/v4-shims.min.css";
import {
  i18nGetAction,
  userGetAction,
  globalAppTypeSetAction,
} from "./actions";

/**
 * Entry point
 */
import App from "./components/orgs/App";
import { epics } from "./epics";
import "./indexOrgs.scss";

/**
 * Store setup
 */
import { organizationReducers } from "./reducers";
import { pushVariables, DataLayerValues } from "./shared/services/analytics";
import { GlobalAppType } from "./shared/services/enums";
import InitialData from "./shared/services/initialData";
import { activate as activateIntercom } from "./shared/services/intercom";
import Logger from "./shared/services/logger";
import "./shared/services/rxjsBuilder";
import { version } from "./shared/services/version";

// set container name for tracking
pushVariables({ appName: "organization" } as DataLayerValues);

// boot intercom widget.
activateIntercom();

Logger.info("Running with organization mode.");

const composeEnhancers =
  (process.env.NODE_ENV === "development" &&
    window &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const epicOptions = { dependencies: { ajax } };
const middleware =
  process.env.NODE_ENV !== "production"
    ? // Redux middleware that detects mutations between and outside redux dispatches.
      [
        require("redux-immutable-state-invariant").default(),
        createEpicMiddleware(epics, epicOptions),
      ]
    : [createEpicMiddleware(epics, epicOptions)];

const store = createStore(
  organizationReducers,
  composeEnhancers(applyMiddleware(...middleware)),
);

// get initial state data
store.dispatch(userGetAction.request());
store.dispatch(i18nGetAction(navigator && navigator.language.split("-")[0]));
store.dispatch(globalAppTypeSetAction.request(GlobalAppType.Org));

/**
 * Render
 */
ReactDOM.render(
  <RollbarProvider>
    {/* need to update some definitions */}
    {/* @ts-ignore */}
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <ZodTranslationProvider>
          <App />
          <ReactQueryDevtools initialIsOpen={false} />
        </ZodTranslationProvider>
      </QueryClientProvider>
    </Provider>
  </RollbarProvider>,
  document.getElementById("root") as HTMLElement,
);

Logger.info("env", process.env);
Logger.info("initialData", InitialData.initialData);
Logger.info("version", version);
