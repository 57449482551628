import * as classNames from "classnames";

import { Icon, Msg, Tooltip } from "@shared/components";
import { AutoFilterType } from "@shared/services/enums";
import Message from "@shared/services/message";

import { AutoFilterFormProps } from "./AutoFilterForm";

const getTooltipText = ({
  filterType,
  passingScore,
  failingScore,
}: Pick<
  AutoFilterFormProps,
  "filterType" | "passingScore" | "failingScore"
>) => {
  const minFailedStr = "0.00";
  const maxFailedStr = (
    filterType === AutoFilterType.AutoPassFail && passingScore !== undefined
      ? passingScore - 0.01
      : failingScore
  )?.toFixed(2);

  const minSubmittedStr = (
    filterType === AutoFilterType.AutoPassFailReview &&
    failingScore !== undefined
      ? Math.min(failingScore + 0.01, 100)
      : 0
  ).toFixed(2);
  const maxSubmittedStr = (
    filterType === AutoFilterType.AutoPassFailReview &&
    passingScore !== undefined
      ? passingScore - 0.01
      : 100
  ).toFixed(2);

  const minPassedStr = passingScore?.toFixed(2);
  const maxPassedStr = "100.00";

  return {
    failed: {
      messageKey:
        minFailedStr === maxFailedStr
          ? "exam.autoFilter.tooltip.singleNumber"
          : "exam.autoFilter.tooltip.range",
      values: {
        folder: Message.getMessageByKey("exam.autoFilter.failed"),
        min: minFailedStr,
        max: maxFailedStr,
      },
    },
    submitted: {
      messageKey:
        minSubmittedStr === maxSubmittedStr
          ? "exam.autoFilter.tooltip.singleNumber"
          : "exam.autoFilter.tooltip.range",
      values: {
        folder: Message.getMessageByKey("exam.autoFilter.submitted"),
        min: minSubmittedStr,
        max: maxSubmittedStr,
      },
    },
    passed: {
      messageKey:
        minPassedStr === maxPassedStr
          ? "exam.autoFilter.tooltip.singleNumber"
          : "exam.autoFilter.tooltip.range",
      values: {
        folder: Message.getMessageByKey("exam.autoFilter.passed"),
        min: minPassedStr,
        max: maxPassedStr,
      },
    },
  };
};

export function BarChartRow({
  filterType,
  passingScore,
  failingScore,
  hasError,
}: Pick<AutoFilterFormProps, "filterType" | "passingScore" | "failingScore"> & {
  hasError: boolean;
}) {
  const wrapperStyles = classNames("barchart-wrapper", {
    "has-white-bg":
      !hasError &&
      (filterType == AutoFilterType.AutoPassFail
        ? Boolean(passingScore)
        : Boolean(passingScore) || Boolean(failingScore)),
  });

  const tooltipText = getTooltipText({
    filterType,
    passingScore,
    failingScore,
  });

  return (
    <div className="barchart-row">
      <div className={wrapperStyles}>
        {!hasError &&
          filterType === AutoFilterType.AutoPassFail &&
          passingScore !== undefined && (
            <>
              {passingScore > 0 && (
                <Tooltip
                  wrapperClassName={classNames("bar", "bar-failed")}
                  wrapperStyle={{ width: `calc(${passingScore}% - 0.5px)` }}
                  text={
                    <Msg
                      id={tooltipText.failed.messageKey}
                      values={tooltipText.failed.values}
                    />
                  }
                  placement="bottom-start"
                >
                  <Icon type="times" />
                </Tooltip>
              )}
              <Tooltip
                wrapperClassName={classNames("bar", "bar-passed", "auto-grow")}
                text={
                  <Msg
                    id={tooltipText.passed.messageKey}
                    values={tooltipText.passed.values}
                  />
                }
                placement="bottom-start"
              >
                <Icon type="check" />
              </Tooltip>
            </>
          )}
        {!hasError && filterType === AutoFilterType.AutoPassFailReview && (
          <>
            {failingScore !== undefined && failingScore >= 0 && (
              <Tooltip
                wrapperClassName={classNames("bar", "bar-failed")}
                wrapperStyle={{ width: `calc(${failingScore}% - 0.5px)` }}
                text={
                  <Msg
                    id={tooltipText.failed.messageKey}
                    values={tooltipText.failed.values}
                  />
                }
                placement="bottom-start"
              >
                <Icon type="times" />
              </Tooltip>
            )}
            {((passingScore !== undefined && passingScore > 0) ||
              (failingScore !== undefined && failingScore < 100)) && (
              <Tooltip
                wrapperClassName={classNames(
                  "bar",
                  "bar-submitted",
                  "auto-grow",
                )}
                text={
                  <Msg
                    id={tooltipText.submitted.messageKey}
                    values={tooltipText.submitted.values}
                  />
                }
                placement="bottom-start"
              >
                <Icon type="inbox" />
              </Tooltip>
            )}
            {passingScore !== undefined && (
              <Tooltip
                wrapperClassName={classNames("bar", "bar-passed")}
                wrapperStyle={{ width: `calc(${100 - passingScore}% - 0.5px)` }}
                text={
                  <Msg
                    id={tooltipText.passed.messageKey}
                    values={tooltipText.passed.values}
                  />
                }
                placement="bottom-start"
              >
                <Icon type="check" />
              </Tooltip>
            )}
          </>
        )}
      </div>
      <div className="bar-ticks">
        <span>0%</span>
        <span>100%</span>
      </div>
    </div>
  );
}
