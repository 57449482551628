import { MiddlewareAPI } from "redux";

import { RootState } from "@reducers";

import { ProjectSwitchItemModel } from "@shared/models";
import { GlobalAppType } from "@shared/services/enums";

export const getCurrentProjectId = (
  state: MiddlewareAPI<RootState>,
): number | undefined => {
  return state.getState().project.currentProjectId;
};

export const getCurrentOrganizationName = (
  state: MiddlewareAPI<RootState>,
): string | undefined => {
  return state.getState().admin.selectedOrg.name;
};

export const getAppBasePath = (state: RootState): string => {
  return state.global.globalAppType === GlobalAppType.Admin
    ? `/admin/orgs/${state.admin.selectedOrg.name}`
    : `/p/${state.project.currentProjectId}`;
};

export const getCurrentProject = (
  state: RootState,
): ProjectSwitchItemModel | undefined => {
  return state.user?.user?.projects.find(
    (item) => item.id === state.project?.currentProjectId,
  );
};
