import { useMutation, useQuery } from "@tanstack/react-query";

import { ApiResponse } from "@api/httpClient";
import {
  getSubmissionResultLocalFile,
  getSubmissionResultLocalFilesMetadata,
} from "@api/submissions/submissionLocalFiles";

import { useStoreContext } from "@context";

import { LocalFileMetaData } from "@shared/models";
import { getLastWeekTimestamp } from "@shared/services/date";
import { FileUploadConfig } from "@shared/services/fileUpload";

import { useRouteParams } from "../useRouteParams";
import { submissionResultKeys } from "./useSubmissionResults";

interface SubmissionResultLocalFilesMetaDataAllKeyParams {
  challengeResultId: number;
  projectId: number;
  submissionId: number;
}

interface DownloadSubmissionResultLocalFileParams {
  challengeResultId: number;
  filename: string;
}

export const submissionResultLocalFilesKeys = {
  all: [...submissionResultKeys.all, "localFiles"] as const,
  localFilesMetadataAll: ({
    challengeResultId,
    projectId,
    submissionId,
  }: SubmissionResultLocalFilesMetaDataAllKeyParams) => [
    ...submissionResultLocalFilesKeys.all,
    projectId,
    submissionId,
    challengeResultId,
  ],
};

export function useGetSubmissionResultLocalFilesMetadata({
  challengeResultId,
  fileUploadConfig,
}: {
  challengeResultId: number;
  fileUploadConfig?: FileUploadConfig;
}) {
  const { projectId } = useStoreContext();
  const { submissionId } = useRouteParams();
  const enabled =
    Boolean(challengeResultId) &&
    Boolean(fileUploadConfig?.uploadEnabled) &&
    Boolean(projectId) &&
    Boolean(submissionId);

  const query = useQuery<LocalFileMetaData[]>({
    queryKey: submissionResultLocalFilesKeys.localFilesMetadataAll({
      challengeResultId,
      projectId,
      submissionId,
    }),
    queryFn: async ({ signal }) => {
      const { result } = await getSubmissionResultLocalFilesMetadata({
        challengeResultId,
        projectId,
        submissionId,
        options: {
          signal,
        },
      });

      return result;
    },
    enabled,
    initialData: [],
    initialDataUpdatedAt: getLastWeekTimestamp(),
    keepPreviousData: true,
  });

  return query;
}

export function useDownloadSubmissionResultLocalFile() {
  const { projectId } = useStoreContext();
  const { submissionId } = useRouteParams();
  const mutation = useMutation({
    mutationFn: ({
      challengeResultId,
      filename,
    }: DownloadSubmissionResultLocalFileParams) =>
      getSubmissionResultLocalFile({
        challengeResultId,
        projectId,
        submissionId,
        options: {
          params: { filename },
        },
      }),
    onSuccess: ({ result }: ApiResponse<string>) => {
      window.location.href = result;
    },
  });

  return mutation;
}
