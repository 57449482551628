import * as classnames from "classnames";

/**
 * Prop interface
 */
export interface BarChartMiniProps {
  max: number;
  data: number[];
  className?: string;
}

/**
 * React Component
 */
export const BarChartMini = ({ max, data, className }: BarChartMiniProps) => {
  const rootStyle = classnames("code-c-bar-chart-mini", {
    [`${className}`]: Boolean(className),
  });

  const bars = data.map((value, index) => (
    <div
      key={index}
      className="code-c-bar-chart-mini__bar"
      style={{ height: `${Math.floor((value / max) * 100)}%` }}
    />
  ));

  return <div className={rootStyle}>{bars}</div>;
};

BarChartMini.defaultProps = {
  max: 100,
  data: [],
};
