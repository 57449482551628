import { Epic } from "redux-observable";

import {
  Action,
  APIResponseAction,
  ajaxAction,
  alertAction,
  redirectAction,
  challengeCopyAction,
  challengeFiltersGetAction,
  challengeGetAction,
  challengeInsightGetAction,
  challengeListGetAction,
  challengeUpdateAction,
  readmeAction,
  challengeReleaseNoteGetAction,
  challengeMajorUpdateAction,
  challengeCollectionListGetAction,
  challengeCollectionGetAction,
  refreshChallengeListAction,
} from "@actions";

import { RootState } from "@reducers";

import { ChallengeModel, PaginationModel } from "@shared/models";
import { getCurrentProjectId } from "@shared/selectors";
import { ChallengeStyle } from "@shared/services/enums";
import Message, { truncateString } from "@shared/services/message";

interface ChallengeAction extends Action {
  payload: {
    result: {};
  };
}

export const challengeGetEpic: Epic<Action, RootState> = (action$, state$) =>
  action$.ofType(challengeGetAction.types.request).map((action) =>
    ajaxAction.request({
      method: "get",
      url: `/api/projects/${getCurrentProjectId(state$)}/challenges/${
        action.payload
      }`,
      body: action.params,
      success: challengeGetAction.success,
      error: challengeGetAction.failure,
    }),
  );

const challengeUpdateEpic: Epic<Action, RootState> = (action$, state$) =>
  action$.ofType(challengeUpdateAction.types.request).map((action) =>
    ajaxAction.request({
      method: "put",
      url: `/api/projects/${getCurrentProjectId(state$)}/challenges/${
        action.payload
      }`,
      body: action.params,
      success: alertAction.success(
        Message.getMessageByKey("message.challenge.update.success"),
        challengeUpdateAction.success,
      ),
      error: alertAction.error(
        Message.getMessageByKey("message.challenge.update.failed"),
        challengeUpdateAction.failure,
      ),
    }),
  );

const challengeSetEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(challengeGetAction.types.success).map((action) => {
    const challenge = new ChallengeModel(
      (action as ChallengeAction).payload.result,
    );
    if (ChallengeStyle.hasReadme(challenge.style)) {
      return readmeAction.request(
        challenge.id,
        challenge.currentVersion.readmeUrl || "",
      );
    } else {
      return { type: "@null" };
    }
  });

const challengeListGetEpic: Epic<Action, RootState> = (action$, state$) =>
  action$
    .ofType(challengeListGetAction.types.request)
    .debounceTime(275)
    .map((action) => {
      // TODO strong type someday...
      const { onlyPinned, ...rest } = action.payload as {
        onlyPinned?: string;
      };
      const body = {
        ...rest,
        // it needs to set project id together when onlyFavorite is true
        ...(onlyPinned === "true"
          ? {
              onlyFavorite: true,
              challengeFavoriteChallengeIds: state$
                .getState()
                .challenge.pinnedChallengeList.map((item) => item.challengeId),
            }
          : {}),
      };
      return ajaxAction.request({
        method: "post",
        url: `/api/projects/${getCurrentProjectId(state$)}/challenges`,
        body,
        success: challengeListGetAction.success,
        cancel: [challengeListGetAction.types.request],
      });
    });

const challengeFiltersGetEpic: Epic<Action, RootState> = (action$, state) =>
  action$
    .ofType(challengeFiltersGetAction.types.request)
    .filter(
      () =>
        !state.getState().challenge.challengeFilters.programmingCategories
          .length,
    )
    .flatMap(() => [
      ajaxAction.request({
        method: "get",
        url: "/api/enum/challenges/programmingcategories",
        success: (payload: {}) =>
          challengeFiltersGetAction.success({
            extra: "programmingCategories",
            ...payload,
          }),
      }),
      ajaxAction.request({
        method: "get",
        url: "/api/enum/challenges/programminglanguages",
        success: (payload: {}) =>
          challengeFiltersGetAction.success({
            extra: "programmingLanguages",
            ...payload,
          }),
      }),
      ajaxAction.request({
        method: "get",
        url: "/api/enum/challenges/difficulties",
        success: (payload: {}) =>
          challengeFiltersGetAction.success({
            extra: "difficulties",
            ...payload,
          }),
      }),
      ajaxAction.request({
        method: "get",
        url: "/api/enum/challenges/styles",
        success: (payload: {}) =>
          challengeFiltersGetAction.success({ extra: "styles", ...payload }),
      }),
    ]);

const challengeInsightGetEpic: Epic<Action, RootState> = (action$, state$) =>
  action$.ofType(challengeInsightGetAction.types.request).map((action) =>
    ajaxAction.request({
      method: "get",
      url: `/api/projects/${getCurrentProjectId(state$)}/insight/${
        action.payload
      }`,
      success: challengeInsightGetAction.success,
      error: challengeInsightGetAction.failure,
    }),
  );

const challengeCopyRequestEpic: Epic<Action, RootState> = (action$, state$) =>
  action$.ofType(challengeCopyAction.types.request).map((action) => {
    const challenge = new ChallengeModel((action as APIResponseAction).payload);
    const body = Object.assign({}, challenge, {
      title: truncateString(
        `[${Message.getMessageByKey("copy.label")}] ${challenge.title}`,
        255,
      ),
      questionIds: challenge.questions.map((question) => question.id),
      basicTimeMinutes: challenge.currentVersion.basicTimeMinutes,
      difficulty: challenge.currentVersion.difficulty,
      // remove unnecessary data
      id: undefined,
      organizationId: undefined,
      isOfficial: undefined,
      tags: undefined,
      questions: undefined,
      currentVersion: undefined,
    });

    return ajaxAction.request({
      method: "post",
      url: `/api/projects/${getCurrentProjectId(state$)}/challenges/question`,
      body,
      success: alertAction.success(
        Message.getMessageByKey("message.challenge.copy.success"),
        challengeCopyAction.success,
      ),
      error: challengeCopyAction.failure,
    });
  });

export const challengeReleaseNoteGetEpic: Epic<Action, RootState> = (action$) =>
  action$.ofType(challengeReleaseNoteGetAction.types.request).map((action) => {
    // TODO apply strong type
    const { challengeId, versionCode } = (
      action as Action & {
        params: { challengeId: number; versionCode?: string };
      }
    ).params;
    return ajaxAction.request({
      method: "get",
      body: { laterThan: versionCode },
      url: `/api/challenges/${challengeId}/releases`,
      success: challengeReleaseNoteGetAction.success,
      error: challengeReleaseNoteGetAction.failure,
    });
  });

export const challengeMajorUpdateActionEpic: Epic<Action, RootState> = (
  action$,
  state$,
) =>
  action$.ofType(challengeMajorUpdateAction.types.request).map((action) => {
    // TODO string type
    const {
      payload: { versionNumber },
      params: { challengeId },
    } = action as Action & {
      params: { challengeId: number };
      payload: { versionNumber: string };
    };
    return ajaxAction.request({
      method: "post",
      url: `/api/projects/${getCurrentProjectId(state$)}/exams/${
        state$.getState().exam.examDetails.id
      }/challenges/${challengeId}/up`,
      body: { versionNumber },
      success: alertAction.success(
        Message.getMessageByKey("message.challenge.version.updated"),
        challengeMajorUpdateAction.success,
      ),
      error: (error: string) =>
        alertAction.error(
          Message.getMessageByKey(
            "message.challenge.version.updated.dupplicated",
          ),
          () => challengeMajorUpdateAction.failure(error),
        )(error),
      options: {
        disableDefaultError: true,
      },
    });
  });

// TODO: migrate challenge update along with exam state
// export const postChallengeMajorUpdateActionEpic: Epic<Action, RootState> = (
//   action$,
//   state$,
// ) =>
//   action$.ofType(challengeMajorUpdateAction.types.success).map(() => {
//     // sync up latest exam
//     const { id } = state$.getState().exam.examDetails;
//     return examGetAction.request(id);
//   });

export const challengeCollectionListGetEpic: Epic<Action, RootState> = (
  action$,
  state$,
) =>
  action$.ofType(challengeCollectionListGetAction.types.request).map((action) =>
    ajaxAction.request({
      method: "get",
      url: `/api/projects/${getCurrentProjectId(state$)}/challengecollections`,
      body: action.payload,
      success: challengeCollectionListGetAction.success,
      error: challengeCollectionListGetAction.failure,
      cancel: [challengeCollectionListGetAction.types.request],
    }),
  );

export const challengeCollectionGetEpic: Epic<Action, RootState> = (
  action$,
  state$,
) =>
  action$.ofType(challengeCollectionGetAction.types.request).map((action) =>
    ajaxAction.request({
      method: "get",
      url: `/api/projects/${getCurrentProjectId(state$)}/challengecollections/${
        action.payload
      }`,
      body: action.params,
      success: challengeCollectionGetAction.success,
      error: challengeCollectionGetAction.failure,
    }),
  );

export const challengeListRefreshEpic: Epic<Action, RootState> = (
  action$,
  state$,
) =>
  action$
    .ofType(
      refreshChallengeListAction.types.request,
      // challengeDeleteAction.types.success,
      challengeUpdateAction.types.success,
      // challengeQuestionUpdateAction.types.success, remove after migrate all actions here
    )
    .map(() => {
      const {
        challenge: { challengeListCondition },
      } = state$.getState();
      return challengeListGetAction.request(challengeListCondition);
    });

export const challengeListResetEpic: Epic<Action, RootState> = (
  action$,
  state$,
) =>
  action$.ofType(challengeCopyAction.types.success).mergeMap(() => [
    challengeListGetAction.request({ ...new PaginationModel() }),
    redirectAction(`/p/${getCurrentProjectId(state$)}/challenges`, undefined, {
      resetScroll: true,
    })(),
  ]);

export default [
  challengeCopyRequestEpic,
  challengeFiltersGetEpic,
  challengeGetEpic,
  challengeInsightGetEpic,
  challengeListGetEpic,
  challengeSetEpic,
  challengeUpdateEpic,
  challengeReleaseNoteGetEpic,
  challengeMajorUpdateActionEpic,
  challengeCollectionListGetEpic,
  challengeCollectionGetEpic,
  challengeListRefreshEpic,
  challengeListResetEpic,
];
