import * as classnames from "classnames";
import { isEqual } from "lodash";
import * as React from "react";

import {
  ActionDropdown,
  Button,
  CollapsibleExamChallengeColumn,
  Column,
  HeaderColumn,
  Icon,
  JumpTo,
  Loading,
  PageTitle,
  Restricted,
  SelectItem,
  Table,
  TableHead,
  Tag,
  Tooltip,
  Msg,
} from "@shared/components";
import {
  ChallengeModel,
  ExamChallengeModel,
  ExamModel,
  ReadmeModel,
} from "@shared/models";
import { formatDate } from "@shared/services/date";
import {
  ExamDeliveryKind,
  ProjectRole,
  TierAction,
  UserRole,
} from "@shared/services/enums";
import History from "@shared/services/history";
import Message from "@shared/services/message";
import { getChallengePreviewPath } from "@shared/services/url";

import { ExamChallengeSetHeader } from "../../../examSections/examChallengeSetHeader/ExamChallengeSetHeader";
import OfficialExamDeliver from "../officialExamDeliver/OfficialExamDeliver.connect";

/**
 * Prop interface
 */
export type ExternalProps = {
  isOpen: boolean;
  examId: number;
  onClose: () => void;
};

export type InjectedProps = {
  appBasePath: string;
  challengeDetailList: ChallengeModel[];
  currentProjectId: number;
  examCreateOptions: {
    roleoptions: SelectItem[];
  };
  loadingChallenge: boolean;
  loadingReadme: boolean;
  readmeList: ReadmeModel[];
  submitting: boolean;
  getChallengeDetail: (payload: {}) => void;
  isTierActionAllowed: (tierAction: TierAction) => boolean;
};

export type HookProps = {
  examDetail?: ExamModel;
  loadingExam: boolean;
};
export type OfficialExamPanelProps = ExternalProps & InjectedProps & HookProps;

/**
 * State interface
 */
export interface OfficialExamPanelState {
  isSampleDeliveryOpen: boolean;
  selectedChallenge: ExamChallengeModel;
}

/**
 * Page component
 */
class OfficialExamPanel extends React.Component<
  OfficialExamPanelProps,
  OfficialExamPanelState
> {
  constructor(props: OfficialExamPanelProps) {
    super(props);
    this.state = {
      isSampleDeliveryOpen: false,
      selectedChallenge: new ExamChallengeModel(),
    };
  }

  public shouldComponentUpdate(
    nextProps: OfficialExamPanelProps,
    nextState: OfficialExamPanelState,
  ) {
    return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state);
  }

  public render() {
    const {
      appBasePath,
      challengeDetailList = [],
      examDetail,
      examId,
      isOpen,
      loadingChallenge,
      loadingExam,
      loadingReadme,
      readmeList = [],
      submitting,
      onClose,
      isTierActionAllowed,
    } = this.props;

    const rootStyle = classnames("code-official-exam-panel", {
      "is-open": isOpen,
    });

    const { roleoptions = [] } = this.props.examCreateOptions;
    const roleOptionsByValue = roleoptions.reduce((arr, option) => {
      arr[option.value] = option;
      return arr;
    }, {});

    const challengeContent = (challenge: ExamChallengeModel) => {
      const actions = (
        <Column className="align-right">
          <ActionDropdown>
            <JumpTo
              role="menuitem"
              to={getChallengePreviewPath(
                challenge.challengeId || challenge.id,
                challenge.style,
                {
                  programmingLanguage: (challenge.programmingLanguages ??
                    [])[0],
                  basePath: appBasePath,
                },
              )}
            >
              <Icon type={"external-link"} />
              <Msg id={"preview"} />
            </JumpTo>
          </ActionDropdown>
        </Column>
      );

      const challengeDetail = challengeDetailList.find(
        (item) => item.id === challenge.challengeId,
      );

      const challengeReadme = readmeList.find(
        (item) => item.challengeId === challenge.challengeId,
      );

      return (
        <CollapsibleExamChallengeColumn
          actions={actions}
          challenge={challenge}
          challengeDetail={challengeDetail}
          challengeReadme={challengeReadme}
          key={challenge.challengeId}
          loading={
            (loadingChallenge && !challengeDetail) ||
            (loadingReadme && !challengeReadme)
          }
          getChallengeDetail={this.getChallengeDetail}
        />
      );
    };

    return !examDetail ? (
      <div className={rootStyle}>
        <Loading isOpen={submitting || loadingExam} />
      </div>
    ) : (
      <>
        <div className={rootStyle}>
          <div className="code-official-exam-panel__contents-wrapper">
            <section className="code-official-exam-panel__contents-wrapper__cancel">
              <Tooltip
                text={Message.getMessageByKey(
                  examDetail.language.toLocaleUpperCase() === "EN"
                    ? "exam.officialExam.english"
                    : "exam.officialExam.japanese",
                )}
              >
                <Tag className={"tag-language"}>
                  {examDetail.language.toLocaleUpperCase()}
                </Tag>
              </Tooltip>
              <button
                className="delete is-medium"
                aria-label="close"
                onClick={onClose}
              />
            </section>
            <section className="code-official-exam-panel__contents-wrapper__exam-info">
              <PageTitle>{examDetail.name}</PageTitle>
              <div className="date-info-container">
                <div className="date-info-title">
                  <Msg id="challenge.numberOfOrgsUsing" />:
                </div>
                <div className="date-info-item">
                  {examDetail.usingOrganizationsCount}
                </div>
                <div className="separator-line">|</div>
                <div className="date-info-title">
                  <Msg id="updated" />:
                </div>
                <div className="date-info-item">
                  {formatDate(examDetail.updatedAt)}
                </div>
              </div>
              <div className="exam-info-container">
                <div className="exam-info-container__section">
                  <div className="exam-info-title">
                    <Msg id="exam.officialExam.bestFor" />
                  </div>
                  <div className="exam-info-item-wrapper">
                    <Icon type="check" size="small" />
                    <div className="info-item">
                      {examDetail.examSegment &&
                        roleOptionsByValue[examDetail.examSegment.engineerRole]
                          ?.label}
                    </div>
                  </div>
                </div>
                <div className="exam-info-title">
                  <Msg id="createExam.officialExam.examinationTime" />
                </div>
                <div>
                  {examDetail.examinationTime}
                  <Msg id="unit.mins" />
                </div>
              </div>
            </section>
            <section className="code-official-exam-panel__contents-wrapper__buttons">
              <Restricted
                strictAllow={
                  examDetail.deliveryKind === ExamDeliveryKind.Standard
                }
                roles={[
                  UserRole.SystemAdmin,
                  ProjectRole.ExamCreator,
                  ProjectRole.ProjectAdmin,
                ]}
              >
                <Tooltip
                  text={Message.getMessageByKey(
                    "createExam.officialExam.deliverSample",
                  )}
                >
                  <Button
                    className="sample-test-button"
                    outlined={true}
                    type="primary"
                    onClick={this.openSampleDelivery}
                    ariaLabel="Deliver Sample Exam"
                  >
                    <Icon type="paper-plane" />
                    <Msg id="delivery.test" />
                  </Button>
                </Tooltip>
              </Restricted>
              <Restricted
                roles={[
                  UserRole.SystemAdmin,
                  ProjectRole.ProjectAdmin,
                  ProjectRole.ExamCreator,
                  ProjectRole.ExamDeliverer,
                ]}
              >
                <Tooltip
                  text={Message.getMessageByKey(
                    "createExam.officialExam.copyExam",
                  )}
                  placement="top-end"
                >
                  <Button
                    type="primary"
                    onClick={this.copyOfficialExam}
                    ariaLabel="Copy Exam"
                  >
                    <Icon type="copy" />
                    <Msg id="exam.copyExam" />
                  </Button>
                </Tooltip>
              </Restricted>
            </section>
            <section className="code-official-exam-panel__contents-wrapper__challenge-list">
              {examDetail.challengesSets.map((challengeSet, index) => (
                <div key={index}>
                  <ExamChallengeSetHeader
                    index={index}
                    challengeSet={challengeSet}
                    isTierActionAllowed={isTierActionAllowed}
                  />
                  <Table className="challenge-list-table">
                    <TableHead className="challenge-list-table__head">
                      <HeaderColumn size={3}>
                        <Msg id="common.title" />
                      </HeaderColumn>
                      <HeaderColumn size={1} className="align-right">
                        <Msg id="difficulty" />
                      </HeaderColumn>
                      <HeaderColumn size={1} className="align-right">
                        <Msg id="challenge.style" />
                      </HeaderColumn>
                      <HeaderColumn size={1} className="align-right">
                        <Msg id="timeLimitMinutes" />
                      </HeaderColumn>
                      <HeaderColumn
                        size={1}
                        className="align-right"
                      ></HeaderColumn>
                    </TableHead>
                    {challengeSet.challenges.map((challenge) =>
                      challengeContent(challenge),
                    )}
                  </Table>
                </div>
              ))}
            </section>
          </div>
        </div>
        <OfficialExamDeliver
          isOpen={this.state.isSampleDeliveryOpen}
          examId={examId}
          onClose={this.closeModal}
        />
      </>
    );
  }

  private getChallengeDetail = (challengeId: number) => {
    const hasData = this.props.challengeDetailList.find(
      (challenge) => challenge.id === challengeId,
    );
    if (!hasData) {
      this.props.getChallengeDetail(challengeId);
    }
  };

  private copyOfficialExam = () => {
    const { currentProjectId, examId } = this.props;
    History.push({
      pathname: `/p/${currentProjectId}/exams/new`,
      state: { fromCopy: true, officialExamId: examId },
    });
  };

  private openSampleDelivery = () => {
    this.setState({ isSampleDeliveryOpen: true });
  };

  private closeModal = () => {
    this.setState({ isSampleDeliveryOpen: false });
  };
}

export default OfficialExamPanel;
