import * as classnames from "classnames";
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { Icon } from "../icon";
import SubMenuItem from "../subMenuItem/SubMenuItem";
import SubMenuList from "../subMenuList/SubMenuList";

type SubMenuListToggleItem = {
  label: string;
  route: string;
};

/**
 * Prop interface
 */
interface ExternalProps {
  className?: string;
  ariaLabel?: string;
  defaultExpanded?: boolean;
  expanded?: boolean;
  item: SubMenuListToggleItem;
  subItems?: SubMenuListToggleItem[];
}

type SubMenuListToggleProps = ExternalProps & RouteComponentProps;

/**
 * React Component
 */
function SubMenuListToggle({
  className,
  defaultExpanded,
  expanded,
  item,
  subItems,
  location,
}: SubMenuListToggleProps) {
  const rootStyle = classnames("code-c-sub-menu-list-toggle", "menu-list", {
    [`${className}`]: Boolean(className),
  });

  const [open, setOpen] = React.useState(
    typeof expanded !== "undefined" ? expanded : Boolean(defaultExpanded),
  );

  React.useEffect(() => {
    if (typeof expanded !== "undefined") {
      setOpen(Boolean(expanded));
    }
  }, [expanded]);

  React.useEffect(() => {
    const currentRoute = location.pathname;
    const isInRoute =
      item.route === currentRoute ||
      subItems?.some((subItem) => subItem.route === currentRoute);

    if (isInRoute) {
      setOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <SubMenuList className={rootStyle}>
      <SubMenuItem
        label={item.label}
        route={{ pathname: item.route }}
        endIcon={
          <Icon
            className="code-c-sub-menu-list-toggle__icon"
            type={open ? "chevron-up" : "chevron-down"}
            onClick={(e) => {
              e?.preventDefault();
              setOpen(!open);
            }}
          />
        }
      />
      {open && Boolean(subItems?.length) && (
        <SubMenuList>
          {subItems?.map((subItem) => (
            <SubMenuItem
              key={subItem.label}
              label={subItem.label}
              route={{ pathname: subItem.route }}
            />
          ))}
        </SubMenuList>
      )}
    </SubMenuList>
  );
}

export default withRouter(SubMenuListToggle);
