// Note: This is the old version of RichMarkdown component for use with the old Form component.
// There is a new RichMarkdown component for use with ReactHookForm in rfcComponents.
import * as classnames from "classnames";
import * as React from "react";

import { Markdown, Textarea, Icon, ValidationMessage, Msg } from "..";

/**
 * Prop interface
 */
export interface RichMarkdownProps {
  className?: string;
  disabled?: boolean;
  error?: { [key: string]: string };
  name?: string;
  onBlur?: (e: React.FormEvent<HTMLTextAreaElement>) => void;
  onChange?: (e: React.FormEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  preview?: boolean;
  readOnly?: boolean;
  value?: string;
}

/**
 * State interface
 */
export interface RichMarkdownState {
  onPreview: boolean;
}

/**
 * React Component
 */
export default class RichMarkdown extends React.Component<
  RichMarkdownProps,
  RichMarkdownState
> {
  constructor(props: RichMarkdownProps) {
    super(props);
    this.state = { onPreview: false };
  }

  public componentDidUpdate(prevProps: RichMarkdownProps) {
    if (this.props.preview && !prevProps.preview) {
      this.setState({
        onPreview: false,
      });
    }
  }

  public render() {
    const {
      className,
      disabled,
      error,
      name,
      onBlur,
      onChange,
      placeholder,
      preview,
      readOnly,
      value,
    } = this.props;
    const { onPreview } = this.state;

    const rootStyle = classnames("code-c-rich-markdown", {
      [`${className}`]: Boolean(className),
      "is-preview": preview,
      "on-preview": onPreview,
      "on-edit": !onPreview,
    });

    return (
      <div className={rootStyle}>
        {preview || (
          <div className="code-c-rich-markdown__header">
            <div className="code-c-rich-markdown__header__tabs">
              <div
                className="code-c-rich-markdown__header__tabs__edit"
                onClick={() => this.onClick(false)}
              >
                <Icon type="pencil" size="medium" />
                <Msg id="edit" />
              </div>
              <div
                className="code-c-rich-markdown__header__tabs__preview"
                onClick={() => this.onClick(true)}
              >
                <Icon type="eye" size="medium" />
                <Msg id="preview" />
              </div>
            </div>
          </div>
        )}
        <div className="code-c-rich-markdown__body">
          {preview || onPreview ? (
            <Markdown body={value || ""} />
          ) : (
            <Textarea
              value={value}
              disabled={disabled}
              error={error}
              name={name}
              onBlur={onBlur}
              onChange={onChange}
              placeholder={placeholder}
              readOnly={readOnly}
            />
          )}
        </div>
        <ValidationMessage name={name} error={error} />
      </div>
    );
  }

  private onClick = (onPreview: boolean) => {
    this.setState({ onPreview });
  };
}
