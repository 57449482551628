import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { deleteChallenge, getChallenge } from "@api/challenges";

import { useStoreContext } from "@context";

import { ChallengeModel } from "@shared/models";
import Message from "@shared/services/message";

interface ChallengeKeyParams {
  challengeId?: number;
  challengeVersionId?: number;
}

export const challengeKeys = {
  all: ["challenge"] as const,
  challenge: ({
    projectId,
    challengeId,
    challengeVersionId,
  }: ChallengeKeyParams & { projectId: number }) => {
    const keys = [...challengeKeys.all, projectId];
    if (challengeId) {
      keys.push(challengeId);

      if (challengeVersionId) {
        keys.push(challengeVersionId);
      }
    }

    return keys;
  },
  delete: () => [...challengeKeys.all, "delete"] as const,
};

interface ChallengeParams {
  challengeId: number;
  challengeVersionId: number;
  enabled?: boolean;
}

export function useGetChallenge({
  challengeId,
  challengeVersionId,
  enabled = true,
}: ChallengeParams) {
  const { projectId } = useStoreContext();

  const query = useQuery<ChallengeModel>({
    queryKey: challengeKeys.challenge({
      projectId,
      challengeId,
      challengeVersionId,
    }),
    enabled,
    queryFn: async ({ signal }) => {
      const { result } = await getChallenge({
        projectId,
        challengeId,
        challengeVersionId,
        options: {
          signal,
        },
      });

      return new ChallengeModel(result);
    },
    keepPreviousData: true,
  });

  return query;
}

export function useDeleteChallenge() {
  const client = useQueryClient();
  const { projectId, refreshChallengeList } = useStoreContext();

  const mutation = useMutation({
    mutationKey: challengeKeys.delete(),
    mutationFn: (challengeId: number) =>
      deleteChallenge({ projectId, challengeId }),
    onSuccess: (_, challengeId) => {
      toast.success(
        Message.getMessageByKey("message.challenge.delete.success"),
      );
      client.removeQueries(challengeKeys.challenge({ projectId, challengeId }));
      refreshChallengeList();
    },
    onError: () => {
      toast.error(Message.getMessageByKey("message.challenge.delete.failed"));
    },
  });

  return mutation;
}
