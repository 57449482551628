import * as classnames from "classnames";
import ReactPaginate from "react-paginate";

import { PaginationModel } from "../../models";
import Message from "../../services/message";

/**
 * Prop interface
 */
export interface PaginationProps {
  pagination: PaginationModel;
  onPageChange?: (page: { selected: number }) => void;
  className?: string;
}

/**
 * React Component
 */
export default function Pagination({
  pagination,
  onPageChange,
  className,
}: PaginationProps) {
  const rootStyle = classnames({
    [`${className}`]: Boolean(className),
  });

  if (!pagination || !pagination.count || pagination.totalPageNo === 1) {
    return null;
  }

  return (
    <div role="navigation" aria-label="Pagination" className={rootStyle}>
      <ReactPaginate
        pageCount={pagination.totalPageNo}
        pageRangeDisplayed={3}
        marginPagesDisplayed={3}
        forcePage={pagination.currentPage}
        previousLabel={Message.getMessageByKey("action.prev")}
        nextLabel={Message.getMessageByKey("action.next")}
        onPageChange={onPageChange}
        containerClassName="code-c-pagination"
        pageClassName="code-c-pagination__link"
        activeClassName="is-current"
        breakClassName="code-c-pagination__break"
        previousClassName="code-c-pagination__previous"
        nextClassName="code-c-pagination__next"
        disabledClassName="is-disabled"
      />
    </div>
  );
}
