import { isEqual } from "lodash";
import { IntlShape } from "react-intl";
import { z } from "zod";

import {
  ExamModel,
  ExamChallengeSetModel,
  ExamChallengeModel,
  UrlSharingConfigModel,
  IdDeliveryConfigModel,
  MemberListModel,
  ApplicantActionSettingsModel,
  AutoFilterSettingsModel,
  GuestSharingSettingsModel,
  WebcamSettingsModel,
} from "@shared/models";
import {
  dayjs,
  isValidDate_datepicker,
  formatDateTimeMinutes,
} from "@shared/services/date";
import {
  AutoFilterType,
  ChallengeStatus,
  ChallengeStyle,
  ExamDeliveryKind,
  WebcamSettings,
} from "@shared/services/enums";
import { isZero } from "@shared/services/math";
import Message from "@shared/services/message";

import {
  ChallengesSet,
  Form,
  GuestSharingSettings,
} from "../../examCreate/types";
import { ExamSettingsFormValidation } from "../examSettingsNew/utils";

export const MAX_CODE_PLAYBACK_AND_WEBCAM_MINUTES = 180;

/**
 * Check if the total weight in the exam is valid
 * @param challengesSets
 */
export function isTotalWeightValid(
  challengesSets: ExamChallengeSetModel[] = [],
) {
  if (challengesSets.length === 0) {
    return "";
  }
  let hasChallenge = false;
  const totalWeight = challengesSets.reduce((total, challengeSet) => {
    const { isOptionalSet, numberChallengesToTake } = challengeSet;
    // numberChallengesToTake = 0 means no weight
    if (isOptionalSet && numberChallengesToTake === 0) {
      return total + 0;
    }
    return (
      total +
      challengeSet.challenges.reduce((subTotal, challenge) => {
        if (!hasChallenge) {
          hasChallenge = true;
        }
        return subTotal + challenge.weight;
      }, 0)
    );
  }, 0);
  return totalWeight === 0 && hasChallenge
    ? "validation.challenges.minimumTotalWeight"
    : "";
}

/**
 * Return the message key if the exam challenge count is not valid
 * @param challengeSet
 */
export function isChallengeSetCountValid(challengeSet: ExamChallengeSetModel): {
  [key: string]: string;
} {
  const {
    challenges,
    isRandomSet,
    isOptionalSet,
    numberChallengesToTake = 0,
  } = challengeSet;
  if (challenges.length > 100) {
    return { ["challengeSetCount"]: "validation.challenges.max-100" };
  }
  // for random
  if (isRandomSet && challenges.length < 2) {
    return { ["challengeSetCount"]: "validation.challenges.atLeast-2" };
  }
  // for required and optional
  if (challenges.length < 1) {
    return { ["challengeSetCount"]: "validation.challenges.atLeast-1" };
  }

  // for optional, the numberChallengesToTake must be less and equal than total challenge count.
  if (isOptionalSet && challenges.length <= numberChallengesToTake) {
    return {
      ["numberChallengesToTake"]: "validation.numberChallengesToTake.max",
    };
  }
  return {};
}

/**
 * Return the message key if the exam challenge set count is not valid
 * @param challengesSets
 */
export function isExamChallengeSetCountValid(
  challengesSets: ExamChallengeSetModel[] = [],
) {
  if (challengesSets.length === 0) {
    return "exam.minimumChallengeSetCount";
  }
  if (challengesSets.length > 10) {
    return "exam.maximumChallengeSetCount";
  }
  return "";
}

/**
 * Re-ordering display order
 * it should be unique in the all challenge set
 * NOTE: Challenge set index starts with 1, but challenge index starts with 0.
 * @param challengesSets
 */
export function updateDisplayOrder(challengesSets: ExamChallengeSetModel[]) {
  let challengeIndex = 0;
  return challengesSets.map((challengeSet, challengeSetIndex) => {
    const challenges = challengeSet.challenges.map((challenge) => {
      return {
        ...challenge,
        ...{ displayOrder: challengeIndex++ },
      };
    });
    return {
      ...challengeSet,
      ...{ challenges, displayOrder: challengeSetIndex + 1 },
    };
  });
}

/**
 * get weight from the first list of challenge
 * NOTE: for random/optional challenge set, it should have the same weight
 * @param challengeSet
 */
export function getChallengeSetWeight(challengeSet: ExamChallengeSetModel) {
  const {
    isOptionalSet,
    isRandomSet,
    challenges,
    weightPlaceholderForNoChallenge,
  } = challengeSet;
  if (isOptionalSet || isRandomSet) {
    if (
      challenges.length === 0 &&
      weightPlaceholderForNoChallenge !== undefined
    ) {
      return weightPlaceholderForNoChallenge;
    }
    return challenges.length > 0 && challenges[0].weight !== undefined
      ? challenges[0].weight
      : 1;
  } else {
    return 1;
  }
}

/**
 * Check if all challenges in the exam not duplicated
 * @param challengesSets
 */
export function isAllChallengesNotDuplicated(
  challengesSets: ExamChallengeSetModel[],
) {
  const challengeIds = challengesSets.reduce((total, challengeSet) => {
    const ids = challengeSet.challenges.map(
      (challenge) => challenge.challengeId,
    );
    return [...total, ...ids];
  }, []);
  return challengeIds.length === Array.from(new Set(challengeIds)).length;
}

/**
 * Switch to linked challenge
 * @param challenge
 */
export function switchLinkedChallenge(challenge: ExamChallengeModel) {
  // NOTE: the listChallenge in the exam challenge model comes from originalChallenge when it is edit mode.
  const linkedChallenge =
    challenge.linkedChallenge || challenge.originalChallenge.linkedChallenge;
  if (!linkedChallenge) {
    throw new Error(`Challenge is not linked. challengeId=${challenge.id}`);
  }
  const { weight } = challenge;
  const {
    id,
    isOfficial,
    title,
    description,
    currentVersion,
    status,
    programmingLanguages,
    style,
    language,
  } = linkedChallenge;

  return challenge.originalLinkedChallenge
    ? // revert to original challenge
      new ExamChallengeModel({
        ...challenge.originalLinkedChallenge,
        ...{ weight },
      })
    : // switch to linked challenge
      new ExamChallengeModel({
        id,
        challengeId: id,
        timeLimitMinutes: currentVersion.basicTimeMinutes,
        title,
        originalChallenge: {
          title,
          programmingLanguages,
          language,
          status,
        },
        description,
        programmingLanguages,
        weight,
        style,
        difficulty: currentVersion.difficulty,
        isOfficial,
        basicTimeMinutes: currentVersion.basicTimeMinutes,
        linkedChallenge: {
          title: challenge.title,
          language: language === "ja" ? "en" : "ja",
          status: ChallengeStatus.Ready,
        },
        usedChallengeVersionCode: `${currentVersion.majorVersionNumber}.${currentVersion.minorVersionNumber}`,
        // cache original exam challenge model to revert
        originalLinkedChallenge: challenge,
      });
}
/**
 *
 * @param fromCopy
 * @param examDetail
 */
export function prepareFormValues({
  hasReviewFeature,
  examDetail,
}: {
  hasReviewFeature: boolean;
  examDetail: ExamModel;
}) {
  const {
    id: originalOfficialExamId,
    name,
    description,
    challengesSets,
    language,
    examSegment,
  } = examDetail;

  const defaultGuestSharingSettings: GuestSharingSettings = {
    showSecretTestcases: false,
    showName: false,
    showEntryFormResponses: false,
    showReviews: false,
    showInsights: false,
    showSubmissionDetails: false,
    showActionLog: false,
    showNote: false,
  };

  const defaultWebcamSettings = {
    enabled: false,
    livenessVerification: WebcamSettings.optional,
  };

  const formValues: Partial<Form> = {
    name,
    engineerRole:
      `${examSegment?.engineerRole}` === "0"
        ? ""
        : `${examSegment?.engineerRole}` || "",
    purposeOfUse:
      examSegment?.purposeOfUse === "not-defined"
        ? ""
        : examSegment?.purposeOfUse || "",
    description,
    challengesSets: challengesSets as unknown as ChallengesSet[],
    language,
    originalOfficialExamId,
    guestSharingSettings: defaultGuestSharingSettings,
    webcamSettings: defaultWebcamSettings,
  };

  return {
    formValues,
    stepValid: [...new Array(hasReviewFeature ? 5 : 4)].fill(true),
  };
}

export type ExamSettingsInitialFormValues = {
  language: "en" | "ja";
  guestSharingEnabled: boolean;
  showSecretTestcases?: boolean;
  guestSharingShowReviews?: boolean;
  guestSharingShowName?: boolean;
  guestSharingShowInsights?: boolean;
  guestSharingSettings: GuestSharingSettingsModel;
  webcamSettings: WebcamSettingsModel;
  deliveryKind: ExamDeliveryKind;
  deadlineNotificationEnabled: boolean;
  urlDeliveryEnabled: boolean;
  urlSharingConfig: UrlSharingConfigModel;
  idDeliveryConfig: IdDeliveryConfigModel;
  urlToken?: string;
  atsIntegrationEnabled: boolean;
  atsDynamicDeadlineDays?: number;
  memo?: string;
  isOfficial: boolean;
  coverImageUrl?: string;
  domainWhitelist?: string;
  applicantActionSettings: ApplicantActionSettingsModel;
  autoFilterSettings?: AutoFilterSettingsModel;
};

export type ExamSettingsFormValues = {
  language: "en" | "ja";

  deliveryKind: ExamDeliveryKind;
  deadlineNotificationEnabled: boolean;
  urlDeliveryEnabled: boolean;
  startAt?: string;
  endAt?: string;
  dynamicDeadlineHours?: number;
  idStartAt?: string;
  idEndAt?: string;
  idDynamicDeadlineHours?: number;
  passwordRequired?: boolean;
  urlToken?: string;
  atsIntegrationEnabled: boolean;
  atsDynamicDeadlineDays?: number;

  // guest sharing settings
  guestSharingEnabled: boolean;
  showSecretTestcases?: boolean;
  guestSharingShowReviews?: boolean;
  guestSharingShowName?: boolean;
  guestSharingShowInsights?: boolean;
  guestSharingShowEntryFormResponses?: boolean;
  guestSharingShowSubmissionDetails?: boolean;
  guestSharingShowActionLog?: boolean;
  guestSharingShowNote?: boolean;

  // applicantActionSettings
  showKeyEvents: boolean;
  showCopyPasteEvents: boolean;
  showTabChangeEvents: boolean;
  showScreenshotEvents: boolean;

  // webcamSettings
  webcamEnabled: boolean;
  webcamLivenessRequired: boolean;

  autoFilterEnabled: boolean;
  filterType: AutoFilterType;
  passingScore?: number;
  failingScore?: number;
  memo?: string;
  isOfficial: boolean;
  coverImageUrl?: string;
  domainWhitelist?: string;
};

export const defaultFormValuesForSettings: ExamSettingsFormValues =
  Object.freeze({
    language: "ja",
    deliveryKind: ExamDeliveryKind.Standard,
    deadlineNotificationEnabled: true,
    urlDeliveryEnabled: false,
    atsIntegrationEnabled: false,

    // guestSharingSettings
    guestSharingEnabled: false,
    showSecretTestcases: false,
    guestSharingShowReviews: false,
    guestSharingShowName: false,
    guestSharingShowInsights: false,
    guestSharingShowEntryFormResponses: false,
    guestSharingShowSubmissionDetails: false,
    guestSharingShowActionLog: false,
    guestSharingShowNote: false,

    // webcamSettings
    webcamEnabled: false,
    webcamLivenessRequired: false,

    // applicantActionSettings
    showKeyEvents: false,
    showCopyPasteEvents: false,
    showTabChangeEvents: false,
    showScreenshotEvents: false,

    isOfficial: false,
    autoFilterEnabled: false,
    filterType: AutoFilterType.AutoPassFail,
  });

export const defaultValidationRuleForSettings = Object.freeze({
  language: ["string", "required"],
  guestSharingEnabled: ["boolean"],
  deadlineNotificationEnabled: ["boolean"],
  deliveryKind: ["string", "required"],
  urlDeliveryEnabled: ["boolean"],
  atsIntegrationEnabled: ["boolean"],

  // applicantActionSettings
  showKeyEvents: ["boolean"],
  showCopyPasteEvents: ["boolean"],
  showTabChangeEvents: ["boolean"],
  showScreenshotEvents: ["boolean"],

  // webcamSettings
  webcamEnabled: ["boolean"],

  autoFilterEnabled: ["boolean"],
});

export const dynamicDeadlineValidationRule = [
  "number",
  "integer",
  ["min", 1],
  ["max", 365],
];

/**
 * get form values for exam settings
 */
export function getFormValuesForSettings(
  values?: Partial<ExamSettingsInitialFormValues>,
): ExamSettingsFormValues {
  if (!values) {
    return defaultFormValuesForSettings;
  }
  const {
    urlSharingConfig = new UrlSharingConfigModel(),
    idDeliveryConfig = new IdDeliveryConfigModel(),
    autoFilterSettings,
    guestSharingSettings,
    webcamSettings,
    guestSharingEnabled,
    ...dest
  } = values;

  const { startAt, endAt, dynamicDeadlineHours, domainWhitelist } =
    urlSharingConfig;
  const {
    startAt: idStartAt,
    endAt: idEndAt,
    dynamicDeadlineHours: idDynamicDeadlineHours,
    passwordRequired,
  } = idDeliveryConfig;

  const {
    enabled: webcamEnabled,
    livenessVerification = WebcamSettings.optional,
  } = webcamSettings || {};

  return {
    ...defaultFormValuesForSettings,
    ...dest,
    ...{
      startAt,
      endAt,
      dynamicDeadlineHours,
      domainWhitelist: domainWhitelist?.join("\r\n") ?? "",
      idStartAt,
      idEndAt,
      idDynamicDeadlineHours,
      passwordRequired,
    },
    ...getAutoFilterFormValuesFromSettings(autoFilterSettings),
    ...getGuestSharingFormValuesFromSettings({
      guestSharingEnabled,
      guestSharingSettings,
    }),
    webcamEnabled: webcamEnabled ?? false,
    webcamLivenessRequired: livenessVerification === WebcamSettings.required,
  };
}

function getValidationRulesForAutoFilter({
  autoFilterEnabled,
  filterType,
  passingScore,
  failingScore,
}: {
  autoFilterEnabled?: boolean;
  filterType?: AutoFilterType;
  passingScore?: number;
  failingScore?: number;
}) {
  if (!autoFilterEnabled || !filterType) {
    return {};
  }

  return {
    filterType: ["string", "required"],
    passingScore: [
      "number",
      "integer",
      ["min", 0],
      ...(filterType === AutoFilterType.AutoPassFailReview &&
      failingScore !== undefined &&
      failingScore >= 0 &&
      failingScore < 100
        ? [["greater", failingScore]]
        : []),
      ["max", 100],
      ...(filterType === AutoFilterType.AutoPassFailReview &&
      failingScore === 100 &&
      isZero(passingScore)
        ? [["greater", 0]]
        : []),
      ...(filterType === AutoFilterType.AutoPassFail ||
      failingScore === undefined
        ? ["required"]
        : []),
    ],
    ...(filterType === AutoFilterType.AutoPassFailReview
      ? {
          failingScore: [
            "number",
            "integer",
            ["min", 0],
            ["max", 100],
            ...(passingScore !== undefined &&
            passingScore > 0 &&
            passingScore <= 100
              ? [["less", passingScore]]
              : []),
            ...(filterType === AutoFilterType.AutoPassFailReview &&
            failingScore === 100 &&
            isZero(passingScore)
              ? [["less", 100]]
              : []),
            ...(passingScore === undefined ? ["required"] : []),
          ],
        }
      : {}),
  };
}

/**
 * get validation rule for exam settings
 * @param values
 */
export function getValidationRuleForSettings({
  values = {},
  isIntegrateATSAllowed = true,
  isDeliverByUrlAllowed = true,
  isGuestShareAllowed = true,
}: {
  values?: Partial<ExamSettingsFormValues>;
  isIntegrateATSAllowed?: boolean;
  isDeliverByUrlAllowed?: boolean;
  isGuestShareAllowed?: boolean;
}) {
  const {
    deliveryKind,
    urlDeliveryEnabled,
    startAt,
    endAt,
    idStartAt,
    idEndAt,
    autoFilterEnabled,
    filterType,
    passingScore,
    failingScore,
  } = values;

  let extraValidationRule = {
    guestSharingEnabled: [
      "boolean",
      ...(isGuestShareAllowed ? [] : ["equal", "false"]),
    ],
    ...(isIntegrateATSAllowed
      ? { atsDynamicDeadlineDays: dynamicDeadlineValidationRule }
      : undefined),
  };

  extraValidationRule = {
    ...extraValidationRule,
    ...getValidationRulesForAutoFilter({
      autoFilterEnabled,
      filterType,
      failingScore,
      passingScore,
    }),
  };

  function setNowOrFutureDate(startAt: string) {
    return startAt < dayjs().format()
      ? dayjs().format()
      : dayjs(startAt).format();
  }

  // NOTE: deliveryKind might be a string
  switch (parseInt((deliveryKind || 0).toString(), 10)) {
    case ExamDeliveryKind.Standard: {
      extraValidationRule = {
        ...extraValidationRule,
        ...{
          urlDeliveryEnabled: [
            "boolean",
            ...(isDeliverByUrlAllowed ? [] : ["equal", "false"]),
          ],
        },
      };

      if (urlDeliveryEnabled) {
        extraValidationRule = {
          ...extraValidationRule,
          ...{
            startAt: [
              ...["date", "iso", "required"],
              ...(isValidDate_datepicker(endAt)
                ? [["max", dayjs(endAt).format()]]
                : []),
            ],
            endAt: [
              ...["date", "iso", "required", "notAllowPast"],
              ...(isValidDate_datepicker(startAt)
                ? [
                    ["min", startAt && setNowOrFutureDate(startAt)],
                    [
                      "within10YearsFrom",
                      startAt && setNowOrFutureDate(startAt),
                    ],
                  ]
                : []),
            ],
            dynamicDeadlineHours: dynamicDeadlineValidationRule,
          },
        };
      }
      return { ...defaultValidationRuleForSettings, ...extraValidationRule };
    }
    case ExamDeliveryKind.ID: {
      extraValidationRule = {
        ...extraValidationRule,
        ...{
          idStartAt: [
            ...["date", "iso", "required"],
            ...(isValidDate_datepicker(idEndAt)
              ? [["max", dayjs(idEndAt).format()]]
              : []),
          ],
          idEndAt: [
            ...["date", "iso", "required", "notAllowPast"],
            ...(isValidDate_datepicker(idStartAt)
              ? [
                  ["min", idStartAt && setNowOrFutureDate(idStartAt)],
                  [
                    "within10YearsFrom",
                    idStartAt && setNowOrFutureDate(idStartAt),
                  ],
                ]
              : []),
          ],
          idDynamicDeadlineHours: dynamicDeadlineValidationRule,
          passwordRequired: ["boolean"],
        },
      };
      return { ...defaultValidationRuleForSettings, ...extraValidationRule };
    }
    default:
      return defaultValidationRuleForSettings;
  }
}
export function mergeReviewer(
  selectedReviewers: MemberListModel[] = [],
  currentReviewers: MemberListModel[] = [],
  append = false,
) {
  let merged = [...currentReviewers];
  if (append) {
    selectedReviewers.forEach((reviewer) => {
      const index = currentReviewers.findIndex(
        (item) => item.id === reviewer.id,
      );
      if (index === -1) {
        merged = [...merged, reviewer];
      }
    });
    return merged;
  } else {
    return merged.filter(
      (reviewer) =>
        selectedReviewers.findIndex((item) => item.id === reviewer.id) === -1,
    );
  }
}

export function isAllReviewerSelected(
  selectedReviewers: MemberListModel[] = [],
  currentReviewers: MemberListModel[] = [],
) {
  return (
    selectedReviewers.length > 0 &&
    currentReviewers.length > 0 &&
    currentReviewers.every(
      (reviewer) =>
        selectedReviewers.findIndex((item) => item.id === reviewer.id) > -1,
    )
  );
}

/**
 * Return a message if the challenge time limit minutes is not valid
 * The validation is same as ["number", "integer", ["min", 1], ["max", 999999]] for Form component
 * @param timeLimitMinutes
 */
export function getTimeLimitMinutesErrorMessage(
  timeLimitMinutes: string | undefined,
) {
  if (timeLimitMinutes === undefined) {
    return "";
  }
  if (!Number.isInteger(Number(timeLimitMinutes))) {
    return Message.getMessageByKey("validation.number.integer");
  }
  if (Number(timeLimitMinutes) < 1) {
    return Message.getMessageByKey("validation.numeric.greaterEqual-1");
  }
  if (Number(timeLimitMinutes) > 999999) {
    return Message.getMessageByKey("validation.number.max").replace(
      "{0}",
      "999999",
    );
  }
  return "";
}

/**
 * Check if changing the Challenge affects scoring
 * @param before
 * @param after
 */
export function scoringHasChanged(
  before: {
    id: number;
    weight: number;
    numberChallengesToTake?: number;
    randomQuiz?: boolean;
  }[][],
  after: {
    id: number;
    weight: number;
    numberChallengesToTake?: number;
    randomQuiz?: boolean;
  }[][],
): boolean {
  const transform = (value: { id: number; weight: number }[][]) => {
    // sort by id
    let transformed = value.map((item) =>
      item.length > 0 ? item.sort((a, b) => a.id - b.id) : item,
    );
    // sort by item length
    transformed = transformed.sort((a, b) =>
      a.length - b.length !== 0 ? a.length - b.length : a[0]?.id - b[0]?.id,
    );
    return transformed;
  };
  return !isEqual(transform(before), transform(after));
}

/**
 * Return message key for challenge set
 * @param isRandomSet
 * @param isOptionalSet
 */
export function getChallengeSetMessageKey(
  isRandomSet?: boolean,
  isOptionalSet?: boolean,
) {
  if (isRandomSet) {
    return "exam.randomChallenges";
  } else if (isOptionalSet) {
    return "exam.optionalChallenges";
  } else {
    return "exam.requiredChallenges";
  }
}

/**
 * Format delivery email template
 * @param template
 * @param options
 */
export function formatDeliveryEmailTemplate(
  template: string,
  options: { body?: string; deadline?: string; token?: string },
): string {
  const { body = "", deadline = "", token = "" } = options;
  return template
    .replace("@{applicant.email}", `<${Message.getMessageByKey("email")}>`)
    .replace(
      "@{customBody}",
      body || `<${Message.getMessageByKey("delivery.body")}>`,
    )
    .replace(
      "@{deadline}",
      deadline && dayjs(deadline).isValid()
        ? formatDateTimeMinutes(deadline, true)
        : `<${Message.getMessageByKey("delivery.deadline")}>`,
    )
    .replace("@{applicantExam.urlToken}", token);
}

/**
 * Replase line breaks to \n char
 * @param value
 */
export function formatApplicantCSV(value = ""): string {
  return value.trim().replace(/(\r?\n)+/g, "\n");
}

export function getAutoFilterFormValuesFromSettings(
  autoFilterSettings?: AutoFilterSettingsModel,
) {
  const autoFilterEnabled = Boolean(autoFilterSettings);
  const filterType =
    autoFilterSettings?.filterType ?? AutoFilterType.AutoPassFail;
  const passingScore =
    filterType === AutoFilterType.AutoPassFail
      ? autoFilterSettings?.autoPassFail?.passFailThreshold
      : autoFilterSettings?.autoPassFailReview?.passAtAndAbove;
  const failingScore = autoFilterSettings?.autoPassFailReview?.failAtAndBelow;

  return {
    autoFilterEnabled,
    filterType,
    passingScore,
    failingScore,
  };
}

// TODO: make tests
export function getGuestSharingFormValuesFromSettings({
  guestSharingEnabled,
  guestSharingSettings,
}: {
  guestSharingEnabled?: boolean;
  guestSharingSettings?: GuestSharingSettingsModel;
}): Pick<
  ExamSettingsFormValues,
  | "guestSharingEnabled"
  | "showSecretTestcases"
  | "guestSharingShowReviews"
  | "guestSharingShowName"
  | "guestSharingShowInsights"
  | "guestSharingShowEntryFormResponses"
  | "guestSharingShowSubmissionDetails"
  | "guestSharingShowActionLog"
  | "guestSharingShowNote"
> {
  if (!guestSharingEnabled) {
    return {
      guestSharingEnabled: false,
      showSecretTestcases: false,
      guestSharingShowReviews: false,
      guestSharingShowName: false,
      guestSharingShowInsights: false,
      guestSharingShowEntryFormResponses: false,
      guestSharingShowSubmissionDetails: false,
      guestSharingShowActionLog: false,
      guestSharingShowNote: false,
    };
  }

  return {
    guestSharingEnabled: true,
    showSecretTestcases: guestSharingSettings?.showSecretTestcases ?? false,
    guestSharingShowReviews: guestSharingSettings?.showReviews ?? false,
    guestSharingShowName: guestSharingSettings?.showName ?? false,
    guestSharingShowInsights: guestSharingSettings?.showInsights ?? false,
    guestSharingShowEntryFormResponses:
      guestSharingSettings?.showEntryFormResponses ?? false,
    guestSharingShowSubmissionDetails:
      guestSharingSettings?.showSubmissionDetails ?? false,
    guestSharingShowActionLog: guestSharingSettings?.showActionLog ?? false,
    guestSharingShowNote: guestSharingSettings?.showNote ?? false,
  };
}

export function getAutoFilterSettingsFromFormValues({
  autoFilterEnabled,
  filterType,
  passingScore,
  failingScore,
}: Pick<
  ExamSettingsFormValues,
  "autoFilterEnabled" | "filterType" | "passingScore" | "failingScore"
>) {
  return autoFilterEnabled
    ? {
        filterType,
        ...(filterType === AutoFilterType.AutoPassFail
          ? {
              autoPassFail: {
                passFailThreshold: passingScore,
              },
            }
          : {
              autoPassFailReview: {
                passAtAndAbove: passingScore,
                failAtAndBelow: failingScore,
              },
            }),
      }
    : undefined;
}

/**
 * Check if the challenge is eligible for code playback or webcam
 * @param examChallenge
 * @returns
 */
export function isChallengeRecordable(examChallenge: ExamChallengeModel) {
  const { timeLimitMinutes } = examChallenge;
  return (
    timeLimitMinutes !== undefined &&
    timeLimitMinutes <= MAX_CODE_PLAYBACK_AND_WEBCAM_MINUTES
  );
}
/**
 * Check if all challenges in the exam are playable
 * @param examChallengesSets
 * @returns
 */
export function isAllChallengesCodePlayable(
  examChallengesSets: ExamChallengeSetModel[] = [],
) {
  const playableChallenges = examChallengesSets.reduce((acc, challengeSet) => {
    return [
      ...acc,
      ...challengeSet.challenges.filter((challenge) =>
        ChallengeStyle.canPlayback(challenge.style),
      ),
    ];
  }, []);

  return playableChallenges.length === 0
    ? true
    : playableChallenges.every(isChallengeRecordable);
}

/**
 * Check if all challenges in the exam can enable webcam
 * @param examChallengesSets
 * @returns
 */
export function areAllChallengesCammable(
  examChallengesSets: ExamChallengeSetModel[] = [],
) {
  const cammableChallenges = examChallengesSets.reduce((acc, challengeSet) => {
    return [
      ...acc,
      ...challengeSet.challenges.filter((challenge) =>
        ChallengeStyle.canWebcam(challenge.style),
      ),
    ];
  }, []);

  return cammableChallenges.length === 0
    ? true
    : cammableChallenges.every(isChallengeRecordable);
}

export function getMonitoringWarningMessage({
  shouldShowPlaybackWarning,
  shouldShowWebcamWarning,
}: {
  shouldShowPlaybackWarning: boolean;
  shouldShowWebcamWarning: boolean;
}) {
  if (shouldShowPlaybackWarning && shouldShowWebcamWarning) {
    return "exam.playbackAndWebcam.warning.maxTime";
  }

  if (shouldShowPlaybackWarning) {
    return "exam.playback.warning.maxTime";
  }

  return "exam.webcam.warning.maxTime";
}

export const examSettingsdateRangeSuperRefine = (
  data: { startAt: Date; endAt: Date },
  ctx: z.RefinementCtx,
  intl: IntlShape,
) => {
  const startAt = data?.startAt;
  const endAt = data?.endAt;

  if (!endAt) {
    ctx.addIssue({
      path: ["endAt"],
      message: intl.formatMessage({
        id: "required",
      }),
      code: z.ZodIssueCode.custom,
    });
  } else {
    const endDate = dayjs(endAt);
    const startDate = dayjs(startAt);
    if (startAt && startDate.isAfter(endDate)) {
      ctx.addIssue({
        path: ["startAt"],
        message: intl.formatMessage(
          {
            id: "validation.date.max",
          },
          {
            0: formatDateTimeMinutes(endAt.toISOString()),
          },
        ),
        code: z.ZodIssueCode.invalid_date,
      });
    }
  }

  if (!startAt) {
    ctx.addIssue({
      path: ["startAt"],
      message: intl.formatMessage({
        id: "required",
      }),
      code: z.ZodIssueCode.custom,
    });
  } else {
    const startDate = dayjs(startAt);
    const minEndDate = startDate;
    const maxEndDate = startDate.add(10, "year");
    const endDate = dayjs(endAt);

    if (endAt && endDate.isSameOrBefore(minEndDate)) {
      ctx.addIssue({
        path: ["endAt"],
        message: intl.formatMessage(
          {
            id: "validation.date.greaterThan",
          },
          {
            0: formatDateTimeMinutes(minEndDate.toISOString()),
          },
        ),
        code: z.ZodIssueCode.invalid_date,
      });
    }

    if (endDate.isAfter(maxEndDate)) {
      ctx.addIssue({
        path: ["endAt"],
        message: intl.formatMessage({
          id: "validation.date.within10YearsFrom",
        }),
        code: z.ZodIssueCode.invalid_date,
      });
    }
  }
};

export const autoFilterSettingsSuperRefine = (
  data: ExamSettingsFormValidation["autoFilterSettings"],
  ctx: z.RefinementCtx,
  intl: IntlShape,
) => {
  if (!data) {
    return;
  }

  const { filterType } = data;
  if (filterType === AutoFilterType.AutoPassFail) {
    return;
  }

  const passingScore = data.autoPassFailReview?.passAtAndAbove;
  const failingScore = data.autoPassFailReview?.failAtAndBelow;

  if (passingScore !== undefined && failingScore !== undefined) {
    if (failingScore > passingScore) {
      if (failingScore !== 100) {
        ctx.addIssue({
          path: ["autoPassFailReview", "passAtAndAbove"],
          message: intl.formatMessage(
            {
              id: "validation.number.greater",
            },
            { 0: failingScore },
          ),
          code: z.ZodIssueCode.custom,
        });
      }

      if (passingScore !== 0) {
        ctx.addIssue({
          path: ["autoPassFailReview", "failAtAndBelow"],
          message: intl.formatMessage(
            {
              id: "validation.number.less",
            },
            { 0: passingScore },
          ),
          code: z.ZodIssueCode.custom,
        });
      }
    } else if (passingScore === failingScore) {
      if (failingScore !== 100) {
        ctx.addIssue({
          path: ["autoPassFailReview", "passAtAndAbove"],
          message: intl.formatMessage(
            {
              id: "validation.number.greater",
            },
            { 0: failingScore },
          ),
          code: z.ZodIssueCode.custom,
        });
      } else {
        ctx.addIssue({
          path: ["autoPassFailReview", "failAtAndBelow"],
          message: intl.formatMessage(
            {
              id: "validation.number.less",
            },
            { 0: passingScore },
          ),
          code: z.ZodIssueCode.custom,
        });
      }
    }
  }
};
