import * as classnames from "classnames";
import * as React from "react";

import { Msg, Checkbox, Spinner } from "@shared/components";
import { ChallengeInsightModel } from "@shared/models";
import { ChallengeStyle } from "@shared/services/enums";

import { ChallengeDetailBox } from "..";
import {
  ChallengeScoreDistribution,
  UsageOverTime,
  ProgrammingLanguageStats,
  AverageStats,
} from "../../../challengeSections";

/**
 * Prop interface
 */
export interface ChallengeStatsProps {
  challengeInsight?: ChallengeInsightModel;
  loading: boolean;
}

/**
 * React Component
 */
export function ChallengeStats({
  challengeInsight,
  loading,
}: ChallengeStatsProps) {
  const rootStyle = classnames("code-challenge-stats");

  const [logarithmicScale, setLogarithmicScale] = React.useState(true);

  const noResult = <Msg id="message.noResults" />;
  const spinner = (
    <div className="code-challenge-stats__loading">
      <Spinner />
    </div>
  );

  const showProgrammingLanguagesStats =
    (challengeInsight?.programmingLanguagesStats || []).reduce(
      (total, item) => total + item.applicantCount,
      0,
    ) > 0;
  const showMonthlyApplicantsStats =
    (challengeInsight?.monthlyApplicantsStats || []).length > 0;
  const showScoreDistributions =
    (challengeInsight?.scoreDistributions || []).length > 0;

  return (
    <div className={rootStyle}>
      <ChallengeDetailBox
        title={
          <div className="code-challenge-stats__score-distribution">
            <Msg id="challenge.scoreDistribution" />
            {showScoreDistributions && (
              <Checkbox
                className="code-challenge-stats__logarithmic_scale"
                value={logarithmicScale}
                onChange={() => setLogarithmicScale(!logarithmicScale)}
              >
                <Msg id="challenge.logarithmicScale" />
              </Checkbox>
            )}
          </div>
        }
      >
        {loading ? (
          spinner
        ) : showScoreDistributions ? (
          <ChallengeScoreDistribution
            challengeInsight={challengeInsight}
            logarithmicScale={logarithmicScale}
            chartWidth={500}
          />
        ) : (
          noResult
        )}
      </ChallengeDetailBox>
      <ChallengeDetailBox title={<Msg id="challenge.usageOverTime" />}>
        {loading ? (
          spinner
        ) : showMonthlyApplicantsStats ? (
          <UsageOverTime challengeInsight={challengeInsight} chartWidth={650} />
        ) : (
          noResult
        )}
      </ChallengeDetailBox>
      {challengeInsight && (
        <>
          <ChallengeDetailBox title={<Msg id="challenge.averageStats" />}>
            <AverageStats challengeInsight={challengeInsight} />
          </ChallengeDetailBox>
          {ChallengeStyle.hasProgrammingLanguage(challengeInsight?.style) && (
            <ChallengeDetailBox title={<Msg id="challenge.languageStats" />}>
              {loading ? (
                spinner
              ) : showProgrammingLanguagesStats ? (
                <ProgrammingLanguageStats
                  challengeInsight={challengeInsight}
                  chartWidth={300}
                />
              ) : (
                noResult
              )}
            </ChallengeDetailBox>
          )}
        </>
      )}
    </div>
  );
}
