import * as classnames from "classnames";
import * as React from "react";

import {
  SubMenuButton,
  Msg,
  Icon,
  Restricted,
  Tooltip,
} from "@shared/components";
import { ProjectRole, UserRole } from "@shared/services/enums";

/**
 * Props
 */
export interface ExamDetailDeliveryButtonProps {
  archived: boolean;
  isAssignedReviewer: boolean;
  isDeliveryAllowed: boolean;
  isSampleDeliveryAllowed: boolean;
  onClickDelivery: () => void;
  onClickSampleDelivery: () => void;
  extraMessages?: {
    disabledDelivery?: React.ReactNode;
    disabledSampleDelivery?: React.ReactNode;
  };
}

export const ExamDetailDeliveryButton: React.FunctionComponent<
  ExamDetailDeliveryButtonProps
> = ({
  archived,
  isAssignedReviewer,
  isDeliveryAllowed,
  isSampleDeliveryAllowed,
  onClickDelivery,
  onClickSampleDelivery,
  extraMessages,
}: ExamDetailDeliveryButtonProps) => {
  const rootStyle = classnames("code-exam-detail-delivery-button");

  return (
    <div className={rootStyle}>
      <Restricted
        roles={[ProjectRole.ExamDeliverer, ProjectRole.ProjectAdmin]}
        strictAllow={!archived}
        wrapperTagName={false}
      >
        <Tooltip
          placement="top-start"
          text={
            extraMessages?.disabledDelivery ?? (
              <Msg id="tier.disabled.notSupported" />
            )
          }
          disabled={isDeliveryAllowed}
        >
          <SubMenuButton
            type="primary"
            onClick={onClickDelivery}
            disabled={!isDeliveryAllowed}
          >
            <Icon type="paper-plane" />
            <Msg id="examDelivery" />
          </SubMenuButton>
        </Tooltip>
      </Restricted>
      <Restricted
        roles={[
          ProjectRole.ExamDeliverer,
          ProjectRole.ExamCreator,
          ProjectRole.ProjectAdmin,
          UserRole.SystemAdmin,
        ]}
        allow={isAssignedReviewer}
        strictAllow={!archived}
        wrapperTagName={false}
      >
        <Tooltip
          placement="top-start"
          text={
            extraMessages?.disabledSampleDelivery ?? (
              <Msg id="tier.disabled.notSupported" />
            )
          }
          disabled={isSampleDeliveryAllowed}
        >
          <SubMenuButton
            type="primary"
            outlined={true}
            onClick={onClickSampleDelivery}
            disabled={!isSampleDeliveryAllowed}
          >
            <Icon type="paper-plane" />
            <Msg id="delivery.test" />
          </SubMenuButton>
        </Tooltip>
      </Restricted>
    </div>
  );
};
