import * as classnames from "classnames";
import * as React from "react";

import { ErrorTile } from "../../../../shared/components";

/**
 * Prop interface
 */
export interface ErrorProps {
  location?: {
    state?: {
      error?: string;
      description?: string;
    };
  };
  hasBoxShadow?: boolean;
  hasBackButton?: boolean;
}

/**
 * Page component
 */
class Error extends React.Component<ErrorProps> {
  constructor(props: ErrorProps) {
    super(props);
  }

  public render() {
    const rootStyle = classnames("code-error");

    const message =
      (this.props.location &&
        this.props.location.state &&
        this.props.location.state.error) ||
      "Sorry, there is something wrong.";
    const description =
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.description;

    return (
      <div className={rootStyle}>
        <ErrorTile
          errorMessage={message}
          errorDescription={description}
          hasBackButton={this.props.hasBackButton}
          hasBoxShadow={this.props.hasBoxShadow}
        />
      </div>
    );
  }
}

export default Error;
