import { CustomFormDefinitionType } from "../services/enums";

class CustomFormDefinitionModel {
  public id: number;
  public examId: number;
  public key: string;
  public displayName: string;
  public dataType: CustomFormDefinitionType = CustomFormDefinitionType.Text;
  public description: string;
  public language?: string;
  public options: string[] = [];
  public required: boolean;
  public displayOrder: number;

  constructor(args?: {}) {
    Object.assign(this, args);
  }

  public toValidationRule() {
    if (this.dataType === CustomFormDefinitionType.Checkbox) {
      if (this.required) {
        return {
          [`${this.key}`]: this.getKeys().reduce(
            (result, key) => ({ ...result, ...{ [`${key}`]: ["boolean"] } }),
            Object.create(null),
          ),
        };
      }
    }

    const rules: (string | string[])[] = [];

    switch (this.dataType) {
      case CustomFormDefinitionType.Text:
      case CustomFormDefinitionType.Textarea: {
        rules.push("string");
        break;
      }
      case CustomFormDefinitionType.Number: {
        rules.push("number");
        break;
      }
      case CustomFormDefinitionType.Date: {
        rules.push(
          "date",
          "iso",
          ["min", "1900/01/01 00:00:00"],
          ["max", "2099/12/31 23:59:59"],
        );
        break;
      }
      case CustomFormDefinitionType.DateRange: {
        rules.push(
          "sparseArray",
          "dateRange",
          ["dateRangeMin", "1900/01/01 00:00:00"],
          ["dateRangeMax", "2099/12/31 23:59:59"],
        );
        break;
      }
      default:
        rules.push("string");
    }

    if (this.required) {
      rules.push("required");
      if (this.dataType === CustomFormDefinitionType.DateRange) {
        rules.push("requiredAll");
      }
    }

    return {
      [`${this.key}`]: rules,
    };
  }

  public getKeys() {
    if (this.dataType === CustomFormDefinitionType.Checkbox) {
      return this.options.map((_, index) => `${this.key}__${index}`);
    } else {
      return [this.key];
    }
  }
}

export default CustomFormDefinitionModel;
