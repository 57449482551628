import * as classnames from "classnames";

import { Icon, LanguageTag, VersionCodeTag } from "..";

export interface LinkedChallengeProps {
  title: string;
  language: string;
  className?: string;
  version?: string;
  onClick?: () => void;
}

/**
 * React Component
 */
const LinkedChallenge = ({
  title,
  language,
  version,
  className,
  onClick,
}: LinkedChallengeProps) => {
  const rootStyle = classnames("code-c-linked-challenge", {
    [`${className}`]: Boolean(className),
    clickable: !!onClick,
  });

  return (
    <div className={rootStyle} role="button" onClick={onClick}>
      <Icon type="link" />
      <LanguageTag language={language} />
      <a className="code-c-linked-challenge__linked-link">
        <strong>{title}</strong>
        <Icon type="file-text" />
      </a>
      {version && <VersionCodeTag version={version} />}
    </div>
  );
};

export default LinkedChallenge;
