/* tslint:disable:no-console */
class Logger {
  public static info(...args: Array<{}>) {
    if (process.env.NODE_ENV === "development") {
      return console.log && console.log(...args);
    }
  }

  public static warn(...args: Array<{}>) {
    if (process.env.NODE_ENV === "development") {
      return console.warn && console.warn(...args);
    }
  }

  public static error(...args: Array<{}>) {
    if (process.env.NODE_ENV === "development") {
      return console.error && console.error(...args);
    }
  }
}

export default Logger;
/* tslint:enable:no-console */
