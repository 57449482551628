import * as classnames from "classnames";
import * as React from "react";

import {
  Msg,
  Block,
  LeftBlock,
  RightBlock,
  BlockItem,
  PageTitle,
  Restricted,
  Button,
  Icon,
  Tooltip,
} from "@shared/components";
import { ProjectRole, TierAction, UserRole } from "@shared/services/enums";
import Message from "@shared/services/message";

/**
 * Props
 */
export interface QuestionListHeaderProps {
  isGivery: boolean;
  count?: number;
  onClickNewQuestion: () => void;
  isTierActionAllowed: (tierAction: TierAction) => boolean;
}

export const QuestionListHeader: React.FunctionComponent<
  QuestionListHeaderProps
> = ({
  count,
  isTierActionAllowed,
  isGivery,
  onClickNewQuestion,
}: QuestionListHeaderProps) => {
  const rootStyle = classnames("code-question-list-header");
  return (
    <Block className={rootStyle}>
      <LeftBlock>
        <PageTitle>
          <Msg id="common.questionList" />
          <span className="code-question-list-header__count">
            {Message.getSearchResultFormat(count)}
          </span>
        </PageTitle>
      </LeftBlock>
      <RightBlock>
        <BlockItem>
          <Restricted
            roles={[
              UserRole.SystemAdmin,
              UserRole.ChallengeCreator,
              ProjectRole.ProjectAdmin,
              ProjectRole.ChallengeCreator,
            ]}
            strictAllow={!isGivery}
          >
            <Tooltip
              disabled={isTierActionAllowed(TierAction.CustomQuizUsage)}
              text={Message.getMessageByKey("tier.disabled")}
              placement="top-end"
            >
              <Button
                type="primary"
                onClick={onClickNewQuestion}
                ariaLabel="Create New Question"
                disabled={!isTierActionAllowed(TierAction.CustomQuizUsage)}
              >
                <Icon type="plus" />
                <Msg id="button.newQuestion" />
              </Button>
            </Tooltip>
          </Restricted>
        </BlockItem>
      </RightBlock>
    </Block>
  );
};
