import * as React from "react";

import { Accordion, CustomFormDisplay, Icon, Msg } from "@shared/components";
import {
  SubmissionCheatMonitoring,
  SubmissionOverlappingIp,
} from "@shared/models";
import {
  ApplicantExamStatus,
  CustomFormDefinitionType,
} from "@shared/services/enums";
import Message from "@shared/services/message";

import { CheatMonitorHeader } from "./CheatMonitorHeader/CheatMonitorHeader";
import { OverlappingIpCountDisplay } from "./OverLappingIpDisplay/OverLappingIpDisplay";
import { SignificantCountBanner } from "./SignificantCountBanner/SignificantCountBanner";
import { SubmissionCheatHelpModal } from "./SubmissionCheatHelpModal";
import { SuspicousChallengeList } from "./SuspiciousChallengeList/SuspicousChallengeList";
import {
  getSignificantIssueTotals,
  getSignificantEvents,
} from "./cheatMonitorUtils";

interface SubmissionCheatMonitorProps {
  cheatMonitoring?: SubmissionCheatMonitoring;
  overlappingIpAddresses?: SubmissionOverlappingIp;
  challengeInfo?: { id: number; title: string }[];
  status?: ApplicantExamStatus;
  onMultiSubmissionDetailsClick?: () => void;
  actionsTabRef?: React.RefObject<HTMLDivElement>;
}

export const SubmissionCheatMonitor = ({
  cheatMonitoring,
  overlappingIpAddresses,
  challengeInfo = [],
  status = ApplicantExamStatus.Unread,
  onMultiSubmissionDetailsClick,
  actionsTabRef,
}: SubmissionCheatMonitorProps) => {
  const [isOpenCheatHelp, setIsOpenCheatHelp] = React.useState(false);

  if (!cheatMonitoring) {
    return null;
  }

  // Totals

  const issueTotals = getSignificantIssueTotals(
    cheatMonitoring,
    overlappingIpAddresses,
  );

  const totalSignificantCount = issueTotals.total;

  const totalOverlappingIpCount = issueTotals.counts.multiSubmission;
  const uniqueSessionCount = issueTotals.counts.sessionData;

  // Details

  const significantEvents = getSignificantEvents(
    cheatMonitoring,
    challengeInfo,
  );

  const significantPastes = significantEvents.filter(
    (event) => event.isSignificantCopyPaste,
  );
  const significantLeftTabs = significantEvents.filter(
    (event) => event.isSignificantLeftTab,
  );
  const significantScreenshots = significantEvents.filter(
    (event) => event.isSignificantScreenshot,
  );

  const significantEventsArray = [
    {
      key: "copyPaste",
      displayName: <Msg id="submission.submissionDetail.cheatMonitor.pasted" />,
      events: significantPastes,
    },
    {
      key: "leftTab",
      displayName: (
        <Msg id="submission.submissionDetail.cheatMonitor.leftTab" />
      ),
      events: significantLeftTabs,
    },
    {
      key: "screenshots",
      displayName: (
        <Msg id="submission.submissionDetail.cheatMonitor.screenshot" />
      ),
      events: significantScreenshots,
    },
  ];

  return (
    <>
      <Accordion
        expanded={totalSignificantCount > 0}
        disabled={totalSignificantCount === 0}
        className="code-exam-detail-top__cheat-monitor"
        summaryClassName="code-submission-detail__entry-form-summary"
        detailClassName="code-submission-detail__entry-form-detail"
        customSummary={(open) => (
          <>
            <CheatMonitorHeader
              onHelpClick={(e) => {
                e.stopPropagation();
                setIsOpenCheatHelp(true);
              }}
            />
            <SignificantCountBanner
              totalSignificantCount={totalSignificantCount}
              status={status}
              actionsTabRef={actionsTabRef}
            />
            {totalSignificantCount > 0 && (
              <Icon
                type={open ? "angle-up" : "angle-down"}
                className="code-submission-detail__toggle-angle"
              />
            )}
          </>
        )}
      >
        {significantEventsArray.map(
          ({ key, displayName, events }) =>
            events.length > 0 && (
              <CustomFormDisplay
                key={key}
                formValue={{
                  key: key,
                  displayName: displayName,
                  dataType: CustomFormDefinitionType.Text,
                }}
              >
                <SuspicousChallengeList suspiciousEvents={events} />
              </CustomFormDisplay>
            ),
        )}
        {uniqueSessionCount > 1 && (
          <CustomFormDisplay
            key="session"
            formValue={{
              key: "session",
              displayName: (
                <Msg id="submission.submissionDetail.cheatMonitor.dataChange" />
              ),
              dataType: CustomFormDefinitionType.Text,
              value: Message.getMessageByKey(
                "submission.submissionDetail.cheatMonitor.detected",
              ),
            }}
          />
        )}
        {totalOverlappingIpCount > 0 && (
          <>
            <OverlappingIpCountDisplay
              totalOverlappingIpCount={totalOverlappingIpCount}
              onMultiSubmissionDetailsClick={onMultiSubmissionDetailsClick}
            />
          </>
        )}
      </Accordion>
      {isOpenCheatHelp && (
        <SubmissionCheatHelpModal
          onClickClose={() => setIsOpenCheatHelp(false)}
        />
      )}
    </>
  );
};

export default SubmissionCheatMonitor;
