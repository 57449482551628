import * as classnames from "classnames";
import * as React from "react";

import {
  ActionDropdown,
  Msg,
  Restricted,
  Icon,
  Tooltip,
} from "@shared/components";
import { ProjectRole, UserRole } from "@shared/services/enums";
import Message from "@shared/services/message";

/**
 * Props
 */
export interface ExamListActionProps {
  status: string;
  disableArchive?: boolean;
  canDeliver: boolean;
  canCopy: boolean;
  onClickDelivery: () => void;
  onClickCopy: () => void;
  onClickDelete: () => void;
  onClickArchive: () => void;
  onClickUnArchive: () => void;
  extraMessages?: {
    disableDelivery?: React.ReactNode;
  };
}

export const ExamListAction: React.FunctionComponent<ExamListActionProps> = (
  props: ExamListActionProps,
) => {
  const { status, disableArchive, canDeliver, canCopy, extraMessages } = props;
  const rootStyle = classnames("code-exam-list-action");
  return (
    <Restricted
      roles={[
        UserRole.SystemAdmin,
        ProjectRole.ProjectAdmin,
        ProjectRole.ExamDeliverer,
        ProjectRole.ExamCreator,
      ]}
    >
      {status !== "active" ? (
        <Restricted roles={[UserRole.SystemAdmin, ProjectRole.ProjectAdmin]}>
          <ActionDropdown className={rootStyle}>
            <a role="menuitem" onClick={props.onClickUnArchive}>
              <span className="code-c-icon icon">
                <i className="code-exam-list-action__icon unarchive" />
              </span>
              <Msg id="common.unarchive" />
            </a>
            <div className="separator" />
            <a
              role="menuitem"
              className="code-exam-list-action__delete-button"
              onClick={props.onClickDelete}
            >
              <Icon type="trash" />
              <Msg id="delete" />
            </a>
          </ActionDropdown>
        </Restricted>
      ) : (
        <ActionDropdown className={rootStyle}>
          <Restricted
            roles={[ProjectRole.ExamDeliverer, ProjectRole.ProjectAdmin]}
          >
            <Tooltip
              text={
                extraMessages?.disableDelivery ??
                Message.getMessageByKey("tier.disabled.notSupported")
              }
              placement="top-end"
              disabled={canDeliver}
            >
              <a
                role="menuitem"
                className={classnames({
                  "code-exam-list-action__disable": !canDeliver,
                })}
                onClick={canDeliver ? props.onClickDelivery : undefined}
              >
                <Icon type="paper-plane" />
                <Msg id="delivery" />
              </a>
            </Tooltip>
          </Restricted>
          <Restricted
            roles={[
              UserRole.SystemAdmin,
              ProjectRole.ProjectAdmin,
              ProjectRole.ExamCreator,
            ]}
          >
            <Tooltip
              text={Message.getMessageByKey("tier.disabled.notSupported")}
              placement="top-end"
              disabled={canCopy}
            >
              <a
                role="menuitem"
                className={classnames({
                  "code-exam-list-action__disable": !canCopy,
                })}
                onClick={canCopy ? props.onClickCopy : undefined}
              >
                <Icon type="copy" />
                <Msg id="copy" />
              </a>
            </Tooltip>
          </Restricted>
          <Restricted roles={[UserRole.SystemAdmin, ProjectRole.ProjectAdmin]}>
            <Tooltip
              text={Message.getMessageByKey("exam.archive.note")}
              placement="top-end"
              disabled={!disableArchive}
            >
              <a
                role="menuitem"
                onClick={props.onClickArchive}
                className={classnames({
                  "code-exam-list-action__disable": disableArchive,
                })}
              >
                <span className="code-c-icon icon">
                  <i className="code-exam-list-action__icon archive" />
                </span>
                <Msg id="common.archive" />
              </a>
            </Tooltip>
          </Restricted>
        </ActionDropdown>
      )}
    </Restricted>
  );
};
