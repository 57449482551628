import {
  AdminChallengeCollectionModel,
  ChallengeCollectionModel,
  ExamChallengeSetModel,
  FormedChallengeCollectionModel,
} from "../models";
import { ProgrammingLanguage, SortDirection } from "./enums";

/**
 * get formed challenge collection
 *
 * @param collectionDetail
 * @param language

 */
export function getFormedChallengeCollection(
  collectionDetail: AdminChallengeCollectionModel | ChallengeCollectionModel,
  language: string,
): FormedChallengeCollectionModel {
  return (
    collectionDetail &&
    (language === "en"
      ? {
          ...collectionDetail,
          title: collectionDetail.enTitle,
          description: collectionDetail.enDescription,
        }
      : {
          ...collectionDetail,
          title: collectionDetail.jpTitle,
          description: collectionDetail.jpDescription,
        })
  );
}

/**
 * Note: A more normal submission has a lower score so the lower the score the
 * higher on the list it should be when sorting by decreased sort.
 * In the case of sorting by normalcy, we inverse the sort direction to get the result we want.
 */
export const getChallengeListSortDirection = (
  columnName: string,
  direction: SortDirection,
) => {
  if (columnName !== "normalcyScore") {
    return direction;
  }

  return direction === SortDirection.Asc
    ? SortDirection.Desc
    : SortDirection.Asc;
};

/**
 * Python2 has been end-of-life since the beginning of 2020
 */
export const isValidProgrammingLanguage = (language: ProgrammingLanguage) => {
  return language !== ProgrammingLanguage.Python;
};

export const filterValidProgrammingLanguages = (
  programmingLanguages: ProgrammingLanguage[],
) => {
  return programmingLanguages.filter(isValidProgrammingLanguage);
};

export const updateExamChallengeSetsPin = ({
  challengeSets,
  targetChallengeId,
  newFavoriteId,
}: {
  challengeSets: ExamChallengeSetModel[];
  targetChallengeId: number;
  newFavoriteId?: number;
}) => {
  return challengeSets.map(({ challenges, ...rest }) => ({
    ...rest,
    challenges: challenges.map((challenge) => ({
      ...challenge,
      favoriteId:
        challenge.challengeId === targetChallengeId
          ? newFavoriteId
          : challenge.favoriteId,
    })),
  }));
};
