import { HTML5Backend } from "react-dnd-html5-backend";

export { HTML5Backend };

/**
 * get sorted item list
 * @param {{ id: number; item: T }[]} items
 * @param {number} dragIndex
 * @param {number} hoverIndex
 */
export function getSortItems<T>(
  items: { id: number; item: T }[],
  dragIndex: number,
  hoverIndex: number,
): { id: number; item: T }[] {
  const newArray = [...items];
  newArray.splice(dragIndex, 1);
  newArray.splice(hoverIndex, 0, items[dragIndex]);
  return newArray;
}

/**
 * Only perform the move when the mouse has crossed half of the items height
 * When dragging downwards, only move when the cursor is below 50%
 * When dragging upwards, only move when the cursor is above 50%
 * @param {number} hoverIndex
 * @param {number} dragIndex
 * @param {number} clientY Determine mouse position Y
 * @param {ClientRect | DOMRect} hoverBoundingRect Determine rectangle on screen
 */
export function isDraggableItem(
  hoverIndex: number,
  dragIndex: number,
  clientY: number,
  hoverBoundingRect: ClientRect | DOMRect,
): boolean {
  const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2; // Get vertical middle
  const hoverClientY = clientY - hoverBoundingRect.top; // Get pixels to the top
  if (
    dragIndex === hoverIndex || // Don't replace items with themselves
    (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) || // Dragging downwards
    (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) // Dragging upwards
  ) {
    return false;
  } else {
    return true;
  }
}
