import * as classnames from "classnames";

import { Tag } from "../..";
import Message from "../../../services/message";

/**
 * Prop interface
 */
export interface LanguageTagProps {
  language: string;
  color?: "gray" | "pink";
  className?: string;
  onClick?: () => void;
}

/**
 * React Component
 */
export function LanguageTag({
  language,
  color = "gray",
  onClick,
  className,
}: LanguageTagProps) {
  const rootStyle = classnames("code-c-language-tag", {
    "is-pink": color === "pink",
    [`${className}`]: Boolean(className),
  });

  return (
    <Tag className={rootStyle} onClick={onClick}>
      {Message.getMessageByKey(
        language === "ja"
          ? "common.language.japanese"
          : language === "en"
          ? "common.language.english"
          : "Invalid Language",
      )}
    </Tag>
  );
}
