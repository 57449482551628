import { connect } from "react-redux";
import { Action, Dispatch } from "redux";

import { challengeGetAction, challengeListGetAction } from "@actions";

import { RootState } from "@reducers";

import {
  useChallengePinList,
  useCreateChallenge,
  useCreateChallengePin,
  useDeleteChallengePin,
  useUpdateChallengeQuestion,
} from "@shared/hooks/query";
import { PaginationModel } from "@shared/models";
import { SortOrderItem } from "@shared/models";
import { getChallengeListSortDirection } from "@shared/services/challengeCollection";
import { getTierAllowedByRedux } from "@shared/services/tier";
import { withCustomHook } from "@shared/services/withCustomHook";

import ChallengeList from "./ChallengeList";

interface OwnProps {
  location?: {
    search: string;
  };
}

const wrapper = withCustomHook(() => {
  const createChallenge = useCreateChallenge();
  const updateChallengeQuestions = useUpdateChallengeQuestion();
  const deletePinChallenge = useDeleteChallengePin();
  const addPinChallenge = useCreateChallengePin();
  const { data: challengePinList } = useChallengePinList();

  return {
    addPinChallenge,
    createChallenge,
    deletePinChallenge,
    updateChallengeQuestions,
    pinnedChallengeList: challengePinList?.challengeFavorites,
  };
})(ChallengeList);

const mapStateToProps = (state: RootState, props: OwnProps) => {
  const {
    challengeDetailList,
    challengeFilters,
    challengeList,
    challengeListPagination,
    copyChallengeError,
    copyChallengeLoading,
    pinnedChallengeList,
    loadingList,
    loadingCreate,
    errorCreate,
    loadingDetail,
  } = state.challenge;
  const { user } = state.user;

  return {
    ...props,
    currentProjectId: state.project.currentProjectId,
    challengeDetailList,
    challengeFilters,
    challengeList,
    pinnedChallengeList,
    copyChallengeError,
    copyChallengeLoading,
    errorCreate,
    loading: loadingList || loadingCreate || copyChallengeLoading,
    loadingCreate,
    loadingDetail,
    org: user?.organization ?? undefined,
    canEditPin:
      state.user.user?.canEditPin(state.project.currentProjectId) ?? false,
    pagination: challengeListPagination,
    isTierActionAllowed: getTierAllowedByRedux(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  getChallenge: (payload: {}) => {
    dispatch(challengeGetAction.request(payload));
  },
  getChallengeList: (
    pagination: PaginationModel,
    filters: {},
    sortOrder: SortOrderItem,
  ) => {
    dispatch(
      challengeListGetAction.request({
        ...pagination,
        ...filters,
        "sortOrder.column": sortOrder.column,
        "sortOrder.direction": getChallengeListSortDirection(
          sortOrder.column,
          sortOrder.direction,
        ),
      }),
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(wrapper);
