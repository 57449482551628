import Logger from "../services/logger";

interface JsonalizedTap {
  ok: boolean;
  lineNumber: number;
  description: string;
}

// ok 1 Input file opened
// not ok 2 First line of the input valid
const regexString = /^(not )?ok\s(\d+)\s(.*)/;

export function parse(output: string): JsonalizedTap[] {
  return output
    .split("\n")
    .filter((line) => isValidFormat(line))
    .map((line) => {
      const parsed = line.match(regexString);
      if (parsed === null) {
        // NOTE: This case won't be happen forever.
        Logger.warn(`Invalid TAP format: ${line}`);
        return {
          ok: false,
          lineNumber: 0,
          description: "",
        } as JsonalizedTap;
      } else {
        const [, testResult, lineNumber, description] = parsed;
        return {
          ok: testResult !== "not ",
          lineNumber: parseInt(lineNumber, 10),
          description,
        } as JsonalizedTap;
      }
    });
}

function isValidFormat(line: string): boolean {
  return line.match(regexString) !== null;
}
