import { useQuery } from "@tanstack/react-query";

import { getReportSubmissionRankingDetails } from "@api/reports";

import { useStoreContext } from "@context";

import { ApplicantReportModel } from "@shared/models";

import { useRouteParams } from "../useRouteParams";

interface SubmissionAnalysisAllKeyParams {
  projectId: number;
  submissionId: number;
}

export const reportRankingDetailsKeys = {
  all: ["report", "submission", "rankingDetails"] as const,
  analysisAll: ({
    projectId,
    submissionId,
  }: SubmissionAnalysisAllKeyParams) => [
    ...reportRankingDetailsKeys.all,
    projectId,
    submissionId,
  ],
};

interface SubmissionRankingDetailsParams {
  submissionId: number;
  enabled?: boolean;
}

export function useGetSubmissionRankingDetails({
  submissionId,
  enabled = true,
}: SubmissionRankingDetailsParams) {
  const { projectId } = useStoreContext();

  const query = useQuery<ApplicantReportModel>({
    queryKey: reportRankingDetailsKeys.analysisAll({
      projectId,
      submissionId,
    }),
    enabled,
    queryFn: async ({ signal }) => {
      const { result } = await getReportSubmissionRankingDetails({
        projectId,
        submissionId,
        options: {
          signal,
        },
      });

      return result;
    },
    keepPreviousData: true,
    meta: { skipErrorToast: true },
  });

  return query;
}

export function useSubmissionRankingDetails() {
  const { submissionId } = useRouteParams();
  return useGetSubmissionRankingDetails({ submissionId });
}
