import { useQuery } from "@tanstack/react-query";
import { useCallback } from "react";

import { getSubmissionTimeline } from "@api/submissions";

import { useStoreContext } from "@context";

import { useSubmissionAccessType } from "@shared/hooks/auth";
import {
  ExamSessionDataResponse,
  SubmissionTimelineResponse,
  TimelineUser,
} from "@shared/models";
import {
  dayjs,
  formatDateTimeMinutes,
  formatTimeElapsedString,
} from "@shared/services/date";
import { ApplicantExamStatus, ExamDeliverMethod } from "@shared/services/enums";
import Message from "@shared/services/message";
import { areFieldsDefined } from "@shared/services/typings";

import { useRouteParams } from "../useRouteParams";

export type SubmissionTimelineType =
  | "progress"
  | "evaluation"
  | "evaluation-by"
  | "to-report";

export interface SubmissionTimeline {
  titleId: string;
  dateTime: string;
  formattedDateTime: string;
  withColon?: boolean;
  body: {
    align?: "center";
    labelId?: string;
    value: string | number;
    tooltip?: string;
    type?: SubmissionTimelineType;
    withColon?: boolean;
  }[];
}

export const submissionTimelineKeys = {
  all: ["submissions", "timeline"] as const,
  timeline: (projectId: number, submissionId: number) =>
    [...submissionTimelineKeys.all, projectId, submissionId] as const,
};

export function useSubmissionTimeline() {
  const { projectId } = useStoreContext();
  const { submissionId } = useRouteParams();
  const { isExamDeliverer } = useSubmissionAccessType();
  const enabled = Boolean(projectId && submissionId && !isExamDeliverer);
  const query = useQuery({
    queryKey: submissionTimelineKeys.timeline(projectId, submissionId),
    queryFn: async ({ signal }) => {
      const { result } = await getSubmissionTimeline({
        projectId,
        submissionId,
        options: { signal },
      });

      return result;
    },
    enabled,
    select: useCallback(normalizeTimelineResponse, []),
  });

  return query;
}

function normalizeSessionChallenge({
  challengesAttempted,
  challengesDelivered,
  startedAt,
  timeSpentSeconds,
}: Required<
  Pick<
    ExamSessionDataResponse,
    | "challengesAttempted"
    | "challengesDelivered"
    | "startedAt"
    | "timeSpentSeconds"
  >
>) {
  const challenge = {
    attempted: challengesAttempted,
    delivered: challengesDelivered,
  };
  return {
    titleId: "challengeResultStatus.started",
    dateTime: startedAt,
    formattedDateTime: startedAt ? formatDateTimeMinutes(startedAt) : "-",
    withColon: true,
    body: [
      {
        labelId: "timeSpent",
        value: formatTimeElapsedString(timeSpentSeconds),
        withColon: true,
      },
      {
        labelId: "submission.challengeAttempted",
        value: `${challenge.attempted}/${challenge.delivered}`,
        tooltip: Message.getMessageByKey(
          "submission.timeline.challenge.tooltip",
        ),
        withColon: true,
      },
      {
        value: Math.trunc((challenge.attempted * 100) / challenge.delivered),
        type: "progress",
      },
      {
        value: 0,
        type: "to-report",
      },
    ],
  } as SubmissionTimeline;
}

export function normalizeTimelineResponse(
  { originalDelivery, extensions, evaluation }: SubmissionTimelineResponse,
  isGuest = false,
) {
  const isExtend = extensions.length > 0;
  const isSubmissionWithActionLogs =
    dayjs(originalDelivery.deliveredAt).unix() > dayjs("2023-06-18").unix();
  const normalizedData: SubmissionTimeline[] = [
    {
      titleId: "applicantExam.delivered",
      dateTime: originalDelivery.deliveredAt,
      formattedDateTime: formatDateTimeMinutes(originalDelivery.deliveredAt),
      body: isGuest
        ? []
        : [
            {
              labelId: "common.deliveredBy",
              value: originalDelivery.deliveredByUser
                ? getDisplayName(originalDelivery.deliveredByUser)
                : originalDelivery.deliveryMethod === ExamDeliverMethod.ATS
                ? Message.getMessageByKey("submission.timeline.atsDelivered")
                : Message.getMessageByKey("submission.timeline.urlDelivered"),
            },
          ],
    },
  ];

  if (originalDelivery.examSessionData) {
    const { examSessionData } = originalDelivery;
    if (
      areFieldsDefined(examSessionData, [
        "challengesAttempted",
        "challengesDelivered",
        "timeSpentSeconds",
        "startedAt",
      ])
    ) {
      normalizedData.push(normalizeSessionChallenge(examSessionData));
    }
    if (examSessionData.submittedAt) {
      normalizedData.push(
        normalizeEmptyTimeline(
          examSessionData.submittedAt,
          "applicantExamStatus.submitted",
        ),
      );
    }
    if (examSessionData.expiredAt) {
      normalizedData.push(
        normalizeEmptyTimeline(
          examSessionData.expiredAt,
          "applicantExamStatus.expired",
        ),
      );
    }
  }

  if (isExtend) {
    normalizedData.push(
      ...extensions
        // each iteration will return up to 3 elements such as extended, started, submitted
        .map((extension) => {
          const items: SubmissionTimeline[] = [
            {
              titleId: "common.extended",
              dateTime: extension.extendedAt,
              formattedDateTime: formatDateTimeMinutes(extension.extendedAt),
              body: isGuest
                ? []
                : [
                    {
                      labelId: "common.extendedBy",
                      value: getDisplayName(extension.extendedBy),
                    },
                  ],
            },
          ];

          if (extension.examSessionData) {
            const { examSessionData } = extension;
            if (
              areFieldsDefined(examSessionData, [
                "challengesAttempted",
                "challengesDelivered",
                "timeSpentSeconds",
                "startedAt",
              ])
            ) {
              items.push(normalizeSessionChallenge(examSessionData));
            }
            if (examSessionData.expiredAt) {
              items.push(
                normalizeEmptyTimeline(
                  examSessionData.expiredAt,
                  "applicantExamStatus.expired",
                ),
              );
            }
            if (examSessionData.submittedAt) {
              items.push(
                normalizeEmptyTimeline(
                  examSessionData.submittedAt,
                  "applicantExamStatus.submitted",
                ),
              );
            }
          }

          return items;
        })
        .flat(),
    );
  }

  if (evaluation) {
    const body: SubmissionTimeline["body"] = [
      {
        labelId: "result",
        value: `submission.${
          evaluation.evaluation === ApplicantExamStatus.Passed ? "pass" : "fail"
        }`,
        type: "evaluation",
        withColon: true,
        align: "center",
      },
    ];

    if (evaluation.evaluationMeta) {
      body.push(
        evaluation.evaluationMeta.humanEvaluation
          ? {
              labelId: "common.evaluatedBy",
              value: getDisplayName(
                evaluation.evaluationMeta.humanEvaluation.evaluatedBy,
              ),
            }
          : {
              value: Message.getMessageByKey("submission.autoEvaluated"),
              type: "evaluation-by",
            },
      );
    }

    normalizedData.push({
      titleId: "submission.evaluated",
      dateTime: evaluation.evaluationMeta
        ? evaluation.evaluationMeta.evaluatedAt
        : "",
      formattedDateTime: evaluation.evaluationMeta
        ? formatDateTimeMinutes(evaluation.evaluationMeta.evaluatedAt)
        : "",
      body,
    });
  }

  return { isSubmissionWithActionLogs, timelines: normalizedData };
}

function normalizeEmptyTimeline(time: string, titleId: string) {
  return {
    titleId,
    dateTime: time,
    formattedDateTime: formatDateTimeMinutes(time),
    body: [],
  };
}

function getDisplayName(user: TimelineUser) {
  return user.name || user.email;
}
