import { filterValidProgrammingLanguages } from "@shared/services/challengeCollection";

import { ChallengeStyle, ProgrammingLanguage } from "../services/enums";
import Msg from "../services/message";

interface ApplicantChallengeSettingsModel {
  cli: string[];
  test?: string;
  main?: string;
  files: { [key: string]: boolean | { [key: string]: boolean } };
  hiddenFiles: string[];
  allowNewFile: boolean;
  allowRunTest: boolean;
}

class ApplicantChallengeModel {
  public id: number;
  public style: number;
  public title: string;
  public description: string;
  public timeLimitMinutes: number;
  public displayOrder: number;
  public weight: number;
  public openTestcases: number;
  public secretTestcases: number;
  public difficulty: number;
  public questionCount: number;
  public localExamEnabled: boolean;
  public programmingLanguages: ProgrammingLanguage[] = [];
  public settings: ApplicantChallengeSettingsModel =
    {} as ApplicantChallengeSettingsModel;

  constructor(args: {}) {
    Object.assign(this, args);

    if (this.programmingLanguages) {
      this.programmingLanguages = filterValidProgrammingLanguages(
        this.programmingLanguages,
      );
    }
  }

  public canSwitchLanguage() {
    return ChallengeStyle.hasProgrammingLanguage(this.style);
  }

  public getStyleLabel() {
    return ChallengeStyle.toString(this.style);
  }

  public getStyleIcon() {
    return ChallengeStyle.toIconType(this.style);
  }

  public getQuestionCountLabel() {
    return this.questionCount
      ? `${this.questionCount}${Msg.getMessageByKey("question.countSuffix")}`
      : "";
  }

  public getTimeLimitLabel() {
    if (!this.timeLimitMinutes) {
      return Msg.getMessageByKey("unlimited");
    } else {
      return `${this.timeLimitMinutes}${Msg.getMessageByKey(
        this.timeLimitMinutes > 1 ? "unit.mins" : "unit.min",
      )}`;
    }
  }

  public getProgrammingLanguagesLabel() {
    const { programmingLanguages = [] } = this;
    return programmingLanguages
      .map((item) => ProgrammingLanguage.toString(item))
      .join(" / ");
  }

  public getEditorURL() {
    // Note:
    // why put challenge id to the last of URL is:
    // we are re-using `EditorMain` component. when switching between the same style of challenges then that URL doesn't change.
    // as the result of this, the editor does not update as we expected.
    // For avoiding that kind of situation, putting challenge id as  a disabling cache functionality.
    switch (this.style) {
      case ChallengeStyle.Algorithm:
      case ChallengeStyle.Development:
      case ChallengeStyle.Function:
        return `${process.env.REACT_APP_BACKEND_HOST}/editor/coding?id=${this.id}`;
      case ChallengeStyle.Quiz:
        return `${process.env.REACT_APP_BACKEND_HOST}/editor/2.0/quiz?id=${this.id}`;
      case ChallengeStyle.AI:
        return `${process.env.REACT_APP_BACKEND_HOST}/editor/ai?id=${this.id}`;
      default:
        return `${process.env.REACT_APP_BACKEND_HOST}/editor/sorry_not_support_yet`;
    }
  }
}

export default ApplicantChallengeModel;
