import * as classnames from "classnames";
import * as React from "react";

import { Tag, Msg, SubmissionDetail } from "../";
import {
  ExamChallengeSetModel,
  SubmissionResultListModel,
  SubmissionResultDetailModel,
  ExamChallengeModel,
} from "../../models";
import { ApplicantExamStatus } from "../../services/enums";
import Message from "../../services/message";
import { isIncludedInFinalScore } from "../../services/score";

/**
 * Prop interface
 */
export interface SubmissionResultDetailsProps {
  className?: string;
  challengeSets: Array<ExamChallengeSetModel>;
  status: ApplicantExamStatus;
  results: Array<SubmissionResultListModel>;
  resultDetails: Array<SubmissionResultDetailModel>;
  canViewCodePlayback?: boolean;
  canViewCodeDiff?: boolean;
  hideFileDownload?: boolean;
  onClickRecalculate?: (challengeResult: SubmissionResultDetailModel) => void;
}

/**
 * React Component
 */
export default function SubmissionResultDetails({
  challengeSets = [],
  className,
  status,
  results = [],
  resultDetails = [],
  canViewCodePlayback,
  canViewCodeDiff,
  hideFileDownload,
  onClickRecalculate,
}: SubmissionResultDetailsProps) {
  const rootStyle = classnames("code-c-submission-result-details", {
    [`${className}`]: Boolean(className),
  });

  const findChallenge = (
    result: Pick<SubmissionResultListModel, "challengeId">,
    examChallenge: ExamChallengeModel,
  ) => {
    return (
      result.challengeId === examChallenge.challengeId ||
      result.challengeId === examChallenge.originalChallenge.linkedChallenge?.id
    );
  };

  const submissionSets = challengeSets.map(
    (challengeSet, challengeSetIndex) => {
      const { challenges, isOptionalSet, isRandomSet, numberChallengesToTake } =
        challengeSet;

      // Organize all scores which included in the challenge set
      const scores = challenges
        .map((challenge) =>
          results.find((result) => findChallenge(result, challenge)),
        )
        .map((result) => (result ? result.score : 0));

      const submissionItems = results
        .filter((result) =>
          challenges.find((challenge) => findChallenge(result, challenge)),
        )
        .map((result, index) => {
          let showNotInclude = false;
          if (isOptionalSet) {
            showNotInclude = !isIncludedInFinalScore(
              index,
              scores,
              numberChallengesToTake,
            );
          }
          return (
            <div
              className="code-c-submission-result-details__detail-wrapper"
              key={index}
            >
              {showNotInclude && (
                <div className="code-c-submission-result-details__not-included" />
              )}
              <SubmissionDetail.SubmissionDetail
                questionNumber={index + 1}
                status={status}
                result={result}
                detail={resultDetails.find(
                  (detail) => detail.challengeId === result.challengeId,
                )}
                onClickRecalculate={onClickRecalculate}
                canViewCodePlayback={canViewCodePlayback}
                canViewCodeDiff={canViewCodeDiff}
                hideFileDownload={hideFileDownload}
              />
            </div>
          );
        });

      const SubmissionResultDetailHeader = () => {
        let headerTitleMessageKey = "challenge.required";
        let numberChallengesToTakeBadge: React.ReactChild | null = null;
        if (isOptionalSet) {
          headerTitleMessageKey = "challenge.optional";
          if (numberChallengesToTake !== undefined) {
            numberChallengesToTakeBadge = (
              <Tag>
                {`${Message.getMessageByKey(
                  "exam.numberChallengesToTake",
                )}: ${numberChallengesToTake}`}
              </Tag>
            );
          }
        }
        if (isRandomSet) {
          headerTitleMessageKey = "challenge.random";
        }

        return (
          <div className="code-c-submission-result-details__header">
            <strong>
              {challengeSetIndex + 1}. <Msg id={headerTitleMessageKey} />
            </strong>
            {numberChallengesToTakeBadge}
            {isOptionalSet && (
              <p className="code-c-submission-result-details__optional-score-explanation">
                <small>
                  <Msg id="submission.optional.scoreExplanation" />
                </small>
              </p>
            )}
          </div>
        );
      };

      return (
        <div
          className="code-c-submission-result-details__container"
          key={challengeSetIndex}
        >
          <SubmissionResultDetailHeader />
          <div className="code-c-submission-result-details__body">
            {submissionItems}
          </div>
        </div>
      );
    },
  );

  return (
    <div className={rootStyle} id="result-detail">
      <SubmissionDetail.SubmissionResultHeader className="code-c-submission-result-details__table-head" />
      {submissionSets}
    </div>
  );
}
