import * as classnames from "classnames";
import * as React from "react";

import { ChallengeModel, EnumModel } from "@shared/models";
import {
  ChallengeStatus,
  ChallengeStyle,
  TierAction,
} from "@shared/services/enums";
import Message from "@shared/services/message";
import {
  isCustomCodingAllowed,
  isCustomQuizAllowed,
} from "@shared/services/tier";

import {
  TagCloud,
  Icon,
  Tag,
  LinkedTag,
  VersionCodeTag,
  LanguageTag,
} from "..";

/**
 * Prop interface
 */
export interface ExternalProps {
  className?: string;
  challenge: ChallengeModel;
  collapsible?: boolean;
  edit?: boolean;
  isClickable?: boolean;
  isChallengeAllowed: boolean;

  onClick?: () => void;
  onChange?: (event: React.FormEvent<HTMLInputElement>) => void;
}

export interface InjectedProps {
  programmingLanguages?: EnumModel[];
  programmingCategories?: EnumModel[];
  isTierActionAllowed: (tierAction: TierAction) => boolean;
}

type ChallengeTitleColumnProps = ExternalProps & InjectedProps;

/**
 * React Component
 */
export default function ChallengeTitleColumn({
  programmingCategories = [],
  programmingLanguages = [],
  className,
  isClickable = true,
  challenge,
  isChallengeAllowed,
  onClick,
  isTierActionAllowed,
}: ChallengeTitleColumnProps) {
  const rootStyle = classnames("code-c-challenge-title-column", {
    [`${className}`]: Boolean(className),
  });

  const canClickChallenge = isClickable && isChallengeAllowed;

  const descriptionStyle = classnames(
    "code-c-challenge-title-column__description",
    {
      ["is-cursor-pointer"]: canClickChallenge,
    },
  );

  const tagItems = [
    ...challenge.programmingLanguages.map(
      (id) =>
        (
          programmingLanguages.find((language) => language.value === id) || {
            displayString: "",
          }
        ).displayString,
    ),
    ...challenge.programmingCategories.map(
      (id) =>
        (
          programmingCategories.find((category) => category.value === id) || {
            displayString: "",
          }
        ).displayString,
    ),
    ...(challenge.tags || []),
  ].map((tag: string) => ({ label: tag }));

  const description = challenge.description ? (
    <p
      className={descriptionStyle}
      onClick={canClickChallenge ? onClick : undefined}
    >
      {challenge.description}
    </p>
  ) : null;

  const title = (
    <a
      className={classnames(
        "code-c-challenge-title-column__challenge-title__body",
        { clickable: canClickChallenge },
      )}
      onClick={canClickChallenge ? onClick : undefined}
      role="button"
      aria-label="Toggle Challenge Detail"
    >
      <div className="code-c-challenge-title-column__challenge-title__title">
        {challenge.title}
      </div>
      <div>
        <Icon
          type="file-text"
          className="code-c-challenge-title-column__detail-button"
        />
      </div>
      <div>
        <VersionCodeTag version={challenge.usedChallengeVersionCode} />
      </div>
    </a>
  );

  return (
    <div className={rootStyle}>
      <span className="code-c-challenge-title-column__title">
        {!isChallengeAllowed && (
          <div className="tag code-c-challenge-title-column__not-available">
            <Icon type="exclamation-triangle" />
            {Message.getMessageByKey("tier.disabled.challenge")}
          </div>
        )}
        <div className="code-c-challenge-title-column__tags">
          <LanguageTag language={challenge.language} />
          {challenge.isOfficial ? (
            <Tag
              hasError={
                challenge.style === ChallengeStyle.Quiz
                  ? !isCustomQuizAllowed(challenge, isTierActionAllowed)
                  : !isCustomCodingAllowed(challenge, isTierActionAllowed)
              }
            >
              {Message.getMessageByKey("questionCategory.preset")}
            </Tag>
          ) : (
            <Tag>{Message.getMessageByKey("questionCategory.original")}</Tag>
          )}
          {challenge.status === ChallengeStatus.Draft && (
            <Tag className="code-c-challenge-title-column__draft">
              {Message.getMessageByKey("challenge.drafted")}
            </Tag>
          )}
          {challenge.status === ChallengeStatus.Removed && (
            <Tag className="code-c-challenge-title-column__draft">
              {Message.getMessageByKey("challenge.removed")}
            </Tag>
          )}
          {challenge.linkedChallenge && <LinkedTag />}
        </div>
        {title}
      </span>
      {description}
      <TagCloud tagItems={tagItems} />
    </div>
  );
}
