import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";

import {
  ExamScoreStatisticsResponse,
  ExamStatisticsResponse,
  getExamStatistics,
} from "@api/exams";

import { useStoreContext } from "@context";

import { getLastWeekTimestamp } from "@shared/services/date";

import { reportAnalysisKeys } from "../reports";
import { useRouteParams } from "../useRouteParams";

export interface ExamStatistics
  extends Omit<ExamStatisticsResponse, "examScoreStatistics"> {
  chartAvgScoreIndex: number;
  examScoreStatistics: Partial<ExamScoreStatisticsResponse> &
    Required<Omit<ExamScoreStatisticsResponse, "queryRelative">>;
}

export const defaultScoreStatistics = {
  average: 0,
  count: 0,
  max: 0,
  median: 0,
  standardDeviationPop: 0,
  standardDeviationSamp: 0,
};

export const examStatisticsKey = {
  all: ["exam", "statistics"] as const,
  detail: (projectId: number, examId?: number) => {
    if (!examId) {
      return [...examStatisticsKey.all, projectId] as const;
    }

    return [...examStatisticsKey.all, projectId, examId] as const;
  },
};

export function useExamStatistics() {
  const { examId } = useRouteParams();
  const { projectId } = useStoreContext();
  const enabled = Boolean(examId && projectId);
  const query = useQuery({
    queryKey: examStatisticsKey.detail(projectId, examId),
    queryFn: async ({ signal }) => {
      const { result } = await getExamStatistics({
        examId,
        projectId,
        options: { signal },
      });

      return result;
    },
    enabled,
    initialData: {
      chartAvgScoreIndex: 0,
      examId: 0,
      examScoreDistribution: [],
      examScoreStatistics: { ...defaultScoreStatistics },
    } as ExamStatistics,
    initialDataUpdatedAt: getLastWeekTimestamp(),
    select: useCallback((data: ExamStatisticsResponse) => {
      const { examScoreStatistics } = data;

      return {
        ...data,
        chartAvgScoreIndex: Math.floor((examScoreStatistics.average ?? 0) / 10),
        examScoreStatistics: {
          ...defaultScoreStatistics,
          ...examScoreStatistics,
        },
      };
    }, []),
  });

  return query;
}

export function useInvalidateExamDashboardChart() {
  const client = useQueryClient();
  const { projectId } = useStoreContext();
  const { examId } = useRouteParams();

  return (submissionId?: number) => {
    client.invalidateQueries(examStatisticsKey.detail(projectId, examId));
    client.resetQueries(
      reportAnalysisKeys.analysisAll({
        projectId,
        submissionId,
      }),
    );
  };
}
