import { LocationListener, Location } from "history";
import * as React from "react";
import { withRouter, RouteComponentProps, matchPath } from "react-router-dom";
import { toast } from "react-toastify";

import { ProjectIdObserver } from "@shared/components";

import { Toastr } from "../../shared/components";
import Message from "../../shared/services/message";
import { isValidResetScrollPosition } from "../../shared/services/url";

/**
 * Prop interface
 */
export type AppContainerProps = {
  currentProjectId: number;
  setCurrentProjectId: (projectId?: number) => void;
  children: React.ReactNode;
} & RouteComponentProps;

/**
 * Container
 */
const AppContainer = ({
  currentProjectId,
  setCurrentProjectId,
  location,
  history,
  children,
}: AppContainerProps & RouteComponentProps) => {
  const savedHistoryCallback = React.useRef<LocationListener>(() => undefined);
  const [prevLocation, setPrevLocation] =
    React.useState<Location<unknown>>(location);

  const historyCallback: LocationListener = (location, action) => {

    // change the current project id
    const matches = matchPath<{ projectId: string }>(location.pathname, {
      path: "/p/:projectId",
    });
    const projectId =
      matches !== null ? Number(matches.params.projectId) : currentProjectId;
    if (currentProjectId !== projectId) {
      toast.info(Message.getMessageByKey("message.currentProject.changed"));
      setCurrentProjectId(projectId);
    }

    if (isValidResetScrollPosition(location, prevLocation, action)) {
      window.scrollTo(0, 0);
      Array.from(
        document.querySelectorAll("[data-track-scroll-reset]"),
      ).forEach((element) => {
        element.scrollTo(0, 0);
      });
    }

    setPrevLocation(location);
  };

  React.useEffect(() => {
    savedHistoryCallback.current = historyCallback;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevLocation, currentProjectId]);

  React.useEffect(() => {
    const unlisten = history.listen(savedHistoryCallback.current);
    return function cleanup() {
      unlisten();
    };
  }, [history, prevLocation, currentProjectId]);

  return (
    <div className="code-app-container">
      <ProjectIdObserver />
      {children}
      <Toastr />
    </div>
  );
};

export default withRouter(AppContainer);
