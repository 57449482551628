import * as React from "react";

import {
  EmptyState,
  Msg,
  ScoreDistributionTable,
  StackedBarChart,
  StackedBarChartItem,
  StackedBarChartProps,
} from "@shared/components";
import { useExamStatistics } from "@shared/hooks/query";
import { breakpoints, useBreakpoints } from "@shared/hooks/useBreakpoints";
import { ChartColor } from "@shared/services/enums";
import Message from "@shared/services/message";

function ExamAnalysis() {
  const {
    data: { chartAvgScoreIndex, examScoreDistribution, examScoreStatistics },
  } = useExamStatistics();

  const xLabel = `${Message.getMessageByKey("score")} (%)`;
  const markerLabel = Message.getMessageByKey("report.average");
  const color = { [xLabel]: ChartColor.Primary };

  const { items, xTicks } = React.useMemo(() => {
    const formatScore = examScoreDistribution.reduce(
      (acum, { leftClosedBoundary, rightClosedBoundary, frequency }) => {
        acum.data.push(frequency);
        acum.xTicks.push(
          `${leftClosedBoundary}${
            rightClosedBoundary !== 100 ? `-${rightClosedBoundary}` : ""
          }`,
        );

        return acum;
      },
      { data: [] as number[], xTicks: [] as string[] },
    );

    return {
      xTicks: formatScore.xTicks,
      items: [
        { data: formatScore.data, label: xLabel },
      ] satisfies StackedBarChartItem[],
    };
  }, [examScoreDistribution, xLabel]);

  const screensize = useBreakpoints();

  const options = React.useMemo(() => {
    let rotate = 0;

    if (screensize === breakpoints.md) rotate = 30;
    else if (screensize === breakpoints.sm) rotate = 45;

    return {
      height: 258,
      maxYTicks: 5,
      xTick: { rotate },
      parentWidthResizing: [breakpoints.md, breakpoints.sm].includes(
        screensize,
      ),
      padding: {
        right: 10,
      },
    } satisfies StackedBarChartProps["options"];
  }, [screensize]);

  return (
    <article className="code-exam-dashboard__panel">
      <h3 className="text-heading3">
        <Msg id="exam.dashboard.scoreDistribution" tagName="none" />
      </h3>
      <div className="code-c-exam-analysis__content">
        {examScoreStatistics.count === 0 ? (
          <EmptyState />
        ) : (
          <div className="code-c-exam-analysis__content__chart">
            <StackedBarChart
              colors={color}
              items={items}
              xTicks={xTicks}
              yLabel={Message.getMessageByKey("question.submissionCount")}
              theme="analysis"
              options={options}
              chartMarkerProps={{
                label: markerLabel,
                tickPosition: chartAvgScoreIndex,
              }}
            />
            <div>
              <ScoreDistributionTable
                averageScore={examScoreStatistics.average}
                deviation={examScoreStatistics.standardDeviationPop}
                medianScore={examScoreStatistics.median}
                theme="gray-light"
              />
            </div>
          </div>
        )}
      </div>
    </article>
  );
}

export default ExamAnalysis;
