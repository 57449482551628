import * as classnames from "classnames";

import { ProjectKind } from "@shared/services/enums";

import { Tag } from "../..";

/**
 * Prop interface
 */
export interface ProjectKindTagProps {
  kind?: number;
  className?: string;
  onClick?: () => void;
}

/**
 * React Component
 */
export function ProjectKindTag({
  kind = ProjectKind.Assessment,
  className,
  onClick,
}: ProjectKindTagProps) {
  const rootStyle = classnames("code-c-project-kind-tag", {
    [`${className}`]: Boolean(className),
  });

  return (
    <div className={rootStyle}>
      <Tag className={`code-c-project-kind-tag__${kind}`} onClick={onClick}>
        {ProjectKind.toString(kind)}
      </Tag>
    </div>
  );
}
