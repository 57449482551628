import { updateIntl } from "@shared/libs/react-intl-redux";

import { switchLocale } from "../shared/services/date";
import Message from "../shared/services/message";

const SUPPORT_LANGUAGES = ["ja", "en"];

export const i18nGetAction = (payload = "en") => {
  const locale = SUPPORT_LANGUAGES.includes(payload)
    ? payload
    : SUPPORT_LANGUAGES[1];
  Message.switchLocale(locale);
  switchLocale(locale);
  return updateIntl({ locale, messages: Message.messages });
};

export const i18nSetCookieAction = (payload = "en") => {
  if (document) {
    const locale = SUPPORT_LANGUAGES.includes(payload)
      ? payload
      : SUPPORT_LANGUAGES[1];
    document.cookie = `PLAY_LANG=${locale};path=/`;
  }
  return { type: "UPDATE_COOKIE", payload: payload };
};
