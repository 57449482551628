import * as c3 from "c3";
import "c3/c3.css";
import { Color as ChartColor } from "chart.js";
import * as classnames from "classnames";
import * as d3 from "d3";
import * as React from "react";
import * as shortid from "shortid";

import MSG from "@shared/services/message";

export interface DonutChartItem {
  id?: number;
  label: string;
  value: number;
}

export type DonutChartColors = {
  [id: string]: {
    fillColor?: ChartColor;
    textColor?: ChartColor;
    borderColor?: ChartColor;
  };
};

interface DonutChartProps {
  className?: string;
  title?: string;
  showTooltip?: boolean;
  items?: DonutChartItem[];
  chartSize?: number;
  colors?: DonutChartColors;
}

const DonutChart = ({
  title = "",
  items,
  colors,
  showTooltip = true,
  className,
  chartSize,
}: DonutChartProps) => {
  const [id] = React.useState(`chart${shortid.generate()}`);
  const [legendId] = React.useState(`legend_${shortid.generate()}`);
  const [chart, setChart] = React.useState<c3.ChartAPI>();

  const rootStyle = classnames("code-c-donut-chart", {
    [`${className}`]: Boolean(className),
  });

  React.useEffect(() => {
    const newColumns: [string, number][] = items?.every(
      (item) => item.value === 0,
    )
      ? []
      : items?.map((item) => [item.label, item.value]) ?? [];

    const config: c3.ChartConfiguration = {
      bindto: `#${id}`,
      data: {
        columns: newColumns,
        type: "donut",
        order: null,
        colors: colors
          ? Object.entries(colors).reduce((acc, [label, { fillColor }]) => {
              if (fillColor) {
                acc[label] = fillColor;
              }

              return acc;
            }, {})
          : undefined,
        // https://c3js.org/reference.html#data-classes
        // value is turned to c3-target-xxx
        classes: colors
          ? Object.entries(colors).reduce((acc, [label, { textColor }]) => {
              if (textColor) {
                acc[label] = textColor;
              }
              return acc;
            }, {})
          : undefined,
        ...(!newColumns.length && {
          empty: {
            label: {
              text: MSG.getMessageByKey("contract.noData"),
            },
          },
        }),
      },
      legend: {
        show: false,
      },
      tooltip: {
        show: showTooltip,
        format: {
          value: (value: number) => value?.toString() ?? "",
        },
      },
      donut: {
        title: newColumns.length ? title : "",
        width: 40,
        label: {
          format: (value: number) => value?.toString() ?? "",
        },
      },
    };

    const newChart = c3.generate(config);

    //  update border colors of arcs
    d3.selectAll(".c3-arcs")
      .select("path")
      .style(
        "stroke",
        (arcData: any, itemIdx: number, elements: HTMLDivElement[]) => {
          const arcId = arcData.data?.id;
          const el = elements[itemIdx];

          return (colors?.[arcId]?.borderColor as string) || el.style.fill;
        },
      );

    if (chartSize) {
      newChart.resize({
        width: chartSize,
        height: chartSize,
      });
    }

    setChart(newChart);
  }, [id, title, items, showTooltip, chartSize, colors]);

  // since there is no option to format the legends
  // We recreate the legends to allow formatting
  React.useEffect(() => {
    if (chart) {
      const getCustomId = (id: string) => {
        return `${legendId}_${id.split(" ").join("_")}`;
      };

      d3.select(`#${legendId}`).selectAll("*").remove();

      d3.select(`#${legendId}`)
        .insert("div", ":first-child")
        .attr("class", "code-c-donut-chart__legend")
        .selectAll("span")
        .data(items?.map((item) => item.label) ?? [])
        .enter()
        .append("div")
        .attr(
          "class",
          (id) => `${getCustomId(id)} code-c-donut-chart__legend__label`,
        )
        .attr("data-id", (id) => id)
        .html(function (id) {
          const color = chart.color(id);
          let style = `background-color: ${color};`;

          if (colors?.[id]?.borderColor) {
            style += `border: 1px solid ${colors?.[id]?.borderColor};`;
          }

          return `
            <span class="code-c-donut-chart__legend__color" style="${style}"></span>
            <span>${id}</span>
          `;
        })
        .on("mouseover", function (event: MouseEvent, id) {
          let shouldFocus = true;
          if (this) {
            shouldFocus = !Array.from(this.classList).includes(
              "code-c-donut-chart__legend__toggled",
            );
          }

          if (shouldFocus) {
            chart.focus(id);
          }
        })
        .on("mouseout", () => {
          chart.revert();
        })
        /**
         * For toggling the blur property, checks if toggled class exists
         */
        .on("click", function (event: PointerEvent, id) {
          // eslint-disable-next-line
          d3.select(this).attr("class", (d3Selection, value: number, elements: HTMLDivElement[]) => {
              const el = elements?.[0];
              if (!el?.classList) {
                return el?.className || "";
              }

              const classArr = Array.from(el?.classList);
              const toggleClass = "code-c-donut-chart__legend__toggled";
              const isToggled = classArr.includes(toggleClass);

              if (isToggled) {
                chart.defocus(id);
                classArr.pop();
                return classArr.join(" ");
              }

              return [...classArr, toggleClass].join(" ");
            },
          );
          chart.toggle(id);
        });
    }
  }, [chart, legendId, items, colors]);

  return (
    <div className={rootStyle}>
      <div id={id} />
      <div id={legendId} style={{ width: chartSize || 200 }} />
    </div>
  );
};

export default DonutChart;
