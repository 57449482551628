import ChallengeDetail from "@components/orgs/challenges/challengeDetail/ChallengeDetail.connect";

import { Overlay } from "@shared/components";
import { ExamChallengeModel } from "@shared/models";

interface ChallengeAnalysisDetailDrawerProps {
  challenge: ExamChallengeModel;
  onChallengeClose: () => void;
}

export const ChallengeAnalysisDetailDrawer = ({
  challenge,
  onChallengeClose,
}: ChallengeAnalysisDetailDrawerProps) => {
  return (
    <>
      <ChallengeDetail
        className="code-dashboard-score-distribution__drawer"
        challengeId={challenge.challengeId}
        onClose={onChallengeClose}
        version={challenge.majorVersionNumber}
        challengeVersionId={challenge.challengeVersionId}
        hidePin={false}
        showExamLevelStats
      />
      <Overlay
        className="code-dashboard-score-distribution__drawer-overlay"
        isOpen
        onClick={onChallengeClose}
      />
    </>
  );
};
