import * as d3 from "d3";
import { useEffect } from "react";

import { adjustTextPosition, getChartXPosition } from "@shared/services/chart";
import { ChartColor } from "@shared/services/enums";

export interface ChartMarkerProps {
  id: string;
  chart: c3.ChartAPI;
  label: string;
  /**
   * position where you want the marker to be.
   * If you have 10 ticks on the graph and you want it to be on the 3rd tick
   * set this to 3 or any decimal close to it. 2.5 or 3.5
   */
  tickPosition: number;
  labelDisplacement?: number;
  color?: ChartColor;
  chartType?: "bar" | "scatter";
}

export const ChartMarker = ({
  id,
  chart,
  tickPosition,
  label,
  labelDisplacement,
  color = ChartColor.Black,
  chartType = "bar",
}: ChartMarkerProps) => {
  useEffect(() => {
    const element = document.getElementById(id);
    if (!element || typeof chart.internal.x === "undefined") {
      return;
    }

    const svg = d3.select(element).select("svg");
    const chartMarkerClass = "code-chart-marker";

    // remove lines from previous render
    svg.selectAll(`.${chartMarkerClass}`).remove();

    const xPlacement = getChartXPosition({
      xScale: chart.internal.x,
      chartElement: element,
      tickPosition,
    });

    // get chart element
    const rootDom = (svg.node() as Element)?.getBoundingClientRect?.();
    const yGridLinesDom = (
      svg.select(".c3-ygrids").node() as Element
    )?.getBoundingClientRect?.();

    const extraHeight = 10;

    // space between the chart container top and the y grid lines
    const chartTopSpacing = Math.abs(rootDom.top - yGridLinesDom.top);

    const top = chartTopSpacing - extraHeight;

    const bottomExtra = chartType === "scatter" ? 4 : 0;
    const bottom = chartTopSpacing + yGridLinesDom.height + bottomExtra;

    const chartMarkerSvg = svg.append("g").attr("class", chartMarkerClass);

    // Add the average score line
    chartMarkerSvg
      .append("line")
      .attr("x1", xPlacement)
      .attr("x2", xPlacement)
      .attr("y1", top)
      .attr("y2", bottom)
      .style("stroke", color)
      .style("stroke-width", "1px");

    // add the triangle
    const x1 = xPlacement;
    const y1 = top;
    const triangleSize = 10;
    const trianglePoints = [
      x1 - triangleSize / 2 + "," + (y1 - 1), // left corner
      x1 + "," + (y1 + triangleSize), // top corner
      x1 + triangleSize / 2 + "," + (y1 - 1), // right corner
    ].join(" ");

    chartMarkerSvg
      .append("polygon")
      .attr("points", trianglePoints)
      .style("fill", color);

    // Add text label above the line
    const textElement = chartMarkerSvg
      .append("text")
      .attr("x", xPlacement + (labelDisplacement ?? 0))
      .attr("y", top - 10)
      .style("text-anchor", "middle")
      .style("fill", color)
      .style("font-size", "0.8125rem")
      .text(label);

    adjustTextPosition({
      chartElement: element,
      textElement,
    });
  }, [
    id,
    chart?.internal.x,
    tickPosition,
    label,
    labelDisplacement,
    chartType,
    color,
  ]);

  return null;
};
