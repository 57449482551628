import { filterValidProgrammingLanguages } from "@shared/services/challengeCollection";

import {
  LinkedChallengeModel,
  QuestionModel,
  CurrentVersionModel,
} from "../models";
import { ChallengeStatus, ProgrammingLanguage } from "../services/enums";
import EnumModel from "./Enum.model";

export interface ChallengeFilterFormModel {
  keyword?: string;
  styles?: number[];
  difficulties?: number[];
  programmingCategories?: number[];
  programmingLanguages?: number[];
  spokenLanguages?: string[];
  categories?: number[];
  onlyNew?: boolean;
  isLinked?: boolean;
}

export type ChallengeFilterModel = {
  programmingCategories: EnumModel[];
  programmingLanguages: EnumModel[];
  difficulties: EnumModel[];
  styles: EnumModel[];
};
class ChallengeModel {
  public averageScore?: number;
  public organizationId: number;
  public isOfficial: boolean;
  public gitUrl: string;
  public gitBranch: string;
  public gitDirectory: string;
  public storageId: string;
  public status: ChallengeStatus;
  public isAutoUpdate: boolean;
  public numberOfOrgsUsing: number;
  public originalChallengeId: number;
  public tags: Array<string> = [];

  public id: number;
  public challengeId: number;
  public isCurrent: boolean;
  public commitHash: string;
  public storageUrl: string;
  public title: string;
  public coverImage: string;
  // NOTE
  // backend returns description is undefined if description is empty.
  public description = "";
  public openTestcases: number;
  public secretTestcases: number;
  public basicTimeMinutes: number;
  public difficulty: number;
  public language: string;
  public summaryJson: {};
  public createdAt: string;
  public updatedAt: string;
  public style: number;
  public programmingLanguages: ProgrammingLanguage[] = [];
  public programmingCategories: Array<number> = [];
  public questions: QuestionModel[] = [];
  public howToSolve?: string;
  public linkedChallenge?: LinkedChallengeModel;
  public currentVersion: CurrentVersionModel;
  public challengeInsightId: number;
  public scoreDistributions: Array<{
    leftClosedBoundary: number;
    rightClosedBoundary: number;
    frequency: number;
  }> = [];

  numberOfSubmission?: number;

  // only appears if there are 10+ submissions
  medianMinutesTaken?: number;
  medianScore?: number;

  // only appears if there are 50+ submissions
  normalcyScore?: number;

  // NOTE: only for challenge select modal
  public usedChallengeVersionCode?: string;
  // NOTE: only for challenge select modal
  public favoriteId?: number;

  constructor(args?: {}) {
    Object.assign(this, args);

    if (Array.isArray(this.questions)) {
      this.questions = this.questions.map((_) => new QuestionModel(_));
    }

    const tags =
      (args && (args as { tags: Array<{ name: string }> }).tags) || [];
    this.tags = tags.map((tag) => tag.name);

    if (this.linkedChallenge) {
      this.linkedChallenge = new LinkedChallengeModel(this.linkedChallenge);
    }

    if (this.programmingLanguages) {
      this.programmingLanguages = filterValidProgrammingLanguages(
        this.programmingLanguages,
      );
    }
  }

  public getProgrammingLanguageLabels() {
    return this.programmingLanguages
      .map((_) => ProgrammingLanguage.toString(_))
      .join(" / ");
  }
}

export default ChallengeModel;
