import classnames from "classnames";
import { History } from "history";
import { isEqual } from "lodash";
import QueryString from "query-string";
import * as React from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";

import { useStoreContext } from "@context";

import {
  Table,
  TableBody,
  Pagination,
  DocumentTitle,
  Input,
  Modal,
  Msg,
  Overlay,
  Tabs,
  PageTitle,
  ExamSegmentTags,
} from "@shared/components";
import { useExamPermissions, useTierActionPermissions } from "@shared/hooks";
import {
  useDeleteSubmission,
  useCreateSubmissionCsv,
  useCreateSubmissionResetPassword,
  useUpdateSubmissionArchive,
  useUpdateSubmissionUnarchive,
  useExam,
  useSubmissionList,
  useCancelPendingSubmissionList,
} from "@shared/hooks/query";
import { PaginationModel } from "@shared/models";
import { SubmissionListModel, SubmissionAccessType } from "@shared/models";
import { SortOrderItem } from "@shared/models";
import {
  Encoding,
  ProjectRole,
  ExamDeliveryKind,
  SubmissionListKind,
  SortDirection,
  ApplicantExamStatus,
} from "@shared/services/enums";
import { getExamStatusString } from "@shared/services/exam";
import Message from "@shared/services/message";
import { parseUrl, updateUrl } from "@shared/services/queryString";
import * as urlParser from "@shared/services/urlParser";

import { ReviewDrawer } from "../../../../submissions/reviewDrawer";
import {
  SubmissionListTableHeader,
  SubmissionListTableRow,
  SubmissionListTableActionMenu,
  SubmissionListHeaderButton,
  SubmissionDownloadModal,
  DeliveryCancelModal,
  ReviewSummaryBox,
} from "./partials";
import { MultipleManageModal } from "./partials";
import useSubmissionListTabs from "./useSubmissionListTabs";

/**
 * Prop interface
 */
export interface ExternalProps {
  submissionsKind: string;
  examId: number;
  history?: History;
  location?: {
    search: string;
  };
}

export type SubmissionListProps = ExternalProps;

export type SubmissionListParams = {
  keyword: string;
  page: number | undefined;
  sortOrder: SortOrderItem;
  needsReview: boolean;
};

/**
 * Page component
 */
export function SubmissionList({
  examId,
  history,
  submissionsKind,
}: SubmissionListProps) {
  const { projectId, user } = useStoreContext();
  const rootStyle = classnames("code-submission-list");
  const {
    data: { examChallenges, examDetail },
  } = useExam();
  const archiveSubmission = useUpdateSubmissionArchive();
  const unarchiveSubmission = useUpdateSubmissionUnarchive();
  const cancelSubmission = useDeleteSubmission();
  const noReviewer = examDetail.reviewers.length === 0;
  const submissionListKind: SubmissionListKind =
    SubmissionListKind[submissionsKind];
  const submissionAccessType = user?.getAccessSubmissionType(
    parseInt(projectId.toString(), 10),
    submissionListKind,
    examDetail.reviewers,
  );
  const isReviewer =
    (user?.hasRole(
      [ProjectRole.Reviewer, ProjectRole.ProjectAdmin],
      projectId,
    ) &&
      Boolean(
        examDetail.reviewers.find((reviewer) => reviewer.id === user.id),
      )) ||
    false;

  const archived = submissionListKind === SubmissionListKind.archived;
  const applicantExamStatus: SubmissionListKind | undefined = [
    SubmissionListKind.all,
    SubmissionListKind.archived,
  ].includes(submissionListKind)
    ? undefined
    : submissionListKind;
  const statusString = getExamStatusString({ submissionListKind, archived });

  const downloadCsv = useCreateSubmissionCsv();

  const isPasswordRequired =
    examDetail.deliveryKind === ExamDeliveryKind.ID &&
    examDetail.idDeliveryConfig.passwordRequired;

  const resetPasswordSubmission = useCreateSubmissionResetPassword();
  const { canDownloadCsv, reviewAllowed } = useTierActionPermissions();
  const { canDownloadEnhancedCsv } = useExamPermissions();
  const cancelAllUnstartedDeliveries = useCancelPendingSubmissionList();
  /**
   * States
   */
  const [params, setParams] = React.useState<SubmissionListParams>({
    keyword: "",
    page: undefined,
    sortOrder: {
      column: "status",
      direction: SortDirection.Asc,
    },
    needsReview: false,
  });
  const [isEncodingOpen, setIsEncodingOpen] = React.useState<boolean>(false);
  const [isCancelOpen, setIsCancelOpen] = React.useState<boolean>(false);
  const [isResetOpen, setIsResetOpen] = React.useState<boolean>(false);
  const [cancelType, setCancelType] = React.useState<"single" | "multiple">(
    "single",
  );
  const [isManageMultiOpen, setIsManageMultiOpen] =
    React.useState<boolean>(false);
  const [selectedSubmission, setSelectedSubmission] =
    React.useState<SubmissionListModel>();
  const [reviewDrawer, setReviewDrawer] = React.useState<{
    isOpen: boolean;
    targetSubmission?: SubmissionListModel;
  }>({ isOpen: false });
  const [reviewSummary, setReviewSummary] = React.useState<{
    targetSubmission?: SubmissionListModel;
    targetElement?: HTMLDivElement;
  }>({});
  const [pagination, setPagination] = React.useState(new PaginationModel());

  const { page, ...queryParams } = params;
  const { data, isFetching: isSubmissionFetching } = useSubmissionList(
    {
      ...queryParams,
      archived,
      examId,
      limit: pagination.limit,
      offset: pagination.getAtPage(page || 0).offset,
      includeOverlappingIps: true,
      includeCheatMonitoring: true,
      ...(applicantExamStatus && {
        statuses:
          SubmissionListKind.toApplicantExamStatusesParam(submissionListKind),
      }),
    },
    true,
  );
  const { submissionList = [], submissionPagination = new PaginationModel() } =
    data || {};

  const { tabItems, onTabClick, activeIndex } = useSubmissionListTabs({
    submissionListKind,
    params,
    history,
    reviewAllowed,
  });

  /**
   * Effects
   */
  React.useEffect(() => {
    if (submissionListKind === undefined) {
      history?.replace("/404");
    }

    if (user && examDetail.id !== undefined) {
      if (submissionAccessType === SubmissionAccessType.Deny) {
        history?.replace("/error", {
          error: Message.getMessageByKey("error.accessDenied.title"),
          description: Message.getMessageByKey(
            "error.accessDenied.description",
          ),
        });
      } else {
        const {
          unread,
          inprogress,
          expired,
          submitted,
          inreview,
          reviewed,
          evaluated,
          passed,
          failed,
        } = SubmissionListKind;

        const {
          column = [unread, inprogress, expired].includes(submissionListKind)
            ? "deadline"
            : [
                submitted,
                inreview,
                reviewed,
                evaluated,
                passed,
                failed,
              ].includes(submissionListKind)
            ? "submittedAt"
            : "status",
          direction = [
            submitted,
            inreview,
            reviewed,
            expired,
            evaluated,
            passed,
            failed,
          ].includes(submissionListKind)
            ? SortDirection.Desc
            : SortDirection.Asc,
          page,
          keyword,
          needsReview,
        } = parseUrl(location?.search);

        setParams({
          keyword: urlParser.getString(keyword) || "",
          page: urlParser.getPageNo(page),
          sortOrder: {
            column,
            direction,
          },
          needsReview: urlParser.getBoolean(needsReview) || false,
        });
      }
    }
  }, [examDetail.id, history, submissionAccessType, submissionListKind, user]);

  React.useEffect(() => {
    const { keyword, page, sortOrder, needsReview } = params;

    updateUrl({
      keyword,
      page,
      needsReview,
      ...sortOrder,
    });
  }, [archived, examId, params]);

  React.useEffect(() => {
    if (!isEqual(pagination, submissionPagination)) {
      setPagination(submissionPagination);
    }
  }, [pagination, submissionPagination]);

  // requery previous page if list becomes empty due to evaluation
  React.useEffect(() => {
    if (submissionList.length === 0) {
      setParams((oldParams) =>
        oldParams.page && oldParams.page > 0
          ? { ...oldParams, page: oldParams.page - 1 }
          : oldParams,
      );
    }
  }, [submissionList]);

  React.useEffect(() => {
    if (isSubmissionFetching) {
      onUnhoverReviewStatusBar();
    }
  }, [isSubmissionFetching]);

  React.useEffect(() => {
    if (
      !reviewAllowed &&
      [SubmissionListKind.inreview, SubmissionListKind.reviewed].includes(
        submissionListKind,
      )
    ) {
      history?.replace(
        `/p/${projectId}/exams/${examId}/submissions_${SubmissionListKind.toPathname(
          submissionListKind,
          false,
        )}`,
      );
    }
  }, [reviewAllowed, examId, history, submissionListKind, projectId]);

  /**
   * Private Functions
   */
  const onCloseModal = () => {
    setIsEncodingOpen(false);
    setIsCancelOpen(false);
    setIsResetOpen(false);
    setIsManageMultiOpen(false);
    setReviewDrawer({ isOpen: false });
    setSelectedSubmission(undefined);
    // setDrawerSubmission(undefined);
  };

  const handlePageChange = ({ selected }: { selected: number }) => {
    setParams({ ...params, page: selected });
  };

  const handleSortChange = (sortOrder: SortOrderItem) => {
    setParams({ ...params, sortOrder, page: undefined });
  };

  const onDownloadCsv = (
    type: string,
    challengeIds: number[],
    columns: number[],
    encoding: Encoding,
    language: string,
    includeFormDefinitions?: boolean,
  ) => {
    const statuses =
      SubmissionListKind.toApplicantExamStatusesParam(submissionListKind) || [];

    downloadCsv.mutate({
      examId,
      keyword: params.keyword,
      statuses,
      archived,
      type,
      challengeIds,
      columns,
      encoding,
      language,
      includeFormDefinitions,
      sortOrder: params.sortOrder,
    });
    onCloseModal();
  };

  const onDownloadInitialPasswordList = () => {
    // TODO: refactor to use redirect from react router
    const parameters = {
      examId,
      keyword: params.keyword,
      statuses:
        SubmissionListKind.toApplicantExamStatusesParam(submissionListKind) ||
        [],
      archived,
      isTest: undefined,
      ...params.sortOrder,
    };
    const query =
      "?" + QueryString.stringify(parameters || {}, { arrayFormat: "bracket" });
    const url = `/api/projects/${projectId}/submissions/initialpasswordlist${query}`;
    window.location.href = url;
  };

  const onSearchChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { value: keyword } = e.currentTarget;
    setParams({ ...params, keyword, page: undefined });
  };

  const onCancel = (submission: SubmissionListModel) => {
    setIsCancelOpen(true);
    setCancelType("single");
    setSelectedSubmission(submission);
  };

  const onClickCancelAll = () => {
    setIsCancelOpen(true);
    setCancelType("multiple");
  };

  const oncancelAllUnstartedDeliveries = (memo = "") => {
    cancelAllUnstartedDeliveries.mutate({ examId, memo });
    onCloseModal();
  };

  const onCancelDelivery = (memo?: string) => {
    if (selectedSubmission) {
      cancelSubmission.mutate({ submissionId: selectedSubmission.id, memo });
    }
    onCloseModal();
  };

  const onResetPassword = (submission: SubmissionListModel) => {
    setIsResetOpen(true);
    setSelectedSubmission(submission);
  };

  const onConfirmResetPassword = () => {
    if (selectedSubmission) {
      resetPasswordSubmission.mutate(selectedSubmission.id);
    }
    onCloseModal();
  };

  const onHoverReviewStatusBar = (
    targetElement: HTMLDivElement,
    targetSubmission: SubmissionListModel,
  ) => {
    if (reviewSummary.targetSubmission?.id !== targetSubmission.id) {
      setReviewSummary({
        targetSubmission,
        targetElement,
      });
    }
  };

  const onUnhoverReviewStatusBar = () => {
    setReviewSummary({});
  };

  const pageTitle = `${Message.getMessageByKey(
    "common.applicantList",
  )} ${statusString}`;

  const isProjectAdmin = Boolean(
    user?.hasRole(ProjectRole.ProjectAdmin, projectId),
  );

  /**
   * Render
   */
  return (
    <div className={rootStyle}>
      <DocumentTitle page={pageTitle} />
      <BreadcrumbsItem to={`/p/projectName/exams/${examId}/submissions`}>
        {pageTitle}
      </BreadcrumbsItem>
      <ExamSegmentTags />
      <div className="code-submission-list__header">
        <div className="code-submission-list__header-left">
          <PageTitle className="subtitle">{statusString}</PageTitle>
          <Input
            className="code-submission-list__search"
            value={params.keyword}
            onChange={onSearchChange}
            placeholder={Message.getMessageByKey("common.emailOrName")}
          />
        </div>
        <div className="code-submission-list__header-right">
          <SubmissionListHeaderButton
            isExamDeliverer={
              submissionAccessType === SubmissionAccessType.ExamDeliverer
            }
            isPasswordRequired={isPasswordRequired}
            submissionListKind={submissionListKind}
            onClickManageMulti={() => setIsManageMultiOpen(true)}
            canDownloadCSV={canDownloadCsv || canDownloadEnhancedCsv}
            onClickDownloadCSV={() => setIsEncodingOpen(true)}
            onClickDownloadPassword={onDownloadInitialPasswordList}
          />
        </div>
      </div>
      {tabItems.length > 0 && (
        <div className="code-submission-list__tabs">
          <Tabs
            tabItems={tabItems}
            activeIndex={activeIndex}
            onClick={onTabClick}
          />
        </div>
      )}
      <Table className="code-submission-list__table">
        <SubmissionListTableHeader
          reviewer={isReviewer ? user : undefined}
          noReviewer={noReviewer}
          shareReviewsWithReviewers={examDetail.shareReviewsWithReviewers}
          applicantNameRequired={examDetail.applicantNameRequired}
          submissionListKind={submissionListKind}
          isCancelAllDisabled={Boolean(params.keyword ?? false)}
          sortItem={params.sortOrder}
          canCancelAll={
            !examDetail.archivedAt &&
            !isSubmissionFetching &&
            submissionListKind === SubmissionListKind.unread &&
            submissionList.length > 0
          }
          onClickCancelAll={onClickCancelAll}
          onClickSortItem={handleSortChange}
        />
        <TableBody>
          {submissionList.map((submission) => {
            const rowStyle = classnames({
              [`is-drawer-open`]:
                submission.id === reviewDrawer.targetSubmission?.id &&
                reviewDrawer.isOpen,
            });
            return (
              <SubmissionListTableRow
                className={rowStyle}
                key={submission.id}
                reviewer={isReviewer ? user : undefined}
                noReviewer={noReviewer}
                shareReviewsWithReviewers={examDetail.shareReviewsWithReviewers}
                submissionListKind={submissionListKind}
                canReview={reviewAllowed}
                canEvaluate={isProjectAdmin}
                submission={submission}
                actionMenu={
                  examDetail.isArchived() ? undefined : (
                    <SubmissionListTableActionMenu
                      archived={submission.archivedAt !== undefined}
                      hasPassword={isPasswordRequired}
                      status={submission.status}
                      onClickArchive={() =>
                        archiveSubmission.mutate(submission.id)
                      }
                      onClickUnarchive={() =>
                        unarchiveSubmission.mutate(submission.id)
                      }
                      onClickCancelDelivery={() => onCancel(submission)}
                      onClickResetPassword={() => onResetPassword(submission)}
                    />
                  )
                }
                onClickReviewDetail={() => {
                  setReviewDrawer({
                    isOpen: true,
                    targetSubmission: submission,
                  });
                }}
                onHoverReviewStatusBar={(e: React.FormEvent<HTMLDivElement>) =>
                  onHoverReviewStatusBar(e.currentTarget, submission)
                }
                onUnhoverReviewStatusBar={onUnhoverReviewStatusBar}
              />
            );
          })}
        </TableBody>
      </Table>
      <div className="code-submission-list__pagination">
        <Pagination
          pagination={submissionPagination}
          onPageChange={handlePageChange}
        />
      </div>
      {isEncodingOpen && (
        <SubmissionDownloadModal
          isOpen={isEncodingOpen}
          onCloseModal={onCloseModal}
          onClickOk={onDownloadCsv}
          examChallenges={examChallenges}
          examDetail={examDetail}
          hasEnhancedCSVDownloadAction={canDownloadEnhancedCsv}
        />
      )}
      {isCancelOpen && (
        <DeliveryCancelModal
          cancelMultiple={cancelType === "multiple"}
          isOpen={isCancelOpen}
          hasMemo={examDetail.deliveryKind === ExamDeliveryKind.Standard}
          onClose={onCloseModal}
          onOK={(memo) => {
            if (cancelType === "multiple") {
              oncancelAllUnstartedDeliveries(memo);
            } else {
              onCancelDelivery(memo);
            }
          }}
        />
      )}
      {reviewDrawer.isOpen && reviewDrawer?.targetSubmission && (
        <>
          <ReviewDrawer
            className="code-submission-list__review-panel"
            submission={reviewDrawer.targetSubmission}
            onClose={onCloseModal}
            onClickSubmission={() => {
              history?.push(
                `submissions/${reviewDrawer.targetSubmission?.id}/details`,
              );
            }}
            onEvaluationChange={(newStatus: ApplicantExamStatus) => {
              setReviewDrawer((prev) => {
                if (!prev.targetSubmission) {
                  return prev;
                }

                return {
                  ...prev,
                  targetSubmission: {
                    ...prev.targetSubmission,
                    status: newStatus,
                  },
                };
              });
            }}
          />
          <Overlay
            className="code-submission-list__overlay"
            isOpen={true}
            onClick={onCloseModal}
          />
        </>
      )}
      {isResetOpen && (
        <Modal
          title={`${Message.getMessageByKey("delivery.resetPassword")} ${
            !!selectedSubmission
              ? `(ID: ${selectedSubmission.applicantEmail})`
              : ""
          }`}
          isOpen={isResetOpen}
          onClose={onCloseModal}
          onClickOk={onConfirmResetPassword}
          onClickCancel={onCloseModal}
          cancelButtonLabel={Message.getMessageByKey("no")}
          cancelButtonAriaLabel="No"
          okButtonLabel={Message.getMessageByKey("yes")}
          okButtonType="danger"
          okButtonAriaLabel="Yes"
          ariaLabel="Reset Password"
        >
          <Msg id="delivery.resetPassword.confirm" />
        </Modal>
      )}

      {reviewSummary.targetSubmission && reviewSummary.targetElement && (
        <ReviewSummaryBox
          reviews={reviewSummary.targetSubmission.reviews}
          pendingReviewers={reviewSummary.targetSubmission.pendingReviewers}
          targetElement={reviewSummary.targetElement}
        />
      )}
      {isManageMultiOpen && (
        <MultipleManageModal
          onClickOk={onCloseModal}
          onClickCancel={onCloseModal}
          isOpen={isManageMultiOpen}
          submissionListKind={submissionListKind}
        />
      )}
    </div>
  );
}
