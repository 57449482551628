import * as classnames from "classnames";

import { Restricted, Tooltip, Icon, Button } from "@shared/components";
import { ProjectRole, ApplicantExamStatus } from "@shared/services/enums";
import Message from "@shared/services/message";

/**
 * Interface
 */
interface SubmissionListTableActionMenuProps {
  archived: boolean;
  hasPassword: boolean;
  status: number;
  onClickUnarchive: () => void;
  onClickArchive: () => void;
  onClickCancelDelivery: () => void;
  onClickResetPassword: () => void;
}

/**
 * Component
 * @param props SubmissionListTableActionMenuProps
 */
export function SubmissionListTableActionMenu({
  archived,
  hasPassword,
  status,
  onClickArchive,
  onClickUnarchive,
  onClickCancelDelivery,
  onClickResetPassword,
}: SubmissionListTableActionMenuProps) {
  const rootStyle = classnames("code-submission-list-table-action-menu");
  return (
    <div className={rootStyle}>
      <Restricted
        roles={[ProjectRole.ProjectAdmin]}
        strictAllow={
          !archived &&
          status !== ApplicantExamStatus.Unread &&
          status !== ApplicantExamStatus.Canceled
        }
      >
        <Tooltip
          text={Message.getMessageByKey("common.archive")}
          placement="top-end"
        >
          <Button
            size={"small"}
            shrink={true}
            onClick={onClickArchive}
            ariaLabel="Archive"
          >
            <span className="code-c-icon icon">
              <i className="code-submission-list-table-action-menu__icon archive" />
            </span>
          </Button>
        </Tooltip>
      </Restricted>
      <Restricted
        roles={[ProjectRole.ProjectAdmin]}
        strictAllow={
          archived &&
          status !== ApplicantExamStatus.Unread &&
          status !== ApplicantExamStatus.Canceled
        }
      >
        <Tooltip
          text={Message.getMessageByKey("common.unarchive")}
          placement="top-end"
        >
          <Button
            size="small"
            shrink={true}
            onClick={onClickUnarchive}
            ariaLabel="Unarchive"
          >
            <span className="code-c-icon icon">
              <i className="code-submission-list-table-action-menu__icon unarchive" />
            </span>
          </Button>
        </Tooltip>
      </Restricted>
      <Restricted
        roles={[ProjectRole.ProjectAdmin]}
        strictAllow={status === ApplicantExamStatus.Unread}
      >
        <Tooltip text={Message.getMessageByKey("cancel")}>
          <Button
            size="small"
            shrink={true}
            onClick={onClickCancelDelivery}
            ariaLabel="Cancel Delivery"
          >
            <Icon type="trash" />
          </Button>
        </Tooltip>
      </Restricted>
      <Restricted
        roles={[ProjectRole.ProjectAdmin, ProjectRole.ExamDeliverer]}
        strictAllow={
          !archived && hasPassword && status !== ApplicantExamStatus.Canceled
        }
      >
        <Tooltip
          text={Message.getMessageByKey("delivery.resetPassword")}
          placement="top-end"
        >
          <Button
            size="small"
            shrink={true}
            onClick={onClickResetPassword}
            ariaLabel="Reset Password"
          >
            <Icon type="key" />
          </Button>
        </Tooltip>
      </Restricted>
    </div>
  );
}
