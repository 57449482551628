import { EnvTag, Msg } from "@shared/components";
import initialData from "@shared/services/initialData";

export type DiffViewerHeaderProps = {
  challengeName: string;
  applicantName: string;
  examName: string;
  totalTestcases: number;
  successfulTestcases: number;
};

// TODO: taken from code playback header, refactor when codeplayback is merged to dev
export const DiffViewerHeader = (props: DiffViewerHeaderProps) => {
  const {
    challengeName,
    applicantName,
    examName,
    successfulTestcases,
    totalTestcases,
  } = props;

  const envString = (initialData.env || "").toLowerCase();
  const shouldShowData =
    challengeName &&
    applicantName &&
    typeof totalTestcases === "number" &&
    typeof successfulTestcases === "number";

  return (
    <header className="code-diff-viewer-header">
      <div className="code-diff-viewer-header__left">
        {shouldShowData && (
          <>
            <div className="text-lg is-text-ellipsis">{examName}</div>
            <div className="code-diff-viewer-header__score__divider">|</div>
            <div className="code-diff-viewer-header__title is-text-ellipsis">
              {challengeName}
            </div>
          </>
        )}
      </div>
      <div className="code-diff-viewer-header__right">
        <div className="is-text-ellipsis">{applicantName}</div>
        <div className="code-diff-viewer-header__score__divider">|</div>
        <div className="code-diff-viewer-header__score">
          <div>
            <Msg id="highestScore" />:
          </div>
          <div className="code-diff-viewer-header__score-badge">
            <div className="code-diff-viewer-header__score-bold">
              {successfulTestcases}
            </div>
            <div>/</div>
            <div>{totalTestcases}</div>
          </div>
        </div>
        {envString !== "production" && <EnvTag envString={envString} />}
      </div>
    </header>
  );
};
