import { AxiosRequestConfig } from "axios";

import { PinnedChallengeModel } from "@shared/models";

import { ApiResponse, _delete, get, post } from "../httpClient";

export interface ChallengePinParams<T = any> {
  pinId: number;
  projectId: number;
  options?: AxiosRequestConfig<T>;
}

export async function deleteChallengePin({
  pinId,
  projectId,
  options,
}: ChallengePinParams) {
  const { data } = await _delete<ApiResponse<PinnedChallengeModel>>(
    `/api/projects/${projectId}/challenges/favorites/${pinId}`,
    options,
  );

  return data;
}

export async function postChallengePin({
  projectId,
  options,
}: Omit<ChallengePinParams<{ challengeId: number }>, "pinId">) {
  const { data } = await post<ApiResponse<PinnedChallengeModel>>(
    `/api/projects/${projectId}/challenges/favorites`,
    options,
  );

  return data;
}

export interface ChallengePinListResponse {
  challengeFavorites: PinnedChallengeModel[];
}

export async function getChallengePinList({
  projectId,
  options,
}: Omit<ChallengePinParams, "pinId">) {
  const { data } = await get<ChallengePinListResponse>(
    `/api/projects/${projectId}/challenges/favorites`,
    options,
  );

  return data;
}
