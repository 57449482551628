import { connect } from "react-redux";
import { Action, Dispatch } from "redux";

import { challengeGetAction } from "../../../../../../actions";
import { RootState } from "../../../../../../reducers";
import { ExamChallengePrint, ExternalProps } from "./ExamChallengePrint";

const mapStateToProps = (state: RootState, props: ExternalProps) => ({
  readmeList: state.readme.readmeList,
  challengeList: state.challenge.challengeDetailList,
  ...props,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  getChallenge: (challengeId: number) => {
    dispatch(challengeGetAction.request(challengeId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ExamChallengePrint);
