import { MailStatus, ProjectRole, UserRole } from "../services/enums";
import { ProjectSwitchItemModel } from "./ProjectSwitchItem.model";
import { UserRoleModel } from "./User.model";

class PendingInviteModel {
  public id: number;
  public email: string;
  public isExpired: boolean;
  public isReactivation: boolean;
  public createdAt: string;
  public updatedAt: string;
  public expiresAt: string;
  public roles: Array<UserRoleModel> = [];
  public mailStatus: MailStatus;
  public mailStatusReason: string;

  constructor(args: {}) {
    Object.assign(this, args);
  }
  public getRoles(projectId?: number): number[] {
    return this.roles
      .filter((role) => role.projectId === projectId)
      .map((role) => role.role);
  }

  public getOrgRoles() {
    const roles = this.roles.map((role) => role.role);

    // if no project roles then could only be an org admin
    return roles
      .filter(
        (role) => role === UserRole.OrgAdmin || role === UserRole.SystemAdmin,
      )
      .map((role) => UserRole.toString(role));
  }

  public getRoleLabelByProject(projects: ProjectSwitchItemModel[]): {
    projectName?: string;
    roles: string[];
  }[] {
    const inviteeRolesByProjectId = this.roles.reduce(
      (arr, role) => {
        if (role.projectId) {
          const projectRoles = arr[role.projectId];
          arr[role.projectId] = projectRoles
            ? [...projectRoles, role.role]
            : [role.role];
        }

        return arr;
      },
      {} as Record<string, number[]>,
    );

    const projectsById = projects.reduce(
      (arr, project) => {
        arr[project.id] = project;
        return arr;
      },
      {} as Record<string, ProjectSwitchItemModel>,
    );

    const projectRoles = Object.entries(inviteeRolesByProjectId)
      .filter(([projectId]) => projectsById[projectId])
      .map(([projectId, roles]) => {
        const projectRoles = roles
          .filter((role) => ProjectRole[role])
          .map((role) => ProjectRole.toString(role));

        return {
          projectName: projectsById[projectId].name,
          roles: projectRoles,
        };
      })
      .flat();

    if (projectRoles.length) {
      return projectRoles;
    }

    return [
      {
        roles: this.getOrgRoles(),
      },
    ];
  }

  public getRoleLabel(): Array<string> {
    const roles = this.roles.map((role) => role.role);

    const projectRoles = roles
      .filter((role) => ProjectRole[role])
      .map((role) => ProjectRole.toString(role));

    if (projectRoles.length) {
      return projectRoles;
    }

    // if no project roles then could only be an org admin
    return this.getOrgRoles();
  }
}

export default PendingInviteModel;
