import { IntlProvider } from "react-intl";
import { connect } from "react-redux";

import { IntlState } from "../types";

function defaultSelector(state: { intl: IntlState }) {
  const { intl } = state;
  return {
    key: intl.locale,
    ...intl,
  };
}

const mapStateToProps = (
  state: { intl: IntlState },
  { intlSelector = defaultSelector },
) => intlSelector(state);

// declaring as "any" should be fine. connect() requires a key prop on the passed component
// but react doesn't require to explicitly declare "any" in the prop types
// This will be automatically fixed when react-redux package is updated to the latest version
export default connect(mapStateToProps)(IntlProvider as any);
