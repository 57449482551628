import * as classnames from "classnames";
import * as React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import { Tabs, TabItem, Msg } from "@shared/components";

import ChallengeList from "../../challenges/challengeList/ChallengeList.connect";
import QuestionList from "../../questions/questionList/QuestionList.connect";

export type InjectedProps = {
  currentProjectId: number;
};

export type ContentsListProps = InjectedProps;

/**
 * Page component
 */
class ContentsList extends React.Component<ContentsListProps> {
  private tabItems: TabItem[];

  constructor(props: ContentsListProps) {
    super(props);

    const { currentProjectId } = props;

    this.tabItems = [
      {
        label: <Msg id="common.challenges" />,
        to: `/p/${currentProjectId}/challenges`,
      },
      {
        label: <Msg id="common.questions" />,
        to: `/p/${currentProjectId}/questions`,
      },
    ];
  }

  public render() {
    const rootStyle = classnames("code-contents-list");

    return (
      <div className={rootStyle}>
        <Tabs tabItems={this.tabItems} />
        <div>
          <Switch>
            <Route
              path="/p/:projectId/challenges"
              exact={true}
              render={({ location }) => <ChallengeList location={location} />}
            />
            <Route
              path="/p/:projectId/challenges/:challengeId"
              exact={true}
              render={({ match }) => (
                <Redirect
                  to={`/p/${match.params.projectId}/challenges?selectedId=${match.params.challengeId}`}
                />
              )}
            />
            <Route
              path="/p/:projectId/questions"
              exact={true}
              render={({ location }) => <QuestionList location={location} />}
            />
          </Switch>
        </div>
      </div>
    );
  }
}

export default ContentsList;
