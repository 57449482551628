import {
  ExamChallengeModel,
  ExamChallengeSetModel,
  CustomFormDefinitionModel,
  MemberListModel,
  UrlSharingConfigModel,
  IdDeliveryConfigModel,
  ApplicantActionSettingsModel,
  AutoFilterSettingsModel,
  GuestSharingSettingsModel,
} from "@shared/models";
import {
  ExamDeliveryKind,
  ExamType,
  SpokenLanguages,
  UserStatus,
} from "@shared/services/enums";
import { getExamName } from "@shared/services/exam";

import { WebcamSettingsModel } from "./WebcamSettings.model";

interface ExamCreatorInfo {
  email: string;
  imageUrl: string;
  name: string;
  status: UserStatus;
  userId: number;
}

class ExamModel {
  public id: number;
  public organizationId: number;
  public projectId: number;
  public name: string;
  public enName?: string;
  public status: number;
  public deliveryKind: ExamDeliveryKind;
  public outline: string;
  public description: string;
  public enDescription?: string;
  public language: SpokenLanguages;
  public urlPath: string;
  public examType: ExamType;
  public createdBy: number;
  public updatedBy: number;
  public createdAt: string;
  public updatedAt: string;
  public archivedAt: string;
  public urlDeliveryEnabled: boolean;
  public guestSharingEnabled: boolean;
  /**
   * @deprecated use guestSharingSettings
   */
  public showSecretTestCases: boolean;
  /**
   * @deprecated use guestSharingSettings
   */
  public guestSharingShowReviews: boolean;
  /**
   * @deprecated use guestSharingSettings
   */
  public guestSharingShowName: boolean;
  /**
   * @deprecated use guestSharingSettings
   */
  public guestSharingShowInsights: boolean;
  public urlToken: string;
  public applicantNameRequired: boolean;
  public isChallengesModificationAllowed: boolean;
  public hasOnlySampleDelivery?: boolean;
  public urlSharingConfig: UrlSharingConfigModel;
  public challengesSets: ExamChallengeSetModel[] = [];
  public formDefinitions: CustomFormDefinitionModel[];
  public reviewers: MemberListModel[];
  public reportingEnabled = true;
  public atsIntegrationEnabled: boolean;
  public atsDynamicDeadlineDays: number;
  public idDeliveryConfig: IdDeliveryConfigModel;
  public nextStepInfo?: string;
  public memo?: string;
  public isOfficial = false;
  public coverImageUrl?: string;
  public usingOrganizationsCount: number;
  public examinationTime: number;
  public sharingGroupIds: string[];
  public shareReviewsWithReviewers: boolean;
  public deadlineNotificationEnabled: boolean;

  public guestSharingSettings: GuestSharingSettingsModel;
  public webcamSettings: WebcamSettingsModel;

  public examSegment?: {
    engineerRole: number;
    purposeOfUse: string;
  };
  public reviewSettings: {
    requiredReviews:
      | {
          requiredType: "all";
        }
      | {
          requiredType: "count";
          count: number;
        };
  };
  public applicantActionSettings: ApplicantActionSettingsModel =
    {} as ApplicantActionSettingsModel;

  /** @deprecated we now have a dedicated endpoint for fetching this part of the exam detail. Please use the new endpoint getExamCount(); src/api/exams/exam.ts #45 */
  public counts: {
    sentDeliveryCount?: number;
    unreadDeliveryCount?: number;
    inProgressCount?: number;
    submittedCount?: number;
    inReviewCount?: number;
    approvedCount?: number;
    rejectedCount?: number;
    expiredCount?: number;
    archivedCount?: number;
    scheduledDeliveriesCount?: number;
    pendingDeliveryCount?: number;
    sentDeliveryExceptArchivedCount?: number;
  } = {};
  public estimatedCompletionTime: number; // minutes
  public creatorInfo: ExamCreatorInfo;
  public autoFilterSettings?: AutoFilterSettingsModel;

  constructor(args?: {}) {
    Object.assign(this, args);

    if (this.challengesSets) {
      this.challengesSets = this.challengesSets.map(
        (challengeSet) => new ExamChallengeSetModel(challengeSet),
      );
    }
  }

  public isChallengeEditable() {
    return this.isChallengesModificationAllowed;
  }

  public isEmpty() {
    return this.id === undefined;
  }

  public isArchived() {
    return this.archivedAt !== undefined;
  }

  /**
   * convert challenges in the challengeSet to challenges
   * NOTE: might it be a temporary solution
   */
  public getChallenges() {
    return [...this.challengesSets]
      .sort((a, b) => a.displayOrder - b.displayOrder)
      .reduce((challenges, challengeSet) => {
        return [
          ...challenges,
          ...challengeSet.challenges
            .sort((a, b) => a.displayOrder - b.displayOrder)
            .map((challenge) => new ExamChallengeModel(challenge)),
        ];
      }, []);
  }

  /**
   * convert challenges in the challengeSet
   * and linked challenges to challenges
   */
  public getMultiLangChallenges() {
    return [...this.challengesSets]
      .sort((a, b) => a.displayOrder - b.displayOrder)
      .reduce(
        (pre, current) => [
          ...pre,
          ...current.challenges,
          ...(this.examType === ExamType.EnglishJapanese
            ? current.challenges.map((challenge) => {
                const linkedChallenge =
                  challenge.linkedChallenge ||
                  challenge.originalChallenge.linkedChallenge;
                return new ExamChallengeModel({
                  ...linkedChallenge,
                  majorVersionNumber: challenge.linkedMajorVersionNumber,
                  challengeId: challenge.linkedChallengeId,
                  timeLimitMinutes: challenge.timeLimitMinutes,
                  originalChallenge: {
                    ...linkedChallenge,
                    language: linkedChallenge?.language,
                  },
                  basicTimeMinutes:
                    linkedChallenge?.currentVersion.basicTimeMinutes,
                  usedChallengeVersionCode: `${linkedChallenge?.currentVersion.majorVersionNumber}.${linkedChallenge?.currentVersion.minorVersionNumber}`,
                });
              })
            : []),
        ],
        [],
      );
  }

  /**
   * Check if the new version of the challenge is available
   */
  public hasOutdatedChallengeVersion(): boolean {
    return this.challengesSets.some((challengeSet) =>
      challengeSet.challenges.some((challenge) =>
        this.examType === ExamType.EnglishJapanese
          ? challenge.nextAvailableChallengeVersionCode !== undefined &&
            challenge.nextAvailableLinkedChallengeVersionCode !== undefined
          : challenge.nextAvailableChallengeVersionCode !== undefined,
      ),
    );
  }

  /**
   * Get proper exam name
   */
  public getName(): string {
    return getExamName(
      this.examType === ExamType.EnglishJapanese,
      this.name,
      this.enName,
      this.language,
    );
  }
}

export default ExamModel;
