import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { Switch, Route } from "react-router-dom";

import { DocumentTitle, Msg } from "../../../shared/components";
import Message from "../../../shared/services/message";
import Setting from "./setting/Setting.connect";

/**
 * Route settings
 */
export const SettingsRoute = () => (
  <div>
    <DocumentTitle page={Message.getMessageByKey("settings")} />
    <BreadcrumbsItem to="/settings">
      <Msg id="settings" />
    </BreadcrumbsItem>
    <Switch>
      <Route path="/settings" component={Setting} />
      <Route path="/p/:projectId/settings" component={Setting} />
    </Switch>
  </div>
);
