import * as classnames from "classnames";
import { isEqual } from "lodash";
import * as React from "react";

/**
 * Prop interface
 */
export interface TableBodyProps {
  className?: string;
  children?: {};
  ariaExpanded?: boolean;
}

/**
 * React Component
 */
export default class TableBody extends React.Component<TableBodyProps, {}> {
  public shouldComponentUpdate(nextProps: TableBodyProps) {
    return !isEqual(nextProps, this.props);
  }

  public render() {
    const { className, ariaExpanded, children } = this.props;

    const rootStyle = classnames("code-c-table-body", {
      [`${className}`]: Boolean(className),
    });

    return (
      <tbody className={rootStyle} aria-expanded={ariaExpanded}>
        {children}
      </tbody>
    );
  }
}
