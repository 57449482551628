import { AxiosRequestConfig } from "axios";

import { get } from "@api/httpClient";

import { LocalFileMetaData } from "@shared/models";

interface SubmissionResultLocalFilesMetaDataParams {
  challengeResultId: number;
  projectId: number;
  submissionId: number;
  options?: AxiosRequestConfig;
}

export async function getSubmissionResultLocalFilesMetadata({
  projectId,
  submissionId,
  challengeResultId,
  options,
}: SubmissionResultLocalFilesMetaDataParams) {
  const { data } = await get<LocalFileMetaData[]>(
    `/api/projects/${projectId}/submissions/${submissionId}/results/${challengeResultId}/localfiles/metadata`,
    options,
  );

  return data;
}

export async function getSubmissionResultLocalFile({
  projectId,
  submissionId,
  challengeResultId,
  options,
}: SubmissionResultLocalFilesMetaDataParams) {
  const { data } = await get<string>(
    `/api/projects/${projectId}/submissions/${submissionId}/results/${challengeResultId}/localfiles/download/presigned`,
    options,
  );

  return data;
}
