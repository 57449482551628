import * as classnames from "classnames";
import { isEqual, isArray } from "lodash";
import * as React from "react";

import { ChallengesListFormValues } from "@components/orgs/challenges/challengeList/ChallengeList";
import { ChallengeSelectForm } from "@components/orgs/challenges/challengeSelect/ChallengeSelect";

import {
  Form,
  SubMenu,
  SubMenuList,
  SubMenuLabel,
  SubMenuCheckboxItem,
  FormGroup,
  Label,
  Input,
  Msg,
  Radio,
  MoreLink,
  Tooltip,
} from "..";
import { FormProps } from "../form/Form";

/**
 * Prop interface
 */
export interface ContentsSearchFormProps {
  onChange?: FormProps["onFormChange"];
  initialValues?: {};
  filters?: Array<{
    title: React.ReactNode;
    value: string;
    optionKind?: "checkbox" | "radio";
    options:
      | Array<{
          value: number | string;
          label: string | JSX.Element;
          count: number;
          disabled?: boolean;
          tooltip?: string;
        }>
      | {
          value: boolean;
          label: string | JSX.Element;
          count: number;
        };
    moreLink?: boolean;
  }>;
  className?: string;
  noKeyword?: boolean;
  keywordHint?: React.ReactNode;
  disableOptionKeys?: string[];
  clear?: boolean;
}

export interface ContentsSearchFormState {
  formValues: ChallengesListFormValues | ChallengeSelectForm;
  showMoreLink: { [key: string]: boolean };
}

const DISPLAY_COUNT = 5;

const FilterComponentForm = ({
  title,
  children,
}: {
  title: React.ReactNode;
  children: React.ReactNode;
}) => {
  return (
    <div className="code-c-contents-search-form__filter">
      <SubMenuLabel>{title}</SubMenuLabel>
      <SubMenuList>{children}</SubMenuList>
    </div>
  );
};

/**
 * React Component
 */
export default class ContentsSearchForm extends React.Component<
  ContentsSearchFormProps,
  ContentsSearchFormState
> {
  constructor(props: ContentsSearchFormProps) {
    super(props);

    this.state = {
      formValues: props.initialValues || {},
      showMoreLink: {},
    };
  }
  public componentDidUpdate(prevProps: ContentsSearchFormProps) {
    if (!prevProps.clear && this.props.clear) {
      this.setState({
        formValues: this.props.initialValues || {},
        showMoreLink: {},
      });
    }
  }

  public shouldComponentUpdate(
    nextProps: ContentsSearchFormProps,
    nextState: ContentsSearchFormState,
  ) {
    return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state);
  }

  public render() {
    const {
      className,
      filters = [],
      initialValues = {},
      clear,
      keywordHint,
      disableOptionKeys = [],
    } = this.props;
    const rootStyle = classnames("code-c-contents-search-form", {
      [`${className}`]: Boolean(className),
    });
    const filterComponents = filters.map((filter, index) => {
      if (isArray(filter.options)) {
        const value = this.state.formValues[filter.value];
        const haveAlreadyChecked = Array.isArray(value)
          ? Boolean(value.length)
          : false;

        return (
          <FilterComponentForm title={filter.title} key={index}>
            {(!filter.moreLink ||
            haveAlreadyChecked ||
            this.state.showMoreLink[filter.value]
              ? filter.options
              : filter.options.slice(0, DISPLAY_COUNT)
            ).map((option) => {
              const checked = (Array.isArray(value) ? value : [value]).includes(
                option.value,
              );

              const isOptionDisabled =
                disableOptionKeys.includes(filter.value) || option.disabled;

              if (filter.optionKind === "radio") {
                <div
                  className="code-c-contents-search-form__radio"
                  key={option.value}
                >
                  <Tooltip
                    key={option.value}
                    disabled={!option.tooltip}
                    placement="top-start"
                    text={option.tooltip}
                  >
                    <Radio
                      defaultValue={option.value.toString()}
                      value={Array.isArray(value) ? value[0] : value}
                      readOnly={isOptionDisabled}
                      onChange={() =>
                        this.onToggleRadioGroup(filter.value, option.value)
                      }
                    >
                      {option.label}
                    </Radio>
                  </Tooltip>
                </div>;
              }

              return (
                <SubMenuCheckboxItem
                  key={option.value}
                  value={checked}
                  onChange={() =>
                    this.onToggleCheckboxGroup(
                      checked,
                      filter.value,
                      option.value,
                    )
                  }
                  disabled={isOptionDisabled}
                  count={option.count}
                >
                  <Tooltip
                    key={option.value}
                    disabled={!option.tooltip}
                    placement="top-start"
                    text={option.tooltip}
                  >
                    <>{option.label}</>
                  </Tooltip>
                </SubMenuCheckboxItem>
              );
            })}
            <div>
              {filter.moreLink && !haveAlreadyChecked && (
                <MoreLink
                  totalCount={filter.options.length}
                  displayCount={DISPLAY_COUNT}
                  onClick={() =>
                    this.setState({
                      showMoreLink: {
                        ...this.state.showMoreLink,
                        [filter.value]: !this.state.showMoreLink[filter.value],
                      },
                    })
                  }
                  showMore={!this.state.showMoreLink[filter.value]}
                />
              )}
            </div>
          </FilterComponentForm>
        );
      } else {
        // MEMO: it comes string from URL, but it comes boolean from state.
        const checked =
          this.state.formValues[filter.value] === true ||
          this.state.formValues[filter.value] === "true";
        const { value, count, label } = filter.options;

        return (
          <FilterComponentForm title={filter.title} key={index}>
            <SubMenuCheckboxItem
              key={`is${value}`}
              value={checked}
              onChange={() => this.onToggleCheckbox(checked, filter.value)}
              disabled={disableOptionKeys.includes(filter.value)}
              count={count}
            >
              {label}
            </SubMenuCheckboxItem>
          </FilterComponentForm>
        );
      }
    });

    return (
      <SubMenu className={rootStyle}>
        <Form
          validation={{
            keyword: ["string"],
          }}
          onFormChange={this.onFormChange}
          initialValues={initialValues}
          clear={clear}
        >
          {!this.props.noKeyword && (
            <FormGroup>
              <Label>
                <Msg id="form.keyword" />
              </Label>
              <Input name="keyword" />
              {keywordHint && (
                <p className="code-c-contents-search-form__keyword-hint">
                  {keywordHint}
                </p>
              )}
            </FormGroup>
          )}
          {filterComponents}
        </Form>
      </SubMenu>
    );
  }

  private onFormChange = (formValid: boolean, formValues: {}) => {
    if (isEqual(this.state.formValues, formValues)) {
      return;
    }

    this.setState(
      {
        formValues: { ...this.state.formValues, ...formValues },
      },
      () =>
        this.props.onChange && this.props.onChange(true, this.state.formValues),
    );
  };

  private onToggleCheckboxGroup = (
    checked: boolean,
    filterValue: string,
    optionValue: number | string,
  ) => {
    const arr = [...(this.state.formValues[filterValue] || [])];
    if (!checked) {
      arr.push(optionValue);
    } else {
      arr.splice(arr.indexOf(optionValue), 1);
    }
    this.setState(
      {
        formValues: {
          ...this.state.formValues,
          [filterValue]: arr,
        },
      },
      () => this.onFormChange(true, {}),
    );
  };

  private onToggleRadioGroup = (
    filterValue: string,
    optionValue: number | string,
  ) => {
    this.setState(
      {
        formValues: { ...this.state.formValues, [filterValue]: [optionValue] },
      },
      () => this.onFormChange(true, {}),
    );
  };

  private onToggleCheckbox = (checked: boolean, filterValue: string) => {
    this.setState(
      {
        formValues: {
          ...this.state.formValues,
          [filterValue]: String(!checked),
        },
      },
      () => this.onFormChange(true, {}),
    );
  };
}
