import { EvaluationType, SubmissionType } from "@shared/services/enums";

import {
  ApplicantModel,
  ExamChallengeSetModel,
  GuestSharingSettingsModel,
} from "./";

export type EvaluationMetaModel = {
  evaluationType: EvaluationType;
};

interface ApplicantExamsWithOverlappingIps {
  applicantExamId: number;
  overlappingAddresses: string[];
}

export interface SubmissionOverlappingIp {
  allApplicantRequestIps: string[];
  overlappingApplicantExams: ApplicantExamsWithOverlappingIps[];
  hasOverlappingApplicantExams?: boolean;
}

export interface SubmissionCheatMonitoring {
  forExam?: {
    sessionData: {
      isSignificant: boolean;
      uniqueSessionCount: number;
    };
  };
  forChallenges?: Challenge[];
}

export interface Challenge {
  challengeResultId: number;
  challengeId: number;
  examChallengeId: number;
  copyPaste?: {
    isSignificant: boolean;
    significantPasteCount: number;
    longestPasteLength: number;
  };
  leftTab?: {
    isSignificant: boolean;
    totalTimeAwaySeconds: number;
    longestTimeAwaySeconds: number;
  };
  screenshot?: {
    isSignificant: boolean;
    keyCombinations: string[][];
  };
}

export interface ApplicantSegmentModel {
  applicantExperience?: string;
}

class SubmissionModel {
  public applicant: ApplicantModel = new ApplicantModel();
  public applicantId: number;
  public applicantName: string;
  public deadline: string;
  public deliveryId: number;
  public examId: number;
  public examName: string;
  public examEnName?: string;
  public formValues: {} = {};
  public orgName?: string;
  public orgDisplayName?: string;
  public examNaturalLanguage: string;
  public id: number;
  public isTest: boolean;
  public mailStatus: number;
  public finalScore?: number;
  public status: number;
  public language?: string;
  public createdAt: string;
  public updatedAt: string;
  public deliveredAt: string;
  public submittedAt: string;
  public openedAt: string;
  public archivedAt: string;
  public urlToken: string;
  public guestSharingToken: string;
  public guestSharingUrl: string;
  public submissionType: SubmissionType;
  public applicantSegment: ApplicantSegmentModel;
  public guestSharingSettings?: GuestSharingSettingsModel;

  // NOTE it is for the guest sharing page
  public challengesSets: ExamChallengeSetModel[] = [];

  public reportingEnabled?: boolean;
  public reviewsEnabled?: boolean;
  public insightsEnabled?: boolean;
  public nameEnabled?: boolean;

  public evaluationMeta?: EvaluationMetaModel;
  public overlappingIpAddresses?: SubmissionOverlappingIp;
  public cheatMonitoring?: SubmissionCheatMonitoring;

  constructor(args?: {}) {
    Object.assign(this, args);
    if (args) {
      const submission = args as SubmissionModel;
      if (Array.isArray(submission.challengesSets)) {
        this.challengesSets = submission.challengesSets.map(
          (item) => new ExamChallengeSetModel(item),
        );
      }
    }
  }

  public getExamName(): string {
    return this.language
      ? this.language === "en" && this.examEnName
        ? this.examEnName
        : this.examName
      : this.examName;
  }

  public hasOverlappingApplicantExams() {
    return Boolean(
      this.overlappingIpAddresses?.overlappingApplicantExams.length,
    );
  }
}

export default SubmissionModel;
