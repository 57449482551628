import { connect } from "react-redux";
import { Action, Dispatch } from "redux";

import { RootState } from "../../../reducers";
import { ApplicantChallengeTimerModel } from "../../../shared/models";
import CountDownTimer from "./CountDownTimer";

interface OwnProps {
  challengeId: number;
  thresholdSeconds?: number;
  className?: string;
}

/**
 * extract time left by arguments
 * @param challengeId
 * @param challengeTimeLeftSeconds
 */
export function extractTimeLeftSeconds(
  challengeId: number,
  challenges: ApplicantChallengeTimerModel[] = [],
) {
  return challengeId !== undefined
    ? challenges.find((item) => item.challengeId === challengeId)?.seconds
    : undefined;
}

const mapStateToProps = (state: RootState, props: OwnProps) => ({
  timeLeftSeconds: extractTimeLeftSeconds(
    props.challengeId,
    state.applicantTimer.challenges,
  ),
  thresholdSeconds: props.thresholdSeconds,
  className: props.className,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CountDownTimer);
