import * as classnames from "classnames";
import * as React from "react";
import { DndProvider } from "react-dnd";

import * as DragAndDropService from "../../services/dragAndDrop";

/**
 * Componentes
 */
import { DraggableItem } from "./DraggableItem";

/**
 * Props
 */
export interface SortableItemListProps {
  className?: string;
  accept?: string;
  children?: React.ReactNode;
  items: { item: React.ComponentType | JSX.Element; id: number }[];
  onReorderItem: (items: number[]) => void;
}

export function SortableItemList({
  accept,
  items,
  className,
  onReorderItem,
}: SortableItemListProps) {
  const rootStyle = classnames("code-c-sortable-item-list", {
    [`${className}`]: Boolean(className),
  });

  /**
   * States
   */
  const [sortableItems, setSortableItems] = React.useState(items);

  /**
   * Effects
   */
  React.useEffect(() => {
    setSortableItems(items);
  }, [items]);

  /**
   * Private Functions
   */
  const onDrag = (dragIndex: number, hoverIndex: number) => {
    const sortedItems = DragAndDropService.getSortItems(
      sortableItems,
      dragIndex,
      hoverIndex,
    );
    setSortableItems(sortedItems);
  };

  const onDrop = () => {
    onReorderItem(sortableItems.map((item) => item.id));
  };

  return (
    <div className={rootStyle}>
      <DndProvider backend={DragAndDropService.HTML5Backend}>
        {sortableItems.map((item, index) => {
          return (
            <DraggableItem
              accept={accept}
              key={item.id}
              id={item.id}
              index={index}
              onDrop={onDrop}
              onDrag={onDrag}
              item={item.item}
            />
          );
        })}
      </DndProvider>
    </div>
  );
}
