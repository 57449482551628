import React, { createContext, useContext, useState, ReactNode } from "react";

export type DraftFormContextType<T> = {
  draftState: T;
  setDraftState: React.Dispatch<React.SetStateAction<T>>;
};

/**
 * This context is used to store the draft state of the form. When forms contain conditional
 * rendering or unregisters, the draft state is used to store the form data so it isn't lost
 */
export const DraftFormContext = createContext<DraftFormContextType<any>>({
  draftState: {},
  setDraftState: () => {},
});

export const DraftFormProvider = <T,>({
  children,
  defaultValues,
}: {
  children: ReactNode;
  defaultValues: T;
}) => {
  const [draftState, setDraftState] = useState<T>(defaultValues);

  return (
    <DraftFormContext.Provider value={{ draftState, setDraftState }}>
      {children}
    </DraftFormContext.Provider>
  );
};

export const useDraftForm = <T,>() => {
  const context = useContext(DraftFormContext) as DraftFormContextType<T>;

  if (!context) {
    throw new Error("useDraftForm must be used within a DraftFormProvider");
  }

  return context;
};
