import { UseMutationResult } from "@tanstack/react-query";
import * as classnames from "classnames";
import { isEqual } from "lodash";
import * as React from "react";

import { ApiResponse } from "@api/httpClient";

import {
  DatePicker,
  DatePickerType,
  Form,
  FormGroup,
  Label,
  Modal,
  Table,
  TableHead,
  TableBody,
  HeaderColumn,
  Row,
  Column,
  Checkbox,
  Loading,
  Icon,
  Tooltip,
  Msg,
  UpdateAvailableTag,
} from "@shared/components";
import { UseUpdateSubmissionExtendParams } from "@shared/hooks/query";
import {
  SubmissionModel,
  SubmissionResultListModel,
  ExamChallengeModel,
  ApplicantExamsModel,
} from "@shared/models";
import {
  getChallengeStatusText,
  isNotStartedSubmission,
} from "@shared/services/challengeStatus";
import {
  areSelectedExamsUpdated,
  isSubmissionLatestVersion,
} from "@shared/services/challengeVersion";
import { dayjs, formatDateTimeMinutes } from "@shared/services/date";
import { ApplicantExamStatus } from "@shared/services/enums";
import Message from "@shared/services/message";

/**
 * Prop interface
 */

export interface ParentProps {
  isOpen: boolean;
  submission: SubmissionModel;
  onClose: () => void;
}

interface HookProps {
  examChallenges: ExamChallengeModel[];
  extendSubmission: UseMutationResult<
    ApiResponse<ApplicantExamsModel>,
    string,
    UseUpdateSubmissionExtendParams
  >;
  submissionResultList: Array<SubmissionResultListModel>;
}
export interface OwnProps {
  loading: boolean;
}

type ExtendSubmissionProps = OwnProps & ParentProps & HookProps;

/**
 * State interface
 */
export interface ExtendSubmissionState {
  confirm: boolean;
  isVersionUpdateModalOpen: boolean;
  examDeadline: string;
  formErrors: {};
  formValid: boolean;
  minDeadline: string;
  selectAll?: boolean;
  selectedChallengeIds: Array<number>;
}

/**
 * Page component
 */
class ExtendSubmission extends React.Component<
  ExtendSubmissionProps,
  ExtendSubmissionState
> {
  constructor(props: ExtendSubmissionProps) {
    super(props);

    this.state = {
      confirm: false,
      isVersionUpdateModalOpen: false,
      examDeadline: "",
      formErrors: {},
      formValid: false,
      minDeadline: dayjs().format(),
      selectedChallengeIds: [],
    };
  }

  public UNSAFE_componentWillReceiveProps(nextProp: ExtendSubmissionProps) {
    if (nextProp.isOpen) {
      // Reset all of things!!
      this.setState({
        confirm: false,
        examDeadline: "",
        formValid: false,
        minDeadline:
          dayjs().format() > dayjs(this.props.submission.deadline).format()
            ? dayjs().format()
            : dayjs(this.props.submission.deadline).format(),
        selectAll: false,
        selectedChallengeIds:
          nextProp.submission.status === ApplicantExamStatus.Expired
            ? nextProp.submissionResultList.map((s) => s.challengeId)
            : [],
      });
    }

    if (
      this.props.extendSubmission.isLoading &&
      !nextProp.extendSubmission.isLoading
    ) {
      // Finish submitting.
      this.props.onClose();
    }
  }

  public shouldComponentUpdate(
    nextProps: ExtendSubmissionProps,
    nextState: ExtendSubmissionState,
  ) {
    return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state);
  }

  public render() {
    const rootStyle = classnames("code-change-deadline");
    const { submission } = this.props;
    const { minDeadline } = this.state;

    const expiredSubmission = submission.status === ApplicantExamStatus.Expired;

    const additionalFooterContent = (
      <Checkbox
        value={this.state.confirm}
        onChange={(e) =>
          this.onToggleConfirm((e.target as HTMLInputElement).checked)
        }
      >
        <Msg id={"change-deadline.confirm"} />
      </Checkbox>
    );

    const selectedChallengesOk =
      dayjs(submission.deadline).format() !== this.state.examDeadline ||
      this.state.selectedChallengeIds.length;

    const onToggleSelectedChallenge = (id: number) => {
      let selectedChallengeIds: Array<number> = [];

      const selected = this.state.selectedChallengeIds.find(
        (item) => item === id,
      );
      if (selected) {
        selectedChallengeIds = this.state.selectedChallengeIds.filter(
          (item) => item !== id,
        );
      } else {
        selectedChallengeIds = [...this.state.selectedChallengeIds, id];
      }

      this.setState({
        selectedChallengeIds,
        selectAll:
          selectedChallengeIds.length ===
          this.props.submissionResultList.filter(
            (list) =>
              !isNotStartedSubmission(
                list.challengeResultStatus,
                submission.status,
              ),
          ).length,
      });
    };

    return (
      <>
        <Modal
          className={rootStyle}
          title={<Msg id={"change-deadline.title"} />}
          isOpen={this.props.isOpen}
          onClose={this.props.onClose}
          onClickCancel={this.props.onClose}
          okButtonLabel={<Msg id={"action.confirm"} />}
          onClickOk={() => {
            if (
              !areSelectedExamsUpdated({
                selectedChallengeIds: this.state.selectedChallengeIds,
                examChallenges: this.props.examChallenges,
                submissionResultList: this.props.submissionResultList,
              })
            ) {
              this.setState({
                isVersionUpdateModalOpen: true,
              });
            } else {
              this.onExtendSubmission(false);
            }
          }}
          okButtonAriaLabel="Confirm"
          additionalFooterContent={additionalFooterContent}
          disableOk={
            !(
              this.state.formValid &&
              this.state.confirm &&
              selectedChallengesOk
            )
          }
          size={"large"}
          ariaLabel="Change Deadline"
        >
          {submission.status === ApplicantExamStatus.InProgress && (
            <div className="code-change-deadline__in-progress">
              <Icon type={"exclamation-triangle "} />
              <Msg id={"change-deadline.warning"} />
            </div>
          )}
          <Loading isOpen={this.props.loading} />
          <Form
            clear={this.props.isOpen}
            initialValues={{
              examDeadline: dayjs(submission.deadline).format(),
            }}
            validation={{
              examDeadline: [
                "date",
                "iso",
                "required",
                "notAllowPast",
                ["min", minDeadline],
                ["within10YearsFrom", dayjs().format()],
              ],
            }}
            error={this.state.formErrors}
            onFormChange={this.onFormChange}
            showAllErrors={true}
          >
            <div className="code-change-deadline__deadline">
              <FormGroup>
                <Label title={<Msg id={"change-deadline.currentDeadline"} />} />
                <p>{formatDateTimeMinutes(submission.deadline)}</p>
              </FormGroup>
              <div className="code-change-deadline__deadline__arrow">
                <Icon type={"arrow-right"} />
              </div>
              <FormGroup>
                <Label title={<Msg id={"change-deadline.newDeadline"} />} />
                <DatePicker
                  type={DatePickerType.DateTime}
                  name={"examDeadline"}
                  minDate={minDeadline}
                  adjustPastDateToPresent={true}
                />
              </FormGroup>
            </div>
            <p className="code-change-deadline__msg">
              <Msg
                id={
                  expiredSubmission
                    ? "change-deadline.expired"
                    : "change-deadline.text"
                }
              />
            </p>
            <Table className="code-change-deadline__table">
              <TableHead>
                <HeaderColumn size={1}>
                  <Checkbox
                    value={Boolean(expiredSubmission || this.state.selectAll)}
                    readOnly={
                      expiredSubmission ||
                      this.props.submissionResultList.every((list) =>
                        isNotStartedSubmission(
                          list.challengeResultStatus,
                          submission.status,
                        ),
                      )
                    }
                    onChange={() => {
                      const selectedChallengeIds = this.state.selectAll
                        ? []
                        : this.props.submissionResultList
                            .filter(
                              (list) =>
                                !isNotStartedSubmission(
                                  list.challengeResultStatus,
                                  submission.status,
                                ),
                            )
                            .map(
                              (submissionResult) =>
                                submissionResult.challengeId,
                            );

                      this.setState({
                        selectAll: !this.state.selectAll,
                        selectedChallengeIds,
                      });
                    }}
                  />
                </HeaderColumn>
                <HeaderColumn size={5}>
                  <Msg id={"common.title"} />
                </HeaderColumn>
                <HeaderColumn size={3}>
                  <Msg id={"status"} />
                </HeaderColumn>
                <HeaderColumn size={3}>
                  <Msg id={"timeLimit"} />
                </HeaderColumn>
              </TableHead>
              <TableBody>
                {this.props.submissionResultList.map((submissionResult) => {
                  const statusText = getChallengeStatusText(
                    submissionResult.challengeResultStatus,
                    submission.status,
                  );

                  const challenge = this.props.examChallenges.find(
                    (examChallenge) =>
                      examChallenge.challengeId ===
                        submissionResult.challengeId ||
                      examChallenge.linkedChallengeId ===
                        submissionResult.challengeId,
                  );

                  const hasAvailableVersion =
                    !!challenge &&
                    !isSubmissionLatestVersion({
                      examChallenge: challenge,
                      submissionResult,
                    });

                  const checkBox = (
                    <Checkbox
                      value={Boolean(
                        expiredSubmission ||
                          this.state.selectedChallengeIds.find(
                            (item) => item === submissionResult.challengeId,
                          ),
                      )}
                      readOnly={
                        expiredSubmission ||
                        isNotStartedSubmission(
                          submissionResult.challengeResultStatus,
                          submission.status,
                        )
                      }
                      onChange={() =>
                        onToggleSelectedChallenge(submissionResult.challengeId)
                      }
                      className="code-change-deadline__checkbox"
                    />
                  );

                  return (
                    <Row key={submissionResult.challengeId}>
                      <Column>
                        {isNotStartedSubmission(
                          submissionResult.challengeResultStatus,
                          submission.status,
                        ) ? (
                          <Tooltip
                            text={Message.getMessageByKey(
                              "change-deadline.notStarted",
                            )}
                            placement="top-start"
                          >
                            {checkBox}
                          </Tooltip>
                        ) : (
                          checkBox
                        )}
                      </Column>
                      <Column>
                        {hasAvailableVersion && (
                          <UpdateAvailableTag className="code-change-deadline__new-version" />
                        )}
                        <div>{submissionResult.title}</div>
                      </Column>
                      <Column>{statusText}</Column>
                      <Column>
                        {challenge &&
                          (challenge.timeLimitMinutes === undefined ? (
                            <Msg id={"change-deadline.timeToNewDeadline"} />
                          ) : (
                            challenge.timeLimitMinutes
                          ))}
                      </Column>
                    </Row>
                  );
                })}
              </TableBody>
            </Table>
          </Form>
        </Modal>
        {this.state.isVersionUpdateModalOpen && (
          <Modal
            className="form-alert-modal"
            isOpen={this.state.isVersionUpdateModalOpen}
            title={<Msg id={"change-deadline.confirmTitle"} />}
            onClickOk={() => this.onExtendSubmission(true)}
            onClose={this.onVersionUpgradeClose}
            okButtonLabel={<Msg id="change-deadline.latestVersion" />}
            cancelButtonLabel={<Msg id="change-deadline.currentVersion" />}
            onClickCancel={() => this.onExtendSubmission(false)}
            ariaLabel="Version Update Confirmation"
          >
            <Msg id="change-deadline.confirmUseLatestVersions" />
          </Modal>
        )}
      </>
    );
  }

  private onFormChange = (
    formValid: boolean,
    formValues: { examDeadline: string },
    error: {},
  ) => {
    // Avoid return valid: false if the values are the same as initialvalues
    this.setState({
      formValid: formValid || !error,
      examDeadline: formValues.examDeadline,
    });
  };

  private onToggleConfirm = (confirm: boolean) => {
    this.setState({ confirm });
  };

  private onVersionUpgradeClose = () => {
    this.setState({
      isVersionUpdateModalOpen: false,
    });
  };

  private onExtendSubmission = (useLatestVersions: boolean) => {
    const { examDeadline, selectedChallengeIds: challengeIds } = this.state;
    this.props.extendSubmission.mutate({
      data: { examDeadline, challengeIds, useLatestVersions },
    });
  };
}

export default ExtendSubmission;
