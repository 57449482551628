import * as classnames from "classnames";
import * as React from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { useStoreContext } from "@context";

import { Msg, PageTitle } from "@shared/components";
import { useExam } from "@shared/hooks/query";

interface ExternalProps {
  children?: React.ReactNode;
  blank?: boolean;
}

export type Props = ExternalProps & RouteComponentProps<{ examId: string }>;

const ExamDeliverLayout = ({
  children,
  blank,
  history,
  match,
  location,
}: Props) => {
  const rootStyle = classnames("code-exam-deliver");
  const { projectId: currentProjectId } = useStoreContext();
  const {
    data: { deliveryPath, examDetail: exam },
    isFetching: loadingExam,
    isFetched: loadedExam,
  } = useExam();
  const [examName, setExamName] = React.useState(exam.getName());
  const examId = parseInt(match.params.examId, 10);
  const page = location.pathname.split("/").pop();
  const isAllowed = !deliveryPath ? true : page === deliveryPath;
  const shouldRedirect =
    !/\/deliveries\/new\/(id|email)$/i.test(location.pathname) || // is match exact path
    !/\/(id|email)\/*$/i.test(location.pathname); // is path end with id or email

  React.useEffect(() => {
    if (!exam.getName()) {
      return;
    }

    setExamName(exam.getName());
  }, [exam]);

  React.useEffect(() => {
    if (deliveryPath && !loadingExam && (shouldRedirect || !isAllowed)) {
      history.replace(
        `/p/${currentProjectId}/exams/${examId}/deliveries/new/${deliveryPath}`,
      );
    }
  }, [
    currentProjectId,
    examId,
    history,
    isAllowed,
    loadingExam,
    shouldRedirect,
    deliveryPath,
  ]);

  if (blank) {
    return null;
  }

  return (
    <div className={rootStyle}>
      <BreadcrumbsItem to="/">
        <Msg id={"home"} />
      </BreadcrumbsItem>
      <BreadcrumbsItem to={`/p/${currentProjectId}/exams`}>
        <Msg id="common.examList" />
      </BreadcrumbsItem>
      {examName && (
        <BreadcrumbsItem to={`/p/${currentProjectId}/exams/${examId}`}>
          {examId && `[${examId}] `}
          {examName}
        </BreadcrumbsItem>
      )}
      <div className="code-exam-deliver__body">
        <PageTitle>{examName}</PageTitle>
        {(!loadingExam || loadedExam) && children}
      </div>
    </div>
  );
};

export default withRouter(ExamDeliverLayout);
