import * as classnames from "classnames";
import { isEqual } from "lodash";
import * as React from "react";
import "track-markdown/dist/main.css";

import { QuestionType } from "../../services/enums";
import { render, renderFreeText, renderFIB } from "../../services/markdown";

/**
 * Prop interface
 */
export interface MarkdownProps {
  answers?: string[];
  body: string;
  className?: string;
  mode?: QuestionType;
  showTextarea?: boolean;
  noRelativeLinks?: boolean;
  internalLinkFallbackURL?: string;
}

/**
 * React Component
 */
export default class Markdown extends React.Component<MarkdownProps, {}> {
  private markdownRef = React.createRef<HTMLDivElement>();

  private formatElementStyle = (
    mode?: QuestionType,
    currentRef?: HTMLDivElement | null,
  ) => {
    if (currentRef) {
      if (mode === QuestionType.FreeText || mode === QuestionType.FIB) {
        const formElement = currentRef.querySelectorAll(".fib");
        formElement.forEach(
          (el: HTMLInputElement | HTMLTextAreaElement, index) => {
            el.disabled = true;
            if (el.tagName.toLowerCase() === "input") {
              el.value = (index + 1).toString();
            }
          },
        );
      }
    }
  };

  public shouldComponentUpdate(nextProps: MarkdownProps) {
    return !isEqual(nextProps, this.props);
  }

  public componentDidMount() {
    const { internalLinkFallbackURL, mode } = this.props;
    const currentRef = this.markdownRef.current;

    if (currentRef) {
      if (internalLinkFallbackURL) {
        const anchorElement = currentRef.querySelectorAll("a");
        const isRelative = (href: string) =>
          !href.match(/^(http:\/\/|https:\/\/)/);
        Array.from(anchorElement || [])
          .filter((a) => isRelative(a.getAttribute("href") || ""))
          .forEach((el: HTMLAnchorElement) => {
            el.addEventListener("click", (e) => {
              const win = window.open(
                internalLinkFallbackURL,
                "_blank",
                "noopener, noreferrer",
              );
              win?.focus();
              e.preventDefault();
            });
          });
      }

      this.formatElementStyle(mode, currentRef);
    }
  }

  public componentDidUpdate() {
    this.formatElementStyle(this.props.mode, this.markdownRef.current);
  }

  public render() {
    const {
      answers = [],
      body,
      className,
      mode,
      showTextarea = false,
      noRelativeLinks,
    } = this.props;

    if (!body) {
      return null;
    }

    const rootStyle = classnames("code-c-markdown", "markdown-body", {
      [`${className}`]: Boolean(className),
    });

    let content = "";

    switch (mode) {
      case QuestionType.FIB:
        content = renderFIB(body, answers, { noRelativeLinks });
        break;
      case QuestionType.FreeText:
        const replacedBody = body.replace("\n${FREETEXT}", "");
        // to reset contents both it has ${FREETEXT} from database and it has no ${FREETEXT} while inputting
        const freetextBody = showTextarea
          ? replacedBody + "\n${FREETEXT}"
          : replacedBody;

        const freetextAnswers = showTextarea ? ["${FREETEXT}"] : [];
        content = renderFreeText(freetextBody, freetextAnswers, {
          noRelativeLinks,
        });
        break;
      case QuestionType.MCQ:
      default:
        content = render(body, { noRelativeLinks });
    }

    return (
      <div
        className={rootStyle}
        dangerouslySetInnerHTML={{ __html: content }}
        ref={this.markdownRef}
      />
    );
  }
}
