import * as React from "react";

import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Msg,
  PageTitle,
  ValidationMessage,
} from "@shared/components";
import initialData from "@shared/services/initialData";
import message from "@shared/services/message";
import { isFeatureEnv } from "@shared/services/url";

export type InjectedProps = {
  error: boolean;
  signIn: (payload: {}) => void;
};

export type ExternalProps = {
  isSystemAdmin?: boolean;
};

export type LocalSignInProps = InjectedProps & ExternalProps;

type LocalSigninForm = {
  orgname: string;
  email: string;
  password: string;
};

export const LocalSignIn = (props: LocalSignInProps) => {
  const { signIn, error, isSystemAdmin } = props;

  const hostname = window.location.hostname;
  const orgNameFromHost = isFeatureEnv(hostname)
    ? hostname.split(".")[0]
    : undefined;

  const [loading, setLoading] = React.useState(false);

  const [form, setForm] = React.useState<{
    formValid?: boolean;
    formValues: LocalSigninForm;
  }>({
    formValues: {
      orgname: isSystemAdmin
        ? "givery"
        : orgNameFromHost ?? initialData.orgName ?? "",
      email: "",
      password: "",
    },
  });

  React.useEffect(() => {
    setLoading(false);
  }, [error]);

  const onFormChange = (
    formValid: boolean,
    formValues: LocalSigninForm,
    formErrors?: {},
  ) => {
    setForm({ formValues, formValid: formValid || !Boolean(formErrors) });
  };

  const onSignIn = () => {
    setLoading(true);
    signIn(form.formValues);
  };

  return (
    <div className="code-local-signin">
      <PageTitle>
        <Msg id="signin" />
      </PageTitle>
      <Form
        validation={{
          orgname: ["string", "required"],
          email: ["string", "required"],
          password: ["string", "required"],
        }}
        initialValues={{
          orgname: form.formValues.orgname,
        }}
        onFormChange={onFormChange}
        autoComplete={true}
      >
        <FormGroup>
          <Label htmlFor="orgname">
            <Msg id="form.organizationName" />
          </Label>
          <Input id="orgname" name="orgname" disabled={isSystemAdmin} />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="email">
            <Msg id="form.email" />
          </Label>
          <Input id="email" name="email" type="email" autocomplete="email" />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="password">
            <Msg id="form.password" />
          </Label>
          <Input id="password" name="password" type="password" />
        </FormGroup>
        {error && (
          <FormGroup>
            <ValidationMessage
              name="signin"
              error={{
                signin: message.getMessageByKey("message.loginFailed"),
              }}
            />
          </FormGroup>
        )}
        <FormGroup>
          <Button
            type="primary"
            onClick={() => onSignIn()}
            disabled={!form.formValid}
            loading={loading}
          >
            <Msg id="signin" />
          </Button>
        </FormGroup>
      </Form>
    </div>
  );
};
