import { Switch, Route, RouteComponentProps } from "react-router-dom";

import { ChallengeResultPreview } from "@components/preview/challengeResultPreview";
import { CodePlayback } from "@components/preview/codePlayback";
import { DiffViewer } from "@components/preview/diffViewer";

import ExamDetail from "./ExamDetail.connect";
import { ExamChallengePrint } from "./partials";

interface InjectedProps {
  shouldRenderCodeDiffRoute: boolean;
}

export type ExternalProps = RouteComponentProps<{
  projectId: string;
  examId: string;
}>;

type ExamDetailRouteProps = ExternalProps & InjectedProps;

/**
 * Route settings
 */
export const ExamDetailRoute = ({
  shouldRenderCodeDiffRoute,
}: ExamDetailRouteProps) => (
  <div>
    <Switch>
      <Route
        path="/p/:projectId/exams/:examId/submissions/:submissionId/preview/:challengeId"
        exact={true}
        component={ChallengeResultPreview}
      />
      <Route
        path="/p/:projectId/exams/:examId/submissions/:submissionId/playback/:challengeId"
        exact={true}
        component={CodePlayback}
      />
      {shouldRenderCodeDiffRoute && (
        <Route
          path="/p/:projectId/exams/:examId/submissions/:submissionId/diff/:challengeId"
          exact={true}
          component={DiffViewer}
        />
      )}
      <Route
        path="/p/:projectId/exams/:examId/challenges/print"
        exact={true}
        component={ExamChallengePrint}
      />
      <Route
        path="/p/:projectId/exams/:examId/delivery_:status"
        exact={true}
        component={ExamDetail}
      />
      <Route path="/p/:projectId/exams/:examId" component={ExamDetail} />
    </Switch>
  </div>
);
