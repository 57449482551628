import * as classnames from "classnames";
import { isEqual } from "lodash";
import * as React from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { Prompt } from "react-router";

import { Modal, Msg } from "@shared/components";
import { ExamChallengeSetModel, ExamModel } from "@shared/models";
import MSG from "@shared/services/message";

import {
  ExamChallenges,
  ExamSectionUtil,
  ExamDetailHeader,
} from "../../../examSections";
import { ExamEditSaveArea } from "../examEditSaveArea/ExamEditSaveArea";

export interface ExamDetailChallengesProps {
  examDetail: ExamModel;
  examDetailChallengesSets: ExamChallengeSetModel[];
  saving?: boolean;
  hasCriticalIssue: boolean;
  hasCriticalChallengeIssue: boolean;
  updateExamChallenges: (payload: {
    challengesSets: ExamChallengeSetModel[];
  }) => void;
}

export interface ExamDetailChallengesState {
  editing: boolean;
  saving: boolean;
  formValid: boolean;
  formValues: { challengesSets: ExamChallengeSetModel[] };
  oldFormValues: { challengesSets: ExamChallengeSetModel[] };
  /**
   *  if a change is made that affects the scoring
   */
  scoringHasChanged: boolean;
  isEditWarningDialogOpen: boolean;
}

class ExamDetailChallenges extends React.Component<
  ExamDetailChallengesProps,
  ExamDetailChallengesState
> {
  constructor(props: ExamDetailChallengesProps) {
    super(props);

    this.state = {
      editing: false,
      saving: false,
      formValid: false,
      formValues: { challengesSets: [] },
      oldFormValues: { challengesSets: [] },
      scoringHasChanged: false,
      isEditWarningDialogOpen: false,
    };
  }

  public shouldComponentUpdate(
    nextProps: Readonly<ExamDetailChallengesProps>,
    nextState: Readonly<ExamDetailChallengesState>,
  ): boolean {
    return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state);
  }

  public UNSAFE_componentWillReceiveProps(newProps: ExamDetailChallengesProps) {
    if (this.props.saving && !newProps.saving) {
      this.setState({
        saving: false,
      });
    }
  }

  public render() {
    const rootStyle = classnames("code-exam-edit__detail_challenges", {
      "code-exam-detail-is-editing": this.state.editing,
    });
    const {
      examDetail,
      examDetailChallengesSets,
      hasCriticalIssue,
      hasCriticalChallengeIssue,
    } = this.props;

    const pageTitle = MSG.getMessageByKey("common.challenges");

    return (
      <div className={rootStyle}>
        <BreadcrumbsItem
          to={`/p/${examDetail.projectId}/exams/${examDetail.id}/challenges`}
        >
          {pageTitle}
        </BreadcrumbsItem>
        <Prompt
          when={this.state.editing}
          message={MSG.getMessageByKey("navigation.confirm")}
        />
        <ExamDetailHeader
          archived={Boolean(examDetail && examDetail.isArchived())}
          editing={this.state.editing}
          hasPrint={examDetailChallengesSets && !hasCriticalChallengeIssue}
          editDisabled={hasCriticalIssue}
          editTooltipText={<Msg id="tier.disabled.examButton" />}
          onClickCancel={this.onCancel}
          onClickEdit={this.onClickEdit}
          title={pageTitle}
          examId={examDetail.id}
        />
        <ExamChallenges
          hideTitle
          examType={examDetail?.examType}
          challengesSets={
            this.state.editing
              ? this.state.formValues.challengesSets
              : examDetailChallengesSets || []
          }
          editAllowed={this.state.editing && examDetail.isChallengeEditable()}
          editLimited={examDetail && !examDetail.isChallengeEditable()}
          onFormChange={this.onFormChange}
          readOnly={!this.state.editing}
          resetForm={!this.state.editing && !this.state.saving}
          showAllErrors={this.state.editing}
          showUpdateAvailableBox={!examDetail.isArchived()}
          showExamLevelInsights={true}
        />
        {this.state.editing && (
          <ExamEditSaveArea
            disabled={this.state.editing && !this.state.formValid}
            onSaveClick={
              examDetail.hasOnlySampleDelivery && this.state.scoringHasChanged
                ? this.onEdit
                : this.onConfirmEdit
            }
          />
        )}
        <Modal
          isOpen={this.state.isEditWarningDialogOpen}
          title={<Msg id={"challenge.editWarningTitle"} />}
          onClose={this.onCloseModal}
          onClickCancel={this.onCloseModal}
          onClickOk={this.onConfirmEdit}
          ariaLabel="Delete Sample Delivery Confirmation"
        >
          <Msg id={"challenge.editWarningMessage"} />
        </Modal>
      </div>
    );
  }

  private onCancel = () => {
    this.setState({
      editing: false,
      saving: false,
      scoringHasChanged: false,
      formValues: { challengesSets: [] },
      oldFormValues: { challengesSets: [] },
    });
  };

  private onCloseModal = () => {
    this.setState({
      isEditWarningDialogOpen: false,
    });
  };

  private onEdit = () => {
    this.setState({
      isEditWarningDialogOpen: true,
    });
  };

  private onConfirmEdit = () => {
    if (this.state.editing) {
      if (this.props.updateExamChallenges) {
        this.props.updateExamChallenges(this.state.formValues);
      }
    }

    this.setState({
      isEditWarningDialogOpen: false,
      editing: false,
      saving: true,
      oldFormValues: { ...this.state.formValues },
    });
  };

  private onClickEdit = () => {
    const challengesSets = this.props.examDetailChallengesSets || [];
    this.setState({
      editing: true,
      saving: false,
      formValues: { challengesSets },
      oldFormValues: { challengesSets },
    });
  };

  private onFormChange = (
    formValid: boolean,
    formValues: { challengesSets: ExamChallengeSetModel[] },
  ) => {
    if (this.state.editing) {
      this.setState({
        formValid,
        formValues,
        scoringHasChanged: ExamSectionUtil.scoringHasChanged(
          (this.props.examDetailChallengesSets || []).map((challengeSet) => {
            const { numberChallengesToTake = 1 } = challengeSet;
            return challengeSet.challenges.map((challenge) => ({
              id: challenge.id,
              weight: challenge.weight,
              numberChallengesToTake,
              randomQuiz: challenge.randomizeQuiz,
            }));
          }),
          formValues.challengesSets.map((challengeSet) => {
            const { numberChallengesToTake = 1 } = challengeSet;
            return challengeSet.challenges.map((challenge) => ({
              id: challenge.id,
              weight: challenge.weight,
              numberChallengesToTake,
              randomQuiz: challenge.randomizeQuiz,
            }));
          }),
        ),
      });
    }
  };
}

export default ExamDetailChallenges;
