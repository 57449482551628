import { isEqual } from "lodash";
import * as React from "react";

import {
  Button,
  Icon,
  Tag,
  TableBody,
  Msg,
} from "../../../../../../shared/components";
import { AuditLogModel } from "../../../../../../shared/models";
import { formatDateTimeSeconds } from "../../../../../../shared/services/date";

const actionClass = {
  "sign-up-success": "primary",
  "sign-in-success": "primary",
  "sign-out": "",
  "sign-in-failure": "error",
};

export interface AuditLogRowProps {
  auditLogEntry: AuditLogModel;
}

export interface AuditLogRowState {
  open: boolean;
}

export default class AuditLogRow extends React.Component<
  AuditLogRowProps,
  AuditLogRowState
> {
  constructor(props: AuditLogRowProps) {
    super(props);

    this.state = {
      open: false,
    };
  }

  shouldComponentUpdate(
    nextProps: Readonly<AuditLogRowProps>,
    nextState: Readonly<AuditLogRowState>,
  ): boolean {
    return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state);
  }

  render() {
    const { auditLogEntry } = this.props;

    return (
      <TableBody className="auditlog__table-body">
        <tr className="auditlog__tr">
          <td className="code-c-column auditlog__td--small">
            <Button
              size="small"
              shrink={true}
              onClick={() => this.setState({ open: !this.state.open })}
              ariaLabel="Toggle Audit Log Item"
            >
              <Icon type={this.state.open ? "angle-up" : "angle-down"} />
            </Button>
          </td>
          <td className="code-c-column">
            <Tag
              className={`auditlog__tag--${actionClass[auditLogEntry.action]}`}
            >
              {auditLogEntry.action}
            </Tag>
          </td>
          <td className="code-c-column">
            {formatDateTimeSeconds(auditLogEntry.createdAt)}
          </td>
          <td className="code-c-column">{auditLogEntry.requestUserEmail}</td>
        </tr>
        {this.state.open && (
          <tr className="auditlog__block-detail">
            <td colSpan={4} className="auditlog__block-detail-td">
              <ul className="auditlog__block-detail-item">
                <li>
                  <Msg id="common.ipAddress" />
                  {`: ${auditLogEntry.requestUserIp}`}
                </li>
                <li>
                  <Msg id="common.userAgent" />
                  {`: ${auditLogEntry.requestUserAgent}`}
                </li>
              </ul>
            </td>
          </tr>
        )}
      </TableBody>
    );
  }
}
