import Message from "./message";

/**
 * Translate responce to error object
 * @param message
 */
export function getErrorObject(message: string): {
  error: string;
  errorCode: number;
} {
  // HACK: since current error action doesn't return it's status code or detail, parse error message to know error reason.
  if (message.match(/attempt limit/) || message.match(/失敗回数/)) {
    return {
      errorCode: 406,
      error: Message.getMessageByKey("message.auth.exceedAttemptLimit"),
    };
  } else if (
    message.match(/double check your validation code/) ||
    message.match(/認証コードを再確認/)
  ) {
    return {
      errorCode: 401,
      error: Message.getMessageByKey("message.auth.resendToken"),
    };
  } else {
    return {
      errorCode: 404,
      error: Message.getMessageByKey("message.loginFailed"),
    };
  }
}
