import { connect } from "react-redux";
import { Action, Dispatch } from "redux";

import { RootState } from "@reducers";

import { getTierAllowedByRedux } from "@shared/services/tier";

import { MemberRoleForm, ExternalProps } from "./MemberRoleForm";

const mapStateToProps = (state: RootState, props: ExternalProps) => ({
  isTierActionAllowed: getTierAllowedByRedux(state),
  ...props,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({});

// for the testing
export { MemberRoleForm as MemberRoleFormUnwrapped };

export default connect(mapStateToProps, mapDispatchToProps)(MemberRoleForm);
