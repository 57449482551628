import * as classnames from "classnames";
import * as Joi from "joi";
import * as React from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";

import {
  Button,
  FormGroup,
  Label,
  Textarea,
  Form,
  Modal,
  DocumentTitle,
  Loading,
  Restricted,
  Msg,
  Radio,
  LanguageTag,
  ApplicantList,
  Icon,
} from "@shared/components";
import { useApplicantList } from "@shared/hooks";
import {
  useConfirmDeliverId,
  useDeliverId,
  useExam,
} from "@shared/hooks/query";
import { ApplicantExamsModel } from "@shared/models";
import { ProjectRole, ExamType } from "@shared/services/enums";
import MSG from "@shared/services/message";
import { getExamDeliveryURL } from "@shared/services/url";

import DeliverIdSettings from "../deliverIdSettings/DeliverIdSettings";

/**
 * Prop interface
 */
interface Props {
  orgName: string;
}

interface FormValues {
  language: string;
  applicants: string;
}

export default function DeliverId({ orgName }: Props) {
  const rootStyle = classnames("code-deliver-id");
  const confirmLabel = MSG.getMessageByKey("action.confirm");
  const {
    data: {
      examDetail: exam,
      examDetail: {
        idDeliveryConfig: {
          startAt = "",
          endAt = "",
          dynamicDeadlineHours,
          passwordRequired,
        } = {},
        examType,
        urlToken,
      },
    },
  } = useExam();
  const {
    applicantCount,
    applicantCountFormat,
    applicantsApiPayload,
    filteredApplicants,
    handleChangeKeyword,
    setApplicants,
    setSearchKeyword,
  } = useApplicantList();

  const deliverExam = useDeliverId();
  const confirmTokens = useConfirmDeliverId();
  const { isLoading: confirmLoading } = confirmTokens;
  const { alreadyDelivered = [], notDelivered = [] } =
    confirmTokens.data?.result || {};
  const [formValues, setFormValues] = React.useState<FormValues>({
    language: "",
    applicants: "",
  });
  const [_, setFormValid] = React.useState(false);
  const [formErrors, setFormErrors] = React.useState({});
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const deliveryUrl = getExamDeliveryURL(orgName, urlToken, false);
  const messageRequired =
    MSG.getMessageByKey("validation.required") ?? "required";
  const options = React.useMemo(
    () => ({
      abortEarly: false,
      language: {
        any: {
          empty: `!!${messageRequired}`,
        },
        array: {
          min: `!!${messageRequired}`,
          max: `!!${
            MSG.getMessageByKey("validation.maxArray").replace(
              "0",
              "{limit}",
            ) ?? "too many"
          }`,
          unique: `!!${
            MSG.getMessageByKey("validation.options.noDuplicate") ?? "unique"
          }`,
        },
        string: {
          alphanum: `!!${MSG.getMessageByKey("validation.alphanumeric")}`,
          max: `!!${
            MSG.getMessageByKey("validation.id.max").replace("0", "{limit}") ??
            "too many"
          }`,
        },
      },
    }),
    [messageRequired],
  );

  const multiLanguage = examType === ExamType.EnglishJapanese;

  const onFormChange = (formValid: boolean, formValues: FormValues) => {
    const applicants = (formValues.applicants || "")
      .trim()
      .split("\n")
      .filter((id) => id);

    const idSchema = Joi.array()
      .items(Joi.string().alphanum().lowercase().max(100))
      .min(1)
      .max(100)
      .unique();

    const { error } = Joi.validate(applicants, idSchema, options);

    const formErrors =
      error?.details?.reduce((acc, cur) => {
        acc["applicants"] = cur.message;
        return acc;
      }, {}) || {};

    setFormValid(formValid);
    setFormErrors(formErrors);
    setFormValues(formValues);
    setApplicants(
      applicants.map(
        (row, id) => ({ id, applicant: { email: row } }) as ApplicantExamsModel,
      ),
    );
  };

  const onCancel = () => {
    setIsModalOpen(false);
  };

  const onFormSubmit = ({ applicants, language }: FormValues) => {
    const formatApplicants = applicants
      .trim()
      .split("\n")
      .filter((token) => token);

    setIsModalOpen(true);
    confirmTokens.mutate({
      examId: exam.id,
      data: { applicants: formatApplicants },
    });
  };

  const onConfirm = () => {
    const { examType, id } = exam;
    const { language } = formValues;

    deliverExam.mutate({
      examId: id,
      data: {
        applicants: applicantsApiPayload,
        ...(examType === ExamType.EnglishJapanese && { language }),
      },
    });
  };

  return (
    <div className={rootStyle}>
      <Loading isOpen={deliverExam.isLoading} />
      <DocumentTitle page={MSG.getMessageByKey("exam.idDelivery")} />
      <BreadcrumbsItem to={`/p/projectName/exams/${exam.id}/deliveries/new/id`}>
        <Msg id="exam.idDelivery" />
      </BreadcrumbsItem>
      <Form
        validation={{
          applicants: ["string", "required"],
          ...(multiLanguage && { language: ["string", "required"] }),
        }}
        onFormChange={onFormChange}
        onSubmit={onFormSubmit}
        error={formErrors}
      >
        <DeliverIdSettings
          copyBoxValue={deliveryUrl}
          dynamicDeadlineHours={dynamicDeadlineHours}
          endAt={endAt}
          passwordRequired={passwordRequired}
          startAt={startAt}
        />
        {multiLanguage && (
          <FormGroup>
            <Label htmlFor="language">
              <Msg id="exam.examlanguage" />
            </Label>
            <div className="code-deliver-id__language">
              <div>
                <Radio name="language" defaultValue="ja">
                  <LanguageTag language="ja" color="pink" />
                </Radio>
              </div>
              <div>
                <Radio name="language" defaultValue="en">
                  <LanguageTag language="en" color="pink" />
                </Radio>
              </div>
            </div>
          </FormGroup>
        )}
        <FormGroup>
          <Label>
            <Msg id="exam.idDelivery.title" />
          </Label>
          <div className="code-deliver-id__applicant-type-wrapper">
            <Textarea
              placeholder={MSG.getMessageByKey("placeholder.enterid")}
              name="applicants"
              ariaLabel="applicants"
              counter={
                <p className="code-deliver-id__applicant-counter">
                  {applicantCountFormat}
                </p>
              }
            />
          </div>
        </FormGroup>
        <Restricted
          roles={[ProjectRole.ExamDeliverer, ProjectRole.ProjectAdmin]}
        >
          <FormGroup centered={true}>
            <Button type="primary" ariaLabel="Confirm">
              {confirmLabel}
            </Button>
          </FormGroup>
        </Restricted>
      </Form>
      <Modal
        isOpen={isModalOpen}
        title={confirmLabel}
        okButtonLabel={MSG.getMessageByKey("action.deliver")}
        onClose={onCancel}
        onClickCancel={onCancel}
        onClickOk={onConfirm}
        disableOk={
          confirmLoading || notDelivered.length === 0 || deliverExam.isLoading
        }
        disableCancel={deliverExam.isLoading}
        disableClose={deliverExam.isLoading}
        okButtonAriaLabel="Deliver Exam"
        ariaLabel="Deliver Exam"
      >
        <Loading fullScreen={false} overlay={false} isOpen={confirmLoading} />
        <Form>
          {multiLanguage && formValues.language && (
            <FormGroup>
              <Label>
                <Msg id="exam.examlanguage" />
              </Label>
              <p>
                <LanguageTag language={formValues.language} color="pink" />
              </p>
            </FormGroup>
          )}
          <DeliverIdSettings
            copyBoxValue={deliveryUrl}
            dynamicDeadlineHours={dynamicDeadlineHours}
            endAt={endAt}
            passwordRequired={passwordRequired}
            startAt={startAt}
          />
          <FormGroup>
            <Label>
              <Msg id="exam.idDelivery.title" />
            </Label>
            <ApplicantList
              applicantCount={applicantCount}
              filteredApplicants={filteredApplicants}
              onChangeKeyword={handleChangeKeyword}
              setKeyword={setSearchKeyword}
              searchTag="common.id"
            />
            {!confirmLoading && alreadyDelivered.length > 0 && (
              <div className="code-deliver-id__already-delivered">
                <div className="code-deliver-id__already-delivered-header">
                  <Icon type={"exclamation-triangle "} />
                  <Msg id="exam.idDelivery.alreadyDelivered" />
                </div>
                <p className={"is-wrap"}>{alreadyDelivered.join("\n")}</p>
              </div>
            )}
          </FormGroup>
        </Form>
      </Modal>
    </div>
  );
}
