import { cloneDeep } from "lodash";

import type { ChallengesListFormValues } from "@components/orgs/challenges/challengeList/ChallengeList";
import type { QuestListFormValues } from "@components/orgs/questions/questionList/QuestionList";

import { RootState } from "@reducers/index";

import {
  ChallengeModel,
  EnumModel,
  ExamChallengeModel,
  ExamChallengeSetModel,
  ExamModel,
  OrganizationModel,
} from "@shared/models";
import { getCurrentProject } from "@shared/selectors";

import {
  ChallengeCategory,
  ChallengeStyle,
  Difficulty,
  ExamDeliveryKind,
  ExamType,
  ProjectRole,
  QuestionCategory,
  TierAction,
  ProjectKind,
  TierActionsForNonAssessmentProject,
  UserRole,
  TierActionsForSystemAdmin,
  GlobalAppType,
  NonAssessmentProjectBillingPlanActions,
} from "./enums";

export const MAX_PROJECT_MEMBERS_COUNT = 999999;

const getOrgTierActions = ({
  organization,
  tiers,
  currentProjectKind,
  isSystemAdmin,
}: {
  organization: OrganizationModel | undefined;
  tiers: EnumModel[];
  currentProjectKind: ProjectKind | undefined;
  isSystemAdmin?: boolean;
}): Map<TierAction, boolean> => {
  if (!organization) {
    return new Map();
  }

  const tier = tiers.find((tier) => tier.value === organization.tier);

  // Non-assessment project types (Training, Contest) are for use with Track Training & Track Job and are not subject to Test billing plan restrictions. They have a fixed set of TierActions defined as an enum.
  const isAssessment = isAssessmentProject(currentProjectKind);

  const allOrganizationTierActions = [
    ...organization.whitelistedActions,
    ...(tier?.actions ?? []),
  ];

  // No restriction on not assesment project
  const allActions = isAssessment
    ? allOrganizationTierActions
    : [...TierActionsForNonAssessmentProject];

  // add must have actions for system admin. Should not be dependent on org tier actions
  if (isSystemAdmin) {
    allActions.push(...TierActionsForSystemAdmin);
  }

  // append project specific actions if whitelisted for non assessment projects
  if (!isAssessment) {
    for (const orgTierAction of allOrganizationTierActions) {
      if (NonAssessmentProjectBillingPlanActions.get(orgTierAction)) {
        allActions.push(orgTierAction);
      }
    }
  }

  const actionMap = allActions.reduce((map, actionValue) => {
    map.set(actionValue, true);

    return map;
  }, new Map());

  return actionMap;
};

export const getTierAllowed = (params: {
  organization: OrganizationModel | undefined;
  tiers: EnumModel[];
  currentProjectKind: ProjectKind | undefined;
  isSystemAdmin?: boolean;
}): ((tierAction: TierAction) => boolean) => {
  const allowedTierActions = getOrgTierActions(params);

  return (tierAction: TierAction) =>
    Boolean(allowedTierActions.get(tierAction));
};

export const getTierAllowedByRedux = (
  state: RootState,
): ((tierAction: TierAction) => boolean) =>
  getTierAllowed({
    organization:
      state.global?.globalAppType === GlobalAppType.Admin
        ? state.orgs.organization // selected organization
        : state.user.user?.organization,
    tiers: state.orgs.tiers,
    currentProjectKind: getCurrentProject(state)?.kind,
    isSystemAdmin: state.user.user?.roles.some(
      (userRole) => userRole.role === UserRole.SystemAdmin,
    ),
  });

export const filterChallengeListFilters = (
  formValues: ChallengesListFormValues,
  isActionAllowed: (tierAction: TierAction) => boolean,
): {
  shouldUpdateUrl: boolean;
  updatedFormValues: ChallengesListFormValues;
} => {
  const updatedFormValues = cloneDeep(formValues);

  if (updatedFormValues.styles) {
    const styles = updatedFormValues.styles.filter((style) => {
      if (style === ChallengeStyle.AI) {
        return isActionAllowed(TierAction.AIChallengeUsage);
      }

      if (style === ChallengeStyle.Development) {
        return isActionAllowed(TierAction.DevelopmentChallengeUsage);
      }

      return true;
    });

    updatedFormValues.styles = styles.length > 0 ? styles : undefined;
  }

  if (updatedFormValues.difficulties) {
    const difficulties = updatedFormValues.difficulties.filter((difficulty) => {
      if (difficulty !== Difficulty.Easy) {
        return isActionAllowed(TierAction.ChallengeDifficultySetting);
      }

      return true;
    });

    updatedFormValues.difficulties =
      (difficulties?.length ?? 0) > 0 ? difficulties : undefined;
  }

  if (updatedFormValues.categories) {
    const categories = updatedFormValues.categories.filter((category) => {
      if (category === ChallengeCategory.Custom) {
        return (
          isActionAllowed(TierAction.CustomCodingUsage) ||
          isActionAllowed(TierAction.CustomQuizUsage)
        );
      }

      return true;
    });

    updatedFormValues.categories =
      categories.length > 0 ? categories : undefined;
  }

  const shouldUpdateUrl =
    updatedFormValues.styles?.length !== formValues.styles?.length ||
    updatedFormValues.difficulties?.length !==
      formValues.difficulties?.length ||
    updatedFormValues.categories?.length !== formValues.categories?.length;

  return { shouldUpdateUrl, updatedFormValues };
};

const filterQuestionListFilters = (
  formValues: ChallengesListFormValues,
  isActionAllowed: (tierAction: TierAction) => boolean,
): {
  shouldUpdateUrl: boolean;
  updatedFormValues: ChallengesListFormValues;
} => {
  const updatedFormValues = cloneDeep(formValues);

  if (updatedFormValues.categories) {
    const categories = updatedFormValues.categories.filter((style) => {
      if (style === QuestionCategory.Original) {
        return isActionAllowed(TierAction.CustomQuizUsage);
      }

      return true;
    });

    updatedFormValues.categories =
      categories.length > 0 ? categories : undefined;
  }

  const shouldUpdateUrl =
    updatedFormValues.categories?.length !== formValues.categories?.length;

  return { shouldUpdateUrl, updatedFormValues };
};

type ApplyAllowedTierActionsInput = (
  | {
      type: "ChallengeList";
      formValues: ChallengesListFormValues;
    }
  | {
      type: "QuestionList";
      formValues: QuestListFormValues;
    }
) & {
  isActionAllowed: (tierAction: TierAction) => boolean;
};

export const applyAllowedTierActions = ({
  type,
  formValues,
  isActionAllowed,
}: ApplyAllowedTierActionsInput): {
  formValues: typeof formValues;
  shouldUpdateUrl: boolean;
} => {
  if (type === "ChallengeList") {
    const { updatedFormValues, shouldUpdateUrl } = filterChallengeListFilters(
      formValues,
      isActionAllowed,
    );

    return {
      formValues: updatedFormValues,
      shouldUpdateUrl,
    };
  }

  if (type === "QuestionList") {
    const { updatedFormValues, shouldUpdateUrl } = filterQuestionListFilters(
      formValues,
      isActionAllowed,
    );

    return {
      formValues: updatedFormValues,
      shouldUpdateUrl,
    };
  }

  return {
    formValues: {},
    shouldUpdateUrl: false,
  };
};

/**
 * Challenge
 */
interface IsChallengeAllowedParams {
  challenge: ExamChallengeModel | ChallengeModel;
  isTierActionAllowed: (tierAction: TierAction) => boolean;
}

interface IsExamAllowedParams {
  examDetail: ExamModel;
  examDetailChallengesSets: ExamChallengeSetModel[];
  isTierActionAllowed: (tierAction: TierAction) => boolean;
}

interface IsExamChallengeAllowedParams {
  examDetailChallengesSets: ExamChallengeSetModel[];
  isTierActionAllowed: (tierAction: TierAction) => boolean;
}

export const isAllowedDifficulty = (
  challenge: ExamChallengeModel | ChallengeModel,
  isTierActionAllowed: (tierAction: TierAction) => boolean,
): boolean =>
  isTierActionAllowed(TierAction.ChallengeDifficultySetting) ||
  (challenge.difficulty ||
    ("currentVersion" in challenge && challenge.currentVersion.difficulty)) ===
    Difficulty.Easy;

export const isDevelopmentAllowed = (
  challenge: ExamChallengeModel | ChallengeModel,
  isTierActionAllowed: (tierAction: TierAction) => boolean,
): boolean =>
  isTierActionAllowed(TierAction.DevelopmentChallengeUsage) ||
  challenge.style !== ChallengeStyle.Development;

export const isAIAllowed = (
  challenge: ExamChallengeModel | ChallengeModel,
  isTierActionAllowed: (tierAction: TierAction) => boolean,
): boolean =>
  isTierActionAllowed(TierAction.AIChallengeUsage) ||
  challenge.style !== ChallengeStyle.AI;

export const isCustomQuizAllowed = (
  challenge: ExamChallengeModel | ChallengeModel,
  isTierActionAllowed: (tierAction: TierAction) => boolean,
): boolean =>
  isTierActionAllowed(TierAction.CustomQuizUsage) ||
  challenge.style !== ChallengeStyle.Quiz ||
  challenge.isOfficial;

export const isCustomCodingAllowed = (
  challenge: ExamChallengeModel | ChallengeModel,
  isTierActionAllowed: (tierAction: TierAction) => boolean,
): boolean =>
  isTierActionAllowed(TierAction.CustomCodingUsage) ||
  challenge.isOfficial ||
  challenge.style === ChallengeStyle.Quiz;

export const isChallengeAllowed = ({
  challenge,
  isTierActionAllowed,
}: IsChallengeAllowedParams): boolean => {
  return (
    isAllowedDifficulty(challenge, isTierActionAllowed) &&
    isDevelopmentAllowed(challenge, isTierActionAllowed) &&
    isAIAllowed(challenge, isTierActionAllowed) &&
    isCustomQuizAllowed(challenge, isTierActionAllowed) &&
    isCustomCodingAllowed(challenge, isTierActionAllowed)
  );
};

export const isCliAllowed = (
  challenge: ExamChallengeModel,
  isTierActionAllowed: (tierAction: TierAction) => boolean,
): boolean =>
  isTierActionAllowed(TierAction.CliSupportEnabling) ||
  !challenge.localExamEnabled;

export const isRandomQuizAllowed = (
  challenge: Pick<ExamChallengeModel, "randomizeQuiz">,
  isTierActionAllowed: (tierAction: TierAction) => boolean,
): boolean =>
  isTierActionAllowed(TierAction.RandomQuiz) || !challenge.randomizeQuiz;

/**
 * Challenge Collection
 */
interface IsCollectionChallengeAllowedParams
  extends Omit<IsChallengeAllowedParams, "challenge"> {
  challenge: ChallengeModel;
}

// collection challenge data format is different from exam challenge
// tweak a bit to satisfy function's params
export const isCollectionChallengeAllowed = (
  params: IsCollectionChallengeAllowedParams,
) =>
  isChallengeAllowed({
    ...params,
    challenge: new ExamChallengeModel({
      ...params.challenge,
      difficulty:
        params.challenge.difficulty ||
        params.challenge.currentVersion.difficulty,
    }),
  });

/**
 * Exam
 */
export const isDeliveryByIdAllowed = (
  deliveryKind: ExamDeliveryKind,
  isTierActionAllowed: (tierAction: TierAction) => boolean,
): boolean =>
  isTierActionAllowed(TierAction.IdDelivering) ||
  deliveryKind !== ExamDeliveryKind.ID;

export const isMultiLangAllowed = (
  examDetail: ExamModel,
  isTierActionAllowed: (tierAction: TierAction) => boolean,
): boolean =>
  isTierActionAllowed(TierAction.MultilanguageExamUsage) ||
  examDetail.examType !== ExamType.EnglishJapanese;

export const isOptionalAllowed = (
  challengeSet: ExamChallengeSetModel,
  isTierActionAllowed: (tierAction: TierAction) => boolean,
): boolean =>
  isTierActionAllowed(TierAction.OptionalChallengeUsage) ||
  !challengeSet.isOptionalSet;

export const isOptionalChallengeAllowed = (
  examDetailChallengesSets: ExamChallengeSetModel[],
  isTierActionAllowed: (tierAction: TierAction) => boolean,
): boolean =>
  examDetailChallengesSets.some((set) =>
    isOptionalAllowed(set, isTierActionAllowed),
  );

export const isRandomAllowed = (
  challengeSet: ExamChallengeSetModel,
  isTierActionAllowed: (tierAction: TierAction) => boolean,
): boolean =>
  isTierActionAllowed(TierAction.RandomChallengeUsage) ||
  !challengeSet.isRandomSet;

export const isRandomChallengeAllowed = (
  examDetailChallengesSets: ExamChallengeSetModel[],
  isTierActionAllowed: (tierAction: TierAction) => boolean,
): boolean =>
  examDetailChallengesSets.some((set) =>
    isRandomAllowed(set, isTierActionAllowed),
  );

export const isGuestShareAllowed = (
  examDetail: ExamModel,
  isTierActionAllowed: (tierAction: TierAction) => boolean,
): boolean =>
  isTierActionAllowed(TierAction.GuestSharingEnabling) ||
  !examDetail.guestSharingEnabled;

export const isCodePlaybackAllowed = (
  examDetail: ExamModel,
  isTierActionAllowed: (tierAction: TierAction) => boolean,
): boolean =>
  isTierActionAllowed(TierAction.CodePlayback) ||
  !examDetail.applicantActionSettings.showKeyEvents;

export const isIntegrateATSAllowed = (
  examDetail: ExamModel,
  isTierActionAllowed: (tierAction: TierAction) => boolean,
): boolean =>
  isTierActionAllowed(TierAction.ExternalATSUsage) ||
  examDetail.deliveryKind === ExamDeliveryKind.ID ||
  !examDetail.atsIntegrationEnabled;

export const isDeliverByUrlAllowed = (
  examDetail: ExamModel,
  isTierActionAllowed: (tierAction: TierAction) => boolean,
): boolean =>
  isTierActionAllowed(TierAction.UrlDelivering) ||
  examDetail.deliveryKind === ExamDeliveryKind.ID ||
  !examDetail.urlDeliveryEnabled;

export const isAssessmentProject = (
  projectKind: ProjectKind | undefined,
): boolean => ProjectKind.Assessment === projectKind;

/**
 * Combination
 */
export const hasIgnorableChallengeIssues = ({
  examDetailChallengesSets,
  isTierActionAllowed,
}: IsExamChallengeAllowedParams): boolean => {
  return !examDetailChallengesSets.every((examDetailChallengesSet) =>
    examDetailChallengesSet.challenges.every((challenge) =>
      isCliAllowed(challenge, isTierActionAllowed),
    ),
  );
};

export const hasFixableIssues = ({
  examDetail,
  isTierActionAllowed,
}: {
  examDetail: ExamModel;
  isTierActionAllowed: (tierAction: TierAction) => boolean;
}): boolean => {
  return !(
    isGuestShareAllowed(examDetail, isTierActionAllowed) &&
    isIntegrateATSAllowed(examDetail, isTierActionAllowed) &&
    isDeliverByUrlAllowed(examDetail, isTierActionAllowed)
  );
};

export const hasCriticalExamIssues = ({
  examDetail,
  examDetailChallengesSets,
  isTierActionAllowed,
}: IsExamAllowedParams) => {
  return !(
    isDeliveryByIdAllowed(examDetail.deliveryKind, isTierActionAllowed) &&
    isMultiLangAllowed(examDetail, isTierActionAllowed) &&
    isOptionalChallengeAllowed(examDetailChallengesSets, isTierActionAllowed) &&
    isRandomChallengeAllowed(examDetailChallengesSets, isTierActionAllowed)
  );
};

export const hasCriticalChallengeIssues = ({
  examDetailChallengesSets,
  isTierActionAllowed,
}: IsExamChallengeAllowedParams): boolean => {
  return !examDetailChallengesSets.every((examDetailChallengesSet) =>
    examDetailChallengesSet.challenges.every(
      (challenge) =>
        isChallengeAllowed({ challenge, isTierActionAllowed }) &&
        isRandomQuizAllowed(challenge, isTierActionAllowed),
    ),
  );
};

export const hasCriticalIssues = ({
  examDetail,
  examDetailChallengesSets,
  isTierActionAllowed,
}: IsExamAllowedParams): boolean => {
  return (
    hasCriticalChallengeIssues({
      examDetailChallengesSets,
      isTierActionAllowed,
    }) ||
    hasCriticalExamIssues({
      examDetail,
      examDetailChallengesSets,
      isTierActionAllowed,
    })
  );
};

export const hasCriticalOrFixableIssues = ({
  examDetail,
  examDetailChallengesSets,
  isTierActionAllowed,
}: IsExamAllowedParams): boolean => {
  return (
    hasCriticalChallengeIssues({
      examDetailChallengesSets,
      isTierActionAllowed,
    }) ||
    hasCriticalExamIssues({
      examDetail,
      examDetailChallengesSets,
      isTierActionAllowed,
    }) ||
    hasFixableIssues({
      examDetail,
      isTierActionAllowed,
    })
  );
};

export const hasCriticalIssuesInChallengePage = ({
  examDetailChallengesSets,
  isTierActionAllowed,
}: IsExamChallengeAllowedParams): boolean => {
  if (!examDetailChallengesSets || !isTierActionAllowed) {
    return false;
  }
  return (
    hasCriticalChallengeIssues({
      examDetailChallengesSets,
      isTierActionAllowed,
    }) ||
    !(
      isOptionalChallengeAllowed(
        examDetailChallengesSets,
        isTierActionAllowed,
      ) &&
      isRandomChallengeAllowed(examDetailChallengesSets, isTierActionAllowed)
    ) ||
    hasIgnorableChallengeIssues({
      examDetailChallengesSets,
      isTierActionAllowed,
    })
  );
};

export const hasCriticalIssuesInSettingsPage = ({
  examDetail,
  isTierActionAllowed,
}: {
  examDetail: ExamModel;
  isTierActionAllowed: (tierAction: TierAction) => boolean;
}): boolean => {
  return (
    !isDeliveryByIdAllowed(examDetail.deliveryKind, isTierActionAllowed) ||
    !(
      isGuestShareAllowed(examDetail, isTierActionAllowed) &&
      isIntegrateATSAllowed(examDetail, isTierActionAllowed) &&
      isDeliverByUrlAllowed(examDetail, isTierActionAllowed) &&
      isCodePlaybackAllowed(examDetail, isTierActionAllowed)
    )
  );
};

export const isRoleAllowed = ({
  role,
  isTierActionAllowed,
}: {
  role: ProjectRole;
  isTierActionAllowed: (tierAction: TierAction) => boolean;
}): boolean => {
  switch (role) {
    case ProjectRole.ProjectAdmin: {
      return true;
    }
    case ProjectRole.Reviewer: {
      return isTierActionAllowed(TierAction.ReviewFunctionality);
    }
    default: {
      return isTierActionAllowed(TierAction.AllRoleCreation);
    }
  }
};

export const blockTierCheck = () => false;
