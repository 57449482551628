import { History } from "history";
import * as React from "react";

import { useExamCounts } from "@shared/hooks/query";
import { SubmissionListKind } from "@shared/services/enums";
import { isEvaluatedList, isSubmittedList } from "@shared/services/exam";
import Message from "@shared/services/message";
import { getUrlParamString } from "@shared/services/queryString";

import { SubmissionListParams } from "./SubmissionList";

type SubmissionListTab =
  | SubmissionListCommonTab
  | SubmissionListSubmittedTab
  | SubmissionListEvaluatedTab;

enum SubmissionListCommonTab {
  All = 0,
}

enum SubmissionListEvaluatedTab {
  Passed = 1,
  Failed,
}

enum SubmissionListSubmittedTab {
  NeedsReview = 1,
  Reviewed,
}

const SubmittedTabIndexToSubmissionListKind = {
  [SubmissionListCommonTab.All]: SubmissionListKind.submitted,
  [SubmissionListSubmittedTab.NeedsReview]: SubmissionListKind.inreview,
  [SubmissionListSubmittedTab.Reviewed]: SubmissionListKind.reviewed,
};

const EvaluatedTabIndexToSubmissionListKind = {
  [SubmissionListCommonTab.All]: SubmissionListKind.evaluated,
  [SubmissionListEvaluatedTab.Passed]: SubmissionListKind.passed,
  [SubmissionListEvaluatedTab.Failed]: SubmissionListKind.failed,
};

const getNewPageName = (
  currentSubmissionListKind: SubmissionListKind,
  newTabIndex: SubmissionListTab,
) => {
  if (isEvaluatedList(currentSubmissionListKind)) {
    return SubmissionListKind[
      EvaluatedTabIndexToSubmissionListKind[newTabIndex]
    ];
  } else if (isSubmittedList(currentSubmissionListKind)) {
    return SubmissionListKind[
      SubmittedTabIndexToSubmissionListKind[newTabIndex]
    ];
  }
  return SubmissionListKind[currentSubmissionListKind];
};

export default function useSubmissionListTabs({
  submissionListKind,
  history,
  params,
  reviewAllowed = false,
}: {
  submissionListKind: SubmissionListKind;
  history?: History;
  params: SubmissionListParams;
  reviewAllowed?: boolean;
}) {
  const {
    data: {
      approvedCount = 0,
      rejectedCount = 0,
      submittedCount = 0,
      inReviewCount = 0,
    },
  } = useExamCounts();
  const [activeIndex, setActiveIndex] = React.useState<SubmissionListTab>(
    SubmissionListCommonTab.All,
  );

  const tabItems = isEvaluatedList(submissionListKind)
    ? [
        {
          index: SubmissionListCommonTab.All,
          label: `${Message.getMessageByKey("submission.all")} (${
            approvedCount + rejectedCount
          })`,
        },
        {
          index: SubmissionListEvaluatedTab.Passed,
          label: `${Message.getMessageByKey(
            "applicantExamStatus.passed",
          )} (${approvedCount})`,
        },
        {
          index: SubmissionListEvaluatedTab.Failed,
          label: `${Message.getMessageByKey(
            "applicantExamStatus.failed",
          )} (${rejectedCount})`,
        },
      ]
    : isSubmittedList(submissionListKind) && reviewAllowed
    ? [
        {
          index: SubmissionListCommonTab.All,
          label: `${Message.getMessageByKey("submission.all")} (${
            submittedCount + inReviewCount
          })`,
        },
        {
          index: SubmissionListSubmittedTab.NeedsReview,
          label: `${Message.getMessageByKey(
            "applicantExamStatus.inReview",
          )} (${submittedCount})`,
        },
        {
          index: SubmissionListSubmittedTab.Reviewed,
          label: `${Message.getMessageByKey(
            "submission.reviewed",
          )} (${inReviewCount})`,
        },
      ]
    : [];

  const onTabClick = (idx: number) => {
    if (idx === activeIndex) {
      return;
    }
    const newPageName = getNewPageName(submissionListKind, idx);

    const { keyword, sortOrder, needsReview } = params;

    const paramString = getUrlParamString({
      keyword,
      needsReview,
      page: 0, // reset to first page when switching tabs
      ...sortOrder,
    });

    history?.push(
      `submissions_${newPageName}${paramString ? `?${paramString}` : ""}`,
    );

    setActiveIndex(idx);
  };

  React.useEffect(() => {
    let newTab: SubmissionListTab = SubmissionListCommonTab.All;

    if (submissionListKind === SubmissionListKind.passed)
      newTab = SubmissionListEvaluatedTab.Passed;

    if (submissionListKind === SubmissionListKind.failed)
      newTab = SubmissionListEvaluatedTab.Failed;

    if (submissionListKind === SubmissionListKind.inreview)
      newTab = SubmissionListSubmittedTab.NeedsReview;

    if (submissionListKind === SubmissionListKind.reviewed)
      newTab = SubmissionListSubmittedTab.Reviewed;

    setActiveIndex(newTab);
  }, [submissionListKind]);

  return { tabItems, onTabClick, activeIndex };
}
