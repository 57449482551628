import * as classnames from "classnames";
import * as React from "react";

import MSG from "../../services/message";

/**
 * Prop interface
 */
export interface LabelProps {
  htmlFor?: string;
  /**
   * label title
   */
  title?: React.ReactNode;
  /**
   *
   */
  isOptional?: boolean;
  className?: string;
  children?: React.ReactNode;
  rightSection?: React.ReactNode;
  locale?: string;
}

/**
 * React Component
 */
export default function Label({
  htmlFor,
  title,
  isOptional,
  className,
  children,
  locale,
  rightSection,
}: LabelProps) {
  const rootStyle = classnames("code-c-label", "label", {
    "is-optional": isOptional,
    [`${className}`]: Boolean(className),
  });
  const optionalLabel = locale
    ? MSG.getMessageByKeyAndLocale("form.optional", locale)
    : MSG.getMessageByKey("form.optional");

  return (
    <label className={rootStyle} htmlFor={htmlFor}>
      {children}
      {title} {isOptional && `(${optionalLabel})`}
      {rightSection}
    </label>
  );
}
