import { useQuery } from "@tanstack/react-query";
import { isNumber } from "lodash";

import { getURLObjectByFilterValues } from "@components/orgs/exams/officialExamList/partials/officialExamUtil/officialExamUtil";

import { getOfficialExam, getOfficialExamList } from "@api/exams";
import { BaseSearchQuery, Pagination } from "@api/httpClient";

import { useStoreContext } from "@context";

import { useDebounce } from "@shared/hooks/useDebounce";
import {
  ExamModel,
  OfficialExamListModel,
  PaginationModel,
} from "@shared/models";
import history from "@shared/services/history";

interface OfficialExamListFilter extends BaseSearchQuery {
  maxExaminationTime?: string;
  naturalLanguage?: string;
  targetEngineerRole?: string;
}

interface OfficialExamList {
  officialExamList: OfficialExamListModel[];
  officialExamListPagination: PaginationModel;
}

export const officialExamKeys = {
  all: ["exams", "official"] as const,
  listAll: (projectId: number) => [...officialExamKeys.all, projectId] as const,
  list: (projectId: number, filters: OfficialExamListFilter) =>
    [...officialExamKeys.listAll(projectId), { filters }] as const,
  detail: (projectId: number, examId: number) =>
    [...officialExamKeys.all, projectId, examId] as const,
};

export function useOfficialExamList(filters: OfficialExamListFilter) {
  const {
    keyword,
    maxExaminationTime,
    naturalLanguage,
    targetEngineerRole,
    ...otherFilters
  } = filters;
  const { projectId } = useStoreContext();
  const debounceKeyword = useDebounce(keyword, 350);
  const { keyword: _, ...formFilters } = getURLObjectByFilterValues({
    keyword: debounceKeyword,
    maxExaminationTime,
    naturalLanguage,
    targetEngineerRole,
  });
  const debounceFilter = {
    ...otherFilters,
    keyword: debounceKeyword,
    ...formFilters,
  };

  const query = useQuery({
    queryKey: officialExamKeys.list(projectId, debounceFilter),
    queryFn: async ({ signal }) => {
      const params = {
        ...debounceFilter,
      };
      const result = await getOfficialExamList({
        projectId,
        options: { params, signal },
      });

      return result;
    },
    keepPreviousData: true,
    select: (data: Pagination<OfficialExamListModel[]>) => {
      const normalizeResults = data.result.map(
        (exam) => new OfficialExamListModel(exam),
      );
      const officialExamListPagination = new PaginationModel(data.pagination);

      return {
        officialExamList: normalizeResults,
        officialExamListPagination,
      } as OfficialExamList;
    },
  });

  if (query.isError) {
    history.replace("/404");
  }

  return query;
}

export function useOfficialExam(examId: number) {
  const { projectId } = useStoreContext();
  const enabled = isNumber(examId) && examId > 0;

  const query = useQuery({
    queryKey: officialExamKeys.detail(projectId, examId),
    queryFn: async ({ signal }) => {
      const { result } = await getOfficialExam({
        examId,
        projectId,
        options: { signal },
      });

      return result;
    },
    enabled,
    select: (data: ExamModel) => new ExamModel(data),
  });

  return query;
}
