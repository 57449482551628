import * as classnames from "classnames";
import * as React from "react";

import {
  Msg,
  Row,
  RestrictedLink,
  Column,
  ExamTitleColumn,
  HighlightedText,
  TableBody,
  TagCloud,
} from "@shared/components";
import { useExamJobTitleMap, usePurposeOfUseMap } from "@shared/hooks/query";
import { ExamListModel, UserModel } from "@shared/models";
import { formatDateTimeMinutes } from "@shared/services/date";
import {
  ExamDeliveryKind,
  UserRole,
  ProjectRole,
} from "@shared/services/enums";

import { ExamListAction } from "..";

/**
 * Props
 */
export interface ExamListTableRowProps {
  exam: ExamListModel;
  keyword?: string;
  projectId: number;
  currentUser: UserModel;
  status: string;
  actionMenu?: React.ReactElement<typeof ExamListAction>;
}

const DEFAULT_COLSPAN = 8;

export const ExamListTableRow: React.FunctionComponent<
  ExamListTableRowProps
> = (props: ExamListTableRowProps) => {
  const { status, exam, projectId, keyword, currentUser, actionMenu } = props;
  const rootStyle = classnames("code-exam-list-table-row");
  const { examSegment } = exam;

  const { data: jobTitleMap } = useExamJobTitleMap();
  const { data: purposeOfUseMap } = usePurposeOfUseMap();

  const tagItems =
    jobTitleMap && purposeOfUseMap
      ? [
          {
            label: jobTitleMap[examSegment.engineerRole],
          },
          {
            label: purposeOfUseMap[examSegment.purposeOfUse],
          },
        ]
      : [];

  return (
    <TableBody className={rootStyle}>
      <Row>
        <Column size={1}>
          <HighlightedText keyword={keyword}>
            {exam.id.toString()}
          </HighlightedText>
        </Column>
        <Column>
          <ExamTitleColumn
            exam={exam}
            projectId={projectId}
            keyword={keyword}
          />
        </Column>
        {status !== "active" ? (
          <Column>{formatDateTimeMinutes(exam.archivedAt)}</Column>
        ) : (
          <Column className="align-center">
            <RestrictedLink
              roles={[
                UserRole.SystemAdmin,
                ProjectRole.ProjectAdmin,
                ProjectRole.ExamCreator,
                ProjectRole.ExamDeliverer,
              ]}
              to={`/p/${projectId}/exams/${exam.id}/settings`}
            >
              {exam.deliveryKind === ExamDeliveryKind.Standard && (
                <div>
                  <Msg id="exam.emailDeliveryShort" />
                </div>
              )}
              {exam.deliveryKind === ExamDeliveryKind.ID && (
                <div>
                  <Msg id="exam.idDeliveryShort" />
                </div>
              )}
              {exam.urlDeliveryEnabled && (
                <div>
                  <Msg id="exam.urlDeliveryShort" />
                </div>
              )}
            </RestrictedLink>
          </Column>
        )}
        <Column className="align-right">
          <RestrictedLink
            roles={[
              ProjectRole.ProjectAdmin,
              ProjectRole.ExamDeliverer,
              UserRole.SystemAdmin,
            ]}
            allow={exam.reviewers.some(
              (reviewer) => reviewer.id === currentUser.id,
            )}
            to={`/p/${projectId}/exams/${exam.id}/submissions_all`}
          >
            {exam.sentDeliveryCount}
          </RestrictedLink>
        </Column>
        <Column className="align-right">
          <RestrictedLink
            roles={[
              ProjectRole.ProjectAdmin,
              ProjectRole.ExamDeliverer,
              UserRole.SystemAdmin,
            ]}
            allow={exam.reviewers.some(
              (reviewer) => reviewer.id === currentUser.id,
            )}
            to={`/p/${projectId}/exams/${exam.id}/submissions_unread`}
          >
            {exam.unreadDeliveryCount}
          </RestrictedLink>
        </Column>
        <Column className="align-right">
          <RestrictedLink
            roles={[
              ProjectRole.ProjectAdmin,
              ProjectRole.ExamDeliverer,
              UserRole.SystemAdmin,
            ]}
            allow={exam.reviewers.some(
              (reviewer) => reviewer.id === currentUser.id,
            )}
            to={`/p/${projectId}/exams/${exam.id}/submissions_inprogress`}
          >
            {exam.inProgressCount}
          </RestrictedLink>
        </Column>
        <Column className="align-right">
          <RestrictedLink
            roles={[
              ProjectRole.ProjectAdmin,
              ProjectRole.ExamDeliverer,
              UserRole.SystemAdmin,
            ]}
            allow={exam.reviewers.some(
              (reviewer) => reviewer.id === currentUser.id,
            )}
            to={`/p/${projectId}/exams/${exam.id}/submissions_submitted`}
          >
            {exam.submittedCount + exam.inReviewCount}
          </RestrictedLink>
        </Column>
        <Column className="align-right">
          <RestrictedLink
            roles={[
              ProjectRole.ProjectAdmin,
              ProjectRole.ExamDeliverer,
              UserRole.SystemAdmin,
            ]}
            allow={exam.reviewers.some(
              (reviewer) => reviewer.id === currentUser.id,
            )}
            to={`/p/${projectId}/exams/${exam.id}/submissions_evaluated`}
          >
            {exam.approvedCount + exam.rejectedCount}
          </RestrictedLink>
        </Column>
        <Column>{actionMenu}</Column>
      </Row>
      <Row>
        <Column />
        <Column colSpan={DEFAULT_COLSPAN}>
          <div className="code-exam-list-table-row__tags">
            {tagItems.length > 0 && <TagCloud tagItems={tagItems} />}
          </div>
        </Column>
      </Row>
    </TableBody>
  );
};
