import classNames from "classnames";

import { Icon, Msg } from "@shared/components";

export const SubmissionAutoEvaluated = () => {
  const rootStyles = classNames(
    "code-submission-auto-evaluated-tag",
    "has-text-weight-medium",
    "text-xs",
  );
  return (
    <div className={rootStyles}>
      <Icon type="wand-magic-sparkles" className="code-c-icon__text-base" />
      <Msg id="submission.autoEvaluated" />
    </div>
  );
};
