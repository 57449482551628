import * as classnames from "classnames";
import * as React from "react";

import { Msg, Button } from "@shared/components";
import { ReviewModel, ReviewerUserModel } from "@shared/models";
import * as ScoreService from "@shared/services/score";

/**
 * Interface
 */
export interface ReviewStatusBarProps {
  reviews: ReviewModel[];
  pendingReviewers: ReviewerUserModel[];
  onClick?: () => void;
  onHover?: (e: React.FormEvent<HTMLDivElement>) => void;
  onUnhover?: () => void;
}

/**
 * Component
 * @param props ReviewStatusBarProps
 */
export function ReviewStatusBar({
  reviews = [],
  pendingReviewers = [],
  onClick,
  onHover,
  onUnhover,
}: ReviewStatusBarProps) {
  /**
   * Private Functions
   */
  const rootStyle = classnames("code-c-review-status-bar");

  const counts = ScoreService.classifyReviews(reviews);
  const reviewCount = reviews.length;
  const pendingCount = pendingReviewers.length;

  const approveCount =
    counts.stronglyApprove + counts.approve + counts.staleApprove;
  const rejectCount =
    counts.stronglyReject + counts.reject + counts.staleReject;
  const staleCount = counts.staleApprove + counts.staleReject;

  /**
   * Components
   */
  const StatusBarCategory = (props: {
    count: number;
    totalCount: number;
    children: React.ReactNode;
  }) => {
    const { count, totalCount, children } = props;

    return count > 0 ? (
      <div
        className="code-c-review-status-bar__category"
        style={{ width: `${(count / totalCount) * 100}%` }}
      >
        {children}
      </div>
    ) : null;
  };

  const StatusBarItem = (props: {
    count: number;
    totalCount: number;
    status: string;
  }) => {
    const { count, totalCount, status } = props;
    const barItemStyle = classnames(
      "code-c-review-status-bar__item",
      `is-${status}`,
    );
    return count > 0 ? (
      <div
        className={barItemStyle}
        style={{ width: `${(count / totalCount) * 100}%` }}
        role="listitem"
        aria-label={`${status} is ${count}`}
      >
        <div className="code-c-review-status-bar__item-count">{count}</div>
      </div>
    ) : null;
  };

  const buildBars = () => {
    if (reviewCount === 0) {
      return (
        <StatusBarCategory count={pendingCount} totalCount={pendingCount}>
          <StatusBarItem
            status="not-reviewed"
            count={pendingCount}
            totalCount={pendingCount}
          />
        </StatusBarCategory>
      );
    }

    if (staleCount === reviewCount) {
      return (
        <>
          <StatusBarCategory
            count={counts.staleApprove}
            totalCount={pendingCount + counts.staleApprove}
          >
            <StatusBarItem
              status="stale-approved"
              count={counts.staleApprove}
              totalCount={counts.staleApprove}
            />
          </StatusBarCategory>
          <StatusBarCategory
            count={counts.staleReject}
            totalCount={pendingCount + counts.staleReject}
          >
            <StatusBarItem
              status="stale-rejected"
              count={counts.staleReject}
              totalCount={counts.staleReject}
            />
          </StatusBarCategory>
          <StatusBarCategory
            count={pendingCount}
            totalCount={pendingCount + staleCount}
          >
            <StatusBarItem
              status="not-reviewed"
              count={pendingCount}
              totalCount={pendingCount}
            />
          </StatusBarCategory>
        </>
      );
    }

    return (
      <>
        <StatusBarCategory count={approveCount} totalCount={reviewCount}>
          <>
            <StatusBarItem
              status="strongly-approved"
              count={counts.stronglyApprove}
              totalCount={approveCount}
            />
            <StatusBarItem
              status="approved"
              count={counts.approve}
              totalCount={approveCount}
            />
            <StatusBarItem
              status="stale-approved"
              count={counts.staleApprove}
              totalCount={approveCount}
            />
          </>
        </StatusBarCategory>
        <StatusBarCategory count={rejectCount} totalCount={reviewCount}>
          <>
            <StatusBarItem
              status="rejected"
              count={counts.reject}
              totalCount={rejectCount}
            />
            <StatusBarItem
              status="strongly-rejected"
              count={counts.stronglyReject}
              totalCount={rejectCount}
            />
            <StatusBarItem
              status="stale-rejected"
              count={counts.staleReject}
              totalCount={rejectCount}
            />
          </>
        </StatusBarCategory>
      </>
    );
  };

  /**
   * Render
   */
  return (
    <div className={rootStyle} role="list" aria-label="Review Summary">
      <div>
        <div
          className="code-c-review-status-bar__container"
          onMouseOver={onHover}
          onMouseOut={onUnhover}
        >
          {buildBars()}
        </div>
      </div>
      <div>
        <Button size="small" shrink={true} onClick={onClick}>
          <Msg id="details" />
        </Button>
      </div>
    </div>
  );
}
