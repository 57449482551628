import { getPreviewSelectOptions } from "@components/preview/PreviewUtil";

import {
  Avatar,
  CliSupportBadge,
  EnvTag,
  Msg,
  Select,
} from "@shared/components";
import { formatTimeLeftString } from "@shared/services/date";
import initialData from "@shared/services/initialData";

import { SaveHistory } from "../../settings.model";

export type ChallengeResultPreviewHeaderProps = {
  orgLogoUrl?: string;
  applicantName: string;
  examName: string;
  isLocalExam: boolean;
  totalTestcases: number;
  successfulTestcases: number;
  highestSuccessfulTestcases: number;
  timeSpentSeconds?: number;
  saveHistory?: SaveHistory[];
  selectedSaveHistoryId?: number;
  onChangeSelectedSaveHistoryId?: (id: number) => void;
};

export const ChallengeResultPreviewHeader = (
  props: ChallengeResultPreviewHeaderProps,
) => {
  const {
    orgLogoUrl,
    applicantName,
    examName,
    isLocalExam,
    successfulTestcases,
    highestSuccessfulTestcases,
    totalTestcases,
    timeSpentSeconds,
    saveHistory = [],
    selectedSaveHistoryId,
    onChangeSelectedSaveHistoryId,
  } = props;

  const envString = (initialData.env || "").toLowerCase();

  return (
    <header className="code-challenge-result-preview-header">
      <div className="code-challenge-result-preview-header__left">
        {orgLogoUrl && <Avatar src={orgLogoUrl} size="small" />}
        <div className="text-lg is-text-ellipsis">{examName}</div>
      </div>
      <div className="code-challenge-result-preview-header__right">
        {saveHistory.length > 0 ? (
          <>
            <div className="code-challenge-result-preview-header__item">
              <div>
                <Msg id="preview.submissionHistory" />
              </div>
              <Select
                value={selectedSaveHistoryId}
                options={getPreviewSelectOptions(
                  saveHistory,
                  highestSuccessfulTestcases,
                )}
                onChange={(e) =>
                  onChangeSelectedSaveHistoryId?.(Number(e.currentTarget.value))
                }
              />
            </div>
          </>
        ) : null}
        {isLocalExam && <CliSupportBadge isExam={true} />}
        <div className="is-text-ellipsis">
          <Msg id="common.applicant" />: {applicantName}
        </div>
        <div className="code-challenge-result-preview-header__item">
          <div>
            <Msg id="score" />:
          </div>
          <div className="code-challenge-result-preview-header__score-badge">
            <div className="code-challenge-result-preview-header__score-bold">
              {successfulTestcases}
            </div>
            <div>/</div>
            <div>{totalTestcases}</div>
          </div>
        </div>
        <div className="code-challenge-result-preview-header__item">
          <div>
            <Msg id="timeSpent" />:
          </div>
          <div>{formatTimeLeftString(timeSpentSeconds) || "-"}</div>
        </div>
        {envString !== "production" && <EnvTag envString={envString} />}
      </div>
    </header>
  );
};
