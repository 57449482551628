import * as React from "react";
import { createContext, useContext } from "react";

interface DropdownProviderProps {
  onItemClick: () => void;
  children: React.ReactNode;
}

const DropdownContext = createContext({
  onItemClick: () => {},
});

export const useDropdown = () => useContext(DropdownContext);

export const DropdownProvider = ({
  onItemClick,
  children,
}: DropdownProviderProps) => {
  return (
    <DropdownContext.Provider value={{ onItemClick }}>
      {children}
    </DropdownContext.Provider>
  );
};
