class QuestionSettingsModel {
  title: string;
  content: string;
  choiceKind?: "radio" | "checkbox";
  choices: string[] = [];
  answers: string[] = [];

  constructor(args?: {}) {
    Object.assign(this, args);
  }
}

export default QuestionSettingsModel;
