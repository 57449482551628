class UrlSharingConfigModel {
  public id: number;
  public examId: number;
  public startAt: string;
  public endAt: string;
  public dynamicDeadlineHours: number;
  public domainWhitelist: string[];

  constructor(args?: {}) {
    Object.assign(this, args);
  }
}

export default UrlSharingConfigModel;
