import classNames from "classnames";

import { ApplicantExamStatus } from "@shared/services/enums";
import { isEvaluatedStatus } from "@shared/services/exam";

import { Icon } from "../icon";
import Msg from "../msg/Msg";

export function ApplicantExamStatusTag({
  applicantExamStatus,
  canReview = false,
  hideEvaluation,
}: {
  applicantExamStatus: ApplicantExamStatus;
  canReview?: boolean;
  hideEvaluation?: boolean;
}) {
  const rootStyle = classNames("code-c-status-tag", {
    "has-text-gray-500": [
      ApplicantExamStatus.Unread,
      ApplicantExamStatus.InProgress,
      ApplicantExamStatus.Expired,
    ].includes(applicantExamStatus),
    "has-text-neutral-500":
      hideEvaluation ||
      [ApplicantExamStatus.Submitted, ApplicantExamStatus.InReview].includes(
        applicantExamStatus,
      ),
    "has-text-success-500":
      !hideEvaluation && applicantExamStatus === ApplicantExamStatus.Passed,
    "has-text-error-500":
      !hideEvaluation && applicantExamStatus === ApplicantExamStatus.Failed,
  });

  const message =
    hideEvaluation && isEvaluatedStatus(applicantExamStatus) ? (
      <Msg id="submission.evaluated" tagName={"none"} />
    ) : (
      ApplicantExamStatus.toString(applicantExamStatus, canReview)
    );

  return (
    <div className={rootStyle}>
      <Icon type="circle" className="code-c-status-tag__circle" />
      <span>{message}</span>
    </div>
  );
}
