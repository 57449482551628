import * as React from "react";

import {
  Alert,
  Checkbox,
  Column,
  DateTime,
  HeaderColumn,
  Icon,
  Modal,
  Msg,
  Row,
  Table,
  TableBody,
  TableHead,
} from "@shared/components";
import { useGetSubmissionsResults } from "@shared/hooks/query";
import { SubmissionModel } from "@shared/models";
import { dayjs, isLessThanADay } from "@shared/services/date";

interface SubmitExamModalProps {
  submission: SubmissionModel;
  onClickCancel: () => void;
  onClickSubmit: () => void;
}

/**
 * Page component
 */
export const SubmitExamModal: React.FunctionComponent<SubmitExamModalProps> = ({
  submission,
  onClickCancel,
  onClickSubmit,
}: SubmitExamModalProps) => {
  const [isChecked, setChecked] = React.useState<boolean>(false);
  const { data: submissionResultList } = useGetSubmissionsResults();

  const lastActivity = submissionResultList.reduce<string | undefined>(
    (latestDate, result) => {
      if (!latestDate) {
        return result.updatedAt;
      }

      return dayjs(result.updatedAt).isAfter(latestDate)
        ? result.updatedAt
        : latestDate;
    },
    undefined,
  );

  const isLessThan24Hours = isLessThanADay(lastActivity);

  const additionalFooterContent = (
    <Checkbox value={isChecked} onChange={() => setChecked(!isChecked)}>
      <Msg id={"submission.submitExam.confirmation"} />
    </Checkbox>
  );

  return (
    <Modal
      title={<Msg id="submission.submitExam" />}
      isOpen={true}
      onClose={onClickCancel}
      onClickCancel={onClickCancel}
      onClickOk={onClickSubmit}
      okButtonLabel={<Msg id="action.submit" />}
      okButtonType="danger"
      disableOk={isLessThan24Hours ? !isChecked : false}
      additionalFooterContent={
        isLessThan24Hours ? additionalFooterContent : undefined
      }
      ariaLabel="Submit Exam"
      className="code-submit-exam-modal"
    >
      <Alert type="warning" className="code-submit-exam-modal__warning">
        <Msg id="submission.submitExam.warning" />
      </Alert>
      <Table className="code-exam-list__table">
        <TableHead>
          <HeaderColumn size={6}>
            <Msg id={"common.applicants"} />
          </HeaderColumn>
          <HeaderColumn size={3}>
            <Msg id={"submission.submitExam.lastActivity"} />
          </HeaderColumn>
          <HeaderColumn size={2}>
            <Msg id={"deliveredAt"} />
          </HeaderColumn>
          <HeaderColumn size={2}>
            <Msg id={"common.deadline"} />
          </HeaderColumn>
        </TableHead>
        <TableBody>
          <Row>
            <Column className="code-submit-exam-modal__name">
              <p>{submission.applicant.fullname}</p>
              <p>{submission.applicant.email}</p>
            </Column>
            <Column>
              {lastActivity && (
                <div className="code-submit-exam-modal__last-activity">
                  {isLessThan24Hours && (
                    <Icon
                      size="small"
                      type="exclamation-triangle"
                      className="code-submit-exam-modal__warning_icon"
                    />
                  )}

                  {dayjs(lastActivity).fromNow()}
                </div>
              )}
            </Column>
            <Column>
              <DateTime dateTime={submission.deliveredAt} />
            </Column>
            <Column>
              <DateTime dateTime={submission.deadline} />
            </Column>
          </Row>
        </TableBody>
      </Table>
    </Modal>
  );
};
