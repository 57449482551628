import { connect } from "react-redux";

import { RootState } from "../../../../../../reducers";
import DeliverId from "./DeliverId";

const mapStateToProps = (state: RootState) => ({
  orgName: state.user.user?.organization.name || "",
});

/**
 * Wrapped component
 */
export default connect(mapStateToProps, {})(DeliverId);
