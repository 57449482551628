import { useMutation } from "@tanstack/react-query";

import { ApiResponse } from "@api/httpClient";
import { getSubmissionAIPresigned } from "@api/submissions";

import { useStoreContext } from "@context";

interface DownloadSubmissionAIParams {
  id: number;
  challengeId: number;
  historyId: number;
}

export function useDownloadSubmissionAIPresigned() {
  const { projectId } = useStoreContext();
  const mutation = useMutation({
    mutationFn: ({ id, challengeId, historyId }: DownloadSubmissionAIParams) =>
      getSubmissionAIPresigned({ projectId, challengeId, historyId, id }),
    onSuccess: ({ result }: ApiResponse<string>) => {
      window.location.href = result;
    },
  });

  return mutation;
}
