import classNames from "classnames";
import * as React from "react";

import { useDropdown } from "./useDropdown";

interface DropdownItemProps {
  className?: string;
  children?: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
}

export const DropdownItem = ({
  className,
  onClick,
  children,
  disabled,
}: DropdownItemProps) => {
  const rootStyle = classNames("code-c-dropdown__item", className);

  const { onItemClick } = useDropdown();

  return (
    <div
      className={rootStyle}
      onClick={
        disabled
          ? undefined
          : () => {
              onItemClick();
              onClick?.();
            }
      }
    >
      {children}
    </div>
  );
};
