import * as classnames from "classnames";

/**
 * Prop interface
 */
export interface ValidationMessageProps {
  name?: string;
  error?: { [key: string]: string };
  className?: string;
  formatter?: (errorString: string) => string;
}

/**
 *
 */
export function hasValidationError(
  name?: string,
  error?: { [key: string]: string },
): boolean {
  return Boolean(name && error && error[name]);
}

/**
 * React Component
 */
export default function ValidationMessage({
  name,
  error = {},
  className,
  formatter,
}: ValidationMessageProps) {
  const rootStyle = classnames("code-c-validation-message", {
    [`${className}`]: Boolean(className),
  });

  if (!name || !error[name]) {
    return null;
  }

  return (
    <div className={rootStyle} role="alert">
      {formatter ? formatter(error[name]) : error[name]}
    </div>
  );
}
