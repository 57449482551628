import { parse as fibParser } from "../services/fibParser";
import QuestionModel from "./Question.model";

class QuestionMCQModel extends QuestionModel {
  private memoizedAnswerRate: {
    exam?: number[];
    global?: number[];
  } = {};

  constructor(args: {}) {
    super(args);
  }

  /**
   * Return true if the answer is correct
   */
  public isCorrect(index: number): boolean {
    return (
      (this.isUserAnswer(index) && this.isCorrectAnswer(index)) ||
      (!this.isUserAnswer(index) && !this.isCorrectAnswer(index))
    );
  }

  /**
   * Return choices
   */
  public getChoices() {
    return (this.choices.length ? this.choices : this.settings.choices) || [];
  }

  /**
   * Return user's answer
   */
  public getUserAnswers(): number[] {
    return this.userAnswer.answerIndices || [];
  }

  /**
   * Return correct answer indecies
   */
  public getCorrectAnswer(): number[] {
    return (
      this.answers.length
        ? this.answers
        : this.correctAnswer.answerIndices
        ? this.correctAnswer.answerIndices
        : []
    ).map((index) =>
      typeof index === "string" ? parseInt(fibParser(index), 10) : index,
    );
  }

  /**
   * Return true if the index of the chioce has user's answer
   */
  public isUserAnswer(index: number) {
    return this.getUserAnswers().includes(index);
  }

  /**
   * Return true if the index of the chioce is correct answer
   */
  public isCorrectAnswer(index: number): boolean {
    return this.getCorrectAnswer().includes(index);
  }

  private getMemoizedAnswerRate(statsLevel: "exam" | "global") {
    const insight =
      statsLevel === "exam" ? this.examLevelInsight : this.globalInsight;
    if (!insight || insight.overall.totalCount === 0) {
      return null;
    }

    const memoizedAnswerRate = this.memoizedAnswerRate[statsLevel];
    if (memoizedAnswerRate) {
      return memoizedAnswerRate;
    }

    const answerCount = this.getChoices().map(() => 0);

    const { answerDistribution, totalCount } = insight.overall;
    answerDistribution.forEach((entry) => {
      entry.answer.forEach((indexStr) => {
        const index = parseInt(indexStr, 10);
        answerCount[index] += entry.count;
      });
    });

    this.memoizedAnswerRate[statsLevel] = answerCount.map(
      (count) => count / totalCount,
    );

    return this.memoizedAnswerRate[statsLevel];
  }

  /**
   * Return the global answer rate for the answer with the given index
   * @param index
   */
  private getAnswerRate(
    index: number,
    statsLevel: "exam" | "global",
  ): number | null {
    return this.getMemoizedAnswerRate(statsLevel)?.[index] ?? null;
  }

  /**
   * Return the global answer rate for the choice of the given index as a formatted string
   * Empty string will be returned if the global insights is not available
   *
   * @param index
   * @returns string
   */
  public getGlobalAnswerRateAsFormattedString(index: number): string {
    const answerRate = this.getAnswerRate(index, "global");

    if (answerRate === null) {
      return "";
    }

    const rounded = Number((answerRate * 100).toFixed(2));

    return `${rounded}%`;
  }

  public getExamLevelAnswerRateAsFormattedString(index: number): string {
    const answerRate = this.getAnswerRate(index, "exam");

    if (answerRate === null) {
      return "";
    }

    const rounded = Number((answerRate * 100).toFixed(2));

    return `${rounded}%`;
  }
}

export default QuestionMCQModel;
