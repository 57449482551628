class ApplicantReportEvaluationPointScoresModel {
  public challengeId: number;
  public evaluationPoint: string;
  public examAverageScore: number;
  public systemAverageScore: number;

  constructor(args?: {}) {
    Object.assign(this, args);
  }
}

export default ApplicantReportEvaluationPointScoresModel;
