import * as classnames from "classnames";
import * as React from "react";
import { Link } from "react-router-dom";

export type ButtonType =
  | "primary"
  | "secondary"
  | "radiotab"
  | "radiotab-selected"
  | "info"
  | "success"
  | "warning"
  | "danger"
  | "default";

/**
 * Prop interface
 */
export interface ButtonProps {
  /**
   * type
   */
  type?: ButtonType;
  /**
   * size
   */
  size?: string;
  /**
   * inverted
   */
  inverted?: boolean;
  /**
   * outlined
   */
  outlined?: boolean;
  /**
   * rounded
   */
  rounded?: boolean;
  /**
   * loading
   */
  loading?: boolean;
  /**
   * disabled
   */
  disabled?: boolean;
  /**
   * stretch(block size)
   */
  stretch?: boolean;
  // remove min-width
  shrink?: boolean;
  // reduce sides padding
  narrow?: boolean;
  /**
   * onClick
   */
  onClick?: (e: React.FormEvent<HTMLButtonElement>) => void;
  /**
   * onKeyDown
   */
  onKeyDown?: (e: React.KeyboardEvent<HTMLButtonElement>) => void;
  /**
   * className
   */
  className?: string;
  /**
   * children
   */
  children?: React.ReactNode;
  /**
   * route
   */
  route?: {
    pathname: string;
    state?: {};
  };
  /**
   * for aria-label
   */
  ariaLabel?: string;
  ariaPressed?: boolean;
  role?: string;
}

/**
 * React Component
 */
export default function Button({
  type,
  size,
  inverted,
  outlined,
  loading,
  disabled,
  stretch,
  shrink,
  rounded,
  narrow,
  onClick,
  onKeyDown,
  route,
  className,
  children,
  ariaLabel,
  ariaPressed,
  role,
}: ButtonProps) {
  const rootStyle = classnames("code-c-button", "button", {
    [`is-${type}`]: Boolean(type),
    [`is-${size}`]: Boolean(size),
    "is-inverted": inverted,
    "is-outlined": outlined,
    "is-loading": loading,
    "is-stretch": stretch,
    "is-shrink": shrink,
    "is-rounded": rounded,
    "is-narrow": narrow,
    [`${className}`]: Boolean(className),
  });

  if (route && !disabled) {
    return (
      <Link className={rootStyle} to={route} aria-label={ariaLabel}>
        {children}
      </Link>
    );
  } else {
    return (
      <button
        // NOTE: ¯\_(ツ)_/¯
        // The past mitz was directly connecting props type to button type even the props type is the wrong type with the button type.
        // The button behave as a submit button if the type is not correct. so this line is just for leaving the back compatibility.
        // *) the undefined means submit (as a default button type)
        type={type ? undefined : "button"}
        className={rootStyle}
        disabled={disabled || loading}
        onClick={onClick}
        onKeyDown={onKeyDown}
        aria-label={ariaLabel}
        aria-pressed={ariaPressed}
        role={role}
      >
        {children}
      </button>
    );
  }
}
