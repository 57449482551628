import * as classnames from "classnames";

import { LanguageTag, CustomForm } from "@shared/components";
import { CustomFormDefinitionModel } from "@shared/models";
import { SpokenLanguages } from "@shared/services/enums";
import Message from "@shared/services/message";

/**
 * Prop interface
 */
export interface CustomFormPreviewBoxProps {
  applicantNameRequired?: boolean;
  definitions: CustomFormDefinitionModel[];
  examLanguage: SpokenLanguages;
  language?: string;
}

/**
 * React Component
 */
export function CustomFormPreviewBox({
  applicantNameRequired,
  definitions,
  examLanguage,
  language,
}: CustomFormPreviewBoxProps) {
  /**
   * State
   */

  /**
   * Private Functions
   */
  const rootStyle = classnames("code-c-custom-form-preview-box");

  /**
   * Render
   */
  return (
    <div className={rootStyle}>
      {language && <LanguageTag language={language} color="pink" />}
      <div className="code-c-custom-form-preview-box__table">
        {applicantNameRequired && (
          <CustomForm
            definition={
              new CustomFormDefinitionModel({
                displayName: language
                  ? Message.getMessageByKeyAndLocale("form.fullname", language)
                  : Message.getMessageByKey("form.fullname"),
                required: true,
              })
            }
          />
        )}
        {definitions
          .filter((definition) =>
            language ? definition.language === language : true,
          )
          .map((definition) => (
            <CustomForm
              key={definition.key}
              definition={new CustomFormDefinitionModel(definition)}
              locale={examLanguage}
            />
          ))}
      </div>
    </div>
  );
}
