import * as classNames from "classnames";
import * as React from "react";

import { Msg, Accordion, Input, Checkbox } from "@shared/components";
import { useExamSimpleByProjectId } from "@shared/hooks/query";
import { SimpleExamModel } from "@shared/models";
import MSG from "@shared/services/message";
import { matchString } from "@shared/services/string";

export type ExternalProps = {
  onFormChange: (values: { selectedExamIds: number[] }) => void;
  examNotificationSubscriptions: number[];
  disabled?: boolean;
  className?: string;
};

export type InjectedProps = {};

export type ExamRestrictionProps = ExternalProps & InjectedProps;

export const ExamRestrictions = ({
  className,
  examNotificationSubscriptions,
  disabled,
  onFormChange,
}: ExamRestrictionProps) => {
  const { data: examList } = useExamSimpleByProjectId();
  const [keyword, setKeyword] = React.useState("");

  // use a map here to save the order in which the items are added
  const [checked, setChecked] = React.useState<Map<number, boolean>>(() =>
    examNotificationSubscriptions.reduce(
      (arr, subscription) => arr.set(subscription, true),
      new Map(),
    ),
  );

  const subscribedExamsById = React.useMemo(
    () =>
      examNotificationSubscriptions.reduce(
        (arr, examId) => {
          arr[examId] = true;
          return arr;
        },
        {} as Record<number, boolean>,
      ),
    [examNotificationSubscriptions],
  );

  const examsSortedByChecked = React.useMemo(() => {
    const { checkedExams, uncheckedExams } = examList.reduce(
      (arr, exam) => {
        if (subscribedExamsById[exam.examId]) {
          arr.checkedExams.push(exam);
        } else {
          arr.uncheckedExams.push(exam);
        }
        return arr;
      },
      {
        checkedExams: [] as SimpleExamModel[],
        uncheckedExams: [] as SimpleExamModel[],
      },
    );

    return [...checkedExams, ...uncheckedExams];
  }, [examList, subscribedExamsById]);

  const [filteredExams, setFilteredExams] = React.useState<SimpleExamModel[]>(
    [],
  );

  const [selectAllChecked, setSelectAllChecked] = React.useState(false);

  React.useEffect(() => {
    if (examsSortedByChecked) {
      const newFilteredExams = examsSortedByChecked.filter(
        (exam) =>
          matchString(exam.examName, keyword) ||
          matchString(exam.examId.toString(), keyword),
      );

      setFilteredExams(newFilteredExams);
    }
  }, [examsSortedByChecked, keyword]);

  React.useEffect(() => {
    setSelectAllChecked(
      filteredExams.every((exam) => checked.get(exam.examId)),
    );
  }, [checked, filteredExams]);

  const onCheckboxChange = (examId: number, isChecked: boolean) => {
    const newChecked = new Map(checked);

    if (isChecked) {
      newChecked.set(examId, true);
    } else {
      newChecked.delete(examId);
    }

    setChecked(newChecked);
    onFormChange({
      selectedExamIds: Array.from(newChecked.keys()),
    });
  };

  const onSelectAllChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { checked: isChecked } = e.currentTarget;

    setSelectAllChecked(isChecked);

    const oldChecked = new Map(checked);

    let newChecked = oldChecked;

    if (isChecked) {
      // if selected all is checked then set all checkboxes to true
      newChecked = filteredExams.reduce((map, exam) => {
        map.set(exam.examId, true);
        return map;
      }, oldChecked);
    } else {
      // otherwise uncheck all exams that are checked
      newChecked = filteredExams.reduce((map, exam) => {
        map.delete(exam.examId);
        return map;
      }, oldChecked);
    }

    setChecked(newChecked);
    onFormChange({
      selectedExamIds: Array.from(newChecked.keys()),
    });
  };

  const rootStyle = classNames("code-exam-restriction", className);

  const onChangeKeyword = (event: React.FormEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value;
    if (keyword !== value) {
      setKeyword(value);
    }
  };

  return (
    <div className={rootStyle}>
      <Accordion
        expanded
        summary={
          <>
            <Msg id={"exam.restrictions.toBeNotified"} />
            <span className="code-exam-delivery-list__applicants-bar-count">
              ({checked.size})
            </span>
          </>
        }
      >
        <div className="code-exam-restriction__input-container">
          <Input
            readOnly={disabled}
            value={keyword}
            onChange={onChangeKeyword}
            inputClassName={classNames("code-exam-restriction__input", {
              disabled,
            })}
            placeholder={MSG.getMessageByKey(
              "exam.restrictions.inputPlaceholder",
            )}
          />
        </div>
        <div className="code-exam-restriction__exams">
          {Boolean(filteredExams.length) && (
            <Checkbox
              readOnly={disabled}
              value={selectAllChecked}
              onChange={onSelectAllChange}
            >
              <span>{MSG.getMessageByKey("exam.restrictions.selectAll")}</span>
            </Checkbox>
          )}
          <div className="code-exam-restriction__exams__list">
            {filteredExams.map((exam) => (
              <React.Fragment key={exam.examId}>
                <Checkbox
                  readOnly={disabled}
                  value={Boolean(checked.get(exam.examId))}
                  onChange={(e) =>
                    onCheckboxChange(exam.examId, e.currentTarget.checked)
                  }
                  id={exam.examId.toString()}
                >
                  <span>{exam.examId}</span>
                </Checkbox>
                <label
                  className={classNames(
                    "code-exam-restriction__exams__list__label",
                    { disabled },
                  )}
                  htmlFor={exam.examId.toString()}
                >
                  {exam.examName}
                </label>
              </React.Fragment>
            ))}
          </div>
        </div>
      </Accordion>
    </div>
  );
};
