import { connect } from "react-redux";
import { Action, Dispatch } from "redux";

import { authLocalSigninAction } from "@actions";

import { RootState } from "@reducers";

import { LocalSignIn, ExternalProps } from "./LocalSignIn";

/**
 * States
 */
const mapStateToProps = (state: RootState, props: ExternalProps) => {
  return {
    error: Boolean(state.auth.error),
    ...props,
  };
};

/**
 * Props
 */
const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  signIn: (payload: {}) => {
    dispatch(authLocalSigninAction.request(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(LocalSignIn);
