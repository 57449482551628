import { Placement } from "@popperjs/core";
import * as classnames from "classnames";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { usePopper, Modifier } from "react-popper";

/**
 * Hooks
 */
import { useMouseWheel } from "../../hooks";

/**
 * Prop interface
 */
export interface TooltipProps {
  text: React.ReactNode;
  placement?: Placement;
  maxSize?: "small" | "medium" | "large" | "xlarge";
  zIndex?: number;
  className?: string;
  disabled?: boolean;
  wrapperClassName?: string;
  wrapperStyle?: {};
  children?: React.ReactElement;
  // Pass a ref if you want the tooltip arrow to be centered on a different element defined by the parent component
  overrideRef?: React.MutableRefObject<
    HTMLDivElement | HTMLAnchorElement | null
  >;
}

/**
 * React Component
 */
export const Tooltip = ({
  text,
  placement = "top",
  maxSize,
  zIndex = 9999999,
  className,
  wrapperClassName,
  wrapperStyle: wrapperStyleExtra,
  disabled,
  children,
  overrideRef,
}: TooltipProps) => {
  /**
   * State
   */
  const [show, setShow] = React.useState(false);

  const referenceElement = React.useRef<HTMLDivElement | null>(null);

  const [popperElement, setPopperElement] =
    React.useState<HTMLDivElement | null>(null);

  const [arrowElement, setArrowElement] = React.useState<HTMLDivElement | null>(
    null,
  );

  const modifiers = React.useMemo<
    Modifier<"offset" | "arrow" | "preventOverflow">[]
  >(() => {
    return [
      { name: "arrow", options: { element: arrowElement } },
      {
        name: "offset",
        options: {
          offset: [0, 8],
        },
      },
      {
        name: "preventOverflow",
        options: {
          // prevent wrong position calculation when the reference element is placed on the edge of the modal content.
          mainAxis: false,
        },
      },
    ];
  }, [arrowElement]);

  const { styles, attributes, update } = usePopper(
    overrideRef ? overrideRef.current : referenceElement.current,
    popperElement,
    {
      strategy: "fixed",
      placement,
      modifiers,
    },
  );

  /**
   * Private Functions
   */
  const rootStyle = classnames("code-c-tooltip", {
    [`${className}`]: Boolean(className),
  });

  const wrapperStyle = classnames("code-c-tooltip-wrapper", {
    [`${wrapperClassName}`]: Boolean(wrapperClassName),
  });

  const onShow = () => {
    setShow(true);
    if (typeof update === "function") {
      update();
    }
  };

  const onHide = React.useCallback(() => {
    setShow(false);
  }, [setShow]);

  // Hide tooltip when the positions have changed due to mouse wheel
  useMouseWheel(onHide);

  /**
   * Render
   */
  if (disabled) {
    return <>{children}</>;
  }

  return (
    <>
      <div
        ref={referenceElement}
        onMouseOver={onShow}
        onMouseOut={onHide}
        onFocus={onShow}
        onBlur={onHide}
        className={wrapperStyle}
        style={wrapperStyleExtra}
        tabIndex={0}
      >
        {children}
      </div>
      {show &&
        ReactDOM.createPortal(
          <div
            role="tooltip"
            ref={setPopperElement}
            className={rootStyle}
            style={{ ...styles.popper, zIndex }}
            {...attributes.popper}
            {...{
              ["data-tooltip-max-size"]: maxSize,
              ["data-show"]: show,
            }}
          >
            {text}
            <div
              className="code-c-tooltip__arrow"
              ref={setArrowElement}
              style={styles.arrow}
            />
          </div>,
          document.querySelector("body") as Element,
        )}
    </>
  );
};
