import * as classnames from "classnames";
import { isEqual } from "lodash";
import * as React from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";

import { useDidUpdateEffect } from "@shared/hooks";
import { useOfficialExamList } from "@shared/hooks/query";
import { updateUrl } from "@shared/services/queryString";

import {
  Input,
  Label,
  Loading,
  OfficialExamCard,
  Overlay,
  PageTitle,
  Pagination,
  Select,
  SelectItem,
  PageSubTitle,
  Msg,
} from "../../../../shared/components";
import {
  PaginationModel,
  ExamCreateOptionsModel,
} from "../../../../shared/models";
import MSG from "../../../../shared/services/message";
import { OfficialExamPanel, OfficialExamUtil } from "./partials";

/**
 * Prop interface
 */
export interface OfficialExamListProps {
  currentProjectId: number;
  examCreateOptions: ExamCreateOptionsModel;
  loadingChallenge: boolean;
  loadingExam: boolean;
  loadingReadme: boolean;
  submitting: boolean;
}

/**
 * State interface
 */
export interface OfficialExamListState {
  filtersValue: {
    keyword: string;
    maxExaminationTime: string;
    naturalLanguage: string;
    targetEngineerRole: string;
  };
  isPanelOpen: boolean;
  selectedExamId?: number;
}

/**
 * Page component
 */
const OfficialExamList = ({
  currentProjectId,
  examCreateOptions: { roleoptions = [] },
  loadingChallenge,
  loadingExam,
  loadingReadme,
  submitting,
}: OfficialExamListProps) => {
  const sortByItems: Readonly<SelectItem[]> = Object.freeze([
    { label: MSG.getMessageByKey("common.notDefined"), value: "empty" },
    {
      label: MSG.getMessageByKey("exam.officialExam.sortByMany"),
      value: "sortByMany",
    },
    {
      label: MSG.getMessageByKey("exam.officialExam.sortByLess"),
      value: "sortByLess",
    },
    {
      label: MSG.getMessageByKey("exam.officialExam.sortByNewest"),
      value: "sortByNewest",
    },
  ]);

  const roleOptionsByValue = React.useMemo(
    () =>
      roleoptions.reduce((arr, option) => {
        arr[option.value] = option;
        return arr;
      }, {}),
    [roleoptions],
  );

  const rootStyle = classnames("code-official-exam-list");
  const {
    page,
    sortBy: parseSortBy,
    sortItem = OfficialExamUtil.getSortItem(),
    filtersValue,
  } = OfficialExamUtil.parseURL(window.location.search || "");
  const parsePage = Number(page) || 0;
  const [isPanelOpen, setIsPanelOpen] = React.useState(false);
  const [selectedExamId, setSelectedExamId] = React.useState<number>();
  const [pagination, setPagination] = React.useState(
    new PaginationModel().getAtPage(parsePage),
  );
  const [keyword, setKeyword] = React.useState<string>(filtersValue.keyword);
  const [maxExaminationTime, setMaxExaminationTime] = React.useState<string>(
    filtersValue.maxExaminationTime || "0",
  );
  const [naturalLanguage, setNaturalLanguage] = React.useState<string>(
    filtersValue.naturalLanguage || "0",
  );
  const [targetEngineerRole, setTargetEngineerRole] = React.useState<string>(
    filtersValue.targetEngineerRole || "0",
  );
  const [sortBy, setSortBy] = React.useState(parseSortBy || "empty");
  const [sortOrder, setSortOrder] = React.useState(sortItem);
  const {
    data,
    isFetched: officialExamListReady,
    isFetching: loadingExamList,
  } = useOfficialExamList({
    keyword,
    limit: pagination.limit,
    maxExaminationTime,
    naturalLanguage,
    targetEngineerRole,
    offset: pagination.offset,
    sortOrder,
  });
  const { officialExamList = [], officialExamListPagination = pagination } =
    data || {};

  const nowLoading =
    !loadingChallenge &&
    !loadingExam &&
    !loadingReadme &&
    (submitting || loadingExamList || !officialExamListReady);

  useDidUpdateEffect(() => {
    const newFiltersValue = {
      keyword,
      maxExaminationTime,
      naturalLanguage,
      targetEngineerRole,
    };

    if (
      parsePage === pagination.currentPage &&
      isEqual(sortItem, sortOrder) &&
      isEqual(filtersValue, newFiltersValue)
    ) {
      return;
    }

    updateUrl({
      ...OfficialExamUtil.getURLObjectByFilterValues(newFiltersValue),
      ...OfficialExamUtil.getURLObjectBySortBy(sortBy),
      page: pagination.currentPage,
    });
  }, [
    filtersValue,
    keyword,
    maxExaminationTime,
    naturalLanguage,
    page,
    pagination.currentPage,
    parsePage,
    sortBy,
    sortItem,
    sortOrder,
    targetEngineerRole,
  ]);

  const onPageChange = ({ selected }: { selected: number }) => {
    setPagination((prev) => prev.getAtPage(selected));
  };

  const onChangeExamId = (id: number) => {
    setSelectedExamId(id);
    setIsPanelOpen(true);
  };

  const onClosePanel = () => {
    setIsPanelOpen(false);
  };

  const onSelectChange = (e: React.FormEvent<HTMLSelectElement>) => {
    const { value, name } = e.target as HTMLSelectElement;
    const newValue = value === "0" ? "" : value;

    switch (name) {
      case "maxExaminationTime":
        setMaxExaminationTime(newValue);
        break;
      case "naturalLanguage":
        setNaturalLanguage(newValue);
        break;
      case "targetEngineerRole":
        setTargetEngineerRole(newValue);
        break;
      default:
        break;
    }
    setPagination(new PaginationModel());
  };

  const onChangeKeyword = (e: React.FormEvent<HTMLInputElement>) => {
    const { value } = e.target as HTMLInputElement;

    setKeyword(value);
    setPagination(new PaginationModel());
  };

  const onSortOrderchange = (e: React.FormEvent<HTMLSelectElement>) => {
    const { value } = e.target as HTMLSelectElement;

    setSortOrder(OfficialExamUtil.getSortItem(value));
    setSortBy(value);
    setPagination(new PaginationModel());
  };

  return (
    <div className={rootStyle}>
      <Loading isOpen={nowLoading} />
      <BreadcrumbsItem to={`/p/${currentProjectId}/exams`}>
        <Msg id="common.examList" />
      </BreadcrumbsItem>
      <BreadcrumbsItem to={`/p/${currentProjectId}/exams/official`}>
        <Msg id="createExam.officialExam.list" />
      </BreadcrumbsItem>
      <PageTitle>
        <Msg id="createExam.officialExam.list" />
        <span className="search-results">
          {MSG.getSearchResultFormat(officialExamListPagination.count)}
        </span>
      </PageTitle>
      <div className="code-official-exam-list__header">
        <div className="code-official-exam-list__header-item">
          <Label title={MSG.getMessageByKey("form.jobTitle")} />
          <Select
            name="targetEngineerRole"
            options={roleoptions}
            onChange={onSelectChange}
            value={targetEngineerRole}
          />
        </div>
        <div className="code-official-exam-list__header-item">
          <Label
            title={MSG.getMessageByKey(
              "createExam.officialExam.examinationTime",
            )}
          />
          <Select
            name="maxExaminationTime"
            options={
              [
                {
                  label: MSG.getMessageByKey("common.notDefined"),
                  value: "0",
                },
                {
                  label: `〜30${MSG.getMessageByKey("unit.mins")}`,
                  value: "30",
                },
                {
                  label: `〜60${MSG.getMessageByKey("unit.mins")}`,
                  value: "60",
                },
                {
                  label: `〜120${MSG.getMessageByKey("unit.mins")}`,
                  value: "120",
                },
                {
                  label: `〜240${MSG.getMessageByKey("unit.mins")}`,
                  value: "240",
                },
              ] as SelectItem[]
            }
            onChange={onSelectChange}
            value={maxExaminationTime}
          />
        </div>
        <div className="code-official-exam-list__header-item">
          <Label title={MSG.getMessageByKey("common.language")} />
          <Select
            name="naturalLanguage"
            options={
              [
                {
                  label: MSG.getMessageByKey("common.notDefined"),
                  value: "0",
                },
                { label: MSG.getMessageByKey("ja"), value: "ja" },
                { label: MSG.getMessageByKey("en"), value: "en" },
              ] as SelectItem[]
            }
            onChange={onSelectChange}
            value={naturalLanguage}
          />
        </div>
        <div className="code-official-exam-list__header-item">
          <Label title={MSG.getMessageByKey("form.keyword")} />
          <Input
            value={keyword}
            onChange={onChangeKeyword}
            placeholder={MSG.getMessageByKey("form.search.byTitle")}
          />
        </div>
        <div className="code-official-exam-list__header-item">
          <Label title={MSG.getMessageByKey("sortBy")} />
          <Select
            name="sortBy"
            value={sortBy}
            options={[...sortByItems]}
            onChange={onSortOrderchange}
          />
        </div>
      </div>
      {officialExamList.length ? (
        <div className="code-official-exam-list__card-container">
          {officialExamList.map((data) => (
            <div
              className="card-wrapper"
              onClick={() => onChangeExamId(data.id)}
              key={`${data.id}${data.name}`}
              role="button"
              aria-label="Open Preset Exam Detail"
            >
              <OfficialExamCard
                imageUrl={data.coverImageUrl}
                language={data.naturalLanguage.toUpperCase()}
                title={data.name}
                engineerRole={
                  roleOptionsByValue[data.examSegment.engineerRole]?.label
                }
                time={data.examinationTime}
                usingOrgCount={data.usingOrganizationsCount}
                updatedAt={data.updatedAt}
              />
            </div>
          ))}
        </div>
      ) : (
        !nowLoading && (
          <PageSubTitle className="code-official-exam-list__no-result">
            <Msg id="message.noResultsFromList" />
          </PageSubTitle>
        )
      )}
      <div className="code-official-exam-list__pagination">
        <Pagination
          pagination={officialExamListPagination}
          onPageChange={onPageChange}
        />
      </div>
      {isPanelOpen && selectedExamId !== undefined && (
        <>
          <Overlay
            className="panel-overlay"
            isOpen={isPanelOpen}
            onClick={onClosePanel}
          />
          <OfficialExamPanel
            isOpen={isPanelOpen}
            examId={selectedExamId}
            onClose={onClosePanel}
          />
        </>
      )}
    </div>
  );
};

export default OfficialExamList;
