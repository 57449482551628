import { useFormContext } from "react-hook-form";

import { Form } from "@components/orgs/exams/examCreate/types";

import {
  DatePicker,
  DatePickerType,
  ErrorMessage,
  FormGroup,
  Input,
  Label,
  Msg,
  QuickHelp,
} from "@shared/components";
import { dayjs } from "@shared/services/date";
import Message from "@shared/services/message";

import { useDraftForm } from "../DraftFormProvider";
import { ExamSettingsFormValidation } from "../utils";

interface UrlActivePeriodProps {
  readOnly?: boolean;
  startAtProps: {
    onChange: (date: string) => void;
    value: string;
    error?: string;
  };
  endAtProps: {
    onChange: (date: string) => void;
    value: string;
    error?: string;
  };
  dynamicDeadlineHoursFieldId:
    | "idDeliveryConfig.dynamicDeadlineHours"
    | "urlSharingConfig.dynamicDeadlineHours";
  isEmailDelivery?: boolean;
}

export const UrlActivePeriod = ({
  readOnly,
  startAtProps,
  endAtProps,
  dynamicDeadlineHoursFieldId,
  isEmailDelivery,
}: UrlActivePeriodProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<Form>();

  const { setDraftState } = useDraftForm<ExamSettingsFormValidation>();

  const dynamicDeadlineHourError =
    dynamicDeadlineHoursFieldId === "idDeliveryConfig.dynamicDeadlineHours"
      ? errors?.idDeliveryConfig?.dynamicDeadlineHours
      : errors?.urlSharingConfig?.dynamicDeadlineHours;
  return (
    <>
      <FormGroup>
        <Label>
          <Msg
            id={
              isEmailDelivery
                ? "exam.idDelivery.selfDeliveryUrlPeriodLabel"
                : "exam.idDelivery.urlPeriodLabel"
            }
          />
        </Label>
        <div className="code-exam-create-new__settings__delivery-date-container">
          <DatePicker
            allowPast={true}
            type={DatePickerType.DateTime}
            disabled={readOnly}
            onChange={startAtProps.onChange}
            errorMessage={startAtProps.error}
            value={startAtProps.value}
            minDate={dayjs().format()}
          />
          <span className="tilde">~</span>
          <DatePicker
            type={DatePickerType.DateTime}
            disabled={readOnly}
            adjustPastDateToPresent={true}
            onChange={endAtProps.onChange}
            value={endAtProps.value}
            errorMessage={endAtProps.error}
            minDate={
              startAtProps.value && startAtProps.value < dayjs().format()
                ? dayjs().format()
                : startAtProps.value
            }
          />
        </div>
      </FormGroup>
      <div>
        <FormGroup inline={true}>
          <Label
            isOptional={true}
            title={<Msg id="exam.timeLimitLabel" />}
            rightSection={
              <QuickHelp
                text={Message.getMessageByKey("exam.timeLimitLabel.tooltip")}
                maxSize="xlarge"
              />
            }
          />
          <Input
            className="code-exam-create-new__settings__days-input"
            type="number"
            {...register(dynamicDeadlineHoursFieldId, {
              setValueAs: (value) => {
                const formattedValue = value === "" ? undefined : Number(value);
                setDraftState((draft) => {
                  if (
                    dynamicDeadlineHoursFieldId ===
                      "urlSharingConfig.dynamicDeadlineHours" &&
                    draft.urlSharingConfig
                  ) {
                    draft.urlSharingConfig.dynamicDeadlineHours =
                      formattedValue;
                  }

                  if (
                    dynamicDeadlineHoursFieldId ===
                      "idDeliveryConfig.dynamicDeadlineHours" &&
                    draft.idDeliveryConfig
                  ) {
                    draft.idDeliveryConfig.dynamicDeadlineHours =
                      formattedValue;
                  }

                  return draft;
                });
                return formattedValue;
              },
            })}
            min="1"
            max="365"
            step="1"
            disabled={readOnly}
            hasError={Boolean(dynamicDeadlineHourError?.message)}
            dataTestautomationid="dynamicDeadlineHours"
          />
          <Msg id={"unit.days"} />
        </FormGroup>
        <ErrorMessage message={dynamicDeadlineHourError?.message} />
      </div>
    </>
  );
};
