import classnames from "classnames";

interface ProgressBarProps {
  color?: "primary" | "neutral";
  percent: number;
}

function ProgressBar({ color = "neutral", percent }: ProgressBarProps) {
  const rootStyle = classnames(
    "code-c-progress-bar",
    `code-c-progress-bar--${color}`,
  );
  const barStyle = classnames(`code-c-progress-bar__bg`);

  return (
    <div className={rootStyle}>
      <div className={barStyle} style={{ width: `${percent}%` }} />
    </div>
  );
}

export default ProgressBar;
