import { filterValidProgrammingLanguages } from "@shared/services/challengeCollection";

import ChallengeModel from "./Challenge.model";

class OriginalChallengeModel {
  public title: string;
  public programmingLanguages: Array<number> = [];
  public status: number;
  public language: string;
  public linkedChallenge?: ChallengeModel;
  public createdAt: string;

  constructor(args?: {}) {
    Object.assign(this, args);

    if (this.programmingLanguages) {
      this.programmingLanguages = filterValidProgrammingLanguages(
        this.programmingLanguages,
      );
    }

    if (this.linkedChallenge) {
      this.linkedChallenge = new ChallengeModel(this.linkedChallenge);
    }
  }
}

class ExamChallengeModel {
  public id: number;
  public challengeVersionId: number;
  public challengeSetId: number;
  public examId: number;
  public challengeId: number;
  public timeLimitMinutes?: number; // undefined means unlimited
  public displayOrder: number;
  public title: string;
  public originalChallenge: OriginalChallengeModel =
    new OriginalChallengeModel();
  public description: string;
  public majorVersionNumber: number;
  public programmingLanguages: Array<number> = [];
  public weight: number;
  public settings: {} = {};
  public createdBy: number;
  public updatedBy: number;
  public createdAt: string;
  public updatedAt: string;
  public difficulty: number;
  public style: number;
  public localExamEnabled: boolean;
  public randomizeQuiz: boolean;
  public basicTimeMinutes: number;
  public isOfficial: boolean;
  public linkedChallenge?: ChallengeModel;
  public linkedChallengeId?: number;
  public linkedChallengeVersionId?: number;
  public linkedLanguage?: string;
  public linkedMajorVersionNumber?: number;

  // NOTE: frontend only! set original exam challenge model when once switched to linked challenge
  public originalLinkedChallenge?: ExamChallengeModel;
  public usedChallengeVersionCode?: string;
  public nextAvailableChallengeVersionCode?: string;
  public nextAvailableLinkedChallengeVersionCode?: string;
  public favoriteId?: number;

  constructor(args?: {}) {
    Object.assign(this, args);

    if (this.programmingLanguages) {
      this.programmingLanguages = filterValidProgrammingLanguages(
        this.programmingLanguages,
      );
    }

    if (this.linkedChallenge) {
      this.linkedChallenge = new ChallengeModel(this.linkedChallenge);
    }

    if (this.originalChallenge) {
      this.originalChallenge = new OriginalChallengeModel(
        this.originalChallenge,
      );
    }

    if (this.originalLinkedChallenge) {
      this.originalLinkedChallenge = new ExamChallengeModel(
        this.originalLinkedChallenge,
      );
    }
  }
}

class ExamChallengeSetModel {
  public id: number;
  public isOptionalSet?: boolean;
  public isRandomSet?: boolean;
  public numberChallengesToTake?: number;
  public displayOrder: number;
  public challenges: ExamChallengeModel[] = [];
  // NOTE: it is just a placeholder for frontend
  public weightPlaceholderForNoChallenge?: number;

  constructor(args?: {}) {
    Object.assign(this, args);

    if (this.challenges) {
      this.challenges = this.challenges.map(
        (challenge) => new ExamChallengeModel(challenge),
      );
    }
  }
}

export default ExamChallengeModel;
export { OriginalChallengeModel, ExamChallengeSetModel };
