import * as classnames from "classnames";

import { Msg, Form, FormGroup, Radio } from "@shared/components";
import { MultiManageForm } from "@shared/models";

/**
 * Interface
 */
export type MultipleManageModalVersionProps = {
  formValues: MultiManageForm;
  isShow: boolean;

  onFormChange: (valid: boolean, value: MultiManageForm, errors: {}) => void;
};

/**
 * Component
 * @param props MultipleManageModalVersionProps
 */
export function MultipleManageModalVersion({
  formValues,
  isShow,
  onFormChange,
}: MultipleManageModalVersionProps) {
  const rootStyle = classnames("form-group-container", {
    "is-show": isShow,
  });

  /**
   * Render
   */
  return (
    <div className={rootStyle}>
      <p>
        <Msg id="submission.message.selectVersion" />
      </p>
      <Form
        validation={{ version: ["required"] }}
        initialValues={{ version: formValues.version?.toString() }}
        onFormChange={onFormChange}
      >
        <FormGroup className="radio-group">
          <Radio name="version" defaultValue="1">
            <Msg id="submission.action.currentVersion" />
          </Radio>
          <Radio name="version" defaultValue="2">
            <Msg id="submission.action.updateVersion" />
          </Radio>
        </FormGroup>
      </Form>
    </div>
  );
}
