import {
  UserStatus,
  UserRole,
  ProjectRole as ProjectRoleEnum,
  ApplicationType,
  SubmissionListKind,
  ProjectRole,
} from "@shared/services/enums";
import { getCurrentProjectIdByUser } from "@shared/services/localStorage";
import Message from "@shared/services/message";
import { getDefaultSignInPathname } from "@shared/services/url";

import { OrganizationModel, MemberListModel, ProjectSwitchItemModel } from ".";

export interface UserRoleModel {
  projectId?: number;
  role: number;
}

export interface ProjectRoleModel {
  id: number;
  roles: number[];
}

export enum SubmissionAccessType {
  Deny,
  Full,
  ExamDeliverer,
}

const INVITATION_STRING = "_invitation_";

class UserModel {
  public id: number;
  public organizationId: number;
  public name: string;
  public email: string;
  public imageUrl: string;
  public language: string;
  public status: UserStatus;
  public notificationLastReadAt: string;
  public dailySubmissionNotificationEnabled: boolean;
  public realtimeSubmissionNotificationEnabled: boolean;
  public newUserNotificationEnabled: boolean;
  public createdBy: number;
  public createdAt: string;
  public updatedAt: string;
  public roles: Array<UserRoleModel> = [];
  public organization: OrganizationModel = new OrganizationModel();
  public projects: Array<ProjectSwitchItemModel> = [];
  public subscribedToAllExamNotifications: boolean;
  public examNotificationSubscriptions: number[];

  constructor(args?: {}) {
    Object.assign(this, args);

    const { organization, projects, roles } = (args as UserModel) || {};

    if (organization) {
      this.organization = new OrganizationModel(organization);
    }

    if (Array.isArray(projects)) {
      this.projects = projects.filter(
        (project) => project.applicationType === ApplicationType.Screening,
      );
    }

    if (Array.isArray(roles)) {
      this.roles = roles.filter(
        (role) =>
          ![
            ProjectRoleEnum.Deprecated_TrainingAdmin,
            ProjectRoleEnum.Deprecated_CourseCreator,
            ProjectRoleEnum.Deprecated_CourseDeliverer,
            ProjectRoleEnum.Deprecated_CourseReviewer,
            UserRole.BookCreator,
          ].includes(role.role),
      );
    }
  }

  public isEnableMultipleAppMode() {
    return Boolean(this.roles.length);
  }

  public getName() {
    return this.name === INVITATION_STRING
      ? Message.getMessageByKey("inviting")
      : this.name;
  }

  /**
   * Get the root path for the initial access
   */
  public getRootPath() {
    // Not authenticated
    if (this.id === undefined) {
      return getDefaultSignInPathname(window.location.hostname);
    }

    // OrgAdmin
    if (this.isOnlyOrgAdmin()) {
      return "/settings/org/profile";
    }

    // Project-related settings
    if (this.projects.length > 0) {
      const lastProjectId = getCurrentProjectIdByUser(this.id.toString());

      // default project is first item in projects array
      let currentProjectId = this.projects[0].id;

      // if there is a last project Id, check if it exists
      // and redirect to that instead
      if (
        lastProjectId &&
        this.projects.find(({ id }) => id === lastProjectId)
      ) {
        currentProjectId = lastProjectId;
      }

      // ChallengeCreator
      return this.isOnlyProjectChallengeCreator(currentProjectId)
        ? `/p/${currentProjectId}/challenges`
        : `/p/${currentProjectId}/exams/active`;
    }

    // Default
    return "/courses";
  }

  /**
   * get roles
   *
   * Note:
   *  this method has different behavior by passed project id.
   *  - if it gets passed, it will return ProjectRoles
   *  - else it will return UserRoles
   * @param projectId
   */
  public getRoles(projectId?: number): Array<number> {
    return this.roles
      .filter(
        (role) =>
          (!projectId && UserRole[role.role] !== undefined) ||
          role.projectId === projectId,
      )
      .map((role) => role.role);
  }

  /**
   * check if the user has the roles
   *
   * @param roles
   * @param projectId
   */
  public hasRole(roles: number | Array<number>, projectId?: number): boolean {
    if (Array.isArray(roles)) {
      return (
        this.getRoles().some((role) => roles.includes(role)) ||
        this.getRoles(projectId).some((role) => roles.includes(role))
      );
    } else {
      return (
        this.getRoles().includes(roles) ||
        this.getRoles(projectId).includes(roles)
      );
    }
  }

  /**
   * Make sure the user only has Project(Scoped)ChallengeCreator role
   * @param projectId
   */
  public isOnlyProjectChallengeCreator(projectId?: number): boolean {
    const projectRoles = this.getRoles(projectId);
    return (
      projectRoles.length === 1 &&
      projectRoles[0] === ProjectRoleEnum.ChallengeCreator
    );
  }

  /**
   * Make sure the user only has SystemAdmin role
   */
  public isOnlySystemAdmin(projectId?: number): boolean {
    return (
      this.hasRole(UserRole.SystemAdmin) &&
      this.roles.filter(
        (item) =>
          ProjectRoleEnum[item.role] !== undefined &&
          item.projectId === projectId,
      ).length === 0
    );
  }

  /**
   * Make sure the user only has OrgAdmin role
   */
  public isOnlyOrgAdmin(): boolean {
    // NOTE: This should be more simple like this.
    //   return this.roles.length === 1 && this.hasRole(UserRoleEnum.OrgAdmin);
    // But frontend send ChallengeCreator and BookCreator roles when you create a OrgAdmin.
    // This fact makes this logic weird. but there is nothing we can do. ツ
    return this.roles.some((role) => role.projectId !== undefined)
      ? false
      : !this.hasRole(UserRole.SystemAdmin) && this.hasRole(UserRole.OrgAdmin);
  }

  public hasProjectAdmin(projectId: number) {
    return (
      this.hasRole([UserRole.SystemAdmin]) ||
      this.hasRole([ProjectRoleEnum.ProjectAdmin], projectId)
    );
  }

  /**
   * Returns the type of permissions that can access submission
   * @param projectId
   * @param submissionStatus
   * @param reviewers
   */
  public getAccessSubmissionType(
    projectId: number,
    submissionListKind?: SubmissionListKind,
    reviewers: MemberListModel[] = [],
  ): SubmissionAccessType {
    if (this.hasProjectAdmin(projectId)) {
      return SubmissionAccessType.Full;
    }

    if (
      this.hasRole([ProjectRoleEnum.Reviewer], projectId) &&
      reviewers.some((reviewer) => reviewer.id === this.id)
    ) {
      return SubmissionAccessType.Full;
    }

    if (
      this.hasRole(ProjectRoleEnum.ExamDeliverer, projectId) &&
      submissionListKind !== undefined &&
      [
        SubmissionListKind.all,
        SubmissionListKind.unread,
        SubmissionListKind.inprogress,
        SubmissionListKind.submitted,
        SubmissionListKind.inreview,
        SubmissionListKind.reviewed,
        SubmissionListKind.passed,
        SubmissionListKind.failed,
        SubmissionListKind.expired,
        SubmissionListKind.evaluated,
      ].includes(submissionListKind)
    ) {
      return SubmissionAccessType.ExamDeliverer;
    }

    return SubmissionAccessType.Deny;
  }

  public canEditPin(currentProjectId: number) {
    return this.hasRole(
      [ProjectRole.ProjectAdmin, ProjectRole.ExamCreator],
      currentProjectId,
    );
  }

  public canAccessDashboardCharts(currentProjectId: number) {
    return (
      this.hasProjectAdmin(currentProjectId) ||
      this.hasRole(
        [ProjectRole.Reviewer, ProjectRole.ExamDeliverer],
        currentProjectId,
      )
    );
  }

  public canViewChallenges(currentProjectId: number) {
    return (
      this.hasProjectAdmin(currentProjectId) ||
      this.hasRole([ProjectRole.Reviewer], currentProjectId)
    );
  }
}

export default UserModel;
