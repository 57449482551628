import { createContext, FC, useContext } from "react";

import { SubmissionAccessType } from "@shared/models";

interface Props {
  submissionAccessType: SubmissionAccessType;
}

const SubmissionAccessTypeContext = createContext(SubmissionAccessType.Deny);

export const useSubmissionAccessTypeContext = () =>
  useContext(SubmissionAccessTypeContext);

export const SubmissionAccessTypeProvider: FC<Props> = ({
  children,
  submissionAccessType,
}) => {
  return (
    <SubmissionAccessTypeContext.Provider value={submissionAccessType}>
      {children}
    </SubmissionAccessTypeContext.Provider>
  );
};
