import * as classnames from "classnames";
import { isEqual } from "lodash";
import * as React from "react";
import * as ReactDOM from "react-dom";

import { QuestionListModel } from "@shared/models";
import { formatTimeElapsedStringFromMillis } from "@shared/services/date";

import {
  Row,
  Column,
  TableBody,
  QuestionTitleColumn,
  MoreLink,
  Loading,
  Checkbox,
  HighlightedText,
} from "..";
import { QuestionType } from "../../services/enums";
import { PossiblyNoDataWithTooltip } from "../question/partials/PossiblyNoDataWithTooltip";

/**
 * Prop interface
 */
export interface CollapsibleQuestionColumnProps {
  question: QuestionListModel;
  onOpen?: (questionId: number) => void;
  keyword?: string;
  className?: string;
  hasCheckbox?: boolean;
  readOnly?: boolean;
  value?: boolean;
  colSpan?: number;
  onChange?: (questionId: number) => void;
  children?: {};
  loading?: boolean;
  actions?: {};
  inModal?: boolean;
}

/**
 * State interface
 */
export interface CollapsibleQuestionColumnState {
  isOpen: boolean;
  isShowMoreLink: boolean;
  showStickyHeader?: boolean;
  stickyTop: number;
  stickyWidth: number;
}

const HEADER_HEIGHT = 48;
const MODAL_HEADER_HEIGHT = 88;
const MODAL_OFFSET = 20;

/**
 * React Component
 */
export default class CollapsibleQuestionColumn extends React.Component<
  CollapsibleQuestionColumnProps,
  CollapsibleQuestionColumnState
> {
  private element: TableBody | null;
  private content: Row | null;
  private stickyHeader: HTMLElement | null;

  constructor(props: CollapsibleQuestionColumnProps) {
    super(props);

    this.state = {
      isOpen: false,
      isShowMoreLink: false,
      showStickyHeader: false,
      stickyTop: 0,
      stickyWidth: 0,
    };
  }

  public shouldComponentUpdate(
    nextProps: CollapsibleQuestionColumnProps,
    nextState: CollapsibleQuestionColumnState,
  ) {
    return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state);
  }

  public componentWillUnmount() {
    this.removeListener();
  }

  public render() {
    const {
      className,
      children,
      question,
      hasCheckbox,
      readOnly,
      value,
      keyword,
      actions,
      colSpan = 9,
    } = this.props;

    const rootStyle = classnames("code-c-collapsible-question-column", {
      "is-open": this.state.isOpen,
      "is-close": !this.state.isOpen,
      [`${className}`]: Boolean(className),
    });

    const challengeString = (
      this.state.isShowMoreLink
        ? question.challenges.map(({ title }) => title)
        : question.challenges.slice(0, 2).map(({ title }) => title)
    ).join(" / ");

    return (
      <TableBody
        className={rootStyle}
        ref={(r) => (this.element = r)}
        ariaExpanded={this.state.isOpen}
      >
        <Row className="code-c-collapsible-question-column__header">
          {hasCheckbox && (
            <Column>
              <Checkbox
                readOnly={readOnly}
                value={value}
                onChange={this.onChange}
              />
            </Column>
          )}
          <Column>
            <HighlightedText keyword={keyword}>
              {question.id.toString()}
            </HighlightedText>
          </Column>
          <Column>
            <QuestionTitleColumn
              isOpen={this.state.isOpen}
              keyword={keyword}
              question={question}
              onClick={this.onToggle}
            />
          </Column>
          <Column>{QuestionType.toString(question.kind)}</Column>
          <Column>
            {challengeString}
            {!this.state.isShowMoreLink && (
              <MoreLink
                totalCount={question.challenges.length}
                displayCount={2}
                onClick={() => this.setState({ isShowMoreLink: true })}
              />
            )}
          </Column>
          <Column
            aria-label={`organizationCount-${question.id}`}
            className="align-right"
          >
            {question.globalInsight?.overall.organizationCount ?? "-"}
          </Column>
          <Column
            aria-label={`totalUsed-${question.id}`}
            className="align-right"
          >
            {question.globalInsight?.overall.totalCount ?? "-"}
          </Column>
          <Column
            aria-label={`globalCorrectRate-${question.id}`}
            className="align-right"
          >
            <PossiblyNoDataWithTooltip
              value={question.getGlobalCorrectRateAsFormattedString()}
            />
          </Column>
          <Column
            aria-label={`timeAverageMillis-${question.id}`}
            className="align-right"
          >
            <PossiblyNoDataWithTooltip
              value={formatTimeElapsedStringFromMillis(
                question.globalInsight?.overall.timeAverageMillis,
              )}
            />
          </Column>
          {actions}
        </Row>
        <Row
          ref={(r) => (this.content = r)}
          className="code-c-collapsible-question-column__contents"
          ariaHidden={!this.state.isOpen}
        >
          <Column colSpan={colSpan}>
            <div style={{ minHeight: 100, position: "relative" }}>
              {this.state.showStickyHeader && (
                <div
                  ref={(r) => (this.stickyHeader = r)}
                  style={{
                    top: this.state.stickyTop + "px",
                    width: this.state.stickyWidth + "px",
                  }}
                  className="code-c-collapsible-question-column__sticky-header"
                >
                  <QuestionTitleColumn
                    isOpen={this.state.isOpen}
                    keyword={keyword}
                    question={question}
                    onClick={this.onClose}
                    hasTagCloud={false}
                  />
                </div>
              )}
              <Loading
                isOpen={this.props.loading}
                fullScreen={false}
                overlay={false}
              />
              {!this.props.loading && (
                <div className="code-c-collapsible-question-column__contents__container">
                  {children}
                </div>
              )}
            </div>
          </Column>
        </Row>
      </TableBody>
    );
  }

  private addListener = () => {
    if (this.props.inModal) {
      const scrollParent = document.querySelector(
        ".modal-card-body .code-c-sub-menu-container-right",
      );
      if (scrollParent) {
        scrollParent.addEventListener("scroll", this.onChangeViewport);
      }
    } else {
      window.addEventListener("scroll", this.onChangeViewport);
    }
    window.addEventListener("resize", this.onChangeViewport);
  };

  private removeListener = () => {
    if (this.props.inModal) {
      const scrollParent = document.querySelector(
        ".modal-card-body .code-c-sub-menu-container-right",
      );
      if (scrollParent) {
        scrollParent.removeEventListener("scroll", this.onChangeViewport);
      }
    } else {
      window.removeEventListener("scroll", this.onChangeViewport);
    }
    window.removeEventListener("resize", this.onChangeViewport);
  };

  private onChange = () => {
    if (typeof this.props.onChange === "function") {
      this.props.onChange(this.props.question.id);
    }
  };

  private onToggle = () => {
    if (this.state.isOpen) {
      this.removeListener();
    } else {
      this.addListener();
    }

    this.setState(
      { isOpen: !this.state.isOpen, showStickyHeader: false },
      () => {
        if (this.state.isOpen && typeof this.props.onOpen === "function") {
          this.props.onOpen(this.props.question.id);
        }
      },
    );
  };

  private onClose = () => {
    this.removeListener();

    this.setState({ isOpen: false, showStickyHeader: false });
    if (this.element) {
      const element = ReactDOM.findDOMNode(this.element) as Element;
      const top = element.getBoundingClientRect().top;

      // When in a modal, the scrollparent is not the window so check for this.
      if (this.props.inModal) {
        const scrollParent = document.querySelector(
          ".modal-card-body .code-c-sub-menu-container-right",
        );
        if (scrollParent) {
          scrollParent.scrollTop += top - MODAL_HEADER_HEIGHT;
        }
      } else {
        window.scrollBy(0, top - HEADER_HEIGHT);
      }
    }
  };

  private onChangeViewport = () => {
    // when resizing or scrolling
    // check if we should show floating header
    if (this.content && this.element) {
      const content = ReactDOM.findDOMNode(this.content) as Element;

      const top = content.getBoundingClientRect().top;
      const bottom = content.getBoundingClientRect().bottom;

      const headerHeight = this.stickyHeader
        ? this.stickyHeader.offsetHeight
        : 100;

      // When in a modal, the scrollparent is not the window so check for this.
      if (this.props.inModal) {
        // in modal
        this.setState({
          showStickyHeader:
            top <= MODAL_HEADER_HEIGHT && bottom > MODAL_HEADER_HEIGHT,
          stickyTop:
            Math.min(MODAL_HEADER_HEIGHT, bottom - headerHeight) - MODAL_OFFSET,
          stickyWidth: content.clientWidth - 1,
        });
      } else {
        // normal list
        this.setState({
          showStickyHeader: top <= HEADER_HEIGHT && bottom > HEADER_HEIGHT,
          stickyTop: Math.min(HEADER_HEIGHT, bottom - headerHeight),
          stickyWidth: content.clientWidth - 1,
        });
      }
    }
  };
}
