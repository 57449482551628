import * as classnames from "classnames";

/**
 * Prop interface
 */
export interface SubMenuContainerLeftProps {
  className?: string;
  children?: {};
}

/**
 * React Component
 */
export default function SubMenuContainerLeft({
  className,
  children,
}: SubMenuContainerLeftProps) {
  const rootStyle = classnames("code-c-sub-menu-container-left", {
    [`${className}`]: Boolean(className),
  });

  return <div className={rootStyle}>{children}</div>;
}
