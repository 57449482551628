import * as classnames from "classnames";

import { Icon, Tooltip } from "@shared/components";
import { useSubmission } from "@shared/hooks/query";
import Message from "@shared/services/message";

interface Props {
  className?: string;
  narrowed?: boolean;
}

export const OverlappingTooltip = ({ className, narrowed }: Props) => {
  const overlappingTooltip = Message.getMessageByKey(
    "submission.actionLog.overlappingIp",
  );
  const rootClass = classnames("code-c-overlapping-tooltip", className);
  const containerClass = classnames({
    "code-c-overlapping-tooltip__container__narrowed": narrowed,
  });
  const { data: submission } = useSubmission();

  if (!submission.hasOverlappingApplicantExams()) {
    return null;
  }

  return (
    <Tooltip wrapperClassName={rootClass} text={overlappingTooltip}>
      <Icon
        type=""
        className={containerClass}
        iconClassName="code-c-icon__user-exclamation code-c-icon__text-base"
      />
    </Tooltip>
  );
};
