import { ExamModel, ExamDeliverModel } from "./";

interface User {
  id: string;
  name: string;
  imageUrl: string;
}

interface UserAttributes {
  fullname: string;
  birthday: string;
}

class ApplicantModel {
  public id: number;
  public userId: number;
  public organizationId: number;
  public deliver: ExamDeliverModel;
  public exam: ExamModel;
  public user: User;
  public fullname: string;
  public userEmail: string;
  public email: string;
  public status: number;
  public scoreStatusSummary: number;
  public openedAt: string;
  public submittedAt: string;
  public allTestcases: number;
  public successTestcases: number;
  public allVotes: number;
  public plusVotes: number;
  public createdAt: string;
  public updatedAt: string;
  public userAttributes: UserAttributes;

  constructor(args?: {}) {
    Object.assign(this, args);
  }
}

export default ApplicantModel;
