import { useStoreContext } from "@context";

import { useUserRoles } from "@shared/hooks/auth";
import { useTierActionPermissions } from "@shared/hooks/auth/useTierActionPermissions";
import { TierAction } from "@shared/services/enums";
import { hasCodingChallenge, hasQuizChallenge } from "@shared/services/exam";
import {
  hasCriticalChallengeIssues,
  hasCriticalIssues,
  hasCriticalIssuesInChallengePage,
  hasCriticalIssuesInSettingsPage,
  hasCriticalOrFixableIssues,
  isMultiLangAllowed,
} from "@shared/services/tier";

import { useFeatureFlags } from "../query";
import { useExam } from "../query/exams/useExam";

export function useExamPermissions() {
  // TODO: refactor to useTierActionPermissions
  const { isTierActionAllowed } = useStoreContext();
  const { allowGuestSharing } = useTierActionPermissions();
  const {
    data: {
      examChallengesSets,
      examDetail,
      examDetail: { applicantActionSettings, webcamSettings },
    },
  } = useExam();
  const { isGuestSharingRole, isSystemAdmin } = useUserRoles();
  const enableViewCodePlayback =
    isTierActionAllowed(TierAction.CodePlayback) &&
    Boolean(applicantActionSettings.showKeyEvents || isSystemAdmin);

  const { data } = useFeatureFlags();

  const enableViewWebcam =
    Boolean(data?.webcamEnabled) &&
    isTierActionAllowed(TierAction.WebcamMonitoring) &&
    Boolean(webcamSettings?.enabled || isSystemAdmin);

  const enableApplicantActionLog =
    isTierActionAllowed(TierAction.ApplicantActionLogs) &&
    Boolean(
      applicantActionSettings.showCopyPasteEvents ||
        applicantActionSettings.showTabChangeEvents ||
        applicantActionSettings.showScreenshotEvents,
    );
  const hasCriticalIssue = hasCriticalIssues({
    examDetail,
    examDetailChallengesSets: examChallengesSets,
    isTierActionAllowed,
  });
  const hasCriticalChallengeIssue = hasCriticalChallengeIssues({
    examDetailChallengesSets: examChallengesSets,
    isTierActionAllowed,
  });
  const hasExamDetailOrChallengeSetIssues = hasCriticalOrFixableIssues({
    examDetail,
    examDetailChallengesSets: examChallengesSets,
    isTierActionAllowed,
  });
  const hasChallengePageIssues = hasCriticalIssuesInChallengePage({
    examDetailChallengesSets: examChallengesSets,
    isTierActionAllowed,
  });
  const hasSettingsPageIssues = hasCriticalIssuesInSettingsPage({
    examDetail,
    isTierActionAllowed,
  });
  const multiLangAllowed = isMultiLangAllowed(examDetail, isTierActionAllowed);
  const canDownloadEnhancedCsv =
    isTierActionAllowed(TierAction.EnhancedCSVDownload) &&
    (hasCodingChallenge(examDetail) || hasQuizChallenge(examDetail));
  const isGuestSharingEnabled =
    allowGuestSharing && examDetail.guestSharingEnabled && isGuestSharingRole;

  return {
    canDownloadEnhancedCsv,
    enableApplicantActionLog,
    enableViewCodePlayback,
    enableViewWebcam,
    hasCriticalIssue,
    hasChallengePageIssues,
    hasCriticalChallengeIssue,
    hasExamDetailOrChallengeSetIssues,
    hasSettingsPageIssues,
    isGuestSharingEnabled,
    multiLangAllowed,
  };
}
