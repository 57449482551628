import classNames from "classnames";
import * as React from "react";

import { Fade, Msg, TimelineItem, Tooltip } from "@shared/components";

interface SubmissionTimelineDetailProps {
  children: React.ReactNode;
  show?: boolean;
}

interface SubmissionTimelineDetailListItemProps {
  children: React.ReactNode;
  align?: "center";
  colon?: boolean;
  label?: React.ReactNode;
  labelId?: string;
  tooltip?: string;
}

function SubmissionTimelineDetail({
  children,
  show,
}: SubmissionTimelineDetailProps) {
  return (
    <TimelineItem className="code-submission-timeline-item" color="none">
      <Fade show={show}>{children}</Fade>
    </TimelineItem>
  );
}

SubmissionTimelineDetail.ListItem = function ListItem({
  children,
  align,
  colon,
  label,
  labelId,
  tooltip,
}: SubmissionTimelineDetailListItemProps) {
  const rootStyle = classNames("code-submission-timeline-item__default", {
    [`items-${align}`]: Boolean(align),
  });
  const labelStyle = classNames(
    "code-submission-timeline-item__default-label",
    { colon: Boolean(colon) },
  );

  return (
    <div className={rootStyle}>
      <Tooltip disabled={!tooltip} text={tooltip} maxSize="medium">
        <>
          {label}
          {labelId && <Msg tagName="div" id={labelId} className={labelStyle} />}
        </>
      </Tooltip>
      <div className="code-submission-timeline-item__default-content">
        {children}
      </div>
    </div>
  );
};

export default SubmissionTimelineDetail;
