import { connect } from "react-redux";

import { RootState } from "@reducers";

import { getTierAllowedByRedux } from "@shared/services/tier";

import CollapsibleChallengeColumn, {
  ExternalProps,
} from "./CollapsibleChallengeColumn";

const mapStateToProps = (state: RootState, props: ExternalProps) => ({
  ...props,
  programmingLanguages: state.challenge.challengeFilters.programmingLanguages,
  programmingCategories: state.challenge.challengeFilters.programmingCategories,
  isTierActionAllowed: getTierAllowedByRedux(state),
});

/**
 * Wrapped component
 */
const wrappedComponent = connect(mapStateToProps, () => ({}))(
  CollapsibleChallengeColumn,
);

export default wrappedComponent;
