import * as classnames from "classnames";
import * as React from "react";

export enum MessageBarType {
  Info,
  Warning,
  Error,
}

/**
 * Prop interface
 */
export interface MessageBarProps {
  type?: MessageBarType;
  dismissSecound?: number;
  onClose?: () => void;
  className?: string;
  children?: React.ReactNode;
}

/**
 * React Component
 */
export default function MessageBar({
  dismissSecound,
  type = MessageBarType.Info,
  onClose,
  className,
  children,
}: MessageBarProps) {
  const rootStyle = classnames("code-c-message-bar", {
    [`is-${MessageBarType[type].toLowerCase()}`]: Boolean(type),
    [`${className}`]: Boolean(className),
  });

  /**
   * State
   */
  const timer = React.useRef<number | null>(null);

  /**
   * Effects
   */
  React.useEffect(() => {
    if (dismissSecound !== undefined) {
      timer.current = window.setTimeout(
        () => onClickClose(),
        dismissSecound * 1000,
      );
    }
    return () => {
      if (timer.current !== null) {
        clearTimeout(timer.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dismissSecound]);

  /**
   * Private Functions
   */
  function onClickClose() {
    if (typeof onClose === "function") {
      onClose();
      if (timer.current !== null) {
        clearTimeout(timer.current);
      }
    }
  }

  return (
    <div className={rootStyle} role="alert">
      <div className="code-c-message-bar__body">{children}</div>
      <div className="code-c-message-bar__close">
        <a className="delete" onClick={onClickClose} />
      </div>
    </div>
  );
}
