// TODO refactor to hook

export const CURRENT_PROJECT_ID = "currentProjectIds";

export function removeCurrentProjectId() {
  localStorage.removeItem(CURRENT_PROJECT_ID);
}

export function getCurrentProjectIdByUser(userId: string): number {
  // When not found covert NaN to 0
  return (
    parseInt(
      JSON.parse(localStorage.getItem(CURRENT_PROJECT_ID) ?? "{}")[userId],
      10,
    ) || 0
  );
}

export function setCurrentProjectIdByUser(projectId: number, userId: string) {
  const localProjectIds = JSON.parse(
    localStorage.getItem(CURRENT_PROJECT_ID) ?? "{}",
  );
  localProjectIds[userId.toString()] = projectId;
  localStorage.setItem("currentProjectIds", JSON.stringify(localProjectIds));
}
