import {
  QueryFunctionContext,
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { isEmpty } from "lodash";
import { useCallback, useEffect, useMemo } from "react";
import { toast } from "react-toastify";

import { BaseSearchQuery, Pagination } from "@api/httpClient";
import { QueryCacheMeta } from "@api/queryClient";
import {
  SubmissionResponse,
  deletePendingSubmissionList,
  deleteSubmission,
  getSubmission,
  getSubmissionList,
  postManualSubmitSubmission,
} from "@api/submissions";

import { useStoreContext } from "@context";

import { useDebounce } from "@shared/hooks/useDebounce";
import {
  PaginationModel,
  SubmissionAccessType,
  SubmissionListModel,
  SubmissionModel,
  UserModel,
} from "@shared/models";
import { getLastWeekTimestamp } from "@shared/services/date";
import {
  ApplicantExamStatus,
  SubmissionListKind,
} from "@shared/services/enums";
import History from "@shared/services/history";
import Message from "@shared/services/message";

import { examKeys, useExam, useInvalidateExamCountRelation } from "../exams";
import { useRouteParams } from "../useRouteParams";

interface SubmissionListFilter extends BaseSearchQuery {
  applicantExamIds?: number[];
  archived?: boolean;
  examId?: number;
  excludedApplicantExamIds?: number[];
  excludedStatuses?: ApplicantExamStatus[];
  needsReview?: boolean;
  // if both status and statuses are passed to backend. statuses will be ignored
  status?: ApplicantExamStatus;
  statuses?: ApplicantExamStatus[];
  includeResults?: boolean;
  // sample submission filter
  isTest?: boolean;
  includeOverlappingIps?: boolean;
  includeCheatMonitoring?: boolean;
}

interface SubmissionList {
  submissionList: SubmissionListModel[];
  submissionPagination: PaginationModel;
}

interface SubmissionFilters {
  includeOverlappingIps?: boolean;
}

interface DeleteSubmissionParams {
  submissionId: number;
  memo?: string;
}

interface UseGetSubmissionParams<T> {
  filters?: SubmissionFilters;
  submissionId?: number;
  select: (data: SubmissionResponse) => T;
}

interface CancelPendingSubmissionParams {
  examId: number;
  memo?: string;
}

interface SubmissionDetailKeys {
  examId: number;
  projectId: number;
  submissionId: number;
  filters?: SubmissionFilters;
}

export const submissionKeys = {
  all: ["submissions"] as const,
  listAll: (projectId: number) => [...submissionKeys.all, projectId] as const,
  listExam: (projectId: number, examId: number) =>
    [...submissionKeys.listAll(projectId), examId] as const,
  list: (projectId: number, filters: SubmissionListFilter) => {
    if (filters.examId) {
      return [
        ...submissionKeys.listAll(projectId),
        filters.examId,
        { filters },
      ] as const;
    }

    // maintain query key structure
    return [...submissionKeys.listAll(projectId), 0, { filters }] as const;
  },
  detail: ({
    examId,
    projectId,
    submissionId,
    filters,
  }: SubmissionDetailKeys) => {
    if (!isEmpty(filters)) {
      return [
        ...submissionKeys.listExam(projectId, examId),
        submissionId,
        { filters },
      ] as const;
    }

    return [
      ...submissionKeys.listExam(projectId, examId),
      submissionId,
    ] as const;
  },
};

export function useSubmissionList(
  filters: SubmissionListFilter,
  isNameRequired?: boolean,
) {
  const { projectId, user } = useStoreContext();
  const debounceKeyword = useDebounce(filters.keyword, 275);
  const { needsReview, ...otherFilters } = filters;
  const debounceFilter = {
    ...otherFilters,
    keyword: debounceKeyword,
    ...(needsReview && {
      pendingReviewer: user?.id,
    }),
  };
  const enabled = Boolean(projectId);

  const query = useQuery({
    queryKey: submissionKeys.list(projectId, debounceFilter),
    queryFn: async ({ signal }) => {
      const params = {
        ...debounceFilter,
      };

      const result = await getSubmissionList(projectId, {
        params,
        signal,
      });

      return result;
    },
    enabled,
    keepPreviousData: true,
    select: (data: Pagination<SubmissionListModel[]>) => {
      const normalizeResults = data.result.map(
        (submission) =>
          new SubmissionListModel({
            ...submission,
            ...(!isNameRequired && {
              applicantFullName: submission.applicantEmail,
              applicantEmail: "",
            }),
          }),
      );
      const submissionPagination = new PaginationModel(data.pagination);

      return {
        submissionList: normalizeResults,
        submissionPagination,
      } as SubmissionList;
    },
  });

  return query;
}

function useGetSubmission<T>({
  filters = {},
  select,
  submissionId,
}: UseGetSubmissionParams<T>) {
  const { projectId, user = new UserModel() } = useStoreContext();
  const { examId, submissionId: routeSubmissionId } = useRouteParams();
  const {
    data: { examDetail },
  } = useExam();
  const localSubmissionId = submissionId ?? routeSubmissionId;
  const isAllowed =
    user.getAccessSubmissionType(
      projectId,
      SubmissionListKind.all,
      examDetail.reviewers,
    ) !== SubmissionAccessType.Deny;
  const enabled = Boolean(projectId && localSubmissionId) && isAllowed;
  const params = useMemo(
    () => ({
      ...filters,
      // This will be included by default
      includeOverlappingIps: true,
      includeCheatMonitoring: true,
    }),
    [filters],
  );

  const query = useQuery({
    // Skip display error toast and redirect to error page
    meta: { skipErrorToast: true } as QueryCacheMeta,
    queryKey: submissionKeys.detail({
      examId,
      projectId,
      submissionId: localSubmissionId,
      filters: params,
    }),
    queryFn: async ({ signal }) => {
      const { result } = await getSubmission({
        projectId,
        submissionId: localSubmissionId,
        options: {
          params,
          signal,
        },
      });

      return result;
    },
    enabled,
    initialData: new SubmissionModel({ applicantSegment: {} }),
    initialDataUpdatedAt: getLastWeekTimestamp(),
    keepPreviousData: true,
    select,
  });

  useEffect(() => {
    if (query.isError) {
      History.replace("/404");
    }
  }, [query.isError]);

  return query;
}

export function useSubmission(
  submissionId?: number,
  filters: SubmissionFilters = {},
) {
  return useGetSubmission({
    filters,
    submissionId,
    select: useCallback(
      (data: SubmissionModel) => new SubmissionModel(data),
      [],
    ),
  });
}

export function useSubmissionOverlappingIpMap() {
  return useGetSubmission({
    select: useCallback(
      (data: SubmissionModel) =>
        data.overlappingIpAddresses?.overlappingApplicantExams?.reduce(
          (acum, { overlappingAddresses }) => {
            overlappingAddresses.forEach((ip) => {
              acum[ip] = true;
            });
            return acum;
          },
          {} as Record<string, boolean>,
        ) ?? {},
      [],
    ),
  });
}

export function useSubmissionListBatch({
  filters,
  isEnabled = true,
}: {
  filters: SubmissionListFilter[];
  isEnabled?: boolean;
}) {
  const { projectId, user } = useStoreContext();
  const {
    data: {
      examDetail: { applicantNameRequired },
    },
  } = useExam();

  const enabled = Boolean(projectId) && isEnabled;

  const select = useCallback(
    (data: Pagination<SubmissionListModel[]>) => {
      const normalizeResults = data.result.map(
        (submission) =>
          new SubmissionListModel({
            ...submission,
            ...(!applicantNameRequired && {
              applicantFullName: submission.applicantEmail,
              applicantEmail: "",
            }),
          }),
      );

      return normalizeResults;
    },
    [applicantNameRequired],
  );

  const queries = useQueries({
    queries: filters.map((filter) => {
      const { needsReview, ...otherFilters } = filter;

      const debounceFilter = {
        ...otherFilters,
        ...(needsReview && {
          pendingReviewer: user?.id,
        }),
      };

      return {
        queryKey: submissionKeys.list(projectId, debounceFilter),
        queryFn: async ({ signal }: QueryFunctionContext) =>
          getSubmissionList(projectId, {
            params: debounceFilter,
            signal,
          }),
        enabled,
        keepPreviousData: true,
        select,
      };
    }),
  });

  const response = queries
    .map(({ data }) => data as SubmissionListModel[])
    .filter(Boolean)
    .flat();

  return response;
}

export function useCreateManualSubmitSubmission() {
  const { projectId } = useStoreContext();
  const { examId } = useRouteParams();
  const invalidateSubmissions = useInvalidateSubmissionRelation();
  const invalidateExamCount = useInvalidateExamCountRelation();

  const mutation = useMutation({
    mutationFn: (submissionId: number) =>
      postManualSubmitSubmission(projectId, submissionId),
    onSuccess: (_, submissionId) => {
      toast.success(
        Message.getMessageByKey("message.submission.manualSubmit.success"),
      );
      invalidateSubmissions(submissionId);
      invalidateExamCount(examId);
    },
    onError: () => {
      toast.error(
        Message.getMessageByKey("message.submission.manualSubmit.failed"),
      );
    },
  });

  return mutation;
}

export function useDeleteSubmission() {
  const { projectId } = useStoreContext();
  const { examId } = useRouteParams();
  const invalidateSubmission = useInvalidateSubmissionRelation();
  const invalidateExamCount = useInvalidateExamCountRelation();

  const mutation = useMutation({
    mutationFn: ({ submissionId, memo }: DeleteSubmissionParams) =>
      deleteSubmission({
        projectId,
        submissionId,
        options: {
          data: { memo },
        },
      }),
    onSuccess: (_, { submissionId }) => {
      toast.success(Message.getMessageByKey("message.submission.canceled"));
      invalidateSubmission(submissionId);
      invalidateExamCount(examId);
    },
  });

  return mutation;
}

export function useCancelPendingSubmissionList() {
  const client = useQueryClient();
  const { projectId } = useStoreContext();
  const invalidateSubmission = useInvalidateSubmissionRelation();

  const mutation = useMutation({
    mutationFn: ({ examId, memo }: CancelPendingSubmissionParams) =>
      deletePendingSubmissionList({
        examId,
        projectId,
        options: { data: { memo } },
      }),
    onSuccess: (_, { examId }) => {
      toast.success(
        Message.getMessageByKey("message.exam.allCanceled.success"),
      );
      client.invalidateQueries(examKeys.count(projectId, examId));
      invalidateSubmission();
    },
    onError: () => {
      toast.error(Message.getMessageByKey("message.exam.allCanceled.failure"));
    },
  });

  return mutation;
}

export function useInvalidateSubmissionRelation() {
  const client = useQueryClient();
  const { projectId } = useStoreContext();
  const { examId } = useRouteParams();
  // get all active queries
  const queryCaches = client.getQueryCache().getAll();
  const nestSubmissionQueryList = queryCaches.filter(
    ({ queryKey }) =>
      queryKey[0] === "submissions" && typeof queryKey[1] === "string",
  );
  const examSubmissionIdList = queryCaches.filter(
    ({ queryKey }) =>
      queryKey[0] === "submissions" &&
      queryKey[1] === projectId &&
      queryKey[2] === examId,
  );

  return (targetId?: number) => {
    const idsToInvalidate = targetId ? [targetId] : examSubmissionIdList;
    client.invalidateQueries(submissionKeys.listExam(projectId, examId));

    nestSubmissionQueryList.forEach(({ queryKey }) => {
      const [, , , querySubmissionId] = queryKey;

      idsToInvalidate.forEach((id) => {
        if (querySubmissionId === id) {
          client.invalidateQueries(queryKey);
        }
      });
    });
  };
}
