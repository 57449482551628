import actionCreator from "../shared/services/actionCreator";

// Action Creator Actions
export const orgsUserUpdateAction = actionCreator("orgsUserUpdateAction");
export const orgsUserListGetAction = actionCreator("orgsUserListGetAction");
export const orgsUserInviteAction = actionCreator("orgsUserInviteAction");
export const orgsUserDeactivateAction = actionCreator(
  "orgsUserDeactivateAction",
);
export const orgsResetListAllAction = actionCreator("orgsResetListAllAction");
export const orgsGetListAllAction = actionCreator("orgsGetListAllAction");
export const orgsDeactivateAction = actionCreator("orgsDeactivateAction");
export const orgsReactivateAction = actionCreator("orgsReactivateAction");
export const orgsUpdateSecurityAction = actionCreator(
  "orgsUpdateSecurityAction",
);
export const orgsGetAction = actionCreator("orgsGetAction");
export const orgsGetIPAction = actionCreator("orgsGetIPAction");

export const orgAllContractsGetAction = actionCreator(
  "orgAllContractsGetAction",
);

export const orgContractGetAction = actionCreator("orgContractGetAction");

export const orgAuditLogGetAction = actionCreator("orgAuditLogGetAction");

export const orgsCreateAction = actionCreator("orgsCreateAction");
export const orgsDeleteAction = actionCreator("orgsDeleteAction");
export const orgsUpdateAction = actionCreator("orgsUpdateAction");

export const orgsUserSetAction = actionCreator("orgsUserSetAction");

export const orgsUserInviteResendAction = actionCreator(
  "orgsUserInviteResendAction",
);
export const orgsUserInviteUpdateAction = actionCreator(
  "orgsUserInviteUpdateAction",
);
export const orgsUserInviteDeleteAction = actionCreator(
  "orgsUserInviteDeleteAction",
);
export const orgsUserInviteListAction = actionCreator(
  "orgsUserInviteListAction",
);
export const orgsUserReactivateAction = actionCreator(
  "orgsUserReactivateAction",
);

export const orgsGitListGetAction = actionCreator("orgsGitListGetAction");
export const orgsGitListResetAction = actionCreator("orgsGitListResetAction");
export const orgsGitUpdateAction = actionCreator("orgsGitUpdateAction");
export const orgsGitDeleteAction = actionCreator("orgsGitDeleteAction");

export const orgsGetListAction = actionCreator("orgsGetListAction");

export const orgsErrorAction = actionCreator("orgsErrorAction");

export const allTiersGetAction = actionCreator("allTiersGetAction");
export const allActionsGetAction = actionCreator("allActionsGetAction");
export const allCsvTypesGetAction = actionCreator("allCsvTypesGetAction");

export const orgsCsvColumnsGetAction = actionCreator("orgsCsvColumnsGetAction");
export const invitationStatusGetAction = actionCreator(
  "invitationStatusGetAction",
);

export const examSimpleListGetAction = actionCreator("examSimpleListGetAction");
