import * as React from "react";

import { Msg, Tooltip } from "@shared/components";

export function PossiblyNoDataWithTooltip({
  value,
}: {
  value?: string | number;
}) {
  const tooltipReferenceElement = React.useRef<HTMLDivElement | null>(null);

  const shouldShowValue = value !== undefined && value !== "";

  if (shouldShowValue) {
    return <>{value}</>;
  }

  return (
    <Tooltip
      text={<Msg id="common.notEnoughData" />}
      wrapperClassName="code-c-question__no-data-tooltip"
      overrideRef={tooltipReferenceElement}
    >
      <div ref={tooltipReferenceElement}>-</div>
    </Tooltip>
  );
}
