import classNames from "classnames";
import * as React from "react";

interface TimelineItemProps {
  children: React.ReactNode;
  className?: string;
  color?: "primary" | "none";
}

function TimelineItem({
  children,
  className,
  color = "primary",
}: TimelineItemProps) {
  const rootStyle = classNames(
    "code-c-timeline-item",
    className,
    `code-c-timeline-item__${color}`,
  );

  return <li className={rootStyle}>{children}</li>;
}

export default TimelineItem;
