import { Column, Msg, Row } from "@shared/components";
import { QuestionInsightModel } from "@shared/models";
import { formatTimeElapsedStringFromMillis } from "@shared/services/date";

import { PossiblyNoDataWithTooltip } from "./PossiblyNoDataWithTooltip";

export const InsightOverviewRow = ({
  rowNameKey,
  insight,
  shouldShowOrganizationCount = true,
}: {
  rowNameKey: string;
  insight?: QuestionInsightModel;
  shouldShowOrganizationCount?: boolean;
}) => {
  const {
    totalCount,
    timeAverageMillis,
    timeStandardDeviationMillis,
    organizationCount,
  } = insight?.overall || {};

  return (
    <Row>
      <Column className="row-header">
        <Msg id={rowNameKey} />
      </Column>
      <Column className="align-right">
        {shouldShowOrganizationCount && organizationCount
          ? organizationCount
          : "-"}
      </Column>
      <Column className="align-right">{totalCount ?? "-"}</Column>
      <Column className="align-right">
        <PossiblyNoDataWithTooltip
          value={formatTimeElapsedStringFromMillis(timeAverageMillis)}
        />
      </Column>
      <Column className="align-right">
        <PossiblyNoDataWithTooltip
          value={formatTimeElapsedStringFromMillis(timeStandardDeviationMillis)}
        />
      </Column>
    </Row>
  );
};
