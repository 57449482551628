import { useQuery } from "@tanstack/react-query";
import { useCallback } from "react";

import { getSubmissionFormValues } from "@api/submissions";

import { useStoreContext, useSubmissionAccessTypeContext } from "@context";

import { CustomFormModel, SubmissionAccessType } from "@shared/models";

import { useRouteParams } from "../useRouteParams";

interface SubmissionFormValueAllKeyParams {
  projectId: number;
  submissionId: number;
}

export const submissionFormValueKeys = {
  all: ["submissions", "formValues"] as const,
  values: ({ projectId, submissionId }: SubmissionFormValueAllKeyParams) =>
    [...submissionFormValueKeys.all, projectId, submissionId] as const,
};

export function useGetSubmissionsFormValues() {
  const { projectId } = useStoreContext();
  const { submissionId } = useRouteParams();
  const isFullAccess =
    useSubmissionAccessTypeContext() === SubmissionAccessType.Full;
  const enabled = Boolean(submissionId) && isFullAccess;
  const query = useQuery<CustomFormModel[]>({
    queryKey: submissionFormValueKeys.values({ projectId, submissionId }),
    queryFn: async ({ signal }) => {
      const { result } = await getSubmissionFormValues(
        projectId,
        submissionId,
        {
          signal,
        },
      );

      return result;
    },
    enabled,
    keepPreviousData: true,
    select: useCallback(
      (data: CustomFormModel[]) =>
        data.map((formField) => new CustomFormModel(formField)),
      [],
    ),
  });

  return query;
}
