import * as classnames from "classnames";

import { Tag } from "../..";
import Message from "../../../services/message";

/**
 * Prop interface
 */
export interface VersionCodeTagProps {
  version?: string;
  onClick?: () => void;
  className?: string;
}

/**
 * React Component
 */
export function VersionCodeTag({
  version = "",
  onClick,
  className,
}: VersionCodeTagProps) {
  const rootStyle = classnames("code-c-version-code-tag tag is-rounded", {
    [`${className}`]: Boolean(className),
  });

  return (
    <Tag className={rootStyle} onClick={onClick}>
      {version.match(/^\d+\.\d+$/) !== null
        ? `v${version}`
        : Message.getMessageByKey("challengeVersion.noVersionAvailable")}
    </Tag>
  );
}
