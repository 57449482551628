import * as classnames from "classnames";

import { Tooltip, Icon } from "..";
import Message from "../../services/message";

/**
 * Prop interface
 */
export interface TogglePinProps {
  pinned?: boolean;
  onClick?: () => void;
  className?: string;
}

/**
 * React Component
 */
export default function TogglePin({
  className,
  pinned,
  onClick,
}: TogglePinProps) {
  const rootStyle = classnames("code-c-toggle-pin", {
    [`${className}`]: Boolean(className),
  });

  return (
    <>
      <Tooltip
        text={Message.getMessageByKey(
          pinned ? "action.remove.pin" : "action.add.pin",
        )}
      >
        <span
          role="checkbox"
          aria-checked={pinned}
          aria-label="Toggle Pin"
          onClick={onClick}
          className={rootStyle}
        >
          <Icon type="thumb-tack" />
        </span>
      </Tooltip>
    </>
  );
}
