import * as classnames from "classnames";
import * as React from "react";

import { Msg } from "../..";

/**
 * Prop interface
 */
export type SubmissionResultHeaderProps = {
  className?: string;
};

/**
 * React Component
 */
export const SubmissionResultHeader: React.FunctionComponent<
  SubmissionResultHeaderProps
> = (props: SubmissionResultHeaderProps) => {
  const { className } = props;

  /**
   * State
   */

  /**
   * Effects
   */

  /**
   * Private Functions
   */
  const rootStyle = classnames("code-c-submission-result-header", {
    [`${className}`]: Boolean(className),
  });

  return (
    <div className={rootStyle}>
      <div>
        <Msg id="common.title" />
      </div>
      <div>
        <Msg id={"common.score"} />
      </div>
      <div className="align-right is-nowrap">
        <Msg id={"timeSpent"} />
      </div>
      <div />
    </div>
  );
};
