import { Placement } from "@popperjs/core";
import * as React from "react";
import { usePopper, Modifier } from "react-popper";

interface UseHoverBoxProps {
  targetElement?: HTMLDivElement | null;
  offset: [number, number];
  minWidth?: number;
  placement?: Placement;
}

export const useHoverBox = ({
  targetElement,
  offset,
  placement = "bottom-start",
  minWidth,
}: UseHoverBoxProps) => {
  const [offsetX, offsetY] = offset;

  const customModifier = React.useMemo<
    Modifier<"offset" | "minWidth" | "flip">[]
  >(() => {
    return [
      {
        name: "offset",
        options: {
          offset: [offsetX, offsetY],
        },
      },
      {
        name: "flip",
        options: {
          fallbackPlacements: ["top", "bottom", "right"],
        },
      },
      {
        name: "minWidth",
        enabled: true,
        phase: "beforeWrite",
        requires: ["computeStyles"],
        fn: ({ state }) => {
          state.styles.popper.minWidth = `${
            minWidth ?? state.rects.reference.width
          }px`;
        },
        effect: ({ state }) => {
          state.elements.popper.style.minWidth = `${
            minWidth ?? (state.elements.reference as HTMLElement).offsetWidth
          }px`;
        },
      },
    ];
  }, [offsetX, offsetY, minWidth]);

  const [referenceElement, setReferenceElement] =
    React.useState<HTMLDivElement | null>(null);

  const [popperElement, setPopperElement] =
    React.useState<HTMLDivElement | null>(null);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement,
    modifiers: [...customModifier],
  });

  /**
   * Effect
   */
  React.useEffect(() => {
    if (targetElement) {
      setReferenceElement(targetElement);
    }
  }, [targetElement, setReferenceElement]);

  return {
    styles,
    attributes,
    popperElement,
    setPopperElement,
  };
};
