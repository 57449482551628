import * as classnames from "classnames";
import * as React from "react";

import MSG from "@shared/services/message";

import { Tag } from "..";

/**
 * Prop interface
 */
export interface CliSupportBadgeProps {
  className?: string;
  hasError?: boolean;
  isExam?: boolean;
  customErrorMessage?: React.ReactNode;
  warningMessage?: React.ReactNode;
}

/**
 * React Component
 */
export default function CliSupportBadge({
  className,
  hasError,
  isExam,
  customErrorMessage,
  warningMessage,
}: CliSupportBadgeProps) {
  const rootStyle = classnames("code-c-cli-support-badge", {
    [`${className}`]: Boolean(className),
  });

  return (
    <Tag
      className={rootStyle}
      hasError={hasError}
      errorMessage={customErrorMessage}
      hasWarning={Boolean(warningMessage)}
      warningMessage={warningMessage}
    >
      {MSG.getMessageByKey(
        isExam ? "challenge.cli.exam" : "challenge.cli.support",
      )}
    </Tag>
  );
}
