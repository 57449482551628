import { useQueries, useQuery } from "@tanstack/react-query";

import { BaseSearchQuery } from "@api/httpClient";
import { getReportExamChallengeStatistics } from "@api/reports";

import { useStoreContext } from "@context";

import { useArrayMemo } from "@shared/hooks/useArrayMemo";
import { ChallengeStatisticModel } from "@shared/models";

const examChallengeStatisticKeys = {
  all: ["REPORT_EXAM_CHALLENGE_STATISTICS"] as const,
  statistics: ({
    projectId,
    examId,
    challengeId,
    limit,
    offset,
    sortOrder,
  }: ExamChallengeStatisticsParams & { projectId: number }) =>
    [
      ...examChallengeStatisticKeys.all,
      "EXAM",
      projectId,
      examId,
      challengeId,
      limit,
      offset,
      sortOrder,
    ] as const,
};

export interface ExamChallengeStatisticsParams
  extends Omit<BaseSearchQuery, "keyword"> {
  examId: number;
  challengeId: number;
}

export function useGetExamChallengeStatistics({
  examId,
  challengeId,
  ...params
}: ExamChallengeStatisticsParams) {
  const { projectId } = useStoreContext();

  const query = useQuery<ChallengeStatisticModel>({
    queryKey: examChallengeStatisticKeys.statistics({
      projectId,
      examId,
      challengeId,
      ...params,
    }),
    enabled: true,
    queryFn: async ({ signal }) => {
      const { result } = await getReportExamChallengeStatistics({
        projectId,
        examId,
        challengeId,
        options: {
          signal,
          params,
        },
      });

      return result;
    },
    keepPreviousData: true,
  });

  return query;
}

export function useGetExamChallengeStatisticsBatch(
  paramsArr: ExamChallengeStatisticsParams[],
) {
  const { projectId } = useStoreContext();

  const queries = useQueries<ChallengeStatisticModel[]>({
    queries: paramsArr.map(({ examId, challengeId, ...params }) => ({
      queryKey: examChallengeStatisticKeys.statistics({
        projectId,
        examId,
        challengeId,
        ...params,
      }),
      queryFn: async ({ signal }): Promise<ChallengeStatisticModel> => {
        const { result } = await getReportExamChallengeStatistics({
          projectId,
          examId,
          challengeId,
          options: {
            signal,
            params,
          },
        });

        return result;
      },
      enabled: true,
    })),
  });

  const data = queries
    .map((query) => query.data as ChallengeStatisticModel)
    .filter(Boolean);

  return useArrayMemo<ChallengeStatisticModel[]>(data);
}
