import { Epic } from "redux-observable";

import { Action, ajaxAction, examOptionsGetAction } from "@actions";

import { RootState } from "@reducers";

const examOptionsGetEpic: Epic<Action, RootState> = (action$, state) =>
  action$
    .ofType(examOptionsGetAction.types.request)
    .filter((action) => {
      const force = action.payload
        ? Boolean((action.payload as { force?: boolean }).force)
        : false;
      // Force update or roleoptions is empty
      return (
        force || !state.getState().exam.examCreateOptions.roleoptions.length
      );
    })
    .flatMap(() => [
      ajaxAction.request({
        method: "get",
        url: "/api/enum/exams/roles",
        success: (payload: {}) =>
          examOptionsGetAction.success({ extra: "roleoptions", ...payload }),
      }),
    ]);

export default [examOptionsGetEpic];
