import {
  QueryFunctionContext,
  useQueries,
  useQuery,
} from "@tanstack/react-query";

import { getSubmissionPreviewContent } from "@api/submissions";

import { useStoreContext } from "@context";

import { KeyEventModel, PreviewContentModel } from "@shared/models";
import { getLastWeekTimestamp } from "@shared/services/date";
import { getSaveHistoryIds } from "@shared/services/keyEvents";

import { useRouteParams } from "../useRouteParams";
import { useGetSubmissionsResultDetail } from "./useSubmissionResults";

interface SubmissionPreviewContentAllKeyParams {
  challengeResultId?: number;
  projectId: number;
  submissionId?: number;
}

interface SubmissionPreviewContentKeyParams
  extends Required<SubmissionPreviewContentAllKeyParams> {
  historyId: number;
}

interface FetchSubmissionPreviewContentParams {
  challengeResultId: number;
  examId: number;
  historyId: number;
  projectId: number;
  submissionId: number;
}

interface UseGetSubmissionsPreviewContentBatchParams {
  challengeId: number;
  challengeResultId: number;
  currentKeyEventLog?: KeyEventModel;
  eventLogId: number;
}

export const submissionPreviewContentKeys = {
  all: ["submissions", "previewContent"] as const,
  contentAll: ({
    challengeResultId,
    projectId,
    submissionId,
  }: SubmissionPreviewContentAllKeyParams) => {
    if (submissionId && challengeResultId) {
      return [
        ...submissionPreviewContentKeys.all,
        projectId,
        submissionId,
        challengeResultId,
      ] as const;
    }
    if (submissionId) {
      return [
        ...submissionPreviewContentKeys.all,
        projectId,
        submissionId,
      ] as const;
    }

    return [...submissionPreviewContentKeys.all, projectId] as const;
  },
  content: ({ historyId, ...rest }: SubmissionPreviewContentKeyParams) =>
    [...submissionPreviewContentKeys.contentAll(rest), historyId] as const,
};

const fetchSubmissionPreviewContent =
  ({
    challengeResultId,
    examId,
    historyId,
    projectId,
    submissionId,
  }: FetchSubmissionPreviewContentParams) =>
  async ({ signal }: QueryFunctionContext) => {
    const { result } = await getSubmissionPreviewContent({
      challengeResultId,
      examId,
      projectId,
      submissionId,
      options: {
        params: { historyId },
        signal,
      },
    });

    return result;
  };

export function useGetSubmissionsPreviewContent(
  challengeResultId: number,
  historyId: number,
) {
  const { projectId } = useStoreContext();
  const { examId, submissionId } = useRouteParams();
  const enabled = Boolean(
    challengeResultId && examId && submissionId && historyId,
  );
  const query = useQuery<PreviewContentModel>({
    queryKey: submissionPreviewContentKeys.content({
      challengeResultId,
      historyId,
      projectId,
      submissionId,
    }),
    queryFn: fetchSubmissionPreviewContent({
      challengeResultId,
      examId,
      historyId,
      projectId,
      submissionId,
    }),
    enabled,
    keepPreviousData: true,
  });

  return query;
}

export function useGetSubmissionsPreviewContentBatch({
  challengeId,
  challengeResultId,
  currentKeyEventLog,
  eventLogId,
}: UseGetSubmissionsPreviewContentBatchParams) {
  const { actionLogs, projectId } = useStoreContext();
  const { examId, submissionId } = useRouteParams();
  const {
    data: { codingResult },
  } = useGetSubmissionsResultDetail(challengeId);
  const keyEventLog = codingResult.keyEventLogs?.find(
    (item) => item.id === eventLogId,
  );
  const { loadIndices = {} } = keyEventLog?.metadata || {};
  const batch = Object.values<number>(loadIndices);

  if (currentKeyEventLog) {
    const actionLogSaveHistoryIds = getSaveHistoryIds(
      actionLogs,
      currentKeyEventLog,
    ).filter(Boolean) as number[];

    batch.push(...actionLogSaveHistoryIds);
  }
  const enabled = Boolean(
    challengeResultId &&
      submissionId &&
      codingResult?.keyEventLogs &&
      keyEventLog &&
      currentKeyEventLog,
  );
  const queries = useQueries<PreviewContentModel[]>({
    queries: batch.map((historyId) => ({
      queryKey: submissionPreviewContentKeys.content({
        challengeResultId,
        historyId,
        projectId,
        submissionId,
      }),
      queryFn: fetchSubmissionPreviewContent({
        challengeResultId,
        examId,
        historyId,
        projectId,
        submissionId,
      }),
      enabled,
      initialData: [] as PreviewContentModel[],
      initialDataUpdatedAt: getLastWeekTimestamp(),
    })),
  });

  return queries.filter(Boolean).map(({ data }) => data as PreviewContentModel);
}
