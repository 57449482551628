import * as classnames from "classnames";
import * as React from "react";

import { Icon, Msg, ErrorIcon, WarningIcon } from "../..";

/**
 * Prop interface
 */
export interface TagProps {
  children: string | React.ReactNode;
  "aria-label"?: string;
  className?: string;
  labelClassName?: string;
  color?:
    | "green"
    | "red"
    | "neutral"
    | "neutral-light"
    | "yellow"
    | "yellow-light"
    | "gray"
    | "gray-lightest"
    | "gray-darkest"
    | "pink";
  darkFont?: boolean;
  hasDelete?: boolean;
  hasError?: boolean;
  hasWarning?: boolean;
  errorMessage?: React.ReactNode;
  opacity?: string;
  warningMessage?: React.ReactNode;
  onClick?: () => void;
  startIcon?: React.ReactNode;
  onDelete?: (e: React.SyntheticEvent<EventTarget>) => void;
}

export interface TagItem
  extends Pick<TagProps, "darkFont" | "hasError" | "opacity" | "onClick"> {
  colorOverride?: TagProps["color"];
  label: string;
}

/**
 * React Component
 */
export function Tag({
  children,
  "aria-label": ariaLabel,
  labelClassName,
  className,
  color,
  darkFont = false,
  hasDelete = false,
  hasError = false,
  hasWarning = false,
  errorMessage = <Msg id="tier.disabled.notSupported" />,
  opacity,
  warningMessage,
  onClick,
  startIcon,
  onDelete,
}: TagProps) {
  const enableOnClick = typeof onClick === "function";

  const rootStyle = classnames("code-c-tag tag", {
    "has-delete": hasDelete,
    "code-c-tag__cursor-pointer": enableOnClick,
    [`${className}`]: Boolean(className),
    [`color-${color}`]: Boolean(color),
    [`dark-font`]: Boolean(darkFont),
    [`opacity-${opacity}`]: Boolean(opacity),
  });

  const labelStyles = classnames("code-c-tag__label", {
    [`${labelClassName}`]: Boolean(labelClassName),
  });

  function handleClick() {
    if (typeof onClick === "function") {
      onClick();
    }
  }

  return (
    <span
      className={rootStyle}
      onClick={handleClick}
      role={enableOnClick ? "button" : undefined}
      aria-label={ariaLabel}
    >
      {startIcon}
      <span className={labelStyles}>{children}</span>
      {hasError && <ErrorIcon tooltipText={errorMessage} />}
      {hasWarning && <WarningIcon tooltipText={warningMessage} />}
      {hasDelete && (
        <Icon
          className="code-c-tag__delete-icon"
          type="times"
          onClick={onDelete}
        />
      )}
    </span>
  );
}
