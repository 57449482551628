import {
  Action,
  GlobalTypeSet,
  globalTypeSetAction,
  globalAppTypeSetAction,
} from "../actions";
import { OrganizationAppType, GlobalAppType } from "../shared/services/enums";

export interface GlobalState {
  type: OrganizationAppType;
  canRender: boolean;
  globalAppType?: GlobalAppType;
}

const initialState: GlobalState = {
  type: OrganizationAppType.Admin,
  canRender: false,
};

export const globalReducer = (
  state: GlobalState = initialState,
  action: Action,
): GlobalState => {
  switch (action.type) {
    case globalTypeSetAction.types.request:
      const globalTypeSetPayload = action.payload as GlobalTypeSet;
      return {
        ...state,
        type: globalTypeSetPayload.appType,
        canRender: true,
      };

    case globalAppTypeSetAction.types.request:
      return {
        ...state,
        globalAppType: action.payload as GlobalAppType,
      };

    default:
      return state;
  }
};
