class ApplicantReportModel {
  public applicantId: number;
  public applicantExamId: number;
  public applicantEmail: string;
  public applicantName: string;
  public deliveredAt: string;
  public submittedAt: string;
  public status: number;

  public finalScore: number;
  public finalScoreDevValue: number;
  public grade = "";
  public rank: number;
  public submissionsNumber: number;

  public submissionsAvgScore = 0;
  public submissionsMaxScore = 0;
  public submissionsStdDev = 0;

  public organizationName: string;
  public examName: string;

  constructor(args?: {}) {
    Object.assign(this, args);
  }
}

export default ApplicantReportModel;
