import { connect } from "react-redux";
import { Action, Dispatch } from "redux";

import {
  challengeReleaseNoteGetAction,
  challengeInsightGetAction,
  challengeGetAction,
  questionExamLevelInsightsGetAction,
} from "@actions";

import { RootState } from "@reducers";

import { getAppBasePath } from "@shared/selectors";
import { getTierAllowedByRedux } from "@shared/services/tier";

import { ChallengeDetail, ExternalProps } from "./ChallengeDetail";

const mapStateToProps = (state: RootState, props: ExternalProps) => {
  const {
    copyChallengeLoading,
    loadingCreate,
    errorCreate,
    challengeDetailList,
    challengeReleaseNote,
    challengeFilters,
    challengeInsightDetail,
    loadingDetail,
    errorDetail,
    loadingInsight,
    loadingReleaseNote,
  } = state.challenge;

  return {
    appBasePath: getAppBasePath(state),
    detailChallenge: challengeDetailList.find(
      (item) => item.id === props.challengeId,
    ),
    releaseNote: challengeReleaseNote,
    readme: state.readme.readmeList.find(
      (item) => item.challengeId === props.challengeId,
    ),
    challengeInsight: challengeInsightDetail,
    programmingCategories: challengeFilters.programmingCategories,
    programmingLanguages: challengeFilters.programmingLanguages,
    challengeUpdateError: errorCreate,
    challengeUpdating: loadingCreate,
    loadingDetail,
    loadingInsight,
    loadingPrompts: state.readme.loadingReadme,
    loadingReleases: loadingReleaseNote,
    loadingChallenge: loadingCreate || copyChallengeLoading,
    errorDetail,
    org: state.user.user?.organization ?? undefined,
    isTierActionAllowed: getTierAllowedByRedux(state),
    questionDetailList: state.question.questionDetailList,
    ...props,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  getChallenge: (challengeId: number, challengeVersionId?: number) => {
    dispatch(
      challengeGetAction.request(
        challengeId,
        challengeVersionId !== undefined
          ? {
              challengeVersionId,
            }
          : undefined,
      ),
    );
  },
  getReleases: (challengeId: number) =>
    dispatch(challengeReleaseNoteGetAction.request(undefined, { challengeId })),
  getChallengeInsight: (challengeInsightId: number) => {
    dispatch(challengeInsightGetAction.request(challengeInsightId));
  },
  getExamLevelInsights: (examId: number, questionId: number) => {
    dispatch(
      questionExamLevelInsightsGetAction.request({}, { examId, questionId }),
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ChallengeDetail);
