import { connect } from "react-redux";

import { RootState } from "../../../reducers";
import QuestionTitleColumn from "./QuestionTitleColumn";

const mapStateToProps = (state: RootState) => ({
  quizCategories: state.question.questionFilters.quizCategories,
});

const wrappedComponent = connect(mapStateToProps, {})(QuestionTitleColumn);

export default wrappedComponent;
