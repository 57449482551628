import * as React from "react";

import PingReviewer from "@components/orgs/submissions/pingReviewer/PingReviewer";

import { useStoreContext } from "@context";

import {
  Accordion,
  Icon,
  Msg,
  Review,
  ReviewStatusTrackerBar,
  ReviewSummary,
} from "@shared/components";
import {
  useGetSubmissionsReviewers,
  useGuestSubmissionReviews,
  usePingReviewer,
} from "@shared/hooks/query";
import { ReviewModel, ReviewerUserModel } from "@shared/models";
import { TierAction } from "@shared/services/enums";
import * as ScoreService from "@shared/services/score";

import { getCurrentUserReview } from "../SubmissionDetailUtils";

interface SubmissionDetailReviewSectionProps {
  showOnlyCurrentReviewer: boolean;
  isEvaluated: boolean;
  shouldShowEditReviewButton: (review: ReviewModel) => boolean;
  onReviewEditClick?: () => void;
}

export const SubmissionDetailReviewSectionGuest = (
  props: SubmissionDetailReviewSectionProps,
) => {
  const { data: reviewList } = useGuestSubmissionReviews();

  if (!reviewList || !reviewList.length) {
    return null;
  }

  return (
    <SubmissionDetailReviewSectionComponent
      {...props}
      reviews={reviewList ?? []}
      pendingReviewers={[]}
    />
  );
};

export const SubmissionDetailReviewSection = (
  props: SubmissionDetailReviewSectionProps,
) => {
  const {
    isTierActionAllowed,
    projectId: currentProjectId,
    user: currentUser,
  } = useStoreContext();

  const {
    data: { pendingReviewers, reviews },
  } = useGetSubmissionsReviewers();
  // const reviewedComment = ;
  return (
    <SubmissionDetailReviewSectionComponent
      {...props}
      canReview={isTierActionAllowed(TierAction.ReviewFunctionality)}
      isCurrentUserProjectAdmin={currentUser?.hasProjectAdmin(currentProjectId)}
      reviews={reviews}
      pendingReviewers={pendingReviewers}
      reviewedComment={
        currentUser ? getCurrentUserReview(reviews, currentUser) : undefined
      }
      currentUserId={currentUser?.id}
    />
  );
};

interface ExtendedProps {
  canReview?: boolean;
  currentUserId?: number;
  isCurrentUserProjectAdmin?: boolean;
  pendingReviewers: ReviewerUserModel[];
  reviews: ReviewModel[];
  reviewedComment?: ReviewModel;
}

const SubmissionDetailReviewSectionComponent = ({
  showOnlyCurrentReviewer,
  isEvaluated,
  shouldShowEditReviewButton,
  onReviewEditClick,
  canReview,
  isCurrentUserProjectAdmin,
  currentUserId,
  pendingReviewers,
  reviews,
  reviewedComment,
}: SubmissionDetailReviewSectionProps & ExtendedProps) => {
  const [pingedIds, setPingedIds] = React.useState<number[]>([]);
  const pingReviewer = usePingReviewer();
  const [pingModalState, setPingModalState] = React.useState<{
    isOpen?: boolean;
    reviewer?: ReviewerUserModel;
  }>({});

  return (
    <>
      {!showOnlyCurrentReviewer && (
        <Accordion
          className="code-detail-review-section"
          summaryClassName="code-detail-review-section__summary with-hover"
          detailClassName="code-detail-review-section__reviews"
          customSummary={(open) => (
            <div>
              <div className="code-detail-review-section__header">
                <div className="code-detail-review-section__header__title">
                  <Msg id="reviews" tagName="h3" />
                  <ReviewSummary
                    reviews={reviews}
                    pendingCount={pendingReviewers.length}
                    hideExistingReviews
                    displayNotReviewed
                  />
                </div>
                <Icon
                  type={open ? "angle-up" : "angle-down"}
                  className="code-submission-detail__toggle-angle"
                />
              </div>
              {Boolean(reviews.length) && (
                <div>
                  <ReviewStatusTrackerBar reviews={reviews} />
                </div>
              )}
            </div>
          )}
        >
          {ScoreService.sortReviews(reviews).map((review) => (
            <Review
              key={review.id}
              reviewer={review.reviewer}
              canReview={canReview}
              isStale={review.isStale}
              score={review.score}
              feedback={review.feedback}
              reviewedAt={review.updatedAt}
              isPingable={
                !isEvaluated &&
                isCurrentUserProjectAdmin &&
                currentUserId !== review.reviewer.id &&
                review.isStale
              }
              pingDisabled={pingedIds.includes(review.reviewer.id)}
              onClickPing={() =>
                setPingModalState({
                  isOpen: true,
                  reviewer: review.reviewer,
                })
              }
              isEditable={shouldShowEditReviewButton(review)}
              onClickEdit={onReviewEditClick}
            />
          ))}
          {pendingReviewers.map((reviewer) => (
            <Review
              key={reviewer.id}
              reviewer={reviewer}
              canReview={canReview}
              isPingable={
                !isEvaluated &&
                isCurrentUserProjectAdmin &&
                currentUserId !== reviewer.id
              }
              pingDisabled={
                Boolean(reviewer.invitationStatus) ||
                pingedIds.includes(reviewer.id)
              }
              onClickPing={() =>
                setPingModalState({
                  isOpen: true,
                  reviewer: reviewer,
                })
              }
            />
          ))}
        </Accordion>
      )}
      {showOnlyCurrentReviewer && reviewedComment && (
        <div className="code-detail-review-section">
          <div className="code-detail-review-section__summary">
            <div className="code-detail-review-section__header">
              <div className="code-detail-review-section__header__title">
                <Msg id="reviews" tagName="h3" />
              </div>
            </div>
            <div>
              <Review
                className="code-detail-review-section__solo-review"
                key={reviewedComment.id}
                reviewer={reviewedComment.reviewer}
                canReview={canReview}
                isStale={reviewedComment.isStale}
                score={reviewedComment.score}
                feedback={reviewedComment.feedback}
                reviewedAt={reviewedComment.updatedAt}
                isPingable={false}
                isEditable={!isEvaluated}
                onClickEdit={onReviewEditClick}
              />
            </div>
          </div>
        </div>
      )}
      {pingModalState.isOpen && pingModalState.reviewer && (
        <PingReviewer
          isOpen={true}
          reviewer={pingModalState.reviewer}
          onOK={() => {
            const { reviewer } = pingModalState;

            if (reviewer) {
              pingReviewer.mutate({ reviewerId: reviewer.id });
              setPingedIds(Array.from(new Set([...pingedIds, reviewer.id])));
              setPingModalState({});
            }
          }}
          onClose={() => setPingModalState({})}
        />
      )}
    </>
  );
};
