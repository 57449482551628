import { useIsMutating } from "@tanstack/react-query";

import { challengeQuestionKeys, useDeleteChallenge } from "@shared/hooks/query";
import { ChallengeFilterModel, ChallengeModel } from "@shared/models";
import { ChallengeStatus } from "@shared/services/enums";

import { ChallengeCopyModal } from "../challengeCopyModal/ChallengeCopyModal";
import { ChallengeDeleteModal } from "../challengeDeleteModal/ChallengeDeleteModal";
import { ChallengeType } from "../challengeNew/ChallengeNew";
import ChallengeNew from "../challengeNew/ChallengeNew.connect";

export type ExternalProps = {
  isGivery: boolean;
  challenge: ChallengeModel;
  isEditOpen: boolean;
  isDeleteOpen: boolean;
  isCopyOpen: boolean;
  onCloseModal: () => void;
  onDeleteChallenge?: () => void;
  updateChallengeQuestions: (id: number, values: number[]) => void;
};

export type InjectedProps = {
  challengeDetailList: ChallengeModel[];
  challengeFilters: ChallengeFilterModel;
  copyChallenge: (challenge: ChallengeModel) => void;
  loading: boolean;
  getChallenge: (payload: {}) => void;
  updateChallenge: (id: number, params: {}) => void;
};

type ChallengeActionModalsProps = ExternalProps & InjectedProps;

export const ChallengeActionModals = ({
  challenge,
  challengeDetailList,
  challengeFilters,
  loading,
  isEditOpen,
  isDeleteOpen,
  isCopyOpen,
  onCloseModal,
  copyChallenge,
  updateChallenge,
  updateChallengeQuestions,
  getChallenge,
  onDeleteChallenge,
}: ChallengeActionModalsProps) => {
  const isUpdateChallengeQuestions = useIsMutating(
    challengeQuestionKeys.update(),
  );
  const deleteChallenge = useDeleteChallenge();

  return (
    <>
      {isEditOpen && (
        <ChallengeNew
          challenge={Object.assign(
            {},
            challenge,
            challengeDetailList.find((item) => item.id === challenge.id),
          )}
          challengeFilters={challengeFilters}
          challengeType={ChallengeType.Edit}
          isOpen={isEditOpen}
          loadingDetail={loading || Boolean(isUpdateChallengeQuestions)}
          onCancel={onCloseModal}
          onSave={(
            formValues?: {},
            questions?: { questionIds: Array<number> },
          ) => {
            if (formValues) {
              updateChallenge(challenge.id, formValues);
            }
            if (questions) {
              updateChallengeQuestions(challenge.id, questions.questionIds);
            }
          }}
        />
      )}
      {isDeleteOpen && (
        <ChallengeDeleteModal
          isDeleteOpen={isDeleteOpen}
          selectedChallenge={challenge}
          onCloseModal={onCloseModal}
          onConfirmDelete={() => {
            deleteChallenge.mutate(challenge.id, {
              onSettled: (_, error) => {
                if (!error) {
                  onDeleteChallenge?.();
                }
              },
            });
            onCloseModal();
          }}
        />
      )}
      {isCopyOpen && (
        <ChallengeCopyModal
          isCopyOpen={isCopyOpen}
          selectedChallenge={challenge}
          removedQuestionList={challengeDetailList
            .find((item) => item.id === challenge.id)
            ?.questions.filter(
              (question) => question.status === ChallengeStatus.Removed,
            )}
          onCloseModal={onCloseModal}
          onConfirmCopy={() => {
            const challengeDetail = challengeDetailList.find(
              (item) => item.id === challenge.id,
            );
            if (challengeDetail) {
              copyChallenge(challengeDetail);
            }
            onCloseModal();
          }}
        />
      )}
    </>
  );
};
