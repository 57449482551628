class InitialData {
  private _env: string;
  private _orgName: string;
  private _flashMessage: string;

  constructor() {
    const dataset = document.body.dataset;
    if (!dataset) {
      return;
    }
    this._orgName = dataset.orgname || "";
    this._flashMessage = dataset.flashMessage || "";
    this._env = dataset.env || "";
  }

  get orgName() {
    return this._orgName;
  }

  get flashMessage() {
    return this._flashMessage;
  }

  get env() {
    return this._env;
  }

  get initialData() {
    return {
      orgName: this._orgName,
      flashMessage: this._flashMessage,
      env: this._env,
    };
  }
}

export default new InitialData();
