import { Placement } from "@popperjs/core";
import * as classnames from "classnames";

import {
  ChallengeResultStatus,
  ChallengeStyle,
  ScoreStatus,
  UnstableReason,
} from "@shared/services/enums";
import { getScoreStatusMessage } from "@shared/services/score";

import { Icon, Tooltip } from "..";

/**
 * Prop interface
 */
export interface ScoreStatusBadgeProps {
  scoreStatus?: ScoreStatus;
  challengeResultStatus?: ChallengeResultStatus;
  challengeStyle?: ChallengeStyle;
  unstableReason?: UnstableReason;
  className?: string;
  tooltipPlacement?: Placement;
}

/**
 * React Component
 */
export default function ScoreStatusBadge({
  className,
  scoreStatus,
  challengeResultStatus,
  challengeStyle,
  unstableReason,
  tooltipPlacement = "top",
}: ScoreStatusBadgeProps) {
  const rootStyle = classnames("code-c-score-status-badge", {
    [`${className}`]: Boolean(className),
  });
  const isChallengeInProgress =
    challengeResultStatus === ChallengeResultStatus.InProgress ||
    challengeResultStatus === ChallengeResultStatus.Started;

  if (
    !unstableReason &&
    !isChallengeInProgress &&
    (scoreStatus === undefined ||
      scoreStatus === ScoreStatus.NotScored ||
      scoreStatus === ScoreStatus.Scored)
  ) {
    return null;
  }

  const label = getScoreStatusMessage({
    unstableReason,
    scoreStatus,
    challengeResultStatus,
    challengeStyle,
  });

  return (
    <Tooltip
      maxSize={"xlarge"}
      wrapperClassName={rootStyle}
      text={label}
      placement={tooltipPlacement}
    >
      <Icon type={"exclamation-triangle"} />
    </Tooltip>
  );
}
