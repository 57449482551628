import { AxiosRequestConfig } from "axios";

import { ApiResponse, put } from "@api/httpClient";

import { MemberListModel } from "../../shared/models";

export interface ExamReviewerPayload {
  reviewers: number[];
}

interface ExamReviewerParams {
  examId: number;
  projectId: number;
  options?: AxiosRequestConfig<ExamReviewerPayload>;
}

export async function putExamReviewer({
  examId,
  projectId,
  options,
}: ExamReviewerParams) {
  const { data } = await put<ApiResponse<MemberListModel>>(
    `/api/projects/${projectId}/exams/${examId}/reviewers`,
    options,
  );

  return data;
}
