import { SearchSelect, SelectOption } from "@shared/components";
import { SimpleExamModel } from "@shared/models";
import MSG from "@shared/services/message";

interface SettingContractExamInputProps {
  selectedExam?: SimpleExamModel;
  simpleExamList: SimpleExamModel[];
  onExamSelected: (selectedExam: SimpleExamModel | undefined) => void;
}

export const SettingContractExamPicker = ({
  simpleExamList,
  selectedExam,
  onExamSelected,
}: SettingContractExamInputProps) => {
  const examOptions = simpleExamList.map((exam) => ({
    value: exam.examId,
    label: `${exam.examId}  ${exam.examName}`,
  }));

  const onValueSelected = (examSelect: SelectOption) => {
    const { value } = examSelect;
    const exam = simpleExamList.find((exam) => exam.examId === Number(value));

    onExamSelected(exam);
  };

  return (
    <SearchSelect
      inputClassName="code-setting-contract__input"
      selected={selectedExam?.examId}
      options={examOptions}
      onChange={onValueSelected}
      placeholder={MSG.getMessageByKey("exam.restrictions.inputPlaceholder")}
      onClearClicked={() => {
        onExamSelected(undefined);
      }}
    />
  );
};
