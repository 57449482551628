import * as classnames from "classnames";
import * as React from "react";

import { useScrollPosition } from "@shared/hooks";

import { Icon, Msg, Tooltip } from "../..";

/**
 * Prop interface
 */
export type SubmissionDetailSectionProps = {
  title: React.ReactNode;
  children: React.ReactNode;
  className?: string;
  isEmpty?: boolean;
};

/**
 * React Component
 */
export const SubmissionDetailSection: React.FunctionComponent<
  SubmissionDetailSectionProps
> = (props: SubmissionDetailSectionProps) => {
  const { title, children, className, isEmpty } = props;

  /**
   * State
   */
  const [isOpen, setIsOpen] = React.useState<boolean | undefined>(
    isEmpty ? false : undefined,
  );

  const targetRef = React.useRef<HTMLDivElement | null>(null);
  const { capturePosition, resetPosition } = useScrollPosition(targetRef);

  /**
   * Effects
   */
  React.useEffect(() => {
    if (isOpen !== undefined) {
      resetPosition();
    }
  }, [isOpen, resetPosition]);

  /**
   * Private Functions
   */
  const rootStyle = classnames("code-c-submission-detail-section", {
    [`${className}`]: Boolean(className),
    disabled: Boolean(isEmpty),
  });

  return (
    <div className={rootStyle}>
      <div
        className="code-c-submission-detail-section__header"
        role="button"
        onClick={() => {
          if (!isEmpty) {
            capturePosition();
            setIsOpen(isOpen === undefined ? false : !isOpen);
          }
        }}
        aria-label="Toggle Submission Detail Section"
        aria-pressed={isOpen}
        ref={targetRef}
      >
        <Tooltip text={<Msg id="submission.noDataFound" />} disabled={!isEmpty}>
          <Icon
            className="code-c-submission-detail-section__icon"
            type={isOpen === false || isEmpty ? "plus" : "minus"}
          />
        </Tooltip>
        <span>{title}</span>
      </div>
      {!isEmpty && isOpen !== false && (
        <div className="code-c-submission-detail-section__body">{children}</div>
      )}
    </div>
  );
};
