import * as classnames from "classnames";

import { BarChart } from "../../../../../shared/components";
import { ChallengeInsightModel } from "../../../../../shared/models";
import Message from "../../../../../shared/services/message";

/**
 * Prop interface
 */
export interface UsageOverTimeProps {
  challengeInsight?: ChallengeInsightModel;
  chartWidth?: number;
}

/**
 * React Component
 */
export function UsageOverTime({
  challengeInsight,
  chartWidth = 800,
}: UsageOverTimeProps) {
  const rootStyle = classnames("code-c-usage-over-time");

  if (!challengeInsight) {
    return null;
  }

  const { monthlyApplicantsStats = [] } = challengeInsight;

  const numberOfApplicants = monthlyApplicantsStats
    .map((data) => {
      return {
        label:
          data.year + "/" + (data.month < 10 ? "0" + data.month : data.month),
        number: data.count,
      };
    })
    .sort((a, b) => (a.label > b.label ? 1 : -1));

  return (
    <div className={rootStyle}>
      <BarChart
        xLabel={Message.getMessageByKey("challenge.appliedDate")}
        yLabel={Message.getMessageByKey("challenge.numberOfSubmission")}
        items={numberOfApplicants}
        size={chartWidth}
      />
    </div>
  );
}
