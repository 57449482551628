import { AxiosRequestConfig } from "axios";

import { put } from "../httpClient";

export async function putSubmissionResetPassword(
  projectId: number,
  submissionId: number,
  options?: AxiosRequestConfig,
) {
  const { data } = await put(
    `/api/projects/${projectId}/submissions/${submissionId}/resetPassword`,
    options,
  );

  return data;
}
