import { useMutation } from "@tanstack/react-query";

import { UpdateExamIdSharingPayload, putExamIdSharing } from "@api/exams";

import { useStoreContext } from "@context";

import { useInvalidateExamRelation } from "./useExam";

interface UpdateExamIdSharingParams {
  examId: number;
  data: UpdateExamIdSharingPayload;
}

export function useUpdateExamIdSharing() {
  const { projectId } = useStoreContext();
  const invalidate = useInvalidateExamRelation();

  const mutation = useMutation({
    mutationFn: ({ examId, data }: UpdateExamIdSharingParams) =>
      putExamIdSharing({ examId, projectId, options: { data } }),
    onSuccess: (_, { examId }) => {
      invalidate(examId);
    },
  });

  return mutation;
}
