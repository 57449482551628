import * as classnames from "classnames";
import * as React from "react";

import { Icon, Tag } from "@shared/components";

export type WarningTagProps = {
  children?: React.ReactNode;
  className?: string;
  rounded?: boolean;
  bordered?: boolean;
  iconSize?: "small" | "medium" | "large";
};

export const WarningTag = ({
  className,
  children,
  rounded,
  bordered,
  iconSize = "small",
}: WarningTagProps) => {
  // MEMO: Need to change the sizes that we pass to the Icon component so they're suitable
  const iconTagSize =
    iconSize === "small"
      ? "small"
      : iconSize === "large"
      ? "medium"
      : undefined;
  return (
    <Tag
      color="yellow"
      className={classnames("code-c-warning-tag", {
        [`is-rounded`]: Boolean(rounded),
        [`is-bordered`]: Boolean(bordered),
        [`${className}`]: Boolean(className),
        [`is-icon-${iconSize}`]: Boolean(iconSize),
      })}
      startIcon={
        <span
          className={classnames({
            [`is-icon-size-${iconSize}`]: Boolean(iconSize),
          })}
        >
          <Icon
            type="exclamation-triangle"
            color="yellow-500"
            size={iconTagSize}
          />
        </span>
      }
    >
      <div className="has-text-weight-medium">{children}</div>
    </Tag>
  );
};
