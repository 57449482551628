import { Epic } from "redux-observable";

import {
  Action,
  applicantActionLogGetAllAction,
  APIResponseAction,
  ajaxAction,
  addToPayload,
} from "@actions";

import { RootState } from "@reducers/index";

export const applicantActionLogGetEpic: Epic<Action, RootState> = (
  action$,
  state,
) =>
  action$.ofType(applicantActionLogGetAllAction.types.request).map((action) => {
    return ajaxAction.request({
      method: "get",
      url: `/api/projects/${
        state.getState().project.currentProjectId
      }/submissions/${action.params}/actionlog`,
      body: action.payload,
      success: addToPayload(
        { params: { submissionId: action.params, ...action.payload } },
        applicantActionLogGetAllAction.success,
      ),
    });
  });

export const postApplicantActionLogGetEpic: Epic<Action, RootState> = (
  action$,
  state,
) =>
  action$.ofType(applicantActionLogGetAllAction.types.success).map((action) => {
    const { pagination, params } = (
      action as APIResponseAction & {
        payload: {
          params: { submissionId: number };
        };
      }
    ).payload;
    if (pagination && pagination.count > pagination.offset + pagination.limit) {
      const { submissionId, ...rest } = params;
      return applicantActionLogGetAllAction.request(
        {
          ...rest,
          offset: pagination.offset + pagination.limit,
        },
        submissionId,
      );
    } else {
      return { type: "@null" };
    }
  });
