import { Socket } from "socket.io-client";

import {
  ApplicantChallengeContextModel,
  ApplicantExamModel,
  KeyEventLogModel,
  KeyEventModel,
  IApplicantActionLog,
} from "@shared/models";
import { ActionSaveChallenge } from "@shared/models/ApplicantActionLog.model";

import { dayjs } from "./date";
import {
  ApplicantActionType,
  ChallengeResultStatus,
  ChallengeStyle,
  KeyEventStatus,
} from "./enums";
import Logger from "./logger";
import { buildSocketClient } from "./socket";

type KeyEventServiceOption = (k: KeyEventService) => void;

export type CodePlayBackEvents = "key" | "load";

export const KEY_EVENT_ERROR_NO_STORAGE_ID = 5001;
export const KEY_EVENT_ERROR_DUPLICATE_CONNECTION = 5002;
export const KEY_EVENT_ERROR_AUTHORIZATION = 5003;

export type KeyEventError = {
  code: number;
  message?: string;
};

const SEND_EVENTS_INTERVAL_MS = 5000;
const RECONNECT_INTERVAL_MS =
  Number(process.env.REACT_APP_KEY_EVENTS_SERVER_RECONNECT_INTERVAL_MS) ??
  1000 * 60 * 10; // default 10 mins

export class KeyEventService {
  private lastEventMs = 0;
  private lastEmitMs = 0;
  private lastConnectedMs = 0;
  private eventCache: {}[] = [];
  private eventQueue: {}[] = [];
  private ws: Socket;

  constructor(...options: KeyEventServiceOption[]) {
    // default

    // set the options
    for (const option of options) {
      option(this);
    }

    const now = performance.now();
    this.lastEmitMs = now;
    this.lastConnectedMs = now;
    window.requestAnimationFrame(this.tick);
  }

  public static async connect({
    keyEventToken,
    challengeResultStorageId,
    extensionId,
    onShutdown,
    onError,
    onConnectError,
  }: {
    keyEventToken: string;
    challengeResultStorageId: string;
    extensionId?: string;
    onShutdown?: () => void;
    onError?: (e: KeyEventError) => void;
    onConnectError?: (e: Error) => void;
  }): Promise<KeyEventServiceOption> {
    const connection = new Promise<Socket>((resolve) => {
      const socket = buildSocketClient(
        process.env.REACT_APP_KEY_EVENTS_SERVER_URL ?? "",
        {
          query: {
            challengeResultStorageId,
            ...(extensionId && { extensionId }),
          },
          extraHeaders: {
            Authorization: `Bearer ${keyEventToken}`,
          },
          reconnection: true,
          reconnectionDelay: 1000,
          withCredentials: true,
        },
      );

      socket.on("reconnect", () => {
        Logger.info("reconnect");
      });

      socket.on("connect_error", (e) => {
        Logger.error("connect_error", e);
        if (typeof onConnectError === "function") {
          onConnectError(e);
        }
        resolve(socket);
      });

      socket.on("connectionComplete", () => {
        Logger.info("connection complete");
        resolve(socket);
      });

      socket.on("disconnect", () => {
        Logger.info("disconnect");
      });

      socket.on("shutdown", () => {
        Logger.info("shutdown");
        if (typeof onShutdown === "function") {
          onShutdown();
        }
      });

      socket.on("error", (e) => {
        Logger.error("error", e);
        if (typeof onError === "function") {
          onError(e);
        }
      });
    });

    const ws = await connection;

    return (k: KeyEventService) => {
      k.ws = ws;
    };
  }

  private tick = async (timestamp: number) => {
    const emittedElapsed = timestamp - this.lastEmitMs;
    const connectedElapsed = timestamp - this.lastConnectedMs;
    if (emittedElapsed > SEND_EVENTS_INTERVAL_MS) {
      await this.sendEvents();
      this.lastEmitMs = timestamp;
    }
    if (connectedElapsed > RECONNECT_INTERVAL_MS) {
      await this.reconnect();
      this.lastConnectedMs = timestamp;
    }
    window.requestAnimationFrame(this.tick);
  };

  private sendEvents = async () => {
    if (this.ws.disconnected) {
      return;
    }
    this.eventQueue.push(...this.eventCache);
    this.eventCache = [];

    try {
      Logger.info("send events", this.eventQueue);
      await this.ws.timeout(1000).emitWithAck("message", this.eventQueue);
      this.eventQueue = [];
    } catch (e) {
      Logger.error("send events error", e);
    }
  };

  public reconnect = async () => {
    if (this.ws.disconnected) {
      return;
    }

    this.ws.disconnect();
    this.ws.connect();
  };

  public send = (
    data: {},
    timestamp: string,
    millisBeforeExamDeadline: number,
  ) => {
    const ms =
      this.lastEventMs === 0
        ? 0
        : Math.round(this.lastEventMs - millisBeforeExamDeadline);
    this.eventCache.push({ ...data, ms, timestamp });
    this.lastEventMs = millisBeforeExamDeadline;
  };

  public destroy = () => {
    this.ws.close();
    this.eventCache = [];
    this.eventQueue = [];
  };
}

/**
 * check if challenge meets the condition to record key events
 *
 * @param exam
 * @param challengeId
 * @returns
 */
export const canRecordKeyEvents = (
  exam: ApplicantExamModel,
  context: ApplicantChallengeContextModel,
  challengeId: number,
): boolean => {
  const challenge = exam.getChallengeByChallengeId(challengeId);

  if (!context.activeKeyEventLog?.storageId) {
    return false;
  }
  if (!exam.isInProgress()) {
    return false;
  }
  if (!exam.isChallengeStarted(challengeId)) {
    return false;
  }
  if (!ChallengeStyle.canPlayback(challenge.style)) {
    return false;
  }
  return true;
};

export const getKeyEventStatus = (
  challengeResultStatus: ChallengeResultStatus,
  keyEventLogs?: KeyEventLogModel[],
): KeyEventStatus | undefined => {
  if (
    keyEventLogs === undefined ||
    keyEventLogs.length === 0 ||
    // ignore when NotModified because keyEventStatus is Processed in this case.
    [ChallengeResultStatus.NotModified].includes(challengeResultStatus)
  ) {
    return undefined;
  }
  if (keyEventLogs.some((event) => event.status === KeyEventStatus.Error)) {
    return KeyEventStatus.Error;
  }
  if (
    keyEventLogs.some((event) => event.status === KeyEventStatus.Unprocessed)
  ) {
    return KeyEventStatus.Unprocessed;
  }
  if (keyEventLogs.some((event) => event.status === KeyEventStatus.Active)) {
    return KeyEventStatus.Active;
  }
  if (
    keyEventLogs.every((event) => event.status === KeyEventStatus.Processed)
  ) {
    return KeyEventStatus.Processed;
  }
  return KeyEventStatus.Error;
};

export const getSaveHistoryIds = (
  actionLogs: IApplicantActionLog[],
  currentKeyEventLog: KeyEventModel,
) => {
  return actionLogs
    .filter(
      (actionLog) =>
        actionLog.action === ApplicantActionType.SaveChallenge &&
        Boolean(actionLog.saveHistoryId) &&
        dayjs(actionLog.actionTime).isBetween(
          dayjs(currentKeyEventLog.keyEventLog.openedAt),
          dayjs(currentKeyEventLog.keyEventLog.finishedAt),
        ),
    )
    .map((actionLog) => (actionLog as ActionSaveChallenge).saveHistoryId);
};
