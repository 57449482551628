import * as React from "react";

export * from "./useApplicantList";
export { useLocalStorage } from "./useLocalStorage";
export {
  CsvDownloadForm,
  useSubmissionDownloadSetting,
} from "./useSubmissionDownloadSetting";
export { useDidUpdateEffect } from "./useDidUpdateEffect";
export { usePageVisibility } from "./usePageVisible";
export { useScrollTo } from "./useScrollTo";
export { useOnClickOutside } from "./useOnClickOutside";
export * from "./useDebounce";
export * from "./useIframeCommunication";
export * from "./useSubmissionAutoRefetch";
export * from "./auth";
export * from "./useDebounce";
export * from "./useHoverBox";
export * from "./useElement";
export * from "./useWindowSize";
export * from "./useArrayMemo";
export * from "./useFileUpload";
export * from "./useBeforeUnload";
export * from "./useOnlineStatus";

export function useOnHidden<T extends Element>(
  ref: React.MutableRefObject<T | null>,
  options?: {
    root?: Element | null;
    rootMargin?: string;
    threshold?: number[];
  },
): boolean {
  const [isHidden, setIsHidden] = React.useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const observer = new IntersectionObserver(
    ([entry]) => {
      setIsHidden(entry.intersectionRatio < 1);
    },
    { ...options },
  );

  React.useEffect(() => {
    if (ref.current !== null) {
      observer.observe(ref.current);
    }
    return () => {
      observer.disconnect();
    };
  }, [observer, ref]);

  return isHidden;
}

export function useScrollPosition<T extends HTMLElement>(
  ref: React.MutableRefObject<T | null>,
) {
  const [distance, setDistance] = React.useState(0);

  const resetPosition = React.useCallback(() => {
    () => {
      const offsetTop = ref.current?.offsetTop || 0;
      document.documentElement.scrollTo(0, offsetTop - distance);
    };
  }, [ref, distance]);

  return {
    capturePosition: () => {
      const offsetTop = ref.current?.offsetTop || 0;
      const scrollTop =
        document.body.scrollTop || document.documentElement.scrollTop || 0;
      setDistance(offsetTop - scrollTop);
    },
    resetPosition,
  };
}

export function useScrollToTop<T extends HTMLElement>(
  ref: React.MutableRefObject<T | null>,
) {
  return () => {
    ref.current?.scrollTo(0, 0);
  };
}

export function useMouseWheel(callback: (e: Event) => void) {
  React.useEffect(() => {
    window.addEventListener("mousewheel", callback);
    return function cleanup() {
      window.removeEventListener("mousewheel", callback);
    };
  }, [callback]);
}
