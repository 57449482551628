import * as classnames from "classnames";

/**
 * Prop interface
 */
export interface SpinnerProps {
  className?: string;
  color?: "primary" | "gray";
  size?: "base" | "tiny" | "small";
}

/**
 * React Component
 */
export default function Spinner({
  className,
  color = "primary",
  size = "base",
}: SpinnerProps) {
  const rootStyle = classnames("code-c-spinner", {
    [`${className}`]: Boolean(className),
    [`code-c-spinner--${color}`]: Boolean(color),
    [`code-c-spinner--${size}`]: Boolean(size),
  });

  return <div role="status" className={rootStyle} />;
}
