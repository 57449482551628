import * as classnames from "classnames";
import * as React from "react";

import { useDeliverSampleById } from "@shared/hooks/query";

import {
  Modal,
  Msg,
  Form,
  FormGroup,
  Label,
  LanguageTag,
  Radio,
  JumpTo,
} from "../../../../../../../shared/components";
import {
  ExamModel,
  IdExamDeliveryModel,
  UserModel,
} from "../../../../../../../shared/models";
import { ExamType } from "../../../../../../../shared/services/enums";
import { getExamDeliveryURL } from "../../../../../../../shared/services/url";

/**
 * Prop interface
 */
export type ExternalProps = {
  exam: ExamModel;
  isOpen?: boolean;
  onClose: () => void;
};

export type InjectedProps = {
  currentUser?: UserModel;
};

export type SampleDeliveryIDProps = ExternalProps & InjectedProps;

type SampleDeliveryIDInput = {
  language: string;
};

/**
 * React Component
 */
export function SampleDeliveryID({
  isOpen,
  exam,
  currentUser,
  onClose,
}: SampleDeliveryIDProps) {
  const { examType, idDeliveryConfig, urlToken } = exam;
  const deliverSample = useDeliverSampleById();
  const { isSuccess: isComplete, isLoading: submitting } = deliverSample;
  const sampleDeliveryInfo = new IdExamDeliveryModel(
    deliverSample.data?.result,
  );

  /**
   * States
   */
  const [form, setForm] = React.useState<{
    formValid: boolean;
    formValues: SampleDeliveryIDInput;
    formErrors?: {};
  }>({ formValid: false, formValues: { language: "ja" } });

  /**
   * Private Functions
   */
  const rootStyle = classnames("code-sample-delivery-id");

  const multiLanguage = examType === ExamType.EnglishJapanese;

  const idDeliverExamURL = getExamDeliveryURL(
    currentUser?.organization.name || "",
    urlToken,
    false,
  );

  const onFormChange = (
    formValid: boolean,
    formValues: SampleDeliveryIDInput,
    formErrors?: {},
  ) => {
    setForm({ formValid, formValues, formErrors });
  };

  const onDelivery = () => {
    if (isComplete) {
      onClose();
    } else {
      const { language } = form.formValues;
      const body = {
        ...(multiLanguage && { language }),
      };
      deliverSample.mutate({ examId: exam.id, data: body });
    }
  };

  return (
    <Modal
      className={rootStyle}
      isOpen={isOpen}
      title={<Msg id="delivery.test" />}
      okButtonLabel={<Msg id={isComplete ? "common.ok" : "action.deliver"} />}
      onClose={onClose}
      onClickCancel={onClose}
      onClickOk={onDelivery}
      okButtonAriaLabel={isComplete ? "OK" : "Deliver Exam"}
      disableOk={submitting}
      hasCancelButton={!isComplete}
      hasCloseButton={!isComplete}
      ariaLabel="ID Deliver Sample Exam"
    >
      {idDeliveryConfig && (
        <Form
          validation={{ language: ["string", "required"] }}
          initialValues={{ language: "ja" }}
          onFormChange={onFormChange}
        >
          <FormGroup>
            <Msg
              id={
                isComplete
                  ? "exam.idDelivery.test.delivered"
                  : idDeliveryConfig.passwordRequired
                  ? "exam.idDelivery.test.confirm.idAndPassword"
                  : "exam.idDelivery.test.confirm.id"
              }
            />
          </FormGroup>
          {multiLanguage && (
            <FormGroup className="code-sample-delivery-id__language">
              <Label>
                <Msg id="exam.examlanguage" />
              </Label>
              {isComplete ? (
                sampleDeliveryInfo.language && (
                  <div>
                    <LanguageTag
                      language={sampleDeliveryInfo.language}
                      color="pink"
                    />
                  </div>
                )
              ) : (
                <>
                  <div>
                    <Radio name="language" defaultValue="ja">
                      <LanguageTag language="ja" color="pink" />
                    </Radio>
                  </div>
                  <div>
                    <Radio name="language" defaultValue="en">
                      <LanguageTag language="en" color="pink" />
                    </Radio>
                  </div>
                </>
              )}
            </FormGroup>
          )}
          <FormGroup>
            <Label>
              <Msg id="exam.idDelivery.test.id" />
            </Label>
            <div className="is-bold">
              {isComplete ? (
                sampleDeliveryInfo.applicantExams[0].applicant.email
              ) : (
                <Msg id="exam.idDelivery.test.willBeDisplayed" />
              )}
            </div>
          </FormGroup>
          {idDeliveryConfig.passwordRequired && (
            <FormGroup>
              <Label>
                <Msg id="exam.idDelivery.test.initialPassword" />
              </Label>
              <div className="is-bold">
                {isComplete ? (
                  sampleDeliveryInfo.applicantExams[0].initialPassword
                ) : (
                  <Msg id="exam.idDelivery.test.willBeDisplayed" />
                )}
              </div>
            </FormGroup>
          )}
          <FormGroup>
            <Label>
              <Msg id="exam.idDelivery.test.url" />
            </Label>
            {isComplete ? (
              <p>
                <JumpTo
                  to={`${idDeliverExamURL}?_tid=${sampleDeliveryInfo.applicantExams[0].applicant.email}`}
                >
                  {idDeliverExamURL}
                </JumpTo>
              </p>
            ) : (
              <div className="is-bold">{idDeliverExamURL}</div>
            )}
          </FormGroup>
          {isComplete && (
            <>
              {idDeliveryConfig.passwordRequired ? (
                <p>
                  <Msg id="exam.idDelivery.test.confirm.password.info" />
                </p>
              ) : (
                <p>
                  <Msg id="exam.idDelivery.test.confirm.id.info" />
                </p>
              )}
            </>
          )}
        </Form>
      )}
    </Modal>
  );
}
