import * as classNames from "classnames";
import * as React from "react";

interface BadgeProps extends React.HTMLProps<HTMLDivElement> {
  className?: string;
  type?: "warning";
  children?: React.ReactNode;
}

const Badge = React.forwardRef<HTMLDivElement, BadgeProps>(
  ({ className, type, children, ...rest }: BadgeProps, ref) => {
    const rootStyle = classNames(
      "code-c-badge",
      {
        [`is-${type}`]: Boolean(type),
      },
      className,
    );

    return (
      <div className={rootStyle} ref={ref} {...rest}>
        {children}
      </div>
    );
  },
);

Badge.displayName = "Badge";

export { Badge };
