import * as classnames from "classnames";

import { Tag } from "../..";
import Message from "../../../services/message";

/**
 * React Component
 */
export function DraftTag() {
  const rootStyle = classnames("code-c-draft-tag");
  return (
    <Tag className={rootStyle}>
      {Message.getMessageByKey("challenge.drafted")}
    </Tag>
  );
}
