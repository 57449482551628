import { AxiosRequestConfig } from "axios";

import { EnumWithStringValueModel, SubmissionListModel } from "@shared/models";

import { ApiResponse, put } from "../httpClient";

export { EnumWithStringValueModel };

export async function putSubmissionArchive(
  projectId: number,
  submissionId: number,
  options?: AxiosRequestConfig,
) {
  const { data } = await put<ApiResponse<SubmissionListModel>>(
    `/api/projects/${projectId}/submissions/${submissionId}/archive`,
    options,
  );

  return data;
}

export async function putSubmissionUnarchive(
  projectId: number,
  submissionId: number,
  options?: AxiosRequestConfig,
) {
  const { data } = await put<ApiResponse<SubmissionListModel>>(
    `/api/projects/${projectId}/submissions/${submissionId}/unarchive`,
    options,
  );

  return data;
}
