import { connect } from "react-redux";
import { Action, Dispatch } from "redux";

import { userRefreshAction } from "@actions/UserActions";

import { RootState } from "@reducers/index";

import { Layout } from "./Layout";

const mapStateToProps = (state: RootState) => ({
  currentProjectId: state.project.currentProjectId,
  currentUser: state.user.user,
  orgLogo: state.user.user ? state.user.user.organization.imageUrl : null,
  orgName: state.user.user ? state.user.user.organization.displayName : null,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  refreshUser: () => {
    dispatch(userRefreshAction.request());
  },
});

/**
 * Wrapped component
 */
const wrappedComponent = connect(mapStateToProps, mapDispatchToProps)(Layout);

export default wrappedComponent;
