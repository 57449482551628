import * as classnames from "classnames";
import { isEqual } from "lodash";
import * as React from "react";

import {
  Modal,
  Label,
  Input,
  Loading,
  Row,
  Column,
  Table,
  TableHead,
  TableBody,
  HeaderColumn,
  Button,
  Icon,
  Msg,
} from "../../../../shared/components";
import { QuestionModel } from "../../../../shared/models";

/**
 * Prop interface
 */
export interface QuestionImportProps {
  isOpen?: boolean;
  importLoading?: boolean;
  importedQuestions?: Array<QuestionModel>;
  className?: string;

  onOk?: (questions: Array<QuestionModel>) => void;
  onClose?: () => void;
  importQuestions?: (payload: {}) => void;
  deleteQuestion?: (id: number) => void;
}

export interface QuestionImportState {
  questions: Array<QuestionModel>;
}

/**
 * React Component
 */
class QuestionImport extends React.Component<
  QuestionImportProps,
  QuestionImportState
> {
  private selectFileInput: HTMLInputElement;

  constructor(props: QuestionImportProps) {
    super(props);

    this.state = {
      questions: [],
    };
  }

  public UNSAFE_componentWillReceiveProps(newProps: QuestionImportProps) {
    if (!isEqual(this.props.importedQuestions, newProps.importedQuestions)) {
      this.setState({
        questions: this.state.questions.concat(
          newProps.importedQuestions || [],
        ),
      });
    }

    if (!isEqual(this.props.isOpen, newProps.isOpen)) {
      this.setState({
        questions: [],
      });
    }
  }

  public render() {
    const { className, isOpen, importLoading } = this.props;

    const rootStyle = classnames("code-question-import", {
      [`${className}`]: Boolean(className),
    });

    const questions = this.state.questions.map((question) => (
      <Row key={question.id}>
        <Column>{question.id}</Column>
        <Column>{question.title}</Column>
        <Column>
          <Button
            shrink={true}
            size={"small"}
            onClick={() => this.deleteQuestion(question.id)}
          >
            <Icon type={"trash"} />
          </Button>
        </Column>
      </Row>
    ));

    return (
      <Modal
        title={<Msg id={"button.importQuestions"} />}
        onClickOk={this.onOk}
        onClose={this.props.onClose}
        isOpen={isOpen}
        className={rootStyle}
        hasCancelButton={false}
      >
        <Loading isOpen={importLoading} />
        <Label className="code-question-import__right-label">
          <Msg id={"importFromZip"} />
          <Button
            size="small"
            shrink={true}
            onClick={() => this.selectFileInput && this.selectFileInput.click()}
          >
            <Msg id={"button.chooseFile"} />
          </Button>
        </Label>
        <Input
          style={{ display: "none" }}
          reference={(r: HTMLInputElement) => (this.selectFileInput = r)}
          type="file"
          accept=".zip"
          onChange={this.onImportQuestions}
          value=""
        />
        <Table>
          <TableHead>
            <HeaderColumn size={2}>
              <Msg id={"id"} />
            </HeaderColumn>
            <HeaderColumn size={6}>
              <Msg id={"common.title"} />
            </HeaderColumn>
            <HeaderColumn size={1} />
          </TableHead>
          <TableBody>{questions}</TableBody>
        </Table>
      </Modal>
    );
  }

  private onImportQuestions = (event: React.FormEvent<HTMLInputElement>) => {
    const files = (event.target as HTMLInputElement).files;
    const file = (files && files.length && files[0]) || undefined;

    const formData = new FormData();
    formData.append("file", file as File);

    if (this.props.importQuestions) {
      this.props.importQuestions(formData);
    }
  };

  private deleteQuestion = (id: number) => {
    const questionList = this.state.questions.filter(
      (question) => question.id !== id,
    );

    this.setState({
      questions: questionList,
    });

    if (this.props.deleteQuestion) {
      this.props.deleteQuestion(id);
    }
  };

  private onOk = () => {
    if (this.props.onOk) {
      this.props.onOk(this.state.questions);
    }
  };
}

export default QuestionImport;
