import * as classnames from "classnames";
import { FormattedMessage as Msg } from "react-intl";

import {
  Modal,
  VersionCodeTag,
  LanguageTag,
} from "../../../../../shared/components";
import { ExamChallengeModel } from "../../../../../shared/models";

/**
 * Prop interface
 */
export interface ChallengeUpdateConfirmProps {
  isOpen: boolean;
  challenge: ExamChallengeModel;
  onUpdate: () => void;
  onCancel: () => void;
}

/**
 * React Component
 */
export function ChallengeUpdateConfirm({
  isOpen,
  challenge,
  onCancel,
  onUpdate,
}: ChallengeUpdateConfirmProps) {
  const rootStyle = classnames("code-c-challenge-major-update-confirm");

  const {
    usedChallengeVersionCode,
    nextAvailableChallengeVersionCode,
    nextAvailableLinkedChallengeVersionCode,
    originalChallenge: { linkedChallenge, language },
    title,
  } = challenge;

  const multiLanguage = linkedChallenge && nextAvailableChallengeVersionCode;

  return (
    <Modal
      className={rootStyle}
      isOpen={isOpen}
      title={<Msg id="challengeVersion.updateConfirm.title" />}
      onClickOk={onUpdate}
      onClickCancel={onCancel}
      onClose={onCancel}
      ariaLabel="Challenge Major Update Confirm"
    >
      <p>
        <Msg id="challengeVersion.updateConfirm.body" />
      </p>
      <div className="code-c-challenge-major-update-confirm__body">
        <div className="code-c-challenge-major-update-confirm__body-box">
          <p className="code-c-challenge-major-update-confirm__body-title">
            {multiLanguage && <LanguageTag language={language} color="pink" />}
            <span className="is-break-all">{title}</span>
          </p>
          <p className="code-c-challenge-major-update-confirm__body-version">
            <Msg id="challengeVersion.updateConfirm.currentVersion" />
            <VersionCodeTag version={usedChallengeVersionCode} />
          </p>
          <p className="code-c-challenge-major-update-confirm__body-version">
            <Msg id="challengeVersion.updateConfirm.newVersion" />
            <VersionCodeTag version={nextAvailableChallengeVersionCode} />
          </p>
        </div>
        {linkedChallenge &&
          nextAvailableLinkedChallengeVersionCode &&
          multiLanguage && (
            <div className="code-c-challenge-major-update-confirm__body-box">
              <p className="code-c-challenge-major-update-confirm__body-title">
                {multiLanguage && (
                  <LanguageTag
                    language={linkedChallenge.language}
                    color="pink"
                  />
                )}
                <span className="is-break-all">{linkedChallenge.title}</span>
              </p>
              <p className="code-c-challenge-major-update-confirm__body-version">
                <Msg id="challengeVersion.updateConfirm.currentVersion" />
                <VersionCodeTag
                  version={`${linkedChallenge.currentVersion.majorVersionNumber}.${linkedChallenge.currentVersion.minorVersionNumber}`}
                />
              </p>
              <p className="code-c-challenge-major-update-confirm__body-version">
                <Msg id="challengeVersion.updateConfirm.newVersion" />
                <VersionCodeTag
                  version={nextAvailableLinkedChallengeVersionCode}
                />
              </p>
            </div>
          )}
      </div>
    </Modal>
  );
}
