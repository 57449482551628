import {
  UseMutationResult,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import { generate } from "shortid";

import { postScreenshotEvent } from "@api/applicants/applicantActionLog";
import { ApiResponse, get, getUnrestricted } from "@api/httpClient";

import { useStoreContext } from "@context";

import {
  ApplicantActionLogCountsAPIModel,
  IApplicantActionLog,
} from "@shared/models";
import { AllActionType } from "@shared/services/enums";
import { getMillisBeforeExamDeadline } from "@shared/services/exam";

import { useFeatureFlags } from "../app/useFeatureFlags";

export type UseSendScreenshotApplicantActionMutationResult = UseMutationResult<
  unknown,
  unknown,
  { pressedKeys: string[] }
>;

export const useSendScreenshotApplicantAction = ({
  challengeResultId,
}: {
  challengeResultId: number;
}) => {
  const { applicantExam, examTimer, applicantExamId } = useStoreContext();
  const mutation = useMutation({
    mutationFn: async ({ pressedKeys }: { pressedKeys: string[] }) =>
      postScreenshotEvent({
        applicantExamId,
        challengeResultId,
        payload: {
          pressedKeys,
          ...(applicantExam && examTimer
            ? {
                millisBeforeDeadline: getMillisBeforeExamDeadline(
                  applicantExam,
                  examTimer,
                ),
              }
            : { millisBeforeDeadline: 0 }),
          activityId: generate(),
        },
      }),
  });

  return mutation;
};

export const useApplicantActionLogCounts = (
  submissionId: number,
  projectId: number,
) => {
  const { data: featureFlags } = useFeatureFlags();
  const webcamEnabled = featureFlags?.webcamEnabled;
  return useQuery({
    queryKey: ["actionLogCounts", submissionId, projectId, webcamEnabled],
    queryFn: async () => {
      const result = await get<ApplicantActionLogCountsAPIModel>(
        `/api/projects/${projectId}/submissions/${submissionId}/actionlog/counts`,
      );
      return result;
    },
    enabled: !!submissionId,
  });
};

interface ApplicantActionLogResponse extends ApiResponse<{}> {
  pagination: Record<string, any>;
  result: IApplicantActionLog[];
}

export const useApplicantActionLog = ({
  submissionId,
  projectId,
  offset,
  actions,
  challengeResultId,
}: {
  submissionId: number;
  projectId: number;
  offset: number;
  actions?: AllActionType[];
  challengeResultId?: number | "all";
}) => {
  // the endpoint doesn't accept "all" as a challengeResultId so we remove it
  const sanitisedChallengeResultId =
    challengeResultId === "all" ? undefined : challengeResultId;
  const query = useQuery({
    queryKey: [
      "applicantActionLog",
      submissionId,
      projectId,
      offset,
      actions,
      sanitisedChallengeResultId,
    ],
    queryFn: () => {
      return getUnrestricted<ApplicantActionLogResponse>(
        `/api/projects/${projectId}/submissions/${submissionId}/actionlog`,
        {
          params: {
            offset,
            actions,
            challengeResultId: sanitisedChallengeResultId,
          },
        },
      );
    },
    meta: {
      hideLoader: true,
    },
  });
  return query;
};
