import { connect } from "react-redux";
import { Action, Dispatch } from "redux";

import { projectMemberListGetAction, projectGetAction } from "@actions";

import { RootState } from "@reducers";

import PaginationModel from "@shared/models/Pagination.model";
import { getCurrentProject } from "@shared/selectors";
import { IncludeInvitedQueryString } from "@shared/services/enums";
import { isAssessmentProject } from "@shared/services/tier";

import ExamDetail, { ParentProps } from "./ExamDetail";

const mapStateToProps = (state: RootState, props: ParentProps) => {
  const currentProject = getCurrentProject(state);

  return {
    currentProject,
    currentUser: state.user.user,
    examId: parseInt(props.match.params.examId, 10),
    loading:
      state.exam.loadingExam ||
      (state.ajax.submitting &&
        !state.challenge.loadingList &&
        !state.challenge.loadingDetail &&
        !state.exam.loadingSubmissionList &&
        !state.report.challengesLoading &&
        !state.report.applicantChallengesLoading &&
        !state.report.challengeCategoriesLoading &&
        !state.project.projectMemberListLoading &&
        !state.actionLog.isActionLogListLoading) ||
      false,
    projectMemberListLoading: state.project.projectMemberListLoading,
    projectMembers: state.project.projectMemberList,
    hasTiers: !!state.orgs.tiers?.length,
    isAssessmentProject: isAssessmentProject(currentProject?.kind),
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  getProjectMembers: (pagination?: PaginationModel, keyword?: string) => {
    dispatch(
      projectMemberListGetAction.request(
        {},
        {
          ...pagination,
          onlyReviewers: true,
          includeInvited: IncludeInvitedQueryString.NonExpired,
          keyword,
        },
      ),
    );
  },
  getProjectDetails: (projectId: number) => {
    dispatch(projectGetAction.request({ projectId }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ExamDetail);
