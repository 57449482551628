import { debounce } from "lodash";
import { useCallback, useLayoutEffect, useState } from "react";

interface WindowSize {
  width?: number;
  height?: number;
}

export function useWindowSize() {
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const debouncedSetWindowSize = debounce(setWindowSize, 275);

  const handleSize = useCallback(
    () =>
      debouncedSetWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      }),
    [debouncedSetWindowSize],
  );

  useLayoutEffect(() => {
    window.addEventListener("resize", handleSize);
    return () => {
      window.removeEventListener("resize", handleSize);
    };
  }, [handleSize]);

  return windowSize;
}
