import * as classnames from "classnames";

import { Overlay, Spinner } from "..";

/**
 * Prop interface
 */
export interface LoadingProps {
  isOpen?: boolean;
  fullScreen?: boolean;
  className?: string;
  hide?: boolean;
  overlay?: boolean;
}

/**
 * React Component
 */
export default function Loading({
  isOpen,
  fullScreen = true,
  overlay = true,
  hide,
  className,
}: LoadingProps) {
  const rootStyle = classnames("code-c-loading", {
    "is-open": isOpen,
    "hide-background": hide,
    "is-full-screen": fullScreen,
    "no-overlay": !overlay,
    [`${className}`]: Boolean(className),
  });

  if (!isOpen) {
    return null;
  }

  return (
    <Overlay isOpen={isOpen} fullScreen={fullScreen} className={rootStyle}>
      <Spinner />
    </Overlay>
  );
}
