import * as classnames from "classnames";
import { isEqual } from "lodash";
import * as React from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";

import {
  ContentsSearchForm,
  DocumentTitle,
  Loading,
  Modal,
  Pagination,
  SubMenu,
  SubMenuContainer,
  SubMenuContainerLeft,
  SubMenuContainerRight,
  SubMenuItem,
  SubMenuLabel,
  SubMenuList,
  Table,
  Msg,
  Label,
} from "@shared/components";
import { useDidUpdateEffect, useTierActionPermissions } from "@shared/hooks";
import {
  useArchiveExam,
  useCopyExam,
  useDeleteExam,
  useExamJobTitleMap,
  useExamJobTitleOptions,
  useExamList,
  usePurposeOfUseMap,
  usePurposeOfUseOptions,
  useUnarchiveExam,
} from "@shared/hooks/query";
import {
  ExamListModel,
  PaginationModel,
  ProjectSwitchItemModel,
  UserModel,
} from "@shared/models";
import { SortOrderItem } from "@shared/models";
import {
  ExamTypeEnums,
  ProjectKind,
  SortDirection,
  TierAction,
} from "@shared/services/enums";
import History from "@shared/services/history";
import Message from "@shared/services/message";
import { isGiveryOrg } from "@shared/services/organization";
import { parseUrl, updateUrl } from "@shared/services/queryString";
import { getNumberArray, getStringArray } from "@shared/services/urlParser";

import { CreationMethodType, ExamSelect } from "../examSelect/ExamSelect";
import {
  ExamListHeader,
  ExamListAction,
  ExamListTableHeader,
  ExamListTableRow,
  ExamCopyModal,
  ExamListColumnName,
} from "./partials";
import ExamListFilterTags, {
  TagKinds,
} from "./partials/ExamListFilterTags/ExamListFilterTags";

interface FilterCheckBoxGroup {
  label: string;
  value: string | number;
  count: number;
}

/**
 * Prop interface
 */
export interface ExternalProps {
  status: string;
  location: { search: string } | undefined;
}

export interface InjectedProps {
  currentUser: UserModel;
  projectId: number;
  currentProject: ProjectSwitchItemModel | undefined;
  loading: boolean;
  isAssessmentProject: boolean;
  isTierActionAllowed: (tierAction: TierAction) => boolean;
}

interface FilterCheckBoxGroup {
  label: string;
  value: string | number;
  disabled?: boolean;
  count: number;
}

interface HookProps {
  purposeOfUseOptions: FilterCheckBoxGroup[];
  jobTitleOptions: FilterCheckBoxGroup[];
  isJobTitleFetched: boolean;
  isPurposeOfUseFetched: boolean;
}

type ExamListProps = ExternalProps & InjectedProps & HookProps;

export default function ExamList({
  loading,
  currentProject,
  currentUser,
  projectId,
  isAssessmentProject,
  status,
}: ExamListProps) {
  const rootStyle = classnames("code-exam-list");
  const { data: purposeMap, isFetched: isPurposeFetched } =
    usePurposeOfUseMap();
  const { data: jobTitleMap, isFetched: isJobTitleFetched } =
    useExamJobTitleMap();
  const {
    column,
    direction,
    keyword: qsKeyword,
    official: qsOfficial,
    multilang: qsMultiLanguage,
    page: qsPage,
    purpose: qsPurposesOfUse,
    job: qsJobTitles,
  } = parseUrl(window.location.search);
  const [sortItem, setSortItem] = React.useState<SortOrderItem>({
    column: ExamListColumnName[column] || undefined,
    direction: SortDirection[direction] || undefined,
  });

  const [keyword, setKeyword] = React.useState(qsKeyword ?? "");
  const [examTypes, setExamTypes] = React.useState([
    ...(Boolean(qsOfficial) ? [ExamTypeEnums.official] : []),
    ...(Boolean(qsMultiLanguage) ? [ExamTypeEnums.multilanguage] : []),
  ]);
  const [official, isMultilanguage] = [
    ExamTypeEnums.official,
    ExamTypeEnums.multilanguage,
  ].map((type) => examTypes.includes(type));
  const [purposesOfUse, setPurposesOfUse] = React.useState<string[]>(
    Array.from(new Set(getStringArray(qsPurposesOfUse) || [])),
  );
  const [jobTitles, setJobTitles] = React.useState<number[]>(
    Array.from(new Set(getNumberArray(qsJobTitles) || [])),
  );
  const [page, setPage] = React.useState(Number(qsPage) || 0);
  const [pagination, setPagination] = React.useState(new PaginationModel());
  const [isOpenCreateExam, setIsOpenCreateExam] = React.useState(false);
  const [isOpenCopyConfirm, setIsOpenCopyConfirm] = React.useState(false);
  const [isOpenDeleteConfirm, setIsOpenDeleteConfirm] = React.useState(false);
  const [selectedExam, setSelectedExam] = React.useState<ExamListModel>();
  const { canCreateMultiLangExams, canCreateOfficialExams, reviewAllowed } =
    useTierActionPermissions();
  const { data } = useExamList({
    filters: {
      archived: status === "inactive",
      keyword,
      official,
      offset: pagination.getAtPage(page).offset,
      sortOrder: sortItem,
      engineerRoles: jobTitles,
      purposesOfUse,
      isMultilanguage,
    },
  });
  const purposeOfUseQuery = usePurposeOfUseOptions(true);
  const jobTitleQuery = useExamJobTitleOptions();
  const deleteExam = useDeleteExam();
  const archiveExam = useArchiveExam();
  const unarchiveExam = useUnarchiveExam();
  const copyExam = useCopyExam();
  const purposeOfUseOptions = purposeOfUseQuery.data as FilterCheckBoxGroup[];
  const jobTitleOptions = jobTitleQuery.data as FilterCheckBoxGroup[];
  const { examList = [], examListPagination = new PaginationModel() } =
    data || {};
  const examTypeOptions = [
    ...(isGiveryOrg(currentUser?.organization?.id)
      ? [
          {
            value: ExamTypeEnums.official,
            label: Message.getMessageByKey("exam.officialExam"),
            count: 0,
          },
        ]
      : []),
    {
      value: ExamTypeEnums.multilanguage,
      label: Message.getMessageByKey("exam.multilanguage"),
      count: 0,
    },
  ];

  useDidUpdateEffect(() => {
    // reset all states when switch between active and inactive or pathname changes
    setKeyword("");
    setExamTypes([]);
    setPurposesOfUse([]);
    setJobTitles([]);
    setPage(0);
  }, [window.location.pathname]);

  useDidUpdateEffect(() => {
    updateUrl({
      page: page || undefined,
      keyword: keyword || undefined,
      official: official || undefined,
      multilang: isMultilanguage || undefined,
      purpose: purposesOfUse,
      job: jobTitles,
      ...sortItem,
    });
  }, [
    page,
    keyword,
    official,
    isMultilanguage,
    purposesOfUse,
    jobTitles,
    sortItem,
  ]);

  React.useEffect(() => {
    if (isPurposeFetched) {
      setPurposesOfUse((prev) => prev.filter((value) => purposeMap?.[value]));
    }
  }, [isPurposeFetched, purposeMap]);

  React.useEffect(() => {
    if (isJobTitleFetched) {
      setJobTitles((prev) => prev.filter((value) => jobTitleMap?.[value]));
    }
  }, [isJobTitleFetched, jobTitleMap]);

  React.useEffect(() => {
    if (!isEqual(examListPagination, pagination)) {
      setPagination(examListPagination);
    }
  }, [examListPagination, pagination]);

  const onSearchChange = (
    formValid: boolean,
    formValues: { keyword: string },
    formErrors: {},
  ) => {
    setKeyword(formValues.keyword);
    setPage(0);
  };

  const onFilterChange = (
    formValid: boolean,
    formValues: {
      examTypes: ExamTypeEnums[];
      purposesOfUse: string[];
      jobTitles: number[];
    },
    formErrors: {},
  ) => {
    const {
      examTypes,
      purposesOfUse: purposeValues,
      jobTitles: jobValues,
    } = formValues;

    setExamTypes(examTypes);
    setPurposesOfUse(purposeValues);
    setJobTitles(jobValues);
    setPage(0);
  };

  const onOpenCreateExamModal = () => {
    setIsOpenCreateExam(true);
  };

  const onSelect = (
    creationMethodType: CreationMethodType,
    enableMultiLanguage: boolean,
  ) => {
    const nextPaths: Record<CreationMethodType | "multi", string> = {
      [CreationMethodType.official]: "official",
      [CreationMethodType.new]: "new",
      [CreationMethodType.talenthub]: "talenthub",
      multi: "new-multi",
    };
    const pathKey =
      creationMethodType === CreationMethodType.new && enableMultiLanguage
        ? "multi"
        : creationMethodType;
    History.push(`/p/${projectId}/exams/${nextPaths[pathKey]}`);
  };

  const onPageChange = ({ selected }: { selected: number }) => {
    setPage(selected);
  };

  const onSortChange = (sortItem: SortOrderItem) => {
    setSortItem(sortItem);
    setPage(0);
  };

  const onCloseModal = () => {
    setIsOpenCreateExam(false);
    setIsOpenCopyConfirm(false);
    setIsOpenDeleteConfirm(false);
    setSelectedExam(undefined);
  };

  const onCopyExam = (options: {
    copyReviewer: boolean;
    projectId: number;
  }) => {
    if (selectedExam) {
      copyExam.mutate({
        examId: selectedExam.id,
        copyReviewer: options.copyReviewer,
        targetProjectId: options.projectId,
      });
      onCloseModal();
    }
  };

  const onDeleteExam = () => {
    if (selectedExam) {
      deleteExam.mutate(selectedExam.id);
      onCloseModal();
    }
  };

  const handleClearAllFilterTags = () => {
    setExamTypes([]);
    setPurposesOfUse([]);
    setJobTitles([]);
    setPage(0);
  };

  const handleFilterTagsChanges = (type: TagKinds, value: number | string) => {
    switch (type) {
      case "official":
        setExamTypes((prev) =>
          prev.filter((item) => item !== ExamTypeEnums.official),
        );
        break;
      case "multilanguage":
        setExamTypes((prev) =>
          prev.filter((item) => item !== ExamTypeEnums.multilanguage),
        );
        break;
      case "purposeOfUse":
        setPurposesOfUse((prev) => prev.filter((item) => item !== value));
        break;
      case "jobTitle":
        setJobTitles((prev) => prev.filter((item) => item !== value));
        break;
    }
  };

  return (
    <div className={rootStyle}>
      <Loading isOpen={loading} />
      <BreadcrumbsItem to={`/p/${projectId}/exams`}>
        <Msg id={"common.examList"} />
      </BreadcrumbsItem>
      <DocumentTitle page={Message.getMessageByKey("common.examList")} />

      <SubMenuContainer>
        <SubMenuContainerLeft>
          <ContentsSearchForm
            className="code-exam-list__keyword"
            onChange={onSearchChange}
            initialValues={{ keyword }}
            keywordHint={<Msg id="exam.keyword.hint" />}
          />
          <SubMenu className="code-exam-list__status">
            <SubMenuLabel>
              <Msg id="status" />
            </SubMenuLabel>
            <SubMenuList>
              <SubMenuItem
                label={<Msg id="common.active" />}
                route={{ pathname: `/p/${projectId}/exams/active` }}
              />
              <SubMenuItem
                label={<Msg id="common.inactive" />}
                route={{ pathname: `/p/${projectId}/exams/inactive` }}
              />
            </SubMenuList>
          </SubMenu>
          <ContentsSearchForm
            className="code-exam-list__filter"
            onChange={onFilterChange}
            noKeyword
            initialValues={{
              examTypes,
              purposesOfUse,
              jobTitles,
            }}
            filters={[
              {
                title: <Msg id="exam.filter.types" />,
                value: "examTypes",
                options: examTypeOptions,
              },
              {
                title: <Msg id="exam.filter.purpose" />,
                value: "purposesOfUse",
                options: purposeOfUseOptions,
              },
              {
                title: <Msg id="exam.filter.jobTitle" />,
                value: "jobTitles",
                options: jobTitleOptions,
              },
            ]}
          />
        </SubMenuContainerLeft>
        <SubMenuContainerRight>
          <ExamListHeader
            count={pagination.count}
            status={status}
            onClickNewExam={onOpenCreateExamModal}
          />
          <ExamListFilterTags
            isMultilanguage={isMultilanguage}
            jobTitles={jobTitles}
            official={official}
            purposesOfUse={purposesOfUse}
            onClearAll={handleClearAllFilterTags}
            onFilterChange={handleFilterTagsChanges}
          />
          <Table className="code-exam-list__table">
            <ExamListTableHeader
              status={status}
              sortItem={sortItem}
              onClickSortItem={onSortChange}
            />
            {examList.map((exam: ExamListModel) => (
              <ExamListTableRow
                key={exam.id}
                status={status}
                projectId={projectId}
                exam={exam}
                currentUser={currentUser}
                keyword={keyword}
                actionMenu={
                  <ExamListAction
                    status={status}
                    disableArchive={Boolean(exam.pendingDeliveryCount)}
                    canDeliver={exam.canDeliver && isAssessmentProject}
                    canCopy={exam.canDeliver}
                    onClickCopy={() => {
                      setSelectedExam(exam);
                      setIsOpenCopyConfirm(true);
                    }}
                    onClickDelivery={() =>
                      History.push(
                        `/p/${projectId}/exams/${exam.id}/deliveries/new`,
                      )
                    }
                    onClickDelete={() => {
                      setIsOpenDeleteConfirm(true);
                      setSelectedExam(exam);
                    }}
                    onClickArchive={() => archiveExam.mutate(exam.id)}
                    onClickUnArchive={() => unarchiveExam.mutate(exam.id)}
                    extraMessages={{
                      disableDelivery: isAssessmentProject ? undefined : (
                        <Msg
                          id="exam.delivery.not.supported.project"
                          values={{
                            name: ProjectKind.toServiceName(
                              currentProject?.kind,
                            ),
                          }}
                        />
                      ),
                    }}
                  />
                }
              />
            ))}
          </Table>
          <div className="code-exam-list__pagination">
            <Pagination pagination={pagination} onPageChange={onPageChange} />
          </div>
        </SubMenuContainerRight>
      </SubMenuContainer>
      {isOpenCopyConfirm && selectedExam && currentProject && (
        <ExamCopyModal
          exam={selectedExam}
          onCopy={onCopyExam}
          onCancel={onCloseModal}
          defaultProject={currentProject}
          userRoles={currentUser.roles}
          projects={currentUser.projects}
          canCopyReviewers={reviewAllowed}
        />
      )}
      {isOpenDeleteConfirm && selectedExam && (
        <Modal
          title={Message.getMessageByKey("confirm.delete")}
          isOpen={true}
          onClose={onCloseModal}
          onClickOk={onDeleteExam}
          onClickCancel={onCloseModal}
          okButtonLabel={Message.getMessageByKey("delete")}
          okButtonAriaLabel="Delete"
          okButtonType="danger"
          ariaLabel="Delete Exam"
        >
          <div className="code-exam-list__modal-body">
            <Msg id="confirmDelete.message" />
            <div>
              <Label>
                <Msg id="form.exam.name" />
              </Label>
              <div className="is-wrap is-bold">{selectedExam.name}</div>
            </div>
          </div>
        </Modal>
      )}
      {isOpenCreateExam && (
        <ExamSelect
          isOpen={true}
          onCloseModal={onCloseModal}
          onSelect={onSelect}
          canCreateOfficialExams={canCreateOfficialExams}
          canCreateMultiLangExams={canCreateMultiLangExams}
          projectKind={currentProject?.kind}
        />
      )}
    </div>
  );
}
