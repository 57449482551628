import { RefObject, useCallback, useEffect, useState } from "react";

import { MESSAGE_EVENT_READY } from "@shared/services/events";
import Logger from "@shared/services/logger";

export const useIframeCommunication = ({
  ref,
  useDangerousPostMessage,
  onMessage,
}: {
  ref: RefObject<HTMLIFrameElement>;
  useDangerousPostMessage?: boolean;
  onMessage?: (e: MessageEvent) => void;
}) => {
  const [ready, setReady] = useState(false);

  const handleMessage = useCallback(
    (e: MessageEvent) => {
      if (e?.data && "name" in e.data) {
        if (e.data.name === MESSAGE_EVENT_READY) {
          setReady(true);
        }

        if (typeof onMessage === "function") {
          onMessage(e);
        }
      }
    },
    [onMessage],
  );

  const postMessage = useCallback(
    (name: string, data: {}) => {
      if (ref.current !== null) {
        ref.current.contentWindow?.postMessage(
          {
            name,
            data,
          },
          useDangerousPostMessage ? "*" : "/",
        );
        Logger.info("postMessage", {
          name,
          data,
        });
      }
    },
    [ref, useDangerousPostMessage],
  );

  useEffect(() => {
    if (ref.current !== null) {
      window.addEventListener("message", handleMessage);
    } else {
      setReady(false); // when iframe is re-mounted, we need to wait for another ready message
    }

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [ref.current, handleMessage]);

  return { postMessage, ready };
};
