import { chunk } from "lodash";
import { useCallback, useMemo, useState } from "react";

import { useSubmissionListBatch } from "@shared/hooks/query";
import { PaginationModel, SortOrderItem } from "@shared/models";
import { DEFAULT_PAGINATION_LIMIT } from "@shared/models/Pagination.model";
import { SortDirection } from "@shared/services/enums";

// 50 seems to be max number of items we can query in our submission list pagination api
const SUBMISSION_LIST_QUERY_LIMIT = 50;

interface UseIpSubmissionListBatchProps {
  isNameRequired?: boolean;
  isEnabled?: boolean;
  applicantExamIds?: number[];
  ipFilter?: string;
}

/**
 * To get all submissions related to an ip, an array of applicantExamIds needs to be passed to the submissionList GET api.
 * All the applicantExamIds needs to be passed in for the sorting to work properly otherwise the data returned would be incomplete.
 * Since GET endpoints have a max character limit, only 70 applicantExamIds can be passed in at a time.
 * Unfortunately, the max number of number applicant ids in prod at the moment is 652, so passing everything via the array is not possible
 * As a fix, I thought of doing this janky way of retrieving all of the submissions in batches of 50, concatenate them then doing some
 * manual sorting and pagination depending on the table state.
 * So at the maximum, about 14 calls will be made to backend when the modal is opened.
 *
 * NOTE:
 * As of November 29, 2023, max number of duplicate Ips in a single exam is 652
 * About 25 exams are above the 70 applicantExamId limit so this workaround should suffice
 * Why was it done this way? because not worth the effort to create a new endpoint.
 * If we do want to improve on this, a submissionList POST method API to be made.
 */
export const useIpSubmissionListBatch = ({
  isEnabled = true,
  applicantExamIds,
  ipFilter,
}: UseIpSubmissionListBatchProps) => {
  const applicantLength = applicantExamIds?.length ?? 0;

  // get exam ids in batches of 50
  const slicedApplicantExamIds = useMemo(
    () =>
      applicantExamIds
        ? chunk(applicantExamIds, SUBMISSION_LIST_QUERY_LIMIT)
        : [],
    [applicantExamIds],
  );

  const submissionListData = useSubmissionListBatch({
    isEnabled: isEnabled && !!slicedApplicantExamIds.length,
    filters: slicedApplicantExamIds.map((applicantExamIds) => ({
      ...(applicantExamIds && { applicantExamIds }),
      keyword: "",
      limit: SUBMISSION_LIST_QUERY_LIMIT,
      includeOverlappingIps: true,
    })),
  });

  // manual pagination and sorting section here
  const [currentPage, setCurrentPage] = useState(0);
  const [sortOrder, setSortOrder] = useState<SortOrderItem>({
    column: "submittedAt",
    direction: SortDirection.Desc,
  });

  const data = useMemo(
    () => ({
      submissionList: [...submissionListData]
        .filter((submission) =>
          ipFilter
            ? submission.overlappingIpAddresses?.allApplicantRequestIps.includes(
                ipFilter,
              )
            : submission,
        )
        .sort((a, b) => {
          const valueA = a[sortOrder.column];
          const valueB = b[sortOrder.column];

          const isAscending = sortOrder.direction === SortDirection.Asc;
          if (typeof valueA === "string" && typeof valueB === "string") {
            return isAscending
              ? valueA.localeCompare(valueB)
              : valueB.localeCompare(valueA);
          }

          return isAscending ? valueA - valueB : valueB - valueA;
        })
        .slice(
          DEFAULT_PAGINATION_LIMIT * currentPage,
          DEFAULT_PAGINATION_LIMIT * (currentPage + 1),
        ),
      submissionPagination: new PaginationModel({
        count: applicantLength,
        limit: DEFAULT_PAGINATION_LIMIT,
        offset: currentPage * DEFAULT_PAGINATION_LIMIT,
      }),
    }),
    [currentPage, submissionListData, applicantLength, sortOrder, ipFilter],
  );

  const customSetSortItem = useCallback((newSortItem: SortOrderItem) => {
    setSortOrder(newSortItem);
    setCurrentPage(0);
  }, []);

  return {
    data,
    currentPage,
    setCurrentPage,
    sortOrder,
    setSortOrder: customSetSortItem,
  };
};
