import { connect } from "react-redux";

import { RootState } from "@reducers";

import { getAppBasePath } from "@shared/selectors";

import CollapsibleExamChallengeColumn, {
  ExternalProps,
} from "./CollapsibleExamChallengeColumn";

const mapStateToProps = (state: RootState, props: ExternalProps) => ({
  ...props,
  programmingLanguages: state.challenge.challengeFilters.programmingLanguages,
  appBasePath: getAppBasePath(state),
});

/**
 * Wrapped component
 */
const wrappedComponent = connect(mapStateToProps, () => ({}))(
  CollapsibleExamChallengeColumn,
);

export default wrappedComponent;
