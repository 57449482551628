import * as classnames from "classnames";

import { Avatar } from "..";
import { MemberListModel } from "../../models";

/**
 * Prop interface
 */
export interface MemberTitleColumnProps {
  onClick?: (member: MemberListModel) => void;
  className?: string;
  member?: MemberListModel;
}

/**
 * React Component
 */
export default function MemberTitleColumn({
  onClick,
  className,
  member,
}: MemberTitleColumnProps) {
  const rootStyle = classnames("code-c-member-title-column", {
    [`${className}`]: Boolean(className),
  });

  return (
    <div className={rootStyle}>
      <Avatar src={member && member.imageUrl} />
      <div className="code-c-member-title-column__container">
        {onClick ? (
          <a
            className="code-c-member-title-column__link is-bold"
            onClick={() => onClick && member && onClick(member)}
          >
            {member && member.name}
          </a>
        ) : (
          <span className="code-c-member-title-column__link is-bold">
            {member && member.name}
          </span>
        )}
        <div className="code-c-member-title-column__info">
          <small>{member && member.email}</small>
        </div>
      </div>
    </div>
  );
}
