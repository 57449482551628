import * as classnames from "classnames";

import { TagItem, Tag, Msg } from "..";
import { EnumModel } from "../../models";
import {
  QuestionCategory,
  SpokenLanguages,
  Difficulty,
  ChallengeStyle,
} from "../../services/enums";
import Message from "../../services/message";

/**
 * Prop interface
 */
export interface ExternalProps {
  conditions: Partial<{
    styles: number[];
    difficulties: number[];
    categories: number[];
    spokenLanguages: string[];
    isLinked: string;
    onlyNew: string;
    onlyPinned: string;
    programmingCategories: number[];
    programmingLanguages: number[];
  }>;
  onChange: (conditions: {}) => void;
  className?: string;
}

export interface InjectedProps {
  programmingLanguages: EnumModel[];
  programmingCategories: EnumModel[];
}

export type FilterConditionsProps = ExternalProps & InjectedProps;

const RemovalPropertyNames = [
  "styles",
  "difficulties",
  "categories",
  "spokenLanguages",
  "isLinked",
  "onlyNew",
  "onlyPinned",
  "programmingCategories",
  "programmingLanguages",
];

/**
 * React Component
 */
export default function FilterConditions({
  conditions,
  onChange,
  programmingLanguages,
  programmingCategories,
  className,
}: FilterConditionsProps) {
  const rootStyle = classnames("code-c-filter-conditions tags", {
    [`${className}`]: Boolean(className),
  });

  const tagItems: TagItem[] = [];

  /**
   * Look up the label name from the dictionary
   * @param value
   * @param dictionary
   */
  const lookUpDictionary = (
    value: number,
    dictionary: EnumModel[] = [],
  ): string =>
    dictionary.find((item) => item.value === value)?.displayString ||
    value.toString();

  /**
   * Remove condition from the list
   * @param value
   * @param items
   */
  const removeCondition = <T extends string | number>(
    value: T,
    items: T[] = [],
  ) => items.filter((item) => item !== value);

  /**
   * Clear all conditions that only contain in the removal list
   */
  const clearAll = () => {
    onChange(
      Object.keys(conditions)
        .filter((key) => !RemovalPropertyNames.includes(key))
        .reduce(
          (result, key) => ({
            ...result,
            [`${key}`]: conditions[key],
          }),
          {},
        ),
    );
  };

  if (conditions.styles) {
    tagItems.push(
      ...[...conditions.styles].sort().map((item) => ({
        label: ChallengeStyle.toString(item),
        onClick: () =>
          onChange({
            ...conditions,
            styles: removeCondition(item, conditions.styles),
          }),
      })),
    );
  }

  if (conditions.difficulties) {
    tagItems.push(
      ...[...conditions.difficulties].sort().map((item) => ({
        label: Difficulty.toString(item),
        onClick: () =>
          onChange({
            ...conditions,
            difficulties: removeCondition(item, conditions.difficulties),
          }),
      })),
    );
  }

  if (conditions.categories) {
    tagItems.push(
      ...[...conditions.categories].sort().map((item) => ({
        label: QuestionCategory.toString(item),
        onClick: () =>
          onChange({
            ...conditions,
            categories: removeCondition(item, conditions.categories),
          }),
      })),
    );
  }

  if (conditions.spokenLanguages) {
    tagItems.push(
      ...[...conditions.spokenLanguages].sort().map((item) => ({
        label: SpokenLanguages.toString(item),
        onClick: () =>
          onChange({
            ...conditions,
            spokenLanguages: removeCondition(item, conditions.spokenLanguages),
          }),
      })),
    );
  }

  if (conditions.isLinked === "true") {
    tagItems.push({
      label: Message.getMessageByKey("challenge.linked"),
      onClick: () => onChange({ ...conditions, isLinked: undefined }),
    });
  }

  if (conditions.onlyNew === "true") {
    tagItems.push({
      label: Message.getMessageByKey("challenge.new"),
      onClick: () => onChange({ ...conditions, onlyNew: undefined }),
    });
  }

  if (conditions.onlyPinned === "true") {
    tagItems.push({
      label: Message.getMessageByKey("challenge.filter.pinned"),
      onClick: () => onChange({ ...conditions, onlyPinned: undefined }),
    });
  }

  if (conditions.programmingCategories) {
    tagItems.push(
      ...[...conditions.programmingCategories].sort().map((item) => ({
        label: lookUpDictionary(item, programmingCategories),
        onClick: () =>
          onChange({
            ...conditions,
            programmingCategories: removeCondition(
              item,
              conditions.programmingCategories,
            ),
          }),
      })),
    );
  }

  if (conditions.programmingLanguages) {
    tagItems.push(
      ...[...conditions.programmingLanguages].sort().map((item) => ({
        label: lookUpDictionary(item, programmingLanguages),
        onClick: () =>
          onChange({
            ...conditions,
            programmingLanguages: removeCondition(
              item,
              conditions.programmingLanguages,
            ),
          }),
      })),
    );
  }

  if (tagItems.length === 0) {
    return null;
  }

  return (
    <div className={rootStyle}>
      <div className="code-c-filter-conditions__clear-all">
        <a role="button" onClick={clearAll}>
          <Msg id="clear.all" />
        </a>
        <span className="code-c-filter-divider">|</span>
      </div>
      {tagItems.map((item, index) => (
        <Tag
          key={`condistion-${index}`}
          hasDelete={true}
          onClick={item.onClick}
        >
          {item.label}
        </Tag>
      ))}
    </div>
  );
}
