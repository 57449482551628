import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { postSubmissionReset } from "@api/submissions";

import { useStoreContext } from "@context";

import { ResetSubmissionsParamsModel } from "@shared/models/SubmissionList.model";
import Message from "@shared/services/message";

import {
  useInvalidateExamCountRelation,
  useInvalidateExamDashboardChart,
} from "../exams";
import { useRouteParams } from "../useRouteParams";
import { useInvalidateSubmissionRelation } from "./useSubmission";

export function useResetSubmission() {
  const { projectId, user } = useStoreContext();
  const { examId } = useRouteParams();
  const organizationName = user?.organization?.name || "";
  const invalidateSubmission = useInvalidateSubmissionRelation();
  const invalidateExamCount = useInvalidateExamCountRelation();
  const invalidateExamDashboard = useInvalidateExamDashboardChart();

  const mutation = useMutation({
    mutationFn: (data: ResetSubmissionsParamsModel) =>
      postSubmissionReset({
        examId,
        organizationName,
        projectId,
        options: { data },
      }),
    onSuccess: () => {
      toast.success(
        Message.getMessageByKey("message.submissions.reset.success"),
      );
      invalidateExamCount(examId);
      invalidateSubmission();
      invalidateExamDashboard();
    },
    onError: () => {
      toast.error(Message.getMessageByKey("message.submissions.reset.failed"));
    },
  });

  return mutation;
}
