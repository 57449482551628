import * as classnames from "classnames";
import * as React from "react";

interface CardProps {
  children?: React.ReactNode;
  className?: string;
  direction?: "row" | "column";
  onClick?: () => void;
}

export default function Card({
  children,
  className,
  direction = "row",
  onClick,
}: CardProps) {
  const rootStyle = classnames("code-c-card", className, {
    "code-c-card--clickable": Boolean(onClick),
  });
  const contentStyle = classnames("code-c-card__content", {
    "code-c-card__content--row": direction === "row",
    "code-c-card__content--column": direction === "column",
  });

  const handleClick = () => {
    onClick?.();
  };

  return (
    <div className={rootStyle} onClick={handleClick}>
      <div className={contentStyle}>{children}</div>
    </div>
  );
}
