import * as classnames from "classnames";
import * as React from "react";

import { useScrollPosition } from "@shared/hooks";
import {
  SubmissionResultListModel,
  SubmissionResultDetailModel,
} from "@shared/models";
import { formatTimeLeftString } from "@shared/services/date";
import {
  ApplicantExamStatus,
  ChallengeResultStatus,
  ChallengeStyle,
} from "@shared/services/enums";
import Message from "@shared/services/message";
import {
  getErrorMessage,
  isScoreValid,
  hasTimeExtension,
  getRoundedRawScore,
} from "@shared/services/score";
import { getChallengeResultPreviewUrl } from "@shared/services/url";

import {
  ScoreStatusBadge,
  Icon,
  QuickHelp,
  Msg,
  JumpTo,
  SubmissionDetail,
  ProgressCircle,
  Tooltip,
} from "../..";
import CodeDiffButton from "../codeDiffButton";
import PlaybackButton from "../playbackButton";

/**
 * Prop interface
 */
export type ExternalProps = {
  applicantExamStatus: ApplicantExamStatus;
  submissionResultList: SubmissionResultListModel;
  submissionResultDetail?: SubmissionResultDetailModel;
  isOpen: boolean;
  onClick: () => void;
  onClickRecalculate?: (challengeResult: SubmissionResultDetailModel) => void;
  canViewCodePlayback?: boolean;
  canViewCodeDiff?: boolean;
  className?: string;
};

export type InjectedProps = {};

type SubmissionDetailSubHeaderProps = ExternalProps & InjectedProps;
/**
 * React Component
 */
export const SubmissionDetailSubHeader: React.FunctionComponent<
  SubmissionDetailSubHeaderProps
> = (props: SubmissionDetailSubHeaderProps) => {
  const {
    applicantExamStatus,
    submissionResultList,
    submissionResultDetail = new SubmissionResultDetailModel(),
    isOpen,
    className,
    canViewCodePlayback,
    canViewCodeDiff,
  } = props;

  const {
    score,
    timeSpentSeconds,
    challengeResultStatus,
    style: challengeStyle,
  } = submissionResultList;

  const {
    totalTestcases,
    style,
    status,
    rawScore,
    scoreStatus,
    successfulTestcases: displaySuccessfulTestcases = 0,
    totalTestcases: displayTotalTestcases = "-",
    codingResult,
    extensions,
    challengeId,
    unstableReason,
  } = submissionResultDetail;

  /**
   * State
   */
  const headerRef = React.useRef<HTMLDivElement | null>(null);
  const { capturePosition, resetPosition } = useScrollPosition(headerRef);

  /**
   * Effects
   */
  React.useEffect(() => {
    resetPosition();
  }, [resetPosition, isOpen]);

  /**
   * Private Functions
   */
  const rootStyle = classnames("code-c-submission-detail-sub-header", {
    [`${className}`]: Boolean(className),
  });

  const openTestcases = codingResult?.testOutputJson?.openTestcases || [];
  const notModified =
    !challengeResultStatus ||
    challengeResultStatus === ChallengeResultStatus.NotModified;
  const timeExtended = hasTimeExtension(extensions, challengeId);

  return (
    <div
      className={rootStyle}
      role="button"
      onClick={() => {
        capturePosition();
        props.onClick();
      }}
      ref={headerRef}
      aria-pressed={isOpen}
      aria-label="Toggle Submission Detail"
    >
      <SubmissionDetail.SubmissionDetailChallengeTitle
        isOpen={isOpen}
        applicantExamStatus={applicantExamStatus}
        submissionResultList={submissionResultList}
      />
      <div className="code-c-submission-detail-sub-header__score">
        {getErrorMessage(score, totalTestcases) || (
          <ProgressCircle
            className="code-c-submission-detail-sub-header__score-chart"
            size="medium"
            value={score}
          />
        )}
      </div>
      <div className="code-c-submission-detail-sub-header__correct">
        {isScoreValid(score, totalTestcases) ? (
          notModified ? (
            "--"
          ) : style === ChallengeStyle.AI ? (
            <div className="code-c-submission-detail-sub-header__ai-test-case">
              <span className="code-c-submission-detail-sub-header__correct-number">
                {getRoundedRawScore(rawScore)}
              </span>
              <ScoreStatusBadge unstableReason={unstableReason} />
              <QuickHelp
                text={Message.getMessageByKey("submission.ai.scoreNote")}
                maxSize="xlarge"
              />
            </div>
          ) : (
            <>
              <div className="code-c-submission-detail-sub-header__total-test-case">
                <div>
                  <ScoreStatusBadge
                    challengeResultStatus={challengeResultStatus}
                    challengeStyle={challengeStyle}
                    scoreStatus={scoreStatus}
                    unstableReason={unstableReason}
                  />
                  <span className="code-c-submission-detail-sub-header__correct-number">
                    {`${displaySuccessfulTestcases} / ${displayTotalTestcases}`}
                  </span>
                </div>
                {typeof props.onClickRecalculate === "function" && (
                  <SubmissionDetail.RecalculateScoreButton
                    style={style}
                    status={status}
                    onClick={() =>
                      props.onClickRecalculate?.(submissionResultDetail)
                    }
                  />
                )}
              </div>
              {openTestcases?.length > 0 && (
                <div className="code-c-submission-detail-sub-header__public-test-case">
                  <span className="public-test-case-label">
                    <Msg id="challenge.testcases.open" />:
                  </span>
                  <span>
                    {`${openTestcases?.filter((testcase) => testcase.ok)
                      .length} / ${openTestcases?.length}`}
                  </span>
                </div>
              )}
            </>
          )
        ) : (
          <div className="code-c-submission-detail-sub-header__score-invalid">
            <Icon
              type="exclamation-triangle"
              className="code-c-submission-detail-sub-header__score-invalid-icon"
            />
            <Msg id="error.contactSupportTeam" />
          </div>
        )}
      </div>
      <div className="code-c-submission-detail-sub-header__time-spent">
        {timeSpentSeconds ? (
          <>
            {formatTimeLeftString(timeSpentSeconds)}
            {timeExtended && (
              <Tooltip
                text={Message.getMessageByKey(
                  "mechange-deadline.timelimit.reset",
                )}
              >
                <span className="code-c-icon icon">
                  <i className="code-c-submission-detail-sub-header__time-extended-icon" />
                </span>
              </Tooltip>
            )}
          </>
        ) : (
          "--"
        )}
      </div>
      <div className="code-c-submission-detail-sub-header__preview">
        {ChallengeStyle.isCodingTypeChallenge(style) && (
          <>
            <Tooltip
              text={Message.getMessageByKey("action.openViewer")}
              placement="top-end"
            >
              <JumpTo
                to={getChallengeResultPreviewUrl(
                  submissionResultDetail,
                  window.location,
                )}
                options={{ stopPropagation: true }}
                className="button is-small is-nomedia"
              >
                <Icon type="code" />
              </JumpTo>
            </Tooltip>
            {canViewCodeDiff && (
              <CodeDiffButton submissionResultDetail={submissionResultDetail} />
            )}

            {canViewCodePlayback && (
              <PlaybackButton resultDetail={submissionResultDetail} />
            )}
          </>
        )}
      </div>
    </div>
  );
};
