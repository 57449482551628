import { formatDateTimeMinutes } from "@shared/services/date";
import MSG from "@shared/services/message";

import { ChallengeResultSettings, SaveHistory } from "./settings.model";

export const getChallengeResultDisplayName = (
  challengeResultPreview?: ChallengeResultSettings,
) => {
  if (
    !challengeResultPreview ||
    ["guest", "guestFullAccess"].includes(
      challengeResultPreview?.context.mode || "",
    )
  ) {
    return "";
  }

  const { applicantName, applicantEmail } = challengeResultPreview;

  return applicantName ? `${applicantName}(${applicantEmail})` : applicantEmail;
};

export const getPreviewSelectOptions = (
  saveHistoryArr: SaveHistory[],
  highestSuccessfulTestcases: number,
) => {
  let highestScoreIndex = -1;

  const newOptions = saveHistoryArr.map(
    ({ id, createdAt, successfulTestcases }, idx) => {
      const datetime = formatDateTimeMinutes(createdAt);

      if (
        successfulTestcases === highestSuccessfulTestcases &&
        highestScoreIndex === -1
      ) {
        highestScoreIndex = idx;
      }

      return {
        value: id,
        label: `${datetime} ${MSG.getMessageByKey(
          "score",
        )}: ${successfulTestcases}`,
      };
    },
  );

  // put option with highest score to start of array
  if (highestScoreIndex !== -1) {
    const option = newOptions.splice(highestScoreIndex, 1)[0];
    newOptions.unshift(option);
  }

  return newOptions;
};
