import * as classnames from "classnames";
import * as React from "react";

import { Markdown, Msg } from "../..";

/**
 * Prop interface
 */
export type ApplicantExplanationProps = {
  applicantExplanation?: string;
  className?: string;
};

/**
 * React Component
 */
export const ApplicantExplanation: React.FunctionComponent<
  ApplicantExplanationProps
> = (props: ApplicantExplanationProps) => {
  const { applicantExplanation, className } = props;

  /**
   * State
   */

  /**
   * Effects
   */

  /**
   * Private Functions
   */
  const rootStyle = classnames("code-c-applicant-explanation", {
    [`${className}`]: Boolean(className),
  });

  return (
    <div className={rootStyle}>
      {applicantExplanation ? (
        <Markdown body={applicantExplanation} />
      ) : (
        <Msg id="submission.noApplicantExplanation" />
      )}
    </div>
  );
};
