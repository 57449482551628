import { useMutation, useQuery } from "@tanstack/react-query";

import {
  getGuestSubmissionResultLocalFile,
  getGuestSubmissionResultLocalFilesMetadata,
} from "@api/guest";
import { ApiResponse } from "@api/httpClient";

import { useParams } from "@context";

import { LocalFileMetaData } from "@shared/models";
import { getLastWeekTimestamp } from "@shared/services/date";
import { FileUploadConfig } from "@shared/services/fileUpload";

interface GuestSubmissionResultLocalFilesMetaDataAllKeyParams {
  challengeResultId: number;
  token: string;
}

interface GuestDownloadSubmissionResultLocalFileParams {
  challengeResultId: number;
  filename: string;
}

const guestResultKeys = {
  all: ["guest", "results"] as const,
};

export const guestSubmissionResultLocalFilesKeys = {
  all: [...guestResultKeys.all, "localFiles"] as const,
  localFilesMetadataAll: ({
    challengeResultId,
    token,
  }: GuestSubmissionResultLocalFilesMetaDataAllKeyParams) => [
    ...guestSubmissionResultLocalFilesKeys.all,
    token,
    challengeResultId,
  ],
};

export function useGetGuestSubmissionResultLocalFilesMetadata({
  challengeResultId,
  fileUploadConfig,
}: {
  challengeResultId: number;
  fileUploadConfig?: FileUploadConfig;
}) {
  const { token = "" } = useParams();

  const enabled =
    Boolean(challengeResultId) &&
    Boolean(fileUploadConfig?.uploadEnabled) &&
    Boolean(token);

  const query = useQuery<LocalFileMetaData[]>({
    queryKey: guestSubmissionResultLocalFilesKeys.localFilesMetadataAll({
      challengeResultId,
      token,
    }),
    queryFn: async ({ signal }) => {
      const { result } = await getGuestSubmissionResultLocalFilesMetadata({
        challengeResultId,
        token,
        options: {
          signal,
        },
      });

      return result;
    },
    enabled,
    initialData: [],
    initialDataUpdatedAt: getLastWeekTimestamp(),
    keepPreviousData: true,
  });

  return query;
}

export function useGuestDownloadSubmissionResultLocalFile() {
  const { token = "" } = useParams();

  const mutation = useMutation({
    mutationFn: ({
      challengeResultId,
      filename,
    }: GuestDownloadSubmissionResultLocalFileParams) =>
      getGuestSubmissionResultLocalFile({
        challengeResultId,
        token,
        options: {
          params: { filename },
        },
      }),
    onSuccess: ({ result }: ApiResponse<string>) => {
      window.location.href = result;
    },
  });

  return mutation;
}
