import { AxiosRequestConfig } from "axios";

import { CustomFormModel } from "@shared/models";

import { get } from "../httpClient";

export async function getSubmissionFormValues(
  projectId: number,
  submissionId: number,
  options?: AxiosRequestConfig,
) {
  const { data } = await get<CustomFormModel[]>(
    `/api/projects/${projectId}/submissions/${submissionId}/formvalues`,
    options,
  );

  return data;
}
