import { connect } from "react-redux";
import { Action, Dispatch } from "redux";

import {
  challengeGetAction,
  challengeUpdateAction,
  challengeCopyAction,
} from "@actions";

import { RootState } from "@reducers";

import { ChallengeModel } from "@shared/models";

import { ChallengeActionModals, ExternalProps } from "./ChallengeActionModals";

const mapStateToProps = (state: RootState, props: ExternalProps) => {
  const {
    challengeDetailList,
    challengeFilters,
    copyChallengeLoading,
    loadingList,
    loadingCreate,
  } = state.challenge;

  return {
    loading: loadingList || loadingCreate || copyChallengeLoading,
    challengeDetailList,
    challengeFilters,
    ...props,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  copyChallenge: (challenge: ChallengeModel) => {
    dispatch(challengeCopyAction.request(challenge));
  },
  updateChallenge: (id: number, params: {}) => {
    dispatch(challengeUpdateAction.request(id, params));
  },
  getChallenge: (payload: {}) => {
    dispatch(challengeGetAction.request(payload));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ChallengeActionModals);
