import { CsvTypes } from "@shared/services/enums";

import EnumModel from "./Enum.model";

export class CsvColumnAPIModel extends EnumModel {
  groupKey: string;
  groupString: string;
}

export type CsvColumnGroupModel = {
  groupKey: string;
  groupString: string;
  columns: CsvColumnAPIModel[];
};

export type CsvColumnsGroupsByCsvTypeModel = Partial<
  Record<CsvTypes, CsvColumnGroupModel[]>
>;
export type LoadingCsvColumnGroupsModel = Partial<Record<CsvTypes, boolean>>;
