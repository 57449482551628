import { ExamChallengeModel, SubmissionResultListModel } from "../models";

/**
 * get masked minor version
 *
 * ex) 2.4 -> 2.X
 *
 * @param currentVersion
 */
export function getMaskedVersion(currentVersion?: string) {
  if (!currentVersion) {
    return "";
  }
  const [major = "0"] = (currentVersion || "0.0").split(".");
  return [major, "X"].join(".");
}

/**
 * Compares the major version of a submission and exam
 * Assumes challenge id exists in both inputs
 * Returns true if challenge major version is different from submission major version
 */
export function isSubmissionLatestVersion({
  examChallenge,
  submissionResult,
}: {
  examChallenge?: ExamChallengeModel;
  submissionResult?: SubmissionResultListModel;
}) {
  if (!examChallenge || !submissionResult) {
    return false;
  }

  const isLinkedChallenge =
    submissionResult.challengeId === examChallenge.linkedChallengeId;

  return (
    submissionResult.majorVersionNumber ===
    (isLinkedChallenge
      ? examChallenge.linkedMajorVersionNumber
      : examChallenge.majorVersionNumber)
  );
}

/**
 * Are the submissions using the most recent challenge exam major version?
 */
export function areSelectedExamsUpdated({
  selectedChallengeIds,
  examChallenges,
  submissionResultList,
}: {
  selectedChallengeIds: number[];
  examChallenges: ExamChallengeModel[];
  submissionResultList: SubmissionResultListModel[];
}): boolean {
  if (!selectedChallengeIds.length) {
    return true;
  }

  const examChallengesById = examChallenges.reduce((arr, exam) => {
    arr[exam.challengeId] = exam;
    if (exam.linkedChallengeId) {
      arr[exam.linkedChallengeId] = exam;
    }

    return arr;
  }, {});

  const selectedSubmissionResults = submissionResultList.filter(
    (submissionResult) =>
      selectedChallengeIds.includes(submissionResult.challengeId),
  );

  return selectedSubmissionResults.every((submissionResult) => {
    const challenge = examChallengesById[submissionResult.challengeId];

    return (
      challenge &&
      isSubmissionLatestVersion({
        examChallenge: challenge,
        submissionResult: submissionResult,
      })
    );
  });
}
