import * as classnames from "classnames";

import { Icon, DifficultyTag, RandomizedBadge, Tag } from "@shared/components";
import { SubmissionResultListModel } from "@shared/models";
import { getChallengeStatusText } from "@shared/services/challengeStatus";
import { ApplicantExamStatus, ChallengeStyle } from "@shared/services/enums";

/**
 * Prop interface
 */
export interface SubmissionDetailChallengeTitleProps {
  isOpen?: boolean;
  applicantExamStatus: ApplicantExamStatus;
  submissionResultList: SubmissionResultListModel;
  className?: string;
}

/**
 * React Component
 */
export const SubmissionDetailChallengeTitle = ({
  isOpen,
  applicantExamStatus,
  submissionResultList: {
    title,
    style,
    difficulty,
    challengeVersionNumber,
    challengeResultStatus,
    randomizeQuiz,
  },
  className,
}: SubmissionDetailChallengeTitleProps) => {
  const rootStyle = classnames("code-c-submission-detail-challenge-title", {
    [`${className}`]: Boolean(className),
  });

  return (
    <div className={rootStyle}>
      <div className="code-c-submission-detail-challenge-title__title">
        <span className="clamp-1">{title}</span>
        {challengeVersionNumber && (
          <small>{`(v${challengeVersionNumber})`}</small>
        )}
        <div className="code-c-submission-detail-challenge__toggle">
          <Icon type={isOpen ? "angle-up" : "angle-down"} />
        </div>
      </div>
      <div className="code-c-submission-detail-challenge-title__options">
        <Tag>{ChallengeStyle.toString(style)}</Tag>
        <DifficultyTag value={difficulty} />
        {randomizeQuiz && <RandomizedBadge />}
        <span className="code-c-submission-detail-challenge-title__status">
          {getChallengeStatusText(challengeResultStatus, applicantExamStatus)}
        </span>
      </div>
    </div>
  );
};
