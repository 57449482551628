import * as React from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { Switch, Route, Redirect } from "react-router-dom";

import { Msg } from "@shared/components";

import {
  SettingMember,
  ProjectSettingMemberPending,
  SettingSlackIntegration,
} from "./orgSettingsPartials";
import AuditLog from "./sharedPartials/auditLog/AuditLog.connect";

/**
 * Types and Interfaces
 */
export type ProjectSettingsRoutesProps = {
  systemAndOrgAdmin: boolean;
  projectAdmin: boolean;
  canViewProjectAuditLogs: boolean;
  currentProjectId: number;
};

class ProjectSettingsRoutes extends React.Component<ProjectSettingsRoutesProps> {
  public render() {
    const {
      systemAndOrgAdmin,
      projectAdmin,
      currentProjectId,
      canViewProjectAuditLogs,
    } = this.props;

    return (
      <>
        <BreadcrumbsItem to={`/p/${currentProjectId}/settings`}>
          <Msg id={"projectSettings"} />
        </BreadcrumbsItem>
        <Switch>
          {!systemAndOrgAdmin && (
            <Route
              path="/p/:projectId/settings/members/pending"
              exact={true}
              component={ProjectSettingMemberPending}
            />
          )}
          <Route
            path="/p/:projectId/settings/members/:status"
            exact={true}
            render={({ match }) => (
              <SettingMember status={match.params.status} />
            )}
          />
          {projectAdmin && (
            <Route
              path="/p/:projectId/settings/integrations/slack"
              exact={true}
              component={SettingSlackIntegration}
            />
          )}
          {canViewProjectAuditLogs && (
            <Route
              path="/p/:projectId/settings/auditlog"
              exact={true}
              render={() => <AuditLog auditType="project" />}
            />
          )}
          <Redirect
            from="/p/:projectId/settings"
            to={`/p/${currentProjectId}/settings/members/active`}
          />
        </Switch>
      </>
    );
  }
}

export default ProjectSettingsRoutes;
