import { connect } from "react-redux";
import { Action, Dispatch } from "redux";

import { RootState } from "../../../reducers";
import CountDownTimer from "./CountDownTimer";

interface OwnProps {
  thresholdSeconds?: number;
  className?: string;
}

const mapStateToProps = (state: RootState, props: OwnProps) => ({
  timeLeftSeconds: state.applicantTimer?.exam.seconds,
  ...props,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CountDownTimer);
