import { AxiosRequestConfig } from "axios";

import { KeyEventLogModel, KeyEventModel } from "@shared/models";

import { ApiResponse, get, post } from "../httpClient";

interface SubmissionResultPlaybackKeyEventLogParams {
  challengeResultId: number;
  keyEventLogId: number;
  projectId: number;
  submissionId: number;
  options?: AxiosRequestConfig;
}

export async function getSubmissionResultPlaybackKeyEventLog({
  challengeResultId,
  keyEventLogId,
  projectId,
  submissionId,
  options,
}: SubmissionResultPlaybackKeyEventLogParams) {
  const { data } = await get<KeyEventModel>(
    `/api/projects/${projectId}/submissions/${submissionId}/results/${challengeResultId}/playback/${keyEventLogId}`,
    options,
  );

  return data;
}

export async function postSubmissionResultPlaybackKeyEventLog({
  challengeResultId,
  keyEventLogId,
  projectId,
  submissionId,
  options,
}: SubmissionResultPlaybackKeyEventLogParams) {
  const { data } = await post<ApiResponse<KeyEventLogModel>>(
    `/api/projects/${projectId}/submissions/${submissionId}/results/${challengeResultId}/playback/${keyEventLogId}/process`,
    options,
  );

  return data;
}
