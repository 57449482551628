import classNames from "classnames";

import { Icon, Msg } from "@shared/components";

interface EmptyStateProps {
  height?: "applicantFunnel" | "overallScoreDistribution" | "challengeAnalysis";
}

export function EmptyState({ height }: EmptyStateProps) {
  const rootStyle = classNames("code-c-empty-state", {
    ["code-exam-dashboard__empty-state__applicant-funnel"]:
      height === "applicantFunnel",
    ["code-exam-dashboard__empty-state__score-distribution"]:
      height === "overallScoreDistribution",
    ["code-exam-dashboard__empty-state__challenge-analysis"]:
      height === "challengeAnalysis",
  });
  return (
    <div className={rootStyle}>
      <Icon type="chart-simple" size="large" />
      <Msg className="text-xl" id="common.noData" />
    </div>
  );
}
