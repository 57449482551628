import { QueryCache, QueryClient, QueryMeta } from "@tanstack/react-query";
import { toast } from "react-toastify";

export interface QueryCacheMeta extends QueryMeta {
  skipErrorToast?: boolean;
}

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 600000, // 10 mins
      staleTime: 300000, // 5 mins
      networkMode: "always",
      retry(failureCount, error: Record<string, any> | string) {
        if (error?.includes?.("404")) {
          return false;
        }

        return failureCount < 3;
      },
    },
    mutations: {
      // this will be overwritten by specific handlers on useMutation
      onError: (e: any) => {
        // Handle generic error
        if (e.message) {
          toast.error(e.message);
          return;
        }

        toast.error(e);
      },
    },
  },
  queryCache: new QueryCache({
    // e could be either API error or AxiosError or error from any 3rd party app
    onError: (e: any, query) => {
      // query.meta: QueryMeta; is not support with dynamic types
      if (query?.meta?.skipErrorToast) {
        return;
      }

      if (e?.messages) {
        toast.error(e.messages.join("\n"));
        return;
      }

      // handle failed background update
      if (query.state.data !== undefined) {
        // Temporary solution
        toast.error("failed background update", {
          toastId: "failed-background-update",
        });
        return;
      }

      toast.error(e);
    },
  }),
});
