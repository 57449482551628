import { ChallengeTextCaseJSONModel, KeyEventLogModel, QuestionModel } from ".";
import {
  ChallengeResultStatus,
  ChallengeStyle,
  ExecStatus,
  ProgrammingLanguage,
  ScoreStatus,
  SaveTrigger,
  ChallengeTakenBy,
  SpokenLanguages,
  UnstableReason,
} from "../services/enums";

export interface ScoreHistoryCoding {
  execStatus: ExecStatus;
  createdAt: string;
  score?: number;
  scoredAt?: string;
  saveTrigger?: SaveTrigger;
  successfulTestcases?: number;
  testOutput?: string;
  unstableReason?: UnstableReason;
}

export interface TimeExtension {
  id: number;
  applicantExamId: number;
  createdAt: string;
  extendedBy: number;
  extendedChallenges: number[];
  previousDeadline: string;
  newDeadline: string;
}

export interface ScoreHistoryAI {
  execStatus: ExecStatus; // exists, but always returns 7 for AI challenges
  createdAt: string;
  id: number;
  score?: number;
  testOutput?: string;
  hasUploadedFile?: boolean;
}
class SubmissionResultDetailModel {
  public id: number;
  public challengeId: number;
  public challengeVersionId: number;
  public applicantId: number;
  public applicantExamId: number;
  public dueDate: string;
  public storageId: string;
  public status: ChallengeResultStatus;
  public rawScore: number;
  public scoreStatus: ScoreStatus;
  public openedAt: string;
  public finishedAt: string;
  public totalTestcases: number;
  public successfulTestcases: number;
  public programmingLanguage: ProgrammingLanguage;
  public style: ChallengeStyle;
  public appealComment?: string;
  public score?: number;
  public showSecretTestcases?: boolean;
  public answers: {}; // Not used?
  public extensions: TimeExtension[];
  public takenBy: ChallengeTakenBy;
  public challengeLanguage?: SpokenLanguages;
  public unstableReason?: UnstableReason;

  // Optional - Only present when style = Quiz(1)
  public quizResult: {
    questions: QuestionModel[];
  };

  // Optional - Only present when style = Coding(2) or Algorithm(3)
  public codingResult: {
    editorLink: string;
    readmeLink: string;
    scoreHistory: ScoreHistoryCoding[];
    /**
     * raw test output data
     * leave it as back compatibility
     * @deprecated
     */
    testOutput?: string;
    /**
     * JSON formatted test output data
     */
    testOutputJson?: ChallengeTextCaseJSONModel;
    evaluationPoint: { order: number; title: string; description: string }[];
    keyEventLogs?: KeyEventLogModel[];
  };

  // Optional - Only present when style = AI(4)
  public aiResult: {
    highScore: number;
    readmeLink: string;
    scoreType: { rankBy: string; max?: number; min?: number };
    scoreHistory: ScoreHistoryAI[];
  };

  constructor(args?: {}) {
    Object.assign(this, args);

    if (this.style === ChallengeStyle.Quiz) {
      if (Array.isArray(this.quizResult.questions)) {
        this.quizResult.questions = this.quizResult.questions.map(
          (item) => new QuestionModel(item),
        );
      }
    }
  }
}

export default SubmissionResultDetailModel;
