import { Modal, Msg } from "@shared/components";
import { ChallengeModel } from "@shared/models";
import Message from "@shared/services/message";

interface ChallengeDeleteModalProps {
  isDeleteOpen: boolean;
  selectedChallenge: ChallengeModel;
  onCloseModal: () => void;
  onConfirmDelete: () => void;
}

export const ChallengeDeleteModal = ({
  isDeleteOpen,
  selectedChallenge,
  onCloseModal,
  onConfirmDelete,
}: ChallengeDeleteModalProps) => {
  return (
    <Modal
      title={Message.getMessageByKey("confirm.delete")}
      isOpen={isDeleteOpen}
      onClose={onCloseModal}
      onClickOk={onConfirmDelete}
      onClickCancel={onCloseModal}
      okButtonLabel={Message.getMessageByKey("delete")}
      okButtonType={"danger"}
      okButtonAriaLabel="Delete"
      ariaLabel="Delete Challenge"
      className="code-challenge-delete-modal"
    >
      <div className="code-challenge-delete-modal__challenge-title is-wrap">
        {selectedChallenge.title}
      </div>
      <Msg id="challenge.confirmDelete" />
    </Modal>
  );
};
