import Axios, { AxiosError, AxiosRequestConfig } from "axios";
import QueryString from "query-string";

import { PaginationModel, SortOrderItem } from "@shared/models";
import MSG from "@shared/services/message";
import { redirectToLogin } from "@shared/services/url";

export interface BaseSearchQuery {
  keyword: string;
  limit?: number;
  offset?: number;
  sortOrder?: SortOrderItem;
}

export type ApiResponse<T = any> = {
  code: number;
  result: T;
  messages?: Array<string>;
  extra?: {} | string;
};

export type Pagination<T> = ApiResponse<T> & {
  pagination: PaginationModel;
};

/*
 * Function to get error from API response
 */
export const getErrorString = (
  payload: string | { messages?: Array<string> },
  code: number,
) => {
  if (isNetworkError(code)) {
    return MSG.getMessageByKey("message.connectionLost");
  }
  if (typeof payload === "string") {
    return code + " error. " + payload;
  }
  return Array.isArray(payload.messages)
    ? payload.messages.join(", ") || `${code} error.`
    : `${code} error.`;
};

const isNetworkError = (error: AxiosError | number) => {
  // The status code starts at 0.
  // Even if ajax processing completes, staying 0 means Network error.
  // - https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/status
  if (typeof error === "number") {
    return error === 0;
  }
  return error.response?.status;
};

const axios = Axios.create();
axios.defaults.paramsSerializer = {
  serialize: (params) => {
    const { sortOrder, ...restParams } = params;
    const serializedParams = {
      ...restParams,
      ...(sortOrder?.column && { "sortOrder.column": sortOrder.column }),
      ...(sortOrder?.direction && {
        "sortOrder.direction": sortOrder.direction,
      }),
    };

    return QueryString.stringify(serializedParams, { arrayFormat: "bracket" });
  },
};

function handleError(error: any) {
  if (error.response) {
    // use axios error message as fallback
    let errorString = error.message;

    if (error.response.data) {
      errorString = getErrorString(error.response.data, error.response.status);
    }

    if (error.response?.status === 401) {
      redirectToLogin();
    }

    return errorString;
  }

  return error;
}

export async function get<T, R = ApiResponse<T>>(
  url: string,
  options?: AxiosRequestConfig,
) {
  try {
    return await axios.get<R>(url, options);
  } catch (e) {
    throw handleError(e);
  }
}

// this function is used to get data without any type restrictions to ApiResponse
export async function getUnrestricted<T>(
  url: string,
  options?: AxiosRequestConfig,
) {
  try {
    return await axios.get<T>(url, options);
  } catch (e) {
    throw handleError(e);
  }
}

export async function post<T>(url: string, options: AxiosRequestConfig = {}) {
  try {
    const { data } = options;
    return await axios.post<T>(url, data, options);
  } catch (e) {
    throw handleError(e);
  }
}

export async function put<T>(url: string, options: AxiosRequestConfig = {}) {
  try {
    const { data } = options;
    return await axios.put<T>(url, data, options);
  } catch (e) {
    throw handleError(e);
  }
}

export async function _delete<T>(url: string, options?: AxiosRequestConfig) {
  try {
    return await axios.delete<T>(url, options);
  } catch (e) {
    throw handleError(e);
  }
}
