import * as classnames from "classnames";
import { isEqual } from "lodash";
import * as React from "react";

import {
  Modal,
  Row,
  Column,
  Form,
  FormGroup,
  Select,
  SelectItem,
  Label,
  Input,
  Checkbox,
  Textarea,
  CustomForm,
  TableBody,
  Table,
  TableHead,
  QuickHelp,
  Msg,
} from "../../../../../../../shared/components";
import { CustomFormDefinitionModel } from "../../../../../../../shared/models";
import {
  CustomFormDefinitionType,
  SpokenLanguages,
} from "../../../../../../../shared/services/enums";
import Message from "../../../../../../../shared/services/message";

/**
 * Prop interface
 */
export interface CustomFormEditProps {
  isOpen: boolean;
  onSave?: (definition: CustomFormDefinitionModel) => void;
  onCancel?: () => void;
  customFormElement?: CustomFormDefinitionModel;
  invalidKeys?: Array<string>;
  examLanguage?: SpokenLanguages;
}

/**
 * State interface
 */
export interface CustomFormEditState {
  definition: CustomFormDefinitionModel;
  formValid: boolean;
  formError?: { [key: string]: string };
}

/**
 * Page component
 */
export class CustomFormEdit extends React.Component<
  CustomFormEditProps,
  CustomFormEditState
> {
  private options: SelectItem[];

  constructor(props: CustomFormEditProps) {
    super(props);

    this.state = {
      definition: new CustomFormDefinitionModel(),
      formValid: false,
    };

    this.options = [
      {
        label: Message.getMessageByKey("entryForm.type.text"),
        value: CustomFormDefinitionType.Text,
      },
      {
        label: Message.getMessageByKey("entryForm.type.number"),
        value: CustomFormDefinitionType.Number,
      },
      {
        label: Message.getMessageByKey("entryForm.type.textarea"),
        value: CustomFormDefinitionType.Textarea,
      },
      {
        label: Message.getMessageByKey("entryForm.type.select"),
        value: CustomFormDefinitionType.Select,
      },
      {
        label: Message.getMessageByKey("entryForm.type.checkbox"),
        value: CustomFormDefinitionType.Checkbox,
      },
      {
        label: Message.getMessageByKey("entryForm.type.radio"),
        value: CustomFormDefinitionType.Radio,
      },
      {
        label: Message.getMessageByKey("entryForm.type.date"),
        value: CustomFormDefinitionType.Date,
      },
      {
        label: Message.getMessageByKey("entryForm.type.daterange"),
        value: CustomFormDefinitionType.DateRange,
      },
    ];
  }

  public shouldComponentUpdate(
    nextProps: CustomFormEditProps,
    nextState: CustomFormEditState,
  ) {
    return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state);
  }

  public componentDidUpdate(prevProps: CustomFormEditProps) {
    if (this.props.isOpen && !prevProps.isOpen) {
      this.setState({
        definition:
          this.props.customFormElement || new CustomFormDefinitionModel(),
        formValid: false,
      });
    }
  }

  public render() {
    const {
      isOpen,
      onCancel,
      invalidKeys = [],
      customFormElement = new CustomFormDefinitionModel(),
      examLanguage,
    } = this.props;

    const { definition } = this.state;

    const rootStyle = classnames("code-custom-form-edit");

    const invalidKeysFiltered = invalidKeys.filter(
      (key) => key !== customFormElement.key,
    );

    return (
      <Modal
        className={rootStyle}
        isOpen={isOpen}
        size="large"
        onClickOk={this.handleSave}
        onClose={onCancel}
        onClickCancel={onCancel}
        okButtonLabel={<Msg id="common.ok" />}
        title={<Msg id="entryForm.addItem" />}
        disableOk={!this.state.formValid}
        ariaLabel="Edit Custom Form"
      >
        <Table hoverable={false}>
          <TableHead>
            <Column>
              <Msg id="exam.entryForm.configuration" />
            </Column>
            <Column>
              <Msg id="preview" />
            </Column>
          </TableHead>
          <TableBody>
            <Row>
              <Column>
                <Form
                  validation={{
                    key: [
                      "string",
                      ["max", 255],
                      "required",
                      ["invalid", invalidKeysFiltered],
                    ],
                    displayName: ["string", ["max", 255], "required"],
                    options: this.shouldShowOptionsField(definition.dataType)
                      ? ["required"]
                      : [],
                  }}
                  onFormChange={this.handleFormChange}
                  initialValues={{
                    dataType: customFormElement.dataType.toString(),
                    required: customFormElement.required,
                    displayName: customFormElement.displayName,
                    key: customFormElement.key,
                    description: customFormElement.description,
                    options: customFormElement.options.join("\n"),
                  }}
                  error={this.state.formError}
                  clear={!isOpen}
                >
                  <FormGroup className="code-custom-form-edit__inline">
                    <div className="code-custom-form-edit__type">
                      <Label>
                        <Msg id="entryForm.type" />
                      </Label>
                      <Select name="dataType" options={this.options} />
                    </div>
                    <div>
                      <Label>
                        <Msg id="entryForm.required" />
                      </Label>
                      <Checkbox name="required">
                        <Msg id="entryForm.required.checkText" />
                      </Checkbox>
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <Label>
                      <Msg id="common.displayName" />
                    </Label>
                    <Input name="displayName" />
                  </FormGroup>
                  <FormGroup>
                    <Label>
                      <Msg id="entryForm.key" />
                      <QuickHelp
                        text={Message.getMessageByKey(
                          "quickHelp.entryForm.key",
                        )}
                      />
                    </Label>
                    <Input name="key" />
                  </FormGroup>
                  <FormGroup>
                    <Label isOptional={true}>
                      <Msg id="common.description" />
                    </Label>
                    <Input name="description" />
                  </FormGroup>
                  {this.shouldShowOptionsField(definition.dataType) && (
                    <FormGroup>
                      <Label>
                        <Msg id="entryForm.options" />
                      </Label>
                      <Textarea name="options" />
                    </FormGroup>
                  )}
                </Form>
              </Column>
              <Column className="code-custom-form-edit__item-preview">
                <Form>
                  <CustomForm definition={definition} locale={examLanguage} />
                </Form>
              </Column>
            </Row>
          </TableBody>
        </Table>
      </Modal>
    );
  }

  private handleFormChange = (
    formValid: boolean,
    formValues: { dataType: string; options?: string; required: boolean },
  ) => {
    const dataType = parseInt(formValues.dataType, 10);
    const options = formValues.options
      ? formValues.options.split("\n").filter((option: string) => option.length)
      : [];

    let formError;
    if (
      dataType === CustomFormDefinitionType.Select ||
      dataType === CustomFormDefinitionType.Radio ||
      dataType === CustomFormDefinitionType.Checkbox
    ) {
      if (
        dataType !== CustomFormDefinitionType.Checkbox &&
        options.length < 2
      ) {
        formError = {
          options: Message.getMessageByKey("validation.options.atLeast-2"),
        };
        formValid = false;
      } else if (options.some((option) => option.length > 100)) {
        formError = {
          options: Message.getMessageByKey("validation.options.maxLength"),
        };
        formValid = false;
      } else if (options.length !== new Set(options).size) {
        formError = {
          options: Message.getMessageByKey("validation.options.noDuplicate"),
        };
        formValid = false;
      }
    }

    this.setState({
      formValid,
      formError,
      definition: new CustomFormDefinitionModel(
        Object.assign({}, this.state.definition, {
          ...formValues,
          dataType,
          options,
        }),
      ),
    });
  };

  private handleSave = () => {
    if (this.props.onSave) {
      this.props.onSave(this.state.definition);
    }
  };

  private shouldShowOptionsField = (
    type: CustomFormDefinitionType,
  ): boolean => {
    return (
      type === CustomFormDefinitionType.Select ||
      type === CustomFormDefinitionType.Radio ||
      type === CustomFormDefinitionType.Checkbox
    );
  };
}
