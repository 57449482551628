/**
 * Enums
 */
import { ExamDeliveryKind, ExamType } from "../services/enums";
import { MemberListModel } from "./";

class ExamListModel {
  public id: number;
  public name: string;
  public canDeliver: boolean;
  public type: number;
  public examType: ExamType;
  public sentDeliveryCount: number;
  public unreadDeliveryCount: number;
  public inProgressCount: number;
  public inReviewCount: number;
  public submittedCount: number;
  public expiredCount: number;
  public updatedAt: string;
  public deliveryKind: ExamDeliveryKind;
  public archivedAt: string;
  public isDeactivated?: boolean;
  public isOfficial?: boolean;
  public urlDeliveryEnabled: boolean;
  public reviewers: MemberListModel[] = [];
  public hasOutdatedChallengeVersion: boolean;
  public scheduledDeliveriesCount: number;
  public pendingDeliveryCount: number;
  public approvedCount: number;
  public challengeCount: number;
  public rejectedCount: number;
  public examSegment: {
    engineerRole: number; // type JobTitles
    purposeOfUse: string; // type PurposesOfUse
  };

  constructor(args?: {}) {
    Object.assign(this, args);
  }
}

export default ExamListModel;
