import * as classnames from "classnames";
import * as React from "react";

import { Question, Markdown, Msg } from "@shared/components";
import { ChallengeModel, ReadmeModel } from "@shared/models";
import { ChallengeStyle } from "@shared/services/enums";
import { getChallengePreviewPath } from "@shared/services/url";

/**
 * Prop interface
 */
export interface ChallengeProps {
  appBasePath?: string;
  challenge?: ChallengeModel;
  readme?: ReadmeModel;
  className?: string;
}

/**
 * React Component
 */
class Challenge extends React.Component<ChallengeProps> {
  constructor(props: ChallengeProps) {
    super(props);

    this.state = {};
  }

  public render() {
    const {
      challenge = new ChallengeModel(),
      readme = new ReadmeModel(),
      appBasePath,
      className,
    } = this.props;

    const rootStyle = classnames("code-c-challenge", {
      [`${className}`]: Boolean(className),
    });

    const previewPath = getChallengePreviewPath(
      challenge.challengeId || challenge.id,
      challenge.style,
      {
        programmingLanguage: (challenge.programmingLanguages ?? [])[0],
        basePath: appBasePath,
      },
    );

    let result = null;

    switch (challenge.style) {
      case ChallengeStyle.Quiz:
        const questions = challenge.questions.map((question, index) => (
          <div className="code-c-challenge__question" key={question.id}>
            <div className="code-c-challenge__question-no">Q{index + 1}</div>
            <Question
              className="code-c-challenge__question-item"
              question={question}
              insightsDisplayOptions={{
                showGlobalStats: true,
                showOverview: true,
              }}
            />
          </div>
        ));

        result = (
          <div className="code-c-challenge__container">
            <div className="code-c-challenge__header" />
            <div className="code-c-challenge__body">{questions}</div>
          </div>
        );
        break;
      case ChallengeStyle.AI:
        result = (
          <div className="code-c-challenge__container">
            <div className="code-c-challenge__body">
              <Markdown
                body={readme.body}
                internalLinkFallbackURL={`/challenges/${
                  challenge.challengeId || challenge.id
                }/preview`}
              />
            </div>
          </div>
        );
        break;
      case ChallengeStyle.Algorithm:
      case ChallengeStyle.Development:
      case ChallengeStyle.Function:
        result = (
          <div className="code-c-challenge__container">
            <div className="code-c-challenge__header">
              <div className="code-c-challenge__header-left">
                <Msg id={"testcases.open"} />：{" "}
                {challenge.currentVersion.openTestcases} /{" "}
                <Msg id={"testcases.secret"} />：{" "}
                {challenge.currentVersion.secretTestcases}
              </div>
            </div>
            <div className="code-c-challenge__body">
              <Markdown
                body={readme.body}
                internalLinkFallbackURL={previewPath}
              />
            </div>
          </div>
        );
        break;
      default:
    }

    return <div className={rootStyle}>{result}</div>;
  }
}

export default Challenge;
