import { SelectItem } from "@shared/components";

import {
  ExamModel,
  ApplicantExamModel,
  ExamChallengeSetModel,
  ApplicantChallengeSetModel,
  ExamChallengeModel,
  ApplicantChallengeModel,
  SubmissionListFilterModel,
  SubmissionListModel,
  SubmissionModel,
  ApplicantExamTimerModel,
  EvaluationMetaModel,
} from "../models";
import { dayjs, getDifference } from "./date";
import {
  ChallengeStyle,
  ApplicantExamStatus,
  ExamType,
  SubmissionListKind,
  EvaluationType,
} from "./enums";
import Message, { truncateString } from "./message";

/**
 *
 * Types
 */
type CommonExamModel = Pick<
  ExamModel | ApplicantExamModel,
  "id" | "challengesSets" | "status"
>;
type CommonChallengeSetModel = Pick<
  ExamChallengeSetModel | ApplicantChallengeSetModel,
  | "id"
  | "challenges"
  | "isOptionalSet"
  | "isRandomSet"
  | "numberChallengesToTake"
>;
type CommonExamChallengeModel = Pick<
  ExamChallengeModel | ApplicantChallengeModel,
  | "id"
  | "style"
  | "title"
  | "description"
  | "difficulty"
  | "localExamEnabled"
  | "weight"
  | "timeLimitMinutes"
>;

/**
 * Return true if the challenge set contains quiz challenge
 * @param exam
 */
export function hasQuizChallenge(exam: CommonExamModel): boolean {
  return exam.challengesSets.some((challengeSet: CommonChallengeSetModel) =>
    challengeSet.challenges.some(
      (challenge: CommonExamChallengeModel) =>
        challenge.style === ChallengeStyle.Quiz,
    ),
  );
}

/**
 * Return true if the challenge set contains coding challenge
 * @param exam
 */
export function hasCodingChallenge(exam: CommonExamModel): boolean {
  return exam.challengesSets.some((challengeSet: CommonChallengeSetModel) =>
    challengeSet.challenges.some((challenge: CommonExamChallengeModel) =>
      ChallengeStyle.isCodingTypeChallenge(challenge.style),
    ),
  );
}

/**
 * Return true if the challenge set contains ai challenge
 * @param exam
 */
export function hasAIChallenge(exam: CommonExamModel): boolean {
  return exam.challengesSets.some((challengeSet: CommonChallengeSetModel) =>
    challengeSet.challenges.some(
      (challenge: CommonExamChallengeModel) =>
        challenge.style === ChallengeStyle.AI,
    ),
  );
}

export function hasChallengeHistory(exam: CommonExamModel): boolean {
  return exam.challengesSets.some((challengeSet: CommonChallengeSetModel) =>
    challengeSet.challenges.some((challenge: CommonExamChallengeModel) =>
      [
        ChallengeStyle.Algorithm,
        ChallengeStyle.AI,
        ChallengeStyle.Development,
        ChallengeStyle.Function,
      ].includes(challenge.style),
    ),
  );
}

/**
 * Return true if the challenge set contains a challenge that localExamEnabled is true
 * @param exam
 */
export function hasCLIChallenge(exam: CommonExamModel) {
  return exam.challengesSets.some((challengeSet: CommonChallengeSetModel) =>
    challengeSet.challenges.some(
      (challenge: CommonExamChallengeModel) =>
        ChallengeStyle.hasLocalExam(challenge.style) &&
        challenge.localExamEnabled,
    ),
  );
}

/**
 * Update submission count object by submission list response
 * @param counts
 * @param submissionCount
 * @param submissionCondition
 */
export function updateSubmissionCounts(
  counts: ExamModel["counts"],
  submissionCount: number,
  submissionCondition: SubmissionListFilterModel = {},
): ExamModel["counts"] {
  const { keyword, status, statuses, archived, needsReview } =
    submissionCondition;

  if (Boolean(keyword) || Boolean(needsReview)) {
    return counts;
  }

  if (!archived && status == undefined && statuses === undefined) {
    return { ...counts, sentDeliveryExceptArchivedCount: submissionCount };
  }

  const newCounts: ExamModel["counts"] = {
    ...counts,
    unreadDeliveryCount:
      status === ApplicantExamStatus.Unread
        ? submissionCount
        : counts.unreadDeliveryCount,
    inProgressCount:
      status === ApplicantExamStatus.InProgress
        ? submissionCount
        : counts.inProgressCount,
    submittedCount:
      status === ApplicantExamStatus.Submitted
        ? submissionCount
        : counts.submittedCount,
    inReviewCount:
      status === ApplicantExamStatus.InReview
        ? submissionCount
        : counts.inReviewCount,
    approvedCount:
      status === ApplicantExamStatus.Passed
        ? submissionCount
        : counts.approvedCount,
    rejectedCount:
      status === ApplicantExamStatus.Failed
        ? submissionCount
        : counts.rejectedCount,
    expiredCount:
      status === ApplicantExamStatus.Expired
        ? submissionCount
        : counts.expiredCount,
    archivedCount: archived ? submissionCount : counts.archivedCount,
  };

  const {
    unreadDeliveryCount = 0,
    inProgressCount = 0,
    submittedCount = 0,
    inReviewCount = 0,
    expiredCount = 0,
    approvedCount = 0,
    rejectedCount = 0,
  } = newCounts;

  return {
    ...newCounts,
    sentDeliveryExceptArchivedCount:
      unreadDeliveryCount +
      inProgressCount +
      submittedCount +
      inReviewCount +
      expiredCount +
      approvedCount +
      rejectedCount,
  };
}

/**
 * Update archived submission count object by submission archive response
 * @param counts
 * @param submission
 */
export function getUpdatedArchivedSubmissionCounts(
  counts: ExamModel["counts"],
  submission: SubmissionListModel | SubmissionModel,
): ExamModel["counts"] {
  const updatedCounts = {
    sentDeliveryCount: 0,
    unreadDeliveryCount: 0,
    inProgressCount: 0,
    submittedCount: 0,
    expiredCount: 0,
    inReviewCount: 0,
    archivedCount: 0,
    approvedCount: 0,
    rejectedCount: 0,
    sentDeliveryExceptArchivedCount: 0,
    ...counts,
  };

  const archived = !!submission.archivedAt;

  updatedCounts.archivedCount += archived ? 1 : -1;

  switch (submission.status) {
    case ApplicantExamStatus.Unread:
      updatedCounts.unreadDeliveryCount -= archived ? 1 : -1;
      updatedCounts.sentDeliveryExceptArchivedCount -= archived ? 1 : -1;
      break;
    case ApplicantExamStatus.InProgress:
      updatedCounts.inProgressCount -= archived ? 1 : -1;
      updatedCounts.sentDeliveryExceptArchivedCount -= archived ? 1 : -1;
      break;
    case ApplicantExamStatus.Submitted:
      updatedCounts.submittedCount -= archived ? 1 : -1;
      updatedCounts.sentDeliveryExceptArchivedCount -= archived ? 1 : -1;
      break;
    case ApplicantExamStatus.Expired:
      updatedCounts.expiredCount -= archived ? 1 : -1;
      updatedCounts.sentDeliveryExceptArchivedCount -= archived ? 1 : -1;
      break;
    case ApplicantExamStatus.Canceled:
      updatedCounts.unreadDeliveryCount -= archived ? 1 : -1;
      updatedCounts.sentDeliveryExceptArchivedCount -= archived ? 1 : -1;
      break;
    case ApplicantExamStatus.InReview:
      updatedCounts.inReviewCount -= archived ? 1 : -1;
      updatedCounts.sentDeliveryExceptArchivedCount -= archived ? 1 : -1;
      break;
    case ApplicantExamStatus.Passed:
      updatedCounts.approvedCount -= archived ? 1 : -1;
      updatedCounts.sentDeliveryExceptArchivedCount -= archived ? 1 : -1;
      break;
    case ApplicantExamStatus.Failed:
      updatedCounts.rejectedCount -= archived ? 1 : -1;
      updatedCounts.sentDeliveryExceptArchivedCount -= archived ? 1 : -1;
      break;
    default:
      break;
  }

  return updatedCounts;
}

export function updateEvaluationSubmissionCounts(
  counts: ExamModel["counts"],
  oldSubmissionStatus: ApplicantExamStatus,
  newSubmissionStatus: ApplicantExamStatus,
) {
  const updatedCounts = {
    sentDeliveryExceptArchivedCount: 0,
    unreadDeliveryCount: 0,
    inProgressCount: 0,
    submittedCount: 0,
    expiredCount: 0,
    inReviewCount: 0,
    archivedCount: 0,
    approvedCount: 0,
    rejectedCount: 0,
    ...counts,
  };

  switch (oldSubmissionStatus) {
    case ApplicantExamStatus.Submitted:
      updatedCounts.submittedCount -= 1;
      break;
    case ApplicantExamStatus.InReview:
      updatedCounts.inReviewCount -= 1;
      break;
    case ApplicantExamStatus.Passed:
      updatedCounts.approvedCount -= 1;
      break;
    case ApplicantExamStatus.Failed:
      updatedCounts.rejectedCount -= 1;
      break;
  }

  switch (newSubmissionStatus) {
    case ApplicantExamStatus.Submitted:
      updatedCounts.submittedCount += 1;
      break;
    case ApplicantExamStatus.InReview:
      updatedCounts.inReviewCount += 1;
      break;
    case ApplicantExamStatus.Passed:
      updatedCounts.approvedCount += 1;
      break;
    case ApplicantExamStatus.Failed:
      updatedCounts.rejectedCount += 1;
      break;
  }

  return updatedCounts;
}

/**
 * Remove unused fields for updating exam
 * @param exam
 */
export function scrubExamUpdatePayload(
  exam?: ExamModel,
): Partial<ExamModel> | undefined {
  if (!exam) {
    return exam;
  }
  const {
    status,
    reportingEnabled,
    urlToken,
    challengesSets,
    formDefinitions,
    reviewers,
    sharingGroupIds,
    counts,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
    usingOrganizationsCount,
    isChallengesModificationAllowed,
    hasOnlySampleDelivery,
    ...rest
  } = exam;
  return rest;
}

/**
 * Remove unused fields for updating exam challenges
 * @param exam
 */
export function scrubExamChallengeUpdatePayload(
  challengeSets: ExamChallengeSetModel[],
): (Pick<
  ExamChallengeSetModel,
  | "id"
  | "displayOrder"
  | "isOptionalSet"
  | "isRandomSet"
  | "numberChallengesToTake"
> & {
  challenges: Partial<ExamChallengeModel>[];
})[] {
  return challengeSets.map((set) => {
    const {
      challenges,
      isOptionalSet,
      isRandomSet,
      numberChallengesToTake,
      id,
      displayOrder,
    } = set;
    return {
      id,
      displayOrder,
      isOptionalSet,
      isRandomSet,
      numberChallengesToTake,
      challenges: challenges.map((challenge) => {
        const {
          challengeId,
          timeLimitMinutes,
          displayOrder,
          title,
          description,
          programmingLanguages,
          weight,
          localExamEnabled,
          randomizeQuiz,
        } = challenge;

        const updatedProgrammingLanguages =
          challenge.style === ChallengeStyle.Development
            ? []
            : programmingLanguages;

        return {
          challengeId,
          timeLimitMinutes,
          displayOrder,
          title,
          description,
          programmingLanguages: updatedProgrammingLanguages,
          weight,
          localExamEnabled,
          randomizeQuiz,
        };
      }),
    };
  });
}

/**
 * Return request body for the copying exam
 * @param exam
 */
export function buildExamCopyBody(exam: ExamModel) {
  const {
    name,
    enName,
    examType,
    challengesSets,
    reviewers,
    formDefinitions,
    applicantNameRequired,
  } = exam;
  const multiLanguage = examType === ExamType.EnglishJapanese;
  return {
    ...scrubExamUpdatePayload(exam),
    isOfficial: false,
    name: truncateString(
      `[${
        multiLanguage
          ? Message.getMessageByKeyAndLocale("copy.label", "ja")
          : Message.getMessageByKey("copy.label")
      }] ${name}`,
      255,
    ),
    ...(multiLanguage
      ? {
          enName: truncateString(
            `[${Message.getMessageByKeyAndLocale(
              "copy.label",
              "en",
            )}] ${enName}`,
            255,
          ),
        }
      : {}),
    challengesSets: scrubExamChallengeUpdatePayload(challengesSets),
    reviewers: reviewers.map((reviewer) => reviewer.id),
    formDefs: {
      forms: formDefinitions,
      applicantNameRequired: applicantNameRequired,
    },
  };
}

/**
 * Get exam name
 * @param isMultiLang
 * @param name
 * @param enName
 * @param language
 */
export function getExamName(
  isMultiLang: boolean,
  name = "",
  enName = "",
  language?: string,
): string {
  return isMultiLang && language === "en" && enName !== "" ? enName : name;
}

export const getExamStatusString = ({
  submissionListKind,
  archived,
}: {
  submissionListKind?: number;
  archived: boolean;
}): string => {
  if (archived) {
    return Message.getMessageByKey("common.archived");
  }

  if (!submissionListKind) {
    return Message.getMessageByKey("common.all");
  }

  if (isEvaluatedList(submissionListKind)) {
    return Message.getMessageByKey("submission.evaluated");
  }

  if (isSubmittedList(submissionListKind)) {
    return Message.getMessageByKey("applicantExamStatus.submitted");
  }

  return ApplicantExamStatus.toString(submissionListKind);
};

export const isSubmittedStatus = (submissionStatus: ApplicantExamStatus) =>
  [
    ApplicantExamStatus.Submitted,
    ApplicantExamStatus.InReview,
    ApplicantExamStatus.Passed,
    ApplicantExamStatus.Failed,
  ].includes(submissionStatus);

export const isEvaluatedStatus = (submissionStatus: ApplicantExamStatus) =>
  [ApplicantExamStatus.Passed, ApplicantExamStatus.Failed].includes(
    submissionStatus,
  );

export const isReviewableStatus = (submissionStatus: ApplicantExamStatus) =>
  [ApplicantExamStatus.Submitted, ApplicantExamStatus.InReview].includes(
    submissionStatus,
  );

export const isEvaluatedList = (submissionListKind: SubmissionListKind) =>
  [
    SubmissionListKind.passed,
    SubmissionListKind.failed,
    SubmissionListKind.evaluated,
  ].includes(submissionListKind);

export const isSubmittedList = (submissionListKind: SubmissionListKind) =>
  [
    SubmissionListKind.inreview,
    SubmissionListKind.reviewed,
    SubmissionListKind.submitted,
  ].includes(submissionListKind);

export const isAllOrArchivedList = (submissionListKind: SubmissionListKind) =>
  [SubmissionListKind.archived, SubmissionListKind.all].includes(
    submissionListKind,
  );

export const canViewSubmissionReport = (
  submissionStatus: ApplicantExamStatus,
) =>
  [
    ApplicantExamStatus.Submitted,
    ApplicantExamStatus.InReview,
    ApplicantExamStatus.Passed,
    ApplicantExamStatus.Failed,
  ].includes(submissionStatus);

export const shouldShowActionLogTab = (submissionStatus: ApplicantExamStatus) =>
  [
    ApplicantExamStatus.InProgress,
    ApplicantExamStatus.Submitted,
    ApplicantExamStatus.InReview,
    ApplicantExamStatus.Passed,
    ApplicantExamStatus.Failed,
  ].includes(submissionStatus);

export const hasBeenSubmittedList = (submissionListKind: SubmissionListKind) =>
  [
    SubmissionListKind.submitted,
    SubmissionListKind.inreview,
    SubmissionListKind.reviewed,
    SubmissionListKind.evaluated,
    SubmissionListKind.passed,
    SubmissionListKind.failed,
  ].includes(submissionListKind);

export const getConditionalColumnsForSubmissionList = (
  submissionListKind: SubmissionListKind,
) => {
  const isAllOrArchivedPage = isAllOrArchivedList(submissionListKind);

  const shouldShowSubmittedAtColumn =
    isAllOrArchivedPage ||
    hasBeenSubmittedList(submissionListKind) ||
    submissionListKind === SubmissionListKind.expired;
  const shouldShowTimeSpentColumn =
    submissionListKind !== SubmissionListKind.unread;
  const shouldShowOverallScoreColumn =
    submissionListKind !== SubmissionListKind.unread;
  const shouldShowResultColumn =
    isAllOrArchivedPage || hasBeenSubmittedList(submissionListKind);

  return {
    shouldShowSubmittedAtColumn,
    shouldShowTimeSpentColumn,
    shouldShowOverallScoreColumn,
    shouldShowResultColumn,
  };
};

export const getMillisBeforeExamDeadline = (
  exam: ApplicantExamModel,
  examTimer: ApplicantExamTimerModel,
) => {
  // Fallback to client's system clock
  let millisBeforeDeadline = getDifference(
    dayjs(),
    exam.deadline,
    "milliseconds",
  );

  const { lastRecordedMillisLeft, lastRecordedTime } = examTimer;
  if (lastRecordedMillisLeft && lastRecordedTime) {
    millisBeforeDeadline =
      lastRecordedMillisLeft - (performance.now() - lastRecordedTime);
  }

  return Math.floor(millisBeforeDeadline);
};

export const isAutoEvaluated = (
  submissionStatus: ApplicantExamStatus,
  evaluationMeta?: EvaluationMetaModel,
) => {
  return (
    isEvaluatedStatus(submissionStatus) &&
    evaluationMeta?.evaluationType === EvaluationType.AutoFilter
  );
};

/**
 * Check if any of the challenge has local exam enabled
 * @param examChallengeSets
 * @returns
 */
export function hasLocalExamEnabled(
  examChallengeSets: ExamChallengeSetModel[] = [],
) {
  return examChallengeSets.some((challengeSet) =>
    challengeSet.challenges.some((challenge) => challenge.localExamEnabled),
  );
}

const JOB_TITLE_NOT_DEFINED_ID = 0;

export function getExamCreateOutlineOptions(options: SelectItem[]) {
  return [
    {
      value: "",
      label: Message.getMessageByKey("custom-form-select.placeholder"),
    },
    ...options.filter(
      (option) => !option.disabled && option.value !== JOB_TITLE_NOT_DEFINED_ID,
    ),
  ];
}

export function getExamDetailOutlineOptions(
  options: SelectItem[],
): SelectItem[] {
  return options.map((option) => ({
    ...option,
    disabled: option.disabled || option.value === JOB_TITLE_NOT_DEFINED_ID,
  }));
}

export function buildApplicantFunnelChartData(
  applicantCounts: ExamModel["counts"],
) {
  const {
    sentDeliveryExceptArchivedCount = 0,
    inProgressCount = 0,
    inReviewCount = 0,
    submittedCount = 0,
    approvedCount = 0,
    rejectedCount = 0,
  } = applicantCounts;

  const passedCount = approvedCount;
  const allSubmittedCount =
    passedCount + rejectedCount + submittedCount + inReviewCount;
  const allStartedCount = allSubmittedCount + inProgressCount;
  const allDeliveredCount = sentDeliveryExceptArchivedCount;

  return [
    {
      label: Message.getMessageByKey(
        "exam.dashboard.applicantFunnel.examsDelivered",
      ),
      count: allDeliveredCount,
    },
    {
      label: Message.getMessageByKey(
        "exam.dashboard.applicantFunnel.examsStarted",
      ),
      count: allStartedCount,
    },
    {
      label: Message.getMessageByKey(
        "exam.dashboard.applicantFunnel.submitted",
      ),
      count: allSubmittedCount,
    },
    {
      label: Message.getMessageByKey("exam.dashboard.applicantFunnel.passed"),
      count: passedCount,
    },
  ].filter((item) => item.count > 0);
}

export function shouldShowLocalExamWarning({
  hasLocalExamEnabled,
  isCodePlaybackAllowed,
  showKeyEventsCheckboxEnabled,
  isWebcamMonitoringAllowed,
  showWebcamCheckboxEnabled,
  isLogApplicantActionAllowed,
  showCopyPasteEventsCheckboxEnabled,
  showTabChangeEventsCheckboxEnabled,
  showScreenshotEventsCheckboxEnabled,
}: {
  hasLocalExamEnabled: boolean;
  isCodePlaybackAllowed: boolean;
  showKeyEventsCheckboxEnabled: boolean;
  isWebcamMonitoringAllowed: boolean;
  showWebcamCheckboxEnabled: boolean;
  isLogApplicantActionAllowed: boolean;
  showCopyPasteEventsCheckboxEnabled: boolean;
  showTabChangeEventsCheckboxEnabled: boolean;
  showScreenshotEventsCheckboxEnabled: boolean;
}) {
  // If local exam is not enabled, no need to show the warning
  if (!hasLocalExamEnabled) {
    return false;
  }

  // Check for code playback and related settings
  if (isCodePlaybackAllowed && showKeyEventsCheckboxEnabled) {
    return true;
  }

  // Check for webcam monitoring and related settings
  if (isWebcamMonitoringAllowed && showWebcamCheckboxEnabled) {
    return true;
  }

  // Check for logging applicant actions and related settings
  if (
    isLogApplicantActionAllowed &&
    (showCopyPasteEventsCheckboxEnabled ||
      showTabChangeEventsCheckboxEnabled ||
      showScreenshotEventsCheckboxEnabled)
  ) {
    return true;
  }

  // If none of the conditions are met, do not show the warning
  return false;
}
