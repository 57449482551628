import * as React from "react";

import { ExamRestrictionOptions } from "@components/orgs/settings/setting/sharedPartials";
import { ExamRestrictions } from "@components/orgs/settings/setting/sharedPartials/examRestrictions/ExamRestrictions";

import {
  Checkbox,
  Divider,
  Form,
  FormGroup,
  Label,
  Msg,
  Select,
  ValidationMessage,
} from "@shared/components";
import {
  SlackIntegrationInputModel,
  SlackChannelModel,
  SlackIntegrationModel,
} from "@shared/models";
import {
  IntegrationSocialEvent,
  SpokenLanguages,
} from "@shared/services/enums";
import Message from "@shared/services/message";

export interface SettingSlackIntegrationFormType {
  errors?: {};
  formValues: SlackIntegrationInputModel;
  formValid: boolean;
}

interface SettingSlackIntegrationFormProps {
  initialValues: SlackIntegrationInputModel;
  socialChannels: SlackChannelModel[];
  form: SettingSlackIntegrationFormType;
  errorIntegrations?: SlackIntegrationModel[];
  showDefaultError?: boolean;
  onFormChange?: (form: SettingSlackIntegrationFormType) => void;
}

interface SettingSlackIntegrationFormValues {
  channelId: string;
  language: SpokenLanguages;
  submittedExam: boolean;
  dailySummary: boolean;
  newUser: boolean;
  subscribedToAllExamNotifications: boolean;
  examNotificationSubscriptions: number[];
}

export const SettingSlackIntegrationForm: React.FunctionComponent<
  SettingSlackIntegrationFormProps
> = ({
  socialChannels,
  errorIntegrations,
  showDefaultError,
  initialValues,
  form,
  onFormChange,
}: SettingSlackIntegrationFormProps) => {
  const getFormattedvalues = (input: SlackIntegrationInputModel) => {
    const {
      channelId,
      language,
      events,
      examNotificationSubscriptions,
      subscribedToAllExamNotifications,
    } = input;

    return {
      channelId,
      language,
      submittedExam: events.includes(IntegrationSocialEvent.SubmittedExam),
      dailySummary: events.includes(IntegrationSocialEvent.DailySummary),
      newUser: events.includes(IntegrationSocialEvent.NewUser),
      examNotificationSubscriptions,
      subscribedToAllExamNotifications,
    };
  };

  const formattedInitialValues: SettingSlackIntegrationFormValues =
    React.useMemo(() => getFormattedvalues(initialValues), [initialValues]);

  const formattedUpdateValues: SettingSlackIntegrationFormValues | undefined =
    React.useMemo(() => getFormattedvalues(form.formValues), [form.formValues]);

  const { dailySummary, submittedExam, subscribedToAllExamNotifications } =
    formattedUpdateValues;

  const shouldShowExamRestrictions =
    (dailySummary || submittedExam) && !subscribedToAllExamNotifications;

  const options = React.useMemo(() => {
    return [...socialChannels]
      .sort((a, b) => (a.channelName > b.channelName ? 1 : -1))
      .map((socialChannel) => ({
        value: socialChannel.channelId,
        label: `${socialChannel.isPrivateChannel ? "" : "# "}${
          socialChannel.channelName
        }`,
      }));
  }, [socialChannels]);

  const isExamRestrictionsEnabled = Boolean(
    form.formValues?.events?.some((event) =>
      [
        IntegrationSocialEvent.DailySummary,
        IntegrationSocialEvent.SubmittedExam,
      ].includes(event),
    ),
  );

  const errorChannelIds =
    errorIntegrations?.map((integration) => integration.channelId) ?? [];

  const errorsByChannelId =
    errorIntegrations?.reduce((arr, integration) => {
      arr[integration.channelId] =
        SlackIntegrationModel.getIntegrationErrorString(
          integration.mostRecentDeliveryResult?.error ?? "",
        );
      return arr;
    }, {}) ?? {};

  const errorString = form.formValues.channelId
    ? errorsByChannelId[form.formValues.channelId]
    : "";

  return (
    <Form
      className="code-setting-integration-form"
      initialValues={formattedInitialValues}
      updateValues={formattedUpdateValues}
      showError={{
        ...(showDefaultError && { channelId: true }),
      }}
      error={{
        ...(errorString && {
          channelId: errorString,
        }),
      }}
      validation={{
        channelId: ["string", "required", ["invalid", errorChannelIds ?? []]],
        checkboxGroup: {
          submittedExam: ["boolean", ["empty", "false"]],
          dailySummary: ["boolean", ["empty", "false"]],
          newUser: ["boolean", ["empty", "false"]],
        },
      }}
      onFormChange={(
        formValid: boolean,
        newFormValues: SettingSlackIntegrationFormValues,
        errors?: {},
      ) => {
        const {
          channelId,
          submittedExam,
          dailySummary,
          newUser,
          language,
          examNotificationSubscriptions,
          subscribedToAllExamNotifications,
        } = newFormValues;

        onFormChange?.({
          formValid,
          formValues: {
            channelId,
            language,
            events: [
              submittedExam && IntegrationSocialEvent.SubmittedExam,
              dailySummary && IntegrationSocialEvent.DailySummary,
              newUser && IntegrationSocialEvent.NewUser,
            ].filter(Boolean) as IntegrationSocialEvent[],
            examNotificationSubscriptions,
            subscribedToAllExamNotifications,
          },
          errors,
        });
      }}
    >
      <FormGroup>
        <Label title={<Msg id="slack.channel" />} />
        <Select
          name="channelId"
          disabled={!socialChannels.length}
          options={[
            {
              value: "",
              label: Message.getMessageByKey("slack.selectChannel"),
            },
            ...options,
          ]}
        />
      </FormGroup>

      <FormGroup>
        <Label title={<Msg id="form.language" />} />
        <Select
          name="language"
          options={[
            { label: Message.getMessageByKey("ja"), value: "ja" },
            { label: Message.getMessageByKey("en"), value: "en" },
          ]}
        />
      </FormGroup>
      <FormGroup>
        <Label>
          <Msg id="slack.notifications" />
        </Label>
        <div className="code-setting-integration-form__section">
          <Label>
            <Msg id="form.notificationType" />
          </Label>
          <div className="code-setting-integration-form__checkboxes">
            <Checkbox name="newUser">
              <Msg id="form.notifications.userInviteAcceptance" />
            </Checkbox>
            <Divider />
            <div>
              <Checkbox name="dailySummary">
                <Msg id="form.notifications.submissionsDaily" />
              </Checkbox>
              <Checkbox name="submittedExam">
                <Msg id="form.notifications.submissionsRealtime" />
              </Checkbox>
            </div>
            <ExamRestrictionOptions
              className="code-setting-integration-form__section"
              onFormChange={({ subscribedToAll }) => {
                onFormChange?.({
                  ...form,
                  formValues: {
                    ...form.formValues,
                    subscribedToAllExamNotifications: subscribedToAll,
                    examNotificationSubscriptions: [],
                  },
                });
              }}
              subscribedToAll={initialValues.subscribedToAllExamNotifications}
              disabled={!isExamRestrictionsEnabled}
            />
            {form.formValues?.events?.length === 0 && (
              <ValidationMessage
                name="checkboxGroup"
                error={{
                  checkboxGroup: Message.getMessageByKey(
                    "validation.object.missing",
                  ),
                }}
              />
            )}
          </div>
          {shouldShowExamRestrictions && (
            <ExamRestrictions
              disabled={!isExamRestrictionsEnabled}
              examNotificationSubscriptions={
                formattedUpdateValues.examNotificationSubscriptions
              }
              onFormChange={({ selectedExamIds }) => {
                onFormChange?.({
                  ...form,
                  formValues: {
                    ...form.formValues,
                    examNotificationSubscriptions: selectedExamIds,
                  },
                });
              }}
            />
          )}
        </div>
      </FormGroup>
    </Form>
  );
};
