import * as classnames from "classnames";
import * as React from "react";

import {
  ActionDropdown,
  Msg,
  Restricted,
  Icon,
  Tooltip,
} from "@shared/components";
import { ChallengeModel } from "@shared/models";
import { ProjectRole, UserRole, ChallengeStyle } from "@shared/services/enums";
import Message from "@shared/services/message";

/**
 * Props
 */
export interface ChallengeActionDropdownProps {
  isGivery: boolean;
  challengeAllowed?: boolean;
  copyAllowed?: boolean;
  challenge: ChallengeModel;
  onClickEdit: () => void;
  onClickCopy: () => void;
  onClickDelete: () => void;
}

export const ChallengeActionDropdown: React.FunctionComponent<
  ChallengeActionDropdownProps
> = (props: ChallengeActionDropdownProps) => {
  const {
    challenge,
    isGivery,
    challengeAllowed = true,
    copyAllowed = true,
  } = props;

  const rootStyle = classnames("code-challenge-list-action");

  return (
    <ActionDropdown className={rootStyle}>
      <Restricted
        roles={[
          UserRole.SystemAdmin,
          UserRole.ChallengeCreator,
          ProjectRole.ProjectAdmin,
          ProjectRole.ChallengeCreator,
        ]}
        strictAllow={!isGivery && challenge.style === ChallengeStyle.Quiz}
      >
        <Tooltip
          text={Message.getMessageByKey("tier.disabled.notSupported")}
          placement="top-end"
          disabled={challengeAllowed && copyAllowed}
        >
          <a
            role="menuitem"
            className={classnames({
              "code-challenge-list-action__disable":
                !challengeAllowed || !copyAllowed,
            })}
            onClick={
              challengeAllowed && copyAllowed ? props.onClickCopy : undefined
            }
          >
            <Icon type="copy" />
            <Msg id="copy" />
          </a>
        </Tooltip>
      </Restricted>
      <Restricted
        roles={[
          UserRole.SystemAdmin,
          UserRole.ChallengeCreator,
          ProjectRole.ProjectAdmin,
          ProjectRole.ChallengeCreator,
        ]}
        strictAllow={
          !isGivery &&
          !challenge.isOfficial &&
          challenge.style === ChallengeStyle.Quiz
        }
      >
        <Tooltip
          text={Message.getMessageByKey("tier.disabled.notSupported")}
          placement="top-end"
          disabled={challengeAllowed}
        >
          <a
            role="menuitem"
            className={classnames({
              "code-challenge-list-action__disable": !challengeAllowed,
            })}
            onClick={challengeAllowed ? props.onClickEdit : undefined}
          >
            <Icon type="pencil" />
            <Msg id="edit" />
          </a>
        </Tooltip>
      </Restricted>
      <Restricted
        roles={[
          UserRole.ChallengeCreator,
          ProjectRole.ProjectAdmin,
          ProjectRole.ChallengeCreator,
        ]}
        strictAllow={
          !isGivery &&
          !challenge.isOfficial &&
          challenge.style === ChallengeStyle.Quiz
        }
      >
        <a role="menuitem" onClick={props.onClickDelete}>
          <Icon type="trash" />
          <Msg id="delete" />
        </a>
      </Restricted>
    </ActionDropdown>
  );
};
