import { Epic } from "redux-observable";

import {
  actions,
  Action,
  addToPayload,
  APIResponseAction,
  alertAction,
  redirectAction,
  ajaxAction,
  orgsGetListAllAction,
  orgsGetAction,
  orgsErrorAction,
  adminOrgSetAction,
  orgsCreateAction,
  orgsDeleteAction,
  orgsUpdateAction,
  orgsGetListAction,
  orgsGitListGetAction,
  orgsGitListResetAction,
  orgsGitUpdateAction,
  orgsGitDeleteAction,
  orgsUserUpdateAction,
  orgsUserListGetAction,
  orgsUserInviteAction,
  orgsUserDeactivateAction,
  orgsDeactivateAction,
  orgsReactivateAction,
  orgsUpdateSecurityAction,
  adminContractListAction,
  adminContractCreateAction,
  adminContractUpdateAction,
  adminContractDeleteAction,
  adminExamCopyOrgGetAction,
  adminExamCopyAction,
  orgsResetListAllAction,
  allActionsGetAction,
  allTiersGetAction,
} from "@actions";

import { RootState } from "@reducers";

import {
  OrganizationModel,
  AdminOrganizationNewParamsModel,
} from "@shared/models";
import {
  AtsKind,
  OrganizationStatus,
  OrganizationKind,
} from "@shared/services/enums";
import Message from "@shared/services/message";

const orgsGetRequestEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(orgsGetAction.types.request).map((action) =>
    ajaxAction.request({
      method: "get",
      url: `/api/admin/orgs/${action.payload}`,
      success: orgsGetAction.success,
      error: redirectAction("/org/givery", orgsErrorAction.request),
    }),
  );

const orgsGetSuccessEpic: Epic<Action, RootState> = (action$, state) =>
  action$
    .ofType(orgsGetAction.types.success)
    .map(({ payload }: APIResponseAction) =>
      adminOrgSetAction.request(payload ? payload.result : {}),
    );

const orgsCreateEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(orgsCreateAction.types.request).map((action) => {
    const {
      name,
      displayName,
      imageUrl,
      applicationTypes,
      isVerifyEnable,
      sharingGroupIds,
      tier,
      whitelistedActions,
      subCategory,
      contestSharingGroups,
      trainingSharingGroups,
    } = (action.payload as AdminOrganizationNewParamsModel).orgFormValue;

    const body = {
      name,
      displayName,
      imageUrl,
      applicationTypes,
      sharingGroupIds,
      tier,
      whitelistedActions,
      contestSharingGroups,
      trainingSharingGroups,
      ...(isVerifyEnable && { createVerifyOrganization: true }),
      ...(subCategory && { subCategory }),
    };

    return ajaxAction.request({
      method: "post",
      url: "/api/admin/orgs",
      body,
      success: addToPayload(
        { input: action.payload },
        alertAction.success(
          Message.getMessageByKey("message.organization.updated"),
          orgsCreateAction.success,
        ),
      ),
      error: (error: string) =>
        alertAction.error(
          error.includes("409")
            ? Message.getMessageByKey("message.organization.conflict")
            : error,
        )(error),
      options: {
        disableDefaultError: true,
      },
    });
  });

const orgsCreatePostEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(orgsCreateAction.types.success).mergeMap((action) => {
    const postActions: Action[] = [];
    const {
      input: { orgFormValue, integrations, gitToken, ipWhitelist, mfaRequired },
    } = action.payload as {
      result: OrganizationModel;
      input: AdminOrganizationNewParamsModel;
    };

    integrations.forEach(
      (item: {
        atsKind: AtsKind;
        enabled: boolean;
        notificationEmail?: string;
      }) => {
        postActions.push(
          actions.externalSystemCreateAction.request({
            formValues: { ...item },
            organizationName: orgFormValue.name,
          }),
        );
      },
    );
    if (gitToken) {
      postActions.push(
        orgsGitUpdateAction.request(
          { token: gitToken, kind: 1 },
          orgFormValue.name,
        ),
      );
    }
    if (ipWhitelist || mfaRequired) {
      postActions.push(
        orgsUpdateSecurityAction.request(
          { ipWhitelist, mfaRequired },
          orgFormValue.name,
        ),
      );
    }
    return postActions;
  });

const orgsDeleteEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(orgsDeleteAction.types.request).map((action) => {
    return ajaxAction.request({
      method: "delete",
      url: `/api/admin/orgs/${action.payload}`,
      success: alertAction.success(
        Message.getMessageByKey("message.organization.deleted"),
        orgsDeleteAction.success,
      ),
      error: alertAction.error(
        Message.getMessageByKey("message.organization.cannotDelete"),
      ),
      options: {
        disableDefaultError: true,
      },
    });
  });

const orgsDeactivateEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(orgsDeactivateAction.types.request).map((action) => {
    const { organizationName, verifyTargetId } = (
      action as APIResponseAction & {
        payload: {
          organizationId: number;
          organizationName: string;
          verifyTargetId?: number;
        };
      }
    ).payload;
    return ajaxAction.request({
      method: "put",
      url: `/api/admin/orgs/${organizationName}`,
      body: {
        status: OrganizationStatus.Inactive,
        verifyTargetId,
      },
      success: alertAction.success(
        Message.getMessageByKey("message.organization.deactivated"),
        orgsDeactivateAction.success,
      ),
    });
  });

const orgsReactivateEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(orgsReactivateAction.types.request).map((action) => {
    const { organizationName, verifyTargetId } = (
      action as APIResponseAction & {
        payload: {
          organizationId: number;
          organizationName: string;
          verifyTargetId?: number;
        };
      }
    ).payload;
    return ajaxAction.request({
      method: "put",
      url: `/api/admin/orgs/${organizationName}`,
      body: {
        status: OrganizationStatus.Active,
        verifyTargetId,
      },
      success: alertAction.success(
        Message.getMessageByKey("message.organization.reactivated"),
        orgsReactivateAction.success,
      ),
    });
  });

const orgsUpdateEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(orgsUpdateAction.types.request).map((action) => {
    const { isVerifyEnable, securitySetting, ...rest } =
      action.payload as AdminOrganizationNewParamsModel["orgFormValue"] & {
        securitySetting: {
          ipWhitelist: string[];
          mfaRequired: boolean;
        };
      };
    const body = {
      ...(isVerifyEnable && { createVerifyOrganization: true }),
      ...rest,
    };
    return ajaxAction.request({
      method: "put",
      url: `/api/admin/orgs/${(action.payload as { name: string }).name}`,
      body,
      success: addToPayload(
        { input: { ...body, securitySetting } },
        alertAction.success(
          Message.getMessageByKey("message.organization.updated"),
          orgsUpdateAction.success,
        ),
      ),
    });
  });

const orgsListGetEpic: Epic<Action, RootState> = (action$, state) =>
  action$
    .ofType(orgsGetListAction.types.request)
    .debounceTime(275)
    .map((action) =>
      ajaxAction.request({
        method: "get",
        url: `/api/admin/orgs`,
        body: {
          ...action.payload,
          kind: [OrganizationKind.Normal, OrganizationKind.Official],
        },
        success: orgsGetListAction.success,
      }),
    );

const orgsUpdateSuccessEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(orgsUpdateAction.types.success).map(({ payload }) => {
    const { input } = payload as {
      input: AdminOrganizationNewParamsModel["orgFormValue"] & {
        securitySetting: {
          ipWhitelist: string[];
          mfaRequired: boolean;
        };
      };
    };
    if (input.securitySetting) {
      return orgsUpdateSecurityAction.request(
        input.securitySetting,
        input.name,
      );
    } else {
      return { type: "@null" };
    }
  });

const orgsListGetAllEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(orgsGetListAllAction.types.request).map((action) =>
    ajaxAction.request({
      method: "get",
      url: `/api/admin/orgs`,
      body: {
        limit: 50,
        ...action.payload,
        status: OrganizationStatus.Active,
        kind: [OrganizationKind.Normal, OrganizationKind.Official],
      },
      success: orgsGetListAllAction.success,
      options: {
        disableDefaultError: true,
        background: true,
      },
    }),
  );

// Note: This will trigger repeated requests until all orgs are fetched. This is slow on dev as there are many orgs.
// However it's not an issue (yet) on prod as there are only about 100 orgs.
const orgsListSetAllEpic: Epic<Action, RootState> = (action$, state) =>
  action$
    .ofType(orgsGetListAllAction.types.success)
    .map(({ payload: { pagination } }: APIResponseAction) => {
      if (
        pagination &&
        pagination.count > pagination.offset + pagination.limit
      ) {
        return orgsGetListAllAction.request({
          offset: pagination.offset + pagination.limit,
        });
      } else {
        const orgname = state.getState().admin.selectedOrg.name;
        if (orgname) {
          return orgsGetAction.request(orgname);
        } else {
          return { type: "@null" };
        }
      }
    });

const orgsResetListAllPreEpic: Epic<Action, RootState> = (action$, state) =>
  action$
    .ofType(
      orgsCreateAction.types.success,
      orgsUpdateAction.types.success,
      orgsDeactivateAction.types.success,
      orgsReactivateAction.types.success,
      orgsDeleteAction.types.success,
    )
    .mergeMap(() => [
      orgsGetListAction.request(
        state.getState().orgs.organizationListConditions,
      ),
      orgsResetListAllAction.request(),
    ]);

const orgsResetListAllEpic: Epic<Action, RootState> = (action$, state) =>
  action$
    .ofType(orgsResetListAllAction.types.request)
    .map(() => orgsGetListAllAction.request());

const orgsGitListGetEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(orgsGitListGetAction.types.request).map((action) =>
    ajaxAction.request({
      method: "get",
      url: `/api/admin/orgs/${action.payload}/org/gits`,

      // For now, just use first result if exists
      success: (response: { result: Array<{}> }) =>
        orgsGitListGetAction.success({ result: response.result[0] || {} }),
    }),
  );

const orgsGitListResetEpic: Epic<Action, RootState> = (action$, state) =>
  action$
    .ofType(orgsGitListResetAction.types.request)
    .map((action) => orgsGitListResetAction.success());

const orgsGitUpdateEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(orgsGitUpdateAction.types.request).map((action) => {
    // update single git or create new if none exists
    const { id } = state.getState().orgs.organizationGit;
    const method = id ? "put" : "post";
    const url = `/api/admin/orgs/${action.params}/org/gits${
      id ? "/" + id : ""
    }`;
    return ajaxAction.request({
      method,
      url,
      body: action.payload,
      success: alertAction.success(
        Message.getMessageByKey("message.organization.updated"),
        orgsGitUpdateAction.success,
      ),
    });
  });

const orgsGitDeleteEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(orgsGitDeleteAction.types.request).map((action) => {
    const { id } = state.getState().orgs.organizationGit;
    return ajaxAction.request({
      method: "delete",
      url: `/api/admin/orgs/${action.payload}/org/gits/${id}`,
      success: alertAction.success(
        Message.getMessageByKey("message.organization.updated"),
        orgsGitDeleteAction.success,
      ),
    });
  });

const orgsUserInviteEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(orgsUserInviteAction.types.request).map((action) => {
    return ajaxAction.request({
      method: "post",
      url: `/api/admin/orgs/${state.getState().admin.selectedOrg.name}/users`,
      body: action.payload,
      success: alertAction.success(
        Message.getMessageByKey("message.invitation.sent"),
      ),
    });
  });

const orgsUserUpdateEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(orgsUserUpdateAction.types.request).map((action) => {
    return ajaxAction.request({
      method: "put",
      url: `/api/admin/orgs/${state.getState().admin.selectedOrg.name}/users/${
        action.payload
      }/roles`,
      body: action.params,
      success: alertAction.success(
        Message.getMessageByKey("message.member.updated"),
        orgsUserUpdateAction.success,
      ),
    });
  });

const orgsUserListGetEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(orgsUserListGetAction.types.request).map((action) =>
    ajaxAction.request({
      method: "get",
      url: `/api/admin/orgs/${state.getState().admin.selectedOrg.name}/users`,
      body: action.payload,
      success: orgsUserListGetAction.success,
      cancel: [orgsUserListGetAction.types.request],
    }),
  );

const orgsUserDeactivateEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(orgsUserDeactivateAction.types.request).map((action) => {
    return ajaxAction.request({
      method: "put",
      url: `/api/admin/orgs/${state.getState().admin.selectedOrg.name}/users/${
        action.payload
      }`,
      body: { status: 10 },
      success: alertAction.success(
        Message.getMessageByKey("message.member.deactivated"),
        orgsUserDeactivateAction.success,
      ),
    });
  });

const orgsAdminSetOrgEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(adminOrgSetAction.types.request).map((action) => {
    const orgname = (action.payload as OrganizationModel).name;
    const changeOrg = action.params;

    const pathname = location ? location.pathname.split("/") : [];

    if (changeOrg && pathname[2] !== orgname) {
      return redirectAction(`/org/${orgname}`, orgsGetAction.request)(orgname);
    } else {
      return { type: "@null" };
    }
  });

const orgsUpdateSecurityRequestEpic: Epic<Action, RootState> = (
  action$,
  state,
) =>
  action$.ofType(orgsUpdateSecurityAction.types.request).map((action) =>
    ajaxAction.request({
      method: "put",
      url: `/api/admin/orgs/${action.params as string}/security`,
      body: action.payload,
      success: alertAction.success(
        Message.getMessageByKey("message.organization.updated"),
        orgsGetAction.success,
      ),
    }),
  );

const adminContractListRequestEpic: Epic<Action, RootState> = (
  action$,
  state,
) =>
  action$.ofType(adminContractListAction.types.request).map((action) =>
    ajaxAction.request({
      method: "get",
      url: `/api/admin/orgs/${
        state.getState().admin.selectedOrg.name
      }/contracts`,
      body: action.payload,
      success: adminContractListAction.success,
      cancel: [adminContractListAction.types.request],
    }),
  );

const adminContractCreateRequestEpic: Epic<Action, RootState> = (
  action$,
  state,
) =>
  action$.ofType(adminContractCreateAction.types.request).map((action) =>
    ajaxAction.request({
      method: "post",
      url: `/api/admin/orgs/${
        state.getState().admin.selectedOrg.name
      }/contracts`,
      body: action.payload,
      success: alertAction.success(
        "Created Contract Successfully",
        adminContractCreateAction.success,
      ),
      error: adminContractCreateAction.failure,
    }),
  );

const adminContractUpdateRequestEpic: Epic<Action, RootState> = (
  action$,
  state,
) =>
  action$.ofType(adminContractUpdateAction.types.request).map((action) =>
    ajaxAction.request({
      method: "put",
      url: `/api/admin/orgs/${
        state.getState().admin.selectedOrg.name
      }/contracts/${action.params}`,
      body: action.payload,
      success: alertAction.success(
        "Updated Contract Successfully",
        adminContractUpdateAction.success,
      ),
      error: adminContractUpdateAction.failure,
    }),
  );

const adminContractDeleteRequestEpic: Epic<Action, RootState> = (
  action$,
  state,
) =>
  action$.ofType(adminContractDeleteAction.types.request).map((action) =>
    ajaxAction.request({
      method: "delete",
      url: `/api/admin/orgs/${
        state.getState().admin.selectedOrg.name
      }/contracts/${action.payload}`,
      success: alertAction.success("Deleted Contract Successfully", () =>
        adminContractDeleteAction.success(action.payload),
      ),
      error: adminContractDeleteAction.failure,
    }),
  );

const adminExamCopyOrgGetEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(adminExamCopyOrgGetAction.types.request).map((action) =>
    ajaxAction.request({
      method: "get",
      url: `/api/admin/orgs/${action.payload}`,
      success: adminExamCopyOrgGetAction.success,
      error: adminExamCopyOrgGetAction.failure,
    }),
  );

const adminExamCopyEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(adminExamCopyAction.types.request).map((action) => {
    const { orgname, projectId, sourceExamId } = action.payload as {
      orgname: string;
      projectId: number;
      sourceExamId: number;
    };
    return ajaxAction.request({
      method: "post",
      url: `/api/admin/orgs/${orgname}/projects/${projectId}/exams/copy`,
      body: { sourceExamId },
      success: alertAction.success(
        "Exam Copied Successfully",
        adminExamCopyAction.success,
      ),
      error: adminExamCopyAction.failure,
    });
  });

const allActionsGetEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(allActionsGetAction.types.request).map((action) =>
    ajaxAction.request({
      method: "get",
      url: `/api/enum/organization/actions`,
      success: allActionsGetAction.success,
      error: allActionsGetAction.failure,
    }),
  );

const allTiersGetEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(allTiersGetAction.types.request).map((action) =>
    ajaxAction.request({
      method: "get",
      url: `/api/enum/organization/tiers`,
      success: allTiersGetAction.success,
      error: allTiersGetAction.failure,
    }),
  );

export default [
  orgsGetRequestEpic,
  orgsGetSuccessEpic,
  orgsGitListGetEpic,
  orgsGitUpdateEpic,
  orgsGitDeleteEpic,
  orgsGitListResetEpic,
  orgsCreateEpic,
  orgsCreatePostEpic,
  orgsDeleteEpic,
  orgsDeactivateEpic,
  orgsReactivateEpic,
  orgsUpdateEpic,
  orgsListGetEpic,
  orgsListGetAllEpic,
  orgsListSetAllEpic,
  orgsResetListAllPreEpic,
  orgsResetListAllEpic,
  orgsUserInviteEpic,
  orgsUserUpdateEpic,
  orgsUserListGetEpic,
  orgsUserDeactivateEpic,
  orgsAdminSetOrgEpic,
  orgsUpdateSecurityRequestEpic,
  adminContractListRequestEpic,
  adminContractCreateRequestEpic,
  adminContractUpdateRequestEpic,
  adminContractDeleteRequestEpic,
  adminExamCopyOrgGetEpic,
  adminExamCopyEpic,
  allActionsGetEpic,
  allTiersGetEpic,
  orgsUpdateSuccessEpic,
];
