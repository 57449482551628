import * as React from "react";

import {
  useGetSubmissionsChallengeResultPreview,
  useGetSubmissionsPreviewContent,
} from "@shared/hooks/query";

import { DiffViewerProps, DiffViewerSelection } from "./DiffViewer";
import { preparePreviewContent, preparePreviewContext } from "./DiffViewerUtil";

export const useDiffViewerData = ({
  examId,
  challengeId,
  applicantExamId,
  userLanguage,
  ...props
}: DiffViewerProps & DiffViewerSelection) => {
  const { data: challengeResultPreview } =
    useGetSubmissionsChallengeResultPreview(challengeId);
  const initialHistoryId = challengeResultPreview?.initialHistoryId || 0;
  const challengeResultId =
    challengeResultPreview?.context?.challengeResultId || 0;
  const preparedPreviewContext = React.useMemo(
    () => ({
      ...preparePreviewContext(challengeResultPreview),
      userLanguage,
    }),
    [challengeResultPreview, userLanguage],
  );
  const originalHistoryId = props.originalHistoryId || initialHistoryId;
  const modifiedHistoryId =
    props.modifiedHistoryId ||
    preparedPreviewContext?.historyIdWithHighestScore ||
    0;
  const { data: originalPreview } = useGetSubmissionsPreviewContent(
    challengeResultId,
    originalHistoryId,
  );
  const { data: modifiedPreview } = useGetSubmissionsPreviewContent(
    challengeResultId,
    modifiedHistoryId,
  );

  const originalPreviewContent = preparePreviewContent(originalPreview);
  const modifiedPreviewContent = preparePreviewContent(modifiedPreview);

  return {
    challengeResultPreview,
    preparedPreviewContext,
    originalPreviewContent,
    modifiedPreviewContent,
  };
};
