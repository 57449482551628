class OfficialExamListModel {
  public id: number;
  public name: string;
  public examinationTime: number;
  public usingOrganizationsCount: number;
  public naturalLanguage: string;
  public isNew: boolean;
  public updatedAt: string;
  public coverImageUrl: string;
  public examSegment: {
    engineerRole: number;
    purposeOfUse: string;
  };

  constructor(args?: {}) {
    Object.assign(this, args);
  }
}

export default OfficialExamListModel;
