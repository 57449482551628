import * as c3 from "c3";

import { formatYTick, getPaddingLeft, getXMax } from "@shared/services/chart";
import { formatDateTimeSeconds } from "@shared/services/date";
import { ChartColor } from "@shared/services/enums";

import { HistoryGraphItem } from "../../HistoryGraph";
import { DataModel } from "../../HistoryGraph";
import { getTooltipElementString } from "../historyGraphTooptip/HistoryGraphTooltip";

/**
 * Prop interface
 */
export interface ChartApiProps {
  id: string;
  items: HistoryGraphItem[];
  isAIChallenge: boolean;
  scoreMax: number;
  scoreMin?: number;
  openedAt?: string;
  submittedAt?: string;
}

export const getChartConfiguration = ({
  id,
  isAIChallenge,
  items,
  scoreMax,
  scoreMin,
  openedAt,
  submittedAt,
}: ChartApiProps): c3.ChartConfiguration => {
  const xItems = items.map((item) => formatDateTimeSeconds(item.datetime));
  const yItems = items.map((item) => item.value);
  const leftPadding = 60 + 5 * String(Math.floor(scoreMax)).length;

  return {
    bindto: `#${id}`,
    padding: {
      top: 0,
      right: 40,
      bottom: 0,
      left: leftPadding,
    },
    legend: {
      show: false,
    },
    grid: {
      y: {
        show: true,
        lines: scoreMin && scoreMin !== 0 ? [{ value: 0 }] : [],
      },
    },
    data: {
      x: "date",
      xFormat: "%Y/%m/%d %H:%M:%S",
      columns: [
        ["date", ...xItems],
        ["value", ...yItems],
      ],
      colors: {
        value: ChartColor.Primary,
      },
      types: {
        value: "area",
      },
      axes: {
        value: "y",
      },
    },
    subchart: {
      show: true,
      size: {
        height: 30,
      },
    },
    point: {
      r: 3.5,
    },
    axis: {
      y: {
        tick: {
          format: (y: number) => formatYTick(isAIChallenge, y, scoreMax),
        },
        max: scoreMax,
        min: scoreMin || 0,
        padding: {
          bottom: 0,
        },
      },
      x: {
        type: "timeseries",
        localtime: true,
        tick: {
          fit: false,
          format: "%m/%d %H:%M",
        },
        padding: {
          left: getPaddingLeft(items, submittedAt),
        },
        max: getXMax(items, submittedAt),
      },
    },
    tooltip: {
      format: {
        title: (d: string) => new Date(d).toLocaleString("ja-JP"),
      },
      contents: (data: DataModel[]) =>
        getTooltipElementString({
          data,
          items,
          startedAt: openedAt || xItems[0],
          isAIChallenge,
          scoreMax,
        }),
    },
  };
};
