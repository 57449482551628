import { AxiosRequestConfig } from "axios";

import { EnumModel, EnumWithStringValueModel } from "@shared/models";

import { get } from "./httpClient";

export { EnumWithStringValueModel };

export async function getApplicantActions(options?: AxiosRequestConfig) {
  const { data } = await get<EnumWithStringValueModel[]>(
    "/api/enum/applicant/actions",
    options,
  );

  return data;
}

export async function getInvitationStatus(options?: AxiosRequestConfig) {
  const { data } = await get<EnumWithStringValueModel[]>(
    "/api/enum/users/invitationstatuses",
    options,
  );

  return data;
}
export async function getExamJobTitles(options?: AxiosRequestConfig) {
  const { data } = await get<EnumModel[]>("/api/enum/exams/roles", options);

  return data;
}

export async function getPurposesOfUse(options?: AxiosRequestConfig) {
  const { data } = await get<EnumWithStringValueModel[]>(
    "/api/enum/exams/purposesofuse",
    options,
  );

  return data;
}

export async function getSegmentApplicantExperiences(
  options?: AxiosRequestConfig,
) {
  const { data } = await get<EnumWithStringValueModel[]>(
    "/api/enum/applicant/segmentexperiences",
    options,
  );

  return data;
}

export async function getOrgMainCategories(options?: AxiosRequestConfig) {
  const { data } = await get<EnumWithStringValueModel[]>(
    "/api/enum/organization/mainCategories",
    options,
  );

  return data;
}

export async function getFileScanningStatuses(options?: AxiosRequestConfig) {
  const { data } = await get<EnumWithStringValueModel[]>(
    "/api/enum/users/filescanningstatuses",
    options,
  );

  return data;
}

export async function getOrgSubCategories(options?: AxiosRequestConfig) {
  const { data } = await get<EnumModel[]>(
    "/api/enum/organization/subCategories",
    options,
  );

  return data;
}

export async function getSkippedFileScanReasons(options?: AxiosRequestConfig) {
  const { data } = await get<EnumWithStringValueModel[]>(
    "/api/enum/users/skippedfilescanreasons",
    options,
  );

  return data;
}

export async function getGuestReportCategoryEnums(
  options?: AxiosRequestConfig,
) {
  const { data } = await get<EnumModel[]>(`/api/enum/questions/tags`, options);

  return data;
}
