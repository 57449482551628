import * as classnames from "classnames";
import * as React from "react";

import { ErrorMessage, ValidationMessage, hasValidationError } from "..";

/**
 * Prop interface
 */
export interface RadioPanelProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  labelClassName?: string;
  error?: { [key: string]: string };
  onChange?: (event: React.FormEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FormEvent<HTMLInputElement>) => void;
  children?: React.ReactNode;
  ariaLabel?: string;
  ariaPressed?: boolean;
  alignCircle?: "top" | "bottom" | "center";
  initialLineHeight?: string; // If we want the circle to be aligned vertically with the first line of the content then set this prop to the line-height of the first line. Needs to include a unit, e.g. "1.5rem"
  width?: string;
  maxWidth?: string;
  errorMessage?: string;
  dataTestautomationid: string;
}

/**
 * React Component
 */
export default function RadioPanel({
  name,
  value,
  defaultValue,
  checked,
  readOnly,
  onChange,
  onBlur,
  error,
  className,
  children,
  labelClassName,
  ariaLabel,
  ariaPressed,
  role,
  alignCircle = "top",
  initialLineHeight,
  width,
  maxWidth,
  errorMessage,
  dataTestautomationid,
}: RadioPanelProps) {
  const isChecked = checked !== undefined ? checked : value === defaultValue;

  const rootStyle = classnames("code-c-radio-panel", "radio", {
    [`${className}`]: Boolean(className),
    "is-checked": isChecked,
  });
  const labelStyle = classnames("code-c-radio-panel__label", labelClassName, {
    "is-readonly": readOnly,
    "is-danger": hasValidationError(name, error),
  });

  return (
    <div className={rootStyle}>
      <label
        className={classnames(labelStyle, [`is-align-${alignCircle}`])}
        style={{ width: width, maxWidth: maxWidth }}
        aria-label={ariaLabel}
        aria-pressed={role === "button" ? ariaPressed : undefined}
        role={role}
      >
        <div
          className="code-c-radio-panel__checkmark-wrapper"
          style={{ height: initialLineHeight }}
        >
          <input
            className="code-c-radio-panel__radio"
            type="radio"
            value={defaultValue}
            name={name}
            checked={isChecked}
            onChange={onChange}
            onBlur={onBlur}
            disabled={readOnly}
            readOnly={readOnly}
            data-testautomationid={dataTestautomationid}
          />
          <span className="code-c-radio-panel__checkmark-container">
            <span className="code-c-radio-panel__checkmark" />
          </span>
        </div>
        <div className="code-c-radio-panel__label__text">{children}</div>
      </label>
      <ErrorMessage message={errorMessage} />
      <ValidationMessage name={name} error={error} />
    </div>
  );
}
