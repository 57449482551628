import { parse as fibParser } from "../services/fibParser";
import QuestionModel from "./Question.model";

interface QuestionFIBModelJsonConfig {
  text: string;
  regexp: string;
  caseSensitive: string;
  flags: string;
  maxLength: number;
  defaultValue: string;
  answer: string;
}

class QuestionFIBModel extends QuestionModel {
  private memoizedCorrectRate: {
    exam?: number[];
    global?: number[];
  } = {};

  constructor(args: {}) {
    super(args);
  }

  /**
   * Return user's answer
   */
  public getUserAnswers(): string[] {
    return this.userAnswer.answers || [];
  }

  /**
   * Return correct answers
   */
  public getCorrectAnswer(noBlankets = true): string[] {
    const answers = this.answers.length
      ? (this.answers as string[])
      : this.correctAnswer.answers || [];
    return noBlankets ? answers.map((answer) => fibParser(answer)) : answers;
  }

  private isCorrectAnswerHelper(correctAnswer: string, userAnswer: string) {
    switch (correctAnswer.charAt(0)) {
      case "/": {
        const lastSlash = correctAnswer.lastIndexOf("/");

        let regexString = correctAnswer.substring(1, lastSlash);
        let flags = correctAnswer.substring(lastSlash + 1);

        const commaIndex = flags.indexOf(",");
        if (commaIndex >= 0) {
          regexString = flags.substring(commaIndex + 1);
          flags = flags.substring(0, commaIndex);
        }
        return new RegExp(regexString, flags).test(userAnswer);
      }

      case "{": {
        try {
          const config = JSON.parse(
            correctAnswer,
          ) as QuestionFIBModelJsonConfig;
          if (config.text) {
            return config.caseSensitive
              ? config.text === userAnswer
              : config.text.toLowerCase() === userAnswer.toLowerCase();
          } else if (config.regexp) {
            return new RegExp(config.regexp, config.flags).test(userAnswer);
          } else {
            return correctAnswer === userAnswer;
          }
        } catch (e) {
          return correctAnswer === userAnswer;
        }
      }

      default:
        return correctAnswer === userAnswer;
    }
  }

  /**
   * Return true if the answer is correct
   */
  public isCorrectAnswer(index: number): boolean {
    const correctAnswer = this.getCorrectAnswer()[index];
    const userAnswer = this.getUserAnswers()[index];

    return this.isCorrectAnswerHelper(correctAnswer, userAnswer);
  }

  private getMemoizedCorrectRate(statsLevel: "exam" | "global") {
    const insight =
      statsLevel === "exam" ? this.examLevelInsight : this.globalInsight;
    if (!insight || insight.overall.totalCount === 0) {
      return null;
    }

    const memoizedCorrectRate = this.memoizedCorrectRate[statsLevel];
    if (memoizedCorrectRate) {
      return memoizedCorrectRate;
    }

    const correctAnswers = this.getCorrectAnswer();
    const answerCount = correctAnswers.map(() => 0);

    const { answerDistribution, totalCount } = insight.overall;

    // Interim solution until we have a better way to handle mismatched question versions
    const mismatchedQuestionVersion = answerDistribution.some(
      (entry) =>
        entry.answer.length !== 0 && // Ignore empty answers
        entry.answer.length !== correctAnswers.length,
    );

    if (mismatchedQuestionVersion) {
      return null;
    }

    answerDistribution.forEach((entry) => {
      entry.answer.forEach((answer, index) => {
        if (this.isCorrectAnswerHelper(correctAnswers[index], answer)) {
          answerCount[index] += entry.count;
        }
      });
    });

    this.memoizedCorrectRate[statsLevel] = answerCount.map(
      (count) => count / totalCount,
    );

    return this.memoizedCorrectRate[statsLevel];
  }

  private getCorrectRate(
    index: number,
    statsLevel: "exam" | "global",
  ): number | null {
    return this.getMemoizedCorrectRate(statsLevel)?.[index] ?? null;
  }

  /**
   * Return the global answer rate as formatted string
   * for the correct answer of the given index
   * Empty string will be returned if the global insights is not available
   *
   * @param index
   * @returns string
   */
  public getGlobalCorrectRateAsFormattedString(index: number): string {
    const rate = this.getCorrectRate(index, "global");
    if (rate === null) {
      return "";
    }

    const rounded = Number((rate * 100).toFixed(2));

    return `${rounded}%`;
  }

  /**
   * Return the exam level answer rate as formatted string
   * for the correct answer of the given index
   * Empty string will be returned if the global insights is not available
   *
   * @param index
   * @returns string
   */
  public getExamLevelCorrectRateAsFormattedString(index: number): string {
    const rate = this.getCorrectRate(index, "exam");
    if (rate === null) {
      return "";
    }

    const rounded = Number((rate * 100).toFixed(2));

    return `${rounded}%`;
  }
}

export default QuestionFIBModel;
