import * as classnames from "classnames";
import { Link } from "react-router-dom";

import { useStoreContext } from "@context";

import { useFeatureFlags } from "@shared/hooks/query";

import { Tag, LanguageTag } from "..";
import { SuspiciousCountTooltip } from "../../../components/orgs/submissions/submissionDetail/partials/suspiciousCountTooltip/SuspiciousCountTooltip";
import SubmissionListModel from "../../../shared/models/SubmissionList.model";
import { ApplicantExamStatus } from "../../../shared/services/enums";
import Message from "../../../shared/services/message";
import { NotePopover } from "./noteTooltip/NotePopover";

/**
 * Prop interface
 */
export interface SubmissionTitleColumnProps {
  className?: string;
  submission: SubmissionListModel;
  isNeedReview?: boolean;
}

/**
 * React Component
 */
export default function SubmissionTitleColumn({
  submission,
  isNeedReview,
  className,
}: SubmissionTitleColumnProps) {
  const rootStyle = classnames("code-c-submission-title-column", {
    [`${className}`]: Boolean(className),
  });
  const { projectId } = useStoreContext();
  const nameClassNames = classnames(
    "code-c-submission-title-column__name is-bold",
  );

  const { data: featureFlags } = useFeatureFlags();
  const submissionNoteFeatureFlag = featureFlags?.submissionNote;

  const nameContent = (
    <div className="code-c-submission-title-column__name">
      <span>{submission.applicantFullName || submission.applicantEmail}</span>
    </div>
  );

  const name =
    submission.status === ApplicantExamStatus.Canceled ||
    submission.archivedAt ? (
      nameContent
    ) : (
      <Link className={nameClassNames} to={`submissions/${submission.id}`}>
        {nameContent}
      </Link>
    );

  return (
    <div className={rootStyle}>
      <div>
        <div>
          {name}
          {submissionNoteFeatureFlag && (
            <NotePopover projectId={projectId} submissionId={submission.id} />
          )}
        </div>
        {submission.applicantFullName && (
          <div className="code-c-submission-title-column__applicant-email">
            <small>{submission.applicantEmail}</small>
          </div>
        )}
      </div>
      <div className="code-c-submission-title-column__tags">
        <SuspiciousCountTooltip
          submission={submission}
          className="no-underline"
          limitHeight
        />
        {submission.isTest && (
          <Tag className="code-c-submission-title-column__sample-tag">
            {Message.getMessageByKey("delivery.test.label")}
          </Tag>
        )}
        {submission.language && (
          <LanguageTag language={submission.language} color="pink" />
        )}
        {isNeedReview && (
          <Tag className="code-c-submission-title-column__needs-review-tag">
            {Message.getMessageByKey("submission.needsReview")}
          </Tag>
        )}
      </div>
    </div>
  );
}
