import { CsvTypes } from "@shared/services/enums";
import MSG from "@shared/services/message";
import { getHumanReadableColumnName } from "@shared/services/string";

import {
  Action,
  APIResponseAction,
  orgsUserUpdateAction,
  orgsUserListGetAction,
  orgsUserDeactivateAction,
  orgsGetListAllAction,
  orgsGetAction,
  orgsUpdateSecurityAction,
  orgsGetIPAction,
  orgAllContractsGetAction,
  orgAuditLogGetAction,
  orgsGetListAction,
  orgsGitListGetAction,
  orgsGitListResetAction,
  orgsUserInviteListAction,
  orgsUserSetAction,
  orgsErrorAction,
  grantSystemadminRoleAction,
  revokeSystemadminRoleAction,
  orgsResetListAllAction,
  orgsUserInviteUpdateAction,
  allActionsGetAction,
  allTiersGetAction,
  examSimpleListGetAction,
  orgContractGetAction,
  projectAuditLogGetAction,
  allCsvTypesGetAction,
  orgsCsvColumnsGetAction,
  invitationStatusGetAction,
} from "../actions";
import {
  PaginationModel,
  OrganizationModel,
  MemberListModel,
  GitModel,
  PendingInviteModel,
  ContractUsageModel,
  AuditLogModel,
  EnumModel,
  CsvTypesModel,
  CsvColumnGroupModel,
  CsvColumnAPIModel,
  CsvColumnsGroupsByCsvTypeModel,
  LoadingCsvColumnGroupsModel,
  EnumWithStringValueModel,
} from "../shared/models";

export interface OrgsState {
  loadingOrgDetails: boolean;
  loadingList: boolean;
  loadingMemberList: boolean;
  organization?: OrganizationModel;
  organizationGit: GitModel;
  orgsMemberList: MemberListModel[];
  orgsMemberPagination: PaginationModel;
  orgsMemberListConditions: {};
  orgsPendingInvitesList: Array<PendingInviteModel>;
  orgsPendingInvitesCondition: {};
  orgsPendingInvitesPagination: PaginationModel;
  orgsPendingInvitesLoading: boolean;
  orgsPendingInviteMemberLoading: boolean;
  orgsPendingInvitesError: boolean;
  organizationList: Array<OrganizationModel>;
  organizationListConditions: {};
  organizationListPagination: PaginationModel;
  organizationAllList: Array<OrganizationModel>;
  currentIP: string;
  currentContracts: ContractUsageModel[];
  pastContracts: ContractUsageModel[];
  futureContracts: ContractUsageModel[];
  filteredContract: ContractUsageModel | undefined;
  auditLog: Array<AuditLogModel>;
  auditLogLoading: boolean;
  auditLogPagination: PaginationModel;
  actions: EnumModel[];
  tiers: EnumModel[];
  csvTypes: CsvTypesModel[];
  loadingCsvColumnGroups: LoadingCsvColumnGroupsModel;
  csvColumnGroupsByCsvType: CsvColumnsGroupsByCsvTypeModel;
  invitationStatus: Record<string, EnumWithStringValueModel>;
}

export const initialState = {
  loadingOrgDetails: false,
  loadingList: false,
  loadingMemberList: false,
  orgsMemberList: [],
  orgsMemberPagination: new PaginationModel(),
  orgsMemberListConditions: {},
  organizationGit: {} as GitModel,
  orgsPendingInvitesList: [],
  orgsPendingInvitesCondition: {},
  orgsPendingInvitesPagination: new PaginationModel(),
  orgsPendingInvitesLoading: false,
  orgsPendingInviteMemberLoading: false,
  orgsPendingInvitesError: false,
  organizationList: [],
  organizationListConditions: {},
  organizationListPagination: new PaginationModel(),
  organizationAllList: [],
  currentIP: "",
  currentContracts: [],
  pastContracts: [],
  futureContracts: [],
  filteredContract: undefined,
  auditLog: [],
  auditLogLoading: false,
  auditLogPagination: new PaginationModel(),
  actions: [],
  tiers: [],
  csvTypes: [],
  loadingCsvColumnGroups: {},
  csvColumnGroupsByCsvType: {},
  invitationStatus: {},
};

export const orgsReducer = (
  state: OrgsState = initialState,
  action: Action,
): OrgsState => {
  const payload = (action as APIResponseAction).payload;

  switch (action.type) {
    case orgsGetAction.types.request:
      return { ...state, loadingOrgDetails: true };

    case orgsGetAction.types.success:
    case orgsUpdateSecurityAction.types.success: {
      const organization = new OrganizationModel(payload.result);
      const organizationList = state.organizationList.map((org) => {
        if (org.id !== organization.id) {
          return org;
        }

        return organization;
      });

      return {
        ...state,
        loadingOrgDetails: false,
        organization,
        organizationList,
      };
    }

    case orgsUserUpdateAction.types.success:
    case orgsUserDeactivateAction.types.success:
    case orgsUserSetAction.types.request:
    case grantSystemadminRoleAction.types.success:
    case revokeSystemadminRoleAction.types.success:
      const memberUpdate = new MemberListModel(payload.result);

      return {
        ...state,
        orgsMemberList: state.orgsMemberList.map((member: MemberListModel) => {
          if (member.id === memberUpdate.id) {
            return memberUpdate;
          } else {
            return member;
          }
        }),
      };
    case orgsUserListGetAction.types.request:
      return {
        ...state,

        loadingMemberList: true,
        orgsMemberList: [],
        orgsMemberPagination: new PaginationModel(),
        orgsMemberListConditions: payload,
      };
    case orgsUserListGetAction.types.success:
      const userListMap = ((payload.result as Array<{ id: number }>) || []).map(
        (user) =>
          new MemberListModel({
            ...user,
            userId: user.id,
          }),
      );
      return {
        ...state,
        loadingMemberList: false,
        orgsMemberList: userListMap,
        orgsMemberPagination: new PaginationModel(payload.pagination),
      };

    case orgsUserInviteListAction.types.request: {
      return {
        ...state,
        orgsPendingInvitesCondition: action.payload || {},
        orgsPendingInvitesLoading: true,
        orgsPendingInvitesError: false,
      };
    }
    case orgsUserInviteListAction.types.failure: {
      return {
        ...state,
        orgsPendingInvitesLoading: false,
        orgsPendingInvitesError: true,
      };
    }
    case orgsUserInviteListAction.types.success: {
      return {
        ...state,
        orgsPendingInvitesList: (payload.result as PendingInviteModel[]).map(
          (item) => new PendingInviteModel(item),
        ),
        orgsPendingInvitesPagination: new PaginationModel(payload.pagination),
        orgsPendingInvitesLoading: false,
      };
    }

    case orgsUserInviteUpdateAction.types.request:
      return {
        ...state,
        orgsPendingInviteMemberLoading: true,
        orgsPendingInvitesError: false,
      };
    case orgsUserInviteUpdateAction.types.failure:
      return {
        ...state,
        orgsPendingInviteMemberLoading: false,
        orgsPendingInvitesError: true,
      };
    case orgsUserInviteUpdateAction.types.success:
      const updatePendingMemberIndex = state.orgsPendingInvitesList.findIndex(
        (member: PendingInviteModel) =>
          member.id === (payload.result as PendingInviteModel).id,
      );
      const updatedPendingMember = new PendingInviteModel({
        ...state.orgsPendingInvitesList[updatePendingMemberIndex],
        ...payload.result,
      });
      const orgsPendingInvitesList = [...state.orgsPendingInvitesList];
      orgsPendingInvitesList.splice(
        updatePendingMemberIndex,
        1,
        updatedPendingMember,
      );

      return {
        ...state,
        orgsPendingInvitesList,
        orgsPendingInviteMemberLoading: false,
      };

    case orgsGitListGetAction.types.success:
      return {
        ...state,
        organizationGit: payload.result as GitModel,
        loadingList: false,
      };

    case orgsGitListGetAction.types.request:
      return {
        ...state,
        organizationListPagination: new PaginationModel(),
        loadingList: true,
      };

    case orgsGitListResetAction.types.success:
      return {
        ...state,
        organizationGit: initialState.organizationGit,
        loadingList: false,
      };

    case orgsGetListAction.types.request: {
      return {
        ...state,
        organizationListConditions: payload,
      };
    }

    case orgsGetListAction.types.success:
      return {
        ...state,
        organizationList: (payload.result as Array<{}>).map(
          (org) => new OrganizationModel(org),
        ),
        organizationListPagination: new PaginationModel(payload.pagination),
        loadingList: false,
      };

    case orgsResetListAllAction.types.request: {
      return { ...state, organizationAllList: [] };
    }

    case orgsGetListAllAction.types.success:
      return {
        ...state,
        organizationAllList: [
          ...(payload.pagination && payload.pagination.offset !== 0
            ? state.organizationAllList
            : []),
          ...(payload.result as Array<{}>).map(
            (org) => new OrganizationModel(org),
          ),
        ],
      };

    case orgsErrorAction.types.request:
      return { ...state, loadingOrgDetails: false };

    case orgsGetIPAction.types.success:
      return { ...state, currentIP: String(payload.result) };

    case examSimpleListGetAction.types.request:
      return {
        ...state,
        currentContracts: [],
        pastContracts: [],
        futureContracts: [],
      };

    case orgAllContractsGetAction.types.success: {
      const { currentContracts, pastContracts, futureContracts } =
        payload.result as {
          currentContracts: Array<ContractUsageModel>;
          pastContracts: Array<ContractUsageModel>;
          futureContracts: Array<ContractUsageModel>;
        };
      return { ...state, currentContracts, pastContracts, futureContracts };
    }

    case orgContractGetAction.types.success: {
      return {
        ...state,
        filteredContract: payload.result as ContractUsageModel,
      };
    }

    case orgAuditLogGetAction.types.request:
    case projectAuditLogGetAction.types.request:
      return {
        ...state,
        auditLog: [],
        auditLogLoading: true,
        auditLogPagination: new PaginationModel(),
      };
    case orgAuditLogGetAction.types.success:
    case projectAuditLogGetAction.types.success:
      return {
        ...state,
        auditLogLoading: false,
        auditLog: (payload.result as {}[]).map(
          (result) => new AuditLogModel(result),
        ),
        auditLogPagination: new PaginationModel(payload.pagination),
      };

    case allActionsGetAction.types.success: {
      return {
        ...state,
        actions:
          (payload.result as EnumModel[]).sort(
            (a, b) => a.displayOrder - b.displayOrder,
          ) ?? [],
      };
    }

    case allTiersGetAction.types.success: {
      return {
        ...state,
        tiers:
          (payload.result as EnumModel[]).sort(
            (a, b) => a.displayOrder - b.displayOrder,
          ) ?? [],
      };
    }

    case allCsvTypesGetAction.types.success: {
      const normalizedCsvTypes = (
        payload.result as EnumModel[]
      ).map<CsvTypesModel>(({ value, displayString, messageKey, ...rest }) => {
        const icon = CsvTypes.findIcon(value);
        const label = displayString ?? MSG.getMessageByKey(messageKey);
        const description = MSG.getMessageByKey(`${messageKey}.description`);

        return {
          ...rest,
          displayString,
          messageKey,
          value,
          icon,
          label,
          description,
        };
      });

      return {
        ...state,
        csvTypes: normalizedCsvTypes,
      };
    }

    case orgsCsvColumnsGetAction.types.request: {
      const csvType = action.params as CsvTypes;

      return {
        ...state,
        loadingCsvColumnGroups: {
          ...state.loadingCsvColumnGroups,
          [csvType]: true,
        },
      };
    }

    case orgsCsvColumnsGetAction.types.success: {
      const csvType = action.params as CsvTypes;
      const csvColumns = (payload.result as CsvColumnAPIModel[]).sort(
        (a, b) => a.displayOrder - b.displayOrder,
      );

      const groupsRecord = {} as Record<string, CsvColumnGroupModel>;
      csvColumns.forEach((column) => {
        const {
          groupKey,
          groupString,
          displayString: rawDisplayString,
        } = column;
        const displayString = getHumanReadableColumnName(rawDisplayString);
        groupsRecord[groupKey] = {
          groupKey: groupKey,
          groupString: groupString,
          columns: [
            ...(groupsRecord[groupKey]?.columns ?? []),
            { ...column, displayString },
          ],
        };
      });

      return {
        ...state,
        loadingCsvColumnGroups: {
          ...state.loadingCsvColumnGroups,
          [csvType]: false,
        },
        csvColumnGroupsByCsvType: {
          ...state.csvColumnGroupsByCsvType,
          [csvType]: Object.values(groupsRecord),
        },
      };
    }

    case orgsCsvColumnsGetAction.types.failure: {
      const csvType = action.params as CsvTypes;

      return {
        ...state,
        loadingCsvColumnGroups: {
          ...state.loadingCsvColumnGroups,
          [csvType]: false,
        },
      };
    }

    case invitationStatusGetAction.types.success: {
      const invitationStatus = (
        payload.result as EnumWithStringValueModel[]
      ).reduce(
        (acc, cur) => {
          acc[cur.value] = cur;
          return acc;
        },
        {} as Record<string, EnumWithStringValueModel>,
      );
      return {
        ...state,
        invitationStatus,
      };
    }

    default:
      return state;
  }
};
