import { connect } from "react-redux";

import { RootState } from "../../../reducers";
import Restricted from "./Restricted";

interface OwnProps {
  allow?: boolean;
  strictAllow?: boolean;
  wrapperTagName?: keyof JSX.IntrinsicElements | false;
  roles?: Array<number>;
  inline?: boolean;
  children: React.ReactElement | React.ReactElement[];
  className?: string;
}

const mapStateToProps = (state: RootState, props: OwnProps) => ({
  ...props,
  allow:
    (props.strictAllow !== false &&
      props.roles &&
      state.user?.user &&
      (state.user.user
        .getRoles(state.project.currentProjectId)
        .some((role) => (props.roles || []).includes(role)) ||
        state.user.user
          .getRoles()
          .some((role) => (props.roles || []).includes(role)))) ||
    props.allow,
});

const wrappedComponent = connect(mapStateToProps, {})(Restricted);

export default wrappedComponent;
