import { useMemo, useState } from "react";

import { ActionDropdown, Msg, TabSwitchButton } from "@shared/components";
import ApplicantAutoComplete, {
  ApplicantModel,
} from "@shared/components/applicantAutoComplete/ApplicantAutoComplete";
import { useSubmissionList } from "@shared/hooks/query";
import { useGetExamChallengeAnalysis } from "@shared/hooks/query/reports";
import { ExamModel } from "@shared/models";
import { ApplicantExamStatus } from "@shared/services/enums";
import { ChallengeAnalysisTabs } from "@shared/services/examDashboard";
import Message from "@shared/services/message";

import { ExamDashboardScoreDistribution } from "../examDashboardScoreDistribution/ExamDashboardScoreDistribution";

interface ChallengeAnalysisProps {
  exam: ExamModel;
}

export const ChallengeAnalysis = ({ exam }: ChallengeAnalysisProps) => {
  const [selected, setSelected] = useState<ChallengeAnalysisTabs>(
    ChallengeAnalysisTabs.timeTaken,
  );
  const [keyword, setKeyword] = useState("");
  const [selectedApplicants, setSelectedApplicants] = useState<
    ApplicantModel[]
  >([]);
  const selectedApplicantIds = useMemo(
    () => selectedApplicants.map(({ id }) => id),
    [selectedApplicants],
  );

  const dropdownButtonLabel = `${Message.getMessageByKey(
    "exam.dashboard.challengeAnalysis.applicantsFilter",
  )} ${selectedApplicants.length ? `(${selectedApplicants.length})` : ""}`;

  // to get the exam challenge analysis, it needs a submission for some reason
  // I have asked backend to make a separate endpoint which accepts only
  // an exam id but i don't think it's a priority
  const { data: submissionListData } = useSubmissionList({
    examId: exam.id,
    keyword: "",
    limit: 1,
    archived: false,
  });

  const { data: examChallengeAnalysisList } = useGetExamChallengeAnalysis({
    submissionId: submissionListData?.submissionList[0]?.id ?? 0,
    enabled: Boolean(submissionListData?.submissionList[0]),
  });

  const { data: applicantList } = useSubmissionList(
    {
      examId: exam.id,
      excludedApplicantExamIds: selectedApplicantIds,
      keyword,
      statuses: [
        ApplicantExamStatus.InReview,
        ApplicantExamStatus.Submitted,
        ApplicantExamStatus.Failed,
        ApplicantExamStatus.Passed,
      ],
    },
    true,
  );

  const handleKeywordChange = (keyword: string) => {
    setKeyword(keyword);
  };

  const handleFilterChange = (value: ApplicantModel[]) => {
    setSelectedApplicants(value);
  };

  return (
    <div className="code-exam-dashboard__panel code-dashboard-challenge-analysis">
      <div className="code-exam-dashboard__panel-header code-exam-dashboard__panel-header__wrap">
        <h3 className="text-heading3">
          <Msg id="exam.dashboard.challengeAnalysis" tagName={"none"} />
        </h3>
        <div className="code-dashboard-challenge-analysis__actions">
          <TabSwitchButton
            tabs={[
              {
                id: ChallengeAnalysisTabs.timeTaken,
                label: Message.getMessageByKey(
                  "exam.dashboard.challengeAnalysis.timeTaken",
                ),
              },
              {
                id: ChallengeAnalysisTabs.scoreDistribution,
                label: Message.getMessageByKey("challenge.scoreDistribution"),
              },
            ]}
            defaultActiveTab={ChallengeAnalysisTabs.timeTaken}
            onTabSwitch={(newActiveTabId: ChallengeAnalysisTabs) => {
              setSelected(newActiveTabId);
            }}
          />
          {false && (
            <ActionDropdown
              buttonLabel={dropdownButtonLabel}
              buttonType={selectedApplicants.length ? "primary" : undefined}
              disabled={selected === ChallengeAnalysisTabs.scoreDistribution}
              icon="filter"
              menuClassName="code-dashboard-challenge-analysis__applicant-dropdown-menu"
              keepOpen
            >
              <ApplicantAutoComplete
                keyword={keyword}
                items={applicantList?.submissionList ?? []}
                selectedValue={selectedApplicants}
                onKeywordChange={handleKeywordChange}
                onChange={handleFilterChange}
              />
            </ActionDropdown>
          )}
        </div>
      </div>
      <div>
        <ExamDashboardScoreDistribution
          exam={exam}
          examChallengeAnalysisList={examChallengeAnalysisList}
          selectedTab={selected}
          selectedApplicantIds={selectedApplicantIds}
        />
      </div>
    </div>
  );
};
