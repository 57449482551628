import { AxiosRequestConfig } from "axios";

import { ApiResponse, post } from "@api/httpClient";

import { ApplicantExamsModel } from "@shared/models";
import { ResetSubmissionsParamsModel } from "@shared/models/SubmissionList.model";

interface SubmissionResetParams {
  examId: number;
  organizationName: string;
  projectId: number;
  options: AxiosRequestConfig<ResetSubmissionsParamsModel>;
}

export async function postSubmissionReset({
  examId,
  organizationName,
  projectId,
  options,
}: SubmissionResetParams) {
  const { data } = await post<ApiResponse<ApplicantExamsModel>>(
    `/api/admin/orgs/${organizationName}/projects/${projectId}/exams/${examId}/submissions/reset`,
    options,
  );

  return data;
}
