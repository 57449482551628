import * as classnames from "classnames";
import * as React from "react";

import { useDeliverSampleByEmail } from "@shared/hooks/query";

import {
  Modal,
  Msg,
  Form,
  FormGroup,
  Label,
  LanguageTag,
  Radio,
} from "../../../../../../../shared/components";
import { ExamModel, UserModel } from "../../../../../../../shared/models";
import { dayjs } from "../../../../../../../shared/services/date";
import { ExamType } from "../../../../../../../shared/services/enums";
import Message from "../../../../../../../shared/services/message";

/**
 * Prop interface
 */
export type ExternalProps = {
  exam: ExamModel;
  isOpen?: boolean;
  onClose: () => void;
};

export type InjectedProps = {
  currentUser?: UserModel;
};

export type SampleDeliveryEmailProps = ExternalProps & InjectedProps;

type SampleDeliveryEmailInput = {
  language: string;
};

/**
 * React Component
 */
export function SampleDeliveryEmail({
  exam,
  currentUser,
  isOpen,
  onClose,
}: SampleDeliveryEmailProps) {
  const { examType } = exam;
  const deliverSample = useDeliverSampleByEmail();

  /**
   * States
   */
  const [form, setForm] = React.useState<{
    formValid: boolean;
    formValues: SampleDeliveryEmailInput;
    formErrors?: {};
  }>({ formValid: false, formValues: { language: "ja" } });

  /**
   * Effects
   */

  /**
   * Private Functions
   */
  const rootStyle = classnames("code-sample-delivery-email");

  const multiLanguage = examType === ExamType.EnglishJapanese;

  const onFormChange = (
    formValid: boolean,
    formValues: SampleDeliveryEmailInput,
    formErrors?: {},
  ) => {
    setForm({ formValid, formValues, formErrors });
  };

  const onDelivery = () => {
    const { language } = form.formValues;

    const body = {
      deadline: dayjs().add(7, "days").startOf("minute").format(),
      applicants: currentUser?.email
        .trim()
        .split("\n")
        .map((email) => ({ email })),
      mailSubject: multiLanguage
        ? Message.getMessageByKeyAndLocale("delivery.test", language)
        : Message.getMessageByKey("delivery.test"),
      mailBody: multiLanguage
        ? Message.getMessageByKeyAndLocale("delivery.test.body", language)
        : Message.getMessageByKey("delivery.test.body"),
      senderName: currentUser?.organization.displayName,
      ...(multiLanguage && { language }),
    };

    deliverSample.mutate({ examId: exam.id, data: body });
    onClose();
  };

  /**
   * Render
   */
  return (
    <Modal
      className={rootStyle}
      isOpen={isOpen}
      title={<Msg id="delivery.test" />}
      okButtonLabel={<Msg id="action.deliver" />}
      onClose={onClose}
      onClickCancel={onClose}
      onClickOk={onDelivery}
      okButtonAriaLabel="Deliver Exam"
      ariaLabel="Deliver Sample Exam"
    >
      <Form
        validation={{ language: ["string", "required"] }}
        initialValues={{ language: "ja" }}
        onFormChange={onFormChange}
      >
        <FormGroup>
          <Msg id="delivery.test.confirm" />
        </FormGroup>
        {multiLanguage && (
          <FormGroup className="code-sample-delivery-email__language">
            <Label>
              <Msg id="exam.examlanguage" />
            </Label>
            <div>
              <Radio name="language" defaultValue="ja">
                <LanguageTag language="ja" color="pink" />
              </Radio>
            </div>
            <div>
              <Radio name="language" defaultValue="en">
                <LanguageTag language="en" color="pink" />
              </Radio>
            </div>
          </FormGroup>
        )}
        <FormGroup>
          <Label>
            <Msg id="common.email" />
          </Label>
          <strong className={"is-wrap"}>{currentUser?.email}</strong>
        </FormGroup>
      </Form>
    </Modal>
  );
}
