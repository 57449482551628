import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { putExamArchive, putExamUnarchive } from "@api/exams";

import { useStoreContext } from "@context";

import Message from "@shared/services/message";

import { examKeys } from "./useExam";

export function useArchiveExam() {
  const client = useQueryClient();
  const { projectId } = useStoreContext();

  const mutation = useMutation({
    mutationFn: (examId: number) => putExamArchive({ examId, projectId }),
    onSuccess: () => {
      toast.success(Message.getMessageByKey("message.exam.deactivated"));
      client.invalidateQueries(examKeys.listAll(projectId));
    },
  });

  return mutation;
}

export function useUnarchiveExam() {
  const client = useQueryClient();
  const { projectId } = useStoreContext();

  const mutation = useMutation({
    mutationFn: (examId: number) => putExamUnarchive({ examId, projectId }),
    onSuccess: () => {
      toast.success(Message.getMessageByKey("message.exam.reactivated"));
      client.invalidateQueries(examKeys.listAll(projectId));
    },
  });

  return mutation;
}
