import { useQuery } from "@tanstack/react-query";

import { getExamTalentHubCategories } from "@api/exams/examTalentHub";

export function useGetExamTalentHubCategories() {
  const query = useQuery({
    queryKey: ["exam", "talentHub", "categories"],
    queryFn: async ({ signal }) => {
      const { result } = await getExamTalentHubCategories({
        signal,
      });
      return result;
    },
  });

  return query;
}
