import { parseISO } from "date-fns";

import {
  Row,
  Column,
  QuickHelp,
  DateTime,
  UpdateAvailableTag,
  LanguageTag,
  Checkbox,
  Tag,
  Tooltip,
  Msg,
} from "@shared/components";
import {
  ActionType,
  ExamChallengeModel,
  SubmissionListModel,
} from "@shared/models";
import {
  getChallengeStatusText,
  getSelectedChallengeResult,
  getTagColor,
} from "@shared/services/challengeStatus";
import { isSubmissionLatestVersion } from "@shared/services/challengeVersion";
import { formatDateTimeMinutes } from "@shared/services/date";
import {
  ApplicantExamStatus,
  ChallengeResultStatus,
  MailStatus,
} from "@shared/services/enums";
import Message from "@shared/services/message";

/**
 * Interface
 */
interface SubmissionListWithResultsTableRowProps {
  action?: ActionType;
  challenge?: ExamChallengeModel;
  isSelected?: boolean;
  isValidStatus?: {
    isValid: boolean;
    detail: {
      isValidSubmissionStatus: boolean;
      isSupportedChallengeStatus: boolean;
      isSupportedVersion: boolean;
    };
  };
  submission: SubmissionListModel;
  onClickSubmission?: (submission: number, checked: boolean) => void;
}

/**
 * Component
 * @param props SubmissionListWithResultsTableRowProps
 */
export function SubmissionListWithResultsTableRow({
  action,
  challenge,
  isSelected,
  isValidStatus,
  submission,
  onClickSubmission,
}: SubmissionListWithResultsTableRowProps) {
  const mailError = [
    MailStatus.Bounced,
    MailStatus.Dropped,
    MailStatus.Deferred,
    MailStatus.Spam,
  ].includes(submission.mailStatus);

  const selectedChallenge = getSelectedChallengeResult(submission, challenge);
  const challengeStatusText = selectedChallenge
    ? getChallengeStatusText(
        selectedChallenge.challengeResultStatus,
        submission.status,
      )
    : "N/A";

  return (
    <Row>
      {typeof onClickSubmission === "function" && (
        <Column className="align-center">
          <div className="checkbox-wrapper">
            {!isValidStatus?.isValid ? (
              <>
                <Checkbox
                  value={Boolean(isSelected)}
                  readOnly={true}
                  name="submissions"
                />
                <Tooltip
                  text={
                    <Msg
                      id={
                        !isValidStatus?.detail.isValidSubmissionStatus
                          ? "submission.tooltip.cannotSelect.submission"
                          : !isValidStatus?.detail.isSupportedChallengeStatus
                          ? "submission.tooltip.cannotSelect.challenge"
                          : "submission.tooltip.cannotSelect.version"
                      }
                      values={{
                        challengeStatus: challengeStatusText,
                        submissionStatus: ApplicantExamStatus.toString(
                          submission.status,
                        ),
                      }}
                    />
                  }
                  placement="top-end"
                />
              </>
            ) : (
              <Checkbox
                value={Boolean(isSelected)}
                readOnly={false}
                name="submissions"
                onChange={(e) =>
                  onClickSubmission(submission.id, e.currentTarget.checked)
                }
              />
            )}
          </div>
        </Column>
      )}
      <Column>
        <div>
          {submission.language && (
            <LanguageTag language={submission.language} color="pink" />
          )}
          <div>
            <span>{submission.applicantFullName}</span>
            <div>
              <small>{submission.applicantEmail}</small>
            </div>
          </div>
        </div>
      </Column>
      <Column>
        {ApplicantExamStatus.toString(submission.status)}
        {submission.status === ApplicantExamStatus.Canceled && (
          <QuickHelp
            text={
              Message.getMessageByKey("canceledAt") +
              "\n" +
              formatDateTimeMinutes(submission.archivedAt)
            }
          />
        )}
      </Column>
      <Column>
        <DateTime
          dateTime={submission.deliveredAt}
          warningMessage={
            mailError
              ? MailStatus.getErrorMessage(submission.mailStatus)
              : undefined
          }
        />
      </Column>
      <Column>
        <DateTime
          dateTime={submission.deadline}
          warningMessage={
            action === ActionType.Reset &&
            parseISO(submission.deadline) < new Date()
              ? Message.getMessageByKey("submission.tooltip.deadlineIsPast")
              : undefined
          }
        />
      </Column>
      <Column>
        <DateTime dateTime={submission.submittedAt} />
      </Column>
      <Column className="align-center">
        {selectedChallenge ? (
          <Tag
            color={getTagColor(
              selectedChallenge.challengeResultStatus ||
                ChallengeResultStatus.Prepared,
            )}
          >
            {challengeStatusText}
          </Tag>
        ) : (
          "N/A"
        )}
      </Column>
      <Column>
        <div className="code-multiple-manage-modal__version-no">
          {selectedChallenge ? (
            <>
              <span>{`v${selectedChallenge.majorVersionNumber}.${selectedChallenge.minorVersionNumber}`}</span>
              {challenge &&
                !isSubmissionLatestVersion({
                  examChallenge: challenge,
                  submissionResult: selectedChallenge,
                }) && <UpdateAvailableTag />}
            </>
          ) : (
            "N/A"
          )}
        </div>
      </Column>
    </Row>
  );
}
