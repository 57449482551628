import * as classnames from "classnames";
import { isEqual } from "lodash";
import * as React from "react";

import {
  Form,
  FormGroup,
  Label,
  Input,
  Modal,
  Checkbox,
  ValidationMessage,
  Icon,
  Msg,
  Tooltip,
  ErrorIcon,
  WarningIcon,
  Banner,
} from "@shared/components";
import { EnumModel, ExamChallengeModel } from "@shared/models";
import { ChallengeStyle, TierAction } from "@shared/services/enums";
import Message from "@shared/services/message";

import { ExamSectionUtil } from "..";
import {
  getMonitoringWarningMessage,
  getTimeLimitMinutesErrorMessage,
} from "../examSectionUtil/ExamSectionUtil";

/**
 * Prop interface
 */
export interface ExternalProps {
  isOpen: boolean;
  editAllowed?: boolean;
  weightEditAllowed?: boolean;
  challenge: ExamChallengeModel;
  onOK: (challenge: ExamChallengeModel) => void;
  onCancel: () => void;
  isTierActionAllowed: (tierAction: TierAction) => boolean;
}
export interface InjectedProps {
  allProgrammingLanguages: Array<EnumModel>;
}

interface HookProps {
  enableCodePlayback: boolean;
  enableWebcam: boolean;
  enableApplicantActionLog: boolean;
}

type ExamChallengeEditProps = ExternalProps & InjectedProps & HookProps;
interface ExamChallengeFormModel {
  title: string;
  weight: string;
  localExamEnabled: boolean;
  randomizeQuiz: boolean;
}

/**
 * State interface
 */
export interface ExamChallengeEditState {
  formValid: boolean;
  formValues: ExamChallengeFormModel;
  formErrors: {};
  timeLimitMinutes?: string;
  noLimit: boolean;
  selectAll: boolean;
  programmingLanguages: number[];
}

/**
 * Page component
 */
class ExamChallengeEdit extends React.Component<
  ExamChallengeEditProps,
  ExamChallengeEditState
> {
  constructor(props: ExamChallengeEditProps) {
    super(props);
    const {
      timeLimitMinutes,
      noLimit,
      selectAll,
      programmingLanguages,
      ...formValues
    } = this.getFormValues();
    this.state = {
      formValid: false,
      formValues: formValues,
      formErrors: {},
      timeLimitMinutes,
      noLimit,
      selectAll,
      programmingLanguages,
    };
  }

  public shouldComponentUpdate(
    nextProps: ExamChallengeEditProps,
    nextState: ExamChallengeEditState,
  ) {
    return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state);
  }

  public componentDidUpdate(prevProps: Readonly<ExamChallengeEditProps>) {
    if (!isEqual(this.props.challenge, prevProps.challenge)) {
      const {
        timeLimitMinutes,
        noLimit,
        selectAll,
        programmingLanguages,
        ...formValues
      } = this.getFormValues();
      this.setState({
        formValues: formValues,
        timeLimitMinutes,
        noLimit,
        selectAll,
        programmingLanguages,
      });
    }
  }

  public render() {
    const {
      isOpen,
      challenge,
      allProgrammingLanguages,
      editAllowed,
      weightEditAllowed,
      enableCodePlayback,
      enableWebcam,
      enableApplicantActionLog,
      isTierActionAllowed,
    } = this.props;
    const {
      formValues,
      formErrors,
      noLimit,
      timeLimitMinutes,
      selectAll,
      programmingLanguages,
    } = this.state;

    const rootStyle = classnames("code-exam-challenge-edit");

    const initialValues = {
      title: challenge.title || "",
      weight: (challenge.weight === undefined
        ? 1
        : challenge.weight
      ).toString(),
      localExamEnabled: challenge.localExamEnabled || false,
      randomizeQuiz: challenge.randomizeQuiz || false,
    };

    const validation = {
      title: ["string", "notEmpty", ["max", 255], "required"],
      weight: ["number", "integer", ["min", 0], ["max", 10], "required"],
    };

    const hasProgrammingLanguages =
      ChallengeStyle.hasProgrammingLanguage(challenge.style) &&
      challenge.originalChallenge.programmingLanguages &&
      challenge.originalChallenge.programmingLanguages.length > 1;
    const programmingLanguagesValid = hasProgrammingLanguages
      ? programmingLanguages.length > 0
      : true;

    const isChallengeRecordable = ExamSectionUtil.isChallengeRecordable(
      new ExamChallengeModel({
        timeLimitMinutes:
          timeLimitMinutes !== undefined ? Number(timeLimitMinutes) : undefined,
      }),
    );

    const getLocalExamWarningIcon = () => {
      if (
        isTierActionAllowed(TierAction.CliSupportEnabling) &&
        (enableCodePlayback || enableApplicantActionLog)
      ) {
        return (
          <WarningIcon
            tooltipText={<Msg id="exam.monitoring.warning.notSupported" />}
          />
        );
      }

      if (!formValues.localExamEnabled) {
        return undefined;
      }

      if (!isTierActionAllowed(TierAction.CliSupportEnabling)) {
        return (
          <ErrorIcon tooltipText={<Msg id="tier.disabled.notSupported" />} />
        );
      }

      return undefined;
    };

    const canChallengePlayback =
      ChallengeStyle.canPlayback(challenge.style) &&
      enableCodePlayback &&
      !isChallengeRecordable;

    const canChallengeWebcam =
      ChallengeStyle.canWebcam(challenge.style) &&
      enableWebcam &&
      !isChallengeRecordable;

    return (
      <Modal
        className={rootStyle}
        title={<Msg id="challenge.edit.title" />}
        isOpen={isOpen}
        onClickOk={this.onClickOK}
        onClose={this.props.onCancel}
        onClickCancel={this.props.onCancel}
        disableOk={
          Boolean(formErrors) ||
          !programmingLanguagesValid ||
          getTimeLimitMinutesErrorMessage(timeLimitMinutes) !== ""
        }
        ariaLabel="Customize Challenge"
      >
        <Form
          initialValues={initialValues}
          validation={validation}
          onFormChange={this.onFormChange}
          clear={!isOpen}
        >
          <FormGroup>
            <Label title={<Msg id="common.title" />} />
            <Input name="title" />
            {challenge.originalChallenge.title !== formValues.title && (
              <p className="code-exam-challenge-edit__sub-text">
                <Msg id="challenge.originalTitle" />
                {challenge.originalChallenge.title}
              </p>
            )}
          </FormGroup>
          <FormGroup>
            <Label title={<Msg id="timeLimitMinutes" />} />
            {(canChallengePlayback || canChallengeWebcam) && (
              <Banner
                type="warning"
                className="code-exam-challenge-edit__playback-warning"
              >
                <Msg
                  id={getMonitoringWarningMessage({
                    shouldShowPlaybackWarning: canChallengePlayback,
                    shouldShowWebcamWarning: canChallengeWebcam,
                  })}
                />
              </Banner>
            )}
            <div className="code-exam-challenge-edit__time-limit">
              <Input
                name="timeLimitMinutes"
                size={3}
                type="number"
                min="1"
                value={timeLimitMinutes || ""}
                onChange={(e) =>
                  this.onTimeLimitMinutesChange("timeLimitMinutes", e)
                }
                error={{
                  timeLimitMinutes:
                    getTimeLimitMinutesErrorMessage(timeLimitMinutes),
                }}
              />
              <Checkbox
                value={noLimit}
                onChange={(e) => this.onTimeLimitMinutesChange("noLimit", e)}
              >
                <Msg id={"noLimit"} />
              </Checkbox>
            </div>
            <p className="code-exam-challenge-edit__sub-text">
              {`${Message.getMessageByKey("basicTimeMinutes")}: ${
                challenge.basicTimeMinutes
              }`}
            </p>
          </FormGroup>
          <FormGroup>
            <Label title={<Msg id="weight" />} />
            <Input
              name="weight"
              size={3}
              type="number"
              disabled={!editAllowed || !weightEditAllowed}
            />
            {!weightEditAllowed && (
              <p className="code-exam-challenge-edit__warning">
                <small>
                  <Icon type="exclamation-triangle" />
                  <Msg id="challenge.weight.notEditable" />
                </small>
              </p>
            )}
          </FormGroup>
          {ChallengeStyle.hasLocalExam(challenge.style) && (
            <FormGroup>
              <Label className="has-error-icon">
                <Msg id="challenge.cli.support" />
                {getLocalExamWarningIcon()}
              </Label>
              <Tooltip
                disabled={isTierActionAllowed(TierAction.CliSupportEnabling)}
                text={Message.getMessageByKey("tier.disabled")}
                placement="top-end"
              >
                <Checkbox
                  name="localExamEnabled"
                  readOnly={
                    // If the action is not supported, it can be unchecked.
                    // Once unchecked and this modal is closed, it will be disabled.
                    !initialValues.localExamEnabled &&
                    !isTierActionAllowed(TierAction.CliSupportEnabling)
                  }
                >
                  <Msg id="challenge.localExamEnabled" />
                </Checkbox>
              </Tooltip>
            </FormGroup>
          )}
          {hasProgrammingLanguages && (
            <FormGroup>
              <Label title={<Msg id="programmingLanguages" />} />
              <div className="code-exam-challenge-edit__programming-languages">
                <div>
                  <Checkbox
                    value={selectAll}
                    onChange={(e) =>
                      this.onToggleProgrammingLanguages(
                        "all",
                        e.currentTarget.checked,
                      )
                    }
                  >
                    <Msg id="common.all" />
                  </Checkbox>
                </div>
                <div>
                  {(challenge.originalChallenge.programmingLanguages || []).map(
                    (code) => (
                      <Checkbox
                        key={code}
                        value={programmingLanguages.includes(code)}
                        onChange={(e: React.FormEvent<HTMLInputElement>) =>
                          this.onToggleProgrammingLanguages(
                            code,
                            e.currentTarget.checked,
                          )
                        }
                      >
                        {allProgrammingLanguages
                          .filter((item) => item.value === code)
                          .map((item) => (item ? item.displayString : code))}
                      </Checkbox>
                    ),
                  )}
                  {!programmingLanguagesValid && (
                    <ValidationMessage
                      name="programmingLanguages"
                      error={{
                        programmingLanguages: Message.getMessageByKey(
                          "validation.object.missing",
                        ),
                      }}
                    />
                  )}
                </div>
              </div>
            </FormGroup>
          )}
          {challenge.style === ChallengeStyle.Quiz && (
            <FormGroup>
              <Label className="has-error-icon">
                <Msg id="challenge.randomQuiz" />
                {!isTierActionAllowed(TierAction.RandomQuiz) &&
                  formValues.randomizeQuiz && (
                    <ErrorIcon
                      tooltipText={<Msg id="tier.disabled.notSupported" />}
                    />
                  )}
              </Label>
              <Tooltip
                disabled={isTierActionAllowed(TierAction.RandomQuiz)}
                text={Message.getMessageByKey("tier.disabled")}
                placement="top-end"
              >
                <Checkbox
                  name="randomizeQuiz"
                  readOnly={
                    !editAllowed ||
                    !(
                      initialValues.randomizeQuiz ||
                      isTierActionAllowed(TierAction.RandomQuiz)
                    )
                  }
                >
                  <Msg id="challenge.randomQuiz.label" />
                </Checkbox>
              </Tooltip>
            </FormGroup>
          )}
        </Form>
      </Modal>
    );
  }

  private getFormValues = () => {
    const {
      challenge: {
        title,
        timeLimitMinutes,
        weight,
        localExamEnabled,
        randomizeQuiz,
        originalChallenge,
        programmingLanguages = [],
      },
    } = this.props;

    return {
      title: title || "",
      timeLimitMinutes:
        timeLimitMinutes === undefined
          ? undefined
          : timeLimitMinutes.toString(),
      noLimit: timeLimitMinutes === undefined,
      weight: (weight === undefined ? 1 : weight).toString(),
      localExamEnabled: localExamEnabled ?? false,
      randomizeQuiz: randomizeQuiz ?? false,
      programmingLanguages: programmingLanguages,
      selectAll:
        originalChallenge.programmingLanguages.length ===
        programmingLanguages.length,
    };
  };

  private onTimeLimitMinutesChange = (
    name: "noLimit" | "timeLimitMinutes",
    e: React.FormEvent<HTMLInputElement>,
  ) => {
    const { challenge } = this.props;
    const { value, checked } = e.target as HTMLInputElement;

    if (name === "noLimit") {
      this.setState({
        noLimit: checked,
        timeLimitMinutes: checked
          ? undefined
          : challenge.basicTimeMinutes.toString(),
      });
    }
    if (name === "timeLimitMinutes") {
      this.setState({
        noLimit: value === "",
        timeLimitMinutes: value === "" ? undefined : value,
      });
    }
  };

  private onFormChange = (
    formValid: boolean,
    formValues: ExamChallengeFormModel,
    formErrors: {},
  ) => {
    this.setState({ formValid, formValues, formErrors });
  };

  private onToggleProgrammingLanguages = (
    code: number | "all",
    checked: boolean,
  ) => {
    const { programmingLanguages } = this.state;
    const {
      challenge: { originalChallenge },
    } = this.props;

    if (code === "all") {
      this.setState({
        selectAll: checked,
        programmingLanguages: checked
          ? originalChallenge.programmingLanguages
          : [],
      });
    } else {
      let newProgrammingLanguages = [...programmingLanguages];
      if (checked && !newProgrammingLanguages.includes(code)) {
        newProgrammingLanguages.push(code);
      } else {
        newProgrammingLanguages = programmingLanguages.filter(
          (item) => item !== code,
        );
      }

      this.setState({
        selectAll:
          originalChallenge.programmingLanguages.length ===
          newProgrammingLanguages.length,
        programmingLanguages: newProgrammingLanguages,
      });
    }
  };

  private onClickOK = () => {
    if (typeof this.props.onOK === "function") {
      const { timeLimitMinutes, programmingLanguages } = this.state;
      const { title, localExamEnabled, weight, randomizeQuiz } =
        this.state.formValues;
      const newChallenge = {
        ...this.props.challenge,
        ...{
          title,
          timeLimitMinutes:
            timeLimitMinutes !== undefined
              ? Number(timeLimitMinutes)
              : undefined,
          programmingLanguages,
          localExamEnabled,
          weight: Number(weight),
          randomizeQuiz,
        },
      };
      this.props.onOK(newChallenge);
    }
  };
}

export default ExamChallengeEdit;
