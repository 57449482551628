import * as classnames from "classnames";

import {
  Msg,
  PieChart,
  Table,
  TableBody,
  Row,
  Column,
  TableHead,
  HeaderColumn,
  QuickHelp,
} from "@shared/components";
import { ChallengeInsightModel } from "@shared/models";
import { formatSeconds } from "@shared/services/date";
import { ProgrammingLanguage } from "@shared/services/enums";
import Message from "@shared/services/message";

const programmingLanguageColors = Object.freeze([
  "#C86DD2",
  "#5B69BA",
  "#6296CD",
  "#72CAD9",
  "#F2941D",
  "#3C833B",
  "#4C598F",
  "#F7C73D",
  "#4385B3",
  "#FC3815",
  "#B01300",
  "#32809D",
  "#959595",
  "#2E8E7E",
  "#EC6327",
  "#2FA6D6",
  "#40B381",
  "#DF8A07",
  "#000000",
  "#F96730",
  "#6571DB",
  "#3178c6",
]);

/**
 * Prop interface
 */
export interface ProgrammingLanguageStatsProps {
  challengeInsight?: ChallengeInsightModel;
  chartWidth?: number;
}

/**
 * React Component
 */
export function ProgrammingLanguageStats({
  challengeInsight,
  chartWidth,
}: ProgrammingLanguageStatsProps) {
  const rootStyle = classnames("code-c-programming-language-stats");

  if (!challengeInsight) {
    return null;
  }

  const { programmingLanguagesStats = [] } = challengeInsight;

  const chartSource = programmingLanguagesStats
    .filter((data) => data.applicantCount !== 0)
    .map((data) => {
      return {
        label: ProgrammingLanguage.toString(data.language),
        number: data.applicantCount,
        color: programmingLanguageColors[data.language],
      };
    });

  return (
    <div className={rootStyle}>
      <div className="code-c-programming-language-stats__chart">
        <PieChart items={chartSource} size={chartWidth} />
        <div className="code-c-programming-language-stats__chart-legend">
          <Msg id="challenge.submissions" />
          (%)
        </div>
      </div>
      <Table narrow={true} hoverable={false}>
        <TableHead>
          <HeaderColumn size={1}>
            <Msg id="form.language" />
          </HeaderColumn>
          <HeaderColumn size={1} className="align-right">
            <Msg id="challenge.numberOfSubmission" />
          </HeaderColumn>
          <HeaderColumn size={1} className="align-right">
            <Msg id="challenge.average" />
          </HeaderColumn>
          <HeaderColumn className="align-right code-c-programming-language-stats__avg-time-column">
            <Msg id="challenge.averageTimeTakenShorthand" />
            <div>
              (<Msg id="challenge.mean" />){" "}
              <QuickHelp
                text={Message.getMessageByKey("challenge.timeQuickHelp")}
                maxSize="large"
              />
            </div>
          </HeaderColumn>
        </TableHead>
        <TableBody>
          {programmingLanguagesStats
            .filter((data) => data.applicantCount !== 0)
            .map((data, index) => {
              return (
                <Row key={`${index}${data.applicantCount}`}>
                  <Column>{ProgrammingLanguage.toString(data.language)}</Column>
                  <Column className="align-right">{data.applicantCount}</Column>
                  <Column className="align-right">{`${data.averageScore}%`}</Column>
                  <Column className="align-right">
                    {formatSeconds(data.averageSolvingTimeInSeconds)}
                  </Column>
                </Row>
              );
            })}
        </TableBody>
      </Table>
    </div>
  );
}
