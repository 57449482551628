import * as React from "react";

import { Msg, Timeline, Button, Banner } from "@shared/components";
import { useSubmissionAccessType, useUserRoles } from "@shared/hooks";
import {
  SubmissionTimeline as ISubmissionTimeline,
  useGuestSubmissionTimeline,
  useSubmissionTimeline,
} from "@shared/hooks/query";

import { SubmissionTimelineDetail } from "./partials";
import { SubmissionTimelineRow } from "./partials/submissionTimelineRow/SubmissionTimelineRow";

export const SubmissionTimelineGuest = () => {
  const { data: { timelines } = {} } = useGuestSubmissionTimeline();
  const { isExamDeliverer } = useSubmissionAccessType();
  const { isProjectAdmin } = useUserRoles();

  return (
    <SubmissionTimelineComponent
      isExamDeliverer={isExamDeliverer}
      isProjectAdmin={isProjectAdmin}
      timelines={timelines}
    />
  );
};
interface SubmissionTimelineProps {
  onEvaluationClick: () => void;
}
export const SubmissionTimeline = (props: SubmissionTimelineProps) => {
  const { data: { isSubmissionWithActionLogs, timelines } = {}, isFetching } =
    useSubmissionTimeline();
  const { isExamDeliverer } = useSubmissionAccessType();
  const { isProjectAdmin } = useUserRoles();
  const showWarningBanner = !isFetching && !isSubmissionWithActionLogs;

  return (
    <SubmissionTimelineComponent
      {...props}
      isExamDeliverer={isExamDeliverer}
      isProjectAdmin={isProjectAdmin}
      showWarningBanner={showWarningBanner}
      timelines={timelines}
    />
  );
};

interface ExtendedProps {
  timelines?: ISubmissionTimeline[];
  isExamDeliverer?: boolean;
  isProjectAdmin?: boolean;
  showWarningBanner?: boolean;
  onEvaluationClick?: () => void;
}
const SubmissionTimelineComponent = ({
  timelines,
  isExamDeliverer,
  isProjectAdmin,
  showWarningBanner,
  onEvaluationClick,
}: ExtendedProps) => {
  const [isExpand, setIsExpand] = React.useState(false);
  const isOverflow = (timelines?.length || 0) > 4;

  const handleExpandClick = () => {
    setIsExpand(true);
  };

  if (isExamDeliverer) {
    return null;
  }

  return (
    <>
      {showWarningBanner && (
        <Banner>
          <Msg id="submission.timeline.warning" className="text-sm" />
        </Banner>
      )}
      <Timeline className="code-submission-timeline">
        {timelines
          ?.slice(0, 4)
          .map((itemProps, index) => (
            <SubmissionTimelineRow
              key={`timeline-${index}`}
              onEvaluationClick={onEvaluationClick}
              show
              isProjectAdmin={isProjectAdmin}
              {...itemProps}
            />
          ))}
        {isOverflow && !isExpand && (
          <SubmissionTimelineDetail show>
            <div />
          </SubmissionTimelineDetail>
        )}
        {isExpand &&
          timelines
            ?.slice(4)
            .map((itemProps, index) => (
              <SubmissionTimelineRow
                key={`timeline-${index}`}
                onEvaluationClick={onEvaluationClick}
                show={isExpand}
                isProjectAdmin={isProjectAdmin}
                {...itemProps}
              />
            ))}
      </Timeline>
      {isOverflow && !isExpand && (
        <Button className="text-xs mb-4" stretch onClick={handleExpandClick}>
          <Msg id="submission.showHistory" />
        </Button>
      )}
    </>
  );
};
