import {
  ChallengeModel,
  ExamChallengeModel,
  OriginalChallengeModel,
} from "../../../../../shared/models";
import { ChallengeStyle } from "../../../../../shared/services/enums";
import Logger from "../../../../../shared/services/logger";

/**
 * Check if the challenge is meet with proper conditions for the selecting
 * @param challenge
 */
export function isValidLinkedChallenge(challenge: ChallengeModel): boolean {
  const {
    id,
    style,
    linkedChallenge,
    programmingLanguages = [],
    currentVersion: { openTestcases, secretTestcases } = {},
    organizationId,
  } = challenge;

  if (!linkedChallenge) {
    Logger.warn(`linkedChallenge is undefined: id=${id}`);
    return false;
  }

  const {
    programmingLanguages: linkedProgrammingLanguages = [],
    currentVersion: {
      openTestcases: linkedOpenTestcases,
      secretTestcases: linkedSecretTestcases,
    } = {},
    organizationId: linkedOrganizationId,
  } = linkedChallenge;
  if (
    [
      ChallengeStyle.Algorithm,
      ChallengeStyle.Development,
      ChallengeStyle.Function,
    ].includes(style) &&
    (programmingLanguages.length !== linkedProgrammingLanguages.length ||
      programmingLanguages.some(
        (item, index) => item !== linkedProgrammingLanguages[index],
      ))
  ) {
    Logger.warn(`programmingLanguages isn't matched: id=${id}`);
    return false;
  }

  if (
    openTestcases !== linkedOpenTestcases ||
    secretTestcases !== linkedSecretTestcases
  ) {
    Logger.warn(`testcases isn't matched: id=${id}`);
    return false;
  }

  if (organizationId !== linkedOrganizationId) {
    Logger.warn(`organization isn't matched: id=${id}`);
    return false;
  }

  return true;
}

/**
 * Check if a pair of exam challenge with linked challenge
 * @param source
 * @param target
 */
export function isLinkedExamChallengePair(
  source: Pick<ExamChallengeModel, "challengeId"> & {
    originalChallenge: {
      linkedChallenge?: Pick<
        Required<OriginalChallengeModel>["linkedChallenge"],
        "id"
      >;
    };
  },
  target: Pick<ChallengeModel, "id">,
): boolean {
  return (
    source.challengeId === target.id ||
    source.originalChallenge.linkedChallenge?.id === target.id
  );
}

/**
 * Check if a pair of challenge with linked challenge
 * @param source
 * @param target
 */
export function isLinkedChallengePair(
  source: Pick<ChallengeModel, "id"> & {
    linkedChallenge?: Pick<Required<ChallengeModel>["linkedChallenge"], "id">;
  },
  target: Pick<ChallengeModel, "id"> & {
    linkedChallenge?: Pick<Required<ChallengeModel>["linkedChallenge"], "id">;
  },
): boolean {
  return (
    source.id === target.id ||
    source.linkedChallenge?.id === target.id ||
    source.id === target.linkedChallenge?.id
  );
}
