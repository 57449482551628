import { AxiosRequestConfig } from "axios";

import { ChallengeStatisticModel } from "@shared/models";

import { get } from "../httpClient";

export interface SubmissionChallengeAnalysisParams {
  projectId: number;
  examId: number;
  challengeId: number;
  options?: AxiosRequestConfig;
}

export async function getReportExamChallengeStatistics({
  projectId,
  examId,
  challengeId,
  options,
}: SubmissionChallengeAnalysisParams) {
  const { data } = await get<ChallengeStatisticModel>(
    `/api/reports/examinees/projects/${projectId}/exams/${examId}/challenges/${challengeId}/statistics`,
    options,
  );

  return data;
}
