import { AxiosRequestConfig } from "axios";

import {
  EnumWithStringValueModel,
  SubmissionListModel,
  SubmissionModel,
} from "@shared/models";

import { ApiResponse, Pagination, _delete, get, post } from "../httpClient";

export { EnumWithStringValueModel };

export type SubmissionResponse = Omit<SubmissionModel, "getExamName">;

interface DeleteSubmissionPayload {
  memo?: string;
}

interface SubmissionDetailParams<T = any> {
  projectId: number;
  submissionId: number;
  options?: AxiosRequestConfig<T>;
}

interface DeletePendingSubmissionParams
  extends Omit<
    SubmissionDetailParams<DeleteSubmissionPayload>,
    "submissionId"
  > {
  examId: number;
}

export async function getSubmission({
  projectId,
  submissionId,
  options,
}: SubmissionDetailParams) {
  const { data } = await get<SubmissionResponse>(
    `/api/projects/${projectId}/submissions/${submissionId}`,
    options,
  );

  return data;
}

export async function getSubmissionList(
  projectId: number,
  options?: AxiosRequestConfig,
) {
  const { data } = await get<
    SubmissionListModel[],
    Pagination<SubmissionListModel[]>
  >(`/api/projects/${projectId}/submissions`, options);

  return data;
}

export async function postManualSubmitSubmission(
  projectId: number,
  submissionId: number,
) {
  const { data } = await post<ApiResponse<SubmissionResponse>>(
    `/api/projects/${projectId}/submissions/${submissionId}/complete`,
  );

  return data;
}

export async function deleteSubmission({
  projectId,
  submissionId,
  options,
}: SubmissionDetailParams<DeleteSubmissionPayload>) {
  const { data } = await _delete(
    `/api/projects/${projectId}/submissions/${submissionId}`,
    options,
  );

  return data;
}

export async function deletePendingSubmissionList({
  examId,
  projectId,
  options,
}: DeletePendingSubmissionParams) {
  const { data } = await _delete(
    `/api/projects/${projectId}/exam/${examId}/submissions`,
    options,
  );

  return data;
}
