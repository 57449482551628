import { AxiosRequestConfig } from "axios";

import { ReportChallengeModel } from "@shared/models";

import { get } from "../httpClient";

export interface ExamChallengeAnalysisParams {
  projectId: number;
  submissionId: number;
  options?: AxiosRequestConfig;
}

export async function getReportExamChallengeAnalysis({
  projectId,
  submissionId,
  options,
}: ExamChallengeAnalysisParams) {
  const { data } = await get<ReportChallengeModel[]>(
    `/api/reports/examinees/projects/${projectId}/submissions/${submissionId}/challenges/analysis`,
    options,
  );

  return data;
}
