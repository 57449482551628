import { zodResolver } from "@hookform/resolvers/zod";
import classNames from "classnames";
import { useEffect } from "react";
import { useForm, FormProvider, useWatch } from "react-hook-form";
import { useIntl } from "react-intl";

import { useStoreContext } from "@context";

import { Banner, Msg, Restricted } from "@shared/components";
import { FormType } from "@shared/components/form/types";
import { ExamModel } from "@shared/models";
import { ExamDeliveryKind, TierAction, UserRole } from "@shared/services/enums";
import { isGiveryOrg } from "@shared/services/organization";

import { useDraftForm } from "./DraftFormProvider";
import { ApplicantMonitoringSection } from "./partials/ApplicantMonitoringSection";
import { AutofilterSection } from "./partials/AutofilterSection";
import { EmailDeliverySection } from "./partials/EmailDeliverySection";
import { ExamDeliveryTypeSection } from "./partials/ExamDeliveryTypeSection";
import { ExternalSharingSection } from "./partials/ExternalSharingSection";
import { IdDeliverySection } from "./partials/IdDeliverySection";
import PresetExamSection from "./partials/presetExamSection/PresetExamSection.connect";
import {
  examSettingsFormValidation,
  ExamSettingsFormValidation,
} from "./utils";

interface DefaultExamSettingsProps {
  readOnly?: boolean;
  editLimited?: boolean;
  urlToken?: string;
  hasBeenDelivered?: boolean;
  onFormChange?: (formValid: boolean, formValues: {}) => void;
  // exam specific property
  challengesSets?: ExamModel["challengesSets"];
}

interface ExamSettingsCreateProps extends DefaultExamSettingsProps {
  type: FormType.Create;
  initialValues?: never;
}

interface ExamSettingsEditProps extends DefaultExamSettingsProps {
  type: FormType.Edit;
  initialValues: ExamSettingsFormValidation;
}

type ExamSettingsProps = ExamSettingsCreateProps | ExamSettingsEditProps;

/**
 * Used in both exam create and exam edit flows
 */
export const ExamSettingsForm = ({
  type,
  initialValues,
  hasBeenDelivered = false,
  readOnly,
  urlToken,
  editLimited,
  challengesSets,
  onFormChange,
}: ExamSettingsProps) => {
  const { isTierActionAllowed, projectDetail } = useStoreContext();

  const isCreate = type === FormType.Create;
  const isLogApplicantActionAllowed = isTierActionAllowed(
    TierAction.ApplicantActionLogs,
  );
  const isWebcamMonitoringAllowed = isTierActionAllowed(
    TierAction.WebcamMonitoring,
  );
  const isCodePlaybackAllowed = isTierActionAllowed(TierAction.CodePlayback);

  const intl = useIntl();

  const { draftState, setDraftState } =
    useDraftForm<ExamSettingsFormValidation>();
  const formSchema = examSettingsFormValidation({
    deliveryKind: draftState.deliveryKind,
    urlDeliveryEnabled: draftState.urlDeliveryEnabled,
    intl,
    isCreate,
  });
  const rootStyle = classNames("code-exam-create-new__settings");

  const methods = useForm<ExamSettingsFormValidation>({
    resolver: zodResolver(formSchema),
    defaultValues: type === FormType.Edit ? initialValues : draftState,
    mode: "all",
    shouldUnregister: false,
  });

  const {
    watch,
    reset,
    formState: { isValid },
    control,
    trigger,
  } = methods;

  const watchedValues = useWatch({ control });
  useEffect(() => {
    reset(initialValues);
  }, [reset, initialValues]);

  useEffect(() => {
    onFormChange?.(isValid, watchedValues);
    const {
      urlSharingConfig,
      idDeliveryConfig,
      autoFilterSettings,
      ...filteredWatchedValues
    } = watchedValues;

    // Merge into newDraftState
    const newDraftState = { ...draftState, ...filteredWatchedValues };
    setDraftState(newDraftState as ExamSettingsFormValidation);
    setTimeout(() => {
      // trigger validation in the timeout to correctly apply conditional validations
      trigger();
    }, 1);
  }, [watchedValues, isValid]);

  const deliveryKind = watch("deliveryKind");

  const showEditLimitedWarning = !readOnly && editLimited;

  return (
    <FormProvider {...methods}>
      <div className={rootStyle}>
        {showEditLimitedWarning && (
          <Banner type="warning">
            <Msg id={"code-exam-edit.limited"} />
          </Banner>
        )}
        <div className="code-exam-create-new__settings__switch-container">
          <div>
            <ExamDeliveryTypeSection
              examDeliveryType={
                type === FormType.Edit
                  ? initialValues.deliveryKind
                  : ExamDeliveryKind.Standard
              }
              hasBeenDelivered={hasBeenDelivered}
              readOnly={readOnly}
              isTierActionAllowed={isTierActionAllowed}
              showEditLimitedTooltip={showEditLimitedWarning}
            />
          </div>
          <div className="code-exam-create-new__settings__container">
            {deliveryKind === ExamDeliveryKind.ID && (
              <IdDeliverySection urlToken={urlToken} readOnly={readOnly} />
            )}
            {deliveryKind === ExamDeliveryKind.Standard && (
              <EmailDeliverySection urlToken={urlToken} readOnly={readOnly} />
            )}
          </div>
        </div>
        <ExternalSharingSection
          readOnly={readOnly}
          isLogApplicantActionAllowed={isLogApplicantActionAllowed}
          isGuestShareAllowed={isTierActionAllowed(
            TierAction.GuestSharingEnabling,
          )}
        />
        <ApplicantMonitoringSection
          readOnly={readOnly}
          isCodePlaybackAllowed={isCodePlaybackAllowed}
          isLogApplicantActionAllowed={isLogApplicantActionAllowed}
          isWebcamMonitoringAllowed={isWebcamMonitoringAllowed}
          examChallengeSets={challengesSets}
        />
        <div className="code-exam-create-new__settings__switch-container">
          <AutofilterSection readOnly={readOnly} />
        </div>

        {/* NOTE: only for givery and SystemAdmin */}
        {isGiveryOrg(projectDetail.organizationId) && (
          <Restricted roles={[UserRole.SystemAdmin]}>
            <div className="code-exam-create-new__settings__switch-container">
              <PresetExamSection
                readOnly={readOnly}
                initialCoverImageUrl={
                  type === FormType.Edit ? initialValues.coverImageUrl : ""
                }
              />
            </div>
          </Restricted>
        )}
      </div>
    </FormProvider>
  );
};
