import QueryString from "query-string";

import History from "./history";

export function parseUrl(search?: string): Record<string, any> {
  if (!search) {
    return {};
  }

  return QueryString.parse(search);
}

export const getUrlParamString = (params: {}) => {
  const queryParams = Object.keys(params).reduce((mapped, param) => {
    if (params[param] && params[param].length === 0) {
      return mapped;
    }
    if (Array.isArray(params[param])) {
      return { [param]: params[param].join(","), ...mapped };
    } else {
      return { [param]: params[param], ...mapped };
    }
  }, {});
  const query = QueryString.stringify(queryParams);

  return query;
};

export function updateUrl(params: {}) {
  const query = getUrlParamString(params);
  History.replace(`?${query}`);
}

export const getFilenameFromDispositionHeader = (
  contentDisposition: string,
) => {
  const params = contentDisposition.split(";").reduce(
    (mapped, param) => {
      const [key, value] = param.split("=");
      return {
        [key.trim().replace(/"/g, "")]: value?.trim()?.replace(/"/g, ""),
        ...mapped,
      };
    },
    {} as {
      filename?: string;
      "filename*": string;
    },
  );

  if (params["filename*"]) {
    const percentEncoded = params["filename*"].split("''")[1];
    return decodeURIComponent(percentEncoded);
  }

  return params.filename ?? "";
};
