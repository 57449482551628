import {
  ApplicantExamStatus,
  DeliveryMethod,
  DeliveryStatus,
  MailStatus,
} from "../services/enums";

class ExamDeliverModel {
  private _id: number;
  private _examId: number;
  private _mailSubject: string;
  private _mailBody: string;
  private _deadline: string;
  private _deliverRoute: number;
  private _createdBy: number;
  private _createdAt: string;

  constructor(args: ExamDeliverModel) {
    this._id = args.id;
    this._examId = args.examId;
    this._mailSubject = args.mailSubject;
    this._mailBody = args.mailBody;
    this._deadline = args.deadline;
    this._deliverRoute = args.deliverRoute;
    this._createdBy = args.createdBy;
    this._createdAt = args.createdAt;
  }

  get id(): number {
    return this._id;
  }

  get examId(): number {
    return this._examId;
  }

  get mailSubject(): string {
    return this._mailSubject;
  }

  get mailBody(): string {
    return this._mailBody;
  }

  get deadline(): string {
    return this._deadline;
  }

  get deliverRoute(): number {
    return this._deliverRoute;
  }

  get createdBy(): number {
    return this._createdBy;
  }

  get createdAt(): string {
    return this._createdAt;
  }
}

export interface DeliveryApplicantModel {
  id: number;
  organizationId: number;
  userId: number;
  email: string;
  fullname: string;
  createdAt: string;
  updatedAt: string;
}

export interface ApplicantExamsModel {
  id: number;
  applicantId: number;
  examId: number;
  deliveryId: number;
  deadline: string;
  urlToken: string;
  status: ApplicantExamStatus;
  mailStatus: MailStatus;
  isTest: boolean;
  formValues: any;
  explanation?: string;
  guestSharingToken?: string;
  guestSharingUrl?: string;
  openedAt?: string;
  submittedAt: string;
  archivedAt: string;
  createdAt: string;
  updatedAt: string;
  applicant: DeliveryApplicantModel;
  atsKind?: number;
  atsParameters?: any;
  atsResponse?: number;
  maxScoreInProgress: number;
  totalScore?: number;
  finalScore?: number;
  deliveredAt?: string;
  deliveryChallengeVersionIds: number[];
  initialPassword?: string;
  includesPerformancesData?: boolean;
  scheduledFor?: string;
}

export interface MailDeliveryModel {
  id: number;
  examId: number;
  mailSenderName?: string;
  mailSubject?: string;
  mailBody?: string;
  startAt?: string;
  endAt?: string;
  language?: string;
  createdBy: number;
  updatedBy: number;
  createdAt: string;
  updatedAt: string;
  applicantExams: ApplicantExamsModel[];
  deliveryMethod: DeliveryMethod;
  deliveryStatus: DeliveryStatus;
  origin: string;
}

export interface MailDeliveryListModel {
  id: number;
  deliveryMethod: DeliveryMethod;
  examId: number;
  mailSenderName?: string;
  mailSubject?: string;
  mailBody?: string;
  startAt?: string;
  endAt?: string;
  language?: string;
  createdBy: number;
  updatedBy: number;
  createdAt: string;
  updatedAt: string;
  applicantExamCount: number;
  deliveryStatus: DeliveryStatus;
}

export interface MailDeliveryCreateModel {
  scheduledFor: string;
  startAt: string;
  deadline: string;
  applicants: { email: string }[];
  mailSubject: string;
  mailBody: string;
  senderName?: string;
}

export type MailDeliveryType = "scheduled" | "history";

export default ExamDeliverModel;
