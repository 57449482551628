import * as classnames from "classnames";

/**
 * Prop interface
 */
export interface PageTitleProps {
  className?: string;
  children?: {};
}

/**
 * React Component
 */
export default function PageTitle({ className, children }: PageTitleProps) {
  const rootStyle = classnames("code-c-page-title", "title", {
    [`${className}`]: Boolean(className),
  });

  return <h1 className={rootStyle}>{children}</h1>;
}
