import { Switch, Route, Redirect } from "react-router-dom";

import { RestrictedRoute } from "../../../../shared/components";
import { UserRole, ProjectRole } from "../../../../shared/services/enums";
import ExamCreate from "../examCreate/ExamCreate.connect";
import ExamDetailRoute from "../examDetail/ExamDetail.route.connect";
import OfficialExamList from "../officialExamList/OfficialExamList.connect";
import { ExamContainer } from "../talenthub/examContainer/ExamContainer";
import ExamList from "./ExamList.connect";

/**
 * Route settings
 */
const ExamListRoute = () => (
  <div>
    <Switch>
      <RestrictedRoute
        path="/p/:projectId/exams/new"
        exact={true}
        roles={[
          UserRole.SystemAdmin,
          ProjectRole.ProjectAdmin,
          ProjectRole.ExamCreator,
        ]}
      >
        <ExamCreate />
      </RestrictedRoute>
      <RestrictedRoute
        path="/p/:projectId/exams/new-multi"
        exact={true}
        roles={[
          UserRole.SystemAdmin,
          ProjectRole.ProjectAdmin,
          ProjectRole.ExamCreator,
        ]}
      >
        <ExamCreate enableMultiLanguage={true} />
      </RestrictedRoute>
      <RestrictedRoute
        path="/p/:projectId/exams/talenthub"
        exact={true}
        roles={[UserRole.SystemAdmin]}
      >
        <ExamContainer />
      </RestrictedRoute>
      <RestrictedRoute
        path="/p/:projectId/exams/official"
        exact={true}
        roles={[
          UserRole.SystemAdmin,
          ProjectRole.ProjectAdmin,
          ProjectRole.ExamCreator,
        ]}
      >
        <OfficialExamList />
      </RestrictedRoute>
      <Route
        path="/p/:projectId/exams/:status(active|inactive)"
        exact={true}
        render={({ match, location }) => (
          <ExamList
            location={location}
            status={match.params.status || "active"}
          />
        )}
      />
      <Route path="/p/:projectId/exams/:examId" component={ExamDetailRoute} />
      <Route
        path="/p/:projectId/exams/"
        exact={true}
        render={({ match }) => (
          <Redirect to={`/p/${match.params.projectId}/exams/active`} />
        )}
      />
    </Switch>
  </div>
);

export default ExamListRoute;
