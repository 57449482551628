import classnames from "classnames";
import * as React from "react";

import { useElement } from "@shared/hooks/useElement";

import { Button, ButtonType } from "..";
import Message from "../../services/message";

/**
 * Prop interface
 */
export interface ModalProps {
  title?: React.ReactNode;
  size?: "small" | "medium" | "large" | "x-large" | "full";
  isOpen?: boolean;
  okButtonLabel?: React.ReactNode;
  okButtonType?: ButtonType;
  okButtonAriaLabel?: string;
  onClickOk?: (payload?: {}) => void;
  cancelButtonLabel?: React.ReactNode;
  cancelButtonType?: ButtonType;
  cancelButtonAriaLabel?: string;
  contentClassName?: string;
  hasOkButton?: boolean;
  hasCancelButton?: boolean;
  hasCloseButton?: boolean;
  onClickCancel?: () => void;
  onClose?: () => void;
  onClickBackground?: () => void;
  className?: string;
  children?: {} | null;
  disableOk?: boolean;
  disableCancel?: boolean;
  disableClose?: boolean;
  hasSubMenu?: boolean;
  additionalFooterContent?: React.ReactNode;
  ariaLabel?: string;
}

/**
 * React Component
 */
const Modal = ({
  title = "",
  isOpen = false,
  hasOkButton = true,
  hasCancelButton = true,
  hasCloseButton = true,
  okButtonType = "primary",
  okButtonAriaLabel = "OK",
  cancelButtonType = "primary",
  cancelButtonAriaLabel = "Cancel",
  contentClassName = "",
  disableOk = false,
  disableCancel = false,
  disableClose = false,
  hasSubMenu = false,
  children,

  size,
  okButtonLabel = Message.getMessageByKey("common.ok"),
  onClickCancel,
  onClickOk,
  cancelButtonLabel = Message.getMessageByKey("cancel"),
  className,
  onClose,
  onClickBackground,
  additionalFooterContent,
  ariaLabel,
}: ModalProps) => {
  const modalBody = React.useRef<HTMLElement | null>(null);

  const { addClass, removeClass } = useElement({ tagName: "html" });

  React.useEffect(() => {
    if (isOpen && modalBody.current) {
      modalBody.current.scrollTop = 0;
    }
  }, [isOpen, modalBody]);

  React.useEffect(() => {
    if (isOpen) {
      addClass("overflow-hidden");
    } else {
      removeClass("overflow-hidden");
    }

    return () => {
      removeClass("overflow-hidden");
    };
  }, [isOpen, addClass, removeClass]);

  const rootStyle = classnames("code-c-modal", "modal", {
    "is-open": isOpen,
    [`is-${size}`]: Boolean(size),
    "is-submenu": hasSubMenu,
    "hide-close": !hasCloseButton,
    [`${className}`]: Boolean(className),
  });
  const contentClassNames = classnames("modal-card-body", contentClassName);
  const cancelButtonClassNames = classnames(
    "modal-card-foot__buttons__cancel",
    `is-${cancelButtonType}`,
  );

  const cancelButton = hasCancelButton ? (
    <Button
      className={cancelButtonClassNames}
      outlined={true}
      onClick={onClickCancel}
      disabled={disableCancel}
      ariaLabel={cancelButtonAriaLabel}
    >
      {cancelButtonLabel}
    </Button>
  ) : null;

  const okButton = hasOkButton ? (
    <Button
      className={"modal-card-foot__buttons__ok"}
      type={okButtonType}
      onClick={onClickOk}
      disabled={disableOk}
      ariaLabel={okButtonAriaLabel}
    >
      {okButtonLabel}
    </Button>
  ) : null;
  const showActions = okButton || cancelButton || additionalFooterContent;

  return (
    <div className={rootStyle}>
      <div className="modal-background" onClick={onClickBackground} />
      <div className="modal-card" role="dialog" aria-label={ariaLabel}>
        <div className="modal-card-head__top-border" />
        <header className="modal-card-head">
          <p className="modal-card-title clamp-1">{title}</p>
          <button
            type="button"
            className="delete"
            aria-label="Close"
            onClick={onClose}
            disabled={disableClose}
          />
        </header>
        <section className={contentClassNames} ref={modalBody}>
          {children}
        </section>
        {showActions && (
          <footer className="modal-card-foot">
            {additionalFooterContent && (
              <div className="modal-card-foot__text">
                {additionalFooterContent}
              </div>
            )}
            <div className="modal-card-foot__buttons">
              {cancelButton}
              {okButton}
            </div>
          </footer>
        )}
      </div>
    </div>
  );
};

export default React.memo(Modal);
