import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

import {
  Avatar,
  FormGroup,
  ImageUpload,
  Msg,
  PanelStandardContents,
  SwitchContainer,
} from "@shared/components";
import { UploadImagesModel } from "@shared/models";
import Message from "@shared/services/message";

import { ExamSettingsFormValidation } from "../../utils";

interface InjectedProps {
  uploadImage: (
    name: string,
    file: File,
    width: number,
    height: number,
    tags: string,
    transformation: string,
  ) => void;
  uploadingImage: boolean;
  uploadedImages: UploadImagesModel;
}

export interface ExternalProps {
  readOnly?: boolean;
  setImageTouched?: () => void;
  initialCoverImageUrl?: string;
}

const defaultImageSrc = require("../../../../../../../assets/images/default_coverimage.png");

type PresetExamSectionProps = InjectedProps & ExternalProps;

export const PresetExamSection = ({
  readOnly,
  uploadImage,
  uploadingImage,
  uploadedImages = {},
  initialCoverImageUrl,
  setImageTouched: setImageTouchedFunc,
}: PresetExamSectionProps) => {
  const [imageTouched, setImageTouched] = useState(false);

  const { watch, setValue } = useFormContext<ExamSettingsFormValidation>();

  const [isOfficial, coverImageUrl] = watch(["isOfficial", "coverImageUrl"]);

  useEffect(() => {
    if (!uploadingImage && imageTouched && uploadedImages.examCover) {
      setValue("coverImageUrl", uploadedImages.examCover.url, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
  }, [
    setValue,
    uploadingImage,
    uploadImage,
    imageTouched,
    uploadedImages.examCover,
  ]);

  useEffect(() => {
    if (readOnly) {
      setImageTouched(false);
    }
  }, [readOnly]);

  const imageURL = imageTouched ? coverImageUrl : initialCoverImageUrl;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue("isOfficial", event.target.checked, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  const onDropImage = (files: Array<File>) => {
    if (files.length) {
      if (typeof setImageTouchedFunc === "function") {
        setImageTouchedFunc();
      }

      uploadImage("examCover", files[0], 190, 110, "exam", "g_face");
      setImageTouched(true);
    }
  };

  const uploadCoverMessage = (
    <div className="code-exam-create__preset-exam__cover-message">
      <Msg tagName="p" id="exam.presetExam.coverImage" />
      <Msg tagName="p" id="exam.presetExam.optional" />
    </div>
  );

  return (
    <SwitchContainer
      expanded={isOfficial}
      value={isOfficial}
      readOnly={readOnly}
      onChange={handleChange}
      isStandardPanel
      switchContent={
        <PanelStandardContents
          title={Message.getMessageByKey("exam.presetExam.set")}
        >
          <Msg id="exam.presetExam.setDescription" />
        </PanelStandardContents>
      }
      dataTestautomationid="presetExamSwitch"
    >
      <div className="code-exam-create__preset-exam">
        <FormGroup className="code-exam-create__preset-exam__cover-image">
          {readOnly ? (
            <div className="code-exam-create__preset-exam__cover-image-spacer">
              <Avatar
                src={imageURL}
                defaultSrc={defaultImageSrc}
                defaultMessage={uploadCoverMessage}
                size="xxlarge-landscape"
              />
            </div>
          ) : (
            <ImageUpload
              src={imageURL}
              disabled={uploadingImage}
              onDrop={onDropImage}
              uploadType="image"
              defaultMessage={uploadCoverMessage}
              stretchButton
              avatarSize="xxlarge-landscape"
              className="code-exam-create__preset-exam__cover-image__upload"
            />
          )}
        </FormGroup>
      </div>
    </SwitchContainer>
  );
};
