import * as classnames from "classnames";
import * as React from "react";

import {
  PageTitle,
  FormGroup,
  Form,
  Label,
  Input,
  Button,
  Loading,
  PasswordRequirements,
  Checkbox,
  LeftBlock,
  RightBlock,
  Block,
  Msg,
  ErrorTile,
} from "@shared/components";
import { UserInvitationModel } from "@shared/models";
import Message from "@shared/services/message";
import { getSignInURL } from "@shared/services/url";

/**
 * Prop interface
 */
export type ExternalProps = {
  token: string;
};

export type InjectedProps = {
  language: string;
  invitation: UserInvitationModel | undefined;
  submitting: boolean;
  success: boolean;
  error: boolean;
  signUp: (payload: {}) => void;
  getInvitationDetails: (payload: {}) => void;
};

export type RegistrationProps = InjectedProps & ExternalProps;

/**
 * State interface
 */
export interface RegistrationState {
  formValues: {
    password: string;
  };
}

/**
 * Page component
 */
class Registration extends React.Component<
  RegistrationProps,
  RegistrationState
> {
  constructor(props: RegistrationProps) {
    super(props);

    this.state = {
      formValues: {
        password: "",
      },
    };
  }

  public componentDidMount() {
    this.props.getInvitationDetails(this.props.token);
  }

  public componentDidUpdate() {
    const { invitation } = this.props;
    if (invitation && !Boolean(invitation.needSignup)) {
      window.setTimeout(() => {
        window.location.href = getSignInURL(invitation.orgname, {
          force: true,
          prefillEmail: invitation.invitation.email,
        });
      }, 1000);
    }
  }

  public render() {
    const rootStyle = classnames("code-registration");

    const { success, error, invitation, submitting } = this.props;

    return (
      <div className={rootStyle}>
        {!success && !error && <Loading isOpen={true} />}
        {success && invitation?.needSignup && (
          <div>
            <PageTitle>
              <Msg id="navigate.signup.title" />
            </PageTitle>
            <Form
              validation={{
                name: ["string", "required"],
                password: ["string", "password", "required"],
                privacy: ["boolean", "required"],
              }}
              onSubmit={this.onSubmit}
              onFormChange={this.onFormChange}
            >
              <FormGroup className="form-email">
                <Label>
                  <Msg id="form.email" />
                </Label>
                {this.props.invitation?.invitation.email ?? ""}
              </FormGroup>
              <Block>
                <LeftBlock direction="column">
                  <FormGroup>
                    <Label>
                      <Msg id="form.name" />
                    </Label>
                    <Input name="name" />
                  </FormGroup>
                  <FormGroup>
                    <Label>
                      <Msg id="form.password" />
                    </Label>
                    <Input name="password" type="password" />
                  </FormGroup>
                </LeftBlock>
                <RightBlock>
                  <PasswordRequirements
                    className="code-password-reset__requirements"
                    password={this.state.formValues.password}
                  />
                </RightBlock>
              </Block>
              <FormGroup>
                <Checkbox name="privacy">
                  <Msg id="member.agreement" />
                </Checkbox>
              </FormGroup>
              <FormGroup className="code-registration__button">
                <Button type="primary" ariaLabel="Sign Up" loading={submitting}>
                  <Msg id="action.signup" />
                </Button>
              </FormGroup>
            </Form>
          </div>
        )}
        {success && !invitation?.needSignup && (
          <ErrorTile
            title="Loading..."
            errorMessage={Message.getMessageByKey(
              "message.invitation.redirect",
            )}
            errorDescription={Message.getMessageByKey(
              "message.invitation.already.registered",
            )}
            hasBoxShadow={false}
            hasBackButton={false}
          />
        )}
        {error && (
          <ErrorTile
            errorMessage={Message.getMessageByKey("error.invitation.title")}
            errorDescription={Message.getMessageByKey(
              "error.invitation.description",
            )}
            hasBoxShadow={false}
            hasBackButton={false}
          />
        )}
      </div>
    );
  }

  private onFormChange = (
    formValid: boolean,
    formValues: { password: string },
  ) => {
    this.setState({
      formValues,
    });
  };

  private onSubmit = (formValues: {}) => {
    this.props.signUp({
      ...formValues,
      token: this.props.token,
      language: this.props.language,
    });
  };
}

export default Registration;
