import classNames from "classnames";

import { Button, CopyBox, Dropdown, Icon, Msg } from "@shared/components";
import { useExamPermissions, useUserRoles } from "@shared/hooks";
import {
  useExam,
  useSubmission,
  useUpdateSubmissionArchive,
} from "@shared/hooks/query";
import { ApplicantExamStatus } from "@shared/services/enums";
import Message from "@shared/services/message";

interface SubmissionActionsProps {
  className?: string;
  onManualSubmitClick: () => void;
}

function SubmissionActions({
  className,
  onManualSubmitClick,
}: SubmissionActionsProps) {
  const rootStyle = classNames("code-c-submission-actions", className);
  const {
    data: { examDetail },
  } = useExam();
  const { data: submission } = useSubmission();
  const archiveSubmission = useUpdateSubmissionArchive(true);
  const { isProjectAdmin } = useUserRoles();
  const { isGuestSharingEnabled } = useExamPermissions();
  const dropdownButtonLabel = Message.getMessageByKey("common.share");
  const isAllowArchive = [
    ApplicantExamStatus.Expired,
    ApplicantExamStatus.Submitted,
    ApplicantExamStatus.InReview,
    ApplicantExamStatus.Passed,
    ApplicantExamStatus.Failed,
  ].includes(submission.status);
  const isAllowSubmitted =
    ApplicantExamStatus.InProgress === submission.status &&
    !Boolean(examDetail.archivedAt);

  const handleArchiveClick = () => {
    archiveSubmission.mutate(submission.id);
  };

  const handleManualSubmitClick = () => {
    onManualSubmitClick?.();
  };

  return (
    <div className={rootStyle}>
      {isGuestSharingEnabled && (
        <Dropdown
          icon="arrow-up-from-bracket"
          placement="bottomRight"
          trigger={{
            label: dropdownButtonLabel,
            className: "text-md",
            shrink: true,
          }}
        >
          <div className="code-c-submission-sharing">
            <Msg
              className="text-sm has-text-gray-600"
              tagName="p"
              id="submission.submissionDetail.sharing"
            />
            <CopyBox value={submission.guestSharingUrl} scale="sm" />
          </div>
        </Dropdown>
      )}
      <Button
        className="text-md"
        onClick={() => window.print()}
        ariaLabel="Print"
        shrink
      >
        <Icon type="print" size="medium" />
        <Msg id="print" />
      </Button>
      {isProjectAdmin && (isAllowArchive || isAllowSubmitted) && (
        <Dropdown
          icon="ellipsis-vertical"
          placement="bottomRight"
          trigger={{
            ariaLabel: "More Actions",
            className: "text-md",
            shrink: true,
          }}
        >
          <ul className="code-c-submission-actions-dropdown-items-list">
            {isAllowArchive && (
              <li onClick={handleArchiveClick} className="code-c-list-item">
                <Icon className="code-c-icon__text-base" type="box-archive" />
                <Msg id="common.archive" />
              </li>
            )}
            {isAllowSubmitted && (
              <li
                onClick={handleManualSubmitClick}
                className="code-c-list-item text-md has-text-error-700"
              >
                <Icon className="code-c-icon__text-base" type="file-export" />
                <Msg id="submission.submitExam" />
              </li>
            )}
          </ul>
        </Dropdown>
      )}
    </div>
  );
}

export default SubmissionActions;
