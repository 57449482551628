import {
  Chart as ChartJS,
  BarElement,
  PointElement,
  LineElement,
  Tooltip,
  TooltipItem,
  ChartData,
  LineController,
  BarController,
} from "chart.js";
import * as classnames from "classnames";
import { isEqual } from "lodash";
import * as React from "react";
import { Chart } from "react-chartjs-2";

import { DifficultyTag, Msg } from "@shared/components";
import { SubmissionReportChallengeModel } from "@shared/models";
import { formatTimeLeftString } from "@shared/services/date";
import {
  ChallengeStyle,
  ChallengeResultStatus,
  ChartColor,
} from "@shared/services/enums";
import Message from "@shared/services/message";

ChartJS.register(
  BarElement,
  PointElement,
  LineElement,
  Tooltip,
  LineController,
  BarController,
);

/**
 * Prop interface
 */
export interface ExamReportChallengeDetailProps {
  challenge: SubmissionReportChallengeModel;
}

// Move to service if needed elsewhere in the future
const getGrade = (scorePercentage: number) => {
  if (scorePercentage >= 90) {
    return "SS";
  } else if (scorePercentage >= 80) {
    return "S";
  } else if (scorePercentage >= 70) {
    return "A";
  } else if (scorePercentage >= 60) {
    return "B";
  } else if (scorePercentage >= 50) {
    return "C";
  } else if (scorePercentage >= 40) {
    return "D";
  } else if (scorePercentage >= 30) {
    return "E";
  } else {
    return "F";
  }
};

const truncateName = (name: string) =>
  (name && name.length) > 20 ? name.substring(0, 17) + "..." : name;

/**
 * Page component
 */
class ExamReportChallengeDetail extends React.Component<ExamReportChallengeDetailProps> {
  constructor(props: ExamReportChallengeDetailProps) {
    super(props);
  }

  public shouldComponentUpdate(nextProps: ExamReportChallengeDetailProps) {
    return !isEqual(nextProps, this.props);
  }

  public render() {
    const { challenge } = this.props;

    const options = {
      indexAxis: "y" as const,
      maintainAspectRatio: false,
      animation: false as const,
      transitions: {
        active: {
          animation: {
            duration: 0,
          },
        },
      },
      scales: {
        xAxis: {
          min: 0,
          max: 100,
          ticks: {
            display: false,
          },
          grid: {
            display: false,
          },
          border: {
            display: false,
          },
        },
        yAxis: {
          grid: {
            display: false,
          },
          border: {
            display: false,
          },
        },
      },
      clip: false as const,
      plugins: {
        datalabels: {
          display: false,
        },
        tooltip: {
          enabled: true,
          position: "nearest" as const,
          mode: "nearest" as const,
          intersect: true,
          animation: {
            duration: 0,
            easing: "linear" as const,
          },
          padding: {
            x: 10,
            y: 5,
          },
          caretPadding: 10,
          displayColors: false,
          callbacks: {
            title: () => "",
            label: (context: TooltipItem<"bar">) =>
              `${context.dataset.label}: ${context.formattedValue}%`,
          },
        },
      },
    };

    const categoryData: ChartData<
      "bar" | "line",
      {
        x: number;
        y: string;
      }[],
      string
    > = challenge.categories.length
      ? {
          labels: challenge.categories.map((category) =>
            truncateName(category.title),
          ),
          datasets: [
            {
              type: "line" as const,
              label: Message.getMessageByKey("report.average.exam"),
              yAxisID: "yAxis",
              xAxisID: "xAxis",
              fill: false,
              tension: 0,
              pointRadius: 5,
              borderColor: ChartColor.Green,
              pointBackgroundColor: ChartColor.Green,
              pointBorderColor: ChartColor.White,
              data: challenge.categories.map((category) => ({
                x: category.examAverageScore,
                y: truncateName(category.title),
              })),
            },
            {
              label: Message.getMessageByKey("report.average.system"),
              xAxisID: "xAxis",
              yAxisID: "yAxis",
              type: "line",
              tension: 0,
              pointRadius: 5,
              fill: false,
              borderColor: ChartColor.Purple,
              pointBackgroundColor: ChartColor.Purple,
              pointBorderColor: ChartColor.White,
              data: challenge.categories.map((category) => ({
                x: category.systemAverageScore,
                y: truncateName(category.title),
              })),
            },
            {
              type: "bar" as const,
              label: Message.getMessageByKey("common.applicant"),
              barThickness: 15,
              xAxisID: "xAxis",
              yAxisID: "yAxis",
              backgroundColor: ChartColor.Primary,
              data: challenge.categories.map((category) => ({
                x:
                  Math.round(
                    (category.applicantScore / category.maximumScore) * 10000,
                  ) / 100,
                y: truncateName(category.title),
              })),
            },
          ],
        }
      : { datasets: [] };

    const rootStyle = classnames("code-exam-report-challenge-detail");

    const categoryList = challenge.categories.length
      ? challenge.categories.map((category) => {
          const score = category.applicantScore;
          const max = category.maximumScore;

          const grade = getGrade((score / max) * 100);

          return (
            <div key={category.title} className="challenge-details__detail">
              <div className="details-title-description">
                <div className="details-title">{category.title}</div>
                <div className="details-description">
                  {category.description}
                </div>
              </div>
              <div className="details-grade-score">
                <div className={`details-grade rank-${grade.toLowerCase()}`}>
                  {grade}
                </div>
                <div className="details-score">{`${score}/${max}`}</div>
              </div>
            </div>
          );
        })
      : null;

    const graph = challenge.categories.length ? (
      <div className="challenge-graph">
        <div style={{ height: 30 + challenge.categories.length * 30 + "px" }}>
          {/* https://react-chartjs-2.js.org/examples/multitype-chart */}
          <Chart type="bar" data={categoryData} options={options} />
        </div>
      </div>
    ) : null;

    const list = challenge.categories.length ? (
      <div className="challenge-details">{categoryList}</div>
    ) : null;

    // TODO decide if not modified somehow
    return challenge.notModified ? (
      <div className={rootStyle}>
        <div className="challenge-title">
          {challenge.title}
          <span className="challenge-title__status">
            {ChallengeResultStatus.toString(ChallengeResultStatus.NotModified)}
          </span>
        </div>
      </div>
    ) : (
      <div className={rootStyle}>
        <div className="challenge-title">{challenge.title}</div>
        <div className="challenge-overview">
          {graph}
          <div className="challenge-stats">
            <table>
              <tbody>
                <tr>
                  <td className="challenge-stats__title">
                    <Msg id="report.score" />
                  </td>
                  <td colSpan={3}>
                    {challenge.score}
                    <Msg id="report.points" />
                  </td>
                </tr>
                <tr>
                  <td className="challenge-stats__title">
                    <Msg id="report.timeSpent" />
                  </td>
                  <td colSpan={3}>
                    {challenge.timeSpentSeconds
                      ? formatTimeLeftString(challenge.timeSpentSeconds)
                      : "--"}
                  </td>
                </tr>
                <tr>
                  <td className="challenge-stats__title">
                    <Msg id="report.average.exam" />
                  </td>
                  <td className="within-exam">
                    {challenge.examAverageScore}
                    <Msg id="report.points" />
                  </td>
                  <td className="challenge-stats__title">
                    <Msg id="report.average.system" />
                  </td>
                  <td className="within-country">
                    {challenge.systemAverageScore}
                    <Msg id="report.points" />
                  </td>
                </tr>
                <tr>
                  <td className="challenge-stats__title">
                    <Msg id="report.max.exam" />
                  </td>
                  <td className="within-exam">
                    {challenge.examMaxScore}
                    <Msg id="report.points" />
                  </td>
                  <td className="challenge-stats__title">
                    <Msg id="report.max.system" />
                  </td>
                  <td className="within-country">
                    {challenge.systemMaxScore}
                    <Msg id="report.points" />
                  </td>
                </tr>
                <tr>
                  <td className="challenge-stats__title">
                    <Msg id="report.std.exam" />
                  </td>
                  <td className="within-exam">
                    {challenge.examStandardDeviation}
                    <Msg id="report.points" />
                  </td>
                  <td className="challenge-stats__title">
                    <Msg id="report.std.system" />
                  </td>
                  <td className="within-country">
                    {challenge.systemStandardDeviation}
                    <Msg id="report.points" />
                  </td>
                </tr>
                <tr>
                  <td className="challenge-stats__title">
                    <Msg id="report.deviationScore.exam" />
                  </td>
                  <td className="within-exam">{challenge.examScoreDevValue}</td>
                  <td className="challenge-stats__title">
                    <Msg id="report.deviationScore.system" />
                  </td>
                  <td className="within-country">
                    {challenge.systemScoreDevValue}
                  </td>
                </tr>
                <tr>
                  <td className="challenge-stats__title">
                    <Msg id="report.type" />
                  </td>
                  <td colSpan={3}>
                    {ChallengeStyle.toString(challenge.style)}
                  </td>
                </tr>
                <tr>
                  <td className="challenge-stats__title">
                    <Msg id="report.difficulty" />
                  </td>
                  <td colSpan={3}>
                    <DifficultyTag value={challenge.difficulty} />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {list}
      </div>
    );
  }
}

export default ExamReportChallengeDetail;
