import * as React from "react";

import { addTextHighlight } from "@shared/services/string";

/**
 * Props
 */
export interface HighlightedTextProps {
  keyword?: string;
  children: string;
}

export const HighlightedText: React.FunctionComponent<HighlightedTextProps> = (
  props: HighlightedTextProps,
) => {
  const { children = "", keyword } = props;
  if (!keyword) {
    return <>{children}</>;
  }
  return (
    <>
      {addTextHighlight(children, keyword, (chunk, index) => (
        <mark key={`match${index}`}>{chunk}</mark>
      ))}
    </>
  );
};
