import { generate } from "shortid";

export enum FileUploadStatus {
  NOT_STARTED,
  UPLOADING,
  COMPLETE,
  FAILED,
  CANCELLED,
}

export interface FileUpload {
  id: string;
  status: FileUploadStatus;
  file: File;
  uploadId?: string;
  chunks: FileChunkUpload[];
  abortController: AbortController;
}

export interface FileChunkUpload {
  id: string;
  fileId: string;
  filename: string;
  partNumber: number;
  chunk: Blob;
  eTag?: string;
  status: FileUploadStatus;
  abortController: AbortController;
}

export interface FileUploadConfig {
  uploadEnabled: boolean;
}
export interface GlobalFileUploadConfigResponse {
  maxUploadLimitMiB: number;
  maxUploadCountLimit: number;
  uploadPartMaxSizeMiB: number;
}

export interface FileList {
  file: File;
  formatSize: string;
}

export const MAX_MB_FILE_UPLOAD = 50;

export const getFileChunks = ({
  fileId,
  file,
  abortController,
  maxChunkSize = MAX_MB_FILE_UPLOAD * 1024 * 1024, // 50MB in bytes
}: {
  fileId: string;
  file: File;
  abortController: AbortController;
  maxChunkSize?: number;
}) => {
  const fileChunks: FileChunkUpload[] = [];

  const chunkCount = Math.ceil(file.size / maxChunkSize);

  for (let i = 0; i < chunkCount; i++) {
    const chunkStart = i * maxChunkSize;
    const chunkEnd = Math.min(file.size, chunkStart + maxChunkSize);
    const chunk = file.slice(chunkStart, chunkEnd);
    fileChunks.push({
      id: generate(),
      fileId,
      filename: file.name,
      partNumber: i + 1,
      chunk,
      status: FileUploadStatus.NOT_STARTED,
      abortController,
    });
  }

  return fileChunks;
};
