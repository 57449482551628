import ApplicantChallengeModel from "./ApplicantChallenge.model";

export default class ApplicantChallengeSetModel {
  public id: number;
  public displayOrder: number;
  public isOptionalSet: boolean;
  public isRandomSet: boolean;
  public numberChallengesToTake = 0;
  public challenges: ApplicantChallengeModel[] = [];

  constructor(args: {}) {
    Object.assign(this, args);
    this.challenges = this.challenges
      .sort((a, b) => a.displayOrder - b.displayOrder)
      .map((item) => new ApplicantChallengeModel(item));
  }
}
