import { Icon, Msg } from "@shared/components";

interface CheatMonitorHeaderProps {
  onHelpClick: (e: React.MouseEvent) => void;
}

export const CheatMonitorHeader = ({
  onHelpClick,
}: CheatMonitorHeaderProps) => (
  <div className="code-c-submission-detail__cheat-monitor-header">
    <h3 className="text-heading3">
      <Msg id="submission.submissionDetail.cheatMonitor" />
    </h3>
    <Icon
      type="info-circle"
      iconClassName="code-c-submission-detail__cheat-monitor-helpbutton"
      onClick={onHelpClick}
    />
  </div>
);
