import * as React from "react";

const nest = (children: React.ReactNode, component: React.ReactElement) =>
  React.cloneElement(component, {}, children);

export type MultiProviderProps = React.PropsWithChildren<{
  providers: React.ReactElement[];
}>;

const MultiProvider = ({ children, providers }: MultiProviderProps) => (
  // reduceRight will nest the providers from top to bottom
  <>{providers.reduceRight(nest, children)}</>
);

export default MultiProvider;
