import * as React from "react";

export function useDidUpdateEffect(
  callback: () => void,
  deps?: React.DependencyList,
) {
  const didMount = React.useRef(false);

  React.useEffect(() => {
    if (didMount.current) {
      return callback();
    }
    didMount.current = true;
    // callback function shouldn't be a dependency, see https://legacy.reactjs.org/docs/hooks-faq.html#is-it-safe-to-omit-functions-from-the-list-of-dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}
