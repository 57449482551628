import * as classnames from "classnames";

import { Tooltip, WarningTag } from "@shared/components";
import { SubmissionModel, SubmissionListModel } from "@shared/models";
import Message from "@shared/services/message";

import { getIssueTotals } from "../SubmissionDetailUtils";

interface Props {
  submission: SubmissionModel | SubmissionListModel;
  className?: string;
  limitHeight?: boolean;
}

export const SuspiciousCountTooltip = ({
  submission,
  className,
  limitHeight,
}: Props) => {
  const rootClass = classnames(
    "code-c-suspicious-count-tooltip",
    { ["code-c-suspicious-count-tooltip-limitheight"]: limitHeight },
    className,
  );
  const cheatMonitoring = submission?.cheatMonitoring || {};

  const suspiciousTypes = {
    copyPaste: false,
    leftTab: false,
    screenshot: false,
    sessionData: false,
    multiSubmission: false,
  };

  const issueTotals = getIssueTotals(
    cheatMonitoring,
    submission?.overlappingIpAddresses,
  );

  Object.entries(issueTotals.counts).forEach(([key, value]) => {
    if (value > 0) {
      suspiciousTypes[key as keyof typeof suspiciousTypes] = true;
    }
  });

  // SubmissionModel and SubmissionListModel have different properties for overlappingIpAddresses
  if (
    issueTotals.counts.multiSubmission > 0 ||
    submission?.overlappingIpAddresses?.hasOverlappingApplicantExams
  ) {
    suspiciousTypes.multiSubmission = true;
  }

  if (issueTotals.total === 0) {
    return null;
  }

  const suspiciousTypesTooltipText =
    Message.getMessageByKey(
      "submission.submissionDetail.cheatMonitor.suspiciousDetectedIn.intro",
    ) +
    Object.keys(suspiciousTypes)
      .filter((key) => suspiciousTypes[key as keyof typeof suspiciousTypes])
      .map((key) => {
        return Message.getMessageByKey(
          `submission.submissionDetail.cheatMonitor.suspiciousDetectedIn.${key}`,
        );
      })
      .join(", ");

  return (
    <Tooltip
      wrapperClassName={rootClass}
      text={suspiciousTypesTooltipText}
      placement="bottom"
    >
      <WarningTag rounded bordered iconSize="medium">
        {issueTotals.total}
      </WarningTag>
    </Tooltip>
  );
};
