import * as classnames from "classnames";

import { Icon, TagCloud, Msg, HighlightedText } from "..";
import { EnumModel, QuestionListModel } from "../../../shared/models";

/**
 * Prop interface
 */
interface ParentProps {
  question: QuestionListModel;
  keyword?: string;
  className?: string;
  hasTagCloud?: boolean;
  isOpen?: boolean;

  onClick?: () => void;
}

export interface OwnProps {
  quizCategories: Array<EnumModel>;
}

type QuestionTitleColumnProps = OwnProps & ParentProps;

/**
 * React Component
 */
export default function QuestionTitleColumn({
  quizCategories = [],
  keyword,
  onClick,
  className,
  question,
  hasTagCloud = true,
  isOpen,
}: QuestionTitleColumnProps) {
  const rootStyle = classnames("code-c-question-title-column", {
    [`${className}`]: Boolean(className),
  });

  const titleStyle = classnames("code-c-question-title-column__title", {
    hasClick: Boolean(onClick),
  });

  const tagItems = [
    <Msg
      id={
        question.isOfficial
          ? "common.category.official"
          : "common.category.custom"
      }
      key="isOfficial"
    />,
    ...question.quizCategories.map(
      (id) =>
        (
          quizCategories.find((category) => category.value === id) || {
            displayString: "",
          }
        ).displayString,
    ),
    ...question.tags,
  ].map((tag: string) => ({ label: tag }));

  const tagCloud = hasTagCloud ? (
    <div className="code-c-question-title-column__tags">
      <TagCloud tagItems={tagItems} />
    </div>
  ) : null;

  return (
    <div className={rootStyle}>
      <p
        className={titleStyle}
        onClick={onClick}
        role="button"
        aria-pressed={isOpen}
        aria-label="Toggle Question Detail"
      >
        <HighlightedText keyword={keyword}>{question.title}</HighlightedText>
        {onClick && (
          <Icon
            type="angle-down"
            className="code-c-question-title-column__toggle"
          />
        )}
      </p>
      {tagCloud}
    </div>
  );
}
