import { Epic } from "redux-observable";
import { Observable } from "rxjs";
import { ajax } from "rxjs/observable/dom/ajax";
import { generate } from "shortid";

import { ChallengeTakenBy } from "@shared/services/enums";
import { getMillisBeforeExamDeadline } from "@shared/services/exam";

import {
  Action,
  ajaxAction,
  alertAction,
  reloadAction,
  redirectAction,
  applicantInitialGetAction,
  applicantSharingGetAction,
  applicantInvitationAction,
  applicantAuthAction,
  applicantExamGetAction,
  applicantExamSubmitAction,
  applicantSessionExtendAction,
  applicantUpdateFormAction,
  applicantExamOpenAction,
  challengeStartAction,
  challengeStartStep1Action,
  challengeStartStep2Action,
  challengeStartStep3Action,
  challengeSwitchLangAction,
  challengeResetAction,
  challengeUpdateVarsAction,
  challengeContextAction,
  challengeSwitchTakenByAction,
  intercomSetAction,
  APIResponseAction,
  presignedAction,
  getSourceFiles,
  updateAppealComment,
  applicantIdSharingGetAction,
  applicantIdStartExamAction,
  applicantIdPasswordAction,
  applicantIdSigninAction,
  applicantPasteEventAction,
  AjaxActionOptions,
  applicantSwitchTabEventAction,
} from "../actions";
import { RootState } from "../reducers";
import Msg from "../shared/services/message";
import {
  sessionStorage,
  SHOW_COMPLETED_MODAL_KEY,
} from "../shared/services/storage";

const applicantInitialGetEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(applicantInitialGetAction.types.request).map((action) =>
    ajaxAction.request({
      method: "get",
      url: `/api/applicants/exams/${action.payload}/min`,
      success: applicantInitialGetAction.success,
      error: alertAction.error(
        Msg.getMessageByKey("message.applicant.examNotFound"),
        applicantInitialGetAction.failure,
      ),
      options: {
        disableDefaultError: true,
      },
    }),
  );

const applicantInitialSetEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(applicantInitialGetAction.types.success).flatMap((action) => [
    intercomSetAction({
      type: "applicant_exam",
      data: (action as APIResponseAction).payload.result,
    }),
    applicantExamGetAction.request({}),
  ]);

const applicantSharingGetEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(applicantSharingGetAction.types.request).map((action) =>
    ajaxAction.request({
      method: "get",
      url: `/api/urlexams/${action.payload}`,
      success: applicantSharingGetAction.success,
      error: alertAction.error(
        Msg.getMessageByKey("message.applicant.examNotFound"),
        applicantSharingGetAction.failure,
      ),
      options: {
        disableDefaultError: true,
      },
    }),
  );

const applicantIdSharingGetEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(applicantIdSharingGetAction.types.request).map((action) =>
    ajaxAction.request({
      method: "get",
      url: `/api/idexam/${action.payload}`,
      success: applicantIdSharingGetAction.success,
      error: alertAction.error(
        Msg.getMessageByKey("message.applicant.examNotFound"),
        applicantIdSharingGetAction.failure,
      ),
      options: {
        disableDefaultError: true,
      },
    }),
  );

const applicantSharingSetEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(applicantSharingGetAction.types.success).map((action) =>
    intercomSetAction({
      type: "applicant_sharing",
      data: (action as APIResponseAction).payload.result,
    }),
  );

const applicantInvitationEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(applicantInvitationAction.types.request).map((action) =>
    ajaxAction.request({
      method: "post",
      url: `/api/urlexams/${state.getState().applicant.initialShared.urlToken}`,
      body: action.payload,
      success: redirectAction(
        `/invitation/sent`,
        alertAction.success(
          Msg.getMessageByKey("message.applicant.sendInvitation"),
        ),
      ),
    }),
  );

const applicantAuthEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(applicantAuthAction.types.request).map((action) =>
    ajaxAction.request({
      method: "post",
      url: "/api/applicants/exams/auth",
      body: action.payload,
      success: alertAction.success(
        Msg.getMessageByKey("message.applicant.verify.email"),
        applicantAuthAction.success,
      ),
      error: applicantAuthAction.failure,
    }),
  );

const applicantAuthSuccessEpic: Epic<Action, RootState> = (action$, state) =>
  action$
    .ofType(applicantAuthAction.types.success)
    // MEMO Avoid infinite loop applicantAuthAction.success => applicantExamGetAction => applicantAuthAction.success
    .filter(() => state.getState().applicant.exam.exam.isEmpty())
    .map((action) => applicantExamGetAction.request({}));

const applicantExamGetEpic: Epic<Action, RootState> = (action$, state) =>
  action$
    .ofType(
      applicantExamGetAction.types.request,
      applicantExamSubmitAction.types.success,
    )
    .exhaustMap((action) =>
      Observable.of(
        ajaxAction.request({
          method: "get",
          url: `/api/applicants/exams/${state.getState().applicant.initial.id}`,
          success: applicantExamGetAction.success,
          error: applicantExamGetAction.failure,
          options: {
            disableDefaultError: true,
            background: true,
          },
        }),
      ),
    );

const applicantExamGetSuccessEpic: Epic<Action, RootState> = (action$, state) =>
  action$
    .ofType(applicantExamGetAction.types.success)
    .mergeMap((action: Action) => [
      intercomSetAction({
        type: "applicant",
        data: (action as APIResponseAction).payload.result,
      }),
      applicantAuthAction.success({}),
    ]);

const applicantExamGetErrorEpic: Epic<Action, RootState> = (action$, state) =>
  action$
    .ofType(applicantExamGetAction.types.failure)
    .map((action) => applicantAuthAction.failure({}));

const applicantExamOpenEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(applicantExamOpenAction.types.request).map((action) =>
    ajaxAction.request({
      method: "put",
      body: action.payload || {},
      url: `/api/applicants/exams/${
        state.getState().applicant.initial.id
      }/open`,
      success: applicantExamOpenAction.success,
      error: applicantExamOpenAction.failure,
    }),
  );

const applicantExamOpenSetEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(applicantExamOpenAction.types.success).map((action) => {
    const orgName = state.getState().applicant.tutorial
      ? "tutorial"
      : state.getState().applicant.exam.org.name;

    return redirectAction(
      `/${orgName}/exams/${
        state.getState().applicant.exam.urlToken
      }/challenges`,
      alertAction.success(Msg.getMessageByKey("message.applicant.startExam")),
    )();
  });

const applicantUpdateFormEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(applicantUpdateFormAction.types.request).map((action) => {
    return ajaxAction.request({
      method: "put",
      url: `/api/applicants/exams/${
        state.getState().applicant.initial.id
      }/forms`,
      body: action.payload,
      success: alertAction.success(
        Msg.getMessageByKey("message.applicant.updateCustomForm"),
        applicantUpdateFormAction.success,
      ),
      error: applicantUpdateFormAction.failure,
    });
  });

const applicantExamSubmitEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(applicantExamSubmitAction.types.request).map(() => {
    return ajaxAction.request({
      method: "put",
      url: `/api/applicants/exams/${
        state.getState().applicant.initial.id
      }/submit`,
      success: alertAction.success(
        Msg.getMessageByKey("message.applicant.submitExam"),
        applicantExamSubmitAction.success,
      ),
      error: applicantExamSubmitAction.failure,
    });
  });

export const applicantSessionExtendEpic: Epic<Action, RootState> = (
  action$,
  state,
) =>
  action$.ofType(applicantSessionExtendAction.types.request).map(() => {
    return ajaxAction.request({
      method: "put",
      url: `/api/applicants/exams/${
        state.getState().applicant.initial.id
      }/extend`,
      success: applicantSessionExtendAction.success,
      error: applicantSessionExtendAction.failure,
    });
  });

const applicantPostExamSubmitEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(applicantExamSubmitAction.types.success).map((action) => {
    const orgName = state.getState().applicant.tutorial
      ? "tutorial"
      : state.getState().applicant.exam.org.name;
    return redirectAction(
      `/${orgName}/exams/${
        state.getState().applicant.exam.urlToken
      }/challenges`,
    )();
  });

const challengeStartEpic: Epic<Action, RootState> = (action$, state) =>
  action$
    .ofType(challengeStartAction.types.request)
    .map((action) => challengeStartStep1Action.request(action.payload || {}));

const challengeStartStep1Epic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(challengeStartStep1Action.types.request).map((action) => {
    const { challengeId, isPrepared } =
      state.getState().applicant.challengeStartContext;
    if (isPrepared) {
      return challengeStartStep2Action.request({});
    } else {
      return ajaxAction.request({
        method: "post",
        url: `/api/applicants/exams/${
          state.getState().applicant.initial.id
        }/results`,
        body: { challengeId },
        success: challengeStartStep2Action.request,
        error: challengeStartAction.failure,
      });
    }
  });

const challengeStartStep2Epic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(challengeStartStep2Action.types.request).map((action) => {
    const { hasLanguageSwitch, programmingLanguage, resultId } =
      state.getState().applicant.challengeStartContext;
    if (hasLanguageSwitch) {
      return ajaxAction.request({
        method: "put",
        url: `/api/applicants/exams/${
          state.getState().applicant.initial.id
        }/results/${resultId}/language`,
        body: { programmingLanguage },
        success: challengeStartStep3Action.request,
        error: challengeStartAction.failure,
      });
    } else {
      return challengeStartStep3Action.request({});
    }
  });

const challengeStartStep3Epic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(challengeStartStep3Action.types.request).map((action) =>
    ajaxAction.request({
      method: "put",
      url: `/api/applicants/exams/${
        state.getState().applicant.initial.id
      }/results/${
        state.getState().applicant.challengeStartContext.resultId
      }/start`,
      body: {
        takenBy: state.getState().applicant.challengeStartContext.takenBy,
      },
      success: alertAction.success(
        Msg.getMessageByKey("message.applicant.startChallenge"),
        challengeStartAction.success,
      ),
      error: challengeStartAction.failure,
    }),
  );

const challengeResetEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(challengeResetAction.types.request).map((action) =>
    ajaxAction.request({
      method: "put",
      url: `/api/applicants/exams/${
        state.getState().applicant.initial.id
      }/results/${action.params}/reset`,
      success: reloadAction,
      error: challengeResetAction.failure,
    }),
  );

const challengeSwitchLangEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(challengeSwitchLangAction.types.request).map((action) =>
    ajaxAction.request({
      method: "put",
      url: `/api/applicants/exams/${
        state.getState().applicant.initial.id
      }/results/${action.params}/language`,
      body: action.payload,
      success: alertAction.success(
        Msg.getMessageByKey("message.applicant.switchLanguage"),
        reloadAction,
      ),
      error: challengeSwitchLangAction.failure,
    }),
  );

const challengeUpdateVarsEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(challengeUpdateVarsAction.types.request).map((action) =>
    ajaxAction.request({
      method: "put",
      url: `/api/applicants/exams/${
        state.getState().applicant.initial.id
      }/results/${action.params}/variables`,
      body: action.payload,
      success: alertAction.success(
        Msg.getMessageByKey("message.applicant.updateEnvVars"),
        challengeUpdateVarsAction.success,
      ),
    }),
  );

const challengeContextEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(challengeContextAction.types.request).map((action) =>
    ajaxAction.request({
      method: "get",
      url: `/api/applicants/exams/${
        state.getState().applicant.initial.id
      }/results/${action.params}/context`,
      success: challengeContextAction.success,
    }),
  );

const challengeSwitchTakenByEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(challengeSwitchTakenByAction.types.request).map((action) => {
    const { takenBy } = action.payload as { takenBy: ChallengeTakenBy };
    return ajaxAction.request({
      method: "put",
      url: `/api/applicants/exams/${
        state.getState().applicant.initial.id
      }/results/${action.params}/takenby`,
      body: { takenBy },
      success: () => {
        sessionStorage.setItem(
          SHOW_COMPLETED_MODAL_KEY,
          `SwitchTakenBy:${takenBy}`,
        );
        return reloadAction();
      },
    });
  });

const presignedEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(presignedAction.types.request).map((action) =>
    ajaxAction.request({
      method: "post",
      url: `/api/applicants/exams/${
        state.getState().applicant.initial.id
      }/results/${action.params}/presigned`,
      body: action.payload,
      success: presignedAction.success,
    }),
  );

const getSourceFilesEpic: Epic<Action, RootState> = (action$, state) =>
  action$
    .ofType(presignedAction.types.success)
    .flatMap((action) => {
      const results = (action as APIResponseAction).payload.result || {};
      return Object.keys(results).map((key) => ({
        name: key,
        file: results[key],
      }));
    })
    .flatMap(({ name, file }) =>
      ajax({
        method: "get",
        responseType: "text",
        url: file,
      }).map(({ response }) =>
        getSourceFiles.success({ name, code: response }),
      ),
    );

const appealCommentUpdateEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(updateAppealComment.types.request).map((action) => {
    const { hideLoader = true, ...restPayload } = (action.payload ?? {}) as {
      hideLoader?: boolean;
      appealComment: string;
    };
    const body = {
      ...restPayload,
      includeInApplicantActionLog:
        state.getState().applicant.isIncludedAppealCommentLog,
    };

    return ajaxAction.request({
      method: "put",
      url: `/api/applicants/exams/${
        state.getState().applicant.initial.id
      }/results/${action.params}/appeal`,
      body,
      success: updateAppealComment.success,
      error: updateAppealComment.failure,
      options: {
        disableDefaultError: hideLoader,
        background: hideLoader,
      },
    });
  });

const applicantIdStartExamEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(applicantIdStartExamAction.types.request).map((action) =>
    ajaxAction.request({
      method: "post",
      url: `/api/idexam/${action.payload}/start`,
      body: action.params,
      success: applicantIdStartExamAction.success,
    }),
  );

const applicantIdPasswordEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(applicantIdPasswordAction.types.request).map((action) =>
    ajaxAction.request({
      method: "post",
      url: `/api/idexam/${action.payload}/password`,
      body: action.params,
      success: applicantIdPasswordAction.success,
    }),
  );

const applicantIdSigninEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(applicantIdSigninAction.types.request).map((action) =>
    ajaxAction.request({
      method: "post",
      url: `/api/idexam/${action.payload}/signin`,
      body: action.params,
      success: applicantIdSigninAction.success,
    }),
  );

export const applicantPasteEventEpic: Epic<Action, RootState> = (
  action$,
  state,
) =>
  action$.ofType(applicantPasteEventAction.types.request).map((action) => {
    const { resultId } = action.params as {
      resultId: number;
    };
    const millisBeforeDeadline = getMillisBeforeExamDeadline(
      state.getState().applicant.exam,
      state.getState().applicantTimer.exam,
    );

    return ajaxAction.request({
      method: "post",
      url: `/api/applicants/exams/${
        state.getState().applicant.initial.id
      }/results/${resultId}/cp`,
      body: { ...action.payload, millisBeforeDeadline, activityId: generate() },
      success: applicantPasteEventAction.success,
      error: applicantPasteEventAction.failure,
      options: {
        disableDefaultError: true,
        background: true,
        retryWhileOffline: {
          maxRetries: 100,
          // Exponential backoff with a max delay of 60 seconds
          delay: (retryCount) => Math.min(60, Math.pow(2, retryCount)) * 1000,
        },
      } as AjaxActionOptions,
    });
  });

export const applicantSwitchTabEventEpic: Epic<Action, RootState> = (
  action$,
  state,
) =>
  action$
    .ofType(applicantSwitchTabEventAction.types.request)
    .filter(() => {
      const {
        challengeContext: { challengeResultId: resultId },
        tabEventUUID,
      } = state.getState().applicant;
      return Boolean(resultId) && Boolean(tabEventUUID);
    })
    .map((action) => {
      const millisBeforeDeadline = getMillisBeforeExamDeadline(
        state.getState().applicant.exam,
        state.getState().applicantTimer.exam,
      );

      const {
        challengeContext: { challengeResultId: resultId },
        initial: { id: examId },
        tabEventUUID: activityId,
      } = state.getState().applicant;
      const body = {
        isReturning: action.payload,
        millisBeforeDeadline,
        activityId,
      };

      return ajaxAction.request({
        method: "post",
        url: `/api/applicants/exams/${examId}/results/${resultId}/tab`,
        body,
        success: applicantSwitchTabEventAction.success,
        error: applicantSwitchTabEventAction.failure,
        options: {
          disableDefaultError: true,
          background: true,
          retryWhileOffline: {
            maxRetries: 252, // expect to back online in 2.1 hours
            delay: 30 * 1000, // 30 secs
          } as AjaxActionOptions,
        },
      });
    });

export default [
  applicantInitialGetEpic,
  applicantInitialSetEpic,
  applicantSharingGetEpic,
  applicantSharingSetEpic,
  applicantInvitationEpic,
  applicantAuthEpic,
  applicantAuthSuccessEpic,
  applicantExamGetEpic,
  applicantExamGetSuccessEpic,
  applicantExamGetErrorEpic,
  applicantExamOpenEpic,
  applicantExamOpenSetEpic,
  applicantExamSubmitEpic,
  applicantSessionExtendEpic,
  applicantPostExamSubmitEpic,
  applicantUpdateFormEpic,
  challengeStartEpic,
  challengeStartStep1Epic,
  challengeStartStep2Epic,
  challengeStartStep3Epic,
  challengeResetEpic,
  challengeSwitchLangEpic,
  challengeUpdateVarsEpic,
  challengeContextEpic,
  challengeSwitchTakenByEpic,
  presignedEpic,
  getSourceFilesEpic,
  appealCommentUpdateEpic,
  applicantIdSharingGetEpic,
  applicantIdStartExamEpic,
  applicantIdPasswordEpic,
  applicantIdSigninEpic,
  applicantPasteEventEpic,
  applicantSwitchTabEventEpic,
];
