import { useQuery } from "@tanstack/react-query";

import { getExamSimpleList, getExamSimpleByProjectId } from "@api/exams";

import { useStoreContext } from "@context";

import { getLastWeekTimestamp } from "@shared/services/date";

interface ExamSimpleFilters {
  archived: string;
}

export const examSimpleKeys = {
  all: ["exam", "simple"] as const,
  list: (filters: ExamSimpleFilters) =>
    [...examSimpleKeys.all, { filters }] as const,
  projectList: (projectId: number) =>
    [...examSimpleKeys.all, projectId] as const,
};

export function useExamSimpleList(filters: ExamSimpleFilters) {
  const query = useQuery({
    queryKey: examSimpleKeys.list(filters),
    queryFn: async ({ signal }) => {
      const { result } = await getExamSimpleList({
        params: { ...filters },
        signal,
      });

      return result;
    },
    keepPreviousData: true,
    initialData: [],
    initialDataUpdatedAt: getLastWeekTimestamp(),
  });

  return query;
}

export function useExamSimpleByProjectId(projectId?: number) {
  const { projectId: storeProjectId } = useStoreContext();
  const localProjectId = projectId || storeProjectId;

  const query = useQuery({
    queryKey: examSimpleKeys.projectList(localProjectId),
    queryFn: async ({ signal }) => {
      const { result } = await getExamSimpleByProjectId(localProjectId, {
        signal,
      });

      return result;
    },
    keepPreviousData: true,
    initialData: [],
    initialDataUpdatedAt: getLastWeekTimestamp(),
  });

  return query;
}
