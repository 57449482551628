import {
  APIResponseAction,
  Action,
  examDetailsGetAction,
  examListGetAction,
  examOptionsGetAction,
  submissionListDrawerAction,
  currentProjectSetAction,
  postExamDetailsGetAction,
} from "@actions";

import {
  EnumModel,
  ExamModel,
  ExamListModel,
  PaginationModel,
  ExamChallengeModel,
  ExamChallengeSetModel,
  ExamCreateOptionsModel,
  MemberListModel,
  SubmissionListModel,
  UrlSharingConfigModel,
  OfficialExamListModel,
  SubmissionListFilterModel,
} from "@shared/models";

export interface ExamState {
  error: boolean;
  examChallenges: Array<ExamChallengeModel>;
  examChallengesSets: Array<ExamChallengeSetModel>;
  examDetails: ExamModel;
  examList: Array<ExamListModel>;
  examListCondition: {};
  examListPagination: PaginationModel;
  examReviewers: Array<MemberListModel>;
  examSettings: UrlSharingConfigModel;
  loadingExam: boolean;
  loadingExamList: boolean;
  loadingSubmissionList: boolean;
  officialExamList: OfficialExamListModel[];
  officialExamListPagination: PaginationModel;
  loadingOfficialExamList?: boolean;
  officialExamDetail?: ExamModel;
  loadingOfficialExam: boolean;
  drawerSubmission: SubmissionListModel | undefined;
  submissionList: SubmissionListModel[];
  submissionCondition?: SubmissionListFilterModel;
  submissionPagination: PaginationModel;
  examCreateOptions: ExamCreateOptionsModel;
  submissionWithResultsCondition?: SubmissionListFilterModel;
  loadingSubmissionWithResultsList: boolean;
  submissionWithResultsList: SubmissionListModel[];
  submissionWithResultsPagination: PaginationModel;
}

export const initialState = {
  error: false,
  /**
   * @deprecated
   */
  examChallenges: [],
  examChallengesSets: [],
  examDeliverIdConfirmDelivered: [],
  examDeliverIdConfirmLoading: false,
  examDeliverIdConfirmNotDelivered: [],
  examDetails: new ExamModel(),
  examForms: [],
  examList: [],
  examListPagination: new PaginationModel(),
  examListCondition: {},
  examReviewers: [],
  examSettings: new UrlSharingConfigModel(),
  loadingExam: false,
  loadingExamList: false,
  loadingOfficialExam: false,
  loadingSubmissionList: false,
  officialExamList: [],
  officialExamListPagination: new PaginationModel(),
  drawerSubmission: undefined,
  submissionList: [],
  submissionPagination: new PaginationModel(),
  examCreateOptions: {
    roleoptions: [],
  },
  examDeliveryError: false,
  loadingExamDeliveryDetail: false,
  loadingExamDeliveryList: false,
  loadingExamDeliverySubmitting: false,
  examDeliveryList: [],
  examScheduledDeliveryList: [],
  examDeliveryCondition: {},
  examScheduledDeliveryCondition: {},
  examDeliveryDetailList: [],
  examDeliveryPagination: new PaginationModel(),
  examScheduledDeliveryPagination: new PaginationModel(),
  loadingSubmissionWithResultsList: false,
  submissionWithResultsList: [],
  submissionWithResultsPagination: new PaginationModel(),
};

export const examReducer = (
  state: ExamState = initialState,
  action: Action,
): ExamState => {
  const payload = (action as APIResponseAction).payload;

  switch (action.type) {
    case examListGetAction.types.request:
      return {
        ...state,
        examListCondition: action.params as {},
        error: false,
        loadingExamList: true,
      };
    case examListGetAction.types.success:
      return {
        ...state,
        examList: (payload.result as Array<{}>).map(
          (exam) => new ExamListModel(exam),
        ),
        examListPagination: new PaginationModel(payload.pagination),
        loadingExamList: false,
      };

    case examListGetAction.types.failure:
      return {
        ...state,
        error: true,
        loadingSubmissionList: false,
        loadingExamList: false,
      };

    case examDetailsGetAction.types.request:
      return {
        ...state,
        examDetails: new ExamModel(),
        examChallenges: [],
        examChallengesSets: [],
        examReviewers: [],
        loadingExam: true,
        examSettings: new UrlSharingConfigModel(),
        error: false,
      };

    // case officialExamCopyAction.types.success:
    case examDetailsGetAction.types.success: {
      const examResult = new ExamModel(payload.result);
      const counts = Object.assign(
        { sentDeliveryCount: 0, archivedCount: 0 },
        examResult.counts,
      );

      return {
        ...state,
        examDetails: new ExamModel(Object.assign({}, examResult, { counts })),
        examChallenges: examResult.getChallenges(),
        examChallengesSets: examResult.challengesSets.sort(
          (a: ExamChallengeSetModel, b: ExamChallengeSetModel) =>
            a.displayOrder - b.displayOrder,
        ),
        examReviewers: examResult.reviewers.map(
          (user) => new MemberListModel(user),
        ),
        examSettings: new UrlSharingConfigModel(examResult.urlSharingConfig),
        loadingExam: false,
      };
    }

    case submissionListDrawerAction.types.request: {
      return {
        ...state,
        drawerSubmission: payload?.result as SubmissionListModel | undefined,
      };
    }

    case examOptionsGetAction.types.success:
      return {
        ...state,
        examCreateOptions: {
          ...state.examCreateOptions,
          [payload.extra as string]: (
            (payload.result as Array<EnumModel>) || []
          )
            .sort((a, b) => a.displayOrder - b.displayOrder)
            .map(({ value, displayString }) => ({
              value,
              label: displayString,
            })),
        },
      };

    // clean up store data right after project switch
    case currentProjectSetAction.types.request: {
      const { examCreateOptions } = state;
      return { ...initialState, examCreateOptions };
    }

    case postExamDetailsGetAction.types.request: {
      return {
        ...state,
        examDetails: new ExamModel({ id: payload }),
      };
    }

    default:
      return state;
  }
};
