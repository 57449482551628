import * as React from "react";

import { useRouteParams } from "@shared/hooks/query/useRouteParams";
import { QuestionModel } from "@shared/models";

/**
 * This is a workaround for sequential requests to avoid connection DB pool
 * Ideally this should be removed when we migrate Questions global state to react query
 */
export function useQuestionInsights({
  questionIds,
  questionDetailList,
  shouldShowExamLevelInsights,
  getExamLevelInsights,
}: {
  questionIds: number[];
  questionDetailList: QuestionModel[];
  shouldShowExamLevelInsights: boolean;
  getExamLevelInsights: (examId: number, questionId: number) => void;
}) {
  const { examId } = useRouteParams();
  const [isFetching, setIsFetching] = React.useState(false);
  const [currentlyFetchingIdx, setCurrentlyFetchingIdx] =
    React.useState<number>(0);

  const currentlyFetchingQuestionId = questionIds[currentlyFetchingIdx];

  const currentQuestion = questionDetailList.find(
    (item) => item.id === currentlyFetchingQuestionId,
  );

  React.useEffect(() => {
    if (
      shouldShowExamLevelInsights &&
      currentlyFetchingQuestionId !== undefined &&
      !currentQuestion?.examLevelInsight && // only fetch if we don't have the insight already
      !isFetching
    ) {
      getExamLevelInsights(examId, currentlyFetchingQuestionId);
      setIsFetching(true);
    }
  }, [
    currentQuestion?.examLevelInsight,
    currentlyFetchingIdx,
    currentlyFetchingQuestionId,
    examId,
    getExamLevelInsights,
    isFetching,
    questionIds,
    shouldShowExamLevelInsights,
  ]);

  React.useEffect(() => {
    if (
      Boolean(currentQuestion?.examLevelInsight) ||
      currentQuestion?.errorLoadingInsight
    ) {
      setIsFetching(false);
      setCurrentlyFetchingIdx(currentlyFetchingIdx + 1);
    }
  }, [
    currentQuestion?.errorLoadingInsight,
    currentQuestion?.examLevelInsight,
    currentlyFetchingIdx,
    currentlyFetchingQuestionId,
    questionDetailList,
  ]);
}
