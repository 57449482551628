import * as classnames from "classnames";

/**
 * Prop interface
 */
export interface SubMenuProps {
  className?: string;
  children?: {};
}

/**
 * React Component
 */
export default function SubMenu({ className, children }: SubMenuProps) {
  const rootStyle = classnames("code-c-sub-menu", "menu", {
    [`${className}`]: Boolean(className),
  });

  return <aside className={rootStyle}>{children}</aside>;
}
