/**
 * Events
 */

export interface DataLayerValues {
  [key: string]: string;
}

export function pushEvent(eventName: string, options: {} = {}) {
  if (window.dataLayer) {
    window.dataLayer.push({ event: eventName, ...options });
  }
}

export function pushVariables(values: DataLayerValues = {} as DataLayerValues) {
  if (window.dataLayer) {
    window.dataLayer.push(values);
  }
}
