import { useStoreContext } from "@context";

import { useExam, useSubmission } from "@shared/hooks/query";
import { SubmissionAccessType } from "@shared/models";

export function useSubmissionAccessType() {
  const { projectId, user } = useStoreContext();
  const { data: submission } = useSubmission();
  const {
    data: { examDetail },
  } = useExam();

  const isExamDeliverer =
    user?.getAccessSubmissionType(
      projectId,
      submission.status,
      examDetail.reviewers,
    ) === SubmissionAccessType.ExamDeliverer;

  return { isExamDeliverer };
}
