import { AxiosRequestConfig } from "axios";

import {
  ApiResponse,
  Pagination,
  _delete,
  get,
  post,
  put,
} from "@api/httpClient";

import {
  AutoFilterSettingsModel,
  ExamListModel,
  ExamModel,
} from "@shared/models";

interface ExamListParams<T = any> {
  projectId: number;
  options?: AxiosRequestConfig<T>;
}

interface ExamDetailParams<T = any> extends ExamListParams<T> {
  examId: number;
}

export type ExamDetailPayload = Partial<
  Pick<
    ExamModel,
    | "guestSharingEnabled"
    | "guestSharingSettings"
    | "webcamSettings"
    | "urlDeliveryEnabled"
    | "language"
    | "atsIntegrationEnabled"
    | "atsDynamicDeadlineDays"
    | "deliveryKind"
    | "nextStepInfo"
    | "applicantActionSettings"
    | "memo"
    | "isOfficial"
    | "coverImageUrl"
    | "shareReviewsWithReviewers"
    | "reviewSettings"
  >
> & {
  autoFilterSettings?: AutoFilterSettingsModel;
  deadlineNotificationEnabled?: boolean;
  showSecretTestcases?: boolean;
};

export interface ExamCounts {
  counts: ExamModel["counts"];
  id: number;
  organizationId: number;
  projectId: number;
}

export async function getExamList({ projectId, options }: ExamListParams) {
  const { data } = await get<ExamListModel[], Pagination<ExamListModel[]>>(
    `/api/projects/${projectId}/exams`,
    options,
  );

  return data;
}

export async function getExamDetail({
  examId,
  projectId,
  options,
}: ExamDetailParams) {
  const { data } = await get<ExamModel>(
    `/api/projects/${projectId}/exams/${examId}`,
    options,
  );

  return data;
}

export async function getExamCount({
  examId,
  projectId,
  options,
}: ExamDetailParams) {
  const { data } = await get<ExamCounts>(
    `/api/projects/${projectId}/exams/${examId}/counts`,
    options,
  );

  return data;
}

export async function postExam({ projectId, options }: ExamListParams) {
  const { data } = await post<ApiResponse<ExamModel>>(
    `/api/projects/${projectId}/exams`,
    options,
  );

  return data;
}

export async function putExam({
  examId,
  projectId,
  options,
}: ExamDetailParams<ExamDetailPayload>) {
  const { data } = await put<ApiResponse>(
    `/api/projects/${projectId}/exams/${examId}`,
    options,
  );

  return data;
}

export async function deleteExam({
  examId,
  projectId,
  options,
}: ExamDetailParams) {
  const { data } = await _delete(
    `/api/projects/${projectId}/exams/${examId}`,
    options,
  );

  return data;
}
