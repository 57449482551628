import { QueryFunctionContext, useQuery } from "@tanstack/react-query";

import { ChallengeResultSettings } from "@components/preview/settings.model";

import { getSubmissionChallengeResultPreview } from "@api/submissions";

import { useStoreContext } from "@context";

import { useRouteParams } from "../useRouteParams";

interface SubmissionChallengeResultPreviewAllKeyParams {
  projectId: number;
  submissionId?: number;
}
interface SubmissionChallengeResultPreviewKeyParams
  extends Required<SubmissionChallengeResultPreviewAllKeyParams> {
  challengeId: number;
  version?: number;
}

interface FetchSubmissionChallengeResultPreviewParams {
  challengeId: number;
  examId: number;
  projectId: number;
  submissionId: number;
  version?: number;
}

export const submissionChallengeResultPreviewKeys = {
  all: ["submissions", "challengeResultPreviews"] as const,
  previewAll: ({
    projectId,
    submissionId,
  }: SubmissionChallengeResultPreviewAllKeyParams) => {
    if (submissionId) {
      return [
        ...submissionChallengeResultPreviewKeys.all,
        projectId,
        submissionId,
      ] as const;
    }

    return [...submissionChallengeResultPreviewKeys.all, projectId] as const;
  },
  preview: ({
    challengeId,
    version,
    ...rest
  }: SubmissionChallengeResultPreviewKeyParams) => [
    ...submissionChallengeResultPreviewKeys.previewAll(rest),
    challengeId,
    version,
  ],
};

function fetchSubmissionChallengeResultPreview({
  challengeId,
  examId,
  projectId,
  submissionId,
  version,
}: FetchSubmissionChallengeResultPreviewParams) {
  return async ({ signal }: QueryFunctionContext) => {
    const { result } = await getSubmissionChallengeResultPreview({
      challengeId,
      examId,
      projectId,
      submissionId,
      options: { signal, params: version ? { version } : undefined },
    });

    return result;
  };
}

export function useGetSubmissionsChallengeResultPreview(
  challengeId: number,
  version?: number,
) {
  const { projectId } = useStoreContext();
  const { examId, submissionId } = useRouteParams();
  const enabled = Boolean(challengeId && examId && submissionId);
  const query = useQuery<ChallengeResultSettings, string>({
    queryKey: submissionChallengeResultPreviewKeys.preview({
      projectId,
      submissionId,
      challengeId,
      version,
    }),
    // To remove examId as query key dependency, we need to extract queryFn to a separate function
    queryFn: fetchSubmissionChallengeResultPreview({
      challengeId,
      examId,
      projectId,
      submissionId,
      version,
    }),
    enabled,
    keepPreviousData: true,
  });

  return query;
}
