import { Encoding } from "@shared/services/enums";

import { SetValue, useLocalStorage } from "./useLocalStorage";

export type CsvDownloadForm = {
  type: string;
  encoding: Encoding;
  language: string;
  allChallenges: boolean;
  challenges: boolean[];
  columnSelectionMode: "all" | "selected";
  columnGroups: Record<string, boolean>; // maps group key to boolean representing selected group of columns
  columns: (boolean | undefined)[]; // maps group key to array of boolean representing selected columns
  saveSettings: boolean;
  challengeIds: Record<string, boolean>;
  includeEntryForm: boolean;
};

export interface CacheCsvDownloadForm extends CsvDownloadForm {
  lastUpdatedAt: number;
}

type CacheCsvDownloadSettings = Record<string, CacheCsvDownloadForm>;

const MAX_CACHE_SIZE = 1000;

export function useSubmissionDownloadSetting(
  examId: string | number,
): [CacheCsvDownloadForm, SetValue<CsvDownloadForm>, () => void] {
  const [cacheSettings, setCacheSettings] =
    useLocalStorage<CacheCsvDownloadSettings>("csv-download-settings", {});
  const cacheSetting = cacheSettings[examId];

  const updateCache = (filteredKeys: string[]) => {
    const newCache = filteredKeys.reduce((acc, key) => {
      acc[key] = cacheSettings[key];
      return acc;
    }, {} as CacheCsvDownloadSettings);
    setCacheSettings(newCache);
  };

  const setValue = (value: CsvDownloadForm) => {
    cacheSettings[examId] = Object.assign(value, {
      lastUpdatedAt: Date.now(),
    });

    const sortKeys = Object.keys(cacheSettings).sort(
      (a, b) => cacheSettings[a].lastUpdatedAt - cacheSettings[b].lastUpdatedAt,
    );
    const filteredKeys = sortKeys.slice(-MAX_CACHE_SIZE);
    updateCache(filteredKeys);
  };

  const removeValue = () => {
    const filteredKeys = Object.keys(cacheSettings).filter(
      (key) => key !== `${examId}`,
    );
    updateCache(filteredKeys);
  };

  return [cacheSetting, setValue, removeValue];
}
