import classnames from "classnames";

import { FileCard, Modal, Msg } from "@shared/components";
import { LocalFileMetaData } from "@shared/models";
import { FileScanningStatus } from "@shared/services/enums";
import { formatBytes } from "@shared/services/string";

import Message from "../../../../shared/services/message";

const getFileCardStatusMessage = (
  scanningStatus: FileScanningStatus,
  fileScanStatusesString: Record<FileScanningStatus, string>,
) => {
  if (scanningStatus === FileScanningStatus.Clean) {
    return undefined;
  } else {
    return fileScanStatusesString[scanningStatus];
  }
};

const shouldDisableDownload = (status: FileScanningStatus) => {
  return [
    FileScanningStatus.ScanningInProgress,
    FileScanningStatus.ScanningFailed,
    FileScanningStatus.Unknown,
  ].includes(status);
};

const shouldShowError = (status: FileScanningStatus) => {
  return status === FileScanningStatus.Malicious;
};

const getActionTooltip = (
  fileMetadata: LocalFileMetaData,
  skippedReasons: Record<string, string>,
) => {
  if (
    fileMetadata.scanningStatus === FileScanningStatus.ScanningSkipped &&
    fileMetadata.skippedScanningReason
  ) {
    return skippedReasons[fileMetadata.skippedScanningReason];
  } else if (
    fileMetadata.scanningStatus === FileScanningStatus.ScanningInProgress
  ) {
    return Message.getMessageByKey(
      "submission.localCodeFiles.tooltip.scanningInProgress",
    );
  } else if (fileMetadata.scanningStatus === FileScanningStatus.Malicious) {
    return Message.getMessageByKey(
      "submission.localCodeFiles.tooltip.anomalyDetected",
    );
  } else if (
    [FileScanningStatus.ScanningFailed, FileScanningStatus.Unknown].includes(
      fileMetadata.scanningStatus,
    )
  ) {
    return Message.getMessageByKey(
      "submission.localCodeFiles.tooltip.unexpectedIssue",
    );
  } else {
    return undefined;
  }
};

export function LocalCodeFiles({
  files,
  onClickDownload,
  isConfirmModalOpen,
  onCloseConfirmModal,
  onConfirmModal,
  fileScanStatusesString,
  skippedFileScanReasons,
  layout = "grid",
}: {
  files: LocalFileMetaData[];
  onClickDownload: (file: LocalFileMetaData) => void;
  isConfirmModalOpen: boolean;
  onCloseConfirmModal: () => void;
  onConfirmModal: () => void;
  fileScanStatusesString: Record<FileScanningStatus, string>;
  skippedFileScanReasons: Record<string, string>;
  layout?: "grid" | "row" | "column";
}) {
  const rootStyles = classnames("code-c-local-code-files", {
    "is-grid": layout === "grid",
    "is-row": layout === "row",
    "is-column": layout === "column",
  });

  return (
    <>
      {files.length > 0 && (
        <div className={rootStyles}>
          {files.map((file) => (
            <FileCard
              key={file.filename}
              fileName={file.filename}
              fileSize={formatBytes(file.sizeInBytes)}
              onClick={() => onClickDownload(file)}
              statusMessage={getFileCardStatusMessage(
                file.scanningStatus,
                fileScanStatusesString,
              )}
              disabled={shouldDisableDownload(file.scanningStatus)}
              isError={shouldShowError(file.scanningStatus)}
              actionTooltip={getActionTooltip(file, skippedFileScanReasons)}
            />
          ))}
          <Modal
            title={
              <Msg id="submission.localCodeFiles.downloadConfirmationModal.title" />
            }
            isOpen={isConfirmModalOpen}
            onClose={onCloseConfirmModal}
            onClickCancel={onCloseConfirmModal}
            okButtonLabel={<Msg id="download" />}
            okButtonType="danger"
            onClickOk={onConfirmModal}
          >
            <Msg id="submission.localCodeFiles.downloadConfirmationModal.message" />
          </Modal>
        </div>
      )}
    </>
  );
}
