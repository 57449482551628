import * as classnames from "classnames";
import * as React from "react";

/**
 * Prop interface
 */
export interface SubMenuListProps {
  className?: string;
  children?: React.ReactNode;
  ariaLabel?: string;
}

/**
 * React Component
 */
export default function SubMenuList({
  className,
  ariaLabel,
  children,
}: SubMenuListProps) {
  const rootStyle = classnames("code-c-sub-menu-list", "menu-list", {
    [`${className}`]: Boolean(className),
  });

  return (
    <ul className={rootStyle} role="navigation" aria-label={ariaLabel}>
      {children}
    </ul>
  );
}
