import * as classnames from "classnames";
import * as React from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { RouteComponentProps } from "react-router-dom";

import {
  formatApplicantCSV,
  formatDeliveryEmailTemplate,
} from "@components/orgs/exams/examSections/examSectionUtil/ExamSectionUtil";

import {
  Button,
  FormGroup,
  Label,
  Textarea,
  Input,
  DatePicker,
  DatePickerType,
  Block,
  LeftBlock,
  Form,
  Loading,
  DocumentTitle,
  Restricted,
  Msg,
  Checkbox,
  Radio,
  LanguageTag,
  Tooltip,
} from "@shared/components";
import { useTierActionPermissions } from "@shared/hooks";
import {
  useDeliverMail,
  useExamDeliveryTemplate,
  useScheduleDeliverMail,
} from "@shared/hooks/query";
import { useExam } from "@shared/hooks/query/exams/useExam";
import { UserModel } from "@shared/models";
import { dayjs, isValidDate } from "@shared/services/date";
import { ProjectRole, ExamType } from "@shared/services/enums";
import Message from "@shared/services/message";

import ConfirmationModal from "../confirmationModal/ConfirmationModal";

/**
 * Prop interface
 */

export type InjectedProps = {
  currentUser: UserModel;
};

export type DeliverEmailProps = InjectedProps &
  RouteComponentProps<{ examId: string }>;

/**
 * State interface
 */
export interface DeliverEmailState {
  formValid?: boolean;
  formErrors: {};
  isOpenConfirm: boolean;
  dateNow: string;
}
interface Form {
  language?: string;
  deadline: string;
  scheduledFor: string;
  applicantCSV: string;
  mailSubject: string;
  mailBody: string;
  senderName: string;
  isScheduleDelivery: boolean;
}

export default function DeliverEmail({
  currentUser,
  match,
}: DeliverEmailProps) {
  const rootStyle = classnames("code-deliver-email");
  const examId = parseInt(match.params.examId, 10);
  const {
    data: {
      template,
      enTemplate = "",
      subject: previousSubject,
      body: previousBody,
      senderName: previousSender,
    },
  } = useExamDeliveryTemplate();
  const deliverMail = useDeliverMail();
  const scheduleDeliverMail = useScheduleDeliverMail();
  const {
    data: {
      examDetail: { examType, urlToken: examUrlToken },
    },
  } = useExam();
  const emailTemplate = {
    ja: template,
    en: enTemplate,
  };
  const [formValues, setFormValues] = React.useState<Form>({
    deadline: "",
    scheduledFor: "",
    applicantCSV: "",
    mailSubject: "",
    mailBody: "",
    senderName: "",
    isScheduleDelivery: false,
  });
  const [formErrors, setFormErrors] = React.useState({});
  const [isOpenConfirm, setIsOpenConfirm] = React.useState(false);
  const [formValid, setFormValid] = React.useState<boolean>();
  const dateNow = React.useRef(dayjs().format());

  const { isScheduleDeliveryAllowed } = useTierActionPermissions();

  const multiLanguage = examType === ExamType.EnglishJapanese;

  const preview = formatDeliveryEmailTemplate(
    formValues.language === "en" ? emailTemplate.en : emailTemplate.ja,
    {
      body: formValues.mailBody,
      deadline: formValues.deadline,
      token: examUrlToken,
    },
  );
  const countApplicant = Boolean(formValues.applicantCSV)
    ? formatApplicantCSV(formValues.applicantCSV).split("\n").length
    : 0;

  const onFormChange = (
    formValid: boolean,
    formValues: {
      applicantCSV: string;
      mailBody: string;
      deadline: string;
      isScheduleDelivery: boolean;
      scheduledFor: string;
    },
    formErrors: {},
  ) => {
    const { isScheduleDelivery, scheduledFor, ...rest } = formValues;
    setFormValid(formValid);
    setFormErrors(formErrors);
    setFormValues((prevState) => ({
      ...prevState,
      ...rest,
      isScheduleDelivery,
      scheduledFor: isScheduleDelivery ? scheduledFor : "",
    }));
  };
  const onCancel = () => {
    setIsOpenConfirm(false);
  };

  const onConfirm = (
    formValues: Pick<Form, "applicantCSV" | "deadline" | "mailBody">,
  ) => {
    setFormValues((prevState) => ({
      ...prevState,
      applicantCSV: formatApplicantCSV(formValues.applicantCSV),
    }));
    setIsOpenConfirm(true);
  };
  const onDelivery = () => {
    const {
      deadline = "",
      applicantCSV = "",
      mailSubject = "",
      mailBody = "",
      senderName,
      scheduledFor = "",
      language,
      isScheduleDelivery,
    } = formValues;
    const body = {
      deadline,
      applicants: applicantCSV
        .trim()
        .split("\n")
        .map((email) => ({ email })),
      mailSubject,
      mailBody,
      senderName,
      ...(examType === ExamType.EnglishJapanese && { language }),
    };

    isScheduleDelivery
      ? scheduleDeliverMail.mutate({
          examId,
          data: {
            ...body,
            scheduledFor,
            startAt: scheduledFor,
          },
        })
      : deliverMail.mutate({ examId, data: body });
  };

  return (
    <div className={rootStyle}>
      <Loading isOpen={deliverMail.isLoading} />
      <DocumentTitle page={Message.getMessageByKey("exam.emailDelivery")} />
      <BreadcrumbsItem
        to={`/p/projectName/exams/${examId}/deliveries/new/email`}
      >
        <Msg id="exam.emailDelivery" />
      </BreadcrumbsItem>
      <Form
        validation={{
          ...{
            deadline: [
              ...[
                "date",
                "iso",
                "required",
                "notAllowPast",
                ["within10YearsFrom", dateNow.current],
              ],
              ...(formValues.isScheduleDelivery &&
              isValidDate(formValues.scheduledFor)
                ? [["min", formValues.scheduledFor]]
                : []),
            ],
            senderName: ["string", ["max", 255]],
            mailSubject: ["string", "required", ["max", 255]],
            mailBody: ["string", "required"],
            applicantCSV: ["string", "required", ["emailArray"]],
            isScheduleDelivery: ["boolean"],
          },
          ...(formValues.isScheduleDelivery && {
            scheduledFor: [
              ...[
                "date",
                "iso",
                "required",
                "notAllowPast",
                ["within10YearsFrom", dateNow.current],
                ...(isValidDate(formValues.deadline)
                  ? [["max", formValues.deadline]]
                  : []),
              ],
            ],
          }),
          ...(multiLanguage && { language: ["string", "required"] }),
        }}
        initialValues={{
          mailSubject: previousSubject,
          mailBody: previousBody,
          senderName: previousSender || currentUser.organization.displayName,
        }}
        onFormChange={onFormChange}
        onSubmit={onConfirm}
        updateValues={{ scheduledFor: formValues.scheduledFor }}
        showError={{ scheduledFor: true }}
        error={formErrors}
      >
        {multiLanguage && (
          <FormGroup>
            <Label htmlFor="language">
              <Msg id="exam.examlanguage" />
            </Label>
            <div className="code-deliver-email__language">
              <div>
                <Radio name="language" defaultValue="ja">
                  <LanguageTag language="ja" color="pink" />
                </Radio>
              </div>
              <div>
                <Radio name="language" defaultValue="en">
                  <LanguageTag language="en" color="pink" />
                </Radio>
              </div>
            </div>
          </FormGroup>
        )}
        <FormGroup>
          <Label htmlFor="isScheduleDelivery">
            <Msg id="exam.delivery.scheduledDelivery" />
          </Label>
          <Tooltip
            placement="top-start"
            text={<Msg id="tier.disabled" />}
            disabled={isScheduleDeliveryAllowed}
          >
            <Checkbox
              name="isScheduleDelivery"
              ariaLabel="Scheduled Delivery"
              className="code-deliver-email__schedule-check"
              readOnly={!isScheduleDeliveryAllowed}
            >
              <Msg id="exam.delivery.scheduledDelivery.enable" />
            </Checkbox>
          </Tooltip>

          {formValues.isScheduleDelivery && (
            <div className="code-deliver-email__schedule-box">
              <Label htmlFor="scheduledFor">
                <Msg id="exam.delivery.scheduledDate" />
              </Label>
              <DatePicker
                id="scheduledFor"
                name="scheduledFor"
                type={DatePickerType.DateTime}
                maxDate={formValues.deadline}
                timeIntervals={60}
                readOnly={true}
              />
            </div>
          )}
        </FormGroup>
        <FormGroup>
          <Label htmlFor="deadline">
            <Msg id="delivery.deadline" />
          </Label>
          <DatePicker
            id="deadline"
            name="deadline"
            minDate={formValues.scheduledFor}
            type={DatePickerType.DateTime}
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="applicantCSV">
            <Msg id="common.applicant" />
          </Label>
          <div className="code-deliver-email__applicant-type-wrapper">
            <Textarea
              id="applicantCSV"
              name="applicantCSV"
              placeholder={Message.getMessageByKey("placeholder.enterEmail")}
              counter={
                <p className="code-deliver-email__applicant-counter">
                  {`${countApplicant}/100`}
                </p>
              }
            />
          </div>
        </FormGroup>
        <FormGroup>
          <Label htmlFor="senderName">
            <Msg id="delivery.from" />
          </Label>
          <Input id="senderName" name="senderName" />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="mailSubject">
            <Msg id="delivery.subject" />
          </Label>
          <Input id="mailSubject" name="mailSubject" />
        </FormGroup>
        <FormGroup fullWidth={true}>
          <Label htmlFor="mailBody">
            <Msg id="delivery.body" />
          </Label>
          <Block className="code-deliver-email__preview">
            <LeftBlock className="code-deliver-email__preview__input">
              <Textarea id="mailBody" name="mailBody" />
            </LeftBlock>
            <div className="code-deliver-email__preview__window">
              <LeftBlock className="code-deliver-email__preview__window__block">
                {preview}
              </LeftBlock>
              <p className="code-deliver-email__preview__window__text">
                <Msg id="message.applicant.urlAnnotation" />
              </p>
            </div>
          </Block>
        </FormGroup>
        <Restricted
          roles={[ProjectRole.ExamDeliverer, ProjectRole.ProjectAdmin]}
          wrapperTagName={false}
        >
          <FormGroup centered={true}>
            <Button type="primary" ariaLabel="Confirm">
              <Msg id="action.confirm" />
            </Button>
          </FormGroup>
        </Restricted>
        {isOpenConfirm && (
          <ConfirmationModal
            applicantCSV={formValues.applicantCSV}
            deadline={formValues.deadline}
            isOpen={isOpenConfirm}
            isScheduleDelivery={formValues.isScheduleDelivery}
            isSubmitting={deliverMail.isLoading}
            disableOk={deliverMail.isLoading || !formValid}
            language={formValues.language}
            mailSubject={formValues.mailSubject}
            multiLanguage={multiLanguage}
            preview={preview}
            scheduledFor={formValues.scheduledFor}
            senderName={formValues.senderName}
            onClose={onCancel}
            onClickCancel={onCancel}
            onClickOk={onDelivery}
          />
        )}
      </Form>
    </div>
  );
}
