import * as React from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { connect } from "react-redux";
import { Switch, Redirect } from "react-router-dom";
import { Action, Dispatch } from "redux";

import {
  orgsGetAction,
  orgsUpdateAction,
  imageUploadAction,
  orgsUpdateSecurityAction,
  orgsGetIPAction,
} from "@actions";

import { RootState } from "@reducers";

import { RestrictedRoute, Msg } from "@shared/components";
import {
  UserModel,
  OrganizationModel,
  UploadImagesModel,
} from "@shared/models";
import { TierAction, UserRole } from "@shared/services/enums";
import { getTierAllowedByRedux } from "@shared/services/tier";

import {
  SettingProfile,
  SettingConfigure,
  OrgSettingMemberPending,
  SettingContract,
} from "./orgSettingsPartials";
import AuditLog from "./sharedPartials/auditLog/AuditLog.connect";

export interface OrgSettingsRoutesProps {
  currentIP: string;
  currentUser: UserModel;
  imageUploading: boolean;
  organization: OrganizationModel;
  uploadedImages: UploadImagesModel;

  getIP: () => void;
  getOrgData: () => void;
  updateSecurity: (payload: {}) => void;
  updateOrgInfo: (payload: {}) => void;
  isTierActionAllowed: (tierAction: TierAction) => boolean;
  uploadImage: (
    name: string,
    file: File,
    width: number,
    height: number,
    tags: string,
    transformation?: string,
  ) => void;
}

class OrgSettingsRoutes extends React.Component<OrgSettingsRoutesProps, {}> {
  public componentDidMount() {
    if (
      this.props.currentUser.hasRole([UserRole.SystemAdmin, UserRole.OrgAdmin])
    ) {
      this.props.getOrgData();
    }
  }

  public render() {
    const {
      organization,
      imageUploading,
      uploadedImages,
      updateOrgInfo,
      uploadImage,
      updateSecurity,
      getIP,
      isTierActionAllowed,
      currentIP,
    } = this.props;

    return (
      <>
        <BreadcrumbsItem to="/settings/org">
          <Msg id={"orgSettings"} />
        </BreadcrumbsItem>
        <Switch>
          <RestrictedRoute
            roles={[UserRole.SystemAdmin, UserRole.OrgAdmin]}
            path="/settings/org/profile"
            exact={true}
          >
            <SettingProfile
              isAdmin={true}
              organization={organization}
              imageUploading={imageUploading}
              uploadedImages={uploadedImages}
              updateOrgInfo={updateOrgInfo}
              uploadImage={uploadImage}
            />
          </RestrictedRoute>
          <RestrictedRoute
            roles={[UserRole.SystemAdmin, UserRole.OrgAdmin]}
            path="/settings/org/settings"
            exact={true}
          >
            <SettingConfigure
              organization={organization}
              updateSecurity={updateSecurity}
              getIP={getIP}
              currentIP={currentIP}
              isTierActionAllowed={isTierActionAllowed}
            />
          </RestrictedRoute>
          <RestrictedRoute
            roles={[UserRole.SystemAdmin, UserRole.OrgAdmin]}
            path="/settings/org/members/pending"
            exact={true}
          >
            <OrgSettingMemberPending />
          </RestrictedRoute>
          <RestrictedRoute
            roles={[UserRole.SystemAdmin, UserRole.OrgAdmin]}
            path="/settings/org/contract"
            exact={true}
          >
            <SettingContract />
          </RestrictedRoute>
          <RestrictedRoute
            roles={[UserRole.SystemAdmin, UserRole.OrgAdmin]}
            path="/settings/org/auditlog"
            exact={true}
          >
            <AuditLog />
          </RestrictedRoute>
          <Redirect from="/settings/org" to="/settings/org/profile" />
        </Switch>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    currentUser: state.user.user,
    imageUploading: state.imageUpload.uploading,
    uploadedImages: state.imageUpload.uploadedImages,
    organization: state.orgs.organization,
    currentIP: state.orgs.currentIP,
    isTierActionAllowed: getTierAllowedByRedux(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  getOrgData: () => {
    dispatch(orgsGetAction.request());
  },
  updateOrgInfo: (updatedInfo: {}) => {
    dispatch(orgsUpdateAction.request(updatedInfo));
  },
  uploadImage: (
    name: string,
    file: File,
    width: number,
    height: number,
    tags: string,
    transformation?: string,
  ) => {
    dispatch(
      imageUploadAction.request({
        name,
        file,
        width,
        height,
        tags,
        transformation,
      }),
    );
  },
  updateSecurity: (payload: {}) => {
    dispatch(orgsUpdateSecurityAction.request(payload));
  },
  getIP: () => {
    dispatch(orgsGetIPAction.request());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(OrgSettingsRoutes);
