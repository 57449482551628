import { connect } from "react-redux";
import { Action, Dispatch } from "redux";

import { challengeReleaseNoteGetAction } from "../../../../actions";
import { RootState } from "../../../../reducers";
import { ChallengeReleaseNote, ExternalProps } from "./ChallengeReleaseNote";

const mapStateToProps = (state: RootState, props: ExternalProps) => ({
  loading: state.challenge.loadingReleaseNote,
  releaseNote: state.challenge.challengeReleaseNote,
  ...props,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  getReleaseNote: (challengeId: number, versionCode?: string) =>
    dispatch(
      challengeReleaseNoteGetAction.request({}, { challengeId, versionCode }),
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ChallengeReleaseNote);
