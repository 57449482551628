import { FormEvent, useEffect, useState } from "react";

import { ApplicantExamsModel } from "@shared/models";
import { matchString } from "@shared/services/string";

import { useDeleteApplicantScheduleDelivery } from "./query";

interface Params {
  applicantExamCount?: number;
  examId?: number;
  initialApplicants?: ApplicantExamsModel[];
}

export const DELIVER_APPLICANT_LIMIT = 100;

export function useApplicantList({
  applicantExamCount = 0,
  examId,
  initialApplicants = [],
}: Params = {}) {
  const removeApplicant = useDeleteApplicantScheduleDelivery();
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const [applicants, setApplicants] = useState<
    ApplicantExamsModel[] | undefined
  >(initialApplicants);
  const [filteredApplicants, setFilteredApplicants] = useState<
    ApplicantExamsModel[] | undefined
  >([]);
  const applicantCount = applicants?.length ?? applicantExamCount;
  const applicantsApiPayload =
    applicants?.map(({ applicant: { email } }) => email) ?? [];
  const applicantCountFormat = `${applicantCount}/${DELIVER_APPLICANT_LIMIT}`;

  const updateKeyword = (value: string) => {
    if (searchKeyword !== value) {
      setSearchKeyword(value);
    }
  };
  const remove = (target: Pick<ApplicantExamsModel, "id">) => {
    const newList = applicants?.filter(({ id }) => id !== target.id);
    setApplicants(newList);

    if (removeApplicant && examId) {
      removeApplicant.mutate({ examId, applicantExamId: target.id });
    }
  };

  const handleChangeKeyword = (event: FormEvent<HTMLInputElement>) => {
    const { value } = event.target as HTMLInputElement;
    updateKeyword(value);
  };

  useEffect(() => {
    if (!applicants) {
      return;
    }

    const filtered = applicants.filter((applicant) =>
      matchString(applicant?.applicant?.email ?? "", searchKeyword),
    );
    setFilteredApplicants(filtered);
  }, [applicants, searchKeyword]);

  return {
    applicants,
    applicantsApiPayload,
    applicantCount,
    applicantCountFormat,
    filteredApplicants,
    searchKeyword,
    handleChangeKeyword,
    updateKeyword,
    remove,
    setApplicants,
    setFilteredApplicants,
    setSearchKeyword,
  };
}
