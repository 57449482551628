import classnames from "classnames";
import { useState, forwardRef, TextareaHTMLAttributes } from "react";

import { Markdown, Icon, ValidationMessage, Msg } from "..";
import { Textarea, TextareaRef } from "./Textarea";

interface RichMarkdownProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  error?: { [key: string]: string };
  preview?: boolean;
  name: string;
  value?: string;
}

const RichMarkdown = forwardRef<TextareaRef, RichMarkdownProps>(
  (props, ref) => {
    const {
      className,
      disabled,
      error,
      name,
      onBlur,
      onChange,
      placeholder,
      preview: previewOnly,
      readOnly,
      value,
    } = props;

    const [onPreview, setOnPreview] = useState(false);

    const handleClick = (preview: boolean) => {
      setOnPreview(preview);
    };

    const rootStyle = classnames("code-c-rich-markdown", {
      [`${className}`]: Boolean(className),
      "is-preview": previewOnly,
      "on-preview": onPreview,
      "on-edit": !onPreview,
    });

    return (
      <div className={rootStyle}>
        {previewOnly || (
          <div className="code-c-rich-markdown__header">
            <div className="code-c-rich-markdown__header__tabs">
              <div
                className="code-c-rich-markdown__header__tabs__edit"
                onClick={() => handleClick(false)}
              >
                <Icon type="pencil" size="medium" />
                <Msg id="edit" />
              </div>
              <div
                className="code-c-rich-markdown__header__tabs__preview"
                onClick={() => handleClick(true)}
              >
                <Icon type="eye" size="medium" />
                <Msg id="preview" />
              </div>
            </div>
          </div>
        )}
        <div className="code-c-rich-markdown__body">
          {previewOnly || onPreview ? (
            <Markdown body={value || ""} />
          ) : (
            <Textarea
              ref={ref}
              // value={value} // NOTE: if we send the value it becomes readonly as it is managed by the ref
              disabled={disabled}
              error={error}
              name={name}
              onBlur={onBlur}
              onChange={onChange}
              placeholder={placeholder}
              readOnly={readOnly}
            />
          )}
        </div>
        <ValidationMessage name={name} error={error} />
      </div>
    );
  },
);

export { RichMarkdown };
