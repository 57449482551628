import * as classNames from "classnames";
import * as React from "react";

import { Icon } from "@shared/components";

export interface AccordionProps {
  defaultExpanded?: boolean;
  disabled?: boolean;
  expanded?: boolean;
  summary?: React.ReactNode;
  customSummary?: (open: boolean) => React.ReactNode;
  onOpen?: () => void;
  onClose?: () => void;
  children: React.ReactNode;
  className?: string;
  summaryClassName?: string;
  detailClassName?: string;
}

const Accordion = ({
  defaultExpanded,
  disabled,
  expanded,
  summary,
  customSummary,
  onOpen,
  onClose,
  children,
  className,
  summaryClassName,
  detailClassName,
}: AccordionProps) => {
  const rootStyle = classNames("code-c-accordion", className);
  const summaryStyle = classNames("code-c-accordion__bar", summaryClassName, {
    "code-c-accordion__bar__disabled": disabled,
  });

  const [open, setOpen] = React.useState(
    typeof expanded !== "undefined" ? expanded : Boolean(defaultExpanded),
  );

  React.useEffect(() => {
    if (typeof expanded !== "undefined") {
      setOpen(Boolean(expanded));
    }
  }, [expanded]);

  const summaryNode = customSummary ? (
    customSummary(open)
  ) : (
    <>
      <Icon type={open ? "angle-down" : "angle-right"} />
      {summary}
    </>
  );

  return (
    <div className={rootStyle}>
      <div
        className={summaryStyle}
        onClick={() => {
          if (disabled) return;

          open ? onClose?.() : onOpen?.();

          setOpen(!open);
        }}
        role="button"
      >
        {summaryNode}
      </div>
      {open && (
        <div
          className={classNames("code-c-accordion__detail", detailClassName)}
        >
          {children}
        </div>
      )}
    </div>
  );
};

export default Accordion;
