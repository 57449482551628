import { connect } from "react-redux";
import { Action, Dispatch } from "redux";

import { orgContractGetAction } from "@actions";

import { RootState } from "@reducers";

import { SettingContractChart, ExternalProps } from "./SettingContractChart";

const mapStateToProps = (state: RootState, props: ExternalProps) => ({
  filteredContract: state.orgs.filteredContract,
  ...props,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  getContract: (contractId: number, examId: number | undefined) => {
    dispatch(
      orgContractGetAction.request(examId ? { examId } : {}, contractId),
    );
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SettingContractChart);
