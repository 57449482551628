import * as classnames from "classnames";
import * as React from "react";

import { Block, LeftBlock, Msg, RightBlock } from "@shared/components";
import { useExamSimpleList } from "@shared/hooks/query";
import { ContractUsageModel, SimpleExamModel } from "@shared/models";
import {
  includeExamInContract,
  getTotalCounts,
  getExamsInContract,
} from "@shared/services/contract";
import { dayjs, formatDate, getDifference } from "@shared/services/date";

import { SettingContractExamPicker } from "./SettingContractExamPicker";

export type ContractTypes = "current" | "future" | "past";

interface SettingContractTitleProps {
  contract: ContractUsageModel;
  contractType: ContractTypes;
  selectedExam?: SimpleExamModel;
  filteredContract?: ContractUsageModel;
  onExamSelected?: (selectedExam: SimpleExamModel | undefined) => void;
}

export const SettingContractTitle = ({
  contract,
  contractType,
  selectedExam,
  filteredContract,
  onExamSelected,
}: SettingContractTitleProps) => {
  const { data: exams } = useExamSimpleList({ archived: "all" });

  const { totalUsageCount, totalDeliveredCount, examsInContract } =
    React.useMemo(() => {
      const targetContract =
        filteredContract && includeExamInContract(contract, selectedExam)
          ? filteredContract
          : contract;

      const { totalDeliveredCount, totalUsageCount } = getTotalCounts(
        targetContract.projectUsages,
      );

      const examsInContract = getExamsInContract(
        targetContract.projectUsages,
        exams,
      );

      return { totalUsageCount, totalDeliveredCount, examsInContract };
    }, [contract, exams, selectedExam, filteredContract]);

  const hasExceededLimit =
    totalUsageCount > (contract.contract.examDeliveryLimit ?? 0);

  const showExamPicker =
    selectedExam || (totalDeliveredCount > 0 && examsInContract.length > 0);

  return (
    <Block className="code-setting-contract__title">
      <LeftBlock>
        <div>
          <h3 className="code-setting-contract__title__name">
            {contract.contract.name}
          </h3>
          <span className="code-setting-contract__title__dates">
            {`${formatDate(contract.contract.startAt)} - ${formatDate(
              contract.contract.endAt,
            )} `}
            {contractType === "current" && (
              <Msg
                id="contract.willExpireIn"
                values={{
                  numDays: getDifference(
                    dayjs().startOf("day"),
                    contract.contract.endAt,
                    "days",
                  ),
                }}
              />
            )}
          </span>
        </div>
      </LeftBlock>
      <RightBlock>
        {contractType === "future" && (
          <div className="code-setting-contract__upcoming-title">
            <span className="code-setting-contract__upcoming-title__text">
              <Msg id="contract.examLimit" />
            </span>
            <span className="code-setting-contract__upcoming-title__sub-text">
              {contract.contract.examDeliveryLimit ?? 0}
            </span>
          </div>
        )}
        {contractType !== "future" && (
          <div className="code-setting-contract__current-title">
            {showExamPicker && (
              <div className="code-setting-contract__input-container">
                <Msg id="contract.filterByExam" />
                <SettingContractExamPicker
                  simpleExamList={examsInContract}
                  selectedExam={selectedExam}
                  onExamSelected={(exam) => {
                    if (typeof onExamSelected === "function") {
                      onExamSelected(exam);
                    }
                  }}
                />
              </div>
            )}
            <div className="code-setting-contract__contract-stats">
              <span
                className={classnames(
                  "code-setting-contract__current-title__total-usage",
                  {
                    "code-setting-contract__current-title__warning":
                      hasExceededLimit,
                  },
                )}
              >
                {totalUsageCount}
              </span>
              <span>
                (
                <Msg
                  id="contract.delivered"
                  values={{ numDeliveries: totalDeliveredCount }}
                />
                ) / {contract.contract.examDeliveryLimit ?? 0}
              </span>
            </div>
            {hasExceededLimit && (
              <div>
                <span className="code-setting-contract__current-title__warning">
                  <Msg id="contract.exceed" />
                </span>
              </div>
            )}
          </div>
        )}
      </RightBlock>
    </Block>
  );
};
