import * as React from "react";

import {
  DonutChart,
  StackedBarChart,
  StackedBarChartItem,
  Msg,
  QuickHelp,
} from "@shared/components";
import { ContractUsageModel, SimpleExamModel } from "@shared/models";
import {
  getXTicksForStackedBarChart,
  includeExamInContract,
  buildDonutChartData,
  buildStackedBarChartData,
  getTotalCounts,
  getDefaultXAxisExtent,
  getIterableProjectUsageYearlyBreakDownModel,
  sumAllIterableUsageBreakDown,
  getContractPeriod,
} from "@shared/services/contract";
import { ChartColor } from "@shared/services/enums";
import MSG from "@shared/services/message";
import Message from "@shared/services/message";

import { SettingContractTitle } from "./SettingContractTitle";

export type ExternalProps = {
  contract: ContractUsageModel;
  isCurrentContract?: boolean;
};

export type InjectedProps = {
  filteredContract?: ContractUsageModel;
  getContract: (contractId: number, examId: number | undefined) => void;
};

export type SettingContractChartProps = ExternalProps & InjectedProps;

export const SettingContractChart = ({
  contract,
  filteredContract,
  getContract,
  isCurrentContract = false,
}: SettingContractChartProps) => {
  const DonutChartPresetColors = {
    [MSG.getMessageByKey("contract.remaining")]: {
      fillColor: ChartColor.White,
      textColor: ChartColor.Black,
      borderColor: ChartColor.Gray,
    },
    [MSG.getMessageByKey("contract.other")]: {
      fillColor: ChartColor.Gray,
      textColor: ChartColor.Black,
      borderColor: ChartColor.Gray,
    },
  };

  const StackedBarChartPresetColors = {
    [MSG.getMessageByKey("contract.totalDeliveredExams")]: ChartColor.LightGray,
  };

  const [selectedExam, setSelectedExam] = React.useState<
    SimpleExamModel | undefined
  >();

  const { dataPoints: donutItems, usagePercentage } = React.useMemo(() => {
    const isFiltered =
      selectedExam &&
      filteredContract &&
      includeExamInContract(contract, selectedExam);

    return buildDonutChartData(
      contract.contract.examDeliveryLimit ?? 0,
      contract.projectUsages,
      isFiltered
        ? {
            filterBy: selectedExam,
            projectUsages: filteredContract?.projectUsages ?? [],
          }
        : undefined,
    );
  }, [contract, selectedExam, filteredContract]);

  const { stackedChartItems, groups, extraXGrids, xTicks } =
    React.useMemo(() => {
      const isFiltered =
        selectedExam &&
        filteredContract &&
        includeExamInContract(contract, selectedExam);

      const iterableProjectUsages = contract.projectUsages.map((item) =>
        getIterableProjectUsageYearlyBreakDownModel(item),
      );

      const totalIterableUsageBreakDown = sumAllIterableUsageBreakDown(
        iterableProjectUsages.map((item) => item.breakdowns),
      );

      // get actual contract period
      const [startAt, endAt] = getContractPeriod({
        breakdowns: totalIterableUsageBreakDown,
        contractStartAt: contract.contract.startAt,
        contractEndAt: contract.contract.endAt,
      });

      const { stackedChartItems: originalStackedChartItems, groups } =
        buildStackedBarChartData({
          projectUsages: iterableProjectUsages,
          totalProjectUsage: {
            breakdowns: totalIterableUsageBreakDown,
          },
          startAt,
          endAt,
          filtered: isFiltered
            ? {
                filterBy: selectedExam,
                projectUsages:
                  filteredContract?.projectUsages
                    .filter(
                      (projectUsage) =>
                        projectUsage.project.id === selectedExam?.projectId,
                    )
                    .map((item) =>
                      getIterableProjectUsageYearlyBreakDownModel(item),
                    ) ?? [],
              }
            : undefined,
        });

      const xTicks = getXTicksForStackedBarChart(
        contract.projectUsages[0]?.yearlyBreakdowns ?? [],
        startAt,
        endAt,
      );

      // add extra x grids to make sure when is the year has ended
      const extraXGrids: c3.GridLineOptionsWithAxis[] = xTicks.reduce(
        (result, item, index) => {
          const [year, month] = item.split("/");
          return month === "01"
            ? ([
                ...result,
                { value: index, text: year },
              ] as c3.GridLineOptionsWithAxis[])
            : result;
        },
        [] as c3.GridLineOptionsWithAxis[],
      );

      // order by donut chart items to keep the coloring
      const stackedChartItems = donutItems
        .map(
          (donutItem) =>
            originalStackedChartItems.find(
              (stackedChartItem) => stackedChartItem.id === donutItem.id,
            ) as StackedBarChartItem,
        )
        // rid undefined data
        .filter((item) => Boolean(item));

      return {
        stackedChartItems,
        groups,
        xTicks,
        extraXGrids,
      };
    }, [contract, donutItems, selectedExam, filteredContract]);

  const { totalDeliveredCount } = getTotalCounts(contract.projectUsages);

  const isOverYear = xTicks.length > 12;

  const options = React.useMemo(() => {
    return isOverYear
      ? {
          subChart: {
            show: true,
            defaultXAxisExtent: getDefaultXAxisExtent(xTicks),
          },
          height: 420,
          extraXGrids,
        }
      : undefined;
  }, [extraXGrids, xTicks, isOverYear]);

  return (
    <div>
      <SettingContractTitle
        contract={contract}
        contractType={isCurrentContract ? "current" : "past"}
        selectedExam={selectedExam}
        filteredContract={filteredContract}
        onExamSelected={(exam) => {
          setSelectedExam(exam);
          getContract(contract.contract.id, exam?.examId);
        }}
      />
      <div className="code-setting-contract__usage">
        {totalDeliveredCount > 0 ? (
          <>
            <div className="code-setting-contract__usage__donut-chart">
              <div>
                <span className="code-setting-contract__usage-chart-title">
                  <Msg id="contract.totalUsage" />
                </span>
              </div>
              <DonutChart
                title={`${usagePercentage}%`}
                items={donutItems}
                colors={DonutChartPresetColors}
                chartSize={250}
              />
            </div>
            <div className="code-setting-contract__usage__bar-chart">
              <div>
                <span className="code-setting-contract__usage-chart-title">
                  <Msg id="contract.monthlyDeliveredExam" />
                </span>
                <QuickHelp
                  text={Message.getMessageByKey(
                    "contract.monthlyDeliveredExam.note",
                  )}
                />
              </div>
              <StackedBarChart
                items={stackedChartItems}
                groups={groups}
                xTicks={xTicks}
                colors={StackedBarChartPresetColors}
                options={options}
              />
            </div>
          </>
        ) : (
          <div className="code-setting-contract__no-delivered">
            <Msg id="contract.noDelivered" />
          </div>
        )}
      </div>
    </div>
  );
};
