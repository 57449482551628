import { CustomFormDefinitionModel } from ".";
import { CustomFormDefinitionType } from "../services/enums";

class CustomFormModel {
  public readonly key: string;
  public readonly displayName: React.ReactNode;
  public readonly language?: string;
  public readonly dataType: CustomFormDefinitionType;
  public readonly value?: string | string[];

  public static parse(
    definition: CustomFormDefinitionModel,
    formValue: { [key: string]: string | string[] },
  ) {
    return new CustomFormModel({
      key: definition.key,
      displayName: definition.displayName,
      language: definition.language,
      dataType: definition.dataType,
      value: formValue[definition.key],
    });
  }

  constructor(args: {}) {
    Object.assign(this, args);
  }
}

export default CustomFormModel;
