import * as classnames from "classnames";

import CustomFormModel from "../../models/CustomForm.model";
import { dayjs, getStartAndEndDateFromDateRange } from "../../services/date";
import { CustomFormDefinitionType } from "../../services/enums";

/**
 * Prop interface
 */
export interface CustomFormDisplayProps {
  formValue: CustomFormModel;
  className?: string;
  children?: React.ReactNode;
}

/**
 * React Component
 */
export default function CustomFormDisplay({
  formValue,
  className,
  children,
}: CustomFormDisplayProps) {
  const rootStyle = classnames("code-c-custom-form-display-old", {
    [`${className}`]: Boolean(className),
  });
  const { dataType } = formValue;
  const value = Array.isArray(formValue.value)
    ? formValue.value.join(", ")
    : formValue.value || "";

  const getDisplayString = () => {
    if (dataType === CustomFormDefinitionType.Date) {
      const fromNow = dayjs(value).fromNow();
      const stringTokens = [
        value.replace(/-/g, "/"), // 2021-01-01 => 2021/01/01
        value !== "" && fromNow !== "Invalid Date" ? `(${fromNow})` : "",
      ];
      return stringTokens.join(" ");
    } else if (dataType === CustomFormDefinitionType.DateRange) {
      const { startDate, endDate } = getStartAndEndDateFromDateRange(value);
      if (!startDate.isValid() || !endDate.isValid()) {
        return "";
      }
      const difference = startDate.to(endDate, true);

      const fromString = startDate.format("YYYY/MM/DD");
      const toString = endDate.format("YYYY/MM/DD");

      const differenceString = difference !== "" ? `(${difference})` : "";

      return `${fromString} - ${toString} ${differenceString}`;
    } else {
      return value;
    }
  };

  return (
    <div className={rootStyle}>
      <dt>{formValue.displayName}</dt>
      <dd>{children ? children : getDisplayString()}</dd>
      {/* // MEMO: if there are children, render those instead of the usual value. This allows for more complex nodes to be rendered. */}
    </div>
  );
}
