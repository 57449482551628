import * as classnames from "classnames";

import { ChallengeDetailBox } from "..";
import { Msg, Markdown } from "../../../../../../shared/components";
import { ChallengeModel } from "../../../../../../shared/models";
import { EvaluationPoint } from "../../../challengeSections";

/**
 * Prop interface
 */
export interface ChallengeSolutionProps {
  challenge: ChallengeModel;
}

/**
 * React Component
 */
export function ChallengeSolution({ challenge }: ChallengeSolutionProps) {
  const rootStyle = classnames("code-challenge-solution");

  return (
    <div className={rootStyle}>
      <ChallengeDetailBox title={<Msg id="challenge.solutionExplanation" />}>
        <Markdown body={challenge.howToSolve || ""} />
      </ChallengeDetailBox>
      <ChallengeDetailBox title={<Msg id="challenge.evaluationPoint" />}>
        <EvaluationPoint challenge={challenge} />
      </ChallengeDetailBox>
    </div>
  );
}
