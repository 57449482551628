import * as classnames from "classnames";
import { cloneDeep } from "lodash";
import { NavLink, matchPath } from "react-router-dom";

import { Restricted, Icon, JumpTo, Msg } from "..";
import { UserModel } from "../../models";
import { ApplicationType, ProjectRole, UserRole } from "../../services/enums";

interface MenuItem {
  titleKey: string;
  icon: string;
  to: string;
  matchPathTo?: string;
  roles?: Array<number>;
}

/**
 * Prop interface
 */
export interface MenuProps {
  currentUser: UserModel;
  projectId: number;
  applicationType?: ApplicationType;
  className?: string;
}

const examsMenuAllowedRoles = [
  // Screening projects roles
  ProjectRole.ProjectAdmin,
  ProjectRole.ExamCreator,
  ProjectRole.ExamDeliverer,
  ProjectRole.Reviewer,
  // Org roles
  UserRole.SystemAdmin,
];

const contentsMenuAllowedRoles = [
  // Screening projects roles
  ProjectRole.ProjectAdmin,
  ProjectRole.ExamCreator,
  ProjectRole.ChallengeCreator,
  // Org roles
  UserRole.SystemAdmin,
  UserRole.ChallengeCreator,
];

const MENU_ITEMS_SCREENING: MenuItem[] = [
  {
    titleKey: "common.exams",
    to: "/p/${projectId}/exams",
    icon: "cubes",
    roles: examsMenuAllowedRoles,
  },
  {
    titleKey: "common.libraries",
    to: "/p/${projectId}/challenges",
    icon: "cube",
    matchPathTo: "/p/:projectId/(challenges|questions)",
    roles: contentsMenuAllowedRoles,
  },
  {
    titleKey: "settings",
    to: "/settings",
    icon: "cogs",
    matchPathTo: "/p/:projectId/settings",
  },
];

/**
 * React Component
 */
export default function Menu({
  projectId,
  applicationType = ApplicationType.Screening,
  className,
}: MenuProps) {
  const rootStyle = classnames("code-c-menu", "menu", {
    [`${className}`]: Boolean(className),
  });

  let newMenuItems: MenuItem[] = [];
  if (projectId) {
    newMenuItems = cloneDeep(MENU_ITEMS_SCREENING).map((item) => {
      item.to = item.to.replace("${projectId}", projectId.toString());
      return item;
    });
  }

  const menuItems = newMenuItems.map((item: MenuItem, index: number) => {
    const isActive = Boolean(
      matchPath(window.location.pathname, {
        path: item.matchPathTo,
      }) ||
        matchPath(window.location.pathname, {
          path: item.to,
        }),
    );

    return (
      <Restricted key={index} roles={item.roles} allow={!item.roles}>
        <li className="code-c-menu__list-item" key={index}>
          <NavLink
            to={{
              pathname: item.to,
              state: { clickMenu: true },
            }}
            className="code-c-menu__list-item-link"
            isActive={() => isActive}
          >
            <Icon type={item.icon} iconClassName="fa-lg" />
            <div>
              <Msg id={item.titleKey} />
            </div>
          </NavLink>
        </li>
      </Restricted>
    );
  });

  return (
    <aside className={rootStyle}>
      <ul
        className="code-c-menu__list menu-list"
        role="navigation"
        aria-label="Main Side Menu"
      >
        {menuItems}
        <li className="code-c-menu__list-item">
          <JumpTo
            to={"https://givery.notion.site/3983a1cf3f564bb4bd713a175f3c058d"}
            className="code-c-menu__list-item-link"
          >
            <Icon type="question-circle" iconClassName="fa-lg" />
            <div>
              <Msg id={"help"} />
            </div>
          </JumpTo>
        </li>
      </ul>
    </aside>
  );
}
