import { connect } from "react-redux";
import { Action, Dispatch } from "redux";

import { imageUploadAction } from "@actions";

import { RootState } from "@reducers";

import { PresetExamSection } from "./PresetExamSection";
import { ExternalProps } from "./PresetExamSection";

const mapStateToProps = (state: RootState, props: ExternalProps) => ({
  uploadingImage: state.imageUpload.uploading,
  uploadedImages: state.imageUpload.uploadedImages,
  ...props,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  uploadImage: (
    name: string,
    file: File,
    width: number,
    height: number,
    tags: string,
    transformation: string,
  ) => {
    dispatch(
      imageUploadAction.request({
        name,
        file,
        width,
        height,
        tags,
        transformation,
      }),
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PresetExamSection);
