import * as classnames from "classnames";
import * as React from "react";

import { Icon, Tooltip, TooltipProps } from "..";

/**
 * Prop interface
 */
export interface QuickHelpProps {
  text: React.ReactNode;
  placement?: TooltipProps["placement"];
  maxSize?: TooltipProps["maxSize"];
  className?: string;
}

/**
 * React Component
 */
export default function QuickHelp({
  text,
  placement,
  maxSize,
  className,
}: QuickHelpProps) {
  const rootStyle = classnames("code-c-quick-help", {
    [`${className}`]: Boolean(className),
  });

  const wrapperRootStyle = classnames("code-c-quick-help-wrapper");

  return (
    <Tooltip
      className={rootStyle}
      wrapperClassName={wrapperRootStyle}
      text={text}
      placement={placement}
      maxSize={maxSize}
    >
      <Icon type="info-circle" />
    </Tooltip>
  );
}
