import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { ApiResponse } from "@api/httpClient";
import { QueryCacheMeta } from "@api/queryClient";
import {
  PingSubmissionReviewerPayload,
  SubmissionReviewPayload,
  SubmissionReviewersResponse,
  UpdateSubmissionReviewerResponse,
  createSubmissionReview,
  getSubmissionReviewers,
  pingReviewer,
  putSubmissionReview,
} from "@api/submissions";

import { useStoreContext } from "@context";

import { getLastWeekTimestamp } from "@shared/services/date";
import Message from "@shared/services/message";

import { examKeys } from "../exams";
import { useInvitationStatus } from "../useEnum";
import { useRouteParams } from "../useRouteParams";
import { useInvalidateSubmissionRelation } from "./useSubmission";

interface SubmissionReviewerKeyParams {
  projectId: number;
  submissionId?: number;
}

interface PingSubmissionReviewerParams extends PingSubmissionReviewerPayload {
  submissionId?: number;
}

const initialData = {
  pendingReviewers: [],
  reviews: [],
};

export const submissionReviewKeys = {
  all: ["submissions", "review"] as const,
  reviewer: ({ submissionId, projectId }: SubmissionReviewerKeyParams) => {
    if (submissionId) {
      return [...submissionReviewKeys.all, projectId, submissionId] as const;
    }

    return [...submissionReviewKeys.all, projectId] as const;
  },
};

export function useGetSubmissionsReviewers(skipErrorToast = true) {
  const { projectId } = useStoreContext();
  const { submissionId } = useRouteParams();
  const invitationStatus = useInvitationStatus();
  const enabled = Boolean(submissionId);
  const query = useQuery<SubmissionReviewersResponse>({
    meta: { skipErrorToast } as QueryCacheMeta,
    queryKey: submissionReviewKeys.reviewer({
      projectId,
      submissionId,
    }),
    queryFn: async ({ signal }) => {
      const { result } = await getSubmissionReviewers(projectId, submissionId, {
        signal,
      });

      return {
        ...result,
        pendingReviewers: result.pendingReviewers.map((r) => ({
          ...r,
          ...(r.name === "_invitation_" ? { name: r.email } : {}),
          displayStatus: r.invitationStatus
            ? invitationStatus?.[r.invitationStatus] || ""
            : "",
        })),
      };
    },
    enabled,
    initialData,
    initialDataUpdatedAt: getLastWeekTimestamp,
    keepPreviousData: true,
  });

  return query;
}

export function useCreateSubmissionsReview() {
  const client = useQueryClient();
  const { projectId } = useStoreContext();
  const { examId, submissionId } = useRouteParams();
  const invalidateSubmission = useInvalidateSubmissionRelation();

  const mutation = useMutation({
    mutationFn: (data: SubmissionReviewPayload) =>
      createSubmissionReview(submissionId, projectId, { data }),
    onSuccess: (res: ApiResponse<UpdateSubmissionReviewerResponse>) => {
      toast.success(
        Message.getMessageByKey("message.yourReview.create.success"),
      );
      client.setQueryData(
        submissionReviewKeys.reviewer({ projectId, submissionId }),
        (prevData: SubmissionReviewersResponse) => {
          const newPendingReviewers = prevData.pendingReviewers.filter(
            (r) => r.id !== res.result.reviewer.id,
          );

          return {
            pendingReviewers: newPendingReviewers,
            reviews: [...prevData.reviews, res.result],
          };
        },
      );
      client.invalidateQueries(examKeys.count(projectId, examId));
      invalidateSubmission(submissionId);
    },
  });

  return mutation;
}

export function useUpdateSubmissionsReview() {
  const client = useQueryClient();
  const { projectId } = useStoreContext();
  const { examId, submissionId } = useRouteParams();
  const invalidateSubmission = useInvalidateSubmissionRelation();

  const mutation = useMutation({
    mutationFn: ({
      reviewerId,
      data,
    }: {
      reviewerId: number;
      data: SubmissionReviewPayload;
    }) =>
      putSubmissionReview({
        reviewerId,
        projectId,
        submissionId,
        options: { data },
      }),
    onSuccess: (res: ApiResponse<UpdateSubmissionReviewerResponse>) => {
      toast.success(
        Message.getMessageByKey("message.yourReview.update.success"),
      );
      client.setQueryData(
        submissionReviewKeys.reviewer({ projectId, submissionId }),
        (prevData: SubmissionReviewersResponse) => {
          const reviewers = prevData.reviews.filter(
            (r) => r.id !== res.result.id,
          );

          return {
            ...prevData,
            reviews: [...reviewers, res.result],
          };
        },
      );
      client.invalidateQueries(examKeys.count(projectId, examId));
      invalidateSubmission(submissionId);
    },
  });

  return mutation;
}

export function usePingReviewer() {
  const { projectId } = useStoreContext();
  const { submissionId: id } = useRouteParams();

  const mutation = useMutation({
    mutationFn: ({ submissionId, ...data }: PingSubmissionReviewerParams) =>
      pingReviewer(submissionId ?? id, projectId, { data }),
    onSuccess: () => {
      toast.success(Message.getMessageByKey("message.submission.ping.success"));
    },
  });

  return mutation;
}
