import { useMutation } from "@tanstack/react-query";

import { UpdateExamUrlSharingPayload, putExamUrlSharing } from "@api/exams";

import { useStoreContext } from "@context";

import { useInvalidateExamRelation } from "./useExam";

interface UpdateExamUrlSharingParams {
  examId: number;
  data: UpdateExamUrlSharingPayload;
}

export function useUpdateExamUrlSharing() {
  const { projectId } = useStoreContext();
  const invalidate = useInvalidateExamRelation();

  const mutation = useMutation({
    mutationFn: ({ examId, data }: UpdateExamUrlSharingParams) =>
      putExamUrlSharing({ examId, projectId, options: { data } }),
    onSuccess: (_, { examId }) => {
      invalidate(examId);
    },
  });

  return mutation;
}
