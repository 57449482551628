import * as React from "react";

/**
 * Prop interface
 */
export interface RestrictedProps {
  allow?: boolean;
  inline?: boolean;
  wrapperTagName?: keyof JSX.IntrinsicElements | false;
  children: React.ReactElement[] | React.ReactElement;
  className?: string;
}

/**
 * React Component
 */
export default function Restricted({
  allow,
  children,
  inline,
  className,
  wrapperTagName = "div",
}: RestrictedProps): React.ReactElement | null {
  if (!allow) {
    return null;
  }
  if (wrapperTagName === false) {
    // TODO the current connect does not accept React.ReactElement[]
    // this "as" will remove after the react-redux bump up to lastest version
    return children as React.ReactElement;
  }
  const WrapperTag = wrapperTagName;
  return (
    <WrapperTag
      className={className}
      style={{ display: inline ? "inline-block" : "block" }}
    >
      {children}
    </WrapperTag>
  );
}
