import { useIsMutating } from "@tanstack/react-query";
import * as classnames from "classnames";
import * as React from "react";

import { CREATE_RECALCULATE_SCORE_MUTATION_KEY } from "@shared/hooks/query";

import { Restricted, Button, Msg } from "../..";
import {
  UserRole,
  ChallengeResultStatus,
  ChallengeStyle,
} from "../../../services/enums";

/**
 * Prop interface
 */
type RecalculateScoreButtonProps = {
  className?: string;
  style: ChallengeStyle;
  status: ChallengeResultStatus;
  onClick: () => void;
};

/**
 * React Component
 */
export const RecalculateScoreButton: React.FunctionComponent<
  RecalculateScoreButtonProps
> = (props: RecalculateScoreButtonProps) => {
  const { className, status, style } = props;

  const rootStyle = classnames("code-c-recalculate-score-button", {
    [`${className}`]: Boolean(className),
  });

  const recalculating = useIsMutating({
    mutationKey: CREATE_RECALCULATE_SCORE_MUTATION_KEY,
  });

  const disabled = Boolean(recalculating);

  /**
   * Private Functions
   */
  const onOpenConfirm = (e: React.FormEvent) => {
    props.onClick();
    e.stopPropagation();
  };

  /**
   * Render
   */
  return (
    <Restricted
      roles={[UserRole.SystemAdmin]}
      strictAllow={
        ChallengeStyle.isCodingTypeChallenge(style) &&
        [ChallengeResultStatus.Finished].includes(status)
      }
      wrapperTagName={false}
    >
      <>
        <Button
          className={rootStyle}
          size="small"
          shrink={true}
          onClick={onOpenConfirm}
          disabled={disabled}
        >
          <Msg id="submission.score.recalculate" />
        </Button>
      </>
    </Restricted>
  );
};
