import { connect } from "react-redux";
import { Action, Dispatch } from "redux";

import { questionExamLevelInsightsGetAction } from "@actions";

import { RootState } from "@reducers/index";

import { QuestionModel } from "@shared/models";

import Question, { ExternalProps } from "./Question";

const mapStateToProps = (state: RootState, props: ExternalProps) => {
  const { question, ...rest } = props;

  if (question && props.insightsDisplayOptions?.showExamLevelStats) {
    const { loadingInsight, examLevelInsight } =
      state.question.questionDetailList.find(
        (item) => item.id === question.id,
      ) || new QuestionModel();

    return {
      question: new QuestionModel({
        ...question,
        loadingInsight,
        examLevelInsight,
      }),
      ...rest,
    };
  }
  return { ...props };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  getExamLevelInsights: (examId: number, questionId: number) => {
    dispatch(
      questionExamLevelInsightsGetAction.request({}, { examId, questionId }),
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Question);
