import * as classnames from "classnames";
import * as React from "react";

import {
  FormGroup,
  Label,
  Input,
  Textarea,
  Checkbox,
  Radio,
  SelectItem,
  Select,
  Markdown,
  DatePicker,
  DatePickerType,
  DateRangePicker,
} from "..";
import { CustomFormDefinitionModel } from "../../models";
import { CustomFormDefinitionType } from "../../services/enums";
import Message from "../../services/message";

/**
 * Prop interface
 */
export interface CustomFormProps {
  name?: string;
  definition?: CustomFormDefinitionModel;
  className?: string;
  value?: string | boolean | string[];
  formValues?: { [key: string]: string | boolean | string[] };
  error?: { [key: string]: string };
  locale?: string;
  onChange?: (
    e:
      | React.FormEvent<
          HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
        >
      | string
      | string[],
  ) => void;
  onBlur?: (
    e: React.FormEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >,
  ) => void;
}

/**
 * React Component
 */
export default function CustomForm({
  definition = new CustomFormDefinitionModel(),
  name,
  value,
  formValues = {},
  error,
  locale,
  onChange,
  onBlur,
  className,
}: CustomFormProps) {
  const rootStyle = classnames("code-c-custom-form", {
    [`${className}`]: Boolean(className),
  });

  let customControl;
  let customControlItems;

  switch (definition.dataType) {
    case CustomFormDefinitionType.Text: {
      customControl = (
        <Input
          name={name}
          value={value as string}
          error={error}
          onChange={onChange}
          onBlur={onBlur}
        />
      );
      break;
    }

    case CustomFormDefinitionType.Number: {
      customControl = (
        <Input
          type="number"
          name={name}
          value={value as string}
          error={error}
          onChange={onChange}
          onBlur={onBlur}
        />
      );
      break;
    }

    case CustomFormDefinitionType.Textarea: {
      customControl = (
        <Textarea
          name={name}
          value={value as string}
          error={error}
          onChange={onChange}
          onBlur={onBlur}
        />
      );
      break;
    }

    case CustomFormDefinitionType.Select: {
      const options = definition.options.map((item: string) => {
        return {
          value: item,
          label: item,
        } as SelectItem;
      });
      options.unshift({
        value: "",
        label: Message.getMessageByKey("custom-form-select.placeholder"),
      } as SelectItem);
      customControl = (
        <Select
          options={options}
          name={name}
          value={value as string}
          error={error}
          onChange={onChange}
          onBlur={onBlur}
        />
      );
      break;
    }

    case CustomFormDefinitionType.Checkbox: {
      const checkBoxNames = definition.getKeys();
      customControlItems = definition.options.map(
        (item: string, index: number) => {
          const checkBoxName = checkBoxNames[index];
          return (
            <Checkbox
              key={index}
              name={checkBoxName}
              value={(formValues[checkBoxName] as boolean) || false}
              onChange={onChange}
              onBlur={onBlur}
            >
              {item}
            </Checkbox>
          );
        },
      );

      customControl = (
        <FormGroup name={name} error={error}>
          {customControlItems}
        </FormGroup>
      );
      break;
    }

    case CustomFormDefinitionType.Radio: {
      customControlItems = definition.options.map(
        (item: string, index: number) => {
          return (
            <Radio
              key={index}
              name={name}
              defaultValue={item}
              value={value as string}
              onChange={onChange}
              onBlur={onBlur}
            >
              {item}
            </Radio>
          );
        },
      );

      customControl = (
        <FormGroup name={name} error={error}>
          {customControlItems}
        </FormGroup>
      );
      break;
    }

    case CustomFormDefinitionType.Date: {
      customControl = (
        <DatePicker
          type={DatePickerType.Date}
          locale={locale}
          name={name}
          minDate="1900-01-01"
          maxDate="2099-12-31"
          showYearDropdown={true}
          value={value as string}
          error={error}
          onChange={onChange}
          onBlur={onBlur}
        />
      );
      break;
    }

    case CustomFormDefinitionType.DateRange: {
      const [startDateValue, endDateValue] = (value as string[]) || [];

      customControl = (
        <DateRangePicker
          name={name}
          startDateValue={startDateValue}
          endDateValue={endDateValue}
          type={DatePickerType.Date}
          onStartDateChange={(startDate) =>
            onChange?.([startDate, endDateValue])
          }
          onEndDateChange={(endDate) => onChange?.([startDateValue, endDate])}
          onBlur={onBlur}
          error={error}
        />
      );
    }
    default:
  }

  return (
    <FormGroup className={rootStyle}>
      <Label
        title={definition.displayName}
        isOptional={!definition.required}
        locale={locale}
      />
      <Markdown
        className="code-c-custom-form__description"
        body={definition.description}
      />
      {customControl}
    </FormGroup>
  );
}
