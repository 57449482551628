import * as React from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";

import {
  PageTitle,
  Msg,
  Tag,
  Divider,
  LeftBlock,
  Block,
} from "@shared/components";
import { ContractUsageModel, OrganizationModel } from "@shared/models";
import { Tier } from "@shared/services/enums";

import {
  ContractTypes,
  SettingContractChart,
  SettingContractTitle,
} from "./partials";

/**
 * Props
 */
export interface SettingContractProps {
  pastContracts: ContractUsageModel[];
  currentContracts: ContractUsageModel[];
  futureContracts: ContractUsageModel[];
  organization: OrganizationModel;
  getContracts: (examId?: number) => void;
  getSimpleExamList: () => void;
}

const SettingContract = ({
  pastContracts,
  currentContracts,
  futureContracts,
  organization,
  getContracts,
  getSimpleExamList,
}: SettingContractProps) => {
  React.useEffect(() => {
    getSimpleExamList();
    getContracts();
  }, [getContracts, getSimpleExamList]);

  const renderContracts = React.useCallback(
    (contracts: ContractUsageModel[], type: ContractTypes) => {
      if (!contracts.length) {
        return (
          <div className="no-contract">
            <Msg
              id={
                type === "current"
                  ? "contract.nocontract"
                  : `contract.nocontract.${type}`
              }
            />
          </div>
        );
      }

      return contracts.map((item) => (
        <SettingContractChart
          key={item.contract.id}
          contract={item}
          isCurrentContract={type === "current"}
        />
      ));
    },
    [],
  );

  return (
    <div className="code-setting-contract">
      <BreadcrumbsItem to={`/settings/org/contract`}>
        <Msg id="contract" />
      </BreadcrumbsItem>
      <Block className="code-setting__header code-setting__contract-header">
        <LeftBlock>
          <PageTitle>
            <Msg id="contract" />
            {organization.tier !== Tier.Legacy && (
              <Tag className="code-setting-contract__tier-tag">
                {Tier.toString(organization.tier)}
              </Tag>
            )}
          </PageTitle>
        </LeftBlock>
      </Block>
      <div className="code-setting-contract__block">
        <div>
          <div>
            <div className="code-setting-contract__block__sub-title code-setting-contract__sticky">
              <p>
                <Msg id="contract.current" />
              </p>
            </div>
            <div className="code-setting-contract__block__content code-setting-contract__sticky">
              {renderContracts(currentContracts, "current")}
            </div>
          </div>
          <div>
            <div className="code-setting-contract__block__sub-title code-setting-contract__sticky">
              <p>
                <Msg id="contract.terminatedContracts" />
              </p>
            </div>
            <div className="code-setting-contract__block__content">
              {renderContracts(pastContracts, "past")}
            </div>
          </div>
        </div>
        <Divider vertical className="code-setting-contract__block__divider" />
        <div>
          <div className="code-setting-contract__block__sub-title">
            <p>
              <Msg id="contract.upcomingContracts" />
            </p>
          </div>
          <div>
            {futureContracts.length ? (
              futureContracts.map((contract) => (
                <SettingContractTitle
                  key={contract.contract.id}
                  contract={contract}
                  contractType="future"
                />
              ))
            ) : (
              <div className="no-contract">
                <Msg id="contract.nocontract.future" />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingContract;
