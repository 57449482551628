import * as classnames from "classnames";
import { range } from "lodash";

import {
  Checkbox,
  Column,
  Form,
  FormGroup,
  HeaderColumn,
  ReviewerRow,
  Row,
  Table,
  TableBody,
  TableHead,
  Loading,
  Msg,
  Select,
  Banner,
} from "@shared/components";
import { MemberListModel } from "@shared/models";
import { InvitationStatus } from "@shared/services/enums";
import Message from "@shared/services/message";

import { ExamReviewersHeader } from "./partials/ExamReviewersHeader";

/**
 * Types and Interfaces
 */
export type ExamReviewersSearchForm = {
  keyword: string;
};

type ExamReviewersSettingsForm = {
  selectedReviewers: Record<number, boolean>;
  shareReviewsWithReviewers: boolean;
  requiredReviewerCount: string;
};

export const ALL_REVIEWERS_OPTION = "-1";

export interface ExamReviewersProps {
  canReview?: boolean;
  checkAll: boolean;
  initialValues: ExamReviewersSettingsForm;
  keyword: string;
  projectMemberListLoading?: boolean;
  readOnly?: boolean;
  resetForm?: boolean;
  hideTitle?: boolean;
  reviewers: MemberListModel[];
  onRowClick: (member: MemberListModel) => void;
  onToggleAllCheck: () => void;
  onSettingsChange?: (params: {
    share: boolean;
    requiredReviewerCount: string;
    formValid: boolean;
  }) => void;
  setKeyword: (keyword: string) => void;
}

export default function ExamReviewers({
  canReview,
  checkAll,
  initialValues,
  keyword,
  projectMemberListLoading,
  readOnly,
  reviewers,
  hideTitle,
  onRowClick,
  onToggleAllCheck,
  onSettingsChange,
  setKeyword,
}: ExamReviewersProps) {
  const rootStyle = classnames("code-exam-edit__reviewers");

  const handleSearch = (
    formValid: boolean,
    formValues: ExamReviewersSearchForm,
  ) => {
    if (formValid) {
      setKeyword(formValues.keyword);
    }
  };

  const handleRowClick = (member: MemberListModel) => () => {
    // Don't allow to select expired reviewers
    if (
      member.invitationStatus === InvitationStatus.Expired &&
      !initialValues.selectedReviewers[member.id]
    ) {
      return;
    }
    onRowClick(member);
  };

  const onReviewSettingsChange = (
    _: boolean, // this is not reliable as initialValues should not change on every form value change
    formValues: ExamReviewersSettingsForm,
    error: {},
  ) => {
    const formValid = !Object.values(error).length;

    onSettingsChange?.({
      share: formValues.shareReviewsWithReviewers,
      requiredReviewerCount: formValues.requiredReviewerCount,
      formValid,
    });
  };

  const selectedReviewerCount = Object.values(
    initialValues.selectedReviewers,
  ).filter((isSelected) => isSelected).length;

  return (
    <div className={rootStyle}>
      {!hideTitle && (
        <ExamReviewersHeader
          hideSearchField={readOnly}
          keyword={keyword}
          onTextChanged={handleSearch}
          className="code-exam-edit__reviewers-header"
        />
      )}
      <Loading
        isOpen={projectMemberListLoading}
        fullScreen={false}
        overlay={false}
      />
      <Form
        validation={{
          shareReviewsWithReviewers: ["boolean"],
          requiredReviewerCount: [
            "string",
            initialValues.requiredReviewerCount !== ALL_REVIEWERS_OPTION &&
              selectedReviewerCount > 0 && [
                "reviewerCount",
                { reviewerCount: selectedReviewerCount },
              ],
          ].filter(Boolean),
        }}
        showError={{
          requiredReviewerCount: true,
        }}
        initialValues={{
          shareReviewsWithReviewers: Boolean(
            initialValues?.shareReviewsWithReviewers,
          ),
          requiredReviewerCount: initialValues.requiredReviewerCount,
        }}
        onFormChange={onReviewSettingsChange}
        className="code-exam-edit__reviewers-container"
      >
        <div>
          <FormGroup className="code-exam-edit__reviewers-container__form-group">
            <Table className="code-exam-edit__reviewer-table">
              <TableHead className="code-exam-edit__reviewer-table__head">
                {!readOnly && (
                  <HeaderColumn size={1}>
                    {!projectMemberListLoading && (
                      <Checkbox
                        className="code-exam-edit__reviewers-checkbox"
                        value={checkAll}
                        readOnly={!canReview}
                        onChange={onToggleAllCheck}
                      />
                    )}
                  </HeaderColumn>
                )}
                <HeaderColumn size={4}>
                  <Msg id="common.name" />
                </HeaderColumn>
                <HeaderColumn size={4}>
                  <Msg id="common.email" />
                </HeaderColumn>
              </TableHead>
              <TableBody>
                {reviewers.map((member) => (
                  <Row
                    key={member.id}
                    disabled={!canReview}
                    onClick={!readOnly ? handleRowClick(member) : undefined}
                  >
                    {/* Make sure that checkbox is controlled component */}
                    {!readOnly && (
                      <Column>
                        {/* This condition logic is not necessary when convert to hook */}
                        {typeof initialValues.selectedReviewers[member.id] ===
                          "boolean" && (
                          <Checkbox
                            onChange={
                              !readOnly ? handleRowClick(member) : undefined
                            }
                            // disabled when reviewer status is expired and not selected
                            readOnly={
                              !canReview ||
                              (member.invitationStatus ===
                                InvitationStatus.Expired &&
                                !initialValues.selectedReviewers[member.id])
                            }
                            value={initialValues.selectedReviewers[member.id]}
                          />
                        )}
                      </Column>
                    )}
                    <Column>
                      <ReviewerRow
                        key={`review-row-${member.id}`}
                        member={member}
                      />
                    </Column>
                    <Column>{member.email}</Column>
                  </Row>
                ))}
              </TableBody>
            </Table>
          </FormGroup>
        </div>
        <div
          className={classnames("code-exam-edit__reviewers-settings", {
            "code-exam-edit__reviewers-settings__min-content": !hideTitle,
          })}
        >
          <p className="text-sm">
            <Msg id="exam.review.settings.numReviewersRequired" />
          </p>
          <Banner transparent className="code-exam-edit__reviewers-info">
            <Msg id="exam.review.settings.info" />
          </Banner>
          <div className="code-exam-edit__reviewers-options">
            <Select
              name="requiredReviewerCount"
              innerClassName="code-exam-edit__reviewers-options__select"
              disabled={readOnly || !canReview}
              options={[
                {
                  value: ALL_REVIEWERS_OPTION,
                  label: Message.getMessageByKey(
                    "exam.review.settings.allReviewers",
                  ),
                },
                ...range(1, 11).map((option) => ({
                  value: `${option}`,
                  label: `${option}`,
                })),
              ]}
            />
          </div>
          <p className="text-sm">
            <Msg id="exam.review.settings.scope.label" />
          </p>
          <Checkbox
            name="shareReviewsWithReviewers"
            readOnly={readOnly || !canReview}
          >
            <Msg id="exam.review.settings.scope.checkboxLabel" />
          </Checkbox>
        </div>
      </Form>
    </div>
  );
}
