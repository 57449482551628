import * as React from "react";
import { Link } from "react-router-dom";

import { Icon, Msg, Tooltip } from "@shared/components";
import { useUserRoles } from "@shared/hooks";
import { ExamChallengeSetModel, ExamModel } from "@shared/models";
import { formatDate, formatTimeElapsedString } from "@shared/services/date";
import { ChallengeStyle, ExamDeliveryKind } from "@shared/services/enums";
import Message from "@shared/services/message";

import ExamOutlineSection from "./ExamOutlineSection";

interface ExamOutlineProps {
  examDetail: ExamModel;
  examDetailChallengesSets: ExamChallengeSetModel[];
}

function ExamOutline({
  examDetail,
  examDetailChallengesSets,
}: ExamOutlineProps) {
  const { isAccessExamSettingPage } = useUserRoles();
  const hasReviewer = examDetail.reviewers.length;
  const label = {
    challenge: Message.getMessageByKey("common.challenges"),
    requiredTime: Message.getMessageByKey(
      "editor-exam-detail.exam.requiredTime",
    ),
    reviewers: `${Message.getMessageByKey("exam.reviewers")}${
      hasReviewer ? ` (${examDetail.reviewers.length})` : ""
    }`,
    settings: Message.getMessageByKey("settings"),
    view: {
      outline: Message.getMessageByKey("exam.dashboard.toOutline"),
      challenge: Message.getMessageByKey("exam.dashboard.toChallenges"),
      reviewer: Message.getMessageByKey("exam.dashboard.toReviewers"),
      setting: Message.getMessageByKey("exam.dashboard.toSettings"),
    },
  };
  const deliveryMethod =
    examDetail.deliveryKind === ExamDeliveryKind.ID ? "ID" : "Email / URL";
  const createdDateFormat = formatDate(examDetail.createdAt);
  const creatorName = examDetail.creatorInfo?.name || "";
  const reviewerList =
    examDetail.reviewers.map(({ name }) => name).join(", ") || "-";

  const timeElapsed = React.useMemo(
    () => formatTimeElapsedString(examDetail.estimatedCompletionTime * 60),
    [examDetail.estimatedCompletionTime],
  );
  const challengeList = React.useMemo(() => {
    let total = 0;
    const styleMap = examDetailChallengesSets.reduce(
      (acum, { challenges }) => {
        challenges.forEach(({ style }) => {
          const key = ChallengeStyle.toString(style);
          if (!acum[key]) {
            acum[key] = 0;
          }
          acum[key] += 1;
          total += 1;
        });
        return acum;
      },
      {} as Record<string, number>,
    );

    return {
      info: Object.entries(styleMap)
        .map(([style, count]) => style + " " + count)
        .join(", "),
      total,
    };
  }, [examDetailChallengesSets]);

  const examName = examDetail.getName();

  return (
    <article className="code-exam-dashboard-outline">
      <div className="code-exam-dashboard-outline__header">
        <article className="code-exam-dashboard-outline__title">
          <h3 className="text-heading3" title={examName}>
            {examName}
          </h3>
          <p className="code-exam-dashboard-outline__sub-title text-sm">
            <Msg id="common.createdBy" />
            :&nbsp;{creatorName},&nbsp;
            {createdDateFormat}
          </p>
        </article>
        <Link to="outline">{label.view.outline}</Link>
      </div>
      <div className="code-exam-dashboard-outline__exam">
        <ExamOutlineSection labelTo={label.view.challenge} to="challenges">
          <ExamOutlineSection.Header icon="cube">
            {label.challenge} ({challengeList.total})
          </ExamOutlineSection.Header>
          <article className="code-exam-dashboard-outline__content-row">
            <div
              className="code-exam-dashboard-outline__estimation"
              title={timeElapsed}
            >
              <Tooltip
                maxSize="medium"
                text={<Msg id="exam.dashboard.approximateTime.tooltip" />}
              >
                <Icon
                  type="clock"
                  variant="regular"
                  className="code-c-icon__text-base code-exam-dashboard-outline__icon"
                />
              </Tooltip>
              <span>
                <Msg id="editor-exam-detail.exam.requiredTimeApproximately" />
                {timeElapsed}
              </span>
            </div>
          </article>
          <article className="code-exam-dashboard-outline__content-row">
            <p className="code-exam-dashboard-outline__section-body code-exam-dashboard-outline__section-body__left-margin">
              {challengeList.total > 0 && (
                <span
                  className="code-exam-dashboard-outline__challenge-style"
                  title={challengeList.info}
                >
                  {challengeList.info}
                </span>
              )}
            </p>
          </article>
        </ExamOutlineSection>
        <ExamOutlineSection labelTo={label.view.reviewer} to="reviewers">
          <ExamOutlineSection.Header icon="comment-dots">
            {label.reviewers}
          </ExamOutlineSection.Header>
          <article className="code-exam-dashboard-outline__content-row">
            <p
              className="code-exam-dashboard-outline__reviewer-list"
              title={reviewerList}
            >
              {reviewerList}
            </p>
          </article>
        </ExamOutlineSection>
        <ExamOutlineSection
          labelTo={label.view.setting}
          to={isAccessExamSettingPage ? "settings" : undefined}
        >
          <ExamOutlineSection.Header icon="cogs">
            {label.settings}
          </ExamOutlineSection.Header>
          <article className="code-exam-dashboard-outline__content-row">
            <p className="code-exam-dashboard-outline__section-body">
              <Icon
                type="paper-plane"
                color={"neutral-300"}
                variant={"regular"}
                className="code-c-icon__text-base code-exam-dashboard-outline__icon"
              />
              {deliveryMethod}
            </p>
          </article>
          <article className="code-exam-dashboard-outline__content-row">
            <div className="code-exam-dashboard-outline__section-body code-exam-dashboard-outline__checkbox">
              <ExamOutlineSection.Checkbox
                checked={examDetail.guestSharingEnabled}
              >
                <Msg id="exam.urlSharing" />
              </ExamOutlineSection.Checkbox>
              <ExamOutlineSection.Checkbox
                checked={Boolean(examDetail.autoFilterSettings)}
              >
                <Msg id="exam.autoFilter" />
              </ExamOutlineSection.Checkbox>
            </div>
          </article>
        </ExamOutlineSection>
      </div>
    </article>
  );
}

export default ExamOutline;
