import * as classnames from "classnames";
import * as React from "react";

import {
  Modal,
  Msg,
  Label,
  Form,
  FormGroup,
  Checkbox,
  Select,
  Tooltip,
} from "@shared/components";
import {
  ExamListModel,
  ProjectSwitchItemModel,
  UserRoleModel,
} from "@shared/models";
import { ProjectRole, UserRole } from "@shared/services/enums";
import { getProjectsWithTargetRoles } from "@shared/services/member";
import Message from "@shared/services/message";

/**
 * Props
 */
export interface ExamCopyModalProps {
  exam: ExamListModel;
  defaultProject: ProjectSwitchItemModel;
  projects: ProjectSwitchItemModel[];
  canCopyReviewers?: boolean;
  userRoles: UserRoleModel[];
  onCopy: (options: ExamCopyModalInput) => void;
  onCancel: () => void;
}

export type ExamCopyModalInput = {
  copyReviewer: boolean;
  projectId: number;
};

export const ExamCopyModal: React.FunctionComponent<ExamCopyModalProps> = (
  props: ExamCopyModalProps,
) => {
  const {
    exam,
    canCopyReviewers = true,
    projects,
    userRoles,
    defaultProject,
  } = props;

  /**
   * State
   */
  const [form, setForm] = React.useState<{
    formErrors?: {};
    formValues: ExamCopyModalInput;
    formValid: boolean;
  }>({
    formValues: {
      copyReviewer: true,
      projectId: defaultProject.id,
    },
    formValid: true,
  });

  const isDefaultProjectChanged = defaultProject
    ? defaultProject.id !== form.formValues.projectId
    : false;

  /**
   * Private Functions
   */
  const rootStyle = classnames("code-exam-copy-modal");

  const onCopy = () => {
    if (typeof props.onCopy === "function") {
      props.onCopy(form.formValues);
    }
  };

  const onFormChange = (
    formValid: boolean,
    formValues: ExamCopyModalInput,
    formErrors?: {},
  ) => {
    const newProjectId = formValues.projectId
      ? Number(formValues.projectId)
      : formValues.projectId;

    const newFormValues: ExamCopyModalInput = {
      projectId: newProjectId,
      copyReviewer:
        defaultProject && newProjectId !== defaultProject.id
          ? false
          : formValues.copyReviewer,
    };
    setForm({ formErrors, formValid, formValues: newFormValues });
  };

  const projectsWithCopyExamPermissions = React.useMemo(() => {
    const isSystemAdmin = userRoles.some(
      (role) => role.role === UserRole.SystemAdmin,
    );

    if (isSystemAdmin) {
      return projects;
    }

    return getProjectsWithTargetRoles({
      userRoles,
      projects,
      targetRoles: [ProjectRole.ProjectAdmin, ProjectRole.ExamCreator],
    });
  }, [projects, userRoles]);

  /**
   * Render
   */
  return (
    <Modal
      title={Message.getMessageByKey("confirmCopy")}
      isOpen={true}
      onClose={props.onCancel}
      onClickOk={onCopy}
      onClickCancel={props.onCancel}
      okButtonLabel={Message.getMessageByKey("copy")}
      okButtonAriaLabel="Copy"
      ariaLabel="Copy Exam"
      className={rootStyle}
    >
      <div className="code-exam-copy-modal__modal-body">
        <p>
          <Msg id="confirmCopy.message" />
        </p>
        <Form
          validation={{
            copyReviewer: ["boolean"],
            projectId: ["number"],
          }}
          initialValues={{
            copyReviewer: form.formValues.copyReviewer,
            projectId: form.formValues.projectId,
          }}
          onFormChange={onFormChange}
        >
          <FormGroup>
            <Label>
              <Msg id="form.exam.name" />
            </Label>
            <div className="is-break-word is-bold">{exam.name}</div>
          </FormGroup>
          {projectsWithCopyExamPermissions.length > 1 && (
            <FormGroup>
              <Label>
                <Msg id="project.copyExam.targetProject" />
              </Label>
              <Select
                name="projectId"
                innerClassName="code-exam-copy-modal__select"
                options={projectsWithCopyExamPermissions.map((project) => ({
                  value: project.id,
                  label: project.name,
                }))}
              />
            </FormGroup>
          )}
          {canCopyReviewers && (
            <FormGroup>
              <Label>
                <Tooltip
                  disabled={!isDefaultProjectChanged}
                  placement="top-start"
                  text={Message.getMessageByKey(
                    "exam.copyExam.reviewer.disabled",
                  )}
                >
                  <Checkbox
                    name="copyReviewer"
                    readOnly={isDefaultProjectChanged}
                  >
                    <Msg id="exam.copyExam.reviewer" />
                  </Checkbox>
                </Tooltip>
              </Label>
            </FormGroup>
          )}
        </Form>
      </div>
    </Modal>
  );
};
