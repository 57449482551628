import * as classnames from "classnames";
import { isEqual } from "lodash";
import * as React from "react";
import { Prompt } from "react-router";

import {
  Banner,
  Form,
  Label,
  Msg,
  Popup,
  Textarea,
  Tooltip,
} from "@shared/components";
import ReviewScoreIcon from "@shared/components/icon/reviewScoreIcon/ReviewScoreIcon";
import {
  useCreateSubmissionsReview,
  useGetSubmissionsReviewers,
  useUpdateSubmissionsReview,
} from "@shared/hooks/query";
import { UserModel } from "@shared/models";
import { ReviewScore } from "@shared/services/enums";
import MSG from "@shared/services/message";

import { getCurrentUserReview } from "../submissionDetail/partials/SubmissionDetailUtils";

type CommentInput = {
  score?: number;
  feedback: string;
};

export function SubmissionReviewPopup({
  currentUser,
  notAllowed,
  className,
  onClickCancel,
  onClickSave,
}: {
  currentUser: UserModel;
  notAllowed?: boolean;
  className?: string;
  onClickCancel: () => void;
  onClickSave: () => void;
}) {
  const {
    data: { reviews },
  } = useGetSubmissionsReviewers();
  const updateSubmissionsReview = useUpdateSubmissionsReview();
  const createSubmissionsReview = useCreateSubmissionsReview();
  const reviewedComment = getCurrentUserReview(reviews, currentUser);
  const initialValues = {
    score: reviewedComment?.score,
    feedback: reviewedComment?.feedback || "",
  };

  /**
   * State
   */
  const [form, setForm] = React.useState<{
    formValid: boolean;
    formValues: CommentInput;
    formErrors?: {};
  }>({
    formValid: false,
    formValues: initialValues,
  });

  const { score, feedback } = form.formValues;

  const onFormChange = (
    formValid: boolean,
    formValues: CommentInput,
    formErrors?: {} | boolean,
  ) => {
    setForm({
      // for stale review, allow users to save without any modification
      formValid: reviewedComment?.isStale
        ? formValid || !formErrors
        : formValid,
      formValues,
      formErrors,
    });
  };

  const onSave = () => {
    if (score !== undefined) {
      const data = {
        score,
        feedback: feedback.trim(),
      };

      if (reviewedComment?.id) {
        updateSubmissionsReview.mutate({
          reviewerId: reviewedComment.id,
          data,
        });
      } else {
        createSubmissionsReview.mutate(data);
      }
    }

    onClickSave();
  };

  const checkShouldCancel = () =>
    isEqual(form.formValues, initialValues) ||
    confirm(MSG.getMessageByKey("message.confirm.cancel"));

  const rootStyle = classnames("code-submission-review-popup", {
    [`${className}`]: Boolean(className),
  });

  /**
   * Component
   */
  const ScoreIcon = ({
    defaultValue,
    disabled,
  }: {
    defaultValue: ReviewScore;
    disabled?: boolean;
  }) => {
    const checked = defaultValue === score;
    const scoreIconStyle = classnames("score-button", {
      ["is-strongly-approved"]: defaultValue === ReviewScore.StronglyApprove,
      ["is-approved"]: defaultValue === ReviewScore.Approve,
      ["is-rejected"]: defaultValue === ReviewScore.Reject,
      ["is-strongly-rejected"]: defaultValue === ReviewScore.StronglyReject,
      [`is-checked`]: checked,
      [`is-disabled`]: disabled,
    });

    return (
      <div
        className={scoreIconStyle}
        role="radio"
        aria-label={ReviewScore[defaultValue]}
        aria-checked={checked}
        onMouseDown={() => {
          if (!disabled) {
            setForm({
              ...form,
              formValues: { ...form.formValues, score: defaultValue },
            });
          }
        }}
      >
        <ReviewScoreIcon score={defaultValue} />
        <div className="score-text">{ReviewScore.toString(defaultValue)}</div>
      </div>
    );
  };

  return (
    <Popup className={rootStyle}>
      <Prompt
        when={!isEqual(form.formValues, initialValues)}
        message={MSG.getMessageByKey("navigation.confirm")}
      />
      <Popup.Header>
        <p>
          <Msg id="submission.yourReview" />
        </p>
      </Popup.Header>
      <Popup.CollapsibleContent>
        <Popup.Body>
          {reviewedComment?.isStale && (
            <Banner type="warning">
              <Msg id="reviewScore.outdated.note" />
            </Banner>
          )}
          <div className="score-container">
            {[
              ReviewScore.StronglyReject,
              ReviewScore.Reject,
              ReviewScore.Approve,
              ReviewScore.StronglyApprove,
            ].map((defaultValue) => (
              <Tooltip
                key={defaultValue}
                text={<Msg id="tier.disabled" />}
                disabled={!notAllowed}
              >
                <ScoreIcon disabled={notAllowed} defaultValue={defaultValue} />
              </Tooltip>
            ))}
          </div>
          <Form
            validation={{
              score: ["number", ["min", 0], "required"],
              feedback: ["string"],
            }}
            initialValues={initialValues}
            updateValues={{ score }}
            onFormChange={onFormChange}
          >
            <Label htmlFor="feedback">
              <Msg id="common.comment" />
            </Label>
            <Tooltip text={<Msg id="tier.disabled" />} disabled={!notAllowed}>
              <Textarea disabled={notAllowed} name="feedback" id="feedback" />
            </Tooltip>
          </Form>
        </Popup.Body>
        <Popup.Footer>
          <Popup.ActionButton
            preCheckAction={checkShouldCancel}
            shouldClosePopup={true}
            onClick={onClickCancel}
            ariaLabel="Cancel"
          >
            <Msg id="cancel" />
          </Popup.ActionButton>
          <Popup.ActionButton
            type="primary"
            shouldClosePopup={true}
            onClick={onSave}
            ariaLabel="Submit"
            disabled={notAllowed || !form.formValid}
          >
            <Msg id="action.submit" />
          </Popup.ActionButton>
        </Popup.Footer>
      </Popup.CollapsibleContent>
    </Popup>
  );
}
