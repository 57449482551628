import { Action, AjaxAction, ajaxAction, AjaxActionOptions } from "../actions";

export interface AjaxState {
  submitting: number;
  success: boolean;
}

const initialState = {
  submitting: 0,
  success: false,
};

function getAjaxOptions(action: AjaxAction) {
  return (
    ((action as AjaxAction).payload &&
      (action as AjaxAction).payload.options) ||
    ({} as AjaxActionOptions)
  );
}

export const ajaxReducer = (
  state: AjaxState = initialState,
  action: Action,
): AjaxState => {
  switch (action.type) {
    case ajaxAction.types.request: {
      const { background } = getAjaxOptions(action as AjaxAction);
      return {
        ...state,
        success: false,
        submitting: state.submitting + (background ? 0 : 1),
      };
    }
    case ajaxAction.types.success: {
      const { background } = getAjaxOptions(action as AjaxAction);
      return {
        ...state,
        success: true,
        submitting: Math.max(state.submitting - (background ? 0 : 1), 0),
      };
    }
    case ajaxAction.types.failure: {
      const { background } = getAjaxOptions(action as AjaxAction);
      return {
        ...state,
        success: false,
        submitting: Math.max(state.submitting - (background ? 0 : 1), 0),
      };
    }

    default:
      return state;
  }
};
