import * as classnames from "classnames";

import { Tag } from "../..";
import MSG from "../../../services/message";

/**
 * Prop interface
 */
export interface LinkedTagProps {
  className?: string;
  onClick?: () => void;
}
/**
 * React Component
 */
export function LinkedTag({ className, onClick }: LinkedTagProps) {
  const rootStyle = classnames("code-c-linked-tag", {
    [`${className}`]: Boolean(className),
  });

  return (
    <Tag className={rootStyle} onClick={onClick}>
      {MSG.getMessageByKey("challenge.linked")}
    </Tag>
  );
}
