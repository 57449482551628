import { Epic } from "redux-observable";

import {
  Action,
  alertAction,
  redirectAction,
  GlobalTypeSet,
  globalTypeSetAction,
} from "../actions";
import { RootState } from "../reducers";
import { OrganizationAppType } from "../shared/services/enums";
import Message from "../shared/services/message";

const globalTypeGetEpic: Epic<Action, RootState> = (action$, state) =>
  action$
    .ofType(globalTypeSetAction.types.request)
    .filter((action) => (action.payload as GlobalTypeSet).forceSwitch)
    .map((action) => {
      const payload = action.payload as GlobalTypeSet;
      return redirectAction(
        "/",
        alertAction.info(
          Message.getMessageByKey(
            payload.appType === OrganizationAppType.Admin
              ? "message.switchApplication.admin"
              : "message.switchApplication.individual",
          ),
        ),
      )({});
    });

export default [globalTypeGetEpic];
