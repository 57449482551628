import { Msg, Tag } from "@shared/components";
import { useExamJobTitleMap, usePurposeOfUseMap } from "@shared/hooks/query";
import { ExamTypeEnums } from "@shared/services/enums";

export type TagKinds =
  | "official"
  | "multilanguage"
  | "purposeOfUse"
  | "jobTitle";

interface TagOption<T> {
  key: string;
  label: string;
  value: T;
}

export interface ExamListFilterTagsProps {
  official?: boolean;
  isMultilanguage?: boolean;
  purposesOfUse?: string[];
  jobTitles?: number[];
  onClearAll?: () => void;
  onFilterChange: (type: TagKinds, value?: number | string) => void;
}

export default function ExamListFilterTags({
  official,
  isMultilanguage,
  purposesOfUse,
  jobTitles,
  onClearAll,
  onFilterChange,
}: ExamListFilterTagsProps) {
  const { data: purposeOfUseOptions } = usePurposeOfUseMap();
  const { data: jobTitleOptions } = useExamJobTitleMap();
  if (
    !official &&
    !isMultilanguage &&
    !purposesOfUse?.length &&
    !jobTitles?.length
  ) {
    return null;
  }

  const purposeOfUseTags = purposesOfUse?.reduce((acum, value) => {
    if (purposeOfUseOptions?.[value]) {
      acum.push({
        key: `pou-${value}`,
        label: purposeOfUseOptions[value],
        value,
      });
    }

    return acum;
  }, [] as TagOption<string>[]);

  const jobTitleTags = jobTitles?.reduce((acum, value) => {
    if (jobTitleOptions?.[value]) {
      acum.push({
        key: `jt-${value}`,
        label: jobTitleOptions[value],
        value,
      });
    }

    return acum;
  }, [] as TagOption<number>[]);

  const handleClickDeleteExamTypeTags = (type: ExamTypeEnums) => () => {
    onFilterChange(
      type === ExamTypeEnums.official ? "official" : "multilanguage",
    );
  };

  const handleClickDeletePurposeOfUseTag = (value: string) => () => {
    onFilterChange("purposeOfUse", value);
  };

  const handleClickDeleteJobTitleTag = (value: number) => () => {
    onFilterChange("jobTitle", value);
  };

  return (
    <div className="code-exam-list__badges-filter">
      <a onClick={onClearAll} role="button">
        <Msg id="clear.all" />
      </a>
      &#124;
      {official && (
        <Tag
          hasDelete
          onClick={handleClickDeleteExamTypeTags(ExamTypeEnums.official)}
        >
          <Msg id="exam.officialExam" />
        </Tag>
      )}
      {isMultilanguage && (
        <Tag
          hasDelete
          onClick={handleClickDeleteExamTypeTags(ExamTypeEnums.multilanguage)}
        >
          <Msg id="exam.multilanguage" />
        </Tag>
      )}
      {purposeOfUseTags?.map(({ key, label, value }) => (
        <Tag
          hasDelete
          key={key}
          onClick={handleClickDeletePurposeOfUseTag(value)}
        >
          {label}
        </Tag>
      ))}
      {jobTitleTags?.map(({ key, label, value }) => (
        <Tag hasDelete key={key} onClick={handleClickDeleteJobTitleTag(value)}>
          {label}
        </Tag>
      ))}
    </div>
  );
}
