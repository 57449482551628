import { connect } from "react-redux";

import { RootState } from "../../../reducers";
import DocumentTitle from "./DocumentTitle";

export interface OwnProps {
  page: string;
}

const mapStateToProps = (state: RootState, props: OwnProps) => ({
  ...props,
  orgname: state.user.user
    ? state.user.user.organization.displayName ||
      state.user.user.organization.name
    : "",
});

/**
 * Wrapped component
 */
const wrappedComponent = connect(mapStateToProps, () => ({}))(DocumentTitle);

export default wrappedComponent;
