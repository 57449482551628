import { isValidProgrammingLanguage } from "@shared/services/challengeCollection";

import {
  Action,
  APIResponseAction,
  challengeUpdateAction,
  challengeCopyAction,
  challengeGetAction,
  challengeInsightGetAction,
  challengeListGetAction,
  challengeFiltersGetAction,
  challengeCurrentVersionSetAction,
  challengeReleaseNoteGetAction,
  challengeMajorUpdateAction,
  challengeCollectionListGetAction,
  challengeCollectionGetAction,
  currentProjectSetAction,
  pinnedChallengeUpdateAction,
  pinnedChallengeListUpdateAction,
} from "../actions";
import {
  ChallengeModel,
  ChallengeFilterModel,
  ChallengeInsightModel,
  PaginationModel,
  EnumModel,
  ChallengeReleaseNoteModel,
  PinnedChallengeModel,
  ChallengeCollectionListModel,
  ChallengeCollectionModel,
} from "../shared/models";

export interface ChallengeState {
  challengeDetailList: Array<ChallengeModel>;
  challengeFilters: ChallengeFilterModel;
  challengeReleaseNote?: ChallengeReleaseNoteModel;
  challengeInsightDetail: ChallengeInsightModel;
  challengeList: Array<ChallengeModel>;
  challengeListCondition: {};
  challengeListPagination: PaginationModel;
  pinnedChallengeList: PinnedChallengeModel[];
  copyChallengeError: boolean;
  copyChallengeLoading: boolean;
  errorCreate: boolean;
  errorDetail: boolean;
  errorMajorUpdate?: boolean;
  loadingCreate: boolean;
  loadingDetail: boolean;
  loadingInsight: boolean;
  loadingList: boolean;
  loadingReleaseNote: boolean;
  loadingMajorUpdate: boolean;

  challengeCollectionList: ChallengeCollectionListModel[];
  challengeCollectionDetails: ChallengeCollectionModel[];
  challengeCollectionLoading: boolean;
  challengeCollectionError: boolean;
}

export const initialState = {
  challengeDetailList: [],
  challengeFilters: {
    programmingCategories: [],
    programmingLanguages: [],
    difficulties: [],
    styles: [],
  },
  challengeInsightDetail: new ChallengeInsightModel(),
  challengeList: [],
  challengeListCondition: {},
  challengeListForLinkedChallenge: [],
  challengeListPagination: new PaginationModel(),
  pinnedChallengeList: [],
  copyChallengeError: false,
  copyChallengeLoading: false,
  errorCreate: false,
  errorDetail: false,
  loadingCreate: false,
  loadingDetail: false,
  loadingInsight: false,
  loadingList: false,
  loadingReleaseNote: false,
  loadingMajorUpdate: false,

  challengeCollectionList: [],
  challengeCollectionDetails: [],
  challengeCollectionLoading: false,
  challengeCollectionError: false,
};

// selector to get programming language and category names from ids
export const challengeListSelector = (state: ChallengeState) => {
  const {
    challengeList,
    challengeFilters: { programmingLanguages, programmingCategories },
  } = state;

  const mappedChallengeList = challengeList.map((challenge) => ({
    ...challenge,
    programmingLanguages: challenge.programmingLanguages.map(
      (id) =>
        (
          programmingLanguages.find((language) => language.value === id) || {
            displayString: "",
          }
        ).displayString,
    ),
    programmingCategories: challenge.programmingCategories.map(
      (id) =>
        (
          programmingCategories.find((category) => category.value === id) || {
            displayString: "",
          }
        ).displayString,
    ),
  }));

  return mappedChallengeList;
};

// selector for category list
export const categorySelector = (state: ChallengeState) => {
  const {
    challengeFilters: { programmingCategories },
  } = state;

  return programmingCategories.reduce(
    (acc, val) => ({ ...acc, [val.value]: val.displayString }),
    {},
  );
};

export const challengeReducer = (
  state: ChallengeState = initialState,
  action: Action,
) => {
  const payload = (action as APIResponseAction).payload;

  switch (action.type) {
    case challengeGetAction.types.request: {
      const challengeDetailList = state.challengeDetailList.filter(
        (item) => item.id !== (action.payload as number),
      );

      return {
        ...state,
        challengeDetailList,
        loadingDetail: true,
        loadingCreate: false,
        errorDetail: false,
      };
    }

    case challengeGetAction.types.success: {
      const challengeDetail = new ChallengeModel(payload.result);
      let challengeDetailList = [] as ChallengeModel[];

      const index = state.challengeDetailList.findIndex(
        (item) => item.id === challengeDetail.id,
      );
      if (index === -1) {
        challengeDetailList = [...state.challengeDetailList, challengeDetail];
      } else {
        challengeDetailList = [
          ...state.challengeDetailList.slice(0, index),
          challengeDetail,
          ...state.challengeDetailList.slice(index + 1),
        ];
      }

      return {
        ...state,
        challengeDetailList,
        loadingDetail: false,
        errorDetail: false,
      };
    }

    case challengeGetAction.types.failure: {
      return {
        ...state,
        loadingDetail: false,
        errorDetail: true,
      };
    }

    case challengeListGetAction.types.request:
      return {
        ...state,
        challengeListCondition: action.payload,
        loadingList: true,
      };

    case challengeListGetAction.types.success:
      return {
        ...state,
        challengeList: ((payload.result as Array<{}>) || []).map(
          (challenge: {}) =>
            new ChallengeModel(
              Object.assign({}, challenge, {
                id: (challenge as { id: number }).id,
              }),
            ),
        ),
        challengeListPagination: new PaginationModel(payload.pagination),
        loadingList: false,
      };

    case challengeInsightGetAction.types.request:
      return { ...state, loadingInsight: true };
    case challengeInsightGetAction.types.success:
      return {
        ...state,
        challengeInsightDetail: new ChallengeInsightModel(payload.result),
        loadingInsight: false,
      };
    case challengeInsightGetAction.types.failure:
      return { ...state, loadingInsight: false };

    case challengeFiltersGetAction.types.success: {
      const key = payload.extra as string;
      return {
        ...state,
        challengeFilters: {
          ...state.challengeFilters,
          // NOTE Python should be removed from programmingLanguages
          [key]: Array.isArray(payload.result)
            ? key === "programmingLanguages"
              ? (payload.result as EnumModel[])
                  .filter((item) => isValidProgrammingLanguage(item.value))
                  .sort((a, b) => a.displayOrder - b.displayOrder)
              : key === "programmingCategories"
              ? (payload.result as EnumModel[]).sort(
                  (a, b) => a.displayOrder - b.displayOrder,
                )
              : payload.result
            : [],
        },
      };
    }

    case challengeUpdateAction.types.request:
      return { ...state, loadingCreate: true, errorCreate: false };

    case challengeUpdateAction.types.success: {
      return { ...state, loadingCreate: false, errorCreate: false };
    }

    case challengeUpdateAction.types.failure:
      return { ...state, loadingCreate: false, errorCreate: true };

    case challengeCurrentVersionSetAction.types.request: {
      const challengeDetail = payload.result as { challengeId: number };
      const currentChallenge =
        state.challengeDetailList.find(
          (item) => item.id === challengeDetail.challengeId,
        ) || new ChallengeModel();
      const index = state.challengeDetailList.findIndex(
        (item) => item.id === challengeDetail.challengeId,
      );

      const challenge = new ChallengeModel(
        Object.assign({}, currentChallenge, {
          ...challengeDetail,
          id: challengeDetail.challengeId,
          currentVersion: Object.assign(
            {},
            currentChallenge.currentVersion,
            challengeDetail,
          ),
        }),
      );
      let challengeDetailList = [] as ChallengeModel[];
      if (index === -1) {
        challengeDetailList = [...state.challengeDetailList, challenge];
      } else {
        challengeDetailList = [
          ...state.challengeDetailList.slice(0, index),
          challenge,
          ...state.challengeDetailList.slice(index + 1),
        ];
      }

      return { ...state, challengeDetailList };
    }

    case challengeCopyAction.types.request:
      return {
        ...state,
        copyChallengeLoading: true,
        copyChallengeError: false,
      };

    case challengeCopyAction.types.failure:
      return {
        ...state,
        copyChallengeLoading: false,
        copyChallengeError: true,
      };

    case challengeCopyAction.types.success: {
      return {
        ...state,
        copyChallengeLoading: false,
        copyChallengeError: false,
      };
    }

    case challengeReleaseNoteGetAction.types.request: {
      return {
        ...state,
        loadingReleaseNote: true,
        challengeReleaseNote: undefined,
      };
    }

    case challengeReleaseNoteGetAction.types.success: {
      return {
        ...state,
        loadingReleaseNote: false,
        challengeReleaseNote: payload.result,
      };
    }

    case challengeReleaseNoteGetAction.types.failure: {
      return {
        ...state,
        loadingReleaseNote: false,
        challengeReleaseNote: undefined,
      };
    }

    case challengeMajorUpdateAction.types.request: {
      return {
        ...state,
        errorMajorUpdate: false,
        loadingMajorUpdate: true,
      };
    }

    case challengeMajorUpdateAction.types.success: {
      return {
        ...state,
        errorMajorUpdate: false,
        loadingMajorUpdate: false,
      };
    }

    case challengeMajorUpdateAction.types.failure: {
      return {
        ...state,
        errorMajorUpdate: true,
        loadingMajorUpdate: false,
      };
    }

    case pinnedChallengeListUpdateAction.types.request: {
      const pinnedChallengeList = action.payload as PinnedChallengeModel;

      return {
        ...state,
        pinnedChallengeList: pinnedChallengeList ?? [],
      };
    }

    case pinnedChallengeUpdateAction.types.request: {
      const { pinnedChallengeList } = state;
      const pinnedChallenge = action.payload as PinnedChallengeModel;
      const { isDelete } = action.params as { isDelete: boolean };

      const newList = pinnedChallengeList.filter(
        (item) => item.challengeId !== pinnedChallenge.challengeId,
      );

      if (!isDelete) {
        newList.push(pinnedChallenge);
      }

      return {
        ...state,
        pinnedChallengeList: newList,
      };
    }

    case challengeCollectionGetAction.types.request: {
      return {
        ...state,
        challengeCollectionError: false,
        challengeCollectionLoading: true,
      };
    }

    case challengeCollectionGetAction.types.success: {
      const collection = payload.result as ChallengeCollectionModel;
      const index = state.challengeCollectionDetails.findIndex(
        (item) => item.id === collection.id,
      );
      return {
        ...state,
        challengeCollectionError: false,
        challengeCollectionLoading: false,
        challengeCollectionDetails:
          index === -1
            ? [...state.challengeCollectionDetails, collection]
            : [
                ...state.challengeCollectionDetails.slice(0, index),
                collection,
                ...state.challengeCollectionDetails.slice(index + 1),
              ],
      };
    }

    case challengeCollectionListGetAction.types.request: {
      return {
        ...state,
        challengeCollectionError: false,
        challengeCollectionLoading: true,
      };
    }

    case challengeCollectionListGetAction.types.success: {
      // TODO strong types
      const { result = [] } = (
        action as APIResponseAction & {
          payload: {
            result: ChallengeCollectionListModel[];
            pagination: PaginationModel;
          };
        }
      ).payload;
      return {
        ...state,
        challengeCollectionList: result,
        challengeCollectionError: false,
        challengeCollectionLoading: false,
      };
    }

    case challengeCollectionListGetAction.types.failure:
    case challengeCollectionGetAction.types.failure: {
      return {
        ...state,
        challengeCollectionError: true,
        challengeCollectionLoading: false,
      };
    }

    // clean up store data right after project switch
    case currentProjectSetAction.types.request: {
      const { challengeFilters } = state;
      return { ...initialState, challengeFilters };
    }

    default:
      return state;
  }
};
