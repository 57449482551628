import { Epic } from "redux-observable";

import {
  Action,
  ajaxAction,
  redirectAction,
  authSignupAction,
  authInvitationAction,
  authSetAction,
  authSignOutAction,
  authLocalSigninAction,
} from "@actions";

import { RootState } from "@reducers";

import { UserModel } from "@shared/models";
import { GlobalAppType } from "@shared/services/enums";
import { getDefaultSignInPathname, getSignInURL } from "@shared/services/url";

const authSignupEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(authSignupAction.types.request).map((action) =>
    ajaxAction.request({
      method: "post",
      url: "/api/auth/signup",
      body: action.payload,
      success: (payload: { result: UserModel }) => {
        const { result: user } = payload;
        window.location.href = getSignInURL(user.organization.name, {
          force: true,
          prefillEmail: user.email,
        });
        return authSignupAction.success();
      },
      error: authSetAction.failure,
    }),
  );

const authInvitationGetEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(authInvitationAction.types.request).map((action) =>
    ajaxAction.request({
      method: "get",
      url: `/api/auth/invites/${action.payload}`,
      success: authInvitationAction.success,
      error: authInvitationAction.failure,
      options: {
        disableDefaultError: true,
      },
    }),
  );

const authSignOutEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(authSignOutAction.types.request).map((action) =>
    ajaxAction.request({
      method: "delete",
      url: "/api/auth/session",
      body: action.payload,
      success: redirectAction(
        getDefaultSignInPathname(
          window.location.hostname,
          state.getState().global.globalAppType === GlobalAppType.Admin,
        ),
        authSignOutAction.success,
        undefined,
        true,
      ),
      error: authSignOutAction.failure,
      options: {
        disableDefaultError: true,
      },
    }),
  );

const authLocalSigninEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(authLocalSigninAction.types.request).map((action) =>
    ajaxAction.request({
      method: "post",
      url: "/api/auth/signin",
      body: action.payload,
      success: redirectAction(
        "/",
        authLocalSigninAction.success,
        undefined,
        true,
      ),
      error: authLocalSigninAction.failure,
    }),
  );

export default [
  authSignupEpic,
  authInvitationGetEpic,
  authSignOutEpic,
  authLocalSigninEpic,
];
