import * as classnames from "classnames";

import { ChallengeDetailBox } from "..";
import { Msg, Spinner } from "../../../../../../shared/components";
import { ChallengeReleaseNoteModel } from "../../../../../../shared/models";
import { ReleaseNote } from "../../../challengeSections";

/**
 * Prop interface
 */
export interface ChallengeReleasesProps {
  releaseNote?: ChallengeReleaseNoteModel;
  loading: boolean;
}

/**
 * React Component
 */
export function ChallengeReleases({
  releaseNote,
  loading,
}: ChallengeReleasesProps) {
  const rootStyle = classnames("code-challenge-releases");
  const spinner = (
    <div className="code-challenge-releases__loading">
      <Spinner />
    </div>
  );
  return (
    <div className={rootStyle}>
      <ChallengeDetailBox title={<Msg id="challenge.releaseHistory" />}>
        {loading ? spinner : <ReleaseNote releaseNote={releaseNote} />}
      </ChallengeDetailBox>
    </div>
  );
}
