import * as classnames from "classnames";

/**
 * Prop interface
 */
export interface BlockItemProps {
  direction?: string;
  className?: string;
  children?: {};
}

/**
 * React Component
 */
export default function BlockItem({
  direction,
  className,
  children,
}: BlockItemProps) {
  const rootStyle = classnames("code-c-block-item", "level-item", {
    [`is-${direction}`]: Boolean(direction),
    [`${className}`]: Boolean(className),
  });

  return <div className={rootStyle}>{children}</div>;
}
