import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { isNumber } from "lodash";
import { useCallback, useEffect } from "react";
import { toast } from "react-toastify";

import {
  ExamCounts,
  ExamDetailPayload,
  deleteExam,
  getExamCount,
  getExamDetail,
  getExamList,
  postExam,
  putExam,
} from "@api/exams";
import { BaseSearchQuery, Pagination } from "@api/httpClient";

import { useStoreContext } from "@context";

import { useDebounce } from "@shared/hooks";
import {
  CustomFormDefinitionModel,
  DEFAULT_PAGINATION_LIMIT,
  ExamChallengeModel,
  ExamChallengeSetModel,
  ExamListModel,
  ExamModel,
  MemberListModel,
  PaginationModel,
  UrlSharingConfigModel,
} from "@shared/models";
import { getLastWeekTimestamp } from "@shared/services/date";
import { ExamDeliveryKind, InvitationStatus } from "@shared/services/enums";
import history from "@shared/services/history";
import { getDisplayInvitationStatus } from "@shared/services/member";
import Message from "@shared/services/message";
import { sortByDisplayOrderAsc } from "@shared/services/sorting";

import {
  useExamJobTitleMap,
  useInvitationStatus,
  usePurposeOfUseMap,
} from "../useEnum";
import { useRouteParams } from "../useRouteParams";

interface ExamListFilter extends BaseSearchQuery {
  archived?: boolean;
  official?: boolean;
  isMultilanguage?: boolean;
  purposesOfUse?: string[];
  engineerRoles?: number[];
}

interface ExamList {
  examList: ExamListModel[];
  examListPagination: PaginationModel;
}

interface UseExamListParams {
  projectId?: number;
  filters: ExamListFilter;
}
export interface ExamDetailQueryResult {
  deliveryPath?: string;
  examDetail: ExamModel;
  examChallenges: ExamChallengeModel[];
  examChallengesSets: ExamChallengeSetModel[];
  examForms: CustomFormDefinitionModel[];
  examReviewers: MemberListModel[];
  examSettings: UrlSharingConfigModel;
}

interface UpdateExamParams {
  examId: number;
  data: ExamDetailPayload;
  hideToast?: boolean;
}

export const examKeys = {
  all: ["exams"] as const,
  listAll: (projectId: number) => [...examKeys.all, projectId] as const,
  list: (projectId: number, filters: ExamListFilter) =>
    [...examKeys.listAll(projectId), { filters }] as const,
  detailAll: (projectId: number) =>
    [...examKeys.listAll(projectId), "detail"] as const,
  detail: (projectId: number, examId: number) =>
    [...examKeys.detailAll(projectId), examId] as const,
  countAll: () => [...examKeys.all, "count"] as const,
  count: (projectId: number, examId: number) =>
    [...examKeys.countAll(), projectId, examId] as const,
};

export function transformExamDetail(
  data: ExamModel,
  invitationStatus?: Record<InvitationStatus, string>,
) {
  const examDetail = new ExamModel({
    ...data,
    counts: {
      sentDeliveryCount: 0,
      archivedCount: 0,
      ...data.counts,
    },
  });
  // sort then transform challengeSet to challenge model
  const examChallenges = examDetail.getChallenges();
  // sort the same challengeSet as previous
  const examChallengesSets = examDetail.challengesSets.sort(
    sortByDisplayOrderAsc,
  );
  const examForms = examDetail.formDefinitions
    ?.map((form) => new CustomFormDefinitionModel(form))
    ?.sort(sortByDisplayOrderAsc);
  const examReviewers =
    examDetail.reviewers?.map(
      (reviewer) =>
        new MemberListModel({
          ...getDisplayInvitationStatus({ invitationStatus, reviewer }),
        }),
    ) || [];
  const examSettings = new UrlSharingConfigModel(examDetail.urlSharingConfig);
  const deliveryPath = examDetail.deliveryKind
    ? examDetail.deliveryKind === ExamDeliveryKind.ID
      ? "id"
      : "email"
    : undefined;

  return {
    deliveryPath,
    examDetail,
    examChallenges,
    examChallengesSets,
    examForms,
    examReviewers,
    examSettings,
  } as ExamDetailQueryResult;
}

export function useExamList({ projectId, filters }: UseExamListParams) {
  const { projectId: storeProjectId } = useStoreContext();
  const { data: purposesOfUse, isFetched: isPurposeOfUseFetched } =
    usePurposeOfUseMap();
  const { data: jobTitle, isFetched: isJobTitleFetched } = useExamJobTitleMap();
  const debounceKeyword = useDebounce(filters.keyword, 275);
  const debounceFilter = {
    ...filters,
    keyword: debounceKeyword,
    official: filters.official || undefined,
    isMultilanguage: filters.isMultilanguage || undefined,
    engineerRoles: filters.engineerRoles?.filter((value) => jobTitle?.[value]),
    purposesOfUse: filters.purposesOfUse?.filter(
      (value) => purposesOfUse?.[value],
    ),
  };
  const localProjectId = projectId || storeProjectId;
  const enabled =
    !!localProjectId || isPurposeOfUseFetched || isJobTitleFetched;

  const query = useQuery({
    queryKey: examKeys.list(localProjectId, debounceFilter),
    queryFn: async ({ signal }) => {
      const params = {
        limit: DEFAULT_PAGINATION_LIMIT,
        offset: 0,
        ...debounceFilter,
      };
      const result = await getExamList({
        projectId: localProjectId,
        options: { params, signal },
      });

      return result;
    },
    enabled,
    keepPreviousData: true,
    select: useCallback((data: Pagination<ExamListModel[]>) => {
      const normalizedResult = data.result.map(
        (exam) => new ExamListModel(exam),
      );

      return {
        examList: normalizedResult,
        examListPagination: new PaginationModel(data.pagination),
      } as ExamList;
    }, []),
  });

  useEffect(() => {
    // short term solution, 404 redirect should be handled by the global config
    if (query.isError) {
      history.replace("/404");
    }
  }, [query.isError]);

  return query;
}

export function useExam(examId?: number) {
  const { projectId, postExamDetailGet, resetQuestionInsight } =
    useStoreContext();
  const { examId: routeExamId } = useRouteParams();
  const invitationStatus = useInvitationStatus();
  const localExamId = examId || routeExamId;
  const enabled = isNumber(localExamId);

  const query = useQuery({
    queryKey: examKeys.detail(projectId, localExamId),
    queryFn: async ({ signal }) => {
      const { result } = await getExamDetail({
        examId: localExamId,
        projectId,
        options: { signal },
      });

      return result;
    },
    enabled,
    initialData: new ExamModel({ reviewers: [] }),
    initialDataUpdatedAt: getLastWeekTimestamp(),
    meta: { skipErrorToast: true },
    select: useCallback(
      (data) => transformExamDetail(data, invitationStatus),
      [invitationStatus],
    ),
  });

  useEffect(() => {
    // short term solution, 404 redirect should be handled by the global config
    if (query.isError) {
      history.replace("/404");
    }
    if (query.isSuccess) {
      // remove this action after migration challenge state
      postExamDetailGet(localExamId);
      resetQuestionInsight();
    }
  }, [
    localExamId,
    postExamDetailGet,
    query.isError,
    query.isSuccess,
    resetQuestionInsight,
  ]);

  return query;
}

export function useExamCounts(examId?: number) {
  const { projectId } = useStoreContext();
  const { examId: routeExamId } = useRouteParams();
  const localExamId = examId || routeExamId;
  const enabled = isNumber(localExamId);

  const query = useQuery({
    queryKey: examKeys.count(projectId, localExamId),
    queryFn: async ({ signal }) => {
      const { result } = await getExamCount({
        examId: localExamId,
        projectId,
        options: { signal },
      });

      return result;
    },
    enabled,
    initialData: {
      counts: {
        sentDeliveryExceptArchivedCount: 0,
        unreadDeliveryCount: 0,
        inProgressCount: 0,
        submittedCount: 0,
        inReviewCount: 0,
        expiredCount: 0,
        archivedCount: 0,
        approvedCount: 0,
        rejectedCount: 0,
      },
      id: 0,
      organizationId: 0,
      projectId: 0,
    } as ExamCounts,
    initialDataUpdatedAt: getLastWeekTimestamp(),
    select: useCallback(({ counts }: ExamCounts) => {
      const hasBeenDelivered = Object.keys(counts).some(
        (countKey) => counts[countKey] > 0,
      );

      return {
        ...counts,
        hasBeenDelivered,
      };
    }, []),
  });

  return query;
}

export function useCreateExam() {
  const client = useQueryClient();
  const { projectId } = useStoreContext();

  const mutation = useMutation({
    mutationFn: async (data) => postExam({ projectId, options: { data } }),
    onSuccess: () => {
      toast.success(Message.getMessageByKey("message.exam.created"));
      client.invalidateQueries(examKeys.listAll(projectId));

      history.push(`/p/${projectId}/exams`);
    },
  });

  return mutation;
}

export function useUpdateExam() {
  const { projectId } = useStoreContext();
  const invalidate = useInvalidateExamRelation();

  const mutation = useMutation({
    mutationFn: async ({ examId, data }: UpdateExamParams) =>
      putExam({ examId, projectId, options: { data } }),
    onSuccess: (_, { examId, hideToast }) => {
      if (!hideToast) {
        toast.success(Message.getMessageByKey("message.exam.updated"));
      }
      invalidate(examId);
    },
  });

  return mutation;
}

export function useDeleteExam() {
  const { projectId } = useStoreContext();
  const invalidate = useInvalidateExamRelation();

  const mutation = useMutation({
    mutationFn: async (examId: number) => deleteExam({ examId, projectId }),
    onSuccess(_, examId) {
      toast.success(Message.getMessageByKey("message.exam.deleted"));
      invalidate(examId);
    },
  });

  return mutation;
}

export function useInvalidateExamRelation(invalidateDetail = true) {
  const client = useQueryClient();
  const { projectId } = useStoreContext();

  return (examId: number) => {
    if (invalidateDetail) {
      client.invalidateQueries(examKeys.detail(projectId, examId));
    }

    client.invalidateQueries(examKeys.listAll(projectId));
    client.invalidateQueries(examKeys.count(projectId, examId));
  };
}

export function useInvalidateExamCountRelation() {
  return useInvalidateExamRelation(false);
}
