import actionCreator from "../shared/services/actionCreator";
import { Action } from "./";

interface AjaxPayload {
  url: string;
  method: string;
  body?: {};
  headers?: {};
  cancel?: Array<string>;
  options?: AjaxActionOptions;

  /* eslint-disable @typescript-eslint/ban-types */
  success?: Function;
  error?: Function;
  /* eslint-enable @typescript-eslint/ban-types */
}

export interface AjaxAction extends Action {
  payload: AjaxPayload;
}

type BackoffFunction = (retryCount: number) => number;

export interface AjaxActionOptions {
  disableDefaultError?: boolean;
  background?: boolean;
  retryWhileOffline?: {
    maxRetries: number;
    delay: number | BackoffFunction;
  };
}

export const ajaxAction = actionCreator("ajaxAction");
