import * as classnames from "classnames";
import * as React from "react";

import { Msg, TableHead, HeaderColumn, SortableText } from "@shared/components";
import { SortOrderItem } from "@shared/models";

export enum ExamListColumnName {
  ID = "id",
  NAME = "name",
  ARCHIVED_AT = "archived_at",
  URL_DELIVERY_ENABLED = "url_delivery_enabled",
  SENT_DELIVERY_COUNT = "sent_delivery_count",
  UNREAD_DELIVERY_COUNT = "unread_delivery_count",
  IN_PROGRESS_COUNT = "in_progress_count",
  UNEVALUATED_COUNT = "unevaluated_count",
  EVALUATED_COUNT = "evaluated_count",
}

/**
 * Props
 */
export interface ExamListTableHeaderProps {
  status: string;
  sortItem: SortOrderItem;
  onClickSortItem: (sortItem: SortOrderItem) => void;
}

export const ExamListTableHeader: React.FunctionComponent<
  ExamListTableHeaderProps
> = (props: ExamListTableHeaderProps) => {
  const { status, sortItem } = props;
  const rootStyle = classnames("code-exam-list-table-header");
  return (
    <TableHead className={rootStyle}>
      <HeaderColumn size={2}>
        <SortableText
          name={ExamListColumnName.ID}
          sortItem={sortItem}
          onClick={props.onClickSortItem}
          ariaLabel="ID"
        >
          <Msg id="common.id" />
        </SortableText>
      </HeaderColumn>
      <HeaderColumn size={6}>
        <SortableText
          name={ExamListColumnName.NAME}
          sortItem={sortItem}
          onClick={props.onClickSortItem}
          ariaLabel="Title"
        >
          <Msg id="common.title" />
        </SortableText>
      </HeaderColumn>
      {status !== "active" ? (
        <HeaderColumn
          size={5}
          className="code-exam-list-table-header__padding-small"
        >
          <SortableText
            name={ExamListColumnName.ARCHIVED_AT}
            sortItem={sortItem}
            onClick={props.onClickSortItem}
            ariaLabel="Archived At"
          >
            <Msg id="archivedAt" />
          </SortableText>
        </HeaderColumn>
      ) : (
        <HeaderColumn
          size={2}
          className="align-center code-exam-list-table-header__padding-small"
        >
          <SortableText
            name={ExamListColumnName.URL_DELIVERY_ENABLED}
            sortItem={sortItem}
            onClick={props.onClickSortItem}
            ariaLabel="Delivery Type"
          >
            <Msg id="exam.deliveryKind" />
          </SortableText>
        </HeaderColumn>
      )}
      <HeaderColumn
        size={2}
        className="align-right code-exam-list-table-header__padding-small"
      >
        <SortableText
          name={ExamListColumnName.SENT_DELIVERY_COUNT}
          sortItem={sortItem}
          onClick={props.onClickSortItem}
          ariaLabel="Sent Delivery Count"
        >
          <Msg id="applicantExam.delivered" />
        </SortableText>
      </HeaderColumn>
      <HeaderColumn className="align-right code-exam-list-table-header__unread-delivery code-exam-list-table-header__padding-small">
        <SortableText
          name={ExamListColumnName.UNREAD_DELIVERY_COUNT}
          sortItem={sortItem}
          onClick={props.onClickSortItem}
          ariaLabel="Unread Delivery Count"
        >
          <Msg id="applicantExamStatus.unread" />
        </SortableText>
      </HeaderColumn>
      <HeaderColumn
        size={2}
        className="align-right code-exam-list-table-header__padding-small"
      >
        <SortableText
          name={ExamListColumnName.IN_PROGRESS_COUNT}
          sortItem={sortItem}
          onClick={props.onClickSortItem}
          ariaLabel="In Progress Count"
        >
          <Msg id="applicantExamStatus.inProgress" />
        </SortableText>
      </HeaderColumn>
      <HeaderColumn
        size={2}
        className="align-right code-exam-list-table-header__padding-small"
      >
        <SortableText
          name={ExamListColumnName.UNEVALUATED_COUNT} // This was changed from "submitted_count" due to TEST-3993
          sortItem={sortItem}
          onClick={props.onClickSortItem}
          ariaLabel="Submitted Count"
        >
          <Msg id="applicantExamStatus.submitted" />
        </SortableText>
      </HeaderColumn>
      <HeaderColumn
        size={2}
        className="align-right code-exam-list-table-header__padding-small"
      >
        <SortableText
          name={ExamListColumnName.EVALUATED_COUNT}
          sortItem={sortItem}
          onClick={props.onClickSortItem}
          ariaLabel="Evaluated Count"
        >
          <Msg id="submission.evaluated" />
        </SortableText>
      </HeaderColumn>
      <HeaderColumn size={1} />
    </TableHead>
  );
};
