import { forwardRef } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import {
  Msg,
  SubmissionResultDetails,
  SubmissionResultReport,
  TabItem,
  Tabs,
  Tooltip,
} from "@shared/components";
import SubmissionResultActionLog from "@shared/components/submissionResultActionLog/SubmissionResultActionLog";
import {
  useGetSubmissionsResultDetailBatch,
  useGetSubmissionsResults,
} from "@shared/hooks/query";
import {
  ExamModel,
  SubmissionModel,
  SubmissionReportChallengeModel,
  SubmissionResultDetailModel,
} from "@shared/models";

import { SuspiciousCountTooltip } from "../suspiciousCountTooltip/SuspiciousCountTooltip";

interface SubmissionDetailTabsProps extends React.HTMLProps<HTMLDivElement> {
  currentProjectId: number;
  examId: number;
  examDetail: ExamModel;
  submissionId: number;
  submission: SubmissionModel;
  showSkillReportTab: boolean;
  canViewSkillReport: boolean;
  canViewActionLog: boolean;
  canViewCodePlayback: boolean;
  canViewCodeDiff: boolean;
  showActionLogTab: boolean;
  reportLoading: boolean;
  reportChallenges: Array<SubmissionReportChallengeModel>;
  getReport: (submissionId: number) => void;
  onClickRecalculate: (
    recalculateChallengeResult: SubmissionResultDetailModel,
  ) => void;
}

export const SubmissionDetailTabs = forwardRef<
  HTMLDivElement,
  SubmissionDetailTabsProps
>(
  (
    {
      currentProjectId,
      examId,
      examDetail,
      submissionId,
      submission,
      showSkillReportTab,
      canViewSkillReport,
      canViewActionLog,
      canViewCodePlayback,
      canViewCodeDiff,
      showActionLogTab,
      reportLoading,
      reportChallenges,
      getReport,
      onClickRecalculate,
    }: SubmissionDetailTabsProps,
    ref: React.ForwardedRef<HTMLDivElement>,
  ) => {
    const { data: results } = useGetSubmissionsResults();
    const submissionResultDetailList =
      useGetSubmissionsResultDetailBatch(results);

    const tabItems: Array<TabItem> = [
      {
        label: <Msg id={"submission.submissionDetail"} />,
        to: `/p/${currentProjectId}/exams/${examId}/submissions/${submission.id}/details`,
      },
    ];

    if (showSkillReportTab) {
      tabItems.push({
        label: <Msg id={"submission.skillReport"} />,
        to: `/p/${currentProjectId}/exams/${examId}/submissions/${submission.id}/report`,
      });
    }

    if (showActionLogTab) {
      tabItems.push({
        label: (
          <Tooltip
            disabled={canViewActionLog}
            text={<Msg id="tier.disabled" />}
          >
            <>
              <div className="code-submission-detail__action-log-tab">
                <Msg id={"submission.actionLog"} />
              </div>
              <SuspiciousCountTooltip
                submission={submission}
                className="no-underline"
                limitHeight
              />
            </>
          </Tooltip>
        ),
        to: canViewActionLog
          ? `/p/${currentProjectId}/exams/${examId}/submissions/${submission.id}/log`
          : undefined,
        disabled: !canViewActionLog,
      });
    }

    return (
      <>
        <Tabs
          className="code-submission-detail__tab is-nomedia"
          tabItems={tabItems}
        />
        <div className="code-submission-detail__tab-body" ref={ref}>
          <Switch>
            <Route
              path={`/p/:projectId/exams/:examId/submissions/:submissionId/details`}
              render={() => (
                <SubmissionResultDetails
                  challengeSets={examDetail.challengesSets}
                  status={submission.status}
                  results={results}
                  resultDetails={submissionResultDetailList}
                  onClickRecalculate={onClickRecalculate}
                  canViewCodePlayback={canViewCodePlayback}
                  canViewCodeDiff={canViewCodeDiff}
                />
              )}
            />
            {canViewSkillReport && (
              <Route
                path={`/p/:projectId/exams/:examId/submissions/:submissionId/report`}
                exact={true}
                render={() => (
                  <SubmissionResultReport
                    getReport={() => getReport(submissionId)}
                    reportLoading={reportLoading}
                    reportChallenges={reportChallenges}
                  />
                )}
              />
            )}
            {canViewActionLog && showActionLogTab && (
              <Route
                path={`/p/:projectId/exams/:examId/submissions/:submissionId/log`}
                exact={true}
                render={({ location }) => (
                  <SubmissionResultActionLog
                    location={location}
                    submissionId={submissionId}
                  />
                )}
              />
            )}
            <Route
              path={`/p/:projectId/exams/:examId/submissions/:submissionId/details"`}
              render={({ match }) => (
                <Redirect
                  to={`/p/${match.params.projectId}/exams/${match.params.examId}/submissions/${match.params.submissionId}/details`}
                />
              )}
            />
            <Route
              path={`/p/:projectId/exams/:examId/submissions/:submissionId`}
              render={({ match }) => (
                <Redirect
                  to={`/p/${match.params.projectId}/exams/${match.params.examId}/submissions/${match.params.submissionId}/details`}
                />
              )}
            />
          </Switch>
        </div>
      </>
    );
  },
);

SubmissionDetailTabs.displayName = "SubmissionDetailTabs";
