import { connect } from "react-redux";
import { Action, Dispatch } from "redux";

import {
  projectMemberDeleteAction,
  orgsUserReactivateAction,
  projectMemberUpdateAction,
  projectMemberListGetAction,
  projectMemberInviteAction,
} from "@actions";

import { RootState } from "@reducers";

import { PaginationModel } from "@shared/models";
import { SortOrderItem } from "@shared/models";
import { ProjectRole } from "@shared/services/enums";

import SettingMember from "./SettingMember";

interface OwnProps {
  status: string;
}

const mapStateToProps = (state: RootState, props: OwnProps) => ({
  ...props,
  currentUser: state.user.user,
  projectId: state.project.currentProjectId,
  members: state.project.projectMemberList,
  pagination: state.project.projectMemberPagination,

  updatingUserLoading: state.project.updatingUserLoading,
  updatingUserError: state.project.updatingUserError,

  projectMemberListLoading: state.project.projectMemberListLoading,
  inviteMemberLoading: state.project.pendingInviteMemberLoading,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  getMembers: ({
    pagination,
    sortOrder,
    keyword,
    roles = [],
  }: {
    pagination: PaginationModel;
    sortOrder: SortOrderItem;
    keyword?: string;
    roles?: ProjectRole[];
  }) => {
    dispatch(
      projectMemberListGetAction.request(
        {},
        {
          ...pagination,
          "sortOrder.column": sortOrder.column,
          "sortOrder.direction": sortOrder.direction,
          keyword,
          roles,
        },
      ),
    );
  },
  inviteMember: (
    projectId: number,
    params?: {
      roles: {
        projectId: number;
        role: number;
      }[];
      email?: string;
    },
  ) => {
    dispatch(projectMemberInviteAction.request(projectId, params));
  },
  deleteMember: (projectId: number, userId: number, params: {}) => {
    dispatch(projectMemberDeleteAction.request({ projectId, userId, params }));
  },
  updateMember: (userId: number, params?: { roles: number[] }) => {
    dispatch(projectMemberUpdateAction.request(userId, params));
  },
  unDeleteMember: (payload?: {}) => {
    dispatch(orgsUserReactivateAction.request(payload));
  },
});

/**
 * Wrapped component
 */
const wrappedComponent = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SettingMember);

export default wrappedComponent;
