import ExamChallengeModel from "./ExamChallenge.model";
import SubmissionListModel from "./SubmissionList.model";

export enum ModalStep {
  action = 1,
  version,
  challenge,
  submissions,
  confirm,
}

export enum ActionType {
  Rescore = 1,
  Reset,
}

export enum VersionType {
  Current = 1,
  New,
}

export interface MultiManageForm {
  action?: ActionType;
  version?: VersionType;
  challenge?: ExamChallengeModel;
  submissions?: SubmissionListModel[];
  deadline?: Date;
}
