type AnswerDistributionEntry = {
  // For multiple-choice, the set of options that the applicant checked.
  // For free-response, a length-one array of their answer
  // For fill-in-the-blank, what the applicant filled in each blank
  answer: string[];

  // The percentage, formatted like a decimal, like "0.20" to mean "one in five"
  fraction: string;

  count: number;
};

type ScoreDistributionEntry = {
  // The score the applicant received on this question
  score: 0 | 1;

  // The percentage, formatted like a decimal, like `"0.20"` to mean "one in five"
  fraction: string;

  count: number;
};

type QuestionInsightResultData = {
  totalCount: number;
  answerDistribution: AnswerDistributionEntry[];
  scoreDistribution: ScoreDistributionEntry[];

  uncommonAnswersCount: number;

  // This is only present for "overall". It is not present for by-month or by-version stats.
  organizationCount?: number;

  // may be missing when there is fewer than 1 submission that has a time recorded
  timeAverageMillis?: number;

  // may be missing when there is fewer than 2 submissions that have a time recorded
  timeStandardDeviationMillis?: number;
};

type ByMonthEntry = {
  // ISO month, such as `"2023-08"`.
  month: string;
  stats: QuestionInsightResultData;

  // fraction is a rounded ASCII representation of the fraction of
  // responses that are included in this month.
  // Example: `"0.25"` (one in four).
  fraction: string;
};

type ByVersionEntry = {
  questionVersionId: number;
  stats: QuestionInsightResultData;
  fraction: string;
};

export class QuestionInsightModel {
  overall: QuestionInsightResultData;
  fractionPrecision: string;
  byMonth: ByMonthEntry[];
  byVersion: ByVersionEntry[];

  constructor(args?: {}) {
    Object.assign(this, args);
  }
}
