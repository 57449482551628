import * as classnames from "classnames";

import { ChallengeDetailBox } from "..";
import {
  Msg,
  TagCloud,
  Markdown,
  Spinner,
} from "../../../../../../shared/components";
import {
  ChallengeModel,
  EnumModel,
  ReadmeModel,
} from "../../../../../../shared/models";

/**
 * Prop interface
 */
export interface ChallengePromptsProps {
  challenge: ChallengeModel;
  prompts?: ReadmeModel;
  programmingLanguages: EnumModel[];
  previewPath: string;
  loading: boolean;
}

/**
 * React Component
 */
export function ChallengePrompts({
  challenge,
  prompts,
  programmingLanguages,
  previewPath,
  loading,
}: ChallengePromptsProps) {
  const rootStyle = classnames("code-challenge-prompts");
  const spinner = (
    <div className="code-challenge-prompts__loading">
      <Spinner />
    </div>
  );
  return (
    <div className={rootStyle}>
      {challenge.programmingLanguages.length > 0 && (
        <ChallengeDetailBox title={<Msg id="supportedProgrammingLanguages" />}>
          <TagCloud
            tagItems={challenge.programmingLanguages.map((id) => ({
              label:
                programmingLanguages.find((language) => language.value === id)
                  ?.displayString || id.toString(),
            }))}
          />
        </ChallengeDetailBox>
      )}
      <ChallengeDetailBox title={<Msg id="challenge.prompts" />}>
        {loading ? (
          spinner
        ) : (
          <Markdown
            body={prompts?.body || ""}
            internalLinkFallbackURL={previewPath}
          />
        )}
      </ChallengeDetailBox>
    </div>
  );
}
