import * as classnames from "classnames";

import {
  TableHead,
  HeaderColumn,
  SortableText,
  Restricted,
  Msg,
  Tooltip,
  Button,
  Icon,
} from "@shared/components";
import { UserModel } from "@shared/models";
import { SortOrderItem } from "@shared/models";
import {
  UserRole,
  ProjectRole,
  SubmissionListKind,
  SortDirection,
} from "@shared/services/enums";
import {
  getConditionalColumnsForSubmissionList,
  isAllOrArchivedList,
  isEvaluatedList,
  isSubmittedList,
} from "@shared/services/exam";
import Message from "@shared/services/message";

/**
 * Interface
 */
interface SubmissionListTableHeaderProps {
  /**
   * if it's not undefined, the current user has assigined as a reviewer
   */
  reviewer?: UserModel;
  noReviewer?: boolean;
  shareReviewsWithReviewers?: boolean;
  submissionListKind: SubmissionListKind;
  applicantNameRequired: boolean;
  sortItem: SortOrderItem;
  isCancelAllDisabled: boolean;
  canCancelAll: boolean;
  onClickCancelAll: () => void;
  onClickSortItem: (sortItem: SortOrderItem) => void;
}

/**
 * Component
 * @param props SubmissionListTableHeaderProps
 */
export function SubmissionListTableHeader({
  reviewer,
  noReviewer,
  shareReviewsWithReviewers,
  submissionListKind,
  applicantNameRequired,
  sortItem,
  isCancelAllDisabled,
  canCancelAll,
  onClickCancelAll,
  onClickSortItem,
}: SubmissionListTableHeaderProps) {
  const isAllOrArchivedPage = isAllOrArchivedList(submissionListKind);
  const isEvaluatedListPage = isEvaluatedList(submissionListKind);
  const isSubmittedListPage = isSubmittedList(submissionListKind);
  const isUnreadListPage = submissionListKind === SubmissionListKind.unread;

  const rootStyle = classnames("code-submission-list-table-header", {
    "is-unread": isUnreadListPage,
  });

  const {
    shouldShowSubmittedAtColumn,
    shouldShowTimeSpentColumn,
    shouldShowOverallScoreColumn,
    shouldShowResultColumn,
  } = getConditionalColumnsForSubmissionList(submissionListKind);

  const shouldResizeColumns =
    !shouldShowSubmittedAtColumn && !shouldShowResultColumn;

  return (
    <TableHead className={rootStyle}>
      <HeaderColumn
        // `key` prop is used to force re-render when submissionListKind changes
        // as a workaround for Safari bug: https://givery.slack.com/archives/C01165N749E/p1680514169030309
        key={`applicant_${submissionListKind}`}
        size={shouldResizeColumns ? undefined : 4}
      >
        <SortableText
          name={applicantNameRequired ? "applicantFullName" : "email"}
          sortItem={sortItem}
          onClick={onClickSortItem}
          ariaLabel={applicantNameRequired ? "Full Name" : "Email"}
        >
          <Msg id="common.applicants" />
        </SortableText>
      </HeaderColumn>
      {isAllOrArchivedPage && (
        <HeaderColumn key={`status_${submissionListKind}`} size={2}>
          <SortableText
            name="status"
            sortItem={sortItem}
            onClick={onClickSortItem}
            ariaLabel="Status"
          >
            <Msg id={"status"} />
          </SortableText>
        </HeaderColumn>
      )}
      {shouldShowResultColumn && (
        <HeaderColumn
          key={`result_${submissionListKind}`}
          absoluteSize={10}
          noPaddingX
        >
          <Msg id={"result"} />
        </HeaderColumn>
      )}
      <Restricted
        roles={[
          UserRole.SystemAdmin,
          ProjectRole.ProjectAdmin,
          ...(Boolean(reviewer) && shareReviewsWithReviewers
            ? [ProjectRole.Reviewer]
            : []),
        ]}
        strictAllow={
          (isEvaluatedListPage || isSubmittedListPage) && !noReviewer
        }
        wrapperTagName={false}
      >
        <HeaderColumn key={`review_${submissionListKind}`} size={3}>
          <SortableText
            name="goodReviewRatio"
            sortItem={sortItem}
            onClick={onClickSortItem}
            ariaLabel="Reviews"
          >
            <Msg id={"review"} />
          </SortableText>
        </HeaderColumn>
      </Restricted>
      {shouldShowOverallScoreColumn && (
        <Restricted
          roles={[UserRole.SystemAdmin, ProjectRole.ProjectAdmin]}
          allow={Boolean(reviewer)}
          wrapperTagName={false}
        >
          <HeaderColumn
            key={`score_${submissionListKind}`}
            className="align-right"
            size={shouldResizeColumns ? 3 : 2}
          >
            <SortableText
              name="score"
              sortItem={sortItem}
              initialDirection={SortDirection.Desc}
              onClick={onClickSortItem}
              className="code-submission-list-table-header__score"
              ariaLabel="Score"
            >
              <Msg id={"overallScore"} />
            </SortableText>
          </HeaderColumn>
        </Restricted>
      )}
      <HeaderColumn
        key={`deliveredAt_${submissionListKind}`}
        size={shouldResizeColumns ? 3 : 2}
      >
        <SortableText
          name="deliveredAt"
          sortItem={sortItem}
          onClick={onClickSortItem}
          ariaLabel="Delivered At"
        >
          <Msg id="deliveredAt" />
        </SortableText>
      </HeaderColumn>
      <HeaderColumn
        key={`deadline_${submissionListKind}`}
        size={shouldResizeColumns ? 3 : 2}
        noPaddingLeft
      >
        <SortableText
          name="deadline"
          sortItem={sortItem}
          onClick={onClickSortItem}
          ariaLabel="Deadline"
        >
          <Msg id="common.deadline" />
        </SortableText>
      </HeaderColumn>
      {shouldShowSubmittedAtColumn && (
        <HeaderColumn
          key={`submittedAt_${submissionListKind}`}
          absoluteSize={7}
          noPaddingX
        >
          <SortableText
            name="submittedAt"
            sortItem={sortItem}
            onClick={onClickSortItem}
            ariaLabel="Submitted At"
          >
            <Msg id={"submittedAt"} />
          </SortableText>
        </HeaderColumn>
      )}
      {shouldShowTimeSpentColumn && (
        <Restricted
          roles={[UserRole.SystemAdmin, ProjectRole.ProjectAdmin]}
          allow={Boolean(reviewer)}
          wrapperTagName={false}
        >
          <HeaderColumn
            key={`timeSpent_${submissionListKind}`}
            className="align-right"
            size={2}
          >
            <SortableText
              name="timespentInSeconds"
              sortItem={sortItem}
              onClick={onClickSortItem}
              ariaLabel="Spent Time"
            >
              <Msg id={"timeSpent"} />
            </SortableText>
          </HeaderColumn>
        </Restricted>
      )}
      <HeaderColumn key={`action_${submissionListKind}`} size={1}>
        <Restricted
          roles={[ProjectRole.ProjectAdmin]}
          strictAllow={canCancelAll}
        >
          <div className="code-submission-list-table-header__action">
            <Tooltip
              text={Message.getMessageByKey(
                isCancelAllDisabled
                  ? "button.cancelAll.disabled"
                  : "button.cancelAll",
              )}
              placement="top-end"
            >
              <Button
                size="small"
                disabled={isCancelAllDisabled}
                shrink={true}
                onClick={onClickCancelAll}
                ariaLabel="Cancel Deliveries"
              >
                <Icon type="trash" />
              </Button>
            </Tooltip>
          </div>
        </Restricted>
      </HeaderColumn>
    </TableHead>
  );
}
