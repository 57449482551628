import { CopyBox, FormGroup, Label, Msg } from "@shared/components";
import {
  formatDateTimeMinutes,
  formatTimeLeftString,
} from "@shared/services/date";

interface Props {
  copyBoxValue?: string;
  dynamicDeadlineHours?: number;
  endAt: string;
  passwordRequired?: boolean;
  startAt: string;
}

export default function DeliverIdSettings({
  copyBoxValue,
  dynamicDeadlineHours,
  endAt,
  passwordRequired,
  startAt,
}: Props) {
  const availablePeriod = `${formatDateTimeMinutes(
    startAt,
  )} ~ ${formatDateTimeMinutes(endAt)}`;
  const dynamicDeadline = dynamicDeadlineHours
    ? formatTimeLeftString(dynamicDeadlineHours * 3600)
    : 0;

  return (
    <div className="code-deliver-id__settings">
      <FormGroup>
        <Label>
          <Msg id="exam.urlDelivery.url" />
        </Label>
        <CopyBox value={copyBoxValue} />
      </FormGroup>
      <FormGroup>
        <Label>
          <Msg id="exam.urlDelivery.availablePeriod" />
        </Label>
        <p>{availablePeriod}</p>
      </FormGroup>
      {dynamicDeadlineHours && (
        <FormGroup>
          <Label>
            <Msg id="exam.urlDelivery.dynamicDeadline" />
          </Label>
          <p>{dynamicDeadline}</p>
        </FormGroup>
      )}
      {passwordRequired && (
        <FormGroup>
          <Label>
            <Msg id="exam.idDelivery.password" />
          </Label>
          <p>
            <Msg id="common.enabled" />
          </p>
        </FormGroup>
      )}
    </div>
  );
}
