import classNames from "classnames";

import { BarChart, Column, Msg, Row, Table, TableBody } from "..";
import { PossiblyNoDataWithTooltip } from "../question/partials/PossiblyNoDataWithTooltip";

export interface ScoreDistributionProps {
  averageScore?: number;
  chartWidth?: number;
  chartClassName?: string;
  tableClassName?: string;
  className?: string;
  deviation?: number;
  logarithmicScale?: boolean;
  medianScore?: number;
  scoreDistribution: { label: string; number: number }[];
  xLabel: string;
  yLabel: string;
}

export function ScoreDistribution({
  averageScore,
  chartWidth,
  chartClassName,
  tableClassName,
  className,
  deviation,
  logarithmicScale,
  medianScore,
  scoreDistribution,
  xLabel,
  yLabel,
}: ScoreDistributionProps) {
  const rootStyle = classNames("code-c-score-distribution", className);
  const chartStyle = classNames(
    "code-c-score-distribution__chart",
    chartClassName,
  );
  const tableStyle = classNames(
    "code-c-score-distribution__table",
    tableClassName,
  );

  return (
    <div className={rootStyle}>
      <div className={chartStyle}>
        <BarChart
          xLabel={xLabel}
          yLabel={yLabel}
          items={scoreDistribution}
          logarithmicScale={logarithmicScale}
          size={chartWidth}
        />
      </div>
      <div>
        <Table narrow={true} hoverable={false} className={tableStyle}>
          <TableBody>
            <Row>
              <Column className="code-c-score-distribution__title">
                <Msg id="challenge.average" />
              </Column>
              <Column className="code-c-score-distribution__body">
                <PossiblyNoDataWithTooltip
                  value={averageScore !== undefined ? `${averageScore}%` : ""}
                />
              </Column>
            </Row>
            <Row>
              <Column className="code-c-score-distribution__title">
                <Msg id="challenge.medianScore" />
              </Column>
              <Column className="code-c-score-distribution__body">
                <PossiblyNoDataWithTooltip
                  value={medianScore !== undefined ? `${medianScore}%` : ""}
                />
              </Column>
            </Row>
            <Row>
              <Column className="code-c-score-distribution__title">
                <Msg id="report.std" />
              </Column>
              <Column className="code-c-score-distribution__body">
                <PossiblyNoDataWithTooltip
                  value={deviation !== undefined ? `${deviation}%` : ""}
                />
              </Column>
            </Row>
          </TableBody>
        </Table>
      </div>
    </div>
  );
}
