import React, { useImperativeHandle } from "react";
import { SubmitHandler, UseFormReturn } from "react-hook-form";
import { FieldValues } from "react-hook-form";

// Handles sending step values up to the parent container when the step changes
// (based on interactions in the StepHeader component)
export function useImperativeSubmit<T extends FieldValues>(
  ref: React.RefObject<{ submit: () => Promise<void> }>,
  handleSubmit: UseFormReturn<T>["handleSubmit"],
  handleFormSubmit: SubmitHandler<T>,
  watchedValues: T,
) {
  useImperativeHandle(ref, () => ({
    submit: () => {
      return new Promise<void>((resolve) => {
        handleSubmit(
          (data) => {
            handleFormSubmit(data); // all ok
            resolve();
          },
          (errors) => {
            handleFormSubmit(watchedValues); // Update parent with current values even if there are errors
            console.log("validation errors in form", errors, watchedValues);
            resolve();
          },
        )();
      });
    },
  }));
}
