import * as classnames from "classnames";

import { GlobalAppType } from "@shared/services/enums";
import Message from "@shared/services/message";

import { JumpTo, Msg } from "..";

/**
 * Prop interface
 */
export interface FooterProps {
  className?: string;
  appType?: GlobalAppType;
}

/**
 * React Component
 */
export default function Footer({ className, appType }: FooterProps) {
  const rootStyle = classnames("code-c-footer", {
    [`${className}`]: Boolean(className),
  });

  return (
    <div className={rootStyle}>
      <div className="code-c-footer__container">
        <div className="code-c-footer__left">
          <div className="code-c-footer__link">
            <JumpTo to={"https://givery.co.jp/"}>
              <small>
                <Msg id="common.aboutUs" />
              </small>
            </JumpTo>
          </div>
          <div className="code-c-footer__link">
            <JumpTo
              to={Message.getMessageByKey(
                appType === GlobalAppType.User
                  ? "common.applicantTerms.url"
                  : "common.terms.url",
              )}
            >
              <small>
                <Msg id="common.tos" />
              </small>
            </JumpTo>
          </div>
          <div className="code-c-footer__link">
            <JumpTo to={Message.getMessageByKey("common.privacy.url")}>
              <small>
                <Msg id="common.privacy" />
              </small>
            </JumpTo>
          </div>
        </div>
        <div className="code-c-footer__right">
          <small>&copy; 2024 Givery, inc. All Rights Reserved.</small>
        </div>
      </div>
    </div>
  );
}
