import * as classnames from "classnames";
import * as React from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";

import {
  PageTitle,
  Table,
  TableHead,
  Pagination,
  Msg,
  HeaderColumn,
  Block,
  LeftBlock,
} from "@shared/components";
import { AuditLogModel, PaginationModel } from "@shared/models";

import AuditLogRow from "./AuditLogRow";

/**
 * React Component
 */
export type ExternalProps = {
  auditType?: "org" | "project";
};

export type InjectedProps = {
  auditLog: Array<AuditLogModel>;
  auditLogPagination: PaginationModel;
  getOrgAuditLog: (pagination?: PaginationModel) => void;
  getProjectAuditLog: (pagination?: PaginationModel) => void;
};

export type AuditLogProps = ExternalProps & InjectedProps;

const AuditLog = ({
  auditType = "org",
  auditLog,
  auditLogPagination,
  getOrgAuditLog,
  getProjectAuditLog,
}: AuditLogProps) => {
  React.useEffect(() => {
    auditType === "org" ? getOrgAuditLog() : getProjectAuditLog();
  }, [auditType, getOrgAuditLog, getProjectAuditLog]);

  const rootStyle = classnames("auditlog");

  const auditEntries = auditLog.map((entry) => (
    <AuditLogRow key={entry.createdAt} auditLogEntry={entry} />
  ));

  const onPageChange = ({ selected }: { selected: number }) => {
    auditType === "org"
      ? getOrgAuditLog(auditLogPagination.getAtPage(selected))
      : getProjectAuditLog(auditLogPagination.getAtPage(selected));
  };

  const pageTitle = (
    <Msg
      id={auditType === "org" ? "auditLog.organization" : "auditLog.project"}
    />
  );

  return (
    <div className={rootStyle}>
      <BreadcrumbsItem
        to={
          auditType === "org"
            ? "/settings/org/auditlog"
            : "/p/:projectId/settings/auditlog" // we don't actually need actual project id as the breadcrumb is not clickable
        }
      >
        {pageTitle}
      </BreadcrumbsItem>
      <Block className="code-setting__header">
        <LeftBlock>
          <PageTitle>{pageTitle}</PageTitle>
        </LeftBlock>
      </Block>
      <Table className="auditlog__table" bordered={true} hoverable={false}>
        <TableHead>
          <HeaderColumn>
            <Msg id="details" />
          </HeaderColumn>
          <HeaderColumn>
            <Msg id="auditLog.action" />
          </HeaderColumn>
          <HeaderColumn>
            <Msg id="auditLog.timestamp" />
          </HeaderColumn>
          <HeaderColumn>
            <Msg id="common.email" />
          </HeaderColumn>
        </TableHead>
        {auditEntries}
      </Table>
      <div className="auditlog__pagination">
        <Pagination
          pagination={auditLogPagination}
          onPageChange={onPageChange}
        />
      </div>
    </div>
  );
};

export default AuditLog;
