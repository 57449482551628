import { ExamDetailHeader } from "@components/orgs/exams/examSections";

import { useUserRoles } from "@shared/hooks";
import { useExam, useExamCounts } from "@shared/hooks/query";
import Message from "@shared/services/message";

import ApplicantFunnel from "./partials/applicantFunnel/ApplicantFunnel";
import { ChallengeAnalysis } from "./partials/challengeAnalysis/ChallengeAnalysis";
import ExamAnalysis from "./partials/examAnalysis/ExamAnalysis";
import ExamOutline from "./partials/examOutline/ExamOutline";

function ExamDashboard() {
  const {
    data: { examDetail, examChallengesSets },
  } = useExam();
  const { data: examCounts } = useExamCounts();
  const pageTitle = Message.getMessageByKey("exam.dashboard.title");

  const { canAccessDashboardCharts } = useUserRoles();

  return (
    <section className="code-exam-dashboard">
      <ExamDetailHeader
        archived={Boolean(examDetail && examDetail.isArchived())}
        editing={false}
        title={pageTitle}
        examId={examDetail.id}
      />
      {/* Layout demonstration, will be replace with the actual components later */}
      <ExamOutline
        examDetail={examDetail}
        examDetailChallengesSets={examChallengesSets}
      />
      {canAccessDashboardCharts && (
        <>
          <ApplicantFunnel examCounts={examCounts} />
          <ExamAnalysis />
          <ChallengeAnalysis exam={examDetail} />
        </>
      )}
    </section>
  );
}

export default ExamDashboard;
