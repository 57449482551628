import * as classnames from "classnames";
import { NavLink } from "react-router-dom";

import Message from "@shared/services/message";

import { Icon, ErrorIcon, WarningIcon } from "..";

/**
 * Prop interface
 */
export interface SubMenuItemProps {
  label?: string | JSX.Element;
  ariaLabel?: string;
  iconType?: string;
  endIcon?: JSX.Element;
  route?: {
    pathname: string;
    state?: {};
  };
  disabled?: boolean;
  className?: string;
  children?: {};
  hasError?: boolean;
  errorId?: string;
  warningId?: string;
}

/**
 * React Component
 */
export default function SubMenuItem({
  label,
  iconType,
  endIcon,
  route,
  disabled,
  className,
  children,
  ariaLabel,
  hasError = false,
  errorId = "tier.error.notSupported",
  warningId,
}: SubMenuItemProps) {
  const rootStyle = classnames("code-c-sub-menu-item", {
    [`${className}`]: Boolean(className),
    "is-disabled": disabled,
  });

  const icon = iconType ? (
    <Icon className="panel-icon" type={iconType} />
  ) : null;

  const toolTipErrorText = Message.getMessageByKey(errorId);
  const tooltipWarningText = warningId
    ? Message.getMessageByKey(warningId)
    : undefined;

  return disabled || !route ? (
    <li className={rootStyle}>
      <a className="code-c-sub-menu-item__link">
        <div>
          {icon}
          {label}
        </div>
      </a>
      {children}
    </li>
  ) : (
    <li className={rootStyle}>
      <NavLink
        className="code-c-sub-menu-item__link"
        activeClassName="is-active"
        to={route}
        aria-label={ariaLabel}
      >
        <div>
          {icon}
          {label}
          {hasError && (
            <ErrorIcon
              tooltipText={toolTipErrorText}
              tooltipPlacement="top-start"
            />
          )}
          {/* Prioritize error icon over warning icon */}
          {!hasError && Boolean(warningId) && (
            <WarningIcon
              tooltipText={tooltipWarningText}
              tooltipPlacement="top-start"
            />
          )}
        </div>
        {endIcon && (
          <div
            className="code-c-sub-menu-item__link__end-icon"
            style={{ textDecoration: "none" }}
          >
            {endIcon}
          </div>
        )}
      </NavLink>
      {children}
    </li>
  );
}
