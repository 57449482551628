import { Epic } from "redux-observable";

import {
  Action,
  adminSSOGetAction,
  adminSSOMetaDeleteAction,
  adminSSOMetaUploadAction,
  adminSSOUpdateAction,
  ajaxAction,
  alertAction,
} from "@actions";

import { RootState } from "@reducers";

import Message from "@shared/services/message";

export const adminSSOGetEpic: Epic<Action, RootState> = (action$, state$) =>
  action$
    .ofType(
      adminSSOGetAction.types.request,
      adminSSOUpdateAction.types.success,
      adminSSOMetaDeleteAction.types.success,
      adminSSOMetaUploadAction.types.success,
    )
    .map((action) =>
      ajaxAction.request({
        method: "get",
        url: `/api/admin/orgs/${state$.getState().admin.selectedOrg.name}/sso`,
        success: adminSSOGetAction.success,
        error: adminSSOGetAction.failure,
      }),
    );

export const adminSSOUpdateEpic: Epic<Action, RootState> = (action$, state$) =>
  action$.ofType(adminSSOUpdateAction.types.request).map((action) =>
    ajaxAction.request({
      method: "put",
      url: `/api/admin/orgs/${state$.getState().admin.selectedOrg.name}/sso`,
      body: action.payload,
      success: alertAction.success(
        Message.getMessageByKey("message.admin.sso.updated"),
        adminSSOUpdateAction.success,
      ),
      error: adminSSOUpdateAction.failure,
    }),
  );

export const adminSSOMetaUpdateEpic: Epic<Action, RootState> = (
  action$,
  state$,
) =>
  action$.ofType(adminSSOMetaUploadAction.types.request).map((action) => {
    const body = new FormData();
    body.append("file", action.payload as File);

    return ajaxAction.request({
      method: "post",
      url: `/api/admin/orgs/${state$.getState().admin.selectedOrg.name}/sso`,
      headers: {
        "X-Requested-With": "codecheck",
      },
      body,
      success: alertAction.success(
        Message.getMessageByKey("message.admin.sso.meta.uploaded"),
        adminSSOMetaUploadAction.success,
      ),
      error: adminSSOMetaUploadAction.failure,
    });
  });

export const adminSSOMetaDeleteEpic: Epic<Action, RootState> = (
  action$,
  state$,
) =>
  action$.ofType(adminSSOMetaDeleteAction.types.request).map((action) =>
    ajaxAction.request({
      method: "delete",
      url: `/api/admin/orgs/${state$.getState().admin.selectedOrg.name}/sso`,
      success: alertAction.success(
        Message.getMessageByKey("message.admin.sso.meta.deleted"),
        adminSSOMetaDeleteAction.success,
      ),
      error: adminSSOMetaDeleteAction.failure,
    }),
  );

export default [
  adminSSOGetEpic,
  adminSSOUpdateEpic,
  adminSSOMetaUpdateEpic,
  adminSSOMetaDeleteEpic,
];
