import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import {
  ChallengeQuestionPayload,
  postChallengeQuestion,
  putChallengeQuestion,
} from "@api/challenges";

import { useStoreContext } from "@context";

import history from "@shared/services/history";
import Message from "@shared/services/message";

import { challengeKeys } from "./useChallenge";

interface UpdateChallengeQuestionParams {
  challengeId: number;
  questionIds: number[];
}

export const challengeQuestionKeys = {
  all: ["challenge", "questions"] as const,
  update: () => [...challengeQuestionKeys.all, "UPDATE"] as const,
};

export function useCreateChallenge() {
  const client = useQueryClient();
  const { projectId, postChallengeListGet } = useStoreContext();

  const mutation = useMutation({
    mutationFn: (data: ChallengeQuestionPayload) =>
      postChallengeQuestion({ projectId, options: { data } }),
    onSuccess: () => {
      toast.success(
        Message.getMessageByKey("message.challenge.create.success"),
      );
      client.invalidateQueries(challengeKeys.challenge({ projectId }));
      // TODO reset the form state after migrate GET
      history.push(`/p/${projectId}/challenges`);
      postChallengeListGet();
      window.scrollTo(0, 0);
    },
    onError: () => {
      toast.error(Message.getMessageByKey("message.challenge.create.failed"));
    },
  });

  return mutation;
}

export function useUpdateChallengeQuestion() {
  const client = useQueryClient();
  const { projectId, refreshChallengeList } = useStoreContext();

  const mutation = useMutation({
    mutationKey: challengeQuestionKeys.update(),
    meta: {
      hideLoader: true,
    },
    mutationFn: ({
      challengeId,
      questionIds,
    }: UpdateChallengeQuestionParams) => {
      return putChallengeQuestion({
        challengeId,
        projectId,
        options: { data: { questionIds } },
      });
    },
    onSuccess: () => {
      toast.success(
        Message.getMessageByKey("message.question.update.succcess"),
      );
      client.invalidateQueries(challengeKeys.challenge({ projectId }));
      refreshChallengeList();
    },
    onError: () => {
      toast.error(Message.getMessageByKey("message.question.update.failed"));
    },
  });

  return mutation;
}
