import * as React from "react";
import { RouteComponentProps } from "react-router-dom";

import NotFound from "@components/common/error/notFound/NotFound";

import { useIframeCommunication } from "@shared/hooks";
import {
  useGetSubmissionsResultDetail,
  useSubmission,
} from "@shared/hooks/query";
import {
  IApplicantActionLog,
  ChallengeModel,
  PreviewContentModel,
  ReadmeModel,
} from "@shared/models";
import { ApplicantActionType, KeyEventStatus } from "@shared/services/enums";
import {
  MESSAGE_CONTACT_TO_SUPPORT,
  MESSAGE_EVENT_CONTEXT,
} from "@shared/services/events";
import { intercom as Intercom } from "@shared/services/intercom";
import { getKeyEventStatus } from "@shared/services/keyEvents";
import { getEditablePresignedFiles } from "@shared/services/preview";
import { getPreviewURL } from "@shared/services/preview";

import { CodePlaybackHeader } from "./CodePlaybackHeader";
import { useCodePlaybackData } from "./useCodePlaybackData";

export type ExternalProps = RouteComponentProps<{
  projectId: string;
  examId: string;
  submissionId: string;
  challengeId: string;
}> & {};

export type InjectedProps = {
  language: string | undefined;
  useLocalPlayback: boolean;
  examId: number;
  challengeId: number;
  submissionId: number;
  challenge: ChallengeModel;
  readme: ReadmeModel;
  actionLogs: IApplicantActionLog[];
  previewContents: PreviewContentModel[];
  getChallenge: (id: number) => void;
  getAllActionLog: (
    submissionId: number,
    options?: {
      challengeResultId: number;
      actions: ApplicantActionType[];
    },
  ) => void;
};

export type CodePlaybackProps = ExternalProps & InjectedProps;

export const CodePlayback = (props: CodePlaybackProps) => {
  const iframeRef = React.useRef<HTMLIFrameElement>(null);

  const [currentKeyEventLogId, setCurrentKeyEventLogId] = React.useState<
    number | undefined
  >(undefined);

  const [showIntercom, setShowIntercom] = React.useState(false);
  const { data: submission } = useSubmission();
  const { data: resultDetail, isFetching: isResultDetailFetching } =
    useGetSubmissionsResultDetail(props.challengeId);

  const { useLocalPlayback, language, challengeId } = props;

  const {
    ready: dataReady,
    readme,
    currentKeyEventLog,
    actionLogs,
    previewContents,
  } = useCodePlaybackData({ ...props, currentKeyEventLogId });

  const onMessage = React.useCallback(
    (e: MessageEvent) => {
      if ("name" in e.data) {
        if (e.data.name === MESSAGE_CONTACT_TO_SUPPORT) {
          setShowIntercom(true);
        }
      }
    },
    [setShowIntercom],
  );

  const { ready: playbackReady, postMessage } = useIframeCommunication({
    ref: iframeRef,
    useDangerousPostMessage: useLocalPlayback,
    onMessage,
  });

  const { challenge } = props;
  const {
    applicant: { fullname = "", email = "" },
  } = submission;

  React.useEffect(() => {
    const keyEventLogs = resultDetail?.codingResult.keyEventLogs;
    if (Array.isArray(keyEventLogs) && keyEventLogs.length > 0) {
      setCurrentKeyEventLogId(keyEventLogs[keyEventLogs.length - 1]?.id);
    }
  }, [resultDetail, setCurrentKeyEventLogId]);

  React.useEffect(() => {
    // add currentKeyEventLog here because prevent undefined data has been sent.
    // postMessage is emitted right after session Id has changed.
    if (dataReady && playbackReady && currentKeyEventLog) {
      postMessage(MESSAGE_EVENT_CONTEXT, {
        language,
        readme,
        resultDetail,
        actionLogs,
        keyEventLog: currentKeyEventLog,
        previewContents: previewContents.map((item) => {
          const { settings, presignedFiles, ...rest } = item;
          return {
            ...rest,
            presignedFiles: getEditablePresignedFiles(
              presignedFiles,
              settings.files,
            ),
          };
        }),
      });
    }
  }, [
    language,
    dataReady,
    playbackReady,
    postMessage,
    readme,
    resultDetail,
    actionLogs,
    previewContents,
    currentKeyEventLog,
  ]);

  React.useEffect(() => {
    showIntercom ? Intercom.show() : Intercom.hide();
  }, [showIntercom]);

  // check if the key events of a challenge has been processed
  if (
    isNaN(challengeId) ||
    (!isResultDetailFetching &&
      resultDetail &&
      getKeyEventStatus(
        resultDetail.status,
        resultDetail.codingResult?.keyEventLogs,
      ) !== KeyEventStatus.Processed)
  ) {
    return <NotFound />;
  }

  return (
    <div className="code-code-playback">
      <CodePlaybackHeader
        challengeName={`${challenge?.title} (v${challenge?.currentVersion.majorVersionNumber}.${challenge?.currentVersion.minorVersionNumber})`}
        applicantName={`${fullname} (${email})`}
        selectedKeyEventLogId={currentKeyEventLogId}
        keyEventLogs={resultDetail?.codingResult.keyEventLogs ?? []}
        successfulTestcases={resultDetail?.successfulTestcases}
        totalTestcases={resultDetail?.totalTestcases}
        onChangeSelectedKeyEventLogId={setCurrentKeyEventLogId}
      />
      <main className="code-code-playback__body">
        <iframe
          id="code-playback"
          className="code-code-playback__iframe"
          src={
            useLocalPlayback
              ? "http://localhost:9001"
              : getPreviewURL("playback")
          }
          ref={iframeRef}
          allow="clipboard-write"
        />
      </main>
    </div>
  );
};
