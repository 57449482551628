import * as classnames from "classnames";

import { ValidationMessage, hasValidationError } from "..";

/**
 * Prop interface
 */
export interface FormGroupProps {
  centered?: boolean;
  fullWidth?: boolean;
  inline?: boolean;
  name?: string;
  error?: { [key: string]: string };
  /**
   * class names
   */
  className?: string;
  /**
   * form control
   */
  children?: {};
}

/**
 * React Component
 */
export default function FormGroup({
  centered,
  fullWidth = false,
  inline,
  className,
  children,
  error,
  name,
}: FormGroupProps) {
  const rootStyle = classnames("code-c-form-group", "field", {
    "is-inline-form": inline,
    "is-centered": centered,
    "is-full-width": fullWidth,
    [`${className}`]: Boolean(className),
    "is-danger": hasValidationError(name, error),
  });

  return (
    <div className={rootStyle}>
      {children}
      <ValidationMessage name={name} error={error} />
    </div>
  );
}
