import actionCreator from "../shared/services/actionCreator";
import { Action } from "./";

export interface ProjectAction extends Action {
  payload: {
    applicationType: number;
    projectId: number;
    userId: number;
    socialId?: number;
    integrationId?: number;
    // Optional: Skips reseting the members list. Only used after deleting a member
    skipReset?: boolean;
    // Optional params object if we need to chain an api call
    params?: {
      pagination: {};
      sortOrder: {
        column: string;
        direction: string;
      };
      keyword?: string;
      roles?: number[];
    };
  };
}

/*
 * temp roles fix
 */
export const projectMemberInviteAction = actionCreator(
  "projectMemberInviteAction",
);

export const projectMemberListGetAction = actionCreator(
  "projectMemberListGetAction",
);
export const projectMemberUpdateAction = actionCreator(
  "projectMemberUpdateAction",
);

export const projectSlackGetAction = actionCreator("projectSlackGetAction");

export const projectSlackRegistrationGetAction = actionCreator(
  "projectSlackRegistrationGetAction",
);

export const projectSocialGetAction = actionCreator("projectSocialGetAction");
export const projectSocialDeleteAction = actionCreator(
  "projectSocialDeleteAction",
);
export const projectSocialChannelsGetAction = actionCreator(
  "projectSocialChannelsGetAction",
);
export const projectSocialChannelAddAction = actionCreator(
  "projectSocialChannelAddAction",
);
export const projectSocialChannelUpdateAction = actionCreator(
  "projectSocialChannelUpdateAction",
);
export const projectSocialChannelMoveStep1Action = actionCreator(
  "projectSocialChannelMoveStep1Action",
);
export const projectSocialChannelMoveStep2Action = actionCreator(
  "projectSocialChannelMoveStep2Action",
);
export const projectSocialChannelDeleteAction = actionCreator(
  "projectSocialChannelDeleteAction",
);
export const projectSocialUserGetAction = actionCreator(
  "projectSocialUserGetAction",
);
export const projectSocialUserUpdateAction = actionCreator(
  "projectSocialUserUpdateAction",
);

export const projectListGetAction = actionCreator("projectListGetAction");
export const projectListAddAction = actionCreator("projectListAddAction");
export const projectListRemoveAction = actionCreator("projectListRemoveAction");

export const projectCreateAction = actionCreator("projectCreateAction");

export const projectUpdateAction = actionCreator("projectUpdateAction");

export const projectGetAction = actionCreator("projectGetAction");

export const projectDeleteAction = actionCreator("projectDeleteAction");

export const projectMemberListAddAction = actionCreator(
  "projectMemberListAddAction",
);

export const projectMemberCreateAction = actionCreator(
  "projectMemberCreateAction",
);

export const projectMemberDeleteAction = actionCreator(
  "projectMemberDeleteAction",
);

export const projectSetSwitcherAction = actionCreator(
  "projectSetSwitcherAction",
);

export const projectAddSwitcherAction = actionCreator(
  "projectAddSwitcherAction",
);

export const projectRemoveSwitcherAction = actionCreator(
  "projectRemoveSwitcherAction",
);

export const projectUpdateSwitcherAction = actionCreator(
  "projectUpdateSwitcherAction",
);

export const currentProjectSetAction = actionCreator("currentProjectSetAction");

export const projectErrorAction = actionCreator("projectErrorAction");

export const projectInviteListGetAction = actionCreator(
  "projectInviteListGetAction",
);
export const projectInviteResendAction = actionCreator(
  "projectInviteResendAction",
);
export const projectInviteUpdateAction = actionCreator(
  "projectInviteUpdateAction",
);
export const projectInviteDeleteAction = actionCreator(
  "projectInviteDeleteAction",
);

export const projectAuditLogGetAction = actionCreator(
  "projectAuditLogGetAction",
);
