import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";

import {
  SubmissionEvaluationStatus,
  deleteSubmissionEvaluation,
  putSubmissionEvaluation,
} from "@api/submissions";

import { useStoreContext } from "@context";

import Message from "@shared/services/message";

import { useInvalidateExamCountRelation } from "../exams";
import { useRouteParams } from "../useRouteParams";
import { useInvalidateSubmissionRelation } from "./useSubmission";

interface EvaluateSubmissionParams {
  submissionId: number;
  data: {
    status: SubmissionEvaluationStatus;
  };
}

export function useEvaluateSubmission() {
  const { projectId } = useStoreContext();
  const { examId } = useRouteParams();
  const invalidateSubmission = useInvalidateSubmissionRelation();
  const invalidateExamCount = useInvalidateExamCountRelation();

  const mutation = useMutation({
    mutationFn: ({ submissionId, data }: EvaluateSubmissionParams) =>
      putSubmissionEvaluation({ projectId, submissionId, options: { data } }),
    onSuccess: (_, { submissionId }) => {
      toast.success(
        Message.getMessageByKey("submission.updateEvaluationSuccess"),
      );
      invalidateSubmission(submissionId);
      invalidateExamCount(examId);
    },
    onError: () => {
      toast.error(Message.getMessageByKey("submission.updateEvaluationError"));
    },
  });

  return mutation;
}

export function useDeleteSubmissionEvaluation() {
  const { projectId } = useStoreContext();
  const { examId } = useRouteParams();
  const invalidateSubmission = useInvalidateSubmissionRelation();
  const invalidateExamCount = useInvalidateExamCountRelation();

  const mutation = useMutation({
    mutationFn: (submissionId: number) =>
      deleteSubmissionEvaluation({
        projectId,
        submissionId,
      }),
    onSuccess: (_, submissionId) => {
      toast.success(
        Message.getMessageByKey("submission.deleteEvaluationSuccess"),
      );
      invalidateSubmission(submissionId);
      invalidateExamCount(examId);
    },
    onError: () => {
      toast.error(Message.getMessageByKey("submission.deleteEvaluationError"));
    },
  });

  return mutation;
}
