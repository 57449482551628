import * as classnames from "classnames";
import * as React from "react";

import { Icon, Modal, Msg } from "@shared/components";
import { ModalProps } from "@shared/components/modal/Modal";
import { useDeliverOfficialExamSample } from "@shared/hooks/query";
import { UserModel } from "@shared/models";
import { dayjs } from "@shared/services/date";
import MSG from "@shared/services/message";

/**
 * Prop interface
 */
interface Props extends Pick<ModalProps, "isOpen" | "onClose"> {
  examId?: number;
  currentUser?: UserModel;
}

export default function OfficialExamDeliver({
  isOpen,
  examId,
  currentUser,
  onClose,
}: Props) {
  const deliverTest = useDeliverOfficialExamSample();
  const rootStyle = classnames("code-official-exam-deliver");
  const bannerLabel = MSG.getMessageByKey("delivery.test.banner");

  React.useEffect(() => {
    if (deliverTest.isSuccess) {
      onClose?.();
    }
  }, [deliverTest.isSuccess, onClose]);

  const onTestDelivery = () => {
    if (!currentUser) {
      return;
    }
    const {
      email: applicants,
      organization: { displayName: senderName },
    } = currentUser;
    const mailSubject = MSG.getMessageByKey("delivery.test");
    const mailBody = MSG.getMessageByKey("delivery.test.body");
    const deadline = dayjs().add(7, "days").startOf("minute").format();

    if (examId) {
      deliverTest.mutate({
        examId,
        data: {
          deadline,
          applicants,
          mailSubject,
          mailBody,
          senderName,
          noTransition: true,
        },
      });
    }
  };

  return (
    <Modal
      className={rootStyle}
      isOpen={isOpen}
      title={<Msg id="delivery.test" />}
      okButtonLabel={<Msg id="action.deliver" />}
      onClickCancel={onClose}
      onClose={onClose}
      onClickOk={onTestDelivery}
      okButtonAriaLabel="Deliver Exam"
      ariaLabel="Deliver Sample Exam"
    >
      <div className="code-official-exam-deliver__modal-banner">
        <Icon
          className="code-c-icon__text-base code-official-exam-deliver__modal-banner-icon"
          type="circle-info"
        />
        <p>{bannerLabel}</p>
      </div>
      <Msg id="delivery.test.confirm" />
      <p>
        <strong>{currentUser?.email}</strong>
      </p>
    </Modal>
  );
}
