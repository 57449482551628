class ApplicantReportCategoryScoresModel {
  public challengeId: number;
  public category: number;
  public totalQuestionsNumber: number;
  public applicantCorrectAnswers: number;
  public examAverageScore: number;
  public globalAverageScore: number;

  constructor(args?: {}) {
    Object.assign(this, args);
  }
}

export default ApplicantReportCategoryScoresModel;
