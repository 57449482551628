import { connect } from "react-redux";
import { Action, Dispatch } from "redux";

import { challengeMajorUpdateAction } from "@actions";

import { RootState } from "@reducers";

import { useExamPermissions } from "@shared/hooks";
import {
  useChallengePinList,
  useCreateChallengePin,
  useDeleteChallengePin,
  useInvalidateExamRelation,
} from "@shared/hooks/query";
import { getTierAllowedByRedux } from "@shared/services/tier";
import { withCustomHook } from "@shared/services/withCustomHook";

import ExamChallenges, { ExternalProps } from "./ExamChallenges";

const wrapper = withCustomHook(() => {
  const { enableViewCodePlayback, enableViewWebcam, enableApplicantActionLog } =
    useExamPermissions();
  const invalidateExam = useInvalidateExamRelation();
  const addPinChallenge = useCreateChallengePin();
  const deletePinChallenge = useDeleteChallengePin();
  const { data } = useChallengePinList();

  return {
    addPinChallenge,
    deletePinChallenge,
    enableViewCodePlayback,
    enableViewWebcam,
    enableApplicantActionLog,
    pinnedChallengeList: data?.challengeFavorites,
    invalidateExam,
  };
})(ExamChallenges);

const mapStateToProps = (state: RootState, props: ExternalProps) => {
  const isTierActionAllowed = getTierAllowedByRedux(state);

  return {
    currentUser: state.user.user,
    currentProjectId: state.project.currentProjectId,
    canEditPin:
      state.user.user?.canEditPin(state.project.currentProjectId) ?? false,
    isTierActionAllowed,
    pinnedChallengeList: state.challenge.pinnedChallengeList,
    loadingMajorUpdate: state.challenge.loadingMajorUpdate,
    errorMajorUpdate: state.challenge.errorMajorUpdate,
    ...props,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  challengeMajorUpdate: (challengeId: number, versionNumber: string) => {
    dispatch(
      challengeMajorUpdateAction.request({ versionNumber }, { challengeId }),
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(wrapper);
