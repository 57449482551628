import * as React from "react";
import { RouteComponentProps } from "react-router-dom";

import { DocumentTitle } from "@shared/components";
import { useIframeCommunication } from "@shared/hooks";
import {
  MESSAGE_EVENT_CONTEXT,
  MESSAGE_PREVIEW_CONTENTS,
  MESSAGE_SWITCH_HISTORY,
} from "@shared/services/events";
import { intercom } from "@shared/services/intercom";
import Message from "@shared/services/message";
import { getPreviewURL } from "@shared/services/preview";

import { getChallengeResultDisplayName } from "../PreviewUtil";
import { DiffViewerHeader } from "./DiffViewerHeader";
import { useDiffViewerData } from "./useDiffViewerData";

export type ExternalProps = RouteComponentProps<{
  projectId: string;
  examId: string;
  submissionId: string;
  challengeId: string;
}>;

interface InjectedProps {
  useLocalDiffViewer: boolean;
  examId: number;
  challengeId: number;
  applicantExamId: number;
  userLanguage?: string;
}
export type DiffViewerProps = ExternalProps & InjectedProps;

export type DiffViewerSelection = {
  originalHistoryId?: number;
  modifiedHistoryId?: number;
};

const DiffViewer = (props: DiffViewerProps) => {
  const diffViewerRef = React.useRef<HTMLIFrameElement>(null);
  const [selectedHistoryId, setSelectedHistoryId] =
    React.useState<DiffViewerSelection>({
      originalHistoryId: undefined,
      modifiedHistoryId: undefined,
    });

  const { useLocalDiffViewer } = props;
  const switchHistoryHandler = ({ data: { name, data } }: MessageEvent) => {
    if (name === MESSAGE_SWITCH_HISTORY) {
      setSelectedHistoryId({
        originalHistoryId: data.originalHistoryId,
        modifiedHistoryId: data.modifiedHistoryId,
      });
    }
  };
  const { postMessage, ready: diffViewerReady } = useIframeCommunication({
    ref: diffViewerRef,
    useDangerousPostMessage: useLocalDiffViewer,
    onMessage: switchHistoryHandler,
  });
  const {
    challengeResultPreview,
    preparedPreviewContext,
    originalPreviewContent,
    modifiedPreviewContent,
  } = useDiffViewerData({ ...props, ...selectedHistoryId });

  // send context to diffViewer iframe
  React.useEffect(() => {
    if (
      diffViewerReady && // wait for diffViewer iframe to be "ready"
      preparedPreviewContext // wait for data to be ready
    ) {
      postMessage(MESSAGE_EVENT_CONTEXT, preparedPreviewContext);
    }
  }, [diffViewerReady, postMessage, preparedPreviewContext]);

  // send preview contents to diffViewer iframe
  React.useEffect(() => {
    if (
      diffViewerReady && // wait for diffViewer iframe to be "ready"
      originalPreviewContent &&
      modifiedPreviewContent // wait for data to be ready
    ) {
      postMessage(MESSAGE_PREVIEW_CONTENTS, {
        originalPreviewContent,
        modifiedPreviewContent,
      });
    }
  }, [
    diffViewerReady,
    modifiedPreviewContent,
    originalPreviewContent,
    postMessage,
    preparedPreviewContext,
  ]);

  React.useEffect(() => {
    intercom.updateIntercomInfo({
      alignment: "right",
    });

    return () => {
      intercom.updateIntercomInfo({
        alignment: "left",
      });
    };
  }, []);

  return (
    <div className="code-diff-viewer">
      <DocumentTitle page={Message.getMessageByKey("submission.codeDiff")} />
      <DiffViewerHeader
        examName={challengeResultPreview?.examName ?? ""}
        challengeName={challengeResultPreview?.context?.title ?? ""}
        applicantName={getChallengeResultDisplayName(challengeResultPreview)}
        totalTestcases={challengeResultPreview?.totalTestcases ?? 0}
        successfulTestcases={challengeResultPreview?.successfulTestcases ?? 0}
      />
      <iframe
        id="diff-viewer"
        ref={diffViewerRef}
        className="code-diff-viewer__iframe"
        // TODO: use proxy config (scripts/proxy.js) instead for local development
        src={
          useLocalDiffViewer ? "http://localhost:9001" : getPreviewURL("diff")
        }
      />
    </div>
  );
};

export default DiffViewer;
