import * as classnames from "classnames";

import {
  Msg,
  Form,
  Table,
  TableHead,
  HeaderColumn,
  TableBody,
  Row,
  Column,
  VersionCodeTag,
  Tooltip,
  Radio,
  LanguageTag,
} from "@shared/components";
import { ExamChallengeModel, MultiManageForm } from "@shared/models";
import { cannotRescore } from "@shared/services/challengeStatus";
import { ChallengeStyle } from "@shared/services/enums";
import Message from "@shared/services/message";

/**
 * Interface
 */
export type MultipleManageModalChallengeProps = {
  formValues: MultiManageForm;
  isShow: boolean;
  examChallenges: ExamChallengeModel[];
  isMultiLang: boolean;

  onFormChange: (valid: boolean, value: MultiManageForm, errors?: {}) => void;
};

/**
 * Component
 * @param props MultipleManageModalChallengeProps
 */
export function MultipleManageModalChallenge({
  formValues: { action, challenge },
  isShow,
  examChallenges,
  onFormChange,
  isMultiLang,
}: MultipleManageModalChallengeProps) {
  const rootStyle = classnames("form-group-container", {
    "is-show": isShow,
  });

  /**
   * Render
   */
  return (
    <div className={rootStyle}>
      <p className="code-multiple-manage-modal__text">
        <Msg id="submission.message.selectChallenge" />
      </p>
      <Form
        validation={{ challenge: ["required"] }}
        initialValues={{
          challenge: challenge?.challengeId.toString(),
        }}
        onFormChange={(valid, value: { challenge: string }, error) =>
          onFormChange(valid, {
            ...value,
            challenge: examChallenges.find(
              (challenge) => challenge.challengeId === Number(value.challenge),
            ),
          })
        }
      >
        <Table>
          <TableHead>
            <HeaderColumn size={1}></HeaderColumn>
            <HeaderColumn size={8}>
              <Msg id={"common.title"} />
            </HeaderColumn>
            <HeaderColumn size={3}>
              <Msg id={"challenge.style"} />
            </HeaderColumn>
          </TableHead>
          <TableBody>
            {examChallenges.map((challenge) => {
              const radioButton = (
                <Radio
                  defaultValue={challenge.challengeId.toString()}
                  readOnly={cannotRescore(challenge.style, action)}
                  className="code-change-deadline__checkbox"
                  name="challenge"
                />
              );
              return (
                <Row key={challenge.challengeId}>
                  <Column className="align-center">
                    {cannotRescore(challenge.style, action) ? (
                      <div className="checkbox-wrapper">
                        {radioButton}
                        <Tooltip
                          text={Message.getMessageByKey(
                            "submission.tooltip.selectChallengeStyle",
                          )}
                          placement="top-start"
                        />
                      </div>
                    ) : (
                      radioButton
                    )}
                  </Column>
                  <Column className="title-column">
                    {isMultiLang && (
                      <LanguageTag
                        language={challenge.originalChallenge?.language}
                        color="pink"
                      />
                    )}
                    <div>{challenge.title}</div>
                    <VersionCodeTag
                      version={`${challenge.usedChallengeVersionCode}`}
                    />
                  </Column>
                  <Column>{ChallengeStyle.toString(challenge.style)}</Column>
                </Row>
              );
            })}
          </TableBody>
        </Table>
      </Form>
    </div>
  );
}
