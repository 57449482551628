import { connect } from "react-redux";
import { Action, Dispatch } from "redux";

import {
  projectMemberListGetAction,
  projectGetAction,
  invitationStatusGetAction,
} from "@actions";

import { useLocation } from "@context";

import { RootState } from "@reducers";

import { useCreateExam, useOfficialExam } from "@shared/hooks/query";
import { ExamModel } from "@shared/models";
import PaginationModel from "@shared/models/Pagination.model";
import { IncludeInvitedQueryString } from "@shared/services/enums";
import { getTierAllowedByRedux } from "@shared/services/tier";
import { withCustomHook } from "@shared/services/withCustomHook";

import { ExamCreateNew } from "./ExamCreateNew";
import { ExternalProps } from "./types";

const wrapper = withCustomHook(() => {
  const { state = {} } = useLocation();
  const { data: examDetail = new ExamModel() } = useOfficialExam(
    state.officialExamId || 0,
  );
  const createExam = useCreateExam();

  const onCreateNew = (examPayload: any) => {
    createExam.mutate(examPayload);
  };
  return {
    examDetail,
    onCreateNew,
  };
})(ExamCreateNew);

const mapStateToProps = (state: RootState, props: ExternalProps) => ({
  currentProjectId: state.project.currentProjectId,
  invitationStatus: state.orgs.invitationStatus,
  projectMemberListLoading: state.project.projectMemberListLoading,
  projectMembers: state.project.projectMemberList,
  submitting: Boolean(
    state.ajax.submitting &&
      !state.challenge.loadingList &&
      !state.challenge.loadingDetail &&
      !state.project.projectMemberListLoading,
  ),
  uploadingImage: state.imageUpload.uploading,
  isTierActionAllowed: getTierAllowedByRedux(state),
  ...props,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  getInvitationStatus: () => {
    dispatch(invitationStatusGetAction.request());
  },
  getProjectMembers: (pagination: PaginationModel, keyword: string) => {
    dispatch(
      projectMemberListGetAction.request(
        {},
        {
          ...pagination,
          onlyReviewers: true,
          includeInvited: IncludeInvitedQueryString.NonExpired,
          keyword,
        },
      ),
    );
  },
  getProjectDetails: (projectId: number) => {
    dispatch(projectGetAction.request({ projectId }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(wrapper);
