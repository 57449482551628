import IntlProvider from "./components/IntlProvider";
import { Provider } from "./components/Provider";
import { IntlAction, IntlState } from "./types";

export * from "./types";

export { IntlProvider, Provider };

const UPDATE = "@@intl/UPDATE";

export const updateIntl = ({ locale, formats, messages }: IntlState) => ({
  type: UPDATE,
  payload: { locale, formats, messages },
});

export const initialState: IntlState = {
  locale: "en",
  messages: {},
};

export function intlReducer(state = initialState, action: IntlAction) {
  if (action.type !== UPDATE) {
    return state;
  }

  return { ...state, ...action.payload };
}
