import * as classnames from "classnames";
import * as React from "react";

import {
  Msg,
  Block,
  LeftBlock,
  RightBlock,
  BlockItem,
  PageTitle,
  Restricted,
  Button,
  Icon,
} from "../../../../../../shared/components";
import { ProjectRole, UserRole } from "../../../../../../shared/services/enums";
import Message from "../../../../../../shared/services/message";

/**
 * Props
 */
export interface ExamListHeaderProps {
  status: string;
  count: number;
  onClickNewExam: () => void;
}

export const ExamListHeader: React.FunctionComponent<ExamListHeaderProps> = (
  props: ExamListHeaderProps,
) => {
  const { status, count } = props;
  const rootStyle = classnames("code-exam-list-header");
  return (
    <Block className={rootStyle}>
      <LeftBlock>
        <PageTitle>
          <Msg id="common.examList" />
          <span className="code-exam-list-header__count">
            {Message.getSearchResultFormat(count)}
          </span>
        </PageTitle>
      </LeftBlock>
      <RightBlock>
        <BlockItem>
          <Restricted
            roles={[
              ProjectRole.ExamCreator,
              ProjectRole.ProjectAdmin,
              UserRole.SystemAdmin,
            ]}
            strictAllow={status === "active"}
          >
            <Button
              type="primary"
              onClick={props.onClickNewExam}
              ariaLabel="Create New Exam"
            >
              <Icon type="plus" />
              <Msg id="button.createExam" />
            </Button>
          </Restricted>
        </BlockItem>
      </RightBlock>
    </Block>
  );
};
