import * as classnames from "classnames";

import { StackedIcon } from "../stackedIcon/StackedIcon";

export function EvaluateIcon({ className }: { className?: string }) {
  const rootStyle = classnames("code-c-evaluate-icon", {
    [`${className}`]: Boolean(className),
  });

  return (
    <StackedIcon
      className={rootStyle}
      icons={[
        { type: "magnifying-glass", variant: "solid", size: "large" },
        { type: "check", variant: "solid", size: "normal" },
      ]}
    />
  );
}
