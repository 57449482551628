import React, { forwardRef } from "react";

import { FormGroup, Label, Select, Msg } from "@shared/components";
import { SpokenLanguages } from "@shared/services/enums";

export interface LanguageSelectProps {
  name: string;
  className?: string;
  disabled?: boolean;
  "data-testid"?: string;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLSelectElement>) => void;
  value?: number | string;
}

const LanguageSelect = forwardRef<HTMLSelectElement, LanguageSelectProps>(
  (props, ref) => {
    const {
      className,
      disabled,
      "data-testid": dataTestId,
      name,
      onChange,
      onBlur,
      value,
    } = props;

    const uiLanguages = Object.values(SpokenLanguages).filter(
      (lang) => typeof lang === "string",
    );

    return (
      <div className={className}>
        <FormGroup>
          <Label>
            <Msg id="common.language" />
          </Label>
          <Select
            options={uiLanguages.map((key) => ({
              label: SpokenLanguages.toString(key as SpokenLanguages),
              value: key as SpokenLanguages,
            }))}
            name={name}
            data-testid={dataTestId}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            disabled={disabled}
            forwardedRef={ref}
          />
        </FormGroup>
      </div>
    );
  },
);

export default LanguageSelect;
