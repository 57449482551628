import * as classnames from "classnames";
import * as React from "react";

/**
 * Prop interface
 */
export interface JumpToProps {
  to: string;
  className?: string;
  options?: {
    stopPropagation?: boolean;
  };
  children?: React.ReactNode;
  role?: string;
}

/**
 * React Component
 */
export default function JumpTo({
  to,
  role,
  className,
  options,
  children,
}: JumpToProps) {
  const rootStyle = classnames("code-c-jump-to", {
    [`${className}`]: Boolean(className),
  });

  return (
    <a
      className={rootStyle}
      target="_blank"
      href={to}
      rel="noreferrer noopener"
      role={role}
      {...(options?.stopPropagation && {
        onClick: (e: React.FormEvent) => e.stopPropagation(),
      })}
    >
      {children}
    </a>
  );
}
