import * as React from "react";
import { RouteComponentProps } from "react-router-dom";

import { useLocalStorage } from "@shared/hooks";
import { UserModel } from "@shared/models";
import { CURRENT_PROJECT_ID } from "@shared/services/localStorage";

export type ProjectIdObserverProps = {
  user?: UserModel;
} & RouteComponentProps;

const ProjectIdObserver = ({ location, user }: ProjectIdObserverProps) => {
  const [currentProjectIds, setCurrentProjectIds] = useLocalStorage(
    CURRENT_PROJECT_ID,
    {},
  );

  React.useEffect(() => {
    const projectId = parseInt(location.pathname.split("/")[2], 10);
    if (!user?.id || isNaN(projectId)) {
      return;
    }

    currentProjectIds[user.id] = projectId;
    setCurrentProjectIds(currentProjectIds);
  }, [currentProjectIds, location.pathname, setCurrentProjectIds, user?.id]);

  return null;
};

export default ProjectIdObserver;
