import classnames from "classnames";
import * as React from "react";

interface FadeProps {
  children: React.ReactNode;
  show?: boolean;
}

const Fade = React.forwardRef<HTMLDivElement, FadeProps>(
  ({ children, show }: FadeProps, ref: React.ForwardedRef<HTMLDivElement>) => {
    const [isShow, setIsShow] = React.useState(show);
    const [isActive, setIsActive] = React.useState(false);
    const timerRef = React.useRef<NodeJS.Timeout | null>(null);
    const rootStyle = classnames("code-c-fade", {
      active: isActive,
      show: isShow,
    });

    // Fade in
    React.useLayoutEffect(() => {
      if (show) {
        if (timerRef.current) {
          clearTimeout(timerRef.current);
        }

        setIsShow(true);
        requestAnimationFrame(() => setIsActive(true));
      }
    }, [show]);

    // Fade out
    React.useLayoutEffect(() => {
      if (!show && isActive) {
        requestAnimationFrame(() => setIsActive(false));
        timerRef.current = setTimeout(() => {
          setIsShow(false);
        }, 750);
      }

      return () => {
        if (timerRef.current) {
          clearTimeout(timerRef.current);
        }
      };
    }, [show, isActive]);

    if (!isShow) {
      return null;
    }

    return (
      <div ref={ref} className={rootStyle}>
        {children}
      </div>
    );
  },
);

Fade.displayName = "Fade";

export default Fade;
