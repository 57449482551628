import * as classnames from "classnames";
import { useState, useEffect, useRef } from "react";

/**
 * Prop interface
 */
export interface ExpandContentsProps {
  className?: string;
  onChange?: (event: React.FormEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FormEvent<HTMLInputElement>) => void;
  children?: React.ReactNode;
  expanded: boolean;
  bordered?: boolean;
  readOnly?: boolean;
}

/**
 * React Component
 */
export default function ExpandContents({
  onChange,
  onBlur,
  children,
  className,
  expanded,
  bordered,
  readOnly = false,
}: ExpandContentsProps) {
  const [isExpanded, setIsExpanded] = useState(expanded);
  const [contentHeight, setContentHeight] = useState<string | undefined>(
    undefined,
  );
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setIsExpanded(expanded);
  }, [expanded]);

  const handleContentHeight = () => {
    if (contentRef.current) {
      setContentHeight(`${contentRef.current.scrollHeight + 2}px`); // Add 2px to account for border/margin
    }
  };

  // Get the height of the content so we can animate it with CSS
  useEffect(() => {
    handleContentHeight();
  }, [children]);

  // Also get the height of the content when the component is first expanded as the height may not be correct when it is hidden
  useEffect(() => {
    const contentElement = contentRef.current;
    if (contentElement) {
      contentElement.addEventListener("transitionend", handleContentHeight);
    }
    return () => {
      if (contentElement) {
        contentElement.removeEventListener(
          "transitionend",
          handleContentHeight,
        );
      }
    };
  }, [isExpanded]);

  const rootStyle = classnames("code-c-expand-contents", {
    [`${className}`]: Boolean(className),
    "is-expanded": isExpanded,
    "is-bordered": bordered,
    "is-readonly": readOnly,
  });

  return (
    <div
      className={rootStyle}
      style={
        {
          "--content-height": contentHeight,
        } as React.CSSProperties
      }
    >
      <div
        ref={contentRef}
        className={classnames("code-c-expand-contents__contents", {
          "is-visible": isExpanded,
        })}
      >
        {children}
      </div>
    </div>
  );
}
