import * as classnames from "classnames";
import * as React from "react";

import { ErrorMessage, ValidationMessage, hasValidationError } from "..";

/**
 * Prop interface
 */
export interface RadioProps {
  name?: string;
  defaultValue?: string;
  value?: string;
  checked?: boolean; // Used when we want to set the checked status explicitly or programmatically from the parent component
  readOnly?: boolean;
  className?: string;
  error?: { [key: string]: string };
  errorMessage?: string;
  onChange?: (event: React.FormEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FormEvent<HTMLInputElement>) => void;
  children?: {};
  labelClassName?: string;
  centered?: boolean;
  ariaLabel?: string;
  ariaPressed?: boolean;
  role?: string;
  dataTestautomationid?: string;
}

/**
 * React Component
 */
const Radio = React.forwardRef<HTMLInputElement, RadioProps>(
  (
    {
      name,
      value,
      defaultValue,
      checked,
      readOnly,
      onChange,
      onBlur,
      error,
      errorMessage,
      className,
      children,
      labelClassName,
      centered,
      ariaLabel,
      ariaPressed,
      role,
      dataTestautomationid,
    }: RadioProps,
    ref,
  ) => {
    const isChecked = checked !== undefined ? checked : value === defaultValue;
    const rootStyle = classnames("code-c-radio", "radio", "is-new", {
      [`${className}`]: Boolean(className),
      "is-checked": isChecked,
    });

    const labelStyle = classnames("code-c-radio__label", labelClassName, {
      "is-readonly": readOnly,
    });

    const radioStyle = classnames("code-c-radio__radio", {
      "is-danger": hasValidationError(name, error),
      "is-centered": centered,
    });

    return (
      <div className={rootStyle}>
        <label
          className={labelStyle}
          aria-label={ariaLabel}
          aria-pressed={role === "button" ? ariaPressed : undefined}
          role={role}
        >
          <input
            className={radioStyle}
            type="radio"
            value={defaultValue}
            name={name}
            checked={ref ? undefined : isChecked}
            onChange={onChange}
            onBlur={onBlur}
            disabled={readOnly}
            readOnly={readOnly}
            ref={ref}
            data-testautomationid={dataTestautomationid}
          />
          <span className="code-c-radio__checkmark-container">
            <span className="code-c-radio__checkmark" />
          </span>
          <div className="code-c-radio__label__text">{children}</div>
        </label>
        <ValidationMessage name={name} error={error} />
        <ErrorMessage message={errorMessage} />
      </div>
    );
  },
);

Radio.displayName = "Radio";

export default Radio;
