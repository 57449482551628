export enum IconType {
  Pencil = "pencil",
  Trash = "trash",
  Plus = "plus",
  Copy = "copy",
  Repeat = "repeat",
  User = "user",
  UserPlus = "user-plus",
  Link = "link",
  Check = "check",
  ExclamationTriangle = "exclamation-triangle",
  Print = "print",
  FileText = "file-text",
  PaperPlane = "paper-plane",
  ClockO = "clock-o",
  Download = "download",
  AngleDown = "angle-down",
  AngleUp = "angle-up",
  AngleRight = "angle-right",
  AngleLeft = "angle-left",
  InfoCircle = "info-circle",
  File = "file",
  Wrench = "wrench",
  Key = "key",
  PlusCircle = "plus-circle",
  ArrowUp = "arrow-up",
  ArrowDown = "arrow-down",
  ArrowLeft = "arrow-left",
  ArrowRight = "arrow-right",
  ThumbTack = "thumb-tack",
  Exchange = "exchange",
  Bars = "bars",
  ExternalLink = "external-link",
  Times = "times",
  Inbox = "inbox",
  Recycle = "recycle",
  Lock = "lock",
  Hashtag = "hashtag",
  BoxArchive = "box-archive",
  FileExport = "file-export",
  CommentDots = "comment-dots",
  Close = "close",
  TimesCircle = "times-circle",
  Comment = "comment",
  Ban = "ban",
  CircleThin = "circle-thin",
  FlagCheckered = "flag-checkered",
  Table = "table",
  Cog = "cog",
  CaretDown = "caret-down",
  CaretUp = "caret-up",
  SignOut = "sign-out",
  Home = "home",
  ChartSimple = "chart-simple",
  Desktop = "desktop",
  Building = "building",
  ExpandAlt = "expand-alt",
  CompressAlt = "compress-alt",
  Envelope = "envelope",
  EyeSlash = "eye-slash",
  Header = "header",
  Bold = "bold",
  Italic = "italic",
  ListUl = "list-ul",
  ListOl = "list-ol",
  Image = "image",
  Search = "search",
  Warning = "warning",
  Play = "play",
  ForwardFast = "forward-fast",
  Code = "code",
  NoteSticky = "note-sticky",
  EllipsisVeritcal = "ellipsis-vertical",
  PenToSquare = "pen-to-square",
  ChevronDown = "chevron-down",
  ChevronUp = "chevron-up",
  ShieldHalved = "shield-halved",
  Video = "video",
  Slash = "slash",
  Circle = "circle",
  CircleExclamation = "circle-exclamation",
}

export enum IconVariant {
  Solid = "solid",
  Regular = "regular",
}
