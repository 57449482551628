import * as classnames from "classnames";
import { isEqual } from "lodash";
import * as React from "react";

import { Form, SubMenu, FormGroup, Input } from "..";
import { FormProps } from "../form/Form";

/**
 * Prop interface
 */
export interface ContentsSearchFormProps {
  onChange?: FormProps["onFormChange"];
  initialValues?: {};
  className?: string;
  noKeyword?: boolean;
  clear?: boolean;
  placeholder?: string;
}

export interface ContentsSearchFormState {
  formValues: {};
}

/**
 * React Component
 * @deprecated this component is buggy. don't use anymore!
 */
export default class MembersSearchForm extends React.Component<
  ContentsSearchFormProps,
  ContentsSearchFormState
> {
  constructor(props: ContentsSearchFormProps) {
    super(props);

    this.state = {
      formValues: props.initialValues || {},
    };
  }

  public componentDidUpdate(prevProps: ContentsSearchFormProps) {
    if (this.props.clear && !prevProps.clear) {
      this.setState({
        formValues: this.props.initialValues || {},
      });
    }
  }

  public shouldComponentUpdate(
    nextProps: ContentsSearchFormProps,
    nextState: ContentsSearchFormState,
  ) {
    return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state);
  }

  public render() {
    const { className = [], initialValues = {}, clear } = this.props;

    const rootStyle = classnames("code-c-members-search-form", {
      [`${className}`]: Boolean(className),
    });

    return (
      <SubMenu className={rootStyle}>
        <Form
          validation={{
            keyword: ["string"],
          }}
          onFormChange={this.onFormChange}
          initialValues={initialValues}
          clear={clear}
        >
          {!this.props.noKeyword && (
            <FormGroup>
              <Input name="keyword" placeholder={this.props.placeholder} />
            </FormGroup>
          )}
        </Form>
      </SubMenu>
    );
  }

  private onFormChange = (formValid: boolean, formValues: {}) => {
    this.setState(
      {
        formValues: { ...this.state.formValues, ...formValues },
      },
      () =>
        this.props.onChange &&
        this.props.onChange(formValid, this.state.formValues),
    );
  };
}
