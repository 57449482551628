import { UserInvitationModel } from "@shared/models";

import {
  Action,
  APIResponseAction,
  authInvitationAction,
  authLocalSigninAction,
  authSetAction,
  authSignOutAction,
} from "../actions";
import * as AuthService from "../shared/services/auth";

export interface AuthState {
  authenticated: boolean;
  error: string;
  errorCode?: number; // http status code on error
  success: boolean;
  invitation?: UserInvitationModel;
  invitationSuccess: boolean;
  invitationError: boolean;
  signingIn: boolean;
  signedOut: boolean;
}

const initialState = {
  authenticated: false,
  error: "",
  success: false,
  invitationSuccess: false,
  invitationError: false,
  signingIn: false,
  signedOut: false,
};

export const authReducer = (
  state: AuthState = initialState,
  action: Action,
): AuthState => {
  const payload = (action as APIResponseAction).payload || {};

  switch (action.type) {
    case authSetAction.types.request:
      return {
        ...state,
        signingIn: false,
        authenticated: Boolean((action as APIResponseAction).payload),
        errorCode: undefined,
      };

    case authSetAction.types.failure: {
      const message = action.payload as string;
      return {
        ...state,
        signingIn: false,
        ...AuthService.getErrorObject(message),
      };
    }

    case authSetAction.types.success:
      return {
        ...state,
        signingIn: false,
        success: true,
        errorCode: undefined,
      };

    case authInvitationAction.types.request: {
      return {
        ...state,
        invitationSuccess: false,
        invitationError: false,
      };
    }

    case authInvitationAction.types.success: {
      return {
        ...state,
        invitation: payload.result as UserInvitationModel,
        invitationSuccess: true,
        invitationError: false,
      };
    }

    case authInvitationAction.types.failure: {
      return {
        ...state,
        invitationSuccess: false,
        invitationError: true,
      };
    }

    case authLocalSigninAction.types.request: {
      return {
        ...state,
        error: "",
      };
    }

    case authLocalSigninAction.types.failure: {
      return {
        ...state,
        error: "error",
      };
    }

    case authSignOutAction.types.request: {
      return { ...initialState, signedOut: true };
    }

    default:
      return state;
  }
};
