import * as classnames from "classnames";
import { isEqual } from "lodash";
import * as React from "react";
import { BehaviorSubject } from "rxjs/BehaviorSubject";
import { Subscription } from "rxjs/Subscription";

import MSG from "@shared/services/message";

import { Input } from "..";

export interface SelectOption {
  value: string | number;
  label: string;
}

export interface SearchSelectProps {
  inputClassName?: string;
  className?: string;

  options?: SelectOption[];
  selected?: string | number;

  onClearClicked?: () => void;
  onChange?: (selected: SelectOption) => void;
  placeholder?: string;
  clear?: boolean;
  autoWidth?: boolean;
}

export interface SearchSelectState {
  filteredOptions: SelectOption[];
  selectedIndex: number;

  selected?: string | number;
  value: string;
  show?: boolean;
}

export default class SearchSelect extends React.Component<
  SearchSelectProps,
  SearchSelectState
> {
  private onChange$: BehaviorSubject<string>;
  private subscription: Subscription;
  private inputElement: HTMLDivElement;
  private dropdownElement: HTMLUListElement;

  constructor(props: SearchSelectProps) {
    super(props);

    this.state = {
      filteredOptions: [],
      value: "",
      selected: props.selected,
      selectedIndex: -1,
    };
    this.onChange$ = new BehaviorSubject("");
  }

  public componentDidMount() {
    this.subscription = this.onChange$
      .debounceTime(300)
      .subscribe(this.renderOptions);
  }

  public componentWillUnmount() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  public componentDidUpdate(prevProps: SearchSelectProps) {
    if (!isEqual(this.props.selected, prevProps.selected)) {
      this.setState({
        selected: this.props.selected,
      });
    } else if (this.props.clear && !prevProps.clear) {
      this.setState({
        filteredOptions: [],
        value: "",
        selected: this.props.selected,
        selectedIndex: -1,
      });
    }
  }

  public shouldComponentUpdate(
    nextProps: SearchSelectProps,
    nextState: SearchSelectState,
  ) {
    return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state);
  }

  public render() {
    const rootStyle = classnames("code-c-search-select", {
      "code-c-search-select__auto-width": Boolean(this.props.autoWidth),
      [`${this.props.className}`]: Boolean(this.props.className),
    });

    const inputWrapperStyle = classnames(
      "input",
      "code-c-search-select__input-wrapper",
      { "is-active": this.state.filteredOptions.length > 0 },
      this.props.inputClassName,
    );

    const inputStyle = classnames(
      "code-c-search-select__input",
      this.props.inputClassName,
    );

    const clearBtnStyle = classnames(
      {
        "code-c-search-select__clear-hidden":
          typeof this.props.selected === "undefined",
      },
      "delete",
    );

    const selectDropdown = this.state.filteredOptions.map(
      (option: SelectOption, index: number) => (
        <li
          className={classnames("code-c-search-select__option clamp-1", {
            "code-c-search-select__option-selected":
              this.state.selectedIndex === index,
          })}
          key={index}
          onMouseDown={() => this.onSelect(option)}
        >
          {option.label}
        </li>
      ),
    );

    const selectedOption = this.props.options
      ? this.props.options.find(
          (option) => option.value === this.state.selected,
        )
      : undefined;

    const value = this.state.show
      ? this.state.value
      : selectedOption
      ? selectedOption.label
      : "";

    return (
      <div className={rootStyle}>
        <div
          className={inputWrapperStyle}
          ref={(r: HTMLInputElement) => {
            this.inputElement = r;
          }}
        >
          <Input
            spellcheck={false}
            inputClassName={inputStyle}
            hasDefaultCss={false}
            value={value}
            onChange={this.onInput}
            onEnter={this.onEnter}
            onKeyDown={this.onKeyDown}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            placeholder={
              this.props.placeholder ?? MSG.getMessageByKey("action.search")
            }
          />
          {this.props.onClearClicked && (
            <button
              className={clearBtnStyle}
              aria-label="Close"
              onClick={this.onClearClicked}
            />
          )}
        </div>
        {this.state.filteredOptions.length > 0 && (
          <ul
            className="code-c-search-select__dropdown"
            style={{
              width: this.props.autoWidth
                ? "auto"
                : this.inputElement?.offsetWidth ?? "auto",
            }}
            ref={(r: HTMLUListElement) => {
              this.dropdownElement = r;
            }}
          >
            {selectDropdown}
          </ul>
        )}
      </div>
    );
  }

  private onSelect = (option: SelectOption) => {
    this.setState(
      {
        value: "",
        selected: option.value,
        show: false,
      },
      () => this.renderOptions(""),
    );

    if (this.props.onChange) {
      this.props.onChange(option);
    }
  };

  private onInput = (e: React.FormEvent<HTMLInputElement>) => {
    const input = e.target as HTMLInputElement;
    this.setState({ value: input.value }, () =>
      this.renderOptions(input.value),
    );
  };

  private onEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      this.state.selectedIndex > -1 &&
      this.state.filteredOptions.length > 0
    ) {
      this.onSelect(this.state.filteredOptions[this.state.selectedIndex]);
    }
    (e.target as HTMLInputElement).blur();
  };

  private scrollParentToChild = (parent: Element, child: Element) => {
    // Where is the parent on page
    const parentRect = parent.getBoundingClientRect();
    // What can you see?
    const parentViewableArea = {
      height: parent.clientHeight,
      width: parent.clientWidth,
    };

    // Where is the child
    const childRect = child.getBoundingClientRect();
    // Is the child viewable?
    const isViewable =
      childRect.top >= parentRect.top &&
      childRect.bottom <= parentRect.top + parentViewableArea.height;

    // if you can't see the child try to scroll parent
    if (!isViewable) {
      // Should we scroll using top or bottom? Find the smaller ABS adjustment
      const scrollTop = childRect.top - parentRect.top;
      const scrollBot = childRect.bottom - parentRect.bottom;
      if (Math.abs(scrollTop) < Math.abs(scrollBot)) {
        // we're near the top of the list
        parent.scrollTop += scrollTop;
      } else {
        // we're near the bottom of the list
        parent.scrollTop += scrollBot;
      }
    }
  };

  private onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const key = e.key;
    let selectedIndex = this.state.selectedIndex;

    switch (key) {
      case "ArrowUp":
        selectedIndex = Math.max(0, selectedIndex - 1);
        e.preventDefault();
        break;
      case "ArrowDown":
        selectedIndex = Math.min(
          this.state.filteredOptions.length - 1,
          selectedIndex + 1,
        );
        e.preventDefault();
        break;
      default:
        break;
    }

    if (this.dropdownElement) {
      const child = this.dropdownElement.children[selectedIndex];

      if (child) {
        this.scrollParentToChild(this.dropdownElement, child);
      }
    }

    this.setState({ selectedIndex });
  };

  private onFocus = (e: React.FormEvent<HTMLInputElement>) => {
    this.setState(
      {
        show: true,
      },
      () => this.renderOptions(""),
    );
  };

  private onBlur = (e: React.FormEvent<HTMLInputElement>) => {
    this.setState(
      {
        show: false,
        value: "",
      },
      () => this.renderOptions(""),
    );
  };

  private onClearClicked = (e: React.FormEvent<HTMLButtonElement>) => {
    this.setState({
      value: "",
      selected: undefined,
      show: false,
      filteredOptions: [],
    });

    this.props.onClearClicked?.();
  };

  private renderOptions = (search: string) => {
    const filteredOptions =
      this.props.options && this.state.show
        ? this.props.options.filter((option) =>
            option.label.toLowerCase().includes(search.toLowerCase()),
          )
        : [];

    this.setState({
      filteredOptions,
      selectedIndex: -1,
    });
  };
}
