import * as classnames from "classnames";
import { XYCoord } from "dnd-core";
import * as React from "react";
import { useRef, FunctionComponent } from "react";
import { useDrag, useDrop, DropTargetMonitor } from "react-dnd";

import * as DragAndDropService from "../../services/dragAndDrop";

/**
 * Props
 */
export interface IDraggableItemProps {
  accept?: string;
  index: number;
  item: React.ComponentType | JSX.Element;
  id: number;
  onDrag: (dragIndex: number, hoverIndex: number) => void;
  onDrop: () => void;
}

export const DraggableItem: FunctionComponent<IDraggableItemProps> = (
  props: IDraggableItemProps,
) => {
  const { id, item, index, accept = "dragTarget" } = props;
  const rootStyle = classnames("code-c-draggable-item");
  const elementRef = useRef<HTMLDivElement>(null);

  const [{ handlerId }, drop] = useDrop({
    accept,
    collect(monitor) {
      return { handlerId: monitor.getHandlerId() };
    },
    hover(
      item: {
        index: number;
        id: string;
        type: string;
      },
      monitor: DropTargetMonitor,
    ) {
      if (!elementRef.current) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;
      const hoverBoundingRect = elementRef.current.getBoundingClientRect();
      const clientOffset = monitor.getClientOffset();

      if (
        DragAndDropService.isDraggableItem(
          hoverIndex,
          dragIndex,
          (clientOffset as XYCoord).y,
          hoverBoundingRect,
        )
      ) {
        props.onDrag(dragIndex, hoverIndex);
        item.index = hoverIndex;
      } else {
        return;
      }
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: accept,
    item: () => ({ id, index }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: () => {
      props.onDrop();
    },
  });

  drag(drop(elementRef));

  return (
    <div
      ref={elementRef}
      className={rootStyle}
      style={{
        backgroundColor: "white",
        cursor: "move",
        opacity: isDragging ? 0 : 1,
      }}
      data-handler-id={handlerId}
    >
      {item}
    </div>
  );
};
