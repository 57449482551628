import * as classnames from "classnames";
import * as React from "react";

import { Modal, Form, Radio, Msg, Tooltip } from "@shared/components";
import { ExamChallengeSetModel } from "@shared/models";
import { TierAction } from "@shared/services/enums";
import Message from "@shared/services/message";

/**
 * Prop interface
 */
export interface ChallengeSetSelectProps {
  isOpen: boolean;
  onSave: (challengeSet: ExamChallengeSetModel) => void;
  onCancel: () => void;
  isTierActionAllowed: (tierAction: TierAction) => boolean;
}

/**
 * State interface
 */
export interface ChallengeSetSelectState {
  formValid: boolean;
  optionalType?: string;
}

/**
 * Page component
 */
export class ChallengeSetSelect extends React.Component<
  ChallengeSetSelectProps,
  ChallengeSetSelectState
> {
  constructor(props: ChallengeSetSelectProps) {
    super(props);
    this.state = {
      formValid: false,
    };
  }

  public render() {
    const { isOpen, onCancel, isTierActionAllowed } = this.props;
    const rootStyle = classnames("code-challenge-set-select");
    return (
      <Modal
        className={rootStyle}
        title={<Msg id="action.addOptionalChallenges" />}
        isOpen={isOpen}
        onClose={onCancel}
        onClickCancel={onCancel}
        onClickOk={this.onSave}
        disableOk={!this.state.formValid}
        okButtonLabel={<Msg id="action.add" />}
        okButtonAriaLabel="Add"
        ariaLabel="Select Challenge Set"
      >
        <Form
          validation={{
            optionalType: ["required"],
          }}
          initialValues={{
            optionalType: "required",
          }}
          onFormChange={this.onFormChange}
          clear={isOpen}
        >
          <p>
            <Msg id="challenge.add.new.challengeSet" />
          </p>
          <div className="code-challenge-set-select__radio-group">
            <Radio name="optionalType" defaultValue={"required"}>
              <div className="code-challenge-set-select__radio-group__item">
                <strong>
                  <Msg id="challenge.required" />
                </strong>
                <Msg id="challenge.required.description" />
              </div>
            </Radio>
            <Tooltip
              disabled={isTierActionAllowed(TierAction.OptionalChallengeUsage)}
              text={Message.getMessageByKey("tier.disabled")}
              placement="top-end"
            >
              <Radio
                name="optionalType"
                defaultValue="optional"
                readOnly={
                  !isTierActionAllowed(TierAction.OptionalChallengeUsage)
                }
              >
                <div className="code-challenge-set-select__radio-group__item">
                  <strong>
                    <Msg id="challenge.optional" />
                  </strong>
                  <Msg id="challenge.optional.description" />
                </div>
              </Radio>
            </Tooltip>
            <Tooltip
              disabled={isTierActionAllowed(TierAction.RandomChallengeUsage)}
              text={Message.getMessageByKey("tier.disabled")}
              placement="top-end"
            >
              <Radio
                name="optionalType"
                defaultValue="random"
                readOnly={!isTierActionAllowed(TierAction.RandomChallengeUsage)}
              >
                <div className="code-challenge-set-select__radio-group__item">
                  <strong>
                    <Msg id="challenge.random" />
                  </strong>
                  <Msg id="challenge.random.description" />
                </div>
              </Radio>
            </Tooltip>
          </div>
        </Form>
      </Modal>
    );
  }

  private onSave = () => {
    const { optionalType = "" } = this.state;
    this.props.onSave(
      new ExamChallengeSetModel({
        ...{
          isOptionalSet: optionalType === "optional",
          isRandomSet: optionalType === "random",
          challenges: [],
        },
        ...(optionalType === "optional" ? { numberChallengesToTake: 1 } : {}),
      }),
    );
  };

  private onFormChange = (
    formValid: boolean,
    formValues: { optionalType: string },
    formErrors: {},
  ) => {
    const { optionalType } = formValues;
    this.setState({ formValid: formValid || !formErrors, optionalType });
  };
}
