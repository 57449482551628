import { connect } from "react-redux";

import { getTierAllowedByRedux } from "@shared/services/tier";

import { RootState } from "../../../../../../reducers";
import { ChallengeListHeader, ExternalProps } from "./ChallengeListHeader";

const mapStateToProps = (state: RootState, props: ExternalProps) => {
  return {
    ...props,
    isTierActionAllowed: getTierAllowedByRedux(state),
  };
};

const mapDispatchToProps = () => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ChallengeListHeader);
