import * as classnames from "classnames";
import * as React from "react";

import {
  ActionDropdown,
  Msg,
  Restricted,
  Icon,
  Tooltip,
} from "@shared/components";
import { QuestionListModel } from "@shared/models";
import { ProjectRole, UserRole } from "@shared/services/enums";
import Message from "@shared/services/message";

/**
 * Props
 */
export interface QuestionListActionProps {
  isGivery: boolean;
  question: QuestionListModel;
  customQuestionAllowed?: boolean;
  onClickEdit: () => void;
  onClickCopy: () => void;
  onClickDelete: () => void;
}

export const QuestionListAction: React.FunctionComponent<
  QuestionListActionProps
> = (props: QuestionListActionProps) => {
  const { question, isGivery, customQuestionAllowed = true } = props;
  const rootStyle = classnames("code-question-list-action");

  if (isGivery) {
    return null;
  }

  return (
    <Restricted
      roles={[
        UserRole.SystemAdmin,
        ProjectRole.ProjectAdmin,
        UserRole.ChallengeCreator,
        ProjectRole.ChallengeCreator,
      ]}
    >
      <ActionDropdown className={rootStyle}>
        <Tooltip
          text={Message.getMessageByKey("tier.disabled.notSupported")}
          placement="top-end"
          disabled={customQuestionAllowed}
        >
          <a
            role="menuitem"
            onClick={customQuestionAllowed ? props.onClickCopy : undefined}
            className={classnames({
              "code-question-list-action__disable": !customQuestionAllowed,
            })}
          >
            <Icon type="copy" />
            <Msg id="copy" />
          </a>
        </Tooltip>
        {!question.isOfficial && (
          <Tooltip
            text={Message.getMessageByKey("tier.disabled.notSupported")}
            placement="top-end"
            disabled={customQuestionAllowed}
          >
            <a
              onClick={customQuestionAllowed ? props.onClickEdit : undefined}
              className={classnames({
                "code-question-list-action__disable": !customQuestionAllowed,
              })}
              role="menuitem"
            >
              <Icon type="pencil" />
              <Msg id="edit" />
            </a>
          </Tooltip>
        )}
        <Restricted
          roles={[
            ProjectRole.ProjectAdmin,
            UserRole.ChallengeCreator,
            ProjectRole.ChallengeCreator,
          ]}
          strictAllow={!question.isOfficial}
        >
          <a role="menuitem" onClick={props.onClickDelete}>
            <Icon type="trash" />
            <Msg id="delete" />
          </a>
        </Restricted>
      </ActionDropdown>
    </Restricted>
  );
};
