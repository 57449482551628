import { connect } from "react-redux";
import { Action, Dispatch } from "redux";

import { SortOrderItem, PaginationModel } from "@shared/models";
import { getTierAllowedByRedux } from "@shared/services/tier";

import {
  questionListGetAction,
  questionGetAction,
  questionSourceGetAction,
  questionSourceUpdateAction,
  questionDeleteAction,
  questionImportAction,
  questionSourceCreateAction,
  questionCopyAction,
} from "../../../../actions";
import { RootState } from "../../../../reducers";
import QuestionList from "./QuestionList";

interface OwnProps {
  location?: { search: string };
}

const mapStateToProps = (state: RootState, props: OwnProps) => {
  const {
    loadingList,
    loadingDetail,
    questionList,
    questionDetailList,
    questionFilters,
    questionListPagination,
    questionSource,
    questionImportList,
    loadingImportList,
    loadingCreate,
    errorCreate,
  } = state.question;
  const { user } = state.user;

  return {
    ...props,
    currentProjectId: state.project.currentProjectId,
    loading: loadingList,
    loadingDetail,
    questionList,
    questionDetailList,
    questionFilters,
    pagination: questionListPagination,
    selectedOrg: user ? user.organization : undefined,
    questionSource,
    importedQuestions: questionImportList,
    importLoading: loadingImportList,
    createQuestionLoading: loadingCreate,
    createQuestionError: errorCreate,
    isTierActionAllowed: getTierAllowedByRedux(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  getQuestionList: (
    pagination: PaginationModel,
    filters: {},
    sortOrder: SortOrderItem,
  ) => {
    dispatch(
      questionListGetAction.request({
        ...pagination,
        ...filters,
        "sortOrder.column": sortOrder.column,
        "sortOrder.direction": sortOrder.direction,
      }),
    );
  },
  getQuestion: (payload: {}) => {
    dispatch(questionGetAction.request(payload));
  },
  getQuestionSource: (id: number) => {
    dispatch(questionSourceGetAction.request(id));
  },
  updateQuestionSource: (id: number, params: {}) => {
    dispatch(questionSourceUpdateAction.request(id, params));
  },
  deleteQuestion: (id: number) => {
    dispatch(questionDeleteAction.request(id));
  },
  importQuestions: (payload: {}) => {
    dispatch(questionImportAction.request(payload));
  },
  createQuestionFromSource: (payload: {}) => {
    dispatch(questionSourceCreateAction.request(payload));
  },
  copyQuestion: (payload: {}) => {
    dispatch(questionCopyAction.request(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(QuestionList);
