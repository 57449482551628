import { StripFunctions } from "@shared/services/typings";

import {
  CustomFormDefinitionModel,
  ExamModel,
  OrganizationModel,
  ApplicantChallengeModel,
  ApplicantModel,
  ApplicantChallengeResultModel,
  ApplicantChallengeSetModel,
  WebcamSettingsModel,
  WebcamStatusModel,
} from "../models";
import { dayjs } from "../services/date";
import {
  ApplicantExamStatus,
  DeliveryMethod,
  SubmissionType,
} from "../services/enums";
import MSG from "../services/message";
import { ApplicantSegmentModel } from "./Submission.model";

class ApplicantExamModel {
  public id: number;
  public deadline: string;
  public urlToken: string;
  public openedAt: string;
  public status: ApplicantExamStatus;
  public deliveryMethod: DeliveryMethod;
  public formValues: { [key: string]: string } = {};
  public applicantSegment: ApplicantSegmentModel = {} as ApplicantSegmentModel;
  public dynamicDeadlineHours: number;
  public org: OrganizationModel = {} as OrganizationModel;
  public exam: ExamModel = {} as ExamModel;
  public applicant: ApplicantModel = {} as ApplicantModel;
  public formDefinitions: CustomFormDefinitionModel[] = [];
  public challenges: ApplicantChallengeModel[] = [];
  public challengesSets: ApplicantChallengeSetModel[] = [];
  public results: ApplicantChallengeResultModel[] = [];
  public applicantNameRequired: boolean;
  public initialPassword: string;
  public isTest: boolean; // true: sample delivery
  public estimatedCompletionTime = 0;
  public submissionType: SubmissionType;
  public webcamSettings: WebcamSettingsModel;
  public webcamStatus: WebcamStatusModel;

  constructor(args: StripFunctions<ApplicantExamModel>) {
    Object.assign(this, args);

    this.org = new OrganizationModel(this.org);
    this.exam = new ExamModel(this.exam);
    this.applicant = new ApplicantModel(this.applicant);
    this.formDefinitions = this.formDefinitions.map(
      (item) => new CustomFormDefinitionModel(item),
    );
    this.challenges = this.challengesSets
      .sort((a, b) => a.displayOrder - b.displayOrder)
      .reduce((previousValue, currentValue) => {
        return [
          ...previousValue,
          ...currentValue.challenges.map(
            (item) => new ApplicantChallengeModel(item),
          ),
        ];
      }, []);
    this.challengesSets = this.challengesSets
      .sort((a, b) => a.displayOrder - b.displayOrder)
      .map((item) => new ApplicantChallengeSetModel(item));
    this.results = this.results.map(
      (item) => new ApplicantChallengeResultModel(item),
    );
  }

  public canStart() {
    return [ApplicantExamStatus.Unread].includes(this.status);
  }

  public canSubmit() {
    return [ApplicantExamStatus.InProgress].includes(this.status);
  }

  public isInProgress() {
    return [ApplicantExamStatus.InProgress].includes(this.status);
  }

  public isStarted() {
    return [
      ApplicantExamStatus.InProgress,
      ApplicantExamStatus.Canceled,
      ApplicantExamStatus.Submitted,
    ].includes(this.status);
  }

  public isFinished() {
    return [
      ApplicantExamStatus.Canceled,
      ApplicantExamStatus.Submitted,
    ].includes(this.status);
  }

  public isExpired() {
    return (
      [ApplicantExamStatus.Expired].includes(this.status) ||
      dayjs().isSameOrAfter(this.deadline)
    );
  }

  public getChallengeByChallengeId(challengeId: number) {
    return (
      this.challenges.find((item) => item.id === challengeId) ||
      new ApplicantChallengeModel({} as ApplicantChallengeModel)
    );
  }

  public getResultByChallengeId(challengeId: number) {
    return (
      this.results.find((item) => item.challengeId === challengeId) ||
      new ApplicantChallengeResultModel({} as ApplicantChallengeResultModel)
    );
  }

  public getChallengeSetByChallengeSetId(challengeSetId: number) {
    return this.challengesSets.find((item) => item.id === challengeSetId);
  }

  public isMinimumChallengesFinished() {
    const totalChallengeCount =
      this.getRequiredChallengeCount() +
      this.getMinimumOptionalChallengeCount();

    const { required, optional, random } = this.getChallengeFinishStats();

    const actualFinishedCount =
      required +
      Math.min(this.getMinimumOptionalChallengeCount(), optional) +
      random;

    return totalChallengeCount <= actualFinishedCount;
  }

  public getChallengeFinishStats() {
    const stats = {
      total: 0,
      required: 0,
      optional: 0,
      random: 0,
    };
    this.challengesSets.forEach((challengeSet) => {
      const { challenges, isOptionalSet, isRandomSet } = challengeSet;
      challenges.forEach((challenge) => {
        const finished = this.isChallengeFinished(challenge.id);
        if (finished) {
          stats.total = stats.total + 1;
          stats.required =
            stats.required + (!isRandomSet && !isOptionalSet ? 1 : 0);
          stats.optional = stats.optional + (isOptionalSet ? 1 : 0);
          stats.random = stats.random + (isRandomSet ? 1 : 0);
        }
      });
    });
    return stats;
  }

  public getStatusLabel() {
    return ApplicantExamStatus.toApplicantString(this.status);
  }

  public getTimeLimitMinutes() {
    return this.deliveryMethod === DeliveryMethod.URL
      ? (this.dynamicDeadlineHours || 0) * 60
      : 0;
  }

  public mergeChallengeResult(data: ApplicantChallengeResultModel) {
    const newChallengeResult = new ApplicantChallengeResultModel(data);
    const index = this.results.findIndex(
      (item) => item.id === newChallengeResult.id,
    );

    if (index === -1) {
      // push
      return [...this.results, newChallengeResult];
    } else {
      // merge
      return [
        ...this.results.slice(0, index),
        newChallengeResult,
        ...this.results.slice(index + 1),
      ];
    }
  }

  public hasOptionalChallengeSet() {
    return Boolean(this.challengesSets.some((item) => item.isOptionalSet));
  }

  /**
   * Get required submission count
   */
  public getRequiredChallengeCount() {
    return this.challengesSets.reduce((total, challengeSet) => {
      return challengeSet.isOptionalSet
        ? total
        : total + challengeSet.challenges.length;
    }, 0);
  }

  /**
   * Get required minimum submission count from optional challenge set
   */
  public getMinimumOptionalChallengeCount() {
    return this.challengesSets.reduce((total, challengeSet) => {
      return challengeSet.isOptionalSet
        ? total + challengeSet.numberChallengesToTake
        : total;
    }, 0);
  }

  /**
   * Check if the exam has entry form item
   */
  public hasEntryForm() {
    return this.applicantNameRequired || Boolean(this.formDefinitions.length);
  }

  // -----------------------------------------------------------------------------
  // Challenge related methods
  // -----------------------------------------------------------------------------

  public getChallengeResultStatusLabel(challengeId: number) {
    const challengeResult = this.getResultByChallengeId(challengeId);
    if (this.isFinished()) {
      return MSG.getMessageByKey(
        challengeResult.isEmpty()
          ? "challengeResultStatus.notModified"
          : "challengeResultStatus.finished",
      );
    } else {
      return challengeResult.getResultStatusLabel();
    }
  }

  public canChallengeStart(challengeId: number) {
    const challengeResult = this.getResultByChallengeId(challengeId);
    return !this.isFinished() && challengeResult.canStart();
  }

  public canChallengeSave(challengeId: number) {
    const challengeResult = this.getResultByChallengeId(challengeId);
    return !this.isFinished() && challengeResult.canSave();
  }

  public canChallengeReset(challengeId: number) {
    const challengeResult = this.getResultByChallengeId(challengeId);
    return !this.isFinished() && challengeResult.canReset();
  }

  public canChallengeSwitchLanguage(challengeId: number) {
    const challengeResult = this.getResultByChallengeId(challengeId);
    return !this.isFinished() && challengeResult.canSwitchLanguage();
  }

  public canChallengeSubmit(challengeId: number) {
    const challengeResult = this.getResultByChallengeId(challengeId);
    return !this.isFinished() && challengeResult.canSubmit();
  }

  public isChallengeStarted(challengeId: number) {
    const challengeResult = this.getResultByChallengeId(challengeId);
    return !this.isFinished() && challengeResult.isStarted();
  }

  public isChallengeFinished(challengeId: number) {
    const challengeResult = this.getResultByChallengeId(challengeId);
    return this.isFinished() || challengeResult.isFinished();
  }

  public areAllChallengesFinished() {
    const areAllFinished = this.challenges.every((challenge) => {
      const challengeResult = this.getResultByChallengeId(challenge.id);
      return challengeResult.isFinished();
    });

    return this.isFinished() || areAllFinished;
  }

  /**
   * Determine if the challenge set can be considered begin
   * @param challengeSetId
   * @example
   *   [not start, not start, not start] => false
   *   [not start, not start, start] => true
   */
  public isChallengeSetStarted(challengeSetId: number) {
    const challengeSet = this.getChallengeSetByChallengeSetId(challengeSetId);
    return challengeSet
      ? challengeSet.challenges.some((item) => this.isChallengeStarted(item.id))
      : false;
  }

  /**
   * Determine if the challenge set can be considered complete
   * @param challengeSetId
   * @example
   *   if numberChallengesToTake is 2,
   *   [done, done, done] => true
   *   [done, done, not start] => true
   *   [done, not start, not start] => false
   */
  public isChallengeSetFinished(challengeSetId: number) {
    const challengeSet = this.getChallengeSetByChallengeSetId(challengeSetId);
    if (!challengeSet) {
      return false;
    }
    const finished = challengeSet.challenges.filter((item) =>
      this.isChallengeFinished(item.id),
    );
    return finished.length >= challengeSet.numberChallengesToTake;
  }
}

export default ApplicantExamModel;
