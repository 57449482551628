import { useCallback, useEffect, useMemo, useRef, useState } from "react";

export type VisibilityEvent = "visibilitychange" | "focus" | "blur";

export const usePageVisibility = (
  initialVisible?: boolean,
  events?: VisibilityEvent[],
) => {
  const visibilityTimer = useRef<NodeJS.Timeout>();
  const [isVisible, setIsVisible] = useState(
    initialVisible ?? !document.hidden,
  );
  const windowEvents = useMemo(
    () => events ?? ["visibilitychange", "focus", "blur"],
    [events],
  );

  const handler = useCallback((e: { type: string }) => {
    switch (e.type) {
      case "focus":
        return setIsVisible(true);
      case "blur":
        return setIsVisible(false);
      case "visibilitychange":
        // Process visibility change after document focus change
        visibilityTimer.current = setTimeout(() => {
          if (document.hidden) {
            return setIsVisible(false);
          }
          return setIsVisible(true);
        }, 300);
    }
  }, []);

  useEffect(() => {
    windowEvents.forEach((event) => {
      window.addEventListener(event, handler);
    });

    return () => {
      windowEvents.forEach((event) => {
        window.removeEventListener(event, handler);
      });
    };
  }, [windowEvents, handler]);

  useEffect(() => {
    setIsVisible(Boolean(initialVisible));
  }, [initialVisible]);

  useEffect(() => {
    return () => clearTimeout(visibilityTimer.current);
  }, []);

  return isVisible;
};
