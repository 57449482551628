import { Epic } from "redux-observable";

import {
  Action,
  ajaxAction,
  tutorialExamGetAction,
  applicantExamGetAction,
} from "../actions";
import { RootState } from "../reducers";

const tutorialStartEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(tutorialExamGetAction.types.request).map((action) =>
    ajaxAction.request({
      method: "post",
      url: "/api/tutorial/start",
      body: action.payload,
      success: tutorialExamGetAction.success,
    }),
  );

const tutorialExamGetEpic: Epic<Action, RootState> = (action$, state) =>
  action$
    .ofType(tutorialExamGetAction.types.success)
    .map((_) => applicantExamGetAction.request({}));

export default [tutorialStartEpic, tutorialExamGetEpic];
