import { connect } from "react-redux";

import { RootState } from "@reducers";

import { SampleDeliveryEmail, ExternalProps } from "./SampleDeliveryEmail";

const mapStateToProps = (state: RootState, props: ExternalProps) => ({
  currentUser: state.user.user,
  ...props,
});

export default connect(mapStateToProps, {})(SampleDeliveryEmail);
