import * as classnames from "classnames";
import { isEqual } from "lodash";
import * as React from "react";
import { BehaviorSubject } from "rxjs/BehaviorSubject";
import { Subscription } from "rxjs/Subscription";

import { Input, TagCloud, TagItem } from "..";
import Message from "../../../shared/services/message";

/**
 * Prop interface
 */
export interface TagPickerProps {
  suggestions?: TagItem[];
  tagItems?: TagItem[];
  className?: string;
  onChange?: (tags: Array<{}>) => void;
  children?: {};
  onlySuggestions?: boolean;
  clear?: boolean;
  showOnFocus?: boolean;
  alwaysShow?: boolean;
}

/**
 * State interface
 */
export interface TagPickerState {
  filteredSuggestions: TagItem[];
  value: string;
  tagItems: TagItem[];
  selectedSuggestion: number;
  showAll: boolean;
}

/**
 * React Component
 */
export default class TagPicker extends React.Component<
  TagPickerProps,
  TagPickerState
> {
  private handleChange$: BehaviorSubject<string>;
  private subscription: Subscription;

  constructor(props: TagPickerProps) {
    super(props);

    this.state = {
      value: "",
      filteredSuggestions: [],
      tagItems: this.props.tagItems || [],
      selectedSuggestion: -1,
      showAll: Boolean(props.alwaysShow),
    };

    this.handleChange$ = new BehaviorSubject("");
  }

  public componentDidMount() {
    this.subscription = this.handleChange$
      .debounceTime(300)
      .subscribe(this.renderSuggestions);

    if (this.props.alwaysShow) {
      this.renderSuggestions("");
    }
  }

  public componentWillUnmount() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  public componentDidUpdate(prevProps: TagPickerProps) {
    if (!isEqual(this.props.tagItems, prevProps.tagItems)) {
      this.setState({
        tagItems: this.props.tagItems || [],
      });
    } else if (this.props.clear && !prevProps.clear) {
      this.setState(
        {
          value: "",
          tagItems: this.props.tagItems || [],
          filteredSuggestions: [],
          selectedSuggestion: -1,
        },
        () => {
          if (this.props.alwaysShow) {
            this.renderSuggestions("");
          }
        },
      );
    }
  }

  public shouldComponentUpdate(
    nextProps: TagPickerProps,
    nextState: TagPickerState,
  ) {
    return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state);
  }

  public render() {
    const { className } = this.props;
    const { filteredSuggestions } = this.state;

    const rootStyle = classnames("code-c-tag-picker", {
      [`${className}`]: Boolean(className),
    });

    const suggestions = filteredSuggestions.map(
      (tag: TagItem, index: number) => (
        <li
          className={classnames("code-c-tag-picker__suggest-item", {
            "code-c-tag-picker__suggest-item-selected":
              this.state.selectedSuggestion === index,
          })}
          onMouseDown={() => this.handleAppendObject(tag)}
          key={index}
        >
          {tag.label}
        </li>
      ),
    );

    return (
      <div className={rootStyle}>
        <Input
          className="code-c-tag-picker__input"
          value={this.state.value}
          onChange={this.handleInput}
          onEnter={this.handleEnter}
          onKeyDown={this.handleKeyDown}
          onFocus={this.props.alwaysShow ? undefined : this.handleFocus}
          onBlur={this.props.alwaysShow ? undefined : this.handleBlur}
          placeholder={Message.getMessageByKey("form.fileter.byKeyword")}
        />
        <TagCloud
          className="code-c-tag-picker__items"
          tagItems={this.state.tagItems}
          onDelete={this.handleDelete}
          hasDelete={true}
        />
        {filteredSuggestions.length ? (
          <ul className="code-c-tag-picker__suggest-list">{suggestions}</ul>
        ) : null}
      </div>
    );
  }

  private onChange = () => {
    if (this.props.onChange) {
      this.props.onChange(this.state.tagItems);
    }
  };

  private renderSuggestions = (label: string) => {
    const { suggestions = [] } = this.props;
    const filteredSuggestions =
      label.length > 0 || this.state.showAll
        ? suggestions.filter(
            (tag) =>
              !this.state.tagItems.some(
                (tagItem) => tagItem.label === tag.label,
              ) && tag.label.toLowerCase().includes(label.toLowerCase()),
          )
        : [];
    this.setState({
      filteredSuggestions,
      selectedSuggestion:
        filteredSuggestions.length && !this.props.alwaysShow ? 0 : -1,
    });
  };

  private handleFocus = (e: React.FormEvent<HTMLInputElement>) => {
    const input = e.target as HTMLInputElement;
    if (this.props.showOnFocus) {
      this.setState(
        {
          showAll: true,
        },
        () => this.renderSuggestions(input.value),
      );
    }
  };

  private handleBlur = (e: React.FormEvent<HTMLInputElement>) => {
    const input = e.target as HTMLInputElement;
    this.setState(
      {
        showAll: false,
      },
      () => this.renderSuggestions(input.value),
    );
  };

  private handleInput = (e: React.FormEvent<HTMLInputElement>) => {
    const input = e.target as HTMLInputElement;
    this.handleChange$.next(input.value);
    this.setState({ value: input.value });
  };

  private handleEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // add selected suggestion
    if (
      this.state.selectedSuggestion > -1 &&
      this.state.filteredSuggestions.length > 0
    ) {
      this.handleAppendObject(
        this.state.filteredSuggestions[this.state.selectedSuggestion],
      );
    } else if (!this.props.onlySuggestions) {
      const input = e.target as HTMLInputElement;
      if (input.value.trim()) {
        this.handleAppend(input.value);
      }
    }
  };

  private handleAppend = (label: string) => {
    const hasChange = !Boolean(
      this.state.tagItems.filter((tag) => tag.label === label).length,
    );

    if (hasChange) {
      this.setState(
        {
          value: "",
          tagItems: [...this.state.tagItems, { label }],
          filteredSuggestions: [],
        },
        () => {
          this.onChange();
          if (this.props.alwaysShow) {
            this.renderSuggestions("");
          }
        },
      );
    } else {
      this.setState(
        {
          value: "",
          filteredSuggestions: [],
        },
        () => {
          if (this.props.alwaysShow) {
            this.renderSuggestions("");
          }
        },
      );
    }
  };

  private handleAppendObject = (toAppend: { label: string }) => {
    const hasChange = !Boolean(
      this.state.tagItems.filter((tag) => tag.label === toAppend.label).length,
    );

    if (hasChange) {
      this.setState(
        {
          value: "",
          selectedSuggestion: -1,
          filteredSuggestions: [],
          tagItems: [...this.state.tagItems, toAppend],
        },
        () => {
          this.onChange();
          if (this.props.alwaysShow) {
            this.renderSuggestions("");
          }
        },
      );
    }
  };

  private handleDelete = (label: string) => {
    this.setState(
      { tagItems: this.state.tagItems.filter((tag) => tag.label !== label) },
      () => {
        this.onChange();
        if (this.props.alwaysShow) {
          this.renderSuggestions("");
        }
      },
    );
  };

  private handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const key = e.key;
    let selectedSuggestion = this.state.selectedSuggestion;

    switch (key) {
      case "ArrowUp":
        selectedSuggestion = Math.max(0, selectedSuggestion - 1);
        e.preventDefault();
        break;
      case "ArrowDown":
        selectedSuggestion = Math.min(
          this.state.filteredSuggestions.length - 1,
          selectedSuggestion + 1,
        );
        e.preventDefault();
        break;
      default:
        break;
    }

    this.setState({ selectedSuggestion });
  };
}
