import {
  autoUpdate,
  size,
  useDismiss,
  useFloating,
  useInteractions,
  useListNavigation,
  useRole,
  FloatingFocusManager,
  FloatingPortal,
  flip,
  offset,
} from "@floating-ui/react";
import classnames from "classnames";
import { useEffect, useRef, useState } from "react";

import ValidationMessage, {
  hasValidationError,
} from "../validationMessage/ValidationMessage";
import { AutoCompleteInput } from "./AutoCompleteInput/AutoCompleteInput";
import { DefaultOptionContainer } from "./OptionContainer/DefaultOptionContainer";
import { DefaultOption, DefaultOptionProps } from "./Options/DefaultOption";

// This is the type that will be used to determine the props that will be passed to the AutoComplete component.
// This type will be used to determine if the AutoComplete is a single or multi-select component.
type ConditionalProps<T extends DefaultOptionProps> =
  | {
      isMulti: true;
      /**
       * Controlled input value.
       */
      value: T[];
      /**
       * The function that will be called when an option is selected.
       * It can take a generic type that extends DefaultOptionProps.
       */
      onChange: (value: T[]) => void;
      onBlur?: (value: T[]) => void;
    }
  // If i pass the src prop i can't pass the icon prop
  | {
      isMulti?: false;
      /**
       * Controlled input value.
       */
      value?: T;
      /**
       * The function that will be called when an option is selected.
       * It can take a generic type that extends DefaultOptionProps.
       */
      onChange: (value?: T) => void;
      onBlur?: (value: T) => void;
    };

interface AutoCompleteProps<T extends DefaultOptionProps> {
  /**
   * The placeholder of the AutoComplete Input.
   * This will be displayed when no value is present.
   * @default ""
   */
  placeholder: string;
  /**
   * This is the custom component that will be used to render the options.
   * If no component is passed, the default option component will be used.
   * For email template options the EmailTemplateOption component is used
   * @default DefaultOption
   */
  optionComponent?: React.ComponentType<T & DefaultOptionProps>;
  /**
   * This is the custom component that will be used to render the options container (popover).
   * If no component is passed, the default option component will be used.
   * For email template options the EmailTemplateOptionContainer component is used
   * @default DefaultOptionContainer
   */
  optionContainerComponent?: typeof DefaultOptionContainer;
  /**
   * The options that will be displayed in the AutoComplete.
   * It can take a generic type that extends DefaultOptionProps.
   * @default DefaultOptionProps[]
   */
  options: T[];
  noMatchesMessage: string;
  className?: string;
  inputClassname?: string;
  popupClassName?: string;
  error?: Record<string, string>;
  name?: string;
}

export function AutoComplete<T extends DefaultOptionProps>({
  placeholder,
  optionComponent: OptionComponent = DefaultOption, // Default to Option if no custom component is passed
  optionContainerComponent: OptionContainerComponent = DefaultOptionContainer,
  options,
  onChange,
  onBlur,
  value,
  isMulti,
  noMatchesMessage,
  className,
  inputClassname,
  popupClassName,
  error,
  name,
}: AutoCompleteProps<T> & ConditionalProps<T>) {
  const [open, setOpen] = useState(false);
  let defaultValue: T[] = [];
  if (!isMulti && value) {
    defaultValue = [value];
  }
  if (isMulti && value) {
    defaultValue = value;
  }

  const [selectedOptions, setSelectedOptions] = useState<T[]>(defaultValue);
  const [inputFilter, setInputFilter] = useState("");
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const listRef = useRef<Array<HTMLElement | null>>([]);

  const openChange = (value: boolean) => {
    setOpen(value);
    if (value === false) {
      setInputFilter("");
    }
  };

  useEffect(() => {
    let formattedValue: T[] = [];
    if (!isMulti && value) {
      formattedValue = [value];
    }
    if (isMulti && value) {
      formattedValue = value;
    }
    setSelectedOptions(formattedValue);
  }, [value, isMulti]);

  const inputRef = useRef<HTMLInputElement>(null);

  const { context, refs, floatingStyles } = useFloating<HTMLInputElement>({
    placement: "bottom",
    whileElementsMounted: autoUpdate,
    open,
    onOpenChange: openChange,
    middleware: [
      offset(4),
      flip({ padding: 10 }),
      size({
        apply({ rects, availableHeight, elements }: any) {
          Object.assign(elements.floating.style, {
            width: `${rects.reference.width}px`,
            maxHeight: `${availableHeight}px`,
          });
        },
        padding: 10,
      }),
    ],
  });

  const role = useRole(context, { role: "listbox" });
  const dismiss = useDismiss(context);
  const listNav = useListNavigation(context, {
    listRef,
    activeIndex,
    onNavigate: setActiveIndex,
    virtual: true,
    loop: true,
    allowEscape: true,
  });

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions(
    [role, dismiss, listNav],
  );

  // onChange and onBlur functions differ for single and multi-select components
  function onInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const value = event.target.value;
    setInputFilter(value);
    if (inputFilter === "" && !isMulti) {
      setSelectedOptions([]);
      onChange();
    }

    if (value) {
      setActiveIndex(null);
      setOpen(true);
    } else {
      setOpen(false);
    }
  }
  function handleOnBlur(event: React.FocusEvent<HTMLInputElement>) {
    if (onBlur) {
      if (isMulti) {
        onBlur(selectedOptions as T[]);
      } else {
        onBlur(selectedOptions[0] as T);
      }
    }
  }

  const items = options.filter((item) =>
    item.label.toLowerCase().includes(inputFilter.toLowerCase()),
  );

  const removeOption = (item: T) => {
    const alreadySelected = selectedOptions.find(
      (option) => option.label === item.label,
    );
    if (alreadySelected) {
      const newSelectedOptions = selectedOptions.filter(
        (option) => option.label !== item.label,
      );
      setSelectedOptions(newSelectedOptions);
      if (isMulti) {
        onChange(newSelectedOptions);
      }
      return true;
    }
    return false;
  };

  const onSelect = (item: T) => {
    // Removes the option if clicked Again
    if (isMulti) {
      const removedOption = removeOption(item);
      if (!removedOption) {
        setSelectedOptions([...selectedOptions, item]);
        onChange([...selectedOptions, item]);
      }
    } else {
      setSelectedOptions([item]);
      onChange(item);
    }
    if (!isMulti) {
      setActiveIndex(null);
      setOpen(false);
    }
    setInputFilter("");
    inputRef.current?.focus();
  };

  let inputValue = "";
  if (!isMulti) {
    if (inputFilter) {
      inputValue = inputFilter;
    } else if (selectedOptions.length > 0) {
      inputValue = selectedOptions[0].label;
    }
  } else {
    inputValue = inputFilter;
  }

  const selectInputProps = getReferenceProps({
    onChange: onInputChange,
    onBlur: handleOnBlur,
    value: inputValue,
    placeholder,
    "aria-autocomplete": "list",
    onKeyDown(event) {
      if (event.key === "Enter" && activeIndex != null && items[activeIndex]) {
        onSelect(items[activeIndex]);
      }
    },
    onClick: () => {
      inputRef.current?.focus();
      setOpen(!open);
    },
    className: classnames(
      "code-c-autocomplete__input-container ",
      inputClassname,
      {
        "is-danger": hasValidationError(name, error),
      },
    ),
  });

  const optionSelectedSet = new Set(
    selectedOptions.map((option) => option.value),
  );

  const autoCompleteInputStyle = classnames({
    "is-danger": hasValidationError(name, error),
  });

  return (
    <>
      <AutoCompleteInput
        open={open}
        inputProps={selectInputProps}
        ref={refs.setReference}
        selectedOptions={isMulti ? selectedOptions : []}
        onDelete={removeOption}
        isMulti={isMulti}
        inputRef={inputRef}
        className={autoCompleteInputStyle}
      />
      <ValidationMessage name={name} error={error} />
      <FloatingPortal>
        {open && (
          <FloatingFocusManager
            context={context}
            initialFocus={-1}
            visuallyHiddenDismiss
          >
            <OptionContainerComponent
              ref={refs.setFloating}
              floatingProps={{
                ...getFloatingProps({
                  style: floatingStyles,
                }),
              }}
              close={() => setOpen(false)}
              popupClassName={popupClassName}
            >
              <div className="options-container">
                {items.map((item, index) => {
                  const isSelected = optionSelectedSet.has(item.value);
                  const hasDivider =
                    index > 0 && items[index - 1].group !== item.group;

                  return (
                    <>
                      {hasDivider && (
                        <div className="code-c-autocomplete__option-divider"></div>
                      )}
                      <OptionComponent
                        key={item.label}
                        {...getItemProps({
                          key: item.label,
                          ref(node) {
                            listRef.current[index] = node;
                          },
                          onClick(e) {
                            onSelect(item);
                            refs.domReference.current?.focus();
                          },
                        })}
                        {...item}
                        isSelected={isSelected}
                        hasDivider={hasDivider}
                        close={() => setOpen(false)}
                        isMulti={isMulti}
                      />
                    </>
                  );
                })}

                {items.length === 0 && (
                  <div className="no-matches">{noMatchesMessage}</div>
                )}
              </div>
            </OptionContainerComponent>
          </FloatingFocusManager>
        )}
      </FloatingPortal>
    </>
  );
}
