import { parseISO } from "date-fns";
import * as dayjs from "dayjs";
import { UnitType } from "dayjs";
import "dayjs/locale/ja";
import * as duration from "dayjs/plugin/duration";
import * as isBetween from "dayjs/plugin/isBetween";
import * as isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import * as isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import * as isoWeek from "dayjs/plugin/isoWeek";
import * as minMax from "dayjs/plugin/minMax";
import * as relativeTime from "dayjs/plugin/relativeTime";
import * as utc from "dayjs/plugin/utc";

import MSG from "./message";

dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(isoWeek);
dayjs.extend(utc);
dayjs.extend(minMax);
dayjs.extend(isBetween);

const secondOf20minutes = 20 * 60;
export const secondsOf5Minutes = 5 * 60;

export function switchLocale(locale: string) {
  dayjs.locale(locale);
}

/**
 * check if the value is valid date or not
 * @param value
 */
export function isValidDate(value?: Date | string | null): boolean {
  if (value === undefined || value === null || value === "") {
    return false;
  }

  if (value instanceof Date) {
    return true;
  }

  const formatted = dayjs(value).format();

  // react-datepicker throws an error if it receives a date string starting with a zero
  // eg. 0100-01-01T00:00:00+09:15
  if (formatted.length > 0 && formatted.startsWith("0")) {
    return false;
  }

  return dayjs(new Date(value)).isValid();
}

/**
 * Another type of checking if the value is valid date or not
 *
 * ¯\_(ツ)_/¯
 * I can't describe how the diffrences between this and isValidDate are.
 * This was added just to maintain consistency between DatePicker output and building the validation rule for the exam settings.
 * It should not be used elsewhere.
 *
 * I hope young heroes can overcome this problem.
 *
 * @param value
 * @returns
 *
 * @deprecated
 */
export function isValidDate_datepicker(value?: Date | string | null): boolean {
  if (value === undefined || value === null || value === "") {
    return false;
  }

  const formatted = dayjs(value).format();

  // react-datepicker throws an error if it receives a date string starting with a zero
  // eg. 0100-01-01T00:00:00+09:15
  if (formatted.length > 0 && formatted.startsWith("0")) {
    return false;
  }

  return dayjs(parseISO(formatted)).isValid();
}

/**
 * formatSeconds
 * @param {number} second
 * @returns {string} hh:mm:ss
 */
export function formatSeconds(second: number): string {
  const sec_num = parseInt(second.toString(), 10);
  const hours = Math.floor(sec_num / 3600);
  const min = Math.floor((sec_num - hours * 3600) / 60);
  const sec = sec_num - hours * 3600 - min * 60;
  const formatNumberToTwoLength = (num: number) => (num < 10 ? "0" + num : num);
  return (
    formatNumberToTwoLength(hours) +
    ":" +
    formatNumberToTwoLength(min) +
    ":" +
    formatNumberToTwoLength(sec)
  );
}

/**
 * formatDate
 * @param {string} date should be formatted ISO 8601 strings(e.x. 2017-11-13T04:45:39Z)
 * @returns {string} YYYY/MM/DD
 */
export function formatDate(date: string): string {
  if (!date || !isValidDate(date)) {
    return "";
  }

  return dayjs(date).format("YYYY/MM/DD");
}

/**
 * formatDateTimeMinutes
 * @param {string} date should be formatted ISO 8601 strings(e.x. 2017-11-13T04:45:39Z)
 * @param {boolean} hasGMT set if formatted string has GMT time zone
 * @returns {string} YYYY/MM/DD HH:mm or YYYY/MM/DD HH:mm ([GMT]Z)
 */
export function formatDateTimeMinutes(date: string, hasGMT?: boolean): string {
  if (!date || !isValidDate(date)) {
    return "";
  }

  return dayjs(date).format(
    hasGMT ? "YYYY/MM/DD HH:mm ([GMT]Z)" : "YYYY/MM/DD HH:mm",
  );
}

/**
 * formatDateTimeSeconds
 * @param {string} date should be formatted ISO 8601 strings(e.x. 2017-11-13T04:45:39Z)
 * @returns {string} YYYY/MM/DD HH:mm:ss
 */
export function formatDateTimeSeconds(date: string): string {
  if (!date || !isValidDate(date)) {
    return "";
  }

  return dayjs(date).format("YYYY/MM/DD HH:mm:ss");
}

/**
 * Get timezone string(ex. Asia/Tokyo)
 */
export function getTimeZoneString() {
  if (Intl && Intl.DateTimeFormat) {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  } else {
    return "";
  }
}

// return string in form like `1day`or `2days` or empty if count === 0
const buildString = (count: number, label: string) =>
  count ? count + MSG.getMessageByKey(count > 1 ? `${label}s` : label) : "";

/**
 * Get object of time string
 */
export function timeStringObject(timeLeftSeconds?: number): {
  [key: string]: string;
} {
  const duration = dayjs.duration(timeLeftSeconds ?? 0, "seconds");
  const year = buildString(duration.years(), "unit.year");
  const month = buildString(duration.months(), "unit.month");
  const day = buildString(duration.days(), "unit.day");
  const hour = buildString(duration.hours(), "unit.hr");
  const minute = buildString(duration.minutes(), "unit.min");
  const second = buildString(duration.seconds(), "unit.sec");

  return {
    year,
    month,
    day,
    hour,
    minute,
    second,
  };
}

/**
 * Get object of time string with converting years and months to days
 */
export function timeStringObjectAsDays(timeLeftSeconds?: number): {
  [key: string]: string;
} {
  const duration = dayjs.duration(timeLeftSeconds ?? 0, "seconds");
  const { hour, minute, second } = timeStringObject(timeLeftSeconds);

  return {
    day: buildString(Math.floor(duration.asDays()), "unit.day"),
    hour,
    minute,
    second,
  };
}

/**
 * formatTimeLeftString
 */
export function formatTimeLeftString(timeLeftSeconds?: number): string {
  if (timeLeftSeconds === undefined || timeLeftSeconds === null) {
    return "";
  }

  if (timeLeftSeconds === -1) {
    return MSG.getMessageByKey("beforeStarting");
  }

  if (timeLeftSeconds <= 0) {
    return MSG.getMessageByKey("finished");
  }

  const { day, hour, minute, second } = timeStringObjectAsDays(timeLeftSeconds);

  const lessThan20minutes =
    dayjs.duration(timeLeftSeconds, "seconds").asSeconds() <= secondOf20minutes;

  return day + hour + minute + (lessThan20minutes ? second : "");
}

/**
 * formatTimeElapsedString
 */
export function formatTimeElapsedString(timeLeftSeconds?: number): string {
  if (timeLeftSeconds === undefined || timeLeftSeconds === null) {
    return "";
  }

  if (Math.floor(timeLeftSeconds) <= 0) {
    return `0${MSG.getMessageByKey("unit.sec")}`;
  }

  const { day, hour, minute, second } = timeStringObjectAsDays(timeLeftSeconds);

  const lessThan20minutes =
    dayjs.duration(timeLeftSeconds, "seconds").asSeconds() <= secondOf20minutes;

  return day + hour + minute + (lessThan20minutes ? second : "");
}

/**
 *
 * @param timeLeftMillis
 * @returns {string} formatted time elapsed string (e.g. "25 minutes 30 seconds"")
 */
export function formatTimeElapsedStringFromMillis(
  timeLeftMillis?: number,
): string {
  return formatTimeElapsedString(
    timeLeftMillis ? Math.round(timeLeftMillis / 1000) : undefined,
  );
}

/**
 * formatDaysLeftString
 */
export function formatDaysLeftString(timeLeftSeconds?: number): string {
  if (timeLeftSeconds === undefined || timeLeftSeconds === null) {
    return "";
  }

  if (timeLeftSeconds <= 0) {
    return MSG.getMessageByKey("finished");
  }

  const { year, month, day } = timeStringObject(timeLeftSeconds);

  return year + month + day;
}

export function getDifference(
  from: string | dayjs.Dayjs,
  to?: string | dayjs.Dayjs,
  unit?: UnitType,
) {
  const fromDate = dayjs(from);
  const toDate = dayjs(to);

  return toDate.diff(fromDate, unit || "days");
}

/**
 * Check if the date string is valid ISO date string
 * @param dateString ISO date string
 */
export function isValidISODateString(dateString?: string) {
  if (!dateString) {
    return false;
  }

  return dayjs(parseISO(dateString)).isValid();
}

/**
 * Check if the date string is the same or after 1 month
 * @param dateString
 */
export function isNew(dateString?: string): boolean {
  return dayjs(dateString).add(1, "month").isSameOrAfter(dayjs());
}

/**
 * Check if the date string is 1 day from current date
 * @param dateString
 */
export function isLessThanADay(dateString?: string): boolean {
  return dateString
    ? Math.abs(dayjs().diff(dayjs(dateString), "hour", true)) < 24
    : false;
}

/**
 * Extract start and end date from date range string with iso-date-time "/" iso-date-time format
 * See https://datatracker.ietf.org/doc/html/rfc3339#:~:text=period%2Dexplicit%20%20%20%3D%20iso%2Ddate%2Dtime%20%22/%22%20iso%2Ddate%2Dtime
 * @param dateRangeString
 */
export function getStartAndEndDateFromDateRange(dateRangeString: string): {
  startDate: dayjs.Dayjs;
  endDate: dayjs.Dayjs;
} {
  const [startDateString, endDateString] = dateRangeString.split("/");

  return {
    startDate: dayjs(startDateString),
    endDate: dayjs(endDateString),
  };
}

/**
 *
 * @returns {number} unix timestamp of the last week
 */
export function getLastWeekTimestamp(): number {
  return dayjs().add(-1, "week").unix();
}

export { dayjs };
