import * as classnames from "classnames";

import {
  ActionDropdown,
  Icon,
  QuickHelp,
  Restricted,
  Tag,
  ValidationMessage,
  Msg,
  ErrorIcon,
} from "@shared/components";
import { ExamChallengeSetModel } from "@shared/models";
import { ProjectRole, TierAction, UserRole } from "@shared/services/enums";
import Message from "@shared/services/message";
import { isOptionalAllowed, isRandomAllowed } from "@shared/services/tier";

import {
  getChallengeSetWeight,
  getChallengeSetMessageKey,
} from "../examSectionUtil/ExamSectionUtil";

/**
 * Props
 */
export interface ExamChallengeSetHeaderProps {
  index: number;
  challengeSet: ExamChallengeSetModel;
  editAllowed?: boolean;
  formErrors?: { [key: string]: string };
  reorderAllowed?: boolean;
  readOnly?: boolean;

  onDelete?: () => void;
  onEdit?: () => void;
  onReorder?: () => void;
  isTierActionAllowed: (tierAction: TierAction) => boolean;
}

export const ExamChallengeSetHeader = (props: ExamChallengeSetHeaderProps) => {
  const {
    index,
    challengeSet,
    editAllowed,
    formErrors = {},
    reorderAllowed,
    readOnly,
    isTierActionAllowed,
  } = props;
  const { isOptionalSet, isRandomSet } = challengeSet;

  const rootStyle = classnames("code-exam-edit__exam-challenge-set-header");

  const weight = getChallengeSetWeight(challengeSet);
  const numberChallengesToTake =
    challengeSet.numberChallengesToTake === undefined
      ? 1
      : challengeSet.numberChallengesToTake;

  return (
    <div className={rootStyle}>
      <div className="code-exam-edit__exam-challenge-set-header__left">
        <div className="code-exam-edit__exam-challenge-set-header__title">
          <strong>
            {`${index + 1}. ${Message.getMessageByKey(
              getChallengeSetMessageKey(
                challengeSet.isRandomSet,
                challengeSet.isOptionalSet,
              ),
            )}`}
          </strong>
          {!(
            isOptionalAllowed(challengeSet, isTierActionAllowed) &&
            isRandomAllowed(challengeSet, isTierActionAllowed)
          ) && (
            <ErrorIcon tooltipText={<Msg id="tier.disabled.notSupported" />} />
          )}
          {(isOptionalSet || isRandomSet) && (
            <div className="code-exam-edit__exam-challenge-set-header__options">
              <div className="code-exam-edit__exam-challenge-set-header__label">
                <Tag>{`${Message.getMessageByKey("weight")}: ${weight}`}</Tag>
              </div>
              {isOptionalSet && (
                <div className="code-exam-edit__exam-challenge-set-header__label">
                  <Tag>
                    {`${Message.getMessageByKey(
                      "exam.numberChallengesToTake",
                    )}: ${numberChallengesToTake}`}
                  </Tag>
                  <QuickHelp
                    text={Message.getMessageByKey(
                      "exam.numberChallengesToTake.description",
                    )}
                  />
                </div>
              )}
            </div>
          )}
        </div>
        {formErrors.numberChallengesToTake && (
          <div className="code-exam-edit__exam-challenge-set-header__options">
            <ValidationMessage
              name="error"
              error={{
                error: Message.getMessageByKey(
                  formErrors.numberChallengesToTake,
                ),
              }}
            />
          </div>
        )}
      </div>
      <div className="code-exam-edit__exam-challenge-set-header__right">
        <Restricted
          className="code-exam-list__action"
          roles={[
            UserRole.SystemAdmin,
            ProjectRole.ProjectAdmin,
            ProjectRole.ExamCreator,
          ]}
          strictAllow={Boolean(!readOnly && (reorderAllowed || editAllowed))}
        >
          <ActionDropdown>
            {reorderAllowed && (
              <a role="menuitem" onClick={props.onReorder}>
                <Icon type="bars" />
                <Msg id="reorder" />
              </a>
            )}
            {editAllowed && (
              <>
                {(isOptionalSet || isRandomSet) && (
                  <a role="menuitem" onClick={props.onEdit}>
                    <Icon type="pencil" />
                    <Msg id="edit" />
                  </a>
                )}
                <a role="menuitem" onClick={props.onDelete}>
                  <Icon type="trash" />
                  <Msg id="delete" />
                </a>
              </>
            )}
          </ActionDropdown>
        </Restricted>
      </div>
    </div>
  );
};
