import { useState } from "react";

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@shared/components/popover/Popover";
import { useSubmissionNoteList } from "@shared/hooks/query";

import { Icon, RichMarkdown, Spinner } from "../..";
import { IconType } from "../../icon/icon/types";

interface NoteTooltipProps {
  projectId: number;
  submissionId: number;
}

export const NotePopover = ({ projectId, submissionId }: NoteTooltipProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { data, isLoading, refetch } = useSubmissionNoteList(
    projectId,
    submissionId,
    false,
  );

  const onOpen = (open: boolean) => {
    if (open) {
      setIsOpen(true);
      refetch();
    } else {
      setIsOpen(false);
    }
  };
  let note = "";

  if (data && data.noteHistory.result.length > 0) {
    note = data.noteHistory.result[0].content;
  }

  return (
    <>
      <Popover
        maxSize="xlarge"
        placement="right"
        onOpenChange={onOpen}
        open={isOpen}
      >
        <PopoverTrigger onClick={() => onOpen(!isOpen)}>
          <Icon type={IconType.NoteSticky} variant="regular" />
        </PopoverTrigger>

        <PopoverContent>
          {isLoading ? (
            <Spinner size="small" />
          ) : (
            <div className="code-c-notepopover-content">
              <RichMarkdown
                preview
                className="code-c-notepopover-content__markdown markdown"
                value={note}
              />
            </div>
          )}
        </PopoverContent>
      </Popover>
    </>
  );
};
