import classNames from "classnames";

import { useSubmissionAccessTypeContext } from "@context";

import { Accordion, CustomFormDisplay, Icon, Msg } from "@shared/components";
import {
  useGetSubmissionsFormValues,
  useGuestEntryForm,
} from "@shared/hooks/query";
import { CustomFormModel, SubmissionAccessType } from "@shared/models";

interface SubmissionDetailCustomFormComponentProps {
  submissionLanguage?: string;
}

export const SubmissionDetailCustomFormGuest = (
  props: SubmissionDetailCustomFormComponentProps & { token: string },
) => {
  const { data = [] } = useGuestEntryForm();

  return <SubmissionDetailCustomFormComponent {...props} form={data} />;
};

export const SubmissionDetailCustomForm = (
  props: SubmissionDetailCustomFormComponentProps,
) => {
  const { data: form } = useGetSubmissionsFormValues();
  const submissionAccessType = useSubmissionAccessTypeContext();

  if (
    submissionAccessType !== SubmissionAccessType.Full ||
    !form ||
    form.length === 0
  ) {
    return null;
  }

  return <SubmissionDetailCustomFormComponent {...props} form={form} />;
};

interface CommonProps {
  form: CustomFormModel[];
}

const SubmissionDetailCustomFormComponent = ({
  submissionLanguage,
  form,
}: SubmissionDetailCustomFormComponentProps & CommonProps) => {
  const totalFieldCount = form.length;
  const responsesCount = form.filter(
    (formField) => formField.value !== undefined && formField.value != "",
  ).length;
  const isEmpty = totalFieldCount === 0;
  const summaryStyle = classNames(
    "code-submission-detail__entry-form-summary",
    { disabled: isEmpty },
  );

  return (
    <Accordion
      className="code-exam-detail-top__custom-form-panel"
      summaryClassName={summaryStyle}
      disabled={isEmpty}
      detailClassName="code-submission-detail__entry-form-detail"
      customSummary={(open) => (
        <>
          <h3 className="text-heading3">
            <Msg id={"submission.submissionDetail.entryFormResponses"} />
            <Msg
              tagName="small"
              id="submission.submissionDetail.entryForm.responsesCount"
              values={{ count: responsesCount, total: totalFieldCount }}
            />
          </h3>
          {!isEmpty && (
            <Icon
              type={open ? "angle-up" : "angle-down"}
              className="code-submission-detail__toggle-angle"
            />
          )}
        </>
      )}
    >
      {form
        .filter((formField) =>
          submissionLanguage ? formField.language === submissionLanguage : true,
        )
        .map((formField) => (
          <CustomFormDisplay key={formField.key} formValue={formField} />
        ))}
    </Accordion>
  );
};
