import { connect } from "react-redux";
import { Action, Dispatch } from "redux";

import { RootState } from "@reducers";

import { getTierAllowedByRedux } from "@shared/services/tier";

import { MemberRoleEdit, ExternalProps } from "./MemberRoleEdit";

const mapStateToProps = (state: RootState, props: ExternalProps) => ({
  ...props,
  isTierActionAllowed: getTierAllowedByRedux(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(MemberRoleEdit);
