import { AxiosRequestConfig } from "axios";

import { PreviewContentModel } from "@shared/models";

import { get } from "../httpClient";

interface SubmissionPreviewPlaybackParams {
  challengeResultId: number;
  examId: number;
  projectId: number;
  submissionId: number;
  options?: AxiosRequestConfig;
}

export async function getSubmissionPreviewContent({
  challengeResultId,
  examId,
  projectId,
  submissionId,
  options,
}: SubmissionPreviewPlaybackParams) {
  const { data } = await get<PreviewContentModel>(
    `/api/projects/${projectId}/exams/${examId}/submissions/${submissionId}/preview/${challengeResultId}/content`,
    options,
  );

  return data;
}
