import { connect } from "react-redux";
import { Action, Dispatch } from "redux";

import { RootState } from "@reducers";

import Header, { ExternalProps, HeaderType } from "./Header";

const mapStateToProps = (state: RootState, props: ExternalProps) => ({
  // NOTE: state.user becomes optional because this component gets used on applicant side as well.
  user: state.user?.user,
  currentProject: state.user?.user?.projects.find(
    (item) => item.id === state.project.currentProjectId,
  ),
  ...props,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({});

/**
 * Wrapped component
 */
const wrappedComponent = connect(mapStateToProps, mapDispatchToProps)(Header);

export { HeaderType };

export default wrappedComponent;
