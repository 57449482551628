import { connect } from "react-redux";
import { Action, Dispatch } from "redux";

import { submissionListDrawerAction } from "@actions";

import { RootState } from "@reducers";

import { SubmissionListModel } from "@shared/models";

import { SubmissionList, ExternalProps } from "./SubmissionList";

const mapStateToProps = (state: RootState, props: ExternalProps) => {
  return {
    currentProjectId: state.project.currentProjectId,
    currentUser: state.user.user,
    drawerSubmission: state.exam.drawerSubmission,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  setDrawerSubmission: (submission?: SubmissionListModel) => {
    dispatch(submissionListDrawerAction.request({ result: submission }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SubmissionList);
