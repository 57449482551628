import classNames from "classnames";
import * as React from "react";

import { ReviewScore } from "@shared/services/enums";

import ReviewScoreIcon from "../icon/reviewScoreIcon/ReviewScoreIcon";
import { reviewClassMap } from "../review/Review";

export const ReviewStatusTrackerBarItem = ({
  reviewType,
  reviewCount,
  totalReviews,
  onHover,
  onUnhover,
}: {
  reviewType: ReviewScore;
  reviewCount: number;
  totalReviews: number;
  onHover?: (
    e: React.FormEvent<HTMLDivElement>,
    reviewType: ReviewScore,
    reviewCount: number,
  ) => void;
  onUnhover?: () => void;
}) => {
  return (
    <div
      className={classNames(
        typeof reviewType !== "undefined"
          ? reviewClassMap[reviewType]
          : undefined,
      )}
      style={{
        flexBasis:
          totalReviews > 0
            ? `${(reviewCount / totalReviews) * 100}%`
            : undefined,
      }}
      onMouseOver={(e) => onHover?.(e, reviewType, reviewCount)}
      onMouseOut={onUnhover}
    >
      <ReviewScoreIcon
        className="code-c-review-status-tracker-bar__icon"
        score={reviewType}
      />
      <span>{reviewCount}</span>
    </div>
  );
};
