import * as classnames from "classnames";

import { Msg } from "../../../../../shared/components";
import { ChallengeReleaseNoteModel } from "../../../../../shared/models";
import { formatDate } from "../../../../../shared/services/date";

/**
 * Prop interface
 */
export interface ReleaseNoteProps {
  releaseNote?: ChallengeReleaseNoteModel;
}

/**
 * React Component
 */
export function ReleaseNote({ releaseNote }: ReleaseNoteProps) {
  const rootStyle = classnames("code-c-release-note");
  return (
    <ul className={rootStyle} role="table">
      {!releaseNote || releaseNote.releaseNotes.length === 0 ? (
        <li role="status">
          <Msg id="challengeVersion.no.releaseNote" />
        </li>
      ) : (
        releaseNote.releaseNotes.map((note) => {
          const releaseAt = formatDate(note.releasedAt || "");
          return (
            <li
              key={note.version}
              className="code-c-release-note__item"
              role="row"
              aria-label={note.version}
            >
              <div className="code-c-release-note__item-header" role="cell">
                <strong>{note.version}</strong>
                <time
                  dateTime={releaseAt}
                  className="code-c-release-note__release-at"
                >
                  {releaseAt}
                </time>
              </div>
              <div className="code-c-release-note__note" role="cell">
                {note.note}
              </div>
            </li>
          );
        })
      )}
    </ul>
  );
}
