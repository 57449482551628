import { connect } from "react-redux";
import { Action, Dispatch } from "redux";

import { SortOrderItem } from "@shared/models";

import { questionListGetAction, questionGetAction } from "../../../../actions";
import { RootState } from "../../../../reducers";
import { PaginationModel } from "../../../../shared/models";
import QuestionSelect from "./QuestionSelect";

const mapStateToProps = (state: RootState) => ({
  questionFilters: state.question.questionFilters,
  loading: state.question.loadingList,
  loadingDetail: state.question.loadingDetail,
  questionList: state.question.questionList,
  questionDetailList: state.question.questionDetailList,
  pagination: state.question.questionListPagination,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  getQuestionList: (
    pagination: PaginationModel,
    filters: {},
    sortOrder: SortOrderItem,
  ) => {
    dispatch(
      questionListGetAction.request({
        ...pagination,
        ...filters,
        "sortOrder.column": sortOrder.column,
        "sortOrder.direction": sortOrder.direction,
      }),
    );
  },
  getQuestion: (payload: {}) => {
    dispatch(questionGetAction.request(payload));
  },
});

/**
 * Wrapped component
 */
const wrappedComponent = connect(
  mapStateToProps,
  mapDispatchToProps,
)(QuestionSelect);

export default wrappedComponent;
