import * as classnames from "classnames";

import { Icon, Button, Msg } from "..";

/**
 * Prop interface
 */
export interface ErrorTileProps {
  title?: string;
  errorCode?: string;
  errorMessage?: string;
  errorDescription?: string;
  helpMessage?: string;
  hasBackButton?: boolean;
  hasBoxShadow?: boolean;
  onClickBackPathName?: string;
  className?: string;
}

/**
 * React Component
 */
export default function ErrorTile({
  title = "Ooops!",
  errorCode = "Error",
  errorDescription,
  errorMessage,
  helpMessage,
  hasBackButton = true,
  hasBoxShadow = true,
  onClickBackPathName = "/",
  className,
}: ErrorTileProps) {
  const rootStyle = classnames("code-c-error-tile", {
    [`${className}`]: Boolean(className),
    [`is-no-box-shadow`]: !hasBoxShadow,
  });

  return (
    <div className={rootStyle} data-error={errorCode}>
      <h1 className="code-c-error-tile__title title is-spaced">{title}</h1>
      <p className="code-c-error-tile__message subtitle">{errorMessage}</p>
      <p className="code-c-error-tile__description">{errorDescription}</p>
      {hasBackButton && (
        <Button
          type="primary"
          outlined={true}
          ariaLabel="Back To Top"
          route={{ pathname: onClickBackPathName }}
        >
          <Icon type="angle-left" />
          <span>
            <Msg id="action.backToTop" />
          </span>
        </Button>
      )}
      <p className="code-c-error-tile__report">
        <Msg id={helpMessage || "error.helpTip"} />
      </p>
    </div>
  );
}
