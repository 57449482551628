import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { RootState } from "@reducers";

import DeliverEmail from "./DeliverEmail";

const mapStateToProps = (state: RootState) => ({
  currentUser: state.user.user,
});

/**
 * Wrapped component
 */
export default withRouter(connect(mapStateToProps, {})(DeliverEmail));
