import classNames from "classnames";

import { useStoreContext } from "@context";

import { Avatar, Tag, Icon, Tooltip, Button, Banner, Msg } from "..";
import { ReviewerUserModel } from "../../models";
import { formatDateTimeMinutes } from "../../services/date";
import { InvitationStatus, ReviewScore } from "../../services/enums";
import Message from "../../services/message";
import ReviewScoreIcon from "../icon/reviewScoreIcon/ReviewScoreIcon";

/**
 * Prop interface
 */
export interface ReviewProps {
  reviewer: ReviewerUserModel;
  score?: ReviewScore;
  feedback?: string;
  reviewedAt?: string;
  isEditable?: boolean;
  isStale?: boolean;
  isPingable?: boolean;
  canReview?: boolean;
  pingDisabled?: boolean;
  onClickEdit?: () => void;
  onClickPing?: () => void;
  className?: string;
}

export const reviewClassMap = {
  [ReviewScore.StronglyReject]: "is-strongly-rejected",
  [ReviewScore.Reject]: "is-rejected",
  [ReviewScore.Approve]: "is-approved",
  [ReviewScore.StronglyApprove]: "is-strongly-approved",
};

/**
 * React Component
 */
export const Review = ({
  reviewer,
  score,
  feedback,
  reviewedAt,
  isEditable,
  isStale,
  isPingable,
  pingDisabled,
  canReview,
  onClickEdit,
  onClickPing,
  className,
}: ReviewProps) => {
  /**
   * Private Functions
   */
  const rootStyle = classNames(
    "code-c-review",
    typeof score !== "undefined" ? reviewClassMap[score] : undefined,
    {
      "is-not-reviewed": score === undefined,
      "is-stale": isStale,
      [`${className}`]: Boolean(className),
    },
  );

  const { user } = useStoreContext();

  const isCurrentUserReviewer = user?.id === reviewer.id;

  const PingButton = () => {
    const pingButtonStyle = classNames("code-c-review__ping-icon", {
      ["is-disabled"]: pingDisabled || !canReview,
    });

    return (
      <div
        className={pingButtonStyle}
        role="button"
        aria-label="Ping Reviewer"
        aria-disabled={pingDisabled || !canReview}
        onClick={pingDisabled || !canReview ? undefined : onClickPing}
      >
        <Icon type="envelope" />
      </div>
    );
  };

  const tagColor =
    reviewer.invitationStatus === InvitationStatus.Expired
      ? "gray-darkest"
      : "gray";

  /**
   * Render
   */
  return (
    <div className={rootStyle}>
      {isStale && (
        <Banner type="warning-gray" className="code-c-review__banner">
          <span className="code-c-review__banner__text">
            {Message.getMessageByKey("reviewScore.outdated.label")}{" "}
            {isCurrentUserReviewer && <Msg id={"reviewScore.outdated.self"} />}
            {!isCurrentUserReviewer && canReview && isPingable && (
              <Msg
                id={"reviewScore.outdated.notifyText"}
                className={classNames({
                  "code-c-review__banner__a-disabled": pingDisabled,
                })}
                values={{
                  a: (chunks: string) => (
                    <a onClick={pingDisabled ? undefined : onClickPing}>
                      {chunks}
                    </a>
                  ),
                }}
              />
            )}
          </span>
        </Banner>
      )}

      <div className="code-c-review__body">
        <div className="code-c-review__top">
          <div>
            {reviewer.displayStatus && (
              <Tag color={tagColor} className="code-c-review__user-status">
                {reviewer.displayStatus}
              </Tag>
            )}
            <div className="code-c-review__profile">
              <div className="code-c-review__avatar">
                <Avatar src={reviewer.imageUrl} size="small" />
              </div>
              <div className="code-c-review__reviewer">
                <div className="code-c-review__reviewer-name-tag">
                  <div className="code-c-review__reviewer-name-container is-break-word">
                    <span className="code-c-review__reviewer-name__name">
                      {reviewer.name}
                    </span>{" "}
                    {isCurrentUserReviewer && (
                      <span className="code-c-review__reviewer-name__you">
                        ({Message.getMessageByKey("reviewScore.you")})
                      </span>
                    )}
                  </div>
                </div>
                {reviewedAt && (
                  <div className="code-c-review__reviewed-at">
                    <span>{formatDateTimeMinutes(reviewedAt)}</span>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="code-c-review__status">
            {score !== undefined ? (
              <Tag
                className="code-c-review__score-tag"
                startIcon={
                  <ReviewScoreIcon
                    score={score}
                    className="code-c-icon__text-base"
                  />
                }
              >
                {ReviewScore.toString(score)}
              </Tag>
            ) : (
              <div className="code-c-review__not-reviewed-tag">
                <Icon type="eye-slash" />
                {ReviewScore.toString(score)}
              </div>
            )}
            {(isPingable || isEditable) && (
              <div className="code-c-review__buttons">
                {isPingable &&
                  !isStale &&
                  (canReview ? (
                    <Tooltip
                      placement="top-end"
                      text={Message.getMessageByKey(
                        "submission.pingReviewer.title",
                      )}
                      disabled={pingDisabled}
                    >
                      <PingButton />
                    </Tooltip>
                  ) : (
                    <Tooltip
                      placement="top-end"
                      text={Message.getMessageByKey("tier.disabled")}
                    >
                      <PingButton />
                    </Tooltip>
                  ))}
                {isEditable && (
                  <Tooltip
                    text={Message.getMessageByKey("edit")}
                    placement="top-end"
                  >
                    <Button
                      onClick={onClickEdit}
                      shrink={true}
                      size={"small"}
                      ariaLabel="Edit Review"
                    >
                      <Icon type="pencil" />
                    </Button>
                  </Tooltip>
                )}
              </div>
            )}
          </div>
        </div>
        {feedback && (
          <div className="code-c-review__feedback is-break-word">
            {feedback}
          </div>
        )}
      </div>
    </div>
  );
};
