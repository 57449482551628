import * as classnames from "classnames";
import * as React from "react";
import { useCallback, useEffect, useState } from "react";

import Message from "@shared/services/message";

import {
  Block,
  BlockItem,
  LeftBlock,
  RightBlock,
  Button,
  Icon,
  Msg,
  Tooltip,
} from "../";

/**
 * Prop interface
 */
export interface StepsHeaderProps {
  className?: string;
  currentStep?: number;
  stepItems?: string[];
  isStrictOrder?: boolean;
  errors?: boolean[];
  onNext?: (currentStep: number, previousStep?: number) => void;
  onPrev?: (currentStep: number, previousStep?: number) => void;
  onComplete?: (currentStep?: number, previousStep?: number) => void;
  completeButtonLabel?: string;
  disableNext?: boolean;
  disablePrev?: boolean;
  disableComplete?: boolean;
}

/**
 * State interface
 */
export interface StepsHeaderState {
  currentStep: number;
}

export const StepsHeader: React.FC<StepsHeaderProps> = ({
  className,
  stepItems = [],
  isStrictOrder = false,
  errors = [],
  currentStep: propCurrentStep = 0,
  completeButtonLabel = Message.getMessageByKey("action.complete"),
  disableNext = false,
  disablePrev = false,
  disableComplete = false,
  onComplete,
  onNext,
  onPrev,
}) => {
  const [currentStep, setCurrentStep] = useState<number>(propCurrentStep);

  useEffect(() => {
    if (propCurrentStep !== currentStep) {
      setCurrentStep(propCurrentStep || 0);
    }
  }, [propCurrentStep, currentStep]);

  const handlePrev = useCallback(() => {
    const presentStep = currentStep;
    const newStep = Math.max(currentStep - 1, 0);
    setCurrentStep(newStep);
    if (onPrev) onPrev(newStep, presentStep);
  }, [currentStep, onPrev]);

  const handleNext = useCallback(() => {
    const presentStep = currentStep;
    const newStep = currentStep + 1;
    setCurrentStep(newStep);
    if (onNext) onNext(newStep, presentStep);
  }, [currentStep, onNext]);

  const handleComplete = useCallback(
    (targetStep: number) => {
      const presentStep = currentStep;
      setCurrentStep(targetStep);
      if (onComplete) onComplete(targetStep, presentStep);
    },
    [onComplete, currentStep],
  );

  const handleJump = useCallback(
    // TTODO: update here to handle disabled steps
    (targetStep: number) => {
      const presentStep = currentStep;
      setCurrentStep(targetStep);
      if (onNext) onNext(targetStep, presentStep);
    },
    [onNext],
  );

  const rootStyle = classnames("code-c-steps-header", {
    [`${className}`]: Boolean(className),
  });

  const nextButton =
    currentStep + 1 >= stepItems.length ? (
      <Button
        type="primary"
        shrink={true}
        onClick={() => handleComplete(currentStep)}
        disabled={disableComplete}
      >
        {completeButtonLabel}
      </Button>
    ) : (
      <Button shrink={true} onClick={handleNext} disabled={disableNext}>
        <span>
          <Msg id="action.next" />
        </span>
        <Icon type="angle-right" />
      </Button>
    );

  const steps = stepItems.map((label, index) => {
    const isDisabled = isStrictOrder
      ? disableNext
        ? index > currentStep
        : index > currentStep + 1
      : false;

    const itemStyle = classnames("code-c-steps-header__item", "steps-segment", {
      "has-error": errors[index],
      "is-active": index === currentStep,
      "is-dashed": index >= (currentStep - 1 || 0) || isDisabled,
    });

    const stepMarker = errors[index] ? (
      <button
        type="button"
        className={classnames("steps-marker has-error", {
          "is-disabled": isDisabled,
        })}
        onClick={() => handleJump(index)}
        aria-label={label}
        disabled={isDisabled}
      >
        <Tooltip
          text={Message.getMessageByKey("validation.mistakeFound")}
          placement="bottom"
        >
          <Icon type="times" />
        </Tooltip>
      </button>
    ) : (
      <button
        className={classnames("steps-marker", {
          "is-disabled": isDisabled,
        })}
        onClick={() => handleJump(index)}
        aria-label={label}
        disabled={isDisabled}
      >
        <Icon type="check" />
      </button>
    );

    return (
      <li className={itemStyle} key={index}>
        {stepMarker}
        <a
          onClick={() => {
            if (!isDisabled) handleJump(index);
          }}
          className={classnames("steps-content", {
            "is-disabled": isDisabled,
          })}
          role="button"
        >
          <span className="steps-content__link">{label}</span>
        </a>
      </li>
    );
  });

  return (
    <Block className={rootStyle}>
      <LeftBlock className="code-c-steps-header__prev">
        <Button
          shrink={true}
          disabled={currentStep === 0 || disablePrev}
          onClick={handlePrev}
        >
          <Icon type="angle-left" />
          <span>
            <Msg id="action.prev" />
          </span>
        </Button>
      </LeftBlock>
      <BlockItem>
        <ul className="steps has-gaps has-content-centered">{steps}</ul>
      </BlockItem>
      <RightBlock className="code-c-steps-header__next">
        {nextButton}
      </RightBlock>
    </Block>
  );
};
