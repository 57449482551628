import * as classnames from "classnames";
import { useState } from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";

import {
  PageTitle,
  Block,
  LeftBlock,
  Form,
  FormGroup,
  Label,
  Button,
  ImageUpload,
  Input,
  Avatar,
  Msg,
} from "@shared/components";
import { OrganizationModel, UploadImagesModel } from "@shared/models";

/**
 * Prop interface
 */
export interface SettingProfileProps {
  isAdmin: boolean;
  organization: OrganizationModel;
  uploadedImages: UploadImagesModel;
  imageUploading: boolean;

  uploadImage: (
    name: string,
    file: File,
    width: number,
    height: number,
    tags: string,
    transformation?: string,
  ) => void;
  updateOrgInfo: (payload: {}) => void;
}

/**
 * Page component
 */
export default function SettingProfile({
  isAdmin,
  imageUploading,
  uploadedImages,
  uploadImage,
  updateOrgInfo,
  organization = {} as OrganizationModel,
}: SettingProfileProps) {
  const rootStyle = classnames("code-setting-profile");

  const [imageTouched, setImageTouched] = useState(false);
  const [formChanged, setFormChanged] = useState(false);

  const imageUrl =
    imageTouched && uploadedImages.coverImg
      ? uploadedImages.coverImg.url
      : organization.imageUrl;

  const handleDrop = (files: Array<File>) => {
    if (files.length) {
      uploadImage("coverImg", files[0], 160, 160, "organizationCover");
      setImageTouched(true);
      setFormChanged(true);
    }
  };

  const handleUpdate = (formValues: {}) => {
    updateOrgInfo({
      ...formValues,
      imageUrl,
    });
  };

  const onFormChange = (_: boolean, formValues: { displayName: string }) => {
    setFormChanged(
      imageTouched || formValues.displayName !== organization.displayName,
    );
  };

  return (
    <div className={rootStyle}>
      <BreadcrumbsItem to="/settings/org/profile">
        <Msg id="orgProfile" />
      </BreadcrumbsItem>
      <Block className="code-setting__header">
        <LeftBlock>
          <PageTitle>
            <Msg id="orgProfile" />
          </PageTitle>
        </LeftBlock>
      </Block>
      <Form
        className="code-setting-profile__form"
        onSubmit={handleUpdate}
        onFormChange={onFormChange}
        validation={{
          displayName: ["string", "required"],
        }}
        initialValues={{
          displayName: organization.displayName,
          imageUrl: organization.imageUrl,
        }}
      >
        <Block>
          <LeftBlock direction={"column"}>
            <FormGroup>
              <Label>
                <Msg id="form.organizationName" />
              </Label>
              <Label>{organization.name}</Label>
            </FormGroup>
            <FormGroup>
              <Label>
                <Msg id="form.displayName" />
              </Label>
              {isAdmin ? (
                <Input size={6} name="displayName" />
              ) : (
                <Label>{organization.displayName}</Label>
              )}
            </FormGroup>
          </LeftBlock>
          <LeftBlock direction={"column"}>
            <FormGroup>
              <Label>
                <Msg id="form.logo" />
              </Label>
              {isAdmin ? (
                <ImageUpload
                  name="imageUrl"
                  src={imageUrl}
                  onDrop={handleDrop}
                  disabled={imageUploading}
                />
              ) : (
                <Avatar src={organization.imageUrl} size="xxlarge" />
              )}
            </FormGroup>
          </LeftBlock>
        </Block>
        {isAdmin && (
          <FormGroup className="code-setting-profile__buttons">
            <Button type="primary" disabled={!formChanged} ariaLabel="Save">
              <Msg id="save" />
            </Button>
          </FormGroup>
        )}
      </Form>
    </div>
  );
}
