import * as classnames from "classnames";

import { dayjs, formatTimeLeftString } from "../../services/date";
import Message from "../../services/message";

/**
 * Prop interface
 */
export interface CountDownTimerProps {
  timeLeftSeconds?: number;
  thresholdSeconds?: number;
  className?: string;
}

/**
 * React Component
 */
export default function CountDownTimer({
  timeLeftSeconds,
  thresholdSeconds,
  className,
}: CountDownTimerProps) {
  const rootStyle = classnames("code-c-count-down-timer", {
    [`${className}`]: Boolean(className),
  });

  const isShow =
    thresholdSeconds !== undefined
      ? dayjs.duration(timeLeftSeconds ?? 0, "seconds").asSeconds() <
        thresholdSeconds
      : true;

  if (!isShow) {
    return null;
  }

  return (
    <span className={rootStyle}>
      {timeLeftSeconds === undefined || timeLeftSeconds <= 0
        ? formatTimeLeftString(timeLeftSeconds)
        : Message.getMessageByKey("challenge.timeRemaining").replace(
            "{time}",
            formatTimeLeftString(timeLeftSeconds),
          )}
    </span>
  );
}
