import * as classnames from "classnames";

import { Label, Msg } from "@shared/components";
import { ActionType, MultiManageForm, VersionType } from "@shared/models";

/**
 * Interface
 */
export type FormStateBoxProps = {
  formValues: MultiManageForm;
  challengeBar: JSX.Element | null;
};

export function FormStateBox({
  formValues: { action, version, challenge },
  challengeBar,
}: FormStateBoxProps) {
  const rootStyle = classnames("code-multiple-manage-modal__form-state-box");

  return (
    <div className={rootStyle}>
      {action && (
        <div>
          <Label title={<Msg id="submission.actionType" />} />
          <p>
            <Msg
              id={
                action === ActionType.Rescore
                  ? "submission.action.rescoreSubmissions.short"
                  : "submission.action.resetSubmissions.short"
              }
            />
          </p>
        </div>
      )}
      {version && (
        <>
          <div className="divider" />
          <div>
            <Label title={<Msg id="submission.version" />} />
            <p>
              <Msg
                id={
                  version === VersionType.Current
                    ? "submission.version.current"
                    : "submission.version.new"
                }
              />
            </p>
          </div>
        </>
      )}
      {challenge && (
        <>
          <div className="divider" />
          <div>
            <Label title={<Msg id="submission.target.challenge" />} />
            {challengeBar}
          </div>
        </>
      )}
    </div>
  );
}
