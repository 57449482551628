import { Modal, Msg } from "@shared/components";
import Message from "@shared/services/message";

interface RequireWebcamConfirmModalProps {
  onClose: () => void;
  onConfirm: () => void;
}

export const RequireWebcamConfirmModal = ({
  onClose,
  onConfirm,
}: RequireWebcamConfirmModalProps) => {
  return (
    <Modal
      title={Message.getMessageByKey(
        "exam.applicantActionLogs.showWebcamEvent.subOption",
      )}
      isOpen
      onClose={onClose}
      onClickOk={onConfirm}
      onClickCancel={onClose}
      //
      okButtonLabel={Message.getMessageByKey(
        "exam.requireWebcam.modal.okButton",
      )}
      okButtonAriaLabel="confirm"
      ariaLabel="Require webcam"
      className="code-exam-webcam-require-confirm"
    >
      <div className="code-exam-webcam-require-confirm__first">
        <Msg id="exam.requireWebcam.modal.content1" />
      </div>
      <Msg id="exam.requireWebcam.modal.content2" />
    </Modal>
  );
};
