import { ChallengeStyle } from "../services/enums";

class ChallengeInsightModel {
  public id: number;
  public title: string;
  public updatedAt: string;
  public createdAt: string;
  public difficulty: number;
  public style: ChallengeStyle;
  public averageScore?: number;
  public medianScore?: number;
  public medianMinutesTaken?: number;
  public deviation?: number;
  public scoreDistributions: Array<{
    leftClosedBoundary: number;
    rightClosedBoundary: number;
    frequency: number;
  }> = [];
  public monthlyApplicantsStats: Array<{
    year: number;
    month: number;
    count: number;
  }> = [];
  public programmingLanguagesStats: Array<{
    language: number;
    applicantCount: number;
    averageScore: number;
    averageSolvingTimeInSeconds: number;
    averageSolvingTimeInMinutes: number;
  }> = [];

  constructor(args?: {}) {
    Object.assign(this, args);
  }

  public getNumberOfSubmission(): number {
    return this.scoreDistributions.reduce(
      (sum, current) => sum + current.frequency,
      0,
    );
  }
}

export default ChallengeInsightModel;
