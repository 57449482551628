import * as classnames from "classnames";
import { isEqual } from "lodash";
import * as React from "react";

import {
  Modal,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Checkbox,
  Question,
  Table,
  TableHead,
  TableBody,
  HeaderColumn,
  Row,
  Column,
  Loading,
  TagPicker,
  RichTextarea,
  Msg,
  Textarea,
} from "../../../../shared/components";
import { QuestionModel, QuestionFilterModel } from "../../../../shared/models";
import { QuestionType } from "../../../../shared/services/enums";
import { appendLineBreak } from "../../../../shared/services/fibParser";
import Message from "../../../../shared/services/message";

export interface QuestionNewProps {
  title: string | JSX.Element;
  isOpen: boolean;
  loading?: boolean;
  question?: QuestionModel;
  questionFilters: QuestionFilterModel;
  onClose?: () => void;
  onCreateQuestion: (payload: {}) => void;
}

export interface QuestionNewState {
  newQuestionType?: QuestionType;
  formValid: boolean;
  mcqValid: boolean;
  formError?: { [key: string]: string };
  formValues: {};
  mcqChoices: Array<string>;
  mcqAnswers: Array<number>;
  quizCategories: Array<number>;
}

class QuestionNew extends React.Component<QuestionNewProps, QuestionNewState> {
  private lastMcqChoice: HTMLInputElement | null;

  constructor(props: QuestionNewProps) {
    super(props);

    this.state = {
      formValid: false,
      mcqValid: false,
      formValues: {
        question: "",
      },
      newQuestionType: props.question ? props.question.kind : undefined,
      quizCategories: props.question ? props.question.quizCategories : [],
      mcqChoices: [],
      mcqAnswers: [],
    };
  }

  public UNSAFE_componentWillReceiveProps(nextProps: QuestionNewProps) {
    if (
      (nextProps.isOpen && !this.props.isOpen) ||
      !isEqual(this.props.question, nextProps.question)
    ) {
      return this.setState(
        {
          formValid: false,
          formValues: {
            question: "",
          },
          newQuestionType: nextProps.question
            ? nextProps.question.kind
            : undefined,
          mcqChoices: (nextProps.question && nextProps.question.choices) || [],
          mcqAnswers: nextProps.question
            ? (nextProps.question.answers || []).map((answer) =>
                parseInt((/\d+/.exec(String(answer)) || ["0"])[0], 10),
              )
            : [],
          quizCategories: nextProps.question
            ? nextProps.question.quizCategories
            : [],
        },
        this.checkAnswers,
      );
    }
  }

  public componentDidUpdate(
    prevProps: QuestionNewProps,
    prevState: QuestionNewState,
  ) {
    if (prevState.mcqChoices.length !== this.state.mcqChoices.length) {
      // focus new answer
      if (this.lastMcqChoice) {
        this.lastMcqChoice.select();
      }
    }
  }

  public render() {
    const { isOpen, onClose, loading, title, question } = this.props;

    const rootStyle = classnames("code-question-new");

    const typeSelect = (
      <div className="code-question-new__select">
        <div className="code-question-new__select-label">
          <Msg id="newQuestion.selectType" />
        </div>
        <div className="code-question-new__select-buttons">
          <Button
            type="primary"
            onClick={() => this.setState({ newQuestionType: QuestionType.FIB })}
          >
            <Msg id="questionType.fib" />
          </Button>
          <Button
            type="primary"
            onClick={() => this.setState({ newQuestionType: QuestionType.MCQ })}
          >
            <Msg id="questionType.mcq" />
          </Button>
          <Button
            type="primary"
            onClick={() =>
              this.setState({ newQuestionType: QuestionType.FreeText })
            }
          >
            <Msg id="questionType.free" />
          </Button>
        </div>
      </div>
    );

    const newFreeText = (
      <Column>
        <Form
          validation={{
            title: ["string", "notEmpty", "required", ["max", 255]],
            // regex to prevent FIB syntax from being used in FreeText question.
            // (FIB syntax will cause backend to automatically convert to FIB)
            question: [
              "string",
              "notEmpty",
              "required",
              "invalidFreeTextQuestion",
            ],
            // TODO: remove regex when backend is able to handle FIB syntax in FreeText question.
            howToSolve: ["string"],
          }}
          initialValues={{
            title: question?.title ?? "",
            question: question?.question
              ? question.question.replace("\n${FREETEXT}", "")
              : "",
            howToSolve: question?.howToSolve ?? "",
          }}
          onFormChange={this.onFormChange}
        >
          <FormGroup>
            <Label>
              <Msg id="common.title" />
            </Label>
            <Input name="title" />
          </FormGroup>
          <FormGroup>
            <Label>
              <Msg id="common.question" />
            </Label>
            <RichTextarea name="question" hideHeadingControl={true} />
          </FormGroup>
          <FormGroup>
            <Label isOptional={true}>
              <Msg id="newQuestion.explanation" />
            </Label>
            <Textarea name="howToSolve" />
          </FormGroup>
          <FormGroup>
            <Label title={<Msg id={"quizCategories"} />} />
            <TagPicker
              onChange={this.onCategoryChange}
              onlySuggestions={true}
              suggestions={this.props.questionFilters.quizCategories.map(
                (category) => ({
                  label: category.displayString,
                  value: category.value,
                }),
              )}
              tagItems={this.props.questionFilters.quizCategories
                .map((category) => ({
                  label: category.displayString,
                  value: category.value,
                }))
                .filter((category) =>
                  (question ? question.quizCategories : []).includes(
                    category.value,
                  ),
                )}
              clear={isOpen}
              alwaysShow={true}
            />
          </FormGroup>
        </Form>
      </Column>
    );

    const newFIB = (
      <Column>
        <Form
          validation={{
            title: ["string", "notEmpty", "required", ["max", 255]],
            question: ["string", "notEmpty", "required"],
            howToSolve: ["string"],
          }}
          initialValues={{
            title: question?.title ?? "",
            question: question?.question ?? "",
            howToSolve: question?.howToSolve ?? "",
          }}
          onFormChange={this.onFormChange}
        >
          <FormGroup>
            <Label>
              <Msg id="common.title" />
            </Label>
            <Input name="title" />
          </FormGroup>
          <FormGroup>
            <Label>
              <Msg id="common.question" />
            </Label>
            <RichTextarea
              name="question"
              hideHeadingControl={true}
              customActions={[
                {
                  title: Message.getMessageByKey("newQuestion.addBlank"),
                  textToInsert: "${}",
                  cursorPosition: 2,
                },
              ]}
            />
          </FormGroup>
          <FormGroup>
            <Label isOptional={true}>
              <Msg id="newQuestion.explanation" />
            </Label>
            <Textarea name="howToSolve" />
          </FormGroup>
          <FormGroup>
            <Label title={<Msg id={"quizCategories"} />} />
            <TagPicker
              onChange={this.onCategoryChange}
              onlySuggestions={true}
              suggestions={this.props.questionFilters.quizCategories.map(
                (category) => ({
                  label: category.displayString,
                  value: category.value,
                }),
              )}
              tagItems={this.props.questionFilters.quizCategories
                .map((category) => ({
                  label: category.displayString,
                  value: category.value,
                }))
                .filter((category) =>
                  (question ? question.quizCategories : []).includes(
                    category.value,
                  ),
                )}
              clear={isOpen}
              alwaysShow={true}
            />
          </FormGroup>
        </Form>
      </Column>
    );

    const mcqChoices = this.state.mcqChoices.map((answer, index) => (
      <Row key={index}>
        <Column>
          <Checkbox
            value={this.state.mcqAnswers.includes(index)}
            onChange={(e: React.FormEvent<HTMLInputElement>) => {
              const { checked } = e.target as HTMLInputElement;
              this.onSelectAnswer(index, checked);
            }}
          />
        </Column>
        <Column>
          <Input
            value={answer}
            reference={(ref) => {
              if (index === this.state.mcqChoices.length - 1) {
                this.lastMcqChoice = ref;
              }
            }}
            onChange={(e: React.FormEvent<HTMLInputElement>) => {
              const { value } = e.target as HTMLInputElement;
              this.onChangeAnswer(value, index);
            }}
          />
        </Column>
        <Column>
          <button
            type="button"
            className="delete"
            aria-label="close"
            onClick={() => this.onRemoveAnswer(index)}
          />
        </Column>
      </Row>
    ));

    const newMCQ = (
      <Column>
        <Form
          validation={{
            title: ["string", "notEmpty", "required", ["max", 255]],
            question: ["string", "required"],
            howToSolve: ["string"],
          }}
          initialValues={{
            title: question?.title ?? "",
            question: question?.question ?? "",
            howToSolve: question?.howToSolve ?? "",
          }}
          onFormChange={this.onFormChange}
          error={this.state.formError}
        >
          <FormGroup>
            <Label>
              <Msg id="common.title" />
            </Label>
            <Input name="title" />
          </FormGroup>
          <FormGroup>
            <Label>
              <Msg id="common.question" />
            </Label>
            <RichTextarea name="question" hideHeadingControl={true} />
          </FormGroup>
          <FormGroup>
            <Button onClick={() => this.onAddAnswer()} disabled={false}>
              <Msg id="newQuestion.addAnswer" />
            </Button>
          </FormGroup>
          <FormGroup name="options">
            {this.state.mcqChoices.length > 0 && (
              <Table>
                <TableHead>
                  <HeaderColumn size={1}>
                    <Msg id="newQuestion.correctAnswer" />
                  </HeaderColumn>
                  <HeaderColumn size={6}>
                    <Msg id="newQuestion.choices" />
                  </HeaderColumn>
                  <HeaderColumn size={1}>
                    <Msg id="delete" />
                  </HeaderColumn>
                </TableHead>
                <TableBody>{mcqChoices}</TableBody>
              </Table>
            )}
          </FormGroup>
          <FormGroup>
            <Label isOptional={true}>
              <Msg id="newQuestion.explanation" />
            </Label>
            <Textarea name="howToSolve" />
          </FormGroup>
          <FormGroup>
            <Label title={<Msg id={"quizCategories"} />} />
            <TagPicker
              onChange={this.onCategoryChange}
              onlySuggestions={true}
              suggestions={this.props.questionFilters.quizCategories.map(
                (category) => ({
                  label: category.displayString,
                  value: category.value,
                }),
              )}
              tagItems={this.props.questionFilters.quizCategories
                .map((category) => ({
                  label: category.displayString,
                  value: category.value,
                }))
                .filter((category) =>
                  (question ? question.quizCategories : []).includes(
                    category.value,
                  ),
                )}
              clear={isOpen}
              alwaysShow={true}
            />
          </FormGroup>
        </Form>
      </Column>
    );

    let newQuestionForm;

    switch (this.state.newQuestionType) {
      case QuestionType.FIB:
        newQuestionForm = newFIB;
        break;
      case QuestionType.MCQ:
        newQuestionForm = newMCQ;
        break;
      case QuestionType.FreeText:
        newQuestionForm = newFreeText;
        break;
      default:
        newQuestionForm = null;
    }

    const newQuestion = (
      <Table hoverable={false}>
        <TableHead>
          <HeaderColumn>
            <Msg id={"common.question"} />
          </HeaderColumn>
          <HeaderColumn>
            <Msg id={"preview"} />
          </HeaderColumn>
        </TableHead>
        <TableBody>
          <Row>
            {newQuestionForm}
            <Column>
              <div className="code-question-new__preview">
                <Question
                  showTextarea={true}
                  question={
                    new QuestionModel({
                      answers:
                        this.state.newQuestionType === QuestionType.FIB
                          ? (
                              (this.state.formValues as { question: string })
                                .question || ""
                            ).match(/\$\{([^}\\]|\\.)*\}/g) || []
                          : this.state.mcqAnswers,
                      choices: this.state.mcqChoices,
                      kind: this.state.newQuestionType,
                      ...this.state.formValues,
                    })
                  }
                />
              </div>
            </Column>
          </Row>
        </TableBody>
      </Table>
    );

    return (
      <div>
        <Loading isOpen={loading} />
        <Modal
          className={rootStyle}
          size="x-large"
          title={title}
          isOpen={isOpen && !loading}
          onClose={onClose}
          onClickCancel={onClose}
          hasOkButton={this.state.newQuestionType !== undefined}
          disableOk={
            !(
              this.state.formValid &&
              (this.state.mcqValid ||
                this.state.newQuestionType === QuestionType.FIB ||
                this.state.newQuestionType === QuestionType.FreeText)
            )
          }
          onClickOk={this.onCreateQuestion}
        >
          {this.state.newQuestionType === undefined ? typeSelect : newQuestion}
        </Modal>
      </div>
    );
  }

  private onFormChange = (
    formValid: boolean,
    formValues: { question: string },
    formErrors: {},
  ) => {
    const { question, ...dest } = formValues;
    this.setState({
      formValid: formValid || !formErrors,
      formValues: {
        question: appendLineBreak(question),
        ...dest,
      },
    });
  };

  private onAddAnswer = () => {
    this.setState(
      {
        mcqChoices: [...this.state.mcqChoices, "ANSWER"],
      },
      this.checkAnswers,
    );
  };

  private onChangeAnswer = (answer: string, index: number) => {
    this.setState(
      {
        mcqChoices: [
          ...this.state.mcqChoices.slice(0, index),
          answer,
          ...this.state.mcqChoices.slice(index + 1),
        ],
      },
      this.checkAnswers,
    );
  };

  private onRemoveAnswer = (index: number) => {
    this.setState(
      {
        mcqChoices: this.state.mcqChoices.filter((_, i) => i !== index),
        mcqAnswers: this.state.mcqAnswers
          .filter((i) => i !== index)
          .map((i) => i - (index < i ? 1 : 0)),
      },
      this.checkAnswers,
    );
  };

  private checkAnswers = () => {
    if (this.state.newQuestionType === QuestionType.FIB) {
      return;
    }

    let formError;
    let mcqValid = true;

    if (this.state.mcqChoices.length < 2) {
      formError = {
        options: Message.getMessageByKey("validation.options.atLeast-2"),
      };
      mcqValid = false;
    } else if (this.state.mcqChoices.some((choice) => choice.length === 0)) {
      formError = {
        options: Message.getMessageByKey("validation.options.noBlank"),
      };
      mcqValid = false;
    } else if (this.state.mcqAnswers.length === 0) {
      formError = {
        options: Message.getMessageByKey("validation.options.selectAtLeast-1"),
      };
      mcqValid = false;
    } else if (
      this.state.mcqChoices.length !== new Set(this.state.mcqChoices).size
    ) {
      formError = {
        options: Message.getMessageByKey("validation.options.noDuplicate"),
      };
      mcqValid = false;
    }

    this.setState({
      mcqValid,
      formError,
    });
  };

  private onSelectAnswer = (index: number, checked: boolean) => {
    if (checked) {
      this.setState(
        {
          mcqAnswers: [...this.state.mcqAnswers, index],
        },
        this.checkAnswers,
      );
    } else {
      this.setState(
        {
          mcqAnswers: this.state.mcqAnswers.filter((i) => i !== index),
        },
        this.checkAnswers,
      );
    }
  };

  private onCreateQuestion = () => {
    const { quizCategories, newQuestionType, mcqChoices, mcqAnswers } =
      this.state;
    const { title, question, howToSolve } = this.state.formValues as {
      title: string;
      question: string;
      howToSolve: string;
    };

    const answers =
      newQuestionType === QuestionType.MCQ
        ? mcqChoices
            .map(
              (choice, index) =>
                `\n- [${mcqAnswers.includes(index) ? "x" : " "}] ${choice}`,
            )
            .join("")
        : "";

    const textbox =
      newQuestionType === QuestionType.FreeText ? "\n${FREETEXT}" : "";
    const explanation = howToSolve ? `\n### explanation\n${howToSolve}` : "";

    this.props.onCreateQuestion({
      source: `##${title}\n${question}${answers}${textbox}${explanation}`,
      quizCategories,
    });
  };

  private onCategoryChange = (quizCategories: Array<{ value: number }>) => {
    this.setState({
      quizCategories: quizCategories.map((category) => category.value),
    });
  };
}

export default QuestionNew;
