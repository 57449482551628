import * as classnames from "classnames";
import { isEqual } from "lodash";
import * as React from "react";

import { Button, ButtonType, Icon, Msg } from "..";

/**
 * Prop interface
 */
export interface WalkThroughProps {
  isOpen: boolean;
  title?: React.ReactNode;
  items: Array<WalkThroughWebcamItem>;
  currentIndex?: number;

  showCloseButton?: boolean;
  showSkipButton?: boolean;
  showDots?: boolean;

  hasPrevButton?: boolean;
  prevButtonLabel?: React.ReactNode;
  prevButtonType?: string;

  nextButtonLabel?: React.ReactNode;
  nextButtonType?: string;

  extraWalkThroughContents?: React.ReactNode;

  className?: string;

  onClose: () => void;
  onMovePage?: (currentIndex: number) => void;
}

/**
 * State interface
 */
export interface WalkThroughState {
  currentIndex: number;
}

export interface WalkThroughWebcamItem {
  component: React.ReactElement;
  title?: React.ReactNode;
  showCloseButton?: boolean;
  showSkipButton?: boolean;
  showDots?: boolean;
  hasPrevButton?: boolean;
  preventGoPrev?: boolean;
  prevButtonLabel?: React.ReactNode;
  prevButtonType?: ButtonType;
  preventGoNext?: boolean;
  nextButtonLabel?: React.ReactNode;
  nextButtonType?: ButtonType;
  nextButtonDisabled?: boolean;
  onClickPrev?: () => void;
  onClickNext?: () => void;
}

/**
 * React Component
 */
export default class WalkThroughWebcam extends React.Component<
  WalkThroughProps,
  WalkThroughState
> {
  constructor(props: WalkThroughProps) {
    super(props);

    this.state = {
      currentIndex: 0,
    };
  }

  public componentDidUpdate(prevProps: WalkThroughProps) {
    if (prevProps.currentIndex !== this.props.currentIndex) {
      this.setState({ currentIndex: this.props.currentIndex || 0 });
    }
    if (!this.props.isOpen) {
      this.setState({ currentIndex: 0 });
    }
  }

  public shouldComponentUpdate(
    nextProps: WalkThroughProps,
    nextState: WalkThroughState,
  ) {
    return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state);
  }

  public render() {
    const {
      className,
      isOpen = false,
      items,
      extraWalkThroughContents,
    } = this.props;

    const {
      title,
      hasPrevButton,
      prevButtonLabel,
      prevButtonType,
      nextButtonLabel,
      nextButtonType,
      nextButtonDisabled,
      showCloseButton,
      showSkipButton,
      showDots,
      preventGoPrev,
      preventGoNext,
    } = Object.assign(
      {},
      {
        title: "",
        hasPrevButton: true,
        prevButtonLabel: <Msg id={"action.back"} />,
        prevButtonType: "",
        nextButtonLabel: <Msg id={"action.next"} />,
        nextButtonType: "primary",
        nextButtonDisabled: false,
        showCloseButton: true,
        showSkipButton: true,
        showDots: false,
        preventGoPrev: false,
        preventGoNext: false,
      },
      this.props,
      items[this.state.currentIndex],
    );

    const rootStyle = classnames("code-c-walk-through-webcam", "modal", {
      // TTODO: Revert classname after removing isWebcamEnabled feature flag
      "is-open": isOpen,
      [`${className}`]: Boolean(className),
    });

    const dots =
      items.map((_, index) => {
        const dotStyle = classnames("code-c-walk-through__dot", {
          "is-current": index === this.state.currentIndex,
        });
        return <div key={index} className={dotStyle} />;
      }) || undefined;

    return (
      <div className={rootStyle}>
        <div className="modal-background" />
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">{title}</p>
            {showCloseButton && (
              <Button
                size={"small"}
                shrink={true}
                className="button is-white code-c-walk-through__close"
                onClick={this.onClose}
              >
                <Icon type={"times"} />
              </Button>
            )}
          </header>
          <section className="modal-card-body">
            <div className="code-c-walk-through__container">
              {items[this.state.currentIndex]
                ? React.cloneElement(items[this.state.currentIndex].component, {
                    className: classnames(
                      "code-c-walk-through__item",
                      "is-current",
                      items[this.state.currentIndex].component.props.className,
                    ),
                  })
                : null}
            </div>
          </section>
          <footer className="modal-card-foot">
            {showSkipButton && (
              <div className="code-c-walk-through__skip">
                <a onClick={this.onClose}>Skip</a>
              </div>
            )}
            {showDots && (
              <div className="code-c-walk-through__dots">{dots}</div>
            )}
            <div className="modal-card-foot__buttons">
              {hasPrevButton && (
                <Button
                  type={prevButtonType}
                  onClick={() => this.onClickPrev(preventGoPrev)}
                >
                  {prevButtonLabel}
                </Button>
              )}
              <Button
                type={nextButtonType}
                onClick={() => this.onClickNext(preventGoNext)}
                disabled={nextButtonDisabled}
              >
                {nextButtonLabel}
              </Button>
            </div>
          </footer>
          {extraWalkThroughContents}
        </div>
      </div>
    );
  }

  private onClickPrev = (preventGoPrev: boolean) => {
    const { currentIndex } = this.state;
    this.setState(
      {
        currentIndex: preventGoPrev
          ? currentIndex
          : currentIndex === 0
          ? 0
          : currentIndex - 1,
      },
      () => {
        const prevItem = this.props.items[currentIndex];
        if (prevItem && prevItem.onClickPrev) {
          if (typeof prevItem.onClickPrev === "function") {
            prevItem.onClickPrev();
          }
        }
        if (typeof this.props.onMovePage === "function") {
          this.props.onMovePage(this.state.currentIndex);
        }
      },
    );
  };

  private onClickNext = (preventGoNext: boolean) => {
    const { currentIndex } = this.state;
    this.setState(
      {
        currentIndex: preventGoNext
          ? currentIndex
          : currentIndex === this.props.items.length - 1
          ? currentIndex
          : currentIndex + 1,
      },
      () => {
        const prevItem = this.props.items[currentIndex];
        if (prevItem && prevItem.onClickNext) {
          if (typeof prevItem.onClickNext === "function") {
            prevItem.onClickNext();
          }
        }
        if (typeof this.props.onMovePage === "function") {
          this.props.onMovePage(this.state.currentIndex);
        }
      },
    );
  };

  private onClose = () => {
    if (typeof this.props.onClose === "function") {
      this.props.onClose();
    }
  };
}
