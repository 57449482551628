import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { ApiResponse } from "@api/httpClient";
import { SubmissionExtendPayload, putSubmissionExtend } from "@api/submissions";

import { useStoreContext } from "@context";

import { ApplicantExamsModel } from "@shared/models";
import Message from "@shared/services/message";

import { useInvalidateExamDashboardChart } from "../exams";
import { useInvalidateExamCountRelation } from "../exams/useExam";
import { useRouteParams } from "../useRouteParams";
import { useInvalidateSubmissionRelation } from "./useSubmission";

export interface UseUpdateSubmissionExtendParams {
  data: SubmissionExtendPayload;
}

export function useUpdateSubmissionExtend() {
  const { projectId } = useStoreContext();
  const { submissionId } = useRouteParams();
  const invalidateSubmission = useInvalidateSubmissionRelation();
  const invalidateExamCount = useInvalidateExamCountRelation();
  const invalidateExamDashboard = useInvalidateExamDashboardChart();

  const mutation = useMutation({
    mutationFn: ({ data }: UseUpdateSubmissionExtendParams) =>
      putSubmissionExtend(projectId, submissionId, { data }),
    onSuccess: (res: ApiResponse<ApplicantExamsModel>) => {
      toast.success(Message.getMessageByKey("message.challenge.extended"));
      invalidateSubmission(submissionId);
      invalidateExamCount(res.result.examId);
      invalidateExamDashboard(submissionId);
    },
  });

  return mutation;
}
