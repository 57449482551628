import * as classnames from "classnames";

import { Icon, Tooltip } from "@shared/components";

import {
  formatDateTimeMinutes,
  formatDateTimeSeconds,
} from "../../services/date";

/**
 * Props
 */
export interface DateTimeProps {
  dateTime: string;
  options?: {
    hasSecound?: boolean;
  };
  className?: string;
  warningMessage?: string;
}

/**
 *
 */
/**
 * React Component
 */
export default function DateTime({
  dateTime,
  options,
  className,
  warningMessage,
}: DateTimeProps) {
  const rootStyle = classnames("code-c-date-time", {
    [`${className}`]: Boolean(className),
  });

  const [date, time] = (
    options?.hasSecound
      ? formatDateTimeSeconds(dateTime)
      : formatDateTimeMinutes(dateTime)
  ).split(" ");

  /**
   * Render
   */
  return date && time ? (
    <time dateTime={dateTime} className={rootStyle}>
      <span className="code-c-date-time__date">{date}</span>
      <div className="code-c-date-time__time-wrapper">
        <span className="code-c-date-time__time">{time}</span>
        {warningMessage && (
          <div className="code-c-date-time__tooltip">
            <Tooltip text={warningMessage} maxSize="xlarge">
              <Icon type="exclamation-triangle" className="has-warning" />
            </Tooltip>
          </div>
        )}
      </div>
    </time>
  ) : null;
}
