import { Location as HistoryLocation, Action } from "history";
import { isEqual } from "lodash";
import QueryString from "query-string";
import { matchPath } from "react-router-dom";

import { SubmissionResultDetailModel, UserModel } from "../models";
import { ChallengeStyle } from "./enums";
import { parseApplicantURL } from "./urlParser";

// Pairs of pathname. ex) tabs in the same page
const IGNORE_URL_PAIRS = Object.freeze([
  [
    "/p/:projectId/exams/:examId/submissions/:submissionId/details",
    "/p/:projectId/exams/:examId/submissions/:submissionId/report",
    "/p/:projectId/exams/:examId/submissions/:submissionId/log",
  ],
]);

// The query parameter list that need to ignore from scroll position resetting
const IGNORE_QUERIES = Object.freeze(["selectedId"]);

const FEATURE_ENV_DOMAIN_NAMES = [
  "coach.tracks-shibuya.tokyo",
  "fenv.tracks-shibuya.tokyo",
];

/**
 * get URL for the challenge preview
 * @param challengeId
 * @param style
 * @param options
 */
export function getChallengePreviewPath(
  challengeId: number,
  style: ChallengeStyle,
  options?: {
    domain?: string;
    basePath?: string;
    programmingLanguage?: number;
    version?: number;
    challengeVersionId?: number;
  },
) {
  const {
    programmingLanguage,
    version,
    challengeVersionId,
    domain = "",
    basePath = "",
  } = options || {};
  const path = `${domain}${basePath}/challenges/${challengeId}/preview`;

  const query = QueryString.stringify({
    ...{
      m: version,
      v: challengeVersionId,
    },
    ...(ChallengeStyle.isAlgorithmTypeChallenge(style) && {
      pl: programmingLanguage,
    }),
  });

  return query !== "" ? `${path}?${query}` : path;
}

/**
 * Return URL for the challenge permalink
 * @param challengeId
 */
export function getChallengePermalinkURL(
  challengeId: number,
  options?: {
    basePath?: string;
  },
): string {
  return `${window.location.origin}${
    options?.basePath ?? ""
  }/challenges/${challengeId}`;
}

/**
 * Return URL for the exam delivery
 * @param orgName
 * @param urlToken
 * @param isStandardDelivery
 */
export function getExamDeliveryURL(
  orgName: string,
  urlToken: string,
  isStandardDelivery: boolean,
): string {
  const domain =
    process.env.REACT_APP_APPLICANT_BASE_DOMAIN || window.location.origin;
  return `${domain}/${orgName}/${
    isStandardDelivery ? "share" : "idshare"
  }/${urlToken}`;
}

export function getOrgAppBaseUrl(locationHost: string, orgname: string) {
  switch (locationHost) {
    case "localhost":
    case "admin.tracks-dev.tokyo":
      return `https://${orgname}.tracks-dev.tokyo/auth/sa/signin`;
    case "admin.tracks-stage.tokyo":
      return `https://${orgname}.tracks-stage.tokyo/auth/sa/signin`;
    case "admin.tracks.run":
      return `https://${orgname}.tracks.run/auth/sa/signin`;
    default:
      // fenv scenario
      // sample - admin.new-fenv.fenv.tracks-shibuya.tokyo
      if (locationHost.startsWith("admin") && locationHost.endsWith("tokyo")) {
        const urlParts = locationHost.split(".");
        urlParts.shift();
        return `https://${orgname}.${urlParts.join(".")}/auth/sa/signin`;
      }

      return undefined;
  }
}

export function getCodeplaybackUrl({
  projectId,
  examId,
  applicantExamId,
  challengeId,
}: {
  projectId: number;
  examId: number;
  applicantExamId: number;
  challengeId: number;
}) {
  return `${window.location.origin}/p/${projectId}/exams/${examId}/submissions/${applicantExamId}/playback/${challengeId}`;
}

export function getCodeDiffUrl({
  projectId,
  examId,
  applicantExamId,
  challengeId,
}: {
  projectId: number;
  examId: number;
  applicantExamId: number;
  challengeId: number;
}) {
  return `${window.location.origin}/p/${projectId}/exams/${examId}/submissions/${applicantExamId}/diff/${challengeId}`;
}

export function getChallengeResultPreviewUrl(
  submissionResultDetail: SubmissionResultDetailModel,
  windowLocation?: Location,
) {
  if (windowLocation && isLocal(windowLocation?.hostname)) {
    const editorUrl = new URL(submissionResultDetail.codingResult.editorLink);
    const localEditorUrl = new URL(editorUrl.pathname, windowLocation.origin);
    localEditorUrl.search = editorUrl.search;

    return localEditorUrl.toString();
  }

  return submissionResultDetail.codingResult.editorLink;
}

export function isValidApplicantURL(pathname: string) {
  if (!pathname) {
    return false;
  }
  // tutorial
  if (pathname === "/tutorial") {
    return true;
  }
  if (pathname.match(/^\/tutorial\?lang=/)) {
    return true;
  }
  return parseApplicantURL(pathname) !== null;
}

export function isQuizURL(pathname = ""): boolean {
  if (!isValidApplicantURL(pathname)) {
    return false;
  }
  const matches = matchPath<{ currentPage: string }>(pathname, {
    path: "/:orgname/exams/:token/challenges/:challengeId/:currentPage",
    exact: true,
  });
  return matches !== null;
}

/**
 * Pluck challenge URL for the applicant URL
 * In particular, it will cut out the question id in the Quiz challenge.
 *
 * ex)
 *   /mitz/exams/12345/challenges/12 -> /mitz/exams/12345/challenges/12
 *   /mitz/exams/12345/challenges/12/1 -> /mitz/exams/12345/challenges/12
 * @param pathname
 */
export function pluckApplicantChallengeURL(pathname = "") {
  if (!isValidApplicantURL(pathname)) {
    return pathname;
  }
  const matches = matchPath<{ currentPage: string }>(pathname, {
    path: "/:orgname/exams/:token/challenges/:challengeId/:currentPage",
    exact: true,
  });
  if (matches === null) {
    return pathname;
  }
  const { currentPage } = matches.params;
  // cut off the currentPage from pathname
  return pathname.slice(0, -`/${currentPage}`.length);
}

/**
 * Check if the current project id in the Redux Store is different from the id of the URL
 *
 * HACK!!
 * To prevent the children from being rendered until the current project ID is updated when the browser's back button is pressed.
 * @param pathname
 * @param currentUser
 * @param currentProjectId
 */
export function isOrgsContainerReady(
  pathname: string,
  currentUser?: UserModel,
  currentProjectId?: number,
) {
  const matches = matchPath<{ projectId: string }>(pathname, {
    path: "/p/:projectId",
  });
  // matches === null means the current location is not on the project page.
  return (
    matches === null ||
    Number(matches.params.projectId) === currentProjectId ||
    currentUser === undefined ||
    currentUser.projects.find(
      (item) => item.id === Number(matches.params.projectId),
    ) === undefined ||
    currentProjectId === 0 // 0 is initial value
  );
}

/**
 * Check if the current page's scroll position should be reset to the top
 *
 * @param location
 * @param prevLocation
 * @param action
 */
export function isValidResetScrollPosition(
  location: HistoryLocation<unknown>,
  prevLocation: HistoryLocation<unknown>,
  action: Action,
) {
  const { pathname, search } = location;
  const { pathname: prevPathname, search: prevSearch } = prevLocation;
  const { page, ...query } = QueryString.parse(search);
  const { page: prevPage, ...prevQuery } = QueryString.parse(prevSearch);

  const scrubQuery = (keyValues: {}) => {
    return Object.keys(keyValues).reduce((result, key) => {
      return {
        ...result,
        ...(IGNORE_QUERIES.includes(key) ? {} : { [`${key}`]: keyValues[key] }),
      };
    }, Object.create(null));
  };

  const matchPair = (
    pairs: readonly string[][],
    pathname: string,
    prevPathname: string,
  ) =>
    !pairs.find((pair) => {
      const matches = (url: string) =>
        pair.find((path) =>
          matchPath<{ tabName: string }>(url, {
            path,
            exact: true,
          }),
        );

      return matches(pathname) && matches(prevPathname);
    });

  const isValidActions = action !== "POP";
  const isNotSamePageNumber = page !== prevPage;
  const isNotSamePathname = !matchPath(pathname, {
    path: prevPathname,
    exact: true,
  });
  const isNotSameQuery = !isEqual(scrubQuery(query), scrubQuery(prevQuery));
  const isValidPairs = matchPair(IGNORE_URL_PAIRS, pathname, prevPathname);

  const result =
    isValidActions &&
    isValidPairs &&
    (isNotSamePathname || isNotSamePageNumber || isNotSameQuery);

  return result;
}

/**
 * Get Sign-in URL
 * @param orgname
 * @param options
 * @returns
 */
export function getSignInURL(
  orgname: string,
  options?: {
    isSystemAdmin?: boolean;
    force?: boolean;
    prefillEmail?: string;
    onsuccess?: string;
  },
) {
  const { isSystemAdmin, force, prefillEmail, onsuccess } = options ?? {};

  const queryString = QueryString.stringify({
    orgname,
    onerror: isSystemAdmin ? "/auth/sa/error" : "/auth/error",
    ...(process.env.REACT_APP_SIGNIN_REDIRECT_ORIGIN_URL
      ? { origin: process.env.REACT_APP_SIGNIN_REDIRECT_ORIGIN_URL }
      : { origin: `${window.location.origin}` }),
    ...(onsuccess && { onsuccess: encodeURIComponent(onsuccess) }),
    ...(force && { re: true }),
    ...(Boolean(prefillEmail) && {
      prefillEmail: encodeURIComponent(prefillEmail ?? ""),
    }),
  });

  return `${
    isSystemAdmin ? "/account/sa/login/authorize" : "/account/login/authorize"
  }?${queryString}`;
}

/**
 *
 * @returns Get Sign-out URL
 */
export function getSignOutURL() {
  const queryString = QueryString.stringify({
    ...(process.env.REACT_APP_SIGNIN_REDIRECT_ORIGIN_URL
      ? { origin: process.env.REACT_APP_SIGNIN_REDIRECT_ORIGIN_URL }
      : { origin: `${window.location.origin}` }),
  });

  return `/account/logout${queryString ? `?${queryString}` : ""}`;
}

/**
 * check if the application runs on the feature env
 * @param hostname
 * @returns
 */
export function isFeatureEnv(hostname = ""): boolean {
  return FEATURE_ENV_DOMAIN_NAMES.some((name) => hostname.includes(name));
}

/**
 * check if the application runs on the local env
 * @param hostname
 * @returns
 */
export function isLocal(hostname = ""): boolean {
  return hostname.startsWith("localhost");
}

/**
 * get default sign-in page pathname
 * @param hostname
 * @param isSystemAdmin
 */
export function getDefaultSignInPathname(
  hostname = "",
  isSystemAdmin = false,
): string {
  const featureEnv = isFeatureEnv(hostname);
  if (isSystemAdmin) {
    return featureEnv ? "/local/signin" : "/signin";
  }
  return featureEnv ? "/auth/local/signin" : "/auth/signin";
}

export function redirectToLogin() {
  const { hostname } = window.location;
  const isSystemAdmin =
    hostname.startsWith("admin.") || process.env.app === "admin";
  const signInURL = getDefaultSignInPathname(hostname, isSystemAdmin);
  window.location.href = signInURL;
}
