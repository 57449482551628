import {
  ReviewModel,
  ReviewerUserModel,
  UserModel,
  TimeExtension,
} from "@shared/models";
import {
  ScoreStatus,
  ApplicantExamStatus,
  ReviewScore,
  SubmissionListKind,
  UnstableReason,
  ChallengeResultStatus,
  ChallengeStyle,
} from "@shared/services/enums";
import Message from "@shared/services/message";

import { isReviewableStatus } from "./exam";

/**
 * if score is invalid, return specific error message.
 */
export function getErrorMessage(score: number, totalTestcases: number) {
  const isNoTest = totalTestcases === 0;
  const invalidScore = score > 100;

  if (isNoTest) {
    return Message.getMessageByKey(
      "submission-detail-challenge-score.invalidScore.noTest",
    );
  } else if (invalidScore) {
    return Message.getMessageByKey(
      "submission-detail-challenge-score.invalidScore.rescoring",
    );
  } else {
    return undefined;
  }
}

export function isScoreValid(score: number, totalTestcases: number) {
  const isNoTest = totalTestcases === 0;
  const invalidScore = score > 100;

  return !isNoTest && !invalidScore;
}

/**
 * Check if the score is included in the final score.
 * For the optional challenge set
 */
export function isIncludedInFinalScore(
  targetIndex: number,
  scores: number[] = [],
  pickCount = 0,
) {
  const includedScores = scores
    .map((score, index) => ({ index, score }))
    .sort((a, b) => b.score - a.score)
    .slice(0, pickCount);
  return includedScores.some((score) => score.index === targetIndex);
}

/**
 * get final score string for submission list
 * NOTE: it will formatted as `${score}%`
 * @param status
 * @param score
 */
export function extractScoreStringFromSubmissionList(
  status: ApplicantExamStatus,
  score?: number,
) {
  switch (status) {
    case ApplicantExamStatus.Submitted:
    case ApplicantExamStatus.InReview:
    case ApplicantExamStatus.Passed:
    case ApplicantExamStatus.Failed: {
      return score === undefined
        ? Message.getMessageByKey("score.calculating")
        : `${score}%`;
    }
    default: {
      return score === undefined ? "" : `${score}%`;
    }
  }
}

/**
 * get ScoreStatus from Submission List Model
 * @param status
 * @param score
 * @param hasScoringDifference
 */
export function extractScoreStatusFromSubmissionList(
  status: ApplicantExamStatus,
  score?: number,
  hasScoringDifference?: boolean,
): ScoreStatus | undefined {
  if (hasScoringDifference) {
    return ScoreStatus.HasDifference;
  }
  // In the case when the scoring does not work properly or just calculating score.
  if (
    [
      ApplicantExamStatus.Submitted,
      ApplicantExamStatus.InReview,
      ApplicantExamStatus.Passed,
      ApplicantExamStatus.Failed,
    ].includes(status) &&
    score === undefined
  ) {
    return ScoreStatus.Scoring;
  }
  return undefined;
}

/**
 * Return rounded raw score
 * @param rawScore
 */
export function getRoundedRawScore(rawScore?: number): number {
  return Math.round((rawScore || 0) * 100000000) / 100000000;
}

/**
 * Classify review counts
 *
 * Category:
 *   stronglyApprove
 *   approve
 *   staleApprove
 *   reject
 *   stronglyReject
 *   staleReject
 *
 * @param reviews
 */
export function classifyReviews(reviews: ReviewModel[]): {
  stronglyApprove: number;
  approve: number;
  staleApprove: number;
  reject: number;
  stronglyReject: number;
  staleReject: number;
} {
  return [...reviews].reduce(
    (summary, review) => {
      const {
        stronglyApprove,
        approve,
        reject,
        stronglyReject,
        staleApprove,
        staleReject,
      } = summary;

      if (review.isStale) {
        return {
          ...summary,
          staleApprove: [
            ReviewScore.Approve,
            ReviewScore.StronglyApprove,
          ].includes(review.score)
            ? staleApprove + 1
            : staleApprove,
          staleReject: [
            ReviewScore.Reject,
            ReviewScore.StronglyReject,
          ].includes(review.score)
            ? staleReject + 1
            : staleReject,
        };
      }

      return {
        ...summary,
        stronglyApprove:
          ReviewScore.StronglyApprove === review.score
            ? stronglyApprove + 1
            : stronglyApprove,
        approve: ReviewScore.Approve === review.score ? approve + 1 : approve,
        reject: ReviewScore.Reject === review.score ? reject + 1 : reject,
        stronglyReject:
          ReviewScore.StronglyReject === review.score
            ? stronglyReject + 1
            : stronglyReject,
      };
    },
    {
      stronglyApprove: 0,
      approve: 0,
      staleApprove: 0,
      reject: 0,
      stronglyReject: 0,
      staleReject: 0,
    },
  );
}

/**
 * Sort reviews by score
 * @deprecated TODO: move sort logic to useSubmissionReviewers.ts or server
 *
 * Order:
 *   reviewed reviews
 *   stale reviews
 *   not-reviewed reviews
 *
 * @param reviews
 */
export function sortReviews(reviews: ReviewModel[]): ReviewModel[] {
  const sort = (a: ReviewModel, b: ReviewModel) =>
    // set default number as -1 is for preventing undefined - 1 = NaN
    (b.score === undefined ? -1 : b.score) -
    (a.score === undefined ? -1 : a.score);

  const reviewed = [...reviews]
    .filter((item) => item.score !== undefined && !item.isStale)
    .sort(sort);

  const staled = [...reviews].filter((item) => item.isStale).sort(sort);

  const notReviewed = [...reviews].filter((item) => item.score === undefined);

  return [...reviewed, ...staled, ...notReviewed];
}

/**
 * Check if the needs review tag needs to be shown
 * @param submissionListKind
 * @param pendingReviewers
 * @param reviewer
 * @param reviews
 */
export function showNeedsReviewTag({
  submissionListKind,
  pendingReviewers = [],
  reviewer,
  reviews,
  submissionStatus,
  hasReviewPermission,
}: {
  submissionListKind: SubmissionListKind;
  pendingReviewers: ReviewerUserModel[];
  reviewer?: Pick<ReviewerUserModel | UserModel, "id">;
  reviews?: ReviewModel[];
  submissionStatus: ApplicantExamStatus;
  hasReviewPermission?: boolean;
}): boolean {
  if (
    !hasReviewPermission ||
    ![
      SubmissionListKind.all,
      SubmissionListKind.submitted,
      SubmissionListKind.inreview,
    ].includes(submissionListKind) ||
    reviewer === undefined ||
    !isReviewableStatus(submissionStatus)
  ) {
    return false;
  }

  if (
    reviews?.some((review) => review.userId === reviewer.id && review.isStale)
  ) {
    return true;
  }

  return (
    pendingReviewers.find(
      (pendingReviewer) => pendingReviewer.id === reviewer.id,
    ) !== undefined
  );
}

/**
 * Check if the review comment has already been submitted
 * @param score
 */
export function hasReviewScored(score?: number) {
  return score !== undefined;
}

/**
 * Get time extensions for the challenge
 * @param extensions
 * @param challengeId
 */
export function getTimeExtensions(
  extensions: TimeExtension[],
  challengeId: number,
): TimeExtension[] {
  return extensions?.filter(
    (extension) =>
      extension.extendedChallenges?.find((id) => id === challengeId),
  );
}

/**
 * Check if the time extensions for the challenge exists
 * @param extensions
 * @param challengeId
 */
export function hasTimeExtension(
  extensions: TimeExtension[],
  challengeId: number,
): boolean {
  return (
    extensions?.find(
      (extension) =>
        extension.extendedChallenges?.find((id) => id === challengeId),
    ) !== undefined
  );
}

/**
 * Return the message based on the status of the challenge result
 */
export function getScoreStatusMessage({
  unstableReason,
  scoreStatus,
  challengeResultStatus,
  challengeStyle,
}: {
  unstableReason?: UnstableReason;
  scoreStatus?: ScoreStatus;
  challengeResultStatus?: ChallengeResultStatus;
  challengeStyle?: ChallengeStyle;
}): string {
  let tooltipMessage = "";

  if (unstableReason) {
    return UnstableReason.toErrorString(unstableReason);
  }

  if (
    challengeResultStatus === ChallengeResultStatus.Started &&
    challengeStyle === ChallengeStyle.Quiz
  ) {
    return Message.getMessageByKey("score.calculating.inProgressNote");
  }

  if (scoreStatus) {
    if (scoreStatus === ScoreStatus.HasDifference) {
      tooltipMessage = Message.getMessageByKey("showHighestScore");
    } else if (scoreStatus === ScoreStatus.Scoring) {
      tooltipMessage = Message.getMessageByKey(
        challengeResultStatus === ChallengeResultStatus.InProgress
          ? "score.calculating.inProgressNote"
          : "score.calculating.note",
      );
    } else {
      tooltipMessage = ScoreStatus.toString(scoreStatus);
    }
  }

  return tooltipMessage;
}
