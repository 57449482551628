import c3, { DataPoint } from "c3";
import classnames from "classnames";
import * as d3 from "d3";
import { isEmpty } from "lodash";
import React from "react";
import shortid from "shortid";

import { ChartColor } from "@shared/services/enums";

import { ChartMarker, ChartMarkerProps } from "../chartMarker/ChartMarker";

type ScatterDataPoint = {
  label?: string;
  x: number;
  y: number;
};

export type ScatterChartOptions = {
  colorMaps?: string[];
  xRange?: [number, number];
  yRange?: [number, number];
  xLabel?: string;
  yLabel?: string;

  xTickValues?: number[];
  yTickValues?: number[];

  xValueFormatter?: (value: number, index: number) => string;
  yValueFormatter?: (value: number, index: number) => string;
};

const defaultArr = [] as string[];

export function ScatterChart({
  data,
  options: {
    colorMaps = defaultArr,
    xRange,
    yRange,
    xLabel,
    yLabel,
    xValueFormatter,
    yValueFormatter,
    xTickValues,
    yTickValues,
  } = {},
  className,
  chartMarkerProps,
}: {
  data: ScatterDataPoint[];
  options?: ScatterChartOptions;
  className?: string;
  chartMarkerProps?: Omit<ChartMarkerProps, "id" | "chart">;
}) {
  const idRef = React.useRef<string>(`scatterChart${shortid.generate()}`);
  const [chart, setChart] = React.useState<c3.ChartAPI>();

  React.useEffect(() => {
    // prevent points to be clipped off on the edges of the graph
    const sidePadding = xRange ? (xRange[1] - xRange[0]) / 30 : 0;

    const maxYTick = yTickValues?.reduce(
      (maxValue, item) => Math.max(maxValue, item),
      0,
    );

    // adjust left padding based on max number on y ticks
    // the chart doesnt auto adjust which causes the label to clip on the left edge
    const decimalPlacing = yTickValues?.some((val) => val % 1 === 0) ? 0 : 2;
    const leftPaddingAdjustment = maxYTick
      ? (maxYTick.toString().length + decimalPlacing) * 5.5
      : 0;

    const config: c3.ChartConfiguration = {
      padding: {
        top: 0,
        left: 35 + leftPaddingAdjustment,
      },
      bindto: `#${idRef.current}`,
      data: {
        classes: {
          y: "additional-y-class",
        },
        color: (color, d: DataPoint) => {
          if (isEmpty(colorMaps)) {
            return color;
          }
          return colorMaps[d.index] ?? "#9BCA5429";
        },
        type: "scatter",
        xs: {
          y: "x",
        },
        columns: [
          ["x", ...data.map((d) => d.x)],
          ["y", ...data.map((d) => d.y)],
        ],
        xSort: false, // data shouldn't be sorted so the tooltip contents function can give the original index
      },
      color: {
        pattern: [ChartColor.Primary],
      },
      axis: {
        x: {
          label: {
            text: xLabel || "",
            position: "outer-center",
          },
          min: xRange ? xRange[0] : undefined,
          max: xRange ? xRange[1] : undefined,
          tick: {
            values: xTickValues,
          },
          height: 50,
          padding: {
            right: sidePadding,
            left: sidePadding,
          },
        },
        y: {
          label: {
            text: yLabel || "",
            position: "outer-middle",
          },
          min: yRange ? yRange[0] : undefined,
          max: yRange ? yRange[1] : undefined,
          tick: {
            values: yTickValues,
          },
          padding: {
            bottom: 16,
          },
        },
      },
      legend: {
        show: false,
      },
      grid: {
        y: {
          show: true,
        },
      },
      point: {
        r: 4,
      },
      tooltip: {
        grouped: false,
        // c3 doesn't support formatting the x value in the tooltip
        // so we have to use the contents function to override the whole tooltip
        contents(d) {
          const label = data[d[0].index].label;
          const x = xValueFormatter
            ? xValueFormatter(d[0].x, d[0].index)
            : d[0].x;
          const y = yValueFormatter
            ? yValueFormatter(d[0].value, d[0].index)
            : d[0].value;

          return `<div class="code-c-scatter-chart__tooltip">
            ${
              label
                ? `<div class="code-c-scatter-chart__tooltip-label">${label}</div>`
                : ""
            }
            <div class="code-c-scatter-chart__tooltip-value-list">
              <div class="code-c-scatter-chart__tooltip-value">
                ${y}
              </div>
              <div class="code-c-scatter-chart__tooltip-value">
                ${x}
              </div>
            </div>
          </div>`;
        },
      },
      size: {
        height: 300,
      },
      onmouseover() {
        // we hide the tooltip when the mouse exits the tooltip and enters back into the chart
        this.tooltip.style("display", "none");
      },
    };

    const chart = c3.generate(config);
    // override the hideTooltip function so tooltip does not get hidden when mouse leaves the data point
    chart.internal.hideTooltip = () => {};

    setChart(chart);

    // set overflow style to be visible so the last tick label does not get clipped off
    d3.select(`#${idRef.current} svg`).style("overflow", "visible");
  }, [
    colorMaps,
    data,
    xLabel,
    xRange,
    xTickValues,
    xValueFormatter,
    yLabel,
    yRange,
    yTickValues,
    yValueFormatter,
  ]);

  const rootStyle = classnames("code-c-scatter-chart", {
    [`${className}`]: Boolean(className),
  });

  const hideTooltip = () => {
    if (chart) {
      chart.internal.tooltip.style("display", "none");
    }
  };

  return (
    <div
      id={idRef.current}
      className={rootStyle}
      onMouseLeave={hideTooltip}
      onClick={hideTooltip}
    >
      {chart && chartMarkerProps && (
        <ChartMarker {...chartMarkerProps} id={idRef.current} chart={chart} />
      )}
    </div>
  );
}
