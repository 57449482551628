import { FileUploadConfig } from "@shared/services/fileUpload";

import {
  ChallengeStyle,
  ChallengeResultStatus,
  ScoreStatus,
} from "../services/enums";

interface ChallengeTag {
  id: number;
  kind: number;
  name: string;
}

class SubmissionResultListModel {
  public id: number; // challenge result id
  public challengeId: number;
  public challengeVersionId: number;
  public challengeVersionNumber: string;
  public title: string;
  public difficulty: number;
  public style: ChallengeStyle;
  public timeSpentSeconds: number;
  public randomizeQuiz: boolean;

  public challengeTags: ChallengeTag[] = [];
  public score: number;
  public scoreStatus: ScoreStatus;
  public totalTestcases: number;
  public updatedAt: string;
  public fileUploadConfig?: FileUploadConfig;

  // if optional values are missing, then it implies the challenge
  // result doesn't exist. Otherwise it should be there
  public challengeResultStatus?: ChallengeResultStatus;
  public minorVersionNumber?: number;
  public majorVersionNumber?: number;

  constructor(args?: {}) {
    Object.assign(this, args);

    this.score = Math.floor(this.score);
  }
}

export default SubmissionResultListModel;
