import * as classnames from "classnames";
import * as React from "react";

/**
 * Prop interface
 */
export interface SubMenuLabelProps {
  className?: string;
  children?: React.ReactNode;
}

/**
 * React Component
 */
export default function SubMenuLabel({
  className,
  children,
}: SubMenuLabelProps) {
  const rootStyle = classnames("code-c-sub-menu-label", "menu-label", {
    [`${className}`]: Boolean(className),
  });

  return <p className={rootStyle}>{children}</p>;
}
