import * as classnames from "classnames";

import { Icon, Msg } from "..";
import * as ScoreService from "../../../shared/services/score";
import { ReviewModel } from "../../models";

/**
 * Prop interface
 */
export interface ReviewSummaryProps {
  reviews: ReviewModel[];
  pendingCount: number;
  style?: "row" | "column";
  className?: string;
  displayNotReviewed?: boolean;
  hideExistingReviews?: boolean;
}

/**
 * React Component
 */
export default function ReviewSummary({
  className,
  reviews,
  pendingCount,
  style = "row",
  displayNotReviewed,
  hideExistingReviews,
}: ReviewSummaryProps) {
  /**
   * Private Functions
   */
  const rootStyle = classnames("code-c-review-summary", {
    "is-column": style === "column",
    [`${className}`]: Boolean(className),
  });

  /**
   * Component
   */
  const ReviewSummaryItem = (props: {
    count?: number;
    messageKey: string;
    status:
      | "not-reviewed"
      | "stale"
      | "strongly-approved"
      | "approved"
      | "rejected"
      | "strongly-rejected";
  }) => {
    const { count, messageKey, status } = props;

    return count === 0 ? null : (
      <div
        className={classnames("code-c-review-summary__item", `is-${status}`)}
        role="listitem"
        aria-label={status}
      >
        <Icon
          type={
            status === "not-reviewed"
              ? "eye-slash"
              : status === "stale"
              ? "exclamation-triangle"
              : "square"
          }
        />
        <span>{count}</span>
        <span>
          <Msg id={messageKey} />
        </span>
      </div>
    );
  };

  const counts = ScoreService.classifyReviews(reviews);
  const staleCount = counts.staleApprove + counts.staleReject;

  /**
   * Render
   */
  return (
    <div className={rootStyle} role="list" aria-label="Review Summary">
      {!hideExistingReviews && (
        <>
          <ReviewSummaryItem
            status="strongly-approved"
            count={counts.stronglyApprove}
            messageKey="submission.reviewStatus.stronglyApproved"
          />
          <ReviewSummaryItem
            status="approved"
            count={counts.approve}
            messageKey="submission.reviewStatus.approved"
          />
          <ReviewSummaryItem
            status="rejected"
            count={counts.reject}
            messageKey="submission.reviewStatus.rejected"
          />
          <ReviewSummaryItem
            status="strongly-rejected"
            count={counts.stronglyReject}
            messageKey="submission.reviewStatus.stronglyRejected"
          />
        </>
      )}
      <ReviewSummaryItem
        status="stale"
        count={staleCount}
        messageKey={
          staleCount > 1
            ? "submission.reviewStatus.outdatedReviews"
            : "submission.reviewStatus.outdatedReview"
        }
      />
      {(displayNotReviewed ||
        reviews.length === 0 ||
        reviews.length === staleCount) && (
        <ReviewSummaryItem
          status="not-reviewed"
          count={pendingCount}
          messageKey="submission.reviewStatus.notReviewed"
        />
      )}
    </div>
  );
}
