import * as c3 from "c3";
import "c3/c3.css";
import * as classnames from "classnames";
import { isEqual } from "lodash";
import * as React from "react";
import * as shortid from "shortid";

import { ChartColor } from "../../services/enums";
import { getLogarithmicScale } from "../../services/math";

export interface BarChartItem {
  label: string;
  number: number;
}

/**
 * Prop interface
 */
export interface BarChartProps {
  color?: string;
  xLabel?: string;
  yLabel?: string;
  className?: string;
  size?: number;
  items: BarChartItem[];
  logarithmicScale?: boolean;
}

/**
 * React Component
 */
export default class BarChart extends React.Component<BarChartProps> {
  private chart: c3.ChartAPI;
  private id: string;

  constructor(props: BarChartProps) {
    super(props);
    this.id = `chart${shortid.generate()}`;
  }

  public componentDidMount() {
    this.updateChart();
  }

  public componentDidUpdate(prevProps: BarChartProps) {
    const { items, logarithmicScale, size } = this.props;
    if (
      logarithmicScale !== prevProps.logarithmicScale ||
      size !== prevProps.size ||
      !isEqual(items, prevProps.items)
    ) {
      this.updateChart();
    }
  }

  updateChart() {
    const {
      xLabel = "",
      yLabel = "",
      color = ChartColor.Green,
      items,
      size,
      logarithmicScale,
    } = this.props;

    const xItems = items.map((item) => item.label);
    const yItems = items.map((item) =>
      logarithmicScale ? getLogarithmicScale(item.number) : item.number,
    );
    // Calculate Y axis labels from total item count
    // Example)
    // total item count: 235
    // 235 => 123 => [1, 2, 3] => [10, 100, 1000]
    const logarithmicYScale = Array.from(
      items.reduce((total, item) => total + item.number, 0).toString(),
    ).map((_, index) => index + 1);
    const logarithmicScaleConfig = {
      axis: {
        y: {
          max: logarithmicYScale.length,
          tick: {
            values: logarithmicYScale,
            format: function (d: number) {
              return d === 0 ? "0" : Math.pow(10, d).toFixed(0);
            },
          },
        },
      },
    };

    const basicConfig: c3.ChartConfiguration = {
      bindto: `#${this.id}`,
      grid: {
        y: {
          show: true,
        },
      },
      data: {
        x: "x",
        type: "bar",
        columns: [
          ["x", ...xItems],
          ["number", ...yItems],
        ],
        names: {
          number: yLabel,
        },
        colors: {
          number: color,
        },
      },
      legend: {
        show: false,
      },
      axis: {
        x: {
          type: "category",
          height: 80,
          label: {
            text: xLabel,
            position: "outer-center",
          },
          tick: {
            fit: false,
            culling: false,
            rotate: 40,
            multiline: false,
          },
        },
        y: {
          min: 0,
          max: Math.max(...yItems) < 10 ? 10 : Math.max(...yItems),
          label: {
            text: yLabel,
            position: "outer-middle",
          },
          padding: {
            top: 16,
            bottom: 0,
          },
        },
      },
      bar: {
        width: {
          ratio: 0.45,
        },
      },
    };

    const config: c3.ChartConfiguration = {
      ...basicConfig,
    };
    if (logarithmicScale && config.axis && config.axis.y) {
      config.axis.y.max = logarithmicScaleConfig.axis.y.max;
      config.axis.y.tick = logarithmicScaleConfig.axis.y.tick;
    }

    this.chart = c3.generate(config);

    if (size) {
      this.chart.resize({
        width: size,
      });
    }
  }

  public render() {
    const { className } = this.props;

    const rootStyle = classnames("code-c-bar-chart", {
      [`${className}`]: Boolean(className),
    });

    return <div className={rootStyle} id={this.id} />;
  }
}
