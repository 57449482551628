import { connect } from "react-redux";
import { Action, Dispatch } from "redux";

import { RootState } from "../../../reducers";
import ProjectSwitch from "./ProjectSwitch";

export { ProjectSwitch };

const mapStateToProps = (state: RootState) => ({
  projects: state.project.projectSwitcher,
  currentProjectId: state.project.currentProjectId,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({});

/**
 * Wrapped component
 */
const wrappedComponent = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProjectSwitch);

export default wrappedComponent;
