import * as classnames from "classnames";
import * as React from "react";

/**
 * Prop interface
 */
export interface ChallengeDetailBoxProps {
  title: React.ReactNode;
  children: React.ReactNode;
}

/**
 * React Component
 */
export function ChallengeDetailBox({
  title,
  children,
}: ChallengeDetailBoxProps) {
  const rootStyle = classnames("code-challenge-detail-box");
  return (
    <section className={rootStyle}>
      <div className="code-challenge-detail-box__title">{title}</div>
      <div className="code-challenge-detail-box__body">{children}</div>
    </section>
  );
}
