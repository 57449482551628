import * as classnames from "classnames";
import * as React from "react";
import SwiperCore from "swiper";
import "swiper/css";
import "swiper/css/mousewheel";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination, Mousewheel } from "swiper/modules";

import {
  CollapsibleChallengeColumn,
  Column,
  HeaderColumn,
  Loading,
  Modal,
  Msg,
  Table,
  TableHead,
  Overlay,
  OfficialExamCard,
} from "@shared/components";
import {
  ChallengeCollectionModel,
  ChallengeModel,
  PinnedChallengeModel,
  ExamCreateOptionsModel,
} from "@shared/models";
import { getFormedChallengeCollection } from "@shared/services/challengeCollection";
import { TierAction } from "@shared/services/enums";
import { isCollectionChallengeAllowed } from "@shared/services/tier";

import OfficialExamPanel from "../../../exams/officialExamList/partials/officialExamPanel/OfficialExamPanel.connect";
import ChallengeDetail from "../../challengeDetail/ChallengeDetail.connect";
import { ChallengeSectionService } from "../../challengeSections";

/**
 * Prop interface
 */
export type ExternalProps = {
  className?: string;
  isOpen: boolean;
  showOfficialExam?: boolean;
  strictLinkedChallenge?: boolean;
  challengeCollectionId: number;
  pinnedChallengeList: PinnedChallengeModel[];
  /**
   * Not editable selected challenge list
   */
  selectedChallengeIds?: number[];
  /**
   * Editable selected challenge list
   */
  temporarySelectedChallenges?: ChallengeModel[];
  canEditPin?: boolean;
  canSelect?: boolean;
  defaultConditions?: {};
  onTogglePin?: (challengeId: number, pinId?: number) => void;
  onSelect?: (data: {
    selectedChallenges: ChallengeModel[];
    removedChallenges: ChallengeModel[];
  }) => void;
  onClose: () => void;
};

export type InjectedProps = {
  challengeCollectionDetails: ChallengeCollectionModel[];
  challengeCollectionLoading: boolean;
  examOptions: ExamCreateOptionsModel;
  language: string;
  isTierActionAllowed: (tier: TierAction) => boolean;

  getChallengeCollection: (id: number, conditions?: {}) => void;
};

export type ChallengeCollectionDetailModalProps = ExternalProps & InjectedProps;

/**
 * React Component
 */
export const ChallengeCollectionDetailModal: React.FunctionComponent<
  ChallengeCollectionDetailModalProps
> = (props: ChallengeCollectionDetailModalProps) => {
  const {
    className,
    language,
    challengeCollectionDetails,
    pinnedChallengeList,
    selectedChallengeIds = [],
    temporarySelectedChallenges = [],
    canEditPin,
    canSelect = false,
    challengeCollectionLoading,
    challengeCollectionId,
    isOpen,
    showOfficialExam,
    strictLinkedChallenge,
    defaultConditions,
    examOptions: { roleoptions = [] },
    getChallengeCollection,
    isTierActionAllowed,
  } = props;

  const roleOptionsByValue = React.useMemo(
    () =>
      roleoptions.reduce((arr, option) => {
        arr[option.value] = option;
        return arr;
      }, {}),
    [roleoptions],
  );

  /**
   * State
   */
  SwiperCore.use([Pagination, Navigation, Mousewheel]);

  const rootStyle = classnames("challenge-collection-detail-modal", {
    [`${className}`]: Boolean(className),
  });

  const [challengeDrawer, setChallengeDrawer] = React.useState<{
    isOpen: boolean;
    targetChallengeId?: number;
  }>({
    isOpen: false,
  });

  const [examDrawer, setExamDrawer] = React.useState<{
    isOpen: boolean;
    targetExamId?: number;
  }>({
    isOpen: false,
  });

  const [selectedChallenges, setSelectedChallenges] = React.useState<
    ChallengeModel[]
  >(temporarySelectedChallenges);

  const [removedChallenges, setRemovedChallenges] = React.useState<
    ChallengeModel[]
  >([]);

  /**
   * Effects
   */
  React.useEffect(() => {
    const currentCollection = challengeCollectionDetails.find(
      (item) => item.id === challengeCollectionId,
    );
    if (!currentCollection) {
      getChallengeCollection(challengeCollectionId, defaultConditions);
    }
  }, [
    challengeCollectionDetails,
    challengeCollectionId,
    getChallengeCollection,
    defaultConditions,
  ]);

  /**
   * Private Functions
   */
  const collectionDetail = challengeCollectionDetails.find(
    (item) => item.id === challengeCollectionId,
  );

  const formedCollection =
    collectionDetail &&
    getFormedChallengeCollection(collectionDetail, language);

  const onOpenChallenge = (challengeId: number) => {
    setChallengeDrawer({ isOpen: true, targetChallengeId: challengeId });
  };

  const onClickPin = (challengeId: number, pinId?: number) => {
    if (typeof props.onTogglePin === "function") {
      props.onTogglePin(challengeId, pinId);
    }
  };

  const onClickCheck = (challenge: ChallengeModel) => {
    const hasSelected = selectedChallenges.some((item) =>
      ChallengeSectionService.isLinkedChallengePair(item, challenge),
    );

    setSelectedChallenges(
      hasSelected
        ? selectedChallenges.filter(
            (item) =>
              !ChallengeSectionService.isLinkedChallengePair(item, challenge),
          )
        : [...selectedChallenges, challenge],
    );
    setRemovedChallenges(
      hasSelected
        ? [...removedChallenges, challenge]
        : removedChallenges.filter(
            (item) =>
              !ChallengeSectionService.isLinkedChallengePair(item, challenge),
          ),
    );
  };

  const onClickOk = () => {
    if (typeof props.onSelect === "function") {
      props.onSelect({ selectedChallenges, removedChallenges });
    }
  };

  const contents = formedCollection?.challenges
    .filter((challenge) =>
      strictLinkedChallenge
        ? ChallengeSectionService.isValidLinkedChallenge(challenge)
        : true,
    )
    .map((challenge) => {
      const pinnedChallenge = pinnedChallengeList.find(
        (item) => item.challengeId === challenge.id,
      );

      return (
        <CollapsibleChallengeColumn
          key={challenge.id}
          challenge={challenge}
          onClickDetail={onOpenChallenge}
          onClickPin={() => onClickPin(challenge.id, pinnedChallenge?.id)}
          onClickCheck={() => onClickCheck(challenge)}
          checked={
            selectedChallengeIds.includes(challenge.id) ||
            selectedChallenges.some((item) =>
              ChallengeSectionService.isLinkedChallengePair(item, challenge),
            )
          }
          readOnly={selectedChallengeIds.includes(challenge.id)}
          postActionMenu={<Column />}
          hasTogglePin={canEditPin}
          hasCheckbox={canSelect}
          isChallengeAllowed={isCollectionChallengeAllowed({
            challenge,
            isTierActionAllowed,
          })}
          isPinned={pinnedChallenge !== undefined}
        />
      );
    });

  /**
   * Render
   */
  return (
    <Modal
      className={rootStyle}
      isOpen={isOpen}
      onClose={props.onClose}
      onClickCancel={props.onClose}
      onClickBackground={props.onClose}
      onClickOk={onClickOk}
      size="x-large"
      title={formedCollection?.title}
      hasOkButton={canSelect}
      okButtonLabel={<Msg id="action.continue" />}
      okButtonAriaLabel="Continue"
      cancelButtonLabel={<Msg id={canSelect ? "cancel" : "close"} />}
      ariaLabel="Challenge Collection"
    >
      {!challengeCollectionLoading && formedCollection ? (
        <div className="challenge-collection__body">
          <div className="challenge-collection__collections">
            <p className="challenge-collection__description is-wrap">
              {formedCollection.description}
            </p>
            <Table className="challenge-collection__table">
              <TableHead>
                <HeaderColumn size={1} />
                <HeaderColumn size={1}>
                  <Msg id="common.id" />
                </HeaderColumn>
                <HeaderColumn size={4}>
                  <Msg id="common.title" />
                </HeaderColumn>
                <HeaderColumn size={1}>
                  <Msg id={"difficulty"} />
                </HeaderColumn>
                <HeaderColumn size={1}>
                  <Msg id={"challenge.style"} />
                </HeaderColumn>
                <HeaderColumn size={1} className="align-right">
                  <Msg id={"challenge.numberOfOrgsUsing"} />
                </HeaderColumn>
                <HeaderColumn size={1} className="align-right">
                  <Msg id={"basicTimeMinutes"} />
                </HeaderColumn>
                <HeaderColumn size={1} className="align-right">
                  <Msg id="challenge.scoreDistribution" />
                </HeaderColumn>
                <HeaderColumn size={1} className="align-right">
                  <Msg id="challenge.numberOfSubmission" />
                </HeaderColumn>
                <HeaderColumn size={1} className="align-right">
                  <Msg id="challenge.average" />
                </HeaderColumn>
                <HeaderColumn size={2} className="align-right">
                  <Msg id="challenge.averageTimeTakenMin" />
                </HeaderColumn>
                <HeaderColumn size={1} />
              </TableHead>
              {contents}
            </Table>
          </div>
          {showOfficialExam && formedCollection.exams?.length > 0 && (
            <div className="challenge-collection__official-exams">
              <p className="challenge-collection__official-exams__title">
                <Msg id="challengeCollection.related.official" />
              </p>
              {formedCollection.exams.map((exam) => (
                <div
                  className="challenge-collection__official-exam"
                  onClick={() =>
                    setExamDrawer({ isOpen: true, targetExamId: exam.id })
                  }
                  key={exam.id}
                  role="button"
                  aria-label="Open Preset Exam Detail"
                >
                  <OfficialExamCard
                    title={exam.name}
                    imageUrl={exam.coverImageUrl}
                    time={exam.examinationTime}
                    language={exam.naturalLanguage.toUpperCase()}
                    engineerRole={
                      roleOptionsByValue[exam.examSegment.engineerRole]?.label
                    }
                    usingOrgCount={exam.usingOrganizationsCount}
                    updatedAt={exam.updatedAt}
                  />
                </div>
              ))}
            </div>
          )}
          {challengeDrawer.isOpen &&
            challengeDrawer.targetChallengeId !== undefined && (
              <>
                <ChallengeDetail
                  challengeId={challengeDrawer.targetChallengeId}
                  onClose={() => setChallengeDrawer({ isOpen: false })}
                  hidePin={!canEditPin}
                />
                <Overlay
                  className="challenge-collection__overlay"
                  isOpen={true}
                  onClick={() => setChallengeDrawer({ isOpen: false })}
                />
              </>
            )}
          {examDrawer.isOpen && examDrawer.targetExamId !== undefined && (
            <>
              <OfficialExamPanel
                isOpen={true}
                examId={examDrawer.targetExamId}
                onClose={() => setExamDrawer({ isOpen: false })}
              />
              <Overlay
                className="challenge-collection__overlay"
                isOpen={true}
                onClick={() => setExamDrawer({ isOpen: false })}
              />
            </>
          )}
        </div>
      ) : (
        <Loading isOpen={true} hide={true} />
      )}
    </Modal>
  );
};
