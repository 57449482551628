import { useEffect, useState, useRef, useCallback } from "react";

import { useStoreContext } from "@context";

import { StepsHeader } from "@shared/components";
import { SpokenLanguages } from "@shared/services/enums";
import MSG from "@shared/services/message";

import ExamOutline from "../examSections/examOutline/ExamOutline";
import ExamSettings from "../examSections/examSettings/ExamSettings";

export const ExamContainer = () => {
  const { user } = useStoreContext();
  const language = user?.language ?? SpokenLanguages.English;

  const [totalValidation, setTotalValidation] = useState<any>({
    0: false,
    1: true, // TTODO NOTE: change this to false once the steps are implemented
    2: true, // TTODO NOTE: change this to false once the steps are implemented
    3: false,
  });

  const [currentStep, setCurrentStep] = useState<number>(0);
  const [nextStepEnabled, setNextStepEnabled] = useState<boolean>(false);

  const [combinedData, setCombinedData] = useState<any>({});

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  // Refs to store handleMoveStep function from the step components
  const stepRefs = [
    useRef<{ submit: () => void }>(null),
    useRef<{ submit: () => void }>(null),
    useRef<{ submit: () => void }>(null),
    useRef<{ submit: () => void }>(null),
  ];

  const handleTotalValidation = useCallback(
    (stepNum: number, stepIsValid: boolean) => {
      const newTotalValidation = {
        ...totalValidation,
        [stepNum]: stepIsValid,
      };
      if (
        JSON.stringify(newTotalValidation) !== JSON.stringify(totalValidation)
      ) {
        setTotalValidation({
          ...newTotalValidation,
        });
      }
    },
    [totalValidation],
  );

  // TTODO NOTE: Remove this once the api is set up
  useEffect(
    () =>
      console.log(
        "CONT totalValidation",
        totalValidation,
        "CONT combinedData",
        combinedData,
      ),
    [totalValidation, combinedData],
  );

  useEffect(() => {
    if (isSubmitting) {
      // TTODO NOTE: placeholder for send to api
      console.log("NEW Sending to api: ", combinedData, totalValidation);
      setIsSubmitting(false);
    }
  }, [isSubmitting, combinedData, totalValidation]);

  /**
   * Handle changes in step components
   **/

  const handleMoveStep = useCallback(
    (isValid: boolean, stepNum: number, data: any) => {
      setNextStepEnabled(isValid);
      handleTotalValidation(stepNum, isValid);
      if (data) {
        setCombinedData({
          ...combinedData,
          [stepNum]: data,
        });
      }
    },
    [handleTotalValidation, combinedData],
  );

  const errors = Object.entries(totalValidation).map((entry) =>
    Number(entry[0]) < currentStep ? !entry[1] : false,
  );

  /**
   * Step components
   **/

  const stepComponents: Record<number, JSX.Element> = {
    0: (
      <ExamOutline
        stepIndex={0}
        ref={stepRefs[0]}
        onFormChange={setNextStepEnabled}
        initialValues={{ ...combinedData[0] }}
        readOnly={false}
        hideTitle={false}
        onMoveStep={handleMoveStep} // Pass the function to ExamOutline which then gets called with the data from outline when the step changes
      />
    ),
    1: <div>Challenges</div>,
    2: <div>Weight</div>,
    3: (
      <ExamSettings
        stepIndex={3}
        ref={stepRefs[3]}
        onFormChange={setNextStepEnabled}
        initialValues={{
          language: combinedData[3]?.language || language, // Defaults to the UI language
          publicationStatus: combinedData[3]?.publicationStatus,
        }}
        readOnly={false}
        hideTitle={false}
        onMoveStep={handleMoveStep} // Pass the function to ExamSettings which then gets called with the data from settings when the step changes
      />
    ),
  };

  const handleStepChange = async (
    newStep: number,
    previousStep: number,
    shouldSubmit?: boolean, // Complete button was clicked, so send to api
  ) => {
    setCurrentStep(newStep);
    const currentRef = stepRefs[previousStep]?.current;
    if (currentRef) {
      await currentRef.submit();
    }
    if (shouldSubmit) {
      setIsSubmitting(true);
    }
  };

  return (
    <div>
      <div className="step-header">
        <StepsHeader
          currentStep={currentStep}
          stepItems={[
            MSG.getMessageByKey("exam.outline"),
            MSG.getMessageByKey("exam.challenges"),
            MSG.getMessageByKey("exam.weight"),
            MSG.getMessageByKey("exam.configure"),
          ].filter(Boolean)}
          errors={errors}
          // isStrictOrder={true} // NOTE: don't allow jumping to later steps TTODO: turned off for development of settings. Enable once all steps are implemented
          disableNext={!nextStepEnabled}
          onNext={handleStepChange}
          onPrev={handleStepChange}
          disableComplete={errors.some((hasError) => !!hasError)}
          onComplete={() => {
            handleStepChange(currentStep, currentStep, true);
          }}
        />
        {stepComponents[currentStep]}
      </div>
    </div>
  );
};
