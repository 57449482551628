import classNames from "classnames";
import { useMemo, useState } from "react";

import { useStoreContext } from "@context";

import { DifficultyTag, EmptyState, Tag } from "@shared/components";
import { useBreakpoints } from "@shared/hooks/useBreakpoints";
import {
  ExamChallengeModel,
  ExamModel,
  ReportChallengeModel,
} from "@shared/models";
import { ChallengeStyle } from "@shared/services/enums";
import {
  ChallengeAnalysisTabs,
  getChallengeStatisticDetails,
} from "@shared/services/examDashboard";
import { isChallengeAllowed } from "@shared/services/tier";

import { ChallengeAnalysisBarChart } from "../challengeAnalysisBarChart/ChallengeAnalysisBarChart";
import { ChallengeAnalysisDetailDrawer } from "../challengeDetailDrawer/ChallengeDetailDrawer";
import { ExamDashboardChallengeStats } from "../examDashboardChallengeStats/ExamDashboardChallengeStats";
import { TimeTakenScatterChart } from "../examDashboardTimeTaken/TimeTakenScatterChart";

interface ExamDashboardScoreDistributionProps {
  exam: ExamModel;
  examChallengeAnalysisList?: ReportChallengeModel[];
  selectedTab: ChallengeAnalysisTabs;
  selectedApplicantIds: number[];
}

export const ExamDashboardScoreDistribution = ({
  exam,
  examChallengeAnalysisList,
  selectedTab,
  selectedApplicantIds,
}: ExamDashboardScoreDistributionProps) => {
  const [selectedChallenge, setSelectedChallenge] =
    useState<ExamChallengeModel | null>(null);

  const challenges = useMemo(() => {
    const formattedChallenges = getChallengeStatisticDetails({
      exam,
      examChallengeAnalysisList,
    });

    return formattedChallenges;
  }, [exam, examChallengeAnalysisList]);

  const onChallengeTitleClick = (challenge: ExamChallengeModel) => {
    setSelectedChallenge(challenge);
  };

  const onChallengeClose = () => {
    setSelectedChallenge(null);
  };

  const { user, projectId, isTierActionAllowed } = useStoreContext();

  const canViewChallenges = user?.canViewChallenges(projectId);
  const screenSize = useBreakpoints();

  return (
    <div className="code-dashboard-score-distribution">
      {challenges.map(
        ({ challenge, analysis, challengeId, challengeVersionId }, idx) => {
          if (!analysis || !challengeId || !challengeVersionId) {
            return <EmptyState key={idx} height="challengeAnalysis" />;
          }

          const isTierAllowedChallenge = isChallengeAllowed({
            challenge,
            isTierActionAllowed,
          });

          const challengeClickable =
            canViewChallenges && isTierAllowedChallenge;

          const canGetChallenges = isTierAllowedChallenge && canViewChallenges;

          return (
            <div
              key={challenge.id}
              className="code-exam-dashboard__panel code-dashboard-score-distribution__panel"
            >
              <div className="code-dashboard-score-distribution__header">
                <a
                  className={classNames({
                    clickable: challengeClickable,
                  })}
                  onClick={
                    challengeClickable
                      ? () => onChallengeTitleClick(challenge)
                      : undefined
                  }
                  role="button"
                  aria-label="Toggle Challenge Title"
                >
                  <h4 className="code-dashboard-score-distribution__header__challenge-title">
                    {challenge.title}
                  </h4>
                </a>
                <div className="code-dashboard-score-distribution__header__tags">
                  <Tag>{ChallengeStyle.toString(challenge.style)}</Tag>
                  <DifficultyTag value={challenge.difficulty} />
                </div>
              </div>
              <div className="code-dashboard-score-distribution__chart">
                {selectedTab === "scoreDistribution" && (
                  <ChallengeAnalysisBarChart
                    examId={exam.id}
                    canGetChallenges={canGetChallenges}
                    examChallengeId={challenge.id}
                    challengeId={challengeId}
                    challengeVersionId={challengeVersionId}
                    analysis={analysis}
                    screenSize={screenSize}
                  />
                )}
                {selectedTab === "timeTaken" && (
                  <TimeTakenScatterChart
                    examId={exam.id}
                    challengeId={challengeId}
                    examChallengeId={challenge.id}
                    selectedApplicantIds={selectedApplicantIds}
                    screenSize={screenSize}
                  />
                )}
              </div>
              <div className="code-dashboard-score-distribution__stats">
                {analysis && (
                  <ExamDashboardChallengeStats analysis={analysis} />
                )}
              </div>
            </div>
          );
        },
      )}
      {selectedChallenge && (
        <ChallengeAnalysisDetailDrawer
          challenge={selectedChallenge}
          onChallengeClose={onChallengeClose}
        />
      )}
    </div>
  );
  //
};
