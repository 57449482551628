import * as classnames from "classnames";
import * as React from "react";
import { RouteComponentProps } from "react-router-dom";

import { SubmissionAccessTypeProvider } from "@context";

import { DocumentTitle, Modal, Msg } from "@shared/components";
import { useExamPermissions, useTierActionPermissions } from "@shared/hooks";
import {
  useExam,
  useRecalculateScore,
  useSubmission,
  useGetSubmissionsReviewers,
  usePingReviewer,
  useEvaluateSubmission,
  useDeleteSubmissionEvaluation,
  useCreateManualSubmitSubmission,
} from "@shared/hooks/query";
import {
  ApplicantReportModel,
  ReportExamScore,
  ReviewerUserModel,
  SubmissionAccessType,
  SubmissionReportChallengeModel,
  SubmissionResultDetailModel,
  UserModel,
} from "@shared/models";
import { SubmissionListKind, TierAction } from "@shared/services/enums";
import History from "@shared/services/history";
import Message from "@shared/services/message";
import { showSurvey } from "@shared/services/survey";

import ExtendSubmission from "../extendSubmission/ExtendSubmission.connect";
import PingReviewer from "../pingReviewer/PingReviewer";
import {
  SubmissionEvaluation,
  SubmissionEvaluationModal,
} from "../submissionEvaluationModal/SubmissionEvaluationModal";
import {
  SubmissionDetailTabs,
  SubmissionDetailFloatingButtons,
  SubmissionDetailTop,
  SubmissionIpAddressModal,
  SubmitExamModal,
} from "./partials";
import { buildDisplayConditionals } from "./partials/SubmissionDetailUtils";

/**
 * Prop interface
 */
export interface SubmissionDetailBaseProps {
  currentProjectId: number;
  examId: number;
  submissionId: number;
  currentUser: UserModel;
  getSubmissionDetail: (submissionId: number, examId?: number) => void;
  applicantReportDetails: ApplicantReportModel | undefined;
  reportExamScores: Array<ReportExamScore>;
  getReport: (submissionId: number) => void;
  reportLoading: boolean;
  reportChallenges: Array<SubmissionReportChallengeModel>;
  isTierActionAllowed: (tierAction: TierAction) => boolean;
  resetReport: () => void;
}

export type SubmissionDetailProps = SubmissionDetailBaseProps &
  RouteComponentProps<{ examId: string; submissionId: string }>;

const SubmissionDetail: React.FC<SubmissionDetailProps> = ({
  currentProjectId,
  examId,
  submissionId,
  currentUser,
  applicantReportDetails,
  reportExamScores,
  reportLoading,
  reportChallenges,
  getSubmissionDetail,
  isTierActionAllowed,
  getReport,
  resetReport,
}) => {
  const recalculateScore = useRecalculateScore();
  const {
    data: { examDetail, examReviewers },
  } = useExam();
  const { data: submission } = useSubmission();
  const tabsRef = React.useRef<HTMLDivElement | null>(null);

  const [isOpenDeadlineExtension, setIsOpenDeadlineExtension] =
    React.useState(false);
  const [isOpenComment, setIsOpenComment] = React.useState(false);
  const [isOpenEvaluation, setIsOpenEvaluation] = React.useState(false);
  const [isOpenIpAddress, setIsOpenIpAddress] = React.useState(false);
  const [isOpenManualSubmit, setIsOpenManualSubmit] = React.useState(false);

  const [
    { isOpenRecalculate, recalculateChallengeResult },
    setRecalculateState,
  ] = React.useState<{
    isOpenRecalculate: boolean;
    recalculateChallengeResult?: SubmissionResultDetailModel;
  }>({
    isOpenRecalculate: false,
    recalculateChallengeResult: undefined,
  });
  const [accessDenied, setAccessDenied] = React.useState<boolean | undefined>(
    undefined,
  );
  const [isSurveyRendered, setIsSurveyRendered] = React.useState(false);
  const [pingModalState, setPingModalState] = React.useState<{
    isOpen?: boolean;
    reviewer?: ReviewerUserModel;
  }>({});
  const { canViewActionLog, canViewCodeDiff } = useTierActionPermissions();
  const { enableViewCodePlayback } = useExamPermissions();
  const updateEvaluation = useEvaluateSubmission();
  const deleteEvaluation = useDeleteSubmissionEvaluation();
  const submitExam = useCreateManualSubmitSubmission();

  const [pingedIds, setPingedIds] = React.useState<number[]>([]);
  const {
    data: { pendingReviewers, reviews },
  } = useGetSubmissionsReviewers();
  const pingReviewer = usePingReviewer();
  const rootStyle = classnames("code-submission-detail");
  const allowReview = examReviewers.some(
    (reviewer) => reviewer.id === currentUser.id,
  );

  const {
    showSubmissionDetails,
    showSkillReportTab,
    canViewSkillReport,
    showActionLogTab,
    showReviewButton,
    showReviewButtonAsPrimary,
    showReviewPopup,
    showEvaluateButton,
  } = buildDisplayConditionals({
    currentUser,
    currentProjectId,
    submission,
    examDetail,
    isTierActionAllowed,
    applicantReportDetails,
    reviews,
    allowReview,
    pendingReviewers,
    isOpenComment,
  });

  const isReviewFunctionalityEnabled = isTierActionAllowed(
    TierAction.ReviewFunctionality,
  );

  // check the permission
  const submissionAccessType = currentUser.getAccessSubmissionType(
    parseInt(currentProjectId.toString(), 10),
    submission.status,
    examDetail.reviewers,
  );

  React.useEffect(() => {
    // light check the permission
    if (
      currentUser.getAccessSubmissionType(
        parseInt(currentProjectId.toString(), 10),
        SubmissionListKind.all, // dummy status
        examDetail.reviewers,
      ) === SubmissionAccessType.Deny
    ) {
      History.replace("/accessDenied");
    }
  }, [currentProjectId, currentUser, examDetail.reviewers, submissionId]);

  React.useEffect(() => {
    if (accessDenied === undefined) {
      // when initial access
      if (
        currentUser &&
        examDetail.id !== undefined &&
        submission.id !== undefined
      ) {
        // exam reviewers have SubmissionAccessType.Full
        const shouldRenderSurvey =
          submissionAccessType === SubmissionAccessType.Full;

        if (shouldRenderSurvey && !isSurveyRendered) {
          showSurvey({
            uniqueId: currentUser.id.toString(),
            data: currentUser,
            surveyType: "reviewer",
          });
        }

        setAccessDenied(submissionAccessType === SubmissionAccessType.Deny);
        setIsSurveyRendered(isSurveyRendered || shouldRenderSurvey);

        if (submissionAccessType === SubmissionAccessType.Deny) {
          History.replace("/accessDenied");
        }
        // if (submissionAccessType === SubmissionAccessType.ExamDeliverer) {
        //   getSubmissionResultList(Number(submissionId));
        // }
        if (submissionAccessType === SubmissionAccessType.Full) {
          getSubmissionDetail(Number(submissionId), examId);
        }
      }
    }
  }, [
    accessDenied,
    currentProjectId,
    currentUser,
    examDetail.id,
    examDetail.reviewers,
    examId,
    getSubmissionDetail,
    isSurveyRendered,
    submission.id,
    submission.status,
    submissionAccessType,
    submissionId,
  ]);

  // Remove report data when changing submission
  React.useEffect(() => {
    return () => {
      resetReport();
    };
  }, [resetReport]);

  React.useEffect(() => {
    if (accessDenied !== undefined && submission.archivedAt) {
      History.replace(
        `/p/${currentProjectId}/exams/${examId}/submissions_${SubmissionListKind.toPathname(
          submission.status,
          isReviewFunctionalityEnabled,
        )}`,
      );
    }
  }, [
    accessDenied,
    currentProjectId,
    examId,
    isReviewFunctionalityEnabled,
    submission,
  ]);

  const onCloseModal = () => {
    setIsOpenDeadlineExtension(false);
    setRecalculateState({
      isOpenRecalculate: false,
      recalculateChallengeResult: undefined,
    });
  };

  const openRecalculate = (
    recalculateChallengeResult: SubmissionResultDetailModel,
  ) => {
    setRecalculateState({
      isOpenRecalculate: true,
      recalculateChallengeResult,
    });
  };

  const onRecalculate = () => {
    if (recalculateChallengeResult) {
      recalculateScore.mutate({
        challengeResultId: recalculateChallengeResult.id,
      });
    }
    onCloseModal();
  };

  const onPingReviewer = () => {
    const { reviewer } = pingModalState;

    if (reviewer) {
      pingReviewer.mutate({ reviewerId: reviewer.id });
      setPingedIds(Array.from(new Set([...pingedIds, reviewer.id])));
      setPingModalState({});
    }
  };

  const handleEvaluation = (newEvaluation: SubmissionEvaluation) => {
    const params = {
      onSuccess: () => {
        setIsOpenEvaluation(false);
      },
    };

    newEvaluation !== undefined
      ? updateEvaluation.mutate(
          {
            submissionId,
            data: { status: newEvaluation },
          },
          params,
        )
      : deleteEvaluation.mutate(submissionId, params);
  };
  if (examDetail.id === undefined || submission.id === undefined) {
    return null;
  }

  return (
    <div className={rootStyle}>
      <SubmissionAccessTypeProvider submissionAccessType={submissionAccessType}>
        <DocumentTitle
          page={Message.getMessageByKey("submission.submissionDetail")}
        />
        <SubmissionDetailTop
          examId={examId}
          isOpenComment={isOpenComment}
          onEvaluationClick={() => setIsOpenEvaluation(true)}
          onReviewEditClick={() => setIsOpenComment(true)}
          onOpenIpAddressModal={() => setIsOpenIpAddress(true)}
          onManualSubmitClick={() => setIsOpenManualSubmit(true)}
          onChangeDeadlineClick={() => setIsOpenDeadlineExtension(true)}
          canViewActionLog={canViewActionLog}
          showActionLogTab={showActionLogTab}
          scrollRef={tabsRef}
        />

        {/* Tab Items */}
        {showSubmissionDetails && (
          <SubmissionDetailTabs
            currentProjectId={currentProjectId}
            examId={examId}
            examDetail={examDetail}
            submissionId={submissionId}
            submission={submission}
            showSkillReportTab={showSkillReportTab}
            canViewSkillReport={canViewSkillReport}
            canViewActionLog={canViewActionLog}
            canViewCodePlayback={enableViewCodePlayback}
            canViewCodeDiff={canViewCodeDiff}
            showActionLogTab={showActionLogTab}
            reportLoading={reportLoading}
            reportChallenges={reportChallenges}
            getReport={getReport}
            onClickRecalculate={openRecalculate}
            ref={tabsRef}
          />
        )}
        {/* Floating Buttons */}
        <SubmissionDetailFloatingButtons
          currentUser={currentUser}
          showReviewButton={showReviewButton}
          showReviewPopup={showReviewPopup}
          showEvaluateButton={showEvaluateButton}
          showReviewButtonAsPrimary={showReviewButtonAsPrimary}
          onReviewButtonClick={() => {
            setIsOpenComment(true);
          }}
          onEvaluateButtonClick={() => {
            setIsOpenEvaluation(true);
          }}
          onReviewPopupSubmit={() => {
            setIsOpenComment(false);
          }}
          onReviewPopupCancel={() => {
            setIsOpenComment(false);
          }}
          isReviewFeatureAllowed={isTierActionAllowed(
            TierAction.ReviewFunctionality,
          )}
        />
        {/* Modals */}
        {isOpenDeadlineExtension && (
          <ExtendSubmission
            isOpen={true}
            onClose={onCloseModal}
            submission={submission}
          />
        )}
        {isOpenRecalculate && (
          <Modal
            isOpen={true}
            size="small"
            title={<Msg id="submission.score.recalculate" />}
            onClickBackground={onCloseModal}
            onClickCancel={onCloseModal}
            onClose={onCloseModal}
            onClickOk={onRecalculate}
            okButtonLabel={<Msg id="submission.score.recalculate" />}
          >
            <Msg id="message.submission.recalculate" />
          </Modal>
        )}
        {pingModalState.isOpen && pingModalState.reviewer && (
          <PingReviewer
            isOpen={true}
            reviewer={pingModalState.reviewer}
            onOK={onPingReviewer}
            onClose={() => setPingModalState({})}
          />
        )}
        {isOpenEvaluation && (
          <SubmissionEvaluationModal
            evaluation={submission.status}
            onClickOk={handleEvaluation}
            onClickCancel={() => setIsOpenEvaluation(false)}
          />
        )}
        {isOpenIpAddress && (
          <SubmissionIpAddressModal
            onClickClose={() => setIsOpenIpAddress(false)}
          />
        )}
        {isOpenManualSubmit && (
          <SubmitExamModal
            submission={submission}
            onClickSubmit={() => {
              submitExam.mutate(submission.id);
              setIsOpenManualSubmit(false);
            }}
            onClickCancel={() => {
              setIsOpenManualSubmit(false);
            }}
          />
        )}
      </SubmissionAccessTypeProvider>
    </div>
  );
};

export default SubmissionDetail;
