import { createContext, useContext, ReactNode } from "react";
import { connect } from "react-redux";
import { Action, Dispatch } from "redux";

import {
  challengeListGetAction,
  challengeSubmitAction,
  examSubmissionGetAction,
  pinnedChallengeListUpdateAction,
  pinnedChallengeUpdateAction,
  postExamDetailsGetAction,
  questionExamLevelInsightClearAction,
  readmeAction,
  refreshChallengeListAction,
} from "@actions";

import { ApiResponse } from "@api/httpClient";

import { RootState } from "@reducers/index";

import {
  IApplicantActionLog,
  ApplicantChallengeResultModel,
  PaginationModel,
  PinnedChallengeModel,
  SubmissionResultDetailModel,
} from "@shared/models";
import { GlobalAppType } from "@shared/services/enums";
import { blockTierCheck, getTierAllowedByRedux } from "@shared/services/tier";

const mapStateToProps = (state: RootState) => {
  const globalAppType = state.global?.globalAppType ?? GlobalAppType.User;
  const isTierActionAllowed =
    globalAppType === GlobalAppType.Admin || globalAppType === GlobalAppType.Org
      ? getTierAllowedByRedux(state)
      : blockTierCheck;

  return {
    actionLogs: state.actionLog?.applicantActionLogList,
    applicantExamId: state.applicant?.initial?.id,
    globalAppType,
    projectId: state.project?.currentProjectId,
    projectDetail: state.project?.projectDetail,
    user: state.user?.user,
    applicantExam: state.applicant?.exam,
    examTimer: state.applicantTimer?.exam,
    isTierActionAllowed,
    uploadingImage: state.imageUpload?.uploading,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  addReadme: (submission: SubmissionResultDetailModel) => {
    const model = new SubmissionResultDetailModel(submission);
    const { challengeId, codingResult, aiResult } = model;
    const readmeLink = codingResult?.readmeLink || aiResult?.readmeLink || "";
    dispatch(readmeAction.request(challengeId, readmeLink));
  },
  postChallengeListGet: () => {
    dispatch(challengeListGetAction.request({ ...new PaginationModel() }));
  },
  postExamDetailGet: (payload: number) => {
    dispatch(postExamDetailsGetAction.request(payload));
  },
  updateChallengePinListState: (model: PinnedChallengeModel[]) => {
    dispatch(pinnedChallengeListUpdateAction.request(model));
  },
  updateChallengePinState: (
    model: PinnedChallengeModel,
    { isDelete }: { isDelete: boolean },
  ) => {
    dispatch(pinnedChallengeUpdateAction.request(model, { isDelete }));
  },
  postSubmitChallenge: (
    payload: ApiResponse<ApplicantChallengeResultModel> | string,
    isSuccess: boolean,
  ) => {
    if (!isSuccess) {
      dispatch(challengeSubmitAction.failure(payload));
      return;
    }

    dispatch(challengeSubmitAction.success(payload));
  },
  refreshChallengeList: () => {
    dispatch(refreshChallengeListAction.request());
  },
  requestSubmission: () => {
    dispatch(examSubmissionGetAction.request());
  },
  resetQuestionInsight: () => {
    dispatch(questionExamLevelInsightClearAction.request());
  },
});

type StoreContextType = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

type Props = StoreContextType & { children?: ReactNode };

const StoreContext = createContext({
  actionLogs: [] as IApplicantActionLog[],
  globalAppType: GlobalAppType.Org,
} as StoreContextType);

export const useStoreContext = () => useContext(StoreContext);

export const StoreProvider = ({ children, ...rest }: Props) => {
  return <StoreContext.Provider value={rest}>{children}</StoreContext.Provider>;
};

export default connect(mapStateToProps, mapDispatchToProps)(StoreProvider);
