import { AxiosRequestConfig } from "axios";

import { ChallengeInsightModel } from "@shared/models";

import { get } from "../httpClient";

export interface ChallengeInsightParams {
  projectId: number;
  challengeInsightId: number;
  options?: AxiosRequestConfig;
}

export async function getChallengeInsight({
  projectId,
  challengeInsightId,
  options,
}: ChallengeInsightParams) {
  const { data } = await get<ChallengeInsightModel>(
    `/api/projects/${projectId}/insight/${challengeInsightId}`,
    options,
  );

  return data;
}
