import { Epic } from "redux-observable";
import { Observable } from "rxjs/Observable";
import { AjaxError } from "rxjs/observable/dom/AjaxObservable";
import { ajax } from "rxjs/observable/dom/ajax";

import { Action, readmeAction } from "../actions";
import { RootState } from "../reducers";

const readmeGetEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(readmeAction.types.request).mergeMap((action) =>
    ajax({
      method: "get",
      url: action.params as string,
      crossDomain: true,
      responseType: "text",
    })
      .map((response) =>
        readmeAction.success({
          challengeId: action.payload,
          body: response.response,
        }),
      )
      .catch((error: AjaxError) =>
        Observable.of({ type: "Readme ajax error" }),
      ),
  );

export default [readmeGetEpic];
