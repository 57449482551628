import { connect } from "react-redux";
import { Action, Dispatch } from "redux";

import {
  projectInviteListGetAction,
  projectInviteResendAction,
  projectInviteDeleteAction,
  projectInviteUpdateAction,
} from "@actions";

import { RootState } from "@reducers";

import { PaginationModel } from "@shared/models";
import { UserRole, ProjectRole } from "@shared/services/enums";

import { SettingMemberPending } from "./SettingMemberPending";

const mapStateToProps = (state: RootState) => ({
  invites: state.project.pendingInviteList,
  pagination: state.project.pendingInviteListPagination,
  currentProjectId: state.project.currentProjectId,
  editableRoles: [UserRole.SystemAdmin, ProjectRole.ProjectAdmin],
  inviteMemberLoading: state.project.pendingInviteMemberLoading,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  getInvites: (pagination: PaginationModel, keyword?: string) => {
    dispatch(projectInviteListGetAction.request({ ...pagination, keyword }));
  },
  resendInvite: (inviteId: number) => {
    dispatch(projectInviteResendAction.request(inviteId));
  },
  updateInvite: (inviteId: number, formValues: {}) => {
    dispatch(projectInviteUpdateAction.request(inviteId, formValues));
  },
  deleteInvite: (inviteId: number) => {
    dispatch(projectInviteDeleteAction.request(inviteId));
  },
});

/**
 * Wrapped component
 */
const wrappedComponent = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SettingMemberPending);

export default wrappedComponent;
