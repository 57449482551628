import { useFormContext } from "react-hook-form";

import { useStoreContext } from "@context";

import { Label, CopyBox, Msg, Checkbox, QuickHelp } from "@shared/components";
import { isValidDate } from "@shared/services/date";
import { TierAction } from "@shared/services/enums";
import Message from "@shared/services/message";
import { getExamDeliveryURL } from "@shared/services/url";

import { useDraftForm } from "../DraftFormProvider";
import { ExamSettingsFormValidation } from "../utils";
import { AtsIntegrationCheckbox } from "./AtsIntegrationCheckbox";
import { UrlActivePeriod } from "./UrlActivePeriod";

interface IdDeliverySectionProps {
  readOnly?: boolean;
  urlToken?: string;
}

export const IdDeliverySection = ({
  readOnly,
  urlToken,
}: IdDeliverySectionProps) => {
  const { isTierActionAllowed, user } = useStoreContext();

  const isIntegrateATSAllowed = isTierActionAllowed(
    TierAction.ExternalATSUsage,
  );

  const isDeliverByUrlAllowed = isTierActionAllowed(TierAction.UrlDelivering);

  const { setDraftState } = useDraftForm<ExamSettingsFormValidation>();

  const {
    register,
    watch,
    setValue,
    formState: { errors },
    trigger,
  } = useFormContext<ExamSettingsFormValidation>();

  const [startAt, endAt] = watch([
    "idDeliveryConfig.startAt",
    "idDeliveryConfig.endAt",
  ]) as [Date | undefined | string, Date | undefined | string];

  let startAtValue = "";
  if (startAt && isValidDate(startAt)) {
    startAtValue = (startAt as Date).toISOString();
  }
  if (startAt && !isValidDate(startAt)) {
    startAtValue = startAt as string;
  }

  let endAtValue = "";
  if (endAt && isValidDate(endAt)) {
    endAtValue = (endAt as Date).toISOString();
  }
  if (endAt && !isValidDate(endAt)) {
    endAtValue = endAt as string;
  }

  return (
    <div className="code-exam-create-new__settings__id-delivery">
      {urlToken && (
        <div>
          <Label>
            <Msg id="exam.idDelivery.urlLabel" />
          </Label>
          <CopyBox
            disabled={!isDeliverByUrlAllowed}
            value={getExamDeliveryURL(
              user?.organization.name || "",
              urlToken,
              false,
            )}
          />
        </div>
      )}
      <UrlActivePeriod
        readOnly={readOnly}
        startAtProps={{
          onChange: (date: string) => {
            const dateFormatted = isValidDate(date) ? new Date(date) : date;
            setDraftState((draft) => {
              if (draft.idDeliveryConfig) {
                draft.idDeliveryConfig.startAt = dateFormatted as Date;
              }
              return draft;
            });
            setValue("idDeliveryConfig.startAt", dateFormatted as Date);
            trigger("idDeliveryConfig.startAt");
            trigger("idDeliveryConfig.endAt");
          },
          value: startAtValue,
          error: (errors?.idDeliveryConfig as any)?.startAt?.message,
        }}
        endAtProps={{
          onChange: (date: string) => {
            const dateFormatted = isValidDate(date) ? new Date(date) : date;
            setDraftState((draft) => {
              if (draft.idDeliveryConfig) {
                draft.idDeliveryConfig.endAt = dateFormatted as Date;
              }
              return draft;
            });
            setValue("idDeliveryConfig.endAt", dateFormatted as Date);
            trigger("idDeliveryConfig.startAt");
            trigger("idDeliveryConfig.endAt");
          },
          value: endAtValue,
          error: (errors?.idDeliveryConfig as any)?.endAt?.message,
        }}
        dynamicDeadlineHoursFieldId="idDeliveryConfig.dynamicDeadlineHours"
      />
      <div className="code-exam-create-new__settings__checkboxes">
        <Label>
          <Msg id="exam.notification" />
        </Label>
        <Checkbox
          readOnly={readOnly}
          {...register("idDeliveryConfig.passwordRequired", {
            onChange: (value) => {
              console.log("value", value.target.checked);
              setDraftState((draft) => {
                if (draft.idDeliveryConfig) {
                  draft.idDeliveryConfig.passwordRequired =
                    value.target.checked;
                }
                return draft;
              });
              return value;
            },
          })}
          dataTestautomationid="passwordRequiredCheckbox"
        >
          <Msg id="exam.idDelivery.uniquePasswordCheckbox" />
          <QuickHelp
            text={Message.getMessageByKey(
              "exam.idDelivery.uniquePasswordCheckbox.tooltip",
            )}
            maxSize="xlarge"
          />
        </Checkbox>
        <AtsIntegrationCheckbox
          readOnly={readOnly}
          isIntegrateATSAllowed={isIntegrateATSAllowed}
        />
      </div>
    </div>
  );
};
