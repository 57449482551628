import * as classnames from "classnames";
import { isEqual } from "lodash";
import * as React from "react";

import {
  Modal,
  ContentsSearchForm,
  SubMenuContainer,
  SubMenuContainerRight,
  SubMenuContainerLeft,
  Table,
  TableHead,
  HeaderColumn,
  CollapsibleQuestionColumn,
  Pagination,
  Question,
  Loading,
  SortableText,
  Msg,
  QuickHelp,
} from "@shared/components";
import {
  PaginationModel,
  QuestionListModel,
  QuestionModel,
  QuestionFilterModel,
} from "@shared/models";
import { SortOrderItem } from "@shared/models";
import {
  QuestionType,
  QuestionCategory,
  SortDirection,
} from "@shared/services/enums";
import Message from "@shared/services/message";

/**
 * Prop interface
 */
export interface ParentProps {
  isOpen: boolean;
  selectedQuestions: Array<QuestionModel>;
  onSelect?: (challenges: Array<{}>) => void;
  onCancel?: () => void;
}

export interface OwnProps {
  loading: boolean;
  loadingDetail: boolean;
  questionList: Array<QuestionListModel>;
  questionDetailList: Array<QuestionModel>;
  pagination: PaginationModel;
  questionFilters: QuestionFilterModel;

  getQuestionList: (
    pagination: PaginationModel,
    filters: {},
    sortOrder: SortOrderItem,
  ) => void;
  getQuestion: (payload: {}) => void;
}

type QuestionSelectProps = OwnProps & ParentProps;

/**
 * State interface
 */
export interface QuestionSelectState {
  selectedQuestions: QuestionListModel[];
  formValues: {};
  sortItem: SortOrderItem;
}

/**
 * Page component
 */
class QuestionSelect extends React.Component<
  QuestionSelectProps,
  QuestionSelectState
> {
  constructor(props: QuestionSelectProps) {
    super(props);

    this.state = {
      selectedQuestions: [],
      formValues: {},
      sortItem: {
        column: "id",
        direction: SortDirection.Desc,
      },
    };
  }

  public UNSAFE_componentWillReceiveProps(nextProps: QuestionSelectProps) {
    if (nextProps.isOpen && !this.props.isOpen) {
      this.setState(
        {
          selectedQuestions: [],
          formValues: {},
          sortItem: {
            column: "id",
            direction: SortDirection.Desc,
          },
        },
        () =>
          this.props.getQuestionList(
            new PaginationModel(),
            {},
            this.state.sortItem,
          ),
      );
    }
  }

  public render() {
    const { isOpen, onCancel } = this.props;

    const rootStyle = classnames("code-question-select");

    const { questionDetailList, questionFilters } = this.props;
    const { keyword } = this.state.formValues as { keyword: string };

    const questions = this.props.questionList.map(
      (question: QuestionListModel) => {
        const selected = this.props.selectedQuestions.some(
          (selectedQuestion) => selectedQuestion.id === question.id,
        );
        const toggledOn = Boolean(
          this.state.selectedQuestions.find(
            (selectedQuestion) => selectedQuestion.id === question.id,
          ),
        );
        const questionDetail = questionDetailList.find(
          (item) => item.id === question.id,
        );

        return (
          <CollapsibleQuestionColumn
            key={question.id}
            question={question}
            onOpen={this.onOpenQuestion}
            loading={this.props.loadingDetail && !questionDetail}
            readOnly={selected}
            hasCheckbox={true}
            value={selected || toggledOn}
            keyword={keyword}
            onChange={() => this.toggleSelectedQuestion(question.id)}
            inModal={true}
          >
            <Question
              question={questionDetail}
              hasHeader={false}
              highlightedKeyword={keyword}
              insightsDisplayOptions={{ showGlobalStats: true }}
            />
          </CollapsibleQuestionColumn>
        );
      },
    );

    return (
      <Modal
        className={rootStyle}
        size="full"
        title={<Msg id="common.selectQuestions" />}
        isOpen={isOpen}
        onClose={onCancel}
        onClickCancel={onCancel}
        onClickOk={this.onSelect}
        okButtonLabel={<Msg id="common.ok" />}
        hasSubMenu={true}
        ariaLabel="Select Question"
      >
        <SubMenuContainer>
          <SubMenuContainerLeft>
            <ContentsSearchForm
              onChange={this.onFilter}
              clear={isOpen}
              initialValues={this.state.formValues}
              keywordHint={<Msg id="question.keyword.hint" />}
              filters={[
                {
                  title: <Msg id="common.categories" />,
                  value: "categories",
                  options: [
                    {
                      value: QuestionCategory.Preset,
                      label: QuestionCategory.toString(QuestionCategory.Preset),
                      count: 0,
                    },
                    {
                      value: QuestionCategory.Original,
                      label: QuestionCategory.toString(
                        QuestionCategory.Original,
                      ),
                      count: 0,
                    },
                  ],
                },
                {
                  title: <Msg id={"kinds"} />,
                  value: "kinds",
                  options: [
                    {
                      value: QuestionType.MCQ,
                      label: QuestionType.toString(QuestionType.MCQ),
                      count: 0,
                    },
                    {
                      value: QuestionType.FIB,
                      label: QuestionType.toString(QuestionType.FIB),
                      count: 0,
                    },
                    {
                      value: QuestionType.FreeText,
                      label: QuestionType.toString(QuestionType.FreeText),
                      count: 0,
                    },
                  ],
                },
                {
                  title: <Msg id={"quizCategories"} />,
                  value: "quizCategories",
                  options: questionFilters.quizCategories.map((filter) => ({
                    value: filter.value,
                    label: filter.displayString,
                    count: 0,
                  })),
                },
              ]}
            />
          </SubMenuContainerLeft>
          <SubMenuContainerRight>
            <Loading
              isOpen={this.props.loading}
              fullScreen={false}
              overlay={false}
            />
            <Table>
              <TableHead>
                <HeaderColumn size={1} />
                <HeaderColumn absoluteSize={5}>
                  <SortableText
                    name="id"
                    sortItem={this.state.sortItem}
                    onClick={this.onSortChange}
                    ariaLabel="ID"
                  >
                    <Msg id="common.id" />
                  </SortableText>
                </HeaderColumn>
                <HeaderColumn size={5}>
                  <SortableText
                    name="title"
                    sortItem={this.state.sortItem}
                    onClick={this.onSortChange}
                    ariaLabel="Title"
                  >
                    <Msg id="common.title" />
                  </SortableText>
                </HeaderColumn>
                <HeaderColumn absoluteSize={5}>
                  <SortableText
                    name="kind"
                    sortItem={this.state.sortItem}
                    onClick={this.onSortChange}
                    ariaLabel="Kind"
                  >
                    <Msg id={"kind"} />
                  </SortableText>
                </HeaderColumn>
                <HeaderColumn size={4}>
                  <Msg id="includedChallenge" />
                </HeaderColumn>
                <HeaderColumn absoluteSize={5} className="align-right">
                  <SortableText
                    name="company_usage_count"
                    sortItem={this.state.sortItem}
                    onClick={this.onSortChange}
                    ariaLabel="Company Usage"
                  >
                    <Msg id="challenge.numberOfOrgsUsing" />
                  </SortableText>
                </HeaderColumn>
                <HeaderColumn
                  absoluteSize={5}
                  className="is-keep-all align-right"
                >
                  <SortableText
                    name="global_submission_count"
                    sortItem={this.state.sortItem}
                    onClick={this.onSortChange}
                    ariaLabel="Submission Count"
                  >
                    <Msg id="challenge.numberOfSubmission" />
                  </SortableText>
                </HeaderColumn>
                <HeaderColumn absoluteSize={5} className="align-right">
                  <SortableText
                    name="global_average_score"
                    sortItem={this.state.sortItem}
                    onClick={this.onSortChange}
                    ariaLabel="Correct Rate"
                  >
                    <Msg id="question.correctRate" />
                    <QuickHelp
                      text={Message.getMessageByKey(
                        "question.correctRateColumn.tooltip",
                      )}
                      maxSize="large"
                    />
                  </SortableText>
                </HeaderColumn>
                <HeaderColumn absoluteSize={5} className="align-right">
                  <SortableText
                    name="global_average_time"
                    sortItem={this.state.sortItem}
                    onClick={this.onSortChange}
                    ariaLabel="Average Time Taken"
                  >
                    <Msg id="challenge.averageTimeTaken" />
                  </SortableText>
                </HeaderColumn>
              </TableHead>
              {questions}
            </Table>
            <div className="code-question-select__pagination">
              <Pagination
                pagination={this.props.pagination}
                onPageChange={this.onPageChange}
              />
            </div>
          </SubMenuContainerRight>
        </SubMenuContainer>
      </Modal>
    );
  }

  private onFilter = (formValid: boolean, formValues: {}, formErrors: {}) => {
    if (isEqual(formValues, this.state.formValues)) {
      return;
    }
    this.setState({
      formValues,
    });

    this.props.getQuestionList(
      new PaginationModel(),
      formValues,
      this.state.sortItem,
    );
  };

  private onPageChange = ({ selected }: { selected: number }) => {
    this.props.getQuestionList(
      this.props.pagination.getAtPage(selected),
      this.state.formValues,
      this.state.sortItem,
    );
  };

  private onSortChange = (sortItem: SortOrderItem) => {
    this.setState({
      sortItem,
    });

    this.props.getQuestionList(
      new PaginationModel(),
      this.state.formValues,
      sortItem,
    );
  };

  private onSelect = () => {
    if (this.props.onSelect) {
      this.props.onSelect(this.state.selectedQuestions);
    }
  };

  private onOpenQuestion = (questionId: number) => {
    const index = this.props.questionDetailList.findIndex(
      (item) => item.id === questionId,
    );
    if (index === -1) {
      this.props.getQuestion(questionId);
    }
  };

  private toggleSelectedQuestion = (id: number) => {
    const selectedQuestions = [...this.state.selectedQuestions];
    const selectedIndex = this.state.selectedQuestions.findIndex(
      (question) => question.id === id,
    );

    if (selectedIndex >= 0) {
      selectedQuestions.splice(selectedIndex, 1);
    } else {
      const question = this.props.questionList.find(
        (element) => element.id === id,
      );

      if (!question) {
        return;
      }

      selectedQuestions.push(question);
    }

    this.setState({
      selectedQuestions,
    });
  };
}

export default QuestionSelect;
