import * as classnames from "classnames";

import { Tag, TagProps, TagItem } from "..";

/**
 * Prop interface
 */
export interface TagCloudProps extends Pick<TagProps, "color" | "opacity"> {
  hasDelete?: TagProps["hasDelete"];
  onDelete?: (tagName: string) => void;
  tagItems: TagItem[];
  className?: string;
}

/**
 * React Component
 */
export default function TagCloud({
  color,
  opacity,
  tagItems = [],
  hasDelete,
  onDelete,
  className,
}: TagCloudProps) {
  const rootStyle = classnames("code-c-tag-cloud tags", {
    [`${className}`]: Boolean(className),
  });

  const tagElements = tagItems.map((item: TagItem, index: number) => {
    const { colorOverride, label, onClick, ...rest } = item;
    function handleClick() {
      if (typeof onClick === "function") {
        onClick();
      }
      if (typeof onDelete === "function") {
        onDelete(label);
      }
    }
    return (
      <Tag
        key={index}
        hasDelete={hasDelete}
        onClick={
          typeof onClick === "function" || typeof onDelete === "function"
            ? handleClick
            : undefined
        }
        color={colorOverride || color}
        {...rest}
      >
        {label}
      </Tag>
    );
  });

  return <div className={rootStyle}>{tagElements}</div>;
}
