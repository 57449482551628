import classNames from "classnames";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";

import { useStoreContext } from "@context";

import { Block, ExamSegmentTags, Msg, PageTitle } from "@shared/components";
import { useWindowSize } from "@shared/hooks";
import {
  useExam,
  useGetSubmissionsReviewers,
  useSubmission,
  useSubmissionRankingDetails,
} from "@shared/hooks/query";
import { breakpoints } from "@shared/hooks/useBreakpoints";
import { isEvaluatedStatus } from "@shared/services/exam";

import { buildDisplayConditionals } from "../SubmissionDetailUtils";
import SubmissionActions from "../submissionActions/SubmissionActions";
import { SubmissionCheatMonitor } from "../submissionCheatMonitor/SubmissionCheatMonitor";
import { SubmissionDetailCustomForm } from "../submissionDetailCustomForm/SubmissionDetailCustomForm";
import {
  SubmissionDetailDeadline,
  SubmissionDetailDeadlineProps,
} from "../submissionDetailDeadline/SubmissionDetailDeadline";
import { SubmissionDetailMemo } from "../submissionDetailMemo/SubmissionDetailMemo";
import { SubmissionDetailReviewSection } from "../submissionDetailReviewSection/SubmissionDetailReviewSection";
import { SubmissionDetailScoreChartSection } from "../submissionDetailScoreChartSection/SubmissionDetailScoreChartSection";
import { SubmissionTimeline } from "../submissionTimeline/SubmissionTimeline";

interface SubmissionDetailTopProps
  extends Pick<SubmissionDetailDeadlineProps, "onChangeDeadlineClick"> {
  examId: number;
  isOpenComment: boolean;
  onEvaluationClick: () => void;
  onReviewEditClick: () => void;
  onManualSubmitClick: () => void;
  onOpenIpAddressModal: () => void;
  canViewActionLog: boolean;
  showActionLogTab: boolean;
  scrollRef?: React.RefObject<HTMLDivElement>;
}

export const SubmissionDetailTop = ({
  examId,
  isOpenComment,
  onChangeDeadlineClick,
  onEvaluationClick,
  onReviewEditClick,
  onManualSubmitClick,
  onOpenIpAddressModal,
  canViewActionLog,
  showActionLogTab,
  scrollRef,
}: SubmissionDetailTopProps) => {
  const {
    data: { examDetail },
  } = useExam();
  const { data: submission } = useSubmission();

  const rootStyle = classNames("code-exam-detail-top");

  const {
    isTierActionAllowed,
    projectId: currentProjectId,
    user: currentUser,
  } = useStoreContext();

  const {
    data: { examReviewers },
  } = useExam();
  const { data: applicantReportDetails } = useSubmissionRankingDetails();

  const {
    data: { pendingReviewers, reviews },
  } = useGetSubmissionsReviewers();
  const { width: screenWidth = 0 } = useWindowSize();

  if (!currentUser) {
    return null;
  }

  const allowReview = examReviewers.some(
    (reviewer) => reviewer.id === currentUser.id,
  );

  const isEvaluated = isEvaluatedStatus(submission.status);
  const submissionDeadline = submission.deadline;

  const {
    showAllReviews,
    showOnlyCurrentReviewer,
    shouldShowEditReviewButton,
  } = buildDisplayConditionals({
    currentUser,
    currentProjectId,
    submission,
    examDetail,
    isTierActionAllowed,
    applicantReportDetails,
    reviews,
    allowReview,
    pendingReviewers,
    isOpenComment,
  });

  const challengeTitles = examDetail.getChallenges().map((challenge) => ({
    id: challenge.id,
    title: challenge.title,
  }));

  return (
    <div className={rootStyle}>
      <BreadcrumbsItem
        to={`/p/${currentProjectId}/exams/${examId}/submissions`}
      >
        <Msg id={"applicantList"} />
      </BreadcrumbsItem>
      <BreadcrumbsItem
        to={`/p/${currentProjectId}/exams/${examId}/submissions/${submission.id}/details`}
      >
        {submission.applicant.email || <Msg id="common.applicant" />}
      </BreadcrumbsItem>
      <Block className="code-exam-detail-top__header-wrapper">
        <div className="code-exam-detail-top__header">
          <PageTitle>
            {examDetail.getName()}
            {examDetail.id && (
              <small className="code-exam-detail-top__exam_id">
                <Msg id={"form.exam.id"} />: {examDetail.id}
              </small>
            )}
          </PageTitle>
        </div>
        {screenWidth > breakpoints.md && (
          <SubmissionActions onManualSubmitClick={onManualSubmitClick} />
        )}
      </Block>
      <ExamSegmentTags />
      {screenWidth <= breakpoints.md && (
        <SubmissionActions
          className="ml-6 mt-2 mb-2"
          onManualSubmitClick={onManualSubmitClick}
        />
      )}
      <div className="code-exam-detail-top__body">
        <div>
          <div className="code-exam-detail-top__panel">
            <SubmissionDetailScoreChartSection
              applicantExamStatus={submission.status}
              applicantFullName={submission.applicant.fullname}
              applicantEmail={submission.applicant.email}
              applicantExperience={
                submission.applicantSegment.applicantExperience
              }
              submissionLanguage={submission.language}
              isSample={submission.isTest}
              hasOverlappingApplicantExams={submission.hasOverlappingApplicantExams()}
              onOverlappingBannerClick={onOpenIpAddressModal}
            />
          </div>
          {canViewActionLog && showActionLogTab && (
            <SubmissionCheatMonitor
              cheatMonitoring={submission.cheatMonitoring}
              overlappingIpAddresses={submission.overlappingIpAddresses}
              challengeInfo={challengeTitles}
              status={examDetail.status}
              onMultiSubmissionDetailsClick={onOpenIpAddressModal}
              actionsTabRef={scrollRef}
            />
          )}
          {(showAllReviews || showOnlyCurrentReviewer) && (
            <div>
              <SubmissionDetailReviewSection
                showOnlyCurrentReviewer={showOnlyCurrentReviewer}
                isEvaluated={isEvaluated}
                shouldShowEditReviewButton={shouldShowEditReviewButton}
                onReviewEditClick={onReviewEditClick}
              />
            </div>
          )}
          <SubmissionDetailCustomForm />
        </div>
        <div className="code-exam-detail-top__side">
          <div className="code-exam-detail-top__side-left">
            <SubmissionTimeline onEvaluationClick={onEvaluationClick} />
            <SubmissionDetailDeadline
              submissionDeadline={submissionDeadline}
              submissionStatus={submission.status}
              onChangeDeadlineClick={onChangeDeadlineClick}
            />
          </div>
          <div>
            <SubmissionDetailMemo />
          </div>
        </div>
      </div>
    </div>
  );
};
