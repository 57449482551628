import classnames from "classnames";
import * as React from "react";

import { useOnClickOutside } from "@shared/hooks";

import Button, { ButtonProps } from "../button/Button";
import { Icon } from "../icon";
import Overlay from "../overlay/Overlay";
import { Portal } from "../portal/Portal";
import { DropdownProvider } from "./useDropdown";

interface TriggerProps extends ButtonProps {
  label?: React.ReactNode;
}
interface DropdownProps {
  children?: React.ReactNode;
  dropdownClassName?: string;
  icon?: string;
  rightIcon?: string;
  disableScrollOnOpen?: boolean;
  placement?: "bottom" | "bottomRight" | "bottomLeft";
  trigger?: TriggerProps;
}

// Fix storybook autodoc issue which require component function is set to a const and need to do the invocation of forwardRef here
const Dropdown = React.forwardRef<HTMLDivElement, DropdownProps>(
  (
    {
      children,
      dropdownClassName,
      icon,
      rightIcon,
      placement = "bottomLeft",
      disableScrollOnOpen,
      trigger,
    },
    ref: React.ForwardedRef<HTMLDivElement>,
  ) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const [isHide, setIsHide] = React.useState(true);
    const [inset, setInset] =
      React.useState<React.CSSProperties["inset"]>("auto");
    const triggerRef = React.useRef<HTMLDivElement>(null);
    const dropdownRef = useOnClickOutside<HTMLDivElement>(
      (event: MouseEvent) => {
        if (triggerRef.current?.contains(event.target as Node)) {
          return;
        }

        if (isOpen) {
          setIsOpen(false);
        }
      },
    );
    const hiddenTimer = React.useRef<NodeJS.Timeout>();
    React.useImperativeHandle(ref, () => triggerRef.current as HTMLDivElement);
    const triggerStyles = classnames(
      "code-c-dropdown__trigger",
      trigger?.className,
    );
    const dropdownStyles = classnames("code-c-dropdown", dropdownClassName, {
      active: isOpen,
      hidden: isHide,
    });
    const rect = triggerRef.current?.getBoundingClientRect() || {
      bottom: 0,
      left: 0,
      right: 0,
      top: 0,
      width: 0,
    };
    const portalStyle: React.CSSProperties = {
      inset,
    };

    const handleTriggerClick = () => {
      if (isOpen) {
        setIsOpen(false);
        return;
      }

      setIsOpen(true);
      setIsHide(false);
    };

    React.useLayoutEffect(() => {
      if (!isOpen) {
        return;
      }

      const yAxis = rect.bottom + window.scrollY;
      switch (placement) {
        case "bottomRight":
          setInset(
            `${yAxis + 4}px auto auto ${
              rect.right -
              (dropdownRef.current?.getBoundingClientRect().width || 0)
            }px`,
          );
          break;
        case "bottomLeft":
          setInset(`${yAxis + 4}px auto auto ${rect.left}px`);
          break;
        default:
          setInset("auto");
          break;
      }
    }, [dropdownRef, isOpen, placement, rect.bottom, rect.left, rect.right]);

    const { label: triggerLabel, ...triggerButtonProps } = trigger || {};

    // Hide dropdown after out animation end
    React.useLayoutEffect(() => {
      if (isOpen) {
        clearTimeout(hiddenTimer.current);
        return;
      }

      hiddenTimer.current = setTimeout(() => {
        setIsHide(true);
      }, 250);

      return () => {
        clearTimeout(hiddenTimer.current);
      };
    }, [isOpen, triggerLabel]);

    const onItemClick = () => {
      setIsOpen(false);
    };

    return (
      <DropdownProvider onItemClick={onItemClick}>
        <div ref={triggerRef} className="code-c-dropdown__overlay">
          <Button
            className={triggerStyles}
            onClick={handleTriggerClick}
            {...triggerButtonProps}
          >
            {icon && <Icon type={icon} />}
            {typeof triggerLabel === "string" ? (
              <span>{triggerLabel}</span>
            ) : (
              triggerLabel
            )}
            {rightIcon && <Icon type={rightIcon} />}
          </Button>
          <Portal>
            <div
              ref={dropdownRef}
              className={dropdownStyles}
              style={portalStyle}
            >
              {children}
            </div>
            {disableScrollOnOpen && isOpen && (
              <Overlay isOpen onClick={() => setIsOpen(false)} />
            )}
          </Portal>
        </div>
      </DropdownProvider>
    );
  },
);

Dropdown.displayName = "Dropdown";

export default Dropdown;
