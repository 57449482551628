import * as classnames from "classnames";

import { InvitationStatus, UserStatus } from "@shared/services/enums";

import { Avatar, Tag } from "..";
import { MemberListModel } from "../../models";

/**
 * Prop interface
 */
export interface ReviewerRowProps {
  className?: string;
  children?: {};
  member?: MemberListModel;
}

/**
 * React Component
 */
export default function ReviewerRow({
  className,
  member = {} as MemberListModel,
}: ReviewerRowProps) {
  const rootStyle = classnames("code-c-reviewer-row", {
    [`${className}`]: Boolean(className),
  });
  const tagColor =
    member.status === UserStatus.Expired ||
    member.invitationStatus === InvitationStatus.Expired
      ? "gray-darkest"
      : "gray";
  const { displayStatus, name } = member;

  return (
    <div className={rootStyle}>
      <Avatar src={member.imageUrl} />
      <div className="code-c-reviewer__row-name">
        {displayStatus && <Tag color={tagColor}>{displayStatus}</Tag>}
        <span>{name}</span>
      </div>
    </div>
  );
}
