import { useQueryClient } from "@tanstack/react-query";
import * as React from "react";

import { useStoreContext } from "@context";

import { enumKeys } from "@shared/hooks/query";

// TODO short term solution, remove this component after migrate user state to react query
export default function OrgBackgroundQueries() {
  const client = useQueryClient();
  const { user } = useStoreContext();

  React.useEffect(() => {
    if (user?.language) {
      client.invalidateQueries(enumKeys.all);
    }
  }, [client, user?.language]);

  return <></>;
}
