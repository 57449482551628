import { useQuery } from "@tanstack/react-query";
import { useCallback } from "react";

import { AppConfigResponse, getAppConfig } from "@api/app";

import { useStoreContext } from "@context";

interface AppConfig {
  webcamEnabled?: boolean;
  submissionNote: boolean;
  talentHub?: boolean;
}

export const featureFlagKeys = {
  all: ["app", "flags"] as const,
};

interface UseFeatureFlagsProps {
  examId?: number;
}

export function useFeatureFlags(props?: UseFeatureFlagsProps) {
  const { examId } = props || {};
  const { user, applicantExam } = useStoreContext();
  const enabled = Boolean(examId || user || applicantExam);
  const normalizedFlagProfiles = useAccessControl();

  const query = useQuery({
    queryKey: featureFlagKeys.all,
    queryFn: async ({ signal }) => {
      const { result } = await getAppConfig({ signal });
      return result;
    },
    placeholderData: {} as AppConfigResponse,
    select: useCallback(
      (data) => {
        const normalizedData = normalizedFlagProfiles(data, { examId });
        return {
          webcamEnabled: normalizedData.webcamEnabled?.enabled,
          submissionNote: normalizedData.submissionNote?.enabled,
          talentHub: normalizedData.talentHub?.enabled,
        } as AppConfig;
      },
      [normalizedFlagProfiles, examId],
    ),
    enabled,
  });

  return query;
}

function useAccessControl() {
  const { user: { organizationId: userOrganizationId } = {}, applicantExam } =
    useStoreContext();

  const organizationId =
    userOrganizationId || applicantExam?.applicant?.organizationId;

  return (data: AppConfigResponse, { examId }: { examId?: number }) => {
    if (!organizationId && !examId) {
      return data;
    }

    return Object.entries(data).reduce((acc, [key, value]) => {
      const { enabled, organizationIdList, guestExamIdList, ...rest } = value;
      const profile = { ...rest };
      profile.enabled = enabled;

      // If the organizationId is set, it means that the feature is enabled for the target organizations
      // Otherwise, it is algin with the toggle state
      if (organizationId) {
        profile.enabled = organizationIdList
          ? organizationIdList.includes(organizationId)
          : enabled;
      }

      if (examId) {
        profile.enabled = guestExamIdList
          ? guestExamIdList.includes(examId)
          : enabled;
      }

      acc[key as keyof AppConfigResponse] = profile;

      return acc;
    }, {} as AppConfigResponse);
  };
}
