import * as classnames from "classnames";

import { Difficulty as DifficultyEnum } from "@shared/services/enums";

import { Tag } from "../..";

/**
 * Prop interface
 */
export interface DifficultyTagProps {
  value?: number;
  className?: string;
  hasError?: boolean;
  onClick?: () => void;
}

/**
 * React Component
 */
export function DifficultyTag({
  value = DifficultyEnum.Easy,
  className,
  hasError = false,
  onClick,
}: DifficultyTagProps) {
  const rootStyle = classnames("code-c-difficulty-tag", {
    [`${className}`]: Boolean(className),
  });

  return (
    <div className={rootStyle}>
      <Tag
        className={`code-c-difficulty-tag__${value}`}
        onClick={onClick}
        hasError={hasError}
      >
        {DifficultyEnum.toString(value)}
      </Tag>
    </div>
  );
}
