import { Button, CustomFormDisplay, Msg } from "@shared/components";
import { CustomFormDefinitionType } from "@shared/services/enums";

interface OverlappingIpCountDisplayProps {
  totalOverlappingIpCount: number;
  onMultiSubmissionDetailsClick?: () => void;
}

export const OverlappingIpCountDisplay = ({
  totalOverlappingIpCount,
  onMultiSubmissionDetailsClick,
}: OverlappingIpCountDisplayProps) => (
  <CustomFormDisplay
    key="overlappingIp"
    formValue={{
      key: "overlappingIp",
      displayName: (
        <Msg id="submission.submissionDetail.cheatMonitor.multiSubmission" />
      ),
      dataType: CustomFormDefinitionType.Text,
    }}
  >
    <div className="code-c-submission-detail__cheat-monitor-sameip-info">
      <span>
        {`${totalOverlappingIpCount} `}
        <Msg
          id={
            totalOverlappingIpCount > 1
              ? "submission.submissionDetail.cheatMonitor.fromSameIp_plural"
              : "submission.submissionDetail.cheatMonitor.fromSameIp"
          }
        />
      </span>
      {typeof onMultiSubmissionDetailsClick === "function" && (
        <Button
          type="secondary"
          size="small"
          shrink
          onClick={onMultiSubmissionDetailsClick}
          className="code-c-submission-detail__cheat-monitor-sameip-info-button"
        >
          <Msg id="details" />
        </Button>
      )}
    </div>
  </CustomFormDisplay>
);
