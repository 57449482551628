import * as classnames from "classnames";
import { isEqual } from "lodash";
import * as React from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { Prompt } from "react-router";

import { Modal, Icon, Msg } from "@shared/components";
import { ExamModel, CustomFormDefinitionModel } from "@shared/models";
import { ExamDeliveryKind } from "@shared/services/enums";
import MSG from "@shared/services/message";

import { ExamForms, ExamDetailHeader } from "../../../examSections";
import { ExamEditSaveArea } from "../examEditSaveArea/ExamEditSaveArea";

export interface ExamDetailFormsProps {
  examDetail: ExamModel;
  examDetailForms: CustomFormDefinitionModel[];
  updateExamForms: (payload: {}) => void;
}

export interface ExamDetailFormsState {
  editing: boolean;
  formValid: boolean;
  formValues: {};
  isFormAlertModalOpen: boolean;
  oldFormValues: {};
  saving: boolean;
}

class ExamDetailForms extends React.Component<
  ExamDetailFormsProps,
  ExamDetailFormsState
> {
  constructor(props: ExamDetailFormsProps) {
    super(props);

    this.state = {
      editing: false,
      formValid: false,
      formValues: {},
      isFormAlertModalOpen: false,
      oldFormValues: {},
      saving: false,
    };
  }

  public render() {
    const rootStyle = classnames("code-exam-edit__detail_forms", {
      "code-exam-detail-is-editing": this.state.editing,
    });
    const { examDetail, examDetailForms } = this.props;
    const pageTitle = MSG.getMessageByKey("exam.entryForm");

    return (
      <div className={rootStyle}>
        <BreadcrumbsItem
          to={`/p/${examDetail.projectId}/exams/${examDetail.id}/form`}
        >
          {pageTitle}
        </BreadcrumbsItem>
        <Prompt
          when={this.state.editing}
          message={MSG.getMessageByKey("navigation.confirm")}
        />
        <ExamDetailHeader
          archived={Boolean(examDetail && examDetail.isArchived())}
          editing={this.state.editing}
          onClickCancel={this.onCancel}
          onClickEdit={this.onClickEdit}
          title={pageTitle}
          examId={examDetail.id}
        />
        <ExamForms
          hideTitle
          examLanguage={examDetail?.language}
          examType={examDetail?.examType}
          readOnly={!this.state.editing}
          initialValues={examDetailForms}
          applicantNameRequired={
            examDetail ? examDetail.applicantNameRequired : true
          }
          onFormChange={this.onFormChange}
        />
        {this.state.editing && (
          <ExamEditSaveArea
            onSaveClick={this.handleUpdate}
            disabled={!this.state.formValid}
          />
        )}
        {this.state.isFormAlertModalOpen && (
          <Modal
            className="form-alert-modal"
            isOpen={this.state.isFormAlertModalOpen}
            title={MSG.getMessageByKey("editExam")}
            onClickOk={this.onClickUpdate}
            onClickCancel={this.closeFormAlertModal}
            onClose={this.closeFormAlertModal}
            ariaLabel="ID Delivery Confirmation"
          >
            <Icon type="exclamation-triangle" size="medium" />
            <Msg id="editExam.confirm.applicantNameRequiredWithIdDelivery.entryForm" />
          </Modal>
        )}
      </div>
    );
  }

  private closeFormAlertModal = () => {
    this.setState({
      isFormAlertModalOpen: false,
    });
  };

  private onCancel = () => {
    this.setState({
      editing: false,
      saving: false,
      formValues: { ...this.state.oldFormValues },
    });
  };

  private onClickEdit = () => {
    this.setState({
      editing: !this.state.editing,
      saving: this.state.editing,
      oldFormValues: { ...this.state.formValues },
    });
  };

  private handleUpdate = () => {
    const { formDefs = { applicantNameRequired: true } } = this.state
      .formValues as { formDefs: { applicantNameRequired: boolean } };

    if (
      this.props.examDetail?.deliveryKind === ExamDeliveryKind.ID &&
      formDefs.applicantNameRequired
    ) {
      this.setState({
        isFormAlertModalOpen: true,
      });
    } else {
      this.props.updateExamForms(this.state.formValues);
      this.onClickEdit();
    }
  };

  private onClickUpdate = () => {
    this.closeFormAlertModal();
    this.props.updateExamForms(this.state.formValues);
    this.onClickEdit();
  };

  private onFormChange = (
    formValid: boolean,
    formValues: { formDefinitions: {}; applicantNameRequired: boolean },
  ) => {
    const valid =
      formValid &&
      (!isEqual(formValues.formDefinitions, this.props.examDetailForms) ||
        formValues.applicantNameRequired !==
          (this.props.examDetail &&
            this.props.examDetail.applicantNameRequired));

    this.setState({
      formValid: valid,
      formValues,
    });
  };
}

export default ExamDetailForms;
