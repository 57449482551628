import {
  Icon,
  IconButton,
  LinkScroll,
  Msg,
  ProgressBar,
} from "@shared/components";
import {
  SubmissionTimeline,
  SubmissionTimelineType,
} from "@shared/hooks/query";

import SubmissionTimelineDetail from "../submissionTimelineDetail/SubmissionTimelineDetail";
import SubmissionTimelineHeader from "../submissionTimelineHeader/SubmissionTimelineHeader";

interface SubmissionTimelineRowProps extends SubmissionTimeline {
  formattedDateTime: string;
  show?: boolean;
  isProjectAdmin?: boolean;
  onEvaluationClick?: () => void;
}

export function SubmissionTimelineRow({
  body,
  formattedDateTime,
  show,
  isProjectAdmin,
  titleId,
  onEvaluationClick,
}: SubmissionTimelineRowProps) {
  const renderContent = (
    value: string | number,
    type?: SubmissionTimelineType,
  ) => {
    switch (type) {
      case "progress":
        const percent = Number(value);
        return (
          <ProgressBar
            percent={percent}
            color={percent === 100 ? "primary" : "neutral"}
          />
        );
      case "to-report": {
        return (
          <LinkScroll
            className="code-submission-timeline-item__link"
            href="#result-detail"
          >
            <Msg id="submission.viewDetail" />
          </LinkScroll>
        );
      }
      case "evaluation":
        return (
          <>
            <Msg
              tagName="div"
              id={`${value}`}
              className="code-submission-timeline-item__default-content"
            />
            {isProjectAdmin && (
              <IconButton aria-label="evaluation" onClick={onEvaluationClick}>
                <Icon type="edit" className="code-c-icon__text-base" />
              </IconButton>
            )}
          </>
        );
      case "evaluation-by":
        return (
          <div className="text-sm has-text-neutral-500">
            <Icon type="wand-magic-sparkles" />
            <span>{value}</span>
          </div>
        );
      default:
        return value;
    }
  };

  return (
    <>
      <SubmissionTimelineHeader
        titleId={titleId}
        dateTime={formattedDateTime}
        show={show}
      />
      <SubmissionTimelineDetail show={show}>
        {body.map(
          ({ align, labelId, value, tooltip, type, withColon }, order) => {
            return (
              <SubmissionTimelineDetail.ListItem
                key={order}
                align={align}
                colon={withColon}
                labelId={labelId}
                tooltip={tooltip}
              >
                {renderContent(value, type)}
              </SubmissionTimelineDetail.ListItem>
            );
          },
        )}
      </SubmissionTimelineDetail>
    </>
  );
}
