import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Route, Switch } from "react-router-dom";

import { LocalSignIn } from "@components/common/auth/localSignIn";

import { getDefaultSignInPathname } from "@shared/services/url";

import Registration from "../../components/common/auth/registration/Registration.connect";
import Signin from "../../components/common/auth/signin/Signin";
import { RootState } from "../../reducers";
import { extractSourceURL } from "../../shared/services/urlParser";

interface OwnProps {
  authenticated: boolean;
}
interface ParentProps {
  routerLocation: { state?: { from: string | { pathname: string } } };
}

type GuestRoutesProps = OwnProps & ParentProps;

/**
 * Route setting
 */
const GuestRoutes = ({ authenticated, routerLocation }: GuestRoutesProps) => {
  const directTo = extractSourceURL(routerLocation.state);
  return authenticated ? (
    <Switch>
      <Redirect to={directTo} />
    </Switch>
  ) : (
    <Switch>
      <Route
        path="/auth/invitation/:token"
        exact={true}
        render={(props: { match: { params: { token: string } } }) => (
          <Registration token={props.match.params.token} />
        )}
      />
      <Route path="/auth/signin" exact={true} component={Signin} />
      <Route
        path="/auth/sa/signin"
        exact={true}
        render={() => <Signin isSystemAdmin={true} />}
      />
      <Route
        path="/auth/error"
        render={({ location }) => (
          <Redirect
            to={{
              pathname: "/auth/signin",
              search: location.search,
            }}
          />
        )}
      />
      <Route
        path="/auth/sa/error"
        render={({ location }) => (
          <Redirect
            to={{
              pathname: "/auth/sa/signin",
              search: location.search,
            }}
          />
        )}
      />
      {process.env.REACT_APP_ENABLE_LOCAL_SIGNIN_FORM && (
        <Route
          path="/auth/local/signin"
          exact={true}
          render={() => <LocalSignIn />}
        />
      )}
      <Redirect to={getDefaultSignInPathname(window.location.hostname)} />
    </Switch>
  );
};

const mapStateToProps = (state: RootState, props: ParentProps) => ({
  authenticated: state.auth.authenticated,
  ...props,
});

export default connect(mapStateToProps, () => ({}))(GuestRoutes);
