import * as classnames from "classnames";
import * as React from "react";

import { Button, Icon, Tooltip } from "@shared/components";
import { useOnClickOutside } from "@shared/hooks";

/**
 *
 */
export interface ActionLogFilterSelectItem {
  value?: number | string;
  label: React.ReactNode;
  disabled?: boolean;
  withDivider?: boolean;
  showTooltip?: boolean;
}

/**
 * Prop interface
 */
export interface ActionLogFilterSelectProps {
  options: ActionLogFilterSelectItem[];
  multiple?: boolean;
  className?: string;
  triggerClassName?: string;
  disabled?: boolean;
  name?: string;
  onSelect?: (item: ActionLogFilterSelectItem) => void;
  value?: number | string | (number | string)[];
  placeholder?: React.ReactNode;
  ariaLabel?: string;
}

function ActionLogFilterSelect({
  className,
  triggerClassName,
  multiple,
  placeholder,
  value,
  options,
  name,
  ariaLabel,
  onSelect,
  disabled,
}: ActionLogFilterSelectProps) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [activeIndex, setActiveIndex] = React.useState<number>(-1);
  const rootRef = useOnClickOutside<HTMLDivElement>(() => {
    if (isOpen) {
      setIsOpen(false);
    }
  });

  const rootStyle = classnames("code-c-action-log-select", "dropdown", {
    "is-active": isOpen,
    "is-multiple": multiple,
    "is-single": !multiple,
    [`${className}`]: Boolean(className),
  });

  const triggerStyle = classnames("dropdown-trigger", {
    [`${triggerClassName}`]: Boolean(triggerClassName),
  });

  const onSelectItem = (item: ActionLogFilterSelectItem) => {
    if (!item.disabled) {
      onSelect?.(item);
      if (!multiple) {
        onToggle();
      }
    }
  };

  const onToggle = () => {
    setIsOpen(!isOpen);
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    const key = e.key;
    if (isOpen) {
      let newActiveIndex = activeIndex;
      switch (key) {
        case "ArrowDown":
          newActiveIndex = Math.min(options.length - 1, activeIndex + 1);
          e.preventDefault();
          break;
        case "ArrowUp":
          newActiveIndex = Math.max(0, activeIndex - 1);
          e.preventDefault();
          break;
        case "Enter":
        case " ":
          onSelectItem(options[activeIndex]);
          e.preventDefault();
          break;
        case "Escape":
        case "Esc":
          setIsOpen(false);
          e.preventDefault();
          break;
        default:
          break;
      }
      setActiveIndex(newActiveIndex);
    } else {
      switch (key) {
        case "ArrowDown":
          setIsOpen(true);
          e.preventDefault();
          break;
        default:
          break;
      }
    }
  };

  const optionElements =
    options?.map((item: ActionLogFilterSelectItem, index: number) => {
      const checked =
        multiple && item.value
          ? ((value as (number | string)[]) || []).includes(item.value)
          : value === item.value;
      const dropdownItemClassNames = classnames("dropdown-item", {
        "is-disabled": Boolean(item.disabled),
        "is-active": activeIndex === index,
      });
      const checkIconClassName = classnames({ hidden: !checked });
      return (
        <React.Fragment key={index}>
          <Tooltip
            text={item.label}
            placement="right-start"
            maxSize="large"
            disabled={!item.showTooltip}
            key={index}
          >
            <li
              className={dropdownItemClassNames}
              key={index}
              role="option"
              aria-selected={activeIndex === index}
            >
              <label htmlFor={`${item.value}`}>
                {!multiple && (
                  <Icon type="check" className={checkIconClassName}></Icon>
                )}
                <input
                  type={multiple ? "checkbox" : "radio"}
                  id={`${item.value}`}
                  value={`${item.value}`}
                  name={name}
                  disabled={Boolean(item.disabled)}
                  checked={checked}
                  onChange={() => {
                    onSelectItem(item);
                  }}
                />
                {item.label}
              </label>
            </li>
          </Tooltip>
          {item.withDivider && <hr className="dropdown-divider" />}
        </React.Fragment>
      );
    }) || [];

  const currentLabel = multiple
    ? placeholder
    : options.find((item: ActionLogFilterSelectItem) => item.value === value)
        ?.label || placeholder;

  return (
    <div ref={rootRef} className={rootStyle}>
      <div className={triggerStyle}>
        <Button
          aria-label={ariaLabel}
          onClick={onToggle}
          aria-pressed={isOpen}
          disabled={disabled}
          onKeyDown={onKeyDown}
          role="combobox"
        >
          <span className="current-label">{currentLabel}</span>
          <Icon type={isOpen ? "angle-up" : "angle-down"}></Icon>
        </Button>
      </div>
      <div className="dropdown-menu">
        <ul
          className="dropdown-content"
          role="listbox"
          tabIndex={-1}
          aria-multiselectable={multiple}
        >
          {optionElements}
        </ul>
      </div>
    </div>
  );
}

export default ActionLogFilterSelect;
