import actionCreator from "../shared/services/actionCreator";

export const challengeUpdateAction = actionCreator("challengeUpdateAction");
export const refreshChallengeListAction = actionCreator(
  "refreshChallengeListAction",
);
export const challengeCopyAction = actionCreator("challengeCopyAction");
/**
 * @deprecated Please use useGetChallengeInsight hook instead
 */
export const challengeInsightGetAction = actionCreator(
  "challengeInsightGetAction",
);
export const challengeListGetAction = actionCreator("challengeListGetAction");

export const challengeGetAction = actionCreator("challengeGetAction");

export const challengeFiltersGetAction = actionCreator(
  "challengeFiltersGetAction",
);

export const challengeCurrentVersionSetAction = actionCreator(
  "challengeCurrentVersionSetAction",
);
export const challengeReleaseNoteGetAction = actionCreator(
  "challengeReleaseNoteGetAction",
);
export const challengeMajorUpdateAction = actionCreator(
  "challengeMajorUpdateAction",
);
export const pinnedChallengeListUpdateAction = actionCreator(
  "pinnedChallengeListUpdateAction",
);
export const pinnedChallengeUpdateAction = actionCreator(
  "pinnedChallengeUpdateAction",
);

export const challengeCollectionListGetAction = actionCreator(
  "challengeCollectionListGetAction",
);
export const challengeCollectionGetAction = actionCreator(
  "challengeCollectionGetAction",
);
