// Note: This is the old version of Textarea component for use with the old Form component.
// There is a new Textarea component for use with ReactHookForm in rfcComponents.
import * as classnames from "classnames";
import { isEqual } from "lodash";
import * as React from "react";

import { ValidationMessage, hasValidationError } from "..";

/**
 * Prop interface
 */
export interface TextareaProps {
  ariaLabel?: string;
  counter?: React.ReactNode;
  id?: string;
  name?: string;
  value?: string;
  disabled?: boolean;
  readOnly?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur?: (e: React.FormEvent<HTMLTextAreaElement>) => void;
  onClick?: (e: React.FormEvent<HTMLTextAreaElement>) => void;
  className?: string;
  textareaClassName?: string;
  error?: { [key: string]: string };
  placeholder?: string;
}

/**
 * React Component
 */
export default class Textarea extends React.Component<TextareaProps> {
  public textarea: HTMLTextAreaElement | null;

  constructor(props: TextareaProps) {
    super(props);
  }

  public componentDidMount() {
    this.resize();
  }

  public componentDidUpdate() {
    this.resize();
  }

  public shouldComponentUpdate(nextProps: TextareaProps) {
    return !isEqual(this.props, nextProps);
  }

  public render() {
    const {
      ariaLabel,
      counter,
      id,
      name,
      value,
      disabled,
      readOnly,
      onBlur,
      onClick,
      className,
      textareaClassName,
      error,
      placeholder,
    } = this.props;

    const rootStyle = classnames("code-c-textarea", "control", {
      [`${className}`]: Boolean(className),
    });

    const textareaStyle = classnames("textarea", {
      [`${textareaClassName}`]: Boolean(textareaClassName),
      "is-danger": hasValidationError(name, error),
    });

    return (
      <div className={rootStyle}>
        <textarea
          aria-label={ariaLabel}
          id={id}
          name={name}
          value={value}
          disabled={disabled}
          readOnly={readOnly}
          onChange={this.onChange}
          onBlur={onBlur}
          onClick={onClick}
          className={textareaStyle}
          placeholder={placeholder}
          ref={(textarea) => (this.textarea = textarea)}
        />
        <div className="code-c-textarea__footer">
          <ValidationMessage name={name} error={error} />
          {counter}
        </div>
      </div>
    );
  }

  public focus = () => {
    if (this.textarea) {
      this.textarea.focus();
    }
  };

  public getSelection = () => {
    if (this.textarea) {
      return [this.textarea.selectionStart, this.textarea.selectionEnd];
    } else {
      return [0, 0];
    }
  };

  public setSelection = (start: number, end: number) => {
    if (this.textarea) {
      this.textarea.selectionStart = start;
      this.textarea.selectionEnd = end;
    }
  };

  private resize = () => {
    const element = this.textarea;

    if (element) {
      element.style.height = element.scrollHeight + "px";
    }
  };

  /**
   * Note: This will only work if the TextArea element is inside a Form component
   * hence the props name, value and type
   */
  public setValue = (text = "") => {
    if (this.textarea) {
      this.props.onChange?.({
        target: {
          name: this.props.name,
          value: text,
          type: "textarea",
        },
      } as any);
    }
  };

  private onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.resize();

    if (this.props.onChange) {
      this.props.onChange(e);
    }
  };
}
