import { connect } from "react-redux";
import { Action, Dispatch } from "redux";

import { challengeCollectionListGetAction } from "@actions";

import { RootState } from "@reducers";

import { getTierAllowedByRedux } from "@shared/services/tier";

import { ChallengeCollection, ExternalProps } from "./ChallengeCollection";

const mapStateToProps = (state: RootState, props: ExternalProps) => {
  return {
    language: state.intl.locale,
    challengeCollectionList: state.challenge.challengeCollectionList,
    challengeCollectionLoading: state.challenge.challengeCollectionLoading,
    isTierActionAllowed: getTierAllowedByRedux(state),
    ...props,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  getChallengeCollectionList: (conditions?: {}) => {
    dispatch(challengeCollectionListGetAction.request(conditions));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ChallengeCollection);
