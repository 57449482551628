import * as classnames from "classnames";
import * as React from "react";

import { ProjectSwitchItemModel } from "@shared/models";
import { ApplicationType } from "@shared/services/enums";
import History from "@shared/services/history";

import { Icon, Button, Overlay } from "..";

/**
 * Prop interface
 */
export interface ProjectSwitchProps {
  projects: ProjectSwitchItemModel[];
  currentProjectId: number;
  className?: string;
}

/**
 * State interface
 */
export interface ProjectSwitchState {
  isOpen: boolean;
}

/**
 * React Component
 */
export default function ProjectSwitch({
  className,
  currentProjectId,
  projects,
}: ProjectSwitchProps) {
  const [isOpen, setIsOpen] = React.useState(false);

  if (!projects || projects.length <= 1) {
    return null;
  }

  const rootStyle = classnames("code-c-project-switch", "dropdown", className, {
    "is-active": isOpen,
  });
  const currentProject = (projects || []).find(
    ({ id }) => id === currentProjectId,
  );
  const currentProjectName = currentProject?.name ?? "Invalid Project ID";

  const onToggle = () => {
    setIsOpen(!isOpen);
  };

  const onChange =
    (payload: { projectId: number; applicationType: ApplicationType }) =>
    () => {
      onToggle();
      History.push(`/p/${payload.projectId}`);
    };

  return (
    <div className={rootStyle} aria-expanded={isOpen}>
      <div className="dropdown-trigger">
        <Button
          onClick={onToggle}
          ariaLabel="Project Switcher"
          ariaPressed={isOpen}
        >
          <span>{currentProjectName}</span>
          <Icon type="angle-down" />
        </Button>
      </div>
      <div className="dropdown-menu" role="menu" aria-hidden={!isOpen}>
        <div className="dropdown-content">
          {projects.map((project, index) => (
            <a
              role="menuitem"
              className="dropdown-item"
              key={index}
              onClick={onChange({
                projectId: project.id,
                applicationType: project.applicationType,
              })}
            >
              {project.name}
            </a>
          ))}
        </div>
      </div>
      <Overlay isOpen={isOpen} onClick={onToggle} />
    </div>
  );
}
