import {
  Action,
  APIResponseAction,
  reportScoresAction,
  reportChallengeAction,
  reportApplicantListFirstAction,
  reportApplicantListAction,
  reportRankingAction,
  reportResultsAction,
  reportCategoriesAction,
  reportEvaluationPointAction,
} from "../actions";
import {
  EnumModel,
  SubmissionListModel,
  ReportChallengeModel,
  ApplicantReportModel,
  ReportExamScore,
  ApplicantReportChallengeModel,
  ApplicantReportCategoryScoresModel,
  SubmissionReportChallengeModel,
  SubmissionReportChallengeCategoryModel,
  ApplicantReportEvaluationPointScoresModel,
} from "../shared/models";
import { ApplicantExamStatus, ChallengeStyle } from "../shared/services/enums";
import { getEvaluationPoints } from "../shared/services/evaluationPoint";

export interface ReportState {
  scoresLoading: boolean;
  scoresLoadingError: boolean;
  scores: Array<ReportExamScore>;

  challengesLoading: boolean;
  challengesLoadingError: boolean;
  challenges: Array<ReportChallengeModel>;

  applicantsLoading: boolean;
  applicantsLoadingError: boolean;
  applicants: Array<SubmissionListModel>;

  applicantDetailsLoading: boolean;
  applicantDetailsLoadingError: boolean;
  applicantDetails?: ApplicantReportModel;

  applicantChallengesLoading: boolean;
  applicantChallengesLoadingError: boolean;
  applicantChallenges: Array<ApplicantReportChallengeModel>;

  challengeCategoriesLoading: boolean;
  challengeCategoriesLoadingError: boolean;
  challengeCategories: Array<ApplicantReportCategoryScoresModel>;

  submissionEvaluationPointsLoading: boolean;
  submissionEvaluationPointsError: boolean;
  submissionEvaluationPoints: Array<ApplicantReportEvaluationPointScoresModel>;
}

const initialState = {
  scoresLoading: false,
  scoresLoadingError: false,
  scores: [],

  challengesLoading: false,
  challengesLoadingError: false,
  challenges: [],

  applicantsLoading: false,
  applicantsLoadingError: false,
  applicants: [],

  applicantDetailsLoading: false,
  applicantDetailsLoadingError: false,
  applicantDetails: undefined,

  applicantChallengesLoading: false,
  applicantChallengesLoadingError: false,
  applicantChallenges: [],

  challengeCategoriesLoading: false,
  challengeCategoriesLoadingError: false,
  challengeCategories: [],

  submissionEvaluationPointsLoading: false,
  submissionEvaluationPointsError: false,
  submissionEvaluationPoints: [],
};

export const challengeSelector = (
  state: ReportState,
  categoryNames: EnumModel[],
) => {
  if (!state.applicantDetails) {
    return [];
  }

  const categoryMap = categoryNames.reduce((mapped, category) => {
    mapped[category.value] = category;
    return mapped;
  }, {});

  return state.challenges.map((challenge) => {
    const applicantChallengeData =
      state.applicantChallenges.find(
        (c) => c.challengeId === challenge.challengeId,
      ) || new ApplicantReportChallengeModel();

    let categories: SubmissionReportChallengeCategoryModel[] = [];

    // category business
    if (challenge.style === ChallengeStyle.Quiz) {
      categories = state.challengeCategories
        .filter(
          (category) =>
            category.challengeId === challenge.challengeId &&
            categoryMap[category.category],
        )
        .map((category) => {
          return new SubmissionReportChallengeCategoryModel({
            title: categoryMap[category.category].displayString,
            description: categoryMap[category.category].descriptionString,
            applicantScore: category.applicantCorrectAnswers,
            maximumScore: category.totalQuestionsNumber,
            examAverageScore: category.examAverageScore,
            systemAverageScore: category.globalAverageScore,
          });
        });
    } else {
      const { evaluationPoints } = getEvaluationPoints(
        applicantChallengeData.testOutput, // Note: Deprecated, but still exists for some exams
        applicantChallengeData.testOutputJson ||
          applicantChallengeData.testcasesJson, // NOTE: testOutputJson is the correct one that is used by most apis, but some apis use testcasesJson
        applicantChallengeData.evaluationPoints,
      );
      categories = evaluationPoints.map((evaluationPoint) => {
        const evaluationPointAverages = state.submissionEvaluationPoints.find(
          (evalPointAv) =>
            evalPointAv.challengeId === challenge.challengeId &&
            evalPointAv.evaluationPoint === evaluationPoint.title,
        ) || { examAverageScore: undefined, systemAverageScore: undefined };

        return new SubmissionReportChallengeCategoryModel({
          title: evaluationPoint.title,
          description: evaluationPoint.description,
          applicantScore: evaluationPoint.testCases.filter(
            (testCase) => testCase.passed,
          ).length,
          maximumScore: evaluationPoint.testCases.length,
          examAverageScore: evaluationPointAverages.examAverageScore,
          systemAverageScore: evaluationPointAverages.systemAverageScore,
        });
      });
    }

    return new SubmissionReportChallengeModel({
      examId: challenge.examId,
      challengeId: challenge.challengeId,
      title: challenge.title,
      style: challenge.style,
      difficulty: challenge.difficulty,
      examAverageScore: challenge.examScopeScores.average,
      examMaxScore: challenge.examScopeScores.maxScore,
      examStandardDeviation: challenge.examScopeScores.standardDeviation,
      examScoreDevValue: challenge.examScopeScores.scoreDevValue,
      systemAverageScore: challenge.systemScopeScores.average,
      systemMaxScore: challenge.systemScopeScores.maxScore,
      systemStandardDeviation: challenge.systemScopeScores.standardDeviation,
      systemScoreDevValue: challenge.systemScopeScores.scoreDevValue,

      score: applicantChallengeData.score,
      timeSpentSeconds: applicantChallengeData.timeSpentSeconds,

      categories,
    });
  });
};

export const reportReducer = (
  state: ReportState = initialState,
  action: Action,
): ReportState => {
  const payload = (action as APIResponseAction).payload || {};

  switch (action.type) {
    case reportScoresAction.types.request:
      return {
        ...state,
        scoresLoading: true,
        scoresLoadingError: false,
        scores: [],
      };
    case reportScoresAction.types.success:
      return {
        ...state,
        scoresLoading: false,
        scores: (payload.result as Array<ReportExamScore>) || [],
      };
    case reportScoresAction.types.failure:
      return {
        ...state,
        scoresLoading: false,
        scoresLoadingError: true,
      };

    case reportChallengeAction.types.request:
      return {
        ...state,
        challengesLoading: true,
        challengesLoadingError: false,
        challenges: [],
      };
    case reportChallengeAction.types.success:
      return {
        ...state,
        challengesLoading: false,
        challenges: ((payload.result as Array<{}>) || []).map(
          (result) => new ReportChallengeModel(result),
        ),
      };
    case reportChallengeAction.types.failure:
      return {
        ...state,
        challengesLoading: false,
        challengesLoadingError: true,
      };

    case reportApplicantListFirstAction.types.request:
      return {
        ...state,
        applicantsLoading: true,
        applicantsLoadingError: false,
        applicants: [],
      };
    case reportApplicantListFirstAction.types.success:
      return {
        ...state,
        applicantsLoading: false,
      };
    case reportApplicantListAction.types.request:
      return {
        ...state,
        applicantsLoading: true,
      };
    case reportApplicantListAction.types.success:
      return {
        ...state,
        applicants: [
          ...state.applicants,
          ...((payload.result as Array<{}>) || [])
            .map((result) => new SubmissionListModel(result))
            .filter((submission) =>
              [
                ApplicantExamStatus.Submitted,
                ApplicantExamStatus.InReview,
              ].includes(submission.status),
            ),
        ],
      };
    case reportApplicantListAction.types.failure:
      return {
        ...state,
        applicantsLoading: false,
        applicantsLoadingError: true,
      };
    case reportRankingAction.types.request:
      return {
        ...state,
        applicantDetailsLoading: true,
        applicantDetailsLoadingError: false,
        applicantDetails: undefined,
      };
    case reportRankingAction.types.success:
      return {
        ...state,
        applicantDetailsLoading: false,
        applicantDetails:
          payload.result && new ApplicantReportModel(payload.result),
      };
    case reportRankingAction.types.failure:
      return {
        ...state,
        applicantDetailsLoading: false,
        applicantDetailsLoadingError: true,
      };

    case reportResultsAction.types.request:
      return {
        ...state,
        applicantChallengesLoading: true,
        applicantChallengesLoadingError: false,
        applicantChallenges: [],
      };
    case reportResultsAction.types.success:
      return {
        ...state,
        applicantChallengesLoading: false,
        applicantChallenges: ((payload.result as Array<{}>) || []).map(
          (result) => new ApplicantReportChallengeModel(result),
        ),
      };
    case reportResultsAction.types.failure:
      return {
        ...state,
        applicantChallengesLoading: false,
        applicantChallengesLoadingError: true,
      };

    case reportCategoriesAction.types.request:
      return {
        ...state,
        challengeCategoriesLoading: true,
        challengeCategoriesLoadingError: false,
        challengeCategories: [],
      };
    case reportCategoriesAction.types.success:
      return {
        ...state,
        challengeCategoriesLoading: false,
        challengeCategories: ((payload.result as {}[]) || [])
          .map((result) => new ApplicantReportCategoryScoresModel(result))
          .filter((result) => result.category),
      };
    case reportCategoriesAction.types.failure:
      return {
        ...state,
        challengeCategoriesLoading: false,
        challengeCategoriesLoadingError: true,
      };

    /*
     * Evaluation Point Averages
     */
    case reportEvaluationPointAction.types.request:
      return {
        ...state,
        submissionEvaluationPointsLoading: true,
        submissionEvaluationPointsError: false,
        submissionEvaluationPoints: [],
      };
    case reportEvaluationPointAction.types.failure:
      return {
        ...state,
        submissionEvaluationPointsLoading: false,
        submissionEvaluationPointsError: true,
      };
    case reportEvaluationPointAction.types.success:
      return {
        ...state,
        submissionEvaluationPointsLoading: false,
        submissionEvaluationPoints: ((payload.result as {}[]) || [])
          .map(
            (result) => new ApplicantReportEvaluationPointScoresModel(result),
          )
          .filter(
            (result) => result.evaluationPoint && result.evaluationPoint.length,
          ),
      };

    default:
      return state;
  }
};
