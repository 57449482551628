import { connect } from "react-redux";
import { Action, Dispatch } from "redux";

import { RootState } from "../../../../../reducers";
import {
  ExamChallengeUpdateAvailableBox,
  ExternalProps,
} from "./ExamChallengeUpdateAvailableBox";

const mapStateToProps = (state: RootState, props: ExternalProps) => ({
  currentProjectId: state.project.currentProjectId,
  currentUser: state.user.user,
  ...props,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ExamChallengeUpdateAvailableBox);
