import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import {
  AddSubmissionNoteParams,
  getSubmissionNoteList,
  postSubmissionNote,
} from "@api/submissions";

import { useStoreContext } from "@context";

import Message from "@shared/services/message";

import { useRouteParams } from "../useRouteParams";

const submissionNoteKeys = {
  all: ["submissions", "notes"] as const,
  notes: (projectId: number, submissionId: number) => [
    ...submissionNoteKeys.all,
    projectId,
    submissionId,
  ],
};

export function useSubmissionNoteList(
  projectId: number,
  submissionId: number,
  enabled = true,
) {
  const query = useQuery({
    queryKey: submissionNoteKeys.notes(projectId, submissionId),
    queryFn: async ({ signal }) => {
      const data = await getSubmissionNoteList({
        projectId,
        submissionId,
        options: {
          signal,
        },
      });

      return data.result;
    },
    enabled: enabled ? Boolean(projectId && submissionId) : false,
    meta: { hideLoader: true },
  });

  return query;
}

export const useAddSubmissionNote = () => {
  const { projectId } = useStoreContext();
  const { submissionId } = useRouteParams();
  const client = useQueryClient();

  const mutation = useMutation({
    mutationFn: (options: AddSubmissionNoteParams["options"]) =>
      postSubmissionNote({ projectId, submissionId, options }),
    onSuccess: () => {
      client.invalidateQueries({
        queryKey: submissionNoteKeys.notes(projectId, submissionId),
      });

      toast.success(
        Message.getMessageByKey("submission.submissionDetail.memo.success"),
      );
    },
    onError: () => {
      toast.error(
        Message.getMessageByKey("submission.submissionDetail.memo.failed"),
      );
    },
  });

  return mutation;
};
