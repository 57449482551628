import * as classnames from "classnames";

import {
  Msg,
  Table,
  TableBody,
  Row,
  Column,
  QuickHelp,
} from "@shared/components";
import { ChallengeInsightModel } from "@shared/models";
import { formatSeconds } from "@shared/services/date";
import Message from "@shared/services/message";

/**
 * Prop interface
 */
export interface AverageStatsProps {
  challengeInsight?: ChallengeInsightModel;
}

/**
 * React Component
 */
export const AverageStats = ({ challengeInsight }: AverageStatsProps) => {
  const rootStyle = classnames("code-c-average-stats");

  const hasMedianMinutesTaken =
    typeof challengeInsight?.medianMinutesTaken !== "undefined";

  return (
    <div className={rootStyle}>
      {!hasMedianMinutesTaken && <Msg id="message.noResults" />}
      {hasMedianMinutesTaken && (
        <Table
          narrow={true}
          hoverable={false}
          className="code-c-average-stats__table"
        >
          <TableBody>
            <Row>
              <Column className="code-c-average-stats__table__title">
                <Msg id="challenge.overallAverageTimeTaken" /> (
                <Msg id="challenge.median" />)
                <QuickHelp
                  text={Message.getMessageByKey("challenge.timeQuickHelp")}
                  maxSize="large"
                />
              </Column>
              <Column className="align-center">
                {formatSeconds(
                  Math.ceil((challengeInsight?.medianMinutesTaken ?? 0) * 60),
                )}
              </Column>
            </Row>
          </TableBody>
        </Table>
      )}
    </div>
  );
};
