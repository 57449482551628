import { useParams } from "@context";

import { LocalFileMetaData } from "@shared/models";
import { FileUploadConfig } from "@shared/services/fileUpload";

import { useFileScanningStatus, useSkippedFileScanReason } from "./query";
import {
  useGetGuestSubmissionResultLocalFilesMetadata,
  useDownloadSubmissionResultLocalFile,
  useGetSubmissionResultLocalFilesMetadata,
  useGuestDownloadSubmissionResultLocalFile,
} from "./query";

export function useLocalCodeFiles({
  fileUploadConfig,
  challengeResultId,
}: {
  fileUploadConfig?: FileUploadConfig;
  challengeResultId: number;
}) {
  const { token } = useParams();
  const isGuestPage = Boolean(token);

  const { data: orgData, isFetching: orgIsFetching } =
    useGetSubmissionResultLocalFilesMetadata({
      fileUploadConfig,
      challengeResultId,
    });

  const { data: guestData, isFetching: guestIsFetching } =
    useGetGuestSubmissionResultLocalFilesMetadata({
      fileUploadConfig,
      challengeResultId,
    });

  const data = isGuestPage ? guestData : orgData;
  const isFetching = isGuestPage ? guestIsFetching : orgIsFetching;

  const orgDownloadFileMutation = useDownloadSubmissionResultLocalFile();
  const guestDownloadFileMutation = useGuestDownloadSubmissionResultLocalFile();
  const { data: fileScanStatusesString = {} } = useFileScanningStatus();
  const { data: skippedFileScanReasons = {} } = useSkippedFileScanReason();

  const downloadFile = (file: LocalFileMetaData) => {
    const params = {
      challengeResultId,
      filename: file.filename,
    };

    isGuestPage
      ? guestDownloadFileMutation.mutate(params)
      : orgDownloadFileMutation.mutate(params);
  };

  const isEmpty = !isFetching && (!data || data?.length === 0);

  return {
    files: data,
    isEmpty,
    fileScanStatusesString,
    skippedFileScanReasons,
    downloadFile,
  };
}
