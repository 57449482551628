import classnames from "classnames";
import { isEqual } from "lodash";
import * as React from "react";
import { Switch, Route, Redirect, matchPath } from "react-router-dom";
import { match } from "react-router-dom";
import { toast } from "react-toastify";

import { CustomFormDefinitionPayload } from "@api/exams";
import { ApiResponse } from "@api/httpClient";

import { useStoreContext } from "@context";

import {
  Loading,
  SubMenu,
  SubMenuLabel,
  SubMenuList,
  SubMenuItem,
  SubMenuContainer,
  SubMenuContainerLeft,
  SubMenuContainerRight,
  DocumentTitle,
  Restricted,
  RestrictedRoute,
  Msg,
  Alert,
} from "@shared/components";
import {
  useExamPermissions,
  useSubmissionAutoRefetch,
  useTierActionPermissions,
} from "@shared/hooks";
import {
  useExam,
  useUpdateExamChallenge,
  useUpdateExam,
  useUpdateExamReviewers,
  useUpdateExamCustomForm,
  useExamCounts,
} from "@shared/hooks/query";
import { useElement } from "@shared/hooks/useElement";
import {
  UserModel,
  PaginationModel,
  MemberListModel,
  ProjectSwitchItemModel,
  MailDeliveryType,
  ExamChallengeSetModel,
  ExamModel,
} from "@shared/models";
import {
  ExamDeliveryKind,
  ProjectKind,
  ProjectRole,
  UserRole,
} from "@shared/services/enums";
import History from "@shared/services/history";
import Message from "@shared/services/message";
import MSG from "@shared/services/message";

import SubmissionDetail from "../../submissions/submissionDetail/SubmissionDetail.connect";
import {
  ExamDetailOutline,
  ExamDetailChallenges,
  ExamDetailForms,
  ExamDetailReviewers,
  SubmissionMenu,
  ExamDetailDeliveryButton,
  ExamDetailUpdateAvailableBox,
  SampleDeliveryEmail,
  SampleDeliveryID,
  ExamDetailSettingsNew,
} from "../examDetail/partials";
import { ExamDashboard } from "../examSections";
import { ExamDeliveryList, SubmissionList } from "./partials";
import ExamDetailBreadcrumbs from "./partials/examDetailBreadcrumbs/ExamDetailBreadcrumbs";

interface OwnParams {
  examId: string;
  status?: MailDeliveryType;
}

export interface ParentProps {
  match: match<OwnParams>;
  location: { pathname: string };
}

export interface OwnProps {
  currentProject: ProjectSwitchItemModel | undefined;
  currentUser: UserModel | undefined;
  examId: number;
  loading: boolean;
  projectMemberListLoading: boolean;
  projectMembers: MemberListModel[];
  hasTiers: boolean;
  isAssessmentProject: boolean;
  getProjectDetails: (projectId: number) => void;
  getProjectMembers: (pagination?: PaginationModel, keyword?: string) => void;
}

type ExamDetailProps = OwnProps & ParentProps;

/**
 * Page component
 */
export default function ExamDetail({
  currentUser,
  currentProject,
  examId,
  loading,
  projectMemberListLoading,
  match,
  location,
  hasTiers,
  isAssessmentProject,
  projectMembers,
  getProjectDetails,
  getProjectMembers,
}: ExamDetailProps) {
  const { projectId: currentProjectId } = useStoreContext();
  const [isOpenEmailModal, setIsOpenEmailModal] = React.useState(false);
  const [isOpenIdModal, setIsOpenIdModal] = React.useState(false);
  const rootStyle = classnames("code-exam-detail");
  const {
    data: {
      deliveryPath,
      examChallengesSets,
      examDetail,
      examForms,
      examReviewers,
      examDetail: { deliveryKind },
    },
  } = useExam();
  const { data: examCount } = useExamCounts();
  const {
    enableApplicantActionLog,
    enableViewCodePlayback,
    hasChallengePageIssues,
    hasCriticalIssue,
    hasCriticalChallengeIssue,
    hasExamDetailOrChallengeSetIssues,
    hasSettingsPageIssues,
  } = useExamPermissions();
  const { cliSupportEnabled, reviewAllowed } = useTierActionPermissions();
  const updateExamChallenges = useUpdateExamChallenge();
  const updateExam = useUpdateExam();
  const updateExamForms = useUpdateExamCustomForm();
  const { examCounts } = useSubmissionAutoRefetch(examId);
  const updateExamReviewers = useUpdateExamReviewers();
  const status = match.params.status as "scheduled" | "history" | undefined;
  const ready = currentProjectId && currentUser && examDetail.id !== undefined;
  const isAssignedReviewer = examReviewers.some(
    (reviewer) => reviewer.id === currentUser?.id,
  );

  const isDeliveryAllowed =
    hasTiers && examDetail && !hasExamDetailOrChallengeSetIssues;

  const isChallengeWithErrorIssue = !cliSupportEnabled;

  // TODO: potential performance issue
  const hasLocalExam = React.useMemo(
    () =>
      examChallengesSets.some((challenges) =>
        challenges.challenges.some(({ localExamEnabled }) => localExamEnabled),
      ),
    [examChallengesSets],
  );
  const isChallengeOrSettingsWithWarningIssue =
    (enableApplicantActionLog || enableViewCodePlayback) && hasLocalExam;

  React.useEffect(() => {
    getProjectDetails(currentProjectId);
  }, [currentProjectId, examId, getProjectDetails]);

  if (isNaN(examId)) {
    History.replace("/404");
  }

  const onCloseModal = () => {
    setIsOpenEmailModal(false);
    setIsOpenIdModal(false);
  };

  const onOpenEmailModal = () => {
    setIsOpenIdModal(true);
  };

  const onOpenIdModal = () => {
    setIsOpenEmailModal(true);
  };

  const onUpdateReviewers = async ({
    reviewers,
    shareReviewsWithReviewers,
    requiredReviews,
  }: {
    reviewers: number[];
    shareReviewsWithReviewers: boolean;
    requiredReviews: ExamModel["reviewSettings"]["requiredReviews"];
  }) => {
    const requests = [] as Promise<ApiResponse>[];
    const prevReviewers = examReviewers.map(({ id }) => id);

    if (
      reviewers.length !== prevReviewers.length ||
      !isEqual([...reviewers].sort(), [...prevReviewers].sort())
    ) {
      requests.push(
        updateExamReviewers.mutateAsync({
          examId: examDetail.id,
          data: { reviewers },
          hideToast: true,
        }),
      );
    }

    const hasSettingTypeChanged =
      examDetail.reviewSettings.requiredReviews.requiredType !==
      requiredReviews.requiredType;

    const hasSettingCountChanged =
      "count" in examDetail.reviewSettings.requiredReviews &&
      "count" in requiredReviews &&
      requiredReviews.count !== examDetail.reviewSettings.requiredReviews.count;

    const hasShareReviewOptionChanged =
      examDetail.shareReviewsWithReviewers !== shareReviewsWithReviewers;

    if (
      hasShareReviewOptionChanged ||
      hasSettingTypeChanged ||
      hasSettingCountChanged
    ) {
      requests.push(
        updateExam.mutateAsync({
          examId,
          data: {
            ...examDetail,
            shareReviewsWithReviewers,
            reviewSettings: {
              requiredReviews,
            },
          },
          hideToast: true,
        }),
      );
    }

    try {
      await Promise.all(requests);
      toast.success(Message.getMessageByKey("message.exam.updated"));
    } catch (_) {
      // error message will be display by side effect of mutation
    }
  };

  const handleUpdateExamOutline = (data: any) => {
    updateExam.mutate({ examId, data });
  };

  const handleUpdateExamChallenges = ({
    challengesSets,
  }: {
    challengesSets: ExamChallengeSetModel[];
  }) => {
    updateExamChallenges.mutate({
      examId,
      payload: challengesSets,
    });
  };

  const handleUpdateExamForms = (data: CustomFormDefinitionPayload) => {
    updateExamForms.mutate({ examId, data });
  };

  const allowInnerScroll =
    location.pathname.endsWith("reviewers") ||
    location.pathname.endsWith("form");

  const requiredReviews = examDetail.reviewSettings?.requiredReviews;

  const { addClass: addClassToHtml, removeClass: removeClassFromHtml } =
    useElement({
      tagName: "html",
    });

  React.useEffect(() => {
    addClassToHtml("disable-overscroll-y");

    return () => {
      removeClassFromHtml("disable-overscroll-y");
    };
  }, [addClassToHtml, removeClassFromHtml]);

  const canAccessSettings = currentUser?.hasRole(
    [
      UserRole.SystemAdmin,
      ProjectRole.ProjectAdmin,
      ProjectRole.ExamCreator,
      ProjectRole.ExamDeliverer,
    ],
    currentProjectId,
  );

  return (
    <div className={rootStyle}>
      <Loading isOpen={loading} />
      <DocumentTitle page={examDetail.name || ""} />
      <ExamDetailBreadcrumbs />
      <SubMenuContainer
        className={classnames("code-exam-detail__sub-menu-container", {
          "code-exam-detail__auto-height": !allowInnerScroll,
        })}
      >
        <SubMenuContainerLeft className="code-exam-detail__sub-menu-container__left">
          <SubMenu className="code-exam-detail__sub-menu-container__menu">
            <ExamDetailUpdateAvailableBox
              hasOutdatedChallengeVersion={examDetail.hasOutdatedChallengeVersion()}
              archived={examDetail.isArchived()}
              showDetailLink={
                matchPath(window.location.pathname, {
                  path: "/p/:projectId/exams/:examId/challenges",
                }) === null
              }
              onClickDetail={() => {
                History.push(
                  `/p/${currentProjectId}/exams/${examId}/challenges`,
                );
              }}
            />
            <ExamDetailDeliveryButton
              archived={examDetail.isArchived()}
              isAssignedReviewer={isAssignedReviewer}
              onClickDelivery={() =>
                History.push(
                  `/p/${currentProjectId}/exams/${examId}/deliveries/new/${deliveryPath}`,
                )
              }
              onClickSampleDelivery={
                deliveryKind === ExamDeliveryKind.ID
                  ? onOpenEmailModal
                  : onOpenIdModal
              }
              isDeliveryAllowed={isDeliveryAllowed && isAssessmentProject}
              isSampleDeliveryAllowed={isDeliveryAllowed}
              extraMessages={{
                disabledDelivery: isAssessmentProject ? undefined : (
                  <Msg
                    id="exam.delivery.not.supported.project"
                    values={{
                      name: ProjectKind.toServiceName(currentProject?.kind),
                    }}
                  />
                ),
              }}
            />
            <SubMenuLabel>
              <Msg id={"common.exam"} />
            </SubMenuLabel>
            <SubMenuList ariaLabel="Exam Side Menu">
              {[
                ...[
                  { label: "dashboard.title", pathName: "dashboard" },
                  { label: "outline", pathName: "outline" },
                  {
                    label: "challenges",
                    pathName: "challenges",
                    hasError:
                      hasChallengePageIssues || isChallengeWithErrorIssue,
                  },
                  { label: "entryForm", pathName: "form" },
                  {
                    label: "reviewers",
                    pathName: "reviewers",
                    hasError:
                      requiredReviews?.requiredType === "count" &&
                      examDetail.reviewers.length > 0 &&
                      examDetail.reviewers.length < requiredReviews?.count,
                    errorId: "validation.reviewerCount",
                  },
                ],
                ...(canAccessSettings
                  ? [
                      {
                        label: "configure",
                        pathName: "settings",
                        hasError: hasSettingsPageIssues,
                      },
                    ]
                  : []),
              ].map((status) => (
                <SubMenuItem
                  key={status.label}
                  label={<Msg id={`exam.${status.label}`} />}
                  route={{
                    pathname: `/p/${currentProjectId}/exams/${examId}/${status.pathName}`,
                  }}
                  hasError={!loading && Boolean(status.hasError)}
                  warningId={
                    (status.label === "challenges" ||
                      status.label === "configure") &&
                    isChallengeOrSettingsWithWarningIssue
                      ? "challenge.subMenu.localExam.warning"
                      : undefined
                  }
                  errorId={status.errorId}
                />
              ))}
            </SubMenuList>
            <Restricted
              roles={[
                UserRole.SystemAdmin,
                ProjectRole.ProjectAdmin,
                ProjectRole.ExamDeliverer,
              ]}
              strictAllow={deliveryKind === ExamDeliveryKind.Standard}
              wrapperTagName={false}
            >
              <>
                <SubMenuLabel>
                  <Msg id="exam.delivery.deliveries" />
                </SubMenuLabel>
                <SubMenuList ariaLabel="Deliveries Side Menu">
                  {examDetail.archivedAt === undefined && (
                    <SubMenuItem
                      label={`${MSG.getMessageByKey(
                        "exam.delivery.scheduled",
                      )} (${examCount.scheduledDeliveriesCount || 0})`}
                      disabled={!Boolean(examCount.scheduledDeliveriesCount)}
                      route={{
                        pathname: `/p/${currentProjectId}/exams/${examId}/delivery_scheduled`,
                      }}
                    />
                  )}
                  <SubMenuItem
                    label={MSG.getMessageByKey("exam.delivery.history")}
                    disabled={
                      !Boolean(examCount.sentDeliveryExceptArchivedCount)
                    }
                    route={{
                      pathname: `/p/${currentProjectId}/exams/${examId}/delivery_history`,
                    }}
                  />
                </SubMenuList>
              </>
            </Restricted>
            <Restricted
              roles={[
                UserRole.SystemAdmin,
                ProjectRole.ProjectAdmin,
                ProjectRole.ExamDeliverer,
              ]}
              allow={isAssignedReviewer}
            >
              <SubmissionMenu
                counts={examCounts}
                isAssignedReviewer={isAssignedReviewer}
                navigationBaseURL={`/p/${currentProjectId}/exams/${examId}`}
                shouldShowSubmittedAsMenuList={reviewAllowed}
              />
            </Restricted>
          </SubMenu>
        </SubMenuContainerLeft>
        <SubMenuContainerRight>
          {ready ? (
            <>
              {!isDeliveryAllowed && (
                <Alert type="warning" className="code-exam-detail__banner">
                  <Msg id="tier.disabled.examBanner" />
                </Alert>
              )}
              <div
                className={classnames("code-exam-detail__body", {
                  "code-exam-detail__body__inner-scroll": allowInnerScroll,
                })}
              >
                <Switch>
                  <Route
                    path="/p/:projectId/exams/:examId/outline/:edit?"
                    exact={true}
                    render={({ match }) => (
                      <ExamDetailOutline
                        updateExamOutline={handleUpdateExamOutline}
                        examDetail={examDetail}
                        // MEMO: edit parameter is only added after copying the exam
                        editing={match.params.edit === "edit" && !loading}
                        currentUser={currentUser}
                      />
                    )}
                  />
                  <Route
                    path="/p/:projectId/exams/:examId/challenges"
                    exact={true}
                    render={() => (
                      <ExamDetailChallenges
                        examDetail={examDetail}
                        updateExamChallenges={handleUpdateExamChallenges}
                        examDetailChallengesSets={examChallengesSets}
                        saving={loading}
                        hasCriticalChallengeIssue={hasCriticalChallengeIssue}
                        hasCriticalIssue={hasCriticalIssue}
                      />
                    )}
                  />
                  <Route
                    path="/p/:projectId/exams/:examId/form"
                    exact={true}
                    render={() => (
                      <ExamDetailForms
                        examDetail={examDetail}
                        updateExamForms={handleUpdateExamForms}
                        examDetailForms={examForms}
                      />
                    )}
                  />
                  <Route
                    path="/p/:projectId/exams/:examId/reviewers"
                    exact={true}
                    render={() => (
                      <ExamDetailReviewers
                        examDetail={examDetail}
                        projectMembers={projectMembers}
                        projectMemberListLoading={projectMemberListLoading}
                        getProjectMembers={getProjectMembers}
                        examDetailReviewers={examReviewers}
                        canReview={reviewAllowed}
                        updateExamReviewers={onUpdateReviewers}
                      />
                    )}
                  />
                  <Route
                    path="/p/:projectId/exams/:examId/dashboard"
                    exact
                    render={() => <ExamDashboard />}
                  />
                  <RestrictedRoute
                    roles={[
                      UserRole.SystemAdmin,
                      ProjectRole.ProjectAdmin,
                      ProjectRole.ExamCreator,
                      ProjectRole.ExamDeliverer,
                    ]}
                    path="/p/:projectId/exams/:examId/settings"
                    exact={true}
                  >
                    <ExamDetailSettingsNew
                      hasCriticalIssue={hasCriticalIssue}
                    />
                  </RestrictedRoute>

                  <RestrictedRoute
                    path="/p/:projectId/exams/:examId/submissions/:submissionId"
                    roles={[
                      UserRole.SystemAdmin,
                      ProjectRole.ProjectAdmin,
                      ProjectRole.ExamDeliverer,
                      ProjectRole.Reviewer,
                    ]}
                  >
                    <SubmissionDetail />
                  </RestrictedRoute>
                  <RestrictedRoute
                    path="/p/:projectId/exams/:examId/delivery_:status"
                    roles={[
                      UserRole.SystemAdmin,
                      ProjectRole.ProjectAdmin,
                      ProjectRole.ExamDeliverer,
                    ]}
                    exact={true}
                  >
                    <ExamDeliveryList
                      status={status}
                      params={window.location.search}
                    />
                  </RestrictedRoute>
                  <Route
                    path="/p/:projectId/exams/:examId/submissions_:submissionsKind"
                    exact={true}
                    render={({ match, history, location }) => (
                      <SubmissionList
                        examId={examId}
                        history={history}
                        location={location}
                        submissionsKind={match.params.submissionsKind}
                      />
                    )}
                  />
                  <Route
                    path="/p/:projectId/exams/:examId"
                    exact={true}
                    render={({ match }) => (
                      <Redirect
                        to={`/p/${match.params.projectId}/exams/${match.params.examId}/dashboard`}
                      />
                    )}
                  />
                  <Route
                    path="/p/:projectId/exams/:examId/submissions"
                    exact={true}
                    render={({ match }) => (
                      <Redirect
                        to={`/p/${match.params.projectId}/exams/${match.params.examId}/submissions_all`}
                      />
                    )}
                  />
                  <Redirect
                    to={`/p/${currentProjectId}/exams/${examId}/dashboard`}
                  />
                </Switch>
              </div>
            </>
          ) : (
            <div />
          )}
        </SubMenuContainerRight>
      </SubMenuContainer>
      {isOpenEmailModal && (
        <SampleDeliveryEmail
          isOpen={true}
          exam={examDetail}
          onClose={onCloseModal}
        />
      )}
      {isOpenIdModal && (
        <SampleDeliveryID
          isOpen={true}
          exam={examDetail}
          onClose={onCloseModal}
        />
      )}
    </div>
  );
}
