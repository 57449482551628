import * as classNames from "classnames";
import * as React from "react";

import { Msg, Radio } from "@shared/components";

type OptionType = "receive_all" | "receive_specific";

export interface ExamRestrictionOptionsProps {
  onFormChange: (values: { subscribedToAll: boolean }) => void;
  subscribedToAll: boolean;
  disabled?: boolean;
  className?: string;
}

export const ExamRestrictionOptions = ({
  subscribedToAll,
  disabled,
  onFormChange,
  className,
}: ExamRestrictionOptionsProps) => {
  const [option, setOption] = React.useState<OptionType>(
    subscribedToAll ? "receive_all" : "receive_specific",
  );

  const onRadioButtonChange = (e: React.FormEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value as OptionType;
    setOption(value);
    onFormChange({
      subscribedToAll: value === "receive_all",
    });
  };

  const rootStyle = classNames("code-exam-restriction-options", className);

  return (
    <div className={rootStyle}>
      <div>
        <Radio
          readOnly={disabled}
          value={option}
          defaultValue="receive_all"
          onChange={onRadioButtonChange}
        >
          <Msg id="exam.restrictions.all" />
        </Radio>
      </div>
      <div>
        <Radio
          readOnly={disabled}
          value={option}
          defaultValue="receive_specific"
          onChange={onRadioButtonChange}
        >
          <Msg id="exam.restrictions.individual" />
        </Radio>
      </div>
    </div>
  );
};
