import { PreviewContentModel } from "@shared/models";
import { getEditablePresignedFiles } from "@shared/services/preview";

import { ChallengeResultSettings } from "../settings.model";

export function preparePreviewContent(previewContent?: PreviewContentModel) {
  if (!previewContent) {
    return null;
  }

  const { settings, presignedFiles, ...rest } = previewContent;
  return {
    ...rest,
    presignedFiles: getEditablePresignedFiles(presignedFiles, settings.files),
  };
}

export function preparePreviewContext(
  challengeResultPreview?: ChallengeResultSettings,
) {
  if (!challengeResultPreview) {
    return null;
  }

  const {
    initialHistoryId,
    saveHistory,
    languageHistory,
    openedAt,
    currentSuccessfulTestcases,
  } = challengeResultPreview;
  const history = [
    { id: initialHistoryId, createdAt: openedAt },
    ...saveHistory,
    ...languageHistory,
  ].sort((a, b) => b.id - a.id);

  // Fallback to initialHistoryId if there is no save history
  const historyIdWithHighestScore =
    saveHistory.find(
      (item) => item.successfulTestcases === currentSuccessfulTestcases,
    )?.id ?? initialHistoryId;

  return {
    ...challengeResultPreview.context,
    history,
    initialHistoryId,
    historyIdWithHighestScore,
  };
}
