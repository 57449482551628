import { connect } from "react-redux";

import { RootState } from "../../../reducers";
import RestrictedLink from "./RestrictedLink";

interface OwnProps {
  allow?: boolean;
  roles: Array<number>;
  to: string;
  children: {};
}

const mapStateToProps = (state: RootState, props: OwnProps) => ({
  ...props,
  allow:
    (state.user.user &&
      (state.user.user
        .getRoles(state.project.currentProjectId)
        .some((role) => props.roles.includes(role)) ||
        state.user.user
          .getRoles()
          .some((role) => props.roles.includes(role)))) ||
    props.allow,
});

export default connect(mapStateToProps, {})(RestrictedLink);
