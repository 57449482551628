import { ApiResponse, post } from "@api/httpClient";

import { IdExamDeliveryModel } from "@shared/models";

import { DeliverExamParams } from "./examDelivery";

interface ConfirmIdDeliveryResponse {
  alreadyDelivered: string[];
  notDelivered: string[];
}

export async function postDeliverExamById({
  examId,
  projectId,
  options,
}: DeliverExamParams) {
  const { data } = await post<ApiResponse<IdExamDeliveryModel>>(
    `/api/projects/${projectId}/exams/${examId}/iddelivery`,
    options,
  );

  return data;
}

export async function postDeliverExamByTestId({
  examId,
  projectId,
  options,
}: DeliverExamParams) {
  const { data } = await post<ApiResponse<IdExamDeliveryModel>>(
    `/api/projects/${projectId}/exams/${examId}/testiddelivery`,
    options,
  );

  return data;
}

export async function postConfirmDeliverExamById({
  examId,
  projectId,
  options,
}: DeliverExamParams) {
  const { data } = await post<ApiResponse<ConfirmIdDeliveryResponse>>(
    `/api/projects/${projectId}/exams/${examId}/iddelivery/confirm`,
    options,
  );

  return data;
}
