import { connect } from "react-redux";

import { RootState } from "@reducers";

import {
  SettingSlackIntegrationUpdateChannelModal,
  ExternalProps,
} from "./SettingSlackIntegrationUpdateChannelModal";

const mapStateToProps = (state: RootState, props: ExternalProps) => {
  return {
    ...props,
    socialChannels: state.project.projectSlackSocialChannels,
  };
};

const mapDispatchToProps = () => ({});

/**
 * Wrapped component
 */
const wrappedComponent = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SettingSlackIntegrationUpdateChannelModal);

export default wrappedComponent;
