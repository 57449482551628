import * as classnames from "classnames";
import * as React from "react";

import {
  DifficultyTag,
  Markdown,
  Question,
  Spinner,
  Msg,
  Button,
  Icon,
} from "@shared/components";
import { useExam } from "@shared/hooks/query";
import { ReadmeModel, ChallengeModel } from "@shared/models";
import { ChallengeStyle } from "@shared/services/enums";

import { getChallengeSetMessageKey } from "../../../examSections/examSectionUtil/ExamSectionUtil";

/**
 * Prop interface
 */
export type ExternalProps = {};
export type InjectedProps = {
  readmeList: ReadmeModel[];
  challengeList: ChallengeModel[];
  getChallenge: (challengeId: number) => void;
};
export type ExamChallengePrintProps = ExternalProps & InjectedProps;

/**
 * React Component
 */
export function ExamChallengePrint({
  readmeList = [],
  challengeList = [],
  getChallenge,
}: ExamChallengePrintProps) {
  const {
    data: { examDetail: exam },
  } = useExam();

  /**
   * States
   */

  /**
   * Effects
   */
  React.useEffect(() => {
    if (exam.challengesSets) {
      exam.challengesSets.forEach((challengeSet) =>
        challengeSet.challenges.forEach((challenge) =>
          getChallenge(challenge.challengeId),
        ),
      );
    }
  }, [exam.challengesSets, getChallenge]);

  /**
   * Private Functions
   */
  const rootStyle = classnames("code-c-exam-challenge-print");

  const getReadme = (challengeId: number) => {
    const readme = readmeList.find((item) => item.challengeId === challengeId);
    return readme ? (
      <Markdown body={readme.body} noRelativeLinks={true} />
    ) : (
      <div className="code-c-exam-challenge-print__loading">
        <Spinner />
      </div>
    );
  };

  const getQuestions = (challengeId: number) => {
    const challenge = challengeList.find((item) => item.id === challengeId);
    return challenge ? (
      challenge.questions.map((question, index) => (
        <div
          key={question.id}
          className="code-c-exam-challenge-print__question"
        >
          <div className="code-c-exam-challenge-print__question-index">
            {`Q${index + 1}`}
          </div>
          <Question question={question} hasHeader={false} showTextarea={true} />
        </div>
      ))
    ) : (
      <div className="code-c-exam-challenge-print__loading">
        <Spinner />
      </div>
    );
  };

  return (
    <div className={rootStyle}>
      <div className="code-c-exam-challenge-print__print-button">
        <Button onClick={() => window.print()} ariaLabel="Print">
          <Icon type="print" size="medium" />
          <Msg id="print" />
        </Button>
      </div>
      <p className="code-c-exam-challenge-print__exam-title is-wrap">
        {exam?.name}
      </p>
      <div>
        {exam?.challengesSets.map((challengeSet, index) => {
          const { isOptionalSet, isRandomSet, challenges } = challengeSet;
          return (
            <div
              key={`challengeSet${index}`}
              className="code-c-exam-challenge-print__challenge-set"
            >
              <p className="code-c-exam-challenge-print__challenge-set-title is-wrap">
                <Msg
                  id={getChallengeSetMessageKey(isRandomSet, isOptionalSet)}
                />
              </p>
              <div>
                {challenges.map((challenge) => {
                  const {
                    challengeId,
                    description,
                    title,
                    difficulty,
                    style,
                    weight,
                    timeLimitMinutes,
                  } = challenge;
                  return (
                    <div
                      key={challengeId}
                      className="code-c-exam-challenge-print__challenge"
                    >
                      <div className="code-c-exam-challenge-print__challenge-header">
                        <div className="code-c-exam-challenge-print__challenge-title">
                          <div className="code-c-exam-challenge-print__challenge-title__left">
                            <span className="is-wrap">
                              <strong>{title}</strong>
                            </span>
                            <DifficultyTag value={difficulty} />
                            <span className="is-nowrap">
                              {ChallengeStyle.toString(style)}
                            </span>
                          </div>
                          <div className="code-c-exam-challenge-print__challenge-title__right">
                            <span>
                              <Msg id="weight" />
                              {`: ${weight}`}
                            </span>
                            <span>
                              <Msg id="timeLimitMinutes" />
                              {`: ${
                                timeLimitMinutes !== undefined
                                  ? timeLimitMinutes
                                  : "N/A"
                              }`}
                            </span>
                          </div>
                        </div>
                        {description && (
                          <div className="code-c-exam-challenge-print__challenge-description is-wrap">
                            {description}
                          </div>
                        )}
                      </div>
                      <div className="code-c-exam-challenge-print__challenge-body">
                        {ChallengeStyle.hasReadme(style)
                          ? getReadme(challengeId)
                          : getQuestions(challengeId)}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
