import * as classnames from "classnames";
import * as React from "react";

import { Icon, IconButton, Msg, Restricted } from "@shared/components";
import { formatDateTimeMinutes } from "@shared/services/date";
import {
  ApplicantExamStatus,
  ProjectRole,
  UserRole,
} from "@shared/services/enums";
import { isEvaluatedStatus } from "@shared/services/exam";

/**
 * Props
 */
export interface SubmissionDetailDeadlineProps {
  submissionDeadline: string;
  submissionStatus: ApplicantExamStatus;
  isActiveExam?: boolean;
  onChangeDeadlineClick: () => void;
}

export const SubmissionDetailDeadline: React.FunctionComponent<
  SubmissionDetailDeadlineProps
> = ({
  submissionDeadline,
  submissionStatus,
  isActiveExam,
  onChangeDeadlineClick,
}: SubmissionDetailDeadlineProps) => {
  const rootStyle = classnames("code-submission-detail-deadline");
  const isEvaluated = isEvaluatedStatus(submissionStatus);
  const formatDeadline = formatDateTimeMinutes(submissionDeadline);

  return (
    <div className={rootStyle}>
      <Msg id="common.deadline" tagName="strong" />
      <div className="code-submission-detail-deadline__panel">
        <span>{formatDeadline}</span>
        <Restricted
          roles={[
            ProjectRole.ProjectAdmin,
            ProjectRole.ExamDeliverer,
            UserRole.SystemAdmin,
          ]}
          strictAllow={!isEvaluated && isActiveExam}
          wrapperTagName={false}
        >
          <IconButton
            onClick={onChangeDeadlineClick}
            aria-label="Open Change Deadline"
            className="code-submission-detail-deadline__button"
            color="text-gray"
          >
            <Icon className="code-c-icon__text-base" type="edit" />
          </IconButton>
        </Restricted>
      </div>
    </div>
  );
};
