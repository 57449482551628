import * as classnames from "classnames";
import * as React from "react";

import { Header, Menu, Breadcrumbs } from "@shared/components";
import { UserModel } from "@shared/models";
import history from "@shared/services/history";
import { isOrgsContainerReady } from "@shared/services/url";

/**
 * Routes
 */
import OrgsRoutes from "./OrgsRoutes";

export interface OrgsContainerProps {
  location: {};
  currentProjectId: number;
  currentUser: UserModel;
  orgLogo: string;
  orgName: string;
  options: {
    hideSideMenu?: boolean;
    hideHeader?: boolean;
    hideBreadcrumbs?: boolean;
  };
  refreshUser: () => void;
}

/**
 * Container component
 * @deprecated please use <Layout> instead (src/components/orgs/layout/Layout.tsx)
 */
export default class OrgsContainer extends React.Component<OrgsContainerProps> {
  public componentDidMount() {
    // HACK!! call User API to rebuild the cache on the API side
    // https://givery-technology.atlassian.net/browse/TRAC-2000
    window.addEventListener("focus", this.props.refreshUser);

    history.listen((location, action) => {
      if (location.pathname === "/" && action === "PUSH") {
        this.props.refreshUser();
      }
    });
  }

  public componentWillUnmount() {
    window.removeEventListener("focus", this.props.refreshUser);
  }

  public render() {
    // TODO: remove this condition after refactor all exam route path
    const { pathname } = window.location;
    // When pathname is exam delivery page, do not render this component
    if (/^\/p\/\d+\/exams\/\d+\/(deliveries)(\/.*)?$/.test(pathname)) {
      return null;
    }

    const { currentUser, currentProjectId, options = {} } = this.props;

    const rootStyle = classnames("code-orgs-container", {
      [`no-sidemenu`]: options.hideSideMenu,
      [`no-header`]: options.hideHeader,
    });

    const currentProject =
      currentUser &&
      currentUser.projects &&
      currentUser.projects.find((project) => project.id === currentProjectId);

    // HACK!!
    // To prevent the children from being rendered until the current project ID is updated when the browser's back button is pressed.
    const ready = isOrgsContainerReady(
      window.location.pathname,
      currentUser,
      currentProjectId,
    );

    return (
      <div className={rootStyle}>
        {!options.hideHeader && (
          <Header
            orgLogo={this.props.orgLogo}
            orgName={this.props.orgName}
            showSwitcher={!options.hideSideMenu}
            isSystemAdmin={
              currentUser && currentUser.isOnlySystemAdmin(currentProjectId)
            }
          />
        )}
        {!options.hideSideMenu && (
          <Menu
            projectId={this.props.currentProjectId}
            currentUser={this.props.currentUser}
            applicationType={currentProject && currentProject.applicationType}
          />
        )}
        <div className="code-orgs-container__body">
          {!options.hideBreadcrumbs && <Breadcrumbs />}
          {ready ? (
            <OrgsRoutes routerLocation={this.props.location} />
          ) : (
            <div /> // dummy
          )}
        </div>
      </div>
    );
  }
}
