import * as classnames from "classnames";
import { Breadcrumbs as ReactBreadcrumbs } from "react-breadcrumbs-dynamic";
import { NavLink } from "react-router-dom";

/**
 * Prop interface
 */
export interface BreadcrumbsProps {
  className?: string;
  children?: {};
}

/**
 * React Component
 */
export default function Breadcrumbs({ className }: BreadcrumbsProps) {
  const rootStyle = classnames("code-c-breadcrumbs", {
    [`${className}`]: Boolean(className),
  });

  const separator = <span className="code-c-breadcrumbs__separator">/</span>;

  return (
    <div className={rootStyle}>
      <ReactBreadcrumbs
        container={"nav"}
        containerProps={{ className: "code-c-breadcrumbs__container" }}
        separator={separator}
        item={NavLink}
        finalItem={"span"}
        finalProps={{ className: "code-c-breadcrumbs__final" }}
      />
    </div>
  );
}
