import { IdDeliveryConfigModel } from ".";
import { dayjs } from "../services/date";
import { getExamName } from "../services/exam";

class IdDeliverySharingModel {
  public examId: number;
  public examName: string;
  public examEnName?: string;
  public examNaturalLanguage: string;
  public organizationId: number;
  public organizationImageUrl: string;
  public organizationDisplayName: string;
  public urlToken: string;
  public idDeliveryConfig: IdDeliveryConfigModel = new IdDeliveryConfigModel();

  constructor(args?: {}) {
    Object.assign(this, args);
  }

  public isEmpty() {
    return !Boolean(this.examId);
  }

  public isAvailable() {
    if (this.idDeliveryConfig.endAt) {
      return dayjs().isBefore(this.idDeliveryConfig.endAt);
    } else {
      return true;
    }
  }

  public getExamName(language: string): string {
    return getExamName(
      this.examEnName !== undefined,
      this.examName,
      this.examEnName,
      language,
    );
  }
}

export default IdDeliverySharingModel;
