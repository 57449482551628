import * as classNames from "classnames";

interface DividerProps {
  className?: string;
  vertical?: boolean;
}

const Divider = ({ className, vertical }: DividerProps) => {
  return (
    <div className={classNames("code-c-divider", { vertical }, className)} />
  );
};

export default Divider;
