import * as classnames from "classnames";
import * as React from "react";

import { Button, ButtonType, Icon, Overlay } from "..";

/**
 * Prop interface
 */
export interface ActionDropdownProps {
  buttonLabel?: string;
  buttonType?: ButtonType;
  className?: string;
  children?: {};
  disabled?: boolean;
  icon?: string;
  keepOpen?: boolean;
  menuClassName?: string;
}

export interface ActionDropdownState {
  isOpen: boolean;
}

/**
 * React Component
 */
export function ActionDropdown({
  buttonLabel,
  buttonType,
  className,
  children,
  disabled,
  icon = "ellipsis-v",
  keepOpen,
  menuClassName,
}: ActionDropdownProps) {
  /**
   * States
   */
  const [isOpen, setIsOpen] = React.useState(false);

  const [position, setPosition] = React.useState<{
    top: number;
    right: number;
  }>({ top: 0, right: 0 });

  const buttonRef = React.useRef<HTMLDivElement | null>(null);
  const menuRef = React.useRef<HTMLDivElement | null>(null);

  /**
   * Private Functions
   */
  const rootStyle = classnames("code-c-action-dropdown", "dropdown", {
    "is-active": isOpen,
    [`${className}`]: Boolean(className),
  });
  const menuStyle = classnames("dropdown-menu", menuClassName);

  const onToggle = () => {
    if (disabled || (isOpen && keepOpen)) {
      return;
    }

    setIsOpen(!isOpen);
  };

  const calculatePosition = React.useCallback(() => {
    if (buttonRef.current !== null) {
      const { bottom, right } = buttonRef.current.getBoundingClientRect();
      setPosition({ top: bottom + 4, right: window.innerWidth - right });
    }
  }, []);

  React.useEffect(() => {
    if (isOpen && menuRef.current && buttonRef.current) {
      const {
        top,
        right,
        bottom,
        height: menuHeight,
      } = menuRef.current.getBoundingClientRect();

      const { height: buttonHeight } =
        buttonRef.current?.getBoundingClientRect();

      const viewportHeight = window.innerHeight;

      const isOverlappingBottom = bottom > viewportHeight;

      const newPosition = top - menuHeight - buttonHeight - 12;

      if (isOverlappingBottom && newPosition >= 0) {
        setPosition({
          top: newPosition,
          right: window.innerWidth - right,
        });
      }
    }
  }, [isOpen, position.top]);

  /**
   * Effects
   */
  React.useEffect(() => {
    if (isOpen) {
      calculatePosition();
      window.addEventListener("resize", calculatePosition);
    } else {
      window.removeEventListener("resize", calculatePosition);
    }
  }, [isOpen, calculatePosition]);

  const handleClickOutSide = () => {
    setIsOpen(false);
  };

  /**
   * Render
   */
  return (
    <>
      <div className={rootStyle} onClick={onToggle} aria-expanded={isOpen}>
        <div className="dropdown-button" ref={buttonRef}>
          <Button
            disabled={disabled}
            size="small"
            shrink={true}
            onClick={onToggle}
            ariaLabel="Action Button"
            ariaPressed={isOpen}
            type={buttonType}
          >
            <div className="dropdown-button__content">
              <Icon type={icon} />
              {buttonLabel}
            </div>
          </Button>
        </div>
        {isOpen && (
          <>
            <div
              ref={menuRef}
              className={menuStyle}
              role="menu"
              aria-hidden={!isOpen}
              style={{ top: position.top, right: position.right }}
            >
              <div className="dropdown-content">{children}</div>
            </div>
            <Overlay isOpen={true} onClick={handleClickOutSide} />
          </>
        )}
      </div>
    </>
  );
}
