class Intercom {
  private options = {};

  constructor(options?: {}) {
    if (options) {
      this.options = options;
    }
    this.bootIntercom();
  }

  public bootIntercom() {
    if (
      window &&
      typeof window.Intercom === "function" &&
      process.env.REACT_APP_INTERCOM_APP_ID
    ) {
      window.Intercom("boot", {
        app_id: process.env.REACT_APP_INTERCOM_APP_ID,
        ...this.options,
      });
    }
  }

  public updateIntercomInfo(data: {}) {
    if (
      window &&
      typeof window.Intercom === "function" &&
      process.env.REACT_APP_INTERCOM_APP_ID
    ) {
      window.Intercom("update", { ...data, ...this.options });
    }
  }

  public clearIntercomInfo() {
    if (
      window &&
      typeof window.Intercom === "function" &&
      process.env.REACT_APP_INTERCOM_APP_ID
    ) {
      window.Intercom("shutdown");
      this.bootIntercom();
    }
  }

  public showMessages() {
    if (
      window &&
      typeof window.Intercom === "function" &&
      process.env.REACT_APP_INTERCOM_APP_ID
    ) {
      window.Intercom("showMessages");
    }
  }

  public show() {
    if (
      window &&
      typeof window.Intercom === "function" &&
      process.env.REACT_APP_INTERCOM_APP_ID
    ) {
      window.Intercom("update", {
        hide_default_launcher: false,
        alignment: "left",
        horizontal_padding: 70,
        vertical_padding: 20,
      });
    }
  }

  public hide() {
    if (
      window &&
      typeof window.Intercom === "function" &&
      process.env.REACT_APP_INTERCOM_APP_ID
    ) {
      window.Intercom("update", { hide_default_launcher: true });
    }
  }
}

let intercom: Intercom;

export function activate(options?: {}) {
  intercom = new Intercom(options);
}

export { intercom };
