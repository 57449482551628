import { useId } from "@floating-ui/react";

export interface DefaultOptionProps {
  label: string;
  value: string | number;
  isSelected?: boolean;
  group?: string;
}

export const DefaultOption = ({
  label,
  isSelected,
  ...rest
}: DefaultOptionProps) => {
  const id = useId();
  return (
    <div
      className="code-c-autocomplete__option"
      id={id}
      aria-selected={isSelected}
      {...rest}
    >
      {label}
    </div>
  );
};
