import classNames from "classnames";

import { SubmissionEvaluationStatus } from "@api/submissions";

import { Dropdown, Icon, Msg } from "@shared/components";
import { DropdownItem } from "@shared/components/dropdown/DropdownItem";
import {
  useDeleteSubmissionEvaluation,
  useEvaluateSubmission,
} from "@shared/hooks/query";
import { ApplicantExamStatus } from "@shared/services/enums";

interface SubmissionEvaluationDropdownProps {
  submissionId: number;
  submissionStatus?: ApplicantExamStatus;
  disabled?: boolean;
  onEvaluationChange?: (newStatus: ApplicantExamStatus) => void;
}

const SubmissionEvaluationDropdownLabel = ({
  submissionStatus,
  className,
}: {
  submissionStatus?: ApplicantExamStatus;
  className?: string;
}) => {
  let evalClassName = "not-evaluated";
  let labelId = "submission.notEvaluated";
  if (submissionStatus === ApplicantExamStatus.Passed) {
    evalClassName = "pass";
    labelId = "submission.pass";
  }
  if (submissionStatus === ApplicantExamStatus.Failed) {
    evalClassName = "fail";
    labelId = "submission.fail";
  }

  return (
    <div
      className={classNames(
        "code-submission-evaluation-dropdown__label",
        evalClassName,
        className,
      )}
    >
      <Icon type="circle" />
      <Msg id={labelId} />
    </div>
  );
};

export const SubmissionEvaluationDropdown = ({
  submissionId,
  submissionStatus,
  disabled,
  onEvaluationChange,
}: SubmissionEvaluationDropdownProps) => {
  const updateEvaluation = useEvaluateSubmission();
  const deleteEvaluation = useDeleteSubmissionEvaluation();

  const onEvaluationSelected = (newEvaluation?: SubmissionEvaluationStatus) => {
    if (newEvaluation !== submissionStatus) {
      newEvaluation !== undefined
        ? updateEvaluation.mutate(
            {
              submissionId,
              data: { status: newEvaluation },
            },
            {
              onSuccess: (data) => {
                onEvaluationChange?.(data.result.status);
              },
            },
          )
        : deleteEvaluation.mutate(submissionId, {
            onSuccess: (data) => {
              onEvaluationChange?.(data.result.status);
            },
          });
    }
  };

  if (disabled) {
    return (
      <div className="code-submission-evaluation-dropdown">
        <SubmissionEvaluationDropdownLabel
          submissionStatus={submissionStatus ?? ApplicantExamStatus.Passed}
        />
      </div>
    );
  }

  return (
    <Dropdown
      disableScrollOnOpen
      rightIcon="chevron-down"
      dropdownClassName="code-submission-evaluation-dropdown"
      placement="bottomLeft"
      trigger={{
        className:
          "code-submission-evaluation-dropdown code-submission-evaluation-dropdown__button",
        size: "small",
        outlined: true,
        label: (
          <SubmissionEvaluationDropdownLabel
            submissionStatus={submissionStatus ?? ApplicantExamStatus.Passed}
          />
        ),
      }}
    >
      {[ApplicantExamStatus.Passed, ApplicantExamStatus.Failed, undefined].map(
        (applicantExamStatus) => {
          let isSelected = applicantExamStatus === submissionStatus;

          // select 'not evaluated' option if not pass or fail
          if (
            !isSelected &&
            ![ApplicantExamStatus.Passed, ApplicantExamStatus.Failed].includes(
              submissionStatus as ApplicantExamStatus,
            ) &&
            applicantExamStatus === undefined
          ) {
            isSelected = true;
          }

          return (
            <DropdownItem
              key={applicantExamStatus ?? -1}
              onClick={() =>
                onEvaluationSelected(
                  applicantExamStatus as SubmissionEvaluationStatus | undefined,
                )
              }
              disabled={isSelected}
              className={classNames(
                "code-submission-evaluation-dropdown__item",
                {
                  selected: isSelected,
                },
              )}
            >
              <SubmissionEvaluationDropdownLabel
                submissionStatus={applicantExamStatus}
              />
            </DropdownItem>
          );
        },
      )}
    </Dropdown>
  );
};
