/**
 * get URL for the preview from editor type
 * @param editorType
 */
export function getPreviewURL(editorType: string): string {
  switch (editorType) {
    case "quiz":
      return "/editor/2.0/quiz";
    case "book":
      return "/editor/1.0/book";
    case "coding":
      return "/editor/1.0/coding";
    case "ai":
      return "/editor/1.0/ai";
    case "playback":
      return "/editor/2.0/playback";
    case "diff":
      return "/editor/2.0/diff";
    default:
      return "/editor/sorry_not_support_yet";
  }
}

/**
 * Get a list of readonly files from the files object
 *
 * @param files
 * @returns
 */
export const getReadOnlyFileSet = (files: object): string[] => {
  if (files === null || typeof files !== "object") {
    return [];
  }

  const process = (result: string[], prefix: string, files: {}) => {
    Object.entries(files).forEach(([key, value]) => {
      const isFolder = typeof value === "object" && value !== null;
      const isBoolean = typeof value === "boolean";
      const path = prefix + key;
      if (isFolder) {
        process(result, path + "/", value);
      } else if (isBoolean && value === false) {
        result.push(path);
      }
    });
    return result;
  };
  return process([], "", files);
};

export const getEditableFileSet = (files: object): string[] => {
  if (files === null || typeof files !== "object") {
    return [];
  }

  const process = (result: string[], prefix: string, files: {}) => {
    Object.entries(files).forEach(([key, value]) => {
      const isFolder = typeof value === "object" && value !== null;
      const isBoolean = typeof value === "boolean";
      const path = prefix + key;
      if (isFolder) {
        process(result, path + "/", value);
      } else if (isBoolean && value === true) {
        result.push(path);
      }
    });
    return result;
  };
  return process([], "", files);
};

/**
 * Get a list of editable files from the files object
 *
 * remove anywhere files that are readonly from presignedFiles
 *
 * @param presignedFiles
 * @param files
 * @returns
 */
export const getEditablePresignedFiles = (
  presignedFiles: object,
  files: object,
) => {
  const readonlyFiles = getReadOnlyFileSet(files);
  const result: { [key: string]: string } = {};

  Object.entries(presignedFiles).forEach(([key, value]) => {
    if (!readonlyFiles.includes(key)) {
      result[key] = value;
    }
  });

  return result;
};
