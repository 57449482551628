import * as c3 from "c3";
import "c3/c3.css";
import * as classnames from "classnames";
import { isEqual } from "lodash";
import * as React from "react";
import * as shortid from "shortid";

export interface PieChartItem {
  label: string;
  number: number;
  color?: string;
}

/**
 * Prop interface
 */
export interface PieChartProps {
  className?: string;
  items: Array<PieChartItem>;
  size?: number;
}

/**
 * React Component
 */
export default class PieChart extends React.Component<PieChartProps> {
  private chart: c3.ChartAPI;
  private id: string;

  constructor(props: PieChartProps) {
    super(props);
    this.id = `chart${shortid.generate()}`;
  }

  public componentDidMount() {
    if (this.props.items.length) {
      this.updateChart();
    }
  }

  public componentDidUpdate(prevProps: PieChartProps) {
    const { items } = this.props;
    if (items.length && !isEqual(items, prevProps.items)) {
      this.updateChart();
    }
  }

  updateChart() {
    const { items, size } = this.props;
    const mapedItems: [string, number][] = items.map((item) => [
      item.label,
      item.number,
    ]);

    this.chart = c3.generate({
      bindto: `#${this.id}`,
      data: {
        columns: mapedItems,
        type: "pie",
      },
      legend: {
        position: "right",
      },
    });

    if (size) {
      this.chart.resize({
        width: size,
      });
    }

    if (items[0].color) {
      const colors = {};
      items.forEach((item) => {
        colors[item.label] = item.color;
      });
      this.chart.data.colors(colors);
    }
  }

  public render() {
    const { className } = this.props;

    const rootStyle = classnames("code-c-pie-chart", {
      [`${className}`]: Boolean(className),
    });

    return this.props.items.length ? (
      <div className={rootStyle} id={this.id} />
    ) : (
      <div></div>
    );
  }
}
