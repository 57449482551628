import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import {
  getSubmissionResultPlaybackKeyEventLog,
  postSubmissionResultPlaybackKeyEventLog,
} from "@api/submissions";

import { useStoreContext } from "@context";

import { KeyEventModel } from "@shared/models";
import { getLastWeekTimestamp } from "@shared/services/date";
import Message from "@shared/services/message";

import { useRouteParams } from "../useRouteParams";
import { submissionResultKeys } from "./useSubmissionResults";

interface SubmissionResultPlaybackAllKeyParams {
  challengeResultId?: number;
  projectId: number;
  submissionId: number;
}

interface SubmissionResultPlaybackKeyParams
  extends Required<SubmissionResultPlaybackAllKeyParams> {
  keyEventLogId: number;
}

export const submissionResultPlaybackKeys = {
  all: [...submissionResultKeys.all, "playback"] as const,
  playbackAll: ({
    challengeResultId,
    projectId,
    submissionId,
  }: SubmissionResultPlaybackAllKeyParams) => {
    if (challengeResultId) {
      return [
        ...submissionResultPlaybackKeys.all,
        projectId,
        submissionId,
        challengeResultId,
      ];
    }

    return [...submissionResultPlaybackKeys.all, projectId, submissionId];
  },
  playback: ({ keyEventLogId, ...rest }: SubmissionResultPlaybackKeyParams) => [
    ...submissionResultPlaybackKeys.playbackAll(rest),
    keyEventLogId,
  ],
};

// TODO: investigate why the result is stored as an array
export function useGetSubmissionResultPlayback(
  challengeResultId: number,
  keyEventLogId: number,
) {
  const { projectId } = useStoreContext();
  const { submissionId } = useRouteParams();
  const enabled = Boolean(challengeResultId && keyEventLogId);
  const query = useQuery<KeyEventModel[]>({
    queryKey: submissionResultPlaybackKeys.playback({
      challengeResultId,
      keyEventLogId,
      projectId,
      submissionId,
    }),
    queryFn: async ({ signal }) => {
      const { result } = await getSubmissionResultPlaybackKeyEventLog({
        challengeResultId,
        keyEventLogId,
        projectId,
        submissionId,
        options: {
          signal,
        },
      });

      return [result];
    },
    enabled,
    initialData: [],
    initialDataUpdatedAt: getLastWeekTimestamp(),
    keepPreviousData: true,
  });

  return query;
}

export function useCreateSubmissionResultPlayback() {
  const client = useQueryClient();
  const { projectId } = useStoreContext();
  const { submissionId } = useRouteParams();
  const mutation = useMutation({
    mutationFn: ({
      challengeResultId,
      keyEventLogId,
    }: {
      challengeResultId: number;
      keyEventLogId: number;
    }) =>
      postSubmissionResultPlaybackKeyEventLog({
        challengeResultId,
        keyEventLogId,
        projectId,
        submissionId,
      }),
    onSuccess: () => {
      toast.success(
        Message.getMessageByKey("message.submission.keyEvent.process.success"),
      );
      client.invalidateQueries(
        submissionResultKeys.resultAll(projectId, submissionId),
      );
    },
    onError: (e: string) => {
      if (e) {
        toast.error(e);
      }
    },
  });

  return mutation;
}
