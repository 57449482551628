import * as d3 from "d3";
import { useEffect } from "react";

import { adjustTextPosition, getChartXPosition } from "@shared/services/chart";
import { ChartColor } from "@shared/services/enums";

export interface ChartBarLabelProps {
  id: string;
  chart: c3.ChartAPI;
  label: string;
  tickPosition: number;
  color?: ChartColor;
}

export const ChartBarLabel = ({
  id,
  chart,
  tickPosition,
  label,
  color = ChartColor.Primary600,
}: ChartBarLabelProps) => {
  useEffect(() => {
    const element = document.getElementById(id);

    if (!element || typeof chart.internal.x === "undefined") {
      return;
    }

    const svg = d3.select(element).select("svg");

    const chartLabelClass = "code-chart-label";
    svg.selectAll(`.${chartLabelClass}`).remove();

    // calculate x position
    const xPlacement = getChartXPosition({
      xScale: chart.internal.x,
      chartElement: element,
      tickPosition,
    });

    // calculate y position of text element
    // should be right above the bar element
    // note: this might not work properly if there are two bars side by side
    const bar = svg.select(".c3-chart-bars").select(`.c3-bar-${tickPosition}`);

    const rootDom = (svg.node() as Element)?.getBoundingClientRect?.();
    const rect = (bar.node() as Element)?.getBoundingClientRect();
    if (rootDom === undefined || rect === undefined) {
      // svg element is not rendered yet, so we skip this effect
      return;
    }
    const chartTopSpacing = Math.abs(rootDom.top - rect.top);

    const chartLabelSvg = svg.append("g").attr("class", chartLabelClass);
    const outlineColor = "#fff";

    const textElement = chartLabelSvg
      .append("text")
      .attr("x", xPlacement)
      .attr("y", chartTopSpacing - 5)
      .text(label)
      .style("text-anchor", "middle")
      .style("font-weight", 700)
      .style("fill", color)
      .style("font-size", "13px")
      .style(
        "text-shadow",
        `-1px -1px 0 ${outlineColor}, 1px -1px 0 ${outlineColor}, -1px 1px 0 ${outlineColor}, 1px 1px 0 ${outlineColor}`,
      );

    adjustTextPosition({
      chartElement: element,
      textElement,
    });
  }, [id, chart?.internal.x, tickPosition, label, color]);

  return null;
};
