import { AxiosRequestConfig } from "axios";

import { ReviewModel, ReviewerUserModel } from "@shared/models";

import { ApiResponse, get, post, put } from "../httpClient";

export interface PingSubmissionReviewerPayload {
  reviewerId: number;
}

export interface SubmissionReviewPayload {
  feedback: string;
  score: number;
}

export interface SubmissionReviewersResponse {
  pendingReviewers: ReviewerUserModel[];
  reviews: ReviewModel[];
}

export interface UpdateSubmissionReviewerResponse {
  applicantExamId: number;
  createdAt: string;
  id: number;
  isStale: boolean;
  reviewer: ReviewModel;
  score: number;
  updatedAt: string;
  userId: number;
}

interface PutSubmissionReviewParams {
  reviewerId: number;
  projectId: number;
  submissionId: number;
  options: AxiosRequestConfig<SubmissionReviewPayload>;
}

export async function getSubmissionReviewers(
  projectId: number,
  submissionId: number,
  options?: AxiosRequestConfig,
) {
  const { data } = await get<SubmissionReviewersResponse>(
    `/api/projects/${projectId}/submissions/${submissionId}/reviews/v2`,
    options,
  );

  return data;
}

export async function createSubmissionReview(
  submissionId: number,
  projectId: number,
  options: AxiosRequestConfig<SubmissionReviewPayload>,
) {
  const { data } = await post<ApiResponse<UpdateSubmissionReviewerResponse>>(
    `/api/projects/${projectId}/submissions/${submissionId}/reviews`,
    options,
  );

  return data;
}

export async function putSubmissionReview({
  reviewerId,
  projectId,
  submissionId,
  options,
}: PutSubmissionReviewParams) {
  const { data } = await put<ApiResponse<UpdateSubmissionReviewerResponse>>(
    `/api/projects/${projectId}/submissions/${submissionId}/reviews/${reviewerId}`,
    options,
  );

  return data;
}

export async function pingReviewer(
  submissionId: number,
  projectId: number,
  options: AxiosRequestConfig<PingSubmissionReviewerPayload>,
) {
  const { data } = await post<ApiResponse<string>>(
    `/api/projects/${projectId}/submissions/${submissionId}/pingreviewer`,
    options,
  );

  return data;
}
