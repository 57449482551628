import * as classnames from "classnames";

import {
  SubMenuLabel,
  SubMenuList,
  SubMenuItem,
  Restricted,
  Msg,
  SubMenuListToggle,
} from "@shared/components";
import { ExamModel } from "@shared/models";
import { UserRole, ProjectRole } from "@shared/services/enums";
import Message from "@shared/services/message";

/**
 * Props interface
 */
interface SubmissionMenuProps {
  counts: ExamModel["counts"];
  isAssignedReviewer?: boolean;
  navigationBaseURL: string;
  shouldShowSubmittedAsMenuList?: boolean;
}

interface SubmissionMenuItemProps {
  messageKey: string;
  pathname: string;
  count: number;
  state?: Record<string, any>;
}

/**
 * Component
 * @param props SubmissionMenuProps
 */
export function SubmissionMenu(props: SubmissionMenuProps) {
  const {
    counts: {
      sentDeliveryExceptArchivedCount = 0,
      unreadDeliveryCount = 0,
      inProgressCount = 0,
      submittedCount = 0,
      inReviewCount = 0,
      expiredCount = 0,
      archivedCount = 0,
      approvedCount = 0,
      rejectedCount = 0,
    },
    navigationBaseURL,
    isAssignedReviewer,
    shouldShowSubmittedAsMenuList = false,
  } = props;
  const rootStyle = classnames("code-submission-menu");

  const SubmissionMenuItem = ({
    messageKey = "",
    pathname = "",
    count = 0,
  }: SubmissionMenuItemProps) => (
    <SubMenuItem
      label={`${Message.getMessageByKey(messageKey)} (${count})`}
      route={{ pathname: `${navigationBaseURL}/${pathname}` }}
    />
  );

  const getSubmissionToggleItem = ({
    messageKey = "",
    pathname = "",
    count = 0,
  }: SubmissionMenuItemProps) => ({
    label: `${Message.getMessageByKey(messageKey)} (${count})`,
    route: `${navigationBaseURL}/${pathname}`,
  });

  return (
    <div className={rootStyle}>
      <SubMenuLabel>
        <Msg id="common.applicants" />
      </SubMenuLabel>
      <SubMenuList ariaLabel="Submission Side Menu">
        <SubmissionMenuItem
          messageKey="common.all"
          pathname="submissions_all"
          count={sentDeliveryExceptArchivedCount}
        />
        <SubMenuList>
          <SubmissionMenuItem
            messageKey="applicantExamStatus.unread"
            pathname="submissions_unread"
            count={unreadDeliveryCount}
          />
          <SubmissionMenuItem
            messageKey="applicantExamStatus.inProgress"
            pathname="submissions_inprogress"
            count={inProgressCount}
          />
          {shouldShowSubmittedAsMenuList ? (
            <SubMenuListToggle
              defaultExpanded
              item={getSubmissionToggleItem({
                messageKey: "applicantExamStatus.submitted",
                pathname: "submissions_submitted",
                count: submittedCount + inReviewCount,
              })}
              subItems={[
                getSubmissionToggleItem({
                  messageKey: "applicantExamStatus.inReview",
                  pathname: "submissions_inreview",
                  count: submittedCount,
                }),
                getSubmissionToggleItem({
                  messageKey: "submission.reviewed",
                  pathname: "submissions_reviewed",
                  count: inReviewCount,
                }),
              ]}
            />
          ) : (
            <SubmissionMenuItem
              messageKey="applicantExamStatus.submitted"
              pathname="submissions_submitted"
              count={submittedCount + inReviewCount}
            />
          )}
          <SubMenuListToggle
            defaultExpanded
            item={getSubmissionToggleItem({
              messageKey: "submission.evaluated",
              pathname: "submissions_evaluated",
              count: approvedCount + rejectedCount,
            })}
            subItems={[
              getSubmissionToggleItem({
                messageKey: "applicantExamStatus.passed",
                pathname: "submissions_passed",
                count: approvedCount,
              }),
              getSubmissionToggleItem({
                messageKey: "applicantExamStatus.failed",
                pathname: "submissions_failed",
                count: rejectedCount,
              }),
            ]}
          />
          <SubmissionMenuItem
            messageKey="applicantExamStatus.expired"
            pathname="submissions_expired"
            count={expiredCount}
          />
        </SubMenuList>
        <Restricted
          roles={[UserRole.SystemAdmin, ProjectRole.ProjectAdmin]}
          allow={isAssignedReviewer}
        >
          <SubmissionMenuItem
            messageKey="common.archived"
            pathname="submissions_archived"
            count={archivedCount}
          />
        </Restricted>
      </SubMenuList>
    </div>
  );
}
