import { combineEpics } from "redux-observable";

import {
  applicantActionLogGetEpic,
  postApplicantActionLogGetEpic,
} from "./ActionLogEpic";
import adminChallengeCollectionEpic from "./AdminChallengeCollectionEpic";
import adminChallengeEpic from "./AdminChallengeEpic";
import adminExamEpic from "./AdminExamEpic";
import adminOrgsEpic from "./AdminOrgsEpic";
import adminProjectEpic from "./AdminProjectEpic";
import adminSSOEpic from "./AdminSSOEpic";
import adminSystemAdminEpic from "./AdminSystemAdminEpic";
import adminUserEpic from "./AdminUserEpic";
import ajaxEpics from "./AjaxEpic";
import applicantEpic from "./ApplicantEpic";
import applicantTimerEpic from "./ApplicantTimerEpic";
import authEpics from "./AuthEpic";
import challengeEpics from "./ChallengeEpic";
import examEpics from "./ExamEpic";
import externalSystemEpic from "./ExternalSystemEpic";
import globalEpic from "./GlobalEpic";
import guestSharingEpic from "./GuestSharingEpic";
import healthCheckEpic from "./HealthCheckEpic";
import ImageUploadEpic from "./ImageUploadEpic";
import orgsEpics from "./OrgsEpic";
import projectEpics from "./ProjectEpic";
import questionEpics from "./QuestionEpic";
import readmeEpic from "./ReadmeEpic";
import reportEpic from "./ReportEpic";
import tutorialEpic from "./TutorialEpic";
import userEpics from "./UserEpic";

const epics = combineEpics(
  ...ajaxEpics,
  ...userEpics,
  ...authEpics,
  ...orgsEpics,
  ...examEpics,
  ...projectEpics,
  ...challengeEpics,
  ...questionEpics,
  ...applicantEpic,
  ...applicantTimerEpic,
  ...readmeEpic,
  ...globalEpic,
  ...tutorialEpic,
  ...healthCheckEpic,
  ...reportEpic,
  ...ImageUploadEpic,
  postApplicantActionLogGetEpic,
  applicantActionLogGetEpic,
);

const adminEpics = combineEpics(
  ...globalEpic,
  ...ajaxEpics,
  ...authEpics,
  ...externalSystemEpic,
  ...readmeEpic,
  ...adminChallengeEpic,
  ...adminProjectEpic,
  ...adminSystemAdminEpic,
  ...adminOrgsEpic,
  ...adminUserEpic,
  ...adminExamEpic,
  ...adminChallengeCollectionEpic,
  ...adminSSOEpic,
  ...ImageUploadEpic,
);

const guestEpics = combineEpics(
  ...ajaxEpics,
  ...readmeEpic,
  ...guestSharingEpic,
);

export { epics, adminEpics, guestEpics };
