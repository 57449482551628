import {
  Action,
  APIResponseAction,
  CurrentProjectSwitchAction,
  projectMemberListGetAction,
  projectMemberUpdateAction,
  projectMemberInviteAction,
  projectListGetAction,
  projectListAddAction,
  projectListRemoveAction,
  projectGetAction,
  projectErrorAction,
  projectDeleteAction,
  projectMemberListAddAction,
  currentProjectSetAction,
  projectSetSwitcherAction,
  projectUpdateSwitcherAction,
  projectAddSwitcherAction,
  projectRemoveSwitcherAction,
  projectInviteListGetAction,
  userGetAction,
  projectInviteUpdateAction,
  projectSlackGetAction,
  projectSocialDeleteAction,
  projectSocialChannelsGetAction,
  projectSocialChannelAddAction,
  projectSocialChannelUpdateAction,
  projectSocialChannelDeleteAction,
  projectSocialUserGetAction,
  projectSocialUserUpdateAction,
  projectSocialChannelMoveStep2Action,
} from "../actions";
import {
  PaginationModel,
  ProjectModel,
  MemberListModel,
  ProjectListModel,
  ProjectContractModel,
  UserModel,
  PendingInviteModel,
  SlackModel,
  SlackChannelModel,
  SlackIntegrationModel,
  SlackIntegrationUserSettingModel,
  ProjectSwitchItemModel,
} from "../shared/models";

export interface ProjectState {
  currentProjectId: number;
  projectDetail: ProjectModel;
  projectList: Array<ProjectListModel>;
  projectPagination: PaginationModel;
  projectMemberList: Array<MemberListModel>;
  projectMemberPagination: PaginationModel;
  projectSwitcher: Array<ProjectSwitchItemModel>;
  error: boolean;
  updatingUserLoading: boolean;
  updatingUserError: boolean;
  projectContractList: Array<ProjectContractModel>;
  projectContractListLoading: boolean;
  projectContractListError: boolean;
  projectMemberListLoading: boolean;
  // TODO 'user' is duplicated with data in UserReducer. Should be deleted when it's ready and add 'store.getState().user.user'
  user?: UserModel;
  pendingInviteList: PendingInviteModel[];
  pendingInviteListPagination: PaginationModel;
  pendingInviteListCondition: {};
  pendingInviteListLoading: boolean;
  pendingInviteListError: boolean;
  pendingInviteMemberLoading: boolean;
  // Slack Integration
  projectSlackSocial: SlackModel | null;
  projectSlackSocialDenied: boolean;
  projectSlackSocialLoading: boolean;
  projectSlackSocialChannels: SlackChannelModel[];
  projectSlackUserSettings: SlackIntegrationUserSettingModel | null;
}

export const initialState = {
  currentProjectId: 0,
  projectDetail: {} as ProjectModel,
  projectList: [],
  projectPagination: new PaginationModel(),
  projectMemberList: [],
  projectMemberPagination: new PaginationModel(),
  projectSwitcher: [],
  error: false,
  updatingUserLoading: false,
  updatingUserError: false,
  projectContractList: [],
  projectContractListLoading: false,
  projectContractListError: false,
  projectMemberListLoading: false,
  pendingInviteList: [],
  pendingInviteListPagination: new PaginationModel(),
  pendingInviteListCondition: {},
  pendingInviteListLoading: false,
  pendingInviteListError: false,
  pendingInviteMemberLoading: false,
  projectSlackSocial: null,
  projectSlackSocialDenied: false,
  projectSlackSocialLoading: false,
  projectSlackSocialChannels: [],
  projectSlackUserSettings: null,
};

export const projectReducer = (
  state: ProjectState = initialState,
  action: Action,
) => {
  const payload = (action as APIResponseAction).payload;

  switch (action.type) {
    case projectGetAction.types.request:
      return {
        ...state,
        projectDetail: {} as ProjectModel,
        error: false,
      };
    case projectGetAction.types.success:
      return {
        ...state,
        projectDetail: new ProjectModel(payload.result),
      };

    case projectErrorAction.types.request:
      return { ...state, error: true };

    case projectDeleteAction.types.request:
      return {
        ...state,
        projectDetail: {} as ProjectModel,
      };

    case projectListGetAction.types.success:
      return {
        ...state,
        projectList: payload.result,
        projectPagination: new PaginationModel(payload.pagination),
      };

    case projectListAddAction.types.request: {
      const updateProjectIndex = state.projectList.findIndex(
        (project) => project.id === (payload.result as ProjectListModel).id,
      );
      const updateProject =
        state.projectList.find(
          (project) => project.id === (payload.result as ProjectListModel).id,
        ) ||
        new ProjectListModel({
          memberCount: 0,
          deliveryCount: 0,
          examCount: 0,
        });

      const updatedList = [...state.projectList];

      const updatedProject = new ProjectListModel(
        Object.assign({}, updateProject, payload.result),
      );

      if (updateProjectIndex >= 0) {
        updatedList.splice(updateProjectIndex, 1, updatedProject);
      } else {
        updatedList.unshift(updatedProject);
      }

      return { ...state, projectList: updatedList };
    }
    case projectListRemoveAction.types.request:
      return {
        ...state,
        projectList: state.projectList.filter(
          (project) => project.id !== (action.payload as number),
        ),
      };

    case projectSlackGetAction.types.request:
      return {
        ...state,
        projectSlackSocial: null,
        projectSlackSocialLoading: true,
        projectSlackSocialChannels: [],
      };

    case projectSlackGetAction.types.success:
      return {
        ...state,
        projectSlackSocial: payload?.result ?? null,
        projectSlackSocialLoading: false,
      };

    case projectSlackGetAction.types.failure:
      return {
        ...state,
        projectSlackSocial: null,
        projectSlackSocialLoading: false,
      };

    case projectSocialDeleteAction.types.success:
      return {
        ...state,
        projectSlackSocial: null,
      };

    case projectSocialChannelsGetAction.types.success:
      return {
        ...state,
        projectSlackSocialDenied: false,
        projectSlackSocialChannels: payload.result,
      };
    case projectSocialChannelsGetAction.types.failure:
      return {
        ...state,
        projectSlackSocialDenied: true,
        projectSlackSocialChannels: [],
      };

    case projectSocialChannelMoveStep2Action.types.success: {
      const newIntegration = payload.result as SlackIntegrationModel;
      const extraPayload = payload.extra as {
        projectId: number;
        userId: number;
        socialId?: number;
        integrationId?: number;
      };

      const updatedIntegrations = [
        ...(state.projectSlackSocial?.integrations ?? []),
        newIntegration,
      ].filter((integration) => integration.id !== extraPayload?.integrationId);

      return {
        ...state,
        projectSlackSocial: {
          ...state.projectSlackSocial,
          integrations: updatedIntegrations,
        },
      };
    }
    case projectSocialChannelAddAction.types.success: {
      const updatedSlackIntegration = {
        ...state.projectSlackSocial,
        integrations: [
          ...(state.projectSlackSocial?.integrations ?? []),
          payload.result,
        ],
      };

      return {
        ...state,
        projectSlackSocial: updatedSlackIntegration,
      };
    }

    case projectSocialChannelUpdateAction.types.success:
      return {
        ...state,
        projectSlackSocial: {
          ...state.projectSlackSocial,
          integrations: (state.projectSlackSocial?.integrations ?? []).map(
            (integration) =>
              integration.id === (payload.result as SlackIntegrationModel)?.id
                ? payload.result
                : integration,
          ),
        },
      };

    case projectSocialChannelMoveStep2Action.types.failure:
    case projectSocialChannelDeleteAction.types.success:
      const payloadResult = payload.result as {
        integrationId: number;
        projectId: number;
        socialId: number;
      };

      const updatedSlackIntegration = {
        ...state.projectSlackSocial,
        integrations: (state.projectSlackSocial?.integrations ?? []).filter(
          (integration) => integration.id !== payloadResult?.integrationId,
        ),
      };

      return {
        ...state,
        projectSlackSocial: updatedSlackIntegration,
      };

    case projectSocialUserGetAction.types.request:
    case projectSocialUserGetAction.types.failure:
      return {
        ...state,
        projectSlackUserSettings: null,
      };

    case projectSocialUserGetAction.types.success:
      return {
        ...state,
        projectSlackUserSettings: payload.result,
      };

    case projectSocialUserUpdateAction.types.success:
      return {
        ...state,
        projectSlackUserSettings: payload.result,
      };

    case projectMemberListGetAction.types.request:
      const skipReset = (payload as { skipReset?: boolean }).skipReset;
      if (skipReset) {
        // Don't empty the list and don't cause a visible refresh
        return state;
      }
      return {
        ...state,
        projectMemberListLoading: true,
        projectMemberList: [],
        projectMemberPagination: new PaginationModel(),
      };

    case projectMemberListGetAction.types.success:
      return {
        ...state,
        projectMemberListLoading: false,
        projectMemberList: (payload.result as Array<MemberListModel>).map(
          (member) =>
            new MemberListModel({ ...member, roles: member.roles.sort() }),
        ),
        projectMemberPagination: new PaginationModel(payload.pagination),
      };

    case projectMemberListAddAction.types.request:
      return {
        ...state,
        projectMemberList: [payload.result, ...state.projectMemberList],
      };

    case projectMemberInviteAction.types.request:
    case projectMemberUpdateAction.types.request:
      return {
        ...state,
        updatingUserLoading: true,
        updatingUserError: false,
      };
    case projectMemberInviteAction.types.failure:
    case projectMemberUpdateAction.types.failure:
      return {
        ...state,
        updatingUserLoading: false,
        updatingUserError: true,
      };
    case projectMemberUpdateAction.types.success:
      const updateMemberIndex = state.projectMemberList.findIndex(
        (member: MemberListModel) =>
          member.id === (payload.result as MemberListModel).id,
      );
      const updatedMember = new MemberListModel(
        Object.assign(
          {},
          state.projectMemberList[updateMemberIndex],
          payload.result,
        ),
      );
      const updatedMemberList = [...state.projectMemberList];
      updatedMemberList.splice(updateMemberIndex, 1, updatedMember);

      return {
        ...state,
        projectMemberList: updatedMemberList,
        updatingUserLoading: false,
      };

    case projectInviteUpdateAction.types.request:
      return {
        ...state,
        pendingInviteMemberLoading: true,
        pendingInviteListError: false,
      };
    case projectInviteUpdateAction.types.failure:
      return {
        ...state,
        pendingInviteMemberLoading: false,
        pendingInviteListError: true,
      };
    case projectInviteUpdateAction.types.success:
      const updatePendingMemberIndex = state.pendingInviteList.findIndex(
        (member) => member.id === (payload.result as PendingInviteModel).id,
      );
      const updatedPendingMember = new PendingInviteModel({
        ...state.pendingInviteList[updatePendingMemberIndex],
        ...payload.result,
      });
      const pendingInviteList = [...state.pendingInviteList];
      pendingInviteList.splice(
        updatePendingMemberIndex,
        1,
        updatedPendingMember,
      );

      return {
        ...state,
        pendingInviteList,
        pendingInviteMemberLoading: false,
      };

    case projectMemberInviteAction.types.success:
      return {
        ...state,
        updatingUserLoading: false,
        updatingUserError: false,
      };

    case userGetAction.types.success:
      return {
        ...state,
        user: new UserModel({ ...state.user, ...payload.result }),
      };

    case currentProjectSetAction.types.request:
      return {
        ...state,
        currentProjectId:
          (action as CurrentProjectSwitchAction).payload.projectId ||
          state.user?.projects[0]?.id ||
          0,
      };

    case projectSetSwitcherAction.types.request:
      return {
        ...state,
        projectSwitcher: action.payload,
      };

    case projectAddSwitcherAction.types.request:
      return {
        ...state,
        projectSwitcher: [...state.projectSwitcher, payload.result],
      };

    case projectRemoveSwitcherAction.types.request:
      let { currentProjectId } = state;
      const projectSwitcher = state.projectSwitcher.filter(
        (project) => project.id !== action.payload,
      );
      if (action.payload === currentProjectId && projectSwitcher.length) {
        currentProjectId = projectSwitcher[0].id;
      }
      return {
        ...state,
        projectSwitcher,
        currentProjectId,
      };
    case projectUpdateSwitcherAction.types.request: {
      const updatedProject = payload.result as ProjectSwitchItemModel;
      return {
        ...state,
        projectSwitcher: state.projectSwitcher.map((project) => {
          if (project.id === updatedProject.id) {
            return updatedProject;
          } else {
            return project;
          }
        }),
      };
    }

    case projectInviteListGetAction.types.request: {
      return {
        ...state,
        pendingInviteListCondition: action.payload,
        pendingInviteListLoading: true,
      };
    }

    case projectInviteListGetAction.types.success: {
      return {
        ...state,
        pendingInviteList: (payload.result as PendingInviteModel[]).map(
          (item) => new PendingInviteModel(item),
        ),
        pendingInviteListPagination: new PaginationModel(payload.pagination),
        pendingInviteListLoading: false,
      };
    }

    case projectInviteListGetAction.types.failure: {
      return { ...state, pendingInviteListLoading: false };
    }

    default:
      return state;
  }
};
