"use strict";

if (typeof Promise === "undefined") {
  // Rejection tracking prevents a common issue where React gets into an
  // inconsistent state due to an error, but it gets swallowed by a Promise,
  // and the user has no idea what causes React"s erratic future behavior.
  require("promise/lib/rejection-tracking").enable();
  window.Promise = require("promise/lib/es6-extensions.js");
}

// ---------------------------------------------------------------
// Intl polyfill
// ---------------------------------------------------------------
import "@formatjs/intl-relativetimeformat/polyfill";
import "@formatjs/intl-relativetimeformat/locale-data/ja";

import "@formatjs/intl-pluralrules/polyfill";
import "@formatjs/intl-pluralrules/locale-data/ja";

// fetch() polyfill for making API calls.
require("whatwg-fetch");

// Object.assign() is commonly used with React.
// It will use the native implementation if it"s present and isn"t buggy.
Object.assign = require("object-assign");

require("core-js/es6/array");
require("core-js/es6/string");
require("core-js/es6/object");
require("core-js/es6/number");
require("core-js/es6/symbol");
require("core-js/fn/array/includes");
