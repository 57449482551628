import * as classNames from "classnames";
import * as React from "react";

import { Icon, Msg, Tooltip } from "@shared/components";

interface Props {
  children: React.ReactNode;
  labelKey?: string;
  isDiff?: boolean;
  isDuplicate?: boolean;
  className?: string;
}

const SubmissionResultActionLogCardFieldContext = React.createContext<{
  isDiff?: boolean;
  warn?: boolean;
}>({});

const useSubmissionResultActionLogCardFieldContext = () =>
  React.useContext(SubmissionResultActionLogCardFieldContext);

function SubmissionResultActionLogCardField({
  children,
  labelKey,
  isDiff,
  isDuplicate,
  className,
}: Props) {
  const [isWarn, setIsWarn] = React.useState<boolean>();
  const [isDataDiff, setDataDiff] = React.useState<boolean>();

  React.useEffect(() => {
    setIsWarn(isDiff || isDuplicate);
    setDataDiff(isDiff);
  }, [isDiff, isDuplicate]);

  const rootStyle = classNames(
    "code-c-submission-result-action-log__card-field",
    className,
  );

  return (
    <SubmissionResultActionLogCardFieldContext.Provider
      value={{ isDiff: isDataDiff, warn: isWarn }}
    >
      <article className={rootStyle}>
        {labelKey && (
          <h3>
            <Msg id={labelKey} />:
          </h3>
        )}
        {children}
      </article>
    </SubmissionResultActionLogCardFieldContext.Provider>
  );
}

SubmissionResultActionLogCardField.Indicators = function Indicators({
  children,
  tooltipContent,
}: {
  children?: React.ReactNode;
  tooltipContent?: string;
}) {
  const { isDiff } = useSubmissionResultActionLogCardFieldContext();

  return (
    <>
      {isDiff && (
        <Tooltip text={tooltipContent}>
          <Icon
            type="exclamation-triangle"
            className="code-c-icon__text-base has-text-yellow-500"
            iconClassName="code-c-icon__text-base"
          />
        </Tooltip>
      )}
      {children}
    </>
  );
};

SubmissionResultActionLogCardField.Content = function Content({
  children,
}: {
  children?: React.ReactNode;
}) {
  const { warn } = useSubmissionResultActionLogCardFieldContext();
  const classes = classNames({
    "has-text-yellow-700": warn,
  });

  return <p className={classes}>{children}</p>;
};

export default SubmissionResultActionLogCardField;
