import { useEffect, useState } from "react";
import * as ReactDOM from "react-dom";

interface PortalProps {
  children: React.ReactNode;
}

export const Portal = ({ children }: PortalProps) => {
  const [el] = useState(() => document.createElement("div"));

  useEffect(() => {
    let modalRoot = document.getElementById("portal-root");

    // create a new portal root if it doesn't exist
    if (!modalRoot) {
      const body = document.getElementsByTagName("BODY")[0];

      const newPortalRoot = document.createElement("div");
      newPortalRoot.id = "portal-root";

      body.appendChild(newPortalRoot);

      modalRoot = document.getElementById("portal-root");
    }

    modalRoot?.appendChild(el);

    return () => {
      modalRoot?.removeChild(el);
    };
  }, [el]);

  return ReactDOM.createPortal(children, el);
};
