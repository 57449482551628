import classnames from "classnames";
import * as React from "react";

import { Msg } from "..";

export interface SearchBoxProps {
  autocomplete?: "off";
  children?: React.ReactNode;
  className?: string;
  enabledClear?: boolean;
  label?: string;
  maxLength?: number;
  placeholder?: string;
  title?: string;
  value?: string;
  renderValue?: React.ReactNode;
  onChange?: (value: string) => void;
  onClear?: () => void;
}

export default React.forwardRef(function SearchBox(
  {
    autocomplete,
    children,
    className,
    enabledClear,
    label,
    maxLength,
    placeholder,
    title,
    value = "",
    renderValue,
    onChange,
    onClear,
  }: SearchBoxProps,
  ref,
) {
  const rootStyle = classnames("code-c-search-box", className);

  const inputRef = React.useRef<HTMLInputElement | null>(null);
  const [keyword, setKeyword] = React.useState(value);
  const [focused, setFocused] = React.useState(false);
  const disabledClear = !keyword && !enabledClear;

  const clearAllStyle = classnames("code-c-search-box__clear", {
    disabled: disabledClear,
  });

  const inputStyle = classnames("code-c-search-box__input-container", {
    active: focused,
  });

  const handleSearch = (e: React.FormEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setKeyword(value);
    onChange?.(value);
  };

  const handleClickInput = () => {
    inputRef.current?.focus();
  };

  const handleFocus = (value: boolean) => () => {
    setFocused(value);
  };

  const handleClickClearAll = () => {
    if (disabledClear) {
      return;
    }

    setKeyword("");
    onClear?.();
  };

  React.useEffect(() => {
    setKeyword(value);
  }, [value]);

  return (
    <div className={rootStyle}>
      {title && <h3 className="code-c-search-box__header">{title}</h3>}
      <div className="code-c-search-box__content">
        <div
          className="code-c-search-box__container"
          onClick={handleClickInput}
        >
          <label className="code-c-search-box__label" htmlFor="search-box">
            {label}
          </label>
          <div className={inputStyle}>
            {renderValue}
            <input
              ref={(node) => {
                inputRef.current = node;
                if (typeof ref === "function") {
                  ref(node);
                } else if (ref) {
                  ref.current = node;
                }
              }}
              autoComplete={autocomplete}
              maxLength={maxLength}
              name="search-box"
              className="code-c-search-box__input"
              value={keyword}
              placeholder={placeholder}
              onChange={handleSearch}
              onFocus={handleFocus(true)}
              onBlur={handleFocus(false)}
            />
          </div>
          <div className="align-right">
            <span
              className={clearAllStyle}
              role="button"
              onClick={handleClickClearAll}
            >
              <Msg id="clear.all" />
            </span>
          </div>
        </div>
        {children}
      </div>
    </div>
  );
});
