import { useEffect } from "react";
import { useFormContext } from "react-hook-form";

import {
  Checkbox,
  ErrorIcon,
  ErrorMessage,
  FormGroup,
  Input,
  Label,
  Msg,
  QuickHelp,
  Select,
  SelectItem,
  Tooltip,
} from "@shared/components";
import Message from "@shared/services/message";

import { ExamSettingsFormValidation } from "../utils";

interface AtsIntegrationCheckboxProps {
  readOnly?: boolean;
  isEmailDelivery?: boolean;
  isIntegrateATSAllowed?: boolean;
  isMultiLanguage?: boolean;
}

export const AtsIntegrationCheckbox = ({
  readOnly,
  isEmailDelivery,
  isIntegrateATSAllowed,
  isMultiLanguage,
}: AtsIntegrationCheckboxProps) => {
  const {
    register,
    watch,
    resetField,
    formState: { errors },
  } = useFormContext<ExamSettingsFormValidation>();

  const isAtsEnabled = watch("atsIntegrationEnabled");

  useEffect(() => {
    if (isMultiLanguage) {
      resetField("language");
    }
  }, [isAtsEnabled, isMultiLanguage, resetField]);

  return (
    <div className="code-exam-create-new__settings__ats-integration">
      <Tooltip
        disabled={
          readOnly
            ? isIntegrateATSAllowed
            : isIntegrateATSAllowed || isAtsEnabled
        }
        text={<Msg id="tier.disabled" />}
      >
        <Checkbox
          readOnly={readOnly || !isIntegrateATSAllowed}
          dataTestautomationid="atsIntegrationCheckbox"
          {...register("atsIntegrationEnabled")}
        >
          <span className="exam-label">
            <Msg id="exam.atsIntegration" />
            {!isIntegrateATSAllowed && isAtsEnabled && <ErrorIcon />}
          </span>
        </Checkbox>
      </Tooltip>
      {isAtsEnabled && (
        <div className="code-exam-create-new__settings__ats-integration__options">
          {isMultiLanguage && (
            <FormGroup>
              <Label>
                <Msg id="common.language" />
                <QuickHelp
                  text={Message.getMessageByKey("exam.language.tooltip")}
                  maxSize="xlarge"
                />
              </Label>
              <Select
                disabled={readOnly}
                {...register("language")}
                options={
                  [
                    {
                      label: Message.getMessageByKey("ja"),
                      value: "ja",
                    },
                    {
                      label: Message.getMessageByKey("en"),
                      value: "en",
                    },
                  ] as SelectItem[]
                }
              />
            </FormGroup>
          )}
          <div>
            <Label
              isOptional={true}
              title={<Msg id="exam.timeLimitLabel" />}
              rightSection={
                <QuickHelp
                  text={Message.getMessageByKey(
                    isEmailDelivery
                      ? "exam.atsIntegration.emailTooltip"
                      : "exam.atsIntegration.idTooltip",
                  )}
                  maxSize="xlarge"
                />
              }
            />
            <FormGroup inline>
              <Input
                type="number"
                min="1"
                max="365"
                step="1"
                disabled={readOnly}
                className="code-exam-create-new__settings__days-input"
                {...register("atsDynamicDeadlineDays", {
                  setValueAs: (value) =>
                    value === "" ? undefined : Number(value),
                })}
                hasError={Boolean(errors?.atsDynamicDeadlineDays?.message)}
              />
              <Msg id={"unit.days"} />
            </FormGroup>
            <ErrorMessage message={errors?.atsDynamicDeadlineDays?.message} />
          </div>
        </div>
      )}
    </div>
  );
};
