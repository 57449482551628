import { connect } from "react-redux";
import { Action, Dispatch } from "redux";

import { authSignupAction, authInvitationAction } from "@actions";

import { RootState } from "@reducers";

import Registration, { ExternalProps } from "./Registration";

const mapStateToProps = (state: RootState, props: ExternalProps) => ({
  language: state.intl.locale,
  submitting: Boolean(state.ajax.submitting),
  invitation: state.auth.invitation,
  success: state.auth.invitationSuccess,
  error: state.auth.invitationError,
  ...props,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  signUp: (payload: {}) => {
    dispatch(authSignupAction.request(payload));
  },
  getInvitationDetails: (payload: {}) => {
    dispatch(authInvitationAction.request(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Registration);
