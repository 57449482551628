export const swapValueWithIndex = ({
  arr,
  value,
  index,
}: {
  arr: any[];
  value: any;
  index: number;
}) => {
  const newArr = [...arr];
  const valueIndex = newArr.indexOf(value);

  if (valueIndex !== -1 && index < arr.length) {
    [newArr[valueIndex], newArr[index]] = [newArr[index], newArr[valueIndex]];
  }

  return newArr;
};
