import * as classnames from "classnames";
import { isEqual } from "lodash";
import * as React from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";

import {
  Button,
  ButtonProps,
  Checkbox,
  CheckboxProps,
  CustomForm,
  CustomFormProps,
  DatePicker,
  DatePickerProps,
  FormGroup,
  ImageUpload,
  ImageUploadProps,
  Input,
  InputProps,
  LanguageSelect,
  Radio,
  RadioProps,
  RadioPanel,
  RadioPanelProps,
  RichMarkdown,
  RichMarkdownProps,
  RichTextarea,
  RichTextareaConnectProps,
  Select,
  SelectProps,
  SubMenuCheckboxItem,
  SubMenuCheckboxItemProps,
  SwitchContainer,
  SwitchContainerProps,
  SwitchPanel,
  SwitchPanelProps,
  Textarea,
  TextareaProps,
} from "..";
import { CustomFormDefinitionType } from "../../services/enums";
import {
  jsonToJoi,
  formatFormValues,
  getValidationError,
  getValidationOptions,
} from "../../services/validation";

/**
 * Prop interface
 */
export interface FormProps {
  autoComplete?: boolean;
  className?: string;
  /**
   * A boolean which will clear the form values on re-render when set to true.
   */
  clear?: boolean;
  /**
   * An object of error strings with the form input/select element names as the keys
   */
  error?: { [key: string]: string };
  /**
   * Object of initial values for form elements,
   *
   * i.e.
   * Populated from redux store.
   */
  initialValues?: {};
  /**
   * Show all errors from the initial state
   */
  showAllErrors?: boolean;
  /**
   * Set key and true to show an error from initially
   *
   * @example
   *
   * { name: true } // always show error for the name property
   */
  showError?: { [key: string]: boolean };
  /**
   * A boolean to let the form know that the data is being sent currently, to disable submitting multiple times.
   */
  submitting?: boolean;
  /**
   * Object for merging changes from outside the Form component.
   *
   * i.e.
   * Populated by onFormChange callback, etc.
   */
  updateValues?: {};
  /**
   * Object describing the validation rules with the input/select names as keys.
   *
   * e.g.
   * validation: { password: ["string", ["min", 6], "required"] }
   *
   * Rules can be grouped in nested objects. This adds a requirement that at least one of the child rules exist.
   * It uses Joi object.or()
   *
   * e.g.
   * validation: {
   *   checkboxGroup: {
   *     checkbox1: ["string", ["empty", "false"]],
   *     checkbox2: ["string", ["empty", "false"]],
   *   },
   *  }
   * Will produce an error unless at least one of checkbox1 and checkbox2 is checked.
   */
  validation?: {
    [key: string]: {} | Array<string | Array<string | number>>;
  };
  /**
   * Form components
   */
  children?: React.ReactNode;
  /**
   * Callback for getting validity and values outside of the form (ie in a Modal where buttons are not in the Form)
   */
  onFormChange?: (valid: boolean, values?: {}, error?: {}) => void;
  onSubmit?: (values: {}) => void;
}

export interface FormState {
  error: {} | undefined;
  formValid: boolean;
  formValues: {};
  hasBlurred: {};
  loadingImage: boolean;
  validation?: {};
}

/**
 * React Component
 */
class Form extends React.Component<
  FormProps & WrappedComponentProps,
  FormState
> {
  constructor(props: FormProps & WrappedComponentProps) {
    super(props);

    this.state = {
      error: {},
      formValid: Boolean(!this.props.validation),
      formValues: { ...this.props.initialValues },
      hasBlurred: {},
      loadingImage: false,
      validation: this.props.validation,
    };
  }

  public componentDidMount() {
    this.validate();
  }

  public UNSAFE_componentWillReceiveProps(nextProps: FormProps) {
    if (isEqual(nextProps, this.props)) {
      return;
    }

    const stateWithEmptyError = {
      error: {},
      formValid: Boolean(!nextProps.validation),
      hasBlurred: {},
      validation: nextProps.validation,
    };

    const nextState: {} | undefined = !isEqual(
      this.props.error,
      nextProps.error,
    )
      ? {
          error: nextProps.error,
          validation: nextProps.validation,
        }
      : (!this.props.clear && nextProps.clear) ||
        !isEqual(this.props.initialValues, nextProps.initialValues)
      ? {
          ...stateWithEmptyError,
          formValues: { ...nextProps.initialValues },
        }
      : !isEqual(this.props.updateValues, nextProps.updateValues)
      ? {
          ...stateWithEmptyError,
          formValues: { ...this.state.formValues, ...nextProps.updateValues },
        }
      : !isEqual(this.props.validation, nextProps.validation)
      ? {
          validation: nextProps.validation,
        }
      : !isEqual(this.props.showAllErrors, nextProps.showAllErrors)
      ? {}
      : undefined;

    if (nextState) {
      this.setState(nextState, () => this.validate());
    }
  }

  public shouldComponentUpdate(nextProps: FormProps, nextState: FormState) {
    return !(isEqual(nextProps, this.props) && isEqual(nextState, this.state));
  }

  public render() {
    const { className, children, autoComplete, submitting } = this.props;

    const rootStyle = classnames("code-c-form", {
      [`${className}`]: Boolean(className),
    });

    return (
      <form
        className={rootStyle}
        autoComplete={autoComplete ? "on" : ""}
        onSubmit={submitting ? this.emptySubmit : this.handleSubmit}
      >
        {React.Children.map(children, this.passPropsToChildren)}
      </form>
    );
  }

  private emptySubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  };

  private handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (this.props.onSubmit) {
      this.props.onSubmit(this.state.formValues);
    }
  };

  private passPropsToChildren = (child: React.ReactChild): React.ReactChild => {
    if (!React.isValidElement(child)) {
      return child;
    }

    const { showAllErrors, showError = {} } = this.props;
    const { formValid, formValues, hasBlurred } = this.state;
    const name = (child.props as { name?: string }).name || "";
    const error = name
      ? (showAllErrors || hasBlurred[name] || showError[name]) &&
        this.state.error
      : undefined;

    const childProps = child.props as Record<string, any>;

    const propsWithStringValue = {
      error,
      onBlur: this.handleBlur,
      onChange: this.handleChange,
      value: formValues[name] || "",
      ...childProps,
    };

    const propsWithBooleanValue = {
      error,
      onBlur: this.handleBlur,
      onChange: this.handleChange,
      value: formValues[name] || false,
      ...childProps,
    };

    const reactChildren = {
      children: React.Children.map(
        (child.props as { children?: {} }).children,
        this.passPropsToChildren,
      ),
    };

    // Note: If you create a new form subcomponent, you need to register its type here, or the component might be readOnly and you might get a warning:
    // Warning: You provided a value prop to a form field without an onChange handler. This will render a read-only field...
    const componentsProps =
      child.type === Input
        ? (propsWithStringValue as InputProps)
        : child.type === Textarea
        ? (propsWithStringValue as TextareaProps)
        : child.type === Select
        ? (propsWithStringValue as SelectProps)
        : child.type === LanguageSelect
        ? (propsWithStringValue as SelectProps)
        : child.type === Radio
        ? (propsWithStringValue as RadioProps)
        : child.type === RadioPanel
        ? (propsWithStringValue as RadioPanelProps)
        : child.type === RichMarkdown
        ? (propsWithStringValue as RichMarkdownProps)
        : child.type === CustomForm &&
          ![
            CustomFormDefinitionType.Date,
            CustomFormDefinitionType.DateRange,
          ].includes(childProps.definition.dataType)
        ? ({
            ...propsWithStringValue,
            formValues, // this property is only for checkbox group.
          } as CustomFormProps)
        : child.type === CustomForm &&
          childProps.definition.dataType === CustomFormDefinitionType.Date
        ? ({
            error,
            onChange: (date: string) => this.handleDateChange(name, date),
            onBlur: this.handleBlur,
            value: formValues[name] || undefined,
            locale: this.props.intl.locale,
            ...childProps,
          } as CustomFormProps)
        : child.type === CustomForm &&
          childProps.definition.dataType === CustomFormDefinitionType.DateRange
        ? ({
            error,
            onChange: (dateRange: string[]) =>
              this.handleDateChange(name, dateRange),
            onBlur: () => this.handleDateRangeBlur(name),
            value: formValues[name] || [],
            locale: this.props.intl.locale,
            ...childProps,
          } as CustomFormProps)
        : child.type === Checkbox
        ? (propsWithBooleanValue as CheckboxProps)
        : child.type === SubMenuCheckboxItem
        ? (propsWithBooleanValue as SubMenuCheckboxItemProps)
        : child.type === SwitchPanel
        ? (propsWithBooleanValue as SwitchPanelProps)
        : child.type === SwitchContainer // TTODO: Temp. Maybe just remove.
        ? ({
            ...propsWithBooleanValue,
            expanded: childProps.expanded,
            switchContent: childProps.switchContent,
            dataTestautomationid: childProps.dataTestautomationid,
          } as SwitchContainerProps)
        : child.type === RichTextarea
        ? ({
            ...propsWithStringValue,
            onChange: this.handleRichChange,
          } as RichTextareaConnectProps)
        : child.type === Button
        ? ({
            disabled: !formValid,
            loading: this.props.submitting,
            ...childProps,
          } as ButtonProps)
        : child.type === ImageUpload
        ? ({
            src: formValues[name] || undefined,
            ...childProps,
          } as ImageUploadProps)
        : child.type === DatePicker
        ? ({
            error,
            onChange: (date: string) => this.handleDateChange(name, date),
            onBlur: this.handleBlur,
            value: formValues[name] || undefined,
            locale: this.props.intl.locale,
            ...childProps,
          } as DatePickerProps)
        : child.type === FormGroup
        ? ({
            ...reactChildren,
            error,
          } as { children?: {} })
        : (child.props as { children?: {} }).children
        ? (reactChildren as { children?: {} })
        : undefined;

    return componentsProps ? React.cloneElement(child, componentsProps) : child;
  };

  private handleDateChange = (name: string, date: string | string[]) => {
    if (!name.length) {
      return;
    }

    this.setState(
      {
        formValues: {
          ...this.state.formValues,
          hasBlurred: { ...this.state.hasBlurred, [name]: true },
          [name]: date,
        },
      },
      () => this.validate(),
    );
  };

  private handleRichChange = (
    event: React.FormEvent<HTMLTextAreaElement>,
    loadingImage: boolean,
  ) => {
    const { name, value } = event.target as HTMLTextAreaElement;

    if (!name || !name.length) {
      return;
    }

    this.setState(
      {
        formValues: { ...this.state.formValues, [name]: value },
        loadingImage,
      },
      () => this.validate(),
    );
  };

  private handleChange = (
    event: React.FormEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >,
  ) => {
    const { name, value, type, checked } = event.target as HTMLInputElement;

    if (!name || !name.length) {
      return;
    }

    this.setState(
      {
        formValues: {
          ...this.state.formValues,
          [name]: type === "checkbox" ? checked : value,
        },
      },
      () => this.validate(),
    );
  };

  private handleBlur = (
    event: React.FormEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >,
  ) => {
    const { name } = event.target as HTMLInputElement;

    this.setState(
      {
        hasBlurred: { ...this.state.hasBlurred, [name]: true },
      },
      () => this.validate(),
    );
  };

  private handleDateRangeBlur = (name: string) => {
    this.setState(
      {
        hasBlurred: { ...this.state.hasBlurred, [name]: true },
      },
      () => this.validate(),
    );
  };

  private validate = () => {
    const { formValues, hasBlurred, loadingImage, validation } = this.state;
    const {
      error,
      intl,
      initialValues,
      onFormChange,
      showAllErrors,
      showError = {},
    } = this.props;

    if (!validation) {
      return;
    }

    const { error: errorFromJson } = jsonToJoi(validation).validate(
      formatFormValues(validation, formValues),
      getValidationOptions(intl),
    );

    // get validation error object from the JoiBase.ValidationError
    const validationError = getValidationError(
      errorFromJson,
      hasBlurred,
      showAllErrors,
      showError,
    );

    const formValid =
      Boolean(!validationError && (!error || !Object.keys(error).length)) &&
      !isEqual(formValues, initialValues) &&
      !loadingImage;

    this.setState({
      error: { ...validationError, ...error },
      formValid,
    });

    if (onFormChange) {
      onFormChange(formValid, formValues, validationError || loadingImage);
    }
  };
}

export default injectIntl(Form);
