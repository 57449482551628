import { Placement } from "@popperjs/core";
import * as classnames from "classnames";
import * as React from "react";

import { Tooltip } from "@shared/components/tooltip/Tooltip";

import { Icon } from "../icon/Icon";

/**
 * Prop interface
 */
export interface ErrorIconProps {
  className?: string;
  tooltipText?: React.ReactNode;
  tooltipPlacement?: Placement;
}

/**
 * React Component
 */
export function ErrorIcon({
  className,
  tooltipText,
  tooltipPlacement,
}: ErrorIconProps) {
  const rootStyle = classnames("code-c-error-icon", {
    [`${className}`]: Boolean(className),
  });

  const errorIcon = (
    <div className={rootStyle}>
      <Icon
        size="small"
        type="exclamation-triangle"
        className="has-error-background"
      />
      <Icon size="small" type="exclamation-triangle" className="has-error" />
    </div>
  );

  return tooltipText ? (
    <Tooltip text={tooltipText} placement={tooltipPlacement}>
      {errorIcon}
    </Tooltip>
  ) : (
    errorIcon
  );
}
