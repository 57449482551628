import * as classnames from "classnames";
import * as React from "react";

import { Msg, Tooltip } from "@shared/components";
import { Icon } from "@shared/components/icon";

export const ChallengeResultPreviewButtonGroup = ({
  isRightDrawerOpen,
  toggleRightDrawer,
  disableCodeExplanationButton = false,
  disableLocalCodeFilesButton = false,
  hideLocalCodeFilesButton = false,
}: {
  isRightDrawerOpen: boolean;
  toggleRightDrawer: () => void;
  disableCodeExplanationButton?: boolean;
  disableLocalCodeFilesButton?: boolean;
  hideLocalCodeFilesButton?: boolean;
}) => {
  const wrapperStyles = classnames("challenge-result-preview__button-group");
  const shouldShowCodeExplanationTooltip = disableCodeExplanationButton;
  const shouldShowLocalCodeFilesTooltip = disableLocalCodeFilesButton;
  const shouldShowLocalCodeFilesButton = !hideLocalCodeFilesButton;
  const codeExplanationTooltipRef = React.useRef<HTMLAnchorElement>(null);
  const localCodeFilesTooltipRef = React.useRef<HTMLAnchorElement>(null);

  if (isRightDrawerOpen) {
    return (
      <div
        className={wrapperStyles}
        role="button"
        aria-label={"Close Right Drawer"}
        onClick={toggleRightDrawer}
      >
        <a>
          <Msg id="close" />
          <Icon type="times-circle" />
        </a>
      </div>
    );
  }

  const codeExplanationButtonStyles = classnames("code-explanation-button", {
    "code-explanation-button--disabled": disableCodeExplanationButton,
  });

  const localCodeFilesButtonStyles = classnames("local-code-files-button", {
    "local-code-files-button--disabled": disableLocalCodeFilesButton,
  });

  return (
    <div className={wrapperStyles}>
      <Tooltip
        text={<Msg id="submission.noApplicantExplanation" />}
        disabled={!shouldShowCodeExplanationTooltip}
        overrideRef={codeExplanationTooltipRef}
        wrapperClassName={codeExplanationButtonStyles}
      >
        <a
          role="button"
          aria-label={"Open Right Drawer"}
          ref={codeExplanationTooltipRef}
          onClick={disableCodeExplanationButton ? undefined : toggleRightDrawer}
        >
          <Msg id={"common.applicantExplanation"} />
          <Icon type={"comment"} />
        </a>
      </Tooltip>
      {shouldShowLocalCodeFilesButton && (
        <Tooltip
          text={<Msg id="submission.noDataFound" />}
          disabled={!shouldShowLocalCodeFilesTooltip}
          overrideRef={localCodeFilesTooltipRef}
          wrapperClassName={localCodeFilesButtonStyles}
        >
          <a
            role="button"
            aria-label={"Open Right Drawer"}
            ref={localCodeFilesTooltipRef}
            onClick={
              disableLocalCodeFilesButton ? undefined : toggleRightDrawer
            }
          >
            <Msg id={"submission.localCodeFiles"} />
            <Icon type={"file"} />
          </a>
        </Tooltip>
      )}
    </div>
  );
};
