import * as classnames from "classnames";

import {
  AdminChallengeCollectionInputModel,
  AdminChallengeCollectionListModel,
  AdminChallengeCollectionModel,
  ChallengeCollectionBaseModel,
  ChallengeCollectionListModel,
  ChallengeCollectionModel,
} from "@shared/models";
import { getFormedChallengeCollection } from "@shared/services/challengeCollection";
import Message from "@shared/services/message";

/**
 * Prop interface
 */
export interface ChallengeCollectionCardProps {
  challengeCollection: Pick<
    | AdminChallengeCollectionInputModel
    | AdminChallengeCollectionListModel
    | AdminChallengeCollectionModel
    | ChallengeCollectionListModel
    | ChallengeCollectionModel,
    "jpTitle" | "jpDescription" | "enTitle" | "enDescription"
  >;
  locale: string;
  onClick?: () => void;
  className?: string;
}

/**
 * React Component
 */
export function ChallengeCollectionCard({
  challengeCollection,
  locale = "en",
  className,
  onClick,
}: ChallengeCollectionCardProps) {
  const rootStyle = classnames("code-c-challenge-collection-card", {
    [`${className}`]: Boolean(className),
  });

  const { title, description } = getFormedChallengeCollection(
    challengeCollection as ChallengeCollectionBaseModel,
    locale,
  );

  return (
    <div
      className={rootStyle}
      role="button"
      aria-label="Challenge Collection Card"
      onClick={onClick}
    >
      <div>
        <p className="code-c-challenge-collection-card__title clamp-1">
          {title}
        </p>
        <p className="code-c-challenge-collection-card__description clamp-2">
          {description}
        </p>
      </div>
      <p className="code-c-challenge-collection-card__more">
        {`>> ${Message.getMessageByKeyAndLocale(
          "action.seeMoreDetails",
          locale,
        )}`}
      </p>
    </div>
  );
}
