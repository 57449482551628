import { Link } from "react-router-dom";

import { EmptyState, HorizontalFunnelChart, Msg } from "@shared/components";
import { ExamModel } from "@shared/models";
import { buildApplicantFunnelChartData } from "@shared/services/exam";

export default function ApplicantFunnel({
  examCounts,
}: {
  examCounts: ExamModel["counts"];
}) {
  const funnelData = buildApplicantFunnelChartData(examCounts);

  return (
    <article className="code-exam-dashboard__panel">
      <div className="code-exam-dashboard__panel-header">
        <h3 className="text-heading3">
          <Msg id="exam.dashboard.applicantFunnel" tagName="none" />
        </h3>
        <Link to="submissions_all">
          <Msg id="exam.dashboard.toApplicants" tagName="none" />
        </Link>
      </div>
      {funnelData.length > 0 ? (
        <HorizontalFunnelChart
          className="code-exam-dashboard-applicant-funnel__chart"
          data={funnelData}
          height={250}
        />
      ) : (
        <EmptyState />
      )}
    </article>
  );
}
