import { Switch, Route } from "react-router-dom";

import { QuestionRoute } from "../questions/Questions.route";
import { ContentsList } from "./contentsList";

/**
 * Route settings
 */
export const ContentsRoute = () => (
  <Switch>
    <Route
      path="/p/:projectId/(challenges|questions)"
      exact={true}
      component={ContentsList}
    />
    <Route path="/p/:projectId/challenges" component={ContentsList} />
    <Route path="/p/:projectId/questions" component={QuestionRoute} />
  </Switch>
);
