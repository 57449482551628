import * as classnames from "classnames";

import Button, { ButtonProps } from "../button/Button";

/**
 * Prop interface
 */
export interface SubMenuButtonProps extends ButtonProps {
  children?: {};
  className?: string;
  outlined?: boolean;
}

/**
 * React Component
 */
export default function SubMenuButton({
  type,
  route,
  onClick,
  outlined,
  disabled,
  className,
  children,
}: SubMenuButtonProps) {
  const rootStyle = classnames("code-c-sub-menu-button", {
    [`${className}`]: Boolean(className),
  });

  return (
    <Button
      className={rootStyle}
      type={type}
      route={route}
      onClick={onClick}
      outlined={outlined}
      stretch={true}
      disabled={disabled}
    >
      {children}
    </Button>
  );
}
