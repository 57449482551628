import * as classNames from "classnames";

import { ExamReviewersSearchForm } from "@components/orgs/exams/examSections/examReviewers/ExamReviewers";

import {
  MembersSearchForm,
  Msg,
  PageSubTitle,
  PageTitle,
} from "@shared/components";
import MSG from "@shared/services/message";

interface ExamReviewersHeaderProps {
  keyword: string;
  isSubtitle?: boolean;
  hideSearchField?: boolean;
  className?: string;
  onTextChanged?: (
    formValid: boolean,
    formValues: ExamReviewersSearchForm,
  ) => void;
  examId?: number;
}

export const ExamReviewersHeader = ({
  isSubtitle,
  hideSearchField,
  keyword,
  onTextChanged,
  className,
  examId,
}: ExamReviewersHeaderProps) => {
  const rootStyle = classNames(
    "code-exam-reviewers-header",
    {
      "code-exam-reviewers-header__spacing": !isSubtitle,
    },
    className,
  );

  return (
    <div className={rootStyle}>
      {isSubtitle ? (
        <PageSubTitle noMarginBottom>
          <Msg id="exam.reviewers" />
          {examId && (
            <small className="code-exam-detail__exam_id">
              <Msg id={"form.exam.id"} />: {examId}
            </small>
          )}
        </PageSubTitle>
      ) : (
        <PageTitle>
          <Msg id="exam.reviewers" />
          {examId && (
            <small className="code-exam-detail__exam_id">
              <Msg id={"form.exam.id"} />: {examId}
            </small>
          )}
        </PageTitle>
      )}
      {!hideSearchField && (
        <MembersSearchForm
          className="code-exam-reviewers-header__search-box"
          onChange={onTextChanged}
          initialValues={{ keyword }}
          placeholder={MSG.getMessageByKey("common.emailOrName")}
        />
      )}
    </div>
  );
};
