import { QuestionType, ChallengeStatus } from "../services/enums";
import { QuestionInsightModel } from "./QuestionInsight.model";
import QuestionSettingsModel from "./QuestionSettings.model";

interface QuestionCorrectAnswer {
  answerIndices?: number[]; // Present when kind = 1
  answers?: string[]; // Present when kind = 2
}

interface QuestionUserAnswer {
  score?: 0 | 1; // 0 = Incorrect, 1 = Correct
  answerIndices?: number[]; // Present when kind = 1 MCQ
  answers?: string[]; // Present when kind = 2 FIB
  answer?: string; // Present when kind = 3 FREE
}

class QuestionModel {
  public id: number;
  public title: string;
  public kind: QuestionType;
  public question: string;
  public howToSolve: string;

  // TODO submission returns question as questionText
  public questionText: string;

  public choices: Array<string> = []; // maybe not used

  public answers: Array<number | string> = [];

  public tags: Array<string> = [];
  public isOfficial: boolean;
  public createdAt: string;
  public updatedAt: string;

  public settings: QuestionSettingsModel;

  public correctAnswer: QuestionCorrectAnswer = {};

  public userAnswer: QuestionUserAnswer = {};

  public quizCategories: Array<number> = [];

  public organizationId: number;

  public status: ChallengeStatus;

  public globalInsight?: QuestionInsightModel;

  public loadingInsight = false;
  public errorLoadingInsight = false;
  public examLevelInsight?: QuestionInsightModel;

  constructor(args?: {}) {
    Object.assign(this, args);

    this.settings = new QuestionSettingsModel(this.settings);
  }

  public getQuestionText() {
    return this.question || this.questionText;
  }
}

export default QuestionModel;
