import * as React from "react";

import { Header, HeaderType, Footer, HeroTile } from "../../shared/components";

/**
 * Routes
 */
import GuestRoutes from "./GuestRoutes";

interface Props {
  size?: "large";
  location: {};
}

/**
 * Container component
 */
export default class GuestContainer extends React.Component<Props> {
  public render() {
    return (
      <div className="code-guest-container">
        <Header type={HeaderType.Bare} />
        <div className="code-guest-container__body">
          <HeroTile size={this.props.size}>
            <GuestRoutes routerLocation={this.props.location} />
          </HeroTile>
        </div>
        <Footer />
      </div>
    );
  }
}
