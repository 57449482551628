import { Portal } from "@shared/components";
import { useHoverBox, useOnClickOutside } from "@shared/hooks";

interface IpAddressBadgeHoverBoxProps {
  ips: string[];
  targetElement?: HTMLDivElement;
  onClickOutside?: () => void;
}

export const IpAddressBadgeHoverBox = ({
  ips,
  targetElement,
  onClickOutside,
}: IpAddressBadgeHoverBoxProps) => {
  const { styles, attributes, popperElement, setPopperElement } = useHoverBox({
    targetElement,
    offset: [0, 8],
    placement: "top",
  });

  const rootRef = useOnClickOutside<HTMLDivElement>(() => {
    onClickOutside?.();
  });

  rootRef.current = popperElement;

  return (
    <Portal>
      <div
        className="code-ip-address-badge-hover-box"
        ref={setPopperElement}
        style={styles.popper}
        {...attributes.popper}
      >
        <div className="code-ip-address-badge-hover-box__container">
          {ips.map((ip, idx) => (
            <div key={idx}>{ip}</div>
          ))}
        </div>
      </div>
    </Portal>
  );
};
