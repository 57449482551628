import { EnumWithStringValueModel } from "@shared/models/Enum.model";
import MemberListModel from "@shared/models/MemberList.model";
import { AutoFilterType, WebcamSettings } from "@shared/services/enums";

import { ExamSettingsFormValidation } from "../examSections/examSettingsNew/utils";
import { Form } from "./types";

export const normalizeReviewers = (
  reviewers: MemberListModel[],
  invitationStatus: Record<string, EnumWithStringValueModel>,
) => {
  return reviewers.map(
    (r) =>
      new MemberListModel({
        ...r,
        displayStatus:
          (r.invitationStatus &&
            invitationStatus[r.invitationStatus]?.displayString) ||
          "",
      }),
  );
};

export const formatNewFormValues = ({
  formValues,
  stepValues,
}: {
  formValues: Partial<Form>;
  stepValues: {};
}) => {
  const newFormValues = {
    ...formValues,
    ...stepValues,
  };

  return newFormValues;
};

export const formatGuestSharingValues = (
  guestSharingEnabled: boolean,
  guestSharingSettings: ExamSettingsFormValidation["guestSharingSettings"],
) => {
  const guestSharingSettingsFormatted: ExamSettingsFormValidation["guestSharingSettings"] =
    guestSharingEnabled
      ? guestSharingSettings
      : {
          showSecretTestcases: false,
          showReviews: false,
          showInsights: false,
          showName: false,
          showEntryFormResponses: false,
          showSubmissionDetails: false,
          showActionLog: false,
          showNote: false,
        };
  return guestSharingSettingsFormatted;
};
export const formatApplicantActionSettingsValues = (
  applicantActionSettingsEnabled: boolean,
  applicantActionSettings: ExamSettingsFormValidation["applicantActionSettings"],
) => {
  const applicantActionSettingsFormatted: ExamSettingsFormValidation["applicantActionSettings"] =
    applicantActionSettingsEnabled
      ? applicantActionSettings
      : {
          showKeyEvents: false,
          showCopyPasteEvents: false,
          showTabChangeEvents: false,
          showScreenshotEvents: false,
        };
  return applicantActionSettingsFormatted;
};

export const formatWebcamSettingsValues = (
  applicantActionSettingsEnabled: boolean,
  webcamSettings: ExamSettingsFormValidation["webcamSettings"],
) => {
  const webcamSettingsFormatted: ExamSettingsFormValidation["webcamSettings"] =
    applicantActionSettingsEnabled
      ? webcamSettings
      : {
          enabled: false,
          livenessVerification: WebcamSettings.optional,
        };
  return webcamSettingsFormatted;
};

export const formatAutoFilterSettingsValues = (
  autoFilterSettings: ExamSettingsFormValidation["autoFilterSettings"],
  autoFilterSettingsEnabled: ExamSettingsFormValidation["autoFilterSettingsEnabled"],
) => {
  if (!autoFilterSettingsEnabled) {
    return undefined;
  }
  const autoFilterSettingsFormatted: any = {
    filterType: autoFilterSettings!.filterType,
  };

  if (
    autoFilterSettings &&
    autoFilterSettings.filterType === AutoFilterType.AutoPassFail
  ) {
    autoFilterSettingsFormatted.autoPassFail = autoFilterSettings.autoPassFail;
  }
  if (
    autoFilterSettings &&
    autoFilterSettings.filterType === AutoFilterType.AutoPassFailReview
  ) {
    autoFilterSettingsFormatted.autoPassFailReview =
      autoFilterSettings.autoPassFailReview;
  }

  return autoFilterSettingsFormatted;
};
