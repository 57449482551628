import { zodResolver } from "@hookform/resolvers/zod";
import React, { forwardRef, useEffect } from "react";
import { useForm, FormProvider, SubmitHandler } from "react-hook-form";
import { z } from "zod";

import {
  FormGroup,
  Label,
  PageTitle,
  Msg,
  RadioPanel,
  PanelStandardContents,
  Select,
  SelectItem,
} from "@shared/components";
import {
  TalenthubPublicationStatus,
  SpokenLanguages,
} from "@shared/services/enums";
import MSG from "@shared/services/message";

import { useImperativeSubmit } from "../../partials/talentHubUtils";

/**
 * Types
 */
export type ExamSettingsForm = {
  language: SpokenLanguages;
  publicationStatus: TalenthubPublicationStatus;
};

export type ExamSettingsProps = {
  stepIndex: number;
  initialValues?: {
    language: SpokenLanguages;
    publicationStatus: TalenthubPublicationStatus;
  };
  onFormChange?: (isValid: boolean) => void;
  readOnly?: boolean;
  hideTitle?: boolean;
  onMoveStep?: (
    isValid: boolean,
    stepNum: number,
    values: ExamSettingsForm,
  ) => void;
  ref?: React.RefObject<{ submit: () => void }>;
};

const ExamSettings = forwardRef((props: ExamSettingsProps, ref) => {
  const {
    readOnly = false,
    initialValues,
    hideTitle = false,
    onFormChange,
    onMoveStep,
  } = props;

  const schema = z.object({
    language: z.string().min(1, MSG.getMessageByKey("form.required")),
    publicationStatus: z.string().min(1, MSG.getMessageByKey("form.required")),
  });

  const methods = useForm<ExamSettingsForm>({
    resolver: zodResolver(schema),
    defaultValues: {
      ...initialValues,
      publicationStatus:
        initialValues?.publicationStatus || TalenthubPublicationStatus.Draft,
    },
    mode: "all", // both onBlur and onChange validation
  });

  const {
    register,
    watch,
    formState: { isValid, isDirty },
    setValue,
    handleSubmit,
  } = methods;

  const watchedValues = watch();
  const publicationStatus = watch("publicationStatus");

  useEffect(() => {
    if (isDirty && onFormChange) {
      onFormChange(isValid);
    }
  }, [isDirty, isValid, onFormChange]);

  const handleFormSubmit: SubmitHandler<ExamSettingsForm> = (data) => {
    if (onMoveStep) {
      // run the submit function passed in from parent component
      onMoveStep(isValid, props.stepIndex, data);
    }
  };

  // Handles step submission to the parent when the step changes
  useImperativeSubmit(
    ref as React.RefObject<{ submit: () => Promise<void> }>,
    handleSubmit,
    handleFormSubmit,
    watchedValues,
  );

  const rootStyle = "code-exam-create__settings";

  /**
   * Render
   */
  return (
    <FormProvider {...methods}>
      <div className={rootStyle}>
        {!hideTitle && (
          <PageTitle className="code-exam-create__step__title">
            <Msg id="settings" />
          </PageTitle>
        )}
        <FormGroup>
          <Label>
            <Msg id="exam.emailLanguage" />
          </Label>
          <Select
            disabled={readOnly}
            {...register("language")}
            options={
              [
                {
                  label: MSG.getMessageByKey(SpokenLanguages.Japanese),
                  value: SpokenLanguages.Japanese,
                },
                {
                  label: MSG.getMessageByKey(SpokenLanguages.English),
                  value: SpokenLanguages.English,
                },
              ] as SelectItem[]
            }
          />
        </FormGroup>
        <FormGroup>
          <Label>
            <Msg id="exam.talentHub.publicationStatus" />
          </Label>
          <div className="code-exam-create__settings__publish-status">
            <RadioPanel
              readOnly={readOnly}
              initialLineHeight="1.5rem"
              checked={publicationStatus === TalenthubPublicationStatus.Draft}
              onChange={() => {
                setValue(
                  "publicationStatus",
                  TalenthubPublicationStatus.Draft,
                  {
                    shouldValidate: true,
                    shouldDirty: true,
                  },
                );
              }}
              dataTestautomationid="publicationStatusDraft"
            >
              <PanelStandardContents
                title={MSG.getMessageByKey(
                  "exam.talentHub.publicationStatus.draft",
                )}
              >
                {MSG.getMessageByKey(
                  "exam.talentHub.publicationStatus.draftDescription",
                )}
              </PanelStandardContents>
            </RadioPanel>
            <RadioPanel
              readOnly={readOnly}
              initialLineHeight="1.5rem"
              checked={publicationStatus === TalenthubPublicationStatus.Limited}
              onChange={() => {
                setValue(
                  "publicationStatus",
                  TalenthubPublicationStatus.Limited,
                  {
                    shouldValidate: true,
                    shouldDirty: true,
                  },
                );
              }}
              dataTestautomationid="publicationStatusLimited"
            >
              <PanelStandardContents
                title={MSG.getMessageByKey(
                  "exam.talentHub.publicationStatus.limited",
                )}
              >
                {MSG.getMessageByKey(
                  "exam.talentHub.publicationStatus.limitedDescription",
                )}
              </PanelStandardContents>
            </RadioPanel>
            <RadioPanel
              readOnly={readOnly}
              initialLineHeight="1.5rem"
              checked={
                publicationStatus === TalenthubPublicationStatus.Published
              }
              onChange={() => {
                setValue(
                  "publicationStatus",
                  TalenthubPublicationStatus.Published,
                  {
                    shouldValidate: true,
                    shouldDirty: true,
                  },
                );
              }}
              dataTestautomationid="publicationStatusPublished"
            >
              <PanelStandardContents
                title={MSG.getMessageByKey(
                  "exam.talentHub.publicationStatus.published",
                )}
              >
                {MSG.getMessageByKey(
                  "exam.talentHub.publicationStatus.publishedDescription",
                )}
              </PanelStandardContents>
            </RadioPanel>
          </div>
        </FormGroup>
      </div>
    </FormProvider>
  );
});

export default ExamSettings;
