import * as classnames from "classnames";
import { Helmet } from "react-helmet";

import { ErrorTile } from "../../../../shared/components";
import Message from "../../../../shared/services/message";

/**
 * Prop interface
 */
export interface AccessDeniedProps {
  hasBackButton?: boolean;
  hasBoxShadow?: boolean;
}

/**
 * Page component
 */
function AccessDenied(props: AccessDeniedProps) {
  const rootStyle = classnames("code-access-denied");
  return (
    <div className={rootStyle}>
      <Helmet>
        <html data-testid="page-access-denied" />
      </Helmet>
      <ErrorTile
        errorCode="403"
        errorMessage={Message.getMessageByKey("error.accessDenied.title")}
        errorDescription={Message.getMessageByKey(
          "error.accessDenied.description",
        )}
        hasBackButton={props.hasBackButton}
        hasBoxShadow={props.hasBoxShadow}
      />
    </div>
  );
}

export default AccessDenied;
