export default class AuditLogModel {
  public action: string;
  public subdomain: string;
  public origin: string;
  public organizationId: number;
  public organizationName: string;
  public createdAt: string;
  public requestUserIp: string;
  public requestUserId: number;
  public requestUserEmail: string;
  public requestUserAgent: string;
  public extraData: {} = {};

  constructor(args?: {}) {
    Object.assign(this, args);
  }
}
