import * as classNames from "classnames";
import * as React from "react";

interface Props {
  children: React.ReactNode;
  hidden?: boolean;
}

export default function SubmissionResultActionLogRowDetail({
  children,
  hidden,
}: Props) {
  const classes = classNames(
    "code-c-submission-result-action-log__table-row-detail",
    { "is-collapse": hidden },
  );

  return <div className={classes}>{children}</div>;
}
