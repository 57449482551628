import { connect } from "react-redux";
import { Action, Dispatch } from "redux";

import { orgAuditLogGetAction, projectAuditLogGetAction } from "@actions";

import { RootState } from "@reducers";

import { PaginationModel } from "@shared/models";

import AuditLog, { ExternalProps } from "./AuditLog";

const mapStateToProps = (state: RootState, props: ExternalProps) => ({
  auditLog: state.orgs.auditLog,
  auditLogPagination: state.orgs.auditLogPagination,
  ...props,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  getOrgAuditLog: (pagination?: PaginationModel) => {
    dispatch(orgAuditLogGetAction.request({ ...pagination }));
  },
  getProjectAuditLog: (pagination?: PaginationModel) => {
    dispatch(projectAuditLogGetAction.request({ ...pagination }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AuditLog);
