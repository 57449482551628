import * as classNames from "classnames";
import * as React from "react";

import { Icon } from "..";

/**
 * Prop interface
 */
interface AlertProps {
  type: "warning" | "error";
  className?: string;
  showIcon?: boolean;
  icon?: React.ReactNode;
  children: React.ReactNode;
}

/**
 * React Component
 * @deprecated please use <Banner> instead (src/shared/components/banner/Banner.tsx)
 */
export const Alert = ({
  type,
  icon,
  showIcon = true,
  className,
  children,
}: AlertProps) => {
  const rootStyle = classNames("code-c-alert", {
    [`is-${type}`]: Boolean(type),
    [`${className}`]: Boolean(className),
  });

  return (
    <div className={rootStyle} role="alert">
      {showIcon &&
        (icon || (
          <Icon
            size="medium"
            type={type === "warning" ? "warning" : "exclamation"}
          />
        ))}

      {children}
    </div>
  );
};
