import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";

import { useStoreContext } from "@context";

import { Msg, Tooltip } from "@shared/components";
import { useExam } from "@shared/hooks/query";

function ExamDetailBreadcrumbs() {
  const { projectId } = useStoreContext();
  const {
    data: {
      examDetail: { id: examId },
      examDetail,
    },
  } = useExam();
  const examBreadcrumbLabel = `[${examId}] ${examDetail.getName()}`;

  return (
    <>
      <BreadcrumbsItem to={`/p/${projectId}/exams`}>
        <Msg id={"common.examList"} />
      </BreadcrumbsItem>
      <BreadcrumbsItem to={`/p/${projectId}/exams/${examId}`}>
        {examBreadcrumbLabel && (
          <Tooltip
            text={examBreadcrumbLabel}
            wrapperClassName="code-exam-detail__breadcrumb-name"
            maxSize="xlarge"
          >
            <>{examBreadcrumbLabel}</>
          </Tooltip>
        )}
      </BreadcrumbsItem>
    </>
  );
}

export default ExamDetailBreadcrumbs;
