import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { getExamDetail, postExam } from "@api/exams";

import { useStoreContext } from "@context";

import { buildExamCopyBody } from "@shared/services/exam";
import history from "@shared/services/history";
import Message from "@shared/services/message";

import { examKeys } from "./useExam";

interface UseCopyExamParams {
  examId: number;
  copyReviewer: boolean;
  targetProjectId: number;
}

export function useCopyExam() {
  const client = useQueryClient();
  const { projectId } = useStoreContext();

  const mutation = useMutation({
    mutationFn: async ({
      examId,
      copyReviewer,
      targetProjectId,
    }: UseCopyExamParams) => {
      const { result } = await getExamDetail({ examId, projectId });

      const { reviewers, ...rest } = buildExamCopyBody(result);

      return postExam({
        projectId: targetProjectId,
        options: {
          data: {
            ...rest,
            ...(copyReviewer ? { reviewers } : {}),
            projectId: targetProjectId,
          },
        },
      });
    },
    onSuccess: (res, { targetProjectId }) => {
      toast.success(Message.getMessageByKey("message.exam.copy"));
      client.invalidateQueries(examKeys.listAll(targetProjectId));

      history.push(`/p/${targetProjectId}/exams/${res.result.id}/outline/edit`);
    },
  });

  return mutation;
}
