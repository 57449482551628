import actionCreator from "../shared/services/actionCreator";

export const adminOrgSetAction = actionCreator("adminOrgSetAction");

export const adminContractListAction = actionCreator("adminContractListAction");
export const adminContractGetAction = actionCreator("adminContractGetAction");
export const adminContractCreateAction = actionCreator(
  "adminContractCreateAction",
);
export const adminContractUpdateAction = actionCreator(
  "adminContractUpdateAction",
);
export const adminContractDeleteAction = actionCreator(
  "adminContractDeleteAction",
);

export const adminExamCopyOrgGetAction = actionCreator(
  "adminExamCopyOrgGetAction",
);
export const adminExamCopyAction = actionCreator("adminExamCopyAction");
export const adminExamDeactivateAction = actionCreator(
  "adminExamDeactivateAction",
);
export const adminExamReactivateAction = actionCreator(
  "adminExamReactivateAction",
);

export const adminProjectMemberDeleteAction = actionCreator(
  "adminProjectMemberDeleteAction",
);
export const grantSystemadminRoleAction = actionCreator(
  "grantSystemadminRoleAction",
);
export const revokeSystemadminRoleAction = actionCreator(
  "revokeSystemadminRoleAction",
);

export const adminChallengeCollectionListAction = actionCreator(
  "adminChallengeCollectionListAction",
);
export const adminChallengeCollectionGetAction = actionCreator(
  "adminChallengeCollectionGetAction",
);
export const adminChallengeCollectionCreateAction = actionCreator(
  "adminChallengeCollectionCreateAction",
);
export const adminChallengeCollectionUpdateAction = actionCreator(
  "adminChallengeCollectionUpdateAction",
);
export const adminChallengeCollectionDeleteAction = actionCreator(
  "adminChallengeCollectionDeleteAction",
);
export const adminOfficialExamAllListGetAction = actionCreator(
  "adminOfficialExamAllListGetAction",
);
export const adminPostOfficialExamAllListGetAction = actionCreator(
  "adminPostOfficialExamAllListGetAction",
);
export const adminSSOGetAction = actionCreator("adminSSOGetAction");
export const adminSSOMetaUploadAction = actionCreator(
  "adminSSOMetaUploadAction",
);
export const adminSSOMetaDeleteAction = actionCreator(
  "adminSSOMetaDeleteAction",
);
export const adminSSOUpdateAction = actionCreator("adminSSOUpdateAction");
