import * as classnames from "classnames";
import { isEqual } from "lodash";
import * as React from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";

import { ExamDetailHeader } from "@components/orgs/exams/examSections";

import {
  Column,
  Msg,
  Row,
  Table,
  TableBody,
  TableHead,
  HeaderColumn,
  Tooltip,
  Button,
  Icon,
  Modal,
  Tag,
  Pagination,
  Loading,
  FormGroup,
  Label,
  Restricted,
  LanguageTag,
  QuickHelp,
} from "@shared/components";
import {
  useDeleteScheduleMailDelivery,
  useExam,
  useExamCounts,
  useExamMailDelivery,
  useUpdateMailDelivery,
  useUpdateScheduleMailDelivery,
} from "@shared/hooks/query";
import {
  MailDeliveryCreateModel,
  MailDeliveryListModel,
  MailDeliveryType,
  PaginationModel,
  UserModel,
} from "@shared/models";
import { formatDateTimeMinutes } from "@shared/services/date";
import {
  DeliveryMethod,
  DeliveryStatus,
  ProjectRole,
  UserRole,
} from "@shared/services/enums";
import Message from "@shared/services/message";
import { updateUrl, parseUrl } from "@shared/services/queryString";

import { ExamDeliveryDetailModal } from "./ExamDeliveryDetailModal";

/**
 * Props
 */
export type ExternalProps = {
  status?: MailDeliveryType;
  params: string;
};

export type InjectedProps = {
  currentUser?: UserModel;
  currentProjectId: number;
};

export type ExamDeliveryListProps = ExternalProps & InjectedProps;

export const ExamDeliveryList: React.FunctionComponent<
  ExamDeliveryListProps
> = ({
  currentUser,
  currentProjectId,
  status,
  params,
}: ExamDeliveryListProps) => {
  const { page } = parseUrl(params);
  const [pagination, setPagination] = React.useState(new PaginationModel());
  const {
    data: {
      examDetail,
      examDetail: { urlToken: examUrlToken },
    },
  } = useExam();
  const { data: examCounts } = useExamCounts();
  const {
    data: { data: deliveryList, pagination: mailDeliveryPagination },
    isFetching: submitting,
  } = useExamMailDelivery(
    {
      limit: pagination.limit,
      offset: page ? pagination.getAtPage(page).offset : pagination.offset,
    },
    status,
  );
  const deleteDelivery = useDeleteScheduleMailDelivery();
  const updateScheduledDelivery = useUpdateScheduleMailDelivery();
  const updateDelivery = useUpdateMailDelivery();

  /**
   * State
   */
  const rootStyle = classnames("code-exam-delivery-list");

  const [modal, setModal] = React.useState<{
    isOpen: boolean;
    selectedDelivery?: MailDeliveryListModel;
    kind?: "detail" | "delete";
  }>({
    isOpen: false,
  });

  const pageTitle =
    status === "history"
      ? "exam.delivery.deliveryHistory"
      : "exam.delivery.scheduledDelivery";

  const deliveredAt = (delivery: MailDeliveryListModel) =>
    status === "history" &&
    delivery.deliveryStatus === DeliveryStatus.Unscheduled
      ? delivery.createdAt
      : delivery.startAt || delivery.updatedAt;

  /**
   * Effects
   */
  const examId = examDetail?.id;

  React.useEffect(() => {
    const newPagination = mailDeliveryPagination;

    if (!isEqual(pagination, newPagination)) {
      setPagination(newPagination);
    }
  }, [status, examId, params, pagination, mailDeliveryPagination]);

  /**
   * Private Functions
   */

  const onCloseModal = () => {
    setModal({ isOpen: false });
  };

  const onDeleteScheduledDelivery = () => {
    if (modal.selectedDelivery) {
      deleteDelivery.mutate({
        deliveryId: modal.selectedDelivery.id,
        examId: modal.selectedDelivery.examId,
      });
    }
    onCloseModal();
  };

  const onUpdateScheduledDelivery = (params: MailDeliveryCreateModel) => {
    if (modal.selectedDelivery) {
      const { examId, id } = modal.selectedDelivery;
      if (params) {
        updateScheduledDelivery.mutate({
          examId,
          deliveryId: id,
          data: params,
        });
      }

      onCloseModal();
    }
  };

  const onUpdateDelivery = ({ deadline }: { deadline: string }) => {
    if (modal.selectedDelivery) {
      const { id } = modal.selectedDelivery;
      if (deadline && currentUser?.organization?.name) {
        updateDelivery.mutate({
          deliveryId: id,
          orgName: currentUser?.organization?.name,
          data: {
            deadline,
          },
        });
      }

      onCloseModal();
    }
  };

  const onOpenModal = (emailDelivery: MailDeliveryListModel) => {
    setModal({
      isOpen: true,
      selectedDelivery: emailDelivery,
      kind: "detail",
    });
  };

  const onPageChange = ({ selected }: { selected: number }) => {
    if (!examDetail) {
      return;
    }

    updateUrl({ page: selected });
  };

  return (
    <div className={rootStyle}>
      <Loading isOpen={submitting} />
      <BreadcrumbsItem
        to={`/p/projectName/exams/${examDetail?.id}/delivery_${status}`}
      >
        {Message.getMessageByKey(pageTitle)}
      </BreadcrumbsItem>
      <ExamDetailHeader
        archived={examDetail?.isArchived()}
        editing={false}
        title={
          <>
            <Msg id={pageTitle} />
            <span className="code-exam-delivery-list__header__count">
              <span>{Message.getSearchResultFormat(pagination.count)}</span>
              {status === "scheduled" && (
                <span>
                  {`${
                    examCounts.pendingDeliveryCount
                  } ${Message.getMessageByKey("common.applicants")}`}
                </span>
              )}
            </span>
          </>
        }
      />
      <Table
        narrow={true}
        hoverable={false}
        className="code-exam-delivery-list__table"
      >
        <TableHead className="code-exam-delivery-list__table-title">
          <HeaderColumn size={6}>
            <Msg
              id={
                status === "history"
                  ? "deliveredAt"
                  : "exam.delivery.scheduledDateTime"
              }
            />
          </HeaderColumn>
          <HeaderColumn size={6}>
            <Msg id="delivery.subject" />
          </HeaderColumn>
          <HeaderColumn size={3}>
            <Msg id="common.deadline" />
          </HeaderColumn>
          <HeaderColumn size={2} className="align-right">
            <Msg id="exam.applicantCount" />
          </HeaderColumn>
          <HeaderColumn size={2} className="align-right"></HeaderColumn>
        </TableHead>
        <TableBody>
          {deliveryList.map((delivery) => {
            return (
              <Row key={delivery.id}>
                <Column>
                  <div
                    className={classnames({
                      "code-exam-delivery-list__table__body-start-at":
                        delivery.deliveryMethod === DeliveryMethod.Mail,
                    })}
                  >
                    {delivery.deliveryMethod === DeliveryMethod.Mail && (
                      <div>{formatDateTimeMinutes(deliveredAt(delivery))}</div>
                    )}
                    {status === "history" &&
                      delivery.deliveryStatus !==
                        DeliveryStatus.Unscheduled && (
                        <Tag>
                          {Message.getMessageByKey(
                            "exam.delivery.scheduledDelivery",
                          )}
                        </Tag>
                      )}
                    {status === "history" &&
                      delivery.deliveryMethod === DeliveryMethod.URL && (
                        <>
                          <span>N/A</span>
                          <QuickHelp
                            text={Message.getMessageByKey(
                              "exam.delivery.differentDeliveredDates",
                            )}
                            maxSize="large"
                          />
                          <Tag>
                            {Message.getMessageByKey("exam.urlDelivery.tag")}
                          </Tag>
                        </>
                      )}
                    {delivery.language && (
                      <LanguageTag language={delivery.language} color="pink" />
                    )}
                  </div>
                </Column>
                <Column>{delivery.mailSubject}</Column>
                <Column>
                  {delivery.deliveryMethod === DeliveryMethod.Mail ? (
                    delivery.endAt && formatDateTimeMinutes(delivery.endAt)
                  ) : (
                    <>
                      <span>N/A</span>
                      <QuickHelp
                        text={Message.getMessageByKey(
                          "exam.delivery.differentDeadlines",
                        )}
                        maxSize="large"
                      />
                    </>
                  )}
                </Column>
                <Column className="align-right">
                  {delivery.applicantExamCount}
                </Column>
                <Column>
                  <div className="code-exam-delivery-list__action-buttons">
                    <Tooltip
                      text={Message.getMessageByKey("action.seeDetails")}
                      placement="top-end"
                    >
                      <Button
                        size="small"
                        shrink={true}
                        onClick={() => onOpenModal(delivery)}
                        ariaLabel="Delivery Detail"
                      >
                        <Icon type="file-text" />
                      </Button>
                    </Tooltip>
                    <Restricted
                      roles={[
                        ProjectRole.ProjectAdmin,
                        ProjectRole.ExamDeliverer,
                      ]}
                      strictAllow={
                        delivery.deliveryStatus === DeliveryStatus.Scheduled
                      }
                      wrapperTagName={false}
                    >
                      <Tooltip
                        text={Message.getMessageByKey("delivery.cancel")}
                        placement="top-end"
                      >
                        <Button
                          size="small"
                          shrink={true}
                          onClick={() =>
                            setModal({
                              isOpen: true,
                              selectedDelivery: delivery,
                              kind: "delete",
                            })
                          }
                          ariaLabel="Cancel Delivery"
                        >
                          <Icon type="trash" />
                        </Button>
                      </Tooltip>
                    </Restricted>
                  </div>
                </Column>
              </Row>
            );
          })}
        </TableBody>
      </Table>
      <div className="code-exam-delivery-list__pagination">
        <Pagination pagination={pagination} onPageChange={onPageChange} />
      </div>
      {modal.isOpen &&
        modal.selectedDelivery &&
        (modal.kind === "delete" ? (
          <Modal
            ariaLabel="Confirm Cancel"
            okButtonType="danger"
            className="code-exam-delivery-list__delete-modal"
            title={<Msg id="cancel.delivery" />}
            okButtonLabel={<Msg id="cancel.delivery" />}
            isOpen={modal.isOpen && modal.kind === "delete"}
            onClose={onCloseModal}
            onClickCancel={onCloseModal}
            onClickOk={onDeleteScheduledDelivery}
            onClickBackground={onCloseModal}
          >
            <div className="code-exam-delivery-list__delete-modal__message">
              <Msg id="delivery.cancel.confirm" />
            </div>
            <FormGroup>
              <Label>
                <Msg id="deliveredAt" />
              </Label>
              <div>
                {modal.selectedDelivery.startAt &&
                  formatDateTimeMinutes(modal.selectedDelivery.startAt)}
              </div>
            </FormGroup>
            <FormGroup>
              <Label>
                <Msg id="delivery.subject" />
              </Label>
              <div className="is-break-word">
                {modal.selectedDelivery.mailSubject}
              </div>
            </FormGroup>
          </Modal>
        ) : (
          <ExamDeliveryDetailModal
            isOpen={modal.isOpen && modal.kind === "detail"}
            selectedDelivery={modal.selectedDelivery}
            status={status}
            canEditScheduledDelivery={
              status === "scheduled" &&
              Boolean(
                currentUser?.hasRole(
                  [ProjectRole.ProjectAdmin, ProjectRole.ExamDeliverer],
                  currentProjectId,
                ),
              )
            }
            canEditHistoryDelivery={
              status === "history" &&
              Boolean(currentUser?.hasRole([UserRole.SystemAdmin]))
            }
            onDeliveryClickOk={onUpdateDelivery}
            onScheduledDeliveryClickOk={onUpdateScheduledDelivery}
            onCloseModal={onCloseModal}
            submitting={submitting}
            examUrlToken={examUrlToken}
            onDeleteScheduledDelivery={onDeleteScheduledDelivery}
          />
        ))}
    </div>
  );
};
