import * as classnames from "classnames";
import * as React from "react";

import Message from "@shared/services/message";

import Card from "../card/Card";
import EllipsisMiddle from "../ellipsisMiddle/EllipsisMiddle";
import { Icon } from "../icon";
import IconButton from "../iconButton/IconButton";
import Spinner from "../spinner/Spinner";
import { Tooltip } from "../tooltip/Tooltip";

export interface FileCardProps {
  className?: string;
  disabled?: boolean;
  fileName: string;
  fileSize: string;
  isError?: boolean;
  isRemove?: boolean;
  isRetry?: boolean;
  isUploading?: boolean;
  statusMessage?: string;
  actionTooltip?: React.ReactNode;
  onClick?: () => void;
  onClose?: () => void;
}

export default function FileCard({
  className,
  disabled,
  fileName,
  fileSize,
  isError,
  isRemove,
  isRetry,
  isUploading,
  statusMessage,
  actionTooltip,
  onClick,
  onClose,
}: FileCardProps) {
  const rootStyle = classnames("code-c-file-card", className);
  const iconStyle = classnames(
    `code-c-file-card__action-icon${disabled ? "--disabled" : ""}`,
    {
      "code-c-file-card__action-icon--error": isRemove,
    },
  );
  const closeTooltip = Message.getMessageByKey("cancel");
  let actionIcon = "download";

  if (isRemove) {
    actionIcon = "trash-can";
  } else if (isRetry) {
    actionIcon = "arrow-rotate-right";
  }

  const handleClick = () => {
    onClick?.();
  };

  return (
    <Card className={rootStyle}>
      <Icon
        className="code-c-file-card__icon"
        type="file"
        variant="regular"
        iconClassName="code-c-icon__text-large"
      />
      <div className="code-c-file-card__content">
        {(isError || statusMessage) && (
          <div className="code-c-file-card__status">
            {isError && (
              <Icon
                type="exclamation-circle"
                className="code-c-icon__text-base code-c-file-card__error-icon"
              />
            )}
            <p className="code-c-file-card__error">{statusMessage}</p>
          </div>
        )}
        <EllipsisMiddle suffixLength={9}>{fileName}</EllipsisMiddle>
        <p
          className="code-c-file-card__size text-xs is-text-ellipsis"
          aria-label={fileSize}
          title={fileSize}
        >
          {fileSize}
        </p>
      </div>
      {isUploading ? (
        <div className="code-c-file-card__spinner">
          <Spinner color="gray" size="tiny" />
        </div>
      ) : (
        <Tooltip
          text={actionTooltip}
          disabled={!actionTooltip}
          maxSize="medium"
          className="code-c-file-card__action-tooltip"
        >
          <IconButton onClick={handleClick} aria-label="action" rounded>
            <Icon className={iconStyle} type={actionIcon} variant="solid" />
          </IconButton>
        </Tooltip>
      )}
      {onClose && (
        <IconButton
          className="code-c-file-card__discard"
          color="gray"
          onClick={onClose}
          aria-label="remove"
          rounded
        >
          <Tooltip text={closeTooltip} maxSize="medium">
            <Icon
              type="xmark"
              variant="solid"
              className="code-c-icon__text-base"
            />
          </Tooltip>
        </IconButton>
      )}
    </Card>
  );
}
