import QueryString from "query-string";
import { Observable } from "rxjs/Observable";
import {
  AjaxResponse,
  AjaxError,
  AjaxCreationMethod,
} from "rxjs/observable/dom/AjaxObservable";

import {
  alertAction,
  authSetAction,
  authSignOutAction,
  ajaxAction,
  AjaxActionOptions,
} from "../../actions";
import MSG from "./message";

/**
 * params:
 * url: API address string
 * body? post or put body
 * successAction: success action creator
 * errorAction: error action creator
 */
export const ajaxWrapper = (
  ajax: AjaxCreationMethod,
  method: string,
  url: string,
  body?: {},
  headers?: {},
  /* eslint-disable @typescript-eslint/ban-types */
  successAction?: Function,
  errorAction?: Function,
  /* eslint-enable @typescript-eslint/ban-types */
  options: AjaxActionOptions = {},
) => {
  if (method === "get" && body) {
    url += "?" + QueryString.stringify(body, { arrayFormat: "bracket" });
  }
  headers = Object.assign({}, headers);

  // if body is not form data, set to json content-type
  if (
    headers &&
    !headers["Content-Type"] &&
    body &&
    !(body instanceof FormData)
  ) {
    headers["Content-Type"] = "application/json";
  }
  return ajax({
    url: `${process.env.REACT_APP_BACKEND_HOST}${url}`,
    body,
    method,
    headers,
  })
    .retryWhen((errors) => {
      if (options.retryWhileOffline) {
        return errors
          .zip(
            Observable.range(1, options.retryWhileOffline.maxRetries),
            (error, retryCount) => ({ error, retryCount }),
          )
          .flatMap(
            ({
              error,
              retryCount,
            }: {
              error: AjaxError;
              retryCount: number;
            }) => {
              if (isNetworkError(error) && options.retryWhileOffline) {
                const delay = options.retryWhileOffline.delay;
                return Observable.timer(
                  typeof delay === "function" ? delay(retryCount) : delay,
                );
              }
              return Observable.throw(error);
            },
          );
      }
      return errors.flatMap((error) => Observable.throw(error));
    })
    .flatMap(({ response, status }: AjaxResponse) => {
      const errorActions = [ajaxAction.failure(options)];

      if (
        (!Boolean(response?.code) && [200, 201].includes(status)) ||
        [200, 201].includes(response?.code)
      ) {
        const successActions = [ajaxAction.success(options)];

        if (successAction) {
          successActions.push(successAction(response));
        }

        return successActions;
      } else {
        const errorString = getErrorString(response, status);

        if (errorAction) {
          errorActions.push(errorAction(errorString));
        }

        return errorActions;
      }
    })
    .catch((error: AjaxError) => {
      // ToDo: support returning status code and object if response was return by json string
      const errorString: string = getErrorString(
        error.xhr.response || error.xhr.statusText,
        error.xhr.status,
      );

      const errorActions = [];

      // Ignore 502 error. AWS load balancer returns it sometimes.
      if (options.disableDefaultError || error.status === 502) {
        errorActions.push(ajaxAction.failure(options));
      } else {
        errorActions.push(
          alertAction.error(errorString, ajaxAction.failure)(options),
        );
      }

      if (errorAction) {
        errorActions.push(errorAction(errorString));
      }

      if (error.status === 401) {
        errorActions.push(authSetAction.request(false));
        errorActions.push(authSignOutAction.success());
      }

      return Observable.of(...errorActions);
    });
};

/*
 * Function to get error from API response
 */
export const getErrorString = (
  payload: string | { messages?: Array<string> },
  code: number,
) => {
  if (isNetworkError(code)) {
    return MSG.getMessageByKey("message.connectionLost");
  }
  if (typeof payload === "string") {
    return code + " error. " + payload;
  }
  return Array.isArray(payload.messages)
    ? payload.messages.join(", ") || `${code} error.`
    : `${code} error.`;
};

const isNetworkError = (error: AjaxError | number) => {
  // The status code starts at 0.
  // Even if ajax processing completes, staying 0 means Network error.
  // - https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/status
  if (typeof error === "number") {
    return error === 0;
  }
  return error.xhr.status === 0;
};
