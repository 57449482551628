import * as classnames from "classnames";
import * as React from "react";

import { useStoreContext } from "@context";

import {
  Modal,
  Form,
  Radio,
  QuickHelp,
  Msg,
  Checkbox,
  Tooltip,
} from "@shared/components";
import { useFeatureFlags } from "@shared/hooks/query";
import { UserRole, ProjectKind } from "@shared/services/enums";
import Message from "@shared/services/message";

/**
 * Prop interface
 */
export interface ExamSelectProps {
  isOpen: boolean;
  canCreateOfficialExams?: boolean;
  canCreateMultiLangExams?: boolean;
  projectKind?: number;
  onCloseModal: () => void;
  onSelect: (creationMethodType: string, enableMultiLanguage: boolean) => void;
}
export enum CreationMethodType {
  official = "official",
  new = "new",
  talenthub = "talenthub",
}

/**
 * State interface
 */
type ExamSelectForm = {
  creationMethodType: CreationMethodType;
  enableMultiLanguage: boolean;
};

/**
 * Page component
 */
export function ExamSelect(props: ExamSelectProps) {
  const {
    isOpen,
    canCreateOfficialExams,
    canCreateMultiLangExams,
    projectKind,
  } = props;

  const { data: featureFlags } = useFeatureFlags();
  const talenthubEnabled = featureFlags?.talentHub;
  const isDigipassProject = projectKind === ProjectKind.Digipass;

  const { user } = useStoreContext();
  const isSystemAdmin = user?.hasRole(UserRole.SystemAdmin);

  /**
   * State
   */
  const [form, setForm] = React.useState<ExamSelectForm>({
    creationMethodType: isDigipassProject
      ? CreationMethodType.talenthub
      : canCreateOfficialExams
      ? CreationMethodType.official
      : CreationMethodType.new,
    enableMultiLanguage: false,
  });

  /**
   * Private Functions
   */
  const rootStyle = classnames("code-exam-select");

  const onFormChange = (_: boolean, formValues: ExamSelectForm) => {
    const { creationMethodType, enableMultiLanguage } = formValues;
    setForm({
      creationMethodType,
      enableMultiLanguage:
        creationMethodType === "new" ? enableMultiLanguage : false,
    });
  };

  const onSelect = () => {
    const { creationMethodType, enableMultiLanguage } = form;
    props.onSelect(
      creationMethodType,
      creationMethodType === "new" ? enableMultiLanguage : false,
    );
  };

  /**
   * Render
   */
  return (
    <Modal
      className={rootStyle}
      title={<Msg id="createExam.title" />}
      isOpen={isOpen}
      onClose={props.onCloseModal}
      onClickCancel={props.onCloseModal}
      onClickOk={onSelect}
      okButtonLabel={<Msg id="action.next" />}
      okButtonAriaLabel="Next"
      ariaLabel="Create New Exam"
    >
      <Form
        validation={{
          creationMethodType: ["string", "required"],
          enableMultiLanguage: ["boolean"],
        }}
        initialValues={{
          creationMethodType: isDigipassProject
            ? CreationMethodType.talenthub
            : canCreateOfficialExams
            ? CreationMethodType.official
            : CreationMethodType.new,
          enableMultiLanguage: false,
        }}
        onFormChange={onFormChange}
        updateValues={{ enableMultiLanguage: form.enableMultiLanguage }}
        clear={isOpen}
      >
        <p>
          <Msg id="createExam.chooseStyle" />
        </p>
        <div className="radio-group">
          {!isDigipassProject && (
            <>
              <Radio
                name="creationMethodType"
                defaultValue={CreationMethodType.official}
                readOnly={!canCreateOfficialExams}
              >
                <div className="radio-group__item">
                  <div>
                    <Tooltip
                      disabled={canCreateOfficialExams}
                      text={<Msg id="tier.disabled" />}
                    >
                      <strong>
                        <Msg id="createExam.officialExam.select" />
                      </strong>
                    </Tooltip>
                    <QuickHelp
                      text={Message.getMessageByKey(
                        "createExam.officialExam.explanation",
                      )}
                      maxSize="large"
                    />
                  </div>
                  <Msg id="createExam.officialExam.optionText" />
                </div>
              </Radio>
              <Radio
                name="creationMethodType"
                defaultValue={CreationMethodType.new}
              >
                <div className="radio-group__item">
                  <strong>
                    <Msg id="createExam.blankExam" />
                  </strong>
                  <Msg id="createExam.blankExam.optionText" />
                </div>
              </Radio>
              <div className="code-exam-select__enable-multiLanguage">
                <Checkbox
                  name="enableMultiLanguage"
                  readOnly={
                    !canCreateMultiLangExams ||
                    form.creationMethodType !== CreationMethodType.new
                  }
                >
                  <Tooltip
                    text={<Msg id="tier.disabled" />}
                    disabled={canCreateMultiLangExams}
                  >
                    <Msg id="exam.multilanguage.choice" />
                  </Tooltip>
                  <QuickHelp
                    text={Message.getMessageByKey(
                      "exam.multilanguage.choice.tooltip",
                    )}
                    maxSize="xlarge"
                  />
                </Checkbox>
              </div>
            </>
          )}
          {talenthubEnabled && isDigipassProject && isSystemAdmin && (
            <Radio
              name="creationMethodType"
              defaultValue={CreationMethodType.talenthub}
              className="radio-container"
            >
              <div className="radio-group__item">
                <div>
                  <strong>
                    <Msg id="createExam.talentHubExam.select" />
                  </strong>
                </div>
                <Msg id="createExam.talentHubExam.optionText" />
              </div>
            </Radio>
          )}
        </div>
      </Form>
    </Modal>
  );
}
