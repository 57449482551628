import * as classnames from "classnames";
import * as classNames from "classnames";
import * as React from "react";

import {
  PageTitle,
  Msg,
  FormGroup,
  Label,
  Checkbox,
  Form,
} from "@shared/components";
import { CustomFormDefinitionModel } from "@shared/models";
import { ExamType, SpokenLanguages } from "@shared/services/enums";

import {
  CustomFormConfigureBox,
  CustomFormPreviewBox,
  CustomFormEdit,
} from "./partials";

/**
 * Prop interface
 */
export interface ExamFormsProps {
  examLanguage?: SpokenLanguages;
  examType?: ExamType;
  readOnly?: boolean;
  onFormChange?: (formValid: boolean, formValues: {}) => void;
  initialValues?: CustomFormDefinitionModel[];
  applicantNameRequired?: boolean;
  hideTitle?: boolean;
}

/**
 * React Component
 */
export default function ExamForms({
  examLanguage = SpokenLanguages.Japanese,
  examType,
  readOnly,
  onFormChange,
  initialValues = [],
  applicantNameRequired,
  hideTitle,
}: ExamFormsProps) {
  const multiLanguage = examType === ExamType.EnglishJapanese;

  /**
   * State
   */
  const [form, setForm] = React.useState<{
    applicantNameRequired?: boolean;
    formDefinitions: CustomFormDefinitionModel[];
  }>({
    applicantNameRequired,
    formDefinitions: initialValues,
  });

  const [customFormEdit, setCustomFormEdit] = React.useState<{
    isOpen: boolean;
    customForm?: CustomFormDefinitionModel;
    language?: string;
  }>({ isOpen: false });

  /**
   * Effects
   */
  React.useEffect(() => {
    if (readOnly) {
      setForm({ applicantNameRequired, formDefinitions: initialValues });
    }
  }, [initialValues, applicantNameRequired, readOnly]);

  React.useEffect(() => {
    const { applicantNameRequired, formDefinitions } = form;

    const formDefs = {
      applicantNameRequired,
      forms: formDefinitions
        // always ja first and next en
        .sort((a, b) => ((a.language || "") > (b.language || "") ? -1 : 1))
        .map(
          (definition, displayOrder) =>
            new CustomFormDefinitionModel({ ...definition, displayOrder }),
        ),
    };

    if (typeof onFormChange === "function") {
      onFormChange(true, { formDefs });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  /**
   * Private Functions
   */
  const rootStyle = classnames("code-exam-forms", { "is-preview": readOnly });

  const onCloseModal = () => {
    setCustomFormEdit({ isOpen: false });
  };

  const onOpenModal = (
    customForm?: CustomFormDefinitionModel,
    language?: string,
  ) => {
    setCustomFormEdit({ isOpen: true, customForm, language });
  };

  const onSave = (definition: CustomFormDefinitionModel) => {
    const { customForm, language } = customFormEdit;
    const newFormDefinitions = customForm
      ? [
          ...form.formDefinitions.map((item) =>
            item.key === customForm.key
              ? new CustomFormDefinitionModel({ ...definition, language })
              : item,
          ),
        ]
      : [
          ...form.formDefinitions,
          new CustomFormDefinitionModel({ ...definition, language }),
        ];
    setForm({
      ...form,
      formDefinitions: newFormDefinitions,
    });
    setCustomFormEdit({ isOpen: false });
  };

  const onRemove = (definition: CustomFormDefinitionModel) => {
    setForm({
      ...form,
      formDefinitions: form.formDefinitions.filter(
        (item) => item.key !== definition.key,
      ),
    });
  };

  const onReorderItem = (sortItemIndexes: number[], language?: string) => {
    const reorderdItems: CustomFormDefinitionModel[] = [];
    if (language) {
      const { en, ja } = form.formDefinitions.reduce(
        (
          object: {
            ja: CustomFormDefinitionModel[];
            en: CustomFormDefinitionModel[];
          },
          definision,
        ) => {
          return {
            ja: [
              ...object.ja,
              ...(definision.language === "ja" ? [definision] : []),
            ],
            en: [
              ...object.en,
              ...(definision.language === "en" ? [definision] : []),
            ],
          };
        },
        { ja: [], en: [] },
      );
      reorderdItems.push(
        ...(language === "ja"
          ? ja.map((_, index) => ja[sortItemIndexes[index]])
          : ja),
        ...(language === "en"
          ? en.map((_, index) => en[sortItemIndexes[index]])
          : en),
      );
    } else {
      reorderdItems.push(
        ...form.formDefinitions.map(
          (_, index) => form.formDefinitions[sortItemIndexes[index]],
        ),
      );
    }
    setForm({
      ...form,
      formDefinitions: reorderdItems,
    });
  };

  const onChangeFullName = (e: React.FormEvent<HTMLInputElement>) => {
    const { checked } = e.currentTarget;
    setForm({ ...form, applicantNameRequired: checked });
  };

  /**
   * Render
   */
  return (
    <>
      <div className={rootStyle}>
        {!hideTitle && (
          <PageTitle>
            <Msg id="exam.entryForm" />
          </PageTitle>
        )}
        <div className="code-exam-forms__container">
          <div className="code-exam-forms__body">
            {!readOnly && (
              <div>
                <div className="code-exam-forms__header">
                  <div>
                    <Msg id="exam.entryForm.configuration" />
                  </div>
                </div>
                <div className="code-exam-forms__form">
                  <Form>
                    <FormGroup>
                      <Label>
                        <Msg id="exam.forms.presetForm" />
                      </Label>
                      <Checkbox
                        value={form.applicantNameRequired}
                        onChange={onChangeFullName}
                      >
                        <Msg id="exam.forms.fullnameRequired" />
                      </Checkbox>
                    </FormGroup>
                    <FormGroup>
                      <Label>
                        <Msg id="exam.forms.customForm" />
                      </Label>
                      <CustomFormConfigureBox
                        definitions={form.formDefinitions}
                        language={multiLanguage ? "ja" : undefined}
                        onEdit={onOpenModal}
                        onRemove={onRemove}
                        onReorderItem={onReorderItem}
                      />
                      {multiLanguage && (
                        <CustomFormConfigureBox
                          definitions={form.formDefinitions}
                          language={"en"}
                          onEdit={onOpenModal}
                          onRemove={onRemove}
                          onReorderItem={onReorderItem}
                        />
                      )}
                    </FormGroup>
                  </Form>
                </div>
              </div>
            )}
            <div
              className={classNames("code-exam-forms__preview-wrapper", {
                "code-exam-forms__left-border": !readOnly,
              })}
            >
              <div className="code-exam-forms__header">
                <div>
                  <Msg id="preview" />
                </div>
              </div>
              <div className="code-exam-forms__preview">
                <CustomFormPreviewBox
                  applicantNameRequired={form.applicantNameRequired}
                  examLanguage={examLanguage}
                  definitions={form.formDefinitions}
                  language={multiLanguage ? "ja" : undefined}
                />
                {multiLanguage && (
                  <CustomFormPreviewBox
                    applicantNameRequired={form.applicantNameRequired}
                    examLanguage={SpokenLanguages.English}
                    definitions={form.formDefinitions}
                    language="en"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {customFormEdit.isOpen && (
        <CustomFormEdit
          isOpen={true}
          customFormElement={customFormEdit.customForm}
          invalidKeys={form.formDefinitions
            .filter(
              (definition) => definition.key !== customFormEdit.customForm?.key,
            )
            .map((definition) => definition.key)}
          examLanguage={
            customFormEdit.language
              ? (customFormEdit.language as SpokenLanguages)
              : examLanguage
          }
          onCancel={onCloseModal}
          onSave={onSave}
        />
      )}
    </>
  );
}
