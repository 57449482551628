import { ChallengeStatus, QuestionType } from "@shared/services/enums";

import { EnumModel, QuestionInsightModel, QuestionModel } from ".";

export type QuestionFilterModel = {
  quizCategories: EnumModel[];
};

class QuestionListModel {
  public id: number;
  public organizationId: number;
  public question: string;
  public status: ChallengeStatus;

  public title: string;
  public kind: QuestionType;
  public tags: Array<string> = [];
  public challenges: Array<{ id: number; title: string }> = [];
  public createdAt: string;
  public updatedAt: string;
  public isOfficial: boolean;
  public quizCategories: Array<number> = [];
  public globalInsight?: QuestionInsightModel;

  constructor(args?: Partial<QuestionListModel> | QuestionModel) {
    Object.assign(this, args);

    if (args?.globalInsight) {
      this.globalInsight = new QuestionInsightModel(args.globalInsight);
    }
  }

  public getGlobalCorrectRate(): number | null {
    const correctScoreDistribution =
      this.globalInsight?.overall.scoreDistribution.find(
        (scoreDistribution) => scoreDistribution.score === 1,
      );

    if (!correctScoreDistribution) {
      return null;
    }

    const fraction = Number(correctScoreDistribution.fraction);

    return fraction * 100;
  }

  public getGlobalCorrectRateAsFormattedString(): string {
    const rate = this.getGlobalCorrectRate();
    if (rate === null) {
      return "";
    }
    const rounded = Number(rate.toFixed(2)); // for cases like 14.000002

    return `${rounded}%`;
  }
}

export default QuestionListModel;
