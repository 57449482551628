import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { RootState } from "../../../reducers";
import ProjectIdObserver, { ProjectIdObserverProps } from "./ProjectIdObserver";

const mapStateToProps = (state: RootState, props: ProjectIdObserverProps) => ({
  ...props,
  user: state.user.user,
});

export default withRouter(connect(mapStateToProps, null)(ProjectIdObserver));
