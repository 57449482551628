import * as classnames from "classnames";
import { isEqual } from "lodash";
import * as React from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";

import {
  Block,
  Table,
  TableHead,
  TableBody,
  Row,
  Column,
  HeaderColumn,
  Pagination,
  Tooltip,
  Button,
  Icon,
  Modal,
  LeftBlock,
  Msg,
  Restricted,
  Input,
  RightBlock,
  Tag,
  DateTime,
  PageTitle,
} from "@shared/components";
import {
  PaginationModel,
  PendingInviteModel,
  ProjectSwitchItemModel,
} from "@shared/models";
import { formatDateTimeMinutes } from "@shared/services/date";
import { MailStatus, MemberEditType } from "@shared/services/enums";
import Message from "@shared/services/message";
import { updateUrl, parseUrl } from "@shared/services/queryString";
import * as urlParser from "@shared/services/urlParser";

import MemberEdit from "../../../../members/memberEdit/MemberEdit.connect";
import MemberRoleEdit from "../../../../members/memberRoleEdit/MemberRoleEdit.connect";

export interface MemberPendingProps {
  currentProjectId: number;
  invites: Array<PendingInviteModel>;
  pagination: PaginationModel;
  editableRoles: number[];
  projects: ProjectSwitchItemModel[] | undefined;
  inviteMemberLoading: boolean;
  getInvites: (pagination: PaginationModel, keyword?: string) => void;
  resendInvite: (inviteId: number) => void;
  deleteInvite: (inviteId: number) => void;
  updateInvite: (inviteId: number, formValues: {}) => void;
}

export interface MemberPendingState {
  confirmDelete: boolean;
  confirmEdit: boolean;
  selectedInvite?: PendingInviteModel;
  keyword: string;
}

export class SettingMemberPending extends React.Component<
  MemberPendingProps,
  MemberPendingState
> {
  constructor(props: MemberPendingProps) {
    super(props);

    this.state = {
      confirmDelete: false,
      confirmEdit: false,
      keyword: "",
    };
  }

  public componentDidMount() {
    this.getInvitesFromURL();
  }

  public shouldComponentUpdate(
    nextProps: MemberPendingProps,
    nextState: MemberPendingState,
  ) {
    return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state);
  }

  public render() {
    const rootStyle = classnames("code-setting-member-pending");
    const {
      editableRoles,
      pagination,
      currentProjectId,
      projects,
      inviteMemberLoading,
    } = this.props;

    // if projects is provided then means on organization pending invite page
    // otherwise it is on project pending invite page
    const accessibleProjects = projects ?? [
      { id: currentProjectId } as ProjectSwitchItemModel,
    ];

    const invites = this.props.invites.map((invite: PendingInviteModel) => (
      <Row key={invite.id}>
        <Column>
          {invite.isExpired && (
            <div>
              <Tag className="code-c-tag__expired">
                {Message.getMessageByKey("invite.expired")}
              </Tag>
            </div>
          )}
          {invite.email}
        </Column>
        <Column>
          <div
            className="code-setting-member-pending__roles"
            aria-label={`member-pending-${invite.id}`}
          >
            {invite
              .getRoleLabelByProject(accessibleProjects)
              .map(({ projectName, roles }, idx) => (
                <div key={idx}>
                  {projectName && (
                    <div className="code-setting-member-pending__roles__project-name is-text-ellipsis">
                      {projectName}
                    </div>
                  )}
                  <div
                    className={classnames({
                      "code-setting-member-pending__roles__role-names":
                        Boolean(projectName),
                    })}
                  >
                    {roles.map((role) => (
                      <div key={role}>{role}</div>
                    ))}
                  </div>
                </div>
              ))}
          </div>
        </Column>
        <Column>
          <DateTime
            dateTime={formatDateTimeMinutes(invite.updatedAt)}
            warningMessage={MailStatus.getErrorMessage(invite.mailStatus)}
          />
        </Column>
        <Column>
          <DateTime dateTime={formatDateTimeMinutes(invite.expiresAt)} />
        </Column>
        <Column>
          <div className="code-setting-member-pending__actions">
            <Restricted roles={editableRoles}>
              <Tooltip
                text={Message.getMessageByKey(
                  invite.isExpired ? "member.invite.expired.message" : "resend",
                )}
                maxSize={invite.isExpired ? "large" : undefined}
                placement="top-end"
              >
                <Button
                  onClick={() => this.props.resendInvite(invite.id)}
                  size={"small"}
                  shrink={true}
                  ariaLabel="Resend"
                  disabled={invite.isExpired}
                >
                  <Icon type={"envelope-o"} />
                </Button>
              </Tooltip>
            </Restricted>
            <Restricted roles={editableRoles}>
              <Tooltip
                text={Message.getMessageByKey(
                  invite.isExpired ? "action.seeDetails" : "edit",
                )}
                placement="top-end"
              >
                <Button
                  onClick={() =>
                    this.setState({
                      selectedInvite: invite,
                      confirmEdit: true,
                    })
                  }
                  size="small"
                  shrink={true}
                  ariaLabel={invite.isExpired ? "Detail" : "Edit"}
                >
                  <Icon type={invite.isExpired ? "file-text" : "pencil"} />
                </Button>
              </Tooltip>
            </Restricted>
            <Restricted roles={editableRoles}>
              <Tooltip
                text={Message.getMessageByKey("delete")}
                placement="top-end"
              >
                <Button
                  onClick={() =>
                    this.setState({
                      selectedInvite: invite,
                      confirmDelete: true,
                    })
                  }
                  size={"small"}
                  shrink={true}
                  ariaLabel="Delete"
                >
                  <Icon type={"trash"} />
                </Button>
              </Tooltip>
            </Restricted>
          </div>
        </Column>
      </Row>
    ));

    return (
      <div className={rootStyle}>
        <BreadcrumbsItem to="/settings/org/members/pending">
          <Msg id="pendingInvites" />
        </BreadcrumbsItem>
        <Block className="code-setting-member-pending__header">
          <LeftBlock>
            <PageTitle>
              <Msg id="pendingInvites" />
              <span className="code-setting-member-pending__header__count">
                {Message.getSearchResultFormat(pagination.count)}
              </span>
            </PageTitle>
          </LeftBlock>
          <RightBlock>
            <Msg id="form.keyword" />
            <Input
              name="keyword"
              value={this.state.keyword}
              onChange={(e) => this.handleKeywordChange(e.currentTarget.value)}
              placeholder={Message.getMessageByKey("form.email")}
            />
          </RightBlock>
        </Block>
        <Table>
          <TableHead>
            <HeaderColumn size={4}>
              <Msg id="common.email" />
            </HeaderColumn>
            <HeaderColumn size={3}>
              <Msg id="common.role" />
            </HeaderColumn>
            <HeaderColumn size={2}>
              <Msg id="invitedAt" />
            </HeaderColumn>
            <HeaderColumn size={2}>
              <Msg id="expiresAt" />
            </HeaderColumn>
            <HeaderColumn size={2} />
          </TableHead>
          <TableBody>{invites}</TableBody>
        </Table>
        <div className="code-setting-member-pending__pagination">
          <Pagination
            pagination={pagination}
            onPageChange={this.handlePageChange}
          />
        </div>
        {this.state.confirmDelete && (
          <Modal
            title={`${Message.getMessageByKey("member.cancelInvite")}`}
            isOpen={this.state.confirmDelete}
            onClose={this.onCancel}
            onClickCancel={this.onCancel}
            onClickOk={() =>
              this.onInviteDelete(
                this.state.selectedInvite && this.state.selectedInvite.id,
              )
            }
            ariaLabel="Cancel Invitation"
          >
            <div className="code-setting-member-pending__email">
              {this.state.selectedInvite && this.state.selectedInvite.email}
            </div>
            <Msg id="navigate.areYouSure" defaultMessage="Are you sure?" />
          </Modal>
        )}
        {this.state.confirmEdit &&
          this.state.selectedInvite &&
          (projects ? (
            <MemberEdit
              projects={projects}
              isOpen={this.state.confirmEdit}
              loading={inviteMemberLoading}
              member={this.state.selectedInvite}
              type={MemberEditType.Edit}
              onCancel={this.onCancel}
              onClickOk={(formValues) => {
                this.state.selectedInvite &&
                  this.onInviteUpdate(
                    this.state.selectedInvite?.id,
                    formValues,
                  );
              }}
            />
          ) : (
            <MemberRoleEdit
              projectId={currentProjectId}
              isOpen={this.state.confirmEdit}
              loading={inviteMemberLoading}
              member={this.state.selectedInvite}
              type={MemberEditType.Edit}
              onCancel={this.onCancel}
              onClickOk={(id, formValues) =>
                this.onInviteUpdate(id, formValues)
              }
            />
          ))}
      </div>
    );
  }

  private getInvitesFromURL = () => {
    const { keyword, page } = parseUrl(window.location.search);
    const formValues = {
      keyword: urlParser.getString(keyword) || "",
    };
    this.setState({ keyword: formValues.keyword }, () => {
      this.props.getInvites(
        this.props.pagination.getAtPage(urlParser.getPageNo(page)),
        formValues.keyword,
      );
    });
  };

  private handleKeywordChange = (keyword: string) => {
    if (this.state.keyword === keyword) {
      return;
    }
    updateUrl({ keyword });
    this.setState({ keyword }, () => {
      this.props.getInvites(new PaginationModel(), this.state.keyword);
    });
  };

  private onCancel = () => {
    this.setState({
      selectedInvite: undefined,
      confirmDelete: false,
      confirmEdit: false,
    });
  };

  private onInviteDelete = (id?: number) => {
    this.onCancel();
    if (id) {
      this.props.deleteInvite(id);
    }
  };

  private onInviteUpdate = (id: number, formValues: {}) => {
    this.onCancel();
    this.props.updateInvite(id, formValues);
  };

  private handlePageChange = ({ selected }: { selected: number }) => {
    updateUrl({
      keyword: this.state.keyword,
      page: selected,
    });

    this.props.getInvites(
      this.props.pagination.getAtPage(selected),
      this.state.keyword,
    );
  };
}
