import { AxiosRequestConfig } from "axios";

import { ChallengeModel } from "@shared/models";
import LinkedChallenge from "@shared/models/LinkedChallenge.model";
import { ChallengeStatus } from "@shared/services/enums";

import { ApiResponse, post, put } from "../httpClient";

interface ChallengeQuestionParams<T = any> {
  projectId: number;
  options?: AxiosRequestConfig<T>;
}

export interface ChallengeQuestionPayload {
  basicTimeMinutes: number;
  description?: string;
  difficulty: number;
  language: string;
  programmingCategories: number[];
  questionIds: number[];
  status: ChallengeStatus;
  title: string;
}

interface UpdateChallengeQuestionParams
  extends ChallengeQuestionParams<
    Pick<ChallengeQuestionPayload, "questionIds">
  > {
  challengeId: number;
}

export async function postChallengeQuestion({
  projectId,
  options,
}: ChallengeQuestionParams<ChallengeQuestionPayload>) {
  const { data } = await post<ApiResponse<ChallengeModel>>(
    `/api/projects/${projectId}/challenges/question`,
    options,
  );

  return data;
}

export async function putChallengeQuestion({
  challengeId,
  projectId,
  options,
}: UpdateChallengeQuestionParams) {
  const { data } = await put<ApiResponse<LinkedChallenge>>(
    `/api/projects/${projectId}/challenges/${challengeId}/questions`,
    options,
  );

  return data;
}
