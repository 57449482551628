import classnames from "classnames";
import * as React from "react";

import {
  Form,
  FormGroup,
  Label,
  Input,
  PageTitle,
  Select,
  Textarea,
  RichMarkdown,
  Msg,
  LanguageTag,
  Icon,
} from "@shared/components";
import {
  useExamJobTitleOptions,
  usePurposeOfUseOptions,
} from "@shared/hooks/query";
import { ExamModel } from "@shared/models";

/**
 * Services
 */
import { ExamType } from "@shared/services/enums";
import {
  getExamCreateOutlineOptions,
  getExamDetailOutlineOptions,
} from "@shared/services/exam";

/**
 * Types
 */
export type ExamOutlineForm = {
  name: string;
  description: string;
  enName?: string;
  enDescription?: string;
  nextStepInfo?: string;
  memo?: string;
  engineerRole: number;
  purposeOfUse: string;
};

export type ExamOutlineProps = {
  examType?: ExamType;
  readOnly?: boolean;
  onFormChange?: (formValid: boolean, formValues: {}, forErrors: {}) => void;
  initialValues?: {};
  resetForm?: boolean;
  showAllErrors?: boolean;
  editLimited?: boolean;
  hideTitle?: boolean;
  componentType: "examCreate" | "examDetail";
};

function ExamOutline(props: ExamOutlineProps) {
  const {
    readOnly = false,
    examType,
    resetForm,
    showAllErrors,
    initialValues,
    hideTitle,
    componentType,
  } = props;

  const { data: purposeOfUseOptions } = usePurposeOfUseOptions();
  const { data: examJobTitleOptions } = useExamJobTitleOptions();

  /**
   * State
   */
  const [form, setForm] = React.useState<{
    formValid: boolean;
    formValues: ExamOutlineForm;
    formErrors?: {};
  }>({ formValid: false, formValues: props.initialValues as ExamOutlineForm });

  /**
   * Private Functions
   */
  const multiLanguage = examType === ExamType.EnglishJapanese;

  const rootStyle = classnames("code-exam-edit__outline", {
    ["is-multi-lang"]: multiLanguage,
  });

  const onFormChange = (
    formValid: boolean,
    formValues: ExamOutlineForm,
    formErrors: {},
  ) => {
    setForm({ formValid, formValues, formErrors });
    props.onFormChange?.(formValid, formValues, formErrors);
  };
  const engineerRole = (props.initialValues as ExamModel).examSegment
    ?.engineerRole;

  /**
   * Render
   */
  return (
    <div className={rootStyle}>
      <Form
        key={`${readOnly}`}
        className="code-exam-edit__form"
        validation={{
          ...{
            name: ["string", ["max", 255], "notEmpty", "required"],
            description: ["string", "notEmpty", "required"],
            nextStepInfo: ["string"],
            memo: ["string"],
          },
          ...(examType === ExamType.EnglishJapanese && {
            enName: ["string", ["max", 255], "notEmpty", "required"],
            enDescription: ["string", "notEmpty", "required"],
          }),
          purposeOfUse: [
            "string",
            "required",
            ["strRequired", { defaultValues: ["not-defined"] }],
          ],
          engineerRole: [
            "string",
            "required",
            ["strRequired", { defaultValues: ["0"] }],
          ],
        }}
        showError={
          componentType === "examDetail"
            ? {
                purposeOfUse: !readOnly,
                engineerRole: !readOnly,
              }
            : undefined
        }
        initialValues={{
          engineerRole:
            typeof engineerRole !== "undefined" ? `${engineerRole}` : "",
          purposeOfUse:
            (props.initialValues as ExamModel).examSegment?.purposeOfUse ?? "",
          ...props.initialValues,
        }}
        onFormChange={onFormChange}
        clear={resetForm}
        showAllErrors={showAllErrors}
      >
        {!hideTitle && (
          <PageTitle className="code-exam-edit__outline__title">
            <Msg id="outline" />
          </PageTitle>
        )}
        <FormGroup className="code-exam-edit__outline__name-container">
          <div className="code-exam-edit__outline__name">
            {multiLanguage && (
              <div>
                <LanguageTag language="ja" color="pink" />
              </div>
            )}
            <div>
              <Label className="text-sm">
                <Msg id="form.exam.name" />
              </Label>
              <Input disabled={readOnly} name="name" />
            </div>
            <div>
              <div className="code-exam-edit__outline__label-container">
                <Label className="code-exam-edit__outline__label">
                  <Msg id="form.description" />
                </Label>
                <div className="code-exam-edit__outline__sub-label">
                  <Msg id="exam.descriptionSubLabel" />
                </div>
              </div>
              <div>
                <RichMarkdown
                  value={
                    readOnly
                      ? (initialValues as { description: string }).description
                      : form.formValues.description
                  }
                  name="description"
                  preview={readOnly}
                />
              </div>
            </div>
            {!multiLanguage && (
              <div>
                <div className="code-exam-edit__outline__label-container">
                  <Label className="code-exam-edit__outline__label" isOptional>
                    <Msg id="exam.guidance" />
                  </Label>
                  <div className="code-exam-edit__outline__sub-label">
                    <Msg id="exam.guidance.subLabel" />
                  </div>
                </div>
                <div>
                  <RichMarkdown
                    value={
                      readOnly
                        ? (initialValues as { nextStepInfo: string })
                            .nextStepInfo
                        : form.formValues.nextStepInfo
                    }
                    name="nextStepInfo"
                    preview={readOnly}
                  />
                </div>
              </div>
            )}
          </div>
          {multiLanguage && (
            <div className="code-exam-edit__outline__name">
              <div>
                <LanguageTag language="en" color="pink" />
              </div>
              <div>
                <Label>
                  <Msg id="form.exam.name" />
                </Label>
                <Input disabled={readOnly} name="enName" />
              </div>
              <div>
                <div className="code-exam-edit__outline__label-container">
                  <div className="code-exam-edit__outline__label">
                    <Msg id="form.description" />
                  </div>
                  <div className="code-exam-edit__outline__sub-label">
                    <Msg id="exam.descriptionSubLabel" />
                  </div>
                </div>
                <div>
                  <RichMarkdown
                    value={
                      readOnly
                        ? (initialValues as { enDescription: string })
                            .enDescription
                        : form.formValues.enDescription
                    }
                    name="enDescription"
                    preview={readOnly}
                  />
                </div>
              </div>
            </div>
          )}
        </FormGroup>
        {multiLanguage && (
          <FormGroup className="code-exam-edit__outline__next-step-container">
            <div className="code-exam-edit__outline__label-container">
              <Label className="code-exam-edit__outline__label" isOptional>
                <Msg id="exam.guidance" />
              </Label>
              <div className="code-exam-edit__outline__sub-label">
                <Msg id="exam.guidance.subLabel" />
              </div>
            </div>
            <div>
              <RichMarkdown
                value={
                  readOnly
                    ? (initialValues as { nextStepInfo: string }).nextStepInfo
                    : form.formValues.nextStepInfo
                }
                name="nextStepInfo"
                preview={readOnly}
              />
            </div>
          </FormGroup>
        )}
        <FormGroup className="code-exam-edit__outline__job-container">
          <div>
            <Icon
              className="code-exam-edit__outline__job-container__info-icon"
              type="info-circle"
            />
            <Msg id="exam.survey.title" />
          </div>
          <div className="code-exam-edit__outline__job-container__select-container">
            <div>
              <Label>
                <Msg id="form.purposeOfUse" />
              </Label>
              <Select
                name="purposeOfUse"
                disabled={readOnly}
                options={
                  componentType === "examCreate"
                    ? getExamCreateOutlineOptions(purposeOfUseOptions || [])
                    : getExamDetailOutlineOptions(purposeOfUseOptions || [])
                }
              />
            </div>
            <div>
              <Label>
                <Msg id="form.jobTitle" />
              </Label>
              <Select
                name="engineerRole"
                disabled={readOnly}
                options={
                  componentType === "examCreate"
                    ? getExamCreateOutlineOptions(examJobTitleOptions || [])
                    : getExamDetailOutlineOptions(examJobTitleOptions || [])
                }
              />
            </div>
          </div>
          <div>
            <Label isOptional>
              <Msg id="exam.survey.notes" />
            </Label>
            <Textarea
              className="code-exam-edit__outline__memo"
              name="memo"
              disabled={readOnly}
            />
          </div>
        </FormGroup>
      </Form>
    </div>
  );
}

export default ExamOutline;
