import * as React from "react";
import { Provider as ReduxProvider } from "react-redux";
import { Store } from "redux";

import { RootState } from "@reducers/index";

import IntlProvider from "./IntlProvider";

type IntlProviderProps = React.ComponentProps<typeof IntlProvider>;

type Props = {
  store: Store<RootState>;
  children: React.ReactNode;
  intlProviderProps?: IntlProviderProps;
};

export const Provider: React.FC<Props> = ({
  store,
  children,
  intlProviderProps = {},
}) => {
  return (
    // note: ts-ignored due to type mismatch between react-redux 7.2 and redux 3.7
    // @ts-ignore
    <ReduxProvider store={store}>
      <IntlProvider {...intlProviderProps}>{children}</IntlProvider>
    </ReduxProvider>
  );
};
