import * as classnames from "classnames";
import { isEqual } from "lodash";
import * as React from "react";

import {
  ChallengeModel,
  EnumModel,
  ChallengeFilterFormModel,
} from "@shared/models";
import { ChallengeStyle, TierAction } from "@shared/services/enums";
import { getLogarithmicScale } from "@shared/services/math";
import {
  isAIAllowed,
  isAllowedDifficulty,
  isDevelopmentAllowed,
} from "@shared/services/tier";

import {
  Row,
  Column,
  ChallengeTitle,
  DifficultyTag,
  TableBody,
  Checkbox,
  BarChartMini,
  TagCloud,
  Tag,
  HighlightedText,
  TogglePin,
  Icon,
  Msg,
  LinkedChallenge,
} from "..";
import { PossiblyNoDataWithTooltip } from "../question/partials/PossiblyNoDataWithTooltip";

/**
 * Prop interface
 */
export interface ExternalProps {
  challenge: ChallengeModel;
  keyword?: string;
  hasCheckbox?: boolean;
  hasTogglePin?: boolean;
  isPinned?: boolean;
  readOnly?: boolean;
  checked?: boolean;
  className?: string;
  preActionMenu?: React.ReactNode;
  postActionMenu?: React.ReactNode;
  isChallengeAllowed?: boolean;
  onClickDetail?: (challengeId: number) => void;
  onClickCheck?: (challengeId: number) => void;
  onClickTag?: (params: Partial<ChallengeFilterFormModel>) => void;
  onClickPin?: () => void;
}
export interface InjectedProps {
  programmingLanguages: EnumModel[];
  programmingCategories: EnumModel[];
  isTierActionAllowed: (tierAction: TierAction) => boolean;
}

type CollapsibleChallengeColumnProps = InjectedProps & ExternalProps;

const DEFAULT_COLSPAN = 10;

/**
 * React Component
 */
export default class CollapsibleChallengeColumn extends React.Component<CollapsibleChallengeColumnProps> {
  constructor(props: CollapsibleChallengeColumnProps) {
    super(props);
  }

  public shouldComponentUpdate(nextProps: CollapsibleChallengeColumnProps) {
    return !isEqual(nextProps, this.props);
  }

  public render() {
    const {
      className,
      challenge,
      hasCheckbox,
      hasTogglePin,
      isPinned,
      readOnly,
      checked,
      preActionMenu,
      postActionMenu,
      keyword,
      isChallengeAllowed = true,
      programmingCategories = [],
      programmingLanguages = [],
      isTierActionAllowed,
    } = this.props;

    const colSpan = DEFAULT_COLSPAN;
    const rootStyle = classnames("code-c-collapsible-challenge-column", {
      "is-pinned": isPinned,
      [`${className}`]: Boolean(className),
    });

    const { scoreDistributions = [], linkedChallenge } = challenge;

    const languageTagItems = [
      ...challenge.programmingLanguages.map((id) => {
        return {
          label:
            programmingLanguages.find((language) => language.value === id)
              ?.displayString || "",
          onClick: () => this.onClickTag({ programmingLanguages: [id] }),
        };
      }),
      ...challenge.programmingCategories.map((id) => {
        return {
          label:
            programmingCategories.find((category) => category.value === id)
              ?.displayString || "",
          onClick: () => this.onClickTag({ programmingCategories: [id] }),
        };
      }),
    ].map(({ label, onClick }) => ({ label, onClick }));

    const logarithmicScale = scoreDistributions.map((item) =>
      getLogarithmicScale(item.frequency),
    );

    return (
      <TableBody className={rootStyle}>
        <Row className="code-c-collapsible-challenge-column__header">
          <Column className="code-c-collapsible-challenge-column__toggle-wrapper">
            <div className="code-c-collapsible-challenge-column__toggle">
              {!hasTogglePin && isPinned && (
                <div className="code-c-collapsible-challenge-column__pinned">
                  <Icon type="thumb-tack" />
                  <Msg id="common.pinned" />
                </div>
              )}
              {hasTogglePin && (
                <div className="code-c-collapsible-challenge-column__toggle-pin">
                  <TogglePin
                    pinned={isPinned}
                    onClick={this.props.onClickPin}
                  />
                </div>
              )}
              {hasCheckbox && (
                <Checkbox
                  readOnly={readOnly}
                  value={checked}
                  onChange={this.onClickCheck}
                  ariaLabel={`Choose Challenge ${challenge.id}`}
                />
              )}
              {preActionMenu}
            </div>
          </Column>
          <Column className="is-nowrap">
            <HighlightedText keyword={keyword}>
              {challenge.id.toString()}
            </HighlightedText>
          </Column>
          <Column>
            <ChallengeTitle
              keyword={keyword}
              isChallengeAllowed={isChallengeAllowed}
              challenge={challenge}
              onClick={() => this.onClickDetail(challenge.id)}
              onTagClick={this.onClickTag}
              isTierActionAllowed={isTierActionAllowed}
            />
          </Column>
          <Column>
            <DifficultyTag
              value={
                challenge.difficulty || challenge.currentVersion.difficulty
              }
              onClick={() =>
                this.onClickTag({
                  difficulties: [
                    challenge.difficulty || challenge.currentVersion.difficulty,
                  ],
                })
              }
              hasError={!isAllowedDifficulty(challenge, isTierActionAllowed)}
            />
          </Column>
          <Column>
            <Tag
              onClick={() => this.onClickTag({ styles: [challenge.style] })}
              hasError={
                !(
                  isDevelopmentAllowed(challenge, isTierActionAllowed) &&
                  isAIAllowed(challenge, isTierActionAllowed)
                )
              }
            >
              {ChallengeStyle.toString(challenge.style)}
            </Tag>
          </Column>
          <Column className="align-right">{challenge.numberOfOrgsUsing}</Column>
          <Column className="align-right">
            {challenge.currentVersion.basicTimeMinutes}
          </Column>
          <Column className="align-right">
            <BarChartMini
              max={Math.max(...logarithmicScale)}
              data={logarithmicScale}
            />
          </Column>
          <Column className="align-right">
            {scoreDistributions
              .map((data) => data.frequency)
              .reduce((prev, current) => prev + current, 0)}
          </Column>
          <Column className="align-right">
            <PossiblyNoDataWithTooltip value={challenge.averageScore} />
          </Column>
          <Column className="align-right">
            <PossiblyNoDataWithTooltip
              value={
                challenge.medianMinutesTaken
                  ? `${Math.ceil(challenge.medianMinutesTaken)}`
                  : ""
              }
            />
          </Column>
          {postActionMenu}
        </Row>
        <Row className="code-c-collapsible-challenge-column__middle">
          <Column />
          <Column />
          <Column colSpan={colSpan}>
            <div className="code-c-collapsible-challenge-column__middle-inner">
              {challenge.description && (
                <div
                  className={classnames(
                    "code-c-collapsible-challenge-column__description",
                    { disabled: !isChallengeAllowed },
                  )}
                  role="button"
                  onClick={() =>
                    isChallengeAllowed
                      ? this.onClickDetail(challenge.id)
                      : undefined
                  }
                >
                  <HighlightedText keyword={keyword}>
                    {challenge.description}
                  </HighlightedText>
                </div>
              )}
              {languageTagItems.length > 0 && (
                <TagCloud tagItems={languageTagItems} />
              )}
              {linkedChallenge && (
                <LinkedChallenge
                  title={linkedChallenge.title}
                  language={linkedChallenge.language}
                  onClick={
                    isChallengeAllowed
                      ? () => this.onClickDetail(linkedChallenge.id)
                      : undefined
                  }
                />
              )}
            </div>
          </Column>
        </Row>
      </TableBody>
    );
  }

  private onClickCheck = () => {
    if (typeof this.props.onClickCheck === "function") {
      this.props.onClickCheck(this.props.challenge.id);
    }
  };

  private onClickDetail = (challengeId: number) => {
    if (typeof this.props.onClickDetail === "function") {
      this.props.onClickDetail(challengeId);
    }
  };

  private onClickTag = (params: Partial<ChallengeFilterFormModel>) => {
    if (typeof this.props.onClickTag === "function") {
      this.props.onClickTag(params);
    }
  };
}
