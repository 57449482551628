import * as classnames from "classnames";
import * as React from "react";

import { Checkbox } from "..";

interface Props {
  ariaName?: string;
  checkAllLabel?: string;
  checkAllValue?: boolean;
  className?: string;
  labelClassName?: string;
  labelLabelClassName?: string;
  labelOptionsClassName?: string;
  options: string[];
  optionClassName?: string;
  optionName?: string;
  values?: boolean[];
  onChange?: (isChecked: boolean, childCheckboxes: boolean[]) => void;
}

export default function CheckboxGroup({
  ariaName,
  checkAllLabel,
  checkAllValue = false,
  className,
  labelClassName,
  labelLabelClassName,
  labelOptionsClassName,
  options,
  optionClassName,
  optionName = "checkbox-group-option",
  values: valuesProp = [],
  onChange,
}: Props) {
  const isSelfUpdate = React.useRef(false);
  const [isChecked, setIsChecked] = React.useState(checkAllValue);
  const [childCheckboxes, setChildCheckboxes] = React.useState(valuesProp);
  const labelClassNames = classnames("is-flex", labelClassName);
  const optionClassNames = classnames("is-flex", optionClassName);

  React.useEffect(() => {
    // This condition is used to prevent the infinite loop
    if (!isSelfUpdate.current) {
      return;
    }
    if (onChange) {
      onChange(isChecked, childCheckboxes);
      isSelfUpdate.current = false;
    }
  }, [childCheckboxes, isChecked, onChange]);

  React.useEffect(() => {
    // This is to reset the state when the parent changes
    setIsChecked(checkAllValue);
    setChildCheckboxes(valuesProp);
  }, [valuesProp, checkAllValue]);

  const handleAllCheckboxChange = (
    event: React.FormEvent<HTMLInputElement>,
  ) => {
    isSelfUpdate.current = true;
    const { checked } = event.target as HTMLInputElement;
    setIsChecked(checked);
    setChildCheckboxes(Array(childCheckboxes.length).fill(checked));
  };

  const handleCheckboxChange =
    (index: number) => (event: React.FormEvent<HTMLInputElement>) => {
      isSelfUpdate.current = true;
      const { checked } = event.target as HTMLInputElement;
      setChildCheckboxes((prevValues) => {
        const newValues = [...prevValues];
        newValues[index] = checked;
        setIsChecked(newValues.every(Boolean));
        return newValues;
      });
    };

  return (
    <div className={className}>
      {checkAllLabel && (
        <Checkbox
          name={ariaName}
          className={labelClassNames}
          labelClassName={labelLabelClassName}
          value={isChecked}
          onChange={handleAllCheckboxChange}
        >
          {checkAllLabel}
        </Checkbox>
      )}
      <div className="code-c-checkbox-group__options">
        {options.map((label, i) => (
          <Checkbox
            key={`checkbox-group-${label}-${i}`}
            name={optionName}
            className={optionClassNames}
            labelClassName={labelOptionsClassName}
            value={childCheckboxes[i]}
            onChange={handleCheckboxChange(i)}
          >
            {label}
          </Checkbox>
        ))}
      </div>
    </div>
  );
}
