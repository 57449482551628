import { ChallengeTextCaseJSONModel } from "../models";

export interface TestCaseData {
  id: number;
  name: string;
  passed: boolean;
  secret?: boolean;
}

export interface ParsedEvaluationPointData {
  [key: string]: {
    id: string;
    name: string;
    passed: boolean;
    secret?: boolean;
  };
}

export interface EvaluationPointData {
  order: number;
  title: string;
  description: string;
  testCases: TestCaseData[];
}

export interface EvaluationPoints {
  testCaseCount: number;
  evaluationPoints: EvaluationPointData[];
  /**
   * parsed from raw test output or well JSON formatted data
   */
  raw?: boolean;
}

/**
 * get evalution point data object
 * @param testOutput
 * @param testcasesJson
 * @param challengeEvaluationPoints
 */
export function getEvaluationPoints(
  testOutput?: string,
  testcasesJson?: ChallengeTextCaseJSONModel,
  challengeEvaluationPoints?: {
    order: number;
    title: string;
    description: string;
  }[],
): EvaluationPoints {
  if (
    (!testOutput && !testcasesJson) ||
    !challengeEvaluationPoints ||
    challengeEvaluationPoints.length === 0
  ) {
    return {
      testCaseCount: 0,
      evaluationPoints: [],
      raw: true,
    };
  }

  const hasJSON = hasJSONSupported(testcasesJson);

  // Parses test output from challenge into object
  const parsedOutput =
    hasJSON && testcasesJson
      ? parseEvaluationPointFromJson(testcasesJson)
      : testOutput
      ? parseEvaluationPointFromRaw(testOutput)
      : {};

  const evaluationPoints = challengeEvaluationPoints
    .filter((evalPoint) => parsedOutput[evalPoint.title]) // filter out unused language
    .map((evalPoint) => ({
      ...evalPoint,
      testCases: parsedOutput[evalPoint.title],
    }));

  const testCaseCount = evaluationPoints.reduce((total, item) => {
    return total + item.testCases.length;
  }, 0);

  return {
    testCaseCount,
    evaluationPoints,
    raw: !hasJSON,
  };
}

/**
 * Check if the data has already has supported JSON
 * @param testcasesJson
 */
export function hasJSONSupported(
  testcasesJson?: ChallengeTextCaseJSONModel,
): boolean {
  return (
    (testcasesJson?.openTestcases.length || []) > 0 ||
    (testcasesJson?.secretTestcases.length || []) > 0
  );
}

/**
 * parse raw test output to evalution point object
 * @param input
 */
export function parseEvaluationPointFromRaw(
  input: string,
): ParsedEvaluationPointData[] {
  return input.split("\n").reduce((parsedObject, testCase) => {
    const parsedTestCase = testCase.match(
      /(ok|not ok)\s(\d*)\s+\[(.*?)\]\s*(.*)/,
    );

    if (parsedTestCase) {
      // parse indexes
      // 0 => matched string
      // 1 => ok|not ok
      // 2 => test case id
      // 3 => evaluation point name
      // 4 => test case name
      if (!parsedObject[parsedTestCase[3]]) {
        parsedObject[parsedTestCase[3]] = [];
      }
      parsedObject[parsedTestCase[3]].push({
        id: Number(parsedTestCase[2]),
        name: parsedTestCase[4],
        passed: parsedTestCase[1] === "ok",
        secret: false,
      });
    }
    return parsedObject;
  }, Object.create(null));
}

/**
 * parse test output JSON to evalution point object
 * @param testcasesJson
 */
export function parseEvaluationPointFromJson(
  input: ChallengeTextCaseJSONModel,
): ParsedEvaluationPointData[] {
  return [
    ...input.openTestcases.map((item) => ({ ...item, secret: false })),
    ...input.secretTestcases.map((item) => ({ ...item, secret: true })),
  ]
    .sort((a, b) => (a.index > b.index ? 1 : -1))
    .reduce((parsedObject, item) => {
      if (!parsedObject[item.evaluationPoint]) {
        parsedObject[item.evaluationPoint] = [];
      }
      parsedObject[item.evaluationPoint].push({
        id: item.index,
        name: item.title,
        passed: item.ok,
        secret: item.secret,
      });
      return parsedObject;
    }, Object.create(null));
}
