import { useQuery } from "@tanstack/react-query";
import { isNil } from "lodash";

import { getReportExamChallengeAnalysis } from "@api/reports";

import { useStoreContext } from "@context";

import { ReportChallengeModel } from "@shared/models";

interface SubmissionAnalysisAllKeyParams {
  projectId: number;
  submissionId?: number;
}

export const reportAnalysisKeys = {
  all: ["report", "submission", "analysis"] as const,
  analysisAll: ({
    projectId,
    submissionId,
  }: SubmissionAnalysisAllKeyParams) => {
    if (isNil(submissionId)) {
      return [...reportAnalysisKeys.all, projectId] as const;
    }
    return [...reportAnalysisKeys.all, projectId, submissionId];
  },
};

interface ExamChallengeAnalysisParams {
  submissionId: number;
  enabled?: boolean;
}

export function useGetExamChallengeAnalysis({
  submissionId,
  enabled = true,
}: ExamChallengeAnalysisParams) {
  const { projectId } = useStoreContext();

  const query = useQuery<ReportChallengeModel[]>({
    queryKey: reportAnalysisKeys.analysisAll({
      projectId,
      submissionId,
    }),
    enabled,
    queryFn: async ({ signal }) => {
      const { result } = await getReportExamChallengeAnalysis({
        projectId,
        submissionId,
        options: {
          signal,
        },
      });

      return result;
    },
    keepPreviousData: true,
  });

  return query;
}
