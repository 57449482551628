import { RefObject } from "react";
import { Link } from "react-router-dom";

import { Msg } from "@shared/components";

interface SeeActionLogLinkTextProps {
  actionsTabRef?: RefObject<HTMLDivElement>;
}

export const SeeActionLogLinkText = ({
  actionsTabRef,
}: SeeActionLogLinkTextProps) => {
  return (
    <>
      <Msg id="submission.submissionDetail.cheatMonitor.forDetails1" />
      <Link
        to="log"
        onClick={(e) => {
          // Changes the url to load the action log tab, and scroll down os it is on-screen
          e.stopPropagation();
          actionsTabRef?.current?.scrollIntoView?.({
            behavior: "smooth",
            block: "start",
          });
        }}
      >
        <Msg id="submission.actionLog" tagName="none" />
      </Link>
      <Msg id="submission.submissionDetail.cheatMonitor.forDetails2" />
    </>
  );
};
