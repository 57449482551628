import { ScoreDistribution } from "@shared/components";
import { ChallengeInsightModel } from "@shared/models";
import Message from "@shared/services/message";

/**
 * Prop interface
 */
export interface ChallengeScoreDistributionProps {
  challengeInsight?: ChallengeInsightModel;
  logarithmicScale?: boolean;
  chartWidth?: number;
}

/**
 * React Component
 */
export function ChallengeScoreDistribution({
  challengeInsight,
  logarithmicScale,
  chartWidth = 650,
}: ChallengeScoreDistributionProps) {
  if (!challengeInsight) {
    return null;
  }

  const {
    averageScore,
    medianScore,
    deviation,
    scoreDistributions = [],
  } = challengeInsight;

  const scoreDistribution = [...scoreDistributions]
    .sort((a, b) => (a.leftClosedBoundary > b.leftClosedBoundary ? 1 : -1))
    .map((data) => {
      return {
        label: String(
          data.leftClosedBoundary === data.rightClosedBoundary
            ? data.leftClosedBoundary
            : data.leftClosedBoundary + "-" + data.rightClosedBoundary,
        ),
        number: data.frequency,
      };
    });

  return (
    <ScoreDistribution
      averageScore={averageScore}
      chartWidth={chartWidth}
      deviation={deviation}
      logarithmicScale={logarithmicScale}
      medianScore={medianScore}
      scoreDistribution={scoreDistribution}
      xLabel={`${Message.getMessageByKey("score")}(%)`}
      yLabel={Message.getMessageByKey("challenge.numberOfSubmission")}
    />
  );
}
