import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { putSubmissionArchive, putSubmissionUnarchive } from "@api/submissions";

import { useStoreContext } from "@context";

import { SubmissionListKind } from "@shared/services/enums";
import history from "@shared/services/history";
import Message from "@shared/services/message";

import {
  useInvalidateExamCountRelation,
  useInvalidateExamDashboardChart,
} from "../exams";
import { useRouteParams } from "../useRouteParams";
import { useInvalidateSubmissionRelation } from "./useSubmission";

export function useUpdateSubmissionArchive(transition?: boolean) {
  const { projectId } = useStoreContext();
  const { examId } = useRouteParams();
  const invalidateSubmission = useInvalidateSubmissionRelation();
  const invalidateExamCount = useInvalidateExamCountRelation();
  const invalidateExamDashboard = useInvalidateExamDashboardChart();

  const mutation = useMutation({
    mutationFn: (submissionId: number) =>
      putSubmissionArchive(projectId, submissionId),
    onSuccess: (res, submissionId) => {
      const { result } = res;
      toast.success(Message.getMessageByKey("message.submission.deactivated"));

      if (transition) {
        history.replace(
          `/p/${projectId}/exams/${examId}/submissions_${
            SubmissionListKind[result.status]
          }`,
        );
      }
      invalidateExamCount(examId);
      invalidateSubmission(submissionId);
      invalidateExamDashboard(submissionId);
    },
  });

  return mutation;
}

export function useUpdateSubmissionUnarchive() {
  const { projectId } = useStoreContext();
  const { examId } = useRouteParams();
  const invalidateSubmission = useInvalidateSubmissionRelation();
  const invalidateExamCount = useInvalidateExamCountRelation();
  const invalidateExamDashboard = useInvalidateExamDashboardChart();

  const mutation = useMutation({
    mutationFn: (submissionId: number) =>
      putSubmissionUnarchive(projectId, submissionId),
    onSuccess: (_, submissionId) => {
      toast.success(Message.getMessageByKey("message.submission.activated"));
      invalidateSubmission(submissionId);
      invalidateExamCount(examId);
      invalidateExamDashboard(submissionId);
    },
  });

  return mutation;
}
