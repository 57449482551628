import * as React from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";

import Message from "../../services/message";

/**
 * Prop interface
 */
export interface RestrictedRouteProps {
  allow?: boolean;
  children: React.ReactNode;
}

/**
 * React Component
 */
export default function RestrictedRoute({
  allow,
  children,
  ...rest
}: RestrictedRouteProps & RouteProps) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        allow ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/error",
              state: {
                from: location,
                error: Message.getMessageByKey("error.accessDenied.title"),
                description: Message.getMessageByKey(
                  "error.accessDenied.description",
                ),
              },
            }}
          />
        )
      }
    />
  );
}
