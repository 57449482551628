import * as classnames from "classnames";
import * as React from "react";

import { useStoreContext } from "@context";

import {
  Loading,
  Modal,
  FormGroup,
  Form,
  Label,
  Input,
  Checkbox,
  Button,
  Icon,
  Msg,
  LanguageSelect,
} from "@shared/components";
import {
  MemberListModel,
  PendingInviteModel,
  ProjectRoleModel,
  UserRoleModel,
  ProjectSwitchItemModel,
} from "@shared/models";
import {
  MemberEditType,
  TierAction,
  UserRole,
  SpokenLanguages,
} from "@shared/services/enums";
import {
  filterValidProjectRoles,
  getProjectRoleArray,
} from "@shared/services/member";
import { isRoleAllowed } from "@shared/services/tier";

import { ProjectRoleForm } from "../partials";

/**
 * Prop interface
 */
export interface ExternalProps {
  type?: MemberEditType;
  isOpen?: boolean;
  member?: MemberListModel | PendingInviteModel;
  loading?: boolean;
  projects: ProjectSwitchItemModel[];
  onClickOk?: (formValues: {
    email: string;
    roles: (UserRoleModel | { role: number })[];
    invitationLanguage?: SpokenLanguages;
  }) => void;
  onCancel?: () => void;
}

export type InjectedProps = {
  isTierActionAllowed: (tierAction: TierAction) => boolean;
};

export type MemberEditProps = ExternalProps & InjectedProps;

type MemberEditForm = {
  email?: string;
  isOrgAdmin?: boolean;
  projects?: ProjectRoleModel[] | number[];
  invitationLanguage?: SpokenLanguages;
};

export const MemberEdit: React.FunctionComponent<MemberEditProps> = ({
  type,
  isOpen,
  member,
  loading,
  projects,
  onClickOk,
  onCancel,
  isTierActionAllowed,
}: MemberEditProps) => {
  /**
   * State
   */
  const [form, setForm] = React.useState<{
    formValues: MemberEditForm;
    formErrors: {};
    formValid: boolean;
  }>({ formValues: {}, formErrors: {}, formValid: false });

  const [tierValid, setTierValid] = React.useState<boolean>(
    getProjectRoleArray(member?.roles || []).every((role) =>
      role.roles.every((role) => isRoleAllowed({ role, isTierActionAllowed })),
    ),
  );

  const [projectRoles, setProjectRoles] = React.useState<ProjectRoleModel[]>(
    [],
  );

  const [notChangeableProjects, setNotChangeableProjects] = React.useState<
    number[]
  >([]);

  const { user } = useStoreContext();

  const language = user?.language ?? SpokenLanguages.English;

  const rootStyle = classnames("code-member-edit");

  const isEdit = type === MemberEditType.Edit;
  const isInvite = type === MemberEditType.New;

  const remainingProjects = projects.filter(
    (project) =>
      !projectRoles.find((projectRole) => projectRole.id === project.id),
  );

  React.useEffect(() => {
    setTierValid(
      projectRoles.every(({ roles }) =>
        roles.every((role) => isRoleAllowed({ role, isTierActionAllowed })),
      ),
    );
  }, [isTierActionAllowed, projectRoles, projects]);

  /**
   * Effects
   */
  React.useEffect(() => {
    if (!member) {
      return;
    }
    const projectRolesArray = getProjectRoleArray(member.roles).filter(
      (project) => projects.find((p) => p.id === project.id)?.name,
    );

    setForm({
      formValues: {},
      formErrors: {},
      formValid: false,
    });
    setProjectRoles(projectRolesArray);
    setNotChangeableProjects(projectRolesArray.map((role) => role.id));
  }, [member, projects]);

  React.useEffect(() => {
    if (!isOpen) {
      setForm({
        formValues: {},
        formErrors: {},
        formValid: false,
      });
      setProjectRoles([]);
      setNotChangeableProjects([]);
    }
  }, [isOpen]);

  /**
   * Private Functions
   */
  const onAddProjectRoles = (id: number) => {
    setProjectRoles([...projectRoles, { id, roles: [] }]);
  };

  const onChangeProject = (oldId: number, newId: number) => {
    setProjectRoles(
      projectRoles.map((pRoles) =>
        pRoles.id === oldId ? { id: newId, roles: [] } : pRoles,
      ),
    );
  };

  const onChangeProjectRoles = (projectId: number, roles: number[]) => {
    setProjectRoles(
      projectRoles.map((item) => ({
        ...item,
        ...(item.id === projectId && {
          roles,
        }),
      })),
    );
  };

  const onDeleteProject = (id: number) => {
    setProjectRoles(
      projectRoles.filter((projectRole) => projectRole.id !== id),
    );
    setNotChangeableProjects(
      notChangeableProjects.filter((projectId) => projectId !== id),
    );
  };

  const onFormChange = (
    formValid: boolean,
    formValues: MemberEditForm,
    formErrors: {},
  ) => {
    const { isOrgAdmin } = formValues;

    const projectRoleCount = projectRoles.reduce(
      (total, project) => total + project.roles.length,
      0,
    );

    setForm({
      formValues,
      formErrors,
      formValid: formValid && (projectRoleCount > 0 || Boolean(isOrgAdmin)),
    });
  };

  const handleClickOk = () => {
    if (typeof onClickOk !== "function") {
      return;
    }
    const { email, isOrgAdmin, invitationLanguage } = form.formValues;

    onClickOk({
      email: email || "",
      roles: filterValidProjectRoles([
        ...projectRoles.flatMap((project) =>
          project.roles.map((role) => ({ projectId: project.id, role })),
        ),
        ...(isOrgAdmin ? [{ role: UserRole.OrgAdmin }] : []),
      ]),
      invitationLanguage,
    });
  };

  const isExpired = member && "isExpired" in member && member?.isExpired;
  return (
    <Modal
      className={rootStyle}
      title={
        <Msg
          id={
            isEdit
              ? isExpired
                ? "member.invite.detail"
                : "member.updateRole"
              : "member.invite"
          }
        />
      }
      okButtonLabel={<Msg id={isEdit ? "action.update" : "action.invite"} />}
      cancelButtonLabel={isExpired ? <Msg id="close" /> : undefined}
      ariaLabel={isEdit ? "Update Member Role" : "Invite New Member"}
      isOpen={isOpen}
      disableOk={!form.formValid || !tierValid}
      onClose={onCancel}
      onClickCancel={onCancel}
      onClickOk={handleClickOk}
      hasOkButton={!isExpired}
      additionalFooterContent={
        !isEdit && (
          <>
            <Icon type="exclamation-triangle" />
            <Msg id="member.invite.message.expiration" />
          </>
        )
      }
    >
      <Loading isOpen={loading} />
      <Form
        validation={{
          email: ["string", "required", "strictEmail"],
          OrgAdmin: ["boolean"],
          ...projectRoles.reduce((schema, item) => {
            return { ...schema, [`project${item.id}`]: ["array", ["min", 1]] };
          }, {}),
        }}
        initialValues={{
          email: member?.email ?? "",
          isOrgAdmin: member?.getRoles()?.includes(UserRole.OrgAdmin),
          invitationLanguage: isInvite ? language : undefined,
        }}
        onFormChange={onFormChange}
        updateValues={{
          ...projectRoles.reduce((values, item) => {
            return { ...values, [`project${item.id}`]: item.roles };
          }, {}),
        }}
        showError={{
          ...projectRoles.reduce((values, item) => {
            return { ...values, [`project${item.id}`]: true };
          }, {}),
        }}
        clear={!isOpen}
      >
        <FormGroup>
          <Label title={<Msg id="common.email" />} />
          {isEdit ? (
            <p className="is-break-word">{member?.email ?? ""}</p>
          ) : (
            <Input name="email" />
          )}
        </FormGroup>
        {member && "name" in member && (
          <FormGroup>
            <Label title={<Msg id="common.name" />} />
            <p className="is-break-word">{member.name}</p>
          </FormGroup>
        )}
        {isInvite && <LanguageSelect name="invitationLanguage" />}
        <FormGroup>
          <Label title={<Msg id="common.role" />} />
          <Checkbox name="isOrgAdmin" readOnly={isExpired}>
            {UserRole.toString(UserRole.OrgAdmin)}
          </Checkbox>
        </FormGroup>
      </Form>
      {projectRoles.map((projectRole) => {
        const projectName = projects.find((p) => p.id === projectRole.id)?.name;
        if (!projectName) {
          return null;
        }
        return (
          <ProjectRoleForm
            key={projectRole.id}
            name={`project${projectRole.id}`}
            errors={form.formErrors}
            initialValues={projectRole}
            remainingProjects={remainingProjects}
            projectName={projectName}
            isProjectChangeable={notChangeableProjects.includes(projectRole.id)}
            onChangeProject={(id) => onChangeProject(projectRole.id, id)}
            onDeleteProject={() => onDeleteProject(projectRole.id)}
            onChangeProjectRoles={(formValues) =>
              onChangeProjectRoles(projectRole.id, formValues)
            }
            isExpired={isExpired}
          />
        );
      })}
      {remainingProjects.length > 0 && !isExpired && (
        <div>
          <Button
            type="primary"
            onClick={() => onAddProjectRoles(remainingProjects[0].id)}
            ariaLabel="Add Project Role"
          >
            <Icon type="plus" />
            <Msg id="button.addProjectRole" />
          </Button>
        </div>
      )}
    </Modal>
  );
};
