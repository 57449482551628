import { zodResolver } from "@hookform/resolvers/zod";
import React, { useEffect, forwardRef, useState } from "react";
import {
  useForm,
  FormProvider,
  Controller,
  SubmitHandler,
} from "react-hook-form";
import { z } from "zod";

import { useStoreContext } from "@context";

import { FormGroup, Label, Input, PageTitle, Msg } from "@shared/components";
import { AutoComplete } from "@shared/components/autoComplete/AutoComplete";
import { CheckboxMultiOption } from "@shared/components/autoComplete/Options/CheckboxMultiOption.tsx/CheckboxMultiOption";
import { DefaultOptionProps as AutocompleteOptionProps } from "@shared/components/autoComplete/Options/DefaultOption";
import { RichMarkdown } from "@shared/components/rhfComponents/RichMarkdown";
import { Textarea } from "@shared/components/rhfComponents/Textarea";
import { useGetExamTalentHubCategories } from "@shared/hooks/query/exams/useExamTalentHub";
import {
  TalenthubLevels,
  TalenthubCategoryKinds,
} from "@shared/services/enums";
import MSG from "@shared/services/message";

import { useImperativeSubmit } from "../../partials/talentHubUtils";

/**
 * Types
 */
export type ExamOutlineForm = {
  name: string;
  description: string;
  talentHubDescription: string;
  level: {
    value: string;
    label: string;
  };
  categories: AutocompleteOptionProps[];
};

export type ExamOutlineProps = {
  stepIndex: number;
  readOnly?: boolean;
  onFormChange?: (isValid: boolean) => void;
  initialValues?: ExamOutlineForm;
  hideTitle?: boolean;
  onMoveStep?: (
    isValid: boolean,
    stepNum: number,
    values: ExamOutlineForm,
  ) => void;
  ref?: React.RefObject<{ submit: () => void }>;
};

interface Level {
  value: string;
  label: string;
}

interface Category {
  id: string;
  categoryGroup: string;
  jaTitle: string;
  enTitle: string;
  supportedLevels: number[];
  supportedStyles: number[];
  createdAt: string;
  updatedAt: string;
  jaDescription?: string;
  enDescription?: string;
}

const ExamOutline = forwardRef<{ submit: () => void }, ExamOutlineProps>(
  (props, ref) => {
    const {
      readOnly = false,
      initialValues,
      hideTitle,
      onFormChange,
      onMoveStep,
    } = props;

    const [initialLevel, setInitialLevel] = useState(
      initialValues?.level || null,
    );

    const { user } = useStoreContext();
    const locale = user?.language;
    const { data: allCategories } = useGetExamTalentHubCategories();

    const schema = z
      .object({
        name: z.string().min(1, MSG.getMessageByKey("form.required")).max(255),
        description: z.string().min(1, MSG.getMessageByKey("form.required")),
        talentHubDescription: z
          .string()
          .min(1, MSG.getMessageByKey("form.required")),
        level: z.object({
          value: z
            .string()
            .min(1, { message: MSG.getMessageByKey("form.required") }),
          label: z.string(),
        }),
        categories: z
          .array(z.object({ value: z.string(), label: z.string() }))
          .optional(),
      })
      .refine(
        (data) => {
          if (
            data.level &&
            (!data.categories || data.categories.length === 0)
          ) {
            return false;
          }
          return true;
        },
        {
          message: MSG.getMessageByKey("form.required"),
          path: ["categories"], // This will point the error to the categories field
        },
      );

    const methods = useForm<ExamOutlineForm>({
      resolver: zodResolver(schema),
      defaultValues: initialValues,
      mode: "all", // both onBlur and onChange validation
    });

    const {
      register,
      watch,
      formState: { isValid, isDirty, errors },
      control,
      getValues,
      setValue,
      trigger,
      handleSubmit,
    } = methods;

    const watchedValues = watch();
    const watchedLevel = watch("level") as { label: string; value: string };

    // Need to prevent level changes triggering a reset of categories when returning to the step
    const [levelHasChanged, setLevelHasChanged] = useState(false);
    useEffect(() => {
      if (
        watchedLevel?.value &&
        watchedLevel?.value !== initialLevel?.value &&
        !levelHasChanged &&
        isDirty
      ) {
        setLevelHasChanged(true);
      }
    }, [watchedLevel, initialLevel?.value, levelHasChanged, isDirty]);

    useEffect(() => {
      // Only reset categories when the user explicitly changes the level
      // and the new level is different from what was initially provided
      if (levelHasChanged) {
        setValue("categories", []);
        trigger("categories");
        setInitialLevel(watchedLevel);
        setLevelHasChanged(false);
      }
    }, [watchedLevel, setValue, trigger, levelHasChanged]);

    const formLevels = TalenthubLevels.values.map((level, idx) => ({
      label: MSG.getMessageByKey(TalenthubLevels.getTalenthubLevelLabel(level)),
      value: level,
    }));

    // The 'skill' category is returned in the same array as the other categories, but we want it at the end.
    // So we'll filter it out and add it back in at the end.
    const skillCategories = ((allCategories as Category[]) || []).map(
      (category: Category) => {
        if (category.id === TalenthubCategoryKinds.Skill) {
          return {
            label: locale === "ja" ? category.jaTitle : category.enTitle,
            value: category.id,
            supportedLevels: category.supportedLevels,
            group: TalenthubCategoryKinds.Skill,
          };
        }
        return null;
      },
    );

    const formCategories = ((allCategories as Category[]) || [])
      .map((category: Category) => {
        if (category.id !== TalenthubCategoryKinds.Skill) {
          return {
            label: locale === "ja" ? category.jaTitle : category.enTitle,
            value: category.id,
            supportedLevels: category.supportedLevels,
            group: "",
          };
        }
        return null;
      })
      .concat(
        // Add the skill category back in at the end
        ...skillCategories,
      )
      .filter((category) => {
        const level: Level | undefined = getValues("level") as unknown as Level;
        return (
          level && category?.supportedLevels.includes(parseInt(level.value))
        );
      });

    useEffect(() => {
      if (isDirty && onFormChange) {
        onFormChange(isValid);
      }
    }, [isDirty, isValid, onFormChange]);

    const handleFormSubmit: SubmitHandler<ExamOutlineForm> = (data) => {
      if (onMoveStep) {
        // run the submit function passed in from parent component
        onMoveStep(isValid, props.stepIndex, data);
      }
    };

    // Handles step submission to the parent when the step changes
    useImperativeSubmit(
      ref as React.RefObject<{ submit: () => Promise<void> }>,
      handleSubmit,
      handleFormSubmit,
      watchedValues,
    );

    const rootStyle = "code-exam-edit__outline";

    /**
     * Render
     */
    return (
      <FormProvider {...methods}>
        <div className={rootStyle}>
          {!hideTitle && (
            <PageTitle className="code-exam-edit__outline__title">
              <Msg id="outline" />
            </PageTitle>
          )}
          <FormGroup className="code-exam-edit__outline__group-container">
            <div className="code-exam-edit__outline__info">
              <div className="code-exam-edit__outline__subtitle">
                <Msg id="exam.information" />
              </div>
              <div>
                <Label className="text-sm">
                  <Msg id="form.exam.name" />
                </Label>
                <Input
                  disabled={readOnly}
                  {...register("name")}
                  errorMessage={errors.name ? errors.name.message : undefined}
                />
              </div>
              <div>
                <div className="code-exam-edit__outline__label-container">
                  <Label className="code-exam-edit__outline__label">
                    <Msg id="form.description" />
                  </Label>
                  <div className="code-exam-edit__outline__sub-label">
                    <Msg id="exam.descriptionSubLabelLong" />
                  </div>
                </div>
                <div>
                  <RichMarkdown
                    value={
                      readOnly
                        ? (initialValues as { description: string }).description
                        : watchedValues.description
                    }
                    {...register("description")}
                    preview={readOnly}
                    error={
                      errors.description
                        ? {
                            description: errors.description.message || "",
                          }
                        : undefined
                    }
                  />
                </div>
              </div>
              <div>
                <div className="code-exam-edit__outline__label-container">
                  <Label className="code-exam-edit__outline__label">
                    <Msg id="exam.talentHub.description" />
                  </Label>
                  <div className="code-exam-edit__outline__sub-label">
                    <Msg id="exam.talentHub.description.subLabel" />
                  </div>
                </div>
                <div>
                  <Textarea
                    {...register("talentHubDescription")}
                    disabled={readOnly}
                    error={
                      errors.talentHubDescription
                        ? {
                            talentHubDescription:
                              errors.talentHubDescription.message || "",
                          }
                        : undefined
                    }
                  />
                </div>
              </div>
            </div>
          </FormGroup>

          <FormGroup className="code-exam-edit__outline__group-container">
            <div className="code-exam-edit__outline__types">
              <div className="code-exam-edit__outline__subtitle">
                <Msg id="exam.talentHub.types" />
              </div>
              <div>
                <Label className="text-sm">
                  <Msg id="common.level" />
                </Label>
                <Controller
                  control={control}
                  name="level"
                  render={({
                    field: { name, onChange, onBlur, value, ref },
                  }) => (
                    <AutoComplete
                      placeholder={MSG.getMessageByKey(
                        "custom-form-select.placeholder",
                      )}
                      options={formLevels}
                      onChange={onChange}
                      onBlur={(e) => {
                        onBlur();
                      }}
                      value={value}
                      noMatchesMessage={MSG.getMessageByKey(
                        "exam.talentHub.notFoundOptions",
                      )}
                      popupClassName="code-exam-edit__outline-autocomplete-popup"
                      error={
                        errors.level
                          ? {
                              level: errors.level.message || "",
                            }
                          : undefined
                      }
                      name={name}
                    />
                  )}
                />
              </div>
              <div>
                <Label className="text-sm">
                  <Msg id="common.categories" />
                </Label>
                <Controller
                  control={control}
                  name="categories"
                  render={({
                    field: { name, onChange, onBlur, value, ref },
                  }) => (
                    <AutoComplete
                      isMulti
                      placeholder={MSG.getMessageByKey(
                        "custom-form-select.placeholder",
                      )}
                      options={formCategories as AutocompleteOptionProps[]}
                      optionComponent={CheckboxMultiOption}
                      onChange={onChange}
                      value={value}
                      noMatchesMessage={MSG.getMessageByKey(
                        "exam.talentHub.notFoundOptions",
                      )}
                      popupClassName="code-exam-edit__outline-autocomplete-popup"
                      error={
                        errors.categories
                          ? {
                              categories: errors.categories.message || "",
                            }
                          : undefined
                      }
                      name={name}
                    />
                  )}
                />
              </div>
            </div>
          </FormGroup>
        </div>
      </FormProvider>
    );
  },
);

export default ExamOutline;
