import QuestionModel from "./Question.model";

class QuestionFreeTextModel extends QuestionModel {
  constructor(args: {}) {
    super(args);
  }

  /**
   * Return true if the answer is correct
   */
  public isCorrect(): boolean {
    return Boolean(this.userAnswer.answer);
  }

  /**
   * Retuen user's answer
   */
  public getUserAnswer(): string {
    return this.userAnswer.answer || "";
  }

  /**
   * Return true if the user has already answered
   */
  public hasUserAnswer(): boolean {
    return Boolean(this.userAnswer.answer);
  }

  /**
   * Return the Global Answer Rate
   */
  private getAnswerRate(statsLevel: "exam" | "global"): number | null {
    const insight =
      statsLevel === "exam" ? this.examLevelInsight : this.globalInsight;
    if (!insight) {
      return null;
    }

    const answerRateInStr =
      insight.overall.scoreDistribution.find(({ score }) => score === 1)
        ?.fraction || null;

    if (answerRateInStr === null) {
      return null;
    }

    return Number(answerRateInStr);
  }

  /**
   * Return the global answer rate as a formatted string for this question
   * Empty string will be returned if the global insights is not available
   */
  public getGlobalAnswerRateAsFormattedString(): string {
    const answerRate = this.getAnswerRate("global");

    if (answerRate === null) {
      return "";
    }

    const rounded = Number((answerRate * 100).toFixed(2));

    return `${rounded}%`;
  }

  public getExamLevelAnswerRateAsFormattedString(): string {
    const answerRate = this.getAnswerRate("exam");

    if (answerRate === null) {
      return "";
    }

    const rounded = Number((answerRate * 100).toFixed(2));

    return `${rounded}%`;
  }
}

export default QuestionFreeTextModel;
