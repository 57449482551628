import {
  Column,
  HeaderColumn,
  Msg,
  Row,
  Table,
  TableBody,
  TableHead,
} from "@shared/components";
import { ReportChallengeModel } from "@shared/models";

interface ExamDashboardChallengeStats {
  analysis: ReportChallengeModel;
}

export const ExamDashboardChallengeStats = ({
  analysis,
}: ExamDashboardChallengeStats) => {
  const { examScopeScores, systemScopeScores } = analysis;

  return (
    <Table
      narrow
      insideBordered
      theme="gray-light"
      className="code-dashboard-challenge-stats"
    >
      <TableHead>
        <HeaderColumn absoluteSize={2} />
        <HeaderColumn className="align-right code-dashboard-challenge-stats__column-width">
          <Msg id="report.average" />
        </HeaderColumn>
        <HeaderColumn size={1} className="align-right">
          <Msg id="report.std" />
        </HeaderColumn>
        <HeaderColumn className="align-right code-dashboard-challenge-stats__column-width">
          <Msg id="challenge.medianScore" />
        </HeaderColumn>
      </TableHead>
      <TableBody>
        <Row>
          <Column className="code-dashboard-challenge-stats__shaded">
            <Msg id="exam.dashboard.challengeAnalysis.thisExam" />
          </Column>
          {[
            examScopeScores.average,
            examScopeScores.standardDeviation,
            examScopeScores.medianScore,
          ].map((score, idx) => (
            <Column className="align-right" key={idx}>
              {score === undefined ? "-" : `${score}%`}
            </Column>
          ))}
        </Row>
        <Row>
          <Column className="code-dashboard-challenge-stats__shaded">
            <Msg id="common.global" />
          </Column>
          {[
            systemScopeScores.average,
            systemScopeScores.standardDeviation,
            systemScopeScores.medianScore,
          ].map((score, idx) => (
            <Column className="align-right" key={idx}>
              {score === undefined ? "-" : `${score}%`}
            </Column>
          ))}
        </Row>
      </TableBody>
    </Table>
  );
};
