import { connect } from "react-redux";

import { RootState } from "../../../../../../reducers";
import OfficialExamDeliver from "./OfficialExamDeliver";

interface OwnProps {
  isOpen: boolean;
  examId?: number;
  onClose: () => void;
}

const mapStateToProps = (state: RootState, props: OwnProps) => ({
  currentUser: state.user.user,
  ...props,
});

export default connect(mapStateToProps, {})(OfficialExamDeliver);
