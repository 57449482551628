import { Icon, Modal, Msg } from "@shared/components";
import { ChallengeModel, QuestionModel } from "@shared/models";
import Message from "@shared/services/message";

interface ChallengeDeleteModalProps {
  isCopyOpen: boolean;
  selectedChallenge: ChallengeModel;
  removedQuestionList: QuestionModel[] | undefined;
  onCloseModal: () => void;
  onConfirmCopy: () => void;
}

export const ChallengeCopyModal = ({
  isCopyOpen,
  selectedChallenge,
  removedQuestionList,
  onCloseModal,
  onConfirmCopy,
}: ChallengeDeleteModalProps) => {
  return (
    <Modal
      className="code-challenge-copy-modal"
      title={Message.getMessageByKey("confirmCopy.challenge")}
      isOpen={isCopyOpen}
      onClose={onCloseModal}
      onClickOk={onConfirmCopy}
      onClickCancel={onCloseModal}
      okButtonLabel={Message.getMessageByKey("copy")}
      okButtonAriaLabel="Copy"
      ariaLabel="Copy Challenge"
      disableOk={Boolean(removedQuestionList?.length)}
    >
      <>
        <div className="code-challenge-copy-modal__challenge-title is-wrap">
          {selectedChallenge?.title ?? ""}
        </div>
        {removedQuestionList?.length ? (
          <>
            <div className="code-challenge-copy-modal__removed-question-message">
              <Icon type="exclamation-triangle" />
              <div>
                <Msg id="confirmCopy.message.challenge.removedQuestion" />
                <ul className="code-challenge-copy-modal__removed-question-title">
                  {removedQuestionList.map((question) => (
                    <li key={question.id}>{question.title}</li>
                  ))}
                </ul>
              </div>
            </div>
          </>
        ) : (
          <Msg id="confirmCopy.message.challenge" />
        )}
      </>
    </Modal>
  );
};
