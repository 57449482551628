import classNames from "classnames";
import * as React from "react";

import { SubmissionAutoEvaluated } from "@components/orgs/submissions/submissionAutoEvaluated/SubmissionAutoEvaluated";
import { SubmissionEvaluationDropdown } from "@components/orgs/submissions/submissionEvaluationDropdown/SubmissionEvaluationDropdown";

import {
  Row,
  Column,
  SubmissionTitleColumn,
  QuickHelp,
  ScoreStatusBadge,
  Restricted,
  DateTime,
  Tag,
  Tooltip,
} from "@shared/components";
import { SubmissionListModel, UserModel } from "@shared/models";
import {
  formatDateTimeMinutes,
  formatTimeLeftString,
} from "@shared/services/date";
import {
  ApplicantExamStatus,
  MailStatus,
  UserRole,
  ProjectRole,
  SubmissionListKind,
  SubmissionType,
} from "@shared/services/enums";
import {
  getConditionalColumnsForSubmissionList,
  isAllOrArchivedList,
  isAutoEvaluated,
  isEvaluatedStatus,
  isSubmittedStatus,
} from "@shared/services/exam";
import Message from "@shared/services/message";
import {
  extractScoreStatusFromSubmissionList,
  extractScoreStringFromSubmissionList,
  showNeedsReviewTag,
} from "@shared/services/score";

import { ReviewStatusBar } from "..";

/**
 * Interface
 */
interface SubmissionListTableRowProps {
  submission: SubmissionListModel;
  /**
   * if it's not undefined, the current user has assigned as a reviewer
   */
  reviewer?: UserModel;
  noReviewer?: boolean;
  shareReviewsWithReviewers?: boolean;
  submissionListKind: SubmissionListKind;
  actionMenu?: React.ReactNode;
  canReview?: boolean;
  canEvaluate?: boolean;
  onClickReviewDetail?: () => void;
  onHoverReviewStatusBar?: (e: React.FormEvent<HTMLDivElement>) => void;
  onUnhoverReviewStatusBar?: () => void;
  className?: string;
}

/**
 * Component
 * @param props SubmissionListTableRowProps
 */
export function SubmissionListTableRow({
  submissionListKind,
  reviewer,
  noReviewer,
  shareReviewsWithReviewers,
  submission,
  actionMenu,
  canReview,
  canEvaluate: canEvaluateProps,
  onClickReviewDetail,
  onHoverReviewStatusBar,
  onUnhoverReviewStatusBar,
  className,
}: SubmissionListTableRowProps) {
  const rootStyle = classNames("code-submission-list-table-row", {
    [`${className}`]: Boolean(className),
  });

  const mailError = [
    MailStatus.Bounced,
    MailStatus.Dropped,
    MailStatus.Deferred,
    MailStatus.Spam,
  ].includes(submission.mailStatus);

  const isSubmitted = isSubmittedStatus(submission.status);
  const isEvaluated = isEvaluatedStatus(submission.status);

  const isArchivedList = submissionListKind === SubmissionListKind.archived;
  const canEvaluate = canEvaluateProps && !isArchivedList;

  const {
    shouldShowSubmittedAtColumn,
    shouldShowTimeSpentColumn,
    shouldShowOverallScoreColumn,
    shouldShowResultColumn,
  } = getConditionalColumnsForSubmissionList(submissionListKind);

  return (
    <Row className={rootStyle}>
      <Column>
        <SubmissionTitleColumn
          submission={submission}
          isNeedReview={showNeedsReviewTag({
            submissionListKind,
            pendingReviewers: submission.pendingReviewers,
            reviewer,
            reviews: submission.reviews,
            submissionStatus: submission.status,
            hasReviewPermission: canReview,
          })}
        />
      </Column>
      {isAllOrArchivedList(submissionListKind) && (
        <Column>
          {isEvaluated
            ? Message.getMessageByKey("submission.evaluated")
            : ApplicantExamStatus.toString(submission.status, canReview)}
          {submission.status === ApplicantExamStatus.Canceled && (
            <QuickHelp
              text={
                Message.getMessageByKey("canceledAt") +
                "\n" +
                formatDateTimeMinutes(submission.archivedAt)
              }
            />
          )}
        </Column>
      )}
      {shouldShowResultColumn && (
        <Column noPaddingX>
          {isSubmitted ? (
            <>
              <SubmissionEvaluationDropdown
                submissionId={submission.id}
                submissionStatus={submission.status}
                disabled={!canEvaluate}
              />
              {isAutoEvaluated(
                submission.status,
                submission.evaluationMeta,
              ) && <SubmissionAutoEvaluated />}
            </>
          ) : (
            "-"
          )}
        </Column>
      )}
      <Restricted
        roles={[
          UserRole.SystemAdmin,
          ProjectRole.ProjectAdmin,
          ...(Boolean(reviewer) && shareReviewsWithReviewers
            ? [ProjectRole.Reviewer]
            : []),
        ]}
        strictAllow={
          [
            SubmissionListKind.submitted,
            SubmissionListKind.inreview,
            SubmissionListKind.reviewed,
            SubmissionListKind.evaluated,
            SubmissionListKind.passed,
            SubmissionListKind.failed,
          ].includes(submissionListKind) && !noReviewer
        }
        wrapperTagName={false}
      >
        <Column>
          <ReviewStatusBar
            reviews={submission.reviews}
            pendingReviewers={submission.pendingReviewers}
            onClick={onClickReviewDetail}
            onHover={onHoverReviewStatusBar}
            onUnhover={onUnhoverReviewStatusBar}
          />
        </Column>
      </Restricted>
      {shouldShowOverallScoreColumn && (
        <Restricted
          roles={[UserRole.SystemAdmin, ProjectRole.ProjectAdmin]}
          allow={Boolean(reviewer)}
          wrapperTagName={false}
        >
          <Column className="align-right">
            {extractScoreStringFromSubmissionList(
              submission.status,
              submission.score,
            )}
            <ScoreStatusBadge
              scoreStatus={extractScoreStatusFromSubmissionList(
                submission.status,
                submission.score,
                submission.hasScoringDifference,
              )}
            />
          </Column>
        </Restricted>
      )}
      <Column>
        <DateTime
          dateTime={submission.deliveredAt}
          warningMessage={
            mailError
              ? MailStatus.getErrorMessage(submission.mailStatus)
              : undefined
          }
        />
      </Column>
      <Column noPaddingX>
        <DateTime dateTime={submission.deadline} />
      </Column>
      {shouldShowSubmittedAtColumn && (
        <Column
          className="code-submission-list-table-row_submitted-column"
          noPaddingX
        >
          {submission.submittedAt ? (
            <DateTime dateTime={submission.submittedAt} />
          ) : (
            "-"
          )}
          {submission.submissionType === SubmissionType.ManualOverride && (
            <Tooltip
              placement="bottom"
              text={Message.getMessageByKey("submission.manuallySubmitted")}
            >
              <Tag color="neutral-light" className="has-text-weight-medium">
                {Message.getMessageByKey("submission.manuallySubmitted")}
              </Tag>
            </Tooltip>
          )}
        </Column>
      )}
      {shouldShowTimeSpentColumn && (
        <Restricted
          roles={[UserRole.SystemAdmin, ProjectRole.ProjectAdmin]}
          allow={Boolean(reviewer)}
          wrapperTagName={false}
        >
          <Column className="align-right">
            <span style={{ wordBreak: "break-word" }}>
              {submission.timespentInSeconds
                ? formatTimeLeftString(submission.timespentInSeconds)
                : "-"}
            </span>
          </Column>
        </Restricted>
      )}
      <Column className="code-exam-list__item-actions">{actionMenu}</Column>
    </Row>
  );
}
