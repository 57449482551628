import { ApplicantExamStatus, SubmissionType } from "@shared/services/enums";

import {
  EvaluationMetaModel,
  ReviewerUserModel,
  ReviewModel,
  SubmissionCheatMonitoring,
  SubmissionOverlappingIp,
  SubmissionResultListModel,
} from ".";

export type SubmissionListFilterModel = {
  examId?: number;
  archived?: boolean;
  keyword?: string;
  // if both status and statuses are passed to backend. statuses will be ignored
  status?: ApplicantExamStatus;
  statuses?: ApplicantExamStatus[];
  needsReview?: boolean;
  limit?: number;
  count?: number;
  excludedStatuses?: number[];
  includeOverlappingIps?: boolean;
  applicantExamIds?: number[];
  "sortOrder.column"?: string;
  "sortOrder.direction"?: string;
  pagination?: number;
};

export type ResetSubmissionsParamsModel = {
  applicantExamIds: number[];
  challengeIds: number[];
  examDeadline?: Date;
  useLatestVersions?: boolean;
};

export type RescoreSubmissionsParamsModel = {
  examId: number;
  applicantExamIds: number[];
  rescoreExam: boolean; // Should be always true
  useLatestVersion?: boolean;
  challengeId: number;
  // challengeVersionId?: number; only for the backend
};

class SubmissionListModel {
  public applicantEmail = "No Email";
  public applicantFullName?: string;
  public applicantId: number;
  public archivedAt: string;
  public deadline: string;
  public deliveredAt: string;
  public examId: number;
  public language?: string;
  public goodReviewRatio: number;
  public hasScoringDifference: boolean;
  public id: number;
  public isTest: boolean;
  public mailStatus: number;
  public status: ApplicantExamStatus;
  public submittedAt: string;
  public timespentInSeconds: number;
  public score: number;
  public pendingReviewers: ReviewerUserModel[];
  public reviews: ReviewModel[];
  public challengeResults?: SubmissionResultListModel[];
  public submissionType: SubmissionType;
  public evaluationMeta?: EvaluationMetaModel;
  public cheatMonitoring?: SubmissionCheatMonitoring;
  public overlappingIpAddresses?: SubmissionOverlappingIp;

  constructor(args?: {}) {
    Object.assign(this, args);

    if (args) {
      const { pendingReviewers } = args as SubmissionListModel;
      const normalizedPendingReviewers = pendingReviewers?.map((r) => ({
        ...r,
        name: r.name === "_invitation_" ? r.email : r.name,
      }));

      Object.assign(this, { pendingReviewers: normalizedPendingReviewers });
    }
  }
}

export default SubmissionListModel;
