import * as classnames from "classnames";

import {
  Button,
  EvaluateIcon,
  Icon,
  Msg,
  Tooltip,
  WarningIcon,
} from "@shared/components";
import { useGetSubmissionsReviewers } from "@shared/hooks/query";
import { UserModel } from "@shared/models";

import { SubmissionReviewPopup } from "../../../submissionReviewPopup/SubmissionReviewPopup";
import { getCurrentUserReview } from "../SubmissionDetailUtils";

export const SubmissionDetailFloatingButtons = ({
  currentUser,
  showReviewButton,
  showReviewButtonAsPrimary,
  showEvaluateButton,
  showReviewPopup,
  isReviewFeatureAllowed,
  onReviewButtonClick,
  onEvaluateButtonClick,
  onReviewPopupSubmit,
  onReviewPopupCancel,
}: {
  currentUser: UserModel;
  showReviewButton: boolean;
  showReviewButtonAsPrimary: boolean;
  showEvaluateButton: boolean;
  showReviewPopup: boolean;
  isReviewFeatureAllowed: boolean;
  onReviewButtonClick: () => void;
  onEvaluateButtonClick: () => void;
  onReviewPopupSubmit: () => void;
  onReviewPopupCancel: () => void;
}) => {
  const {
    data: { reviews },
  } = useGetSubmissionsReviewers();
  const reviewedComment = getCurrentUserReview(reviews, currentUser);

  return (
    <div className="code-submission-detail__floating-button-group">
      {showEvaluateButton && (
        <Button
          className="code-submission-detail__floating-button"
          type="primary"
          rounded
          onClick={() => {
            onEvaluateButtonClick();
          }}
        >
          <EvaluateIcon />
          <Msg id="submission.evaluate" />
        </Button>
      )}
      {showReviewButton && (
        <Tooltip
          text={<Msg id="submission.reviewButton.outdated.tooltip" />}
          disabled={!reviewedComment || !reviewedComment.isStale}
          placement="top-start"
        >
          <Button
            className={classnames("code-submission-detail__floating-button", {
              "is-stale": reviewedComment?.isStale,
            })}
            type={showReviewButtonAsPrimary ? "primary" : "default"}
            rounded
            onClick={() => {
              onReviewButtonClick();
            }}
          >
            {reviewedComment?.isStale ? (
              <WarningIcon />
            ) : (
              <Icon type="comment-dots" variant="regular" />
            )}
            <Msg id="submission.review" />
          </Button>
        </Tooltip>
      )}
      {showReviewPopup && (
        <SubmissionReviewPopup
          onClickCancel={onReviewPopupCancel}
          onClickSave={onReviewPopupSubmit}
          currentUser={currentUser}
          notAllowed={!isReviewFeatureAllowed}
        />
      )}
    </div>
  );
};
