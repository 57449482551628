import * as classnames from "classnames";
import * as React from "react";

import { Loading } from "..";
import ExamReportChallengeDetail from "../../../components/orgs/exams/examReport/examReportChallengeDetail/ExamReportChallengeDetail";
import { SubmissionReportChallengeModel } from "../../models";

export interface SubmissionResultReportProps {
  getReport?: () => void;
  reportLoading: boolean;
  reportChallenges: Array<SubmissionReportChallengeModel>;
}

export default class SubmissionResultReport extends React.Component<SubmissionResultReportProps> {
  constructor(props: SubmissionResultReportProps) {
    super(props);
  }

  public UNSAFE_componentWillMount() {
    if (!this.props.reportChallenges.length) {
      this.props.getReport?.();
    }
  }

  public render() {
    const rootStyle = classnames("code-c-submission-result-report");

    if (this.props.reportLoading) {
      return (
        <div
          style={{
            position: "relative",
            minHeight: "300px",
          }}
        >
          <Loading isOpen={true} fullScreen={false} overlay={false} />
        </div>
      );
    }

    const challengeDetails = this.props.reportChallenges.map((challenge) => (
      <ExamReportChallengeDetail
        key={challenge.challengeId}
        challenge={challenge}
      />
    ));

    return <div className={rootStyle}>{challengeDetails}</div>;
  }
}
