import { useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";

import {
  Label,
  Msg,
  Checkbox,
  QuickHelp,
  Button,
  CopyBox,
  Divider,
} from "@shared/components";
import { isValidDate } from "@shared/services/date";
import Message from "@shared/services/message";
import { getExamDeliveryURL } from "@shared/services/url";

import { ManageDomainModal } from "../../examSettings/partials";
import { useDraftForm } from "../DraftFormProvider";
import { ExamSettingsFormValidation } from "../utils";
import { UrlActivePeriod } from "./UrlActivePeriod";

interface SelfDeliveryProps {
  readOnly?: boolean;
  urlToken?: string;
  orgname: string;
  isDeliverByUrlAllowed: boolean;
}

export const SelfDeliveryCheckbox = ({
  readOnly,
  urlToken,
  orgname,
  isDeliverByUrlAllowed,
}: SelfDeliveryProps) => {
  const [isDomainsOpen, setIsDomainsOpen] = useState(false);

  const { watch, setValue, formState, trigger } =
    useFormContext<ExamSettingsFormValidation>();

  const urlDeliveryEnabled = watch("urlDeliveryEnabled");

  const errors = formState.errors;

  const { setDraftState, draftState } =
    useDraftForm<ExamSettingsFormValidation>();
  const [startAt, endAt, domainWhitelist] = watch([
    "urlSharingConfig.startAt",
    "urlSharingConfig.endAt",
    "urlSharingConfig.domainWhitelist",
  ]);

  const formattedDomains = useMemo(() => {
    return domainWhitelist?.map((val) => val?.trim()).filter(Boolean) ?? [];
  }, [domainWhitelist]);

  let endAtValue = "";
  if (endAt && isValidDate(endAt)) {
    endAtValue = (endAt as Date).toISOString();
  }
  if (endAt && !isValidDate(endAt)) {
    endAtValue = endAt as unknown as string;
  }

  let startAtValue = "";
  if (startAt && isValidDate(startAt)) {
    startAtValue = (startAt as Date).toISOString();
  }
  if (startAt && !isValidDate(startAt)) {
    startAtValue = startAt as unknown as string;
  }

  let startDateError = "";
  if (errors.urlSharingConfig && "startAt" in errors.urlSharingConfig) {
    startDateError = errors.urlSharingConfig.startAt?.message ?? "";
  }
  let endDateError = "";
  if (errors.urlSharingConfig && "endAt" in errors.urlSharingConfig) {
    endDateError = errors.urlSharingConfig.endAt?.message ?? "";
  }

  return (
    <div className="code-self-delivery-checkbox">
      <Checkbox
        readOnly={readOnly}
        description={<Msg id="exam.emailDelivery.enableSelfDelivery.subText" />}
        value={urlDeliveryEnabled}
        dataTestautomationid="selfDeliveryCheckbox"
        onChange={(e) => {
          const isChecked = e.target.checked;
          setValue("urlDeliveryEnabled", isChecked);
          if (isChecked) {
            if (!draftState.urlSharingConfig) {
              const defaultValues = {
                domainWhitelist: [],
                startAt: undefined as unknown as Date,
                endAt: undefined as unknown as Date,
                dynamicDeadlineHours: undefined,
              };
              setValue("urlSharingConfig", defaultValues);
              setDraftState((draft) => {
                draft.urlSharingConfig = defaultValues;
                return draft;
              });
            } else {
              setValue(
                "urlSharingConfig",
                {
                  ...draftState.urlSharingConfig,
                  domainWhitelist: draftState.urlSharingConfig.domainWhitelist,
                },
                { shouldDirty: true },
              );
            }
          }
          if (!isChecked) {
            setValue("urlSharingConfig", undefined);
            setDraftState((draft) => {
              draft.urlSharingConfig = undefined;
              return draft;
            });
          }
        }}
      >
        <Msg id="exam.emailDelivery.enableSelfDelivery" />
      </Checkbox>
      {urlDeliveryEnabled && (
        <div className="code-exam-create-new__settings__self-delivery-options">
          {urlToken && (
            <CopyBox
              disabled={!isDeliverByUrlAllowed}
              value={getExamDeliveryURL(orgname || "", urlToken, true)}
            />
          )}
          <Divider />
          <UrlActivePeriod
            isEmailDelivery
            readOnly={readOnly}
            startAtProps={{
              onChange: (date: string) => {
                const dateFormatted = isValidDate(date) ? new Date(date) : date;
                setDraftState((draft) => {
                  if (urlDeliveryEnabled === true && draft.urlSharingConfig) {
                    draft.urlSharingConfig.startAt = dateFormatted as Date;
                  }
                  return draft;
                });
                setValue("urlSharingConfig.startAt", dateFormatted as Date);

                trigger("urlSharingConfig.startAt");
                trigger("urlSharingConfig.endAt");
              },
              value: startAtValue,
              error: startDateError,
            }}
            endAtProps={{
              onChange: (date: string) => {
                const dateFormatted = isValidDate(date) ? new Date(date) : date;
                setDraftState((draft) => {
                  if (urlDeliveryEnabled === true && draft.urlSharingConfig) {
                    draft.urlSharingConfig.endAt = dateFormatted as Date;
                  }
                  return draft;
                });
                setValue("urlSharingConfig.endAt", dateFormatted as Date);
                trigger("urlSharingConfig.startAt");
                trigger("urlSharingConfig.endAt");
              },
              value: endAtValue,
              error: endDateError,
            }}
            dynamicDeadlineHoursFieldId="urlSharingConfig.dynamicDeadlineHours"
          />
          <div>
            <Label
              isOptional
              rightSection={
                <QuickHelp
                  text={Message.getMessageByKey("exam.domains.tooltipNew")}
                  maxSize="xlarge"
                />
              }
            >
              <Msg id="exam.domain.whitelist" />
            </Label>
            {Boolean(formattedDomains.length) && (
              <div className="code-exam-create-new__settings__domain-list">
                {formattedDomains.map((domain, idx) => (
                  <p key={idx}>{domain}</p>
                ))}
              </div>
            )}
            <div>
              <Button
                shrink
                size="small"
                onClick={() => setIsDomainsOpen(true)}
                disabled={readOnly}
              >
                <Msg id="exam.domains.manage" />
              </Button>
            </div>
          </div>
        </div>
      )}
      {isDomainsOpen && (
        <ManageDomainModal
          isOpen
          domains={formattedDomains}
          onClose={() => setIsDomainsOpen(false)}
          onOk={(newDomains) => {
            setDraftState((draft) => {
              if (urlDeliveryEnabled === true && draft.urlSharingConfig) {
                draft.urlSharingConfig.domainWhitelist = newDomains;
              }
              return draft;
            });
            setValue("urlSharingConfig.domainWhitelist", newDomains, {
              shouldValidate: true,
              shouldDirty: true,
            });
            setIsDomainsOpen(false);
          }}
        />
      )}
    </div>
  );
};
