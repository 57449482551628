import * as classnames from "classnames";
import * as React from "react";

import { ErrorTile } from "../../../../shared/components";
import Message from "../../../../shared/services/message";

/**
 * Prop interface
 */
export interface NotFoundProps {
  hasBackButton?: boolean;
  hasBoxShadow?: boolean;
}

/**
 * Page component
 */
class NotFound extends React.Component<NotFoundProps> {
  constructor(props: NotFoundProps) {
    super(props);
  }

  public render() {
    const rootStyle = classnames("code-not-found");

    return (
      <div className={rootStyle}>
        <ErrorTile
          errorCode="404"
          errorMessage={Message.getMessageByKey("error.notFound")}
          hasBackButton={this.props.hasBackButton}
          hasBoxShadow={this.props.hasBoxShadow}
        />
      </div>
    );
  }
}

export default NotFound;
