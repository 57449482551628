import { AxiosRequestConfig } from "axios";

import { ApiResponse, post } from "@api/httpClient";

import { RescoreSubmissionsParamsModel } from "@shared/models/SubmissionList.model";

interface SubmissionRescoreParams {
  options: AxiosRequestConfig<RescoreSubmissionsParamsModel>;
}

export async function postSubmissionRescore({
  options,
}: SubmissionRescoreParams) {
  const { data } = await post<ApiResponse<number[]>>(
    `/api/admin/scoremaintain/submission/rescorebatch`,
    options,
  );

  return data;
}
