import {
  ExternalSystemLinkListModel,
  ExternalSystemLinkModel,
  PaginationModel,
} from "../shared/models";
import { actionCreator2 } from "../shared/services/actionCreator";
import { APIResponseAction } from "./index";

export interface ExternalSystemListAction extends APIResponseAction {
  payload: {
    code: number;
    result: Array<ExternalSystemLinkListModel>;
    pagination: PaginationModel;
  };
}

export interface ExternalSystemAction extends APIResponseAction {
  payload: {
    code: number;
    result: Array<ExternalSystemLinkModel>;
  };
}

export const externalSystemListGetAction = actionCreator2(
  "externalSystemListGetAction_REQUEST",
  "externalSystemListGetAction_SUCCESS",
  "externalSystemListGetAction_FAILURE",
)<
  {
    organizationName: string;
  },
  {
    result: ExternalSystemLinkListModel[];
    pagination: PaginationModel;
  },
  Error
>();

export const externalSystemListResetAction = actionCreator2(
  "externalSystemListResetAction_REQUEST",
  "externalSystemListResetAction_SUCCESS",
  "externalSystemListResetAction_FAILURE",
)<{}, {}, Error>();

export const externalSystemSetAction = actionCreator2(
  "externalSystemSetAction_REQUEST",
  "externalSystemSetAction_SUCCESS",
  "externalSystemSetAction_FAILURE",
)<{ result: ExternalSystemLinkModel }, {}, Error>();

export const externalSystemCreateAction = actionCreator2(
  "externalSystemCreateAction_REQUEST",
  "externalSystemCreateAction_SUCCESS",
  "externalSystemCreateAction_FAILURE",
)<
  {
    formValues: {};
    organizationName: string;
  },
  { result: ExternalSystemLinkModel },
  Error
>();

export const externalSystemUpdateAction = actionCreator2(
  "externalSystemUpdateAction_REQUEST",
  "externalSystemUpdateAction_SUCCESS",
  "externalSystemUpdateAction_FAILURE",
)<
  {
    formValues: {};
    organizationName: string;
    atsRelationId: number;
  },
  { result: ExternalSystemLinkModel },
  Error
>();
