import {
  Action,
  APIResponseAction,
  userGetAction,
  userUpdateAction,
  userAuthedAction,
  userOrgSetAction,
  userRefreshAction,
  authSignOutAction,
} from "../actions";
import { UserModel } from "../shared/models";

export interface UserState {
  updatingImage: boolean;
  updatingUser: boolean;
  loading: boolean;
  error: string;
  success: boolean;
  user?: UserModel;
}

const initialState = {
  updatingImage: false,
  updatingUser: false,
  loading: false,
  error: "",
  success: false,
};

export const userReducer = (
  state: UserState = initialState,
  action: Action,
): UserState => {
  const payload = (action as APIResponseAction).payload || {};

  switch (action.type) {
    case userGetAction.types.request:
      return { ...state, loading: true, error: "" };
    case userRefreshAction.types.success:
    case userGetAction.types.success:
      return Object.assign({}, state, {
        user: new UserModel({
          ...state.user,
          ...payload.result,
        }),
      });

    case userAuthedAction.types.request:
      return {
        ...state,
        loading: false,
      };

    case userGetAction.types.failure:
      return Object.assign({}, state, {
        loading: false,
        updatingImage: false,
        error: action.payload,
      });

    case userUpdateAction.types.request:
      return { ...state, updatingUser: true };
    case userUpdateAction.types.success:
      return {
        ...state,
        updatingUser: false,
        success: true,
        updatingImage: false,
      };

    case userOrgSetAction.types.request:
      const organization = (state.user || { organization: {} }).organization;
      return {
        ...state,
        user: new UserModel({
          ...state.user,
          organization: {
            ...organization,
            ...payload.result,
          },
        }),
      };

    case authSignOutAction.types.success: {
      return initialState;
    }

    default:
      return state;
  }
};
