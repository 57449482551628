import { connect } from "react-redux";
import { Action, Dispatch } from "redux";

import {
  projectSlackGetAction,
  projectSlackRegistrationGetAction,
  projectSocialChannelAddAction,
  projectSocialChannelDeleteAction,
  projectSocialChannelMoveStep1Action,
  projectSocialChannelUpdateAction,
  projectSocialDeleteAction,
} from "@actions";

import { RootState } from "@reducers";

import { SlackIntegrationInputModel } from "@shared/models";

import {
  SettingSlackIntegration,
  SettingSlackIntegrationProps,
} from "./SettingSlackIntegration";

const mapStateToProps = (
  state: RootState,
  props: SettingSlackIntegrationProps,
) => {
  return {
    ...props,
    projectId: state.project.currentProjectId,
    socialChannels: state.project.projectSlackSocialChannels,
    slackSocial: state.project.projectSlackSocial,
    slackSocialDenied: state.project.projectSlackSocialDenied,
    slackSocialLoading: state.project.projectSlackSocialLoading,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  getSlackSocial: (projectId: number) => {
    dispatch(projectSlackGetAction.request({ projectId }));
  },
  getSlackRegistration: (projectId: number) => {
    dispatch(projectSlackRegistrationGetAction.request({ projectId }));
  },
  deleteSocial: (projectId: number, socialId: number) => {
    dispatch(projectSocialDeleteAction.request({ projectId, socialId }));
  },
  addSocialChannel: (
    projectId: number,
    socialId: number,
    params: SlackIntegrationInputModel,
  ) => {
    dispatch(
      projectSocialChannelAddAction.request({ projectId, socialId }, params),
    );
  },
  updateSocialChannel: (
    projectId: number,
    socialId: number,
    integrationId: number,
    params: SlackIntegrationInputModel,
  ) => {
    dispatch(
      projectSocialChannelUpdateAction.request(
        { projectId, socialId, integrationId },
        params,
      ),
    );
  },
  moveSocialChannel: (
    projectId: number,
    socialId: number,
    integrationIdToDelete: number,
    params: SlackIntegrationInputModel,
  ) => {
    dispatch(
      projectSocialChannelMoveStep1Action.request(
        { projectId, socialId, integrationId: integrationIdToDelete },
        params,
      ),
    );
  },
  deleteSocialChannel: (
    projectId: number,
    socialId: number,
    integrationId: number,
  ) => {
    dispatch(
      projectSocialChannelDeleteAction.request(
        { projectId, socialId, integrationId },
        {},
      ),
    );
  },
});
/**
 * Wrapped component
 */
const wrappedComponent = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SettingSlackIntegration);

export default wrappedComponent;
