export default class ContractModel {
  public id: number;
  public organizationId: number;
  public name: string;
  public startAt: string;
  public endAt: string;
  public createdBy: number;
  public updatedBy: number;
  public createdAt: string;
  public updatedAt: string;

  public examDeliveryLimit?: number;
  public projects: Array<{ projectId: number }> = [];

  constructor(args?: {}) {
    Object.assign(this, args);
  }
}
