import * as classnames from "classnames";
import * as React from "react";

import { SubmissionDetail } from "../..";
import { SubmissionResultDetailModel } from "../../../models";

/**
 * Prop interface
 */
export type SubmissionDetailQuizProps = {
  submissionResultDetail: SubmissionResultDetailModel;
  className?: string;
};

/**
 * React Component
 */
export const SubmissionDetailQuiz: React.FunctionComponent<
  SubmissionDetailQuizProps
> = (props: SubmissionDetailQuizProps) => {
  const { submissionResultDetail, className } = props;
  const { quizResult } = submissionResultDetail;

  /**
   * Private Functions
   */
  const rootStyle = classnames("code-c-submission-detail-quiz", {
    [`${className}`]: Boolean(className),
  });

  return (
    <div className={rootStyle}>
      {quizResult.questions.map((question, index) => (
        <SubmissionDetail.SubmissionDetailQuestion
          key={index}
          questionNumber={index + 1}
          question={question}
          language={submissionResultDetail.challengeLanguage}
        />
      ))}
    </div>
  );
};
