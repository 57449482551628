import { Button, Msg } from "@shared/components";

interface ExamEditSaveAreaProps {
  disabled?: boolean;
  onSaveClick: () => void;
}

export const ExamEditSaveArea = ({
  disabled,
  onSaveClick,
}: ExamEditSaveAreaProps) => {
  return (
    <div className="code-exam-edit__action-area">
      <Button
        type={"primary"}
        disabled={disabled}
        onClick={onSaveClick}
        ariaLabel="Save"
      >
        <Msg id={"save"} />
      </Button>
    </div>
  );
};
