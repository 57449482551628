import * as classnames from "classnames";

import { Tag, Msg, Icon, Tooltip } from "..";
import Message from "../../../shared/services/message";
import { formatDate } from "../../services/date";

/**
 * Prop interface
 */
export interface OfficialExamCardProps {
  className?: string;
  engineerRole?: string;
  imageUrl: string;
  language?: string;
  time?: number;
  title?: string;
  updatedAt?: string;
  usingOrgCount?: number;
}

/**
 * React Component
 */
export default function OfficialExamCard({
  className,
  engineerRole,
  imageUrl,
  language,
  time,
  title = "",
  updatedAt,
  usingOrgCount = 0,
}: OfficialExamCardProps) {
  const rootStyle = classnames("code-c-official-exam-card", {
    [`${className}`]: Boolean(className),
  });

  return (
    <div className={rootStyle}>
      <div className="code-c-official-exam-card__image-container">
        <div className="cover-image-wrapper">
          <img className="cover-image" src={imageUrl} />
        </div>
        {language && (
          <Tooltip
            text={Message.getMessageByKey(
              language === "EN"
                ? "exam.officialExam.english"
                : "exam.officialExam.japanese",
            )}
          >
            <Tag className={"tag-language"}>{language}</Tag>
          </Tooltip>
        )}
      </div>
      <div className="code-c-official-exam-card__detail-container">
        {title && (
          <div className="code-c-official-exam-card__detail-container__title clamp-2">
            {title}
          </div>
        )}
        <div className="code-c-official-exam-card__detail-container__items">
          {time && (
            <div>
              <Icon type={"clock-o"} />
              {time}
              <Msg id="unit.mins" />
            </div>
          )}
          {engineerRole && (
            <div>
              <Icon type={"desktop"} />
              {engineerRole}
            </div>
          )}
          {(usingOrgCount || usingOrgCount === 0) && (
            <div>
              <Icon type={"building"} />
              <Msg
                id="createExam.officialExam.usingOrgCount"
                values={{ count: usingOrgCount }}
              />
            </div>
          )}
        </div>

        {updatedAt && (
          <div className="code-c-official-exam-card__updatedat">
            <Msg id="updated" />: {formatDate(updatedAt)}
          </div>
        )}
      </div>
    </div>
  );
}
