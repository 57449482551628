import { connect } from "react-redux";
import { Action, Dispatch } from "redux";

import {
  orgsUserInviteListAction,
  orgsUserInviteResendAction,
  orgsUserInviteDeleteAction,
  orgsUserInviteUpdateAction,
} from "@actions";

import { RootState } from "@reducers";

import { PaginationModel } from "@shared/models";
import { UserRole } from "@shared/services/enums";

import { SettingMemberPending } from "./SettingMemberPending";

const mapStateToProps = (state: RootState) => ({
  invites: state.orgs.orgsPendingInvitesList,
  pagination: state.orgs.orgsPendingInvitesPagination,
  editableRoles: [UserRole.SystemAdmin, UserRole.OrgAdmin],
  currentProjectId: state.project.currentProjectId,
  projects: state.user.user?.projects,
  inviteMemberLoading: state.orgs.orgsPendingInviteMemberLoading,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  getInvites: (pagination: PaginationModel, keyword?: string) => {
    dispatch(orgsUserInviteListAction.request({ ...pagination, keyword }));
  },
  resendInvite: (inviteId: number) => {
    dispatch(orgsUserInviteResendAction.request(inviteId));
  },
  updateInvite: (inviteId: number, formValues: {}) => {
    dispatch(orgsUserInviteUpdateAction.request(inviteId, formValues));
  },
  deleteInvite: (inviteId: number) => {
    dispatch(orgsUserInviteDeleteAction.request(inviteId));
  },
});

/**
 * Wrapped component
 */
const wrappedComponent = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SettingMemberPending);

export default wrappedComponent;
