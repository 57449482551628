const PREFIX = "track";
const CHECK_STRING = "checkStorage";

export const CREATE_EXAM_KEY = "create_exam_key";
export const SHOW_COMPLETED_MODAL_KEY = "show_completed_modal_key";
export const SHOW_INTERCOM_KEY = "show_intercom_key";
export const LAST_SCORE_KEY = "last_score_key";
export const REDIRECT_TO = "redirect_to_key";
export const ACTION_LOG_OPENED_KEY = "action_log_opened_key";

class StorageBase {
  public readonly available: boolean = true;
  private storage: Storage = {} as Storage;

  constructor(storageName: string) {
    try {
      const storage = window[storageName];
      storage.setItem(this.generateKey(CHECK_STRING), CHECK_STRING);
      storage.removeItem(this.generateKey(CHECK_STRING));
      this.storage = storage;
    } catch (e) {
      // Access denied :(
      this.available = false;
    }
  }

  public setItem(key: string, value: {}) {
    if (this.available) {
      try {
        this.storage.setItem(this.generateKey(key), JSON.stringify(value));
      } catch (e) {
        // Storage is full :(
      }
    }
  }

  public getItem(key: string) {
    if (this.available) {
      try {
        const stringObject = this.storage.getItem(this.generateKey(key));
        return stringObject ? JSON.parse(stringObject) : "";
      } catch (e) {
        return "";
      }
    } else {
      return "";
    }
  }

  public removeItem(key: string) {
    if (this.available) {
      this.storage.removeItem(this.generateKey(key));
    }
  }

  private generateKey(key: string) {
    return `${PREFIX}.${key}`;
  }
}

class LocalStorage extends StorageBase {
  constructor() {
    super("localStorage");
  }
}

class SessionStorage extends StorageBase {
  constructor() {
    super("sessionStorage");
  }
}

const localStorage = new LocalStorage();
const sessionStorage = new SessionStorage();

export { localStorage, sessionStorage };
