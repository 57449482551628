import { AxiosRequestConfig } from "axios";
import { get } from "lodash";

import {
  DownloadCSVParamsModel,
  EnumWithStringValueModel,
} from "@shared/models";

import { post } from "../httpClient";

export { EnumWithStringValueModel };

export async function postSubmissionCsv({
  projectId,
  options,
}: {
  projectId: number;
  options: AxiosRequestConfig<DownloadCSVParamsModel>;
}) {
  const type = get(options, "data.type");
  const url = [
    `/api/projects/${projectId}/submissions`,
    ...(type ? [type] : []),
    "download",
  ].join("/");
  const requestOptions = {
    ...options,
    responseType: "blob",
    withCredentials: true,
  } as AxiosRequestConfig<DownloadCSVParamsModel>;

  const { data, headers } = await post(url, requestOptions);

  return { data, headers };
}
