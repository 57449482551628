import { connect } from "react-redux";
import { Action, Dispatch } from "redux";

import { imageUploadAction } from "../../../actions";
import { RootState } from "../../../reducers";
import RichTextarea from "./RichTextarea";

export interface RichTextareaConnectProps {
  className?: string;
  name?: string;
  value?: string;
  disabled?: boolean;
  readOnly?: boolean;
  onChange?: (e: React.FormEvent<HTMLTextAreaElement>) => void;
  onBlur?: (e: React.FormEvent<HTMLTextAreaElement>) => void;
  textareaClassName?: string;
  error?: { [key: string]: string };
  placeholder?: string;

  customActions?: Array<{
    title: string;
    textToInsert: string;
    cursorPosition?: number;
    multiline?: boolean;
  }>;

  hideHeadingControl?: boolean; // currently questions can't have headers because the backend parseing fails
  hideImageUploadControl?: boolean;
}

const mapStateToProps = (
  state: RootState,
  props: RichTextareaConnectProps,
) => ({
  ...props,
  uploadingImage: state.imageUpload.uploading,
  uploadedImages: state.imageUpload.uploadedImages,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  uploadImage: (name: string, file: File, width: number, height: number) => {
    dispatch(imageUploadAction.request({ name, file, width, height }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(RichTextarea);
