import {
  QueryFunctionContext,
  useQueries,
  useQuery,
} from "@tanstack/react-query";

import {
  getSubmissionResultDetail,
  getSubmissionResults,
} from "@api/submissions";

import { useStoreContext, useSubmissionAccessTypeContext } from "@context";

import {
  SubmissionAccessType,
  SubmissionResultDetailModel,
  SubmissionResultListModel,
} from "@shared/models";
import { getLastWeekTimestamp } from "@shared/services/date";
import { ChallengeStyle, GlobalAppType } from "@shared/services/enums";

import { useRouteParams } from "../useRouteParams";

interface FetchSubmissionResultDetailParams {
  challengeId: number;
  globalAppType: GlobalAppType;
  projectId: number;
  submissionId: number;
  addReadme: (result: SubmissionResultDetailModel) => void;
}

interface SubmissionResultDetailAllKeys {
  projectId: number;
  submissionId: number;
  globalAppType: number;
}

interface SubmissionResultDetailKeys extends SubmissionResultDetailAllKeys {
  challengeId: number;
}

export const submissionResultKeys = {
  all: ["submissions", "results"] as const,
  project: (projectId: number) =>
    [...submissionResultKeys.all, projectId] as const,
  resultAll: (projectId: number, submissionId: number) =>
    [...submissionResultKeys.project(projectId), submissionId] as const,
  resultDetailAll: ({
    projectId,
    submissionId,
    globalAppType,
  }: SubmissionResultDetailAllKeys) =>
    [
      ...submissionResultKeys.resultAll(projectId, submissionId),
      globalAppType,
    ] as const,
  resultDetail: ({ challengeId, ...rest }: SubmissionResultDetailKeys) => [
    ...submissionResultKeys.resultDetailAll(rest),
    challengeId,
  ],
};

export function useGetSubmissionsResults(noDetails?: boolean) {
  const { projectId } = useStoreContext();
  const { submissionId } = useRouteParams();
  const isAccessDeny =
    useSubmissionAccessTypeContext() !== SubmissionAccessType.Deny;
  const enabled = Boolean(submissionId) && isAccessDeny;
  const query = useQuery<SubmissionResultListModel[]>({
    queryKey: submissionResultKeys.resultAll(projectId, submissionId),
    queryFn: async ({ signal }) => {
      const { result } = await getSubmissionResults({
        projectId,
        submissionId,
        options: {
          signal,
        },
      });

      return result.map((r) => new SubmissionResultListModel(r));
    },
    enabled,
    initialData: [],
    initialDataUpdatedAt: getLastWeekTimestamp,
    keepPreviousData: true,
  });

  return query;
}

const fetchSubmissionResultDetail =
  ({
    challengeId,
    globalAppType,
    projectId,
    submissionId,
    addReadme,
  }: FetchSubmissionResultDetailParams) =>
  async ({ signal }: QueryFunctionContext) => {
    const { result } = await getSubmissionResultDetail({
      projectId,
      submissionId,
      challengeId,
      options: { signal },
    });

    const model = new SubmissionResultDetailModel({
      ...result,
      // it always shows for the org side by Roy
      showSecretTestcases: globalAppType === GlobalAppType.Org,
    });

    if (ChallengeStyle.hasReadme(model.style)) {
      addReadme(model);
    }

    return model;
  };

export function useGetSubmissionsResultDetail(challengeId: number) {
  const { globalAppType, projectId, addReadme } = useStoreContext();
  const { submissionId } = useRouteParams();
  const enabled = Boolean(submissionId);
  const query = useQuery<SubmissionResultDetailModel>({
    queryKey: submissionResultKeys.resultDetail({
      projectId,
      submissionId,
      challengeId,
      globalAppType,
    }),
    queryFn: fetchSubmissionResultDetail({
      challengeId,
      globalAppType,
      projectId,
      submissionId,
      addReadme,
    }),
    enabled,
    initialData: new SubmissionResultDetailModel({ codingResult: {} }),
    initialDataUpdatedAt: getLastWeekTimestamp(),
    keepPreviousData: true,
  });

  return query;
}

export function useGetSubmissionsResultDetailBatch(
  submissionResults?: SubmissionResultListModel[],
) {
  const { globalAppType, projectId, addReadme } = useStoreContext();
  const { submissionId } = useRouteParams();
  const enabled =
    useSubmissionAccessTypeContext() === SubmissionAccessType.Full;
  // Filter only the submission results that have a challenge result status
  const batch = (submissionResults || []).filter(({ challengeResultStatus }) =>
    Boolean(challengeResultStatus),
  );
  const queries = useQueries<SubmissionResultDetailModel[]>({
    queries: batch.map((item) => ({
      queryKey: submissionResultKeys.resultDetail({
        projectId,
        submissionId,
        challengeId: item.challengeId,
        globalAppType,
      }),
      queryFn: fetchSubmissionResultDetail({
        challengeId: item.challengeId,
        globalAppType,
        projectId,
        submissionId,
        addReadme,
      }),
      enabled,
    })),
  });

  // useQueries will fill array with empty objects before the requests are done
  return queries
    .map((query) => query.data as SubmissionResultDetailModel)
    .filter(Boolean);
}
