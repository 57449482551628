import { connect } from "react-redux";
import { Action, Dispatch } from "redux";

import {
  projectSocialUserGetAction,
  projectSocialUserUpdateAction,
  userLocalUpdateAction,
  userUpdateAction,
} from "@actions";

import { RootState } from "@reducers";

import { UserModel } from "@shared/models";
import { ProjectRole } from "@shared/services/enums";

import AccountGeneral from "./AccountGeneral";

const mapStateToProps = (state: RootState) => ({
  language: state.user.user?.language,
  dailySubmissionNotificationEnabled:
    state.user.user?.dailySubmissionNotificationEnabled ?? false,
  realtimeSubmissionNotificationEnabled:
    state.user.user?.realtimeSubmissionNotificationEnabled ?? false,
  newUserNotificationEnabled:
    state.user.user?.newUserNotificationEnabled ?? false,
  hasSettings: Boolean(
    state.user.user?.hasRole(
      [ProjectRole.ProjectAdmin, ProjectRole.Reviewer],
      state.project.currentProjectId,
    ),
  ),
  hasSlackIntegration: Boolean(state.project.projectSlackUserSettings),
  slackAllNotificationEnabled:
    state.project.projectSlackUserSettings?.slackEnabled ?? false,
  updating: Boolean(state.ajax.submitting),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  updateUser: (payload: UserModel) => {
    dispatch(userUpdateAction.request(payload));
  },
  updateUserLocal: (payload: {}) => {
    dispatch(userLocalUpdateAction.request(payload));
  },
  getSocialUserSettings: () => {
    dispatch(projectSocialUserGetAction.request());
  },
  updateSocialUserSettings: (enableSlackAllNotification: boolean) => {
    dispatch(
      projectSocialUserUpdateAction.request(
        {},
        {
          slackEnabled: enableSlackAllNotification,
        },
      ),
    );
  },
});

const AccountSettingConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountGeneral);

export default AccountSettingConnect;
