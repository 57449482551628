import { AxiosRequestConfig } from "axios";

import { get } from "@api/httpClient";

import { SimpleExamModel } from "@shared/models";

export async function getExamSimpleList(options: AxiosRequestConfig) {
  const { data } = await get<SimpleExamModel[]>(`/api/exams/simple`, options);

  return data;
}

export async function getExamSimpleByProjectId(
  projectId: number,
  options: AxiosRequestConfig,
) {
  const { data } = await get<SimpleExamModel[]>(
    `/api/projects/${projectId}/exams/simple`,
    options,
  );

  return data;
}
