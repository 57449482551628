import * as classnames from "classnames";
import { isEqual } from "lodash";
import * as React from "react";
import { toast } from "react-toastify";

import { Textarea, Input, Tooltip, Icon, Loading, Button } from "../";
import Message from "../../../shared/services/message";
import { getFormattedText } from "../../services/fibParser";

/**
 * Prop interface
 */
export interface RichTextareaProps {
  className?: string;
  name?: string;
  value?: string;
  disabled?: boolean;
  readOnly?: boolean;
  onChange?: (
    e: React.FormEvent<HTMLTextAreaElement>,
    loading?: boolean,
  ) => void;
  onBlur?: (e: React.FormEvent<HTMLTextAreaElement>) => void;
  textareaClassName?: string;
  error?: { [key: string]: string };
  placeholder?: string;

  hideHeadingControl?: boolean; // currently questions can't have headers because the backend parseing fails
  hideImageUploadControl?: boolean;

  customActions?: Array<{
    title: string;
    textToInsert: string;
    cursorPosition?: number;
    multiline?: boolean;
  }>;

  uploadingImage: boolean;
  uploadImage: (
    name: string,
    file: File,
    width: number,
    height: number,
  ) => void;
  uploadedImages: {
    [key: string]: { error: boolean; url: string };
  };
}

export interface RichTextareaState {
  text: string;
  error?: { [key: string]: string };
}

/**
 * React Component
 */
export default class RichTextarea extends React.Component<
  RichTextareaProps,
  RichTextareaState
> {
  private textarea: Textarea | null;
  private input: HTMLInputElement;

  constructor(props: RichTextareaProps) {
    super(props);

    this.state = {
      text: props.value || "",
    };
  }

  public UNSAFE_componentWillReceiveProps(nextProps: RichTextareaProps) {
    if (!isEqual(this.props.uploadedImages, nextProps.uploadedImages)) {
      const { text } = this.state;
      let newText = text;
      let error: { [key: string]: string } | undefined;

      Object.keys(nextProps.uploadedImages).forEach((imageName) => {
        const uploadResult = nextProps.uploadedImages[imageName];
        if (uploadResult.error) {
          newText = text.replace(`![](Uploading... ${imageName})`, "");
          error = { [nextProps.name || "textarea"]: uploadResult.url };
        } else {
          newText = text.replace(
            `![](Uploading... ${imageName})`,
            `![${imageName}](${uploadResult.url})`,
          );
        }
      });

      return this.setState({ text: newText }, () => {
        this.dispatchEvent();
        this.setState({ error });
      });
    }

    if (nextProps.value !== undefined && this.props.value !== nextProps.value) {
      return this.setState({
        text: nextProps.value,
      });
    }
  }

  public shouldComponentUpdate(
    nextProps: RichTextareaProps,
    nextState: RichTextareaState,
  ) {
    return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state);
  }

  public render() {
    const {
      className,
      hideHeadingControl,
      hideImageUploadControl,
      uploadingImage,
      name,
      disabled,
      readOnly,
      onBlur,
      textareaClassName,
      error,
      placeholder,
      customActions = [],
    } = this.props;

    const rootStyle = classnames("code-c-rich-textarea", {
      [`${className}`]: Boolean(className),
    });

    const customButtons = customActions.map((customAction, index) => (
      <Button
        key={index}
        onClick={() =>
          this.insertText(
            customAction.textToInsert,
            customAction.cursorPosition,
            customAction.multiline,
          )
        }
      >
        {customAction.title}
      </Button>
    ));

    return (
      <div>
        <div className={rootStyle}>
          <Loading isOpen={uploadingImage} fullScreen={false} />
          <div className="code-c-rich-textarea__header">
            <div className="input-group">
              {!hideHeadingControl && (
                <Tooltip text={Message.getMessageByKey("heading")}>
                  <a onClick={() => this.insertText("### ", 4)}>
                    <Icon type="header" />
                  </a>
                </Tooltip>
              )}
              <Tooltip text={Message.getMessageByKey("bold")}>
                <a onClick={() => this.insertText("****", 2)}>
                  <Icon type="bold" />
                </a>
              </Tooltip>
              <Tooltip text={Message.getMessageByKey("italic")}>
                <a onClick={() => this.insertText("__", 1)}>
                  <Icon type="italic" />
                </a>
              </Tooltip>
            </div>
            <div className="input-group">
              <Tooltip text={Message.getMessageByKey("bulletedList")}>
                <a onClick={() => this.insertText("- ", 2, true)}>
                  <Icon type="list-ul" />
                </a>
              </Tooltip>
              <Tooltip text={Message.getMessageByKey("numberedList")}>
                <a onClick={() => this.insertText("1. ", 3, true)}>
                  <Icon type="list-ol" />
                </a>
              </Tooltip>
            </div>
            {!hideImageUploadControl && (
              <div className="input-group">
                <Tooltip text={Message.getMessageByKey("imageUpload")}>
                  <a onClick={() => this.input.click()}>
                    <Icon type="image" />
                  </a>
                </Tooltip>
              </div>
            )}
          </div>
          <Input
            style={{ display: "none" }}
            reference={(r: HTMLInputElement) => (this.input = r)}
            type="file"
            accept="image/*"
            onChange={this.uploadImage}
            value=""
          />
          <Textarea
            ref={(ref) => (this.textarea = ref)}
            name={name || "textarea"}
            value={this.state.text}
            disabled={uploadingImage || disabled}
            readOnly={readOnly}
            onChange={this.onChange}
            onBlur={onBlur}
            textareaClassName={textareaClassName}
            error={this.state.error || error}
            placeholder={placeholder}
          />
        </div>
        <div className="code-c-rich-textarea__custom-actions">
          {customButtons}
        </div>
      </div>
    );
  }

  public insertText = (
    textToInsert: string,
    cursorPosition?: number,
    multiline?: boolean,
  ) => {
    if (!this.textarea) {
      return;
    }

    const { text = "" } = this.state;
    const [start, end] = this.textarea.getSelection();
    const markdownPrefix = textToInsert.slice(0, cursorPosition);
    const markdownSuffix = cursorPosition
      ? textToInsert.slice(cursorPosition)
      : "";
    const [
      formattedText,
      [newCursorStart, newCursorEnd],
      [textStart, textEnd],
    ] = getFormattedText(
      text,
      [start, end],
      [markdownPrefix, markdownSuffix],
      multiline,
    );

    this.textarea.focus();
    this.textarea.setSelection(textStart, textEnd);

    // execCommand doesn't work on textarea in firefox, insert text the dirty way
    if (document.execCommand("insertText", false, formattedText) === false) {
      this.setState(
        {
          text: text.slice(0, textStart) + formattedText + text.slice(textEnd),
        },
        () => {
          if (this.textarea) {
            this.textarea.focus();
            this.textarea.setSelection(newCursorStart, newCursorEnd);
            this.dispatchEvent();
          }
        },
      );
    } else {
      this.textarea.setSelection(newCursorStart, newCursorEnd);
    }
  };

  private onChange = (e: React.FormEvent<HTMLTextAreaElement>) => {
    const { value } = e.target as HTMLTextAreaElement;

    this.setState({
      text: value,
      error: undefined,
    });

    if (this.props.onChange) {
      this.props.onChange(e, this.props.uploadingImage);
    }
  };

  private dispatchEvent = () => {
    if (this.textarea && this.textarea.textarea) {
      this.onChange({
        target: this.textarea.textarea,
        bubbles: true,
        currentTarget: this.textarea.textarea,
        cancelable: true,
        defaultPrevented: false,
        eventPhase: 0,
        isTrusted: true,
        nativeEvent: new Event("input"),
        preventDefault: () => undefined,
        isDefaultPrevented: () => false,
        stopPropagation: () => undefined,
        isPropagationStopped: () => false,
        persist: () => undefined,
        timeStamp: 0,
        type: "input",
      } as React.FormEvent<HTMLTextAreaElement>);
    }
  };

  private uploadImage = (event: React.FormEvent<HTMLInputElement>) => {
    const files = (event.target as HTMLInputElement).files;
    const file = (files && files.length && files[0]) || undefined;

    if (file === undefined) {
      return;
    }

    // If the backend can make the width/height optional for the cloudinary config
    // it would make this much simpler and less prone to bugs
    const url = URL.createObjectURL(file);
    const img = new Image();

    const maxMegapixels = 25000000;
    const maxFilesize = 10000000;

    img.onload = () => {
      if (img.width * img.height >= maxMegapixels) {
        toast.error(Message.getMessageByKey("error.filesize.megapixels"));
        this.setState({
          error: {
            [this.props.name || "textarea"]: Message.getMessageByKey(
              "error.filesize.megapixels",
            ),
          },
        });
      } else if (file.size >= maxFilesize) {
        toast.error(Message.getMessageByKey("error.filesize"));
        this.setState({
          error: {
            [this.props.name || "textarea"]:
              Message.getMessageByKey("error.filesize"),
          },
        });
      } else {
        this.props.uploadImage(file.name, file, img.width, img.height);
        this.insertText("![](Uploading... " + file.name + ")");
      }
    };

    img.src = url;
  };
}
