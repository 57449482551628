import * as classnames from "classnames";
import * as Clipboard from "clipboard";
import { isEqual } from "lodash";
import * as React from "react";
import * as ReactDOM from "react-dom";
import * as shortid from "shortid";

import { Input, Button, Icon, IconButton } from "..";
import Message from "../../services/message";

/**
 * Prop interface
 */
export interface CopyBoxProps {
  disabled?: boolean;
  placeholder?: string;
  size?: number;
  value?: string;
  className?: string;
  scale?: "sm";
}

/**
 * State interface
 */
export interface CopyBoxState {
  isCopied: boolean;
}

/**
 * React Component
 */
export default class CopyBox extends React.Component<
  CopyBoxProps,
  CopyBoxState
> {
  private clipboard: Clipboard;
  private id: string;

  constructor(props: CopyBoxProps) {
    super(props);

    this.id = `copybox${shortid.generate()}`;

    this.state = {
      isCopied: false,
    };
  }

  public componentDidMount() {
    const element = ReactDOM.findDOMNode(this) as Element;
    const targetElement = element.querySelector(".code-c-copy-box__button");
    if (targetElement) {
      targetElement.setAttribute("data-clipboard-target", `#${this.id}`);
      targetElement.setAttribute(
        "data-badge",
        Message.getMessageByKey("message.copied"),
      );
      this.clipboard = new Clipboard(targetElement);
    }
  }

  public shouldComponentUpdate(
    nextProps: CopyBoxProps,
    nextState: CopyBoxState,
  ) {
    return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state);
  }

  public componentWillUnmount() {
    this.clipboard.destroy();
  }

  public render() {
    const { className, size, value, disabled, placeholder, scale } = this.props;

    const rootStyle = classnames("code-c-copy-box", "field", " has-addons", {
      [`${className}`]: Boolean(className),
    });

    const buttonStyle = classnames("code-c-copy-box__button", {
      badge: this.state.isCopied,
      "is-scale": scale,
    });

    const inputStyle = classnames({
      "text-sm": scale === "sm",
      "code-c-input": scale === "sm",
    });

    return (
      <div className={rootStyle}>
        <Input
          disabled={disabled}
          id={this.id}
          size={size}
          value={value}
          readOnly={true}
          placeholder={placeholder}
          inputClassName={inputStyle}
        />
        {scale ? (
          <IconButton
            disabled={disabled}
            onClick={this.onCopy}
            className={buttonStyle}
            aria-label="Copy To Clipboard"
          >
            <Icon className="code-c-icon__text-base" type="copy" />
          </IconButton>
        ) : (
          <div className="control">
            <Button
              disabled={disabled}
              onClick={this.onCopy}
              className={buttonStyle}
              ariaLabel="Copy To Clipboard"
            >
              <Icon type="copy" />
            </Button>
          </div>
        )}
      </div>
    );
  }

  private onCopy = () => {
    this.setState({ isCopied: true });
    window.setTimeout(() => this.setState({ isCopied: false }), 1000);
  };
}
