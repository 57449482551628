import * as classnames from "classnames";

/**
 * Prop interface
 */
export interface PageSubTitleProps {
  className?: string;
  size?: string;
  noMarginBottom?: boolean;
  children?: {};
}

/**
 * React Component
 */
export default function PageSubTitle({
  className,
  size = "normal",
  children,
  noMarginBottom,
}: PageSubTitleProps) {
  const sizeTable = {
    small: "is-6",
  };

  const rootStyle = classnames("code-c-page-sub-title", "subtitle", {
    [`${sizeTable[size]}`]: Boolean(size),
    [`${className}`]: Boolean(className),
    "code-c-page-sub-title__no-margin-bottom": noMarginBottom,
  });

  return <h2 className={rootStyle}>{children}</h2>;
}
