import * as classnames from "classnames";
import { isEqual } from "lodash";
import * as React from "react";

import { ErrorMessage, ValidationMessage, hasValidationError } from "..";

/**
 *
 */
export interface SelectItem {
  value: number | string;
  label: string;
  disabled?: boolean;
}

/**
 * Prop interface
 */
export interface SelectProps {
  options?: SelectItem[];
  multiple?: boolean;
  size?: number;
  className?: string;
  innerClassName?: string;
  selectClassName?: string;
  disabled?: boolean;
  "data-testid"?: string;
  name?: string;
  onChange?: (e: React.FormEvent<HTMLSelectElement>) => void;
  onBlur?: (e: React.FormEvent<HTMLSelectElement>) => void;
  value?: number | string;
  error?: { [key: string]: string };
  errorMessage?: string;
  forwardedRef?: React.Ref<HTMLSelectElement>;
  dataTestautomationid?: string;
}

/**
 * React Component
 */
class Select extends React.Component<SelectProps, {}> {
  private select: HTMLSelectElement | null;

  constructor(props: SelectProps) {
    super(props);
  }

  public shouldComponentUpdate(nextProps: SelectProps) {
    return !isEqual(nextProps, this.props);
  }

  /*
   * Set initial value on form
   */
  public componentDidMount() {
    /**
     * TODO: this can be removed once all forms are using react-hook-form
     * This seems to be a fix that is triggered on the initial mount for class components
     */
    if (this.select && !this.props.value) {
      const event = new Event("change", { bubbles: true });
      this.select.dispatchEvent(event);
    }
  }

  public render() {
    const {
      options = [],
      multiple,
      size,
      className,
      innerClassName,
      selectClassName,
      name,
      disabled,
      onChange,
      onBlur,
      value,
      error,
      "data-testid": dataTestId,
      forwardedRef,
      errorMessage,
      dataTestautomationid,
    } = this.props;

    const rootStyle = classnames("code-c-select", {
      [`${className}`]: Boolean(className),
    });

    const innerRootStyle = classnames("select", {
      "is-multiple": multiple,
      [`${innerClassName}`]: Boolean(innerClassName),
    });

    const selectStyle = classnames("select", {
      "is-danger": hasValidationError(name, error),
      [`${selectClassName}`]: Boolean(selectClassName),
    });

    const optionElements = options.map((item: SelectItem, index: number) => {
      const disableOption =
        item.value === undefined ||
        item.value === null ||
        item.value === "" ||
        item.disabled;

      return (
        <option key={index} value={item.value} disabled={disableOption}>
          {item.label}
        </option>
      );
    });

    return (
      <div className={rootStyle}>
        <div className={innerRootStyle}>
          <select
            required={true}
            className={selectStyle}
            multiple={multiple}
            size={size}
            name={name}
            disabled={disabled}
            onChange={onChange}
            onBlur={onBlur}
            value={forwardedRef ? undefined : value || ""}
            data-testid={dataTestId}
            data-testautomationid={dataTestautomationid}
            ref={
              forwardedRef ? forwardedRef : (select) => (this.select = select)
            }
          >
            {optionElements}
          </select>
        </div>
        <ValidationMessage name={name} error={error} />
        <ErrorMessage message={errorMessage} />
      </div>
    );
  }
}

export default React.forwardRef<HTMLSelectElement, SelectProps>(
  (props, ref) => <Select forwardedRef={ref} {...props} />,
);
