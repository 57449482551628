import * as classnames from "classnames";
import * as React from "react";

import { ErrorMessage, ValidationMessage, hasValidationError } from "..";

/**
 * Prop interface
 */
export interface CheckboxProps {
  className?: string;
  labelClassName?: string;
  checkboxClassName?: string;
  id?: string;
  name?: string;
  value?: boolean;
  readOnly?: boolean;
  ariaLabel?: string;
  error?: { [key: string]: string };
  errorMessage?: string;
  hideErrorMessage?: boolean;
  description?: React.ReactNode;
  children?: React.ReactNode;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FormEvent<HTMLInputElement>) => void;
  stopPropagation?: boolean;
  dataTestautomationid?: string;
}

const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      name,
      id,
      value,
      className,
      labelClassName,
      checkboxClassName,
      readOnly = false,
      ariaLabel,
      hideErrorMessage = false,
      description,
      children,
      onChange,
      onBlur,
      error,
      errorMessage,
      stopPropagation,
      dataTestautomationid,
    }: CheckboxProps,
    ref,
  ) => {
    const rootStyle = classnames("code-c-checkbox", "control", {
      [`${className}`]: Boolean(className),
    });

    const labelStyle = classnames("code-c-checkbox__label", "checkbox", {
      [`${labelClassName}`]: Boolean(labelClassName),
      "is-readonly": readOnly,
    });

    const checkboxStyle = classnames("code-c-checkbox__checkbox", {
      [`${checkboxClassName}`]: Boolean(checkboxClassName),
      "is-danger": hasValidationError(name, error),
      "has-description": description,
    });

    return (
      <div className={rootStyle}>
        <label className={labelStyle} aria-label={ariaLabel}>
          <input
            className={checkboxStyle}
            type="checkbox"
            id={id}
            name={name}
            checked={value}
            readOnly={readOnly}
            disabled={readOnly}
            onChange={onChange}
            onBlur={onBlur}
            aria-label={ariaLabel}
            ref={ref}
            onClick={(e) => {
              if (stopPropagation) {
                e.stopPropagation();
              }
            }}
            data-testautomationid={dataTestautomationid}
          />
          <span className="code-c-checkbox__checkmark"></span>
          <div className="code-c-checkbox__label__text">{children}</div>
        </label>
        {description && (
          <div className="code-c-checkbox__description">{description}</div>
        )}
        {!hideErrorMessage && <ValidationMessage name={name} error={error} />}
        <ErrorMessage message={errorMessage} />
      </div>
    );
  },
);

Checkbox.displayName = "Checkbox";

export default Checkbox;
