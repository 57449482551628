import * as JoiBase from "joi";
import { FileError } from "react-dropzone";
import { IntlShape } from "react-intl";

import { isMSIE } from "./browserSupport";
import DangerousAnyType from "./dangerousAnyType";
import { dayjs, formatDateTimeMinutes, isValidISODateString } from "./date";
import {
  filenameReservedRegex,
  spaceDotBoundaryRegex,
  windowsReservedNameRegex,
} from "./string";

// Subdomain rules:
// https://en.wikipedia.org/wiki/Domain_name#Technical_requirements_and_process
// a-z
// 0-9
// - but not as a starting or ending character
// . as a separator for the textual portions of a domain name (but we will not allow . for org names)
const subdomainRegex = "^[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$";

// matches givery.com, a-.com, b.com
const prefixDomainRegex =
  "^[A-Za-z0-9][A-Za-z0-9-]{0,62}(\\.[A-Za-z0-9][A-Za-z0-9-]{0,62})+$";

// matches givery.com, -b.com, b.b.b
const suffixDomainRegex =
  "^[A-Za-z0-9-]{0,62}[A-Za-z0-9](\\.[A-Za-z0-9-]{0,62}[A-Za-z0-9])+$";

export interface PasswordOptions {
  min?: number;
  max?: number;
  lowerCase?: number;
  upperCase?: number;
  numeric?: number;
  symbol?: number;
  requirementCount?: number;
}

export interface FileUploadValidator {
  fileNameMap: Record<string, number>;
  maxSize: number;
  maxFiles: number;
}

export const defaultPasswordOptions: PasswordOptions = {
  min: 12,
  lowerCase: 1,
  upperCase: 1,
  numeric: 1,
};
const MAX_FILE_NAME = 980;

const Joi = JoiBase.extend([
  {
    base: JoiBase.string(),
    name: "string",
    rules: [
      {
        name: "notEmpty",
        // Use any here to match Joi type definitions
        validate(
          param: {},
          value: DangerousAnyType,
          state: JoiBase.State,
          options: JoiBase.ValidationOptions,
        ) {
          if (typeof value === "string") {
            if (value.match(/^\s+$/)) {
              return this.createError(
                "string.notEmpty",
                { value },
                state,
                options,
              );
            }
          }
          return value;
        },
      },
      {
        name: "strictEmail",
        // Use any here to match Joi type definitions
        validate(
          param: {},
          value: DangerousAnyType,
          state: JoiBase.State,
          options: JoiBase.ValidationOptions,
        ) {
          if (typeof value === "string") {
            // this regex string comes from jquense/yup validation library
            // See: https://github.com/jquense/yup/blob/master/src/string.ts
            const regexMatched =
              value.match(
                /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i,
              ) !== null;

            // local-part is longer than 64 characters
            const [localPart] = value.split("@");
            const validLength = localPart.length < 64;

            // local-part should not allow unicode characters
            const hasUnicode = localPart.match(/[^\u0000-\u007f]/) !== null;

            if (!regexMatched || !validLength || hasUnicode) {
              return this.createError(
                "string.email",
                { value },
                state,
                options,
              );
            }
          }
          return value;
        },
      },
      {
        name: "subdomain",
        // Use any here to match Joi type definitions
        validate(
          param: {},
          value: DangerousAnyType,
          state: JoiBase.State,
          options: JoiBase.ValidationOptions,
        ) {
          if (typeof value === "string") {
            if (!new RegExp(subdomainRegex).test(value)) {
              return this.createError(
                "string.subdomain",
                { value },
                state,
                options,
              );
            }
          }
          return value;
        },
      },
      {
        name: "domain",
        params: {
          options: JoiBase.object({
            excludeDomains: JoiBase.array(),
          }),
        },
        validate(
          param: {
            options?: { excludeDomains?: string[] };
          },
          value: DangerousAnyType,
          state: JoiBase.State,
          options: JoiBase.ValidationOptions,
        ) {
          if (typeof value !== "string") {
            return value;
          }

          const { excludeDomains = [] } = param.options || {};

          const domains = [
            ...value
              .trim()
              .split("\n")
              .map((val) => val.trim())
              .filter((item) => item),
            ...excludeDomains,
          ];

          const { error: errorObject } = JoiBase.validate(
            domains,
            JoiBase.array().items(JoiBase.string()).unique(),
            options,
          );

          if (!errorObject) {
            // safari does not support lookbehind so implemented
            // prefix and suffix alternative
            const allValidDomains = domains.every(
              (domain) =>
                new RegExp(prefixDomainRegex).test(domain) &&
                new RegExp(suffixDomainRegex).test(domain),
            );

            if (!allValidDomains) {
              return this.createError(
                "string.domain",
                { value },
                state,
                options,
              );
            }
          }

          const errorKey = errorObject?.details.reduce(
            (_, error) => error.type,
            "",
          );

          return errorKey
            ? this.createError(errorKey, { value }, state, options)
            : value;
        },
      },
      {
        name: "password",
        params: {
          options: JoiBase.object({
            min: JoiBase.number(),
            max: JoiBase.number(),
            lowerCase: JoiBase.number(),
            upperCase: JoiBase.number(),
            numeric: JoiBase.number(),
            symbol: JoiBase.number(),
            requirementCount: JoiBase.number(),
          }),
        },
        // Use any here to match Joi type definitions
        validate(
          params: { options: PasswordOptions },
          value: DangerousAnyType,
          state: JoiBase.State,
          options: JoiBase.ValidationOptions,
        ) {
          const passwordOptions = params.options || defaultPasswordOptions;
          if (passwordOptions && !passwordOptions.requirementCount) {
            passwordOptions.requirementCount =
              (passwordOptions.lowerCase && passwordOptions.lowerCase > 0
                ? 1
                : 0) +
              (passwordOptions.upperCase && passwordOptions.upperCase > 0
                ? 1
                : 0) +
              (passwordOptions.numeric && passwordOptions.numeric > 0 ? 1 : 0) +
              (passwordOptions.symbol && passwordOptions.symbol > 0 ? 1 : 0);
          }
          const {
            min,
            max,
            lowerCase,
            upperCase,
            numeric,
            symbol,
            requirementCount = 0,
          } = passwordOptions;

          let validated = 0;
          let matchMin = false;
          let matchMax = false;

          if (typeof value === "string") {
            matchMin = min ? value.length >= min : true;
            matchMax = max ? value.length <= max : true;

            validated +=
              lowerCase && (value.match(/[a-z]/g) || []).length >= lowerCase
                ? 1
                : 0;
            validated +=
              upperCase && (value.match(/[A-Z]/g) || []).length >= upperCase
                ? 1
                : 0;
            validated +=
              numeric && (value.match(/[0-9]/g) || []).length >= numeric
                ? 1
                : 0;
            validated +=
              symbol && (value.match(/[^a-zA-Z0-9]/g) || []).length >= symbol
                ? 1
                : 0;
          }

          if (matchMin && matchMax && validated >= requirementCount) {
            return value;
          }

          return this.createError("string.password", { value }, state, options);
        },
      },
      {
        name: "invalidFreeTextQuestion",
        validate(
          params: {},
          value: DangerousAnyType,
          state: JoiBase.State,
          options: JoiBase.ValidationOptions,
        ) {
          if (typeof value === "string") {
            const hasIllegalChars = value.match(/\${/);

            if (hasIllegalChars) {
              return this.createError(
                "string.invalidFreeTextQuestion",
                { value },
                state,
                options,
              );
            }
          }
          return value;
        },
      },
      {
        name: "emailArray",
        params: {
          options: JoiBase.object({
            min: JoiBase.number(),
            max: JoiBase.number(),
            excludeEmails: JoiBase.array(),
            whitelistDomains: JoiBase.array(),
          }),
        },
        // Use any here to match Joi type definitions
        validate(
          param: {
            options?: {
              min?: number;
              max?: number;
              excludeEmails: string[];
              whitelistDomains?: string[];
            };
          },
          value: DangerousAnyType,
          state: JoiBase.State,
          options: JoiBase.ValidationOptions,
        ) {
          const {
            min = 1,
            max = 100,
            excludeEmails = [],
            whitelistDomains = [],
          } = param.options || {};
          if (typeof value === "string") {
            const emails = [
              ...value
                .trim()
                .split("\n")
                .filter((item) => item),
              ...excludeEmails,
            ];
            const { error: errorObject } = JoiBase.validate(
              emails,
              JoiBase.array()
                .items(JoiBase.string().email())
                .min(min)
                .max(max)
                .unique(),
              options,
            );

            if (!errorObject && whitelistDomains.length) {
              const invalidEmail = emails.find(
                (email) =>
                  !whitelistDomains.some((domain) => email.endsWith(domain)),
              );

              if (invalidEmail) {
                const invalidDomain = invalidEmail.split("@").pop();

                return this.createError(
                  "string.emailDomain",
                  { value: invalidDomain || "" },
                  state,
                  options,
                );
              }
            }

            const errorKey = errorObject?.details.reduce(
              (_, error) => error.type,
              "",
            );

            return errorKey
              ? this.createError(
                  errorKey,
                  { value, limit: max },
                  state,
                  options,
                )
              : value;
          } else {
            return value;
          }
        },
      },
      {
        name: "ipWhitelist",
        params: {
          options: JoiBase.object({
            currentIP: JoiBase.string(),
          }),
        },
        // Use any here to match Joi type definitions
        validate(
          param: {
            options?: { currentIP?: string };
          },
          value: DangerousAnyType,
          state: JoiBase.State,
          options: JoiBase.ValidationOptions,
        ) {
          const { currentIP = "" } = param.options || {};
          const schema = Joi.array()
            .items(
              // HACK: MSIE does not suppurt this valdation rule and crashes here!!
              isMSIE
                ? Joi.string()
                : Joi.string().ip({ version: ["ipv4"], cidr: "optional" }),
            )
            .unique();

          if (typeof value === "string") {
            const ipWhitelist = [
              ...value
                .trim()
                .split("\n")
                .filter((ip) => ip),
            ];

            if (ipWhitelist.length > 0 && !ipWhitelist.includes(currentIP)) {
              return this.createError(
                "string.mustHaveCurrentIP",
                { value },
                state,
                options,
              );
            }

            const { error: errorObject } = JoiBase.validate(
              ipWhitelist,
              schema,
              options,
            );
            const { type: errorKey, value: errorContextValue } =
              errorObject?.details.reduce(
                (_, error) => ({
                  type: error.type,
                  value: error.context?.value,
                }),
                Object.create(null),
              ) || {};
            return errorKey
              ? this.createError(
                  errorKey,
                  { value: errorContextValue },
                  state,
                  options,
                )
              : value;
          } else {
            return value;
          }
        },
      },
      {
        /**
         * If an empty string is passed, the validation won't pass through here
         * for some reason, make sure to pair this with "required" if you also want
         * to validate empty strings
         */
        name: "strRequired",
        params: {
          options: JoiBase.object({
            defaultValues: JoiBase.array(),
          }),
        },
        validate(
          param: {
            options?: { defaultValues?: string[] };
          },
          value: DangerousAnyType,
          state: JoiBase.State,
          options: JoiBase.ValidationOptions,
        ) {
          const { defaultValues = [] } = param.options || {};

          if (
            typeof value === "string" &&
            defaultValues.length &&
            defaultValues.includes(value)
          ) {
            return this.createError(
              "string.required",
              { value },
              state,
              options,
            );
          }

          return value;
        },
      },
      {
        name: "reviewerCount",
        params: {
          options: JoiBase.object({
            reviewerCount: JoiBase.number(),
          }),
        },
        validate(
          param: {
            options?: { reviewerCount: number };
          },
          value: DangerousAnyType,
          state: JoiBase.State,
          options: JoiBase.ValidationOptions,
        ) {
          const { reviewerCount } = param.options || {};

          const requiredReviewerCount = Number(value);

          if (
            typeof reviewerCount === "number" &&
            reviewerCount < requiredReviewerCount
          ) {
            return this.createError(
              "string.reviewerCount",
              { value },
              state,
              options,
            );
          }

          return value;
        },
      },
    ],
  },
  {
    base: JoiBase.date(),
    name: "date",
    rules: [
      {
        name: "notAllowPast",
        // Use any here to match Joi type definitions
        validate(
          param: {},
          value: DangerousAnyType,
          state: JoiBase.State,
          options: JoiBase.ValidationOptions,
        ) {
          if (value instanceof Date) {
            if (dayjs().isAfter(value)) {
              return this.createError(
                "date.notAllowPast",
                { value },
                state,
                options,
              );
            }
          }
          return value;
        },
      },
      {
        name: "min",
        params: {
          min: JoiBase.date(),
        },
        validate(
          params: { min: string },
          value: DangerousAnyType,
          state: JoiBase.State,
          options: JoiBase.ValidationOptions,
        ) {
          if (value instanceof Date) {
            if (dayjs(value).isBefore(dayjs(params.min))) {
              return this.createError(
                "date.min",
                { value, limit: formatDateTimeMinutes(params.min) },
                state,
                options,
              );
            }
          }
          return value;
        },
      },
      {
        name: "max",
        params: {
          max: JoiBase.date(),
        },
        validate(
          params: { max: string },
          value: DangerousAnyType,
          state: JoiBase.State,
          options: JoiBase.ValidationOptions,
        ) {
          if (value instanceof Date) {
            if (dayjs(value).isAfter(dayjs(params.max))) {
              return this.createError(
                "date.max",
                { value, limit: formatDateTimeMinutes(params.max) },
                state,
                options,
              );
            }
          }
          return value;
        },
      },
      {
        name: "greaterThan",
        params: {
          greaterThan: JoiBase.date(),
        },
        validate(
          params: { greaterThan: string },
          value: DangerousAnyType,
          state: JoiBase.State,
          options: JoiBase.ValidationOptions,
        ) {
          if (value instanceof Date) {
            if (dayjs(value).isSameOrBefore(dayjs(params.greaterThan))) {
              return this.createError(
                "date.greaterThan",
                { value, limit: formatDateTimeMinutes(params.greaterThan) },
                state,
                options,
              );
            }
          }
          return value;
        },
      },
      {
        name: "within10YearsFrom",
        params: {
          from: JoiBase.date(),
        },
        validate(
          params: { from: string },
          value: DangerousAnyType,
          state: JoiBase.State,
          options: JoiBase.ValidationOptions,
        ) {
          if (value instanceof Date) {
            if (dayjs(value).isAfter(dayjs(params.from).add(10, "years"))) {
              return this.createError(
                "date.within10YearsFrom",
                { value },
                state,
                options,
              );
            }
          }
          return value;
        },
      },
    ],
  },
  {
    base: JoiBase.array().sparse(),
    name: "sparseArray",
    rules: [
      {
        name: "requiredAll",
        validate(
          params: {},
          value: DangerousAnyType,
          state: JoiBase.State,
          options: JoiBase.ValidationOptions,
        ) {
          if (
            !value.length ||
            value.some(
              (item: DangerousAnyType) =>
                item === undefined || item === null || item === "",
            )
          ) {
            return this.createError("any.required", { value }, state, options);
          }

          return value;
        },
      },
      {
        name: "dateRange",
        validate(
          params: {},
          value: DangerousAnyType,
          state: JoiBase.State,
          options: JoiBase.ValidationOptions,
        ) {
          const [valueDateStart, valueDateEnd] = value;

          if (valueDateStart || valueDateEnd) {
            if (
              (valueDateStart && !isValidISODateString(valueDateStart)) ||
              (valueDateEnd && !isValidISODateString(valueDateEnd))
            ) {
              return this.createError(
                "date.isoDate",
                { value },
                state,
                options,
              );
            }
            if (!valueDateStart || !valueDateEnd) {
              return this.createError(
                "dateRange.incomplete",
                { value },
                state,
                options,
              );
            } else if (!dayjs(valueDateStart).isBefore(dayjs(valueDateEnd))) {
              return this.createError(
                "dateRange.invalid",
                { value },
                state,
                options,
              );
            }
          }

          return value;
        },
      },
      {
        name: "dateRangeMin",
        params: {
          min: JoiBase.date(),
        },
        validate(
          params: { min: string },
          value: DangerousAnyType,
          state: JoiBase.State,
          options: JoiBase.ValidationOptions,
        ) {
          const [valueDateStart, valueDateEnd] = value;
          const invalidStartDate =
            valueDateStart && dayjs(valueDateStart).isBefore(dayjs(params.min));
          const invalidStartEnd =
            valueDateEnd && dayjs(valueDateEnd).isBefore(dayjs(params.min));
          if (invalidStartDate || invalidStartEnd) {
            return this.createError(
              "date.min",
              { valueDateStart, limit: formatDateTimeMinutes(params.min) },
              state,
              options,
            );
          }

          return value;
        },
      },
      {
        name: "dateRangeMax",
        params: {
          max: JoiBase.date(),
        },
        validate(
          params: { max: string },
          value: DangerousAnyType,
          state: JoiBase.State,
          options: JoiBase.ValidationOptions,
        ) {
          const [valueDateStart, valueDateEnd] = value;

          const invalidStartDate =
            valueDateStart && dayjs(valueDateStart).isAfter(dayjs(params.max));
          const invalidStartEnd =
            valueDateEnd && dayjs(valueDateEnd).isAfter(dayjs(params.max));
          if (invalidStartDate || invalidStartEnd) {
            return this.createError(
              "date.max",
              { valueDateStart, limit: formatDateTimeMinutes(params.max) },
              state,
              options,
            );
          }

          return value;
        },
      },
    ],
  },
]);

export function jsonToJoi(validationJson = {}): JoiBase.LazySchema {
  const validationschema = Joi.object().keys(
    Object.keys(validationJson).reduce((validationObject, key) => {
      const jsonRule = validationJson[key];
      let schema;

      if (Array.isArray(jsonRule)) {
        schema = validationJson[key].reduce(
          (
            lazySchema: JoiBase.LazySchema,
            validationRule: string | Array<string | number>,
          ) => {
            if (typeof validationRule === "string") {
              return lazySchema[validationRule]
                ? lazySchema[validationRule]()
                : lazySchema;
            } else if (Array.isArray(validationRule)) {
              // special mapping for referencing other inputs
              const argument =
                validationRule[0] === "match"
                  ? Joi.ref(validationRule[1] as string)
                  : validationRule[1];
              const rule =
                validationRule[0] === "match" ? "equal" : validationRule[0];

              return lazySchema[rule] ? lazySchema[rule](argument) : lazySchema;
            }
          },
          Joi,
        );
      } else if (typeof jsonRule === "object") {
        // Nested groups
        schema = (jsonToJoi(jsonRule) as JoiBase.ObjectSchema)
          .required()
          .or(...Object.keys(jsonRule));
      }
      return Object.assign({}, validationObject, { [key]: schema });
    }, {}),
  );

  return validationschema;
}

export function formatFormValues(validationJson: {}, formValues: {}): {} {
  const replacedValues = Object.keys(validationJson).reduce(
    (valueObject, key) => {
      if (formValues[key] || formValues[key] === 0) {
        valueObject[key] = formValues[key];
      } else if (
        typeof validationJson[key] === "object" &&
        validationJson[key].constructor !== Array
      ) {
        valueObject[key] = formatFormValues(validationJson[key], formValues);
      }

      return valueObject;
    },
    {},
  );
  return replacedValues;
}

/**
 * convert JoiBase.ValidationError to pure key-value object and only pick items that blurred is true.
 * return undefined, if there are no errors.
 * @param error
 * @param hasBlurred
 * @param showAllErrors
 * @param showError
 */
export function getValidationError(
  error: JoiBase.ValidationError,
  hasBlurred: {},
  showAllErrors?: boolean,
  showError?: {},
) {
  const isBlurred = (value: string) =>
    showAllErrors ||
    (hasBlurred && hasBlurred[value]) ||
    (showError && showError[value]);
  return error
    ? error.details.reduce(
        (errorsObject, { context = {}, message }) =>
          (context.peers &&
            context.peers.every((peer: string) => !isBlurred(peer))) ||
          !isBlurred(context.key as string)
            ? errorsObject
            : {
                ...errorsObject,
                [context.key as string]: message,
              },
        {},
      )
    : undefined;
}

/**
 * Return validate options
 * @param intl
 */
export function getValidationOptions(intl: IntlShape) {
  // see this file to add a new custom message.
  // https://github.com/hapijs/joi/blob/v12-commercial/lib/language.js
  return {
    abortEarly: false,
    allowUnknown: true,
    language: {
      any: {
        allowOnly: `!!${
          intl.formatMessage({
            id: "validation.passwordsDoNotMatch",
          }) || "does not match"
        }`,
        empty: `!!${
          intl.formatMessage({
            id: "validation.required",
          }) || "required"
        }`,
        required: `!!${
          intl.formatMessage({
            id: "validation.required",
          }) || "required"
        }`,
      },
      string: {
        email: `!!${
          intl.formatMessage(
            { id: "validation.invalidEmail" },
            { "0": "{{!value}}" },
          ) || "invalid email"
        }`,
        emailDomain: `!!${
          intl.formatMessage(
            { id: "validation.invalidEmailDomain" },
            { "0": "{{!value}}" },
          ) || "invalid email domain"
        }`,
        min: `!!${
          intl.formatMessage({ id: "validation.min" }, { "0": "{{!limit}}" }) ||
          "too short"
        }`,
        max: `!!${
          intl.formatMessage({ id: "validation.max" }, { "0": "{{!limit}}" }) ||
          "too large"
        }`,
        password: `!!${
          intl.formatMessage({
            id: "validation.passwordRequirements",
          }) || "password doesn't meet requirements"
        }`,
        notEmpty: `!!${
          intl.formatMessage({
            id: "validation.notEmpty",
          }) || "not allowed to be empty"
        }`,
        domain: `!!${
          intl.formatMessage({
            id: "validation.domain",
          }) || "invalid domain"
        }`,
        subdomain: `!!${
          intl.formatMessage({
            id: "validation.subdomain",
          }) || "invalid sub-domain"
        }`,
        invalidFreeTextQuestion: `!!${
          intl.formatMessage({
            id: "validation.invalidFreeTextQuestion",
          }) || "invalid question text"
        }`,
        ipVersion: `!!${
          intl.formatMessage(
            { id: "validation.invalidIP" },
            { "0": "{{!value}}" },
          ) || "invalid IP"
        }`,
        mustHaveCurrentIP: `!!${
          intl.formatMessage({
            id: "validation.ipWhitelist.currentIP.missing",
          }) || "must have Current IP"
        }`,
        required: `!!${
          intl.formatMessage({
            id: "validation.required",
          }) || "required"
        }`,
        reviewerCount: `!!${
          intl.formatMessage({
            id: "validation.reviewerCount",
          }) || "Must be valid reviewer count"
        }`,
      },
      date: {
        base: `!!${intl.formatMessage({ id: "validation.date" }, { "0": "" })}`,
        strict: `!!${intl.formatMessage(
          { id: "validation.date" },
          { "0": "" },
        )}`,
        isoDate: `!!${intl.formatMessage(
          { id: "validation.date" },
          { "0": "" },
        )}`,
        min: `!!${intl.formatMessage(
          { id: "validation.date.min" },
          { "0": "{{!limit}}" },
        )}`,
        max: `!!${intl.formatMessage(
          { id: "validation.date.max" },
          { "0": "{{!limit}}" },
        )}`,
        greaterThan: `!!${intl.formatMessage(
          { id: "validation.date.greaterThan" },
          { "0": "{{!limit}}" },
        )}`,
        notAllowPast: `!!${intl.formatMessage(
          { id: "validation.date.notAllowPast" },
          { "0": "" },
        )}`,
        within10YearsFrom: `!!${intl.formatMessage(
          { id: "validation.date.within10YearsFrom" },
          { "0": "" },
        )}`,
      },
      number: {
        base: `!!${intl.formatMessage({
          id: "validation.number",
        })}`,
        greater: `!!${
          intl.formatMessage(
            { id: "validation.number.greater" },
            { "0": "{{!limit}}" },
          ) || "too short"
        }`,
        less: `!!${
          intl.formatMessage(
            { id: "validation.number.less" },
            { "0": "{{!limit}}" },
          ) || "too large"
        }`,
        min: `!!${
          intl.formatMessage(
            { id: "validation.number.min" },
            { "0": "{{!limit}}" },
          ) || "too short"
        }`,
        max: `!!${
          intl.formatMessage(
            { id: "validation.number.max" },
            { "0": "{{!limit}}" },
          ) || "too large"
        }`,
        integer: `!!${intl.formatMessage({
          id: "validation.number.integer",
        })}`,
      },
      object: {
        missing: `!!${
          intl.formatMessage(
            { id: "validation.object.missing" },
            { "0": "" },
          ) || "must contain at least one of those"
        }`,
      },
      array: {
        min: `!!${
          intl.formatMessage(
            { id: "validation.array.min" },
            { "0": "{{!limit}}" },
          ) || "too short"
        }`,
        max: `!!${
          intl.formatMessage(
            { id: "validation.array.max" },
            { "0": "{{!limit}}" },
          ) || "too large"
        }`,
        unique: `!!${
          intl.formatMessage({ id: "validation.options.noDuplicate" }) ||
          "duplicated"
        }`,
      },
      dateRange: {
        incomplete: `!!${
          intl.formatMessage({
            id: "validation.dateRange.incomplete",
          }) || "incomplete"
        }`,
        invalid: `!!${
          intl.formatMessage({
            id: "validation.dateRange.invalid",
          }) || "incorrect order"
        }`,
        required: `!!${
          intl.formatMessage({
            id: "validation.required",
          }) || "required"
        }`,
      },
    },
  };
}

export function fileUploadValidator({
  fileNameMap,
  maxFiles,
  maxSize,
}: FileUploadValidator) {
  return <T extends File>(file: T): FileError | FileError[] | null => {
    if (
      file.name.length > MAX_FILE_NAME ||
      spaceDotBoundaryRegex.test(file.name) ||
      filenameReservedRegex.test(file.name) ||
      windowsReservedNameRegex.test(file.name)
    ) {
      return {
        code: "invalidFileName",
        message: "uploadFilesModal.invalidFileName",
      };
    }
    // Check if file name is duplicated
    if (fileNameMap[file.name]) {
      if (maxSize + fileNameMap[file.name] < file.size) {
        return {
          code: "fileTooLarge",
          message: "uploadFilesModal.exceedsLimit",
        };
      }

      maxFiles += 1;
      maxSize += fileNameMap[file.name];
      fileNameMap[file.name] = file.size;
    }
    // Check if new file size is larger than the remaining size
    if (maxSize < file.size) {
      return {
        code: "fileTooLarge",
        message: "uploadFilesModal.exceedsLimit",
      };
    }
    // Check if there is available slot for new file
    if (maxFiles < 1) {
      return {
        code: "tooManyFiles",
        message: "uploadFilesModal.exceedsCount",
      };
    }

    // When upload file as batch, the remainingSize won't be updated
    maxSize -= file.size;
    maxFiles -= 1;

    return null;
  };
}
