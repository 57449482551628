import * as React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";

import {
  PageTitle,
  FormGroup,
  Form,
  Label,
  Input,
  Button,
  ValidationMessage,
  Msg,
} from "@shared/components";
import InitialData from "@shared/services/initialData";
import Message from "@shared/services/message";
import { parseUrl } from "@shared/services/queryString";
import { getSignInURL } from "@shared/services/url";

export type SigninProps = {
  isSystemAdmin?: boolean;
} & RouteComponentProps;

type SigninForm = {
  orgname: string;
};

/**
 * Page component
 */
const Signin = ({ isSystemAdmin, location }: SigninProps) => {
  const { code: errorCode = "" } = parseUrl(window.location.search);

  const { from } =
    (location.state as { from?: { pathname: string; search: string } }) ?? {};

  const [form, setForm] = React.useState<{
    formValid?: boolean;
    formValues: SigninForm;
  }>({ formValues: { orgname: InitialData.orgName ?? "" } });

  const [loading, setLoading] = React.useState(false);

  const orgnameEditable =
    !form.formValues.orgname || process.env.REACT_APP_ENV === "local";

  const onFormChange = (
    formValid: boolean,
    formValues: SigninForm,
    formErrors?: {},
  ) => {
    setForm({ formValues, formValid: formValid || !Boolean(formErrors) });
  };

  const onSignIn = () => {
    setLoading(true);

    window.location.href = getSignInURL(form.formValues.orgname, {
      isSystemAdmin,
      force: Boolean(errorCode),
      onsuccess: from ? `${from.pathname}${from.search}` : "",
    });
  };

  return (
    <div className="code-signin">
      <PageTitle>
        <Msg id={isSystemAdmin ? "signin.systemAdmin" : "signin"} />
      </PageTitle>
      <Form
        validation={{
          orgname: ["string", "required"],
        }}
        initialValues={{
          orgname: form.formValues.orgname,
        }}
        onFormChange={onFormChange}
        autoComplete={true}
      >
        <FormGroup>
          <Label htmlFor="orgname">
            <Msg id="form.organizationName" />
          </Label>
          {orgnameEditable ? (
            <Input id="orgname" name="orgname" />
          ) : (
            <Label>{form.formValues.orgname}</Label>
          )}
        </FormGroup>
        <FormGroup>
          <ValidationMessage
            name={"signin"}
            error={{
              signin: errorCode
                ? Message.getMessageByKey(
                    `message.loginFailed${errorCode ?? ""}`,
                  )
                : "",
            }}
          />
        </FormGroup>
        <FormGroup>
          <Button
            type="primary"
            ariaLabel={isSystemAdmin ? "Sign In as SystemAdmin" : "Sign In"}
            onClick={() => onSignIn()}
            disabled={!form.formValid}
            loading={loading}
          >
            <Msg id={isSystemAdmin ? "signin.systemAdmin" : "signin"} />
          </Button>
        </FormGroup>
      </Form>
    </div>
  );
};

export default withRouter(Signin);
