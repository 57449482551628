import { connect } from "react-redux";

import { RootState } from "../../../../../../../reducers";
import { SampleDeliveryID, ExternalProps } from "./SampleDeliveryID";

const mapStateToProps = (state: RootState, props: ExternalProps) => ({
  currentUser: state.user.user,
  ...props,
});

export default connect(mapStateToProps, {})(SampleDeliveryID);
