import { ChallengeStyle } from "@shared/services/enums";

class ApplicantEditorContextModel {
  // if it is true, the challenge editor has unsaved contents.
  public hasUnsaved = false;
  public editorReady = false;
  public contextReady = false;
  public challengeSubmitting = false;
  public challengeSubmitted = false;
  public challengeStyle: ChallengeStyle;
  public lastSavedAt = "";
  public lastTestRanAt = "";

  constructor(args?: {}) {
    Object.assign(this, args);
  }
}

export default ApplicantEditorContextModel;
