import { Provider } from "@rollbar/react";
import * as React from "react";

import errorTracking from "@shared/services/errorTracking";

const hasToken = process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN;

const rollbarParams = {
  instance: hasToken ? errorTracking.rollbar : undefined,
  config: hasToken ? undefined : {},
};

export const RollbarProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return <Provider {...rollbarParams}>{children}</Provider>;
};
