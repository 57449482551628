import * as classnames from "classnames";
import * as React from "react";

import { useOnHidden } from "@shared/hooks";
import {
  QuestionModel,
  QuestionFIBModel,
  QuestionMCQModel,
  QuestionFreeTextModel,
} from "@shared/models";
import { QuestionType, SpokenLanguages } from "@shared/services/enums";

import { Msg, Markdown, Icon } from "../..";
import { SubmissionDetailQuestionFreeText } from "./partials";

/**
 * Prop interface
 */
export interface SubmissionDetailQuestionProps {
  question: QuestionModel;
  questionNumber: number;
  className?: string;
  language?: SpokenLanguages;
}

/**
 * React Component
 */
export const SubmissionDetailQuestion: React.FunctionComponent<
  SubmissionDetailQuestionProps
> = (props: SubmissionDetailQuestionProps) => {
  const { question, questionNumber, language, className } = props;

  /**
   * State
   */
  const targetRef = React.useRef<HTMLDivElement | null>(null);

  /**
   * Effects
   */

  /**
   * Private Functions
   */
  const isWrong = !Boolean(question?.userAnswer.score);

  const onBehind = useOnHidden<HTMLDivElement>(targetRef, {
    threshold: [1],
    // hard coded!! be careful if you change the layout.
    rootMargin: "-320px 0px 0px 0px",
  });

  const rootStyle = classnames("code-c-submission-detail-question", {
    "is-wrong": isWrong,
    "is-correct": !isWrong,
    "is-behind": onBehind,
    [`${className}`]: Boolean(className),
  });

  const getStatusIcon = (correct: boolean) => {
    const statusIconStyle = classnames(
      "code-c-submission-detail-question__status-icon",
      {
        "is-wrong": !correct,
        "is-correct": correct,
      },
    );
    return (
      <Icon type={correct ? "check" : "times"} className={statusIconStyle} />
    );
  };

  const buildElement = () => {
    switch (question.kind) {
      case QuestionType.MCQ: {
        const mcq = new QuestionMCQModel(question);
        return {
          prompt: <Markdown mode={mcq.kind} body={mcq.getQuestionText()} />,
          answers: (
            <div>
              <div className="code-c-submission-detail-question__table-header question-mcq-table-head">
                <div />
                <div className="align-center">
                  <Msg id="submission.quiz.expected" />
                </div>
                <div className="align-center">
                  <Msg id="submission.quiz.submittedAnswer" />
                </div>
                <div />
              </div>
              <ol type="A">
                {mcq.getChoices().map((choice, index) => {
                  const showCorrect = !isWrong && mcq.isCorrect(index);
                  const showWrong = isWrong && !mcq.isCorrect(index);
                  const choiceStyle = classnames(
                    "code-c-submission-detail-question__table-col question-mcq-table-body1",
                    {
                      "is-correct": showCorrect,
                    },
                  );
                  return (
                    <div key={`mcq-${index}`} className={choiceStyle}>
                      <div>{showWrong && getStatusIcon(false)}</div>
                      <li className="code-c-submission-detail-question__choice">
                        <div className="question-mcq-table-body2">
                          <div className="align-center">
                            <Icon
                              type={
                                mcq.isCorrectAnswer(index)
                                  ? "check-square"
                                  : "square-o"
                              }
                            />
                          </div>
                          <div className="align-center">
                            <Icon
                              type={
                                mcq.isUserAnswer(index)
                                  ? "check-square"
                                  : "square-o"
                              }
                            />
                          </div>
                          <div className="code-c-submission-detail-question__choice__choice">
                            <Markdown body={choice} />
                          </div>
                        </div>
                      </li>
                    </div>
                  );
                })}
              </ol>
            </div>
          ),
          howToSolve: question.howToSolve && (
            <Markdown mode={mcq.kind} body={question.howToSolve} />
          ),
        };
      }
      case QuestionType.FIB: {
        const fib = new QuestionFIBModel(question);
        return {
          prompt: (
            <Markdown
              mode={fib.kind}
              body={fib.getQuestionText()}
              answers={fib.getCorrectAnswer(false)}
            />
          ),
          answers: (
            <div>
              <div className="code-c-submission-detail-question__table-header question-fib-table">
                <div />
                <div />
                <div>
                  <Msg id="submission.quiz.expected" />
                </div>
                <div>
                  <Msg id="submission.quiz.submittedAnswer" />
                </div>
              </div>
              {fib.getCorrectAnswer().map((answer, index) => (
                <div
                  className="code-c-submission-detail-question__table-col question-fib-table"
                  key={`fbi-${index}`}
                >
                  <div>
                    {isWrong
                      ? fib.isCorrectAnswer(index)
                        ? null
                        : getStatusIcon(false)
                      : getStatusIcon(true)}
                  </div>
                  <div>
                    <div className="code-c-submission-detail-question__dummy-blank">
                      {index + 1}
                    </div>
                  </div>
                  <div className="is-wrap">{answer}</div>
                  <div className="is-wrap">{fib.getUserAnswers()[index]}</div>
                </div>
              ))}
            </div>
          ),
          howToSolve: question.howToSolve && (
            <Markdown mode={fib.kind} body={question.howToSolve} />
          ),
        };
      }
      case QuestionType.FreeText: {
        const freetext = new QuestionFreeTextModel(question);

        return {
          prompt: (
            <Markdown mode={freetext.kind} body={freetext.getQuestionText()} />
          ),
          answers: (
            <SubmissionDetailQuestionFreeText
              userAnswer={freetext.getUserAnswer()}
              language={language}
              icon={getStatusIcon(freetext.isCorrect())}
            />
          ),
          howToSolve: question.howToSolve && (
            <Markdown mode={freetext.kind} body={question.howToSolve} />
          ),
        };
      }
      default: {
        return {
          prompt: null,
          answers: <div>No answer</div>,
          howToSolve: null,
        };
      }
    }
  };

  const { prompt, answers, howToSolve } = buildElement();

  return (
    <div className={rootStyle}>
      <div className="code-c-submission-detail-question__header is-wrap">
        <div className="code-c-submission-detail-question__status">
          <Icon type={isWrong ? "times" : "check"} />
          <div className="code-c-submission-detail-question__title">
            <Msg
              id="submission.quiz.q"
              values={{ number: questionNumber, title: question.title }}
            />
          </div>
        </div>
      </div>
      <div className="code-c-submission-detail-question__body">
        <p
          className="code-c-submission-detail-question__sub-title"
          ref={targetRef}
        >
          <Msg id="submission.quiz.question" />
        </p>
        <div className="code-c-submission-detail-question__question">
          {prompt}
        </div>
        <p className="code-c-submission-detail-question__sub-title">
          <Msg id="common.answer" />
        </p>
        {answers}
        {howToSolve && (
          <>
            <p className="code-c-submission-detail-question__sub-title">
              <Msg id="common.explanation" />
            </p>
            <div>{howToSolve}</div>
          </>
        )}
      </div>
    </div>
  );
};
