import * as c3 from "c3";
import "c3/c3.css";
import classnames from "classnames";
import * as d3 from "d3";
import { debounce } from "lodash";
import * as React from "react";
import shortid from "shortid";

import {
  calculateChartTicks,
  getLogarithmicTicks,
} from "@shared/services/chart";
import {
  getLogInterpolation,
  getLogarithmicScale,
  getReverseLogInterpolation,
} from "@shared/services/math";
import Message from "@shared/services/message";

import {
  ChartBarLabel,
  ChartBarLabelProps,
} from "../chartBarLabel/ChartBarLabel";
import { ChartMarker, ChartMarkerProps } from "../chartMarker/ChartMarker";

export interface StackedBarChartItem {
  id?: number;
  label: string;
  data: number[];
}

export interface StackedBarChartProps {
  className?: string;
  title?: string;
  items?: StackedBarChartItem[];
  groups?: string[][];
  labelColor?: string;
  xTicks?: string[];
  xLabel?: string;
  yLabel?: string;
  colors?: { [id: string]: string };
  theme?: "default" | "analysis" | "submission-score-summary";
  options?: {
    padding?: c3.Padding;
    tooltip?: c3.TooltipOptions;
    xLabelHeight?: number;
    legendPositionTopRight?: boolean;
    hideLegend?: boolean;
    /**
     * In the case that the built-in dynamic resizing doesn't work, try this option
     * Resizes the chart to the parent div width
     */
    parentWidthResizing?: boolean;
    subChart?: {
      show?: boolean;
      defaultXAxisExtent?: number[];
    };
    xTick?: c3.XTickConfiguration | undefined;
    data?: c3.Data;
    bar?: c3.ChartConfiguration["bar"];
    yAxisPadding?: c3.Padding;
    height?: number;
    extraXGrids?: c3.GridLineOptionsWithAxis[];
    maxYTicks?: number;
    logarithmicYTicks?: boolean;
    hideTooltipColorIcon?: boolean;
  };
  chartMarkerProps?: Omit<ChartMarkerProps, "id" | "chart">;
  chartBarLabelProps?: Omit<ChartBarLabelProps, "id" | "chart">;
}

const StackedBarChart = ({
  title = "",
  items,
  groups,
  labelColor,
  xTicks,
  yLabel,
  xLabel,
  className,
  colors,
  theme = "default",
  options,
  chartMarkerProps,
  chartBarLabelProps,
}: StackedBarChartProps) => {
  const [id] = React.useState(`chart${shortid.generate()}`);
  const rootRef = React.useRef<HTMLDivElement>(null);
  const [chart, setChart] = React.useState<c3.ChartAPI>();

  const rootStyle = classnames("code-c-stacked-bar-chart", {
    [`${className}`]: Boolean(className),
    "code-c-stacked-bar-chart__analysis": theme === "analysis",
    "code-c-stacked-bar-chart__submission-score-summary":
      theme === "submission-score-summary",
    "code-c-stacked-bar-chart__tooltip_no-color-icon":
      options?.hideTooltipColorIcon,
  });

  React.useEffect(() => {
    const isLogarithmic = options?.logarithmicYTicks ?? false;

    let maxValue = Math.max(...(items?.map((item) => item.data).flat() ?? []));

    if (isLogarithmic) {
      maxValue = getLogarithmicScale(maxValue);
    } else {
      maxValue = maxValue > 10 ? maxValue : 10;

      // Math.ceil function rounds the number to the higher 10... 5 => 10, 11 => 20
      maxValue = Math.ceil(maxValue / 10) * 10;
    }

    const yTicks = isLogarithmic
      ? getLogarithmicTicks(maxValue) // [0, 1, 2, 3] (These values will be used in Math.pow())
      : calculateChartTicks({
          minPoint: 0,
          maxPoint: maxValue,
          maxTicks: options?.maxYTicks ?? 10,
        });

    const maxYTick = yTicks.reduce(
      (maxValue, item) => Math.max(maxValue, item),
      0,
    );

    // adjust left padding based on max number on y ticks
    // the chart doesnt auto adjust which causes the label to clip on the left edge
    const leftPaddingAdjustment = maxYTick.toString().length * 5.5;

    const config: c3.ChartConfiguration = {
      bindto: `#${id}`,
      data: {
        type: "bar",
        columns: isLogarithmic
          ? items?.map((item) => [
              item.label,
              ...item.data.map((dataItem) => getLogInterpolation(dataItem)),
            ]) ?? []
          : items?.map((item) => [item.label, ...item.data]) ?? [],
        groups: groups ?? [],
        colors,
        ...options?.data,
      },
      bar: Object.assign(
        {
          width: {
            ratio: 0.5,
          },
          ...(options?.tooltip && {
            tooltip: options?.tooltip,
          }),
        },
        options?.bar,
      ),
      grid: {
        y: {
          show: true,
        },
      },
      legend: {
        show: !options?.legendPositionTopRight && !options?.hideLegend,
      },
      padding: {
        // to prevent labels from getting clipped on the
        // edges of the element
        // increase this value accordingly
        right: 35,
        top: 15,
        bottom: 10,
        ...options?.padding,
        left: yLabel
          ? (options?.padding?.left ?? 35) + leftPaddingAdjustment
          : options?.padding?.left,
      },
      axis: {
        y: {
          max: Math.max(...yTicks, maxValue),
          tick: {
            values: yTicks,
            ...(isLogarithmic && {
              format: function (d: number) {
                if (d === 0) return "0";
                if (d === 0.1) return "1";

                return getReverseLogInterpolation(d).toFixed(0);
              },
            }),
          },
          ...(options?.yAxisPadding && {
            padding: options?.yAxisPadding,
          }),
          ...(yLabel && {
            label: {
              text: yLabel,
              position: "outer-middle",
            },
          }),
        },
        x: {
          tick: {
            format: (x: number) => {
              if (xTicks) {
                return xTicks?.[x] ?? "";
              }

              return x;
            },
            fit: false,
            culling: false,
            rotate: options?.xTick?.rotate ?? 40,
            multiline: false,
            outer: options?.xTick?.outer ?? true,
            width: 38,
          },
          ...(options?.xLabelHeight && { height: options?.xLabelHeight }),
          ...(xLabel && {
            label: {
              text: xLabel,
              position: "outer-center",
            },
          }),
          extent: options?.subChart?.defaultXAxisExtent,
        },
      },
      ...(["analysis", "submission-score-summary"].includes(theme) && {
        tooltip: {
          format: {
            title: (x: number) => {
              return `${Message.getMessageByKey("common.score")} ${
                xTicks?.[x] ?? x
              }%`;
            },
            name: () => Message.getMessageByKey("question.submissionCount"),
          },
        },
      }),
      ...(options?.subChart?.show && {
        subchart: { show: true, size: { height: 30 } },
      }),
      ...(options?.height && { size: { height: options.height } }),
    };

    const newChart = c3.generate(config);

    if (options?.extraXGrids) {
      newChart.xgrids(
        options.extraXGrids.map((item) => ({
          ...item,
          class: "code-c-stacked-bar-chart__region-line-x",
        })),
      );
    }

    // make resize bar on the sub chart looks more fancy
    if (options?.subChart?.show) {
      d3.select(`#${id}`)
        .select("svg")
        .select(".c3-brush")
        .select(".background")
        .attr("rx", 4);

      d3.select(`#${id}`)
        .select("svg")
        .select(".c3-brush")
        .selectAll(".resize")
        .select("rect")
        .attr("width", 3);
    }

    setChart(newChart);
  }, [
    id,
    title,
    items,
    groups,
    xTicks,
    colors,
    options,
    yLabel,
    xLabel,
    theme,
    labelColor,
  ]);

  // for creating custom legend since position top-right doesn't exist in c3
  React.useEffect(() => {
    if (!chart || !options?.legendPositionTopRight) {
      return;
    }

    d3.select(`.${id}.code-c-stacked-bar-chart__legend`)
      .selectAll("*")
      .remove();

    d3.select(`#${id}`)
      .insert("div", ":first-child")
      .attr("class", `${id} code-c-stacked-bar-chart__legend`)
      .selectAll("span")
      .data(groups?.flat() ?? [])
      .enter()
      .append("span")
      .attr("class", (id) => `code-c-stacked-bar-chart__legend__label`)
      .attr("data-id", (id) => id)
      .html(function (id) {
        const color = chart.color(id);
        const style = `background-color: ${color};`;

        return `
          <span class="code-c-stacked-bar-chart__legend__color" style="${style}"></span>
          <span>${id}</span>
        `;
      })
      .on("mouseover", function (_: PointerEvent, id) {
        let shouldFocus = true;
        if (this) {
          shouldFocus = !Array.from(this.classList).includes(
            "code-c-stacked-bar-chart__legend__toggled",
          );
        }

        if (shouldFocus) {
          chart.focus(id);
        }
      })
      .on("mouseout", function () {
        chart.revert();
      })
      .on("click", function (_: PointerEvent, id) {
        d3.select(this).attr(
          "class",
          (_, _value: number, elements: HTMLDivElement[]) => {
            const el = elements?.[0];
            if (!el?.classList) {
              return el?.className || "";
            }

            const classArr = Array.from(el?.classList);
            const toggleClass = "code-c-stacked-bar-chart__legend__toggled";
            const isToggled = classArr.includes(toggleClass);

            if (isToggled) {
              chart.defocus(id);
              classArr.pop();
              return classArr.join(" ");
            }

            return [...classArr, toggleClass].join(" ");
          },
        );

        chart.toggle(id);
      });
  }, [chart, id, groups, colors, options?.legendPositionTopRight]);

  React.useEffect(() => {
    const debouncedResize = debounce(() => {
      const parentWidth = rootRef.current?.parentElement?.clientWidth;
      chart?.resize(
        options?.parentWidthResizing && parentWidth !== undefined
          ? { width: parentWidth }
          : undefined,
      );
    }, 500);

    window.addEventListener("resize", debouncedResize);

    return () => {
      window.removeEventListener("resize", debouncedResize);
    };
  }, [chart, options?.parentWidthResizing]);

  return (
    <div className={rootStyle} id={id} ref={rootRef}>
      {chart && chartMarkerProps && (
        <ChartMarker {...chartMarkerProps} id={id} chart={chart} />
      )}
      {chart && chartBarLabelProps && (
        <ChartBarLabel {...chartBarLabelProps} id={id} chart={chart} />
      )}
    </div>
  );
};

export default StackedBarChart;
