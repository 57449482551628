import { useQuery } from "@tanstack/react-query";
import { startCase } from "lodash";
import { useCallback, useMemo } from "react";

import {
  EnumWithStringValueModel,
  getApplicantActions,
  getExamJobTitles,
  getInvitationStatus,
  getOrgMainCategories,
  getOrgSubCategories,
  getPurposesOfUse,
  getSegmentApplicantExperiences,
  getSkippedFileScanReasons,
  getFileScanningStatuses,
  getGuestReportCategoryEnums,
} from "@api/enum";

import { EnumModel } from "@shared/models";
import {
  InvitationStatus,
  JobTitles,
  PurposesOfUse,
  toDic,
  WebcamActionType,
} from "@shared/services/enums";
import Message from "@shared/services/message";

import { useFeatureFlags } from "./app/useFeatureFlags";

export const enumKeys = {
  all: ["enum"] as const,
  applicantActionAll: () => [...enumKeys.all, "applicantActions"] as const,
  invitationStatusAll: () => [...enumKeys.all, "invitationStatuses"] as const,
  examJobTitleAll: () => [...enumKeys.all, "examJobTitles"] as const,
  purposeOfUseAll: () => [...enumKeys.all, "purposesOfUse"] as const,
  segmentApplicantExperienceAll: () =>
    [...enumKeys.all, "segmentApplicantExperiences"] as const,
  orgMainCategoryAll: () => [...enumKeys.all, "orgMainCategory"] as const,
  orgSubCategoryAll: () => [...enumKeys.all, "orgSubCategory"] as const,
  fileScanningStatusAll: () =>
    [...enumKeys.all, "fileScanningStatuses"] as const,
  skippedFileScanReasons: () =>
    [...enumKeys.all, "skippedFileScanReasons"] as const,
  guestCategoryEnums: () => [...enumKeys.all, "guestCategoryEnums"] as const,
};

export function useGetApplicantActionEnums() {
  const { data: featureFlags } = useFeatureFlags();
  const webcamEnabled = featureFlags?.webcamEnabled;
  const query = useQuery<EnumWithStringValueModel[], Error>({
    queryKey: [...enumKeys.applicantActionAll(), webcamEnabled],
    queryFn: async ({ signal }) => {
      const { result } = await getApplicantActions({ signal });

      if (webcamEnabled) {
        // Todo: remove this when webcam action logs are added to backend
        result.push(
          {
            value: WebcamActionType.WebcamAppeal,
            displayOrder: 5,
            messageKey: "",
            displayString: "Webcam Appeal",
          },
          {
            value: WebcamActionType.WebcamImpersonationDetected,
            displayOrder: 6,
            messageKey:
              "submission.actionLog.webcamImpersonationDetected.label",
            displayString: "",
          },
          {
            value: WebcamActionType.WebcamMultiplePeopleDetected,
            displayOrder: 7,
            messageKey:
              "submission.actionLog.webcamMultiplePeopleDetected.label",
            displayString: "",
          },
          {
            value: WebcamActionType.FaceVerificationResult,
            displayOrder: 8,
            messageKey: "submission.actionLog.faceVerificationResult",
            displayString: "",
          },
          {
            value: WebcamActionType.ExternalDeviceUsageDetected,
            displayOrder: 9,
            messageKey: "submission.actionLog.externalDeviceUsage.label",
            displayString: "",
          },
        );
      }
      return result.sort((a, b) => a.displayOrder - b.displayOrder);
    },
    keepPreviousData: true,
  });

  return query;
}

function useGetInvitationStatusEnums<T>(
  select?: (data: EnumWithStringValueModel[]) => T,
) {
  const query = useQuery({
    queryKey: enumKeys.invitationStatusAll(),
    queryFn: async ({ signal }) => {
      const { result } = await getInvitationStatus({ signal });

      return result;
    },
    placeholderData: [],
    select,
  });

  return query;
}

export function useInvitationStatus() {
  const { data } = useGetInvitationStatusEnums(
    useCallback(
      (data: EnumWithStringValueModel[]) =>
        data.reduce(
          (acum, { displayString, value }) =>
            Object.assign(acum, { [value]: displayString }),
          {} as Record<InvitationStatus, string>,
        ),
      [],
    ),
  );

  return data;
}
const useGetExamJobTitleEnums = <T>(select: (data: EnumModel[]) => T) => {
  const query = useQuery({
    queryKey: enumKeys.examJobTitleAll(),
    queryFn: async ({ signal }) => {
      const { result } = await getExamJobTitles({ signal });
      return result;
    },
    placeholderData: [],
    select,
  });

  return query;
};

export function useGetApplicantSegmentExperienceEnums() {
  const query = useQuery<EnumWithStringValueModel[], Error>({
    queryKey: enumKeys.segmentApplicantExperienceAll(),
    queryFn: async ({ signal }) => {
      const { result } = await getSegmentApplicantExperiences({ signal });

      return result;
    },
    placeholderData: [],
    select: (data) => data.sort((a, b) => a.displayOrder - b.displayOrder),
  });

  return query;
}

export function useApplicantSegmentExperience() {
  const { data = [] } = useGetApplicantSegmentExperienceEnums();

  const applicantSegmentExperience = useMemo(
    () =>
      data.reduce(
        (acum, { displayString, value }) =>
          Object.assign(acum, { [value]: displayString }),
        {} as Record<string, string>,
      ),
    [data],
  );

  return applicantSegmentExperience;
}

export const useExamJobTitleOptions = () => {
  const query = useGetExamJobTitleEnums(
    useCallback(
      (data: EnumModel[]) =>
        [...data]
          .sort((a, b) => a.displayOrder - b.displayOrder)
          .map((item) => ({
            label: item.displayString,
            value: item.value,
          })),
      [],
    ),
  );

  return query;
};

export const useExamJobTitleMap = () => {
  const query = useGetExamJobTitleEnums(
    useCallback(
      (data: EnumModel[]) =>
        data.reduce(
          (acum, { displayString, value }) => {
            acum[value] =
              ([JobTitles.NotDefined, JobTitles.Other].includes(value)
                ? startCase(Message.getMessageByKey("exam.filter.jobTitle")) +
                  ": "
                : "") + displayString;
            return acum;
          },
          {} as Record<string, string>,
        ),
      [],
    ),
  );

  return query;
};

const useGetPurposeOfUseEnums = <T>(
  select: (data: EnumWithStringValueModel[]) => T,
) => {
  const query = useQuery({
    queryKey: enumKeys.purposeOfUseAll(),
    queryFn: async ({ signal }) => {
      const { result } = await getPurposesOfUse({ signal });
      return result;
    },
    placeholderData: [],
    select,
  });

  return query;
};

export const usePurposeOfUseOptions = (isExcludeDisabled?: boolean) => {
  const query = useGetPurposeOfUseEnums(
    useCallback(
      (data: EnumWithStringValueModel[]) =>
        [...data]
          .sort((a, b) => a.displayOrder - b.displayOrder)
          .map((item) => ({
            label: item.displayString,
            value: item.value,
            disabled: isExcludeDisabled ? undefined : !item.uiSelectable,
          })),
      [isExcludeDisabled],
    ),
  );

  return query;
};

export const useOrgCategoryEnums = () => {
  const { data } = useQuery<EnumWithStringValueModel[]>({
    queryKey: enumKeys.orgMainCategoryAll(),
    queryFn: async ({ signal }) => {
      const { result } = await getOrgMainCategories({ signal });

      return result;
    },
    placeholderData: [],
  });

  return data;
};

export const usePurposeOfUseMap = () => {
  const query = useGetPurposeOfUseEnums(
    useCallback(
      (data: EnumWithStringValueModel[]) =>
        data.reduce(
          (acum, { displayString, value }) => {
            acum[value] =
              ((
                [PurposesOfUse.NotDefined, PurposesOfUse.Other] as string[]
              ).includes(value)
                ? startCase(Message.getMessageByKey("exam.filter.purpose")) +
                  ": "
                : "") + displayString;
            return acum;
          },
          {} as Record<string, string>,
        ),
      [],
    ),
  );

  return query;
};

export const useOrgCategoryOptions = () => {
  const data = useOrgCategoryEnums();

  return useMemo(
    () =>
      [...(data ?? [])]
        .sort((a, b) => a.displayOrder - b.displayOrder)
        .map((item) => ({
          label: item.displayString,
          value: item.value,
        })),
    [data],
  );
};

export const useOrgSubCategoryEnums = () => {
  const { data } = useQuery<EnumModel[]>({
    queryKey: enumKeys.orgSubCategoryAll(),
    queryFn: async ({ signal }) => {
      const { result } = await getOrgSubCategories({ signal });

      return result;
    },
    placeholderData: [],
  });

  return data;
};

export const useOrgSubCategoryOptions = () => {
  const data = useOrgSubCategoryEnums();

  return useMemo(
    () =>
      [...(data || [])]
        .sort((a, b) => a.displayOrder - b.displayOrder)
        .reduce((arr, subCategory) => {
          if (!subCategory.mainCategory) {
            return arr;
          }

          const newItem = {
            label: subCategory.displayString,
            value: subCategory.value,
          };

          if (arr[subCategory.mainCategory]) {
            arr[subCategory.mainCategory].push(newItem);
          } else {
            arr[subCategory.mainCategory] = [newItem];
          }

          return arr;
        }, {}),
    [data],
  );
};
function useGetFileScanningStatus<T>(
  select: (data: EnumWithStringValueModel[]) => T,
) {
  const query = useQuery({
    queryKey: enumKeys.fileScanningStatusAll(),
    queryFn: async ({ signal }) => {
      const { result } = await getFileScanningStatuses({ signal });

      return result;
    },
    placeholderData: [],
    select,
  });

  return query;
}

export function useFileScanningStatus() {
  const query = useGetFileScanningStatus(
    useCallback((data) => toDic(data), []),
  );

  return query;
}

function useGetSkippedFileScanReason<T>(
  select: (data: EnumWithStringValueModel[]) => T,
) {
  const query = useQuery({
    queryKey: enumKeys.skippedFileScanReasons(),
    queryFn: async ({ signal }) => {
      const { result } = await getSkippedFileScanReasons({ signal });

      return result;
    },
    placeholderData: [],
    select,
  });

  return query;
}

export function useSkippedFileScanReason() {
  const query = useGetSkippedFileScanReason(
    useCallback((data) => toDic(data), []),
  );

  return query;
}

export const useGuestReportCategoryEnums = ({
  enabled = true,
}: {
  enabled?: boolean;
}) => {
  const query = useQuery({
    queryKey: enumKeys.guestCategoryEnums(),
    queryFn: async ({ signal }) => {
      const { result } = await getGuestReportCategoryEnums({
        signal,
      });

      return result;
    },
    enabled: Boolean(enabled),
  });

  return query;
};
