import { range } from "lodash";
import { useMemo } from "react";

import { useStoreContext } from "@context";

import { ScatterChart } from "@shared/components";
import { ScatterChartOptions } from "@shared/components/scatterChart/ScatterChart";
import { useGetExamChallengeStatistics } from "@shared/hooks/query/reports";
import { breakpoints } from "@shared/hooks/useBreakpoints";
import { calculateChartTicks } from "@shared/services/chart";
import { formatTimeElapsedString } from "@shared/services/date";
import Message from "@shared/services/message";

import { useChallengeStatistic } from "../../hooks/useExamChallengeStatistic";

export function TimeTakenScatterChart({
  examId,
  examChallengeId,
  selectedApplicantIds,
  screenSize,
}: {
  examId: number;
  challengeId: number;
  examChallengeId: number;
  screenSize: number;
  selectedApplicantIds: number[];
}) {
  const statisticInfoData = useGetExamChallengeStatistics({
    examId,
    challengeId: examChallengeId,
  });

  const statistic = useChallengeStatistic({
    examChallengeId,
    statisticInfo: statisticInfoData.data,
  });

  const { projectId } = useStoreContext();

  const transformedData = useMemo(() => {
    const data = statistic?.dataSamples?.result || [];

    return data.map((d) => ({
      x: d.solvingTimeSeconds / 60, // in minutes
      y: d.scorePercent,
      // we use html string here so it's compatible with c3.js tooltip contents configuration
      label: `<a href="/p/${projectId}/exams/${examId}/submissions/${
        d.id
      }" target="_blank">${d.fullname || d.email}</a>`,
    }));
  }, [statistic, examId, projectId]);

  const averageTime = useMemo(
    () =>
      transformedData.length === 0
        ? undefined
        : transformedData.reduce((acc, d) => acc + d.x, 0) /
          transformedData.length,
    [transformedData],
  );

  const xTickValues = useMemo(() => {
    const timeValues = transformedData.map((d) => d.x);

    const minTime = 0;
    const maxTime =
      timeValues.length === 0 ? 60 : Math.ceil(Math.max(...timeValues));

    const niceTickValues = calculateChartTicks({
      maxTicks: screenSize === breakpoints.sm ? 5 : 10,
      minPoint: minTime,
      maxPoint: maxTime,
    }).map((value) => Number(value.toFixed(2))); // limit to 2 decimal places for nice looking chart

    // due to fixed precision, duplicate values may occur
    const uniqueTickValues = Array.from(new Set(niceTickValues));

    return uniqueTickValues;
  }, [transformedData, screenSize]);

  const statisticDataIdMaps = useMemo(
    () =>
      statistic?.dataSamples.result.reduce(
        (acc, { id }) => {
          acc[id] = true;
          return acc;
        },
        {} as Record<number, boolean>,
      ) || {},
    [statistic?.dataSamples.result],
  );
  const colorMaps = useMemo(
    () =>
      selectedApplicantIds.reduce((acc, id, idx) => {
        if (statisticDataIdMaps[id]) {
          acc[idx] = "#9BCA54";
        }

        return acc;
      }, [] as string[]),
    [selectedApplicantIds, statisticDataIdMaps],
  );

  const options = useMemo(
    () =>
      ({
        colorMaps,
        xLabel: Message.getMessageByKey(
          "exam.dashboard.challengeAnalysis.timeMin",
        ),
        yLabel: `${Message.getMessageByKey("score")} (%)`,
        xRange: [xTickValues[0], xTickValues[xTickValues.length - 1]],
        yRange: [0, 105],
        xTickValues,
        yTickValues: range(0, 105, 10),
        xValueFormatter: (value) =>
          `<span>${Message.getMessageByKey(
            "exam.dashboard.challengeAnalysis.timeTaken",
          )}</span><span>${formatTimeElapsedString(value * 60)}</span>`,
        yValueFormatter: (value) =>
          `<span>${Message.getMessageByKey(
            "score",
          )}</span><span>${value.toFixed(2)}%</span>`,
      }) satisfies ScatterChartOptions,
    [colorMaps, xTickValues],
  );

  return (
    <ScatterChart
      data={transformedData}
      className="code-c-dashboard-time-taken__chart"
      options={options}
      chartMarkerProps={
        averageTime === undefined
          ? undefined
          : {
              tickPosition: averageTime,
              label: `${Message.getMessageByKey(
                "challenge.averageTimeTaken",
              )}: ${formatTimeElapsedString(averageTime * 60)}`,
            }
      }
    />
  );
}
