import * as c3 from "c3";
import "c3/c3.css";
import * as classnames from "classnames";
import * as d3 from "d3";
import * as React from "react";
import * as shortid from "shortid";

import { Msg } from "@shared/components";
import { TimeExtension } from "@shared/models";
import { ChartColor, ExecStatus } from "@shared/services/enums";

import { getChartConfiguration } from "./partials/c3ChartConfig/C3ChartConfig";
import {
  getXgrids,
  getYgrids,
} from "./partials/historyGraphGrids/HistoryGraphGrids";

/**
 * Prop interface
 */
export interface DataModel extends Omit<c3.DataPoint, "x"> {
  x: string | number;
}
export interface HistoryGraphItem {
  datetime: string;
  value: number;
  status: ExecStatus;
}
export interface HistoryGraphProps {
  items: HistoryGraphItem[];
  scoreMax: number;
  scoreMin?: number;
  className?: string;
  openedAt?: string;
  submittedAt?: string;
  scoreType?: string;
  timeExtensions: TimeExtension[];
}

/**
 * React Component
 */
export function HistoryGraph({
  items,
  submittedAt,
  scoreMax,
  scoreMin,
  scoreType,
  openedAt,
  timeExtensions,
  className,
}: HistoryGraphProps) {
  const rootStyle = classnames("code-c-history-graph", {
    [`${className}`]: Boolean(className),
  });
  const [id] = React.useState(`chart${shortid.generate()}`);

  React.useEffect(() => {
    if (!items.length) {
      return undefined;
    }
    const isAIChallenge = Boolean(scoreType);
    const sortedItems = [...items].sort((a, b) =>
      (a.datetime || "").localeCompare(b.datetime || ""),
    );

    const chart = c3.generate(
      getChartConfiguration({
        id,
        items: sortedItems,
        isAIChallenge,
        openedAt,
        scoreMax,
        scoreMin,
        submittedAt,
      }),
    );

    // To make the graph color a gradient.
    d3.select(`#${id}`)
      .select("svg")
      .append("linearGradient")
      .attr("id", "gradient-style")
      .attr("gradientUnits", "userSpaceOnUse")
      .attr("x1", 0)
      .attr("y1", 0)
      .attr("x2", 0)
      .attr("y2", 200)
      .selectAll("stop")
      .data([
        { offset: "60%", color: ChartColor.Primary, opacity: 1 },
        { offset: "100%", color: ChartColor.Primary, opacity: 0.3 },
      ])
      .enter()
      .append("stop")
      .attr("offset", (d) => d.offset)
      .attr("stop-color", (d) => d.color)
      .attr("stop-opacity", (d) => d.opacity);

    // To make the data dot color red if the data status is not success.
    d3.select(`#${id}`)
      .select("svg")
      .select(".c3-circles-value")
      .selectAll(".c3-circle")
      .attr("data-status", (data: DataModel) =>
        sortedItems[data.index]?.status === ExecStatus.Success
          ? "success"
          : "error",
      );

    // To display specific xgrids.
    if (submittedAt || timeExtensions.length) {
      chart.xgrids(getXgrids({ submittedAt, timeExtensions }));
    }

    // To display specific ygrids.
    if (!isAIChallenge && sortedItems.length > 0) {
      chart.ygrids(getYgrids({ scoreMax, items }));
    }

    // make sure graph resizes correctly when printing
    const resize = (e: MediaQueryListEvent) => {
      if (e.matches) {
        chart.resize();
      }
    };
    const mql = window.matchMedia("print");
    mql.addListener(resize);

    return function cleanup() {
      mql.removeListener(resize);
    };
  }, [
    id,
    items,
    submittedAt,
    scoreMax,
    scoreMin,
    scoreType,
    openedAt,
    timeExtensions,
  ]);

  return items.length ? (
    <div className={rootStyle} id={id} />
  ) : (
    <Msg id="submission.noAnswer" />
  );
}
