import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import {
  postConfirmDeliverExamById,
  postDeliverExamById,
  postDeliverExamByTestId,
} from "@api/exams";

import { useStoreContext } from "@context";

import history from "@shared/services/history";
import Message from "@shared/services/message";

import { submissionKeys } from "../submissions";
import {
  useInvalidateExamCountRelation,
  useInvalidateExamRelation,
} from "./useExam";

interface UseDeliverIdPayload {
  applicants?: string[];
  language?: string;
}

interface ExamDeliveryKeys {
  projectId: number;
  examId: number;
}

interface UseDeliverParams {
  examId: number;
  data: UseDeliverIdPayload;
}

export const examIdDeliveryKeys = {
  all: ["exam", "idDelivery"] as const,
  confirmIdDeliveryAll: () =>
    [...examIdDeliveryKeys.all, "confirmIdDelivery"] as const,
  confirmIdDelivery: ({ projectId, examId }: ExamDeliveryKeys) =>
    [...examIdDeliveryKeys.confirmIdDeliveryAll(), projectId, examId] as const,
};

export function useDeliverId() {
  const client = useQueryClient();
  const { projectId } = useStoreContext();
  const invalidateExamCount = useInvalidateExamCountRelation();

  const mutation = useMutation({
    mutationFn: ({ examId, data }: UseDeliverParams) =>
      postDeliverExamById({
        examId,
        projectId,
        options: { data },
      }),
    onSuccess: (_, { examId }) => {
      toast.success(Message.getMessageByKey("message.exam.delivered"));
      client.invalidateQueries(
        submissionKeys.list(projectId, { examId, keyword: "" }),
      );
      invalidateExamCount(examId);
      history.push({
        pathname: `/p/${projectId}/exams/${examId}/submissions_unread`,
      });
    },
  });

  return mutation;
}

export function useDeliverSampleById() {
  const client = useQueryClient();
  const { projectId } = useStoreContext();
  const invalidateExam = useInvalidateExamRelation();

  const mutation = useMutation({
    mutationFn: ({ examId, data }: UseDeliverParams) =>
      postDeliverExamByTestId({
        examId,
        projectId,
        options: { data },
      }),
    onSuccess: (_, { examId }) => {
      toast.success(Message.getMessageByKey("message.exam.delivered"));
      invalidateExam(examId);
      client.invalidateQueries(
        submissionKeys.list(projectId, { examId, keyword: "" }),
      );
    },
  });

  return mutation;
}

export function useConfirmDeliverId() {
  const client = useQueryClient();
  const { projectId } = useStoreContext();

  const mutation = useMutation({
    mutationFn: ({ examId, data }: UseDeliverParams) =>
      postConfirmDeliverExamById({
        examId,
        projectId,
        options: { data },
      }),
    onSuccess: (res, { examId }) => {
      client.setQueryData(
        examIdDeliveryKeys.confirmIdDelivery({ projectId, examId }),
        res,
      );
      client.invalidateQueries(
        submissionKeys.list(projectId, { examId, keyword: "" }),
      );
    },
  });

  return mutation;
}
