import { AxiosRequestConfig } from "axios";

import { post } from "@api/httpClient";

export interface RecalculateScorePayload {
  challengeResultId: number;
}

export async function postRecalculateScore(
  options: AxiosRequestConfig<RecalculateScorePayload>,
) {
  const { data } = await post(`/api/admin/scoremaintain/result/run`, {
    ...options,
    data: {
      ...options.data,
      shouldCommit: true,
      rescoreExam: true,
    },
  });

  return data;
}
