import { Epic } from "redux-observable";
import { Observable } from "rxjs";

import {
  Action,
  ProjectAction,
  ajaxAction,
  alertAction,
  projectListGetAction,
  projectListAddAction,
  projectListRemoveAction,
  projectMemberListGetAction,
  projectMemberListAddAction,
  projectErrorAction,
  projectMemberUpdateAction,
  projectGetAction,
  projectUpdateAction,
  projectDeleteAction,
  projectCreateAction,
  projectMemberCreateAction,
  projectMemberDeleteAction,
  adminProjectMemberDeleteAction,
  orgsUserListGetAction,
} from "../actions";
import { RootState } from "../reducers";

const projectGetEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(projectGetAction.types.request).map((action) =>
    ajaxAction.request({
      method: "get",
      url: `/api/admin/orgs/${
        state.getState().admin.selectedOrg.name
      }/projects/${action.payload}`,
      success: projectGetAction.success,
      error: projectErrorAction.request,
    }),
  );

const projectUpdateEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(projectUpdateAction.types.request).map((action) =>
    ajaxAction.request({
      method: "put",
      url: `/api/admin/orgs/${
        state.getState().admin.selectedOrg.name
      }/projects/${action.payload}`,
      body: action.params,
      success: alertAction.success(
        "Project Updated",
        projectListAddAction.request,
      ),
      error: alertAction.error("Failed to update Project"),
    }),
  );

const projectDeleteEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(projectDeleteAction.types.request).map((action) =>
    ajaxAction.request({
      method: "delete",
      url: `/api/admin/orgs/${
        state.getState().admin.selectedOrg.name
      }/projects/${action.payload}`,
      // TODO remove from list action
      success: alertAction.success("Project Deleted", () =>
        projectListRemoveAction.request(action.payload as number),
      ),
      error: alertAction.error("Failed to Delete Project"),
    }),
  );

const projectCreateEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(projectCreateAction.types.request).map((action) =>
    ajaxAction.request({
      method: "post",
      url: `/api/admin/orgs/${
        state.getState().admin.selectedOrg.name
      }/projects`,
      body: action.payload,
      success: alertAction.success(
        "Project Created",
        projectListAddAction.request,
      ),
    }),
  );

const projectListGetEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(projectListGetAction.types.request).map((action) =>
    ajaxAction.request({
      method: "get",
      url: `/api/admin/orgs/${
        state.getState().admin.selectedOrg.name
      }/projects`,
      body: action.payload,
      success: projectListGetAction.success,
    }),
  );

const projectMemberListGetEpic: Epic<Action, RootState> = (action$, state) =>
  action$
    .ofType(projectMemberListGetAction.types.request)
    .map(({ payload, params }: ProjectAction) =>
      ajaxAction.request({
        method: "get",
        url: `/api/admin/orgs/${
          state.getState().admin.selectedOrg.name
        }/projects/${
          payload.projectId || state.getState().project.currentProjectId
        }/members`,
        body: params,
        success: projectMemberListGetAction.success,
      }),
    );

const projectMemberCreateEpic: Epic<Action, RootState> = (action$, state) =>
  action$
    .ofType(projectMemberCreateAction.types.request)
    .map(({ payload, params }: ProjectAction) =>
      ajaxAction.request({
        method: "post",
        url: `/api/admin/orgs/${
          state.getState().admin.selectedOrg.name
        }/projects/${payload.projectId}/members`,
        body: params,
        success: alertAction.success(
          "Member Added",
          projectMemberListAddAction.request,
        ),
      }),
    );

const adminProjectMemberDeleteEpic: Epic<Action, RootState> = (action$) =>
  action$
    .ofType(adminProjectMemberDeleteAction.types.request)
    .concatMap((action) => {
      const { userId, projectIds } = (
        action as Action & {
          payload: { userId: number; projectIds: number[] };
        }
      ).payload;
      return Observable.of(...projectIds).concatMap((projectId) =>
        Observable.of(projectId)
          .delay(500)
          .map(() => projectMemberDeleteAction.request({ userId, projectId })),
      );
    });

const adminProjectMemberDeletePostEpic: Epic<Action, RootState> = (
  action$,
  state$,
) =>
  action$.ofType(adminProjectMemberDeleteAction.types.success).map((action) => {
    return orgsUserListGetAction.request(
      state$.getState().orgs.orgsMemberListConditions,
    );
  });

const projectMemberDeleteEpic: Epic<Action, RootState> = (action$, state) =>
  action$
    .ofType(projectMemberDeleteAction.types.request)
    .map(({ payload }: ProjectAction) =>
      ajaxAction.request({
        method: "delete",
        url: `/api/admin/orgs/${
          state.getState().admin.selectedOrg.name
        }/projects/${payload.projectId}/members/${payload.userId}`,
        success: projectMemberDeleteAction.success,
        error: projectMemberDeleteAction.failure,
      }),
    );

const projectMemberDeleteSuccessEpic: Epic<Action, RootState> = (
  action$,
  state,
) =>
  action$.ofType(projectMemberDeleteAction.types.success).map(() => {
    return state.getState().admin.deleteMember.count === 0
      ? alertAction.success(
          "Member Deleted",
          adminProjectMemberDeleteAction.success,
        )()
      : { type: "@null" };
  });

const projectMemberUpdateEpic: Epic<Action, RootState> = (action$, state) =>
  action$
    .ofType(projectMemberUpdateAction.types.request)
    .map(({ payload, params }: ProjectAction) =>
      ajaxAction.request({
        method: "put",
        url: `/api/admin/orgs/${
          state.getState().admin.selectedOrg.name
        }/projects/${
          payload.projectId || state.getState().project.currentProjectId
        }/members/${payload.userId}`,
        body: params,
        success: alertAction.success(
          "Member Updated",
          projectMemberUpdateAction.success,
        ),
        error: projectMemberUpdateAction.failure,
      }),
    );

export default [
  projectGetEpic,
  projectUpdateEpic,
  projectDeleteEpic,
  projectCreateEpic,
  projectListGetEpic,
  projectMemberListGetEpic,
  projectMemberCreateEpic,
  projectMemberDeleteEpic,
  projectMemberUpdateEpic,
  adminProjectMemberDeleteEpic,
  adminProjectMemberDeletePostEpic,
  projectMemberDeleteSuccessEpic,
];
