import * as React from "react";

import { Modal, Msg } from "@shared/components";
import { SlackIntegrationInputModel, SlackChannelModel } from "@shared/models";
import {
  IntegrationSocialEvent,
  SpokenLanguages,
} from "@shared/services/enums";

import {
  SettingSlackIntegrationForm,
  SettingSlackIntegrationFormType,
} from "../settingSlackIntegrationForm/SettingSlackIntegrationForm";

/**
 * Prop interface
 */
export interface ExternalProps {
  isOpen: boolean;
  onClickCancel: () => void;
  onClickOk: (params: SlackIntegrationInputModel) => void;
}

interface SettingSlackIntegrationAddChannelModalProps extends ExternalProps {
  socialChannels: SlackChannelModel[];
}

const initialValues: SlackIntegrationInputModel = {
  channelId: "",
  language: SpokenLanguages.Japanese,
  events: [
    IntegrationSocialEvent.DailySummary,
    IntegrationSocialEvent.NewUser,
    IntegrationSocialEvent.SubmittedExam,
  ],
  examNotificationSubscriptions: [],
  subscribedToAllExamNotifications: true,
};

/**
 * Page component
 */
export const SettingSlackIntegrationAddChannelModal: React.FunctionComponent<
  SettingSlackIntegrationAddChannelModalProps
> = ({
  isOpen,
  socialChannels,
  onClickCancel,
  onClickOk,
}: SettingSlackIntegrationAddChannelModalProps) => {
  const [form, setForm] = React.useState<SettingSlackIntegrationFormType>({
    formValues: initialValues,
    formValid: false,
  });

  React.useEffect(() => {
    if (isOpen) {
      setForm({
        formValues: initialValues,
        formValid: false,
      });
    }
  }, [isOpen]);

  return (
    <Modal
      title={<Msg id="slack.addChannelNotification" />}
      isOpen={true}
      onClose={onClickCancel}
      onClickCancel={onClickCancel}
      onClickOk={() => onClickOk(form.formValues)}
      disableOk={!form.formValid}
      ariaLabel="Add Channel Notification"
    >
      <SettingSlackIntegrationForm
        initialValues={initialValues}
        socialChannels={socialChannels}
        form={form}
        onFormChange={(updatedFormValues) => setForm(updatedFormValues)}
      />
    </Modal>
  );
};
