import * as classnames from "classnames";
import * as React from "react";

import {
  Table,
  TableHead,
  TableBody,
  HeaderColumn,
  Row,
  Column,
  Msg,
  Tooltip,
  Button,
  Icon,
} from "../..";
import { SubmissionResultDetailModel } from "../../../models";
import { formatDateTimeMinutes } from "../../../services/date";
import { ExecStatus } from "../../../services/enums";
import Message from "../../../services/message";
import { getRoundedRawScore } from "../../../services/score";

/**
 * Prop interface
 */
export interface SubmittedFileTableProps {
  submissionResultDetail: SubmissionResultDetailModel;

  onClickDownload: (historyId: number) => void;
  className?: string;
}

/**
 * React Component
 */
export const SubmittedFileTable: React.FunctionComponent<
  SubmittedFileTableProps
> = (props: SubmittedFileTableProps) => {
  const { submissionResultDetail, className } = props;

  /**
   * State
   */
  const rootStyle = classnames("code-c-submitted-file-table", {
    [`${className}`]: Boolean(className),
  });
  const { scoreHistory } = submissionResultDetail.aiResult;

  const downloadButton = (historyId: number) => (
    <Button
      size="small"
      shrink={true}
      onClick={() => props.onClickDownload(historyId)}
    >
      <Icon type="download" />
      <Msg id="button.download" />
    </Button>
  );

  return scoreHistory.length > 0 ? (
    <Table className={rootStyle}>
      <TableHead>
        <HeaderColumn size={4}>
          <Msg id="score" />
        </HeaderColumn>
        <HeaderColumn>
          <Msg id="submittedAt" />
        </HeaderColumn>
        <HeaderColumn />
      </TableHead>
      <TableBody>
        {scoreHistory.map((history) => (
          <Row key={history.id}>
            <Column>
              {history.score !== undefined ? (
                getRoundedRawScore(history.score)
              ) : (
                <div className="code-c-submitted-file-table__score-unavailable">
                  <Icon type="exclamation-triangle" />
                  <Msg id="submission.score.unavailable" />
                </div>
              )}
            </Column>
            <Column>{formatDateTimeMinutes(history.createdAt)}</Column>
            <Column className="align-right">
              {history.hasUploadedFile && history.execStatus && (
                <>
                  {history.execStatus === ExecStatus.RunError ||
                  (history.score === undefined && history.testOutput) ? (
                    <Tooltip
                      text={Message.getMessageByKey(
                        "submission.ai.downloadNote",
                      )}
                      maxSize="xlarge"
                      placement="top-end"
                    >
                      {downloadButton(history.id)}
                    </Tooltip>
                  ) : (
                    downloadButton(history.id)
                  )}
                </>
              )}
            </Column>
          </Row>
        ))}
      </TableBody>
    </Table>
  ) : (
    <Msg id="submission.noAnswer" />
  );
};
