export function getLogarithmicScale(data: number) {
  return data <= 0 ? 0 : data === 1 ? 0.1 : Math.log10(data);
}

/**
 * Given a number, returns the number between its exponential neighbors.
 * Sample input: 20
 * 20 is between 10^1 and 10^2
 * We need to find x which is between 1 and 2
 * This function finds x which is 1.1111111111111112
 */
export function getLogInterpolation(num: number) {
  if (num === 0) return 0;

  // 1 is a special case log 1 is 0
  if (num === 1) return 0.1;

  const logarithmicScale = getLogarithmicScale(num);

  if (logarithmicScale % 1 === 0) {
    return logarithmicScale;
  }

  const y1 = Math.floor(logarithmicScale);
  const y2 = Math.ceil(logarithmicScale);

  const x1 = Math.pow(10, y1);
  const x2 = Math.pow(10, y2);

  // interpolation formula :)
  const x = y1 + ((num - x1) * (y2 - y1)) / (x2 - x1);
  return x;
}

/**
 * Opposite of getLogInterpolation
 * Given a log number, returns the original number
 */
export function getReverseLogInterpolation(logarithmicNum: number) {
  if (logarithmicNum === 0) return 0;
  if (logarithmicNum === 0.1) return 1;

  if (logarithmicNum % 1 === 0) {
    return Math.pow(10, logarithmicNum);
  }

  const y1 = Math.floor(logarithmicNum);
  const y2 = Math.ceil(logarithmicNum);

  const x1 = Math.pow(10, y1);
  const x2 = Math.pow(10, y2);

  // interpolation formula... but reversed! :D
  const x = x1 + ((x2 - x1) * (logarithmicNum - y1)) / (y2 - y1);
  return x;
}

export function isZero(num?: number) {
  return num === 0;
}
