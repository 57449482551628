import * as classnames from "classnames";

import { Avatar } from "..";

/**
 * Prop interface
 */
export interface HeaderLogoProps {
  imageUrl?: string;
  displayName?: string;
  className?: string;
}

/**
 * React Component
 */
export default function HeaderLogo({
  imageUrl,
  displayName,
  className,
}: HeaderLogoProps) {
  const rootStyle = classnames("code-c-header-logo", {
    [`${className}`]: Boolean(className),
  });

  if (imageUrl || displayName) {
    return (
      <div className={rootStyle}>
        {imageUrl ? (
          <Avatar src={imageUrl} size="small" />
        ) : (
          <div className="code-c-header-logo__track-logo-mini" />
        )}
        <div className="code-c-header-logo__name is-hidden-touch">
          {displayName}
        </div>
      </div>
    );
  } else {
    return (
      <div className={rootStyle}>
        <div className="code-c-header-logo__track-logo" />
      </div>
    );
  }
}
