import actionCreator from "../shared/services/actionCreator";

export const healthCheckAction = actionCreator("healthCheckAction");
export const apiServerHealthAction = actionCreator("apiServerHealthAction");
export const orcaServerHealthAction = actionCreator("orcaServerHealthAction");
export const orcaServerWsHealthAction = actionCreator(
  "orcaServerWsHealthAction",
);
export const contentsServerHealthAction = actionCreator(
  "contentsServerHealthAction",
);
export const imageServerHealthAction = actionCreator("imageServerHealthAction");
