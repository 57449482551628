import * as classnames from "classnames";
import * as React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import {
  SubmissionIpAddressModal,
  OverlappingTooltip,
} from "@components/orgs/submissions/submissionDetail/partials";

import { RootState } from "@reducers/index";

import {
  Banner,
  FormattedPressedKeys,
  Icon,
  Loading,
  Msg,
  Pagination,
  Tag,
  WarningTag,
} from "@shared/components";
import {
  useGetSubmissionsResults,
  useSubmissionOverlappingIpMap,
  useGetSubmissionsResultDetailBatch,
  useGetApplicantActionEnums,
  useApplicantActionLogCounts,
  useApplicantActionLog,
} from "@shared/hooks/query";
import {
  PaginationModel,
  ApplicantActionTypesToCountsModel,
} from "@shared/models";
import {
  AllActionType,
  ApplicantActionType,
  ChallengeTakenBy,
  WebcamActionType,
} from "@shared/services/enums";
import Message from "@shared/services/message";
import { updateUrl } from "@shared/services/queryString";
import * as urlParser from "@shared/services/urlParser";

import {
  SubmissionResultActionLogRow,
  SubmissionResultActionLogRowDetail,
  SubmissionResultActionLogSessionCard,
  SubmissionResultActionLogActionDetail,
} from "./partials";
import ActionLogFilter, {
  SelectedFilters,
} from "./partials/actionLogFilter/ActionLogFilter";
import SubmissionResultActionLogPastedCard from "./partials/submissionResultActionLogPastedCard/SubmissionResultActionLogPastedCard";
import {
  transformActionLogList,
  webcamActionTypeToMessage,
} from "./partials/submissionResultActionLogUtils/SubmissionResultActionLogUtils";

export interface SubmissionResultActionLogProps {
  className?: string;
  location?: { search: string };
  submissionId: number;
}

export default function SubmissionResultActionLog({
  className,
  submissionId,
}: SubmissionResultActionLogProps) {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const queryPage = params.get("page");
  const initialPageAsNumber = urlParser.getNumber(queryPage);

  const [collapsedRows, setCollapsedRows] = React.useState<
    Record<number, boolean>
  >([]);
  // Get specific query params
  const queryChallengeResultId = params.get("challengeResultId");
  const queryActions = params.get("actions")
    ? (params.get("actions")?.split(",") as ApplicantActionType[])
    : [];
  // You can transform the values to your desired format
  const initialChallengeResultId = queryChallengeResultId
    ? Number(queryChallengeResultId)
    : "all"; // Default to "all" if not present
  const initalActions = queryActions.length
    ? (queryActions as ApplicantActionType[])
    : []; // Default to empty array if no actions

  const [offset, setOffset] = React.useState(initialPageAsNumber ?? 0);
  const [challengeResultId, setChallengeResultId] = React.useState<
    number | "all"
  >(initialChallengeResultId);
  const [actionTypes, setActionTypes] =
    React.useState<AllActionType[]>(initalActions);

  const [selectedDropdownActions, setSelectedDropdownActions] = React.useState<
    AllActionType[]
  >([]);

  const [selectedIp, setSelectedIp] = React.useState<string | false>(false);

  const projectId = useSelector(
    (state: RootState) => state.project.currentProjectId,
  );
  const {
    data: applicantActionLogData,
    isLoading: isLoadingApplicantAccountLogs,
  } = useApplicantActionLogCounts(submissionId, projectId);
  const { data: actionLog, isLoading: isLoadingApplicantActionEnums } =
    useGetApplicantActionEnums();

  const {
    data: applicantActionLogListResponse,
    isLoading: isLoadingApplicantActionLogList,
  } = useApplicantActionLog({
    submissionId,
    projectId,
    offset,
    actions: actionTypes,
    challengeResultId,
  });

  const applicantActionLogList = [
    ...(applicantActionLogListResponse?.data.result ?? []),
  ];

  const applicantActions = actionLog || [];

  const applicantActionsDisplayString = applicantActions.reduce(
    (acc, { value, displayString }) => {
      acc[value as ApplicantActionType] = displayString;
      return acc;
    },
    {} as Record<AllActionType, string>,
  );

  const { data: challengeResults } = useGetSubmissionsResults();
  const submissionResultDetailList =
    useGetSubmissionsResultDetailBatch(challengeResults);

  const shouldShowLocalExamWarning = submissionResultDetailList.some(
    ({ takenBy }) => takenBy === ChallengeTakenBy.LocalMachine,
  );

  const examChallenges = React.useMemo(
    () =>
      challengeResults.reduce(
        (acc, { id, title }) => {
          acc[id] = title;
          return acc;
        },
        {} as Record<string, string>,
      ),
    [challengeResults],
  );
  const { data: overlappingIps } = useSubmissionOverlappingIpMap();

  const rootStyle = classnames("table code-c-submission-result-action-log", {
    [`${className}`]: Boolean(className),
  });

  const onFilterChange = ({
    selectedChallengeResultId,
    selectedActions,
  }: SelectedFilters) => {
    setChallengeResultId(selectedChallengeResultId);
    setSelectedDropdownActions(selectedActions);
    setActionTypes(selectedActions);
    setOffset(0);
    setCollapsedRows({});
    const challengeResultId =
      selectedChallengeResultId === "all" ? "" : selectedChallengeResultId;

    updateUrl({
      page: 0,
      actions: selectedActions,
      challengeResultId,
    });
  };

  const handleRowClick = (id: number, value: boolean) => {
    setCollapsedRows({
      ...collapsedRows,
      [id]: value,
    });
  };

  const handlePageChange = ({ selected }: { selected: number }) => {
    const newOffset = selected * pagination.limit;
    setOffset(newOffset);
    updateUrl({ page: selected });
  };

  const handleOpenAddressClick = (ip: string) => {
    setSelectedIp(ip);
  };

  const handleCloseAddressClick = () => {
    setSelectedIp(false);
  };

  const challengeOptions = challengeResults.map(
    ({ id, title, challengeResultStatus }) => ({
      challengeResultId: id,
      challengeTitle: title,
      challengeResultStatus: challengeResultStatus,
    }),
  );

  const actionOptions = applicantActions.map(({ value, displayString }) => {
    let label = displayString;
    if (value === ApplicantActionType.Webcam) {
      label = Message.getMessageByKey(
        "exam.applicantActionLogs.showWebcamEvent",
      );
    }
    return {
      actionType: value as ApplicantActionType,
      displayString: label,
    };
  });

  const isLoading =
    isLoadingApplicantAccountLogs ||
    isLoadingApplicantActionLogList ||
    isLoadingApplicantActionEnums;

  const pagination = new PaginationModel(
    applicantActionLogListResponse?.data.pagination,
  );

  const { challengeCounts, examCounts } = applicantActionLogData?.data
    .result ?? {
    challengeCounts: [],
    examCounts: { actionTypesToCounts: {} },
  };

  const applicantActionLogCounts: {
    challengeCounts: Record<number, ApplicantActionTypesToCountsModel>;
    examCounts: Partial<Record<AllActionType, number>>;
  } = {
    challengeCounts: [],
    examCounts: examCounts.actionTypesToCounts,
  };

  for (let i = 0; i < challengeCounts.length; i++) {
    const { challengeResultId, actionTypesToCounts } = challengeCounts[i];
    const actionTypesToCountFormatted = Object.entries(
      actionTypesToCounts,
    ).reduce(
      (acc, [actionType, count]) => {
        const typedActionType = actionType as WebcamActionType;
        acc[typedActionType] = count;

        return acc;
      },
      {} as Record<AllActionType | string, number>,
    );
    applicantActionLogCounts.challengeCounts[challengeResultId] =
      actionTypesToCountFormatted;
  }

  const newData = transformActionLogList({
    apiResponse: applicantActionLogList ? applicantActionLogList : [],
    examChallenges,
    applicantActionsDisplayString,
    overlappingIps,
  });

  return (
    <div className={rootStyle}>
      <div className="code-c-submission-result-action-log__banner">
        <Icon type="info-circle" />
        <Msg id="submission.actionLog.sessionData.changed.info" />
        <a
          href={Message.getMessageByKey(
            "submission.actionLog.sessionData.changed.info.url",
          )}
          target="_blank"
          rel="noreferrer noopener"
        >
          <Msg id="common.learnMore" />
          <Icon type="external-link" />
        </a>
      </div>
      {shouldShowLocalExamWarning && (
        <Banner type="warning">
          <Msg id="submission.actionLog.localExam.warning" />
        </Banner>
      )}

      <ActionLogFilter
        challengeOptions={challengeOptions}
        actionOptions={actionOptions}
        onChange={onFilterChange}
        selectedActions={selectedDropdownActions}
        selectedChallengeResultId={challengeResultId}
        actionLogCounts={applicantActionLogCounts}
      />

      <div className="code-c-submission-result-action-log__table">
        <div className="code-c-submission-result-action-log__table-head">
          <div>
            <Msg id="submission.actionLog.challengeTitle" />
          </div>
          <div>
            <Msg id="submission.actionLog.action" />
          </div>
          <div>
            <Msg id="submission.actionLog.time" />
          </div>
        </div>
        <Loading
          className="code-c-submission-result-action-log__loading-list"
          isOpen={isLoading}
          fullScreen={false}
          overlay={false}
        />
        {
          <div className="code-c-submission-result-action-log__table-body">
            {!isLoadingApplicantActionLogList && newData.length === 0 && (
              <div className="code-c-submission-result-action-log__empty">
                <Msg id="submission.actionLog.noAction" />
              </div>
            )}
            {newData.map(({ action, actionDetails }, index) => {
              const collapsed =
                collapsedRows[action.id] !== false ? true : false;
              return (
                <React.Fragment key={actionDetails.key}>
                  <SubmissionResultActionLogRow
                    time={actionDetails.formattedActionTime}
                    isCollapse={collapsed}
                    clickable={actionDetails.clickable}
                    onClick={(value) => handleRowClick(action.id, value)}
                  >
                    <SubmissionResultActionLogRow.Title>
                      {actionDetails.title}
                    </SubmissionResultActionLogRow.Title>
                    <SubmissionResultActionLogRow.Action
                      className={actionDetails.actionClassName}
                    >
                      {actionDetails.icon && (
                        <Icon type={actionDetails.icon} size="small" />
                      )}
                      <p>
                        {actionDetails.displayAction}{" "}
                        <SubmissionResultActionLogRow.ActionExtraInfo
                          unfocusedTime={actionDetails.unfocusedTime}
                          pastedText={
                            action.action === ApplicantActionType.CopyPaste
                              ? action.pastedText
                              : undefined
                          }
                          screenshotPressedKeys={
                            action.action === ApplicantActionType.Screenshot
                              ? action.screenshotPressedKeys
                              : undefined
                          }
                        />
                      </p>
                      {actionDetails.isDiff && (
                        <WarningTag>
                          <Msg id="submission.actionLog.sessionData.changed" />
                        </WarningTag>
                      )}
                      {(actionDetails.isServerAction ||
                        actionDetails.isManualSubmission) && (
                        <Tag color="neutral-light">
                          <div className="has-text-weight-medium">
                            {actionDetails.isServerAction && (
                              <Msg id="submission.actionLog.sessionData.serverAction" />
                            )}
                            {actionDetails.isManualSubmission && (
                              <Msg id="submission.actionLog.sessionData.manuallySubmitted" />
                            )}
                          </div>
                        </Tag>
                      )}
                      {actionDetails.isDuplicateIp && (
                        <OverlappingTooltip narrowed />
                      )}
                    </SubmissionResultActionLogRow.Action>
                  </SubmissionResultActionLogRow>
                  <SubmissionResultActionLogRowDetail hidden={collapsed}>
                    {!actionDetails.isServerAction &&
                      !actionDetails.isManualSubmission &&
                      actionDetails.sessionData && (
                        <>
                          <SubmissionResultActionLogSessionCard
                            agent={actionDetails.sessionData.agent || ""}
                            hash={actionDetails.sessionData.hash || ""}
                            ip={actionDetails.sessionData.ip || ""}
                            isAgentDiff={actionDetails.isAgentDiff}
                            isHashDiff={actionDetails.isHashDiff}
                            isIpDiff={actionDetails.isIpDiff}
                            isDuplicateIp={actionDetails.isDuplicateIp}
                            onOpenIpAddressClick={handleOpenAddressClick}
                          />
                          <SubmissionResultActionLogActionDetail labelKey="submission.actionLog.actionDetail.unfocusedTime">
                            {actionDetails.unfocusedTime}
                          </SubmissionResultActionLogActionDetail>
                          {action.action === ApplicantActionType.Screenshot && (
                            <SubmissionResultActionLogActionDetail labelKey="submission.actionLog.actionDetail.pressedKeys">
                              {action.screenshotPressedKeys && (
                                <FormattedPressedKeys
                                  pressedKeys={action.screenshotPressedKeys}
                                />
                              )}
                            </SubmissionResultActionLogActionDetail>
                          )}

                          {action.action === ApplicantActionType.CopyPaste && (
                            <SubmissionResultActionLogPastedCard
                              pastedText={action.pastedText}
                              pastedTextFilename={action.pastedTextFilename}
                              pastedTextQuestionTitle={
                                action.pastedTextQuestionTitle
                              }
                            />
                          )}
                        </>
                      )}

                    {action.action === ApplicantActionType.Webcam && (
                      <SubmissionResultActionLogActionDetail
                        titleKey="submission.actionLog.webcamMultiplePeopleDetected.title"
                        className="code-c-submission-result-action-log__webcam-multiple-people"
                      >
                        <div>
                          {action.webcam.suspiciousActivities.map(
                            (activity) => {
                              return (
                                <div key={activity}>
                                  <strong>
                                    <Msg
                                      key={webcamActionTypeToMessage[activity]}
                                      id={
                                        webcamActionTypeToMessage[activity] +
                                        ".label"
                                      }
                                    />{" "}
                                  </strong>

                                  <Msg
                                    id={
                                      webcamActionTypeToMessage[activity] +
                                      ".text"
                                    }
                                  />
                                </div>
                              );
                            },
                          )}
                        </div>
                        <div className="code-c-submission-result-action-log__webcam-multiple-people__image-container">
                          <img
                            src={action.webcam.imageUrl}
                            alt="Detected Person"
                          />
                        </div>
                        <div className="code-c-submission-result-action-log__webcam-multiple-people__image-container">
                          <img
                            src={action.webcam.referenceImageUrl}
                            alt="Reference Image"
                          />
                        </div>
                      </SubmissionResultActionLogActionDetail>
                    )}
                  </SubmissionResultActionLogRowDetail>
                </React.Fragment>
              );
            })}
          </div>
        }
      </div>
      <Pagination
        pagination={pagination}
        className="code-c-submission-result-action-log__pagination"
        onPageChange={handlePageChange}
      />
      {selectedIp && (
        <SubmissionIpAddressModal
          onClickClose={handleCloseAddressClick}
          ipFilter={selectedIp}
        />
      )}
    </div>
  );
}
