import actionCreator from "../shared/services/actionCreator";

export const applicantInitialGetAction = actionCreator(
  "applicantInitialGetAction",
);
export const applicantSharingGetAction = actionCreator(
  "applicantSharingGetAction",
);
export const applicantInvitationAction = actionCreator(
  "applicantInvitationAction",
);
export const applicantAuthAction = actionCreator("applicantAuthAction");
export const applicantExamGetAction = actionCreator("applicantExamGetAction");
export const applicantExamFaceVerifiedAction = actionCreator(
  "applicantExamFaceVerifiedAction",
);
export const applicantExamOpenAction = actionCreator("applicantExamOpenAction");
export const applicantExamSubmitAction = actionCreator(
  "applicantExamSubmitAction",
);
export const applicantSessionExtendAction = actionCreator(
  "applicantSessionExtendAction",
);
export const applicantUpdateFormAction = actionCreator(
  "applicantUpdateFormAction",
);
export const applicantNotificationAddAction = actionCreator(
  "applicantNotificationAddAction",
);
export const applicantNotificationRemoveAction = actionCreator(
  "applicantNotificationRemoveAction",
);

export const challengeStartAction = actionCreator("challengeStartAction");
export const challengeStartStep1Action = actionCreator(
  "challengeStartStep1Action",
);
export const challengeStartStep2Action = actionCreator(
  "challengeStartStep2Action",
);
export const challengeStartStep3Action = actionCreator(
  "challengeStartStep3Action",
);
export const challengeResetAction = actionCreator("challengeResetAction");
export const challengeSaveAction = actionCreator("challengeSaveAction");
export const challengeSubmitAction = actionCreator("challengeSubmitAction");
export const challengeSwitchLangAction = actionCreator(
  "challengeSwitchLangAction",
);
export const challengeUpdateVarsAction = actionCreator(
  "challengeUpdateVarsAction",
);
export const challengeContextAction = actionCreator("challengeContextAction");
export const challengeContextRemoveAction = actionCreator(
  "challengeContextRemoveAction",
);
export const challengeSwitchTakenByAction = actionCreator(
  "challengeSwitchTakenByAction",
);

export const editorHasUnsavedSetAction = actionCreator(
  "editorHasUnsavedSetAction",
);
export const editorReadyAction = actionCreator("editorReadyAction");
export const presignedAction = actionCreator("presignedAction");
export const getSourceFiles = actionCreator("getSourceFiles");
export const updateAppealComment = actionCreator("updateAppealComment");
export const resetAppealComment = actionCreator("resetAppealComment");

export const applicantIdSharingGetAction = actionCreator(
  "applicantIdSharingGetAction",
);
export const applicantIdStartExamAction = actionCreator(
  "applicantIdStartExamAction",
);
export const applicantIdPasswordAction = actionCreator(
  "applicantIdPasswordAction",
);
export const applicantIdSigninAction = actionCreator("applicantIdSigninAction");
export const applicantIncludeAppealCommentLogAction = actionCreator(
  "applicantIncludeAppealCommentLogAction",
);
export const applicantPasteEventAction = actionCreator(
  "applicantPasteEventAction",
);
export const applicantSwitchTabEventAction = actionCreator(
  "applicantSwitchTabEventAction",
);
export const applicantKeyEventSessionDuplicatedAction = actionCreator(
  "applicantKeyEventSessionDuplicatedAction",
);
export const applicantFileUploadAction = actionCreator(
  "applicantFileUploadAction",
);
