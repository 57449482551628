import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { postSubmissionRescore } from "@api/submissions";

import { RescoreSubmissionsParamsModel } from "@shared/models/SubmissionList.model";
import Message from "@shared/services/message";

import { useInvalidateSubmissionRelation } from "./useSubmission";

export function useRescoreSubmission() {
  const invalidateSubmission = useInvalidateSubmissionRelation();

  const mutation = useMutation({
    mutationFn: (data: RescoreSubmissionsParamsModel) =>
      postSubmissionRescore({ options: { data } }),
    onSuccess: () => {
      toast.success(
        Message.getMessageByKey("message.submissions.rescore.success"),
      );
      invalidateSubmission();
    },
    onError: () => {
      toast.error(
        Message.getMessageByKey("message.submissions.rescore.failed"),
      );
    },
  });

  return mutation;
}
