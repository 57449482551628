import { Banner, Msg } from "@shared/components";
import { ApplicantExamStatus } from "@shared/services/enums";

import { SeeActionLogLinkText } from "../SeeActionLogLinkText/SeeActionLogLinkText";

interface SignificantCountBannerProps {
  totalSignificantCount: number;
  actionsTabRef?: React.RefObject<HTMLDivElement>;
  status: ApplicantExamStatus;
}

export const SignificantCountBanner = ({
  totalSignificantCount,
  actionsTabRef,
  status,
}: SignificantCountBannerProps) => (
  <>
    {totalSignificantCount > 0 ? (
      <Banner
        className="code-c-submission-detail__cheat-monitor-banner"
        type="warning-strong"
      >
        <div>
          <span className="banner-link">
            {`${totalSignificantCount} `}
            <Msg
              id={
                totalSignificantCount > 1
                  ? "submission.submissionDetail.cheatMonitor.isDetected_plural"
                  : "submission.submissionDetail.cheatMonitor.isDetected"
              }
            />

            {actionsTabRef && (
              <SeeActionLogLinkText actionsTabRef={actionsTabRef} />
            )}
          </span>
        </div>
      </Banner>
    ) : (
      <Banner
        className="code-c-submission-detail__cheat-monitor-banner"
        type="success"
      >
        <div>
          <span className="banner-link">
            <Msg id="submission.submissionDetail.cheatMonitor.notDetected" />
            {status !== ApplicantExamStatus.Unread && actionsTabRef && (
              <SeeActionLogLinkText actionsTabRef={actionsTabRef} />
            )}
          </span>
        </div>
      </Banner>
    )}
  </>
);
