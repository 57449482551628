import { Epic } from "redux-observable";

import {
  Action,
  ajaxAction,
  authSetAction,
  userGetAction,
  userAuthedAction,
  orgsGetListAllAction,
  challengeFiltersGetAction,
  adminOfficialExamAllListGetAction,
  adminOrgSetAction,
  APIResponseAction,
  allTiersGetAction,
  allActionsGetAction,
  userRefreshAction,
  authSignOutAction,
} from "@actions";

import { RootState } from "@reducers";

import { UserModel } from "@shared/models";
import { UserRole } from "@shared/services/enums";

export const userGetEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(userGetAction.types.request).map((action) =>
    ajaxAction.request({
      method: "get",
      url: "/api/user",
      success: userGetAction.success,
      error: userGetAction.failure,
      options: {
        disableDefaultError: true,
      },
    }),
  );

export const userSetAuthEpic: Epic<Action, RootState> = (action$, state) =>
  action$.ofType(userGetAction.types.success).flatMap((action: Action) => {
    const { roles = [], organization } = (
      action as APIResponseAction & {
        payload: { result: UserModel };
      }
    ).payload.result;
    const authed = roles.some((role) => role.role === UserRole.SystemAdmin);

    return authed
      ? [
          authSetAction.request(true),
          userAuthedAction.request(),
          orgsGetListAllAction.request(),
          challengeFiltersGetAction.request(),
          adminOfficialExamAllListGetAction.request(),
          adminOrgSetAction.request(organization),
          allTiersGetAction.request(),
          allActionsGetAction.request(),
        ]
      : [userGetAction.failure()];
  });

/**
 * Refresh User Cache of API Side
 * @param action$
 * @param state
 */
const userRefreshEpic: Epic<Action, RootState> = (action$, state) =>
  action$
    .ofType(userRefreshAction.types.request)
    .debounceTime(275)
    .map((action) =>
      ajaxAction.request({
        method: "get",
        url: "/api/user",
        success: (response: APIResponseAction & { result: UserModel }) => {
          const user = new UserModel(response.result);

          // check if the user has SystemAdmin privilege just in case.
          return user.hasRole(UserRole.SystemAdmin)
            ? userRefreshAction.success(response)
            : authSignOutAction.request();
        },
        error: (error: string) => {
          if (error.startsWith("404")) {
            return authSignOutAction.request();
          }
          return userRefreshAction.failure();
        },
        options: {
          disableDefaultError: true,
          background: true,
        },
      }),
    );

export default [userGetEpic, userSetAuthEpic, userRefreshEpic];
