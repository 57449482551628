import * as classNames from "classnames";
import * as React from "react";

import {
  ErrorMessage,
  Icon,
  Input,
  Msg,
  ValidationMessage,
  hasValidationError,
} from "@shared/components";
import { AutoFilterType } from "@shared/services/enums";
import Message from "@shared/services/message";

import { AutoFilterFormProps } from "./AutoFilterForm";

export function ScoreInputRow({
  filterType,
  inputName,
  labelBeforeKey,
  labelAfterKey,
  score,
  min,
  max,
  onChange,
  error,
  errorMessage,
  icon,
  readOnly,
}: Pick<AutoFilterFormProps, "filterType" | "error" | "readOnly"> & {
  score?: number;
  min: number;
  max: number;
  onChange: (arg?: number) => void;
  inputName: string;
  icon: "check" | "times";
  labelBeforeKey: string;
  labelAfterKey: string;
  errorMessage?: string;
}) {
  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const key = e.key;
    if (["e", "E", "+", "-"].includes(key)) {
      e.preventDefault();
    }
  };

  return (
    <div className="score-input-row">
      <label htmlFor={inputName}>
        <Icon type={icon} />
        <span>
          <Msg id={labelBeforeKey} />
          {filterType === AutoFilterType.AutoPassFailReview &&
            ` (${Message.getMessageByKey("form.optional")})`}
        </span>
      </label>
      <div className="right-section">
        <div className="score-input-wrapper">
          <Input
            type="number"
            name={inputName}
            value={`${score ?? ""}`}
            className={classNames("score-input", {
              "is-danger": errorMessage || hasValidationError(inputName, error),
            })}
            suffix=".00"
            min={`${min}`}
            max={`${max}`}
            onChange={(e) =>
              onChange(
                e.currentTarget.value === ""
                  ? undefined
                  : Number(e.currentTarget.value.slice(0, 3)), // limit to 3 digits
              )
            }
            disabled={readOnly}
            readOnly={readOnly}
            onKeyDown={onKeyDown}
          />
          <Msg id={labelAfterKey} />
        </div>
        <div className="error-wrapper">
          {/* TODO: can get rid of this once new exam settings have replaced everything */}
          <ValidationMessage
            name={inputName}
            error={error}
            // To append the number in the error message with ".00"
            formatter={(errorString) => errorString.replace(/(\d+)/, "$1.00")}
          />
          <ErrorMessage message={errorMessage} />
        </div>
      </div>
    </div>
  );
}
