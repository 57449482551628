import { Epic } from "redux-observable";

import {
  Action,
  ajaxAction,
  alertAction,
  grantSystemadminRoleAction,
  revokeSystemadminRoleAction,
} from "../actions";
import { RootState } from "../reducers";
import Message from "../shared/services/message";

const grantSystemAdminRoleEpic: Epic<Action, RootState> = (action$) =>
  action$
    .ofType(grantSystemadminRoleAction.types.request)
    .map(({ payload }: Action & { payload: { userId: number } }) =>
      ajaxAction.request({
        method: "put",
        url: `/api/admin/${payload.userId}/systemadmin`,
        success: alertAction.success(
          Message.getMessageByKey("message.member.updated"),
          grantSystemadminRoleAction.success,
        ),
        error: grantSystemadminRoleAction.failure,
      }),
    );

const revokeSystemAdminRoleEpic: Epic<Action, RootState> = (action$) =>
  action$
    .ofType(revokeSystemadminRoleAction.types.request)
    .map(({ payload }: Action & { payload: { userId: number } }) =>
      ajaxAction.request({
        method: "delete",
        url: `/api/admin/${payload.userId}/systemadmin`,
        success: alertAction.success(
          Message.getMessageByKey("message.member.updated"),
          revokeSystemadminRoleAction.success,
        ),
        error: revokeSystemadminRoleAction.failure,
      }),
    );

export default [grantSystemAdminRoleEpic, revokeSystemAdminRoleEpic];
