import { AxiosRequestConfig } from "axios";

import {
  SubmissionResultDetailModel,
  SubmissionResultListModel,
} from "@shared/models";

import { get } from "../httpClient";

interface GetSubmissionResultParams {
  projectId: number;
  submissionId: number;
  options?: AxiosRequestConfig;
}

export interface GetSubmissionResultDetailParams
  extends GetSubmissionResultParams {
  challengeId: number;
}

export async function getSubmissionResults({
  projectId,
  submissionId,
  options,
}: GetSubmissionResultParams) {
  const { data } = await get<SubmissionResultListModel[]>(
    `/api/projects/${projectId}/submissions/${submissionId}/results`,
    options,
  );

  return data;
}

export async function getSubmissionResultDetail({
  projectId,
  submissionId,
  challengeId,
  options,
}: GetSubmissionResultDetailParams) {
  const { data } = await get<SubmissionResultDetailModel>(
    `/api/projects/${projectId}/submissions/${submissionId}/results/${challengeId}`,
    options,
  );

  return data;
}
