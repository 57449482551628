import * as React from "react";

import { Badge } from "@shared/components";

import { IpAddressBadgeHoverBox } from "../IpAddressBadgeHoverBox.tsx/IpAddressBadgeHoverBox";

interface IpAddressBadgeProps {
  ips: string[];
}

export const IpAddressBadge = ({ ips }: IpAddressBadgeProps) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isHovered, setIsHovered] = React.useState(false);
  const ref = React.useRef<HTMLDivElement>(null);

  return (
    <div>
      <Badge
        className="code-ip-address-badge"
        type="warning"
        onMouseOver={() => setIsHovered(true)}
        onMouseOut={() => setIsHovered(false)}
        onClick={() => setIsOpen(!isOpen)}
        ref={ref}
      >
        +{ips.length}
      </Badge>
      {isOpen && ref.current && (
        <IpAddressBadgeHoverBox
          ips={ips}
          targetElement={ref.current}
          onClickOutside={() => {
            if (!isHovered) {
              setIsOpen(false);
            }
          }}
        />
      )}
    </div>
  );
};
