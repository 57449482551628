import * as React from "react";

interface LinkScrollProps {
  children: React.ReactNode;
  className?: string;
  href: string;
}

/**
 * This component renders a link that, when clicked, scrolls to the HTML element
 * with the ID that matches the `href` prop.
 *
 * @component
 * @example
 * // Usage example
 * // <LinkScroll href="#section1">Go to Section 1</LinkScroll>
 *
 * @param {string} props.href - The ID of the HTML element to scroll to. Should be prefixed with `#`.
 *
 * @returns The LinkScroll component
 */
function LinkScroll({ children, className, href }: LinkScrollProps) {
  const handleSubmissionDetailClick = (
    e: React.MouseEvent<HTMLAnchorElement>,
  ) => {
    e.preventDefault();
    const offset = 0;
    const id = e.currentTarget.getAttribute("href")?.slice(1);

    if (!id) {
      return;
    }

    const target = document.getElementById(id);
    const offsetTop =
      (target?.getBoundingClientRect()?.top || 0) + window.scrollY;
    window.scroll({
      top: offsetTop - offset,
      behavior: "smooth",
    });
  };

  return (
    <a className={className} href={href} onClick={handleSubmissionDetailClick}>
      {children}
    </a>
  );
}

export default LinkScroll;
