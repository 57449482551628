import classnames from "classnames";

import { Msg, Tooltip } from "@shared/components";
import Message from "@shared/services/message";

import { SuspiciousEvent } from "../cheatMonitorTypes";

interface SuspicousChallengeListProps {
  suspiciousEvents: SuspiciousEvent[];
}

export const SuspicousChallengeList = ({
  suspiciousEvents,
}: SuspicousChallengeListProps) => {
  if (suspiciousEvents.length <= 0) {
    return null;
  }
  return (
    <Tooltip
      disabled={suspiciousEvents.length === 1}
      maxSize={"large"}
      text={
        Message.getMessageByKey(
          "submission.submissionDetail.cheatMonitor.detectedIn",
        ) +
        suspiciousEvents
          .map((susEvent) => susEvent.examChallengeTitle)
          .join(", ")
      }
    >
      <div className="code-c-custom-form-display__inlinelistwrapper">
        <Msg id="submission.submissionDetail.cheatMonitor.detectedIn" />
        {suspiciousEvents.map((susEvent, index) => (
          <div key={susEvent.examChallengeId}>
            <div
              className={classnames({
                ["code-c-custom-form-display__inlinelistitem"]:
                  suspiciousEvents.length > 1,
              })}
            >
              {susEvent.examChallengeTitle}
            </div>
            {index < suspiciousEvents.length - 1 && <span>, </span>}
          </div>
        ))}
      </div>
    </Tooltip>
  );
};
