class ReadmeModel {
  public challengeId: number;
  public body: string;

  constructor(args?: {}) {
    Object.assign(this, args);
  }
}

export default ReadmeModel;
