import { Action, readmeAction } from "../actions";
import ReadmeModel from "../shared/models/Readme.model";

export interface ReadmeState {
  loadingReadme: boolean;
  readmeList: Array<ReadmeModel>;
}

const initialState = {
  loadingReadme: false,
  readmeList: [],
};

export const readmeReducer = (
  state: ReadmeState = initialState,
  action: Action,
): ReadmeState => {
  switch (action.type) {
    case readmeAction.types.request:
      return { ...state, loadingReadme: true };
    case readmeAction.types.success:
      const readme = new ReadmeModel(action.payload);
      let newReadmeList = [];

      const index = state.readmeList.findIndex(
        (item) => item.challengeId === readme.challengeId,
      );
      if (index === -1) {
        newReadmeList = [...state.readmeList, readme];
      } else {
        newReadmeList = [
          ...state.readmeList.slice(0, index),
          readme,
          ...state.readmeList.slice(index + 1),
        ];
      }

      return {
        ...state,
        loadingReadme: false,
        readmeList: newReadmeList,
      };

    default:
      return state;
  }
};
