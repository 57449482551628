import * as classnames from "classnames";
import { isEqual } from "lodash";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { generate } from "shortid";

import {
  Button,
  Icon,
  DifficultyTag,
  CliSupportBadge,
  ChallengeTitleColumn,
  Restricted,
  LinkedChallengeItem,
  Overlay,
  Msg,
  TogglePin,
  LinkedChallenge,
  Tag,
  RandomizedBadge,
  WarningTag,
  Tooltip,
} from "@shared/components";
import {
  ExamChallengeModel,
  ChallengeModel,
  ExamChallengeSetModel,
} from "@shared/models";
import { isMSEdge } from "@shared/services/browserSupport";
import {
  ChallengeStyle,
  ProjectRole,
  UserRole,
  ChallengeStatus,
  ExamType,
  TierAction,
} from "@shared/services/enums";
import Message from "@shared/services/message";
import {
  isAIAllowed,
  isAllowedDifficulty,
  isChallengeAllowed,
  isDevelopmentAllowed,
} from "@shared/services/tier";

import { ExamChallengeUpdateAvailableBox, ExamSectionUtil } from "..";
import ChallengeDetail from "../../../challenges/challengeDetail/ChallengeDetail.connect";

export interface ExamChallengeSetProps {
  examType?: ExamType;
  readOnly?: boolean;
  editAllowed?: boolean;
  editPin?: boolean;
  showAllErrors?: boolean;
  challengeSet: ExamChallengeSetModel;
  formErrors?: { [key: string]: string };
  showUpdateAvailableBox?: boolean;
  hideCopy?: boolean;
  isClickable?: boolean;
  showExamLevelStats: boolean;
  enableViewCodePlayback: boolean;
  enableViewWebcam: boolean;
  enableApplicantActionLog: boolean;
  isTierActionAllowed: (tierAction: TierAction) => boolean;
  onExamChallengeEdit: (challenge: ExamChallengeModel) => void;
  onChallengeSelect: () => void;
  onRemoveChallenge: (index: number) => void;
  onSwitchLinkedChallenge: (index: number) => void;
  onChallengeMoved: (challengeSet: ExamChallengeSetModel) => void;
  onOpenChallengeReleaseNote: (
    challengeId: number,
    usedChallengeVersionCode: string,
  ) => void;
  onOpenChallengeVersionUpConfirm: (challenge: ExamChallengeModel) => void;
  onTogglePin?: (challengeId: number, pinId?: number) => void;
}

export interface ExamChallengeSetState {
  isOpenLinkedChallenge: boolean;
  openLinkedChallengeIndex?: number;
  drawer: {
    isOpen: boolean;
    targetChallenge?: {
      id: number;
      challengeVersionId: number;
      majorVersionNumber: number;
    };
  };
}

class ExamChallengeSet extends React.Component<
  ExamChallengeSetProps,
  ExamChallengeSetState
> {
  constructor(props: ExamChallengeSetProps) {
    super(props);
    this.state = {
      isOpenLinkedChallenge: false,
      drawer: {
        isOpen: false,
      },
    };
  }

  public shouldComponentUpdate(
    nextProps: Readonly<ExamChallengeSetProps>,
    nextState: Readonly<ExamChallengeSetState>,
  ): boolean {
    return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state);
  }

  public render() {
    const {
      readOnly = false,
      editAllowed = false,
      challengeSet,
      formErrors = {},
      hideCopy,
      editPin = false,
      showExamLevelStats,
    } = this.props;

    const { drawer } = this.state;

    const rootStyle = classnames("code-exam-edit__challenge", {
      "is-edit": !readOnly,
    });

    return (
      <div className={rootStyle}>
        <div className="code-exam-edit__challenge-table">
          <div className="code-exam-edit__challenge-table-header">
            <div />
            <div className="code-exam-edit__challenge-table-header__title">
              <Msg id="common.title" />
              {!readOnly && editAllowed && (
                <Button
                  size="small"
                  disabled={readOnly}
                  shrink={true}
                  onClick={this.props.onChallengeSelect}
                  ariaLabel="Open Challenge List"
                >
                  <Icon type="plus-circle" />
                  <Msg id="action.add" />
                </Button>
              )}
            </div>
            <div>
              <Msg id="challenge.difficulty" />
            </div>
            <div>
              <Msg id="challenge.style" />
            </div>
            <div className="align-right is-nowrap">
              <Msg id="weight" />
            </div>
            <div className="align-right is-nowrap">
              <Msg id="timeLimitMinutes" />
            </div>
            <div />
            <div />
          </div>
          {this.props.showAllErrors && formErrors.challengeSetCount && (
            <div className="code-exam-edit__challenge-table__error">
              <div role="alert">
                {Message.getMessageByKey(formErrors.challengeSetCount)}
              </div>
            </div>
          )}
          <div className="code-exam-edit__challenge-table-items">
            {challengeSet.challenges.map((challenge, index, array) =>
              this.renderChallengeRow(challenge, index, array.length),
            )}
          </div>
        </div>
        {drawer.isOpen && drawer.targetChallenge !== undefined && (
          <>
            <ChallengeDetail
              className="code-exam-edit__challenge__detail-panel"
              challengeId={drawer.targetChallenge.id}
              onClose={this.onCloseChallenge}
              version={drawer.targetChallenge.majorVersionNumber}
              challengeVersionId={drawer.targetChallenge.challengeVersionId}
              hidePin={!editPin}
              hideCopy={hideCopy}
              showExamLevelStats={showExamLevelStats}
            />
            <Overlay
              className="code-exam-edit__challenge__overlay"
              isOpen={true}
              onClick={this.onCloseChallenge}
            />
          </>
        )}
      </div>
    );
  }

  private onOpenChallenge = (
    id: number,
    challengeVersionId: number,
    majorVersionNumber: number,
  ) => {
    // disable scrolling and store the current scroll position
    // TODO: should make this as a shared service...
    document.body.style.top = `-${window.scrollY}px`;
    document.body.style.position = "fixed";

    this.setState({
      drawer: {
        isOpen: true,
        targetChallenge: { id, challengeVersionId, majorVersionNumber },
      },
    });
  };

  private onCloseChallenge = () => {
    // restore the previous scroll position
    const scrollY = document.body.style.top;
    document.body.style.position = "";
    document.body.style.top = "";
    window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);

    this.setState({
      drawer: { isOpen: false },
    });
  };

  private renderChallengeRow = (
    challenge: ExamChallengeModel,
    index: number,
    selectedChallengeCount: number,
  ) => {
    const {
      readOnly = false,
      editAllowed = false,
      editPin = false,
      showUpdateAvailableBox,
      isClickable,
      examType,
      enableViewCodePlayback,
      enableViewWebcam,
      enableApplicantActionLog,
      isTierActionAllowed,
    } = this.props;

    const { isOpenLinkedChallenge, openLinkedChallengeIndex } = this.state;

    const noTimeLimit =
      !challenge.hasOwnProperty("timeLimitMinutes") ||
      typeof challenge.timeLimitMinutes === "undefined";
    const programmingLanguages =
      !ChallengeStyle.hasProgrammingLanguage(challenge.style) &&
      challenge.originalChallenge &&
      challenge.originalChallenge.programmingLanguages
        ? challenge.originalChallenge.programmingLanguages
        : challenge.programmingLanguages;

    // NOTE: the listChallenge in the exam challenge model comes from originalChallenge when it is edit mode.
    const linkedChallenge =
      challenge.linkedChallenge || challenge.originalChallenge.linkedChallenge;
    const canSwitchLinkedChallenge =
      linkedChallenge &&
      linkedChallenge.status === ChallengeStatus.Ready &&
      examType !== ExamType.EnglishJapanese;
    const isChallengeRecordable =
      ExamSectionUtil.isChallengeRecordable(challenge);

    const isPinned = challenge.favoriteId !== undefined;

    const showPlaybackWarning =
      ChallengeStyle.canPlayback(challenge.style) &&
      enableViewCodePlayback &&
      !isChallengeRecordable;

    const showWebcamWarning =
      ChallengeStyle.canWebcam(challenge.style) &&
      enableViewWebcam &&
      !isChallengeRecordable;

    const rowStyle = classnames("code-exam-edit__challenge-table-body", {
      "is-pinned": isPinned,
      "has-two-tags": showPlaybackWarning && showWebcamWarning,
    });

    let containerRef: HTMLDivElement | null = null;

    const challengeAllowed = isChallengeAllowed({
      challenge,
      isTierActionAllowed,
    });

    return (
      <div
        key={generate()}
        className={rowStyle}
        ref={(element) => (containerRef = element)}
      >
        <div className="code-exam-edit__challenge-table__actions-container">
          {!readOnly && (
            <div className="code-exam-edit__challenge-table__actions">
              {index !== 0 && (
                <Button
                  shrink={true}
                  onClick={(e) => this.onMoveChallenge(index, -1, containerRef)}
                  ariaLabel="Move Up"
                >
                  <Icon type="arrow-up" />
                </Button>
              )}
              {index < selectedChallengeCount - 1 && (
                <Button
                  shrink={true}
                  onClick={(e) => this.onMoveChallenge(index, 1, containerRef)}
                  ariaLabel="Move Down"
                >
                  <Icon type="arrow-down" />
                </Button>
              )}
            </div>
          )}
          {readOnly && editPin && (
            <div>
              <TogglePin
                pinned={isPinned}
                onClick={() => this.onTogglePin(challenge)}
              />
            </div>
          )}
          {(!editPin || !readOnly) && isPinned && (
            <div className="code-exam-edit__challenge-table__pinned">
              <Icon type="thumb-tack" />
              <Msg id="common.pinned" />
            </div>
          )}
        </div>
        <div className="code-exam-edit__challenge-table__title">
          <ChallengeTitleColumn
            isClickable={isClickable}
            onClick={() =>
              this.onOpenChallenge(
                challenge.challengeId,
                challenge.challengeVersionId,
                challenge.majorVersionNumber,
              )
            }
            challenge={
              new ChallengeModel({
                ...challenge,
                language: challenge.originalChallenge.language,
                status: challenge.originalChallenge.status,
                programmingLanguages,
              })
            }
            isChallengeAllowed={challengeAllowed}
          />
          <div className="code-exam-edit__tags">
            {challenge.localExamEnabled && (
              <CliSupportBadge
                className="code-exam-edit__cli-support"
                hasError={!isTierActionAllowed(TierAction.CliSupportEnabling)}
                customErrorMessage={
                  !isTierActionAllowed(TierAction.CliSupportEnabling) ? (
                    <Msg id="tier.disabled.notSupported" />
                  ) : undefined
                }
                warningMessage={
                  isTierActionAllowed(TierAction.CliSupportEnabling) &&
                  (enableApplicantActionLog ||
                    enableViewCodePlayback ||
                    enableViewWebcam) &&
                  challenge.localExamEnabled && (
                    <Msg id="exam.monitoring.warning.notSupported" />
                  )
                }
              />
            )}
            {challenge.randomizeQuiz && (
              <RandomizedBadge
                className="code-exam-edit__cli-support"
                hasError={!isTierActionAllowed(TierAction.RandomQuiz)}
              />
            )}
          </div>
          {canSwitchLinkedChallenge &&
            linkedChallenge &&
            !readOnly &&
            editAllowed && (
              <div className="code-exam-edit__challenge__switch-linked-challenge">
                <Button
                  size="small"
                  onClick={() => this.onOpenLinkedChallenge(index)}
                  ariaLabel="Switch Challenge Language"
                >
                  <Icon type="exchange" />
                  <Msg id={"challenge.switchSpokenLanguage"} />
                </Button>
                {isOpenLinkedChallenge &&
                  openLinkedChallengeIndex === index && (
                    <Overlay
                      isOpen={true}
                      onClick={this.onCloseLinkedChallenge}
                    />
                  )}
                {isOpenLinkedChallenge &&
                  openLinkedChallengeIndex === index && (
                    <LinkedChallengeItem
                      challenge={linkedChallenge}
                      hasBorderTop={true}
                      onClick={() => this.onChangeLinkedChallenge(index)}
                    />
                  )}
              </div>
            )}
          {examType === ExamType.EnglishJapanese && linkedChallenge && (
            <LinkedChallenge
              title={linkedChallenge.title}
              language={linkedChallenge.language}
              version={`${linkedChallenge.currentVersion.majorVersionNumber}.${linkedChallenge.currentVersion.minorVersionNumber}`}
              onClick={
                isClickable && challengeAllowed
                  ? () =>
                      this.onOpenChallenge(
                        linkedChallenge.id,
                        linkedChallenge.currentVersion.id,
                        linkedChallenge.currentVersion.majorVersionNumber,
                      )
                  : undefined
              }
            />
          )}
        </div>
        <div>
          <DifficultyTag
            value={challenge.difficulty}
            hasError={!isAllowedDifficulty(challenge, isTierActionAllowed)}
          />
        </div>
        <div>
          <Tag
            hasError={
              !isDevelopmentAllowed(challenge, isTierActionAllowed) ||
              !isAIAllowed(challenge, isTierActionAllowed)
            }
          >
            {ChallengeStyle.toString(challenge.style)}
          </Tag>
        </div>
        <div className="align-right">{challenge.weight}</div>
        <div className="align-right">
          <div className="code-exam-edit__challenge-table__timeLimit">
            <div>
              {noTimeLimit ? (
                <Msg id={"noLimit"} />
              ) : (
                challenge.timeLimitMinutes
              )}
            </div>
            {showPlaybackWarning && (
              <Tooltip
                text={<Msg id="exam.playback.warning.maxTime" />}
                placement="bottom-end"
                maxSize="xlarge"
              >
                <WarningTag>
                  <Msg id="exam.playback" />
                </WarningTag>
              </Tooltip>
            )}
            {showWebcamWarning && (
              <Tooltip
                text={<Msg id="exam.webcam.warning.maxTime" />}
                placement="bottom-end"
                maxSize="xlarge"
              >
                <WarningTag>
                  <Msg id="exam.webcam" />
                </WarningTag>
              </Tooltip>
            )}
          </div>
        </div>
        <div>
          {!readOnly && (
            <Button
              size="small"
              shrink={true}
              onClick={() => this.props.onExamChallengeEdit(challenge)}
              ariaLabel="Edit"
            >
              <Icon type="pencil" />
            </Button>
          )}
        </div>
        <div>
          {!readOnly && editAllowed && (
            <a
              className="code-exam-edit__challenge-table__actions delete"
              onClick={() => this.props.onRemoveChallenge(index)}
            />
          )}
        </div>
        <div className="code-exam-edit__challenge-table__version-box">
          <Restricted
            roles={[
              UserRole.SystemAdmin,
              ProjectRole.ProjectAdmin,
              ProjectRole.ExamCreator,
              ProjectRole.ExamDeliverer,
            ]}
            strictAllow={readOnly && showUpdateAvailableBox}
          >
            <ExamChallengeUpdateAvailableBox
              examType={examType}
              challenge={challenge}
              onOpenChallengeReleaseNote={this.props.onOpenChallengeReleaseNote}
              onOpenChallengeVersionUpConfirm={
                this.props.onOpenChallengeVersionUpConfirm
              }
            />
          </Restricted>
        </div>
      </div>
    );
  };

  private onMoveChallenge = (
    oldIndex: number,
    direction: 1 | -1,
    containerElement: HTMLDivElement | null,
  ) => {
    const challenges = [...this.props.challengeSet.challenges];
    const toMove = challenges.splice(oldIndex, 1)[0];
    challenges.splice(oldIndex + direction, 0, toMove);
    // for the scrolling to the target element
    if (containerElement) {
      const currentDOM = ReactDOM.findDOMNode(containerElement) as Element;
      // NOTE: window.scrollTo does not work in MSEdge
      if (!isMSEdge) {
        window.scrollTo({
          behavior: "smooth",
          top: window.pageYOffset + currentDOM.clientHeight * direction,
        });
      }
    }
    this.props.onChallengeMoved({
      ...this.props.challengeSet,
      ...{ challenges },
    });
  };

  private onOpenLinkedChallenge = (openLinkedChallengeIndex: number) => {
    this.setState({ isOpenLinkedChallenge: true, openLinkedChallengeIndex });
  };

  private onCloseLinkedChallenge = () => {
    this.setState({
      isOpenLinkedChallenge: false,
      openLinkedChallengeIndex: undefined,
    });
  };

  private onChangeLinkedChallenge = (index: number) => {
    if (typeof this.props.onSwitchLinkedChallenge === "function") {
      this.props.onSwitchLinkedChallenge(index);
    }
    this.setState({ isOpenLinkedChallenge: false });
  };

  private onTogglePin = (challenge: ExamChallengeModel) => {
    const { challengeId, favoriteId: pinId } = challenge;
    this.props.onTogglePin?.(challengeId, pinId);
  };
}

export default ExamChallengeSet;
