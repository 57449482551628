import * as classnames from "classnames";
import * as React from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";

import {
  Alert,
  Block,
  LeftBlock,
  RightBlock,
  Button,
  Table,
  TableHead,
  TableBody,
  Row,
  Column,
  HeaderColumn,
  Icon,
  PageTitle,
  Msg,
  Modal,
  BlockItem,
  Tooltip,
  ErrorIcon,
} from "@shared/components";
import {
  SlackModel,
  SlackIntegrationInputModel,
  SlackIntegrationModel,
  SlackChannelModel,
} from "@shared/models";
import { formatDate } from "@shared/services/date";
import {
  IntegrationSocialEvent,
  SpokenLanguages,
} from "@shared/services/enums";

import {
  SettingSlackIntegrationAddChannelModal,
  SettingSlackIntegrationUpdateChannelModal,
} from "./partials";

/**
 * Prop interface
 */
export interface SettingSlackIntegrationProps {
  projectId: number;
  slackSocial: SlackModel;
  socialChannels: SlackChannelModel[];
  slackSocialDenied: boolean;
  slackSocialLoading: boolean;
  getSlackSocial: (projectId: number) => void;
  getSlackRegistration: (projectId: number) => void;
  deleteSocial: (projectId: number, socialId: number) => void;
  moveSocialChannel: (
    projectId: number,
    socialId: number,
    integrationIdToDelete: number,
    params: SlackIntegrationInputModel,
  ) => void;
  addSocialChannel: (
    projectId: number,
    socialId: number,
    params: SlackIntegrationInputModel,
  ) => void;
  updateSocialChannel: (
    projectId: number,
    socialId: number,
    integrationId: number,
    params: SlackIntegrationInputModel,
  ) => void;
  deleteSocialChannel: (
    projectId: number,
    socialId: number,
    integrationId: number,
  ) => void;
}

/**
 * Page component
 */
export const SettingSlackIntegration: React.FunctionComponent<
  SettingSlackIntegrationProps
> = ({
  projectId,
  slackSocial,
  slackSocialDenied,
  socialChannels,
  slackSocialLoading,
  getSlackSocial,
  getSlackRegistration,
  deleteSocial,
  moveSocialChannel,
  addSocialChannel,
  updateSocialChannel,
  deleteSocialChannel,
}: SettingSlackIntegrationProps) => {
  React.useEffect(() => {
    getSlackSocial(projectId);
  }, [projectId, getSlackSocial]);

  const [isRemoveIntegrationOpen, setRemoveIntegrationOpen] =
    React.useState(false);
  const [isAddChannelOpen, setAddChannelOpen] = React.useState(false);

  const [channelToUpdate, setChannelToUpdate] =
    React.useState<SlackIntegrationModel | null>(null);
  const [channelToDelete, setChannelToDelete] =
    React.useState<SlackIntegrationModel | null>(null);

  const rootStyle = classnames("code-setting-integration");

  const renderIntegrationContent = () => {
    const addChannelButton = (
      <Button
        type="primary"
        onClick={() => setAddChannelOpen(true)}
        ariaLabel="Add channel for notifications"
        disabled={slackSocialDenied || !socialChannels.length}
      >
        <Icon type="plus" />
        <Msg id="slack.addChannelNotification" />
      </Button>
    );

    return (
      <>
        {slackSocialDenied && (
          <Block>
            <Alert type="warning">
              <Msg id="slack.slackSocialDenied" />
            </Alert>
          </Block>
        )}
        <Block className="code-setting-integration__workspace">
          <div className="code-setting-integration__workspace__card">
            <div className="is-nowrap">
              <Msg id="slack.workspaceName" />
            </div>
            <div>{slackSocial.displayName}</div>
            <div className="is-nowrap">
              <Msg id="slack.createIntegrationDate" />
            </div>
            <div>{formatDate(slackSocial.createdAt)}</div>
          </div>
        </Block>
        <Block className="code-setting-integration__channel-header">
          <LeftBlock className="code-setting-integration__block">
            <p className="code-setting-integration__block__sub-title">
              <Msg id="slack.channels" />
            </p>
            <span>
              {slackSocial?.integrations?.length ?? 0}{" "}
              <Msg
                id={
                  slackSocial?.integrations?.length === 1
                    ? "searchResult"
                    : "searchResults"
                }
              />
            </span>
          </LeftBlock>
          <RightBlock>
            {socialChannels.length || slackSocialDenied ? (
              addChannelButton
            ) : (
              <Tooltip text={<Msg id="slack.noAvailableChannels" />}>
                {addChannelButton}
              </Tooltip>
            )}
          </RightBlock>
        </Block>
        <div>
          <Table>
            <TableHead>
              <HeaderColumn size={2}>
                <Msg id="common.name" />
              </HeaderColumn>
              <HeaderColumn size={3}>
                <Msg id="slack.notifications" />
              </HeaderColumn>
              <HeaderColumn size={1}>
                <Msg id="common.language" />
              </HeaderColumn>
              <HeaderColumn size={1} />
            </TableHead>
            <TableBody>
              {slackSocial?.integrations.map((channel) => {
                const {
                  channelId,
                  displayName,
                  language,
                  events,
                  isPrivate,
                  mostRecentDeliveryResult,
                } = channel;

                return (
                  <Row key={channelId}>
                    <Column size={2}>
                      <div className="code-setting-integration__column-name">
                        <span>
                          <Icon type={isPrivate ? "lock" : "hashtag"} />
                          {displayName}
                        </span>
                        {mostRecentDeliveryResult?.error && (
                          <ErrorIcon
                            tooltipText={SlackIntegrationModel.getIntegrationErrorString(
                              mostRecentDeliveryResult?.error,
                            )}
                          />
                        )}
                      </div>
                    </Column>
                    <Column size={3}>
                      <div>
                        {events.map((event) => (
                          <div key={event}>
                            <span>
                              <Icon type="check" />
                              {IntegrationSocialEvent.toString(event)}
                            </span>
                          </div>
                        ))}
                      </div>
                    </Column>
                    <Column size={2}>
                      {SpokenLanguages.toString(language)}
                    </Column>
                    <Column size={1}>
                      <div className="code-setting-integration__actions">
                        <Button
                          size="small"
                          shrink
                          onClick={() => setChannelToUpdate(channel)}
                          disabled={slackSocialDenied}
                          ariaLabel="Edit Channel Button"
                        >
                          <Icon type="pencil" />
                        </Button>
                        <Button
                          size="small"
                          shrink
                          onClick={() => setChannelToDelete(channel)}
                          disabled={slackSocialDenied}
                          ariaLabel="Delete Channel Button"
                        >
                          <Icon type="trash" />
                        </Button>
                      </div>
                    </Column>
                  </Row>
                );
              })}
            </TableBody>
          </Table>
        </div>
      </>
    );
  };

  return (
    <div className={rootStyle}>
      <BreadcrumbsItem to="/settings/profile/slackIntegration">
        <Msg id="slack.integration" />
      </BreadcrumbsItem>
      <Block className="code-setting-integration__header">
        <LeftBlock>
          <PageTitle>
            <Msg id="slack.integration" />
          </PageTitle>
        </LeftBlock>
        <RightBlock>
          {!slackSocialLoading && !slackSocial && (
            <Button
              type="primary"
              onClick={() => getSlackRegistration(projectId)}
              ariaLabel="Create Slack Integration"
            >
              <Icon type="external-link" />
              <span>
                <Msg id="slack.createIntegration" />
              </span>
            </Button>
          )}
          {slackSocial && (
            <Button
              type="danger"
              ariaLabel="Remove Slack Integration"
              onClick={() => setRemoveIntegrationOpen(true)}
            >
              <span>
                <Msg id="slack.removeSlackIntegration" />
              </span>
            </Button>
          )}
        </RightBlock>
      </Block>
      <div className="code-setting-integration__body">
        {!slackSocial && (
          <p>
            <Msg id={"slack.noIntegrations"} />
          </p>
        )}
        {slackSocial && renderIntegrationContent()}
      </div>
      {isRemoveIntegrationOpen && (
        <Modal
          title={<Msg id="confirm.delete" />}
          isOpen={true}
          onClose={() => setRemoveIntegrationOpen(false)}
          okButtonType="danger"
          okButtonLabel={<Msg id="action.remove" />}
          onClickCancel={() => setRemoveIntegrationOpen(false)}
          onClickOk={() => {
            deleteSocial(projectId, slackSocial.id);
            setRemoveIntegrationOpen(false);
          }}
          ariaLabel="Confirm Delete"
        >
          <Msg id="slack.confirmIntegrationDeleteMessage" />
        </Modal>
      )}
      {channelToDelete && (
        <Modal
          title={<Msg id="confirm.delete" />}
          isOpen={true}
          okButtonType="danger"
          okButtonLabel={<Msg id="action.remove" />}
          onClose={() => setChannelToDelete(null)}
          onClickCancel={() => setChannelToDelete(null)}
          onClickOk={() => {
            setChannelToDelete(null);
            deleteSocialChannel(projectId, slackSocial.id, channelToDelete.id);
          }}
          ariaLabel="Confirm Delete"
        >
          <Block>
            <p>
              <Msg id="slack.confirmChannelDeleteMessage" />
            </p>
          </Block>
          <Block>
            <BlockItem
              direction="column"
              className="code-setting-integration__delete-channel-modal-channel"
            >
              <p>
                <Msg id="slack.channel" />
              </p>
              <p>
                <span>
                  <Icon type={channelToDelete.isPrivate ? "lock" : "hashtag"} />
                  <span className="is-bold">{channelToDelete.displayName}</span>
                </span>
              </p>
            </BlockItem>
          </Block>
        </Modal>
      )}
      {isAddChannelOpen && (
        <SettingSlackIntegrationAddChannelModal
          isOpen={isAddChannelOpen}
          onClickCancel={() => setAddChannelOpen(false)}
          onClickOk={(params) => {
            addSocialChannel(projectId, slackSocial.id, params);
            setAddChannelOpen(false);
          }}
        />
      )}
      {channelToUpdate && (
        <SettingSlackIntegrationUpdateChannelModal
          isOpen={true}
          slackChannelIntegrations={slackSocial.integrations}
          channelClicked={channelToUpdate}
          onClickCancel={() => setChannelToUpdate(null)}
          onClickOk={(integrationId, params) => {
            // if channel changes then need to delete the
            // current channel and create a new channel
            if (channelToUpdate.channelId !== params.channelId) {
              moveSocialChannel(
                projectId,
                slackSocial.id,
                channelToUpdate.id,
                params,
              );
            } else {
              updateSocialChannel(
                projectId,
                slackSocial.id,
                integrationId,
                params,
              );
            }
            setChannelToUpdate(null);
          }}
        />
      )}
    </div>
  );
};
