import * as classnames from "classnames";
import * as React from "react";

import {
  Msg,
  Block,
  LeftBlock,
  RightBlock,
  BlockItem,
  PageTitle,
  Restricted,
  Button,
  Icon,
  Tooltip,
} from "@shared/components";
import { ProjectRole, TierAction, UserRole } from "@shared/services/enums";
import Message from "@shared/services/message";

/**
 * Props
 */
export interface ExternalProps {
  isGivery: boolean;
  count?: number;
  onClickNewQuiz: () => void;
}

type InjectedProps = {
  isTierActionAllowed: (tierAction: TierAction) => boolean;
};

type ChallengeListHeaderProps = ExternalProps & InjectedProps;

export const ChallengeListHeader: React.FunctionComponent<
  ChallengeListHeaderProps
> = (props: ChallengeListHeaderProps) => {
  const { count, isTierActionAllowed } = props;
  const rootStyle = classnames("code-challenge-list-header");

  return (
    <Block className={rootStyle}>
      <LeftBlock>
        <PageTitle>
          <Msg id="common.challengeList" />
          <span className="code-challenge-list-header__count">
            {Message.getSearchResultFormat(count)}
          </span>
        </PageTitle>
      </LeftBlock>
      <RightBlock>
        <BlockItem>
          <Restricted
            roles={[
              UserRole.SystemAdmin,
              UserRole.ChallengeCreator,
              ProjectRole.ProjectAdmin,
              ProjectRole.ChallengeCreator,
            ]}
            strictAllow={!props.isGivery}
          >
            <Tooltip
              disabled={isTierActionAllowed(TierAction.CustomQuizUsage)}
              text={Message.getMessageByKey("tier.disabled")}
              placement="top-end"
            >
              <Button
                type="primary"
                onClick={props.onClickNewQuiz}
                ariaLabel="Open New Quiz"
                disabled={!isTierActionAllowed(TierAction.CustomQuizUsage)}
              >
                <Icon type="plus" />
                <Msg id="button.newQuizStyle" />
              </Button>
            </Tooltip>
          </Restricted>
        </BlockItem>
      </RightBlock>
    </Block>
  );
};
