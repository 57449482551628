import { isEqual } from "lodash";
import * as React from "react";

import { CustomForm, Form, FormGroup, Input, Label } from "../../components";
import { CustomFormDefinitionModel } from "../../models";
import { dayjs } from "../../services/date";
import { CustomFormDefinitionType } from "../../services/enums";
import Message from "../../services/message";

/**
 * Prop interface
 */
export interface CustomFormInputProps {
  definitions?: CustomFormDefinitionModel[];
  fullname?: string;
  fullnameRequired?: boolean;
  formValues?: {};
  examLanguage?: string;
  onFormChange?: (formValid: boolean, formValues: {}, formErrors?: {}) => void;
  showErrors?: boolean;
}

/**
 * Page component
 */
export default class CustomFormInput extends React.Component<CustomFormInputProps> {
  public shouldComponentUpdate(nextProps: CustomFormInputProps) {
    return !isEqual(nextProps, this.props);
  }

  public render() {
    const {
      definitions = [],
      formValues = {},
      fullname,
      fullnameRequired,
      examLanguage,
      showErrors = false,
    } = this.props;

    const customFormItems = definitions.map(
      (definition: CustomFormDefinitionModel, index: number) => {
        return (
          <CustomForm
            name={definition.key}
            key={index}
            definition={definition}
          />
        );
      },
    );

    const initialFullNameValue = fullnameRequired ? { fullname } : {};
    const fullNameValidation = fullnameRequired
      ? { fullname: ["string", ["max", 200], "notEmpty", "required"] }
      : undefined;

    const initialFormValues = definitions.reduce((result, definition) => {
      if (definition.dataType === CustomFormDefinitionType.Checkbox) {
        const options = (formValues[definition.key] as string[]) || [];
        const checkboxNames = definition.getKeys();
        const checkboxValues = definition.options.reduce(
          (checkboxResult, value, index) =>
            Object.assign(checkboxResult, {
              [`${checkboxNames[index]}`]: options.includes(value),
            }),
          {},
        );
        return Object.assign(result, { ...checkboxValues });
      } else if (definition.dataType === CustomFormDefinitionType.DateRange) {
        return Object.assign(result, {
          [`${definition.key}`]:
            typeof formValues[definition.key] === "string"
              ? formValues[definition.key].split("/")
              : [],
        });
      } else {
        return Object.assign(result, {
          [`${definition.key}`]: formValues[definition.key],
        });
      }
    }, {});

    const formValidations = definitions.reduce(
      (result, item) => Object.assign(result, item.toValidationRule()),
      {},
    );

    const initialValues = { ...initialFullNameValue, ...initialFormValues };
    const validation = { ...fullNameValidation, ...formValidations };

    return (
      <div className="code-c-custom-form-input">
        <Form
          initialValues={initialValues}
          validation={validation}
          onFormChange={this.onFormChange}
          showAllErrors={showErrors}
        >
          {fullnameRequired && (
            <FormGroup>
              <Label
                title={
                  examLanguage
                    ? Message.getMessageByKeyAndLocale(
                        "form.fullname",
                        examLanguage,
                      )
                    : Message.getMessageByKey("form.fullname")
                }
              />
              <Input name="fullname" />
            </FormGroup>
          )}
          {Boolean(definitions.length) && customFormItems}
        </Form>
      </div>
    );
  }

  private onFormChange = (
    formValid: boolean,
    formValues: { [key: string]: string | boolean },
    formErrors: {},
  ) => {
    const { definitions = [] } = this.props;

    const newFormValues = definitions.reduce((result, item) => {
      let value;
      if (item.dataType === CustomFormDefinitionType.Checkbox) {
        value = item
          .getKeys()
          .filter((key) => formValues[key] === true)
          .map((key) => {
            // pick up last item as a array index. ex) experience__0 => 0. then get an item from options.
            const index = key.split("__").slice(-1)[0];
            return item.options[index];
          });

        value = value.length ? value : undefined;
      } else if (item.dataType === CustomFormDefinitionType.Date) {
        const dateString = formValues[item.key] as string;
        value = dateString
          ? // MEMO: backend accept only this format
            dayjs(formValues[item.key] as string).format("YYYY-MM-DD")
          : undefined;
      } else if (item.dataType === CustomFormDefinitionType.DateRange) {
        const dateRangeValues =
          (formValues[item.key] as unknown as string[]) || [];

        if (!dateRangeValues.length) {
          value = undefined;
        } else {
          value = dateRangeValues
            .map((value) => dayjs(value).format("YYYY-MM-DD"))
            .join("/");
        }
      } else {
        value = formValues[item.key];
      }

      if (
        value === undefined &&
        item.dataType !== CustomFormDefinitionType.DateRange
      ) {
        return result;
      }
      return Object.assign(result, { [`${item.key}`]: value });
    }, {});

    const fullname = formValues.fullname;

    if (typeof this.props.onFormChange === "function") {
      this.props.onFormChange(
        formValid,
        { fullname, ...newFormValues },
        formErrors,
      );
    }
  };
}
