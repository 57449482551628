class IdDeliveryConfigModel {
  public id: number;
  public examId: number;
  public startAt: string;
  public endAt: string;
  public dynamicDeadlineHours: number;
  public passwordRequired: boolean;

  constructor(args?: {}) {
    Object.assign(this, args);
  }
}

export default IdDeliveryConfigModel;
