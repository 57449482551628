import { connect } from "react-redux";
import { Action, Dispatch } from "redux";

import { userRefreshAction } from "../../actions";
import { RootState } from "../../reducers";
import OrgsContainer from "./OrgsContainer";

interface OwnProps {
  location: {};
  hasSideMenu?: boolean;
}

const mapStateToProps = (state: RootState, props: OwnProps) => ({
  ...props,
  currentProjectId: state.project.currentProjectId,
  currentUser: state.user.user,
  orgLogo: state.user.user ? state.user.user.organization.imageUrl : null,
  orgName: state.user.user ? state.user.user.organization.displayName : null,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  refreshUser: () => {
    dispatch(userRefreshAction.request());
  },
});

/**
 * Wrapped component
 */
const wrappedComponent = connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrgsContainer);

export default wrappedComponent;
